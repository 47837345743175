//
// SIGN IN/SIGN UP PAGE
// Some complexity is around working with the buttons provided by Google and Apple plugins
// there is also very subtle effects on the form as it's filled out, so that most obvious option is always clear
//

$width-signinsignup-centerBlock: 300px;
$red-hover: rgb(206,92,92);

.grecaptcha-badge{
  visibility: hidden !important; // hide captcha logo
}

.signin,
.signup,
.forgotPassword{
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgb(253,253,255);

  overflow: hidden;
}

.signin-centerBlock,
.signup-centerBlock,
.forgotPassword-centerBlock {
  height: 100%;
  margin: 0 auto;

  display: flex;
  flex-direction: column;
}

.signin-centerBlock,
.signup-centerBlock {
  width: $width-signinsignup-centerBlock;
}

.forgotPassword-centerBlock {
  width: 340px
}

//

@import 'SignInSignUp-Forms';
@import 'SignInSignUp-Divider';
@import 'VerifyPasswordToSignin-Form';

//
// PRIMARY BUTTONS - SHARED STYLING
//

$height-signinsignup-primaryButton: 38px;

button.signin-signInWithGoogleBtn,
button.signin-signInWithAppleBtn,
button.signin-form-submitBtn,
button.signup-signInWithGoogleBtn,
button.signup-signInWithAppleBtn,
button.signup-form-submitBtn,
.verifyPasswordToSignin-form .btn {
  width: 100%;
  height: $height-signinsignup-primaryButton;
  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 5px;

  border: 1px solid;

  cursor: pointer;
  box-sizing: border-box;

  font-size: 19px;
  font-weight: $effra-medium;

  display: flex;
  align-items: center;
  justify-content: center;

  position: relative;
}

// overwrite "default" font-size and weight to
// align with google-provided button
button.signin-signInWithAppleBtn,
button.signup-signInWithAppleBtn {
  font-size: 15px;
  font-weight: $effra-regular;
}

@import 'SignInSignUp-GoogleAndAppleButtons';
@import 'SignInSignUp-FormSubmitButtons';
@import 'SignInSignUp-ResetPassword';

//
// SIGN IN LAYOUT

.signin-centerBlock-logo{
  width: 100%;
  flex-grow: 0;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 46px;
}

.signin-centerBlock-logo .icon{
  width: 340px;
  height: 72px;
}

.signin-centerBlock--top{
  width: 100%;
  flex-grow: 1;
  flex-shrink: 1;
}

.signin-centerBlock--bottom{
  width: 100%;
  flex-grow: 2;
  flex-shrink: 2;
}

.signin-centerBlock--middle{
  width: 100%;
  justify-content: center;
  align-items: center;
  position: relative;
}


//
// SIGN UP LAYOUT

.signup-centerBlock--top{
  width: 100%;
  flex-grow: 1;
  flex-shrink: 1;
}

.signup-centerBlock--bottom{
  width: 100%;
  flex-grow: 2;
  flex-shrink: 2;
}

.signup-centerBlock--middle{
  width: 100%;
  justify-content: center;
  align-items: center;
  position: relative;
}

.signup-centerBlock--top{
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: column;
}

.signup-centerBlock-logo{
  width: 100%;
  flex-grow: 0;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.signup-centerBlock-logo .icon{
  width: 75px;
  height: 75px;
}

.signup-centerBlock-logoSpacer-top{
  flex-grow: 1;
  flex-shrink: 1;
  height: 1px;
  width: 100%;
}

.signup-centerBlock-logoSpacer-bottom{
  flex-grow: 1;
  height: 1px;
  max-height: 64px;
  width: 100%;
}

.signup-centerBlock-title{
  font-size: 44px;
  font-weight: $effra-bold;
  color: $textBlack;
  width: 100%;
  text-align: center;

  margin-bottom: 35px;
  cursor: default;
  user-select: none;
}

//
// FORGOT PASSWORD LAYOUT
//

.forgotPassword-centerBlock--top{
  width: 100%;
  flex-grow: 1;
  flex-shrink: 1;
}

.forgotPassword-centerBlock--bottom{
  width: 100%;
  flex-grow: 2;
  flex-shrink: 2;
}

.forgotPassword-centerBlock--middle{
  width: 100%;
  justify-content: center;
  align-items: center;
  position: relative;

  height: 235px;
}

.forgotPassword-centerBlock-title{
  font-size: 42px;
  font-weight: $effra-bold;
  color: $textBlack;
  width: 100%;
  text-align: center;

  margin-bottom: 20px;
  cursor: default;
  user-select: none;
}

.forgotPassword-centerBlock-subtitle{
  font-size: 16px;
  font-weight: $effra-medium;
  color: $textMid;
  width: 100%;
  text-align: center;

  margin-bottom: 22px;
  cursor: default;
  user-select: none;
}

.forgotPassword-centerBlock-sentEmailMessage{
  font-size: 20px;
  font-weight: $effra-medium;
  color: $blue;
  width: 100%;
  text-align: center;

  cursor: default;
  user-select: none;
}


.forgotPassword .signup-form-submitBtn{
  margin-top: 10px;// REFACTOR LATER TO DO FORGOT PASSWORD BUTTONS NICELY
}


.forgotPassword-backtoPlickersLogin,
.resetPassword-backtoPlickersLogin{
  width: 175px;

  position: absolute;
  left: 16px;
  top: 16px;

  background: none;
  height: 36px;
  border-radius: 5px;

  font-weight: $effra-medium;
  font-size: 15px;

  color: $blue-light;

  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;
}

.forgotPassword-backtoPlickersLogin:hover,
.resetPassword-backtoPlickersLogin:hover{
  color: $textBlue;
  background: $blue-extraLight;
}

.forgotPassword-backtoPlickersLogin:active,
.resetPassword-backtoPlickersLogin:active{
  background: darken($blue-extraLight, 4%);
}


//
// SIGN UP DEVICE ARRAY
//

.signup-deviceArray{
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  pointer-events: none;
  opacity: 0.9;
}


.signup-deviceArray-topLeft{
  position: absolute;
  top: -510px;
  left: -966px;
  transform: scale(0.67) rotate(-13deg);
}

.signup-deviceArray-topRight{
  position: absolute;
  top: -466px;
  right: -147px;
  transform: scale(0.67) rotate(113deg);
}

.signup-deviceArray-bottomLeft{
  position: absolute;
  bottom: -400px;
  left: -70px;
  transform: scale(0.67) rotate(-16deg);
}

.signup-deviceArray-bottomRight{
  position: absolute;
  bottom: -470px;
  right: -870px;
  transform: scale(0.67) rotate(6deg);
}


//
// ERROR MESSAGE (SIGN IN), GO TO CREATE ACCOUNT
// AND GO TO LOGIN LINKS
//

.signin-centerBlock--bottom,
.signup-centerBlock--bottom,
.forgotPassword-centerBlock--bottom{
  position: relative;
}

.signin-centerBlock-error,
.signup-centerBlock-error,
.forgotPassword-centerBlock-error {
  color: $red;
  text-align: center;
  font-size: 15px;
  font-weight: $effra-medium;

  margin-top: 10px;
}

.signin-centerBlock-error-link {
  cursor: pointer;
  text-decoration: underline;
}

.signin-centerBlock-error-link:hover {
  color: $red-hover;
}

.signin-centerBlock-goToCreateAccount,
.signup-centerBlock-goToLoginBtn {
  width: 100%;
  margin-top: 20px;

  font-size: 14px;
  font-weight: $effra-regular;

  color: $textLight;
  text-align: center;

  cursor: pointer;

}

.signin-centerBlock-goToCreateAccount:hover,
.signin-centerBlock-goToCreateAccount:focus-within,
.signup-centerBlock-goToLoginBtn:hover,
.signup-centerBlock-goToLoginBtn:focus-within {
  outline: none;
  text-decoration: underline;
}

.signin-centerBlock-goToCreateAccount--bold {
  font-weight: $effra-medium;
}

.signin-centerBlock-goToCreateAccount-signUp {
  color: $blue-light;
}

.signin-centerBlock-goToCreateAccount:hover .signin-centerBlock-goToCreateAccount-signUp {
  color: $textBlue;
}









