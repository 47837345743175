//
// SCORESHEET SIDEPANEL CALENDAR
// loaded with props in ScoreSheetSidePanelCalendar.js
// uses react-dates library - see https://github.com/airbnb/react-dates
// this file is styling overrides
//


// React-Dates Overrides
.scoreSheetSidePanel-calendar {

	.CalendarDay{
		height: 24px !important;
		line-height: 1.5;
	}

	// HIGHLIGHTED
	// primarily useful in mini-calendar

	.CalendarDay__default.CalendarDay__highlighted_calendar,
	.CalendarDay__default.CalendarDay__highlighted_calendar:hover{	
		border-radius: 4px;
		background: #ffe8bc; //initial value
		border-color: #ffe8bc;	
		color: $textBlackDark !important;
	}

	.DayPicker{
		box-shadow: none;
		background: none;
		border: none;
	}

	.CalendarDay__default{
		font-weight: $lato-semibold;
		color: $textDark;
		border-color: $white;
		font-size: 13.5px;
	}

	.CalendarDay__default:hover{
		background: $gray-15;
		color: $textBlack;
		cursor: pointer;
	}

	.CalendarDay__default.CalendarDay__outside{
		color: $textLight;
	}

	.CalendarDay__default.CalendarDay__outside:hover{
		color: $textMid;
	}

	.CalendarDay__selected_span,
	.CalendarDay__selected,
	.CalendarDay__selected:active,
	.CalendarDay__selected_span:hover,
	.CalendarDay__selected:hover,
	.CalendarDay__selected.CalendarDay__highlighted_calendar,
	.CalendarDay__selected.CalendarDay__highlighted_calendar:hover,
	.CalendarDay__selected_span.CalendarDay__highlighted_calendar,
	.CalendarDay__selected_span.CalendarDay__highlighted_calendar:hover,
	.CalendarDay__default.CalendarDay__selected_span.CalendarDay__blocked_out_of_range,
	.CalendarDay__default.CalendarDay__selected_span.CalendarDay__outside,
	.CalendarDay__default.CalendarDay__blocked_out_of_range.CalendarDay__selected_end{
		background: $blue !important;
		border-color: $blue !important;
		color: $white !important;
		border-radius: 0px !important;
	}

	// OUT OF RANGE BUT STILL SELECTED
	.CalendarDay__default.CalendarDay__selected_span.CalendarDay__blocked_out_of_range,
	.CalendarDay__default.CalendarDay__blocked_out_of_range.CalendarDay__selected_end{
		color: transparentize($white, 0.25);
	}

	.CalendarDay__hovered_span,
	.CalendarDay__hovered_span:hover{
		background: $gray-14;
		color: $textBlack;
		border-color: $lineMid;
	}

	.CalendarDay__default.CalendarDay__blocked_out_of_range,
	.CalendarDay__default.CalendarDay__outside.CalendarDay__blocked_out_of_range,
	.CalendarDay__default.CalendarDay__blocked_out_of_range:hover
	.CalendarDay__default.CalendarDay__outside.CalendarDay__blocked_out_of_range:hover{
		background: rgb(248,248,253);
		color: lighten($textLight, 20%);
		cursor: default;
	}

	// MONTH LABELS

	.CalendarMonth_caption{
		font-size: 15px;
		font-weight: $lato-semibold;
		color: $textBlack;
		padding-top: 12px;
		padding-bottom: 26px;
	}


	// WEEK HEADERS

	.DayPicker_weekHeader{
		top: 36px;
	}
	.DayPicker_weekHeader_li{
		color: $invisible;
		text-align: center;
		padding-left: 1px;
		font-size: 0px;
	}

	.DayPicker_weekHeader_li::first-letter{
		color: $gray-10;
		font-weight: $lato-semibold;
		font-size: 12px;
	}


	// NAV BUTTONS
	button.DayPickerNavigation_button,
	button.DayPickerNavigation_button:hover,
	button.DayPickerNavigation_button:active{
		width: 50px;
		height: 39px;
		border: none;
		background: none;
	}

	.DayPickerNavigation_rightButton__horizontal{
		right: 0px;
	}

	.DayPickerNavigation_rightButton__horizontal
	.scoreSheet-calendarNavCustom{
		padding-right: 20px;
	}

	.DayPickerNavigation_leftButton__horizontal
	.scoreSheet-calendarNavCustom{
		padding-left: 20px;
	}

	.DayPickerNavigation_leftButton__horizontal{
		left: 0px;
	}

	.DayPickerNavigation_button__horizontal{
		padding: 0px;
		top: 0px;
		border-radius: 0px;
	}

	.scoreSheet-calendarNavCustom{
		width: 100%;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.scoreSheet-calendarNavCustom .icon {
		fill: $blue;
		width: 12px;
		height: 12px;
	}

	button.DayPickerNavigation_button:hover
	.scoreSheet-calendarNavCustom .icon {
		fill: $textBlue;
	}

	button.DayPickerNavigation_button:active
	.scoreSheet-calendarNavCustom .icon {
		fill: $textBlue;
	}


}
