//
// QUESTION SLIDE CAROUSEL
// Slide preview carousel click through - in e.g. preview panel in right-side of library
// Navigation is a prop, with navigation you can click on edges to go back/fwd, and shows a progress bar
// There is a play button in the middle
//

.slideCarousel{
	position: relative;	
}

.slideCarousel-slide{
	position: absolute;
	top: 0px;
	left: 0px;
	width: 100%;
	border: 1px solid rgba(0,0,30,0.1);
	border-bottom: 1px solid rgba(0,0,30,0.15);

	border-radius: 3px;
	box-sizing: border-box;
	overflow: hidden;

	background: $white;
}

.slideCarousel-slide .slide{
	border: 1px solid $invisible;
}

.slideCarousel-slideControls{
	display: flex;
	position: absolute;
	top: 0px;
	left: 0px;
	width: 100%;
	z-index: 10;
}

.slideCarousel-slide .questionCountBadge{
	position: absolute;
	top: 8px;
	right: 8px;
	margin: 0px;
	transform: scale(1.2);
	transform-origin: top right;
	z-index: 50;
	user-select: none;
}

.slideCarousel .questionCountBadge--gray{
	opacity: 0;
	transition: 100ms opacity ease-in-out;
	z-index: 60;
}

.slideCarousel:hover .questionCountBadge--gray{
	opacity: 1;
	transition: 100ms opacity ease-in-out;
}

.slideCarousel-playBtnContainer{
	flex-grow: 1;
	width: 10px;
	height: 100%;

	display: flex;
	align-items: center;
	justify-content: center;
}

.slideCarousel.slideCarousel--hasSetNavigator
.slideCarousel-playBtnContainer{
	width: 70%;
	margin: auto;
}

.slideCarousel-playBtn{
	width: 48px;
	height: 48px;
	border-radius: 50%;
	//background: $colorBG-fullScreen-controls;
	background: rgba(0,0,20,0.7);

	display: flex;
	align-items: center;
	justify-content: center;

	box-sizing: border-box;
	cursor: pointer;
}

.slideCarousel-playBtn .icon.play{
	width: 24px;
	height: 24px;
	fill: $white;
	position: relative;
	right: -2px;
}

// these buttons only show when showSetNavigator is true

.slideCarousel-navBtn{
	//background: $tempb;

	height: 100%;
	flex-grow: 0;
	flex-shrink: 1;
	width: 15%;
	
	display: flex;
	align-items: center;
	justify-content: center;

	opacity: 0;
	cursor: pointer;
}

.slideCarousel-navBtn .icon{
	fill: $textDark;
}

.slideCarousel-navBtn--previous{
	left: 0px;
}

.slideCarousel-navBtn--next{
	right: 0px;
}

//
// SET NAVIGATOR

.slideCarousel-setNavigator{
	position: absolute;
	bottom: 0px;
	left: 15%;
	width: 70%;
	height: 20px;
}

$height-carouselSlider: 20px;
$height-carouselSliderRail: 3px;

.slideCarousel-setNavigator{
  opacity: 0.75;
  transition: opacity 150ms linear;
}

.slideCarousel:hover .slideCarousel-setNavigator{
  opacity: 1;
  transition: opacity 50ms linear;
}

.slideCarousel-setNavigator{
  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 6px;
  overflow: hidden;
}

.slideCarousel-setNavigator-itemContainer{  
  flex-grow: 1;
  flex-shrink: 1;

  height: $height-carouselSlider;
  
  position: relative;
  cursor: pointer;
}

.slideCarousel-setNavigator-item{
  position: absolute;
  left: 0px;
  right: 0px;
  top: calc((#{$height-carouselSlider} - #{$height-carouselSliderRail}) / 2);
  height: $height-carouselSliderRail;
}

// STATES

.slideCarousel-setNavigator-itemContainer--inactive
.slideCarousel-setNavigator-item{
  background-color: $gray-125;
}

.slideCarousel-setNavigator-itemContainer--inactive:hover
.slideCarousel-setNavigator-item{
  background-color: $gray-12;
}

.slideCarousel-setNavigator-itemContainer--active
.slideCarousel-setNavigator-item{
  background-color: $gray-8;  
}

// BORDER RADIUS

.slideCarousel-setNavigator-itemContainer:first-child
.slideCarousel-setNavigator-item{
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
}

.slideCarousel-setNavigator-itemContainer:last-child
.slideCarousel-setNavigator-item{
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
}

// NAV INTERACTIONS

.slideCarousel-slideControls .slideCarousel-navBtn,
.slideCarousel-playBtnContainer .slideCarousel-playBtn{
	opacity: 0;
	transition: 100ms all ease-in-out;
}


//
// HOVER STATES

.slideCarousel-playBtnContainer:hover .slideCarousel-playBtn {
	opacity: 0.4;
	transform: scale(1);
	transition: 100ms all ease-in-out;
}

.slideCarousel-playBtnContainer .slideCarousel-playBtn:hover{
	opacity: 1;
	transform: scale(1.2);
	transition: 100ms all ease-in-out;
}

//

.slideCarousel-slideControls:hover .slideCarousel-navBtn {
	opacity: 0.1;
}

.slideCarousel-slideControls .slideCarousel-navBtn:hover{
	opacity: 0.5;
}

.slideCarousel-slideControls .slideCarousel-navBtn:active{
	opacity: 0.8;
}


// FIX FOR OVERFLOW ON SECTION UPCOMING
// without this, arrows were overflowing
// should refactor, sorry about this

.sectionHome-upcoming-upcomingCard
.slideCarousel-slideControls
.slideCarousel-navBtn--previous{
	padding-right: 20%; //instead of 30%
}

.sectionHome-upcoming-upcomingCard
.slideCarousel-slideControls
.slideCarousel-navBtn--next{
	padding-left: 20%; //instead of 30%
}