.sectionHome-completeSetupPrompt{
  cursor: default;
}
.sectionHome-completeSetupPrompt-header{
  font-size: 34px;
  color: $textBlack;
  font-weight: $lato-heavy;
  margin-top: 150px;
}

span.sectionHome-completeSetupPrompt-header-sectionName{
  display: inline-block; // forces line-break preferred after section name
  word-break: break-word;
}

span.sectionHome-completeSetupPrompt-header-sectionName--color-blue			{color: $classColor-blue; }
span.sectionHome-completeSetupPrompt-header-sectionName--color-purple		{color: $classColor-purple; }
span.sectionHome-completeSetupPrompt-header-sectionName--color-red			{color: $classColor-red; }
span.sectionHome-completeSetupPrompt-header-sectionName--color-orange		{color: $classColor-orange; }
span.sectionHome-completeSetupPrompt-header-sectionName--color-green		{color: $classColor-green; }
span.sectionHome-completeSetupPrompt-header-sectionName--color-sky			{color: $classColor-sky; }
span.sectionHome-completeSetupPrompt-header-sectionName--color-pink			{color: $classColor-pink; }
span.sectionHome-completeSetupPrompt-header-sectionName--color-teal			{color: $classColor-teal; }
span.sectionHome-completeSetupPrompt-header-sectionName--color-grey			{color: $classColor-grey; }

.sectionHome-completeSetupPrompt-subtitle {
  margin-top: 15px;
  font-size: 18px;
  font-weight: $lato-semibold;
  color: $textDark;
}

.sectionHome-completeSetupPrompt button {
  margin-top: 50px;
  min-width: 184px;
  width: 184px;
  cursor: pointer;
}

.sectionHome-completeSetupPrompt--dataOwner button {
  width: 250px;

}

.sectionHome-completeSetupPrompt-skip{
  position: absolute;
  cursor: pointer;
  bottom: 60px;
  left: 0px;
  font-size: 14px;
  font-weight: $lato-semibold;
  color: $textMid;
  left: $leftGutter-centerPanel;
}

.sectionHome-completeSetupPrompt-skip:hover{
  color: $textBlack;
}
