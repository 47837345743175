// INSTANT IMAGES

.upgrade--landing-featureSection--instantImages-dottedLineContainer{
	width: 580px;
	height: 100px;
	position: absolute;
	top: 195px;
	left: 50%;
	transform: translateX(-50%);

	z-index: 15;
}

.upgrade--landing-featureSection-instantImages-demoContainer{
	position: absolute;
	width: 1000px;
	height: 384px;
	bottom: 0px;
	left: 50%;
	transform: translateX(-50%);
	
	z-index: 10;
	overflow: hidden;
}

.upgrade--landing-featureSection-instantImages-demoBG{
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;

	background: $white;
	border: 1px solid $lineDark;
	border-bottom: 0px;

	border-top-right-radius: 11px;
	border-top-left-radius: 11px;
}

.upgrade--landing-featureSection-instantImages-demoBG-screen{
	position: absolute;
	top: 20px;
	bottom: 0px;
	width: 960px;
	left: 50%;
	transform: translateX(-50%);
	background: $gray-2;
	border: 1px solid $lineDark;
	border-bottom: 0px;
	border-top-right-radius: 2px;
	border-top-left-radius: 2px;
}

.upgrade--landing-featureSection-instantImages-demoBG-screen svg{
	width: 960px;
	height: 540px;
	opacity: 0.5;
	position: absolute;
	top: 0px;
	left: 0px;
	right: 0px;
}

.upgrade--landing-featureSection-instantImages-demo{
	width: 725px;
	height: 350px;

	position: absolute;
	left: 50%;
	transform: translateX(-50%);
	bottom: 0px;

	z-index: 10;
	background: $white;
	border-top-right-radius: 5px;
	border-top-left-radius: 5px;

	box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.12);

	overflow: hidden;
	
}

.upgrade--landing-featureSection-instantImages-demo-search{
	height: 50px;
	width: 100%;
	display: flex;

	box-sizing: border-box;
	padding-left: 100px;
	padding-right: 100px;

	//background: $tempr;
	background: $white;
	border-bottom: 1px solid $lineMid;
	box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.12);
}

.upgrade--landing-featureSection-instantImages-demo-search
input.upgrade--landing-featureSection-instantImages-demo-search-input{
	height: 36px;
	line-height: 36px;
	flex-grow: 1;

	border-radius: 4px;
	outline: none;
	border: none;
	box-shadow: none;

	border: 1px solid $lineMid;

	background: $white;
	color: $textBlack;
	font-size: 18px;
	font-weight: $effra-medium;

	box-sizing: border-box;
	padding-left: 12px;

	-moz-appearance: none;

	margin: 8px;
}

.upgrade--landing-featureSection-instantImages-demo-search
input.upgrade--landing-featureSection-instantImages-demo-search-input:focus{
	border: 1px solid $blue;
}

.upgrade--landing-featureSection-instantImages-demo-search
input.upgrade--landing-featureSection-instantImages-demo-search-input::-webkit-input-placeholder{
  color: $textInputPlaceholder !important;
}



.upgrade--landing-featureSection-instantImages-demo-search
button.upgrade--landing-featureSection-instantImages-demo-search-btn{
	height: 36px;
	width: 140px;
	flex-grow: 0;
	border-radius: 4px;
	-webkit-appearance: none;
	outline: none;
	box-shadow: none;
	border: none;

	background: $gray-14;
	color: $textMid;
	font-weight: $effra-bold;
	font-size: 16px;

	text-align: center;
	line-height: 32px;

	margin: 8px;

	position: relative;
}



.upgrade--landing-featureSection-instantImages-demo-search-btn-loadingSpinnerContainer{
	position: absolute;
	
	display: flex;
	align-items: center;
	justify-content: center;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
}

.upgrade--landing-featureSection-instantImages-demo-search-btn-loadingSpinner{
	height: 20px;
	width: 20px;	
  
  border-radius: 50%;

  border: 3px rgba(26,28,34,0.55) solid;
  border-top: 3px $white solid;

  opacity: 0.5;

  animation: spinnerRotateAnimation .3s infinite linear;
}

.upgrade--landing-featureSection-instantImages-demo-resultsContainer{
	height: 350px;
	width: 100%;
	background: rgb(252,252,255);
	box-sizing: border-box;
	overflow: hidden;
	padding-left: 40px;
	padding-right: 40px;

	pointer-events: none;
	cursor: default;
}

input.upgrade--landing-featureSection-instantImages-demo-search-input:valid ~
button.upgrade--landing-featureSection-instantImages-demo-search-btn{
	background: $blue;
	color: $white;
}