.distributeStudentCodesGCModalContainer{
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(5,5,10,0.75);

  display: flex;

  align-items: center;
  justify-content: center;
}

.distributeStudentCodesGCModal{
  border-radius: 6px;

  background: rgb(250,250,255);
  border: 1px solid $gray-5;
  box-shadow:
    0px 8px 16px 0px rgba(5,5,10,0.12),
    0px 16px 32px 0px rgba(5,5,10,0.08);

  box-sizing: border-box;

  overflow: hidden;

  width: 620px;
  min-height: 500px;

  padding: 24px 55px;

  position: relative;

  cursor: default;
}

//
// SECTION MARKER

.distributeStudentCodesGCModal-sectionLabel .sectionMarker{
  font-size: 15px;
  font-weight: $lato-semibold;
  color: $textBlackDark;
  opacity: 0.75;
}

.distributeStudentCodesGCModal-sectionLabel .sectionMarker svg{
  width: 9px;
  height: 9px;
  margin-right: 6px;
  position: relative;
  top: -1px;
}

//
// HEADER AND DESCRIPTION


.distributeStudentCodesGCModal-header{
  font-size: 19px;
  font-weight: $lato-heavy;
  color: $textBlackDark;
  letter-spacing: 0.01em;

  margin-top: 14px;
  margin-bottom: 8px;
}

.distributeStudentCodesGCModal-description{
  font-size: 14px;
  font-weight: $lato-medium;
  color: $textBlack;
}

.distributeStudentCodesGCModal-description-p{
  margin-bottom: 8px;
}


.distributeStudentCodesGCModal-description emph{
  font-weight: $lato-bold;
  color: $textBlackDark;
}


//
// CUSTOM MESSAGE
.distributeStudentCodesGCModal-customMessage{
  padding-right: 14px;
  // border-left: 1px solid $blue;
  margin-top: 12px;
  padding-top: 1px;
  padding-bottom: 1px;

  box-sizing: border-box;

  width: 100%;
}

.distributeStudentCodesGCModal-customMessage-title{
  font-size: 15px;
  font-weight: $lato-bold;
  color: $textBlackDark;

  margin-bottom: 6px;
}

textarea.distributeStudentCodesGCModal-customMessage-textArea{
  resize: none;
  width: 100%;
  height: 84px;

  padding: 8px;
  border: 1px solid $lineDark;
  border-radius: 3px;

  box-sizing: border-box;

  font-weight: $lato-medium;
  color: $textBlackDark;

  font-size: 14px;

  white-space: pre-wrap;

  overflow-x: hidden;
  overflow-y: scroll;
}

textarea.distributeStudentCodesGCModal-customMessage-textArea:focus{
  border: 1px solid $blue;
  outline: none;
}

// CHECKBOX FOR CC

.distributeStudentCodesGCModal-CCcheckboxContainer{
  margin-top: 10px;
}

.distributeStudentCodesGCModal-CCcheckboxContainer
.featureModal-selectControl-label{
  letter-spacing: initial;
  font-size: 14px;
  font-weight: $lato-semibold;
  color: $textBlack;
}


//
// BUTTON

.distributeStudentCodesGCModal-buttonContainer{
  min-height: 85px;
  margin-top: 30px;
}

.distributeStudentCodesGCModal-buttonContainer button.btn.distributestudentcodessubmitbtn{
  width: 100%;
  height: 44px;
  border-radius: 5px;
  font-size: 18px;
  font-weight: $lato-heavy;
  color: $white;
  background: $blue-bold;
  color: 1px solid darken($blue-bold, 5%);

  cursor: pointer;

  margin: 0 auto;
  margin-bottom: 5px;
}

.distributeStudentCodesGCModal-buttonContainer button.btn.distributestudentcodessubmitbtn:hover{
  background: darken($blue-bold, 3%);
  color: 1px solid darken($blue-bold, 8%);
}

.distributeStudentCodesGCModal-buttonContainer button.btn.distributestudentcodessubmitbtn:active{
  background: darken($blue-bold, 6%);
  color: 1px solid darken($blue-bold, 11%);
}

.distributeStudentCodesGCModal-buttonContainer-cancelBtn{
  background: none;
  width: 100px;
  font-size: 14px;
  font-weight: $lato-medium;
  color: $textLight;

  cursor: pointer;
  height: 26px;

  margin: 10px auto;

  display: flex;
  align-items: center;
  justify-content: center;

  user-select: none;
}

.distributeStudentCodesGCModal-buttonContainer-errorMessage {
  color: $red;
  text-align: center;
  font-weight: $lato-heavy;
  font-size: 14px;
}



