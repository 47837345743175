
// HISTORY

.historyList{	
	width: 100%;
	//display: flex;	
	//flex-direction: column;
	margin-top: 20px;
	//margin-bottom: 120px;
}

$height-historyToolbar: 65px;



.historyCardGroup-dateHeader{	

	cursor: default;

	font-size: 16px;
	color: $textLight;	
	font-weight: $lato-bold;
	height: 50px;

	padding-top: 30px;
	box-sizing: border-box;
}

.historyCardGroup-dateHeader:first-child{
	height: 24px;
	padding-top: 4px;
}


/* HISTORY CARD */

$height-historyCard: 120px;
$width-historyCard-slidePreview: 190px;

.historyCardContainer{
	height: calc(#{$height-historyCard} + 20px);	
	box-sizing: border-box;
	position: relative;
}

a.historyCard{
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	top: 0;

	height: 100%;
	padding-top: 16px;
	padding-bottom: 15px;
	box-sizing: border-box;
	padding-left: calc(#{$width-historyCard-slidePreview} + 16px);
	padding-right: 15px;
	
}

//


.historyCard-slidePreview {
	position: absolute;
	left: 0;
	top: 10px;

	width: $width-historyCard-slidePreview;	
	height: calc(#{$width-historyCard-slidePreview} / 1.6);

	
}

.historyCard-slidePreview-slide{
	height: 100%;
	width: 100%;

	border: 1px solid $lineDark;
	position: relative;
	z-index: 5;
	
	border-radius: 2px;
	box-shadow: 0px 1px 2px 0px rgba(0,0,0,0.04),
							0px 5px 10px -5px rgba(0,0,0,0.1);

	background: $white;
}

.historyCard-slidePreview-slide:hover {
	box-shadow: 0px 1px 2px 0px rgba(0,0,0,0.04),
							0px 5px 10px -5px rgba(0,0,0,0.15);
}

.historyCard-slidePreview-slide .slide{
	border: 1px solid $invisible;
}


//

a.historyCard .scorePill{
	opacity: 0.75;
	transition: opacity 200ms ease-in-out;
}

.historyCard:hover .scorePill{
	opacity: 1;
	transition: opacity 100ms ease-in-out;
}

.historyCard-slidePreview .scorePill{
	position: absolute;
	top: 6px;
	right: 6px;
	cursor: pointer;
	z-index: 20;
	height: 20px;
	width: 37px;
	font-size: 14px;
}

.historyCard-slidePreview .scorePill.is--survey{
	line-height: 1.3;
}

.historyCard-title{
	font-size: 17px;
	color: $textBlack;	
	font-weight: $lato-bold;
	width: 100%;
	box-sizing: border-box;	
	margin-bottom: 4px;
	line-height: 1.35;
	max-height: 44px;

	display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;

  text-overflow: clip;
  white-space: normal;
  overflow: hidden; 

}

.historyCard-title:hover{
	cursor: pointer;
	color: $textBlackDark;
}

.historyCard-subtitle{
	width: 100%;

	white-space: nowrap; 
  overflow: hidden;
  text-overflow: ellipsis;
}

.historyCard-subtitle-time{
	font-size: 14px;
	font-weight: $lato-semibold;
	color: transparentize($textLight, 0.4);
	cursor: default;
}

a.historyCard:hover .historyCard-subtitle-time{
	color: $textLight;
}

a.historyCard.historyCard--sectionHistory .historyCard-subtitle-time{
	color: transparentize($textMid, 0.4);
}

a.historyCard.historyCard--sectionHistory:hover .historyCard-subtitle-time{
	color: $textMid;
}


a.historyCard .sectionMarker{
	font-size: 14px;
	font-weight: $lato-semibold;
	color: $textDark;	
	display: inline;
	box-sizing: border-box;
	padding-right: 5px;
	padding-bottom: 2px;
}

a.historyCard.historyCard--sectionHistory .sectionMarker{
	display: none;
}

a.historyCard .sectionMarker .icon{
	height: 10px;
	width: 10px;
	margin-right: 5px;
}

a.historyCard .sectionMarker span{
	
}


a.historyCard-content:hover .sectionMarker{	
	color: $textBlack;	
}


//

.historyCard-rightArrow{
	position: absolute;
	height: 14px;
	width: 14px;
	top: 20px;
	right: 0px;
	//opacity: 1;
	display: flex;
	//background: $tempr;
}

.historyCard-rightArrow .icon{
	height: 13px;
	width: 13px;
	fill: $lineDark;
}


a.historyCard:hover
.historyCard-rightArrow .icon{
	fill: $blue;
}

.historyList-loadMoreBtnContainer{
	width: 200px;
	height: 80px;
	//background: $tempr;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-bottom: -40px;
}

.historyList-loadMoreBtnContainer button.btn{
	min-width: 250px;
	width: 250px;
	cursor: pointer
}



// .history-content-backTop{
// 	position: absolute;
// 	right: 0px;
// 	top: $height-historyToolbar;

// 	margin-right: 16px;
// 	margin-top: 16px;
// 	min-width: 122px;
// 	width: 122px;
// }


// // .history-content-backTop-icon{
// // 	display: inline-block;
// // 	margin-left: -6px;
// // }

// // .history-content-backTop-icon .icon{
// // 	height: 13px;
// // 	fill: $blue;
// // }

// // .history-content-backTop:focus{
// // 	outline: none;
// // }

// // .history-content-backTop:active{
// // 	background: $white;
// // 	border: 1px solid $gray-7;
// // }




















// // 
// // 
// // 
// // 
// // 

// // DATEPICKER
// // overriding AirBnB React-Dates

// .SingleDatePickerInput{
// 	background: none;
// 	// border: 1px solid $gray-11;
// 	border: none;
// 	width: 122px; max-width: 122px; min-width: 122px;
// 	// border-radius: 4px;
// 	height: 32px;
// 	// height: 30px;
	
// }
// .DateInput{
// 	padding: 0px;
// 	height: 32px;
// 	box-sizing: border-box;
	
// 	width: 122px; max-width: 122px; min-width: 122px;
// 	background: none;
// 	// background: $red;
// 	border-radius: 3px;
// 	cursor: pointer;
// 	// border: 1px solid rgba(0,0,0,0);
// 	border: 1px solid $gray-11;

// }

// .DateInput--with-caret{
// 	background: none;
// 	width: 122px; max-width: 122px; min-width: 122px;
// 	border: 1px solid $gray-9;
// 	box-sizing: border-box;
// }

// .DateInput__input{
// 	border-radius: 4px;
// 	cursor: pointer;
// }

// // HIDE THEIR DATE INPUT STUFF
// .DateInput__display-text,
// .DateInput__display-text--focused{
// 	display: none;
// }

// .CalendarDay--selected{
// 	background: $blue;
// 	border: 1px double $blue;
// }

// .SingleDatePicker__picker{
// 	top: 42px;
// }

// .SingleDatePicker__picker{
// 	left: -200px !important;
// }

// .DayPicker.DayPicker--horizontal{
// 	border: 1px solid $gray-11;
// 	box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.12);
// 	padding: 1px;
// }

// .DateInput--with-caret::before,
// .DateInput--with-caret::after, {
// 	display: none;
// }

// .toolbar-right-content-datepicker-templabel{
// 	display: inline-block;
// 	vertical-align: top;
// 	margin-left: -108px;
// 	margin-top: 4px;
// 	font-size: 15.75px;
// 	font-weight: $lato-semibold;
// 	color: $gray-4;
// }



// .section .history .history-content-backTop{
// 	top: 0px;
// }




