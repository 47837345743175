//
// ACCOUNT
//

/// Header

//

.account-content{
	width: 100%;
	padding-bottom: 120px;
}

//
// SUBSECTIONS
//

.account-subsection{
	height: auto;
	border-bottom: 1px solid $lineMid;
}

.account-subsectionContainer--twoColumn{
	display: flex;
}

.account-subsectionContainer--twoColumn .account-subsection{
	width: 50%;
}

.account-subsection-content{
	padding-bottom: 16px;
	// general non-button styling
	font-size: 16px;
	font-weight: $lato-bold;
	color: $textBlack;
	cursor: default;
}

.account-subsection-header{
	margin-top: 20px;
	margin-bottom: 12px;

	font-size: 12px;
	font-weight: $lato-semibold;
	color: $textLight;

	user-select: none;
	cursor: default;

	text-transform: uppercase;
}

.account-subsection-actionRow{
	margin-top: 4px;
	display: flex;
}

span.account-subsection-light{
	font-weight: $lato-medium;
	color: $textMid;
}

.account-subsection-actionRow--ed360{
	color: darken($textBlue, 5%);
	cursor: pointer;
}

.account-subsection-actionRow--ed360:hover{
	color: darken($textBlue, 8%);
}

// CREATOR AUTHOR PROFILE

a.account-subsection-creatorURL{
	display: block;
	color: $textBlue;
	font-weight: $lato-semibold;
	font-size: 15px;
	margin-top: 4px;
	white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  box-sizing: border-box;
  padding-right: 10px;
}

.account-subsection-creatorStat{
	margin-top: 1px;
	margin-bottom: 5px;
	font-size: 15px;
}


// DATA OWNER
.account-subsection--dataOwner-row {
  margin-left: 5px;
 }

.account-subsection--dataOwner-row:not(:last-child) {
 margin-bottom: 5px;
}

.account-subsection--dataOwner-row-dataOwnerName {
  cursor: pointer;
}

//

@import 'Account-AccountButton'; // small blue button

@import 'Account-UpgradeSubSection'; // where marketing stuff goes

@import 'Account-ServiceStatus'; // where service status like free/pro/cancelled etc. go

@import 'Account-AccountFormsGeneral'; // provides overall layout for some modals
@import 'Account-AuthenticationModals'; // the hairy ones with google and stuff
@import 'Account-BillingInfoModal'; // shows invoices
@import 'Account-DeleteAccountModal'; // proceed with auth for account deletion
@import 'Account-AddEmailPasswordForm'; // should really refactor this into the others but i'm losing the will to live
@import 'Account-UpdatePaymentInfo'; // update payment info
@import 'Account-SwitchPlanModal'; // switch between monthly and yearly
@import 'Account-AddLicenseKeyModal'; // add group order license key
@import 'Account-UpdateUsernameModal'; // update username if plickers creator
@import 'Account-PayPalModal'; // Edit Paypal modal

//

.account-subsection--quickLinks .account-subsection--quickLinks-colContainer{
	display: flex;
}

.account-subsection--quickLinks .account-subsection--quickLinks-col{
	width: 33%;
}

//
.account-footer-colContainer{
	display: flex;
}

.account-footer-col{
	width: 33%;
}

//

.upgrade--payment .StripeElement--focus{
	outline: none;
	border: 1px solid $blue;
	background: $white;
}

///  refactor this somewhere

.downgrade .modal-content{
	width: 700px;
	height: auto;
}

.downgradeCancelModal{
	padding-left: 24px;
	padding-right: 24px;
	padding-top: 24px;
	padding-bottom: 48px;
	box-sizing: border-box;

	text-align: center;
}

.downgradeCancelModal-btnContainer{
	display: flex;
	margin-top: 24px;
	width: 100%;
	align-items: center;
	justify-content: center;
}

.downgradeCancelModal-btnContainer button{
	margin: 8px;
}


.downgradeCancelModal-error{
	width: 80%;
	margin: 0 auto;
	text-align: center;
	margin-top: 15px;
	margin-bottom: 0px;
	color: $red-dark;
	font-size: 15px;
	font-weight: $lato-semibold;
}
