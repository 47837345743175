

.richTextPrereleasePopup{
	position: fixed;
	width: 310px;	
	background: $white;	

	bottom: 10px;
	right: 10px;

	z-index: 1000;

	border: 1px solid $lineDark;
	box-shadow: 0px 4px 8px 0px rgba(0,0,20,0.12), 0px 0px 3px 0px rgba(0,0,20,0.04);

	border-radius: 6px;	
	
	user-select: none;

	box-sizing: border-box;
	padding: 16px;
	padding-top: 10px;
	padding-bottom: 4px;
}

.richTextPrereleasePopup-top{
	display: flex;
	width: 100%;
	justify-content: space-between;
	align-items: center;

	margin-bottom: 8px;
}

.richTextPrereleasePopup-top-hand{
	height: 30px;
	width: 30px;
}

.richTextPrereleasePopup-top-hand img {
	width: 100%;
	height: 100%;
}

button.richTextPrereleasePopup-top-dismiss{

	display: flex;
	align-items: center;
	justify-content: center;

	margin-right: -16px;
	margin-top: -10px;

	padding-top: 8px;
	padding-right: 8px;
	padding-left: 4px;
	padding-bottom: 4px;

	background: none;
	border: none;
	box-shadow: none;

	height: 40px;
	width: 40px;

	cursor: pointer;

	// background: $tempr;
}

.richTextPrereleasePopup-top-dismiss-inner{
	
	width: 100%;
	height: 100%;

	border-radius: 3px;

	background: $tempr;
	background: $gray-13;

	display: flex;
	align-items: center;
	justify-content: center;

	opacity: 0.6;
}

.richTextPrereleasePopup-top-dismiss-inner svg{
	height: 14px;
	width: 14px;

	fill: $gray-4;
}

button.richTextPrereleasePopup-top-dismiss:hover
.richTextPrereleasePopup-top-dismiss-inner{
	opacity: 1;
}

//

.richTextPrereleasePopup-h1{
	font-weight: $lato-heavy;
	color: $textBlack;	
	font-size: 17px;

	letter-spacing: 0.01em;

	margin-bottom: 6px;

	box-sizing: border-box;
	padding-right: 14px;
}


.richTextPrereleasePopup-h2{
	font-weight: $lato-semibold;
	color: lighten($textMid, 10%);
	font-size: 14px;

	letter-spacing: 0.005em;

	margin-bottom: 2px;

	
}

button.richTextPrereleasePopup-link{
	color: $textBlue;
	font-weight: $lato-heavy;
	font-size: 16px;
	text-decoration: underline !important;

	background: none;
	box-shadow: none;
	border: none;

	letter-spacing: 0.01em;

	// background: $tempr;

	padding-left: 8px;
	margin-left: -8px;

	padding-right: 36px;

	padding-top: 16px;
	padding-bottom: 20px;

	border-radius: 3px;

	cursor: pointer;

	// background: $tempr;
}

button.richTextPrereleasePopup-link:hover{
	color: darken($textBlue, 8%);
}

button.richTextPrereleasePopup-link:active{
	color: darken($textBlue, 10%);
}













// .richTextPrereleasePopup-header{
// 	height: 28px;
// 	width: 100%;
// 	display: flex;
// 	pointer-events: none;

// 	position: absolute;
// 	top: 10px;
// 	left: 0px;

// 	z-index: 500;

// 	box-sizing: border-box;
// 	padding-left: 12px;
// 	padding-right: 12px;
// }

// .richTextPrereleasePopup-questionPreview{
// 	width: 100%;
// 	height: 100%;	
// 	cursor: pointer;
// }


// .richTextPrereleasePopup-header-expandLabel{

// 	height: 28px;
// 	padding-left: 8px;
// 	padding-right: 8px;

// 	margin-right: auto;

// 	color: $white;
// 	font-weight: $lato-bold;
// 	font-size: 15px;
// 	// text-transform: uppercase;	
// 	background: rgba(85,85,105,0.95);
// 	position: relative;
// 	line-height: 1.7;
// 	border-radius: 3px;
// 	text-align: center;

// 	user-select: none;

// 	position: relative;
// }

// button.richTextPrereleasePopup-header-closeButton{
// 	width: 32px;
// 	pointer-events: auto;
// 	height: 28px;
	
// 	border-radius: 3px;
// 	box-shadow: none;
// 	cursor: pointer;
// 	background: $colorBG-fullScreen-controls;
// 	border-color: $colorBG-fullScreen-controls;
// 	display: flex;
// 	align-items: center;
// 	justify-content: center;
// }

// button.richTextPrereleasePopup-header-closeButton .icon{
// 	width: 16px;
// 	height: 16px;
// 	fill: $white;
// }

// .richTextPrereleasePopup{
// 	transition: 200ms all ease-in-out;
// }

// .richTextPrereleasePopup:hover{
// 	transform: scale(1.03);	
// 	transition: 200ms all ease-in-out;
// }

// // // INTERACTIONS: OPACITY MANAGEMENT

// // .richTextPrereleasePopup .richTextPrereleasePopup-header-expandLabel{
// // 	opacity: 0;
// // 	transition: 200ms all linear;
// // }

// // .richTextPrereleasePopup:hover .richTextPrereleasePopup-header-expandLabel{
// // 	opacity: 1;
// // 	transition: 100ms all linear;
// // }

// // .richTextPrereleasePopup button.richTextPrereleasePopup-header-closeButton{
// // 	opacity: 0.4;
// // 	transition: 100ms all linear;
// // }

// // .richTextPrereleasePopup:hover button.richTextPrereleasePopup-header-closeButton{
// // 	opacity: 0.65;
// // 	transition: 100ms all linear;
// // }

// // .richTextPrereleasePopup button.richTextPrereleasePopup-header-closeButton:hover{
// // 	opacity: 0.9;
// // 	transform: scale(1.05);
// // 	transition: 100ms all linear;
// // }

// // .richTextPrereleasePopup button.richTextPrereleasePopup-header-closeButton:active{
// // 	opacity: 1;
// // 	transform: scale(1.05);
// // 	transition: 100ms all linear;
// // }

// // OLD APP ERROR

// .richTextPrereleasePopup-questionPreview-oldAppError{	
// 	position: absolute;
// 	top: 0px;
// 	left: 0px;
// 	right: 0px;
// 	bottom: 0px;

// 	background: transparentize($red, 0.9);

// 	padding: 20px;
// 	padding-top: 50px;

// 	color: $red-dark;
// 	font-size: 18px;
// 	text-align: center;

// 	box-sizing: border-box;
// }
