.importQuestionsOnboardingModalContainer{
	position: fixed;
	z-index: 9999;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: rgba(5,5,10,0.75);
	
	display: flex;
	
	align-items: center;
	justify-content: center;
}

.importQuestionsOnboardingModal{
		border-radius: 10px;
	
	background: rgb(252,252,255);
	border: 1px solid $gray-5;
	box-shadow: 
		0px 8px 16px 0px rgba(5,5,10,0.12),
		0px 16px 32px 0px rgba(5,5,10,0.08) ;

	box-sizing: border-box;

	overflow: hidden;

	width: 950px;
	height: 600px;

	width: calc(100vw - 100px);	
	max-width: 1400px;

	// height: calc(100vh - 50px);
	// max-width: 1120px;
	// max-height: 670px;
	// min-height: 620px;
	// min-width: 1056px;
	
	position: relative;

	display: flex;
}

///

.importQuestionsOnboardingModal-left{
	flex-grow: 1;
	flex-shrink: 1;
	max-width: 910px;

	height: 600px;
	overflow: hidden;

	min-width: 40px;
	padding-left: 40px;
	padding-right: 25px;

	display: flex;
	align-items: center;
	justify-content: center;
}

.importQuestionsOnboardingModal-videoContainer{
  float: none;
  clear: both;
  width: 100%;
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 25px;
  height: 0;
}

iframe.importQuestionsOnboardingModal-video{
	
	position: absolute;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;
}


///

.importQuestionsOnboardingModal-right{
	
	flex-grow: 0;
	flex-shrink: 0;

	width: 400px;

	height: 600px;



	box-sizing: border-box;
	padding-right: 50px;
	padding-top: 48px;

	user-select: none;

	position: relative;
}


.importQuestionsOnboardingModal-step{
	padding-top: 10px;
	padding-bottom: 10px;
}


.importQuestionsOnboardingModal-step-number{
	color: $textBlue;
	font-size: 12px;
	font-weight: $lato-semibold;
	text-transform: uppercase;
	letter-spacing: 0.005em;
	margin-bottom: 4px;
}	

.importQuestionsOnboardingModal-step-name{
	font-size: 17px;
	font-weight: $lato-heavy;
	color: $textBlackDark;
	margin-bottom: 5px;
}

.importQuestionsOnboardingModal-step-label{
	font-size: 14px;
	font-weight: $lato-medium;
	color: $textBlack;
}

.importQuestionsOnboardingModal-buttonRow{
	position: absolute;
	bottom: 34px;
	left: 0px;
	right: 36px;
}

.importQuestionsOnboardingModal-buttonRow .featureModal-selectControl{
	cursor: pointer;
}

.importQuestionsOnboardingModal-buttonRow 
.featureModal-selectControl-label{
	letter-spacing: 0em;
	font-weight: $lato-semibold;
	font-size: 14px;
	cursor: pointer;
}

.importQuestionsOnboardingModal-buttonRow button{
	width: 100%;
	height: 54px;
	font-weight: $lato-heavy;
	letter-spacing: 0.005em;
	margin-top: 6px;
	font-size: 20px;

	cursor: pointer;
}