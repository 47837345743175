.pageNotFoundLabel{
	font-size: 28px;
	font-weight: $lato-heavy;
	color: transparentize($textLight, 0.7);
	margin-top: 140px;
	padding-right: $rightGutter-centerPanel;
	width: 100%;
	text-align: center;

	box-sizing: border-box;

	user-select: none;
	cursor: default;
}