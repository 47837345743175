//
// SIGN IN SIGN UP DIVIDER
// this is the bit that says or use email or password
// also used in reauthentication modal
//

.signin-centerBlock-divider,
.signup-centerBlock-divider,
.reauthenticationModal-signinMethodContainer-divider {
  width: 100%;
  height: 18px;
  margin-top: 15px;
  margin-bottom: 15px;

  display: flex;
  // background: $tempr;

  cursor: default;
  user-select: none;
}

.signin-centerBlock-divider-line,
.signup-centerBlock-divider-line,
.reauthenticationModal-signinMethodContainer-divider-line {
  height: 50%;
  border-bottom: 1px solid $lineMid;
  width: 1px;
  flex-grow: 1;
  flex-shrink: 1;
  // background: $tempb;
}

.signin-centerBlock-divider-label,
.signup-centerBlock-divider-label,
.reauthenticationModal-signinMethodContainer-divider-label {
  width: auto;
  font-size: 14px;
  font-weight: $effra-medium;
  color: $textMid;
  letter-spacing: 0.005em;
  padding-left: 12px;
  padding-right: 12px;
  line-height: 1.3;
}


// //
// // WE HAD A COOL BETA BADGE FOR PLICKERS-BETA.COM DEPLOYMENTS
// // We can probably refactor this out now

// .signin-centerBlock-logo--beta{
// 	width: calc(100% + 40px);
// 	margin-left: -16px;
// }

// .signin-centerBlock-betaBadgeContainer{
// 	display: flex;
// 	align-items: center;
// 	justify-content: center;

// 	margin-left: 10px;
// 	margin-right: -24px;
// 	margin-top: -6px;
// }

// .signin-centerBlock-betaBadge{
// 	padding-left: 12px;
// 	padding-right: 12px;
// 	padding-top: 4px;
// 	padding-bottom: 4px;

// 	user-select: none;
// 	cursor: default;

// 	border-radius: 4px;

// 	font-size: 22px;
// 	letter-spacing: 0.001em;

// 	font-weight: $effra-bold;

// 	background: $blue;

// 	color: $white;

// 	//background: linear-gradient(124deg, #ff2400, #e81d1d, #1de840, #1ddde8, #2b1de8, #dd00f3, #dd00f3);
// 	background: linear-gradient(124deg, $classColor-blue, $classColor-purple, $classColor-red, $classColor-pink, $classColor-orange, $classColor-green, $classColor-sky, $classColor-teal);
// 	background-size: 1800% 1800%;

// 	-webkit-animation: rainbow 42s ease infinite;
// 	-z-animation: rainbow 42s ease infinite;
// 	-o-animation: rainbow 42s ease infinite;
// 	animation: rainbow 42s ease infinite;

// 	opacity: 0.75;

// }

// .signin-centerBlock-betaBadge:hover{
// 	animation-duration: 4s;
// }

// @-webkit-keyframes rainbow {
//     0%{background-position:0% 82%}
//     50%{background-position:100% 19%}
//     100%{background-position:0% 82%}
// }
// @-moz-keyframes rainbow {
//     0%{background-position:0% 82%}
//     50%{background-position:100% 19%}
//     100%{background-position:0% 82%}
// }
// @-o-keyframes rainbow {
//     0%{background-position:0% 82%}
//     50%{background-position:100% 19%}
//     100%{background-position:0% 82%}
// }
// @keyframes rainbow {
//     0%{background-position:0% 82%}
//     50%{background-position:100% 19%}
//     100%{background-position:0% 82%}
// }
