.sectionHome-upcoming{
	padding-bottom: 0px;	
}

//
// UPCOMING CARD
//

$marginBottom-sectionHome-upcomingCard: 20px;

.sectionHome-upcoming-upcomingCard{
	width: 100%;
	margin-left: calc(-1 * #{$leftGutter-centerPanel});
	padding-left: $leftGutter-centerPanel;
	padding-right: $rightGutter-centerPanel;
	margin-bottom: $marginBottom-sectionHome-upcomingCard;
	height: 127px;
	display: flex;
	box-sizing: border-box;
	position: relative;
	cursor: default  !important;
	user-select: none;

	transform-origin: 24% 50%;
}

.sectionHome-upcoming-itemList--isPressed
.sectionHome-upcoming-upcomingCard{
	opacity: 0.65;
	transition: opacity 100ms linear;
}

.sectionHome-upcoming-itemList--isPressed
.sectionHome-upcoming-upcomingCard--isPressed{
	opacity: 1;
	transition: opacity 100ms linear;
}

//
// SLIDE CAROUSEL
//

.sectionHome-upcoming-upcomingCard .slideCarousel{
	margin-right: 16px; // distance to right-side information
	flex-shrink: 0;
}

// same as previewpamel state hover
.sectionHome-upcoming-upcomingCard:hover .slideCarousel-slide{
	box-shadow: 0px 1px 2px 0px rgba(0,0,0,0.04),
							0px 5px 10px -5px rgba(0,0,0,0.1);
}

.sectionHome-upcoming-upcomingCard
.slideCarousel-setNavigator{
	opacity: 0;
	margin-bottom: 5px;
}

.sectionHome-upcoming-upcomingCard:hover
.slideCarousel-setNavigator{
	opacity: 0.25;
}

.sectionHome-upcoming-upcomingCard:hover
.slideCarousel:hover .slideCarousel-setNavigator{
	opacity: 1;
}

.sectionHome-upcoming-upcomingCard .slideCarousel .questionCountBadge{
	transform: scale(1.1);
	transform-origin: top right;
	top: 8px;
	right: 6px;
}

// don't show hover controls when drag-drop
.sectionHome-upcoming-upcomingCard--isPressed .slideCarousel{
	pointer-events: none;
}


//
// RIGHT SIDE INFORMATION
//

.sectionHome-upcoming-upcomingCard-rightContentContainer{
	flex-grow: 1;
	height: 100%;
	display: flex;
	flex-direction: column;
	position: relative;

	flex-basis: 100%;
}

.sectionHome-upcoming-upcomingCard-title{
	font-size: 18px;
	font-weight: $lato-heavy;
	color: $textBlack;
	margin-top: 8px;
	line-height: 1.25;
	word-break: break-word;
	width: calc(100% - 170px);

	max-height: 48px;

	display: -webkit-box;
	-webkit-line-clamp: 2;
    -webkit-box-orient: vertical;

	text-overflow: clip;
	white-space: normal;
	overflow: hidden; 

	word-wrap: wrap;
}

.sectionHome-upcoming-upcomingCard-title .icon.shared{
	fill: $gray-115;
	height: 15px;
	width: 15px;
	margin-left: 2px;
}

.sectionHome-upcoming-upcomingCard-subline{
	font-size: 15px;
	font-weight: $lato-medium;
	color: $textLight;
	margin-top: 4px;
	margin-bottom: 10px;

	width: calc(100% - 170px);

  display: flex;
  align-items: center;
}

.sectionHome-upcoming-upcomingCard-subline-timestamp{
	flex-grow: 0;
	flex-shrink: 0;
}


a.sectionHome-upcoming-upcomingCard-subline-containingRepo{
	flex-grow: 0;
	flex-shrink: 1; 
	
	color: $textMid;
	margin-right: 6px;	

	white-space: nowrap; 
  overflow: hidden;
  text-overflow: ellipsis;
}

a.sectionHome-upcoming-upcomingCard-subline-containingRepo .icon.globe-shared{
	fill: $gray-115;
	height: 15px;
	width: 15px;
	position: relative;
	top: 2px;
	margin-right: 2px;
}

.sectionHome-upcoming-upcomingCard:hover a.sectionHome-upcoming-upcomingCard-subline-containingRepo:hover{
	color: $textBlue;
}

.sectionHome-upcoming-upcomingCard:hover a.sectionHome-upcoming-upcomingCard-subline-containingRepo:hover .icon.globe-shared{
	fill: $textBlue;
}

//
/// ACTION ROW (Play and Edit)
//

.sectionHome-upcoming-upcomingCard-actionRow{
	display: flex;	
	margin-left: -9px; // buttons hang into margin
	opacity: 1;
	transition: 100ms opacity ease-in-out;
}

.sectionHome-upcoming-itemList.sectionHome-upcoming-itemList--isPressed
.sectionHome-upcoming-upcomingCard-actionRow{
	opacity: 0;
	transition: 100ms opacity ease-in-out;
}


// Remove BTN

.sectionHome-upcoming-upcomingCard-removeBtn{
	position: absolute;
	top: 8px;
	right: 45px;

	width: 24px;
	height: 24px;
	display: flex;
	align-items: center;
	justify-content: center;
	
	border: 1px solid $lineDark;
	border-bottom: 1px solid $lineDark;
	background: $white;
	border-radius: 3px;
	
	cursor: pointer;
}

.sectionHome-upcoming-upcomingCard-removeBtn .icon{
	width: 12px;
	height: 12px;
	fill: $textLight;
}

.sectionHome-upcoming-upcomingCard
.sectionHome-upcoming-upcomingCard-removeBtn{
	opacity: 0;
}

.sectionHome-upcoming-upcomingCard:hover
.sectionHome-upcoming-upcomingCard-removeBtn{
	opacity: 0.5;
}

.sectionHome-upcoming-upcomingCard
.sectionHome-upcoming-upcomingCard-removeBtn:hover{
	opacity: 1;
}

.sectionHome-upcoming-itemList.sectionHome-upcoming-itemList--isPressed
.sectionHome-upcoming-upcomingCard
.sectionHome-upcoming-upcomingCard-removeBtn{
	opacity: 0;
}

.sectionHome-upcoming-itemList
.sectionHome-upcoming-upcomingCard:hover
.sectionHome-upcoming-upcomingCard-dragHandleContainer:hover ~
.sectionHome-upcoming-upcomingCard-removeBtn{
	opacity: 0;
}


// drag handle on left side of card 
@import 'SectionHome-Upcoming-DragHandle';

// blocks UpNext when user drags onto up-next
@import 'SectionHome-Upcoming-UpNextBlocker';



// Card Background for Drag-Drop
// Size of background needs to smaller than card size to keep card sizing logic clean
.sectionHome-upcoming-upcomingCard-background{
	position: absolute;
	top: 0px;
	right: 45px;
	left: 20px;
	bottom: 20px;
	top: 0px;
	background: $white;
	z-index: -1;
	opacity: 0.85;
}


// ContextMenuOpen

.sectionHome-upcoming-upcomingCard.sectionHome-upcoming-upcomingCard--contextOpen
.sectionHome-upcoming-upcomingCard-background{
	top: -10px;
	right: 25px;
	left: 25px;
	bottom: 10px;
	border-radius: 8px;
	// top: 0px;
	background: $gray-15;
	box-sizing: border-box;
	z-index: -1;
	opacity: 1;
}

.sectionHome-upcoming-itemList.sectionHome-upcoming-itemList--upNextContextMenuOpen
.sectionHome-upcoming-upcomingCard{
	opacity: 0.75;
	transition: opacity 100ms linear;
}

.sectionHome-upcoming-itemList.sectionHome-upcoming-itemList--contextMenuOpen
.sectionHome-upcoming-upcomingCard{
	opacity: 0.75;
	transition: opacity 100ms linear;
}

.sectionHome-upcoming-itemList.sectionHome-upcoming-itemList--contextMenuOpen
.sectionHome-upcoming-upcomingCard.sectionHome-upcoming-upcomingCard--contextOpen{
	opacity: 1;
}

