

.library-repoGrid{
	
	margin-left: -7px;
	width: calc(100% + 35px);

	display: flex;
	flex-direction: column;
	flex-wrap: wrap;

	height: 300px;

	margin-top: 8px;
	margin-bottom: 14px;

	position: relative;
	
}

a.library-repoGrid-repo{
	width: 50%;
	
	box-sizing: border-box;
	height: 40px;
	height: 32px;
	//height: 28px;

	padding-left: 11px;

	display: flex;
	align-items: center;
	//justify-content: center;

	cursor: pointer;

	position: relative;

	color: $textBlack;
	border-radius: 3px;

	user-select: none;
}


.library-repoGrid-repo--contents{
	height: 100%;

	display: flex;
	align-items: center;
	box-sizing: border-box;

	flex-grow: 1;
	flex-shrink: 1;
	border-bottom: 1px solid $gray-14;
	//border-bottom: 1px solid $invisible;

	white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  position: relative;
}

.library-repoGrid-repo:hover{
	background: rgb(250,250,255);
}

.library-repoGrid-repo:active{
	background: $blue-extraLight;
}


.library-repoGrid-repo-label{
	flex-grow: 1;
	font-size: 14.5px;
	font-weight: $lato-semibold;
	//font-weight: $lato-medium;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  display: flex;
  align-items: center;
}

.library-repoGrid-repo-label--pending{
	font-weight: $lato-heavy;
}



// ICONS ON REPO GRID

.library-repoGrid-repo-icon{
	background: $white;

	display: flex;
	align-items: center;
	justify-content: center;

	// margin-left: auto;

	position: absolute;
	top: 0px;
	right: 0px;
	bottom: 0px;

	width: 22px;
}

.library-repoGrid-repo:hover .library-repoGrid-repo-icon{
	background: rgb(250,250,255);
}

.library-repoGrid-repo:active .library-repoGrid-repo-icon{
	background: $blue-extraLight;
}

.library-repoGrid-repo-icon--pending-circle{
	width: 9px;
	height: 9px;
	border-radius: 4.5px;
	background: $red--notification;

}


.library-repoGrid-repo-icon .icon.shared,
.library-repoGrid-repo-icon .icon.globe-shared,
.library-repoGrid-repo-icon .icon.globe-shared-author{
	width: 15px;
	height: 15px;
	fill: $gray-12;
}


.library-repoGrid-repo .library-repoGrid-repo-icon--shared,
.library-repoGrid-repo .library-repoGrid-repo-icon--publicConsumer,
.library-repoGrid-repo .library-repoGrid-repo-icon--publicAuthor{	
	opacity: 0;
	transition: opacity 150ms linear;
}

.library-repoGrid:hover .library-repoGrid-repo .library-repoGrid-repo-icon--shared,
.library-repoGrid:hover .library-repoGrid-repo .library-repoGrid-repo-icon--publicConsumer,
.library-repoGrid:hover .library-repoGrid-repo .library-repoGrid-repo-icon--publicAuthor{
	opacity: 1;
	transition: opacity 50ms linear;
}

.library-repoGrid .library-repoGrid-repo:hover .library-repoGrid-repo-icon--shared .icon.shared,
.library-repoGrid .library-repoGrid-repo:hover .library-repoGrid-repo-icon--publicConsumer .icon.globe-shared,
.library-repoGrid .library-repoGrid-repo:hover .library-repoGrid-repo-icon--publicAuthor .icon.globe-shared-author{
	fill: $gray-115;
}


//


.library-repoGrid-repo-chevronContainer{	
	flex-shrink: 0;
	flex-grow: 0;

	width: 22px;
	padding-right: 6px;

	display: flex;
	align-items: center;
	justify-content: center;
}

.library-repoGrid-repo-chevronContainer .icon.chevron-right{
	width: 12px;
	height: 12px;
	fill: $gray-15;
}


///

.library-repoGrid:hover .library-repoGrid-repo .icon.chevron-right{
	fill: $lineMid;
	fill: $gray-13;
}

.library-repoGrid .library-repoGrid-repo:hover .icon.chevron-right{
	fill: $blue;
}

.library-repoGrid-lastLineBlocker{
	position: absolute;
	bottom: 0px;
	left: 0px;
	right: 0px;
	height: 1px;
	background: $white;
}


/// DRAG AND DROP

.library-repoGrid--isDnDDragging{
	//background: $tempr;
}


// remove when dragging
.library-repoGrid--isDnDDragging .library-repoGrid-repo-chevronContainer{
	opacity: 0;
	transition: opacity 100ms linear;
}

// fade out read-only
.library-repoGrid--isDnDDragging .library-repoGrid-repo--cannotDrop .library-repoGrid-repo-label,
.library-repoGrid--isDnDDragging .library-repoGrid-repo--cannotDrop .library-repoGrid-repo-statusIconContainer{
	opacity: 0.25;
	transition: opacity 100ms linear;
}


// when over and ready to drop
.library-repoGrid-repo--isDnDOver.library-repoGrid-repo--canDrop{
	//border: 1px solid $blue;
	//border-radius: 2px;
	//box-sizing: border-box;
	// /background: transparentize($blue-light,0.9); // same color to folder cell
	background: rgb(250,250,255);
}

.library-repoGrid-repo--isDnDOver.library-repoGrid-repo--canDrop .library-repoGrid-repo--contents{
	
	
}

.library-repoGrid-repo--isDnDOver.library-repoGrid-repo--canDrop .library-repoGrid-repo-label{
	font-weight: $lato-bold;
	//margin-left: -1px;
}

// .library-repoGrid-repo--isDnDOver.library-repoGrid-repo--canDrop .icon.shared {
// 	opacity: 0;
// }


// turn pending red
.library-repoGrid--isDnDDragging .library-repoGrid-repo-icon--pending-circle{
	background: $gray-13;	
}


// Drop icon


.library-repoGrid-repo-dropIcon{
	position: absolute;
	right: 0px;
	top: 0px;
	bottom: 0px;
	width: 24px;
	z-index: 10;	
	
	opacity: 0;

	display: flex;
	align-items: center;
	justify-content: center;

	background: rgb(250,250,255);
	// background: $tempr;
}

	
.library-repoGrid-repo-dropIcon .icon{
	transform: rotate(180deg);
	height: 12px;
	width: 12px;
	fill: $blue;
}

.library-repoGrid-repo--isDnDOver.library-repoGrid-repo--canDrop
.library-repoGrid-repo-dropIcon{
	opacity: 1;
}



