// TOOLBAR when clicking
// might be able to get rid of this...

  .equationToolbar {
    // cursor: auto;
    // position: absolute;
    // // background: silver;
    // padding: 3px;
    // border-radius: 2px;
    // /*width: 300px;*/
    // width: 100%
    display: none;
  }

//
// MODAL

$margin-equationEditor-smallBtn: 4px;

.equationModalContainer {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 9998;

  display: flex;

  align-items: center;
  justify-content: center;
  background: rgba(0,0,4,0.75);
}

.equationModal {
  min-width: 920px;
  max-width: calc(100vw - 100px);
  min-height: 536px;
  max-height: calc(100vh - 40px);

  z-index: 9999;

  background: rgb(253,253,255);
  background: rgb(251,251,255);

  border-radius: 6px;
  border: 1px solid rgb(216,216,219);
  border-bottom: 1px solid rgb(206,206,212);
  box-shadow: 0px 5px 10px 0px rgba(0,0,20,0.24), 0px 12px 20px 0px rgba(0,0,20,0.32);

  box-sizing: border-box;
  padding-left: 32px;
  padding-right: 32px;
  padding-bottom: 32px;

  position: relative;

}

.equationModal--advanced{
  background: rgb(30,30,32);
  border: 1px solid rgb(10,10,14);
  border-bottom: 1px solid black;

  padding-bottom: 4px;
}

.equationModalContainerBG{
  position: absolute;
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;
}

//
// TOP BTN ROW

.equationModal-topBtnRow{
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 34px;
  // background: $tempr;
  margin-top: 4px;
  margin-bottom: 2px;

  width: calc(100% + 16px);
}

button.equationModal-topBtnRow-btn,
a.equationModal-topBtnRow-link{
  display: flex;
  align-items: center;
  justify-content: center;



  padding-left: 14px;
  padding-right: 14px;
  height: 100%;

  border: none;
  background: none;

  font-size: 14.5px;
  font-weight: $lato-semibold;
  color: $textBlack;

  border-radius: 5px;
  box-shadow: none;

  letter-spacing: 0.015em;

  cursor: pointer;

  opacity: 0.75;

  // background: $tempr;

  user-select: none;

}


button.equationModal-topBtnRow-btn--cancel{
  font-weight: $lato-bold;
  color: $textBlackDark;
  opacity: 0.9;
}




button.equationModal-topBtnRow-btn:hover,
a.equationModal-topBtnRow-link:hover{
  background: rgba(0,0,80,0.04);
  opacity: 1;
  box-shadow: none;
}

.equationModal--advanced button.equationModal-topBtnRow-btn,
.equationModal--advanced a.equationModal-topBtnRow-link{
  color: $white;
}

.equationModal--advanced button.equationModal-topBtnRow-btn:hover,
.equationModal--advanced a.equationModal-topBtnRow-link:hover{
  background: black;
}

// make padding smaller on advanced to align standard/advanced button
.equationModal--advanced a.equationModal-topBtnRow-link{
  padding-left: 10px;
  padding-right: 10px;
}

// 
// FAST-TIP

.equationModal-topBtnRow-fastTip{
  position: absolute;

  bottom: 8px;
  left: 50%;
  transform: translateX(-50%);

  display: flex;
  align-items: center;
  justify-content: center;



  padding-left: 16px;
  padding-right: 16px;
  padding-top: 8px;
  padding-bottom: 8px;
  
  border: none;
  background: lighten($gray-1, 5%);

  font-size: 14.5px;
  font-weight: $lato-semibold;
  color: transparentize($white, 0.1);

  border-radius: 5px;
  box-shadow: none;

  letter-spacing: 0.015em;

  cursor: pointer;

  opacity: 0.9;

  // background: $tempr;

  user-select: none;
}

.equationModal-topBtnRow-fastTip span{
  font-weight: bold;
  color: $white;
  margin-left: 4px;
  margin-right: 4px;
}


//
// MATHQUILL INPUT
.equationModal-inputContainer.mq-editable-field.mq-math-mode{
  font-size: 280%; // increase core editing font size 
  border-radius: 5px;
  border: 1px solid $gray-11;
  border: 1px solid $blue; // i think should always be focused no?
  box-shadow: 0px 0px 4px 1px transparentize($blue, 0.9);

  padding-left: 36px;

  max-width: 100%;
  overflow-x: auto;

  max-height: 240px;
  overflow-y: clip;
}


@media screen and (max-height: 640px) {
  .equationModal-inputContainer.mq-editable-field.mq-math-mode {
    max-height: 200px;
  }
}


@media screen and (max-height: 600px) {
  .equationModal-inputContainer.mq-editable-field.mq-math-mode {
    max-height: 190px;
  }
}



.equationModal-inputContainer.mq-editable-field.mq-math-mode.mq-focused{
  box-shadow: none;
  border: 1px solid $blue;
  box-shadow: 0px 0px 4px 1px transparentize($blue, 0.9);
}

.equationModal-inputContainer.mq-editable-field{
  padding: 0px;
  padding-top: 12px;
  padding-bottom: 24px;
  min-height: 166px;

  display: flex;
  align-items: center;  
  justify-content: center;
}

.equationModal-inputContainer.mq-editable-field .mq-root-block{
  
  overflow: initial; // prevents overarrow from getting clipped
}

.equationModal-inputContainer.mq-math-mode .mq-empty{
  background: #DCEBFD;
}

// TIP

.equationModal-inputHintContainer{
  position: absolute;
  top: 158px; // refactor
  left: 70px; // refactor
  width: 400px;
  height: 28px;
  // background: $tempr;
  z-index: 100;
  display: flex;
  align-items: center;
  pointer-events: none;
}

.equationModal-inputHint{
  font-size: 15.5px;
  font-weight: $lato-semibold;
  color: $textMid;
  letter-spacing: 0.01em;
}

.equationModal-inputHint--show{
  opacity: 1;
  transition: opacity 50ms linear;
}

.equationModal-inputHint--hide{
  opacity: 0;
  transition: opacity 50ms linear;
}



//
// DEV - LATEX OUTPUT
.equationModal-latexOutput{
  opacity: 0;
  height: 0px;
}



//

$maxWidthControls-equationEditor: 880px;

.equationEditor-controls-top{
  margin: 0 auto;
  margin-top: 18px;
  width: 100%;
  max-width: $maxWidthControls-equationEditor;
  // background: $tempr;

  display: flex;
  align-items: center;
  justify-content: space-between;
  user-select: none;


}

.equationEditor-controls-top-btnGroup{
  display: flex;
}

.equationEditor-controls-top-btnGroup button:nth-child(n+1){
  margin-right: 4px;
}

.equationEditor-controls-top-btnGroup button:last-child{
  margin-right: 0px;
}

.equationEditor-controls-mid{
  margin: 0 auto;
  margin-top: 14px;
  width: 100%;  
  max-width: $maxWidthControls-equationEditor;

  display: flex;
  justify-content: space-between;
  user-select: none;
}


.equationEditor-controls-mid-btnGroup-row,
.equationEditor-controls-bottom-btnGroup-row{
  display: flex;
  margin-bottom: $margin-equationEditor-smallBtn;
}


.equationEditor-controls-mid-btnGroup-row:last-child,
.equationEditor-controls-bottom-btnGroup-row:last-child{
  margin-bottom: 0px;
}

.equationEditor-controls-mid-btnGroup-row button:nth-child(n+1),
.equationEditor-controls-bottom-btnGroup-row button:nth-child(n+1){
  margin-right: $margin-equationEditor-smallBtn;
}

.equationEditor-controls-mid-btnGroup-row button:last-child,
.equationEditor-controls-bottom-btnGroup-row button:last-child{
  margin-right: 0px;
}



.equationEditor-controls-bottom{
  margin: 0 auto;
  margin-top: 14px;
  width: 100%;
  max-width: $maxWidthControls-equationEditor;
  // height: 116px;
  // background: $tempb;

  display: flex;
  align-items: flex-end; // vertical position of primary button
  justify-content: space-between;
  user-select: none;
}


//
//

button.equationEditor-controls-primaryBtn{  
  margin-left: 26px; // additional padding

  height: 90px;
  width: 300px;

  border: 1px solid $blue-dark;
  background: $blue;

  border-radius: 6px;
  box-sizing: border-box;

  box-shadow: 0px 2px 4px 0px rgba(0,0,40,0.16);

  text-align: left;

  padding-left: 22px;

  cursor: pointer;
  user-select: none;

  position: relative;

}

button.equationEditor-controls-primaryBtn--advanced{
  margin-left: 0px; // centered
  width: 450px;
  text-align: center;
  padding: 0px;
  // height: 72px;
}

button.equationEditor-controls-primaryBtn:hover{
  background: saturate($blue, 3%);
}

button.equationEditor-controls-primaryBtn:disabled{
  background: $gray-8;
  border: 1px solid $gray-7;
  pointer-events: none;
  cursor: default;
}

button.equationEditor-controls-primaryBtn--advanced:disabled{
  background: $gray-5;
  border: 1px solid $gray-4;
}


button.equationEditor-controls-primaryBtn:disabled
.equationEditor-controls-primaryBtn-keyboardHint{
  visibility: hidden;
  opacity: 0;
}

.equationEditor-controls-primaryBtn-title{
  font-size: 19px;
  font-weight: $lato-heavy;
  color: $white;

  letter-spacing: 0.03em;
}



.equationEditor-controls-primaryBtn-keyboardHint{
  font-size: 12px;
  font-weight: $lato-semibold;
  letter-spacing: 0.06em;
  color: $white;
  opacity: 0.7;
  text-transform: uppercase;

  margin-top: 4px;
  margin-bottom: 0px;

  opacity: 1;  
  transition: opacity 100ms linear;  
}

button.equationEditor-controls-primaryBtn--advanced
.equationEditor-controls-primaryBtn-keyboardHint{
  margin-bottom: -14px;
  margin-top: 6px;
}

// Error State

.equationEditor-controls-primaryBtn-error{
  position: absolute;
  bottom: -34px;
  left: 50%;
  transform: translateX(-50%);
  height: 28px;

  z-index: 10;

  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 12px;
  padding-right: 12px;

  background: $white;
  // border: 1px solid $gray-4;
  // border-bottom: 1px solid darken($gray-4, 5%);
  border-radius: 4px;
  //box-shadow: 0px 1px 2px 0px rgba(0,0,20,0.04);

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  font-size: 13px;
  font-weight: $lato-semibold;
  letter-spacing: 0.015em;
  color: $textBlack;

  user-select: none;

  background: $gray-6;
  color: $white;
}

.equationEditor-controls-primaryBtn-error--hasError{
  opacity: 1;
  transition: opacity 150ms linear;
  transition-delay: 1500ms;
}

.equationEditor-controls-primaryBtn-error--noError{
  opacity: 0;  
  transition: opacity 50ms linear;
}


// .equationEditor-controls-primaryBtn-errorIcon{
//   position: absolute;
//   top: 0px;
//   left: 0px;
//   bottom: 24px;
//   width: 50px;

//   display: flex;
//   align-items: center;
//   justify-content: center;
// }

// .equationEditor-controls-primaryBtn-errorIcon svg{
//   fill: $white;
//   opacity: 0.75;
//   width: 24px;
//   height: 24px;
// }

// .equationEditor-controls-primaryBtn-errorWords{
//   font-size: 14.5px;
//   font-weight: $lato-semibold;
//   letter-spacing: 0.01em;
  
//   color: $white;
// }

//
// CONTROLS

.equationEditor-controls {
  padding-top: 32px;
  box-sizing: border-box;
  width: 100%;
}

.equationEditor-controls-large,
.equationEditor-controls-small {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

//
//

button.equationEditor-controls-btn {
  border: 1px solid $gray-11;
  border-bottom: 1px solid $gray-9;
  border-radius: 4px;
  background: $white;
  box-shadow: 0px 1px 1px 0px rgba(0,0,20,0.04);

  padding: 0px;

  cursor: pointer;

  position: relative;
}

button.equationEditor-controls-btn:hover{
  border: 1px solid $gray-9;
  // background: #EBF4FF;
  background: $white;
}

button.equationEditor-controls-btn:active{
  // background: #EBF4FF;
  background: $gray-15;
  border: 1px solid $gray-7;
  //background: red;
  outline: none;
  // border: 1px solid blue;
}

button.equationEditor-controls-btn:focus{
  border: 1px solid $gray-7;
  // background: green;
}

//
// LARGE BUTTONS
//

button.equationEditor-controls-btn--large {
  width: 50px;
  height: 50px;
  padding: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  
}

button.equationEditor-controls-btn--large svg.mathIcon{
  
  width: 48px;
  height: 48px;
  fill: #41414e;
}



button.equationEditor-controls-btn--large span{
  font-size: 28px;
  font-weight: $lato-regular;
  margin-bottom: 2px;
  color: #41414e;
}

button.equationEditor-controls-btn--large-pi span{
  font-size: 25px;
  position: relative;
  top: -1px;
}

//

button.equationEditor-controls-btn--small{
  width: 36px;
  height: 36px;

  
  padding: 0px;

  display: flex;
  align-items: center;
  justify-content: center;

  box-sizing: border-box;

}

button.equationEditor-controls-btn--small span{
  font-size: 17px;
  font-weight: $lato-semibold;
  margin-bottom: 1px;
  margin-left: 1px;
  color: #41414e;
}


button.equationEditor-controls-btn--small svg.mathIcon{
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  width: 34px;
  height: 34px;

  fill: lighten(#41414e, 2%);
}



button.equationEditor-controls-btn svg.mathIcon #box,
button.equationEditor-controls-btn svg.mathIcon #box-top,
button.equationEditor-controls-btn svg.mathIcon #box-bottom{
  fill: $gray-13;
}

button.equationEditor-controls-btn svg.mathIcon #box-second,
button.equationEditor-controls-btn svg.mathIcon--textMode #cursor{
 fill: $gray-128; 
}

//
// CUSTOM


button.equationEditor-controls-btn--plus span,
button.equationEditor-controls-btn--minus span,
button.equationEditor-controls-btn--divide span,
button.equationEditor-controls-btn--times span,
button.equationEditor-controls-btn--cdot span,
button.equationEditor-controls-btn--plusminus span,
button.equationEditor-controls-btn--tilde span{
  font-size: 22px;
}


.equationEditor-controls-bottom-btnGroup--greek
button.equationEditor-controls-btn--small span{
  font-size: 17.5px;
}

.equationEditor-controls-bottom-btnGroup--equivalents
button.equationEditor-controls-btn--small span{
  font-size: 20px;
}

button.equationEditor-controls-btn--small.equationEditor-controls-btn--muchless span,
button.equationEditor-controls-btn--small.equationEditor-controls-btn--muchgreater span{
  font-size: 16px;
}

button.equationEditor-controls-btn--small.equationEditor-controls-btn--notleq span,
button.equationEditor-controls-btn--small.equationEditor-controls-btn--notgeq span{
  font-size: 20px;
}

button.equationEditor-controls-btn--small.equationEditor-controls-btn--cup span{
  // color: red;
  transform: scale(-1,-1);
  position: relative;
  font-weight: $lato-bold;
  top: 3px;
}


button.equationEditor-controls-btn--small.equationEditor-controls-btn--cap span{
  position: relative;
  font-weight: $lato-bold;
  top: -1px;
}

button.equationEditor-controls-btn--small.equationEditor-controls-btn--subset span,
button.equationEditor-controls-btn--small.equationEditor-controls-btn--supset span,
button.equationEditor-controls-btn--small.equationEditor-controls-btn--in span{
  position: relative;
  top: -1px;
}


button.equationEditor-controls-btn--sin span,
button.equationEditor-controls-btn--cos span,
button.equationEditor-controls-btn--tan span,
button.equationEditor-controls-btn--log span,
button.equationEditor-controls-btn--logn span,
button.equationEditor-controls-btn--ln span{
  font-size: 15px;
}



//



// CONTROL TOOLTIP



.equationEditor-controls-btn-tooltip{
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 70px;
  bottom: -40px;

  background: #282636;
  z-index: 10;
  pointer-events: none;

  padding-top: 5px;
  padding-bottom: 5px;

  border-radius: 5px;
  opacity: 0.9;
  opacity: 0;
}

.equationEditor-controls-btn-tooltip--wide{
  width: 80px;
}

.equationEditor-controls-btn-tooltip--extraWide{
  width: 110px;
}

.equationEditor-controls-btn-tooltip--extraExtraWide{
  width: 144px;
}

button.equationEditor-controls-btn:hover
.equationEditor-controls-btn-tooltip{
  opacity: 0.9;
  transition: opacity 50ms linear;
  transition-delay: 150ms;
}

.equationEditor-controls-btn-tooltip-label{
  font-size: 12px;
  font-weight: $lato-bold;
  letter-spacing: 0.02em;
  color: $white;
}

.equationEditor-controls-btn-tooltip-latex{
  font-size: 11px;
  margin-top: 2px;
  font-weight: $lato-medium;
  letter-spacing: 0.025em;
  color: $white;
  opacity: 0.75;
}


//
// KEYBOARD INSTRUCTIONS

.equationEditor-keyboardInstructions{
  margin-top: 16px;
  text-align: left;
  color: $textMid;
}


////
//// ADVANCED

.equationEditor-latexTextAreaContainer{
  width: 100%;
  height: 160px;
  // background: $tempr;

  position: relative;

  margin-bottom: 12px;
}

textarea.equationEditor-latexTextArea,
.equationEditor-latexTextAreaPlaceholder{    
  height: 100%;
  width: 100%;

  resize: none;

  box-sizing: border-box;
  border-radius: 4px;

  padding-top: 14px;
  padding-left: 14px;
  padding-right: 14px;

  font-size: 16px;
  font-weight: $lato-medium;  

  background: rgb(0,0,5);
  color: $white;

  border: 1px solid $blue;

  font-family: 'SFMono-Regular','Menlo','Monaco','Consolas','Liberation Mono','Courier New','monospace', 'Plickers Lato';
}

textarea.equationEditor-latexTextArea:focus{
  border: 1px solid $blue;
  outline: none;
}

.equationEditor-latexTextAreaPlaceholder{
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;

  background: none;
  z-index: 10;
  border: 1px solid $invisible;

  opacity: 0.5;
  pointer-events: none;
  user-select: none;
}

.equationEditor-latexPreviewArea{
  height: 200px;
  width: 100%;

  background: $white;
  border: 1px solid $lineMid;

  border-radius: 4px;

  position: relative;

}

.equationEditor-latexPreviewArea-label{
  position: absolute;
  top: 10px;
  left: 12px;

  font-size: 12.5px;
  font-weight: $lato-bold;
  letter-spacing: 0.025em;
  text-transform: uppercase;

  color: lighten($textMid, 18%);

  cursor: default;
  user-select: none;
}

.equationEditor-latexPreviewArea-preview{
  width: 100%;
  height: 100%;

  user-select: none;
  cursor: default;

  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 24px;
  font-family: 'SFMono-Regular','Menlo','Monaco','Consolas','Liberation Mono','Courier New','monospace', 'Plickers Lato';

  overflow: hidden;
}

.equationEditor-addShortcutCheckboxContainer{
  width: 100%;
  // background: $tempr;
  display: flex;
  align-items: center;
  justify-content: center;

  margin-top: 12px;

  opacity: 0.7;
}

.equationEditor-addShortcutCheckboxContainer:hover{
  opacity: 1;
}

.equationEditor-addShortcutCheckboxContainer .featureModal-selectControl,
.equationEditor-addShortcutCheckboxContainer .featureModal-selectControl *{
  cursor: pointer;
}

.equationEditor-addShortcutCheckboxContainer .featureModal-selectControl-label{
  color: $white;
  font-weight: $lato-medium;
  position: relative;
}

.equationEditor-advancedPrimaryBtnContainer{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  margin-top: 16px;
}


@media screen and (max-height: 600px) {
  .equationEditor-latexPreviewArea {
    height: 180px;
  }
  .equationEditor-latexTextAreaContainer{
    height: 150px;
  }
}

