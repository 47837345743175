
//
// PROGRESS BAR
//

.syncLiveView-player-main-slideContainer-progressBar{
	display: flex;
	align-items: top;
	justify-content: center;

	width: 80%;
}

.syncLiveView-player-main-slideContainer-progressBar-item{
	flex-grow: 1;
	flex-shrink: 1;
	height: 25px;
	cursor: pointer;

	display: flex;
	align-items: center;

	position: relative;
	
	margin: 1px;
}

.syncLiveView-player-main-slideContainer-progressBar-item-pillContainer{
	width: 100%;
	height: 4px;
	
	border-radius: 2px;
	overflow: hidden;
	position: relative;
}

.syncLiveView-player-main-slideContainer-progressBar-item-pillContainer .scorePill,
.syncLiveView-player-main-slideContainer-progressBar-item-pillContainer .scorePill.is--survey{
	position: absolute;
	top: 0px;
	left: 0px;
	right: 0px;
	bottom: 0px;
	width: 100%;
	height: 100%;

	z-index: 2;

	opacity: 0;
	transition: opacity 150ms linear;
	transition-delay: 100ms;
}

.syncLiveView-player-main-slideContainer-progressBarContainer:hover 
.syncLiveView-player-main-slideContainer-progressBar-item-pillContainer
.scorePill{
	opacity: 1;
	transition: opacity 150ms linear;
	transition-delay: 100ms;
}

.syncLiveView-player-main-slideContainer-progressBar-item-pill{
	position: absolute;
	top: 0px;
	left: 0px;
	right: 0px;
	bottom: 0px;
	z-index: 5;
	
}

.syncLiveView-player-main-slideContainer-progressBar-item-pill{
	background: $gray-12;
}


// Class Safe Mode, still show the answers they got
.syncLiveView-player-main-slideContainer-progressBar-item--hasPoll
.syncLiveView-player-main-slideContainer-progressBar-item-pill--classSafePoll{
	background: $gray-10;
}


.syncLiveView-player-main-slideContainer-progressBar-item--hasPoll
.syncLiveView-player-main-slideContainer-progressBar-item-pill{
	z-index: 1;
}

.syncLiveView-player-main-slideContainer-progressBar-item--hasPoll.syncLiveView-player-main-slideContainer-progressBar-item--activeQuestion
.syncLiveView-player-main-slideContainer-progressBar-item-pill{
	z-index: 5;
}

.syncLiveView-player-main-slideContainer-progressBar-item--hasPoll:hover
.syncLiveView-player-main-slideContainer-progressBar-item-pill{
	z-index: 5;
}

.syncLiveView-player-main-slideContainer-progressBar-item--activeQuestion
.syncLiveView-player-main-slideContainer-progressBar-item-pill,
.syncLiveView-player-main-slideContainer-progressBar-item--activeQuestion:hover
.syncLiveView-player-main-slideContainer-progressBar-item-pill{
	background: $gray-4;
}

.syncLiveView-player-main-slideContainer-progressBar-item:hover
.syncLiveView-player-main-slideContainer-progressBar-item-pill{
	background: $gray-7;
}

.syncLiveView-player-main-slideContainer-progressBar-item:active
.syncLiveView-player-main-slideContainer-progressBar-item-pill{
	background: $gray-2;
}


$syncLiveView-progressBar-item-slidePreviewWidth: 256px;

.syncLiveView-player-main-slideContainer-progressBar-item-slideContainer{
	position: absolute;
	z-index: 50;
	height: calc(#{$syncLiveView-progressBar-item-slidePreviewWidth}/1.6);
	width: $syncLiveView-progressBar-item-slidePreviewWidth;

	background: $white;
	border: 1px solid darken($lineMid, 5%);
	border-bottom: 1px solid darken($lineMid, 9%);
	border-radius: 2px;
	box-shadow: 0px 1px 2px 0px rgba(0,0,20,0.08);

	top: calc((-1 * #{$syncLiveView-progressBar-item-slidePreviewWidth}/1.6) + 2px);
	left: 50%;
	transform: translateX(-50%);

	opacity: 0;
	visibility: hidden;

	pointer-events: none;
	overflow: hidden;
}


.syncLiveView-player-main-slideContainer-progressBar-item--notActiveQuestion:hover
.syncLiveView-player-main-slideContainer-progressBar-item-slideContainer{
	visibility: visible;
}

.syncLiveView-player-main-slideContainer-progressBar
.syncLiveView-player-main-slideContainer-progressBar-item-slideContainer{
	opacity: 0;
}

.syncLiveView-player-main-slideContainer-progressBar:hover

.syncLiveView-player-main-slideContainer-progressBar-item-slideContainer{
	opacity: 1;
	transition: opacity 0ms linear;
	transition-delay: 500ms;
}

//
// IS SCANNING
//

.syncLiveView-player-main-slideContainer-progressBar--isScanning
.syncLiveView-player-main-slideContainer-progressBar-item{
	cursor: default;
}

.syncLiveView-player-main-slideContainer-progressBar--isScanning
.syncLiveView-player-main-slideContainer-progressBar-item:hover
.syncLiveView-player-main-slideContainer-progressBar-item-pill{
	background: $gray-12;
}

.syncLiveView-player-main-slideContainer-progressBar--isScanning
.syncLiveView-player-main-slideContainer-progressBar-item--activeQuestion:hover
.syncLiveView-player-main-slideContainer-progressBar-item-pill{
	background: $gray-4;
}

.syncLiveView-player-main-slideContainer-progressBar--isScanning
.syncLiveView-player-main-slideContainer-progressBar-item--hasPoll:hover
.syncLiveView-player-main-slideContainer-progressBar-item-pill{
	background: none;
}

//class safe fix
.syncLiveView-player-main-slideContainer-progressBar--isScanning
.syncLiveView-player-main-slideContainer-progressBar-item--hasPoll:hover
.syncLiveView-player-main-slideContainer-progressBar-item-pill--classSafePoll{
	background: $gray-10;
}