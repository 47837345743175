//
// IMPORT QUESTIONS RIGHT PANEL
//

.importQuestions-rightPanel{
	background: rgb(251,251,255);
	width: $width-importQuestions-rightPanel;
	height: 100%;
	flex-grow: 0;
	flex-shrink: 0;
	overflow-y: auto;
	overflow-y: overlay;

	position: relative;
	z-index: 5;
}

.importQuestions-rightPanel-subSection{
	border-bottom: 1px solid $lineMid;
	padding-top: 10px;
	padding-left: 10px;
	padding-bottom: 14px;
	box-sizing: border-box;

}

.importQuestions-rightPanel-subSection-title{
	font-size: 15px;
	font-weight: $lato-bold;
	color: $textBlack;
	margin-bottom: 5px;
	cursor: default;
	user-select: none;
}

//
// CLEAN PASTED TEXT
//

.importQuestions-rightPanel-subSection--cleanPastedText-subtitle{
	font-size: 13px;
	font-weight: $lato-semibold;
	color: $textMid;
	margin-top: -3px;
	margin-bottom: 8px;
	user-select: none;
	cursor: default;
}

.importQuestions-rightPanel-subSection--cleanPastedText-buttonRow{
	display: flex;
	margin-bottom: 8px;
	padding-right: 24px;
	box-sizing: border-box;
	justify-content: space-between;
}

.importQuestions-rightPanel-subSection--cleanPastedText-buttonRow button{
	flex-grow: 0;
	flex-shrink: 0;
	width: calc(32% + 2px);
	padding: 0px;
}



//
// FIND AND REPLACE
//

.importQuestions-rightPanel-subSection--findAndRemove{
	padding-right: 20px;
	box-sizing: border-box;
}



.importQuestions-rightPanel-subSection--findAndRemove-inputRow{
	display: flex;
}

.importQuestions-rightPanel-subSection--findAndRemove-inputRow input{
	flex-grow: 1;
	margin-right: 4px;
	box-sizing: border-box;
	padding-left: 6px;

	border-radius: 2px;
	font-size: 14px;

	border: 1px solid $lineDark;
	outline: none;
	box-shadow: none;
}

.importQuestions-rightPanel-subSection--findAndRemove-inputRow input:focus{
	border: 1px solid $blue;
	
	outline: none;
	box-shadow: none;
}

.importQuestions-rightPanel-subSection--findAndRemove-inputRow button{
	font-weight: $lato-bold;
}


// CHECKBOXES


.importQuestions-rightPanel-subSection--findAndRemove-checkboxesRow{
	display: flex;
	margin-top: 8px;
}

.importQuestions-rightPanel-subSection--findAndRemove-checkboxContainer{
	display: flex;
	align-items: center;
	margin-right: 12px;
	cursor: pointer;
}

.importQuestions-rightPanel-subSection--findAndRemove-checkbox{
	width: 12px;
	height: 12px;
	cursor: pointer;
	border-radius: 2px;

	margin-right: 5px;

	position: relative;
	flex-grow: 0;
	flex-shrink: 0;
}

// ACTIVE STATE
.importQuestions-rightPanel-subSection--findAndRemove-checkboxContainer--active
.importQuestions-rightPanel-subSection--findAndRemove-checkbox{
	border: 1px solid darken($blue, 10%);
	background: $blue;
}

.importQuestions-rightPanel-subSection--findAndRemove-checkboxContainer--active
.importQuestions-rightPanel-subSection--findAndRemove-checkbox svg{
	position: absolute;
	left: 2px;
	top: 2px;
	width: 8px;
	height: 8px;
}

.importQuestions-rightPanel-subSection--findAndRemove-checkbox svg #border{
	fill: darken($blue, 10%);
}

.importQuestions-rightPanel-subSection--findAndRemove-checkbox svg #fill{
	fill: $white;
}

// INACTIVE STATE
.importQuestions-rightPanel-subSection--findAndRemove-checkboxContainer--inactive
.importQuestions-rightPanel-subSection--findAndRemove-checkbox{
	border: 1px solid $lineDark;
	background: $white;
}


.importQuestions-rightPanel-subSection--findAndRemove-label{
	font-size: 13.5px;
	color: $textDark;
	font-weight: $lato-semibold;
	user-select: none;
	position: relative;
	top: 1px;
}

.importQuestions-rightPanel-subSection--findAndRemove-checkboxContainer{
	opacity: 0.9;
}

.importQuestions-rightPanel-subSection--findAndRemove-checkboxContainer:hover{
	opacity: 1;
}

.importQuestions-rightPanel-subSection--findAndRemove-checkboxContainer:hover
.importQuestions-rightPanel-subSection--findAndRemove-label{
	color: $textBlack;
}




//
// IMPORT PREVIEW
//

.importQuestions-rightPanel-subSection--importPreview{
	padding-bottom: 2px;
}


.importQuestions-rightPanel-subSection--importPreview .slideCarousel-navBtn{
	width: 50%;	
}

.importQuestions-rightPanel-subSection--importPreview .questionCountBadge--gray{
	opacity: 1;
}


.slideCarousel-navBtn--previous{
	padding-right: 30%;
}

.slideCarousel-navBtn--next{
	padding-left: 30%;
}

.importQuestions-rightPanel-subSection--importPreview .slideCarousel-playBtnContainer{
	display: none;
}

.importQuestions-rightPanel-subSection--importPreview-emptySpacer{
	width: 5px;
	height: 3px;
}

//
// HELP
//

.importQuestions-rightPanel-subSection--help{
	padding-bottom: 100px;
}

.importQuestions-rightPanel-subSection--help-videoLinks{
	display: flex;
	margin-left: -8px;
	padding-right: 10px;
	box-sizing: border-box;
}

.importQuestions-rightPanel-subSection--help button.importquestionhelp{
	color: $textBlue;
	cursor: pointer;
	width: 50%;
	justify-content: flex-start;
	background: none;
	border: none;
	box-shadow: none;
}

.importQuestions-rightPanel-subSection--help button.importquestionhelp:hover{
	background: transparentize($textBlue, 0.95);
	border: none;
	box-shadow: none;
}

.importQuestions-rightPanel-subSection--help-faqQuestions{
	margin-top: 10px;
	box-sizing: border-box;
	padding-right: 56px;
	opacity: 0.8;
	cursor: default;
}

.importQuestions-rightPanel-subSection--help-faqItem{
	margin-bottom: 14px;
	box-sizing: border-box
}

.importQuestions-rightPanel-subSection--help-faqItem-title{
	font-size: 14px;
	font-weight: $lato-bold;
	color: $textBlack;
	margin-bottom: 3px;
	margin-left: 15px;
}



ul.importQuestions-rightPanel-subSection--help-faqitem-list{
	margin-block-start: 4px;
	margin-block-end: 4px;
	padding-inline-start: 15px;
}

li.importQuestions-rightPanel-subSection--help-faqItem-description{
	font-size: 13.5px;
	font-weight: $lato-medium;
	color: $textDark;
	line-height: 17px;

	margin: 0px;
	padding: 0px;
	margin-bottom: 5px;
}

.importQuestions-rightPanel-subSection--help-faqItem-description--2,
.importQuestions-rightPanel-subSection--help-faqItem-description--3,
.importQuestions-rightPanel-subSection--help-faqItem-description--4{
	margin-top: 2px;
}

.importQuestions-rightPanel:hover
.importQuestions-rightPanel-subSection--help-faqQuestions{
	opacity: 1;
}

//
// BUTTON ROW
//

.importQuestions-rightPanel-buttonRow{
	position: fixed;
	bottom: 0px;
	right: 0px;
	width: $width-importQuestions-rightPanel;
	padding-top: 6px;
	padding-bottom: 6px;
	padding-left: 6px;
	padding-right: 6px;
	box-sizing: border-box;

	border-top: 1px solid $lineDark;

	background: rgb(250,250,255);

	display: flex;
}

.importQuestions-rightPanel-buttonRow button{
	width: 100%;
	height: 50px;
	font-weight: $lato-heavy;
	font-size: 18px;
	cursor: pointer;
	background: $blue-light;
}

.importQuestions-rightPanel-buttonRow button:hover{
	background: darken($blue-light, 5%);
}

.importQuestions-rightPanel-buttonRow button.btn--disabled,
.importQuestions-rightPanel-buttonRow button.btn--disabled:hover{
	background: $white;
	border-color: $gray-10;
	color: $textDark;
}

/// VIDEO

.importQuestions-rightPanel-subSection--help-gettingStartedVideoContainer{	
	padding-left: 16px;
	margin-top: -2 px;
	width: 400px;

	display: flex;
	align-items: center;
	justify-content: flex-start;

	cursor: pointer;

	border-radius: 5px;
	margin-bottom: 15px;

	background: rgb(246,246,253);
}

.importQuestions-rightPanel-subSection--help-gettingStartedVideoContainer .helpVideoThumbnail{
	cursor: pointer;
	transform: scale(0.8);
}

.importQuestions-rightPanel-subSection--help-gettingStartedVideoContainer:hover{
	background: rgb(240,240,250);
}

.importQuestions-rightPanel-subSection--help-gettingStartedVideo-text{
	width: 190px;
	user-select: none;
}

.importQuestions-rightPanel-subSection--help-gettingStartedVideo-text-label{
	font-weight: $lato-heavy;
	color: $textBlue;
	font-size: 14.5px;
	margin-top: 6px;
	margin-bottom: 4px;
}

.importQuestions-rightPanel-subSection--help-gettingStartedVideoContainer:hover
.importQuestions-rightPanel-subSection--help-gettingStartedVideo-text-label{
	color: darken($textBlue, 3%);
}


.importQuestions-rightPanel-subSection--help-gettingStartedVideo-text-sub{
	font-weight: $lato-semibold;
	color: $textMid;	
	font-size: 13px;
	opacity: 0.8;
}

.importQuestions-rightPanel-subSection--help-gettingStartedVideoContainer:hover
.importQuestions-rightPanel-subSection--help-gettingStartedVideo-text-sub{
	opacity: 1;
}