//
/// SECTION HOME ADD TO CLASS HAS THREE PARTS
//

// 1. INLINE (Table with Button opened in Section Home Page)
// 2. SEARCH MODAL (Opened from the inline component)
// 3. CELL (used in 1 and 2)
// Styling for all of these are here

///
//// ADD TO CLASS INLINE
///

.sectionHome-addToClassInline{
	margin-bottom: 20px;
	position: relative;
	width: 100%;
	user-select: none;
}

.sectionHome-addToClassInline.sectionHome-addToClassInline--showContents{
	height: auto; // opens when shown
}

.sectionHome-addToClassInline.sectionHome-addToClassInline--hideContents{
	height: 50px;
}

.sectionHome-addToClassInline button.add-to-queue{
	min-width: 200px;
	margin: 0 auto;
}

//

.sectionHome-addToClassInline-table{
	width: 100%;
	margin-top: 10px;

	background: rgb(250,250,255);
	padding: 10px;
	padding-left: 20px;
	padding-right: 20px;

	box-sizing: border-box;
	border-radius: 7px;
}

.sectionHome-addToClassInline--emptyQueue
.sectionHome-addToClassInline-table{
	background: none;
	padding: 0px;
	padding-left: 0px;
	padding-right: 0px;
}

//

.sectionHome-addToClassInline-searchLibrary{	
	width: 140px;
	text-align: center;

	margin-top: 4px;
	margin-left: 75px;
	
	box-sizing: border-box;

	cursor: pointer;

	font-size: 15px;
	font-weight: $lato-semibold;
	color: $textBlue;
	border-radius: 3px;
	
	height: 32px;
	line-height: 2;
}

.sectionHome-addToClassInline-searchLibrary:hover{
	color: darken($textBlue, 5%);
	background: $gray-15;
}

.sectionHome-addToClassInline-searchLibrary:active{
	color: darken($textBlue, 10%);
	background: $gray-14;
}

///
//// SEARCH MODAL
///
/// similar to image-search layout in Set Editor

$height-addToClassSearchModal: 605px;

.addToClassSearch-modal .modal-content{
  width: 800px;
  height: $height-addToClassSearchModal;
}

.addToClassSearch{
	box-sizing: border-box;
  width: calc(100% - 64px);
  margin: 0 auto;
  position: relative; 
  margin-top: 24px;
}

.addToClassSearch input {
  //height: 40px;
  width: 100%;
  border: 1px solid $gray-10;
  line-height: 36px;
  border-radius: 4px;
  padding-left: 10px;
  //padding-bottom: 4px;
  box-sizing: border-box;
  font-size: 20px;
  font-weight: $lato-bold;
  color: $textBlack;
  outline: none;
  pointer-events: default;
  box-shadow: none;
}

.addToClassSearch input:focus{
    border: 1px solid $blue;
}

.addToClassSearch ::-webkit-input-placeholder {
   color: $gray-115 !important;
   font-size: 20px;
   font-weight: $lato-bold;
}

.addToClassSearch-resultsList{
	width: calc(100% - 64px);
	margin: 0 auto;
	padding-top: 12px;
	max-height: calc(#{$height-addToClassSearchModal} - 112px);
	//background: $tempr;
	overflow: auto;
	padding-bottom: 32px;
	box-sizing: border-box;

}

///
//// CELL
///

.sectionHome-addToClassCell{
	width: 100%;
	height: 100%;
	height: 42px;
	position: relative;
	cursor: pointer;
}

.sectionHome-addToClassCell-addBtn{
	width: 85px;
	
	border: 1px solid $gray-125;
	border-radius: 3px;
	background: $white;
	color: $textMid;
	
	height: 28px;

	font-size: 14px;
	font-weight: $lato-semibold;
	line-height: 2;

	position: absolute;
	top: 7px;
	left: 0px;
	text-transform: uppercase;
	text-align: center;
}


.sectionHome-addToClassCell-contents{
	position: absolute;
	left: 96px;
	right: 0px;
	height: 100%;
	border-bottom: 1px solid $lineMid;
	
	display: flex;
	align-items: center;
}

.sectionHome-addToClassInline-table
.sectionHome-addToClassCell-contents{
	border-top: 1px solid rgb(250,250,255);
}

.sectionHome-addToClassCell-contents
.questionCountBadge{
	position: absolute;
	left: 0px;
	top: 13px;
}

.sectionHome-addToClassCell-title{
	font-size: 15px;
	font-weight: $lato-semibold;
	color: $textBlack;

	margin-left: 30px;

	white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.sectionHome-addToClassCell-title .icon.shared{
	width: 13px;
	height: 13px;

	fill: $gray-10;	
	
	margin-left: 2px;
	margin-right: 2px;
	position: relative;
	top: 1px;
}

.sectionHome-addToClassCell-timestamp{
	font-size: 14px;
	font-weight: $lato-medium;
	color: $textLight;
	flex-grow: 1;
	flex-shrink: 0;
	padding-left: 4px;
	line-height: 1.8;
}


//
// INTERACTIONS
//

.sectionHome-addToClassCell:hover
.sectionHome-addToClassCell-addBtn{
	background: $blue;
	border-color: $textBlue;
	color: $white;
}

.sectionHome-addToClassCell:active{
	background: $gray-13;
}

.sectionHome-addToClassCell:active
.sectionHome-addToClassCell-addBtn{
	background: saturate($blue, 20%)
}


.sectionHome-addToClassInline-table
.sectionHome-addToClassCell:hover{
	opacity: 1;
	background: $white;
}

.sectionHome-addToClassInline-table
.sectionHome-addToClassCell:hover
.sectionHome-addToClassCell-contents{
	border-top: 1px solid $lineMid;
}

.sectionHome-addToClassInline.sectionHome-addToClassInline--hideContents
.sectionHome-addToClassInline-table,
.sectionHome-addToClassInline.sectionHome-addToClassInline--hideContents
.sectionHome-addToClassInline-searchLibrary{
	opacity: 0;
	pointer-events: none;
}

.sectionHome-addToClassInline.sectionHome-addToClassInline--showContents
.sectionHome-addToClassInline-table,
.sectionHome-addToClassInline.sectionHome-addToClassInline--showContents
.sectionHome-addToClassInline-searchLibrary{
	opacity: 1;
	transition: opacity 50ms linear;
}

.sectionHome-addToClassInline.sectionHome-addToClassInline--hideContents
.sectionHome-addToClassInline-searchLibrary{
	opacity: 0;
	transition: opacity 0ms linear;
}