.upgrade--payment-paymentFormContainer{
	display: flex;
	align-items: flex-start;
	justify-content: space-between;

	margin-top: 24px;
}

.upgrade--payment-paymentFormContainer-smallPrint{
	width: 360px;

	font-size: 12.5px;
	line-height: 1.3;
	font-weight: $lato-regular;
	color: $textMid;
}

form.upgrade--payment-paymentForm{
	width: 400px;	
	display: flex;
	flex-direction: column;	
}

form.upgrade--payment-paymentForm.upgrade--payment-paymentForm--submitLoading{
	//opacity: 0.75;
	pointer-events: none;
}


// NEW CARD vs. SAVED CARD

.upgrade--payment-paymentForm-savedCard{
	display: flex;
	cursor: pointer;
}

.upgrade--payment-paymentForm-savedCard--currentCard{
	margin-bottom: 16px;
	margin-top: -29px; // bit hacky
}

.upgrade--payment-paymentForm-savedCard--newCard{
	margin-bottom: 8px;
}

.upgrade--payment-paymentForm-savedCard--newCard{
	color: $textMid;
}

//

.upgrade--payment-paymentForm-savedCard-radio{
	width: 16px;
	height: 16px;
	border-radius: 50%;
	background: $white;
	border: 1px solid $lineMid;

	margin-top: 1px;
	margin-right: 12px;

	display: flex;
	align-items: center;
	justify-content: center;

}

.upgrade--payment-paymentForm-savedCard--active
.upgrade--payment-paymentForm-savedCard-radio{
	background: $blue-light;
	border: 1px solid $blue;
}

//

.upgrade--payment-paymentForm-savedCard-radio-inner{
	width: 8px;
	height: 8px;
	border-radius: 50%;
	background: $white;
	border: 1px solid $blue;
	opacity: 0;
}

.upgrade--payment-paymentForm-savedCard--active
.upgrade--payment-paymentForm-savedCard-radio-inner{
	opacity: 1;
}

//


.upgrade--payment-paymentForm-savedCard-right{
	font-size: 19px;
	font-weight: $effra-medium;
}

.upgrade--payment-paymentForm-savedCard--active
.upgrade--payment-paymentForm-savedCard-right{
	color: darken($textBlue, 2%);
}

.upgrade--payment-paymentForm-savedCard--currentCard-cardNumber{
	color: $textMid;
	font-weight: $effra-medium;
	font-size: 17.5px;
	margin-top: 3px;
}

.upgrade--payment-paymentForm-savedCard--active
.upgrade--payment-paymentForm-savedCard--currentCard-cardNumber{
	color: $textBlack;
}

.upgrade--payment-paymentForm-savedCard-newCardSpacer{
	height: 50px;
}

//




// FIELD
$height-paymentForm-field: 44px;

input.upgrade--payment-paymentForm-field{
	width: 100%;
	height: $height-paymentForm-field;
	line-height: $height-paymentForm-field; // Safari fix

	background: $white;

	font-size: 18px;
	border-radius: 4px;
	font-weight: $effra-medium;
	color: $textBlackDark;

	padding-left: 14px;

	padding-top: 3px;
	box-sizing: border-box;

	border: 1px solid $lineDark;

	margin-bottom: 10px;

	box-shadow: none;
	outline: none;
	-webkit-appearance: none;
}



input.upgrade--payment-paymentForm-field::-webkit-input-placeholder{
	color: transparentize($textLight, 0.2);
}

input.upgrade--payment-paymentForm-field:hover::-webkit-input-placeholder{
	color: transparentize($textLight, 0.4);
}	

input.upgrade--payment-paymentForm-field:focus::-webkit-input-placeholder{
	color: transparentize($textLight, 0.6);
}

input.upgrade--payment-paymentForm-field--email{
	pointer-events: none; // no selectable
	user-select: none;
	cursor: default;
	background: transparentize($white, 0.2);
	border-color: $lineMid;
	color: $textMid;
}

// ADDRESS 

.upgrade--payment-paymentForm-addressContainer{
	display: flex;
}

input.upgrade--payment-paymentForm-field--addressLine{
	width: 10px;
	flex-grow: 1;
	flex-shrink: 1;
	
}

input.upgrade--payment-paymentForm-field--postcode{
	width: 110px;
	margin-left: 12px;
	flex-grow: 0;
	flex-shrink: 0;
}

// CARD ELEMENT

.upgrade--payment .StripeElement{
	width: 100%;
	height: $height-paymentForm-field;

	background: $white;

	font-size: 18px;
	border-radius: 4px;
	font-weight: $effra-medium;
	color: $textBlackDark;

	padding-left: 14px;
	padding-top: 9px;
	box-sizing: border-box;

	border: 1px solid $lineDark;

	margin-bottom: 10px;

	box-shadow: none;
	outline: none;
	-webkit-appearance: none;

	font-family: 'Effra';
}

.upgrade--payment .StripeElement--focus{
	outline: none;
	border: 1px solid $blue-light;
	background: rgba(255,255,255,1);
}


//
// STATES
//


// // EMPTY (LIGHT)
// input.signin-form-field-input,
// .signup-form-field--empty input.signup-form-field-input{
// 	border: 1px solid $gray-128;
// 	background: rgba(255,255,255,0.85);
// }

// // EMPTY :HOVER
// .signin-form-field--empty:hover input.signin-form-field-input,
// .signup-form-field--empty:hover input.signup-form-field-input{
// 	border: 1px solid $gray-125;
// 	background: rgba(255,255,255,0.85);
// }

// // NONEMPTY (DARK)
// input.signin-form-field-input,
// .signup-form-field--nonEmpty input.signup-form-field-input{
// 	border: 1px solid $gray-125;
// 	background: rgba(255,255,255,1);
// }

// // NONEMPTY (DARK):HOVER
// .signin-form-field--nonEmpty input.signin-form-field-input:hover,
// .signup-form-field--nonEmpty:hover input.signup-form-field-input{
// 	border: 1px solid $gray-12;
// 	background: rgba(255,255,255,1);
// }

// // FOCUS (ALWAYS BLUE)
input.upgrade--payment-paymentForm-field:focus{
	outline: none;
	border: 1px solid $blue-light;
	background: rgba(255,255,255,1);
}



// SUBMIT BUTTON
button.upgrade--payment-paymentForm-submit{
	width: 100%;
	height: 72px;
	background: $upgrade-btn-green;
	color: $white;

	margin-top: 8px;

	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	font-size: 22px;
	font-weight: $effra-medium;

	border-radius: 5px;

	cursor: pointer;

	position: relative;
	border: none;
}

button.upgrade--payment-paymentForm-submit
.upgrade--payment-paymentForm-submit-sub{
	font-size: 17px;
	opacity: 0.85;
	margin-top: 0px;
}



// SUBMIT BUTTON SPINNER

.upgrade--payment-paymentForm-submitSpinnerContainer{
	position: absolute;
	
	display: flex;
	align-items: center;
	justify-content: center;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;	
}

.upgrade--payment-paymentForm-submitSpinner{
	height: 24px;
	width: 24px;
  
  border-radius: 15px;

  border: 3px rgba(26,28,34,0.55) solid;
  border-top: 3px $white solid;

  opacity: 0.5;

  animation: spinnerRotateAnimation .4s infinite linear;
}


		

// ERROR
.upgrade--payment-paymentForm-error{
	width: 100%;
	margin-top: 14px;
	
	color: $red;

	
	text-align: center;
	
	font-size: 15px;
	font-weight: $effra-medium;

	display: flex;
	align-items: center;
	justify-content: center;

}
