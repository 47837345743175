


.studentSidePanel{
	width: 100%;
	height: 100%;

	margin-top: 50px;
	
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
}

.studentSidePanel button{
	min-width: 150px;
	margin-bottom: 4px;
}

.studentSidePanel button.edit-student-name{
	margin-bottom: 14px;
	margin-top: 2px;

}

.studentSidePanel button.archive-student,
.studentSidePanel button.unarchive-student{
	margin-top: 8px;
}

//
/// EMPTY (ADD STUDENT)
//

.studentSidePanel--empty .addStudentBar{
	height: 30px;
	width: 100%;
	flex-grow: 0;
	position: relative;
}

.studentSidePanel--empty .addStudentBar input{
	border-color: $lineDark;
	background: $white;
}


.studentSidePanel--empty button{
	width: 100%;
	max-width: 150px;
	margin-top: 6px;
}


//
/// ACTIVE STUDENT
//

.studentSidePanel-header{
	position: relative;
	padding-right: 40px;

	box-sizing: border-box;

	font-size: 18px;
	color: $textBlack;

	padding-bottom: 50px;
	//border-bottom: 1px solid $lineDark;

	user-select: none;
	cursor: default;
}

.studentSidePanel-header-firstName{
	font-weight: $lato-bold;
}

.studentSidePanel-header-lastName{
	font-weight: $lato-semibold;
}

.studentSidePanel-header-cardNo{
	position: absolute;
	top: -5px;
	right: 0px;

	width: 32px;
	height: 32px;

	background: $white;
	
	border: 1px solid;
	border-top-color: $lineMid;
	border-left-color: $lineMid;
	border-right-color: $lineMid;
	border-bottom-color: $lineDark;
	
	border-radius: 3px;

	text-align: center;

	color: $textBlue;
	font-size: 17px;
	font-weight: $lato-bold;
	line-height: 1.9;
	

}