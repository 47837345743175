$height-explorerTopbar: 48px;
$leftWidth-explorerCrossFilter: 360px;
$rightWidth-explorerCrossFilter: 220px;

@import 'Explorer-TopBar';
@import 'Explorer-CrossFilterDisplayControl';
@import 'Explorer-CrossFilterMarketPanel';
@import 'Explorer-CrossFilterRepo';
@import 'Explorer-EditRepoNotesModal';

.page.explorer{
	background: $white;
}

.plickers-explorer-exportCSVButtonContainer{
	position: fixed;
	z-index: 9999;
	top: 8px;
	right: 20px;
}


// contains display controls
.explorerCrossFilter--left{
	position: fixed;
	left: 0px;
	top: $height-explorerTopbar;
	bottom: 0px;
	width: $leftWidth-explorerCrossFilter;
	
	box-sizing: border-box;
	padding: 12px;
	padding-bottom: 0px;
	padding-left: 8px;

	overflow: auto;

	background: $gray-15;
	border-right: 1px solid $lineMid;
}

.explorerCrossFilter--center{
	margin-left: $leftWidth-explorerCrossFilter;
	width: calc(100vw - #{$leftWidth-explorerCrossFilter} - #{$rightWidth-explorerCrossFilter} - 30px);
	
	box-sizing: border-box;
	padding-left: 12px;
}

.explorerCrossFilter--right{
	position: fixed;
	top: $height-explorerTopbar;
	right: 0px;
	bottom: 0px;
	width: $rightWidth-explorerCrossFilter;	
}



// temp

.explorerCrossFilter-table{
	padding-bottom: 120px;
}

.explorerCrossFilter-table-header{
	font-size: 16px;
	font-weight: $lato-heavy;
	color: $textBlue;
	margin-left: 10px;
	margin-top: 20px;	
	margin-bottom: 10px;

	cursor: default;
}


.explorerCrossFilter-table-header--loading{
	color: $textLight;
	font-size: 24px;
	margin-left: 120px;
	margin-top: 80px;
}