

.repoReleaseModal-visual-pageHeader{
	display: flex;
	padding-top: 34px;
	padding-right: 24px;
	padding-left: 24px;
	padding-bottom: 20px;
	box-sizing: border-box;

	height: $repoReleaseModal-visual-pageHeaderHeight;

	position: absolute;
	top: 0px;
	left: 0px;
	right: 0px;

	user-select: none;
	pointer: default;
}

.repoReleaseModal-visual-pageHeader-imageContainer{
	flex-grow: 0;
	flex-shrink: 0;

	
	width: 100px;
	height: 100px;
	border-radius: 5px;
	overflow: hidden;
}

.repoReleaseModal-visual-pageHeader-imageContainer img{
	width: 100px;
	height: 100px;
}

.repoReleaseModal-visual-pageHeader-right{
	margin-left: 10px;

	min-height: 100px;
	position: relative;
}

.repoReleaseModal-visual-pageHeader-right-title{
	font-size: 24px;
	font-weight: $effra-bold;

	color: $textBlackDark;

	line-height: 1.05;

	margin-top: 4px;
	margin-bottom: 5px;
}

.repoReleaseModal-visual-pageHeader-right-desc{
	font-size: 14px;

	font-weight: $effra-medium;
	color: $textMid;
}

.repoReleaseModal-visual-pageHeader-right-buttonArray{
	display: flex;
	position: absolute;
	left: 0px;
	bottom: 0px;
}

.repoReleaseModal-visual-pageHeader-right-buttonArray-btn{
	height: 14px;
	border-radius: 3px;
	background: $gray-15;
	border: 1px solid $gray-13;
	margin-right: 5px;
}

