.switchPlanModalContainer{
	position: fixed;
	z-index: 9999;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	background: rgba(0, 0, 0, 0.5);

	display: flex;
	align-items: center;
	justify-content: center;

}
.switchPlanModalContainer .switchPlanModal{
	width: 600px;
	height: 450px;
	
	position: relative;
	overflow: hidden;

	background: rgb(252,252,255);

	border-radius: 10px;
	padding-left: 44px;
	padding-right: 100px;
	padding-top: 36px;

	box-sizing: border-box;

  border: 1px solid rgb(204,204, 216);
  box-shadow: 0px 0px 2px 0px rgba(0,0,0,0.12), 0px 3px 4px 0px rgba(0,0,0,0.06);

  user-select: none;
	cursor: default;
}

.switchPlanModal-header{
	font-size: 28px;
	font-weight: $lato-heavy;
	margin-bottom: 24px;
	color: $textBlackDark;
}

.switchPlanModal-changeSummary-header{
	font-size: 19px;
	font-weight: $lato-bold;
	color: $textBlackDark;
	margin-top: 24px;
	margin-bottom: 6px;
}

.switchPlanModal-changeSummary-text{
	font-size: 15px;
	font-weight: $lato-medium;
	color: $textBlack;
	line-height: 19px;
}



.switchPlanModal-actionRow{
	display: flex;
	
	position: absolute;
	left: 44px;
	right: 100px;
	bottom: 34px;
}

.switchPlanModal-actionRow button{
	font-weight: $lato-bold;
	letter-spacing: 0.005em;
	cursor: pointer;
}

.switchPlanModal-actionRow button.switchbilling{
	flex-grow: 1;
	margin-right: 14px;
}

.switchPlanModal-actionRow button.cancel{
	width: 160px;
	flex-grow: 0;
	flex-shrink: 0;
}


//
// TOGGLE BOXES
// 

.switchPlanModalToggleBox{
	display: flex;
	width: 100%;
	background: $white;
	margin-bottom: 12px;
	padding-top: 10px;
	height: 64px;

	box-sizing: border-box;

	padding-left: 46px;
	padding-right: 18px;

	box-sizing: border;

	position: relative;

	box-shadow: 0px 2px 4px 0px rgba(0,0,40,0.05);

	border-radius: 5px;

	cursor: pointer;
}

.switchPlanModalToggleBox.switchPlanModalToggleBox--selectedToggle{
	border: 1px solid $textBlue;
}

.switchPlanModalToggleBox.switchPlanModalToggleBox--unselectedToggle{
	border: 1px solid $lineDark;
	opacity: 0.9;
}

.switchPlanModalToggleBox.switchPlanModalToggleBox--unselectedToggle:hover{
	border: 1px solid $gray-11;
	opacity: 1;
}

.switchPlanModalToggleBox-radio{
	position: absolute;
	left: 20px;
	top: 16px;
	width: 16px;
	height: 16px;
	border-radius: 8px;

	
	box-sizing: border-box;
}


.switchPlanModalToggleBox--selectedToggle
.switchPlanModalToggleBox-radio{
	border: 1px solid darken($blue, 10%);
	background: $blue;

	display: flex;
	align-items: center;
	justify-content: center;
	box-sizing: border-box;
}

.switchPlanModalToggleBox--selectedToggle
.switchPlanModalToggleBox-radio
.switchPlanModalToggleBox-radio-inner{
	width: 8px;
	height: 8px;
	border-radius: 50%;
	background: $white;
	border: 1px solid darken($blue, 10%);
	box-sizing: border-box;
}

.switchPlanModalToggleBox--unselectedToggle
.switchPlanModalToggleBox-radio{
	border: 1px solid $lineDark;
	background: $white;
	box-sizing: border-box;
}


.switchPlanModalToggleBox-left{
	flex-grow: 1;
}
.switchPlanModalToggleBox-left-headline{
	font-size: 18px;
	margin-top: 3px;
	font-weight: $lato-heavy;
	color: $textBlackDark;
	margin-bottom: 2px;
}

.switchPlanModalToggleBox-left-subline{
	font-size: 15px;
	font-weight: $lato-bold;
	color: $textMid;
}

.switchPlanModalToggleBox--selectedToggle
.switchPlanModalToggleBox-left-subline{
	color: $textBlue;
}
	
.switchPlanModalToggleBox-right{
	text-align: right;
}

.switchPlanModalToggleBox-right-mainPrice{
	font-size: 22px;
	font-weight: $lato-bold;
	color: $textBlack;
	margin-bottom: 2px;
}

.switchPlanModalToggleBox-right-mainPrice-period{
	font-size: 14px;
	font-weight: $lato-medium;
	color: $textMid;
}

.switchPlanModalToggleBox-right-equivalentPrice{
	font-size: 14px;
	font-weight: $lato-medium;
	color: $textMid;
}

.switchPlanModalToggleBox-right-equivalentPrice-period{
	color: $textMid;
}


.switchPlanModalToggleBox--yearly
.switchPlanModalToggleBox-right-mainPrice{
	margin-right: 7px;
}

.switchPlanModalToggleBox--monthly
.switchPlanModalToggleBox-right-equivalentPrice{
	margin-right: 7px;
}