.liveView-toolbar{
  user-select: none;
  cursor: default;
  position: relative;
}

.liveView-toolbar-liveStatusIndicator{
  margin-left: 20px;

  width: 48px;
  height: 24px;
  margin-right: 16px;
  flex-grow: 0;

  color: $white;
  font-weight: $lato-bold;
  font-size: 15px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  position: relative;
  line-height: 1.5;
  text-align: center;

  cursor: default;
  user-select: none;
}

.liveView-toolbar-liveStatusIndicator .sectionMarker{
  position: absolute;
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;
  border-radius: 3px;
}

.liveView-toolbar-liveStatusIndicator--spacer{
  background: $invisible;
  width: 10px;
  margin-right: auto;
}

.liveView-toolbar-sectionNameContainer {
  margin-right: auto;
  display: flex;
  align-items: center;
}

.liveView-toolbar-sectionNameContainer--liveView {
  max-width: calc(100vw - 580px);
}

.liveView-toolbar-sectionNameContainer--liveViewHybrid {
  max-width: calc((100vw - 230px)/2 - 85px);
}

.liveView-toolbar-sectionName{
  font-size: 22px;
  font-weight: $lato-heavy;
  color: $textBlack;
  line-height: 1.4;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.liveView-toolbar .liveView-sectionDataOwnerLabel {
  height: 30px;
  margin-left: 10px;
}

// BUTTONS

button.liveView-toolbar-btn{
  height: 100%;

  border: none;
  box-shadow: none;
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;
  background: none;

  box-sizing: border-box;

  flex-grow: 0;
  flex-shrink: 0;

}

button.liveView-toolbar-btn.liveView-toolbar-btn--minimize{
  width: 48px;
}

button.liveView-toolbar-btn.liveView-toolbar-btn--close{
  width: calc(48px + 10px);
  padding-right: 10px;
}







button.liveView-toolbar-btn.liveView-toolbar-btn--close .icon{
  width: 20px;
  height: 20px;
  opacity: 0.6;
  fill: $gray-4;
}

button.liveView-toolbar-btn.liveView-toolbar-btn--minimize .icon{
  height: 27px;
  width: 27px;
  opacity: 0.6;
  fill: $gray-4;
}

button.liveView-toolbar-btn--minimize .icon{
  fill: $gray-6;
  transition: all 100ms cubic-bezier(.2, .3, .25, .9);
}

button.liveView-toolbar-btn:hover .icon{
  opacity: 1;
  transform: scale(1.05);
  transition: all 150ms cubic-bezier(.2, .3, .25, .9);
}

button.liveView-toolbar-btn:active{
  background: rgba(0,0,25,0.04);
}

button.liveView-toolbar-btn:active .icon{
  fill: $gray-6;
  opacity: 1;
  transform: scale(1.05);
}



// to do: clean up tooltips
button.liveView-toolbar-btn.tooltip:after{
  margin-top: -6px;
  box-shadow: none;
  background: $colorBG-fullScreen-controls;
  color: $white;
}

button.liveView-toolbar-btn.liveView-toolbar-btn--close.tooltip:after{
  margin-left: -6px; // sorry, bad hack to align
}



// DISPLAY OPTION BUTTON

button.btn.btn--large.btn--white.display-options,
button.btn.btn--large.btn--white.student-list{
  display: flex;

  height: 30px;

  align-items: center;
  justify-content: center;

  box-sizing: border-box;


  border: none;
  border-radius: 4px;

  background: rgba(0,0,40,0.03);

  font-weight: $lato-semibold;
  color: $textMid;
  font-size: 14.5px;
  letter-spacing: 0.01em;

  cursor: pointer;
}

button.btn.btn--large.btn--white.display-options svg.icon,
button.btn.btn--large.btn--white.student-list svg.icon{
  display: none; // only displayed at narrow widths
}

button.btn.btn--large.btn--white.display-options{
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}

button.btn.btn--large.btn--white.student-list{
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}

button.btn.btn--large.btn--white.display-options:hover,
button.btn.btn--large.btn--white.student-list:hover{
  color: $textBlackDark;
  background: rgba(0,0,40,0.04);
}

button.btn.btn--large.btn--white.display-options{
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 10px; // slightly hacky but don't want to move when minimized is added
  width: 135px;
  min-width: 135px;
}


button.btn.btn--large.btn--white.display-options ~ .dropdownMenu.position--left{
  left: -200px;
}

button.btn.btn--large.btn--white.student-list{
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 147px; // slightly hacky but don't want to move when minimized is added
  width: 110px;
  min-width: 110px;
}

button.btn.btn--large.btn--white.student-list ~ .dropdownMenu.position--left{
  left: -325px;
}

.liveView-toolbar .dropdownMenu{
  width: 250px;
}

.liveView-toolbar .dropdownMenu.direction--down{
  top: 20px;
}




@media only screen and (max-width : 1240px) {
  .liveView-toolbar--hybrid button.btn.btn--large.btn--white.display-options,
  .liveView-toolbar--hybrid button.btn.btn--large.btn--white.student-list{
    font-size: 0px;
    color: $invisible;
    width: 40px;
    min-width: 40px;
    padding: 0px;
  }
  .liveView-toolbar--hybrid button.btn.btn--large.btn--white.student-list{
    right: 52px;
  }
  .liveView-toolbar--hybrid button.btn.btn--large.btn--white.display-options svg.icon,
  .liveView-toolbar--hybrid button.btn.btn--large.btn--white.student-list svg.icon{
    display: block;
    fill: $gray-5;
    opacity: 0.6;
    margin-right: 0px;
    width: 16px;
    height: 16px;
  }
  .liveView-toolbar--hybrid button.btn.btn--large.btn--white.student-list svg.icon{
    width: 14px;
    height: 14px;
  }
  .liveView-toolbar--hybrid button.btn.btn--large.btn--white.display-options svg.icon{
    width: 18px;
    height: 18px;
  }
  .liveView-toolbar--hybrid button.btn.btn--large.btn--white.display-options:hover svg.icon,
  .liveView-toolbar--hybrid button.btn.btn--large.btn--white.student-list:hover svg.icon{
    opacity: 1;
  }
  .liveView-toolbar--hybrid button.btn.btn--large.btn--white.student-list ~ .dropdownMenu.position--left{
    left: -205px;
  }
  .liveView-toolbar--hybrid button.btn.btn--large.btn--white.display-options ~ .dropdownMenu.position--left{
    left: -160px;
  }
}



// .liveView-toolbar {
// 	left: -605px;
// }

// $width-liveView-dropdownMenuItem: 180px;

// .liveView-toolbar .dropdownMenu{
//  	width: 620px;
//  	padding-top: 10px;
//  	height: 260px;
//  	overflow: hidden;
//  	display: flex;
//  	flex-direction: column;
//  	flex-wrap: wrap;
//  	padding-left: 12px;
//  	padding-right: 12px;
// }


// .liveView-toolbar .dropdownMenu .dropdownSelect--container{
//  	width: $width-liveView-dropdownMenuItem;
//  	margin: 8px;
// }

// .liveView-toolbar .dropdownMenu .dropdownSelect--container
// .dropdownSelect-header{
// 	padding-left: 10px;
// }

// .liveView-toolbar .dropdownMenu .dropdownSelect--container
// .dropdownMenu-item.is--selectItem{
// 	padding-left: 10px;
// 	border-radius: 2px;
// }

.liveView-toolbar .dropdownMenu .dropdownSelect--container
.dropdownMenu-item.is--selectItem.is--inactive:not(:hover){
  opacity: 0.75;
}


// HYBRID COUNTDOWN CONTAINER

.liveView-toolbar-hybridCountdownContainer{
  width: 230px;
  height: 100%;
  // background: $tempr;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.liveView-toolbar-hybridCountdownContainer-scanStopWatchContainer{
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;

  margin: auto;

  width: 96px;
  height: 30px;

  z-index: 5;
}


// STOPWATCH
.liveView-toolbar--hybrid .liveView-toolbar-hybridCountdownContainer:hover
.liveView-toolbar-hybridCountdownContainer-scanStopWatchContainer{
   visibility: hidden;
}



//


// //

// .liveViewContainer:-webkit-full-screen .liveView-toolbar{
// 	background: $bgColor-liveView;
// 	border-bottom: $border-navBar;
// 	box-shadow: $shadow-navBar;
// }

// // SHOW AND HIDE WHEN FULL-SCREEN
// .liveViewContainer:-webkit-full-screen .liveView-toolbar{

// 	position: fixed;
// 	top: 0px;
// 	left: 0px;
// 	width: 100%;

// 	z-index: 8000;
// }

// //


// .liveViewContainer:-webkit-full-screen
// .liveView-toolbar--fullScreenHidden {
// 	opacity: 0;
// 	transform: translateY(-48px);
// 	transition: all 500ms cubic-bezier(.2, .3, .25, .9);
// }


// .liveViewContainer:-webkit-full-screen
// .liveView-toolbar--fullScreenVisible {
// 	opacity: 1;
// 	transform: translateY(0px);
// 	transition: all 100ms cubic-bezier(.2, .3, .25, .9);
// }
