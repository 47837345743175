//
// DETAIL PANEL
//

.creatorsList-detailPanel-header{
	padding-bottom: 14px;
	border-bottom: 1px solid $gray-125;

	display: flex;

	cursor: default;

	position: relative;
}

.creatorsList-detailPanel-header--left{
	flex-grow: 1;
	flex-shrink: 1;
}

.creatorsList-detailPanel-header--right{
	flex-grow: 0;
	flex-shrink: 0;

	position: relative;
}

.creatorsList-detailPanel-header-creatorName{
	font-size: 23px;
	font-weight: $lato-heavy;
	color: $textBlack;

	margin-bottom: 5px;

	display: flex;
	align-items: flex-end;
}

// PAYPAL BADGE

.creatorsList-detailPanel-header-payPalBadge,
.creatorsList-detailPanel-header-proBadge{
	font-size: 14px;
	font-weight: $lato-semibold;
	text-transform: uppercase;
	color: $white;

	height: 18px;


	display: flex;
	align-items: center;
	justify-content: center;

	
	padding-right: 6px;
	padding-left: 6px;
	border-radius: 3px;

	margin-left: 10px;
	margin-bottom: 3px;
}

.creatorsList-detailPanel-header-proBadge{
	margin-left: auto;
}

.creatorsList-detailPanel-header-payPalBadge--active{
	background: $color-o85;
}

.creatorsList-detailPanel-header-payPalBadge--inactive{
	background: $red;
}

.creatorsList-detailPanel-header-proBadge--active{
	background: $blue;
}

.creatorsList-detailPanel-header-proBadge--inactive{
	background: $gray-13;
	color: $gray-2;
}

//

.creatorsList-detailPanel-header-submissionID{
	position: absolute;
	top: 5px;
	left: -47px;
	user-select: none;
	cursor: default;
	width: 40px;
	text-align: right;
	font-size: 18px;
	font-weight: $lato-heavy;

	color: lighten($textLight, 20%);

}

// 

.creatorsList-detailPanel-header-author{
	display: flex;
	align-items: baseline;
	margin-bottom: 6px;
}

// a.creatorsList-detailPanel-header-author-name,
// a.creatorsList-detailPanel-header-author-name:visited{
// 	font-size: 15.5px;
// 	font-weight: $lato-bold;
// 	color: $textBlack;
// 	margin-right: 5px;
// }

// a.creatorsList-detailPanel-header-author-name:hover{
// 	color: $blue-bold;
// 	text-decoration: underline !important;
// }

// a.creatorsList-detailPanel-header-author-email,
// a.creatorsList-detailPanel-header-author-email:visited{
// 	font-size: 15px;
// 	font-weight: $lato-semibold;
// 	color: $textLight;
// 	margin-bottom: 4px;
// }

// a.creatorsList-detailPanel-header-author-email:hover{
// 	color: $textDark;
// 	text-decoration: underline !important;
// }

//

.creatorsList-detailPanel-header-metadata{
	font-size: 14.5px;
	font-weight: $lato-semibold;
	color: $textMid;
	margin-bottom: 4px;
}

a.creatorsList-detailPanel-header-url,
a.creatorsList-detailPanel-header-url:visited,
.creatorsList-detailPanel-header-url--noLink{
	display: block;

	margin-left: -20px;
	padding-left: 20px;
	
	padding-top: 2.5px;
	padding-bottom: 2.5px;
	padding-right: 5px;
	// background: $tempg;

	position: relative;

	font-size: 14px;
	font-weight: $lato-semibold;
	color: $blue-bold;

	cursor: pointer;
}

a.creatorsList-detailPanel-header-url--authorPage,
a.creatorsList-detailPanel-header-url--authorPage:visited,
a.creatorsList-detailPanel-header-url--adminConsoleURL,
a.creatorsList-detailPanel-header-url--adminConsoleURL:visited{
	color: $textLight;
}

a.creatorsList-detailPanel-header-url:hover{
	color: darken($blue-bold, 5%);
	text-decoration: underline !important;
}

a.creatorsList-detailPanel-header-url--authorPage:hover,
a.creatorsList-detailPanel-header-url--adminConsoleURL:hover{
	color: $textMid;
	text-decoration: none !important;
}

.creatorsList-detailPanel-header-url--noLink{
	color: lighten($textLight, 10%);
	cursor: default;
}


.creatorsList-detailPanel-header-url-icon{
	position: absolute;
	top: 3px;
	left: 4px;
}

a.creatorsList-detailPanel-header-url--frontID
.creatorsList-detailPanel-header-url-icon{
	top: 4px;
}

.creatorsList-detailPanel-header-url-icon svg{
	height: 10px;
	width: 10px;
	fill: $blue-bold;
	opacity: 0.5;
}





//PAYOUTS

.creatorsList-detailPanel-actionArea-section{
	width: 100%;
	border-bottom: 1px solid $lineMid;
	padding-top: 15px;
	padding-bottom: 20px;
	cursor: default;
}

.creatorsList-detailPanel-actionArea-section-header{
	font-size: 15px;
	font-weight: $lato-bold;
	color: $textBlack;

	margin-bottom: 10px;
}
	

.creatorsList-detailPanel-actionArea-section--awards-section{
	margin-top: 10px;
	box-sizing: border-box;	
	width: 100%;
}

.creatorsList-detailPanel-actionArea-section--awards-section-header{
	font-size: 13px;
	font-weight: $lato-semibold;
	color: $textMid;
	text-transform: uppercase;
}

.creatorsList-detailPanel-actionArea-section--awards-section-awards{
	display: flex;
	flex-wrap: wrap;
	margin-left: -10px;
}



// AWARD ITEM

.creatorsList-detailPanel-award{
	font-size: 13px;
	margin-bottom: 10px;
	cursor: default;
	width: 250px;
	overflow: hidden;
	border: 1px solid $lineMid;
	border-bottom: 1px solid darken($lineMid, 5%);
	margin: 5px;
	padding: 5px;
	border-radius: 2px;
}

a.creatorsList-detailPanel-award-title{
	font-weight: $lato-bold;
	color: $black;
}

a.creatorsList-detailPanel-award-title:hover{
	color: $blue-bold;
	text-decoration: underline !important;
}

.creatorsList-detailPanel-award-occasionReason{
	font-size: 12.5px;
	color: $textMid;
}

.creatorsList-detailPanel-award-amount{
	font-size: 12.5px;
	margin-top: 4px;
	margin-bottom: 2px;
	font-weight: $lato-semibold;
}

.creatorsList-detailPanel-award button.btn.btn--small.btn--blue.activateawardbutton{

	cursor: pointer;

	height: 24px;
	border-radius: 2px;
	font-weight: $lato-semibold;

	line-height: 1.6;

	width: 115px;

	margin-top: 3px;
}

.creatorsList-detailPanel-award button.btn.btn--small.btn--blue.activateawardbutton:hover{
	font-weight: $lato-bold;
}


// PAYOUTS


.creatorsList-detailPanel-actionArea-section--payouts-columnContainer{
	display: flex;
}

.creatorsList-detailPanel-actionArea-section--payouts-col{
	width: 300px;
}

.creatorsList-detailPanel-actionArea-section--payouts-col-header{
	font-size: 13px;
	font-weight: $lato-semibold;
	color: $textMid;
	text-transform: uppercase;
	margin-bottom: 4px;
}

.creatorsList-detailPanel-actionArea-section--payouts-col-value{
	font-size: 15px;
	font-weight: $lato-bold;
	color: $textBlack;
	margin-bottom: 5px;
}

.creatorsList-detailPanel-actionArea-section--payouts-col--outstanding button.btn.btn--mid.btn--blue{
	font-weight: $lato-bold;
	cursor: pointer;
}



/// EMAIL PREVIEW

// ///
// /// EMAIL TEMPLATER
// ///

.creatorsList-detailPanel-actionArea-emailTemplater{
	display: flex;
}

.creatorsList-detailPanel-actionArea-emailTemplater-controls{
	flex-grow: 0;
	flex-shrink: 0;
	padding-right: 25px;
	height: 480px;
	position: relative;
}

.creatorsList-detailPanel-actionArea-emailTemplater-previewContainer{
	flex-grow: 1;
	flex-shrink: 1;
	padding-right: 40px;
}

.creatorsList-detailPanel-actionArea-emailTemplater-controls-februaryPayout{

}

.creatorsList-detailPanel-actionArea-emailTemplater-controls-februaryPayout-header{
	font-size: 13px;
	font-weight: $lato-semibold;
	margin-bottom: 5px;
}

.creatorsList-detailPanel-actionArea-emailTemplater-controls-februaryPayout input{
	margin-top: 5px;
	margin-bottom: 5px;
}


button.creatorsList-detailPanel-actionArea-btn--frontButton{
	// position: absolute;
	// bottom: 10px;
	// width: 185px;
	// left: 0px;
}
button.creatorsList-detailPanel-actionArea-btn--frontButton svg{
	height: 15px;
	width: 15px;
	margin-right: 5px;
	fill: $blue-bold;
}

button.creatorsList-detailPanel-actionArea-btn--disabled svg{
	fill: $gray-1125;
}


// //
// // EMAIL PREVIEW
// //

.creatorsList-detailPanel-actionArea-emailTemplater-preview{
	border-left: 1px solid $gray-13;
	padding-left: 30px;
	height: 100%;
}

.creatorsList-detailPanel-actionArea-emailTemplater-preview-title{
	font-size: 12px;
	font-weight: $lato-semibold;
	text-transform: uppercase;
	color: lighten($textLight, 5%);
	margin-bottom: 4px;
	user-select: none;
}

.creatorsList-detailPanel-actionArea-emailTemplater-preview-section{
	padding-top: 6px;
	padding-bottom: 6px;
	cursor: default;
}

.creatorsList-detailPanel-actionArea-emailTemplater-preview-section-label{
	font-size: 10px;
	font-weight: $lato-bold;
	color: lighten($textLight, 20%);
	user-select: none;
	text-transform: uppercase;
	padding-bottom: 4px;
}

.creatorsList-detailPanel-actionArea-emailTemplater-preview-section--subject{
	padding-bottom: 0px;
}

.creatorsList-detailPanel-actionArea-emailTemplater-preview-section--body{
	padding-top: 0px;
}

.creatorsList-detailPanel-actionArea-emailTemplater-preview-section--body
.creatorsList-detailPanel-actionArea-emailTemplater-preview-section-label{
	margin-top: 15px;
}


.creatorsList-detailPanel-actionArea-emailTemplater-preview-section--subject
.creatorsList-detailPanel-actionArea-emailTemplater-preview-section-contents{
	font-size: 13.5px;
	font-weight: $lato-bold;
	color: $textBlack;
}

.creatorsList-detailPanel-actionArea-emailTemplater-preview-section--body
.creatorsList-detailPanel-actionArea-emailTemplater-preview-section-contents{
	font-size: 13px;
	font-weight: $lato-medium;
	color: $textBlack;
}

.creatorsList-detailPanel-actionArea-emailTemplater-preview-section-contents
a {
	text-decoration: underline !important;
	color: $blue-bold !important;
} 


// //
// // EMAIL SENT CONTROLS
// //

// .creatorsList-detailPanel-actionArea-emailSentControls{
// 	position: absolute;
// 	top: 24px;
// 	right: 0px;
// 	// background: $tempr;
// 	//height: 100px;

// 	display: flex;
// 	align-items: center;
// }

// .creatorsList-detailPanel-actionArea-emailSentControls:hover button{
// 	opacity: 0.5;
// }

// .creatorsList-detailPanel-actionArea-emailSentControls:hover button:hover{
// 	opacity: 1;
// }

// .creatorsList-detailPanel-actionArea-emailSentControls button:first-child{
// 	border-top-right-radius: 0px;
// 	border-bottom-right-radius: 0px;
// 	margin-right: -1px;
// }

// .creatorsList-detailPanel-actionArea-emailSentControls button:last-child{
// 	border-top-left-radius: 0px;
// 	border-bottom-left-radius: 0px;
// }

// .creatorsList-detailPanel-actionArea-emailSentControls button:first-child:hover b{
// 	color: $blue-bold;
// }

// .creatorsList-detailPanel-actionArea-emailSentControls button:last-child:hover b{
// 	color: $red--notification;
// }



// SUBMISSION

// .creatorsList-detailPanel-header-status{
	
// 	background: $blue-bold;
// 	color: $white;
// 	font-weight: $lato-semibold;
// 	text-transform: uppercase;
// 	font-size: 13.5px;
// 	letter-spacing: 0.02em;

// 	padding-left: 7px;
// 	padding-right: 7px;
// 	padding-top: 4px;
// 	padding-bottom: 4px;

// 	border-radius: 3px;


// 	flex-grow: 0;
// 	flex-shrink: 0;
// }


// .creatorsList-detailPanel-header-status--in-review{
// 	background: $blue;
// }

// .creatorsList-detailPanel-header-status--hidden{
// 	background: $gray-7;
// }

// .creatorsList-detailPanel-header-status--approved{
// 	background: $green;
// }


// // ASSIGNEE

// .creatorsList-detailPanel-header-assignee{
// 	position: absolute;
// 	bottom: 14px;
// 	right: 0px;
// 	width: auto;

// 	border: 1px solid $gray-125;
// 	color: lighten($textMid, 15%);
// 	border-radius: 4px;
// 	padding-top: 2px;
// 	padding-bottom: 2px;
// 	padding-right: 5px;
// 	padding-left: 5px;

// 	font-size: 12px;
// 	text-transform: uppercase;
// 	font-weight: $lato-bold;
// 	letter-spacing: 0.01em;
// }


// //
// // ACTION AREA
// //

// // ACTION AREA HEADER

// .creatorsList-detailPanel-actionArea{
// 	padding-top: 20px;
// }

// .creatorsList-detailPanel-actionArea-header{
// 	position: relative;
// 	cursor: default;
// 	padding-bottom: 10px;
// }
// .creatorsList-detailPanel-actionArea-header-nextSteps,
// .creatorsList-detailPanel-actionArea-header-label{
// 	font-size: 13.5px;
// 	font-weight: $lato-bold;
// 	text-transform: uppercase;
// 	color: $textBlack;
// }

// .creatorsList-detailPanel-actionArea-header-nextSteps{
// 	margin-bottom: 2px;
// }

// .creatorsList-detailPanel-actionArea-header-label{
// 	margin-bottom: 5px;	
// }

// .creatorsList-detailPanel-actionArea-header-description{
// 	font-size: 13px;
// 	font-weight: $lato-medium;
// 	color: $textDark;
// }

// .creatorsList-detailPanel-actionArea-header-nextSteps{
// 	color: $textLight;	
// }

// .creatorsList-detailPanel-actionArea--submitted .creatorsList-detailPanel-actionArea-header-nextSteps,
// .creatorsList-detailPanel-actionArea--in-review-pre-contact .creatorsList-detailPanel-actionArea-header-nextSteps{
// 	color: $blue;
// }



// .creatorsList-detailPanel-actionArea-header-icon{
// 	position: absolute;
// 	top: 14px;
// 	left: -16px;
// }

// .creatorsList-detailPanel-actionArea-header-icon svg{
// 	width: 9px;
// 	height: 9px;
// 	fill: $red--notification;
// }

// .creatorsList-detailPanel-actionArea--in-review-post-contact .creatorsList-detailPanel-actionArea-header-icon,
// .creatorsList-detailPanel-actionArea--cancelled .creatorsList-detailPanel-actionArea-header-icon,
// .creatorsList-detailPanel-actionArea--withdrawn .creatorsList-detailPanel-actionArea-header-icon,
// .creatorsList-detailPanel-actionArea--approved .creatorsList-detailPanel-actionArea-header-icon{
// 	display: none;
// }

// //
// // ACTION AREA
// //
// .creatorsList-detailPanel-actionArea{
// 	position: relative;
// }

// // SELECT CONTROL

// .creatorsList-detailPanel-actionArea
// .featureModal-selectControl{
// 	border-radius: 3px;
// 	padding-left: 5px;
// 	padding-right: 5px;
// 	width: 220px;
// 	cursor: pointer;
// 	padding-top: 2px;

// 	// margin-bottom: 4px;
// 	// background: $tempr;
// }

// .creatorsList-detailPanel-actionArea
// .featureModal-selectControl-checkbox{
// 	margin-top: 4px;
// 	margin-right: 6px;
// }

// .creatorsList-detailPanel-actionArea
// .featureModal-selectControl-radio{
// 	margin-top: 4px;
// 	margin-right: 6px;
// 	height: 14px;
// 	width: 14px;

// }


// .creatorsList-detailPanel-actionArea
// .featureModal-selectControl:hover{
// 	background: transparentize($blue-bold, 0.95);
// }

// .creatorsList-detailPanel-actionArea
// .featureModal-selectControl-label{
// 	font-size: 13px;
// 	font-weight: $lato-bold;
// 	letter-spacing: 0em;
// 	color: $textBlack;
// 	cursor: pointer;

// 	margin-top: 4px;
// }


// .creatorsList-detailPanel-actionArea-emailTemplater-controls-template--hasTemplate
// .featureModal-selectControl--inactive{
// 	opacity: 0.5;
// }

// .creatorsList-detailPanel-actionArea-emailTemplater-controls-template--hasTemplate
// .featureModal-selectControl--inactive:hover{
// 	opacity: 1;
// }


// //
// // GENERAL BUTTON
// //

// button.creatorsList-detailPanel-actionArea-btn{
// 	font-size: 13px;
// 	font-weight: $lato-semibold;
// 	color: $textBlack;	

// 	display: flex;
// 	align-items: center;
// 	justify-content: center;

// 	height: 30px;

// 	padding-left: 12px;
// 	padding-right: 12px;

// 	box-shadow: none;
// 	border: 1px solid $lineDark;
// 	background: $white;
// 	border-radius: 3px;

// 	cursor: pointer;
// }

// button.creatorsList-detailPanel-actionArea-btn b{
// 	font-weight: $lato-bold;
// }

// button.creatorsList-detailPanel-actionArea-btn:hover{
// 	border-bottom: 1px solid $gray-1125;
// 	box-shadow: 0px 2px 4px 0px rgba(0,0,50,0.02);
// }

// button.creatorsList-detailPanel-actionArea-btn--disabled{
// 	opacity: 0.5;
// 	pointer-events: none;
// }

// .creatorsList-detailPanel-actionArea--submitted
// button.creatorsList-detailPanel-actionArea-btn{
// 	margin-top: 10px;
// }


// ///
// /// EMAIL TEMPLATER
// ///

// .creatorsList-detailPanel-actionArea-emailTemplater{
// 	display: flex;
// }

// .creatorsList-detailPanel-actionArea-emailTemplater-controls{
// 	flex-grow: 0;
// 	flex-shrink: 0;
// 	padding-right: 25px;
// 	height: 480px;
// 	position: relative;
// }

// .creatorsList-detailPanel-actionArea-emailTemplater-previewContainer{
// 	flex-grow: 1;
// 	flex-shrink: 1;
// 	padding-right: 40px;
// }

// .creatorsList-detailPanel-actionArea-emailTemplater-controls-guideline{
// 	margin-top: 14px;
// }

// .creatorsList-detailPanel-actionArea-emailTemplater-controls-template-title,
// .creatorsList-detailPanel-actionArea-emailTemplater-controls-guideline-title{
// 	font-size: 12px;
// 	font-weight: $lato-semibold;
// 	text-transform: uppercase;
// 	color: $textMid;
// 	margin-bottom: 4px;
// 	margin-left: 25px;

// 	cursor: default;
// 	user-select: none;
// }

// button.creatorsList-detailPanel-actionArea-btn--frontButton{
// 	position: absolute;
// 	bottom: 10px;
// 	width: 185px;
// 	left: 0px;
// }
// button.creatorsList-detailPanel-actionArea-btn--frontButton svg{
// 	height: 15px;
// 	width: 15px;
// 	margin-right: 5px;
// 	fill: $blue-bold;
// }

// button.creatorsList-detailPanel-actionArea-btn--disabled svg{
// 	fill: $gray-1125;
// }




// //


// .creatorsList-detailPanel-actionArea-buttonRowLabel{
// 	font-size: 13px;
// 	font-weight: $lato-semibold;
// 	color: $textDark;
// }

// .creatorsList-detailPanel-actionArea-buttonRow{
// 	margin-top: 10px;
// 	display: flex;

// 	width: 400px;
// 	align-items: center;
// 	justify-content: space-between;
// }
