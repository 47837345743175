.syncLiveView-toolbar{
	height: 48px;
	width: 100%;

	user-select: none;
	cursor: default;

	display: flex;

	box-sizing: border-box;

	position: relative;
	z-index: 1000; // hope don't need this

	opacity: 1;
}


.syncLiveView-toolbar-left{
	width: $syncLiveView-leftWidth;
	height: 100%;
}


//
// SUMMARY FOR NARROW SCREENS
//

.syncLiveView-toolbar-narrowScreenSummary{
	position: absolute;
	top: 0px;
	left: 10px;
	bottom: 0px;

	//display: flex; // set to flex in media query in parent
	display: none;

	align-items: center;
	justify-content: flex-start;

	width: 250px;
}

@media only screen and (max-width : 1160px) {
	.syncLiveView-toolbar-narrowScreenSummary{
		display: flex;
	}
}

.syncLiveView-toolbar-narrowScreenSummary .sectionMarker{
	width: 31px;
	height: 15px;
	border-radius: 2px;
	font-size: 10px;
	font-weight: $lato-bold;
	color: $white;
	text-transform: uppercase;

	display: flex;
	align-items: center;
	justify-content: center;

	flex-grow: 0;
	flex-shrink: 0;

	user-select: none;
}

.syncLiveView-toolbar-narrowScreenSummary-sectionName{
	font-size: 15px;
	font-weight: $lato-bold;
	color: $textBlack;
	padding-left: 8px;

	flex-grow: 1;
	flex-shrink: 1;

	white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;


}


//

.syncLiveView-toolbar-centerContainer{
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;
	flex-grow: 1;
	flex-shrink: 1;
}

.syncLiveView-toolbar-center{
	position: relative;
	height: 100%;

	// background: $tempr;
}

.syncLiveView-toolbar-right{
	width: $syncLiveView-rightDefaultWidth;
	display: flex;
	align-items: center;
	justify-content: flex-end;
}


@import 'SyncLiveView-Toolbar-CountdownTimer';






//

//
// SET COUNTER AND SCAN STOPWATCH
//

.syncLiveView-toolbar-center-setCounter{
	font-size: 15px;
	//font-weight: $lato-bold;
	font-weight: $lato-semibold;
	color: $textLight;
	// font-weight: $lato-medium;
	display: flex;
	align-items: center;
	justify-content: center;

	position: absolute;
	left: 5px;	
	bottom: 0px;
	top: 0px;

	padding-right: 6px;
	padding-left: 6px;
	padding-top: 4px;
	padding-bottom: 4px;

	border-radius: 4px;
}

.syncLiveView-toolbar-center-scanStopWatchContainer{
	position: absolute;
	top: 0px;
	left: 0px;
	right: 0px;
	bottom: 0px;

	margin: auto;

	width: 96px;
	height: 30px;

	z-index: 5;
}

.syncLiveView-toolbar-center-scanStopWatch{
	width: 100%;
	height: 100%;

	

	border-radius: 4px;

	background: $gray-5;

	display: flex;
	align-items: center;
	justify-content: center;
	letter-spacing: 0.02em;

	color: $white;
	font-size: 17px;
	font-weight: $lato-bold;
}





span.syncLiveView-toolbar-center-scanStopWatch-minutes--zeroMinutes{
	opacity: 0.5;
}

span.syncLiveView-toolbar-center-scanStopWatch-centiseconds{
	opacity: 0.5;
}

.syncLiveView-toolbar-center-scorePillContainer{
	position: absolute;
	

	// INSET INTO SLIDE OPTION
	// right: 7px;
	// bottom: 0px;
	// top: 70px;

	top: 0px;
	bottom: 0px;
	right: 8px;

	display: flex;
	align-items: center;
	justify-content: flex-end;

	width: 100px;
}

.syncLiveView-toolbar-center-scorePillContainer .scorePill{
	transform: scale(1.1);
	transform-origin: 100% 50%;
}



//
// CLASS SAFE TOGGLE

button.syncLiveView-toolbar-viewToggle{

	flex-grow: 0;
	flex-shrink: 0;

	height: 30px;
	width: 36px;

	margin-right: 7px;

	position: relative;

	background: none;
	border: none;
	box-shadow: none;
	outline: none;

	cursor: pointer;
	letter-spacing: 0.008em;

	border-radius: 4px;

	


}


.syncLiveView-toolbar-viewToggle-iconContainer{
	position: absolute;
	top: 0px;
	right: 0px;
	bottom: 0px;
	left: 0px;

	cursor: pointer;

	border-radius: 4px;

	display: flex;
	align-items: center;
	justify-content: center;

	background: rgba(0,0,40,0.03);
}

.syncLiveView-toolbar-viewToggle-iconContainer svg{
	width: 20px;
	height: 20px;
	position: relative;
	top: -2px;
	right: -1px;
	transition: all 100ms ease-in;
}

//
// STATES

.syncLiveView-toolbar-viewToggle-iconContainer--active{
	background: $blue;
}

.syncLiveView-toolbar-viewToggle-iconContainer--active svg{
	fill: white;
}

.syncLiveView-toolbar-viewToggle-iconContainer--inactive{
	background: rgba(0,0,40,0.03);
}

.syncLiveView-toolbar-viewToggle-iconContainer--inactive svg{
	fill: $gray-6;
	opacity: 0.8;
}


button.syncLiveView-toolbar-viewToggle:hover
.syncLiveView-toolbar-viewToggle-iconContainer--inactive{
	background: rgba(0,0,40,0.05);	
}


button.syncLiveView-toolbar-viewToggle:hover
.syncLiveView-toolbar-viewToggle-iconContainer--inactive svg{
	opacity: 1;
}


button.syncLiveView-toolbar-viewToggle:hover
.syncLiveView-toolbar-viewToggle-iconContainer--inactive svg{
	transform: scale(1.03);
}



//


.syncLiveView-toolbar-viewToggle-keyboardTooltip{

	position: absolute;
	bottom: 1px;
	left: -196px;
	width: 192px;


	
	border-radius: 4px;



	font-size: 13px;
  letter-spacing: 0.01em;
  font-weight: $lato-semibold;
	
	background-color: $white;
  color: $textBlack;
  border: 1px solid $lineDark;
  border: none;

  background: #F1F1F6;
	color: $textDark;

  display: flex;
  align-items: center;
  justify-content: center;

  height: 28px;
 
  box-sizing: border-box;

  // background: $tempr;



	opacity: 0;
	transition: opacity 50ms linear;
}

.syncLiveView-toolbar-viewToggle:hover
.syncLiveView-toolbar-viewToggle-keyboardTooltip{
	opacity: 1;
	transition: opacity 100ms linear;
	transition-delay: 800ms;
}




//RIGHT BUTTONS 

button.syncLiveView-toolbar-btn{
	height: 100%;
	
	border: none;
	box-shadow: none;
	cursor: pointer;

	display: flex;
	align-items: center;
	justify-content: center;
	background: none;

	box-sizing: border-box;

	flex-grow: 0;
	flex-shrink: 0;

	// background: $tempr;
}

button.syncLiveView-toolbar-btn.syncLiveView-toolbar-btn--minimize{
	width: 48px;
}

button.syncLiveView-toolbar-btn.syncLiveView-toolbar-btn--close{
	width: calc(48px + 10px);
	padding-right: 10px;
	// background: $tempr;
}


button.syncLiveView-toolbar-btn.syncLiveView-toolbar-btn--close .icon{
	width: 20px;
	height: 20px;
	opacity: 0.6;
	fill: $gray-4;	
}

button.syncLiveView-toolbar-btn.syncLiveView-toolbar-btn--minimize .icon{
	height: 27px;
	width: 27px;
	opacity: 0.6;
	fill: $gray-4;
}

button.syncLiveView-toolbar-btn--minimize .icon{	
	fill: $gray-6;
	transition: all 100ms cubic-bezier(.2, .3, .25, .9);
}

button.syncLiveView-toolbar-btn:hover .icon{
	opacity: 1;
	transform: scale(1.05);
	transition: all 150ms cubic-bezier(.2, .3, .25, .9);
}

button.syncLiveView-toolbar-btn:active{
	background: rgba(0,0,25,0.04);
}

button.syncLiveView-toolbar-btn:active .icon{
	fill: $gray-6;
	opacity: 1;
	transform: scale(1.05);
}


button.syncLiveView-toolbar-btn.tooltip:after{
	margin-top: -6px;
	box-shadow: none;
	background: $colorBG-fullScreen-controls;
	color: $white;
}

button.syncLiveView-toolbar-btn.syncLiveView-toolbar-btn--close.tooltip:after{
	margin-left: -6px; // sorry, bad hack to align
}







// button.syncLiveView-toolbar-btn--classSafe:hover{	
// 	transform: scale(1.05);
// 	opacity: 1;
// 	transition: all 150ms cubic-bezier(.2, .3, .25, .9);
// 	background: $gray-13;
// }


// button.syncLiveView-toolbar-btn--classSafe{
// 	transition: all 100ms cubic-bezier(.2, .3, .25, .9);
// }

// to do: clean up tooltips
// button.syncLiveView-toolbar-btn.tooltip:after{
// 	margin-top: -6px;
// 	box-shadow: none;
// 	background: $colorBG-fullScreen-controls;
// 	color: $white;
// }

// button.syncLiveView-toolbar-btn.syncLiveView-toolbar-btn--close.tooltip:after{
// 	margin-left: -8px; // sorry, bad hack to align
// }
