.liveView-perfectScoreCelebrationContainer{
	position: fixed;
	top: 0px;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 9990;
	background: rgba(255,255,255,0.5);
}


.liveView-perfectScoreCelebration-label{
	
	cursor: default;
	font-size: 8vw;
	font-weight: $lato-bold;
	color: $white;

	z-index: 9999;

	text-align: center;

	width: 25vw;
	height: 10vw;

	background: rgb(0,207,127);
	background: linear-gradient(to bottom, rgb(0,207,127) 0%, rgb(41,215,113) 100%); 
	
	border: 1px solid rgb(40,178,122);

	//box-shadow: 0px 12px 24px 0px rgba(0,0,0,0.04);

	border-radius: 1.2vw;

	position: fixed;
	top: calc(50vh - 5vw);
	left: calc(50vw - 12.5vw);

	transform-origin: center center;
}