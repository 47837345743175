.account-serviceStatus-sub{
	font-size: 14.5px;
	font-weight: $lato-semibold;
	color: $textMid;
	margin-top: 6px;
	margin-bottom: 6px;
}

.account-serviceStatus-pill{
	display: inline-block;
	
	padding-left: 5px;
	padding-right: 5px;
	height: 16px;
	background: $blue;
	color: $white;
	border-radius: 2px;
	margin-left: 4px;
	letter-spacing: 0.03em;

	line-height: 1.5;
	// margin-top: -2px;

	position: relative;
	top: -1px;

	font-size: 11px;
	font-weight: $lato-semibold;
	text-transform: uppercase;
}

.account-serviceStatus-pill--cancelled{
	background: $gray-7;
}

.account-serviceStatus-pill--trial{
	background: $green;
}