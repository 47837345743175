


//
// SUBMISSION
//

.packReview-submissionItem{
	width: 100%;
	
	height: 68px;

	box-sizing: border-box;
	padding-left: 24px;
	padding-top: 8px;
	padding-right: 10px;
	cursor: pointer;	

	position: relative;

	background: white;
}

.packReview-submissionItem--inactive:hover{
	background: rgb(250,250,253);
}


.packReview-submissionItem-bottomBorder{
	position: absolute;
	bottom: 0px;
	left: 24px;
	right: 0px;
	height: 1px;
	background: $lineMid;
}


//
// TOPLINE
//

.packReview-submissionItem-submissionID{
	font-size: 12px;
	font-weight: $lato-semibold;
	position: absolute;
	top: 9px;
	left: 2px;
	width: 18px;
	
	color: lighten($textLight, 5%);
	text-align: right;
	user-select: none;

}

.packReview-submissionItem:hover
.packReview-submissionItem-submissionID{
	color: $textLight;
}

.packReview-submissionItem-topLine{
	display: flex;

	align-items: flex-end;
	margin-bottom: 3px;
}

.packReview-submissionItem-topLine-repoName{
	font-size: 14.5px;
	font-weight: $lato-bold;
	color: $textBlack;

	flex-grow: 0;
	flex-shrink: 1;

	white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

}



.packReview-submissionItem-topLine-status{
	
	background: $blue-bold;
	color: $white;
	font-weight: $lato-semibold;
	text-transform: uppercase;
	font-size: 9.5px;
	letter-spacing: 0.02em;

	padding-left: 4px;
	padding-right: 4px;
	padding-top: 1.5px;
	padding-bottom: 1.5px;

	margin-left: 5px;
	margin-bottom: 2px;

	border-radius: 2px;


	flex-grow: 0;
	flex-shrink: 0;
}


.packReview-submissionItem-topLine-status--in-review{
	background: $blue;
}

.packReview-submissionItem-topLine-status--hidden{
	background: $gray-7;
}

.packReview-submissionItem-topLine-status--canceled{
	background: $red;
}

.packReview-submissionItem-topLine-status--approved{
	background: $green;
}



.packReview-submissionItem-topLine-date{	

	flex-grow: 0;
	flex-shrink: 0;

	min-width: 30px;	
	text-align: right;
	padding-left: 5px;

	margin-left: auto;

	margin-bottom: 2px;

	font-size: 12px;
	font-weight: $lato-medium;
	color: $textLight;
}



//
//
//


.packReview-submissionItem-author{
	font-size: 13.5px;
	font-weight: $lato-semibold;
	color: $textDark;
	margin-bottom: 3px;
}

.packReview-submissionItem-metadata{
	font-size: 13px;
	font-weight: $lato-semibold;
	color: $textMid;
}

.packReview-submissionItem-assignee{
	position: absolute;
	bottom: 8px;
	right: 8px;
	width: auto;
	border: 1px solid $gray-125;
	color: lighten($textMid, 15%);
	border-radius: 3px;
	padding-top: 1.5px;
	padding-bottom: 1.5px;
	padding-right: 4px;
	padding-left: 4px;

	font-size: 11px;
	text-transform: uppercase;
	font-weight: $lato-bold;
	letter-spacing: 0.01em;
}

// a.packReview-submissionItem-viewPack,
// a.packReview-submissionItem-viewPack:hover,
// a.packReview-submissionItem-viewPack:visited{
// 	padding-left: 8px;
// 	padding-right: 8px;

// 	margin-left: -8px;
// 	padding-top: 2px;
// 	padding-bottom: 2px;

// 	// background: $tempr;
// 	border-radius: 2px;

// 	font-size: 12px;
// 	font-weight: $lato-bold;
// 	color: $textBlue;

// 	cursor: pointer;
// 	user-select: none;
// }

// a.packReview-submissionItem-viewPack:hover{
// 	background: rgba(0,0,40,0.08);
// }

// //
// ACTIVE STATE
//

.packReview-submissionItem--active{
	background: $blue;
	position: relative;
	z-index: 5;
}

.packReview-submissionItem--active .packReview-submissionItem-topLine-repoName{
	color: white;
}

.packReview-submissionItem--active .packReview-submissionItem-topLine-status{
	background: rgba(0,0,40,0.2);
}

.packReview-submissionItem--active .packReview-submissionItem-topLine-date{
	color: $white;
	opacity: 0.75;
}

.packReview-submissionItem--active .packReview-submissionItem-author{
	color: $white;
	opacity: 0.9;
}

.packReview-submissionItem--active .packReview-submissionItem-metadata{
	color: $white;
	opacity: 0.5;
}


.packReview-submissionItem--active .packReview-submissionItem-bottomBorder{
	background: darken($blue-bold, 10%);
}

.packReview-submissionItem--active .packReview-submissionItem-assignee{
	border: 1px solid transparentize($white,0.4);
	color: $white;
}

.packReview-submissionItem--active .packReview-submissionItem-submissionID,
.packReview-submissionItem--active:hover .packReview-submissionItem-submissionID{
	color: transparentize($white, 0.25);
}
