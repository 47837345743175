$bg-cellHover: rgb(250,250,255);
$minWidth-cellRow-date--library: 145px;
$minWidth-cellRow-date--recent: 165px;
$minWidth-cellRow-date--repo: 205px;
$height-cellRow: 45px;
$width-cellRowLeft: 35px;
$width-cellRowRight: 35px;
$leftHangingMargin-cellRow: 20px;
$rightHangingMargin-cellRow: 16px;
$width-extendedHangingMargins: calc(100% + #{$leftHangingMargin-cellRow} + #{$rightHangingMargin-cellRow});

@import 'LibraryAndRecent-Cells-DragPreview';

.cellRow{
	// unfortunate amount of logic here but hanging margins are useful for the active blue row
	width: $width-extendedHangingMargins;
	margin-left: calc(-1 * #{$leftHangingMargin-cellRow});
	margin-right: calc(-1 * #{$rightHangingMargin-cellRow});
	padding-left: $leftHangingMargin-cellRow;
	padding-right: $rightHangingMargin-cellRow;
	box-sizing: border-box;

	background: $white;
	border-radius: 3px;
	margin-bottom: 1px;
	cursor: default;
	user-select: none;
}

.cellRow-content{
	height: $height-cellRow;
	width: 100%;
	display: flex;
	box-shadow: 0px 1px 0px 0px $lineMid;	
	position: relative;
}

// CELL COLUMN LAYOUTS

.cellRow-left,
.cellRow-center,
.cellRow-name,
.cellRow-date,
.cellRow-right{
	height: 100%;
}

.cellRow-left{
	flex-grow: 0;
	flex-shrink: 0;
	width: $width-cellRowLeft;
}

.cellRow-center{
	flex-grow: 1;
	display: flex;
	width: 100px;
}

.cellRow-name{	
	flex-grow: 10;
	width: 100px;
}

.library .cellRow-date{
	width: $minWidth-cellRow-date--library;
}

.repo .cellRow-date{
	width: $minWidth-cellRow-date--repo;
}

.recent .cellRow-date{
	width: $minWidth-cellRow-date--recent;
}

.cellRow-date{
	flex-grow: 1;
	flex-shrink: 0;
	padding-left: 15px; // right padding on cell-name doesn't work because of overflow ellipses
}

.cellRow-right{
	width: $width-cellRowRight;
	flex-grow: 0;	
	opacity: 0; // opacity changed later on hover
}

// STICKY DIVIDER HEADER

.stickyDivider.in--library,
.stickyDivider.in--repo{	
	border-bottom: 1px solid $lineMid;
	width: $width-extendedHangingMargins;
	margin-left: calc(-1 * #{$leftHangingMargin-cellRow});
	margin-right: calc(-1 * #{$rightHangingMargin-cellRow});
	padding-left: calc(#{$leftHangingMargin-cellRow} + #{$width-cellRowLeft});
	padding-right: calc(#{$rightHangingMargin-cellRow} + #{$width-cellRowRight});
	box-sizing: border-box;

	padding-top: 6px;
	height: 32px;


	background: $white;

	position: sticky;
	top: calc(#{$height-pageHeader--sticky} + #{$height-topNav});	
	z-index: 5;

	margin-bottom: 0px;
}

.stickyDivider.in--library .sortableColumnHeaders,
.stickyDivider.in--repo .sortableColumnHeaders{
	display: flex;
	width: 100%;
	box-sizing: border-box;
	padding-left: 0px;	
	font-size: 12px;
	font-weight: $lato-semibold;
	color: $textMid;
	text-transform: uppercase;
	letter-spacing: 0.1em;
	line-height: 2.2;

	cursor: default;
	user-select: none;
}

.stickyDivider.in--library .sortableColumnHeaders-name,
.stickyDivider.in--repo .sortableColumnHeaders-name{
	flex-grow: 10;
	display: flex;
	width: 100px;
	//background: $tempr;
} 

.stickyDivider.in--library .sortableColumnHeaders-modified{
	flex-grow: 1;
	width: $minWidth-cellRow-date--library;
	flex-shrink: 0;
}

.stickyDivider.in--repo .sortableColumnHeaders-modified{
	flex-grow: 1;
	width: $minWidth-cellRow-date--repo;
	flex-shrink: 0;
}


// CELL LEFT

.cellRow-left{
	display: flex;
	//justify-content: center;
	align-items: center;
	box-sizing: border-box;
}

.cellRow-left .icon.folder{
	width: 18px;
	height: 18px;
	position: relative; 
	left: 5px;
	top: -2px;
}

.cellRow-left .cellplay {
	position: absolute;
	left: 0px;
	top: 9px;
	width: 26px;
	height: 26px;
	border-radius: 13px;
	padding: 0px;

	visibility: hidden;
	background: rgba(0,0,40,0.15);
	border-color: $invisible;
}


// EXAMPLE WITH PLAY ON HOVER
// .cellRow:hover .cellplay{
// 	visibility: visible;
// 	background: $blue-extraLight;
// 	border: 1px solid $blue;
// }

// .cellRow:hover .cellplay .icon{
// 	fill: $gray-8;
// }

// .cellRow:hover .questionCountBadge{
// 	visibility: hidden;
// }


.cellRow-left .cellplay:hover {
	background: rgba(0,0,40,0.25);
	border-color: $invisible;
	box-shadow: none;	
}

.cellRow-left .cellplay .icon{
	margin-right: -2px;
	fill: $white;
}
.cellRow-left .questionCountBadge{
	position: relative;
	top: -0.5px;
}



// CELL NAME AND DATE

.cellRow-name{
	font-size: 14px;
	line-height: 3.1;
	font-weight: $lato-medium;
	color: $textBlack;

	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}

.cellRow.cellRow--imageOnlyQuestion .cellRow-name{
	color: $textMid;
}

.cellRow-sharedIcon { // Recent only when item in shared repo or public 
	width: 20px;
	height: 100%;
	
	flex-grow: 0;
	flex-shrink: 0;

	display: flex;
	align-items: center;
	justify-content: center;

	margin-right: -8px; // override padding on date
}

.cellRow-sharedIcon .icon.shared,
.cellRow-sharedIcon .icon.globe-shared,
.cellRow-sharedIcon .icon.globe-shared-author{
	width: 14px;
	height: 14px;
	fill: $gray-125;
	position: relative;
}

.cellRow:hover .cellRow-sharedIcon .icon.shared,
.cellRow:hover .cellRow-sharedIcon .icon.globe-shared,
.cellRow:hover .cellRow-sharedIcon .icon.globe-shared-author{
	fill: $gray-115;
}


.cellRow-date{	
	font-size: 13px;
	color: $textLight;
	line-height: 3.4;

}

.cellRow-date:first-letter {
  text-transform:uppercase;
}

.cellRow-date--repo b {
	color: $textMid;
	font-weight: $lato-medium;
	margin-right: 4px;
}

// CELL RIGHT 
// DETAIL OR MORE BUTTON (TBC)

.cellRow-right{
	display: flex;
	justify-content: center;
	align-items: center;
}

.cellRow-right .btn{
	height: 26px;
	width: 32px;
	background: $white;
	border-color: $lineMid;
}




// CONTEXT MENU STATE
// REFACTORED OUT AS NO LONGER MENU STATE

// .cellRow.has--menu,
// .cellRow.has--menu:hover {
// 	background: transparentize($blue-light,0.9);
// }

// .cellRow.is--active.has--menu,
// .cellRow.is--active.has--menu:hover,
// .cellRow.is--multiSelect.has--menu,
// .cellRow.is--multiSelect.has--menu:hover{
// 	background: darken($blue-light,5%);
// }



// HOVER STATE
// show the more button

.cellRow:hover .cellRow-right{
	opacity: 1;
}

.cellRow:hover{
	background: rgb(250,250,255);
}

.cellRow:hover .cellRow-name{
	color: $textBlackDark;
}

.cellRow:hover .cellRow-date{
	color: $textMid;
}

.cellRow:hover .cellRow-date--repo b {
	color: $textDark;	
}

// ACTIVE STATE

.cellRow.is--active,
.cellRow.is--multiSelect{
	background: $blue-light;
}

.cellRow.is--active.is--multiSelect,
.cellRow.is--active.is--multiSelect:active{
	background: darken($blue-light, 5%);
}

.cellRow.is--active .cellplay{
	visibility: visible;
}

.cellRow.is--active .questionCountBadge{
	visibility: hidden;
}

.cellRow.is--multiSelect .questionCountBadge{
	background: darken($blue-light, 5%);
	color: $white;
}

.cellRow.is--active .cellRow-date--repo--live-dot,
.cellRow.is--multiSelect .cellRow-date--repo--live-dot{
	background: $white;
}

.cellRow.is--active .cellRow-left .icon.folder #top,
.cellRow.is--active .cellRow-left .icon.folder #main,
.cellRow.is--multiSelect .cellRow-left .icon.folder #top,
.cellRow.is--multiSelect .cellRow-left .icon.folder #main{
	fill: $white;
}

// ISBE

.cellRow.is--readOnly.is--active .cellRow-left .icon.folder #isbe,
.cellRow.is--readOnly.is--multiselect .cellRow-left .icon.folder #isbe,{
	fill: $blue;
}

.cellRow.is--readOnly.is--active .cellRow-left .icon.folder #top,
.cellRow.is--readOnly.is--active .cellRow-left .icon.folder #main,
.cellRow.is--readOnly.is--multiSelect .cellRow-left .icon.folder #top,
.cellRow.is--readOnly.is--multiSelect .cellRow-left .icon.folder #main,{
	fill: $white;
}
//

.cellRow.is--active .cellRow-name,
.cellRow.is--multiSelect .cellRow-name{
	color: white;
}

.cellRow.is--active.cellRow--imageOnlyQuestion,
.cellRow.is--multiSelect.cellRow--imageOnlyQuestion
.cellRow-name{
	color: rgba(255,255,255,0.9);
}

.cellRow.is--active .cellRow-date,
.cellRow.is--multiSelect .cellRow-date{
	color: white;
	opacity: 0.9;
}

.cellRow.is--active .cellRow-date.cellRow-date--repo,
.cellRow.is--multiSelect .cellRow-date.cellRow-date--repo{
	opacity: 1;
}

.cellRow.is--active .cellRow-date.cellRow-date--repo,
.cellRow.is--multiSelect .cellRow-date.cellRow-date--repo{
	color: rgba(255,255,255,0.9);
}

.cellRow.is--active .cellRow-date.cellRow-date--repo b ,
.cellRow.is--multiSelect .cellRow-date.cellRow-date--repo b{
	color: $white;
}

.cellRow.is--active .icon.shared,
.cellRow.is--active .icon.globe-shared,
.cellRow.is--active .icon.globe-shared-author{
	fill: rgba(255,255,255,0.8);
}


.cellRow.is--active:hover .cellRow-right .btn,
.cellRow.is--multiSelect:hover .cellRow-right .btn{	
	background: rgba(0,0,0,0.1);
	border-color: rgba(0,0,0,0);
}

.cellRow.is--active .cellRow-right .btn:hover,
.cellRow.is--multiSelect .cellRow-right .btn:hover{	
	background: rgba(0,0,0,0.14);
	border-color: rgba(0,0,0,0.02);
}

.cellRow.is--active:hover .cellRow-right .btn .icon,
.cellRow.is--multiSelect:hover .cellRow-right .btn .icon,{	
	fill: white;
}

.cellRow.is--active:hover .icon.shared,
.cellRow.is--active:hover .icon.globe-shared,
.cellRow.is--active:hover .icon.globe-shared-author{
	fill: rgba(255,255,255,0.9);
}


// DRAG AND DROP -- can drop
// when item is over folder

.cellRow.is--DnDdragging{
	// background: red;
}

.cellRow.is--DnDover{
	// state when an item is over-top of it
	background: transparentize($blue-light,0.9);
}

.cellRow.is--DnDover.is--DnDcantDrop{
	background: $white;
}

.cellRow.is--DnDdragging.is--DnDover{
	// reset to normal if over itself
	background: $blue;
	opacity: 1;
}

// ARCHIVED (AKA TRASH) CELLS 

.cellRow.is--archived.is--active .questionCountBadge,
.cellRow.is--archived.is--multiSelect .questionCountBadge{
	visibility: visible;
	background: rgba(0,0,0,0.1);

	color: $white;
}


.cellRow.is--archived button.btn{
	width: 85px;
	margin-left: auto;
	margin-top: 9px; // a bit messy sorry
}

.cellRow.is--archived.is--active .btn,
.cellRow.is--multiselect.is--active .btn{
	background: rgba(0,0,0,0.1);
	border-color: rgba(0,0,0,0.05);
	color: $white;
}


/// LIVE UPDATED

.cellRow-date--repo{
	display: flex;
	align-items: center;
}

.cellRow-date--repo--live{
	flex-shrink: 0;
	flex-grow: 0;
	margin-left: 2px;

	height: 17px;
	border-radius: 3px;
	//background: transparentize($blue-extraLight,0.1);
	
	display: flex;
	align-items: center;
	justify-content: center;

	// margin-left: auto;
}

.cellRow-date--repo--live-dot{
	width: 5px;
	height: 5px;	

	margin: 1.5px;
	
	border-radius: 50%;
	background: $blue-light;
	
	opacity: 0.5;
}

//
// FACEBOOK EFFECT
//

// @keyframes chatWave {
// 	0%, 60%, 100% {
// 		transform: translateY(1px);
// 	}

// 	30% {
// 		transform: translateY(-4px);
// 	}
// }

// .cellRow-date--repo--live-dot{
// 	animation: chatWave 1.3s linear infinite;
// }

// .cellRow-date--repo--live-dot:nth-child(2){
// 	animation-delay: -1.1s;
// }

// .cellRow-date--repo--live-dot:nth-child(3){
// 	animation-delay: -0.9s;
// }



//
// IMESSAGE EFFECT
//

@keyframes blink {
  40%, 60% {
    opacity: 1;
  }
}

.cellRow-date--repo--live-dot:nth-child(1){
	animation: 2s blink infinite 0.3333s;
}

.cellRow-date--repo--live-dot:nth-child(2){
	animation: 2s blink infinite 0.6666s;
}

.cellRow-date--repo--live-dot:nth-child(3){
	animation: 2s blink infinite 0.9999s;
}