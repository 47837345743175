//
// FORM FIELD TEXT
//

.formField.is--text input {
	width: 100%;
	margin-top: 10px;
	margin-bottom: 6px;

	height: $height-formField;
	line-height: $height-formField; // safari fix

	border: $border-formField;
	border-radius: 4px;
	background: $white;

	padding-left: 10px;
	padding-bottom: 2px;
	box-sizing: border-box;
	
	color: $textBlack;
	font-size: $fontSize-formField;
	font-weight: $lato-semibold;
}

.formField.is--text input:disabled {
	border-color: $gray-13;
	background: $gray-13;
	pointer-events: none;
	user-select: none;
}

.formField.is--text input:hover{
	border: $border-formField--hover;
}

.formField.is--text input:focus{
	border: $border-formField--focus;
	outline: none;
	box-shadow: $boxShadow-formField--focus;
}

.formField.is--text input::-webkit-input-placeholder {
   color: $textInputPlaceholder !important;
   font-size: $fontSize-formField;
   font-weight: $lato-semibold;
}

.formField.is--text input::-moz-placeholder {
   color: $textInputPlaceholder !important;
   font-size: $fontSize-formField;
   font-weight: $lato-semibold;
}

.formField.is--text input:-ms-input-placeholder {
   color: $textInputPlaceholder !important;
   font-size: $fontSize-formField;
   font-weight: $lato-semibold;
}

//

.formField.is--text.has--error input {
	background: $color-formField--warningBG;
	border-color: $color-formField--warningBorder;
}

.formField.is--text.is--large input{
	height: 40px;
	font-size: 20px;
	font-weight: $lato-bold;
}

.formField.is--text.is--large input::-webkit-input-placeholder {
   font-size: 20px;
   font-weight: $lato-bold;
}

.formField.is--text.is--large input::-moz-input-placeholder {
   font-size: 20px;
   font-weight: $lato-bold;
}

.formField.is--text.is--large input:-ms-input-placeholder {
   font-size: 20px;
   font-weight: $lato-bold;
}