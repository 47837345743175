.fontSizeCalculator{
	// background: $tempr;
	position: absolute;
	top: -9999px;
	left: -9999px;
	
	width: 100vw;
	height: 9999px;

	z-index: 9999;

	
}


.richTextEditorContainer--fontSize
.fontSizeCalculator{
	// background: $tempr;
	position: absolute;
	top: 80vh;
	left: 0px;
	
	width: 100vw;
	height: 9999px;

	z-index: 9999;

	
}

.fontSizeCalculator .slide-body{
	position: relative; // temp for height counter	
}


.fontSizeCalculator-choicesArrayContainer{
	width: calc(1280px - 60px - 60px); // for 100% width
}