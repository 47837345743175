//
// KEYBOARD KEY
// Keyboard key "icon" used in e.g. ScoreSheet empty state, can be used in other keyboard shortcut contexts
//

.keyboardKey{
	box-sizing: border-box;
	min-width: 26px;

  height: 24px;
  
  border: 1px solid $gray-115;
  border-bottom: 2px solid $gray-10;

  border-radius: 3px;
  
  line-height: 1.5;
 
  background: $white;

  font-size: 13px;
  font-weight: $lato-semibold;
  color: $textBlackDark;

  display: flex;
  align-items: center;
  justify-content: center;

  margin-left: 5px;
}

.keyboardKey span{
	margin-left: 7px;
	margin-right: 7px;
}

.keyboardKey .icon{
	fill: $textBlackDark;
  width: 12px;
  height: 12px;
}

.keyboardKey .icon.icon-key--backSpace{
	fill: $textBlackDark;
  width: 19px;
  height: 19px;
  margin-left: 7px;
  margin-top: 1px;
  margin-right: 7px;
}

.keyboardKey .icon.enterKey{
	fill: $textLight;
  width: 12px;
  height: 12px;
  margin-left: -2px;
  margin-top: 2px;
  margin-right: 8px;
}

.keyboardKey .icon.icon-key--cmd{
	opacity: 0.75;
}