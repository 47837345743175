.sectionHome-students{
	padding-bottom: 0px;
	position: relative;
}

.sectionHome-students .sectionHome--block-header-link{
	cursor: pointer;
	white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.sectionHome-students-scrollRef{
	width: 10px;
	height: 10px;
	background: $invisible;
	position: absolute;
	pointer-events: none;
	top: -46px; // for scrollbar
	left: 0px;
}

.sectionHome-students-studentCards--container{
	cursor: default;

	margin-top: 2px;

	column-count: 4;
  column-gap: 20px;
	width: 100%;
	overflow: hidden;
}

.sectionHome-students-studentCard{
	cursor: default;

 	display: inline-block;
 	vertical-align: top;

 	break-inside: avoid-column;
 	-webkit-column-break-inside: avoid;

	width: 170px;

	height: 30px;
	border-bottom: 1px solid $lineMid;
	
	position: relative;

	line-height: 2;
	
	font-size: 14px;
	font-weight: $lato-semibold;
	color: $textBlack;

	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.sectionHome-students-studentCard-lastName{
	color: $textLight;
}


/// DEVICE TOKEN

.sectionHome-students-studentCard.sectionHome-students-studentCard--device{
	height: 47px;
}

.sectionHome-students-studentCard.sectionHome-students-studentCard--device,
.sectionHome-students-studentCard--device *{
	cursor: pointer;
}

.sectionHome-students-studentCard--device-primary{
	margin-top: 1px;
	line-height: 2.1;
}

.sectionHome-students-studentCard--device-token{
	font-size: 13px;
	// background: $tempb;
	line-height: 0.4;
	font-weight: $lato-medium;
}

.sectionHome-students-studentCard--device:active{
	background: transparentize($blue-bold, 0.95);
}

.sectionHome-students-studentCard--device
.sectionHome-students-studentCard-lastName{
	color: $textBlack;
}

.sectionHome-students-studentCard .sectionHome-students-studentCard--device-token-token{
	display: block;
	color: $textLight;
}

.sectionHome-students-studentCard .sectionHome-students-studentCard--device-token-hover,
.sectionHome-students-studentCard .sectionHome-students-studentCard--device-token-copied{
	display: none;
	color: $textBlue;
}


.sectionHome-students-studentCard:hover
.sectionHome-students-studentCard--device-token-token{
	display: none;
}

.sectionHome-students-studentCard:hover
.sectionHome-students-studentCard--device-token-hover,
.sectionHome-students-studentCard:hover
.sectionHome-students-studentCard--device-token-copied{
	display: block;
}


///// EMPTY STUDENTS
.sectionHome-emptyStudents{
	 height: 210px; // to prevent white space at bottom caused by add-to-queue dropdown
}
.sectionHome-emptyStudents-label{
	margin-top: -12px;
	font-size: 16px;
	font-weight: $lato-semibold;
	color: $textMid;
	margin-bottom: 25px;
}

.sectionHome-emptyStudents button{
	min-width: 150px;
}

///// GOOGLE CLASSROOM INTEGRATION
.sectionHome--block-header-title--googleClassroomStudents{
	padding-left: 50px;
	box-sizing: border-box;
	position: relative;
}
.sectionHome--block-header-title-integration{
	position: relative;
}

.sectionHome--block-header-title-integration-label{
	font-size: 14px;
	font-weight: $lato-semibold;
	color: $textLight;
	margin-top: 3px;
	text-transform: initial;
	letter-spacing: initial;
}

.sectionHome--block-header-title-integration-logoContainer{
	width: 40px;
	height: 40px;
	// background: $tempr;
	position: absolute;
	left: 0px;
	top: 0px;
}

.sectionHome--block-header-title-integration-logoContainer svg{
	width: 40px;
	height: 40px;
}

//
// ELEARNING SETUP SUPPORT
//


.sectionHome-students-eLearningSetupHelp{
	width: 100%;
	margin-top: -8px;
	margin-bottom: 24px;
}

// TOP PART WITH VIDEO
.sectionHome-students-eLearningSetupHelp-generalContainer{
	display: flex;
	justify-content: space-between;
}
.sectionHome-students-eLearningSetupHelp-general{
	width: 10px;
	flex-grow: 1;
	flex-shrink: 1;

	max-width: 350px;
}						
.sectionHome-students-eLearningSetupHelp-general-header{
	padding-top: 12px;

	font-size: 15px;
	font-weight: $lato-bold;
	color: $textBlackDark;
	margin-bottom: 10px;
	letter-spacing: 0.01em;
}
.sectionHome-students-eLearningSetupHelp-general-description{
	font-size: 13.5px;
	font-weight: $lato-medium;
	color: $textBlack;
	letter-spacing: 0.005em;
	line-height: 1.15;
}
.sectionHome-students-eLearningSetupHelp-general-description-p{
	margin-bottom: 10px;
}

.sectionHome-students-eLearningSetupHelp-general-description emph{
	font-weight: $lato-semibold;
	color: $textBlackDark;
}

.sectionHome-students-eLearningSetupHelp-general-helpLink,
.sectionHome-students-eLearningSetupHelp-general-helpLink:hover,
.sectionHome-students-eLearningSetupHelp-general-helpLink:visited{
	color: $blue;
	padding-top: 4px;
	padding-bottom: 4px;
	padding-left: 5px;
	padding-right: 5px;
	border-radius: 3px;
	margin-left: -4px;
	cursor: pointer;
}

.sectionHome-students-eLearningSetupHelp-general-helpLink:hover{
	background: $blue-extraLight;
	color: darken($blue, 2%);
}

.sectionHome-students-eLearningSetupHelp-videoContainer{
	
	width: 240px;
	height: 180px;

	display: flex;
	justify-content: flex-end;

	flex-grow: 0;
	flex-shrink: 0;

	position: relative;
	z-index: 10;
}

.sectionHome-students-eLearningSetupHelp-video{
	width: 100%;
	height: 160px;
	position: relative;	

	cursor: pointer;
	user-select: none;

	z-index: 5;
}

.sectionHome-students-eLearningSetupHelp-video .helpVideoThumbnail{
	margin: 0 auto;
	margin-top: 9px;
	cursor: pointer;
}

.sectionHome-students-eLearningSetupHelp-video-label{
	font-weight: $lato-heavy;
	color: $textBlue;
	font-size: 15px;
	text-align: center;
	margin-top: 6px;
	margin-bottom: 2px;
}

.sectionHome-students-eLearningSetupHelp-video:hover .sectionHome-students-eLearningSetupHelp-video-label{
	color: darken($textBlue, 3%);
}


.sectionHome-students-eLearningSetupHelp-video-sub{
	font-weight: $lato-semibold;
	color: $textMid;	
	font-size: 13px;
	text-align: center;
	opacity: 0.8;
}

.sectionHome-students-eLearningSetupHelp-video:hover .sectionHome-students-eLearningSetupHelp-video-sub{
	opacity: 1;
}


.sectionHome-students-eLearningSetupHelp-video-videoBG svg{
	fill: rgb(241,247,255);
	position: absolute;
	top: 0px;
	left: 0px;
	bottom: 0px;
	right: 0px;
}



// BUT WITH VIDEO

.sectionHome-students-eLearningSetupHelp-shareLinksContainer{
	display: flex;
	margin-top: 12px;
}

.sectionHome-students-eLearningSetupHelp-shareLinksBlock{
	width: 50%;
	box-sizing: border-box;
}

.sectionHome-students-eLearningSetupHelp-shareLinksBlock-top{
	padding-left: 14px;
	border-left: 1px solid $blue;
	padding-top: 3px;
	padding-bottom: 3px;
}

.sectionHome-students-eLearningSetupHelp-shareLinksBlock-top-option{
	font-size: 13px;
	font-weight: $lato-medium;
	color: $textMid;
	margin-bottom: 4px;
	letter-spacing: 0.005em;
}

.sectionHome-students-eLearningSetupHelp-shareLinksBlock-top-header{
	font-size: 14.5px;
	font-weight: $lato-bold;
	letter-spacing: 0.01em;
	color: $textBlack;
}

.sectionHome-students-eLearningSetupHelp-shareLinksBlock-bottom{
	padding-left: 14px;
	padding-top: 8px;
	border-left: 1px solid transparentize($blue, 0.8)
}

.sectionHome-students-eLearningSetupHelp-shareLinksBlock-bottom-description{
	font-size: 13.5px;
	font-weight: $lato-medium;
	color: $textDark;
	max-width: 300px;

	margin-bottom: 12px;
}

.sectionHome-students-eLearningSetupHelp-shareLinksBlock-bottom-description-p{
	padding-bottom: 8px;
}

//
// PRIMARY BTN

button.sectionHome-students-eLearningSetupHelp-primaryBtn{
	
	height: 32px;
	border: 1px solid darken($lineDark, 2%);
	background: $white;
	box-shadow: 0px 1px 2px 0px rgba(0,0,40,0.12);

	font-size: 14px;
	font-weight: $lato-bold;
	color: $textBlack;
	letter-spacing: 0.01em;

	padding-left: 12px;
	padding-right: 12px;

	border-radius: 4px;

	cursor: pointer;
}

button.sectionHome-students-eLearningSetupHelp-primaryBtn:hover{
	border: 1px solid darken($lineDark, 8%);
}

button.sectionHome-students-eLearningSetupHelp-primaryBtn:active{
	border: 1px solid darken($lineDark, 10%);
	background: $gray-14;
}

button.sectionHome-students-eLearningSetupHelp-primaryBtn svg{
	fill: $green;
	margin-left: 2px;
	margin-right: -2px;

	height: 10px;
	width: 10px;

	position: relative;
	top: 1px;
}
