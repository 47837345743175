
.repo-emptyStateHelpVideoContainer{
	position: absolute;
	
	left: 50%;
	bottom: 10px;
	transform: translateX(-50%);
	width: 240px;
	height: 160px;

	display: flex;
	align-items: center;
	justify-content: center;

	z-index: 10;
}

.repo-emptyStateHelpVideo{
	width: 100%;
	height: 100%;
	position: relative;	

	cursor: pointer;
	user-select: none;
}

.repo-emptyStateHelpVideo .helpVideoThumbnail{
	margin: 0 auto;
	margin-top: 9px;
	cursor: pointer;
}

.repo-emptyStateHelpVideo-label{
	font-weight: $lato-heavy;
	color: $textBlue;
	font-size: 15px;
	text-align: center;
	margin-top: 6px;
	margin-bottom: 2px;
}

.repo-emptyStateHelpVideo:hover .repo-emptyStateHelpVideo-label{
	color: darken($textBlue, 3%);
}


.repo-emptyStateHelpVideo-sub{
	font-weight: $lato-semibold;
	color: $textMid;	
	font-size: 13px;
	text-align: center;
	opacity: 0.8;
}

.repo-emptyStateHelpVideo:hover .repo-emptyStateHelpVideo-sub{
	opacity: 1;
}


.repo-emptyStateHelpVideoBG svg{
	fill: rgb(241,247,255);
	z-index: -1;
	position: absolute;
	top: 0px;
	left: 0px;
	bottom: 0px;
	right: 0px;
}