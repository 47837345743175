.upgrade--payment-planSelection{
	width: 100%;
	
	display: flex;
	align-items: center;
	justify-content: space-between;

	margin-top: 24px;
	margin-bottom: 75px;
}


/// PLAN SELECTION BUTTONS
button.upgrade--payment-planBtn{
	height: 96px;
	width: calc(50% - 11px);

	border-radius: 13px;
	background: $white;
	box-shadow: 0px 4px 8px 0px rgba(0,0,20,0.05);

	box-sizing: border-box;
	padding-left: 82px;
	padding-right: 28px;

	display: flex;
	align-items: center;
	justify-content: space-between;

	position: relative;

	cursor: pointer;

	text-align: left; // override button default
}

.upgrade button.upgrade--payment-planBtn--active{
	border: 1px solid $blue;
}

.upgrade button.upgrade--payment-planBtn--inactive{
	border: 1px solid $lineDark;
	opacity: 0.7;
	transition: opacity 150ms linear;
}

.upgrade button.upgrade--payment-planBtn--inactive:hover{
	border: 1px solid $gray-115;
	opacity: 0.95;
	transition: opacity 50ms linear;
}

// CHECK CONTAINER

.upgrade--payment-planBtn-checkContainer{
	width: 44px;
	height: 44px;
	border-radius: 50%;
	position: absolute;
	left: 22px;
	top: 50%;
	transform: translateY(-50%);

	display: flex;
	align-items: center;
	justify-content: center;

	box-sizing: border-box;
}

.upgrade--payment-planBtn-checkContainer .icon{
	width: 22px;
	fill: $white;
}

button.upgrade--payment-planBtn--active
.upgrade--payment-planBtn-checkContainer{
	background: $blue-light;
}

button.upgrade--payment-planBtn--inactive
.upgrade--payment-planBtn-checkContainer{
	background: $white;
	border: 2px solid $lineMid;
}


// LEFT SIDE
.upgrade--payment-planBtn-left-title{
	font-size: 20px;
	font-weight: $effra-medium;
	color: $textBlack;
	margin-bottom: 4px;
}

.upgrade--payment-planBtn-left-selected{
	font-size: 16px;
	height: 16px;	
	font-weight: $effra-medium;
	margin-bottom: 3px;
}

.upgrade--payment-planBtn-left-selected{
	color: $blue;
}


// RIGHT SIDE

button.upgrade--payment-planBtn
.upgrade--payment-planBtn-right-price{
	font-size: 40px;
	font-weight: $effra-bold;
	color: $textBlack;
	text-align: right;	
}

button.upgrade--payment-planBtn
.upgrade--payment-planBtn-right-price-sub{
	font-size: 18px;
	font-weight: $effra-regular;
	color: $textDark;
}

//

button.upgrade--payment-planBtn
.upgrade--payment-planBtn-right-diffPrice{
	font-size: 14px;
	font-weight: $effra-regular;
	color: $textMid;
	opacity: 0.8;
	text-align: right;
	margin-top: -2px;
	margin-bottom: 2px;
}

