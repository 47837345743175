//
// PAGE HEADER TOP - HEADER ROW
// Small timestamp or section marker, like in Set Detail or Set Report
//

.pageHeaderTop-headerRow{
  display: flex;
  position: relative;
  height: $height-pageHeaderTop-headerRow;
  width: 100%;
  margin-bottom: $marginBottom-pageHeaderTop-headerRow;
}

.pageHeaderTop-headerRow-header{
  user-select: none;
  cursor: default;
}

.pageHeaderTop-headerRow-header,
.pageHeaderTop-headerRow-header input{
  flex-grow: 1;

  font-size: 36px;
  height: 100%;
  font-weight: $lato-heavy;
  color: $textBlack;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.pageHeaderTop-headerRow-header input{
  width: 100%;
}

.pageHeaderTop--multiLine .pageHeaderTop-headerRow{
  height: auto;
}

.pageHeaderTop--multiLine
.pageHeaderTop-headerRow-header{

  max-height: calc(#{$height-pageHeaderTop-headerRow--multiLine} - 5px); // some tweaks here for cross-browser

  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;

  overflow: hidden;
  line-height: 1.15;

  text-overflow: clip;
  white-space: normal;
}

// IF SHARED, SHOW ICON
// ITEM DETAIL ETC.

.pageHeaderTop-headerRow-header .icon.shared{
  fill: $gray-115;
  height: 26px;
  width: 26px;
  margin-left: 10px;
  position: relative;
  top: 2px;
}


//
// REPO HEADER
// infinite lines

.pageHeaderTop--repo .pageHeaderTop-headerRow{
  height: auto;
  margin-bottom: 0px;
  padding-bottom: $marginBottom-pageHeaderTop-headerRow; // use padding to allow for cursor - pointer for click anywhere
}

.pageHeaderTop--repo .pageHeaderTop-headerRow,
.pageHeaderTop--repo .pageHeaderTop-headerRow-header,
.pageHeaderTop--repo .pageHeaderTop-leftBadgeContainer *,
.pageHeaderTop--repo .pageHeaderTop-headerInfoRow{
  cursor: pointer; // editable elements
}

.pageHeaderTop--repo.pageHeaderTop--repoViewOnly .pageHeaderTop-headerRow,
.pageHeaderTop--repo.pageHeaderTop--repoViewOnly .pageHeaderTop-headerRow-header,
.pageHeaderTop--repo.pageHeaderTop--repoViewOnly .pageHeaderTop-leftBadgeContainer *,
.pageHeaderTop--repo.pageHeaderTop--repoViewOnly .pageHeaderTop-headerInfoRow{
  cursor: default; // non editable
}

.pageHeaderTop--repo .pageHeaderTop-headerRow-header{
  line-height: 1.1;
  white-space: normal;

  word-wrap: break-word;
}



//
// REPO AND AUTHOR PAGE LOADER

.pageHeaderTop--repo-headerRow-header-loadingPlaceholder,
.pageHeaderTop--author-headerRow-header-loadingPlaceholder{
  height: 39px;
  background: $color-pageLoaderElement;
  border-radius: 6px;
}

.pageHeaderTop--repo-headerRow-header-loadingPlaceholder{
  width: 305px;
}

.pageHeaderTop--author-headerRow-header-loadingPlaceholder{
  width: 265px;
}



//
// INPUT

.pageHeaderTop--libraryFolder .pageHeaderTop-headerRow,
.pageHeaderTop--repoFolder .pageHeaderTop-headerRow{
  padding-left: 6px;
  margin-left: -12px;
  margin-top: -5px;
  height: 50px;
}

.pageHeaderTop-headerRow-header input,
.pageHeaderTop-headerRow-header input:disabled:hover{
  height: 100%;
  padding-left: 5px;
  box-sizing: border-box;
  box-shadow: none;
  border-radius: 3px;

  border: 1px solid $invisible;
  background: $invisible;
}

.pageHeaderTop-headerRow-header input:hover{
  background: rgba(0,0,40,0.025);
  color: $textBlackDark;
  border: 1px solid $invisible;
  cursor: default;
}

.pageHeaderTop-headerRow-header input::-webkit-input-placeholder{
  color: $textInputPlaceholder;
}

.pageHeaderTop-headerRow-header input:focus {
  color: $textBlackDark;
  background: $white;
  border: 1px solid $lineDark;
  box-shadow: none;
  outline: none;
  cursor: text;
}

//
// SEARCH RESULTS

.pageHeaderTop--searchResults .pageHeaderTop-headerRow{
  //padding-left: 6px;
  margin-left: -1px;
  margin-top: -3px;
  height: 50px;
  padding-bottom: 10px;
  width: calc(100% + 1px);

}

.pageHeaderTop--searchResults .pageHeaderTop-headerRow input{
  height: 100%;
  padding-left: 0px;
  padding-right: 40px;
  //padding-right: 8px;

  box-sizing: border-box;
  box-shadow: none;

  border: 1px solid $invisible;

  color: $textBlack;
  background: $invisible;
  border-bottom: 1px solid $lineMid;
}

.pageHeaderTop--searchResults .pageHeaderTop-headerRow input:hover{
  color: $textBlackDark;
  border: 1px solid $invisible;
  border-bottom: 1px solid $blue;
  cursor: text;
}

.pageHeaderTop--searchResults .pageHeaderTop-headerRow input:focus{
  color: $textBlue;

  background: $white;

  border: 1px solid $invisible;
  border-bottom: 1px solid $blue;
  box-shadow: none;
  outline: none;
  cursor: text;
}


//
// PROFILE PIC ON ACCOUNT PAGE

.pageHeaderTop--account .pageHeaderTop-headerRow-header {
  display: flex;
  align-items: center;
  overflow: visible;
}

.pageHeaderTop--account-headerRow-profilePicContainer{
  height: 56px;
  width: 56px;
  border-radius: 6px;
  margin-right: 24px;
  overflow: hidden;
  position: relative;
  // fixes for overflow not clipping because of transform
  -webkit-backface-visibility: hidden;
   -moz-backface-visibility: hidden;
   -webkit-transform: translate3d(0, 0, 0);
   -moz-transform: translate3d(0, 0, 0);
}

.pageHeaderTop--account-headerRow-button{
  position: absolute;

  display: flex;
  align-items: center;
  justify-content: center;

  background: rgba(0,0,20,0.75);

  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;

  z-index: 100;

  opacity: 0;
  transition: opacity 50ms linear;

  cursor: pointer;
}

.pageHeaderTop--account-headerRow-button:hover{
  opacity: 1;
  transition: opacity 50ms linear;
  transition-delay: 100ms;
}

.pageHeaderTop--account-headerRow-button--uploadPic .icon{
  fill: $white;
  height: 24px;
  width: 24px;
}

.pageHeaderTop--account-headerRow-button--removePic .icon{
  fill: $white;
  height: 30px;
  width: 30px;
}

// UPLOADING

.pageHeaderTop--account-headerRow-profilePicUploading{
  position: absolute;
  left: 0px;
  top: 0px;
  right: 0px;
  bottom: 0px;
  border-radius: 6px;

  background: $gray-10;

  display: flex;
  align-items: center;
  justify-content: center;
}

.pageHeaderTop--account-headerRow-profilePicUploading-loadingSpinnerContainer{
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;
}

.pageHeaderTop--account-headerRow-profilePicUploading-loadingSpinner{

  height: 22px;
  width: 22px;

  border-radius: 22px;

  border: 3px rgba(26,28,34,0.55) solid;
  border-top: 3px $white solid;

  opacity: 0.5;

  animation: spinnerRotateAnimation .5s infinite linear;
}
