
/*format the doc output thing */
code {
    // float:left;
    text-align: left;
    
    border: 1px solid #ddd;
    border-left: 3px solid #f36d33;
    
    page-break-inside: avoid;
    font-family: monospace;
    font-size: 10px;
    line-height: 1.2;
    margin-bottom: 1.6em;
    width: 45%;
    overflow: auto;
    padding: 1em 1.5em;
    display: block;
    word-wrap: break-word;

    background: rgb(255,255,255);
    color: $textBlackDark;
    letter-spacing: 0.02em;

    // background: $textBlackDark;
    // color: $white;
}


.editorContainerWithOutput-outputContainer code{
    width: 92%;
    // background: rgb(252,252,255);
    //color: $textBlackDark;
    
    font-size: 10px;
}

.editorContainerWithOutput-outputContainer pre{
    margin: 0px;
}



//

.editorContainerWithOutput
.editor-canvas-bottomBar{
    position: absolute; // for output
}

.editorContainerWithOutput
.editor{
    position: absolute; // for output
}

.editorContainerWithOutput
.editor-topBar{
    position: absolute; // for output
}
.editorContainerWithOutput
.editor-formatBar{
    position: absolute; // for output
}

.editorContainerWithOutput
.editor-slideList{
    position: absolute; // for output
}
.editorContainerWithOutput
.editor-canvasGhost{
    position: absolute; // for output
}

.editorContainerWithOutput
.editor-canvas{
    position: absolute; // for output
}



.editorContainerWithOutput-outputContainer{ 
    position: fixed;
    z-index: 1000;
    
    top: 75px;
    right: 0px;
    width: 460px;
    height: calc(100vh - 78px);
    // background: $white;
    z-index: 1000;

    overflow: scroll;
}


.editorContainerWithOutput-outputSection{
    width: 100%;
    margin-top: 12px;
    margin-bottom: 20px;
}

.editorContainerWithOutput-outputSection-title{
    text-align: left;
    font-size: 14px;
    font-weight: $lato-bold;

    width: 100%;
    height: 24px;

    
    padding-left: 8px;
    padding-top: 3px;

    box-sizing: border-box;

    color: $textBlackDark;
    background: $gray-15;

}


.editorContainerWithOutput-outputSection--doc
.editorContainerWithOutput-outputSection-title{
  border-left: 6px solid $blue;  
}

.editorContainerWithOutput-outputSection--doc
code {
    border-left: 2px solid $blue;
}

.editorContainerWithOutput-outputSection--html
.editorContainerWithOutput-outputSection-title{
  border-left: 6px solid darken($classColor-purple, 5%);
}

.editorContainerWithOutput-outputSection--html
code {
    border-left: 2px solid darken($classColor-purple, 5%);
}

.editorContainerWithOutput-outputSection--setJSON
.editorContainerWithOutput-outputSection-title{  
  border-left: 6px solid darken($classColor-green, 5%);
}

.editorContainerWithOutput-outputSection--setJSON
code {
    border-left: 2px solid darken($classColor-green, 5%);
}

.editorContainerWithOutput-outputSection--questionJSON
.editorContainerWithOutput-outputSection-title{
  border-left: 6px solid darken($classColor-orange, 5%);
}

.editorContainerWithOutput-outputSection--questionJSON
code {
    border-left: 2px solid darken($classColor-orange, 14%);
}





.editorContainerWithOutput-outputContainer-toggleContainerBtn{
    position: absolute;
    top: 0px;
    left: 0px;
}







.richTextEditorContainer-outputContainer-staticSlideContainer--small{
    width: 600px;
    height: 400px;
    margin-top: 500px;
    // background: $tempr;
}

.richTextEditorContainer-outputContainer-staticSlideContainer--large{
    width: 1200px;
    margin-top: 10px;
    margin-left: 10px;
    background: rgb(250,250,255);
    padding-top: 20px;
    padding-left: 20px;
    box-sizing: border-box;
    // background: $tempb;  
}