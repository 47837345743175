//
// DELETE CHOICE BUTTON
//


.slide .slide-choice-deleteBtn{
	width: 40px;
	height: $min-height-choice;
	border-radius: 4px;
	position: absolute;
	top: 1px;
	right: -40px;

	cursor: pointer;
	
	text-align: center;
	box-sizing: border-box;

	border-radius: 4px;
	border-top-left-radius: 0px;
	border-bottom-left-radius: 0px;
	opacity: 0;
	
	-webkit-appearance: none;
}

.slide .slide-choice-deleteBtn .editorIcon { 
	width: 20px;
	box-sizing: border-box;
	fill: $white;
	margin-top: 9px;
	fill: $textDark;
}

//
// OPACITY MANAGEMENT

.slide:hover .slide-choices .slide-choice .slide-choice-deleteBtn .editorIcon {
	//opacity: 0.15;
	opacity: 0;
}

.slide:hover .slide-choices .slide-choice.choice--empty .slide-choice-deleteBtn .editorIcon {
	//opacity: 0.2;
	opacity: 0;
}

.slide .slide-choices:hover .slide-choice .slide-choice-deleteBtn .editorIcon {
	opacity: 0.25;
}

.slide:hover  .slide-choices:hover .slide-choices .slide-choice.choice--empty .slide-choice-deleteBtn .editorIcon {
	opacity: 0.3;
}

.slide .slide-choices .slide-choice:hover .slide-choice-deleteBtn .editorIcon {
	opacity: 0.35;
}

.slide .slide-choices .slide-choice.choice--empty:hover .slide-choice-deleteBtn .editorIcon {
	opacity: 0.8;
	fill: $red;
}

//need to refactor
.slide .slide-choices .slide-choice:focus-within .slide-choice-deleteBtn .editorIcon {
	opacity: 0.4;
}

.slide .slide-choices .slide-choice .slide-choice-deleteBtn:hover .editorIcon {
	fill: $red;
	opacity: 1;
}

//

.slide:hover .slide-choices .slide-choice .slide-choice-deleteBtn{
	opacity: 1;
}

.slide .slide-choices:hover .slide-choice .slide-choice-deleteBtn {
	background: rgba(0,0,25,0.01);
}

.slide .slide-choices .slide-choice:hover .slide-choice-deleteBtn {
	background: rgba(0,0,25,0.02);
}

// Maybe refactor
.slide .slide-choices .slide-choice:focus-within .slide-choice-deleteBtn {
	opacity: 0;
}


.slide .slide-choices .slide-choice .slide-choice-deleteBtn:hover {
	background: transparentize($red, 0.85);
	opacity: 1;
}

