.sectionHome-activateELearningModalContainer{
	position: fixed;
	z-index: 9999;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: rgba(5,5,10,0.75);
	
	display: flex;
	
	align-items: center;
	justify-content: center;
}

.sectionHome-activateELearningModal{
	border-radius: 12px;
	
	background: rgb(250,250,255);
	border: 1px solid $gray-5;
	box-shadow: 
		0px 8px 16px 0px rgba(5,5,10,0.12),
		0px 16px 32px 0px rgba(5,5,10,0.08);

	box-sizing: border-box;

	overflow: hidden;

	width: 520px;
	min-height: 480px;

	padding-top: 34px;
	padding-bottom: 80px;
	padding-right: 80px;
	padding-left: 80px;

	position: relative;

	cursor: default;
}

.sectionHome-activateELearningModal-iconContainer{
	position: absolute;
	left: 26px;
	top: 30px;
}

.sectionHome-activateELearningModal-iconContainer svg{
	width: 35px;
	height: 35px;
	fill: transparentize($blue-bold, 0.8);
}

.sectionHome-activateELearningModal-header{
	font-size: 22px;
	font-weight: $lato-heavy;
	color: $textBlackDark;

	margin-bottom: 10px;
}

.sectionHome-activateELearningModal-checkbox-header a,
.sectionHome-activateELearningModal-checkbox-header a:visited{
	color: $textBlue;
	text-decoration: underline !important;
	cursor: pointer;
}


.sectionHome-activateELearningModal-description{
	font-size: 14px;
	font-weight: $lato-semibold;
	color: lighten($textBlack, 5%);

	margin-bottom: 6px;
}

.sectionHome-activateELearningModal-description a,
.sectionHome-activateELearningModal-description a:visited{
	color: $textBlue;
	text-decoration: underline !important;
}


.sectionHome-activateELearningModal-divider{
	width: 100%;
	height: 1px;
	background: $gray-12;

	margin-bottom: 18px;
	margin-top: 18px;
}

.sectionHome-activateELearningModal-checkbox{
	margin-bottom: 20px;
}

.sectionHome-activateELearningModal-checkbox-header{
	font-size: 15px;
	font-weight: $lato-heavy;
	color: $textBlack;
	margin-bottom: 8px;
}

.sectionHome-activateELearningModal-checkbox .featureModal-selectControl{
	// background: $tempr;
	width: 150px;
	cursor: pointer;

	// margin-bottom: 4px;

	border-radius: 4px;
	margin-left: -4px;
}

.sectionHome-activateELearningModal-checkbox--consent{
	padding-bottom: 10px;
}

.sectionHome-activateELearningModal-checkbox--consent .featureModal-selectControl{
	width: 350px;
	height: 40px;
}

.sectionHome-activateELearningModal-checkbox .featureModal-selectControl:hover{
	background: $blue-extraLight;
}

.sectionHome-activateELearningModal-checkbox .featureModal-selectControl-checkbox{
	margin-top: 5px;
	margin-left: 4px;
	margin-right: 6px;
}

.sectionHome-activateELearningModal-checkbox .featureModal-selectControl-label{
	margin-top: 3px;
	cursor: pointer;
	font-size: 14px;
	letter-spacing: 0;
	color: $textBlack;
}

.sectionHome-activateELearningModal-buttonContainer{
	position: absolute;
	bottom: 0px;
	left: 80px;
	right: 80px;
	height: 85px;

}

.sectionHome-activateELearningModal-buttonContainer button.btn.activateclassmodalbtn{
	width: 100%;
	height: 44px;
	border-radius: 5px;
	font-size: 18px;
	font-weight: $lato-heavy;
	color: $white;

	cursor: pointer;

	margin: 0 auto;
	margin-bottom: 5px;
}

.sectionHome-activateELearningModal-buttonContainer-cancelBtn{
	background: none;
	width: 100px;


	font-size: 14px;
	font-weight: $lato-medium;
	color: $textLight;

	cursor: pointer;
	height: 26px;

	margin: 0 auto;


	display: flex;
	align-items: center;
	justify-content: center;

	user-select: none;
}



