


.syncLiveView-toolbar-center-countDownTimerContainer{
	position: absolute;
	left: 50%;
	transform: translateX(-50%);
	top: 0px;
	bottom: 0px;
	width: 300px;

	display: flex;

	z-index: 1000; //temp

}

.syncLiveView-toolbar-center-countDownTimer{
	position: absolute;
	top: 0px;
	left: 0px;
	right: 0px;
	bottom: 0px;

	display: flex;
}

.syncLiveView-toolbar-center-countDownTimer-centerContainer{	
	flex-grow: 0;
	flex-shrink: 0;
	display: flex;
	align-items: center;
	justify-content: center;
}

.syncLiveView-toolbar-center-countDownTimer-rightContainer,
.syncLiveView-toolbar-center-countDownTimer-leftContainer{
	width: 10px;
	flex-grow: 1;
	flex-shrink: 1;
	height: 100%;

	display: flex;
	align-items: center;
}

.syncLiveView-toolbar-center-countDownTimer-leftContainer{
	justify-content: flex-end;
}

.syncLiveView-toolbar-center-countDownTimer-rightContainer{
	justify-content: flex-start;
}

//
// TIMER
// 

.syncLiveView-toolbar-center-countDownTimer-timer{
	width: 96px;
	height: 30px;

	border-radius: 4px;

	background: $blue;

	display: flex;
	align-items: center;
	justify-content: center;
	letter-spacing: 0.02em;

	color: $white;
	font-size: 17px;
	font-weight: $lato-bold;

	position: relative;
}

.syncLiveView-toolbar-center-countDownTimer-timer:hover
button.syncLiveView-toolbar-center-countDownTimer-timer-cancel{
	opacity: 1;
}


span.syncLiveView-toolbar-center-countDownTimer-timer-minutes--zeroMinutes{
	opacity: 0.5;
}


button.syncLiveView-toolbar-center-countDownTimer-timer-cancel{
	position: absolute;
	width: 96px;
	height: 30px;
	top: 0px;
	left: 0px;

	z-index: 5;
	background: none;
	box-shadow: none;
	border: none;
	padding: 0px;

	opacity: 0;

	cursor: pointer;
	
	background: #E7F1FD;
	background: $white;
	background: lighten($blue, 8%);
	border: 1px solid $lineDark;
	border: 1px solid lighten($blue, 8%);
	border-radius: 4px;

	color: $textBlack;
	color: $blue-bold;
	color: $white;

	display: flex;
	align-items: center;
	justify-content: center;
	letter-spacing: 0.025em;
	
	font-size: 15px;
	font-weight: $lato-bold;
}
	

.syncLiveView-toolbar-center-countDownTimer-timer-submitLoading-minutes{
	color: $invisible;
}

.syncLiveView-toolbar-center-countDownTimer-timer-submitLoading-seconds{
	color: $invisible;
}


@keyframes syncLiveViewSubmitLoadingTimer {
  0% { opacity: 0}
  50% { opacity: 1}
  100%{ opacity: 0}  
}


.syncLiveView-toolbar-center-countDownTimer-timer-submitLoading-dot{
	animation: 1s syncLiveViewSubmitLoadingTimer infinite;
	
}




//
// SET TIIMER
// 

button.syncLiveView-toolbar-center-countDownTimer-setTimerBtn{
	width: 100%;
	height: 30px;
	border-radius: 5px;

	min-width: 120px;

	box-shadow: none;
	border: none;
	background: none;
	outline: none;

	background: $white;
	border: 1px solid $lineDark;
	cursor: default;
	box-shadow: 0px 1px 2px 0px rgba(0,0,0,0.08);

	font-size: 14.5px;
	font-weight: $lato-bold;
	color: $textBlack;

	display: flex;
	align-items: center;
	justify-content: center;

}



button.syncLiveView-toolbar-center-countDownTimer-setTimerBtn--unset{
	opacity: 0.6;
	color: $textMid;
	font-weight: $lato-semibold;
	box-shadow: none;
}

.syncLiveView-toolbar-center:hover
button.syncLiveView-toolbar-center-countDownTimer-setTimerBtn--unset,
.liveView-toolbar-hybridCountdownContainer:hover
button.syncLiveView-toolbar-center-countDownTimer-setTimerBtn--unset{
	opacity: 1;
}

button.syncLiveView-toolbar-center-countDownTimer-setTimerBtn--scanningNotSet{
	opacity: 0;
	transition: all 100ms linear;
}

.syncLiveView-toolbar-center:hover
button.syncLiveView-toolbar-center-countDownTimer-setTimerBtn--scanningNotSet,
.liveView-toolbar-hybridCountdownContainer:hover
button.syncLiveView-toolbar-center-countDownTimer-setTimerBtn--scanningNotSet,{
	opacity: 1;
	transition: opacity 100ms linear;
}



.syncLiveView-toolbar-center-countDownTimer-setTimerBtn-iconContainer{
	width: 12px;
	height: 12px;

	// background: $tempr;

	margin-right: 5px;
	position: relative;
	top: -1px;
}

.syncLiveView-toolbar-center-countDownTimer-setTimerBtn-iconContainer svg{
	// display: none;
	width: 12px;
	height: 12px;
}

button.syncLiveView-toolbar-center-countDownTimer-setTimerBtn:hover{

}


button.syncLiveView-toolbar-center-countDownTimer-addTenBtn,
button.syncLiveView-toolbar-center-countDownTimer-minusTenBtn{
	width: 45px;
	height: 30px;
	background: none;
	border: none;
	box-shadow: none;
	outline: none;

	cursor: pointer;

	margin: 3px;

	font-size: 13.5px;
	font-weight: $lato-bold;
	color: $textMid;

	display: flex;
	align-items: center;
	justify-content: center;

	border-radius: 3px;

	opacity: 0;
	transition: all 200ms cubic-bezier(0.2, 0.3, 0.25, 0.9);
}

.syncLiveView-toolbar-center:hover button.syncLiveView-toolbar-center-countDownTimer-addTenBtn,
.syncLiveView-toolbar-center:hover button.syncLiveView-toolbar-center-countDownTimer-minusTenBtn,
.liveView-toolbar-hybridCountdownContainer:hover button.syncLiveView-toolbar-center-countDownTimer-addTenBtn,
.liveView-toolbar-hybridCountdownContainer:hover button.syncLiveView-toolbar-center-countDownTimer-minusTenBtn{
	opacity: 0.5;
	transition: all 200ms cubic-bezier(0.2, 0.3, 0.25, 0.9);
	transition-delay: 50ms;
}

.syncLiveView-toolbar-center button.syncLiveView-toolbar-center-countDownTimer-addTenBtn:hover,
.syncLiveView-toolbar-center button.syncLiveView-toolbar-center-countDownTimer-minusTenBtn:hover,
.liveView-toolbar-hybridCountdownContainer button.syncLiveView-toolbar-center-countDownTimer-addTenBtn:hover,
.liveView-toolbar-hybridCountdownContainer button.syncLiveView-toolbar-center-countDownTimer-minusTenBtn:hover{
	background: rgba(0,0,20,0.02);
	color: $textDark;
	opacity: 1;
	transition: all 200ms cubic-bezier(0.2, 0.3, 0.25, 0.9);
}

.syncLiveView-toolbar-center button.syncLiveView-toolbar-center-countDownTimer-addTenBtn:active,
.syncLiveView-toolbar-center button.syncLiveView-toolbar-center-countDownTimer-minusTenBtn:active,
.liveView-toolbar-hybridCountdownContainer button.syncLiveView-toolbar-center-countDownTimer-addTenBtn:active,
.liveView-toolbar-hybridCountdownContainer button.syncLiveView-toolbar-center-countDownTimer-minusTenBtn:active{
	background: rgba(0,0,20,0.04);
}


//
// PRESENT DROPDOWN
//
.syncLiveView-toolbar-center-countDownTimer-presetDropdownContainer{
	
	width: 210px;

	position: absolute;
	left: calc(50% - 105px);
	top: 38px;

	height: 205px;

	opacity: 0;

	transform: translateY(-5px);

	transition: all 200ms cubic-bezier(0.2, 0.3, 0.25, 0.9);

	// background: $tempr;

	pointer-events: none;

	cursor: pointer;
}

button.syncLiveView-toolbar-center-countDownTimer-setTimerBtn:hover ~ .syncLiveView-toolbar-center-countDownTimer-presetDropdownContainer,
.syncLiveView-toolbar-center-countDownTimer-presetDropdownContainer:hover{
	opacity: 1;
	transform: translateY(0px);
	transition: all 200ms cubic-bezier(0.2, 0.3, 0.25, 0.9);
	pointer-events: auto;
}

.syncLiveView-toolbar-center-countDownTimer-centerContainer--selectedPreset
.syncLiveView-toolbar-center-countDownTimer-presetDropdownContainer{
	display: none !important;
}

.syncLiveView-toolbar-center-countDownTimer-presetDropdown{
	position: absolute;
	
	top: 3px;
	left: 0px;
	right: 0px;
	bottom: 0px;

	background: $white;
	padding-top: 6px;
	padding-bottom: 6px;
	padding-left: 4px;
	padding-right: 4px;
	overflow: none;
	box-sizing: border-box;
	border: 1px solid $lineDark;
	border-radius: 5px;
	box-shadow: 0px 4px 8px 0px rgba(0,0,0,0.12);
	z-index: 500;

	display: flex;
	flex-wrap: wrap;
}



// PRESETS

.syncLiveView-toolbar-center-countDownTimer-presetDropdown:hover
button.syncLiveView-toolbar-center-countDownTimer-presetDropdown-presetBtn{
	opacity: 0.75;
	transition: opacity 100ms linear;
}

.syncLiveView-toolbar-center-countDownTimer-presetDropdown:hover
button.syncLiveView-toolbar-center-countDownTimer-presetDropdown-presetBtn:hover{
	opacity: 1;
	transition: opacity 50ms linear;
}




button.syncLiveView-toolbar-center-countDownTimer-presetDropdown-presetBtn{
	border: none;	
	outline: none;
	background: none;
	box-shadow: none;

	width: 50%;	

	position: relative;

	height: 30px;
	border-radius: 0px;	


	cursor: pointer;

	opacity: 1;
}

.syncLiveView-toolbar-center-countDownTimer-presetDropdown-presetBtn-label{
	position: absolute;
	top: 0px;
	right: 0px;
	bottom: 0px;
	left: 0px;

	display: flex;
	align-items: center;
	justify-content: center;

	font-size: 14px;
	font-weight: $lato-bold;
	color: darken($textMid, 5%);

	z-index: 10;

}


.syncLiveView-toolbar-center-countDownTimer-presetDropdown:not(:hover)
button.syncLiveView-toolbar-center-countDownTimer-presetDropdown-presetBtn--active
.syncLiveView-toolbar-center-countDownTimer-presetDropdown-presetBtn-inner{
	opacity: 1;	
}

.syncLiveView-toolbar-center-countDownTimer-presetDropdown:not(:hover)
button.syncLiveView-toolbar-center-countDownTimer-presetDropdown-presetBtn--active
.syncLiveView-toolbar-center-countDownTimer-presetDropdown-presetBtn-label{
	color: darken($blue-bold, 5%);
}



// button.syncLiveView-toolbar-center-countDownTimer-presetDropdown-presetBtn--active
// .syncLiveView-toolbar-center-countDownTimer-presetDropdown-presetBtn-label{
// 	color: $textBlack;
// }

// button.syncLiveView-toolbar-center-countDownTimer-presetDropdown-presetBtn:hover
// .syncLiveView-toolbar-center-countDownTimer-presetDropdown-presetBtn-inner{
// 	opacity: 1;
// }




.syncLiveView-toolbar-center-countDownTimer-presetDropdown-presetBtn-inner{
	position: absolute;
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
	border-radius: 4px;
	background: $blue-extraLight;
	opacity: 0;
}

button.syncLiveView-toolbar-center-countDownTimer-presetDropdown-presetBtn:hover
.syncLiveView-toolbar-center-countDownTimer-presetDropdown-presetBtn-inner{
	opacity: 1;
}

button.syncLiveView-toolbar-center-countDownTimer-presetDropdown-presetBtn:active
.syncLiveView-toolbar-center-countDownTimer-presetDropdown-presetBtn-inner{
	background: transparentize($blue, 0.85);
}

button.syncLiveView-toolbar-center-countDownTimer-presetDropdown-presetBtn:hover
.syncLiveView-toolbar-center-countDownTimer-presetDropdown-presetBtn-label{
	color: darken($blue-bold, 5%);
}

button.syncLiveView-toolbar-center-countDownTimer-presetDropdown-disableTimerBtn{
	margin-top: 2px;
	width: 100%;
	background: none;	
	border: none;
	border-radius: 3px;
	font-size: 14px;
	font-weight: $lato-bold;
	color: $textLight;

	height: 30px;
	cursor: pointer;
}

button.syncLiveView-toolbar-center-countDownTimer-presetDropdown-disableTimerBtn:hover{
	color: $red;
	background: transparentize($red, 0.9);
}