//
// SLIDE MEDIA
//

.slide.slide--template--bodyLeft .slide-mediaContainer{
  display: none; // hide media containers
}

.slide.slide--template--bodyCenterChoicesMedia .slide-choice-deleteBtn svg.editorIcon,
.slide.slide--template--bodyLeftMediaRightChoicesMedia .slide-choice-deleteBtn svg.editorIcon{
  margin-top: 0px;

  width: 18px;
  height: 18px;
}

.slide.slide--template--bodyLeftMediaRight .slide-mediaContainer{
  position: absolute;
  top: 60px;
  right: 60px;
  height: 680px;
  width: 580px;  
}

.slide.slide--template--bodyRightMediaLeft .slide-mediaContainer{
  position: absolute;
  top: 60px;
  left: 60px;
  height: 680px;
  width: 580px;
}

.slide.slide--template--bodyCenter .slide-mediaContainer{
  display: none;
}

.slide.slide--template--bodyLeftMediaRightChoicesMedia .slide-mediaContainer{
  position: absolute;
  top: 60px;
  right: 60px;
  height: 311px;
  width: 580px;
}

.slide.slide--template--bodyCenterMediaCenter .slide-mediaContainer{
  flex-grow: 1;
  flex-shrink: 1;
  width: 100%;  
  background: none;
  margin-top: 12px; // this is reflected in font size calcs
}