//
// QUESTION REPORT
//

// CHOICE BLOCK

.questionReport-contentContainer{
	width: 100%;
	display: flex;
}

.questionReport-choiceDetail{
	margin-top: 24px;
	width: 100%;
	max-width: 550px;
}

.questionReport-sidePanel-innerSpacer{
	width: 100%;
}

.questionReport-choiceDetail-divider{
	width: 100%;
	height: 1px;
	background: $lineMid;
	margin-top: 12px;
	margin-bottom: 20px;
}

.questionReport-slidePreview{
	border: 1px solid $lineDark;
	border-radius: 2px;
	overflow: hidden;
	cursor: pointer;
	position: relative;
	margin-bottom: 8px;

	box-shadow: 0px 2px 4px 0px rgba(0,0,40,0.05);
}

.questionReport-slidePreview-expandLabel{
	height: 26px;
	width: 26px;

	position: absolute;
	top: 10px;
	right: 10px;

	z-index: 20;

	display: flex;
	align-items: center;
	justify-content: center;

	// background: rgba(85,85,105,0.95);	
	// background: $white;
	background: $gray-7;
	border-radius: 3px;
	
	user-select: none;
}

.questionReport-slidePreview-expandLabel .icon{
	fill: $white;
	width: 22px;
	height: 22px;
}

.questionReport-slidePreview .slide{
	border: 1px solid $invisible;
}

// .questionReport-slidePreview{
// 	transition: 200ms all ease-in-out;
// 	opacity: 0.8;
// }

// .questionReport-slidePreview:hover{
// 	opacity: 1;
// 	border: 1px solid darken($lineDark, 10%);
// 	transition: 200ms all ease-in-out;
// }

// INTERACTIONS: OPACITY MANAGEMENT

.questionReport-slidePreview 
.questionReport-slidePreview-expandLabel{
	opacity: 0;
	transition: 200ms all linear;
}

.questionReport-slidePreview:hover 
.questionReport-slidePreview-expandLabel{
	opacity: 1;
	transition: 100ms all linear;
}

@import 'QuestionReport-ChoiceDetailBlock';


/// EXPANDED REVIEW SLIDE MODAL


.expandedReviewSlideModal .modal-content{
	width: 960px;
	height: 600px;
	padding: none;
	overflow: hidden;
	border-radius: 5px;
	border: 1px solid $lineDark;
	box-shadow: 0px 4px 8px 0px rgba(0,0,40,0.12);
	// pointer-events: none;
}

.expandedReviewSlideModal .modal-content-header{
	display: none;
}


.expandedReviewSlideModal .modal-content .slide{
	border: 1px solid $invisible;
}
/// QUESTION REPORT SIDEPANEL
//similar to set report sidepanel


.questionReport .sidePanel button.btn{
	min-width: 160px;
	margin-bottom: 4px;
}

.questionReport .sidePanel button.reveal-library{
	margin-top: 12px;
	margin-bottom: 10px;
}

.questionReport .sidePanel button.archive-report,
.questionReport .sidePanel button.delete-report{
	opacity: 0.75;
}

