.editAuthorDescriptionModalContainer{
	position: fixed;
	left: 0;
	top: 0;
	bottom: 0;
	right: 0;
	z-index: 5000;
	display: flex;
	align-items: center;
	justify-content: center;

	overflow: hidden;

}

.editAuthorDescriptionModal{

	width: 700px;
	
	overflow: hidden;
	position: relative;
	z-index: 10;
	
	border-radius: 5px;

	background: rgb(252,252,255);
	border: 1px solid $gray-5;
	box-shadow: 
		0px 8px 16px 0px rgba(5,5,10,0.12),
		0px 16px 32px 0px rgba(5,5,10,0.08) ;

	box-sizing: border-box;
	padding-left: 32px;
	padding-right: 32px;

	padding-top: 22px;
	padding-bottom: 26px;

	height: 475px;
}

.editAuthorDescriptionModalBackground{
	position: absolute;
	left: 0;
	right: 0;
	bottom: 0;
	top: 0;
	background: rgba(0,0,3,0.75);
	overflow: hidden;
}

///

.editAuthorDescriptionModal-title{
	font-size: 24px;
	font-weight: $lato-heavy;
	color: $textBlackDark;

	margin-bottom: 8px;
	user-select: none;
	cursor: default;
}

.editAuthorDescriptionModal-subtitle{
	font-size: 16px;
	font-weight: $lato-medium;
	color: $textDark;
	width: 80%;	
}

// Character count

.editAuthorDescriptionModal-characterCount{
	width: 100%;

	font-size: 13.5px;

	text-align: right;
	font-weight: $lato-medium;
	color: $textLight;
	margin-bottom: 5px;
	opacity: 0;
}

.editAuthorDescriptionModal-characterCount--max{
	font-weight: $lato-bold;
	color: $textBlack;
}

.editAuthorDescriptionModal-characterCount--show{
	opacity: 1;
}

//

.editAuthorDescriptionModal-input--description{	
	box-sizing: border-box;
	flex-grow: 1;
}

.editAuthorDescriptionModal-input--description textarea{
	height: 215px;
	width: 100%;
	resize: none;
  
  border: 1px solid $lineDark;
  border-radius: 3px;
  padding-left: 8px;
  padding-top: 6px;
  padding-right: 8px;

  line-height: 1.35;
  
  box-sizing: border-box;

  font-size: 15px;
  font-weight: $lato-medium;
  color: $textBlack;
  outline: none;
  pointer-events: default;
}

.editAuthorDescriptionModal-input--description textarea:focus{
   border: 1px solid $blue;
}

.editAuthorDescriptionModal-input--description textarea.is--nonEmpty:focus{
    box-shadow: 0px 0px 4px 0px transparentize($blue, 0.5);
}


.editAuthorDescriptionModal-input--description textarea::-webkit-input-placeholder {
   color: $textInputPlaceholder !important;
   font-size: 15px;
   font-weight: $lato-medium;
}




///
/// DONE BUTTON
///

.editAuthorDescriptionModal button.btn.btn--xLarge.editauthordescriptionsubmit{
	position: absolute;
	bottom: 20px;
	left: 50%;
	transform: translateX(-50%);

	width: 360px;
	font-size: 20px;
	font-weight: $lato-heavy;
	height: 48px;
	border-radius: 5px;
}

.editAuthorDescriptionModal button.btn.btn--xLarge.editauthordescriptionsubmit:focus{
	background: darken($blue-light, 6%);
	border-color: darken($blue, 6%);
	color: $white;

	box-shadow: 0px 2px 4px 0px transparentize($blue, 0.9),
	0px 0px 14px 0px transparentize($blue, 0.7);
}

///

.editAuthorDescriptionModal-imageContainer--uploading-loadingSpinnerContainer{
	position: relative;
	
	display: flex;
	align-items: center;
	justify-content: center;
}

.editAuthorDescriptionModal-imageContainer--uploading-loadingSpinner{

	height: 40px;
	width: 40px;
  
  border-radius: 60px;

  border: 5px transparentize($blue, 0.8) solid;
  border-top: 5px $blue solid;

  animation: spinnerRotateAnimation .5s infinite linear;
}


///
/// CLOSE BUTTON
///

.editAuthorDescriptionModal-closeBtn{
	position: absolute;
	width: 36px;
	height: 36px;
	top: 20px;
	right: 20px;
	cursor: pointer;
	border-radius: 18px;

	opacity: 0.2;
	transition: opacity 100ms linear;

	z-index: 100;
}

.editAuthorDescriptionModal-closeBtn:hover{
	opacity: 0.7;
	transition: opacity 50ms linear;
}

.editAuthorDescriptionModal-closeBtn:active{
	opacity: 1;
}

.editAuthorDescriptionModal-closeBtn .icon{
	width: 36px;
	height: 36px;
	fill: $gray-6;
}