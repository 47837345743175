//
// PAGE HEADER - FOLDER NAV ROW
// this is breadcrumb like UI available in Library if you navigate into folders
// also available in Discover
//

//
// FOLDER BREADCRUMB ROW
.pageHeaderTop-folderNavRow{
	position: absolute;
	top: 6px;
	left: 0px;
	display: flex;
	width: 100%;
	user-select: none;
	cursor: default;
}

// FOLDER PATH
.pageHeaderTop-folderNavRow-folderPathItem{
	flex-shrink: 1;
	max-width: 150px;

	padding-left: 34px;
	margin-right: 4px;


	position: relative;
	font-weight: $lato-semibold;
	color: $textMid;
	font-size: 14px;
	
	line-height: 1.4;

	white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  cursor: pointer;	
}

.pageHeaderTop-folderNavRow-folderPathItem.is--currentFolder{
	flex-grow: 1;
}

.pageHeaderTop-folderNavRow-folderPathItem.is--library{
	padding-left: 0px;
	flex-shrink: 0;
}

.pageHeaderTop-folderNavRow-folderPathItem .icon.chevron-right{
	height: 10px;
	width: 10px;
	fill: $textLight;
	opacity: 0.4;
	
	position: absolute;
	left: 0px;
	top: 6px;
}

.pageHeaderTop-folderNavRow-folderPathItem .icon.folder{
	height: 13px;
	width: 13px;
	
	position: absolute;
	left: 15px;
	top: 4px;
}

.pageHeaderTop-folderNavRow-folderPathItem .icon.folder #top,
.pageHeaderTop-folderNavRow-folderPathItem .icon.folder #main{
	fill: $textLight;
	opacity: 0.5;
}

.pageHeaderTop-folderNavRow-folderPathItem.is--currentFolder .icon.folder #top,
.pageHeaderTop-folderNavRow-folderPathItem.is--currentFolder .icon.folder #main{
	fill: $blue;
	opacity: 1;
}

.pageHeaderTop-folderNavRow-folderPathItem:hover .icon.folder #top,
.pageHeaderTop-folderNavRow-folderPathItem:hover .icon.folder #main{
	opacity: 0.6;
}

.pageHeaderTop-folderNavRow-folderPathItem:hover{
	color: $textBlack;
}

.pageHeaderTop-folderNavRow-folderPathItem:hover.is--currentFolder .icon.folder #top,
.pageHeaderTop-folderNavRow-folderPathItem:hover.is--currentFolder .icon.folder #main{
	opacity: 1;
}

.pageHeaderTop-folderNavRow-folderPathItem.is--currentFolder{
	color: $textBlackDark;
}

.pageHeaderTop-folderNavRow-folderPathItem.is--library .icon.shared,
.pageHeaderTop-folderNavRow-folderPathItem.is--library .icon.globe-shared,
.pageHeaderTop-folderNavRow-folderPathItem.is--library .icon.globe-shared-author{
	height: 14px;
	width: 14px;
	fill: $gray-11;
	position: relative;
	top: 2px;
	margin-right: 3px;
}


// FOR DISCOVER
// tweak styling 
.pageHeaderTop--discoverMarket .pageHeaderTop-folderNavRow-folderPathItem.is--currentFolder{
	padding-left: 13px;
}

.pageHeaderTop--discoverMarket .pageHeaderTop-folderNavRow-folderPathItem .icon.chevron-right{
	top: 5px;
}