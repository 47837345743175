
.featureModal-configControls-optionTabs{
	border-bottom: 1px solid $lineMid;
	display: flex;
	width: 100%;
	margin-bottom: 24px;
}

.featureModal-configControls-optionTabs-optionTab{
	width: 110px;
	height: 30px;
	font-size: 14px;
	text-transform: uppercase;
	
	display: flex;

	align-items: center;
	justify-content: center;
	margin-bottom: -1px;

	cursor: pointer;
	user-select: none;
}

.featureModal-configControls-optionTabs-optionTab--active{
	font-weight: $lato-heavy;
	color: $textBlue;
	border-bottom: 2px solid $blue;
}

.featureModal-configControls-optionTabs-optionTab--inactive{
	font-weight: $lato-bold;
	color: $textLight;
	border-bottom: 2px solid $invisible;	
}

.featureModal-configControls-optionTabs-optionTab--inactive:hover{
	color: darken($textLight, 15%);
	border-bottom: 2px solid $lineMid;	
}




