//
// SET REPORT
//

// SECTION HEADER
.setReport-sectionHeader{
	cursor: default;

	font-size: 15px;
	font-weight: $lato-bold;
	color: $textMid;
	letter-spacing: 0.02em;
	text-transform: uppercase;

	margin-top: 28px;
	margin-bottom: 6px;

	display: flex;
	align-items: center;
}

.setReport-sectionHeader .gridColumnToggle{
	text-transform: none;
}

// STUDENT LIST
.setReport-studentListContainer{
	margin-top: 12px;
	padding-bottom: 24px;
	border-bottom: 1px solid $lineMid;
}

.setReport-studentList{
	column-count: 4;
  column-gap: 28px;
	width: 100%;
	overflow: hidden;	
}

.setReport-studentList-blockLine{
	// blocks top border of top in each column
	position: relative;
	z-index: 5;
	width: 100%;
	border-bottom: 2px solid $white;
	margin: 0 auto;
	margin-bottom: -1px;
}


.setReport-studentCard{
	
	user-select: none; 
	cursor: default;

 	display: inline-block;
 	vertical-align: top;

 	break-inside: avoid-column;
 	-webkit-column-break-inside: avoid;

	width: 100%;

	height: 30px;
	border-top: 1px solid $lineMid;
	
	position: relative;

	display: flex;
	align-items: center;

}

.setReport-studentCard .setReport-studentCard-name{
	line-height: 1.9;
  	
	font-weight: $lato-semibold;
	color: $textBlack;
	font-size: 15px;


	width: 10px;
	flex-grow: 1;

	white-space: nowrap;
	overflow: hidden;

	white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}


.setReport-studentCard .scorePill{
	flex-grow: 0;
}


.setReport .slideGrid{
	margin-top: 0px;
}


/// SET REPORT SIDEPANEL

.setReport .sidePanel{
	margin-top: 100px;
}

.setReport .sidePanel button{
	min-width: 160px;
	margin-bottom: 4px;
}

.setReport .sidePanel .setReport-continuePlayingTimestamp{
	font-size: 12px;
	font-weight: $lato-medium;
	color: $textDark;
	margin-bottom: 10px;
}

.setReport .sidePanel .setReport-continuePlayingTimestamp{
	font-size: 12px;
	font-weight: $lato-medium;
	color: $textMid;
	margin-top: 4px;
	width: 150px;
	text-align: center;
}

.setReport .sidePanel button.reveal-library{
	margin-top: 10px;
	margin-bottom: 10px;
}

///  STUDENT SORT TOGGLE

.setReport-studentSortToggle,
.setReport-showAnsweredToggle{
	cursor: default;

	font-size: 14px;
	font-weight: $lato-bold;
	color: $textLight;
	letter-spacing: 0.02em;
	text-transform: uppercase;

	display: flex;
	align-items: center;
}

.setReport-showAnsweredToggle,
.setReport-studentSortToggle{
	margin-left: auto;
}

.setReport-showAnsweredToggle-toggle,
.setReport-studentSortToggle-toggle{
	padding-left: 5px;
	padding-right: 5px;
}

.setReport-showAnsweredToggle-toggle--active,
.setReport-studentSortToggle-toggle--active{
	color: $textBlue;
}


.setReport-showAnsweredToggle-toggle:hover,
.setReport-studentSortToggle-toggle:hover{
	color: $textMid;
}

.setReport-showAnsweredToggle-toggle--active:hover,
.setReport-studentSortToggle-toggle--active:hover{
	color: darken($textBlue, 5%);
}