//
// PAGE LAYOUT TEMPLATES
// these have responsiveness built in
// see readmes/PAGELAYOUT.md for more information on how this works
//


//
// GLOBAL PAGE
//

.page-centerPanel{	
	flex-grow: 1; // pick up any rounding errors
	flex-shrink: 1; // pick up any rounding errors
}

.page-leftPanel,
.page-rightPanel{
	flex-grow: 0;
	flex-shrink: 0;
}


@media (max-width: 1139px) {
	.page--defaultLayout .page-rightPanel .sidePanelContainer,
	.page--wideLayout .page-rightPanel .sidePanelContainer{
		opacity: 0; //  refactor later if we keep this
	}
}


// LEFT PANEL and SIDENAV

.page-leftPanel,
.sideNavContainer,
.topNav-left{
	@include poly-fluid-sizing('width', (
		1020px: 185px, // this means at 1020px screen width, this element is 185px wide		
		1140px: 185px, // at 1140px, this element will be 185px wide
		1280px: 200px,
		1366px: 215px,
		1600px: 320px,
		1920px: 450px)
	);
}

.sideNavContainer .sideNav{		
	@include poly-fluid-sizing('width', (
		1020px: 165px,	
		1140px: 165px,		
		1280px: 172px,
		1366px: 180px,
		1600px: 200px,
		1920px: 220px)
	);
}


//
// CORE PAGE LAYOUTS
//

//
// DEFAULT LAYOUT
//

.page--defaultLayout .page-centerPanel{	
	@include poly-fluid-sizing('width', (
		1020px: 769px,		
		1140px: 769px,		
		1280px: 769px,
		1366px: 769px,
		1600px: 839px,
		1920px: 839px)
	);
}

.page--defaultLayout .page-rightPanel{	
	@include poly-fluid-sizing('width', (
		1020px: 66px,		
		1140px: 186px,		
		1280px: 311px,
		1366px: 382px,
		1600px: 441px,
		1920px: 631px)
	);
}

// sidePanel width
.page--defaultLayout .sidePanel{	
	@include poly-fluid-sizing('width', (		
		1140px: 160px,		
		1280px: 265px,
		1366px: 285px,
		1600px: 300px,
		1920px: 300px)
	);
}

// internal leftMargin between rightPanel and sidePanel
.page--defaultLayout .sidePanelContainer{	
	@include poly-fluid-sizing('padding-left', (
		1140px: 10px,		
		1280px: 20px,
		1366px: 20px,
		1600px: 20px,
		1920px: 20px)
	);
}



//
// SIDEPANEL LAYOUT
//

.page--sidePanelLayout .page-centerPanel{	
	@include poly-fluid-sizing('width', (
		1020px: 610px,		
		1140px: 700px,		
		1280px: 769px,
		1366px: 769px,
		1600px: 839px,
		1920px: 839px)
	);
}

.page--sidePanelLayout .page-rightPanel{	
	@include poly-fluid-sizing('width', (
		1020px: 225px,		
		1140px: 255px,		
		1280px: 311px,
		1366px: 382px,
		1600px: 441px,
		1920px: 631px)
	);
}


// sidePanel width
.page--sidePanelLayout .sidePanel{	
	@include poly-fluid-sizing('width', (		
		1020px: 210px,
		1140px: 225px,		
		1280px: 265px,
		1366px: 285px,
		1600px: 300px,
		1920px: 300px)
	);
}

// internal leftMargin between rightPanel and sidePanel
.page--sidePanelLayout .sidePanelContainer{	
	@include poly-fluid-sizing('padding-left', (
		1020px: 10px,	
		1140px: 10px,	
		1280px: 20px,
		1366px: 20px,
		1600px: 20px,
		1920px: 20px)
	);
}

//
// WIDE LAYOUT
//

// Section Home, Search
// with SidePanel: Set Detail, Set Report

.page--wideLayout .page-centerPanel{	
	@include poly-fluid-sizing('width', (
		1020px: 735px,		
		1140px: 735px,		
		1280px: 865px,
		1366px: 890px,
		1600px: 925px,
		1920px: 925px,)
	);
}

.page--wideLayout .page-rightPanel{	
	@include poly-fluid-sizing('width', (
		1020px: 100px,		
		1140px: 220px,		
		1280px: 215px,
		1366px: 261px,
		1600px: 355px,
		1920px: 545px)
	);
}


.page--wideLayout .sidePanel{	
	@include poly-fluid-sizing('width', (				
		1140px: 200px,		
		1280px: 190px,
		1366px: 231px,
		1600px: 320px,
		1920px: 510px)
	);
}

// internal leftMargin between rightPanel and sidePanel

.page--wideLayout .sidePanelContainer{	
	@include poly-fluid-sizing('padding-left', (				
		1140px: 5px,		
		1280px: 10px,
		1366px: 15px,
		1600px: 20px,
		1920px: 20px)
	);
}


//
// EXTRA-WIDE LAYOUT
//

// Discover (no SidePanel)

.page--extraWideLayout .page-centerPanel{	
	@include poly-fluid-sizing('width', (
		1020px: 800px,		
		1140px: 910px,		
		1280px: 1030px,
		1366px: 1091px,
		1600px: 1215px,
		1920px: 1270px,)
	);
}

.page--extraWideLayout .page-rightPanel{	
	@include poly-fluid-sizing('width', (
		1020px: 35px,		
		1140px: 45px,		
		1280px: 50px,
		1366px: 60px,
		1600px: 65px,
		1920px: 200px,)
	);
}



//
// PAGE HEADER STICKY
// left is leftPanel, width is centerPanel
//

	.pageHeader.pageHeaderSticky{
		position: fixed;
		@include poly-fluid-sizing('left', (
			1020px: 185px,		
			1140px: 185px,		
			1280px: 200px,
			1366px: 215px,
			1600px: 320px,
			1920px: 450px)
		);
	}

	.page--defaultLayout .pageHeaderSticky{
		@include poly-fluid-sizing('width', (
			1020px: 769px,		
			1140px: 769px,		
			1280px: 769px,
			1366px: 769px,
			1600px: 839px,
			1920px: 839px,
			2560px: 1475px) // <-- bit of a hack! using flex-grow on center layout
			);
	}

	.page--sidePanelLayout .pageHeaderSticky{
		@include poly-fluid-sizing('width', (
			1020px: 610px,		
			1140px: 700px,		
			1280px: 769px,
			1366px: 769px,
			1600px: 839px,
			1920px: 839px,
			2560px: 1475px)
			);
	}

	.page--wideLayout .pageHeaderSticky{
		@include poly-fluid-sizing('width', (
			1020px: 735px,		
			1140px: 735px,		
			1280px: 865px,
			1366px: 890px,
			1600px: 925px,
			1920px: 925px,
			2560px: 1563px)
		);

	}


	.page--extraWideLayout .pageHeaderSticky{
		@include poly-fluid-sizing('width', (
			1020px: 800px,		
			1140px: 910px,		
			1280px: 1035px,
			1366px: 1091px,
			1600px: 1215px,
			1920px: 1270px,
			2560px: 1910px)
		);
	}