$canvasVideoControls-seekRed: #FF2323;
$canvasVideoControls-internalSideMargin: 10px;

.slide-mediaContainer--video{
  background: none;
  position: relative;
  text-align: left;

  overflow: initial;
}

//
// FULLY STATIC STATIC
// thumbnail with duration etc.

.slide-mediaContainer--video
.slide-media--video--static-thumbnailContainer{
  position: absolute;
  right: 0px;
  top: 0px;
  background: $white;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0px 2px 4px 0px rgba(0,0,20,0.12), 0px 0px 0px 1px rgba(0,0,20,0.12);

}

// THUMBNAIL
.slide-mediaContainer--video
img.slide-media--video--static-thumbnail{
  user-select: none;
  height: 100%;
  width: 100%;
  position: relative;
  z-index: 10;
  // pointer-events: none;

  // opacity: 0;
}



// TITLE
.slide-mediaContainer--video--static-topLeftContainer{
  position: absolute;
  top: 0px;
  left: 0px;

  height: 60px;

  transform-origin: top left;

  z-index: 20;

  display: flex;
  align-items: top;
  justify-content: flex-start;

  // background: $tempr;

  cursor: default;
  user-select: none;
}

.slide-mediaContainer--video--static-videoTitle{
  border-radius: 7px;
  margin-top: 9px;
  margin-left: 9px;

  height: 34px;

  padding-left: 14px;
  padding-right: 14px;

  background: rgba(0,0,0,0.7);

  display: flex;
  align-items: center;
  justify-content: flex-start;

  color: $white;

  font-size: 20px;
  font-weight: $lato-bold;
  letter-spacing: 0.05em;

  max-width: calc(100% - 45px)
}

.slide-mediaContainer--video--static-videoTitle-title{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  position: relative;
  top: -1px;
}

.slide-mediaContainer--video--static-videoTitle-iconContainer{
  width: 33px;
  flex-shrink: 0;
  flex-grow: 0;
  height: 37px;
  margin-right: 5px;
  margin-left: -9px;
  // background: $white;

  display: flex;
  align-items: center;
  justify-content: center;
}

.slide-mediaContainer--video--static-videoTitle svg.editorIcon{
  width: 24px;
  height: 24px;

  position: relative;

  // top: -1px;
}

.slide-mediaContainer--video--static-videoTitle svg.editorIcon #bg{
  fill: #FF2323;
  //fill: $white;
}

.slide-mediaContainer--video--static-videoTitle svg.editorIcon #play{
  fill: white;
}

// DURATION

.slide-mediaContainer--video--static-bottomRightContainer{
  position: absolute;

  right: 0px;

  height: 50px;
  margin-top: -50px;
  width: 200px;

  z-index: 20;

  // background: $tempb;

  display: flex;
  align-items: flex-end;
  justify-content: flex-end;

  cursor: default;
  user-select: none;
}

.slide-mediaContainer--video--static-duration{
  border-radius: 7px;

  margin-bottom: 9px;
  margin-right: 9px;

  height: 34px;

  padding-left: 9px;
  padding-right: 9px;

  background: rgba(0,0,0,0.7);

  display: flex;
  align-items: center;
  justify-content: center;

  color: $white;

  font-size: 20px;
  font-weight: $lato-bold;
  letter-spacing: 0.05em;

  display: flex;
}

.slide-mediaContainer--video--static-duration-duration{
  padding-bottom: 1px;
}


//
// TRIM INDICATOR

.slide-mediaContainer--video--static-trimIndicatorContainer{
  position: absolute;

  left: 0px;
  bottom: 0px;
  height: 50px;
  margin-top: -50px;



  z-index: 20;
}

.slide-mediaContainer--video--static-trimIndicator{
  position: absolute;
  left: 15px;
  right: 8px;
  bottom: 23px;
  height: 6px;

  border-radius: 3px;
  overflow: hidden;

  background: rgba(255,255,255,0.4);
  // background: rgba(0,0,0,0.4);

  cursor: default;
  user-select: none;

  opacity: 0.9;
}

.slide-mediaContainer--video--static-trimIndicator-trim{
  position: absolute;
  top: 0px;
  bottom: 0px;
  background: rgba(255,255,255,1);
  // opacity: 0.75;
  border-radius: 0px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  box-sizing: border-box;
  padding-left: 1px;
  padding-right: 1px;

}





















// temp
// .slide-mediaContainer-tempControls{
// 	margin-top: 420px;
// }


.slide-media--video-videoContainer{
  position: absolute;
  top: 0px;
  margin: auto;
  left: 0px;
  right: 0px;
}

.slide-media--video-videoContainer--isSeeking{
  cursor: pointer;
}

.slide--template--bodyLeftMediaRightChoicesMedia
.slide-media--video-videoContainer{
  // position top right
  top: 0px;
  right: 0px;
  left: unset;
  margin: unset;
}


.slide-media--video-videoScaleContainer{
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  transform-origin: top left;
}

.slide-media--video-videoContainer--isSeeking
.slide-media--video-videoScaleContainer{
  pointer-events: none;
}

iframe.slide-media--video-video{
  transform-origin: top left;
  position: absolute;
  width: 100%;
  height: 100%;

  // opacity: 0;
}







.liveView-mediaItem--promptVideoPlayer-startZoomControlBarContainer,
.slide-media--video-controlBarContainer{
  position: absolute;
  left: 0px;

  // height: 46px;
  height: 35px;
  z-index: 100;

  transform-origin: bottom left;
}

.slide-media--video-controlBarContainer{
  bottom: 0px;
}

.liveView-mediaItem--choiceVideo-startZoomControlBarContainer{
  position: absolute;
  bottom: 0px;
  // bottom: -80px;
  left: 0px;
  transform-origin: bottom left;
}

.liveView-mediaItem--choiceVideo-endZoomControlBarContainer{
  transform-origin: bottom left;
}

.slide-media--video-controlBar{
  opacity: 0;
}

.liveView-mediaItem--choiceVideo-startZoomControlBarContainer .slide-media--video-controlBar{
  transition-delay: 0ms;
  transition: opacity 150ms linear;
}

.slide-media--video-video:hover ~ .slide-media--video-controlBarContainer .slide-media--video-controlBar,
.liveView-mediaItem--promptVideoPlayer:hover .slide-media--video-controlBar,
.liveView-mediaItemContainer--choiceVideoContainer:hover .liveView-mediaItem--choiceVideo-startZoomControlBarContainer .slide-media--video-controlBar,
.slide-media--video-videoContainer:hover .slide-media--video-controlBar,
.slide-media--video-controlBarContainer:hover .slide-media--video-controlBar,
.liveView-mediaItem--promptVideoPlayer-startZoomControlBarContainer--showMouseMoveControls .slide-media--video-controlBar,
.liveView-mediaItem--promptVideoPlayer-endZoomControlBarContainer--show .slide-media--video-controlBar,
.liveView-mediaItem--promptVideoPlayer-endZoomControlBarContainer:hover .slide-media--video-controlBar,
.liveView-mediaItem--choiceVideo-endZoomControlBarContainer--show .slide-media--video-controlBar,
.liveView-mediaItem--choiceVideo-endZoomControlBarContainer:hover .slide-media--video-controlBar{
  transition: opacity 25ms linear;
  opacity: 1;

  // probably need to tidy up to set to 0 if no mouse movement and not hovered directly ontop of controlbarContainer
}

.liveView-mediaItem--choiceVideoPlayer-endZoomControlBarContainer--hide .slide-media--video-controlBar,
.liveView-mediaItem--promptVideoPlayer-endZoomControlBarContainer--hide .slide-media--video-controlBar{
  transition-delay: 2500ms;
}

.slide-media--video-controlBar{
  position: absolute;
  top: 0px;
  // left: 12px;
  // right: 12px;
  left: 5px;
  right: 5px;
  bottom: 4px; // height is 44px;
  // background: rgba(0,0,40,0.4);
  background: rgba(0,0,10,0.8);

  border-radius: 4px;
}

.liveView-mediaItem--choiceVideo-startZoomControlBarContainer
.slide-media--video-controlBar{
  left: 6px;
  right: 6px;
  bottom: 6px;
  border-radius: 5px;
  cursor: pointer;
}

.liveView-mediaItem--promptVideoPlayer-endZoomControlBarContainer .slide-media--video-controlBar,
.liveView-mediaItem--choiceVideo-endZoomControlBarContainer .slide-media--video-controlBar{
  top: 0px;
  left: 10vw;
  right: 10vw;
  bottom: 4px; // height is 44px;
  // background: rgba(0,0,40,0.4);
  background: rgba(0,0,10,0.8);

  border-radius: 4px;
}

.slide-media--video-controlBar-buttonContainer{
  position: absolute;
  bottom: 0px;
  // height: 36px;
  height: 25px;
  left: 10px;
  right: 10px;

  // background: $tempb;

  display: flex;
}


button.slide-media--video-controlBar-btn{
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  background: none;
  border: none;
  outline: none;

  cursor: pointer;

  // background: red;

  border-radius: 4px;
}

.slide-media--video-controlBar-btn:hover{
  background: rgba(0,0,10,0.6);
}

.slide-media--video-controlBar-btn svg.editorIcon{
  height: 14px;
  fill: white;
}


// Play Pause
button.slide-media--video-controlBar-btn--playPause{
  width: 50px;
  // background: blue;
}

.slide-media--video-controlBar-btn--playPause svg.editorIcon{
  // height: 16px;
  height: 12px;
}

.slide-media--video-controlBar-btn--playPause svg.editorIcon.editorIcon-playbackPlay{
  position: relative;
  left: 2px; // optical centering
}

.slide-media--video-controlBar--liveViewChoiceStartZoom
button.slide-media--video-controlBar-btn--playPause{
  width: 40px;
}

// Other Buttons
button.slide-media--video-controlBar-btn--restart,
button.slide-media--video-controlBar-btn--volume,
button.slide-media--video-controlBar-btn--CC{
  width: 36px;
  // background: red;
}

button.slide-media--video-controlBar-btn--CC{
  // display: none;
}

button.slide-media--video-controlBar-btn--restart svg.editorIcon{
  opacity: 0.7;
}

button.slide-media--video-controlBar-btn--restart:hover svg.editorIcon{
  opacity: 0.9;
}

button.slide-media--video-controlBar-btn--volume svg.editorIcon,
button.slide-media--video-controlBar-btn--CC svg.editorIcon{
  height: 16px;
  opacity: 0.9;
}

button.slide-media--video-controlBar-btn--restart{
  margin-right: auto;
}



button.slide-media--video-controlBar-btn--inactive svg.editorIcon{
  opacity: 0.5;
}

// EDIT VIDEO BTN

button.slide-media--video-controlBar-btn--editVideo{
  margin-left: auto;
  font-size: 11px;
  font-weight: $lato-bold;
  letter-spacing: 0.025em;
  color: $white;

  padding-left: 10px;
  padding-right: 10px;
  margin-right: -6px;

  opacity: 0.9;

  // background: $blue;
  height: 100%;
  position: relative;
  // top: 4px;

  border-radius: 4px;
}

button.slide-media--video-controlBar-btn--editVideo:hover{
  opacity: 1;
}

button.slide-media--video-controlBar-btn--editVideo svg.editorIcon{
  opacity: 0.75;
  margin-top: 1px;
  height: 21px;
  width: 21px;
  margin-right: 6px;
}

@media (max-width:1120px) {
  button.slide-media--video-controlBar-btn--editVideo{
    padding-left: 2px;
    padding-right: 5px;
  }
  button.slide-media--video-controlBar-btn--editVideo svg{
    display: none; // button wraps text on narrow screens
  }
}


button.slide-media--video-controlBar-btn--editVideo:hover svg{
  opacity: 1;
}



// Timestamps
.slide-media--video-controlBar-timestamp{
  display: flex;
  align-items: center;
  cursor: default;
  user-select: none;
}

.slide-media--video-controlBar-timestamp-current,
.slide-media--video-controlBar-timestamp-total{
  padding-left: 2px;
  padding-right: 2px;
  font-size: 13px;
  font-weight: $lato-bold;
  color: $white;
  letter-spacing: 0.01em;
}

.slide-media--video-controlBar-timestamp-total{
  opacity: 0.75;
  margin-left: 2px;
}



//
// SEEK BAR

.slide-media--video-controlBar-seekBar{
  position: absolute;
  top: 0px;
  height: 13px;
  left: $canvasVideoControls-internalSideMargin;
  right: $canvasVideoControls-internalSideMargin;

  z-index: 10;
  cursor: pointer;

  // background: $tempr;
}

.slide-media--video-controlBar-seekBar
.rc-slider-handle{
  width: 11px;
  height: 11px;
  margin-top: -3px;
  // border-radius: 50%;
  background: $canvasVideoControls-seekRed;
  // border: 1px solid $white;
  // box-shadow: 0px 0px 0px 2px rgba(0,0,10,0.08);

  cursor: pointer;

  // opacity: 0;
  border: none;
  border: 1px solid darken($canvasVideoControls-seekRed, 10%);

  box-shadow: none;

  // position: absolute;
  // top: 3px;
  // left: 25%;
}

.slide-media--video-controlBar-seekBar
.rc-slider-handle:hover{
  width: 14px;
  height: 14px;
  margin-top: -4px;

}

.slide-media--video-controlBar-seekBar .rc-slider-handle:hover,
.slide-media--video-controlBar-seekBar .rc-slider-handle:focus,
.slide-media--video-controlBar-seekBar .rc-slider-handle:active{
  // border: 1px solid rgba(255,255,255,1);
  background: $canvasVideoControls-seekRed;
  border: 1px solid darken($canvasVideoControls-seekRed, 10%);
  box-shadow: none;
  // border: 1px solid $white;
  // box-shadow: 0px 0px 0px 2px rgba(0,0,10,0.08);

  cursor: pointer;
}


.slide-media--video-controlBar-seekBar
.rc-slider-track{
  background: $canvasVideoControls-seekRed;
  height: 3px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  margin-top: 1px;
}

.slide-media--video-controlBar-seekBar
.rc-slider-rail{
  background: #D8D8D8;
  background: rgb(110,110,120);
  height: 3px;
  margin-top: 1px;
}


//
// CLOSE BTN


button.slide-mediaContainer--video-deleteBtn{
  transform-origin: top right;

  position: absolute;
  top: -12px;
  right: -12px;

  background: none;
  border: none;
  box-shadow: none;

  cursor: pointer;

  height: 24px;
  width: 24px;

  background: $gray-3;
  border: 2px solid rgba(255,255,255,1);
  // border: 3px solid rgba(0,0,20,0.8);

  padding: 0px;
  border-radius: 6px;

  z-index: 100;

  opacity: 0;
  transition: opacity 50ms;

}


button.slide-mediaContainer--video-deleteBtn:hover{
  background: $red;
}

button.slide-mediaContainer--video-deleteBtn svg{
  width: 10px;
  height: 10px;

  fill: $gray-14;
}

button.slide-mediaContainer--video-deleteBtn:hover svg{
  fill: $white;
}



// HOVER STUFF

.slide-media--video-videoContainer:hover ~
button.slide-mediaContainer--video-deleteBtn{
  opacity: 1;
  transition: opacity 50ms;
  transition-delay: 200ms;
}

button.slide-mediaContainer--video-deleteBtn:hover{
  opacity: 1;
  transition: opacity 50ms;
}














// .slide-media--video-controlBar-seekBar-tooltip
// .rc-slider-tooltip-placement-top{
// 	padding-top: 0px;
// 	padding-bottom: 0px;
// }

// .slide-media--video-controlBar-seekBar
// .rc-slider-tooltip-content{

// }

// .slide-media--video-controlBar-seekBar-tooltip
// .rc-slider-tooltip-content-arrow{
// 	display: none;
// }

// .slide-media--video-controlBar-seekBar
// .rc-slider-tooltip-content{
// 	height: 24px;
// 	font-size: 11px;
// 	font-weight: $lato-bold;
// 	color: $white;
// 	box-shadow: none;
// 	background-color: transparentize($gray-5, 0.2);
// 	border-radius: 2px;
// }



// .rc-slider {
//   position: relative;
//   height: 14px;
//   padding: 5px 0;
//   width: 100%;
//   border-radius: 6px;
//   touch-action: none;
//   box-sizing: border-box;
//   -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
// }
// .rc-slider * {
//   box-sizing: border-box;
//   -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
// }
// .rc-slider-rail {
//   position: absolute;
//   width: 100%;
//   background-color: #e9e9e9;
//   height: 4px;
//   border-radius: 6px;
// }
// .rc-slider-track {
//   position: absolute;
//   left: 0;
//   height: 4px;
//   border-radius: 6px;
//   background-color: #abe2fb;
// }
// .rc-slider-handle {
//   position: absolute;
//   width: 14px;
//   height: 14px;
//   cursor: pointer;
//   cursor: -webkit-grab;
//   margin-top: -5px;
//   cursor: grab;
//   border-radius: 50%;
//   border: solid 2px #96dbfa;
//   background-color: #fff;
//   touch-action: pan-x;
// }
// .rc-slider-handle-dragging.rc-slider-handle-dragging.rc-slider-handle-dragging {
//   border-color: #57c5f7;
//   box-shadow: 0 0 0 5px #96dbfa;
// }
// .rc-slider-handle:focus {
//   outline: none;
// }
// .rc-slider-handle-click-focused:focus {
//   border-color: #96dbfa;
//   box-shadow: unset;
// }
// .rc-slider-handle:hover {
//   border-color: #57c5f7;
// }
// .rc-slider-handle:active {
//   border-color: #57c5f7;
//   box-shadow: 0 0 5px #57c5f7;
//   cursor: -webkit-grabbing;
//   cursor: grabbing;
// }
// .rc-slider-mark {
//   position: absolute;
//   top: 18px;
//   left: 0;
//   width: 100%;
//   font-size: 12px;
// }
// .rc-slider-mark-text {
//   position: absolute;
//   display: inline-block;
//   vertical-align: middle;
//   text-align: center;
//   cursor: pointer;
//   color: #999;
// }
// .rc-slider-mark-text-active {
//   color: #666;
// }
// .rc-slider-step {
//   position: absolute;
//   width: 100%;
//   height: 4px;
//   background: transparent;
// }
// .rc-slider-dot {
//   position: absolute;
//   bottom: -2px;
//   margin-left: -4px;
//   width: 8px;
//   height: 8px;
//   border: 2px solid #e9e9e9;
//   background-color: #fff;
//   cursor: pointer;
//   border-radius: 50%;
//   vertical-align: middle;
// }
// .rc-slider-dot-active {
//   border-color: #96dbfa;
// }
// .rc-slider-dot-reverse {
//   margin-right: -4px;
// }
// .rc-slider-disabled {
//   background-color: #e9e9e9;
// }
// .rc-slider-disabled .rc-slider-track {
//   background-color: #ccc;
// }
// .rc-slider-disabled .rc-slider-handle,
// .rc-slider-disabled .rc-slider-dot {
//   border-color: #ccc;
//   box-shadow: none;
//   background-color: #fff;
//   cursor: not-allowed;
// }
// .rc-slider-disabled .rc-slider-mark-text,
// .rc-slider-disabled .rc-slider-dot {
//   cursor: not-allowed !important;
// }
// .rc-slider-vertical {
//   width: 14px;
//   height: 100%;
//   padding: 0 5px;
// }
// .rc-slider-vertical .rc-slider-rail {
//   height: 100%;
//   width: 4px;
// }
// .rc-slider-vertical .rc-slider-track {
//   left: 5px;
//   bottom: 0;
//   width: 4px;
// }
// .rc-slider-vertical .rc-slider-handle {
//   margin-left: -5px;
//   touch-action: pan-y;
// }
// .rc-slider-vertical .rc-slider-mark {
//   top: 0;
//   left: 18px;
//   height: 100%;
// }
// .rc-slider-vertical .rc-slider-step {
//   height: 100%;
//   width: 4px;
// }
// .rc-slider-vertical .rc-slider-dot {
//   left: 2px;
//   margin-bottom: -4px;
// }
// .rc-slider-vertical .rc-slider-dot:first-child {
//   margin-bottom: -4px;
// }
// .rc-slider-vertical .rc-slider-dot:last-child {
//   margin-bottom: -4px;
// }
// .rc-slider-tooltip-zoom-down-enter,
// .rc-slider-tooltip-zoom-down-appear {
//   animation-duration: 0.3s;
//   animation-fill-mode: both;
//   display: block !important;
//   animation-play-state: paused;
// }
// .rc-slider-tooltip-zoom-down-leave {
//   animation-duration: 0.3s;
//   animation-fill-mode: both;
//   display: block !important;
//   animation-play-state: paused;
// }
// .rc-slider-tooltip-zoom-down-enter.rc-slider-tooltip-zoom-down-enter-active,
// .rc-slider-tooltip-zoom-down-appear.rc-slider-tooltip-zoom-down-appear-active {
//   animation-name: rcSliderTooltipZoomDownIn;
//   animation-play-state: running;
// }
// .rc-slider-tooltip-zoom-down-leave.rc-slider-tooltip-zoom-down-leave-active {
//   animation-name: rcSliderTooltipZoomDownOut;
//   animation-play-state: running;
// }
// .rc-slider-tooltip-zoom-down-enter,
// .rc-slider-tooltip-zoom-down-appear {
//   transform: scale(0, 0);
//   animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
// }
// .rc-slider-tooltip-zoom-down-leave {
//   animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
// }
// @keyframes rcSliderTooltipZoomDownIn {
//   0% {
//     opacity: 0;
//     transform-origin: 50% 100%;
//     transform: scale(0, 0);
//   }
//   100% {
//     transform-origin: 50% 100%;
//     transform: scale(1, 1);
//   }
// }
// @keyframes rcSliderTooltipZoomDownOut {
//   0% {
//     transform-origin: 50% 100%;
//     transform: scale(1, 1);
//   }
//   100% {
//     opacity: 0;
//     transform-origin: 50% 100%;
//     transform: scale(0, 0);
//   }
// }
// .rc-slider-tooltip {
//   position: absolute;
//   left: -9999px;
//   top: -9999px;
//   visibility: visible;
//   box-sizing: border-box;
//   -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
// }
// .rc-slider-tooltip * {
//   box-sizing: border-box;
//   -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
// }
// .rc-slider-tooltip-hidden {
//   display: none;
// }
// .rc-slider-tooltip-placement-top {
//   padding: 4px 0 8px 0;
// }
// .rc-slider-tooltip-inner {
//   padding: 6px 2px;
//   min-width: 24px;
//   height: 24px;
//   font-size: 12px;
//   line-height: 1;
//   color: #fff;
//   text-align: center;
//   text-decoration: none;
//   background-color: #6c6c6c;
//   border-radius: 6px;
//   box-shadow: 0 0 4px #d9d9d9;
// }
// .rc-slider-tooltip-arrow {
//   position: absolute;
//   width: 0;
//   height: 0;
//   border-color: transparent;
//   border-style: solid;
// }
// .rc-slider-tooltip-placement-top .rc-slider-tooltip-arrow {
//   bottom: 4px;
//   left: 50%;
//   margin-left: -4px;
//   border-width: 4px 4px 0;
//   border-top-color: #6c6c6c;
// }


