$width-packReviewSideNav: 240px;
$width-packReviewDetailPanel: 940px;

.page.packReview{
	background: $white;
}

//
// LAYOUT
//


.packReview-sideNav{
	position: fixed;
	width: $width-packReviewSideNav;
	top: 0px;
	left: 0px;
	bottom: 0px;	

	box-sizing: border-box;
	padding-left: 36px;
	padding-right: 22px;
	padding-top: 20px;

	background: rgb(252,252,255);
	border-right: 1px solid $gray-125;
}

.packReview-centerPanel{
	position: fixed;
	left: $width-packReviewSideNav;
	top: 0px;
	bottom: 0px;
	right: $width-packReviewDetailPanel;
	overflow-y: scroll;

	box-sizing: border-box;
	padding-top: 56px;	
	padding-bottom: 108px;
}

.packReview-centerPanel:focus{
	outline: none;
}

.packReview-detailPanel{
	position: fixed;	
	top: 0px;
	bottom: 0px;
	width: $width-packReviewDetailPanel;
	right: 0px;
	overflow-y: scroll;

	box-sizing: border-box;
	border-left: 1px solid $gray-125;

	padding-left: 50px;
	padding-top: 45px;
	padding-right: 40px;
}	


@import 'PackReview-SideNav';
@import 'PackReview-SubmissionItem';
@import 'PackReview-DetailPanel';
// @import 'packReview-CrossFilterDisplayControl';
// @import 'packReview-CrossFilterMarketPanel';
// @import 'packReview-CrossFilterRepo';
// @import 'packReview-EditRepoNotesModal';


