.repoDetailsModalContainer{
	position: fixed;
	left: 0;
	top: 0;
	bottom: 0;
	right: 0;
	z-index: 5000;
	display: flex;
	align-items: center;
	justify-content: center;

	overflow: hidden;

}

.repoDetailsModal{

	width: 700px;
	
	overflow: hidden;
	position: relative;
	z-index: 10;
	
	border-radius: 5px;

	background: rgb(252,252,255);
	border: 1px solid $gray-5;
	box-shadow: 
		0px 8px 16px 0px rgba(5,5,10,0.12),
		0px 16px 32px 0px rgba(5,5,10,0.08) ;


	box-sizing: border-box;
	padding-left: 32px;
	padding-right: 32px;

	padding-top: 22px;
	padding-bottom: 26px;

}

.repoDetailsModal{
	height: 475px; // declare as done button needs to be absolute positioned
}

.repoDetailsModal--expanded{
	height: 575px;
}

//

.repoDetailsImageSearchModal{
	width: 800px;
	height: 600px;
	background: $white;
	z-index: 10;
	border-radius: 4px;
	overflow: hidden;
}

.repoDetailsImageSearchModal .modal-content{
	width: 800px;
	height: 600px;
}

.repoDetailsModalBackground{
	position: absolute;
	left: 0;
	right: 0;
	bottom: 0;
	top: 0;
	background: rgba(0,0,3,0.75);
	overflow: hidden;
}

///

.repoDetailsModal-title{
	font-size: 24px;
	font-weight: $lato-heavy;
	color: $textBlackDark;

	margin-bottom: 14px;
	user-select: none;
	cursor: default;
}

// Add Subject and Grade Level Button

.repoDetailsModal button.btn.addmetadata{
	position: absolute;
	top: 21px;
	right: 70px;
	border: none;
	background: none;
	cursor: pointer;
	padding-right: 14px;
	padding-left: 14px;
}

.repoDetailsModal button.btn.addmetadata:hover{
	background: rgba(0,0,40,0.03);
	color: $textBlackDark;
}


//

.repoDetailsModal--middle{
	display: flex;
	margin-top: 14px;
}

//

.repoDetailsModal-image{
	flex-grow: 0;
	flex-shrink: 0;

	width: 215px;
}

.repoDetailsModal-imageContainer{
	width: 215px;	
	height: 215px;
	overflow: hidden;
	position: relative;
	border-radius: 4px;

	box-sizing: border-box;
}

// No image

.repoDetailsModal-imageContainer--noImage,
.repoDetailsModal-imageContainer--uploadingImage{
	background: $blue-extraLight;
	display: flex;
	align-items: center;
	justify-content: center;

	border: 1px solid transparentize($blue, 0.9);
}

.repoDetailsModal-imageContainer--noImage{
	cursor: pointer;
}

.repoDetailsModal-imageContainer-addImage{
	cursor: pointer;
	border-radius: 4px;
	width: 64px;
	height: 64px;

	border-radius: 50%;
	background: transparentize($blue, 0.97);
  // border: 1px solid $blue;

  display: flex;
  align-items: center;
  justify-content: center;
}


.repoDetailsModal-imageContainer-addImage .icon.plus{
	fill: $blue;
	transition: opacity 50ms linear;
	width: 32px;
	height: 32px;
}


.repoDetailsModal-imageContainer:hover 
.repoDetailsModal-imageContainer-addImage{
	opacity: 1;
	transition: opacity 100ms linear;
	transition-delay: 50ms;
}



// Has Image

.repoDetailsModal-imageContainer img{	
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.repoDetailsModal-imageContainer-image-border{
	position: absolute;
	top: 0px;
	left: 0px;
	right: 0px;
	bottom: 0px;

	border: 2px solid rgba(0,0,20,0.1);	

	border-radius: 4px;
	z-index: 10;
}

// Remove Image

.repoDetailsModal-imageContainer-removeImage{
	position: absolute;
	top: 8px;
	right: 8px;
	z-index: 20;
	cursor: pointer;	
	border-radius: 50%;
	width: 32px;
	height: 32px;
	background: rgba(26,28,34,0.85);
  border: 1px solid rgba(26,28,34,1);

  display: flex;
  align-items: center;
  justify-content: center;
}


.repoDetailsModal-imageContainer-removeImage .icon.close{
	fill: $white;
	transition: opacity 50ms linear;
	width: 16px;
	height: 16px;
}

.repoDetailsModal-imageContainer-removeImage{
	opacity: 0;
}

.repoDetailsModal-imageContainer:hover 
.repoDetailsModal-imageContainer-removeImage{
	opacity: 0.4;
	transition: opacity 100ms linear;
	transition-delay: 50ms;
}

.repoDetailsModal-imageContainer
.repoDetailsModal-imageContainer-removeImage:hover{
	opacity: 0.8;
	transition: opacity 50ms linear;
}

.repoDetailsModal-imageContainer
.repoDetailsModal-imageContainer-removeImage:active{
	opacity: 1;
	transition: opacity 50ms linear;
}

// UPLOADING


//

.repoDetailsModal-input-label{
	font-size: 15px;
	font-weight: $lato-bold;
	color: $textDark;
	letter-spacing: 0.015em;
	cursor: default;
	user-select: none;
	margin-bottom: 5px;

	box-sizing: border-box;

	display: flex;

	align-items: flex-end;
}

.repoDetailsModal-input-label-optional{
	font-weight: $lato-semibold;
	color: $textMid;
}

.repoDetailsModal-input-label-required{
	font-weight: $lato-semibold;
	color: $red-dark;
}


.repoDetailsModal-input-label-characterCount{
	margin-left: auto;

	font-size: 13.5px;

	text-align: right;
	font-weight: $lato-medium;
	color: $textLight;
	opacity: 0;
}

.repoDetailsModal-input-label-characterCount--max{
	font-weight: $lato-bold;
	color: $textBlack;
}

.repoDetailsModal-input-label-characterCount--show{
	opacity: 1;
}


.repoDetailsModal-input--description{
	padding-left: 12px;
	box-sizing: border-box;
	flex-grow: 1;
}

.repoDetailsModal-input--description textarea{
	height: 215px;
	width: 100%;
	resize: none;
  
  border: 1px solid $lineDark;
  border-radius: 3px;
  padding-left: 8px;
  padding-top: 6px;
  padding-right: 8px;

  line-height: 1.35;
  
  box-sizing: border-box;

  font-size: 15px;
  font-weight: $lato-medium;
  color: $textBlack;
  outline: none;
  pointer-events: default;
}

.repoDetailsModal-input--description textarea:focus{
   border: 1px solid $blue;
}

.repoDetailsModal-input--description textarea.is--nonEmpty:focus{
    box-shadow: 0px 0px 4px 0px transparentize($blue, 0.5);
}


.repoDetailsModal-input--description textarea::-webkit-input-placeholder {
   color: $textInputPlaceholder !important;
   font-size: 15px;
   font-weight: $lato-medium;
}


///
/// Metadata Select Control Tweaks
/// Make smaller basically
/// 


.repoDetailsModal .publishingFlowSelectControl{
	padding: 4px;
	padding-left: 26px;
	border-radius: 3px;
}

.repoDetailsModal .publishingFlowSelectControl-radio{
	top: 4px;
	left: 4px;
}

.repoDetailsModal .publishingFlowSelectControl-checkbox{
	top: 5px;
	left: 4px;
}

.repoDetailsModal .publishingFlowSelectControl-label{
	font-size: 15px;
}

.repoDetailsModal .publishingFlowSelectControl-description{
	margin-top: 2px;
	font-size: 13.5px;
}

.repoDetailsModal input.publishingFlowSelectControl-optionalOtherInputBox{
	font-size: 14px;
	height: 28px;
	padding-left: 4px;
}


///
/// Subject
///

.repoDetailsModal-subject-subjectListContainer{
	display: flex;
	margin-top: 10px;
}

.repoDetailsModal-subject-subjectListColumn{
	flex-grow: 0;
	flex-shrink: 0;
	width: 300px;
	margin-right: 30px;
}

.repoDetailsModal-subject-subjectListSubSubjectGroup{
	padding-left: 22px; // inset
	box-sizing: border-box;
	margin-bottom: 12px; // additional space below
}

.repoDetailsModal-subject-subjectListSubSubjectGroup
.publishingFlowSelectControl{
	padding-top: 3px; // appear tighter together
	padding-bottom: 3px;
}

// Two column for languages
.repoDetailsModal-subject-subjectListSubSubjectGroup--twoColumn{
	display: flex;
}

.repoDetailsModal-subject-subjectListSubSubjectGroup-column{
	flex-grow: 1;
	flex-shrink: 1;
}

.repoDetailsModal-subject-subjectListSubSubjectGroup-column--wide{
	width: 140px;
}

// spacer for other
.repoDetailsModal-subject-allSubjectsSpacer{
	height: 18px;
}

// .repoDetailsModal-subject-otherSpacer{
// 	height: 24px;
// }



///
/// Grade Level
///

.repoDetailsModal-gradeLevel-gradeLevelCheckboxContainer{
	display: flex;
	flex-wrap: wrap;
	flex-direction: column;
	height: 135px; // flows to three columns nicely
	margin-bottom: 14px;
	width: 80%;
}

.repoDetailsModal-gradeLevel-gradeLevelCheckboxContainer
.publishingFlowSelectControl{
	width: 150px;
}

.repoDetailsModal-gradeLevel-extraOptionsContainer{
	display: flex;
	margin-bottom: 10px;
}

.repoDetailsModal-gradeLevel-extraOptionsContainer
.publishingFlowSelectControl {
	margin-right: 10px;
}

.repoDetailsModal-gradeLevel-otherContainer
.publishingFlowSelectControl{
	width: 300px;
}

//
// Language
//

.repoDetailsModal-gradeLevel-language{
	display: flex;
	margin-top: 40px;
	margin-left: 6px;

	align-items: center;
}

.repoDetailsModal-gradeLevel-language-title{
	font-size: 15px;
	font-weight: $lato-bold;
	color: $textDark;
	letter-spacing: 0.015em;
	cursor: default;
	user-select: none;
}

.repoDetailsModal-gradeLevel-language .Select{
	margin-left: 30px;
	width: 220px;
}

.repoDetailsModal-gradeLevel-language .Select-control{
	height: 30px;
}

.repoDetailsModal-gradeLevel-language .Select-placeholder,
.repoDetailsModal-gradeLevel-language .Select-input,
.repoDetailsModal-gradeLevel-language .Select-value{
	font-size: 14px;
	line-height: 30px;
	height: 30px;
	font-weight: $lato-semibold;
	padding-left: 8px;
}

.repoDetailsModal-gradeLevel-language .Select-input,
.repoDetailsModal-gradeLevel-language .Select-value-label{
	position: relative;
	top: -2px;
}

.repoDetailsModal-gradeLevel-language .Select-value-label{
	left: -2px;
}

.repoDetailsModal-gradeLevel-language .Select-menu-outer{
	max-height: 150px;
}

.repoDetailsModal-gradeLevel-language .Select-menu{
	max-height: 148px;	
}

.repoDetailsModal-gradeLevel-language .Select-option{
	font-size: 14px;
	height: 28px;
	padding-top: 5px;
	padding-bottom: 5px;
}

.repoDetailsModal-gradeLevel-language .Select.is-focused:not(.is-open) > .Select-control{
	box-shadow: none;
}


///
/// ERROR MESSAGE
/// for public packs
///

.repoDetailsModal-errorMessageContainer{
	position: absolute;
	bottom: 76px;
	left: 50%;
	transform: translateX(-50%);
	width: 385px;
	z-index: 100;
}

.repoDetailsModal-errorMessage{
	font-size: 14px;
	font-weight: $lato-bold;
	color: $red-dark;

	background: transparentize($red, 0.9); 
	background: rgb(254,241,240); // same as above, but we need it opaque
	border-radius: 3px;
	padding: 5px;
	padding-left: 10px;
	padding-right: 10px;

	box-sizing: border-box;
	text-align: center;
	margin-top: 5px;
	cursor: default;
}




///
/// DONE BUTTON
///

.repoDetailsModal button.btn.btn--xLarge.repodetailsubmit{
	position: absolute;
	bottom: 20px;
	left: 50%;
	transform: translateX(-50%);

	width: 360px;
	font-size: 20px;
	font-weight: $lato-heavy;
	height: 48px;
	border-radius: 5px;
}

.repoDetailsModal button.btn.btn--xLarge.repodetailsubmit:focus{
	background: darken($blue-light, 6%);
	border-color: darken($blue, 6%);
	color: $white;

	box-shadow: 0px 2px 4px 0px transparentize($blue, 0.9),
	0px 0px 14px 0px transparentize($blue, 0.7);
}

///



.repoDetailsModal-imageContainer--uploading-loadingSpinnerContainer{
	position: relative;
	
	display: flex;
	align-items: center;
	justify-content: center;
}

.repoDetailsModal-imageContainer--uploading-loadingSpinner{

	height: 40px;
	width: 40px;
  
  border-radius: 60px;

  border: 5px transparentize($blue, 0.8) solid;
  border-top: 5px $blue solid;

  animation: spinnerRotateAnimation .5s infinite linear;
}


/// TABS FOR EXPANDED VIEW

.repoDetailsModal--expanded-tabsContainer{
	width: calc(100% + 32px); // line draws to right edge
	display: flex;
	margin-bottom: 20px;
	border-bottom: 1px solid $lineMid;
}

.repoDetailsModal--expanded-tab{
	width: 110px;
	height: 30px;
	font-size: 14px;
	text-transform: uppercase;
	
	display: flex;

	align-items: center;
	justify-content: center;
	margin-bottom: -1px;

	cursor: pointer;
	user-select: none;
}

.repoDetailsModal--expanded-tab--active{
	font-weight: $lato-heavy;
	color: $textBlue;
	border-bottom: 2px solid $blue;
}

.repoDetailsModal--expanded-tab--inactive{
	font-weight: $lato-bold;
	color: $textLight;
	border-bottom: 2px solid $invisible;	
}

.repoDetailsModal--expanded-tab--inactive:hover{
	color: darken($textLight, 15%);
	border-bottom: 2px solid $lineMid;	
}









///

.repoDetailsModal-closeBtn{
	position: absolute;
	width: 42px;
	height: 42px;
	top: 20px;
	right: 20px;
	cursor: pointer;
	border-radius: 21px;

	opacity: 0.2;
	transition: opacity 100ms linear;

	z-index: 100;
}

.featureModal-closeBtn:hover{
	opacity: 0.7;
	transition: opacity 50ms linear;
}

.featureModal-closeBtn:active{
	opacity: 1;
}

.featureModal-closeBtn .icon{
	width: 42px;
	height: 42px;
	fill: $gray-6;
}