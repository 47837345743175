
//
// STUDENT REPORT HEADER
//

.printPack--studentReport-studentReportHeader{
	position: absolute;
	top: $printPack-pageMargin--top;
	left: $printPack-pageMargin--sideInner;
	right: $printPack-pageMargin--sideInner;

	height: $printPack-heightPackHeader;

  display: flex;

  box-sizing: border-box;
}

.printPack--studentReport-studentReportHeader-left{
	flex-grow: 1;
	margin-right: auto;

	position: relative;
}

.printPack--studentReport-studentReportHeader-right{
	text-align: right;
}

.printPack--studentReport-studentReportHeader-left-studentName{
	font-size: 22pt;
	font-weight: $lato-heavy;
	color: $textBlackDark;

	white-space: nowrap;
  overflow: hidden;
}

.printPack--studentReport-studentReportHeader-left-dateRangeLabel{
	margin-top: 8px;
	font-size: 11pt;
	font-weight: $lato-semibold;
	color: $textMid;
}


.printPack--studentReport-studentReportHeader-left-personalizedContentLabel{
	display:  none;
	position: absolute;
	bottom: 48px;
	left: 0px;

	font-size: 10.5pt;
	font-weight: $lato-medium;
	color: $textLight;
}

.printPack--studentReport-studentReportHeader-right-score{
	height: 40px;
	//background: $tempr;
	margin-top: -4px;

	display: flex;
	align-items: center;
	justify-content: flex-end;

	font-size: 22pt;
	font-weight: $lato-heavy;
	color: $textBlackDark;
	width: 100%;
}


.printPack--studentReport-studentReportHeader-right-score-scoreCircleSVG svg{
	fill: $red;
	margin-top: 1px;
}

//

.printPack--studentReport-studentReportHeader-right-score--score-o85
.printPack--studentReport-studentReportHeader-right-score-scoreCircleSVG svg{
	fill: $color-o85;
}

.printPack--studentReport-studentReportHeader-right-score--score-o70
.printPack--studentReport-studentReportHeader-right-score-scoreCircleSVG svg{
	fill: $color-o70;
}

.printPack--studentReport-studentReportHeader-right-score--score-o60
.printPack--studentReport-studentReportHeader-right-score-scoreCircleSVG svg{
	fill: $color-o60;
}

.printPack--studentReport-studentReportHeader-right-score--score-u60
.printPack--studentReport-studentReportHeader-right-score-scoreCircleSVG svg{
	fill: $color-u60;
}

//

.printPack--studentReport-studentReportHeader-right-score-percent{
	opacity: 0.75;
}

.printPack--studentReport-studentReportHeader-right-scoreBreakdown{
	margin-top: 5px;
	font-size: 10.5pt;
	font-weight: $lato-semibold;
	color: $textLight;
}

