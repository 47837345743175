
// SECTION (CONTEXT ONLY RIGHT NOW)
.dropdownMenu-item--section,
.dropdownMenu-item--removeFromClass {
	position: relative;
	cursor: pointer;
}

.react-contextmenu-item .sectionMarker.is--circle,
.dropdownMenu-item--section .sectionMarker.is--circle, 
.dropdownMenu-item--removeFromClass .sectionMarker.is--circle{
	width: calc(100% + 20px);
	width: 100%;
	margin-left: -20px;
	height: 30px;
	position: absolute;

	padding-right: 10px;
	
	box-sizing: border-box;
	padding-left: 28px;
	z-index: 1;

	white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.dropdownMenu-item--removeFromClass .sectionMarker.is--circle{
	padding-right: 20px;
}

.dropdownMenu-item--section:hover .sectionMarker.is--circle,
.react-contextmenu-item--selected .sectionMarker.is--circle{
	opacity: 1;
	color: $white;
}

.dropdownMenu-item--removeFromClass:hover{
	background: rgba(243,127,130,0.3);
	color: $textBlack;
}


.react-contextmenu-item .sectionMarker .icon,
.dropdownMenu-item--section .sectionMarker .icon,
.dropdownMenu-item--removeFromClass .sectionMarker .icon{
	height: 9px;
	width: 9px;
	top: 10px;
	left: 10px;
	position: absolute;
}

.react-contextmenu-item .sectionMarker.is--colorOnly,
.dropdownMenu-item--section .sectionMarker.is--colorOnly,{
	position: absolute;
	top: 0px;
	bottom: 0px;
	left: 0px;
	right: 0px;
	z-index: 0;
	opacity: 0;
}

.react-contextmenu-item--selected .sectionMarker.is--colorOnly,
.dropdownMenu-item--section:hover .sectionMarker.is--colorOnly{
	opacity: 1;
}


// ADD TO CLASS AND PLAY SECTION

.dropdownMenu-item--play .icon.play,
.react-contextmenu-item .icon.play,
.dropdownMenu-item--addToClass .icon.plus,
.react-contextmenu-item .icon.plus{

	position: absolute;

	fill: $white;	
	z-index: 5;

	opacity: 0;
}

.dropdownMenu-item--play .icon.play,
.react-contextmenu-item .icon.play{
	width: 10px;
	height: 10px;
	left: 10px;
	top: 10px;
}


.dropdownMenu-item--addToClass .icon.plus,
.react-contextmenu-item .icon.plus{
	width: 12px;
	height: 12px;
	left: 9px;
	top: 8px;
}

.dropdownMenu-item--play:hover .icon.play,
.dropdownMenu-item--addToClass:hover .icon.plus,
.react-contextmenu-item--selected .icon.play,
.react-contextmenu-item--selected .icon.plus{
	opacity: 1;
}

.dropdownMenu-item--section:hover .sectionMarker .icon.circle,
.react-contextmenu-item--selected .sectionMarker .icon.circle{
	opacity: 0;
}

// REMOVE FROM CLASS

.dropdownMenu-item--removeFromClass .icon.close{
	position: absolute;

	fill: $lineMid;
	
	width: 10px;
	height: 10px;
	right: 7px;
	top: 10px;	
}

.dropdownMenu-item--removeFromClass:hover .icon.close{
	fill: $red;	
}

