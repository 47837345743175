// Almost all very similar to edit image modal. May want to reconsider merging later
.editor-insertVideoModalContainer{
  position: fixed;
  top: 0px;
  right: 0px;
  left: 0px;
  bottom: 0px;

  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;

  background: rgba(0,0,10,0.25);
}

.editor-insertVideoModalContainerBG{
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}


.editor-insertVideoModal{
  width: 752px;
  height: calc(100vh - 140px);
  min-height: 500px;
  max-height: 850px;

  background: rgb(253,253,255);
  border: 1px solid rgb(204,204,208);
  border-bottom: 1px solid darken(rgb(204,204,208),5%);

  box-shadow: 0px 2px 4px 0px rgba(0,0,40,0.16), 0px 6px 12px 0px rgba(0,0,40,0.12);
  border-radius: 8px;

  box-sizing: border-box;

  padding-left: 28px;
  padding-right: 28px;
  padding-top: 24px;

  text-align: left;

  position: relative;

  z-index: 10;
}


//
// BASICALLY IDENTICAL TO IMAGE SEARCH

.editor-insertVideoModal-searchBar{
  height: 48px;
  width: 100%;
  position: relative;
}

input.editor-insertVideoModal-searchBar-input{
  width: 100%;
  height: 100%;

  box-sizing: border-box;

  font-size: 21.5px;
  font-weight: $lato-bold;
  color: $textBlackDark;

  border: 1px solid $lineDark;
  background: $white;

  padding-left: 14px;

  padding-left: 38px;
  padding-bottom: 2px;

  border-radius: 5px;

}

input.editor-insertVideoModal-searchBar-input:focus{
  border: 1px solid $blue;
  outline: none;
  box-shadow: 0px 0px 0px 0.5px $blue;

  padding-right: 185px;
}

input.editor-insertVideoModal-searchBar-input::placeholder{
  color: #D3D2D8;
  color: rgb(192,192,205);
}

input.editor-insertVideoModal-searchBar-input.editor-insertVideoModal-searchBar-input--isSearching:focus,
input.editor-insertVideoModal-searchBar-input.editor-insertVideoModal-searchBar-input--isSearching{
  padding-right: 70px; // prevent overlap search spinner
}

input.editor-insertVideoModal-searchBar-input.editor-insertVideoModal-searchBar-input--showClearButton,
input.editor-insertVideoModal-searchBar-input.editor-insertVideoModal-searchBar-input--showClearButton:focus{
  padding-right: 100px;
}


//
// SEARCH UI

.editor-insertVideoModal-searchBar-searchBarUI{
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
}

// ICON

.editor-insertVideoModal-searchBar-searchBarUI-iconContainer{
  position: absolute;
  top: 2px;
  left: 2px;
  width: 36px;

  height: 44px;
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;

  box-sizing: border-box;

  padding-left: 4px;

  border: none;
  outline: none;
  box-shadow: none;

  // background: $tempr;

  z-index: 5;

  display: flex;
  align-items: center;
  justify-content: center;

  pointer-events: none;
}

.editor-insertVideoModal-searchBar-searchBarUI-iconContainer svg.magnifying-glass{
  width: 18px;
  height: 18px;
  fill: rgb(192,192,205);
}


.editor-insertVideoModal-searchBar-searchBarUI-iconContainer svg.editorIcon-insertVideoLink{
  width: 18px;
  height: 18px;
  fill: rgb(192,192,205);
  // fill: $blue;
}


.editor-insertVideoModal-searchBar-input--nonEmpty:focus ~
.editor-insertVideoModal-searchBar-searchBarUI
.editor-insertVideoModal-searchBar-searchBarUI-iconContainer svg{
  fill: $blue;
}


// input.editor-insertVideoModal-searchBar-input.editor-insertVideoModal-searchBar-input--nonEmpty:focus ~
// .editor-insertVideoModal-searchBar-searchBarUI
// .editor-insertVideoModal-searchBar-searchBarUI-searchIconContainer svg{
// 	fill: $gray-11;
// }


// BUTTON/HINT

.editor-insertVideoModal-searchBar-searchBarUI-hint{
  position: absolute;
  top: 1px;
  right: 1px;

  height: 46px;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;

  padding-right: 14px;
  padding-left: 14px;

  min-width: 178px;

  background: none;
  // background: $tempr;

  cursor: pointer;
  user-select: none;

  border: none;
  box-shadow: none;
  outline: none;

  display: flex;
  align-items: center;
  justify-content: flex-end;

  font-size: 13px;
}

.editor-insertVideoModal-searchBar-searchBarUI-hint-secondaryLabel{
  font-weight: $lato-semibold;
  color: lighten($textDark, 5%);

  letter-spacing: 0.01em;
}

.editor-insertVideoModal-searchBar-searchBarUI-hint-key{
  display: flex;

  align-items: center;
  justify-content: center;

  padding-top: 4px;
  padding-bottom: 4px;

  padding-left: 6px;
  padding-right: 8px;

  font-weight: $lato-bold;
  color: $white;
  background: $gray-5;
  border-radius: 4px;

  margin-left: 6px;
  margin-right: 6px;

  letter-spacing: 0.025em;
}

.editor-insertVideoModal-searchBar-searchBarUI-hint-key svg{
  width: 10px;
  height: 10px;
  fill: $white;

  margin-left: 3px;
}

.editor-insertVideoModal-searchBar-searchBarUI-hint{
  opacity: 0;
  transition: opacity 50ms linear;
  pointer-events: none;
}

input.editor-insertVideoModal-searchBar-input.editor-insertVideoModal-searchBar-input--showSearchHint ~
.editor-insertVideoModal-searchBar-searchBarUI
.editor-insertVideoModal-searchBar-searchBarUI-hint{
  pointer-events: auto;
}

input.editor-insertVideoModal-searchBar-input.editor-insertVideoModal-searchBar-input--showSearchHint:focus ~
.editor-insertVideoModal-searchBar-searchBarUI
.editor-insertVideoModal-searchBar-searchBarUI-hint{
  opacity: 1;
  transition: opacity 50ms linear;
}

.editor-insertVideoModal-searchBar-searchBarUI-hint:hover
.editor-insertVideoModal-searchBar-searchBarUI-hint-key,
.editor-insertVideoModal-searchBar-searchBarUI-hint:hover
.editor-insertVideoModal-searchBar-searchBarUI-hint-secondaryLabel{
  display: none;
}

// SEARCHING SPINNER

.editor-insertVideoModal-searchBar-searchBarUI-searchingHint{
  position: absolute;
  top: 1px;
  right: 1px;

  height: 46px;

  width: 64px;

  display: flex;
  align-items: center;
  justify-content: center;
}

.editor-insertVideoModal-searchBar-searchBarUI-searchingHint-spinnerContainer{
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.editor-insertVideoModal-searchBar-searchBarUI-searchingHint-spinner{

  height: 20px;
  width: 20px;

  border-radius: 50%;

  border: 3px transparentize($blue, 0.8) solid;
  border-top: 3px $blue solid;

  opacity: 0.75;

  animation: spinnerRotateAnimation 0.4s infinite linear;
}




// SEARCH BTN
.editor-insertVideoModal-searchBar-searchBarUI-hint-searchBtn{

  position: absolute;
  right: 4px;
  left: 36px;
  top: 4px;
  bottom: 4px;

  background: $blue;
  background: transparentize($blue, 0.95);

  // background: $gray-15;
  // background: none;

  border: none;
  //border: 1px solid transparentize($blue, 0.98);

  box-shadow: none;

  color: $white;
  color: darken($blue, 5%);

  font-size: 15px;
  font-weight: $lato-bold;
  letter-spacing: 0.025em;

  border-radius: 4px;

  opacity: 0;

  display: flex;
  align-items: center;
  justify-content: center;

}

button.editor-insertVideoModal-searchBar-searchBarUI-hint:active
.editor-insertVideoModal-searchBar-searchBarUI-hint-searchBtn{
  background: transparentize($blue, 0.9);
}

.editor-insertVideoModal-searchBar-searchBarUI-hint:hover .editor-insertVideoModal-searchBar-searchBarUI-hint-searchBtn,
.editor-insertVideoModal-searchBar-searchBarUI-hint:active .editor-insertVideoModal-searchBar-searchBarUI-hint-searchBtn{
  opacity: 1;
}

// CLEAR AND CANCEL BUTTON
button.editor-insertVideoModal-searchBar-searchBarUI-cancelBtn,
button.editor-insertVideoModal-searchBar-searchBarUI-clearBtn{
  position: absolute;
  top: 6px;
  right: 6px;
  height: 36px;
  width: 80px;

  background: $tempr;
  background: $gray-15;
  background: none;
  border: none;
  box-shadow: none;

  cursor: pointer;

  font-size: 14px;
  font-weight: $lato-semibold;
  letter-spacing: 0.015em;

  border-radius: 4px;

  color: $textMid;
}

button.editor-insertVideoModal-searchBar-searchBarUI-cancelBtn,
button.editor-insertVideoModal-searchBar-searchBarUI-clearBtn:hover{
  background: $gray-15;
  border: 1px solid $gray-13;
  color: $textBlack;
}

button.editor-insertVideoModal-searchBar-searchBarUI-cancelBtn{
  color: $textMid;
}

button.editor-insertVideoModal-searchBar-searchBarUI-cancelBtn:hover{
  background: $gray-15;
  border: 1px solid $gray-125;
  color: $textBlackDark;
}




// PRO LIMIT AND WARNING MESSAGE
.editor-insertVideoModal-searchBar-proLimitedMessageContainer{
  display: flex;
  align-items: center;
  justify-content: flex-start;

  user-select: none;
  cursor: pointer;
}

.editor-insertVideoModal-searchBar-proLimitedMessage{
  margin-left: 20px;
  margin-top: 12px;
  padding: 10px;
  border: 1px solid $lineDark;
  background: $white;
  border-radius: 4px;

  display: flex;
  flex-direction: column;

  padding-left: 44px;
  padding-right: 14px;
  position: relative;
}

.editor-insertVideoModal-searchBar-proLimitedMessage-iconContainer{
  position: absolute;
  left: 0px;
  top: 0px;
  bottom: 0px;
  width: 44px;

  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 24px;
}

.editor-insertVideoModal-searchBar-proLimitedMessage-title{
  font-size: 14px;
  font-weight: $lato-bold;
  color: $textBlack;
  letter-spacing: 0.01em;
  margin-bottom: 2px;
}

.editor-insertVideoModal-searchBar-proLimitedMessage-sub{
  font-size: 12.5px;
  font-weight: $lato-medium;
  letter-spacing: 0.02em;
  color: $textMid;
}

.editor-insertVideoModal-searchBar-proLimitedMessage-sub span{
  text-decoration: underline;
  color: $blue;
}


// FOOTER WHEN EMPTY WITH YT LOGO

.editor-insertVideoModal-footer {

  position: absolute;

  //height: 90px;
  height: 60px;
  bottom: 16px;
  left: 50%;

  transform: translateX(-50%);

  padding-left: 60px;
  padding-right: 60px;
  box-sizing: border-box;

  border-radius: 10px;

  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.editor-insertVideoModal-footer-YTIconContainer-youtubeLogo {
  width: 100px;
}

a.editor-insertVideoModal-footer-YTLinkContainer-link {
  margin-top: 5px;
}

.editor-insertVideoModal-footer--isSearching{
  opacity: 0.25;
  transition: opacity 50ms linear;
}





// SEARCH RESULTS

.editor-insertVideoModal-resultsContainer{
  padding-top: 10px;

  // width: calc(100% + 48px);
  margin-left: -24px;

  box-sizing: border-box;
  padding-left: 12px;
  padding-right: 12px;

  overflow-y: scroll;

  height: calc(100% - 48px);

  // background: $tempb;

  padding-bottom: 24px;
}

.editor-insertVideoModal-videoResult{
  width: 100%;

  box-sizing: border-box;
  padding-left: 12px;
  padding-right: 12px;

  padding-top: 6px;
  padding-bottom: 6px;

  border-radius: 5px;

  // background: $tempb;

  display: flex;
  align-items: top;

  cursor: pointer;

  position: relative;
}

.editor-insertVideoModal-videoResult:hover{
  background: transparentize($blue,0.95);
}

// LOAD MORE

.editor-insertVideoModal-resultsContainer-loadMoreContainer{
  width: 100%;
  height: 50px;
  // background: $tempr;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;

  padding-top: 12px;
  padding-bottom: 0px;
}

button.editor-insertVideoModal-resultsContainer-loadMoreBtn{
  width: 80%;
  margin-left: 24px; // this is because scroll container is assymetrical
  height: 100%;
  background: none;
  background: $blue-extraLight;
  border: none;

  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;

  color: $textBlue;
  letter-spacing: 0.02em;

  font-size: 14.5px;
  font-weight: $lato-semibold;

  border-radius: 5px;
}

button.editor-insertVideoModal-resultsContainer-loadMoreBtn:hover{
  background: lighten($blue, 43%);
}

button.editor-insertVideoModal-resultsContainer-loadMoreBtn--loading,
button.editor-insertVideoModal-resultsContainer-loadMoreBtn--loading:hover{
  background: lighten($blue, 41%);
}

// .editor-insertVideoModal-videoResult:last-child{
// 	border-bottom: 1px solid $invisible;
// // }

// .editor-insertVideoModal-videoResult-border{
// 	position: absolute;
// 	bottom: 0px;
// 	height: 1px;
// 	background: $lineMid;
// 	left: 10px;
// 	right: 10px;
// // }

// .editor-insertVideoModal-videoResult:last-child
// .editor-insertVideoModal-videoResult-border{
// // 	background: $invisible;

// }

// .editor-insertVideoModal-videoResult:hover
// .editor-insertVideoModal-videoResult-border{
// 	background: $invisible;
// }


$heightVideoResultThumbnail: 106px;
.editor-insertVideoModal-videoResult-thumbnail{
  width: calc(#{$heightVideoResultThumbnail} * 16 / 9);
  height: $heightVideoResultThumbnail;

  margin-right: 10px;

  flex-grow: 0;
  flex-shrink: 0;

  border-radius: 4px;
  background: $gray-12;

  overflow: hidden;

  position: relative;
}

.editor-insertVideoModal-videoResult-thumbnail-duration{
  position: absolute;
  z-index: 5;
  bottom: 4px;
  right: 4px;

  font-size: 12.5px;
  font-weight: $lato-bold;
  color: white;

  background: $gray-1;

  padding-left: 5px;
  padding-right: 5px;
  padding-top: 1px;
  padding-bottom: 1px;

  border-radius: 3px;
}

.editor-insertVideoModal-videoResult-thumbnail img{
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.editor-insertVideoModal-videoResult-info{
  flex-grow: 1;
  flex-shrink: 1;
  min-width: 10px;

  //padding-top: 12px;
  padding-top: 18px;
  user-select: none;
  // display: flex;
  // flex-direction: column;
  // align-items: flex-start;
  // justify-content: center;

}

.editor-insertVideoModal-videoResult-info-title{
  font-size: 15px;
  font-weight: $lato-bold;
  color: $textBlackDark;

  letter-spacing: 0.02em;
  line-height: 1.15;

  box-sizing: border-box;
  padding-right: 24px; // narrower so easier to read

  // white-space: nowrap;
 //  overflow: hidden;
 //  text-overflow: ellipsis;

  margin-bottom: 4px;


}

.editor-insertVideoModal-videoResult-info-author{
  font-size: 13px;
  font-weight: $lato-semibold;
  color: $textBlue;
  opacity: 0.8;
  letter-spacing: 0.01em;
}

.editor-insertVideoModal-videoResult-info-counts{
  font-size: 12.5px;
  font-weight: $lato-medium;
  color: $textMid;
  display: flex;
  margin-top: 1px;

}

.editor-insertVideoModal-videoResult-info-counts-CCBadge{
  font-size: 11px;
  font-weight: $lato-bold;
  padding-left: 3px;
  padding-right: 3px;
  padding-top: 1px;
  padding-bottom: 1px;

  border-radius: 2px;
  background: $gray-14;

  color: $textMid;

  margin-left: 4px;

}

.editor-insertVideoModal-videoResult-info-description{
  margin-top: 2px;
  font-size: 12px;
  font-weight: $lato-medium;
  color: $textDark;

  letter-spacing: 0.005em;

  max-height: 50px;

  overflow: hidden;
  max-width: 370px;

  display: -webkit-box;

  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;

  //opacity: 0.5;
  opacity: 0;
  display: none;
}

.editor-insertVideoModal-videoResult:hover
.editor-insertVideoModal-videoResult-info-description{
  opacity: 0;
}

// No Results
.editor-insertVideoModal-results--noResults{
  padding-top: 96px;
  //padding-left: 40px;

  width: 100%;
  text-align: center;

  font-size: 15.5px;
  font-weight: $lato-medium;
  color: $textMid;

  user-select: none;
  cursor: default;
}
