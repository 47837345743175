//
// FORM FIELD SELECT CLASS COLOR
//

.formField-classColorBtn--row{
	display: flex;
	height: $height-formField;
	align-items: center;
	justify-content: space-between;

	margin-top: 10px;
	margin-bottom: 6px;

	border-radius: 4px;
}

.formField-classColorBtn--row label {
	width: 22px;
	height: 22px;
	border-radius: 11px;
	background: $red;
	position: relative;
	transition: transform 250ms cubic-bezier(.2, .3, .25, .9);
}

.formField-classColorBtn--row label:hover{
	transform: scale(1.05);
	transition: transform 75ms cubic-bezier(.2, .3, .25, .9);
}

.formField-classColorBtn--row input[type=radio]{
	visibility: hidden;
	height: 0px;
	width: 0px;
}

.formField-classColorBtn--row label .icon{
	width: 12px;
	height: 12px;
	position: absolute;
	top: 5px;
	left: 5px;	
	fill: $white;
}

.formField-classColorBtn--row label .icon{
	opacity: 0;
}

.formField-classColorBtn--row input[type=radio]:checked ~ .icon{
	opacity: 1;
}

.formField-classColorBtn--row label.is--color-blue				{ background: $classColor-blue; }
.formField-classColorBtn--row label.is--color-purple			{ background: $classColor-purple; }
.formField-classColorBtn--row label.is--color-red				{ background: $classColor-red; }
.formField-classColorBtn--row label.is--color-orange			{ background: $classColor-orange; }
.formField-classColorBtn--row label.is--color-green			{ background: $classColor-green; }
.formField-classColorBtn--row label.is--color-sky				{ background: $classColor-sky; }
.formField-classColorBtn--row label.is--color-pink				{ background: $classColor-pink; }
.formField-classColorBtn--row label.is--color-teal				{ background: $classColor-teal; }
.formField-classColorBtn--row label.is--color-grey				{ background: $classColor-grey; }