//
// EMAIL SUBMIT BUTTONS
//

// FORM-FOCUSED
button.signin-form-submitBtn,
button.signin-form-submitBtn:focus,
form.signin-form.signin-form--anyTouched button.signin-form-submitBtn,
form.signup-form.signup-form--anyTouched button.signup-form-submitBtn,
.verifyPasswordToSignin-form button.sign-in,
.verifyPasswordToSignin-form button.sign-in:focus {
  color: $textBlue;

  background: $white;
  border-color: $gray-1125;

  box-shadow: 0px 2px 4px 0px rgba(0,0,20,0.05);
}

// Webkit fancy rules
form.signin-form:focus-within button.signin-form-submitBtn,
form.signup-form:focus-within button.signup-form-submitBtn,
.verifyPasswordToSignin-form button.sign-in {
  color: $textBlue;

  background: $white;
  border-color: $gray-1125;

  box-shadow: 0px 2px 4px 0px rgba(0,0,20,0.05);
}

// FORM FOCUSED AND HOVER
form.signin-form:focus-within button.signin-form-submitBtn:hover,
form.signin-form.signup-form--anyTouched button.signin-form-submitBtn:hover,
form.signup-form:focus-within button.signup-form-submitBtn:hover,
form.signup-form.signup-form--anyTouched button.signup-form-submitBtn:hover{
  color: darken($textBlue, 2%);
  box-shadow: 0px 2px 4px 0px rgba(0,0,20,0.1);
}

//
// DEFAULT (INACTIVE) – ONLY FOR SIGNUP FORM
//

button.signup-form-submitBtn{
  color: lighten($textMid, 10%);

  background: $white;
  border: 1px solid $lineMid;

  box-shadow: none;
}


//
// STATES FOR CONTINUE WITH EMAIL ONLY

//
// VALID AND READY TO GO
// currently not different
form.signin-form.signin-form--valid button.signin-form-submitBtn,
form.signup-form.signup-form--valid button.signup-form-submitBtn{
  background: $blue-light;
  border-color: $blue;
  color: $white;

  box-shadow: 0px 2px 4px 0px rgba(0,0,20,0.05);
}


//
// VALID AND READY TO GO AND FOCUSED
//

form.signin-form.signin-form--valid button.signin-form-submitBtn:focus,
form.signin-form.signin-form--valid button.signin-form-submitBtn:hover,
form.signup-form.signup-form--valid button.signup-form-submitBtn:focus,
form.signup-form.signup-form--valid button.signup-form-submitBtn:hover{
  background: darken($blue-light, 5%);
  border-color: darken($blue, 5%);
  color: $white;

  box-shadow: 0px 2px 4px 0px transparentize($blue, 0.8),
  0px 0px 14px 0px transparentize($blue, 0.5);

}



//
/// SUBMIT LOADING ANIMATION
//

.signup-form-submitBtn-loadingSpinnerContainer,
.signin-form-submitBtn-loadingSpinnerContainer{
  position: absolute;

  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.signup-form-submitBtn-loadingSpinner,
.signin-form-submitBtn-loadingSpinner{

  height: 20px;
  width: 20px;

  border-radius: 16px;

  border: 3px rgba(26,28,34,0.55) solid;
  border-top: 3px $white solid;

  opacity: 0.5;

  animation: spinnerRotateAnimation .3s infinite linear;
}
