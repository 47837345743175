.liveViewModeSwitchDropdown{
	position: relative;
}

button.liveViewModeSwitchDropdown-button{
	width: 130px;
	height: 30px;

	display: flex;

	//background: $tempr;

	align-items: center;
	justify-content: flex-start;

	box-sizing: border-box;
	padding-right: 8px;
	padding-left: 12px;

	border: none;
	border-radius: 4px;

	background: rgba(0,0,40,0.03);

	cursor: pointer;
}

button.liveViewModeSwitchDropdown-button:hover{
	background: rgba(0,0,40,0.05);	
}

.liveViewModeSwitchDropdown-button-iconContainer{
	width: 16px;
	height: 16px;
	margin-right: 5px;
}

.liveViewModeSwitchDropdown-button-iconContainer svg.icon{
	width: 100%;
	height: 100%;
	fill: $gray-6;
	opacity: 0.6;
}

.liveViewModeSwitchDropdown-button-iconContainer svg.icon #backcard{
	opacity: 0.4;
}


.liveViewModeSwitchDropdown-button-label{
	font-weight: $lato-semibold;
	color: $textMid;
	font-size: 14.5px;
	letter-spacing: 0.01em;
}

.liveViewModeSwitchDropdown-button:hover
.liveViewModeSwitchDropdown-button-label{
	color: $textBlackDark;
}

.liveViewModeSwitchDropdown-button:hover
.liveViewModeSwitchDropdown-button-iconContainer svg.icon{
	opacity: 0.9;
}


.liveViewModeSwitchDropdown-button-dropdownIcon{
	width: 10px;
	height: 10px;
	margin-left: auto;
	// background: $tempr;
	position: relative;
	top: -4px;
}

.liveViewModeSwitchDropdown-button-dropdownIcon svg{
	width: 100%;
	height: 100%;
	transform: rotate(90deg);
	fill: $gray-5;
	opacity: 0.2;
}

button.liveViewModeSwitchDropdown-button:hover
.liveViewModeSwitchDropdown-button-dropdownIcon svg{
	opacity: 1;
}


// DROPDOWN

.liveViewModeSwitchDropdown .dropdownMenu.position--left.direction--down{
	width: 310px;
	top: 35px;
	left: -85px;
	background: $gray-15;
}


.liveViewModeSwitchDropdown-dropdown-item{
	display: flex;
	cursor: pointer;

	background: $gray-15;
}

.liveViewModeSwitchDropdown-dropdown-item:hover{
	background: $white;
}

.liveViewModeSwitchDropdown .dropdownMenu:not(:hover)
.liveViewModeSwitchDropdown-dropdown-item--active{
	background: $white;
}

.liveViewModeSwitchDropdown .dropdownMenu:hover
.liveViewModeSwitchDropdown-dropdown-item{
	opacity: 0.75;
}


.liveViewModeSwitchDropdown .dropdownMenu:hover
.liveViewModeSwitchDropdown-dropdown-item:hover{
	opacity: 1;
}

.liveViewModeSwitchDropdown-dropdown-item-leftContainer{
	display: flex;
	width: 35px;
	flex-grow: 0;
	flex-shrink: 0;
	border-left: 4px solid $invisible;
}

.liveViewModeSwitchDropdown-dropdown-item--disabled{
	pointer-events: none;
	opacity: 0.5;
}

.liveViewModeSwitchDropdown-dropdown-item--active
.liveViewModeSwitchDropdown-dropdown-item-leftContainer{
	// border-left: 4px solid $blue;
}

// ICON

.liveViewModeSwitchDropdown-dropdown-item-iconContainer{
	width: 16px;
	height: 16px;
	//background: $tempr;
	margin-top: 9px;
	margin-left: auto;
	margin-right: 10px;
}



.liveViewModeSwitchDropdown-dropdown-item-iconContainer svg.icon{
	width: 100%;
	height: 100%;
	fill: $gray-8;
}


.liveViewModeSwitchDropdown-dropdown-item-iconContainer svg.icon.npmode--hybrid{
	width: 20px;
	height: 20px;
	margin-left: -3px;
}





.liveViewModeSwitchDropdown-dropdown-item--active 
.liveViewModeSwitchDropdown-dropdown-item-iconContainer svg.icon{
	fill: $blue;
}

.liveViewModeSwitchDropdown-dropdown-item
.liveViewModeSwitchDropdown-dropdown-item-iconContainer svg.icon #backcard{
	opacity: 0.4;
}

//

.liveViewModeSwitchDropdown-dropdown-item-rightContainer{
	width: 10px;
	flex-grow: 1;
	flex-shrink: 1;

	padding-top: 9px;
	padding-bottom: 10px;
	padding-right: 12px;

	border-bottom: 1px solid $lineDark;

	position: relative;
}


.liveViewModeSwitchDropdown-dropdown-item:nth-child(3)
.liveViewModeSwitchDropdown-dropdown-item-rightContainer{
	border-bottom: none;
}

.liveViewModeSwitchDropdown-dropdown-item-title{
	font-size: 15px;
	font-weight: $lato-semibold;
	// letter-spacing: 0.01em;
	margin-bottom: 2px;
	color: $textBlackDark;
}

.liveViewModeSwitchDropdown-dropdown-item--active
.liveViewModeSwitchDropdown-dropdown-item-title{
	font-weight: $lato-bold;
}

.liveViewModeSwitchDropdown-dropdown-item-description{
	font-size: 13px;
	font-weight: $lato-medium;
	letter-spacing: 0.005em;
	color: lighten($textBlack, 8%);
}

.liveViewModeSwitchDropdown-dropdown-item--active 
.liveViewModeSwitchDropdown-dropdown-item-description{
	color: $textBlack;
}

//

.liveViewModeSwitchDropdown-dropdown-onlineStudentCountContainer{
	width: 100%;
	padding-top: 4px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.liveViewModeSwitchDropdown-dropdown-onlineStudentCount{
	display: flex;
	align-items: center;
	justify-content: center;

	font-size: 14px;
	font-weight: $lato-bold;
	
	border-radius: 3px;
	padding-top: 4px;
	padding-bottom: 4px;

}

.liveViewModeSwitchDropdown-dropdown-onlineStudentCount--active{
	width: 130px;
	background: transparentize($color-o85, 0.85);
	color: darken($color-o85, 10%);
}

.liveViewModeSwitchDropdown-dropdown-onlineStudentCount--inactive{
	width: 200px;
	background: $gray-13;
	color: $textMid;
}

//

.liveViewModeSwitchDropdown-dropdown-syncActivateTipContainer{
	width: 100%;
	padding-top: 1px;
	padding-bottom: 0px;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
}

.liveViewModeSwitchDropdown-dropdown-syncActivateTip{
	width: 240px;
	text-align: center;

	font-size: 13.5px;
	font-weight: $lato-medium;
	color: $textDark;

	border-radius: 4px;
	padding-top: 7px;
	padding-bottom: 7px;
	padding-left: 10px;
	padding-right: 10px;



	background: $white;
	border: 1px solid darken($lineMid, 5%);
	border-bottom: 1px solid $lineDark;
	
	
}

.liveViewModeSwitchDropdown-dropdown-syncActivateTip:hover{
	color: $textBlack;
}

.liveViewModeSwitchDropdown-dropdown-syncActivateTip span{
	color: $blue;
}

//
// TOOLTIP
//

.liveViewModeSwitchDropdown-tooltipContainer{
	position: absolute;
	top: 32px;
	left: -26px;
	right: 0px;
	height: 78px;
	width: 252px;
	z-index: 99;
}

.liveViewModeSwitchDropdown-tooltip{
	box-sizing: border-box;
	margin-top: 6px;
	padding-top: 10px;
	padding-bottom: 6px;
	width: 100%;
	padding-left: 10px;
	padding-right: 10px;
	position: relative;
	z-index: 10;
}

.liveViewModeSwitchDropdown-tooltip-label{
	font-size: 14.5px;
	font-weight: $lato-bold;
	color: $white;
	letter-spacing: 0.01em;
	margin-bottom: 4px;
	letter-spacing: 1.6;
}

.liveViewModeSwitchDropdown-tooltip-dismiss{
	font-size: 14px;
	font-weight: $lato-semibold;
	color: $white;
	opacity: 0.75;
	cursor: pointer;
}

.liveViewModeSwitchDropdown-tooltip:hover
.liveViewModeSwitchDropdown-tooltip-dismiss{
	opacity: 1;	
}

.liveViewModeSwitchDropdown-tooltipBG{
	position: absolute;
	top: 0px;
	left: 0px;
	right: 0px;
	bottom: 0px;
}

.liveViewModeSwitchDropdown-tooltipBG svg{
	width: 100%;
	height: 100%;
}

.liveViewModeSwitchDropdown-tooltipBG svg #border{
	fill: $blue-bold;
}

.liveViewModeSwitchDropdown-tooltipBG svg #bg{
	fill: $blue;
	opacity: 0.95;
}

.liveViewModeSwitchDropdown-tooltipContainer:hover
.liveViewModeSwitchDropdown-tooltipBG svg #bg{
	opacity: 1;
}