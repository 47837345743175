//
// BUTTONS
// this is the <Button /> component
// it is used especially on older pages
// a large number of modifier-classes are applied in the component for size, color, etc.
// buttons can be just text, just icon, or text and icon
//

//
// RESET BROWSER
// (maybe should be refactored to browser reset)
button{
	padding: 0px;
}

button:focus,
button:active {
	padding: 0px;
	border: none;
	outline: none !important;
	box-shadow: none;
}

//
// BUTTON - BASIC LAYOUT

button.btn {
	cursor: default;
	user-select: none;

	padding: 0px;

	display: flex;
	justify-content: center;
	align-items: center;

	position: relative;
}

// 
// BUTTON SIZES (SMALL, MID, LARGE)

.btn--small, .btn--mid, .btn--large, .btn--xLarge{
	border: 1px solid;
	box-sizing: border-box;
}

button.btn--small {
	border-radius: 2.5px;	
	
	height: 28px;
	padding-left: 8px;
	padding-right: 8px;

	font-size: 13px;
	font-weight: $lato-medium;
	line-height: 1.92;
}

.btn--small .icon {
	width: 11px;
	height: 11px;
	margin-right: 5px;
}

//

button.btn--mid {
	border-radius: 3px;
	
	height: 30px;
	padding-left: 8px;
	padding-right: 8px;

	font-size: 14px;
	font-weight: $lato-semibold;
	line-height: 1.9;
}

.btn--mid .icon {
	width: 12px;
	height: 12px;
	margin-right: 6px;
}

.btn--mid.icon--large .icon {
	width: 15px;
	height: 15px;
}

//

.btn--midLarge .icon{
	width: 14px;
	height: 14px;
	margin-right: 6px;
}

button.btn--midLarge{
	border-radius: 3px;
	height: 32px;
	padding-right: 12px;
	padding-left: 12px;
	font-size: 15px;
	font-weight: $lato-semibold;
}

//

button.btn--large{
	border-radius: 4px;

	height: 36px;
	padding-left: 10px;
	padding-right: 10px;
	
	font-size: 16px;
	font-weight: $lato-semibold;
	line-height: 2;
}

.btn--large .icon {
	width: 14px;
	height: 14px;
	margin-right: 7px;
}

.btn--large.icon--large .icon {
	width: 16px;
	height: 16px;
}

.btn--xLarge .icon {
	width: 16px;
	height: 16px;
	margin-right: 8px;
}

//

button.btn--xLarge{
	border-radius: 4px;

	height: 40px;
	padding-left: 10px;
	padding-right: 10px;
	
	font-size: 18px;
	font-weight: $lato-semibold;
	line-height: 2;
}

//
// OVERFLOW ELLIPSES
// used for troublesome google translated buttons
.btn--overflowEllipses{
	white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

// 
// BUTTON COLORS (WHITE, BLUE, GRAY)

button.btn--white {
	color: $textDark;
	border: 1px solid $gray-1225;
	background: linear-gradient(to bottom, rgb(255,255,255) 0%, rgb(251,251,251) 100%); 
}

button.btn--white:hover {
	border: 1px solid $gray-115;
	background: $white;
	box-shadow: 0px 1px 1px 0px rgba(0,0,0,0.04);
}

button.btn--white:active {
	border: 1px solid $gray-10;
	background-color: $gray-15;
}

button.btn--white .icon {
	fill: $blue;
}

button.btn--white.label--light { color: $textMid; 		}
button.btn--white.label--black { color: $textBlack; 	}
button.btn--white.icon--green .icon { fill: $green;		}
button.btn--white.icon--gray .icon { fill: $textLight;	}

// 

.btn--blue {
	color: $white;
	background: linear-gradient(to bottom, $blue-light 0%, $blue-light 100%); 
	border: 1px solid $blue;	
	// text-shadow: 0px 0px 2px $blue-dark;
}

.btn--blue:hover{	
	// background: linear-gradient(to bottom, saturate(rgb(79,161,255), 10%) 0%, saturate(rgb(75,157,250), 10%) 100%); 
	background: saturate($blue-light, 10%);
	border: 1px solid saturate($blue, 10%);
	box-shadow: 0px 1px 1px 0px rgba(0,0,0,0.04);
}

.btn--blue:active{
	background: linear-gradient(to bottom, saturate(rgb(79,161,255), 10%) 0%, saturate(rgb(75,157,250), 10%) 100%); 
	border: 1px solid saturate($blue-dark, 10%);
}

.btn--blue .icon {
	fill: $white;
}

// 

.btn--gray,
.btn--gray:hover,
.btn--gray:active {
	color: $white;
	background: linear-gradient(to bottom, rgb(155,155,163) 0%, rgb(155,155,161) 100%); 
	border-color: rgb(146,146,156);
	pointer-events: none;
}

//

.btn--red {
	color: $white;
	background: linear-gradient(to bottom, rgb(248,106,106) 0%, $red 100%); 
	border-color: $red-dark;
}

.btn--red:hover{		
	background: saturate($red, 10%);
	border: 1px solid saturate($red-dark, 10%);
	box-shadow: 0px 1px 1px 0px rgba(0,0,0,0.04);
}

.btn--red:active{
	background: linear-gradient(to bottom, saturate(rgb(248,106,106), 10%) 0%, saturate($red, 10%) 100%); 
	border: 1px solid saturate($red-dark, 10%);
}

//

.btn--dark, .btn--dark:hover{
	color: $white;
	background: rgba(26,28,34,0.7); 
	border-color: rgba(26,28,34,0.95);
}

.btn--dark:active{
	background: rgba(26,28,34,0.9); 
	border-color: rgba(26,28,34,1);
}

.btn--dark .icon {
	fill: $white;
}

// 

$btn--green-bg: rgb(49,201,136);
$btn--green-borderColor: rgb(40,178,122);

.btn--green {
	color: $white;
	background: $btn--green-bg; 
	border: 1px solid $btn--green-borderColor;
}

.btn--green:hover{	
	background: saturate($btn--green-bg, 10%);
	border: 1px solid saturate($btn--green-borderColor, 10%);
	box-shadow: 0px 1px 1px 0px rgba(0,0,0,0.04);
}

.btn--green:active{
	background: saturate($btn--green-bg, 15%);
	border: 1px solid saturate($btn--green-borderColor, 15%);
}

.btn--green .icon {
	fill: $white;
}

//
// LIGHT TEXT BUTTON

.btn--white.btn--lightText{
	color: $textMid;
}


// 
// BUTTON TYPES (ICON, TEXT, ICON ONLY)

.btn--iconOnly .icon{
	margin: 0px;
}

.btn--icon {
	line-height: 1.8;
}

.btn--icon .icon {
	height: 11px;
	position: relative;
	top: 1px;
}

.btn--white.btn--icon .icon {
	fill: $blue;
}

.btn--white.btn--icon:hover .icon {
	fill: $blue;
}

//
// GHOST BUTTON 

button.btn--ghost{
	cursor: pointer !important;
}

.btn--ghost,
.btn--ghost.btn--blue,
.btn--ghost.btn--gray{
	background: none;
	border: none;
	box-shadow: none;
}

.btn--ghost.btn--blue:hover{
	background: transparentize($blue-extraLight, 0.5);
}

.btn--ghost,
.btn--ghost.btn--blue,{
	color: $textBlue;
}

.btn--ghost,
.btn--ghost.btn--blue .icon,{
	fill: $blue;
}

.btn--ghost.btn--gray{
	color: $textDark;
}

.btn--ghost.btn--gray .icon{
	fill: $gray-8;
}


//
// SPECIAL SIDEPANEL BUTTON

button.btn--sidePanel{	
	justify-content: flex-start;
	width: 195px;
	border: 1px solid $invisible;
	background: none;
	transition: all 150ms cubic-bezier(.2, .3, .25, .9);
}

button.btn--sidePanel:hover{
	background: $white;
	border: 1px solid rgb(235,235,240);
	transition: all 25ms cubic-bezier(.2, .3, .25, .9);
}

//
// MENU BUTTON (AS IN DETAIL PANEL EMPTY)

.btn--menu {
	height: 36px;
	text-align: left;
	color: $gray-5;
	width: 214px;
	display: block;
	font-weight: $lato-semibold;
	font-size: 15px;
	// background: $tempr;
	padding-left: 8px;
	border-radius: 4px;
	line-height: 2.2;
	box-sizing: border-box;
}

.btn--menu:hover{
	background: $gray-14;
	color: $gray-4;
}


//
// DISABLED BUTTONS

button.btn--disabled{	
	opacity: 0.45;
	cursor: default;
	pointer-events: none;
}


//
// DROPDOWN BUTTONS

.btn.btn--dropdown.btn--dropdown--open{
	background: rgb(250,250,255);
	border-color: rgb(200,200,210);
}

//
// CREATE GREEN BUTTON

.btn--create {
	border-radius: 4px;
	border: 1px solid $green-dark;
	box-sizing: border-box;
	background: linear-gradient(to bottom, rgb(46,199,153) 0%, rgb(48,202,157) 100%); 
	box-shadow: 0px 1px 1px 0px rgba(0,0,0,0.04);
	color: $white;
	text-shadow: 0px 0px 2px $green-dark;
}

.btn--create:hover{
	border: 1px solid saturate($green-dark, 5%);
	background: linear-gradient(to bottom, saturate(rgb(46,199,153), 5%) 0%, saturate(rgb(48,202,157), 5%) 100%); 
}

.btn--create:active{
	border: 1px solid saturate($green-dark, 10%);
	background: linear-gradient(to bottom, saturate(rgb(46,199,153), 10%) 0%, saturate(rgb(48,202,157), 10%) 100%); 	
}

// Play Button (with btn white)
.btn--play {
	line-height: 1.8;
	width: 69px;
	font-size: 14px;
	font-weight: $lato-semibold;
	color: $textDark;
	// display: flex;
	// align-items: center;
	// justify-items: center;
}

.btn--play .icon.play {
	// fill: $gray-8;
	fill: $blue;
	width: 9px;
	height: 11px;
	margin-left: 12px;
	position: relative;
	top: 1.2px;
	margin-right: 1.5px;
}

.btn--play:hover .icon.play{
	fill: $blue;
}


// MORE / 3 DOTS (WITH BUTTON WHITE)

.btn--more{
	width: 44px;
	height: 32px;
	display: flex;
  	align-items: center;
 	justify-content: center;
}

.btn--white.btn--more .icon{
	fill: $gray-8;
}

.btn--white.btn--more:hover .icon{
	fill: $gray-6;
}

// 
// SUBMIT BUTTONS

button.btn.btn--large.can--submit,
button.btn.btn--large.canNot--submit{
	width: 194px;
	font-weight: $lato-bold;
	// min-width: 194px;
}

button.btn.canNot--submit{
	pointer-events: none;
}

button.btn.btn--large.cancel.can--submit,
button.btn.btn--large.cancel.canNot--submit{
	width: 140px;
	font-weight: $lato-bold;
}

button.btn.btn--large.cancel.canNot--submit{	
	color: $textLight;
}


button.btn.btn--XLarge.can--submit,
button.btn.btn--XLarge.canNot--submit{
	width: 194px;
	font-weight: $lato-heavy;
}

// 
// SUBMIT LOADING
// this is the state after button is clicked and action is yet to be confirmed

button.btn.canNot--submit.submit--loading{	
	color: $invisible;
	background: saturate($blue, 10%);
	border: 1px solid saturate($blue-dark, 10%);
	box-shadow: 0px 1px 1px 0px rgba(0,0,0,0.04);	
}

button.btn.btn--red.canNot--submit.submit--loading{
	background: saturate($red, 10%);
	border: 1px solid saturate($red-dark, 10%);
}

button.btn.canNot--submit.submit--loading{
	color: $invisible;
}

.button-loadingSpinnerContainer{
	position: absolute;
	
	display: flex;
	align-items: center;
	justify-content: center;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
}

.button-loadingSpinner{
	height: 20px;
	width: 20px;
		
  border-radius: 16px;

  border: 3px rgba(26,28,34,0.55) solid;
  border-top: 3px $white solid;

  opacity: 0.5;

  animation: spinnerRotateAnimation .3s infinite linear;
}
