.scanHelper{
	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	z-index: 9999;

}

.scanHelper-NPContainer{
	position: absolute;
	top: 0px;
	bottom: 0px;
	left: 0px;
	right: 300px;
}

.scanHelper-mediaControlContainer{
	position: absolute;
	right: 0px;
	width: 300px;
	bottom: 0px;
	top: 0px;
}

.scanHelper-toolbar{
	display: flex;
	width: 100%;
	height: 50px;
	box-sizing: border-box;
	padding-left: 16px;
	padding-right: 16px;

	align-items: center;
	font-size: 18px;
}

.scanHelper-toolbar-title{
	font-weight: $lato-heavy;
}

.scanHelper-toolbar-nowplaying{
	margin-left: 24px;
	max-width: 400px;
	text-overflow: ellipses;
	white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.scanHelper-toolbar-section{
	margin-left: 24px;
	max-width: 400px;
	text-overflow: ellipses;
	white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.scanHelper-toolbar-controls{
	margin-left: auto;
	display: flex;
	align-items: center;
}

.scanHelper-toolbar-controls-slider{
	width: 200px;
}

.scanHelper-toolbar-controls-slider .rc-slider-track{
	background: $blue;
}

.scanHelper-toolbar-controls-slider .rc-slider-handle{
	border-color: $blue;
}

.scanHelper-toolbar-controls-slider .rc-slider-handle:hover{
	border-color: $blue;	
}

.scanHelper-toolbar-controls-slider .rc-slider-handle:active{
	border-color: $blue;
	box-shadow: none;
}

.scanHelper-toolbar-controls-number{
	width: 60px;
	text-align: right;
}

.scanHelper-toolbar-controls-label{
	width: 100px;
}

.scanHelper-cards{
	width: 100%;
	display: flex;

	justify-content: center;

	flex-wrap: wrap;
	position: relative;
}

.scanHelper-cards-card{
	position: relative;
	width: 140px;
	height: 160px;
	margin: 8px;
	//background: $white;
	//border-radius: 3px;
	//border: 1px solid $lineMid;
	//box-shadow: 0px 2px 4px 0px rgba(0,0,20,0.12);
}

.scanHelper-cards-card img{
	position: absolute;
	width: 140px;
	height: 140px;
	top: 0px;
	left: 0px;

}

.scanHelper-cards-card-label{
	position: absolute;
	bottom: 0px;
	left: 0px;
	right: 0px;
	height: 20px;
	display: flex;
	cursor: default;
}

.scanHelper-cards-card-label-name{
	flex-grow: 1;
	height: 100%;
	box-sizing: border-box;
	padding-left: 9px;
	line-height: 1;
	font-size: 14px;
	color: $textDark;
}

.scanHelper-cards-card-label-letter{
	height: 100%;
	width: 20px;
	flex-grow: 0;
	font-size: 14px;
	color: $textMid;
	line-height: 1;
}

// MEDIA CONTROL

.scanHelper-mediaControlContainer{
	border-left: 1px solid $lineDark;
	background: $gray-15;
	padding: 8px;
	box-sizing: border-box;
}

.scanHelper-mediaControl{
	padding: 8px;
	border: 1px solid $lineDark;
	background: $white;
	border-radius: 4px;

	box-shadow: 0px 2px 4px 0px rgba(0,0,20,0.08);
}

.scanHelper-mediaControlContainer-title{
	font-size: 18px;
	font-weight: $lato-heavy;
	color: $textBlack;
	margin-bottom: 12px;
	margin-top: 8px;
	text-align: center;
	width: 100%;
}

.scanHelper-mediaControlContainer-question,
.scanHelper-mediaControlContainer-choice{
	width: 100%;
	padding-top: 14px;
	padding-bottom: 8px;

	border-top: 1px solid $lineMid;
	border-bottom: 1px solid $lineMid;
}

.scanHelper-mediaControlContainer-question--inactive,
.scanHelper-mediaControlContainer-choice--inactive{
	opacity: 0.5;
}

.scanHelper-mediaControlContainer-question-title,
.scanHelper-mediaControlContainer-choice-title{
	text-transform: uppercase;
	font-size: 13px;
	font-weight: $lato-bold;
	letter-spacing: 0.02em;

	margin-bottom: 12px;
	color: $textDark;

	width: 100%;
	text-align: center;
}

.scanHelper-mediaControlContainer-question-mediaTypeTable,
.scanHelper-mediaControlContainer-choice-mediaTypeTable{
	display: flex;
	align-items: center;
	justify-content: center;
}

.scanHelper-mediaControlContainer-question-mediaTypeTable-type,
.scanHelper-mediaControlContainer-choice-mediaTypeTable-type{
	width: 33%;
	flex-grow: 0;
	flex-shrink: 0;

	display: flex;
	align-items: center;
	justify-content: center;

	font-size: 13px;
	font-weight: $lato-semibold;

	height: 25px;
	border: 1px solid $lineMid;
}

.scanHelper-mediaControlContainer-question-mediaTypeTable-type--inactive,
.scanHelper-mediaControlContainer-choice-mediaTypeTable-type--inactive{
	background: $gray-14;
	color: $textLight;
	border: 1px solid $lineMid;
	opacity: 0.5;
}

.scanHelper-mediaControlContainer-question-mediaTypeTable-type--active,
.scanHelper-mediaControlContainer-choice-mediaTypeTable-type--active{
	border: 1px solid $blue;
	font-size: 14px;
	background: $blue;
	color: $white;
	font-weight: $lato-bold;
}

.scanHelper-mediaControlContainer-question-mediaTypeTable-type:first-child,
.scanHelper-mediaControlContainer-choice-mediaTypeTable-type:first-child{
	border-top-left-radius: 3px;
	border-bottom-left-radius: 3px;
}

.scanHelper-mediaControlContainer-question-mediaTypeTable-type:last-child,
.scanHelper-mediaControlContainer-choice-mediaTypeTable-type:last-child{
	border-top-right-radius: 3px;
	border-bottom-right-radius: 3px;
}

.scanHelper-mediaControlContainer-question-controls,
.scanHelper-mediaControlContainer-choice-controls{
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-top: 8px;
}

button.scanHelper-mediaControlContainer-question-controls,
button.scanHelper-mediaControlContainer-choice-controls{
	width: 40%;
	height: 24px;
	margin: 5px;

	height: 40px;

	border-radius: 4px;

	box-shadow: 0px 1px 2px 0px rgba(0,0,20,0.04);

	cursor: pointer;
}

button.scanHelper-mediaControlContainer-question-controls svg,
button.scanHelper-mediaControlContainer-choice-controls svg{
	height: 16px;
	width: 16px;
	fill: $gray-6;
}

button.scanHelper-mediaControlContainer-question-controls--active svg,
button.scanHelper-mediaControlContainer-choice-controls--active svg{
	height: 20px;
	width: 20px;
	fill: $white;

}

button.scanHelper-mediaControlContainer-question-controls--expand.scanHelper-mediaControlContainer-question-controls--active,
button.scanHelper-mediaControlContainer-choice-controls--expand.scanHelper-mediaControlContainer-choice-controls--active{
	background: $green;
	border: 1px solid $green-dark;
}

button.scanHelper-mediaControlContainer-question-controls--playback.scanHelper-mediaControlContainer-question-controls--active,
button.scanHelper-mediaControlContainer-choice-controls--playback.scanHelper-mediaControlContainer-choice-controls--active{
	background: $blue;
	border: 1px solid $blue-dark;
}

button.scanHelper-mediaControlContainer-question-controls--inactive,
button.scanHelper-mediaControlContainer-choice-controls--inactive{
	background: $white;
	border: 1px solid $lineMid;
}


button.scanHelper-mediaControlContainer-question-controls:disabled{
	opacity: 0.25;
}