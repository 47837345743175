.upgrade--landing-featureSection--layoutTemplates-carouselContainer{
	position: absolute;
	bottom: 0px;
	left: 0px;
	right: 0px;

	height: 400px;
	//background: $tempr;

	z-index: 5;

	overflow: hidden;
}



@keyframes layoutTemplatesCarousel {
  100% { 
    transform: translateX(-1491px);  
  }
}

.upgrade--landing-featureSection--layoutTemplates-carousel{	
	animation: layoutTemplatesCarousel 90s linear infinite;
	transform: translate3d(0,0,0);
}

.upgrade--landing-featureSection--layoutTemplates-carousel-row{
	display: flex;
	align-items: center;
	justify-content: flex-start;
}


.upgrade--landing-featureSection--layoutTemplates-carousel-row--bottom{
	transform: translateX(-210px);
}

.upgrade--landing-featureSection--layoutTemplates-carousel-row-imageContainer{
	flex-shrink: 0;
	flex-grow: 0;
	margin: 8px;
	border: 1px solid $lineMid;
	border-bottom: 2px solid $lineDark;
	background: $white;

	border-radius: 5px;

	display: flex;
	align-items: center;
	justify-content: center;

	width: 280px;
	height: 175px;

	overflow: hidden;
}

 img.upgrade--landing-featureSection--layoutTemplates-carousel-row
 img.upgrade--landing-featureSection--layoutTemplates-carousel-row-image{
	width: 280px;
	height: 175px;
}