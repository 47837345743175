//
// Publishing Flow Footer
//

$height-publishingFlowModalFooter: 80px;
$height-publishingFlowModalFooterButton: 52px;

.publishingFlowModal-footer{
	position: absolute;
	bottom: 0px;
	left: 0px;
	right: 0px;	
	height: $height-publishingFlowModalFooter;
	// background: $tempr;
}

.publishingFlowModal-footer button.btn.btn--large.btn--white.back,
.publishingFlowModal-footer button.publishingFlowModal-footer-nextBtn{
	position: absolute;
	height: $height-publishingFlowModalFooterButton;
	top: 0px;
	cursor: pointer;
}
//
//
//

.publishingFlowModal-footer button.btn.btn--large.btn--white.back{	
	left: 25px;
	width: $height-publishingFlowModalFooterButton;	
	background: none;
	border-color: $gray-125;
	box-shadow: none;
}

.publishingFlowModal-footer button.btn.btn--large.btn--white.back:hover{
	background: $white;
}

.publishingFlowModal-footer button.btn.btn--large.btn--white.back .icon{
	fill: $gray-11;
	width: 16px;
	height: 16px;
	position: relative;
	left: -1px;
}

.publishingFlowModal-footer button.btn.btn--large.btn--white.back:hover .icon{
	fill: $gray-9;
}

.publishingFlowModal-footer button.btn.btn--large.btn--white.back::after{
	top: 35px; // align tooltip better
}

.publishingFlowModal-footer button.btn.btn--large.btn--white.back:hover::after{
	transition-delay: 500ms;
}

//
//
//



button.publishingFlowModal-footer-nextBtn{	
	left: 50%;
	transform: translateX(-50%);
	width: 350px;
	background: rgb(0,114,255); // temp refactor

	display: block;

	position: relative;
	font-size: 21px;
	font-weight: $lato-heavy;
	
	letter-spacing: 0.015em;	
}



button.publishingFlowModal-footer-nextBtn:hover{
	background: darken(rgb(0,114,255), 5%);
}

button.publishingFlowModal-footer-nextBtn--disabled{
	pointer-events: none;
	background: $gray-9;
	border-color: $gray-8;
}


.publishingFlowModal-footer-pressEnterHint{
	position: absolute;
	bottom: 8px;
	width: 200px;
	left: 50%;

	transform: translateX(-50%);

	text-align: center;

	font-weight: $lato-medium;
	font-size: 12px;
	// letter-spacing: 0.01em;
	height: 14px;
	user-select: none;
	color: $textLight;
}

.publishingFlowModal-footer-pressEnterHint b{
	font-weight: $lato-bold;
	color: $textMid;
}

// SubmitLoading

.publishingFlowModal-footer-nextBtn-loadingSpinnerContainer{
	position: absolute;
	
	display: flex;
	align-items: center;
	justify-content: center;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
}

.publishingFlowModal-footer-nextBtn-loadingSpinner{

	height: 20px;
	width: 20px;
	
  border-radius: 16px;

  border: 3px rgba(26,28,34,0.55) solid;
  border-top: 3px $white solid;

  opacity: 0.5;

  animation: spinnerRotateAnimation .4s infinite linear;
}



//
// ERRORS
//

.publishingFlowModal-footer-errorContainer{
	position: absolute;
	bottom: 85px;
	left: 50%;
	transform: translateX(-50%);

	display: flex;
	align-items: center;
	justify-content: center;
}


.publishingFlowModal-footer-error{
	font-size: 14px;
	font-weight: $lato-bold;
	padding: 5px;
	padding-left: 8px;
	padding-right: 8px;
	border-radius: 4px;
	background: transparentize($red, 0.95);
	color: $red-dark;
	max-width: 360px;
	text-align: center;
}