.repoSocialMediaShareRow{
	display: flex;
	user-select: none;
}

.repoSocialMediaShareRow-item{
	
	width: 61px;
	
	flex-grow: 0;
	flex-shrink: 0;

	color: $white;

	margin-right: 7px;

	font-size: 11.5px;
	font-weight: $lato-bold;
	letter-spacing: 0.005em;
	cursor: pointer;
	height: 20px;
	border-radius: 3px;

	display: flex;	
	justify-content: center;
	align-items: center;

	line-height: 1.4;

}

// react-share styling
.repoSocialMediaShareRow .SocialMediaShareButton:focus{
	outline: none;
}

.repoSocialMediaShareRow:last-child{
	margin-right: 0px;
}

.repoSocialMediaShareRow-item--facebook{
	background: #1A76F2; // facebook blue
}

.repoSocialMediaShareRow .SocialMediaShareButton:active
.repoSocialMediaShareRow-item--facebook{
	background: darken(#1A76F2, 5%);
}

.repoSocialMediaShareRow-item--twitter{
	background: #1B95E0; // twitter blue
}

.repoSocialMediaShareRow .SocialMediaShareButton:active
.repoSocialMediaShareRow-item--twitter{
	background: darken(#1B95E0, 5%);
}

.repoSocialMediaShareRow-item--pinterest{
	background: #E60122; // pinterest red
}

.repoSocialMediaShareRow .SocialMediaShareButton:active
.repoSocialMediaShareRow-item--pinterest{
	background: darken(#E60122, 5%);
}


.repoSocialMediaShareRow-item svg.icon{
	margin-right: 4px;
	margin-left: -3px;
}

.repoSocialMediaShareRow-item--facebook svg.icon{	
	fill: $white;
	height: 10px;
	width: 10px;	
}

.repoSocialMediaShareRow-item--twitter svg.icon{
	fill: $white;
	height: 10px;
	width: 10px;
}

.repoSocialMediaShareRow-item--pinterest svg.icon{
	width: 10px;
	height: 10px;
}