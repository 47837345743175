//
// SCORESHEET TABLE RESPONSE ROWS
// these are the core table, made up of columns - one for each question - in a flex row
// collapsed columns are displayed when you collapse set
//

.scoreSheet-table-responseRows{
  display: flex;  
  overflow-y: scroll;
  overflow-x: hidden;
  //flex-shrink: 0;
  
  position: absolute;
  top: calc(#{$height-scoreSheetSetHeader} + #{$height-scoreSheetQuestionHeader} + #{$height-scoreSheetSetFooter});
  bottom: $height-scoreSheetXScrollBar;  
  left: 0px;
  right: 0px;
}


.scoreSheet-table-responseRows::-webkit-scrollbar {
  width: 0px;  
}

.scoreSheet-table-responseRows-setResponseColumnGroup{  
  display: flex;
  flex-shrink: 0;
}

// COLUMNS

.scoreSheet-table-responseRows-responseColumn{
	width: $width-scoreSheetQuestion;
}

.scoreSheet-table-responseRows-responseColumn,
.scoreSheet-table-responseRows-collapsedColumn{
  flex-shrink: 0;
  flex-direction: column;
}

// CELLS

.scoreSheet-table-responseRows-responseCell,
.scoreSheet-table-responseRows-collapsedCell{
	height: $height-scoreSheetResponseCell;
	
	cursor: default;
	user-select: none;

	box-sizing: border-box;

	display: flex;
	align-items: center;
	justify-content: center;

	border-right: 1px solid $scoreSheet-lineMid;

	background: $white;
	position: relative;
}

.scoreSheet-table-responseRows-responseCell{
	width: $width-scoreSheetQuestion;
	min-width: $width-scoreSheetQuestion;
}

.scoreSheet-table-responseRows-collapsedCell{
	justify-content: flex-end;
	padding-right: 9px;
}

.scoreSheet-table-responseRows-collapsedCell--set{
  width: $width-scoreSheetCollapsedColumn;
  min-width: $width-scoreSheetCollapsedColumn;
}

.scoreSheet-table-responseRows-collapsedCell--question{
  width: $width-scoreSheetQuestion;
  min-width: $width-scoreSheetQuestion;
}

.scoreSheet-table-responseRows-responseCell:nth-child(even),
.scoreSheet-table-responseRows-collapsedCell:nth-child(even){
	background: $color-scoreSheetCellZebra-bg;
}

.scoreSheet-table-responseRows-responseCell:last-child,
.scoreSheet-table-responseRows-collapsedCell:last-child{
	box-shadow: 0px 1px 0px 0px $lineDark;
}

// RESPONSE CELL CONTENTS

.scoreSheet-table-responseRows-responseCell-response{
	height: 18px;
	width: 18px;
	user-select: none;
	cursor: default;

	border-radius: 2px;
	border: 1px solid $invisible;

	font-size: 14px;
	font-weight: $lato-semibold;

	text-align: center;
}

.scoreSheet-table-responseRows-responseCell.correct .scoreSheet-table-responseRows-responseCell-response{
	color: $green;
}

.scoreSheet-table-responseRows-responseCell.incorrect .scoreSheet-table-responseRows-responseCell-response{
	border-color: $red;
	color: $red;
	background: rgba(255,255,255,0.5);
}

.scoreSheet-table-responseRows-responseCell.survey .scoreSheet-table-responseRows-responseCell-response{
	color: $blue;
}

.scoreSheet-table-responseRows-responseCell.absent .scoreSheet-table-responseRows-responseCell-response{
	color: $textLight;
}


// EXCLUDED 

$scoresheet-responseRows-excludedColumn-color: $scoresheet-excluded-color;

.scoreSheet-table-responseRows-responseColumn--isExcluded
.scoreSheet-table-responseRows-responseCell.correct .scoreSheet-table-responseRows-responseCell-response{
	color: $scoresheet-responseRows-excludedColumn-color;
}

.scoreSheet-table-responseRows-responseColumn--isExcluded
.scoreSheet-table-responseRows-responseCell.incorrect .scoreSheet-table-responseRows-responseCell-response{
	color: $scoresheet-responseRows-excludedColumn-color;
	border-color: $gray-12;
	background: none;
}

.scoreSheet-table-responseRows-responseColumn--isExcluded
.scoreSheet-table-responseRows-responseCell.survey .scoreSheet-table-responseRows-responseCell-response{
	color: $scoresheet-responseRows-excludedColumn-color;
}

.scoreSheet-table-responseRows-responseColumn--isExcluded .scoreSheet-table-responseRows-responseCell:nth-child(odd),
.scoreSheet-table-responseRows-responseColumn--isExcluded .scoreSheet-table-responseRows-collapsedCell:nth-child(odd){
	background: $scoresheet-excluded-cell-BG;
}

.scoreSheet-table-responseRows-responseColumn--isExcluded
.scoreSheet-table-responseRows-responseCell.absent .scoreSheet-table-responseRows-responseCell-response{
	color: lighten($textLight, 25);
}

//
// COLLAPSED CELL CONTENTS
//

.scoreSheet-table-responseRows-collapsedCell .scorePill.type--dot{
	width: auto;
	font-size: 14px;
	color: $textBlackDark;	
	font-weight: $lato-medium;
	margin-top: 3px;
}

.scoreSheet-table-responseRows-collapsedCell .scorePill.type--dot
.scorePill-percentSign{
	opacity: 0.75;
	font-weight: $lato-medium;
}

.scoreSheet-table-responseRows-collapsedCell .scorePill.type--dot
.scorePill-dot{
	width: 6px;
	height: 6px;
	margin-top: 4px;
}

.scoreSheet-table-responseRows-collapsedCell span.scoreSheet-table-responseRows-collapsedCell--absent{	
	font-size: 14px;
	color: $textLight;	
	font-weight: $lato-medium;
	
	margin-top: 0px;
}


.scoreSheet-table-responseRows-collapsedCell .scorePill.type--dot.is--survey{
	color: $textMid;
}

.scoreSheet-table-responseRows-collapsedCell .scorePill.type--dot.is--survey
.scorePill-dot{
	display: none;
}


// SOME SET POLLS EXCLUDED

.scoreSheet-table-responseRows-collapsedCell--isSomeSetPollsExcluded
.scorePill.type--dot{
	padding-left: 6px;
}

.scoreSheet-table-responseRows-collapsedCell--isSomeSetPollsExcluded
.scorePill.type--dot .scorePill-dot{
	display: none;
}

.scoreSheet-table-responseRows-collapsedCell--isSomeSetPollsExcluded
.scoreSheet-table-responseRows-collapsedCell-primary{
	opacity: 0.4;
}


// ALL EXCLUDED

.scoreSheet-table-responseRows-collapsedCell--isExcluded .scorePill.type--dot,
.scoreSheet-table-responseRows-collapsedCell--isExcluded .scorePill.type--dot.is--survey,
.scoreSheet-table-responseRows-collapsedCell--isExcluded span.scoreSheet-table-responseRows-collapsedCell--absent{
	color: $scoresheet-excluded-color;
}