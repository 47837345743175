.bulkStudents-modal .modal-content{
	width: 940px;
	min-height: 550px;
	height: 90vh;
}

.bulkStudents{
	width: 100%;
	height: calc(100% - 46px); // height of modal toolbar
	user-select: none;
	cursor: default;
}

.bulkStudents--add{
	width: 100%;
	height: 100%;
}

.bulkStudents--add .bulkStudents-main{
	width: 100%;
	height: calc(100% - 68px);
	display: flex;
}

.bulkStudents--add-left{
	width: 50%;

	box-sizing: border-box;
	padding: 32px;
	border-right: 1px solid $lineMid;
}

.bulkStudents--add-right{
	width: 50%;

	box-sizing: border-box;
	padding-top: 32px;
	padding-left: 16px;
	padding-right: 16px;
}

.bulkStudents--empty
.bulkStudents--add-right{
	opacity: 0.5;
}
///

.bulkStudents--add-title{
	font-size: 14px;
	font-weight: $lato-bold;
	color: $textDark;
	margin-bottom: 5px;
}

.bulkStudents--add-explainText{
	font-size: 12.5px;
	font-weight: $lato-medium;
	color: $textMid;
	line-height: 1.4;
	margin-bottom: 15px;
}

.bulkStudents--add-left textarea{
	width: 100%;
	// height: 100%;
	height: calc(100% - 70px);
	resize: none;
	border: 1px solid $lineDark;
  border-radius: 3px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  padding-left: 12px;
  padding-top: 8px;
  padding-bottom: 20px;
  box-sizing: border-box;
  color: $textBlack;
  font-size: 14px;

  font-weight: $lato-medium;
	line-height: 22px;
  outline: none;
}

.bulkStudents--add-left textarea:focus{
	border: 1px solid $blue;
	box-shadow: 0px 0px 4px 0px transparentize($blue, 0.5);
}

.bulkStudents--add-left textarea::-webkit-input-placeholder {
   color: $textLight;
   font-size: 14px;
   font-weight: $lato-medium;
}

.bulkStudents--add-left textarea:focus::-webkit-input-placeholder {
   color: $textInputPlaceholder;
}



// PREVIEW STUDENT CARDS

.bulkStudents--add-previewBox{
	width: 100%;
	height: calc(100% - 104px);
	overflow-y: scroll;
	padding-top: 8px;
	padding-bottom: 20px;
	box-sizing: border-box;	
}


.bulkStudents--add-previewStudent{
	width: 95%;
	display: flex;
	
	font-size: 14px;
	color: $textBlack;
	font-weight: $lato-semibold;
	line-height: 1.45;

	border: 1px solid $lineMid;

	background: $white;

	height: 21px;
	margin-bottom: -1px;

	border-radius: 2px;


	white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}


.bulkStudents--add-previewStudent-firstName{
	border-right: 1px solid $lineMid;
	width: 150px;
	flex-grow: 0;
	flex-shrink: 0;
}

.bulkStudents--add-previewStudent-firstName,
.bulkStudents--add-previewStudent-lastName{
	
	padding-left: 6px;
	box-sizing: border-box;

}

.bulkStudents--add-previewStudent-lastName{
	flex-grow: 1;
	flex-shrink: 0;
	color: $textMid;
}

.bulkStudents--add-previewStudent.is--titleRow{
	font-weight: $lato-semibold;
	border-color: $invisible;
	background: none;
	margin-bottom: 5px;
	margin-top: 44px;
	color: $textMid;
}

.bulkStudents--empty
.bulkStudents--add-previewStudent.is--titleRow{
	color: $invisible;
}

.bulkStudents--empty
.bulkStudents--add-previewStudent-lastName{
	color: $invisible;
}

.bulkStudents--add-previewStudent.is--titleRow .bulkStudents--add-previewStudent-firstName{
	border-right-color: $invisible;
}

.bulkStudents--add-previewStudent.is--titleRow .bulkStudents--add-previewStudent-firstName,
.bulkStudents--add-previewStudent.is--titleRow .bulkStudents--add-previewStudent-lastName{
	padding-left: 0px;
}


//

.bulkStudents-bottom{
	width: 100%;
	height: 68px;
	background: $white;

	border-top: 1px solid $lineDark;
	display: flex;
	align-items: center;
	justify-content: center;

	position: relative;
}

.bulkStudents-bottom .btn--large.btn--blue{
	width: 210px;
}

.bulkStudents-bottom .btn.back{
	position: absolute;
	left: 20px;
	top: 19px;
	width: 75px;
}

.bulkStudents-bottom-error{
	position: absolute;
	left: 36px;
	top: 20px;
	width: 250px;
	font-size: 13px;
	font-weight: $lato-semibold;
	color: $red-dark;
}


//REVIEW CARDS

.bulkStudents--reviewCards{
	width: 100%;
	height: 100%;
}


.bulkStudents--reviewCards .bulkStudents-main{
	width: 100%;
	overflow-y: scroll;
	max-height: calc(100% - 68px);
	min-height: calc(100% - 68px);
	padding-bottom: 20px;
}

.bulkStudents--reviewCards-top{
	width: 100%;
	display: flex;
	justify-content: center;
	margin-top: 10px;
	margin-bottom: 15px;
}

.bulkStudents--reviewCards-middle{
	width: 100%;
	// max-height: 100%;

	overflow: hidden;

	box-sizing: border-box;
	padding-left: 40px;
	padding-right: 40px;

	column-count: 3;
  column-gap: 0px;
  column-width: 190px;
}

// STUDENT CARDS
.bulkStudents--reviewCards-StudentCard{
	width: 280px;
	height: 26px;

	margin-bottom: 10px;

	display: inline-block;
 	vertical-align: top;

 	break-inside: avoid-column;
 	-webkit-column-break-inside: avoid;
}

.bulkStudents--reviewCards-StudentCard-cardNumber{
	display: inline-block;
 	vertical-align: top;

	width: 26px;
	height: 26px;
	border: 1px solid $lineDark;
	background: $white;
	border-radius: 2px;
	box-shadow: 0px 1px 1px 0px rgba(0,0,0,0.08);

	font-size: 13px;
	text-align: center;
	line-height: 1.9;
	font-weight: $lato-bold;
	color: $textBlack;

	margin-right: 8px;

}

.bulkStudents--reviewCards-StudentCard-firstName,
.bulkStudents--reviewCards-StudentCard-lastName{
	display: inline-block;
 	vertical-align: top;

	font-size: 14px;
	line-height: 1.8;

	white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
}


.bulkStudents--reviewCards-StudentCard-firstName{
	color: $textDark;
	font-weight: $lato-semibold;
	margin-right: 4px;
	max-width: 120px;
}
.bulkStudents--reviewCards-StudentCard-lastName{
	color: $textMid;
	font-weight: $lato-medium;
	max-width: 120px;
}

.bulkStudents--reviewCards-top .btn{
	margin: 8px;
	width: 150px;
	min-width: 150px;
}