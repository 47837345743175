.deleteAccount-centerBlock{
  width: 340px;
  margin: 0 auto;
  padding: 10px;
}

.deleteAccount-centerBlock-logo{
  margin-top: 30px;
  margin-bottom: 30px;
  display: flex;
  justify-content: center;
}

.deleteAccount-centerBlock-logo .icon{
  width: 75px;
  height: 75px;
}

.deleteAccount-centerBlock--middle{
  margin-bottom: 80px;
}

.deleteAccount-centerBlock-title{
  font-size: 30px;
  font-weight: $lato-bold;
  color: $textBlack;
  width: 100%;
  text-align: center;

  margin-bottom: 20px;
  cursor: default;
  user-select: none;
}

.deleteAccount-centerBlock-step{
  font-size: 20px;
  text-align: center;
  color: $textMid;
  font-weight: $lato-bold;
  margin-top: 10px;
}

.deleteAccount-centerBlock-accountContent{
  padding: 30px;
}

.deleteAccount-centerBlock-accountContent-header{
  margin-bottom: 5px;

  font-size: 12px;
  font-weight: $lato-semibold;
  color: $textLight;

  user-select: none;
  cursor: default;

  text-transform: uppercase;
}

.deleteAccount-centerBlock-accountContent-content{
  padding-bottom: 16px;
  // general non-button styling
  font-size: 16px;
  font-weight: $lato-bold;
  color: $textBlack;
  cursor: default;
}

.deleteAccount-centerBlock-accountContent-content-description{
  font-size: 13px;
  color: $textMid;
}


.deleteAccount-centerBlock-checkbox .featureModal-selectControl-label{
  font-size: 14px;
  font-weight: $lato-medium;
  color: $textDark;
}

.deleteAccount-centerBlock-accountContent-content-list li{
  list-style-type: circle;
  font-size: 14px;
  font-weight: $lato-medium;
  color: $textDark;
}

.deleteAccount-centerBlock-button{
  margin-top: 30px;
  margin-bottom: 20px;
}

.deleteAccount-centerBlock-button .btn{
  margin: auto;
  width: 200px;
  cursor: pointer;
}

.deleteAccount-authorizing,
.deleteAccount-errorMessage,
.deleteAccount-successMessage{
  text-align: center;
}

.deleteAccount-errorMessage{
  color: $red-dark;
}
