///

.liveView-controlBar-popoverContainer{
	width: 328px;

	position: absolute;
	bottom: 56px;
	left: 50%;
  transform: translate(-50%, 0);

  cursor: default;
	user-select: none;
	
}

.liveView-controlBar-popover{
	width: 100%;
	position: relative;
	background: $colorBG-fullScreen-controls;
	border: 1px solid black;
	border-radius: 6px;
	
	box-sizing: border-box;
	padding-top: 4px;
	margin-bottom: 10px;
}


// QUEUE NAVIGATOR POPOVER

.liveView-controlBar-popover.liveView-controlBar-popover--queue{
	padding-bottom: 0px;
}

.liveView-controlBar-popover.liveView-controlBar-popover--queue .scrollbar-container{
	max-height: calc(100vh - 170px); // prevent popover overlapping top toolbar
	min-height: 100px;
	height: calc(100% - 45px);	
	padding-bottom: 16px;
}

.liveView-controlBar-popover--queue-listSubtitle{
	font-size: 13px;
	text-transform: uppercase;
	color: $gray-12;
	font-weight: $lato-bold;
	letter-spacing: 0.04em;
	margin-top: 10px;
	margin-bottom: 1px;
	margin-left: 16px;
}

.liveView-controlBar-popover--queue-queueItem--container{
	padding-left: 16px;
	padding-right: 16px;
	height: 77px;
	display: flex;
	align-items: center;

	cursor: pointer;
}

.liveView-controlBar-popover--queue-queueItem{
	width: 100%;
	height: 61px;
	box-sizing: border-box;
	
	padding-left: 107px;

	position: relative;

}

.liveView-controlBar-popover--queue-queueItem-slidePreview{
	position: absolute;
	left: 0px;
	top: 0px;

	height: 59px;
	width: 94px;

	background: $white;
	border: 1px solid rgba(0,0,0,0.5);
	border-radius: 1px;
}

.liveView-controlBar-popover--queue-queueItem-slidePreview .questionCountBadge{
	position: absolute;
	top: 4px;
	right: 4px;
	transform: scale(0.8);
	transform-origin: top right;
}


/// RIGHT SECTION

.liveView-controlBar-popover--queue-queueItem--right{
	height: 100%;
	position: relative;
}

.liveView-controlBar-popover--queue-queueItem-title{
	height: 36px; 
	overflow: hidden;
	width: 100%;
	font-size: 15px;
	font-weight: $lato-semibold;
	color: $white;
	margin-top: 2px;
	opacity: 0.9;
}


.liveView-controlBar-popover--queue-queueItem-playLabel{
	margin-top: 2px;
	font-size: 13px;
	font-weight: $lato-semibold;
	color: $white;
	opacity: 0;
}

/// INTERACTIONS

.liveView-controlBar-popover--queue-queueItem--container:hover{
	background: rgba(0,0,0,0.33);
}

.liveView-controlBar-popover--queue-queueItem--container:hover
.liveView-controlBar-popover--queue-queueItem-title {
	opacity: 1;
}


.liveView-controlBar-popover--queue-queueItem--container:hover
.liveView-controlBar-popover--queue-queueItem-playLabel {
	opacity: 0.5;
}




//
//ARROWS ON POPOVER
//

.liveView-controlBar-popover:after, .liveView-controlBar-popover:before {
	top: 100%;
	left: 50%;
	border: solid transparent;
	content: " ";
	height: 0;
	width: 0;
	position: absolute;
	pointer-events: none;
}

.liveView-controlBar-popover:after {
	border-color: rgba(213, 17, 82, 0);
	border-top-color: $colorBG-liveView-opaque;
	border-width: 12px;
	margin-left: -12px;
}
.liveView-controlBar-popover:before {
	border-color: rgba(0, 255, 102, 0);
	border-top-color: black;
	border-width: 12.5px;
	margin-left: -12.5px;
}

/// SCROLL POPOVER


$width--queuePopover-Scrollbar: 12px;

.liveView-controlBar-popover {

/*
 * Container style
 */
.ps {
  overflow: hidden !important;
  overflow-anchor: none;
  -ms-overflow-style: none;
  touch-action: auto;
  -ms-touch-action: auto;
  position: relative;
  height: 100%;
}

/*
 * Scrollbar rail styles
 */

.ps__rail-y {
  display: none;

  transition: none;
  transition: background-color 200ms linear;
  width: $width--queuePopover-Scrollbar;
  
  right: 0;
  
  position: absolute;

  z-index: 98;
}

.ps--active-y .ps__rail-y {
  display: block;
  background-color: $invisible;
}

.ps:hover .ps__rail-y,
.ps--scrolling-y .ps__rail-y {
  width: $width--queuePopover-Scrollbar;
  background-color: rgba(255,255,255,0.08);
}

.ps .ps__rail-y:hover,
.ps .ps__rail-y:focus {
  width: $width--queuePopover-Scrollbar;
  background-color: rgba(255,255,255,0.12);
}

/*
 * Scrollbar thumb styles
 */

.ps__thumb-y {
  // background-color: #aaa;
  box-sizing: border-box;

  border-radius: 1px;
  -webkit-transition: none;
  transition: none;
  transition: opacity 200ms linear;
  width: $width--queuePopover-Scrollbar;
  /* there must be 'right' for ps__thumb-y */
  right: 0px;
  /* please don't change 'position' */
  position: absolute;

}

.ps .ps__thumb-y {
  background-color: rgb(255,255,255);
  opacity: 0.12;
}

.ps:hover .ps__thumb-y {  
  background-color: rgb(255,255,255);
  opacity: 0.36;
  width: $width--queuePopover-Scrollbar;
}

.ps__rail-y:hover .ps__thumb-y,
.ps__rail-y:focus .ps__thumb-y {
  background-color: rgb(255,255,255);
  opacity: 0.6;
  width: $width--queuePopover-Scrollbar;
}

.ps__rail-y .ps__thumb-y:hover,
.ps__rail-y .ps__thumb-y:hover,
.ps--scrolling-y .ps__rail-y .ps__thumb-y,
.ps--scrolling-y .ps__rail-y:hover .ps__thumb-y,
.ps--scrolling-y .ps__rail-y:focus .ps__thumb-y {
  background-color: rgb(255,255,255);
  opacity: 1;
  width: $width--queuePopover-Scrollbar;
}

/* MS supports */
@supports (-ms-overflow-style: none) {
  .ps {
    overflow: auto !important;
  }
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .ps {
    overflow: auto !important;
  }
}


}


//

// // TOOLS POPOVER

// .liveView-controlBar-popover.is--liveViewTools{
// 	padding-bottom: 16px;
// }

// .liveView-controlBar-popover-accessibility-item{
// 	height: 80px;
// 	width: 100%;
// 	// background: $tempr;
// 	box-sizing: border-box;
// 	padding-left: 68px;
// 	padding-right: 16px;

// 	position: relative;
// 	// border: 1px solid red;

// 	padding-top: 10px;
// 	cursor: pointer;
// }

// .liveView-controlBar-popover-accessibility-item-title{
// 	font-size: 15px;
// 	font-weight: $lato-semibold;
// 	color: $white;
// 	margin-top: 2px;
// 	opacity: 0.9;
// }

// .liveView-controlBar-popover-accessibility-item-label{
// 	margin-top: 2px;
// 	font-size: 13px;
// 	font-weight: $lato-semibold;
// 	color: $white;
// 	opacity: 0.5;
// }

// .liveView-controlBar-popover-accessibility-item-icon{
// 	height: 32px;
// 	width: 32px;
// 	position: absolute;
// 	left: 16px;
// 	top: 50%;
// 	transform: translateY(-50%);
// }

// .liveView-controlBar-popover-accessibility-item .icon{
// 	width: 32px;
// 	height: 32px;
// 	fill: $white;
// }


// /// INTERACTIONS

// .liveView-controlBar-popover-accessibility-item:hover{
// 	background: rgba(0,0,0,0.33);
// }

// .liveView-controlBar-popover-accessibility-item:hover
// .liveView-controlBar-popover-accessibility-item-title {
// 	opacity: 1;
// }


// .liveView-controlBar-popover-accessibility-item:hover
// .liveView-controlBar-popover-accessibility-item-label {
// 	opacity: 0.5;
// }





