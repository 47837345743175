.editor-topBar-setTitleInputContainer{
	position: absolute;
	top: 0px;
	left: 30px;
	right: 30px;
	bottom: 0px;

	font-size: 21px;
	font-weight: $lato-heavy;
	color: rgb(39,39,51);

	// background: $tempb;
}

.editor-topBar-center-questionEditorTitle{	
	font-size: 18px;
	font-weight: $lato-heavy;
	color: $textLight;
	cursor: default;
	user-select: none;

	
}

.editor-topBar-setTitleInputContainer--viewOnly{
	pointer-events: none;
}




input.editor-topBar-setTitleInput-input{
	position: absolute;
	top: 5px;
	bottom: 5px;
	width: 100%;
	left: 0px;
	right: 0px;

	z-index: 10;
	padding: 0px;

	border-radius: 3px;

	opacity: 0;
	// opacity: 1;

	text-align: center;
	box-shadow: none;
	outline: none;

	box-sizing: border-box;


	background: rgb(237,238,249); 
	border: 1px solid rgb(237,238,249);

	white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

	// background: $tempr;
	// border: 1px solid red;

}

input.editor-topBar-setTitleInput-input{
	
}

input.editor-topBar-setTitleInput-input:focus{
	background: $white;
	border: 1px solid $blue;
	outline: none;
}

input.editor-topBar-setTitleInput-input::placeholder{
	color: $textBlackDark;
}

input.editor-topBar-setTitleInput-input:focus::placeholder{
	color: $textInputPlaceholder;
}

.editor-topBar-setTitleInputContainer:hover input.editor-topBar-setTitleInput-input,
input.editor-topBar-setTitleInput-input:focus{
	opacity: 1;
}

input.editor-topBar-setTitleInput-input,
.editor-topBar-setTitleInput-static--notContainingPackName
.editor-topBar-setTitleInput-static-title{
	font-size: 18px;
	font-weight: $lato-heavy;
	color: $textBlackDark;	
	letter-spacing: 0.01em;
}


.editor-topBar-setTitleInput-static{
	position: absolute;
	top: 0px;
	bottom: 0px;
	left: 0px;
	right: 0px;

	// background: $tempg;
	opacity: 1;

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	border: 1px solid $invisible;


}

.editor-topBar-setTitleInput-static-title{
	max-width: 100%;
	// background: $tempr;
}


// .editor-topBar-setTitleInput-static--containingPackName
// .editor-topBar-setTitleInput-static-title{
// 	font-size: 16px;
// 	font-weight: $lato-heavy;
// 	color: $textBlackDark;	
// 	letter-spacing: 0.01em;
// }



.editor-topBar-setTitleInput-static-containingPackName{
	margin-top: -1px;
	font-size: 12px;
	font-weight: $lato-medium;
	color: $textMid;
	letter-spacing: 0.01em;
	margin-bottom: -3px;
}

.editor-topBar-setTitleInputContainer:hover
.editor-topBar-setTitleInput-static{
	opacity: 0;
}

.input.editor-topBar-setTitleInput-input:focus ~
.editor-topBar-setTitleInput-static{
	opacity: 0;
}


.editor-topBar-setTitleInput-static-title-title{
	position: relative;
}

.editor-topBar-setTitleInput-static-title-title-title{
	max-width: 100%;

	white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.editor-topBar-setTitleInput-static-title-title-sharedIconContainer{
	width: 18px;
	height: 18px;

	// background: $tempb;

	position: absolute;
	top: 2px;
	left: -28px;

	display: flex;
	align-items: center;
	justify-content: center;
}

.editor-topBar-setTitleInput-static-title-title-sharedIconContainer .icon.shared,
.editor-topBar-setTitleInput-static-title-title-sharedIconContainer .icon.globe-shared,
.editor-topBar-setTitleInput-static-title-title-sharedIconContainer .icon.globe-shared-author,
.editor-topBar-center-questionEditorTitle .icon.shared,
.editor-topBar-center-questionEditorTitle .icon.globe-shared,
.editor-topBar-center-questionEditorTitle .icon.globe-shared-author{
 	fill: $gray-11;	
 	height: 18px;
	width: 18px;
}

.editor-topBar-setTitleInput-static-title-sharedIconContainer .icon.lock,
.editor-topBar-center-questionEditorTitle .icon.lock{
 	fill: $gray-8;	
 	height: 18px;
	width: 18px;
	position: relative;
	top: -1px;
}

.editor-topBar-center-questionEditorTitle .icon.shared,
.editor-topBar-center-questionEditorTitle .icon.lock,
.editor-topBar-center-questionEditorTitle .icon.globe-shared,
.editor-topBar-center-questionEditorTitle .icon.globe-shared-author{
	position: relative;
	top: 2px;
	left: -11px;
}