//
// SYNC SUMMARY
//

.syncLiveView-player-summary{
  box-sizing: border-box;
  padding-top: 24px;
  width: $syncLiveView-leftWidth;
  height: 100%;

  flex-grow: 0;
  flex-shrink: 0;

  padding-left: 12px;
  padding-right: 12px;


  cursor: default;

  position: relative;
  z-index: 5;
}

.syncLiveView-player-summary-section{
  display: flex;
  min-height: 60px;

  align-items: center;
}

.syncLiveView-player-summary .sectionMarker{
  width: 31px;
  height: 15px;
  border-radius: 2px;
  font-size: 10px;
  font-weight: $lato-bold;
  color: $white;
  text-transform: uppercase;

  display: flex;
  align-items: center;
  justify-content: center;

  flex-grow: 0;
  flex-shrink: 0;

  user-select: none;
}

.syncLiveView-player-summary-section-name{
  font-size: 16px;
  font-weight: $lato-bold;
  color: $textBlack;
  padding-left: 8px;

  flex-grow: 1;
  flex-shrink: 1;
}

.syncLiveView-player-summary-dataOwner {
  margin: 5px 0 10px 0;
}

.syncLiveView-player-summary-dataOwner .icon {
  width: 18px;
  height: 18px;
}

.syncLiveView-player-summary-dataOwner-name {
  font-size: 13px;
  line-height: 18px;
}

//
//

.syncLiveView-player-summary{
  opacity: 1;
  transition: opacity 100ms linear;
}
.syncLiveView-player-summary--isScanning{
  opacity: 0.5;
  transition: opacity 100ms linear;
}

.syncLiveView-player-summary--isScanning:hover{
  opacity: 1;
  transition: 50ms linear;
}






// MAYBE REFACTOR THIS TO PLAYER
.syncLiveView-player-summary-setSummary-title{
  font-weight: $lato-bold;
  color: $textLight;
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 0.01em;

  margin-bottom: 8px;

  user-select: none;
}

.syncLiveView-player-summary-setSummary-topLine{
  display: flex;
  margin-bottom: 5px;
}

.syncLiveView-player-summary-setSummary-topLine-setTitle{
  flex-grow: 1;
  flex-shrink: 1;

  font-size: 15px;
  font-weight: $lato-bold;
  color: $textBlack;
}

.syncLiveView-player-summary-setSummary-topLine-setScore{
  padding-left: 10px;
  flex-shrink: 0;
  flex-grow: 0;

}


.syncLiveView-player-summary-setSummary-setCounter{
  display: flex;

  font-size: 13px;
  font-weight: $lato-bold;
  letter-spacing: 0.01em;

  user-select: none;
}

.syncLiveView-player-summary-setSummary-setCounter-currentQuestion{
  color: $textMid;
}

.syncLiveView-player-summary-setSummary-setCounter-totalQuestionCount{
  color: $textLight;
}
