$height-controlBar-bar: 66px;
$colorBG-liveView-opaque: rgb(51,52,56);


//BAR CONTROL IS ALONG THE BOTTOM

// container is used to double up on visibility behaviour (hover over area)
.liveView-controlBarContainer{
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	//height: 200px; old
	height: 4vh; // testing new to make harder to find
	z-index: 8000;
}

.liveView-controlBar{
	position: absolute;
	bottom: 30px;

	width: calc(100% - 280px);
	max-width: 1200px;
	
	height: 98px;
	
	left: 50%;
  transform: translate(-50%, 0);
}




.liveView-controlBar-bar{
	height: $height-controlBar-bar;
	right: 0px;
	bottom: 0px;

	background: $colorBG-fullScreen-controls;
	border: 1px solid black;
	
	border-radius: 4px;
	box-sizing: border-box;
	padding-left: 20px;
	padding-right: 10px; //the icon has internal padding on right

	position: absolute;
	
	display: flex;
	align-items: center;
}

.liveView-controlBar--hide{
	opacity: 0;
	pointer-events: none !important;
	transition: all 500ms cubic-bezier(.2, .3, .25, .9);
}

.liveView-controlBar--hide * {
	pointer-events: none !important;
}

.liveView-controlBar--show{
	opacity: 1;
	pointer-events: auto !important;
	transition: all 100ms cubic-bezier(.2, .3, .25, .9);
}


.liveView-controlBar.liveView-controlBar--playingIndependentQuestion
.liveView-controlBar-bar{
	justify-content: flex-end;
}

.liveView-controlBar .liveView-controlBar-bar{
	width: 100%;
}

.liveView-controlBar.liveView-controlBar--playingIndependentQuestion
.liveView-controlBar-bar{
	width: 205px;
	padding-left: 10px;
}


// BUTTONS


.liveView-controlBar-bar-setTitle{
	flex-grow: 1;
	height: 100%;
	font-size: 23px;
	font-weight: $lato-bold;
	color: white;
	line-height: 2.7;
	cursor: default;
	user-select: none;
}


.liveView-controlBar-bar-btn{
	width: 56px;
	height: 56px;
	flex-grow: 0;
	border-radius: 4px;

	display: flex;
	align-items: center;
	justify-content: center;

	box-sizing: border-box;
	margin: 2px;
	cursor: pointer;

	position: relative;
}

.liveView-controlBar-bar-btn .icon.enterFS,
.liveView-controlBar-bar-btn .icon.exitFS,
.liveView-controlBar-bar-btn .icon.queue,
.liveView-controlBar-bar-btn .icon.card-assignment,
.liveView-controlBar-bar-btn .icon.play-next,
.liveView-controlBar-bar-btn .icon.play-previous{
	fill: $white;
	height: 32px;
	width: 32px;
	opacity: 0.9;
	transition: all 50ms cubic-bezier(.2, .3, .25, .9);
}



.liveView-controlBar-bar-btn .icon.card-assignment{
	width: 40px;
}

.liveView-controlBar-bar-btn--cardAssignment{
	position: relative;	
}

.liveView-controlBar-bar-btn--cardAssignment-classCount{
	width: 28px;
	height: 25px;
	position: absolute;
	left: 14px;
	top: 16px;
	z-index: 10;
	user-select: none;
	text-align: center;
	line-height: 1.55;
	font-size: 16px;
	font-weight: $lato-heavy;
	color: $colorBG-liveView-opaque;
}

// DISABLED

.liveView-controlBar-bar-btn.liveView-controlBar-bar-btn--disabled{
	opacity: 0.5;
	pointer-events: none;
}


// INTERACTION

.liveView-controlBar-bar-btn:hover{
	background: rgba(0,0,0,0.2);	
}

.liveView-controlBar-bar-btn:hover .icon.enterFS,
.liveView-controlBar-bar-btn:hover .icon.exitFS,
.liveView-controlBar-bar-btn:hover .icon.queue,
.liveView-controlBar-bar-btn:hover .icon.play-next,
.liveView-controlBar-bar-btn:hover .icon.play-previous{
	opacity: 1;
	transform: scale(1.05);
	transition: all 150ms cubic-bezier(.2, .3, .25, .9);
}

// DIVIDER BETWEEN PLAY-NEXT AND PLAY-PREVIOUS 

.liveView-controlBar-bar-divider{
	height: 60%;
	width: 1px;
	background: white;
	opacity: 0.15;
	margin-left: 14px;
	margin-right: 14px;
}

//
// POPOVER MENUS
//

@import "LiveView-PopoverMenus";

//
// SET NAVIGATOR
//

@import "LiveView-SetNavigator";

