


// BLANK

.blankLiveScreen{
	width: 100%;
	height: 100%;
}

.blankLiveScreen-title{
	position: absolute;
	top: 15%;
	font-size: 36px;
	width: 100%;
	text-align: center;
	font-weight: $lato-bold;
	color: $textLight;
}

.blankLiveScreen-selectTitle{
	position: absolute;
	top: calc(5% + 75px);
	text-align: center;
	width: 100%;
}

.blankLiveScreen-sectionList{
	width: 720px;
	// background: $tempr;
	max-height: 50%;
	position: absolute;
	left: calc((100% - 720px) / 2);
	top: calc(5% + 75px + 40px);

	display: flex;
	flex-wrap: wrap;
	// flex-direction: column;
	align-items: center;
}

.blankLiveScreen-sectionList .sectionMarker{
	width: 200px;
	border-radius: 3px;
	margin: 8px;	
	font-weight: $lato-semibold;
	color: $textDark;

	border: 1px solid $gray-1225;

	background: linear-gradient(to bottom, rgb(255,255,255) 0%, rgb(251,251,251) 100%); 
	padding: 10px;
	box-shadow: 0px 1px 2px 0px rgba(0,0,0,0.1);
	
	height: 25px;

	cursor: default;

	-webkit-user-select: none; /* Chrome/Safari */        
	-moz-user-select: none; /* Firefox */
	-ms-user-select: none; /* IE10+ */

	transition: transform 250ms cubic-bezier(.2, .3, .25, .9);
}

.blankLiveScreen-sectionList .sectionMarker:hover{
	border: 1px solid $gray-115;
	transform: scale(1.02);
	background: $white;
	transition: all 250ms cubic-bezier(.2, .3, .25, .9);
}

.blankLiveScreen-sectionList .sectionMarker:active{
	border: 1px solid $gray-10;
	background-color: $gray-15;
}

.blankLiveScreen-sectionList .sectionMarker .icon{
	height: 15px;
	width: 15px;
	margin-right: 10px;
	position: relative;
	top: 2px;
}






.blankLiveScreen-h2{
	font-size: 20px;
	font-weight: $lato-semibold;
	color: $textDark;
	text-align: center;
}

.blankLiveScreen-starter{
	position: absolute;
	bottom: 140px;
	width: 100%;
}

.blankLiveScreen-oldApp{
	position: absolute;
	bottom: 200px;
	width: 640px;
	padding: 20px;
	
	text-align: center;
	left: 50%;
	transform: translateX(-50%);

	background: $red;
	border-radius: 5px;
	font-size: 18px;
	font-weight: $lato-bold;
	line-height: 0.8;

	color: $white;

	cursor: default;
	user-select: none;
}

.blankLiveScreen-help{
	position: absolute;
	bottom: 60px;
	width: 340px;
	
	text-align: left;
	left: 50%;
	transform: translateX(-50%);
}

.blankLiveScreen-h3{
	font-size: 18px;
	font-weight: $lato-bold;
	margin-bottom: 12px;
	width: 100%;
	text-align: center;
}

.blankLiveScreen-h4{
	font-size: 16px;
	font-weight: $lato-medium;
	color: $textDark;
	margin-bottom: 6px;
}

.blankLiveScreen-h4 a,
.blankLiveScreen-h4 a:visited{
	color: $blue;
	text-decoration: underline;
}
