$recordingRed: #fd5e62;
// also contains some styling for edit sound modal

.editor-insertSoundModalContainer{
	position: fixed;
	top: 0px;
	right: 0px;
	left: 0px;
	bottom: 0px;

	z-index: 9999;
	display: flex;
	align-items: center;
	justify-content: center;
}

// for click to cancel;
.editor-insertSoundModalContainerBG{
	position: absolute;
	top: 0px;
	left: 0px;
	right: 0px;
	bottom: 0px;
	background: rgba(0,0,10,0.25);
}

.editor-insertSoundModal{
	width: 810px;
	height: 446px;
	
	background: rgb(253,253,255);
	border: 1px solid rgb(204,204,208);
	border-bottom: 1px solid darken(rgb(204,204,208),5%);

	box-shadow: 0px 2px 4px 0px rgba(0,0,40,0.16), 0px 6px 12px 0px rgba(0,0,40,0.12);
	border-radius: 8px;

	box-sizing: border-box;

	padding-left: 36px;
	padding-right: 36px;
	padding-top: 21px;
	padding-bottom: 21px;

	position: relative;
	z-index: 5;

}

//
// HELP BTN

a.editor-insertSoundModal-helpBtn{
	position: absolute;
	top: 4px;
	right: calc(36px - 14px + 76px);

	padding-right: 12px;
	padding-left: 12px;

	height: 30px;
	
	color: $textBlack;
	margin-bottom: 8px;

	text-align: center;
	opacity: 0.6;
	background: none;

	border-radius: 4px;

	font-size: 13px;
	letter-spacing: 0.025em;

	font-weight: $lato-medium;

	display: flex;
	align-items: center;
	justify-content: center;

	transition: opacity 50ms linear;

	// background: $tempb;
}


a.editor-insertSoundModal-helpBtn:hover{
	//background: rgba(0,0,20,0.02);
	opacity: 1;
	
	// text-decoration: underline !important;
}
	

//
// CANCEL BUTTON
button.editor-insertSoundModal-cancelBtn{
	position: absolute;
	top: 0px;
	right: 0px;

	padding-top: 1px;

	z-index: 1000;


	height: 36px;
	width: 80px;
	width: 98px;

	padding-right: 22px;

	box-shadow: none;

	cursor: pointer;

	font-size: 14px;
	font-size: 13px;
	font-weight: $lato-semibold;
	letter-spacing: 0.015em;

	border-radius: 4px;

	color: $textMid;

	background: $gray-15;
	background: rgba(255,255,255,0.75);

	//border: 1px solid $gray-13;
	border: 1px solid rgba(0,0,20,0.05);
	color: $textMid;

	background: none;
	border: none;

	// background: $tempr;
}

button.editor-insertSoundModal-cancelBtn:hover{
	// background: $white;
	// border: 1px solid $gray-125;
	color: $textBlackDark;	
}




//
// INITIAL
//

.editor-insertSoundModal--initial{
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	box-sizing: border-box;
	padding-top: 36px;
}

.editor-insertSoundModal--initial-recordingContainer{
	width: 100%;
	flex-grow: 1;
	flex-shrink: 1;
	background: transparentize($blue, 0.96);

	display: flex;
	position: relative;

	border-radius: 8px;

	display: flex;
	align-items: center;
	justify-content: center;

	cursor: pointer;
}

button.editor-insertSoundModal--initial-recordBtn{
	width: 168px;
	height: 168px;

	background: transparentize($blue, 0.9);
	border: none;
	box-shadow: none;

	border-radius: 50%;

	display: flex;
	align-items: center;
	justify-content: center;

	cursor: pointer;

	position: relative;
	z-index: 10;
	
}

button.editor-insertSoundModal--initial-recordBtn svg{
	height: 70px;
	width: 70px;

	fill: $blue;

	transition: transform 150ms linear;
}

.editor-insertSoundModal--initial-recordingContainer:hover
button.editor-insertSoundModal--initial-recordBtn:hover{
	background: transparentize($blue, 0.89);
}

.editor-insertSoundModal--initial-recordingContainer:hover
button.editor-insertSoundModal--initial-recordBtn svg{
	transform: scale(1.10);
	transition: transform 100ms linear;
	fill: darken($blue, 3%);
}


// TOOLTIPS

.editor-insertSoundModal--initial-recording--tooltips{
	position: absolute;
	top: 0px;
	left: 0px;
	right: 0px;
	bottom: 0px;
	user-select: none;
	pointer-events: none;
	// background: $tempr;
}

// .editor-insertSoundModal--initial-recording--tooltip-clickRecord{
// 	position: absolute;
// 	top: 30px;
// 	left: 50%;
// 	transform: translateX(-50%);

// 	font-size: 18px;
// 	font-weight: $lato-heavy;
// 	letter-spacing: 0.02em;
// 	color: darken($blue, 5%);

// 	opacity: 0;
// 	transition: opacity 100ms linear;
// }

// .editor-insertSoundModal--initial-recordingContainer
// .editor-insertSoundModal--initial-recording--tooltips
// .editor-insertSoundModal--initial-recording--tooltip-clickRecord{
// 	opacity: 0;
// 	transition: opacity 150ms linear;
// 	transition-delay: 500ms;
// }

.editor-insertSoundModal--initial-recording--tooltip-spacebarRecord{
	position: absolute;
	bottom: 32px;
	left: 0px;
	right: 0px;

	text-align: center;

	font-size: 14px;
	font-weight: $lato-semibold;
	letter-spacing: 0.02em;
	color: transparentize($textBlackDark, 0.2);
	opacity: 0.8;
	transition: opacity 150ms linear;
	// transition-delay: 500ms;

	pointer-events: none;
	// opacity: 0;
	// offset to center-align spacebar
	margin-left: 16px;
}


// .editor-insertSoundModal--initial-recording--tooltips
// .editor-insertSoundModal--initial-recording--tooltip-spacebarRecord{
// 	opacity: 0.8;
// 	transition: opacity 50ms linear;
// 	transition-delay: 0ms;
// }

.editor-insertSoundModal--initial-recordingContainer:hover
.editor-insertSoundModal--initial-recording--tooltips
.editor-insertSoundModal--initial-recording--tooltip-spacebarRecord{
	opacity: 1;
	transition: opacity 50ms linear;
	transition-delay: 0ms;
}

span.editor-insertSoundModal--initial-recording--tooltip-spacebarRecord-spacebar{
	background: $blue;
	color: $white;
	padding-left: 6px;
	padding-right: 6px;
	padding-top: 3px;
	padding-bottom: 3px;

	margin-left: 1px;
	margin-right: 1px;

	border-radius: 3px;
	box-shadow: 0px 1px 2px 0px rgba(0,0,40,0.06);


}

// DENIED STATE

.editor-insertSoundModal--initial-recordingContainer--denied{
	display: flex;
	flex-direction: column;
	background: $gray-15;
	cursor: default;
}

.editor-insertSoundModal--initial-recordingContainer--denied svg{
	width: 74px;
	height: 74px;
	fill: $gray-11;

	margin-bottom: 32px;
	margin-top: 24px;
}

a.editor-insertSoundModal--initial-recording--denied-h1,
a.editor-insertSoundModal--initial-recording--denied-h1:visited,
a.editor-insertSoundModal--initial-recording--denied-h1:hover{
	width: 72%;
	font-size: 15px;

	font-weight: $lato-semibold;
	color: $textBlack;
	letter-spacing: 0.01em;

	line-height: 1.4;

	text-align: center;

	cursor: default;
	user-select: none;

	padding-bottom: 4px;
}

a.editor-insertSoundModal--initial-recording--denied-h2,
a.editor-insertSoundModal--initial-recording--denied-h2:visited{

	padding-left: 24px;
	padding-right: 24px;
	
	height: 36px;

	font-size: 15px;
	letter-spacing: 0.01em;

	font-weight: $lato-bold;

	color: darken($textBlue, 4%);
	//background: $blue-extraLight;
	background: none;

	text-decoration: underline !important;

	// background: $blue;
	// color: $white;

	border-radius: 5px;

	margin: 0 auto;
	margin-top: 0px;

	display: flex;
	align-items: center;
	justify-content: center;

	cursor: pointer;
	user-select: none;
}

a.editor-insertSoundModal--initial-recording--denied-h2:hover{
	color: darken($textBlue, 6%);
	background: $blue-extraLight;
}

// SAFARI STATE

.editor-insertSoundModal--initial-recordingContainer--safari{
	display: flex;
	flex-direction: column;
	background: $gray-15;
	cursor: default;
}

.editor-insertSoundModal--initial-recordingContainer--safari svg{
	width: 74px;
	height: 74px;
	fill: $gray-11;

	margin-bottom: 32px;
	margin-top: 24px;
}

.editor-insertSoundModal--initial-recording--safariTooltip-h1{
	width: 80%;
	font-size: 19px;

	font-weight: $lato-heavy;
	color: $textBlack;
	letter-spacing: 0.01em;

	line-height: 1.4;

	text-align: center;

	cursor: default;
	user-select: none;

	margin-bottom: 4px;
}

.editor-insertSoundModal--initial-recording--safariTooltip-h2{
	width: 90%;
	font-size: 15px;

	font-weight: $lato-semibold;
	color: lighten($textBlack, 5%);
	letter-spacing: 0.008em;

	line-height: 1.4;

	text-align: center;

	cursor: default;
	user-select: none;
}

.editor-insertSoundModal--initial-recording--safariTooltip-h2 emph{
	font-weight: $lato-bold;
}

// button.editor-insertSoundMiniModal-recordBtn--initial:disabled{
// 	background: $gray-125;
// }

// button.editor-insertSoundMiniModal-recordBtn--initial:disabled svg{
// 	fill: $white;
// }




// FOOTER

.editor-insertSoundModal--initial-footerContainer{
	flex-grow: 0;
	flex-shrink: 0;

	height: 72px;
	width: 100%;

	display: flex;
	align-items: flex-end;
	justify-content: center;

}

// CURRENTLY SHARED WITH INSERT IMAGE MODAL

.editor-insertSoundModal--initial-uploadBtn,
button.editor-insertSoundModal--initial-addFromYouTubeBtn{    
  width: 250px;
  height: 56px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  padding-top: 2px;
  box-sizing: border-box;

  cursor: pointer;
  
  user-select: none;
  border-radius: 5px;
      
  margin: 10px;
  margin-bottom: 0px;
  
  border: 1px solid $gray-12;
  border-bottom: 1px solid $gray-11;
  background: $white;

  box-shadow: 0px 2px 4px 0px rgba(0,0,5,0.04);

  position: relative;
}

.editor-insertSoundModal--initial-uploadBtn--label{
	position: absolute;
	top: 0px;
	left: 0px;
	right: 0px;
	bottom: 0px;

	display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  cursor: pointer;
}

.editor-insertSoundModal--initial-uploadBtn-hiddenUploadInput{
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
  pointer-events: none;
}

.editor-insertSoundModal--initial-uploadBtn-primary,
.editor-insertSoundModal--initial-addFromYouTubeBtn-primary{
	font-size: 14.5px;
  font-weight: $lato-bold;
  color: $textBlackDark;
  letter-spacing: 0.02em;
  margin-bottom: 2px;
}

.editor-insertSoundModal--initial-addFromYouTubeBtn-primary-icon{
	margin-right: 18px;
	background: $tempr;
	height: 14px;
	width: 14px;
	position: relative;
}

.editor-insertSoundModal--initial-addFromYouTubeBtn-primary-icon svg{
	position: absolute;
	top: -2px;
	left: -8px;
	right: 0px;

	height: 20px;
	width: 20px;

	fill: transparentize($blue, 0.05);
}


button.editor-insertSoundModal--initial-addFromYouTubeBtn:hover
.editor-insertSoundModal--initial-addFromYouTubeBtn-primary-icon svg{
	fill: $blue;
}

.editor-insertSoundModal--initial-uploadBtn-secondary,
.editor-insertSoundModal--initial-addFromYouTubeBtn-secondary{
	font-size: 12px;
	font-weight: $lato-medium;
  color: lighten($textMid, 10%);
  letter-spacing: 0.02em;
}

.editor-insertSoundModal--initial-uploadBtn:hover,
button.editor-insertSoundModal--initial-addFromYouTubeBtn:hover{
	border: 1px solid $gray-10;
  border-bottom: 1px solid $gray-9;
}


// TEMP DEV SOUND GALLERY
@import 'Editor-InsertEditSoundModals-DevSoundGallery';



//
// RECORDING STATE

.editor-insertSoundModal--recording,
.editor-insertSoundModal--uploading{
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;	

	padding-top: 36px;	
	padding-bottom: 0px;
}

.editor-insertSoundModal--recording-header{
	width: 100%;
	flex-grow: 0;
	flex-shrink: 0;

	box-sizing: border-box;
	padding-top: 0px;
	height: 56px;

	display: flex;

	align-items: center;
	justify-content: space-between;
}

.editor-insertSoundModal--recording-header-title{
	display: flex;
	align-items: center;
	justify-content: center;

	font-size: 19px;
	font-weight: $lato-bold;
	color: $textBlack;

	letter-spacing: 0.01em;
}

.editor-insertSoundModal--recording-header-title-recordingDot{
	height: 12px;
	width: 12px;
	background: $recordingRed;
	border-radius: 50%;
	position: relative;
	top: 2px;

	margin-right: 8px;
}

// later share with other things
button.editor-insertSoundModal--recording-cancelBtn{


  height: 32px;

  display: flex;
  align-items: center;
  justify-content: center;
  
  cursor: pointer;
  
  user-select: none;

  border-radius: 4px;
  
  padding-right: 28px;
  padding-left: 28px;
   
  font-size: 13.5px;
  font-weight: $lato-bold;
  color: $textBlack;
  letter-spacing: 0.02em;

  border: 1px solid $gray-12;
  border-bottom: 1px solid $gray-11;
  background: $white;

  box-shadow: 0px 2px 4px 0px rgba(0,0,5,0.04);
}

button.editor-insertSoundModal--recording-cancelBtn:hover{
	border: 1px solid $gray-10;
  border-bottom: 1px solid $gray-9;
}

// UPLOADING AND RECORDING
//

.editor-insertSoundModal--recording-recordingContainer,
.editor-insertSoundModal--uploading-progressArea{	
	width: 100%;
	flex-grow: 1;
	flex-shrink: 1;
	

	display: flex;
	position: relative;

	border-radius: 8px;

	display: flex;
	align-items: center;
	justify-content: center;

	

	position: relative;
}

.editor-insertSoundModal--recording-recordingContainer{
	cursor: pointer;
	background: transparentize($red, 0.96);
}

.editor-insertSoundModal--uploading-progressArea{
	cursor: default;
	background: transparentize($green, 0.9);
}



button.editor-insertSoundModal--recording-endRecordBtn{
	width: 168px;
	height: 168px;

	background: transparentize($recordingRed, 0.1);
	border: none;
	box-shadow: none;

	border-radius: 50%;

	display: flex;
	align-items: center;
	justify-content: center;

	cursor: pointer;

	position: relative;
	z-index: 20;

	opacity: 1;
	
}

button.editor-insertSoundModal--recording-endRecordBtn svg{
	height: 70px;
	width: 70px;

	fill: $white;

	transition: transform 150ms linear;
}

.editor-insertSoundModal--recording-endRecordBtn-stopIcon{
	width: 56px;
	height: 56px;
	border-radius: 5px;

	background: $white;
	opacity: 0.95;

	transition: all 150ms linear;
}

button.editor-insertSoundModal--recording-endRecordBtn:hover{
	background: transparentize($recordingRed, 0);
}

button.editor-insertSoundModal--recording-endRecordBtn:hover svg{
	transform: scale(1.04);
	transition: transform 100ms linear;
}

button.editor-insertSoundModal--recording-endRecordBtn:hover
.editor-insertSoundModal--recording-endRecordBtn-stopIcon{
	transform: scale(1.04);
	opacity: 1;
	transition: all 100ms linear;
}

// ANIMATED CANVAS

.editor-insertSoundMiniModal-recordBtn-visualizer{
	position: absolute;
	top: 0px;
	left: 50%;
	transform: translateX(-50%);
	bottom: 0px;
	
}

//

.editor-insertSoundModal--recording-footer,
.editor-insertSoundModal--uploading-footer{
	height: 93px;
	width: 100%;
	flex-grow: 0;
	flex-shrink: 0;

	display: flex;
	align-items: center;
	justify-content: center;	

	cursor: default;
	user-select: none;
}

.editor-insertSoundModal--recording-completedTime{	
	font-size: 23px;
	font-weight: $lato-bold;
	color: $textDark;
	letter-spacing: 0.03em;
}

.editor-insertSoundModal--uploading-fileName{
	display: flex;
	align-items: center;
	justify-content: center;

	flex-direction: column;

	width: 100%;
}

.editor-insertSoundModal--uploading-fileName-name{
	font-size: 18px;
	font-weight: $lato-bold;
	color: $textBlack;
	letter-spacing: 0.018em;	

	white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  text-align: center;

  width: 100%;
}

.editor-insertSoundModal--uploading-fileName-label{
	font-size: 13px;
	font-weight: $lato-bold;
	text-transform: uppercase;

	letter-spacing: 0.03em;

	color: darken($green, 8%);

	background: transparentize($green, 0.88);

	padding-left: 8px;
	padding-right: 8px;

	padding-top: 3px;
	padding-bottom: 3px;

	margin-top: 8px;

	border-radius: 3px;

	animation: shimmerLabel 3s infinite;  
	animation-direction: alternate;
}

@keyframes shimmerLabel {
  0% {
    // transform: scale(1);
    opacity: 0.8;
  }
  50% {
    // transform: scale(1.05);
    opacity: 1;
  }
  100% {
    // transform: scale(1);
    opacity: 0.8;
  }  
}





// UPLOADING PROGRESS BAR

button.editor-insertSoundModal--uploading-progressArea-cancelBtn{
	position: absolute;
	top: 0px;
	right: 0px;
	height: 48px;
	padding-right: 24px;
	padding-left: 24px;

	font-size: 13px;
	font-weight: $lato-semibold;
	color: darken($green, 15%);

	letter-spacing: 0.02em;

	display: flex;
	align-items: center;
	justify-content: center;

	cursor: pointer;

	border: none;
	box-shadow: none;

	background: none;

	// background: $tempr;

	opacity: 0.6;
}

button.editor-insertSoundModal--uploading-progressArea-cancelBtn:hover{
	opacity: 1;
}

.editor-insertSoundModal--uploading-progressArea-progressBar{
	height: 14px;
	width: 70%;
	position: relative;

	border-radius: 7px;
	overflow: hidden;
	will-change: transform;
}

.editor-insertSoundModal--uploading-progressArea-progressBar-bar{
	border-radius: 1px;
	background: $green;
	position: absolute;
	top: 0px;
	left: 0px;
	bottom: 0px;
	right: 0px;

	z-index: 5;
	transform-origin: top left;

	overflow: hidden;
}

.editor-insertSoundModal--uploading-progressArea-progressBar-bar-shimmer{
	position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;

  // background: $tempr;
  transform: translateX(-100%);
  background-image: linear-gradient(
    90deg,
    rgba(saturate($green, 20%), 0) 0,
    rgba(saturate($green, 20%), 0.15) 20%,
    rgba(saturate($green, 20%), 0.3) 60%,
    rgba(saturate($green, 20%), 0)
  );
  animation: shimmer 2s infinite;  
 }

@keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}

.editor-insertSoundModal--uploading-progressArea-progressBar-rail{
	position: absolute;
	top: 0px;
	left: 0px;
	bottom: 0px;
	right: 0px;

	background: transparentize($green, 0.7);
}

//
// RECORDING REVIEW MODAL
//


.editor-insertSoundModal--recordingReview{
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;	

	padding-top: 36px;	
	padding-bottom: 0px;

	position: relative;

}

.editor-insertSoundModal--recordingReview--submitLoading .editor-insertSoundModal--recordingReview-center,
.editor-insertSoundModal--recordingReview--submitLoading .editor-insertSoundModal--recordingReview-header-leftContainer,
.editor-insertSoundModal--recordingReview--submitLoading .editor-insertSoundModal--recordingReview-header-titleContainer {
	opacity: 0.5;
	transition: opacity 100ms linear;
	pointer-events: none;
	cursor: default;
}

// .editor-insertSoundModal--recordingReview--submitLoading .editor-insertSoundModal--recordingReview-footer,
// .editor-insertSoundModal--recordingReview--submitLoading .editor-insertSoundModal--recordingReview-footer *{
// 	opacity: 1;
// 	transition: opacity 0ms linear;
// }

.editor-insertSoundModal--recordingReview-header{
	position: absolute; // to make v center aligning elements between stages easier
	top: 0px;
	left: 24px;
	right: 24px;
	height: 66px;
	
	// background: $tempr;
	display: flex;
	align-items: center;
	justify-content: center;
}

.editor-insertSoundModal--recordingReview-header-leftContainer,
.editor-insertSoundModal--recordingReview-header-rightContainer{
	height: 100%;
	width: 100px;

	// background: $tempr;
	flex-grow: 0;
	flex-shrink: 0;

	display: flex;
	align-items: center;
}

.editor-insertSoundModal--recordingReview-header-leftContainer{
	justify-content: flex-start;
}

.editor-insertSoundModal--recordingReview-header-rightContainer{
	justify-content: flex-end;
}

.editor-insertSoundModal--recordingReview-header-titleContainer{
	flex-grow: 1;
	flex-shrink: 1;

	display: flex;
	align-items: center;
	justify-content: center;

	position: relative;

	// background: $tempr;

	box-sizing: border-box;
	padding-left: 16px;
	padding-right: 16px;
}


input.editor-insertSoundModal--recordingReview-header-title{
	// font-size: 20px;
	// font-weight: $lato-bold;
	// color: $textBlack;
	// letter-spacing: 0.005em;
	// text-align: center;

	font-size: 17.5px;
	font-weight: $lato-bold;
	color: $textBlack;
	letter-spacing: 0.005em;
	text-align: center;

	height: 34px;
	// border: 1px solid $invisible;
	background: none;

	border-radius: 4px;

	min-width: 380px;
	flex-grow: 1;
	flex-shrink: 1;

	box-sizing: border-box;

	background: $white;
	border: 1px solid $lineMid;
	outline: none;
	box-shadow: none;

	// background: $tempr;
}

input.editor-insertSoundModal--recordingReview-header-title:hover{
	background: $white;
	border: 1px solid $lineDark;
}

// during insert flow, always show as editablish
// .editor-editSoundModalContainer--insertFlow
// input.editor-editSoundModal-header-title{
// 	background: rgba(0,0,25,0.03);
// 	border: 1px solid $invisible;
// }

input.editor-insertSoundModal--recordingReview-header-title:focus{
	background: $white;
	border: 1px solid $blue;
	outline: none;
	box-shadow: none;
}

input.editor-insertSoundModal--recordingReview-header-title:disabled{
	pointer-events: none;
	user-select: none;
	background: none;
	border: 1px solid $invisible;
}

.editor-insertSoundModal--recordingReview-header-title-tooltip{
	position: absolute;
	left: 0px;
	right: 0px;
	bottom: -25px;
	height: 25px;
	background: rgb(253,253,255);

	display: flex;
	align-items: top;
	justify-content: center;

	box-sizing: border-box;
	padding-top: 6px;

	text-align: center;
	font-size: 13px;
	font-weight: $lato-medium;
	letter-spacing: 0.01em;
	color: $textMid;

	pointer-events: none;
	user-select: none;

	opacity: 1;
	transition: opacity 100ms linear;
}

input.editor-insertSoundModal--recordingReview-header-title:focus ~ 
.editor-insertSoundModal--recordingReview-header-title-tooltip{
	opacity: 0.75;
	transition: opacity 50ms linear;
}



//

button.editor-insertSoundModal--recordingReview-header-cancelBtn,
button.editor-insertSoundModal--recordingReview-header-backBtn{
	height: 34px;
	padding-left: 24px;
	padding-right: 24px;

	font-size: 14px;
	color: $textBlackDark;
	font-weight: $lato-semibold;
	letter-spacing: 0.02em;

	opacity: 0.7;

	border: none;
	background: none;
	border-radius: 5px;

	cursor: pointer;
	background: rgba(0,0,40,0.01);

	// background: $tempr;
}

button.editor-insertSoundModal--recordingReview-header-backBtn:hover{
	opacity: 1;
	background: rgba(0,0,40,0.03);
}

button.editor-insertSoundModal--recordingReview-header-cancelBtn:hover{
	opacity: 1;
	background: transparentize($red, 0.85);
	color: darken($red, 22%);
}

button.editor-insertSoundModal--recordingReview-header-backBtn:disabled{
	pointer-events: none;
	cursor: default;
}


//
//
.editor-insertSoundModal--recordingReview-center{
	// background: $tempb;
	flex-grow: 1;
	flex-shrink: 1;

	width: 100%;

	box-sizing: border-box;
	padding-top: 24px;

	display: flex;
	align-items: center;
	justify-content: center;
}

button.editor-insertSoundModal--recordingReview-center-playFromStartBtn{
	position: absolute;
	top: 65px;
	height: 65px;
	width: 32px;

	left: 50%;	
	transform: translateX(-50%);

	display: flex;
	align-items: flex-end;

	box-sizing: border-box;
	padding-bottom: 7px;	

	cursor: pointer;

	z-index: 200;

	border: none;
	outline: none;
	box-shadow: none;

 	background: none; 
 	// background: $tempr;
}

button.editor-insertSoundModal--recordingReview-center-playFromStartBtn:focus,
button.editor-insertSoundModal--recordingReview-center-playFromStartBtn:hover{
	outline: none;
}

.editor-insertSoundModal--recordingReview-center-playFromStartBtn-circle{
	display: none;

	// too confusing to keep in
	// border-radius: 2px;
	// border-top-left-radius: 8px;
	// border-bottom-left-radius: 8px;
	// background: #5091F6;
	// background: $blue;

	// // border: 2px solid $white;

	// opacity: 0.25;

	// opacity: 0;
	// opacity: 1;

	// transition: all 100ms linear;
	// transform: scale(1.02);
}

// .editor-insertSoundModal--recordingReview-center-playFromStartBtn:hover
// .editor-insertSoundModal--recordingReview-center-playFromStartBtn-circle{
// 	// opacity: 0.25;
// 	// // transform: scale(1.02);
// 	// transition: all 50ms linear;
	
// }

// .editor-insertSoundModal--recordingReview-center-playFromStartBtn:active
// .editor-insertSoundModal--recordingReview-center-playFromStartBtn-circle{
// 	// opacity: 0.25;
// 	// // transform: scale(1.08);
// 	// transition: all 50ms linear;
// }

.editor-insertSoundModal--recordingReview-center-leftContainer,
.editor-insertSoundModal--recordingReview-center-rightContainer{
	width: 200px;
	flex-grow: 1;
	flex-shrink: 1;

	display: flex;
	align-items: center;
	justify-content: center;
}

.editor-insertSoundModal--recordingReview-center-leftContainer{
	box-sizing: border-box;
	padding-right: 24px;
}

.editor-insertSoundModal--recordingReview-center-rightContainer{
	box-sizing: border-box;
	padding-left: 24px;
}

.editor-insertSoundModal--recordingReview-center-timestamps-current{
	font-size: 21px;
	font-weight: $lato-bold;
	color: $textBlack;
	letter-spacing: 0.01em;
	margin-bottom: 2px;

	text-align: center;

	cursor: default;
}

.editor-insertSoundModal--recordingReview-center-timestamps-total{
	text-align: center;

	font-size: 18px;
	font-weight: $lato-semibold;
	color: lighten($textMid, 5%);

	cursor: default;
}

//

$width-recordingReviewRadialProgressBar: 196px;
$strokeWidth-recordingReviewRadialProgressBar: 8px;


.editor-insertSoundModal--recordingReview-center-player{
	flex-grow: 0;
	flex-shrink: 0;
	width: $width-recordingReviewRadialProgressBar;
	height: $width-recordingReviewRadialProgressBar;
	// background: $tempb;
	//background: rgba(255,0,0,0.04);

	position: relative;
}


button.editor-insertSoundModal--recordingReview-center-player-playPauseBtn{
	width: calc(#{$width-recordingReviewRadialProgressBar} - (4 * #{$strokeWidth-recordingReviewRadialProgressBar}) - 14px);
	height: calc(#{$width-recordingReviewRadialProgressBar} - (4 * #{$strokeWidth-recordingReviewRadialProgressBar}) - 14px);

	position: absolute;
	margin: auto;
  top: calc(2 * #{$strokeWidth-recordingReviewRadialProgressBar} + 7px);
  left: 0;
  right: 0;

  z-index: 5;

  border-radius: 50%;

  
  background: $white;

  display: flex;
  align-items: center;
  justify-content: center;

  border: none;
  box-shadow: none;

  cursor: pointer;
}

button.editor-insertSoundModal--recordingReview-center-player-playPauseBtn:disabled{
	pointer-events: none;
	cursor: default;
}

button.editor-insertSoundModal--recordingReview-center-player-playPauseBtn:hover{
	background: transparentize($blue, 0.92);
}

button.editor-insertSoundModal--recordingReview-center-player-playPauseBtn--playing{
	background: transparentize($blue, 0.95);
}

button.editor-insertSoundModal--recordingReview-center-player-playPauseBtn--playing:hover{
	background: transparentize($blue, 0.88);
}

button.editor-insertSoundModal--recordingReview-center-player-playPauseBtn:active,
button.editor-insertSoundModal--recordingReview-center-player-playPauseBtn--playing:active{
	background: transparentize($blue, 0.86);
}





button.editor-insertSoundModal--recordingReview-center-player-playPauseBtn svg{
	width: 44px;
	height: 44px;
	fill: $blue;
	transform: scale(1);
	transform-origin: center center;
	transition: transform 50ms linear;
}

button.editor-insertSoundModal--recordingReview-center-player-playPauseBtn svg.editorIcon-playbackPlay{
	position: relative;
	right: -4px;
}

button.editor-insertSoundModal--recordingReview-center-player-playPauseBtn:hover svg{
	transform: scale(1.05);
	transition: transform 50ms linear;
}

// button.editor-insertSoundModal--recordingReview-center-player-playPauseBtn:active svg{
// 	transform: scale(1.15);
// 	transition: transform 50ms linear;
// }

.editor-insertSoundModal--recordingReview-center-player-progressBarContainer{
	width: $width-recordingReviewRadialProgressBar;
	height: $width-recordingReviewRadialProgressBar;

	margin: auto;
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;

  padding-left: 64px;
  padding-right: 64px;
  padding-top: 36px;
  padding-bottom: 36px;

  margin-left: -64px;
  margin-top: -36px;

  cursor: pointer;

  // background: $tempr;
}

.editor-insertSoundModal--recordingReview-center-player-progressBarContainer .CircularProgressbar .CircularProgressbar-path{
	stroke: $blue;
	transition: none;
}

.editor-insertSoundModal--recordingReview-center-player-progressBarContainer .CircularProgressbar .CircularProgressbar-trail{
	stroke: transparentize($blue, 0.7);
	transition: none;
}	


	




//

button.editor-insertSoundModal--recordingReview-center-reRecordBtn{
	width: 140px;
	height: 49px;
	
	border-radius: 4px;
	background: $white;
	border: 1px solid $lineMid;
	border-bottom: 1px solid darken($lineMid, 5%);

	box-shadow: 0px 1px 2px 0px rgba(0,0,40,0.04);

	display: flex;
	align-items: center;
	justify-content: center;

	cursor: pointer;

	position: relative;
}

button.editor-insertSoundModal--recordingReview-center-reRecordBtn svg{
	height: 24px;
	width: 24px;
	fill: $recordingRed;
}

.editor-insertSoundModal--recordingReview-center-reRecordTooltip{
	
	height: 50px;

	display: flex;
	align-items: center;
	justify-content: center;

	background: $tempr;
	border-radius: 7px;

	background: rgba(0,0,40,0.01);
	padding-left: 22px;
	padding-right: 22px;

	cursor: default;
	user-select: none;
}


.editor-insertSoundModal--recordingReview-center-reRecordTooltip svg{
	height: 20px;
	width: 20px;
	fill: $recordingRed;

	margin-right: 14px;
}

.editor-insertSoundModal--recordingReview-center-reRecordTooltip-label{
	font-size: 13px;
	font-weight: $lato-bold;
	color: $textDark;
}

.editor-insertSoundModal--recordingReview-center-recordTooltip{
	position: absolute;
	bottom: -22px;
	
	font-size: 13.5px;
  font-weight: $lato-semibold;
  color: $textBlack;
  letter-spacing: 0.02em;

  opacity: 0;
  transition: opacity 100ms linear;

  user-select: none;

}

.editor-insertSoundModal--recordingReview-center-reRecordBtn:hover
.editor-insertSoundModal--recordingReview-center-recordTooltip{
	opacity: 1;
	transition: opacity 50ms linear;
	transition-delay: 200ms;
}



//
//

.editor-insertSoundModal--recordingReview-footer{	
	height: 93px;
	width: 100%;
	flex-grow: 0;
	flex-shrink: 0;
	// background: $tempg;

	display: flex;
	align-items: center;
	justify-content: space-between;

	border-top: 1px solid darken($lineMid, 2%);
}

.editor-insertSoundModal--recordingReview-footer-leftContainer,
.editor-insertSoundModal--recordingReview-footer-rightContainer{
	width: 100px;
	flex-grow: 1;
	flex-shrink: 1;

	display: flex;
	align-items: center;

}

.editor-insertSoundModal--recordingReview-footer-leftContainer{
	justify-content: flex-start;
}

.editor-insertSoundModal--recordingReview-footer-rightContainer{
	justify-content: flex-end;
}

.editor-insertSoundModal--recordingReview-footer-doneBtn{
	width: 380px;
	height: 66px;
	flex-grow: 0;
	flex-shrink: 0;

	box-sizing: border-box;

	background: $blue;
	border-radius: 5px;
	border-radius: 7px;

	border: none;
	box-shadow: none;

	box-shadow: 0px 2px 2px 0px rgba(0,0,0,0.12);

	cursor: pointer;

	margin-top: 0px;

	position: relative;
	user-select: none;
	// padding-bottom: 12px;
}


.editor-insertSoundModal--recordingReview-footer-doneBtn:hover{
	background: saturate($blue, 5%);
	box-shadow: 0px 2px 2px 0px rgba(0,0,0,0.16);
}

.editor-insertSoundModal--recordingReview-footer-doneBtn-primary{
	font-size: 19px;
	font-weight: $lato-heavy;
	letter-spacing: 0.03em;
	color: $white;

	margin-bottom: 3px;
}

.editor-insertSoundModal--recordingReview-footer-doneBtn-sub{

	display: flex;
	align-items: center;
	justify-content: center;
}

.editor-insertSoundModal--recordingReview-footer-doneBtn:disabled{
	pointer-events: none;
	cursor: default;
}



// PROGRESS BAR

.editor-insertSoundModal--recordingReview-footer-doneBtn-uploadProgressBar{
	// width: 
	// display: flex;
	// align-items: center;
	// justify-content: center;

	margin: auto;

	width: 300px;
	height: 6px;

	position: relative;
	border-radius: 5px;
	overflow: hidden;
}

.editor-insertSoundModal--recordingReview-footer-doneBtn-uploadProgressBar-bar{
	position: absolute;
	top: 0px;
	left: 0px;
	right: 0px;
	bottom: 0px;

	background: $white;

	z-index: 5;

}

.editor-insertSoundModal--recordingReview-footer-doneBtn-uploadProgressBar-rail{
	position: absolute;
	top: 0px;
	left: 0px;
	right: 0px;
	bottom: 0px;

	background: rgba(0,0,20,0.25);
}


//


.editor-insertSoundModal--recordingReview-footer-doneBtn-sub-key{
	position: absolute;
	bottom: 3px;

	padding-left: 7px;
	padding-right: 6px;
	padding-top: 3px;
	padding-bottom: 4px;
	background: rgba(0,0,50,0.05);

	color: $white;
	font-weight: $lato-bold;
	font-size: 12px;

	letter-spacing: 0.03em;

	border-radius: 3px;

	display: flex;
	align-items: center;
	justify-content: center;

	opacity: 0;
	transition: opacity linear 50ms;
}

.editor-insertSoundModal--recordingReview-footer-doneBtn-sub-key svg{
	height: 9px;
	width: 9px;

	margin-left: 3px;
	margin-right: 2px;

	fill: $white;

	position: relative;
	top: 1px;
}

.editor-insertSoundModal--recordingReview-footer-doneBtn:hover
.editor-insertSoundModal--recordingReview-footer-doneBtn-sub-key{	
	opacity: 1;
	transition: opacity linear 50ms;
}



button.editor-insertSoundModal--recordingReview-footer-editBtn,
button.editor-insertSoundModal--recordingReview-footer-insertAndNextChoice{
	width: 150px;
	height: 44px;
	background: $white;

	font-size: 14px;
	font-weight: $lato-bold;
	letter-spacing: 0.02em;
	color: $textDark;

	border: 1px solid darken($lineMid, 5%);
	border-bottom: 1px solid darken($lineMid, 8%);
	box-shadow: 0px 1px 2px 0px rgba(0,0,40,0.04);

	border-radius: 5px;

	display: flex;
	align-items: center;
	justify-content: center;

	cursor: pointer;
}

button.editor-insertSoundModal--recordingReview-footer-editBtn:hover,
button.editor-insertSoundModal--recordingReview-footer-insertAndNextChoice:hover{
	border: 1px solid darken($lineMid, 10%);
	border-bottom: 1px solid darken($lineMid, 12%);
}




@import 'Editor-InsertSoundModal-SoundLibrary';