//
// EDIT SOUND MODAL
//

$recordingRed: #fd5e62;
// also contains some styling for edit sound modal

.editor-editSoundModalContainer{
	position: fixed;
	top: 0px;
	right: 0px;
	left: 0px;
	bottom: 0px;

	z-index: 9999;
	display: flex;
	align-items: center;
	justify-content: center;
}

// for click to cancel;
.editor-editSoundModalContainerBG{
	position: absolute;
	top: 0px;
	left: 0px;
	right: 0px;
	bottom: 0px;
	background: rgba(0,0,10,0.25);
}

.editor-editSoundModal{
	width: 950px;
	height: 527px;
	
	background: rgb(253,253,255);
	border: 1px solid rgb(204,204,208);
	border-bottom: 1px solid darken(rgb(204,204,208),5%);

	box-shadow: 0px 2px 4px 0px rgba(0,0,40,0.16), 0px 6px 12px 0px rgba(0,0,40,0.12);
	border-radius: 8px;

	box-sizing: border-box;

	padding-left: 36px;
	padding-right: 36px;
	padding-top: 12px;

	position: relative;
	z-index: 5;

	display: flex;
	flex-direction: column;

	overflow: hidden;
}

.editor-editSoundModal--draggingTrimBar,
.editor-editSoundModal--draggingTrimBar *,
.editor-editSoundModal--draggingSeekBar,
.editor-editSoundModal--draggingSeekBar *{
	cursor: grabbing !important; // want grab cursor to stay as you hold down around
}

//
.editor-editSoundModal-header{	
	// background: $tempr;
	width: calc(100% + 46px);
	margin-left: -23px;

	display: flex;
	align-items: center;

	user-select: none;
}

.editor-editSoundModal--draggingTrimBar .editor-editSoundModal-header,
.editor-editSoundModal--draggingSeekBar .editor-editSoundModal-header{
	pointer-events: none;
}


// button.editor-editSoundModal-header-backBtn{
// 	height: 48px;
// 	width: 42px;

// 	background: none;
// 	border: none;

// 	border-radius: 3px;

// 	display: flex;
// 	align-items: center;
// 	justify-content: flex-end;

// 	box-sizing: border-box;
// 	padding-right: 8px;

// 	cursor: pointer;
// }

// button.editor-editSoundModal-header-backBtn svg{
// 	height: 15px;
// 	width: 15px;

// 	fill: $gray-8;
// }


// button.editor-editSoundModal-header-backBtn:hover svg{
// 	fill: $blue;
// }


//

.editor-editSoundModal-header-leftContainer,
.editor-editSoundModal-header-rightContainer{
	width: 170px;
	height: 100%;
	flex-grow: 0;
	flex-shrink: 0;
}


///
/// TITLE INPUT
//

.editor-editSoundModal-header-titleContainer{
	flex-grow: 1;
	flex-shrink: 1;

	display: flex;
	align-items: center;
	justify-content: center;

	position: relative;

	// background: $tempr;
	
}

input.editor-editSoundModal-header-title{
	font-size: 17px;
	font-weight: $lato-bold;
	color: $textBlack;
	letter-spacing: 0.005em;
	text-align: center;

	height: 25px;
	border: 1px solid $invisible;
	background: none;

	border-radius: 4px;

	min-width: 340px;
	flex-grow: 1;
	flex-shrink: 1;

	// background: $tempr;

	// background: $tempr;
}

input.editor-editSoundModal-header-title:hover{
	background: rgba(0,0,25,0.03);
	border: 1px solid $invisible;
}

// during insert flow, always show as editablish
// .editor-editSoundModalContainer--insertFlow
// input.editor-editSoundModal-header-title{
// 	background: rgba(0,0,25,0.03);
// 	border: 1px solid $invisible;
// }

input.editor-editSoundModal-header-title:focus,
.editor-editSoundModalContainer--insertFlow input.editor-editSoundModal-header-title:focus{
	background: $white;
	border: 1px solid $blue;
	outline: none;
	box-shadow: none;
}

.editor-editSoundModal-header-title-tooltip{
	position: absolute;
	left: 0px;
	right: 0px;
	bottom: -45px;
	height: 45px;
	background: rgb(253,253,255);

	display: flex;
	align-items: top;
	justify-content: center;

	box-sizing: border-box;
	padding-top: 5px;

	text-align: center;
	font-size: 14px;
	font-weight: $lato-semibold;
	letter-spacing: 0.005em;
	color: $textDark;

	pointer-events: none;
	user-select: none;

	opacity: 0;
	transition: opacity 50ms linear;
}

input.editor-editSoundModal-header-title:hover ~ .editor-editSoundModal-header-title-tooltip,
input.editor-editSoundModal-header-title:focus ~ .editor-editSoundModal-header-title-tooltip{
	opacity: 1;
	transition: opacity 100ms linear;
}

input.editor-editSoundModal-header-title:hover ~ .editor-editSoundModal-header-title-tooltip{
	transition-delay: 500ms;
}


.editor-editSoundModal-header-rightContainer{
	display: flex;
	align-items: center;
	justify-content: flex-end;

	height: 100%;

	position: relative;
	//background: $tempr;
}

button.editor-editSoundModal-header-cancelBtn,
button.editor-editSoundModal-header-backBtn{
	
	height: 34px;

	cursor: pointer;

	font-size: 13px;
	color: $textBlackDark;
	font-weight: $lato-semibold;
	letter-spacing: 0.02em;

	opacity: 0.7;

	border: none;
	background: none;
	border-radius: 5px;

	display: flex;
	align-items: center;
	justify-content: center;

	background: $invisible;
}

button.editor-editSoundModal-header-cancelBtn{
	position: absolute;
	top: -2px;
	right: 2px;

	padding-right: 32px;
	padding-left: 32px;
}

button.editor-editSoundModal-header-backBtn{
	margin-top: -2px;
	width: 140px;
	padding-left: 8px;
	padding-right: 12px;
}

button.editor-editSoundModal-header-backBtn svg{
	height: 11px;
	width: 11px;
	fill: $textDark;
	margin-right: 10px;
	position: relative;
	top: 1px;
}

button.editor-editSoundModal-header-cancelBtn:hover,
button.editor-editSoundModal-header-backBtn:hover{
	opacity: 1;
	background: rgba(0,0,40,0.03);
	outline: none;
	border: none;
	box-shadow: none;
}

.editor-editSoundModalContainer--insertFlow
button.editor-editSoundModal-header-cancelBtn:hover{
	background: transparentize($red, 0.85);
	color: darken($red, 22%);
}


@keyframes buttonSoundBackBounce {
  from { transform: translateX(0px); }
  to { transform: translateX(-3px); }
}

button.editor-editSoundModal-header-backBtn:hover svg{
	 animation: buttonSoundBackBounce .3s infinite ease-in-out;
	 animation-direction: alternate;
	 animation-delay: 200ms;
}




//
// 
// TIMESTAMP

.editor-editSoundModal-timestampContainer{
	width: 100%;
	height: 45px;
	// background: $tempr;
	text-align: center;

	box-sizing: border-box;
	cursor: default;
}

.editor-editSoundModal-currentTimestamp{
	font-size: 20px;
	font-weight: $lato-bold;
	color: $textBlack;
}

.editor-editSoundModal-fullTimestamp{
	font-size: 15px;
	font-weight: $lato-medium;
	color: $textMid;
	margin-top: 1px;
}

.editor-editSoundModal-timestampContainer--withCurrentTimestamp
.editor-editSoundModal-fullTimestamp{
	color: $textLight;
}


//
//

$seekBarHeight-editSoundModal: 160px; // note that seekbar click area is effectively seekbar + trimbar
$trimBarHeight-editSoundModal: 44px;
$previewBarHeight-editSoundModal: 36px;

$sidePadding-editSoundModal: 48px;
$outerSidePadding-editSoundModal: 20px;

.editor-editSoundModal-playbackBarsContainer{
	height: calc(#{$seekBarHeight-editSoundModal} + #{$trimBarHeight-editSoundModal} + #{$previewBarHeight-editSoundModal});
	//width: calc(100% + #{$sidePadding-editSoundModal} + #{$sidePadding-editSoundModal});
	width: calc(100% + #{$outerSidePadding-editSoundModal} + #{$outerSidePadding-editSoundModal});
	margin-left: calc(-1 *  #{$outerSidePadding-editSoundModal});

	flex-grow: 0;
	flex-shrink: 0;
	
	user-select: none;

	position: relative;	
	box-sizing: border-box;
	// border: 1px solid $lineDark;
}


.editor-editSoundModal-seekBarContainer {
	position: absolute;
	top: 0px;
	left: $sidePadding-editSoundModal;
	right: $sidePadding-editSoundModal;
	bottom: $previewBarHeight-editSoundModal;
	// border: 1px solid red;
	// background: $tempr;

	z-index: 5;
}

.editor-editSoundModal-seekbar{
	width: 100%;
	height: 100%;

	user-select: none;
	cursor: pointer;
}

// TRACK

.editor-editSoundModal-seekbar-track-0{
	height: 6px;
	background: $gray-5;
	margin-top: calc((#{$seekBarHeight-editSoundModal} * 0.5) - 1px);
	border-radius: 3px;
}

.editor-editSoundModal-seekbar-track-1{
	height: 6px;
	background: transparentize($gray-8, 0.5);
	margin-top: calc((#{$seekBarHeight-editSoundModal} * 0.5) - 1px);
	border-radius: 3px;
}


// THUMB
.editor-editSoundModal-seekbar-thumb{
	width: 2px;
	height: 100%;
	cursor: grab;
}

.editor-editSoundModal-seekbar-thumb:focus{
	outline: $invisible;
}

.editor-editSoundModal-seekbar-thumb-tooltip{
	position: absolute;
	// width: 16px;
	height: 24px;
	// width: 46px;
	padding-left: 10px;
	padding-right: 10px;

	top: calc((#{$seekBarHeight-editSoundModal} * 0.5) - 42px);
	//top: calc((#{$previewHeightSeekBar} * 0.5) - 12px);
	//left: calc(-26px);
	left: 50%;
	transform: translateX(-50%);

	background: $gray-4;
	//border: 1px solid darken($gray-2, 5%);
	border: 1px solid rgba(255,255,255,0.15);
	

	border-radius: 5px;

	pointer-events: none;
	user-select: none;
	
	font-size: 14px;
	font-weight: $lato-bold;
	letter-spacing: 0.01em;

	color: $white;

	display: flex;
	align-items: center;
	justify-content: center;

	background: $white;
	border: 1px solid darken($lineDark, 3%);
	border-bottom: 1px solid darken($lineDark, 5%);
	box-shadow: 0px 1px 2px 0px rgba(0,0,10,0.06);

	color: $textBlack;

	// border: 2px solid white;
	// box-shadow: 0px 0px 0px 1px rgba(0,0,10,0.06);

	// s
}

.editor-editSoundModal-seekbar-thumb-circle{
	position: absolute;
	width: 18px;
	height: 18px;

	top: calc((#{$seekBarHeight-editSoundModal} * 0.5) - 10px);
	left: -11px;	

	background: $recordingRed;
	border-radius: 50%;

	border: 2px solid white;
	box-shadow: 0px 0px 0px 1px rgba(0,0,10,0.06);

	z-index: 2;

	transition: transform 50ms linear;
	transform-origin: center center;
}

.editor-editSoundModal-seekbar-thumb:hover
.editor-editSoundModal-seekbar-thumb-circle{
	transform: scale(1.05);

	box-shadow: 0px 0px 0px 1px rgba(0,0,10,0.1);

}

.editor-editSoundModal--draggingSeekBar
.editor-editSoundModal-seekbar-thumb-circle,
.editor-editSoundModal-seekbar-thumb:active
.editor-editSoundModal-seekbar-thumb-circle{
	transform: scale(1.2);
	
	transition: transform 0ms linear;

	box-shadow: 0px 0px 0px 1px rgba(0,0,10,0.15);
}

.editor-editSoundModal-seekbar-thumb:active{
	cursor: grabbing !important;
}

.editor-editSoundModal-seekbar-thumb-line{
	position: absolute;

	width: 2px;
	border-radius: 2px;
	margin-left: -1px;

	height: calc((#{$seekBarHeight-editSoundModal} * 0.5) + #{$trimBarHeight-editSoundModal});

	top: calc(#{$seekBarHeight-editSoundModal} * 0.5);
	background: $recordingRed;
}



//
// TRIM BAR
//

$trimBarThumbWidth-editSoundModal: 16px;

.editor-editSoundModal-trimBarContainer{
	position: absolute;
	left: $sidePadding-editSoundModal;
	right: $sidePadding-editSoundModal;
	bottom: calc(#{$trimBarHeight-editSoundModal} + #{$previewBarHeight-editSoundModal});
	height: 0px; // to prevent clicks
	
	box-sizing: border-box;

	// background: $tempg;
	z-index: 5;
}

.editor-editSoundModal-trimBar{
	width: 100%;
	height: 0px; // clicks are saved for seekbar

	user-select: none;
}

//

.editor-editSoundModal-trimBar-track-1{
	height: $trimBarHeight-editSoundModal;
	background: transparentize($blue, 0.95);
	// display: none;
	pointer-events: none;
}


// THUMB

.editor-editSoundModal-trimBar-thumbContainer{
	width: 0px;
	height: $trimBarHeight-editSoundModal;
	background: $red;

	user-select: none;
	position: relative;
}

.editor-editSoundModal-trimBar-thumbContainer:focus{
	outline: $invisible;
}

.editor-editSoundModal-trimBar-thumb{
	background: $blue;
	position: absolute;
	width: $trimBarThumbWidth-editSoundModal;
	height: $trimBarHeight-editSoundModal;
	top: 0px;
	cursor: grab;
}

.editor-editSoundModal-trimBar-thumbContainer-0 .editor-editSoundModal-trimBar-thumb{
	left: calc(-1 * #{$trimBarThumbWidth-editSoundModal});
	border-top-left-radius: 5px;
	border-bottom-left-radius: 5px;
}

.editor-editSoundModal-trimBar-thumbContainer-1 .editor-editSoundModal-trimBar-thumb{
	right: calc(-1 * #{$trimBarThumbWidth-editSoundModal});
	border-top-right-radius: 5px;
	border-bottom-right-radius: 5px;
}


.editor-editSoundModal-trimBar-thumb-line{
	width: 2px;
	border-radius: 2px;
	height: 70%;

	margin: auto;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;	

	background: $white;	

	opacity: 0.7;
}

.editor-editSoundModal-trimBar-thumb:active 
.editor-editSoundModal-trimBar-thumb-line{
	opacity: 1;
}

.editor-editSoundModal-trimBar-thumb:active{
	cursor: grabbing !important;
}


button.editor-editSoundModal-trimBar-thumb-align{
	position: absolute;
	width: 24px;
	height: 28px;
	top: 50%;
	transform: translateY(-50%);

	display: flex;
	align-items: center;
	justify-content: center;

	border: none;
	background: none;

	z-index: 15;

	border-radius: 4px;
	background: transparentize(#D7E4FD, 0.1);
	// background: #D7E4FD;

	cursor: pointer;
}

button.editor-editSoundModal-trimBar-thumb-align:hover{
	background: #d1e0fe;
}

button.editor-editSoundModal-trimBar-thumb-align--show{
	opacity: 1;
	transition: opacity 100ms linear;
}

button.editor-editSoundModal-trimBar-thumb-align--hide{
	opacity: 0;
	transition: opacity 100ms linear;
	pointer-events: none;
}

.editor-editSoundModal-trimBar-thumbContainer-0
button.editor-editSoundModal-trimBar-thumb-align{
	left: -27px;
}

.editor-editSoundModal-trimBar-thumbContainer-1
button.editor-editSoundModal-trimBar-thumb-align{
	right: -27px;
}

button.editor-editSoundModal-trimBar-thumb-align svg{
	width: 12px;
	height: 12px;
	fill: $blue;
}


.editor-editSoundModal-trimBar-thumbContainer-1
button.editor-editSoundModal-trimBar-thumb-align--start{
	display: none;
}

.editor-editSoundModal-trimBar-thumbContainer-0
button.editor-editSoundModal-trimBar-thumb-align--end{
	display: none;	
}






// WAVEFORM TRACK

.editor-editSoundModal-waveformBG{
	position: absolute;
	top: 4px;
	left: $sidePadding-editSoundModal;
	right: $sidePadding-editSoundModal;
	height: calc(#{$seekBarHeight-editSoundModal} - 8px);

	pointer-events: none;
	z-index: 1;
}

img.editor-editSoundModal-waveFormBG-waveform{
	width: 100%;
	height: 100%;
	object-fit: fill;

	opacity: 0.1;

	pointer-events: none;

}

// FAKE TRACK

.editor-editSoundModal-trimBarContainer-bgTrack{
	position: absolute;
	top: 0px;
	left: 0px;
	right: 0px;
	height: $trimBarHeight-editSoundModal;
	z-index: 1;

	border-radius: 5px;

	background: rgba(0,0,30,0.03);
	pointer-events: none;

}




//
//


.editor-editSoundModal-previewBarContainer{
	position: absolute;
	left: $sidePadding-editSoundModal;
	right: $sidePadding-editSoundModal;
	bottom: 0px;
	height: $previewBarHeight-editSoundModal;
	
	box-sizing: border-box;

	// background: $tempb;
}

.editor-editSoundModal-previewBar{
	position: absolute;
	height: 100%;
	// background: $tempr;
}

.editor-editSoundModal-previewBar-line{
	position: absolute;
	top: 3px;
	left: 0px;
	right: 0px;
	height: 2px;
	border-radius: 2px;
}

.editor-editSoundModal-previewBar--readyToPlayClip
.editor-editSoundModal-previewBar-line{
	background: $gray-125;
}

.editor-editSoundModal-previewBar--draggingTrimBarHandles
.editor-editSoundModal-previewBar-line{
	background: $gray-10;
}

.editor-editSoundModal-previewBar--draggingTrimBarHandles
.editor-editSoundModal-previewBar-line--playingClip{
	background: transparentize($blue, 0.75);
}

.editor-editSoundModal-previewBar--playingClip
.editor-editSoundModal-previewBar-line,
.editor-editSoundModal-previewBar--resumeClip
.editor-editSoundModal-previewBar-line{
	height: 2px;
	background: $blue-extraLight;
}

.editor-editSoundModal-previewBar--playingClip
.editor-editSoundModal-previewBar-playClipProgressBar,
.editor-editSoundModal-previewBar--resumeClip
.editor-editSoundModal-previewBar-playClipProgressBar,
.editor-editSoundModal-previewBar--draggingTrimBarHandles
.editor-editSoundModal-previewBar-playClipProgressBar{
	position: absolute;
	top: 3px;
	height: 2px;
	left: 0px;
	background: $blue;
	z-index: 5;
	border-radius: 2px;
}

// MAIN BUTTON/LABEL THING

.editor-editSoundModal-previewBar-clipDuration,
button.editor-editSoundModal-previewBar-clipProgress,
button.editor-editSoundModal-previewBar-btn,
button.editor-editSoundModal-previewBar-clipRestartBtn{
	position: absolute;
	
	left: 50%;
	transform: translateX(-50%);
	
	bottom: 0px;

	height: 26px;

	font-size: 14px;
	color: $white;

	display: flex;
	align-items: center;
	justify-content: center;

	border: none;
	box-shadow: none;
	border-radius: 4px;

	user-select: none;
}

// STATE: READY TO PLAY
button.editor-editSoundModal-previewBar-btn{
	padding-left: 12px;
	padding-right: 12px;
	
	background: $gray-2;
	// background: $blue;
	

	font-weight: $lato-bold;	
	letter-spacing: 0.025em;

	white-space: nowrap;

	cursor: pointer;

	width: auto;
	opacity: 0.75;
	transition: opacity 75ms linear;
	margin-bottom: 2px;
}

button.editor-editSoundModal-previewBar-btn:hover{
	opacity: 1;
	transition: opacity 50ms linear;
}

button.editor-editSoundModal-previewBar-btn svg{
	height: 12px;
	width: 12px;
	fill: $white;
	margin-right: 6px;
	opacity: 0.8;
}

.editor-editSoundModal-previewBar--resumeClip
button.editor-editSoundModal-previewBar-btn{
	background: $blue;
	opacity: 1;
}

// STATE: CLIP DURATION
.editor-editSoundModal-previewBar-clipDuration{
	font-weight: $lato-bold;

	width: auto;
	padding-left: 9px;
	padding-right: 9px;
	letter-spacing: 0.04em;

	background: $gray-5;
	height: 26px;
	margin-bottom: 2px;

	background: $gray-4;
	color: $white;
}

.editor-editSoundModal-previewBar-clipDuration--playingClip{
	background: darken($blue, 4%);
	color: $white;

	padding-left: 11px;
	padding-right: 11px;
}

.editor-editSoundModal-previewBar-clipDuration--playingClip--clipProgress{
	opacity: 0.75;
	white-space: nowrap;
}

.editor-editSoundModal-previewBar-clipDuration--playingClip--duration{
	padding-left: 5px;
}

.editor-editSoundModal-previewBar-clipDuration-milliseconds{
	opacity: 0.5;
}

// STATE: CLIP PROGRESS
button.editor-editSoundModal-previewBar-clipProgress{
	font-weight: $lato-bold;
	width: auto;

	padding-left: 18px;
	padding-right: 18px;

	background: $blue;
	letter-spacing: 0.05em;
	height: 26px;
	margin-bottom: 2px;

	cursor: pointer;

	display: flex;
	align-items: center;

	padding-left: 8px;
	padding-right: 11px;
}

.editor-editSoundModal-previewBar-clipProgress-icon{
	padding-right: 5px;
}

.editor-editSoundModal-previewBar-clipProgress-icon svg{
	fill: rgba(255,255,255,0.7);
	height: 10px;
	width: 10px;
	
	position: relative;
	top: 0px;
	//transition: opacity 50ms linear; removed because of safari bug
}

button.editor-editSoundModal-previewBar-clipProgress:hover 
.editor-editSoundModal-previewBar-clipProgress-icon svg{
	fill: rgba(255,255,255,1);
	// transition: opacity 50ms linear;
}

// rewind clip button



button.editor-editSoundModal-previewBar-clipRestartBtn{
	
	margin-left: -60px;
	width: 36px;

	background: transparentize($blue, 0.9);
	height: 26px;
	margin-bottom: 2px;

	opacity: 0.9;

	cursor: pointer;

	display: flex;
	align-items: center;
	justify-content: center;
}

button.editor-editSoundModal-previewBar-clipRestartBtn--millisecond{
	margin-left: - 71px; // timestamp is wider, so bigger offset
}

.editor-editSoundModal-previewBar--resumeClip
button.editor-editSoundModal-previewBar-clipRestartBtn{
	margin-left: -76px;
}

button.editor-editSoundModal-previewBar-clipRestartBtn{
	opacity: 0.9;
	transition: opacity 50ms linear;
}

button.editor-editSoundModal-previewBar-clipRestartBtn:hover{
	opacity: 1;	
	transition: opacity 50ms linear;
}

button.editor-editSoundModal-previewBar-clipRestartBtn svg{	
	fill: $blue;
	height: 13px;
	width: 13px;
	
	position: relative;
	left: -1px;
	transition: opacity 50ms linear;
}

//
// EXTRA SEEK TO START BUTTON

.editor-editSoundModal-seekToStartBtn{
	position: absolute;
	left: calc(-1 * #{$outerSidePadding-editSoundModal});
	width: calc(#{$sidePadding-editSoundModal} + #{$outerSidePadding-editSoundModal});
	top: 0px;
	height: $seekBarHeight-editSoundModal;

	background: none;
	box-shadow: none;

	cursor: pointer;

	border: none;

}


// WAVE FORM
.editor-editSoundModal-seekBarContainer-waveformImageContainer{
	position: absolute;
	top: 15px;
	left: 0px;
	bottom: 15px;
	right: 0px;

	pointer-events: none;
}

.editor-editSoundModal-seekBarContainer-waveformImageContainer img{
	width: 100%;
	height: 100%;
	object-fit: fill;

	opacity: 0.08;
}




// iPAD VERSION

@media (hover: none) {
	.editor-editSoundModal-seekbar-thumb-circle{
		// width: 20px;
		// height: 20px;
		// left: -11px;
		// top: calc((160px * 0.5) - 9px);

		width: 34px;
		height: 34px;
		left: -20px;
		top: calc((160px * 0.5) - 20px);

		border-width: 4px;
	}

	.editor-editSoundModal-trimBarContainer{
		bottom: calc(64px + 36px)
	}

	button.editor-editSoundModal-trimBar-thumb-align{
		display: none;
	}

	.editor-editSoundModal-trimBar-thumb{
		width: 28px;
	}

	.editor-editSoundModal-trimBar-thumbContainer-0 .editor-editSoundModal-trimBar-thumb{
		left: calc(-1 * 28px);
		border-top-left-radius: 8px;
		border-bottom-left-radius: 8px;
		border-top-right-radius: 3px;
		border-bottom-right-radius: 3px;
	}

	.editor-editSoundModal-trimBar-thumbContainer-1 .editor-editSoundModal-trimBar-thumb{
		right: calc(-1 * 28px);
		border-top-left-radius: 3px;
		border-bottom-left-radius: 3px;
		border-top-right-radius: 8px;
		border-bottom-right-radius: 8px;
	}

	.editor-editSoundModal-trimBarContainer-bgTrack,
	.editor-editSoundModal-trimBar-track-1,
	.editor-editSoundModal-trimBar-thumb{
		height: 64px;
	}

	.editor-editSoundModal-waveformBG{
		height: calc(144px - 8px);
	}

	.editor-editSoundModal-trimBar-thumb-line{
		width: 4px;
		border-radius: 4px;
	}

	.editor-editSoundModal-playbackControls-tooltip{
		visibility: hidden;
	}
}



// .editor-editSoundModal-seekBarContainer .rc-slider{
// 	height: $seekBarHeight-editSoundModal;	
// 	cursor: pointer;
// 	padding: 0px;
// 	z-index: 10;

// }

// .editor-editSoundModal-seekBarContainer .rc-slider-handle,
// .editor-editSoundModal-seekBarContainer .rc-slider-handle:hover,
// .editor-editSoundModal-seekBarContainer .rc-slider-handle:focus,
// .editor-editSoundModal-seekBarContainer .rc-slider-handle:active{
// 	width: 18px;
// 	top: 0px;
// 	height: $seekBarHeight-editSoundModal;
 	
// 	border-radius: 0px;
// 	border: none;
// 	box-shadow: none;
// 	margin-top: 0px;

// 	cursor: pointer;

// 	// background: $tempb;
// 	background: $invisible;
// }

// // .editor-editSoundModal-seekBarContainer .rc-slider-handle:hover .editor-editSoundModal-seekBar-positionDot,
// // .editor-editSoundModal-seekBarContainer .rc-slider-handle:focus .editor-editSoundModal-seekBar-positionDot,
// // .editor-editSoundModal-seekBarContainer .rc-slider-handle:active .editor-editSoundModal-seekBar-positionDot{
// // 	transform: scale(1.2);
// // 	transform-origin: center center;
// // }

// .editor-editSoundModal-seekBarContainer .rc-slider-handle
// .editor-editSoundModal-seekBar-positionDot{
// 	position: absolute;
// 	z-index: 10;
// 	left: 0px;
// 	top: 50%;

// 	background: red;
// 	border-radius: 50%;

// 	opacity: 1;

// 	border: 2px solid white;
// 	box-shadow: 0px 0px 0px 1px rgba(0,0,10,0.06);

// 	width: 18px;
// 	height: 18px;

// 	margin-top: -7px;
// }

// .editor-editSoundModal-seekBarContainer .rc-slider-handle
// .editor-editSoundModal-seekBar-positionVerticalLine{
// 	height: calc((#{$seekBarHeight-editSoundModal} / 2) + #{$trimBarHeight-editSoundModal} - 4px);
// 	// height: calc((#{$seekBarHeight-editSoundModal} / 2) + 4px);
// 	width: 2px;
// 	background: red;
// 	opacity: 0.9;
// 	position: absolute;
// 	top: 50%;
// 	left: 8px;
// 	border-radius: 2px;
// }

// .editor-editSoundModal-seekBarContainer .rc-slider-track,
// .editor-editSoundModal-seekBarContainer .rc-slider-rail{
// 	height: 3px;
// 	top: calc(50% + 1px);
// }

// .editor-editSoundModal-seekBarContainer .rc-slider-track{
// 	background: $gray-7;
// 	border-radius: 3px;
// }

// .editor-editSoundModal-seekBarContainer .rc-slider-rail{	
// 	background: $gray-10;	
// 	border-radius: 3px;
// }


// .editor-editSoundModal-trimBarContainer{
// 	position: absolute;
// 	left: 24px;
// 	right: 24px;
// 	bottom: 0px;
// 	height: $trimBarHeight-editSoundModal;
	
// 	box-sizing: border-box;
	

// 	z-index: 10;
// }






// .editor-editSoundModal-trimBarContainer
// .rc-slider{	
// 	padding: 0px;
// }

// .editor-editSoundModal-trimBarContainer
// .rc-slider-handle{
// 	background: $gray-5;
// 	height: $trimBarHeight-editSoundModal;
// 	width: 14px;
// 	margin-top: 0px;
// 	border-radius: 0px;
// 	border: none;
// }

// .editor-editSoundModal-trimBarContainer
// .rc-slider-handle:hover{
// 	background: darken($gray-5, 10%);
// }

// .editor-editSoundModal-trimBarContainer
// .rc-slider-handle-1{
// 	border-top-left-radius: 5px;
// 	border-bottom-left-radius: 5px;
// 	margin-left: 6px;	
// }

// .editor-editSoundModal-trimBarContainer
// .rc-slider-handle-2{
// 	border-top-right-radius: 5px;
// 	border-bottom-right-radius: 5px;
// 	margin-right: -6px;
// }

// .editor-editSoundModal-trimBarContainer
// .editor-editSoundModal-center-trimBar-customHandleInsert{
	
// 	width: 3px;
// 	border-radius: 3px;
// 	height: 28px;

// 	margin: 0 auto;
// 	margin-top: 4px;

// 	background: $white;

// 	opacity: 0.9;
// }

// .editor-editSoundModal-trimBarContainer:hover
// .editor-editSoundModal-center-trimBar-customHandleInsert{
// 	opacity: 1;
// }

// .editor-editSoundModal-trimBarContainer
// .rc-slider-handle-dragging{
// 	border: none !important;
// 	box-shadow: none !important;
// }


// .editor-editSoundModal-trimBarContainer .rc-slider-rail{
// 	height:  calc(#{$trimBarHeight-editSoundModal} - 4px);
// 	margin-top: 2px;
// 	border-radius: none;
// 	background: transparentize($gray-5,0.9);

// }

// .editor-editSoundModal-trimBarContainer .rc-slider-track{
// 	//cursor: grab;
// 	cursor: pointer;
// 	height:  $trimBarHeight-editSoundModal;
// 	background: $gray-5;
// }


// .editor-editSoundModal-trimBarContainer-tooltipContainer{
// 	position: absolute;
// 	top: 0px;
// 	bottom: 0px;

		

// 	display: flex;
// 	align-items: center;
// 	justify-content: center;

// 	pointer-events: none;
// 	background: none;

	

// }

// .editor-editSoundModal-trimBarContainer-tooltip{
// 	font-size: 15.5px;
// 	padding-top: 1px;
// 	box-sizing: border-box;
// 	font-weight: $lato-semibold;
// 	letter-spacing: 0.03em;
// 	color: $white;

// 	transition: opacity 50ms linear;
// }

// .editor-editSoundModal-trimBarContainer-tooltip--show{
// 	opacity: 1;
// 	transition: opacity 50ms linear;
// }

// .editor-editSoundModal-trimBarContainer-tooltip--hide{
// 	opacity: 0;
// 	transition: opacity 50ms linear;
// }



//

.editor-editSoundModal-playbackControls{
	width: 100%;
	height: 104px;
	flex-grow: 1;
	flex-shrink: 1;

	display: flex;
	align-items: center;
	justify-content: center;

	position: relative;
	z-index: 10;
}

button.editor-editSoundModal-playbackControls-playPauseBtn{
	width: 240px;
	height: 62px;
	margin-left: 18px;
	margin-right: 18px;

	background: $white;

	border: 1px solid darken($lineMid, 5%);
	border-bottom: 1px solid darken($lineMid, 8%);
	box-shadow: 0px 1px 2px 0px rgba(0,0,40,0.04);
	border-radius: 6px;

	display: flex;
	align-items: center;
	justify-content: center;

	cursor: pointer;

	transition: transform 250ms linear;

	position: relative;
	will-change: transform;
}



button.editor-editSoundModal-playbackControls-playPauseBtn:hover{
	//transform: scale(1.02);
	//transition: transform 50ms linear;

	border: 1px solid darken($lineMid, 9%);
	border-bottom: 1px solid darken($lineMid, 14%);
}

button.editor-editSoundModal-playbackControls-playPauseBtn:active{
	transform: scale(0.96);
	transition: transform 200ms ease;
	transition-delay: 50ms;
	background: lighten($blue-extraLight, 4%);
	border: 1px solid darken($lineMid, 10%);
	border-bottom: 1px solid darken($lineMid, 15%);
}

button.editor-editSoundModal-playbackControls-playPauseBtn svg{
	width: 32px;
	height: 32px;
	fill: $blue;
	transition: transform 50ms linear;
	will-change: transform;
}

button.editor-editSoundModal-playbackControls-playPauseBtn:hover svg{	
	transform: scale(1.06);
	transition: transform 50ms linear;
}

button.editor-editSoundModal-playbackControls-playPauseBtn svg.editorIcon-playbackPlay{
	position: relative;
	right: -1px;
}

button.editor-editSoundModal-playbackControls-seekBtn{
	width: 60px;
	height: 50px;
	background: $white;

	border: 1px solid darken($lineMid, 5%);
	border-bottom: 1px solid darken($lineMid, 8%);

	box-shadow: 0px 1px 1px 0px rgba(0,0,40,0.04);
	border-radius: 6px;

	display: flex;
	align-items: center;
	justify-content: center;

	cursor: pointer;
	opacity: 0.8;
	opacity: 1;

	position: relative;
	will-change: transform;
}

.editor-editSoundModal--draggingTrimBar button.editor-editSoundModal-playbackControls-seekBtn,
.editor-editSoundModal--draggingSeekBar button.editor-editSoundModal-playbackControls-seekBtn{
	pointer-events: none;
}

button.editor-editSoundModal-playbackControls-seekBtn svg.editorIcon{
	width: 24px;
	height: 24px;
	fill: $gray-6;
	opacity: 0.8;
	transition: transform 50ms linear;
}

button.editor-editSoundModal-playbackControls-seekBtn:hover{
	border: 1px solid darken($lineMid, 9%);
	border-bottom: 1px solid darken($lineMid, 14%);
	opacity: 1;
}

button.editor-editSoundModal-playbackControls-seekBtn:hover svg{
	opacity: 1;
	transform: scale(1.04);
	transition: transform 50ms linear;
}

button.editor-editSoundModal-playbackControls-seekBtn:disabled{
	opacity: 0.5;
	pointer-events: none;
}

.editor-editSoundModal-playbackControls-tooltip{
	position: absolute;
	bottom: -26px;

	background: darken($gray-7, 5%);
	background: $gray-7;

	display: flex;
	align-items: center;
	justify-content: center;


	border-radius: 3px;

	box-sizing: border-box;

	opacity: 0;
	transition: opacity 100ms linear;

	pointer-events: none;
	user-select: none;
}

.editor-editSoundModal-playbackControls-tooltip svg.icon{
	width: 9px;
	height: 9px;
	fill: $white;
	opacity: 1;
}

.editor-editSoundModal-playbackControls-playPauseBtnContainer{
	position: relative;	
}

button.editor-editSoundModal-playbackControls-seekBtn
.editor-editSoundModal-playbackControls-tooltip{
	bottom: -24px;

	height: 19px;
	width: 38px;
}

.editor-editSoundModal-playbackControls-playPauseBtnContainer
.editor-editSoundModal-playbackControls-tooltip{
	bottom: -24px;
	left: 50%;
	transform: translateX(-50%);

	height: 20px;
	width: 80px;
	padding-bottom: 1px;

	font-size: 12px;
	font-weight: $lato-bold;
	color: $white;
	letter-spacing: 0.035em;
}

.editor-editSoundModal-playbackControls-playPauseBtnContainer:hover .editor-editSoundModal-playbackControls-tooltip,
button.editor-editSoundModal-playbackControls-seekBtn:hover .editor-editSoundModal-playbackControls-tooltip{
	opacity: 1;
	transition: opacity 50ms linear;
	transition-delay: 100ms;
}

.editor-editSoundModal--draggingTrimBar .editor-editSoundModal-playbackControls-playPauseBtnContainer,
.editor-editSoundModal--draggingSeekBar .editor-editSoundModal-playbackControls-playPauseBtnContainer{
	pointer-events: none;
}

//




// SEEK BAR

// TRIM BAR

//
//


// .editor-editSoundModal-trimBarContainer--overlength
// .rc-slider-track,
// .editor-editSoundModal-trimBarContainer--overlength
// .rc-slider-handle{
// 	background: $red;
// }
// .editor-editSoundModal-trimBarContainer--overlength
// .rc-slider-handle:hover{
// 	background: darken($red, 5%);
// }















//
// FOOTER


.editor-editSoundModal-footer{
	width: calc(100% + 36px + 36px;);
	margin-left: -36px;

	border-top: 1px solid darken($lineMid, 5%);
	background: rgb(250,250,254);
	
	height: 112px;

	display: flex;
	align-items: center;
	justify-content: center;

	box-sizing: border-box;

	position: relative;
}

.editor-editSoundModal--draggingTrimBar .editor-editSoundModal-footer,
.editor-editSoundModal--draggingSeekBar .editor-editSoundModal-footer{
	pointer-events: none;
}



// ATTRIBUTION
.editor-editSoundModal-footer-attribution{
	position: absolute;
	left: 20px;
	top: 50%;
	transform: translateY(-50%);

	width: 200px;
	height: 62px;

	// background: $tempr;

	display: flex;
	align-items: center;
	justify-content: center;

}


// similar styling to help btn
button.editor-editSoundModal-footer-attribution-btn{
	height: 32px;
	
	color: $textBlack;

	opacity: 0.6;
	background: none;
	border: none;
	box-shadow: none;

	padding-left: 8px;
	padding-right: 8px;

	border-radius: 4px;

	font-size: 13px;
	letter-spacing: 0.025em;

	font-weight: $lato-semibold;

	display: flex;
	align-items: center;
	justify-content: center;

	cursor: pointer;

	transition: opacity 50ms linear;

	user-select: none;
}


.editor-editSoundModal-footer:hover
button.editor-editSoundModal-footer-attribution-btn{
	opacity: 0.8;
}

.editor-editSoundModal-footer
button.editor-editSoundModal-footer-attribution-btn:hover{
	background: rgba(10,10,70,0.04);
	opacity: 1;
	
	// text-decoration: underline !important;
}

//textArea
.editor-editSoundModal-footer-attribution-textAreaContainer{
	width: 100%;
	height: 100%;
	// background: $tempg;

}


.editor-editSoundModal-footer-attribution-textArea-title{
	font-size: 13.5px;
	font-weight: $lato-bold;
	letter-spacing: 0.02em;
	color: $textDark;

	box-sizing: border-box;

	width: 100%;
	text-align: center;

	user-select: none;
	cursor: default;

	// background: $tempb;
}
textarea.editor-editSoundModal-footer-attribution-textArea{
	box-sizing: border-box;
	padding: 6px;
	padding-top: 4px;
	padding-bottom: 4px;
	font-size: 13px;

	width: 100%;
	height: 52px;
	
	resize: none;

	border: 1px solid $lineMid;
	background: $white;
	border-radius: 2px;

	outline: none;
	box-shadow: none;

	text-align: center;
	// background: $tempr;
}

textarea.editor-editSoundModal-footer-attribution-textArea:hover{
	border: 1px solid $lineDark;
	background: $white;
}

textarea.editor-editSoundModal-footer-attribution-textArea:focus{
	border: 1px solid $blue;
	background: $white;
}



// DONE BTN

.editor-editSoundModal-doneBtn{
	height: 55px;
	height: 74px;
	width: 450px;
	box-sizing: border-box;

	background: $blue;
	border-radius: 5px;
	border-radius: 7px;

	border: none;
	box-shadow: none;

	box-shadow: 0px 2px 2px 0px rgba(0,0,0,0.12);

	cursor: pointer;

	margin-top: 0px;

	position: relative;
	user-select: none;
}

.editor-editSoundModal-doneBtn:hover{
	background: saturate($blue, 5%);
	box-shadow: 0px 2px 2px 0px rgba(0,0,0,0.16);
}

.editor-editSoundModal-doneBtn-primary{
	font-size: 19px;
	font-weight: $lato-heavy;
	letter-spacing: 0.03em;
	color: $white;

	margin-bottom: 3px;
}

.editor-editSoundModal-doneBtn-sub{

	display: flex;
	align-items: center;
	justify-content: center;

	opacity: 0;
	transition: opacity 250ms linear;
	//transition-delay: 500ms;
}

button.editor-editSoundModal-doneBtn:hover 
.editor-editSoundModal-doneBtn-sub{
	opacity: 1;
	transition: opacity 50ms linear;
}

.editor-editSoundModal-doneBtn-sub-key{
	position: absolute;
	bottom: 5px;

	padding-left: 7px;
	padding-right: 6px;
	padding-top: 3px;
	padding-bottom: 4px;
	background: rgba(0,0,50,0.05);

	color: $white;
	font-weight: $lato-bold;
	font-size: 12px;

	letter-spacing: 0.03em;

	border-radius: 3px;

	display: flex;
	align-items: center;
	justify-content: center;

	opacity: 0;
	transition: opacity linear 50ms;
}

.editor-editSoundModal-doneBtn-sub-key svg{
	height: 9px;
	width: 9px;

	margin-left: 3px;
	margin-right: 2px;

	fill: $white;

	position: relative;
	top: 1px;
}

.editor-editSoundModal-doneBtn:hover
.editor-editSoundModal-doneBtn-sub-key{	
	opacity: 1;
	transition: opacity linear 50ms;
}


a.editor-editSoundModal-footer-helpBtn{
	position: absolute;
	right: 20px;
	top: 50%;
	transform: translateY(-50%);

	width: 200px;
	
	
	height: 32px;
	
	color: $textBlack;
	margin-bottom: 8px;

	text-align: center;
	opacity: 0.6;
	background: none;

	border-radius: 4px;

	font-size: 13px;
	letter-spacing: 0.025em;

	font-weight: $lato-semibold;

	display: flex;
	align-items: center;
	justify-content: center;

	transition: opacity 50ms linear;

	user-select: none;

	// background: $tempr;
}

.editor-editSoundModal-footer:hover
a.editor-editSoundModal-footer-helpBtn{
	opacity: 0.8;
}

.editor-editSoundModal-footer
a.editor-editSoundModal-footer-helpBtn:hover{
	background: rgba(10,10,70,0.04);
	opacity: 1;
	
	// text-decoration: underline !important;
}


/// BACK BUTTON
button.editor-editSoundModal-backBtnContainer{
	position: fixed;
	top: 0px;
	left: 0px;
	background: $tempr;

	display: flex;
	align-items: center;
	justify-content: center;

	padding-top: 5px;
	padding-bottom: 10px;
	padding-left: 8px;
	padding-right: 8px;

	border: none;
	background: none;
	outline: none;

	// background: $tempr;
	z-index: 100;

	cursor: default;
}

.editor-editSoundModal-rerecordTipContainer{
	position: fixed;
	bottom: 0px;
	left: 0px;
	right: 0px;
	padding-top: 10px;

	height: calc((100vh - 527px) / 2);

	// background: $tempr;

	display: flex;
	align-items: flex-start;
	justify-content: center;
	

	box-sizing: border-box;

	z-index: 100;
}

.editor-editSoundModal-backBtn,
.editor-editSoundModal-rerecordTip{
	
	background: $gray-2;

	padding-top: 10px;
	padding-bottom: 10px;
	padding-left: 14px;
	padding-right: 14px;

	color: $white;

	opacity: 1;
	font-size: 13.5px;
	font-weight: $lato-semibold;
	letter-spacing: 0.01em;

	display: flex;
	align-items: center;
	justify-content: center;

	cursor: pointer;

	border-radius: 5px;

	opacity: 0.9;
	transition: opacity 50ms linear;

}

button.editor-editSoundModal-backBtnContainer:hover
.editor-editSoundModal-backBtn{
	opacity: 1;
	transition: opacity 50ms linear;
	font-weight: $lato-bold;
}

.editor-editSoundModal-backBtn svg.icon{
 	height: 10px;
 	width: 10px;

 	fill: $white;
 	margin-right: 5px;
 	margin-bottom: 1px;
 }

@keyframes editSoundModalBackBounce {
  from { transform: translateX(1px); }
  to { transform: translateX(-3px); }
}

.editor-editSoundModal-backBtn:hover svg.icon{
	 animation: editSoundModalBackBounce .4s infinite ease-in-out;
	 animation-direction: alternate;
}

.editor-editSoundModal-rerecordTip{
	opacity: 1;
	padding-top: 8px;
	padding-bottom: 8px;
}

.editor-editSoundModal-rerecordTip emph{
	font-weight: $lato-bold;
}





// //
// // OVERLENGTH TOOLTIP

// .editor-editSoundModal-overlengthTooltipContainer{
// 	width: 100%;
// 	// background: $tempb;

// 	flex-grow: 0;
// 	flex-shrink: 0;

// 	height: 17px;

// 	display: flex;
// 	align-items: center;
// 	justify-content: center;

// 	position: relative;
// 	top: -5px; // bit hacky, to preserve same btn formatting in quick record
// }

// .editor-editSoundModal-overlengthTooltip{
// 	font-size: 11.5px;
// 	font-weight: $lato-semibold;
// 	color: $textBlack;
// }

// .editor-editSoundModal-overlengthTooltip--hide{
// 	opacity: 0;
// }

// .editor-editSoundModal-overlengthTooltip--show{
// 	opacity: 1;
// }


//
// REFACTOR OUT

//
// QUICK RECORDING REVIEW


@import 'Editor-InsertEditSoundModals-RefactorOut';
