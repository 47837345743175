.publishingFlowModalModalConfetti-left,
.publishingFlowModalModalConfetti-right{
	position: fixed;
	top: calc(50vh + 200px);
	// top: 25vh;

	height: 50px;
	width: 50px;

	z-index: 100;
}

.publishingFlowModalModalConfetti-left{
	left: 0px;
}

.publishingFlowModalModalConfetti-right{	
	right: 0px;
}