//
// CONTROL BAR
// Scanning, Fwd and Backward buttons, etc.
// Layout of container is in parent scss
//

.syncLiveView-player-main-controlBarContainer--narrow{

}

.syncLiveView-player-main-controlBar{
	height: 56px;
	display: flex;
	
	width: 100%;

	align-items: center;
	justify-content: space-between;

	// background: $tempr;
}

.syncLiveView-player-main-controlBar-center{
	position: relative;
	// width: 80%;

	box-sizing: border-box;
	padding-left: 10px;
	padding-right: 10px;
	
	flex-grow: 1;
	flex-shrink: 1;
}

@import 'SyncLiveView-PlayerControlBar-QuestionControlButtons';
// next and previous




//
// SCAN BUTTON
//


button.syncLiveView-player-main-controlBar-scanBtn{
	height: 56px;
	border-radius: 7px;
	cursor: pointer;
	font-size: 16px;
	font-weight: $lato-heavy;
	letter-spacing: 0.02em;
	user-select: none;	
	position: absolute;
	top: 0px;
	left: 0px;
	right: 0px;
	bottom: 0px;
	max-width: 420px;
	margin: auto;
}

button.syncLiveView-player-main-controlBar-scanBtn--ask,
button.syncLiveView-player-main-controlBar-scanBtn--scan{
	width: 100%;
	border: 1px solid darken($lineMid, 5%);
	border-bottom: 1px solid darken($lineMid, 12%);
	background: $white;
	box-shadow: 0px 2px 4px 0px rgba(0,0,40,0.08);
	
}

button.syncLiveView-player-main-controlBar-scanBtn--scan{
	z-index: 5;
}

button.syncLiveView-player-main-controlBar-scanBtn--ask
.syncLiveView-player-main-controlBar-scanBtn-primary,
button.syncLiveView-player-main-controlBar-scanBtn--scan
.syncLiveView-player-main-controlBar-scanBtn-primary{
	color: $textBlackDark;
	margin-top: 2px;
}

// Keyboard Prompt
button.syncLiveView-player-main-controlBar-scanBtn--ask
.syncLiveView-player-main-controlBar-scanBtn-secondary{
	font-size: 10.5px;
	font-weight: $lato-bold;
	color: $textBlue;
	margin-top: 2px;
}

// Live Graph
button.syncLiveView-player-main-controlBar-scanBtn--scan
.syncLiveView-player-main-controlBar-scanBtn--scan-graph{
	width: 80%;
	height: 3px;
	margin: 0 auto;
	border-radius: 2px;
	overflow: hidden;
	margin-top: 6px;
	position: relative;
}

button.syncLiveView-player-main-controlBar-scanBtn--scan
.syncLiveView-player-main-controlBar-scanBtn--scan-graph-bar,
button.syncLiveView-player-main-controlBar-scanBtn--scan
.syncLiveView-player-main-controlBar-scanBtn--scan-graph-track{
	height: 100%;
	position: absolute;
	top: 0px;
	left: 0px;
	bottom: 0px;
}

button.syncLiveView-player-main-controlBar-scanBtn--scan
.syncLiveView-player-main-controlBar-scanBtn--scan-graph-bar{
	background: $blue;
	z-index: 5;
}

button.syncLiveView-player-main-controlBar-scanBtn--scan
.syncLiveView-player-main-controlBar-scanBtn--scan-graph-track{
	background: $blue-extraLight;
	width: 100%;
}


button.syncLiveView-player-main-controlBar-scanBtn--ask--show,
button.syncLiveView-player-main-controlBar-scanBtn--scan--show,
.syncLiveView-player-main-controlBar-reviewContainer--show{
	opacity: 1;
	transform: translateY(0px);
	transition: 200ms cubic-bezier(.2, .3, .25, .9);
	transition-delay: 100ms;
}

button.syncLiveView-player-main-controlBar-scanBtn--ask--hide,
button.syncLiveView-player-main-controlBar-scanBtn--scan--hide,
.syncLiveView-player-main-controlBar-reviewContainer--hide{
	opacity: 0;
	transform: translateY(5px);
	transition: 250ms cubic-bezier(.2, .3, .25, .9);
	pointer-events: none;
}

button.syncLiveView-player-main-controlBar-scanBtn--ask--show{
	transition: 0ms linear;
}

.syncLiveView-player-main-controlBar-reviewContainer--hide{
	transition: 0ms linear;
}

// button.syncLiveView-player-main-controlBar-scanBtn--ask:hover,
// button.syncLiveView-player-main-controlBar-scanBtn--scan:hover{
// 	border: 1px solid darken($lineMid, 8%);
// 	border-bottom: 1px solid darken($lineMid, 14%);
// 	box-shadow: 0px 2px 4px 0px rgba(0,0,40,0.12);
// }

// button.syncLiveView-player-main-controlBar-scanBtn--ask:active,
// button.syncLiveView-player-main-controlBar-scanBtn--scan:active{
// 	border: 1px solid darken($lineMid, 12%);
// 	box-shadow: none;
// 	background: rgb(252,252,255);
// }


@import 'SyncLiveView-PlayerControlBar-Review';

//
// MEDIA INSTRUCTIONS MESSAGE
// tells teachers that students play/pause on their devices

button.syncLiveView-player-main-controlBar-mediaInstructionsMessage{
	position: absolute;
	left: 50%;
	transform: translateX(-50%);

	// top: -6px;
	bottom: 82px;

	height: 26px;
	padding-left: 10px;
	padding-right: 10px;
	
	background: $tempr;
	margin: auto;

	display: flex;
	align-items: center;
	justify-content: center;

	white-space: nowrap;

	font-size: 13.5px;
	font-weight: $lato-semibold;
	color: $textBlack;

	border: none;
	box-shadow: none;

	border-radius: 4px;
	background: $gray-3;
	color: $white;

	cursor: pointer;

	// border: 1px solid $lineMid;
	// border-bottom: 1px solid $lineDark;
	// box-shadow: 0px 1px 2px 0px rgba(0,0,0,0.08);
}

.syncLiveView-player-main-controlBar-mediaInstructionsMessage-message{

}

.syncLiveView-player-main-controlBar-mediaInstructionsMessage-confirmBtn{
	margin-left: 8px;

	box-shadow: none;
	border: none;

	background: $blue;
	color: $white;
	font-weight: $lato-bold;

	background: none;

	border-radius: 3px;
	box-shadow: none;

	opacity: 0.5;
	transition: opacity 50ms linear;
}

button.syncLiveView-player-main-controlBar-mediaInstructionsMessage:hover
.syncLiveView-player-main-controlBar-mediaInstructionsMessage-confirmBtn{
	opacity: 1;
	transition: opacity 50ms linear;
}

button.syncLiveView-player-main-controlBar-mediaInstructionsMessage:hover,
button.syncLiveView-player-main-controlBar-mediaInstructionsMessage:focus{
	outline: none;
	box-shadow: none;
	border: none;
}