.ItemDetailHistoryList{
	height: 100%;
	width: 100%;
	position: relative;
}

.ItemDetailHistoryList-header{
	font-size: 16px;
	font-weight: $lato-bold;
	color: $textDark;
	letter-spacing: 0.02em;
	text-transform: uppercase;
	margin-bottom: 10px;
	margin-top: 55px;
	cursor: default;
}

.ItemDetailHistoryList-list{
	position: absolute;
	top: 30px;
	bottom: 0px;
	left: 0px;
	right: 0px;
}

.ItemDetailHistoryList-item{
	width: 100%;
	max-width: 290px;
	position: relative;
	padding-right: 25px; // space for scrollbar and arrow
	box-sizing: border-box;

	padding-top: 10px;
	padding-bottom: 10px;
}

.ItemDetailHistoryList a:last-child .ItemDetailHistoryList-item{
	margin-bottom: 100px;
}

.ItemDetailHistoryList:hover
.ItemDetailHistoryList-item{
	opacity: 0.75;
	transition: opacity 150ms ease-in-out;
}

.ItemDetailHistoryList
.ItemDetailHistoryList-item:hover{
	opacity: 1;
	transition: opacity 50ms ease-in-out;
}

.ItemDetailHistoryList-item-topRow{
	display: flex;
	align-items: center;
}

.ItemDetailHistoryList-item .sectionMarker{
	font-size: 15px;
	font-weight: $lato-bold;
	color: $textBlack;
	width: 10px;
	flex-grow: 1;
	
	white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}


.ItemDetailHistoryList-item .sectionMarker .icon{
	width: 8px;
	height: 8px;
	margin-right: 5px;
	position: relative;
	top: -2px;

}

.ItemDetailHistoryList-item-timestamp{
	margin-top: 2px;
	font-size: 13px;
	font-weight: $lato-medium;
	color: $textLight;
	margin-left: 12px;
}

.ItemDetailHistoryList-item .scorePill{
	flex-shrink: 0px;
	margin-left: auto;
	//transform: scale(0.9);
}


.ItemDetailHistoryList-item-rightArrow{
	position: absolute;
	top: 11px;
	right: 10px; // make space for scrollbar
	opacity: 0.2;
}

.ItemDetailHistoryList-item:hover 
.ItemDetailHistoryList-item-rightArrow{	
	opacity: 1;
}

.ItemDetailHistoryList-item-rightArrow .icon{
	width: 12px;
	height: 12px;
	fill: $textMid;
}