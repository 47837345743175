

// ACCOUNT FORM MODAL LAYOUTS

.update-name .modal-content,
.update-email .modal-content,
.change-password .modal-content{
  height: auto;
  width: 500px;
}

.delete-account .modal-content{
  height: auto;
  width: 600px;
}

.update-name .modal-content form,
.update-email .modal-content form,
.change-password .modal-content form,
.delete-account .modal-content form{
  width: 80%;
  margin: 0 auto;
  margin-top: 24px;
}


.accountForm-checkbox{
  margin-top: -6px;
  margin-bottom: 16px;
}

.accountForm-checkbox .featureModal-selectControl-label{
  font-size: 14px;
  font-weight: $lato-semibold;
  letter-spacing: 0.005em;
  margin-top: 1px;
}

.accountForm-actionRow{
  display: flex;
  margin-bottom: 40px;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.accountForm-actionRow .btn{
  width: 60%;
  position: relative;
}

.accountForm-error{
  width: 80%;
  margin: 0 auto;
  text-align: center;
  margin-top: -20px;
  margin-bottom: 20px;
  color: $red-dark;
  font-size: 15px;
  font-weight: $lato-semibold;
}

.accountForm-error--recaptcha div{
  margin-top: 10px;
}
