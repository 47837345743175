//
// "CLASSIC"/REDUX MODAL WRAPPER
// there are also featureModals.scss (used for e.g. printing)
// there are also confirmModals.scss (confirmation modals when e.g. deleting something)
// sometimes there are also custom modals in pages
//

.modal-backdrop {
	position: fixed;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;
	z-index: 9998;

	display: flex;

	align-items: center;
	justify-content: center;
}

.modal-backdrop.is--dark{
	background: rgba(0, 0, 0, 0.5);
}

.modal-backdrop.is--light{
	background: rgba(255, 255, 255, 0.75);   
}

//

.modal-backdrop .icon.close{
	position: fixed;
	top: 24px;
	right: 24px;    
	
	width: 24px;
	height: 24px;
	
	pointer-events: none;
}

.modal-backdrop.is--light .icon.close{
	fill: $textLight;
}

.modal-backdrop.is--dark .icon.close{
	fill: $white;
}

//

.modal-content {
	width: 500px; // often customized per modal
	height: 500px; //
	box-sizing: border-box;

	z-index: 9999;
	background: rgb(252,252,255);

	border-radius: 3px;
	border: 1px solid rgb(204,204, 216);
	box-shadow: 0px 0px 2px 0px rgba(0,0,0,0.12), 0px 3px 4px 0px rgba(0,0,0,0.06);
}

$height-modalContent-header: 46px;

.modal-content-header {
	width: 100%;
	height: 46px;
	background: $white;

	position: relative;

	box-sizing: border-box;
 
	border-top-left-radius: 3px;
	border-top-right-radius: 3px;

	box-shadow: 0px 1px 1px 0px rgba(184,188,195,0.50), 0px 2px 1px 0px rgba(0,0,0,0.05);
}

.modal-content-header .btn.cancel{
	position: absolute;
	right: 20px;
	top: 8px;
	min-width: 87px;
}

.modal-content-header-center{
	display: flex;
	height: 100%;
	width: 100%;
	align-items: center;
	justify-content: center;
}

.modal-content-header-center .icon{
	margin-right: calc(-115px + 10px); // messy, refactor
	height: 20px;
	width: 20px;
	fill: $blue;
	flex-shrink: 0;
}

.modal-content-header-title{	
	font-weight: $lato-heavy;
	font-size: 20px;
	color: $textDark;

	// Prevent overflow
	box-sizing: border-box;
	padding-left: 115px; // account for cancel button
	padding-right: 115px; // account for cancel button
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;

	cursor: default;    
}

// IS EMPTY

.modal-backdrop.is--empty{
	background: $invisible;
}

.modal-backdrop.is--empty .modal-content-header{
	display: none;
}

.modal-backdrop.is--empty .icon.close{
	display: none;
}