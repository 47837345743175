//
// SCORESHEET SIDEPANEL
// this contains the class/section list and calendar
//

.scoreSheet-sidePanel{
	position: absolute;
	top: $height-scoreSheetTopbar;
	bottom: 0px;
	left: 0px;
	width: $width-scoreSheetSidePanel;

	display: flex;
	flex-direction: column;
	
	background: rgb(245,244,251);

	box-sizing: border-box;
	border-right: 1px solid $lineDark;
	box-shadow: 1px 0px 2px rgba(0,0,40,0.08);
}

.scoreSheet-sidePanel-sectionSelection{
	width: 100%;
	flex-grow: 1;
	flex-shrink: 1;

	overflow-y: auto;
}

//
// SECTION SELECTION
// uses the sectionMarker component for class color styling

$height-scoreSheet-sidePanel-sectionSelection-section: 28px;

.scoreSheet-sidePanel-sectionSelection-header{
	width: 100%;
	font-size: 14px;
	font-weight: $lato-bold;
	color: $textLight;
	margin-top: 12px;
	margin-bottom: 8px;
	padding-left: 16px;
	box-sizing: border-box;
	user-select: none;
	cursor: default;
}

.scoreSheet-sidePanel-sectionSelection-section{
	position: relative;
	cursor: pointer;
	height: $height-scoreSheet-sidePanel-sectionSelection-section;
	width: 100%;

	font-size: 15px;
	font-weight: $lato-semibold;

	overflow: hidden;
	user-select: none;
}

.scoreSheet-sidePanel-sectionSelection-section--inactive
.sectionMarker.is--circle{
	color: $textBlack;
}

.scoreSheet-sidePanel-sectionSelection-section--inactive:hover
.sectionMarker.is--circle{
	color: $textBlack;
}

.scoreSheet-sidePanel-sectionSelection-section--inactive:hover{	
	background: $gray-128;
}


.scoreSheet-sidePanel-sectionSelection-section 
.sectionMarker.is--circle .icon.circle{
	height: 10px;
	width: 10px;
	margin-right: 10px;
}

.scoreSheet-sidePanel-sectionSelection-section
.sectionMarker.is--circle{	
	width: 100%;	
	height: $height-scoreSheet-sidePanel-sectionSelection-section;
	position: absolute;

	padding-right: 10px;
	
	box-sizing: border-box;
	padding-left: 15px;
	z-index: 1;

	line-height: 1.8;

	white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.scoreSheet-sidePanel-sectionSelection-section--active 
.sectionMarker.is--circle{
	opacity: 1;
	color: $white;
}

.scoreSheet-sidePanel-sectionSelection-section--active .sectionMarker.is--circle .icon.circle{
	fill: $black;
	opacity: 0.25;
}

.scoreSheet-sidePanel-sectionSelection-section .sectionMarker.is--colorOnly{
	// we change the color marker to be the full size of the cell
	// 
	position: absolute;
	top: 0px;
	bottom: 0px;
	left: 0px;
	right: 0px;
	z-index: 0;
	opacity: 0;
}

.scoreSheet-sidePanel-sectionSelection-section--active .sectionMarker.is--colorOnly{
	opacity: 1;
}


//
// CALENDAR

.scoreSheetSidePanel-calendar{
	flex-shrink: 0;
	height: $height-scoreSheetSidePanelCalendar;
	
	width: calc(#{$width-scoreSheetSidePanel} - 1px);

	box-sizing: border-box;	

	border-top: 1px solid $lineDark;
	background: $white;

	overflow: hidden;

	position: relative;
}

.scoreSheetSidePanel-calendarInnerContainer{
	position: absolute;
	top: 0px;
	bottom: 0px;
	width: 242px;
	left: -15px;
}

@import 'ScoreSheet-SidePanel-Calendar';