//
// HELP VIDEO THUMBNAIL
// Used in a few places, e.g. empty pack state and import questions help
//

.helpVideoThumbnail{
	width: 184px;
	height: 100px;
	background: $white;
	border: 1px solid $lineDark;

	box-sizing: border-box;
	padding-left: 22px;
	padding-top: 36px;

	border-radius: 2px;
	box-shadow: 0px 1px 2px 0px rgba(0,0,0,0.04),
							0px 5px 10px -5px rgba(0,0,0,0.1);

	user-select: none;
	cursor: default;

	position: relative;
}

.helpVideoThumbnail-plickersLogo svg{
	position: absolute;
	left: 22px;
	top: 14px;
	width: 11px;
	height: 11px;
}

.helpVideoThumbnail-title{
	font-size: 8.5px;
	font-family: 'Effra'; // here we force effra
  -webkit-font-smoothing: antialiased;
  font-weight: $effra-bold;

  color: $textBlack;

  width: 64%; // may be better to pass in <br/> in future
}