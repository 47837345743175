
.repoRelease-visual-cloudBGContainer{
	z-index: 0;
	top: 0px;
	left: 0px;
	right: 0px;

	display: flex;
	align-items: center;
}

.repoRelease-visual-cloudBGContainer svg{
	margin: 0 auto;
	fill: rgb(241,247,255)	
}

.repoReleaseModal-visual--device{
	position: absolute;

	top: 16px;
	left: 50%;
	transform: translateX(-50%);

	width: 820px;
	height: 600px;
	
	background: $tempr;	

	border-radius: 10px;

	background: $white;
	border: 1px solid $gray-12;

	z-index: 10;
}

.repoReleaseModal-visual--screen{
	
	position: absolute;
	top: 28px; // distance from top of device to screen
	left: 19px; // distance from sides of device to screen
	right: 19px;
	bottom: 0px;

	border: 1px solid $gray-115;

	background: white;

	overflow: hidden;

	border-radius: 3px;

}

///

$repoReleaseModal-visual-screenWidth: 782px; // hardcoded for now

$repoReleaseModal-visual-topBarHeight: 32px;

$repoReleaseModal-visual-sideNavWidth: 107px;
$repoReleaseModal-visual-pageWidth: 504px;
$repoReleaseModal-visual-rightPanelWidth: calc(#{$repoReleaseModal-visual-screenWidth} - #{$repoReleaseModal-visual-sideNavWidth} - #{$repoReleaseModal-visual-pageWidth});

$repoReleaseModal-visual-bgColor: rgb(246,246,249);

.repoReleaseModal-visual-topBar{	
	position: absolute;
	left: 0px;
	right: 0px;
	height: $repoReleaseModal-visual-topBarHeight;
	background: rgb(240,240,245);
	border-bottom: 1px solid $lineDark;
	box-shadow: 0px 2px 4px 0px rgba(0,0,40,0.05);
	box-sizing: border-box;
	z-index: 10;
}

.repoReleaseModal-visual-sideNav,
.repoReleaseModal-visual-page,
.repoReleaseModal-visual-rightPanel{
	position: absolute;
	top: $repoReleaseModal-visual-topBarHeight;
	bottom: 0px;
	box-sizing: border-box;
}

.repoReleaseModal-visual-sideNav{
	
	left: 0px;
	width: $repoReleaseModal-visual-sideNavWidth;

	background: $repoReleaseModal-visual-bgColor;
	border-right: 1px solid $lineDark;
	z-index: 5;
}

.repoReleaseModal-visual-page{	
	left: $repoReleaseModal-visual-sideNavWidth;	
	width: $repoReleaseModal-visual-pageWidth;
	position: relative;
	

	// background: $tempg;
}

.repoReleaseModal-visual-rightPanel{ 	
 	width: $repoReleaseModal-visual-rightPanelWidth;
 	right: 0px;

 	border-left: 1px solid $lineDark;
 	
 	background: $repoReleaseModal-visual-bgColor;
 	z-index: 5;
}

$repoReleaseModal-visual-pageHeaderHeight: 150px;

@import 'RepoReleaseModal-Visual-TopBar';
@import 'RepoReleaseModal-Visual-SideNav';
@import 'RepoReleaseModal-Visual-PageHeader';
@import 'RepoReleaseModal-Visual-PageTable';
@import 'RepoReleaseModal-Visual-RightPanel';
