//
// DETAIL PANEL
//

.packReview-detailPanel-header{
	padding-bottom: 14px;
	border-bottom: 1px solid $gray-125;

	display: flex;

	cursor: default;

	position: relative;
}

.packReview-detailPanel-header--left{
	flex-grow: 1;
	flex-shrink: 1;
}

.packReview-detailPanel-header--right{
	flex-grow: 0;
	flex-shrink: 0;

	position: relative;
}

.packReview-detailPanel-header-repoName{
	font-size: 23px;
	font-weight: $lato-heavy;
	color: $textBlack;

	margin-bottom: 5px;
}

.packReview-detailPanel-header-submissionID{
	position: absolute;
	top: 5px;
	left: -47px;
	user-select: none;
	cursor: default;
	width: 40px;
	text-align: right;
	font-size: 18px;
	font-weight: $lato-heavy;

	color: lighten($textLight, 20%);

}

// 

.packReview-detailPanel-header-author{
	display: flex;
	align-items: baseline;
}

a.packReview-detailPanel-header-author-name,
a.packReview-detailPanel-header-author-name:visited{
	font-size: 15.5px;
	font-weight: $lato-bold;
	color: $textBlack;
	margin-right: 5px;
}

a.packReview-detailPanel-header-author-name:hover{
	color: $blue-bold;
	text-decoration: underline !important;
}

a.packReview-detailPanel-header-author-email,
a.packReview-detailPanel-header-author-email:visited{
	font-size: 15px;
	font-weight: $lato-semibold;
	color: $textLight;
	margin-bottom: 4px;
}

a.packReview-detailPanel-header-author-email:hover{
	color: $textDark;
	text-decoration: underline !important;
}

//

.packReview-detailPanel-header-metadata{
	font-size: 14.5px;
	font-weight: $lato-semibold;
	color: $textBlack;
	margin-bottom: 8px;
}

a.packReview-detailPanel-header-url,
a.packReview-detailPanel-header-url:visited,
.packReview-detailPanel-header-url--noLink{
	display: block;

	margin-left: -20px;
	padding-left: 20px;
	
	padding-top: 2.5px;
	padding-bottom: 2.5px;
	padding-right: 5px;
	// background: $tempg;

	position: relative;

	font-size: 14px;
	font-weight: $lato-semibold;
	color: $blue-bold;

	cursor: pointer;
}

a.packReview-detailPanel-header-url--authorPage,
a.packReview-detailPanel-header-url--authorPage:visited,
a.packReview-detailPanel-header-url--adminConsoleURL,
a.packReview-detailPanel-header-url--adminConsoleURL:visited{
	color: $textLight;
}

a.packReview-detailPanel-header-url:hover{
	color: darken($blue-bold, 5%);
	text-decoration: underline !important;
}

a.packReview-detailPanel-header-url--authorPage:hover,
a.packReview-detailPanel-header-url--adminConsoleURL:hover{
	color: $textMid;
	text-decoration: none !important;
}

.packReview-detailPanel-header-url--noLink{
	color: lighten($textLight, 10%);
	cursor: default;
}


.packReview-detailPanel-header-url-icon{
	position: absolute;
	top: 3px;
	left: 4px;
}

a.packReview-detailPanel-header-url--frontID
.packReview-detailPanel-header-url-icon{
	top: 4px;
}

.packReview-detailPanel-header-url-icon svg{
	height: 10px;
	width: 10px;
	fill: $blue-bold;
	opacity: 0.5;
}

// SUBMISSION



.packReview-detailPanel-header-status{
	
	background: $blue-bold;
	color: $white;
	font-weight: $lato-semibold;
	text-transform: uppercase;
	font-size: 13.5px;
	letter-spacing: 0.02em;

	padding-left: 7px;
	padding-right: 7px;
	padding-top: 4px;
	padding-bottom: 4px;

	border-radius: 3px;


	flex-grow: 0;
	flex-shrink: 0;
}


.packReview-detailPanel-header-status--in-review{
	background: $blue;
}

.packReview-detailPanel-header-status--hidden{
	background: $gray-7;
}

.packReview-detailPanel-header-status--approved{
	background: $green;
}


// ASSIGNEE

.packReview-detailPanel-header-assignee{
	position: absolute;
	bottom: 14px;
	right: 0px;
	width: auto;

	border: 1px solid $gray-125;
	color: lighten($textMid, 15%);
	border-radius: 4px;
	padding-top: 2px;
	padding-bottom: 2px;
	padding-right: 5px;
	padding-left: 5px;

	font-size: 12px;
	text-transform: uppercase;
	font-weight: $lato-bold;
	letter-spacing: 0.01em;
}


//
// ACTION AREA
//

// ACTION AREA HEADER

.packReview-detailPanel-actionArea{
	padding-top: 20px;
}

.packReview-detailPanel-actionArea-header{
	position: relative;
	cursor: default;
	padding-bottom: 10px;
}
.packReview-detailPanel-actionArea-header-nextSteps,
.packReview-detailPanel-actionArea-header-label{
	font-size: 13.5px;
	font-weight: $lato-bold;
	text-transform: uppercase;
	color: $textBlack;
}

.packReview-detailPanel-actionArea-header-nextSteps{
	margin-bottom: 2px;
}

.packReview-detailPanel-actionArea-header-label{
	margin-bottom: 5px;	
}

.packReview-detailPanel-actionArea-header-description{
	font-size: 13px;
	font-weight: $lato-medium;
	color: $textDark;
}

.packReview-detailPanel-actionArea-header-nextSteps{
	color: $textLight;	
}

.packReview-detailPanel-actionArea--submitted .packReview-detailPanel-actionArea-header-nextSteps,
.packReview-detailPanel-actionArea--in-review-pre-contact .packReview-detailPanel-actionArea-header-nextSteps{
	color: $blue;
}



.packReview-detailPanel-actionArea-header-icon{
	position: absolute;
	top: 14px;
	left: -16px;
}

.packReview-detailPanel-actionArea-header-icon svg{
	width: 9px;
	height: 9px;
	fill: $red--notification;
}

.packReview-detailPanel-actionArea--in-review-post-contact .packReview-detailPanel-actionArea-header-icon,
.packReview-detailPanel-actionArea--cancelled .packReview-detailPanel-actionArea-header-icon,
.packReview-detailPanel-actionArea--withdrawn .packReview-detailPanel-actionArea-header-icon,
.packReview-detailPanel-actionArea--approved .packReview-detailPanel-actionArea-header-icon{
	display: none;
}

//
// ACTION AREA
//
.packReview-detailPanel-actionArea{
	position: relative;
}

// SELECT CONTROL

.packReview-detailPanel-actionArea
.featureModal-selectControl{
	border-radius: 3px;
	padding-left: 5px;
	padding-right: 5px;
	width: 220px;
	cursor: pointer;
	padding-top: 2px;

	// margin-bottom: 4px;
	// background: $tempr;
}

.packReview-detailPanel-actionArea
.featureModal-selectControl-checkbox{
	margin-top: 4px;
	margin-right: 6px;
}

.packReview-detailPanel-actionArea
.featureModal-selectControl-radio{
	margin-top: 4px;
	margin-right: 6px;
	height: 14px;
	width: 14px;

}


.packReview-detailPanel-actionArea
.featureModal-selectControl:hover{
	background: transparentize($blue-bold, 0.95);
}

.packReview-detailPanel-actionArea
.featureModal-selectControl-label{
	font-size: 13px;
	font-weight: $lato-bold;
	letter-spacing: 0em;
	color: $textBlack;
	cursor: pointer;

	margin-top: 4px;
}


.packReview-detailPanel-actionArea-emailTemplater-controls-template--hasTemplate
.featureModal-selectControl--inactive{
	opacity: 0.5;
}

.packReview-detailPanel-actionArea-emailTemplater-controls-template--hasTemplate
.featureModal-selectControl--inactive:hover{
	opacity: 1;
}


//
// GENERAL BUTTON
//

button.packReview-detailPanel-actionArea-btn{
	font-size: 13px;
	font-weight: $lato-semibold;
	color: $textBlack;	

	display: flex;
	align-items: center;
	justify-content: center;

	height: 30px;

	padding-left: 12px;
	padding-right: 12px;

	box-shadow: none;
	border: 1px solid $lineDark;
	background: $white;
	border-radius: 3px;

	cursor: pointer;
}

button.packReview-detailPanel-actionArea-btn b{
	font-weight: $lato-bold;
}

button.packReview-detailPanel-actionArea-btn:hover{
	border-bottom: 1px solid $gray-1125;
	box-shadow: 0px 2px 4px 0px rgba(0,0,50,0.02);
}

button.packReview-detailPanel-actionArea-btn--disabled{
	opacity: 0.5;
	pointer-events: none;
}

.packReview-detailPanel-actionArea--submitted
button.packReview-detailPanel-actionArea-btn{
	margin-top: 10px;
}


///
/// EMAIL TEMPLATER
///

.packReview-detailPanel-actionArea-emailTemplater{
	display: flex;
}

.packReview-detailPanel-actionArea-emailTemplater-controls{
	flex-grow: 0;
	flex-shrink: 0;
	padding-right: 25px;
	height: 480px;
	position: relative;
}

.packReview-detailPanel-actionArea-emailTemplater-previewContainer{
	flex-grow: 1;
	flex-shrink: 1;
	padding-right: 40px;
}

.packReview-detailPanel-actionArea-emailTemplater-controls-guideline{
	margin-top: 14px;
}

.packReview-detailPanel-actionArea-emailTemplater-controls-template-title,
.packReview-detailPanel-actionArea-emailTemplater-controls-guideline-title{
	font-size: 12px;
	font-weight: $lato-semibold;
	text-transform: uppercase;
	color: $textMid;
	margin-bottom: 4px;
	margin-left: 25px;

	cursor: default;
	user-select: none;
}

button.packReview-detailPanel-actionArea-btn--frontButton{
	position: absolute;
	bottom: 10px;
	width: 185px;
	left: 0px;
}
button.packReview-detailPanel-actionArea-btn--frontButton svg{
	height: 15px;
	width: 15px;
	margin-right: 5px;
	fill: $blue-bold;
}

button.packReview-detailPanel-actionArea-btn--disabled svg{
	fill: $gray-1125;
}

//
// EMAIL PREVIEW
//

.packReview-detailPanel-actionArea-emailTemplater-preview{
	border-left: 1px solid $gray-13;
	padding-left: 30px;
	height: 100%;
}

.packReview-detailPanel-actionArea-emailTemplater-preview-title{
	font-size: 12px;
	font-weight: $lato-semibold;
	text-transform: uppercase;
	color: lighten($textLight, 5%);
	margin-bottom: 4px;
	user-select: none;
}

.packReview-detailPanel-actionArea-emailTemplater-preview-section{
	padding-top: 6px;
	padding-bottom: 6px;
	cursor: default;
}

.packReview-detailPanel-actionArea-emailTemplater-preview-section-label{
	font-size: 10px;
	font-weight: $lato-bold;
	color: lighten($textLight, 20%);
	user-select: none;
	text-transform: uppercase;
	padding-bottom: 4px;
}

.packReview-detailPanel-actionArea-emailTemplater-preview-section--subject{
	padding-bottom: 0px;
}

.packReview-detailPanel-actionArea-emailTemplater-preview-section--body{
	padding-top: 0px;
}

.packReview-detailPanel-actionArea-emailTemplater-preview-section--body
.packReview-detailPanel-actionArea-emailTemplater-preview-section-label{
	margin-top: 15px;
}


.packReview-detailPanel-actionArea-emailTemplater-preview-section--subject
.packReview-detailPanel-actionArea-emailTemplater-preview-section-contents{
	font-size: 13.5px;
	font-weight: $lato-bold;
	color: $textBlack;
}

.packReview-detailPanel-actionArea-emailTemplater-preview-section--body
.packReview-detailPanel-actionArea-emailTemplater-preview-section-contents{
	font-size: 13px;
	font-weight: $lato-medium;
	color: $textBlack;
}

.packReview-detailPanel-actionArea-emailTemplater-preview-section-contents
a {
	text-decoration: underline !important;
	color: $blue-bold !important;
} 


//
// EMAIL SENT CONTROLS
//

.packReview-detailPanel-actionArea-emailSentControls{
	position: absolute;
	top: 24px;
	right: 0px;
	// background: $tempr;
	//height: 100px;

	display: flex;
	align-items: center;
}

.packReview-detailPanel-actionArea-emailSentControls:hover button{
	opacity: 0.5;
}

.packReview-detailPanel-actionArea-emailSentControls:hover button:hover{
	opacity: 1;
}

.packReview-detailPanel-actionArea-emailSentControls button:first-child{
	border-top-right-radius: 0px;
	border-bottom-right-radius: 0px;
	margin-right: -1px;
}

.packReview-detailPanel-actionArea-emailSentControls button:last-child{
	border-top-left-radius: 0px;
	border-bottom-left-radius: 0px;
}

.packReview-detailPanel-actionArea-emailSentControls button:first-child:hover b{
	color: $blue-bold;
}

.packReview-detailPanel-actionArea-emailSentControls button:last-child:hover b{
	color: $red--notification;
}


//


.packReview-detailPanel-actionArea-buttonRowLabel{
	font-size: 13px;
	font-weight: $lato-semibold;
	color: $textDark;
}

.packReview-detailPanel-actionArea-buttonRow{
	margin-top: 10px;
	display: flex;

	width: 400px;
	align-items: center;
	justify-content: space-between;
}
