.fontSizeCalculationVisualizer-fieldLengthSelectPanel{
	display: flex;
	width: 100%;
}

.fontSizeCalculationVisualizer-fieldLengthSelectPanel-controlBox{
	width: calc(100% / 6);
	flex-grow: 0;
	flex-shrink: 0;
	border: 1px solid $invisible;
	border-right: 1px solid $lineDark;

	position: relative;


}

.fontSizeCalculationVisualizer-fieldLengthSelectPanel-controlBox-title{
	width: 100%;
	margin-top: 10px;
	text-align: center;
	font-size: 15px;
	font-weight: $lato-bold;
	letter-spacing: 0.02em;
	color: $textBlackDark;
	margin-bottom: 3px;

	pointer: default;
	user-select: none;
}

.fontSizeCalculationVisualizer-fieldLengthSelectPanel-controlBox:last-child{
	border-right: 1px solid $invisible;
}

button.fontSizeCalculationVisualizer-fieldLengthSelectPanel-controlBox-choiceHideBtn{
	position: absolute;
	top: 10px;
	right: 10px;
	width: 50px;
	height: 40px;

	border: 1px solid $lineDark;
	border-radius: 4px;
	font-size: 12.5px;
	background: $white;
	cursor: pointer;
	box-shadow: none;

	opacity: 0.5;
}

button.fontSizeCalculationVisualizer-fieldLengthSelectPanel-controlBox-choiceHideBtn:hover{
	opacity: 1;
}

.fontSizeCalculationVisualizer-fieldLengthSelectPanel-controlBox-value{
	width: 100%;
	text-align: center;
	pointer: default;
	user-select: none;
}

.fontSizeCalculationVisualizer-fieldLengthSelectPanel-controlBox-value-value{
	font-size: 16px;
	font-weight: $lato-heavy;
	color: $textBlackDark;
	margin-bottom: 0px;
	height: 18px;
}

.fontSizeCalculationVisualizer-fieldLengthSelectPanel-controlBox-value-label{
	font-size: 12px;
	font-weight: $lato-medium;
	color: $textMid;
}

.fontSizeCalculationVisualizer-fieldLengthSelectPanel-controlBox-sliderContainer{
	width: 80%;
	margin-left: 10%;
	height: 30px;
	//background: $tempr;
}

.fontSizeCalculationVisualizer-fieldLengthSelectPanel-controlBox-sliderContainer
.rc-slider{
	height: 30px;
}

.fontSizeCalculationVisualizer-fieldLengthSelectPanel-controlBox-sliderContainer
.rc-slider-track{
	background: $blue;
	top: 12px;
}

.fontSizeCalculationVisualizer-fieldLengthSelectPanel-controlBox-sliderContainer
.rc-slider-rail{
	background: transparentize($blue, 0.9);
	top: 12px;
}

.fontSizeCalculationVisualizer-fieldLengthSelectPanel-controlBox-sliderContainer
.rc-slider-handle{
	border: 2px solid $blue;
	margin-top: 3px;
	width: 12px;
	height: 12px;
}