//
// Publishing Flow Hgeader
//

.publishingFlowModal-header{
	width: 100%;
	// background: $tempr;
	display: flex;
	align-items: center;
	padding-top: 36px;
	opacity: 1;
}

//
// Fade out on success
//

.publishingFlowModal--success
.publishingFlowModal-header{
	opacity: 0;
	transition: opacity 200ms linear;
}


// Progress

.publishingFlowModal-header-progress{
	display: flex;
	cursor: default;
	user-select: none;
}

.publishingFlowModal-header-progress-title{
	font-size: 17px;
	position: relative;
}

.publishingFlowModal-header-progress-title-inactiveLabel{
	position: absolute;
	left: 0px;
	top: 0px;
}

.publishingFlowModal-header-progress-title-activeLabel{
	font-weight: $lato-heavy;
	color: $textBlue;
}

//

.publishingFlowModal-header-progress-title--active
.publishingFlowModal-header-progress-title-activeLabel{
	color: $textBlue;
}

.publishingFlowModal-header-progress-title--inactive
.publishingFlowModal-header-progress-title-activeLabel{
	color: $invisible;
}

//

.publishingFlowModal-header-progress-title--inactive
.publishingFlowModal-header-progress-title-inactiveLabel{
	font-weight: $lato-bold;
	color: $gray-9;
}

.publishingFlowModal-header-progress-title--active
.publishingFlowModal-header-progress-title-inactiveLabel{
	display: none;
}


//


.publishingFlowModal-header-progress-iconContainer{
	display: flex;
	align-items: center;
	justify-content: center;

	padding-left: 7px;
	padding-right: 7px;
}

.publishingFlowModal-header-progress-iconContainer svg.icon{
	height: 12px;
	width: 12px;
	position: relative;
	fill: $gray-12;
	top: 1px;
}

// Cancel

.publishingFlowModal-header button.btn.btn--mid.btn--white.cancel{
	margin-left: auto;
	background: none;
	border: none;
	box-shadow: none;

	font-size: 16px;
	font-weight: $lato-semibold;
	color: $textLight;

	letter-spacing: 0.005em;

	cursor: pointer;

	// background: $tempr;
	padding-right: 15px;
	padding-left: 15px;
	height: 36px;
	border-radius: 4px;

	margin-right: -15px;
}

.publishingFlowModal-header button.btn.btn--mid.btn--white.cancel:hover{
	background: $gray-14;
	border: none;
	box-shadow: none;
	color: $textDark;
}