.verifyPasswordToSignin-form .btn {
  cursor: pointer;
  margin: 20px auto 0px auto;
  width: 100%;
}

.verifyPasswordToSignin-form-note {
  font-size: 13px;
  color: $textMid;
  margin-top: 20px;
}

.verifyPasswordToSignin-form .btn.sign-in {
  margin-top: 10px;
}
