.discoverHome-upcoming{
	width: 100%;
	margin-top: 72px;
}

.discoverHome-upcoming-title{
	font-size: 16px;
	font-weight: $lato-bold;
	
	color: $textDark;
	letter-spacing: 0.02em;

	text-transform: uppercase;
	flex-grow: 1;
	user-select: none;

	margin-bottom: 7px;

	cursor: default;
	user-select: none;
}

.discoverHome-upcoming-subtitle{
	font-size: 14.5px;
	font-weight: $lato-medium;
	color: $textMid;

	cursor: default;
	user-select: none;
}

.discoverHome-upcoming-subjectsContainer{
	margin-top: 36px;

	display: flex;
	flex-wrap: wrap;
}

.discoverHome-upcoming-subject{
	width: calc(100% / 3);
	flex-grow: 0;
	flex-shrink: 0;

	box-sizing: border-box;

	padding-right: 24px;
	padding-bottom: 24px;

	user-select: none;
}

.discoverHome-upcoming-subject-title{
	font-size: 18px;
	font-weight: $lato-bold;
	color: $textBlack;
	letter-spacing: 0.01em;
	margin-bottom: 6px;

	cursor: default;
}

.discoverHome-upcoming-subject-marketsContainer{
	margin-left: -8px;
}

.discoverHome-upcoming-subject-market{
	padding-left: 8px;
	display: flex;
	align-items: center;
	height: 28px;
	font-size: 15.5px;
	font-weight: $lato-medium;
	color: $textBlack;
	letter-spacing: 0.005em;

	position: relative;

	border-radius: 4px;
	cursor: pointer;
}

// shift up more subjects
.discoverHome-upcoming-subject:last-child{	
	margin-top: -28px; // a bit hacky
}

.discoverHome-upcoming-subject-market--notSubscribed:hover{
	//background: transparentize($blue, 0.9);
	background: #EDF5FE; // sorry equivalent of above
}

.discoverHome-upcoming-subject-market--subscribed{
	font-weight: $lato-bold;
	letter-spacing: 0em;

}

.discoverHome-upcoming-subject-market--subscribed:hover{
	background: transparentize($green, 0.9);
}


.discoverHome-upcoming-subject-market-text{

	box-sizing: border-box;
	
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.discoverHome-upcoming-subject-market--notSubscribed{
	padding-right: 28px; // for narrow screens
}


button.discoverHome-upcoming-subject-market-subscribeBtn{
	height: 21px;

	border-radius: 3px;
	padding-left: 5px;
	padding-right: 5px;

	font-size: 13.5px;
	font-weight: $lato-semibold;
	letter-spacing: 0.005em;

	border: none;
	box-shadow: none;
	cursor: pointer;
}


button.discoverHome-upcoming-subject-market-subscribeBtn--notSubscribed{	
	position: absolute;
	right: 27px;
	
	background: none;
	color: darken($blue, 5%);
	font-weight: $lato-bold;

	opacity: 0;
	letter-spacing: 0em;

	padding-left: 5px;
	padding-right: 2px;
	
	background: #EDF5FE; // hacky, light blue, to cover up on narrow screen
}


button.discoverHome-upcoming-subject-market-subscribeBtn--notSubscribed svg{
	fill: $blue;
	width: 15px;
	height: 15px;

	margin-left: 3px;
	opacity: 1;
	position: relative;
	top: 2px;	
}



.discoverHome-upcoming-subject-market--notSubscribed:hover button.discoverHome-upcoming-subject-market-subscribeBtn{
	opacity: 1;
}

button.discoverHome-upcoming-subject-market-subscribeBtn--subscribed{
	background: $green;
	color: $white;

	margin-left: 8px;

	cursor: pointer;

	padding-left: 5px;
	padding-right: 7px;
}

button.discoverHome-upcoming-subject-market-subscribeBtn--subscribed svg{
	fill: $white;
	height: 11px;
	width: 11px;
	margin-right: 2px;
	opacity: 0.7;
	position: relative;
	top: 1px;	
}


.discoverHome-upcoming-subject-market-icon{
	position: absolute;
	right: 8px;
	height: 20px;
	width: 20px;

	display: flex;
	align-items: center;
	justify-content: center;
}

.discoverHome-upcoming-subject-market-icon svg{
	fill: $gray-12;
	width: 15px;
	height: 15px;
}

.discoverHome-upcoming-subject-market:hover
.discoverHome-upcoming-subject-market-icon svg{
	fill: $blue;
}


.discoverHome-upcoming-subject-market--subscribed{
	display: flex;
}