.repoInvitationModalContainer{
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 900;
	
	display: flex;
	align-items: center;
	justify-content: center;	
}

.repoInvitationModalContainer--BG{
	position: absolute;
	top: 0; left: 0; bottom: 0; right: 0;

	background: rgba(0,0,0,0.75);
	z-index: 0;
}

$repoInvitationModal-width: 525px;
.repoInvitationModal{
	width: $repoInvitationModal-width;

	position: relative;
	z-index: 5;

	border-radius: 8px;

	overflow: hidden;

	background: rgb(245,245,247);
	border: 1px solid $gray-125;
	box-shadow: 0px 8px 16px 0px rgba(0,0,10,0.12), 0px 24px 48px 0px rgba(0,0,10,0.08);
}

$repoInvitationModal-sidePadding: 28px;

//
// Header
//

.repoInvitationModal-header{
	width: 100%;
	background: rgb(245,245,247);

	border-bottom: 1px solid $lineDark;
	box-sizing: border-box;

	padding-left: $repoInvitationModal-sidePadding;
	padding-right: $repoInvitationModal-sidePadding;
	padding-top: 18px;
	padding-bottom: 12px;

	cursor: default;
}


.repoInvitationModal-header-label{
	font-size: 20px;
	font-weight: $lato-bold;
	color: $blue;
	margin-bottom: 2px;
}

.repoInvitationModal-header-repoName{
	font-size: 22px;
	font-weight: $lato-heavy;
	color: $textBlack;
	margin-bottom: 12px;	

	white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}


// change permissions
.repoInvitationModal-header-changePermissionNewMemberContainer{
	position: absolute;
	top: 16px;
	right: 20px;

}

// strip out styling
.repoInvitationModal-header-changePermissionNewMemberContainer button.btn.btn--mid.btn--white,
.repoInvitationModal-header-changePermissionNewMemberContainer button.btn.btn--mid.btn--white:hover,
.repoInvitationModal-header-changePermissionNewMemberContainer button.btn.btn--mid.btn--white:focus,
.repoInvitationModal-header-changePermissionNewMemberContainer button.btn.btn--mid.btn--white:active,{
	background: none;
	border: none;
	box-shadow: none;
}

.repoInvitationModal-header-changePermissionNewMemberContainer button.btn.btn--mid.btn--white .icon{
	fill: $gray-8;
}

.repoInvitationModal-header-changePermissionNewMemberContainer .dropdown{
	z-index: initial !important;
}

.repoInvitationModal-header-changePermissionNewMemberContainer .dropdownMenu{
	width: 240px;
	left: -210px;
	top: 28px;
}

// custom stuff, add bold
.repoInvitationModal-header-changePermissionNewMemberContainer .dropdownMenu-item.is--selectItem.is--active{
	font-weight: $lato-bold;
	color: $textBlue;
}

.repoInvitationModal-header-changePermissionNewMemberContainer .dropdownMenu-item.is--selectItem.is--active:hover{	
	color: $white;
}

.repoInvitationModal-header-changePermissionNewMemberContainer .dropdownMenu-item.is--selectItem.is--active
.dropdownMenu-item--multiline-sublabel{
	font-weight: $lato-medium; //inherit original
	color: transparentize($textMid, 0.1);
}

.repoInvitationModal-header-changePermissionNewMemberContainer .dropdownMenu-item.is--selectItem.is--active:hover
.dropdownMenu-item--multiline-sublabel{
	color: rgba(255,255,255,0.9); // inherit original
}





//
// Member List
//


.repoInvitationModal-membersList{
	width: 100%;	
	background: $white;
	overflow-x: hidden;
	overflow-y: auto;

	
	height: calc(100vh - 270px);
	max-height: 600px;
}






.repoInvitationModal-membersList-pendingDivider{
	width: calc(100% - 30px);
	margin-left: 30px;
	//background: $gray-15;
	font-size: 13px;
	font-weight: $lato-bold;
	//color: $textBlue;
	color: $textMid;

	letter-spacing: 0.03em;
	text-transform: uppercase;

	//padding-left: 30px;

	padding-top: 18px;

	border-top: 1px solid $gray-13;
	//border-bottom: 1px solid $gray-13;

	position: relative;


	user-select: none;
	cursor: default;
}



//
// Footer
//


.repoInvitationModal-footer{	
	width: 100%;		
	height: 74px;
	
	border-top: 1px solid $lineDark;
	background: rgb(245,245,247);
}


.repoInvitationModal-footer button.primarybutton{
	
	width: 80%;
	margin: 0 auto;

	margin-top: 10px;

	margin-bottom: 2px;
	font-weight: $lato-bold;	
}

.repoInvitationModal-footer-cancelBtn{
	
	font-size: 13.5px;
	font-weight: $lato-semibold;
	color: $textLight;

	width: 100px;
	margin: 0 auto;
	
	//background: $tempr;

	padding: 2px;
	cursor: pointer;
	text-align: center;

	user-select: none;
}

.repoInvitationModal-footer-cancelBtn:hover{
	color: $red;
}

// Changes to save alert in footer

.repoInvitationModal-footer-alerts{
	position: absolute;

	bottom: 80px;
	width: 330px; // don't cover up buttons on invitation row
	left: 50%;
	transform: translateX(-50%);

	//background: $tempr;


	display: flex;
	flex-direction: column;

	align-items: center;
}


.repoInvitationModal-footer-alerts-alert{

	border-radius: 3px;

	text-align: center;

	padding-left: 8px;
	padding-right: 8px;

	font-size: 12.5px;
	font-weight: $lato-semibold;
	letter-spacing: 0.002em;

	cursor: default;

	margin-top: 3px;
}



.repoInvitationModal-footer-alerts-alert--inviteToPlickers{
	background: white;
	//background: $blue-extraLight;
	background: #F9FCFF;
	border: 1px solid $blue;
	color: $textBlack;

	padding-top: 4px;
	padding-bottom: 4px;
}

.repoInvitationModal-footer-alerts-alert--inviteToPlickers
.repoInvitationModal-footer-alerts-alert--inviteToPlickers--sendInvite{
	font-weight: $lato-bold;
	white-space: nowrap;
	color: $textBlue;

	cursor: pointer;
}

.repoInvitationModal-footer-alerts-alert--inviteToPlickers
.repoInvitationModal-footer-alerts-alert--inviteToPlickers--sendInvite:hover{
	
	text-decoration: underline;
}

.repoInvitationModal-footer-alerts-alert--changesToSave{
	background: #FFF7D8; // yellow
	border: 1px solid #F0C36D; // yellow
	color: #CC7C1B; // yellow

	padding-top: 2px;
	padding-bottom: 2px;

}



@import 'Repo-InvitationModal-PermissionRow';
@import 'Repo-InvitationModal-InviteInput';


.repoInvitationModal--confirmationModal{
	width: 400px;
	padding-top: 28px;
	padding: 32px;
	padding-bottom: 36px;

	z-index: 900;

	border-radius: 8px;
	
	overflow: hidden;

	background: rgb(250,251,255);
	border: 1px solid $gray-125;
	box-shadow: 0px 8px 16px 0px rgba(0,0,10,0.12), 0px 24px 48px 0px rgba(0,0,10,0.08);
}

.repoInvitationModal--confirmationModal-label{
	font-size: 22px;
	font-weight: $lato-bold;
	color: $textBlack;

	word-wrap: break-word;
}

.repoInvitationModal--confirmationModal-label strong{
	color: $blue;
}


.repoInvitationModal--confirmationModal-label-multiHeader{
	margin-bottom: 10px;
	font-size: 20px;
}

.repoInvitationModal--confirmationModal-label-multiEmail{
	margin-bottom: 4px;
	font-size: 18px;
}

//

.repoInvitationModal--confirmationModal-btnRow{
	display: flex;
	width: 100%;
	align-items: center;
	justify-content: center;
}

.repoInvitationModal--confirmationModal-btnRow button{
	flex-grow: 1;
	margin-top: 28px;
	font-weight: $lato-bold;
}

.repoInvitationModal--confirmationModal button.btn--blue{	
	background: $blue;
	margin-right: 10px;
}

.repoInvitationModal--confirmationModal button.btn--red{
	margin-right: 10px;
}	