//
// PAGE HEADER BUTTONS
// Generally, override default Button component styles
// Buttons in headers are a lighter (no border/no background)
// 

.pageHeaderSticky,
.pageHeaderTop{

	//overriding the default button styling
	button.btn.btn--mid.btn--white{
		background: none;
		border: 1px solid $invisible;
		box-shadow: none;		

		color: rgb(54, 120, 250);
		font-weight: $lato-bold;

		font-size: 15px;
		height: 32px;
	}

	button.btn.btn--mid.btn--white.new-set{
	 	min-width: 102px;
	}

	button.btn.btn--mid.btn--white .icon{
		width: 14px;
		height: 14px;
		margin-right: 5px;
		fill: $textBlue;
	}
	
	button.btn.btn--mid.btn--white.btn--iconOnly{
		min-width: 38px;
	}

	button.btn.btn--mid.btn--white.btn--iconOnly.menu{
		margin-right: -8px;
	}


	button.btn.btn--mid.btn--white.btn--iconOnly .icon{
		margin-right: 0px;
	}

	button.btn.btn--mid.btn--white:hover{
		background: rgb(247, 250, 255);		
		color: rgb(45, 110, 240);		
	}

	button.btn.btn--mid.btn--white:active{
		background: rgb(242, 245, 255);	
	}

	button.btn.btn--mid.btn--white .icon.folder-outline{
		width: 18px;
		height: 18px;
	}

	button.btn.btn--mid.btn--white .icon.compose{
		width: 15px;
		height: 15px;
		position: relative;
		top: -1px;
	}

}