///
///
///

.printPackQuestion-choices{
  margin-top: 10px;
  margin-left: -24px;
}

.printPackQuestion-choice{
  display: flex;
  width: 100%;
  padding-left: 10px;
  box-sizing: border-box;
  min-height: 16px;
  margin-bottom: 4px;
}


//

.printPackQuestion-choice-responseContainer{
  width: $printPack-pageMargin--sideInnerOuterDiff;
  height: 16px;
  //background: $tempr;

  flex-shrink: 0;
  flex-grow: 0;

  display: flex;
  align-items: center;
  justify-content: flex-end;

  box-sizing: border-box;
  padding-right: 5px; // distance from dot to letterblock
}

.printPackQuestion-choice-responseContainer-response{
  width: 12px;
  height: 12px;

  border-radius: 2px;
  border: 1px solid $gray-12;
  box-sizing: border-box;

  flex-grow: 0;
  flex-shrink: 0;

  position: relative;
}

.printPackQuestion-choice-responseContainer-response svg{
  position: absolute;

  left: 3px;
  top: 3px;

  fill: $gray-4;
}

.printPackQuestion-choice-letterBlock{
  flex-grow: 0;
  flex-shrink: 0;

  //background: rgb(250,250,255);

  display: flex;
  align-items: center;
  justify-content: center;

  line-height: 0;

  font-weight: $lato-medium;
  color: $gray-8;

  width: 16px;
  height: 16px;
  font-size: 9pt;
  border-radius: 2px;
  margin-right: 10px;
  //margin-left: 4px;
  text-align: center;
}

.printPackQuestion-choice-letterBlock--correctHighlight{
  background: none;
}

///

.printPackQuestion-choice-body{
  color: $textBlackAbsolute;
  font-weight: $lato-medium;
  margin-top: 0px;
  font-size: 9pt;

  line-height: 1.15;
  padding-top: 1px;
}

.printPackQuestion-choice-body-body{
  width: 100%;
}

.printPackQuestion--isGraded
.printPackQuestion-choice.printPackQuestion-choice--isHighlightCorrect.printPackQuestion-choice--isIncorrect
.printPackQuestion-choice-body{
  opacity: 0.75;
}

//MEDIA SUPPORT

.printPackQuestion-choice-body-video,
.printPackQuestion-choice-body-sound{
  box-sizing: border-box;

  // padding: 4px;
  padding-left: 19px;
  // border-radius: 4px;
  // background: $blue-extraLight;
  // background: $tempr;

  position: relative;

  width: 100%;

  word-break: break-word; // for long URLs

  // font-weight: $lato-semibold;

}

.printPackQuestion-choice-body-video svg{
  position: absolute;

  height: 16px;
  width: 16px;

  top: -1px;
  left: -1px;
}

.printPackQuestion-choice-body-sound svg{
  position: absolute;

  height: 13px;
  width: 13px;

  top: 0px;
  left: 1px;

  fill: $blue;
}

.printPackQuestion-choice-body-video-clip{
  margin-top: 2px;
  font-weight: $lato-medium;
  font-size: calc(#{$printPack-bodyFontSize} - 1px);
  color: $textDark;
}


// IMAGE SUPPORT
.printPackQuestion-choice-imageContainer img{
  display: block;
  max-width: $printPack-question-imageSide;
  max-height: $printPack-question-imageSide;
  width: auto;
  height: auto;
}

.printPackQuestion-choice-imageContainer .printPackQuestion-choice-ghostImageSpacer{
  width: $printPack-question-imageSide;
  height: $printPack-question-imageSide;
}

.printPackQuestion-choice-imageContainer .printPackQuestion-choice-ghostImageSpacer{
  display: none;
}

// HIDE IMAGE AND SHOW SPACER WHEN GHOSTS

.printPack--ghost .printPackQuestion-choice-imageContainer .printPackQuestion-choice-ghostImageSpacer{
  display: block;
}

.printPack--ghost .printPackQuestion-choice-imageContainer img{
  display: none;
}
