.editor-insertSoundModal--soundLibrary{
	// width: 1025px;
	// height: 530px;

	//width: calc(100vw - 200px);
	width: 88vw;
	height: calc(100vh - 70px);

	min-height: 530px;
	min-width: 980px;

	max-width: 1120px;
	max-height: 720px;

	display: flex;
	padding-left: 0px;
	padding-right: 0px;
	padding-top: 0px;
	padding-bottom: 0px;

	background: rgb(253,253,255);
	position: relative;
	z-index: 5;
}

.editor-insertSoundModal--soundLibraryBG{
	position: fixed;
	top: 0px;
	left: 0px;
	right: 0px;
	bottom: 0px;

	background: $invisible;
	z-index: 3;
}

.editor-insertSoundModal--soundLibrary-categoryNav{
	height: 100%;
	width: 270px;
	flex-grow: 0;
	flex-shrink: 0;

	box-sizing: border-box;
	padding-left: 20px;
	padding-top: 23px;
	padding-bottom: 0px;
	padding-right: 12px;

	display: flex;
	flex-direction: column;

	border-right: 1px solid $lineMid;
}

button.editor-insertSoundModal--soundLibrary-categoryNav--category{
	width: 100%;
	height: 26px;
	box-sizing: border-box;


	display: flex;
	align-items: center;

	border: none;
	box-shadow: none;
	outline: none;
	background: none;
	padding: 0px;

	padding-left: 8px;
	padding-right: 8px;

	cursor: pointer;

	border-radius: 2px;
}

button.editor-insertSoundModal--soundLibrary-categoryNav--category--inactive{
	opacity: 0.75;
}

button.editor-insertSoundModal--soundLibrary-categoryNav--category--inactive:hover{
	opacity: 1;
	background: rgba(0,0,0,0.02);
}

.editor-insertSoundModal--soundLibrary-categoryNav--category-name{
	font-size: 15.5px;
	font-weight: $lato-semibold;
	color: $textBlackDark;
	letter-spacing: 0.025em;
}

.editor-insertSoundModal--soundLibrary-categoryNav--category-resultsCount{
	margin-left: auto;

	font-size: 13px;
	font-weight: $lato-semibold;
	color: $textBlack;

	opacity: 0.65;
	//opacity: 0;
}

button.editor-insertSoundModal--soundLibrary-categoryNav--category--active{
	background: #F3F8FE;
}

button.editor-insertSoundModal--soundLibrary-categoryNav--category--active
.editor-insertSoundModal--soundLibrary-categoryNav--category-resultsCount{
	opacity: 1;
}

button.editor-insertSoundModal--soundLibrary-categoryNav--category--inactive:hover
.editor-insertSoundModal--soundLibrary-categoryNav--category-resultsCount{
	opacity: 0.8;
}

button.editor-insertSoundModal--soundLibrary-categoryNav--category--active
.editor-insertSoundModal--soundLibrary-categoryNav--category-name{
	color: darken($textBlue, 7%);
	font-weight: $lato-bold;
	letter-spacing: 0.02em;
}

button.editor-insertSoundModal--soundLibrary-categoryNav--category--active
.editor-insertSoundModal--soundLibrary-categoryNav--category-resultsCount{
	color: $textBlue;
}

.editor-insertSoundModal--soundLibrary-main-resultsList-searchGroupLoading--loading ~
.editor-insertSoundModal--soundLibrary-categoryNav--category-resultsCount{
	visibility: hidden;
}

//

.editor-insertSoundModal--soundLibrary-categoryNav-discoverLabel{
	width: 100%;
	box-sizing: border-box;
	padding-left: 8px;

	margin-top: 20px;
	margin-bottom: 8px;

	font-size: 11px;
	font-weight: $lato-semibold;
	text-transform: uppercase;
	letter-spacing: 0.02em;

	color: lighten($textDark, 5%);
	user-select: none;
	cursor: default;
}

//


.editor-insertSoundModal--soundLibrary-categoryNav-addSubject{
	margin-top: 2px;

	font-weight: $lato-medium;
	color: $textBlack;

	font-size: 14px;
	height: 26px;
	background: none;
	border: 0px;
	border-radius: 2px;

	opacity: 0.7;

	width: 100%;

	box-sizing: border-box;
	padding-left: 8px;
	text-align: left;

	letter-spacing: 0.02em;

	cursor: pointer;
}


.editor-insertSoundModal--soundLibrary-categoryNav-vSpacer{
	height: 10px;
	width: 100%;
	flex-grow: 1;
	flex-shrink: 1;
}

a.editor-insertSoundModal--soundLibrary-categoryNav-helpBtn{
	margin-left: $leftPadding-leftPanel-editVideoModal;
	width: calc(100% + 12px);
	margin-left: -10px;
	height: 32px;
	
	color: $textBlack;
	margin-bottom: 8px;

	text-align: center;
	opacity: 0.4;
	background: none;

	border-radius: 4px;

	font-size: 13px;
	letter-spacing: 0.025em;

	font-weight: $lato-medium;

	display: flex;
	align-items: center;
	justify-content: center;

	transition: opacity 50ms linear;
}

.editor-insertSoundModal--soundLibrary-categoryNav:hover
a.editor-insertSoundModal--soundLibrary-categoryNav-helpBtn{
	opacity: 0.8;
}

.editor-insertSoundModal--soundLibrary-categoryNav:hover
a.editor-insertSoundModal--soundLibrary-categoryNav-helpBtn:hover{
	background: rgba(0,0,20,0.04);
	opacity: 1;
	
	// text-decoration: underline !important;
}
	
.editor-insertSoundModal--soundLibrary-categoryNav-submitBtnContainer{	
	width: calc(100% + 12px);
	margin-left: -10px;
	
	box-sizing: border-box;
}

//
// 


button.editor-insertSoundModal--soundLibrary-categoryNav-insertBtn{
	height: 55px;
	width: 100%;
	box-sizing: border-box;

	background: $blue;
	border-radius: 5px;

	border: none;
	box-shadow: none;

	padding-top: 10px;

	box-shadow: 0px 2px 2px 0px rgba(0,0,0,0.12);

	cursor: pointer;

	margin-top: 0px;
}

.editor-insertSoundModal--soundLibrary-categoryNav-insertBtn-primary{
	font-size: 17px;
	font-weight: $lato-bold;
	letter-spacing: 0.03em;
	color: $white;
}

.editor-insertSoundModal--soundLibrary-categoryNav-insertBtn-secondary{
	font-size: 10px;
	font-weight: $lato-semibold;
	color: $white;
	opacity: 0;
	letter-spacing: 0.02em;
	text-transform: uppercase;
	margin-top: 1px;
}

button.editor-insertSoundModal--soundLibrary-categoryNav-insertBtn:hover
.editor-insertSoundModal--soundLibrary-categoryNav-insertBtn-secondary{
	opacity: 0.5;
	transition: opacity 100ms;
	transition-delay: 150ms;
}

button.editor-insertSoundModal--soundLibrary-categoryNav-cancelBtn{
	width: 100%;
	//margin-top: 6px;
	margin-top: 2px;
	//margin-bottom: 8px;
	margin-bottom: 8px;

	height: 32px;
	border-radius: 5px;
	background: rgba(0,0,0,0.7);
	background: none;

	color: $textBlack;

	font-size: 14px;
	font-weight: $lato-semibold;
	letter-spacing: 0.015em;

	cursor: pointer;

	outline: none;
	border: none;
	box-shadow: none;

	display: flex;
	align-items: center;
	justify-content: center;

	opacity: 0.6;
}


// button.editor-insertSoundModal--soundLibrary-categoryNav-cancelBtn--hide{
// 	opacity: 0.5;
// 	transition: opacity 50ms linear;
// }

// button.editor-insertSoundModal--soundLibrary-categoryNav-cancelBtn--show{
// 	opacity: 0.7;
// 	transition: opacity 50ms linear;
// }

button.editor-insertSoundModal--soundLibrary-categoryNav-cancelBtn:hover{
	opacity: 1;
	background: rgba(0,0,20,0.04);
}




//
//

.editor-insertSoundModal--soundLibrary-main{
	height: 100%;
	width: 10px;
	flex-grow: 1;
	flex-shrink: 1;
}

.editor-insertSoundModal--soundLibrary-main{

}

.editor-insertSoundModal--soundLibrary-main-titleBar{
	
	width: 100%;
	// background: $tempb;

	
	display: flex;

	box-sizing: border-box;
	padding-left: 48px;
	padding-right: 24px;
}


.editor-insertSoundModal--soundLibrary-main-titleBar--browse{
	height: 86px;
	align-items: flex-start;
	padding-top: 22px;
}

.editor-insertSoundModal--soundLibrary-main-titleBar--search{
	height: 56px;
	align-items: flex-end;
}

.editor-insertSoundModal--soundLibrary-main-titleBar-categoryNameContainer{
	width: 100%;	
	margin-top: 3px;
}

.editor-insertSoundModal--soundLibrary-main-titleBar-categoryName{
	font-size: 22px;
	font-weight: $lato-heavy;
	color: $textBlackDark;
	letter-spacing: 0.02em;

	cursor: default;
	user-select: none;

	display: flex;
}

.editor-insertSoundModal--soundLibrary-main-titleBar-categoryName-discover{
	margin-right: 6px;
	color: $textBlue;	
}

.editor-insertSoundModal--soundLibrary-main-titleBar-categoryName-categoryName{
	text-transform: capitalize;
}

.editor-insertSoundModal--soundLibrary-main-titleBar-categoryLabel{
	margin-top: 4px;
	font-weight: $lato-medium;
	color: lighten($textDark, 5%);
	font-size: 14px;
	letter-spacing: 0.02em;
}

.editor-insertSoundModal--soundLibrary-main-titleBar-categoryLabel span{
	text-transform: capitalize;
}


.editor-insertSoundModal--soundLibrary-main-titleBar-searchBarContainer{
	position: relative;
	margin-left: auto;

	height: 34px;
	width: 310px;
	// width: 320px;

}

.editor-insertSoundModal--soundLibrary-main-titleBar-searchBarContainer--active{
	margin-left: -12px;
	width: calc(100% + 12px);
}

//

.editor-insertSoundModal--soundLibrary-main-titleBar-searchBar{
	position: absolute;
	top: 0px;
	left: 0px;
	right: 0px;
	bottom: 0px;
}

input.editor-insertSoundModal--soundLibrary-main-titleBar-searchBar-input{
	font-size: 17px;
	color: $textBlackDark;
	font-weight: $lato-bold;
	letter-spacing: 0.01em;
	background: $white;

	position: absolute;
	top: 0px;
	left: 0px;
	right: 0px;
	bottom: 0px;

	width: 100%;
	box-sizing: border-box;
	padding-left: 34px;

	border-radius: 4px;
	border: 1px solid $lineDark;
}

.editor-insertSoundModal--soundLibrary-main-titleBar-searchBarContainer--active
input.editor-insertSoundModal--soundLibrary-main-titleBar-searchBar-input{
	padding-left: 43px;
}

input.editor-insertSoundModal--soundLibrary-main-titleBar-searchBar-input:focus{
	border: 1px solid $blue;
	outline: none;
	box-shadow: 0px 0px 0px 2px transparentize($blue, 0.9);
}

input.editor-insertSoundModal--soundLibrary-main-titleBar-searchBar-input::placeholder{
	color: lighten($textLight, 5%);
}

input.editor-insertSoundModal--soundLibrary-main-titleBar-searchBar-input:focus::placeholder{
	color: $textInputPlaceholder;
}


.editor-insertSoundModal--soundLibrary-main-titleBar-searchBar-iconContainer{
	position: absolute;
	top: 0px;
	left: 0px;
	width: 40px;
	bottom: 0px;

	border-radius: 5px;

	pointer-events: none;

	display: flex;
	align-items: center;
	justify-content: center;
}

.editor-insertSoundModal--soundLibrary-main-titleBar-searchBarContainer--active
.editor-insertSoundModal--soundLibrary-main-titleBar-searchBar-iconContainer{	
	width: 45px;
}

.editor-insertSoundModal--soundLibrary-main-titleBar-searchBar-iconContainer svg{
	width: 15px;
	height: 15px;
}

.editor-insertSoundModal--soundLibrary-main-titleBar-searchBar--empty
.editor-insertSoundModal--soundLibrary-main-titleBar-searchBar-iconContainer svg{
	fill: $gray-10;
}

.editor-insertSoundModal--soundLibrary-main-titleBar-searchBar--notEmpty
.editor-insertSoundModal--soundLibrary-main-titleBar-searchBar-iconContainer svg{
	fill: $blue;
}


.editor-insertSoundModal--soundLibrary-main-titleBar-searchBar-iconContainer-loadingSpinnerContainer{
	position: absolute;
	top: 1px;
	left: 0px;
	right: 2px;
	bottom: 0px;
	
	display: flex;
	align-items: center;
	justify-content: center;

	// background: $tempr;
	
}

.editor-insertSoundModal--soundLibrary-main-titleBar-searchBar-iconContainer-loadingSpinner{	
	height: 12px;
	width: 12px;
  
  border-radius: 11px;

  border: 2px transparentize($blue, 0.65) solid;
  border-top: 2px $blue solid;

  opacity: 0.8;

  animation: spinnerRotateAnimation .5s infinite linear;
}



//

button.editor-insertSoundModal--soundLibrary-main-titleBar-searchBar-clearBtnContainer{
	position: absolute;
	top: 1px;
	right: 1px;
	bottom: 1px;
	
	background: none;
	z-index: 10;

	border: none;
	box-shadow: none;
	outline: none;

	cursor: pointer;

	box-sizing: border-box;
	padding-left: 1px;
	padding-right: 2px;
	padding-top: 2px;
	padding-bottom: 2px;
}

.editor-insertSoundModal--soundLibrary-main-titleBar-searchBar-clearBtn{
	background: $tempr;
	height: 100%;
	display: flex;
	align-items: center;

	padding-left: 14px;
	padding-right: 14px;

	padding-left: 20px;
	padding-right: 20px;

	font-size: 14px;
	font-weight: $lato-bold;
	letter-spacing: 0.02em;

	border-radius: 3px;

	background: $blue-extraLight;
	color: $textBlue;

	background: transparentize($red, 0.95);
	color: $red;
	color: darken($textMid, 5%);

	background: $gray-15;
	
	font-size: 13.5px;
	letter-spacing: 0.02em;

}

.editor-insertSoundModal--soundLibrary-main-titleBar-searchBar-clearBtn svg{
	height: 10px;
	width: 10px;

	fill: $textLight;
}

button.editor-insertSoundModal--soundLibrary-main-titleBar-searchBar-clearBtnContainer:hover
.editor-insertSoundModal--soundLibrary-main-titleBar-searchBar-clearBtn{
	background: darken($gray-14, 3%);
}

button.editor-insertSoundModal--soundLibrary-main-titleBar-searchBar-clearBtnContainer:hover
.editor-insertSoundModal--soundLibrary-main-titleBar-searchBar-clearBtn svg{
	fill: lighten($textDark, 5%);
}

//


// RESULTS LIST

.editor-insertSoundModal--soundLibrary-main-resultsList{	
	width: 100%;

	overflow-y: auto;

	box-sizing: border-box;
	padding-left: 24px;
	padding-right: 24px;

	padding-bottom: 36px;
}

.editor-insertSoundModal--soundLibrary-main-resultsList--search{
	height: calc(100% - 56px);
	padding-top: 20px;
}

.editor-insertSoundModal--soundLibrary-main-resultsList--browse{
	height: calc(100% - 86px - 24px);
}

.editor-insertSoundModal--soundLibrary-main-resultsList-searchGroup{	
	width: 100%;
	box-sizing: border-box;
	padding-bottom: 32px;
}

.editor-insertSoundModal--soundLibrary-main-resultsList-searchGroup--noResults{
	padding-bottom: 16px;
}

.editor-insertSoundModal--soundLibrary-main-resultsList-searchGroup-groupTitle{
	font-size: 12.5px;
	font-weight: $lato-bold;
	text-transform: uppercase;

	padding-left: 30px;

	letter-spacing: 0.03em;
	color: lighten($textDark, 6%);
	color: $textMid;

	margin-bottom: 5px;
	cursor: default;
	user-select: none;
}

.editor-insertSoundModal--soundLibrary-main-resultsList-searchGroupLoading--loading ~
.editor-insertSoundModal--soundLibrary-main-resultsList-searchGroup
.editor-insertSoundModal--soundLibrary-main-resultsList-searchGroup-groupTitle{
	visibility: hidden;
}

//

.editor-insertSoundModal--soundLibrary-main-resultsList-searchGroup-groupTitle-noResults{
	margin-left: 5px;
}

.editor-insertSoundModal--soundLibrary-main-resultsList-searchGroup--noResults
.editor-insertSoundModal--soundLibrary-main-resultsList-searchGroup-groupTitle{
	opacity: 0.7;
}



//
//

$width-soundLibraryRow-playPauseBtnContainer: 56px;
.editor-insertSoundModal--soundLibrary-main-resultsList-soundClipRow{
	width: 100%;
	height: 40px;

	background: none;
	border: none;
	box-shadow: none;
	outline: none;

	display: flex;
	
	border-radius: 5px;

	display: flex;
	align-items: center;

	box-sizing: border-box;

	color: $textBlackDark;

	position: relative;
	cursor: pointer;
}

.editor-insertSoundModal--soundLibrary-main-resultsList-soundClipRow--active{
	background: $blue;
	color: $white;
}

.editor-insertSoundModal--soundLibrary-main-resultsList-soundClipRow--inactive:hover{
	background: rgb(251,251,253);
}

.editor-insertSoundModal--soundLibrary-main-resultsList-searchGroupLoading--loading ~
.editor-insertSoundModal--soundLibrary-main-resultsList-searchGroup
.editor-insertSoundModal--soundLibrary-main-resultsList-soundClipRow{
	visibility: hidden;
}


//
//

.editor-insertSoundModal--soundLibrary-main-resultsList-soundClipRow-playPauseBtnContainer,
.editor-insertSoundModal--soundLibrary-main-resultsSortHeaders-playPauseBtnContainer{
	width: $width-soundLibraryRow-playPauseBtnContainer;
	display: flex;
	align-items: center;
	justify-content: center;
}

.editor-insertSoundModal--soundLibrary-main-resultsList-soundClipRow-playPauseBtnContainer{
	// background: $tempr;
	height: 100%;
}

button.editor-insertSoundModal--soundLibrary-main-resultsList-soundClipRow-playPauseBtn{
	width: 34px;
	height: 100%;

	// background: $tempr;
	background: none;

	border: none;
	outline: none;

	cursor: pointer;

	display: flex;
	align-items: center;
	justify-content: center;
}

.editor-insertSoundModal--soundLibrary-main-resultsList-soundClipRow-playPauseBtn-internal{
	width: 26px;
	height: 26px;
	border-radius: 13px;
	background: none;

	display: flex;
	align-items: center;
	justify-content: center;

	position: relative;
	// background: $tempr;
}

button.editor-insertSoundModal--soundLibrary-main-resultsList-soundClipRow-playPauseBtn svg{
	width: 12px;
	height: 12px;
}

button.editor-insertSoundModal--soundLibrary-main-resultsList-soundClipRow-playPauseBtn svg.editorIcon-playbackPlay{
	position: relative;
	right: -1px;
}

//
//


.editor-insertSoundModal--soundLibrary-main-resultsList-soundClipRow--inactive
button.editor-insertSoundModal--soundLibrary-main-resultsList-soundClipRow-playPauseBtn--notPlaying
.editor-insertSoundModal--soundLibrary-main-resultsList-soundClipRow-playPauseBtn-internal{
	background: $blue-extraLight;
}

.editor-insertSoundModal--soundLibrary-main-resultsList-soundClipRow--inactive
button.editor-insertSoundModal--soundLibrary-main-resultsList-soundClipRow-playPauseBtn--notPlaying:hover
.editor-insertSoundModal--soundLibrary-main-resultsList-soundClipRow-playPauseBtn-internal{
	background: lighten($blue, 42%);
}

.editor-insertSoundModal--soundLibrary-main-resultsList-soundClipRow--inactive
button.editor-insertSoundModal--soundLibrary-main-resultsList-soundClipRow-playPauseBtn--notPlaying
.editor-insertSoundModal--soundLibrary-main-resultsList-soundClipRow-playPauseBtn-internal svg{
	fill: $blue;
}

//

.editor-insertSoundModal--soundLibrary-main-resultsList-soundClipRow--active
button.editor-insertSoundModal--soundLibrary-main-resultsList-soundClipRow-playPauseBtn--notPlaying
.editor-insertSoundModal--soundLibrary-main-resultsList-soundClipRow-playPauseBtn-internal,
.editor-insertSoundModal--soundLibrary-main-resultsList-soundClipRow--active
button.editor-insertSoundModal--soundLibrary-main-resultsList-soundClipRow-playPauseBtn--playing
.editor-insertSoundModal--soundLibrary-main-resultsList-soundClipRow-playPauseBtn-internal{
	background: darken($blue, 5%);
}

.editor-insertSoundModal--soundLibrary-main-resultsList-soundClipRow--active
button.editor-insertSoundModal--soundLibrary-main-resultsList-soundClipRow-playPauseBtn--notPlaying:hover
.editor-insertSoundModal--soundLibrary-main-resultsList-soundClipRow-playPauseBtn-internal{
	background: darken($blue, 7%);
}

.editor-insertSoundModal--soundLibrary-main-resultsList-soundClipRow--active
button.editor-insertSoundModal--soundLibrary-main-resultsList-soundClipRow-playPauseBtn--notPlaying
.editor-insertSoundModal--soundLibrary-main-resultsList-soundClipRow-playPauseBtn-internal svg{
	fill: $white;
}




button.editor-insertSoundModal--soundLibrary-main-resultsList-soundClipRow-playPauseBtn--playing
.editor-insertSoundModal--soundLibrary-main-resultsList-soundClipRow-playPauseBtn-internal{
	background: $blue;
	transform: scale(1.1);
}

button.editor-insertSoundModal--soundLibrary-main-resultsList-soundClipRow-playPauseBtn--playing
.editor-insertSoundModal--soundLibrary-main-resultsList-soundClipRow-playPauseBtn-internal svg{
	fill: $white;
}

// LOADING SPINNER

.editor-insertSoundModal--soundLibrary-main-resultsList-soundClipRow-playPauseBtn-internal-spinnerContainer{
	position: absolute;
	
	display: flex;
	align-items: center;
	justify-content: center;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
}

.editor-insertSoundModal--soundLibrary-main-resultsList-soundClipRow-playPauseBtn-internal-spinnerContainer-spinner{
	height: 12px;
	width: 12px;
  
  border-radius: 12px;

  border: 2px rgba(26,28,34,0.1) solid;
  border-top: 2px $white solid;

  opacity: 0.8;

  animation: spinnerRotateAnimation .5s infinite linear;
}




//

.editor-insertSoundModal--soundLibrary-main-resultsList-soundClipRow-name,
.editor-insertSoundModal--soundLibrary-main-resultsSortHeaders-header--name{
	width: 10px;
	flex-grow: 1;
	flex-shrink: 1;
		
	//background: $tempr;
	text-align: left;

}

.editor-insertSoundModal--soundLibrary-main-resultsList-soundClipRow-name{
	font-size: 14.5px;
	font-weight: $lato-semibold;
	color: $textBlackDark;
	letter-spacing: 0.01em;

	cursor: pointer;
	min-width: 0;

	white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  user-select: none;


}
.editor-insertSoundModal--soundLibrary-main-resultsList-soundClipRow--active
.editor-insertSoundModal--soundLibrary-main-resultsList-soundClipRow-name{
	color: $white;
}

//

.editor-insertSoundModal--soundLibrary-main-resultsList-soundClipRow-seekBarContainer{
	width: 130px;
	height: 100%;
	// background: transparentize($blue, 0.8);
	padding-left: 10px;
}




// .rc-slider
.editor-insertSoundModal--soundLibrary-main-resultsList-soundClipRow-seekBarContainer
.rc-slider{
	height: 100%;
	// background: $tempr;
	cursor: pointer;
	opacity: 0.8;
	transition: opacity 50ms linear;
}

.editor-insertSoundModal--soundLibrary-main-resultsList-soundClipRow-seekBarContainer
.rc-slider:hover{
	opacity: 1;
	transition: opacity 50ms linear;
}

.editor-insertSoundModal--soundLibrary-main-resultsList-soundClipRow-seekBarContainer
.rc-slider-rail{
	top: 18px;
	background: rgba(0,0,0,0.3);
}

.editor-insertSoundModal--soundLibrary-main-resultsList-soundClipRow-seekBarContainer
.rc-slider-track{
	top: 18px;
	background: $white;
	border-top-right-radius: 0px;
	border-bottom-right-radius: 0px;
}



.editor-insertSoundModal--soundLibrary-main-resultsList-soundClipRow-seekBarContainer
.rc-slider-handle{
	top: 18px;
	height: 12px;
	width: 12px;
	margin-top: -4px;
	fill: $white;
	border: none;

	display: none; // maybe its useful, may add weight
}


.editor-insertSoundModal--soundLibrary-main-resultsList-soundClipRow--inactive
.editor-insertSoundModal--soundLibrary-main-resultsList-soundClipRow-seekBarContainer
.rc-slider-rail{
	background: transparentize($blue, 0.7);
}

.editor-insertSoundModal--soundLibrary-main-resultsList-soundClipRow--inactive
.editor-insertSoundModal--soundLibrary-main-resultsList-soundClipRow-seekBarContainer
.rc-slider-track{
	background: $blue;
}



// CATEGORY LABEL

.editor-insertSoundModal--soundLibrary-main-resultsList-soundClipRow-categoryLabelContainer{
	display: flex;
	align-items: center;
	justify-content: center;
	padding-right: 0px;
	padding-left: 2px;
}

.editor-insertSoundModal--soundLibrary-main-resultsList-soundClipRow-categoryLabel{
	font-size: 11px;
	font-weight: $lato-bold;
	text-transform: uppercase;
	letter-spacing: 0.03em;
	padding-top: 3px;
	padding-bottom: 3px;
	padding-right: 5px;
	padding-left: 5px;

	border-radius: 2px;
}

.editor-insertSoundModal--soundLibrary-main-resultsList-soundClipRow--active
.editor-insertSoundModal--soundLibrary-main-resultsList-soundClipRow-categoryLabel{
	background: rgba(0,0,0,0.06);
	color: $white;
}

.editor-insertSoundModal--soundLibrary-main-resultsList-soundClipRow--inactive
.editor-insertSoundModal--soundLibrary-main-resultsList-soundClipRow-categoryLabel{
	background: $blue-extraLight;
	color: $textBlue;
}


//
// DURATION AND PLAYS

.editor-insertSoundModal--soundLibrary-main-resultsList-soundClipRow-plays,
.editor-insertSoundModal--soundLibrary-main-resultsList-soundClipRow-duration{
	letter-spacing: 0.015em;
	font-weight: $lato-semibold;	
	text-align: right;
	font-size: 14.5px;

	cursor: pointer;

	color: $textBlackDark;
	user-select: none;
}

.editor-insertSoundModal--soundLibrary-main-resultsList-soundClipRow--active
.editor-insertSoundModal--soundLibrary-main-resultsList-soundClipRow-plays,
.editor-insertSoundModal--soundLibrary-main-resultsList-soundClipRow--active
.editor-insertSoundModal--soundLibrary-main-resultsList-soundClipRow-duration{
	color: $white;
}

.editor-insertSoundModal--soundLibrary-main-resultsList-soundClipRow-duration,
.editor-insertSoundModal--soundLibrary-main-resultsSortHeaders-header--duration{
	// width: 64px;	
	width: 78px;	
}


.editor-insertSoundModal--soundLibrary-main-resultsList-soundClipRow-plays,
.editor-insertSoundModal--soundLibrary-main-resultsSortHeaders-header--plays{
	// width: 60px;
	width: 62px;
}

.editor-insertSoundModal--soundLibrary-main-resultsList-soundClipRow--active
.editor-insertSoundModal--soundLibrary-main-resultsList-soundClipRow-plays{
	opacity: 0.8;
}

.editor-insertSoundModal--soundLibrary-main-resultsList-soundClipRow--inactive
.editor-insertSoundModal--soundLibrary-main-resultsList-soundClipRow-plays{
	color: $textLight;
}






//

.editor-insertSoundModal--soundLibrary-main-resultsList-soundClipRow-insertBtnContainer,
.editor-insertSoundModal--soundLibrary-main-resultsSortHeaders-insertBtnContainer{
	display: flex;
	align-items: center;
	justify-content: flex-end;

	width: 60px;
	box-sizing: border-box;
	padding-right: 4px;


}

button.editor-insertSoundModal--soundLibrary-main-resultsList-soundClipRow-insertBtn{
	height: 28px;
	width: 38px;
	
	border-radius: 4px;
	

	cursor: pointer;

	background: none;
	border: none;
	box-shadow: none;
}

button.editor-insertSoundModal--soundLibrary-main-resultsList-soundClipRow-insertBtn svg{
	width: 12px;
	height: 12px;
	position: relative;
	right: -1px;
}

.editor-insertSoundModal--soundLibrary-main-resultsList-soundClipRow--inactive
button.editor-insertSoundModal--soundLibrary-main-resultsList-soundClipRow-insertBtn{
	background: rgb(244,244,250);
	opacity: 0.6;
}

.editor-insertSoundModal--soundLibrary-main-resultsList-soundClipRow--inactive
button.editor-insertSoundModal--soundLibrary-main-resultsList-soundClipRow-insertBtn svg{
	fill: $textMid;
}

.editor-insertSoundModal--soundLibrary-main-resultsList-soundClipRow--inactive
button.editor-insertSoundModal--soundLibrary-main-resultsList-soundClipRow-insertBtn:hover{
	opacity: 1;
}


//

.editor-insertSoundModal--soundLibrary-main-resultsList-soundClipRow--active
button.editor-insertSoundModal--soundLibrary-main-resultsList-soundClipRow-insertBtn{
	background: rgba(0,0,0,0.06);
	opacity: 0.9;
}

.editor-insertSoundModal--soundLibrary-main-resultsList-soundClipRow--active
button.editor-insertSoundModal--soundLibrary-main-resultsList-soundClipRow-insertBtn:hover{
	background: rgba(0,0,0,0.1);
	opacity: 1;
}

.editor-insertSoundModal--soundLibrary-main-resultsList-soundClipRow--active
button.editor-insertSoundModal--soundLibrary-main-resultsList-soundClipRow-insertBtn svg{
	fill: $white;
}


.editor-insertSoundModal--soundLibrary-main-resultsList-soundClipRow-borderBottom{
	position: absolute;
	bottom: 0px;
	left: $width-soundLibraryRow-playPauseBtnContainer;
	right: 0px;
	height: 1px;
	background: $lineMid;
}

.editor-insertSoundModal--soundLibrary-main-resultsList-soundClipRow--active
.editor-insertSoundModal--soundLibrary-main-resultsList-soundClipRow-borderBottom{
	visibility: hidden;
}

//

//

.editor-insertSoundModal--soundLibrary-main-resultsSortHeaders{
	box-sizing: border-box;
	padding-left: 8px;
	margin-left: 16px;
	padding-right: 12px;
	margin-right: 12px;


	width: calc(100% - 28px);
	height: 24px;
	display: flex;
	align-items: center;
	
	border-bottom: 1px solid $lineMid;
}

.editor-insertSoundModal--soundLibrary-main-resultsSortHeaders--hide{
	display: none;
}

button.editor-insertSoundModal--soundLibrary-main-resultsSortHeaders-header{
	font-size: 11.5px;
	text-transform: uppercase;
	color: $textBlack;
	font-weight: $lato-bold;
	letter-spacing: 0.01em;

	background: none;
	border: none;
	cursor: pointer;

	opacity: 0.7;

	display: flex;
	align-items: center;

	box-sizing: border-box;
	padding: 0px;

	user-select: none;
	// border: 1px solid red;
}

button.editor-insertSoundModal--soundLibrary-main-resultsSortHeaders-header:hover{
	opacity: 1;
}

.editor-insertSoundModal--soundLibrary-main-resultsSortHeaders-header-iconContainer{
	display: flex;
	margin-left: 4px;
}

.editor-insertSoundModal--soundLibrary-main-resultsSortHeaders-header-iconContainer svg{
	width: 8px;
	height: 8px;
	fill: $blue;
}

.editor-insertSoundModal--soundLibrary-main-resultsSortHeaders-header-iconContainer svg.chevron-right{
	transform: rotate(90deg);
}

.editor-insertSoundModal--soundLibrary-main-resultsSortHeaders-header-iconContainer svg.chevron-left{
	transform: rotate(90deg);
}

button.editor-insertSoundModal--soundLibrary-main-resultsSortHeaders-header--duration,
button.editor-insertSoundModal--soundLibrary-main-resultsSortHeaders-header--plays{
	justify-content: flex-end;
}

.editor-insertSoundModal--soundLibrary-main-resultsSortHeaders-header-label svg{
	height: 16px;
	width: 16px;
}