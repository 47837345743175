$width-emojiSymbolPicker-searchIcon: 28px;

.editor-emojiSymbolPicker-search{
	display: flex;
	align-items: flex-end;
	// padding-left: $sidePaddingInner-emojiSymbolPicker;
	// padding-right: $sidePaddingInner-emojiSymbolPicker;	
	padding-left: 14px;
	padding-right: 14px;
	z-index: 10;	
}

.editor-emojiSymbolPicker-search-inputContainer{
	width: 100%;
	height: 32px;
	margin-bottom: 2px;

	position: relative;
}

input.editor-emojiSymbolPicker-search-input{

	position: absolute;
	top: 0px;
	left: 0px;
	width: 100%;
	bottom: 0px;

	box-sizing: border-box;

	border: 1px solid $lineDark;

	background: $white;
	border-radius: 5px;

	padding-left: $width-emojiSymbolPicker-searchIcon;

	font-size: 15.5px;
	font-weight: $lato-semibold;

	padding-bottom: 2px;
	height: 32px; // firefox bug fix
}

input.editor-emojiSymbolPicker-search-input::placeholder{
	color: $textLight;
}

input.editor-emojiSymbolPicker-search-input:focus{
	outline: none;
	box-shadow: 0px 0px 0px 5px transparentize($blue,0.95);
	border: 1px solid $blue;
}

.editor-emojiSymbolPicker-search-inputIcon{
	position: absolute;
	left: 2px;
	top: 2px;
	bottom: 2px;

	width: $width-emojiSymbolPicker-searchIcon;

	display: flex;
	align-items: center;
	justify-content: center;

	pointer-events: none;

}

.editor-emojiSymbolPicker-search-inputIcon svg.editor-emojiSymbolPicker-search-inputIcon-icon{
	fill: $gray-9 !important;
	height: 13px;
	width: 13px;
	position: relative;
}