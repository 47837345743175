.moveToFolder--modal .modal-content{
	width: 780px;
	height: 484px;

	position: relative;

	user-select: none;
	cursor: default;
}

.moveToFolder--modal .modal-content-header{
	background: none;
	box-shadow: none;
}

.moveToFolder{
	width: 100%;
	height: calc(100% - 46px); //for modal header

	box-sizing: border-box;
	padding-top: 24px;

	user-select: none;
	cursor: default;
}

.moveToFolder-itemName{
	position: absolute;
	top: 38px;
	left: 125px;
	right: 125px;

	white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

	text-align: center;

	font-size: 17px;
	font-weight: $lato-bold;
	color: $textLight;
}



.moveToFolder-sharedItemWarning{
	position: absolute;
	top: 458px;

	left: 50%;
	transform: translateX(-50%);

	border-radius: 3px;
	white-space: nowrap;

	text-align: center;

	padding-left: 8px;
	padding-right: 8px;
	padding-top: 2px;
	padding-bottom: 2px;



	font-size: 12.5px;
	font-weight: $lato-semibold;
	letter-spacing: 0.002em;

	cursor: default;

	background: #FFF7D8; // yellow
	border: 1px solid #F0C36D; // yellow
	color: #CC7C1B; // yellow
}

.moveToFolder-sharedItemWarning--multi{
	width: 569px;
}

// EXPLORER

.moveToFolder-explorer{
	width: 100%;
	height: 333px;
	background: $white;
	border-top: 1px solid $lineDark;
	border-bottom: 1px solid $lineDark;

	overflow: scroll;

	display: flex;

	overflow-y: hidden;
}

.moveToFolder-explorer-column{
	width: 180px;
	flex-shrink: 0;
	flex-grow: 0;
	border-right: 1px solid $lineMid;

	overflow-y: auto;
}

.moveToFolder-explorer-row{
	width: 100%;
	height: 27px;
	display: flex;

	align-items: center;

	position: relative;

	line-height: 1.8;

	border-top: 1px solid $invisible;
	border-bottom: 1px solid $invisible;

}

.moveToFolder-explorer-row-label{
	white-space: nowrap;
  	overflow: hidden;
  	text-overflow: ellipsis;

	font-size: 14px;
	font-weight: $lato-semibold;

	height: 100%;

	line-height: 1.8;

	margin-left: 6px;

	width: calc(100% - 46px);

}


.moveToFolder-explorer-row.is--library
.moveToFolder-explorer-row-label{
	font-weight: $lato-bold;
}

.moveToFolder-explorer-row.is--library .moveToFolder-explorer-row-label,
.moveToFolder-explorer-row.is--repo .moveToFolder-explorer-row-label{
	margin-left: 8px;
}

.moveToFolder-explorer-row .icon.folder{
	width: 13px;
	height: 13px;

	margin-left: 7px;

	fill: $textLight;
}

.moveToFolder-explorer-row .icon.chevron-right{
	width: 9px;
	height: 9px;

	margin-right: 9px;

	margin-left: auto;
}

// ROW  STATES

.moveToFolder-explorer-row{
	color: $textDark;
}


// {
// 	background: rgb(247,247,252);
// 	color: $textBlack;
// 	border-top: 1px solid $lineMid;
// 	border-bottom: 1px solid $lineMid;
// }

.moveToFolder-explorer-row.is--open,
.moveToFolder-explorer-row.is--selected{
	background: $blue;
	color: $white;
}

.moveToFolder-explorer-row.is--notSelectable{
	opacity: 0.5;
}

// FOLDER ICON STATES
.moveToFolder-explorer-row .icon.folder #top,
.moveToFolder-explorer-row .icon.folder #main{
	fill: $gray-11;
}

// .moveToFolder-explorer-row.is--open .icon.folder #top,
// .moveToFolder-explorer-row.is--open .icon.folder #main{
// 	fill: $blue;
// }

.moveToFolder-explorer-row.is--selected .icon.folder #top,
.moveToFolder-explorer-row.is--selected .icon.folder #main,
.moveToFolder-explorer-row.is--open .icon.folder #top,
.moveToFolder-explorer-row.is--open .icon.folder #main{
	fill: $white;
}

// CHEVRON STATES

.moveToFolder-explorer-row .icon.chevron-right{
	fill: $gray-12;
}

.moveToFolder-explorer-row.is--open .icon.chevron-right{
	fill: $textMid;
}

.moveToFolder-explorer-row.is--open .icon.chevron-right,
.moveToFolder-explorer-row.is--selected .icon.chevron-right{
	fill: $white;
}

////





.moveToFolder .btn.btn--xLarge{
	width: 340px;
	margin: 0 auto;
	margin-top: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
}
