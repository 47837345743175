//
// PAGE HEADER STICKY
// this appears as you scroll the page
// it has limited functionality (compared to pageHeaderTop)
// it is hidden and shown using JS
// it is one flex row
// there are some cool animation-delay effects
//

$height-pageHeaderSticky: 75px;

.pageHeaderSticky{
	display: none; // initial value, changed to flex in JS 
	position: fixed;
	top: $height-topNav;
	width: inherit;
	margin-left: calc(-1 * #{$leftGutter-centerPanel} - 1px);
	// offset required for width inherit, and -1px for the border

	height: $height-pageHeaderSticky;
	padding-right: $rightGutter-centerPanel;
	box-sizing: border-box;

	z-index: 300;

	align-items: center;
	
	background: $white;
	
	border-bottom: 1px solid $color-pageCenterBorder;
	border-right: 1px solid $color-pageCenterBorder;
	border-left: 1px solid $color-pageCenterBorder;
}

.pageHeaderSticky-header{
	flex-grow: 0;
	flex-shrink: 1;
	min-width: 0px;

	font-size: 28px;
	font-weight: $lato-heavy;
	color: $textBlack;

	white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

//

.pageHeaderSticky-spacer{
	width: 1px;
	flex-grow: 1; // expands to fill space
}

// ACTION GROUP

.pageHeaderSticky-actionGroup{
	display: flex;
	flex-shrink: 0;		
}

.pageHeaderSticky--searchResults
.pageHeaderSticky-actionGroup{
	color: $textMid;
	font-size: 15px;
	// 'search results' label
}

.pageHeaderSticky-rightBadge{
	display: flex;
	height: 100%;
	flex-shrink: 0;
	background: $tempr;
	width: 50px;

	align-items: center;
}

// REPO CUSTOM, REMOVE MEMBERS LIST FOR STICKY

.pageHeaderSticky--repo .pageHeaderSticky-header{
	cursor: default;
}

.pageHeaderSticky--repo .pageHeaderTop-actionRow--repoMemberList,
.pageHeaderSticky--repo .pageHeaderTop-actionRow-viewOnlyBanner{
	display: none;
}


//
// ANIMATION
// elements fade in once goes from display:none --> flex
// there are delays on items

@keyframes stickyHeader-item{
	0% { 
		opacity: 0; 
		transform: translateY(10px);
	}
	100% { 
		opacity: 1; 
		transform: translateY(0px);
	}
}

.pageHeaderSticky-leftBadgeContainer,
.pageHeaderSticky-header,
.pageHeaderSticky-rightBadge,
.pageHeaderSticky-subHeader,
.pageHeaderSticky-actionGroup button.btn{
	animation: stickyHeader-item 200ms cubic-bezier(.2, .3, .25, .9);
	animation-fill-mode: backwards;
}

.pageHeaderSticky-header {	
	animation-delay: 25ms;
}

.pageHeaderSticky-subHeader {	
	animation-delay: 50ms;
}

.pageHeaderSticky-rightBadge {	
	animation-delay: 75ms;	
}

.pageHeaderSticky-actionGroup button:nth-of-type(1) {
	animation-delay: 75ms;
}

.pageHeaderSticky-actionGroup button:nth-of-type(2) {
	animation-delay: 125ms;
}

.pageHeaderSticky-actionGroup button:nth-of-type(3) {
	animation-delay: 150ms;
}

.pageHeaderSticky-actionGroup .dropdown button {
	animation-delay: 175ms;
}
