// FLAG 
.printPackQuestion-flags{
	display: flex;
	margin-top: -2px;
	margin-bottom: 9px;
}


.printPackQuestion-flag{
	width: 80px;
	height: 16px;
	position: relative;
	margin-right: 10px;
	flex-shrink: 0;
	flex-grow: 0;

	box-sizing: border-box;
	padding-right: 6px;
	padding-left: 5px;
	
	font-size: 8pt;
	font-weight: $lato-semibold;
	line-height: 1.5;
	letter-spacing: 0.02em;
	
	text-transform: uppercase;
	
}

$printPackQuestion-flagColor--correct:			$green;
$printPackQuestion-flagColor--incorrect:		$red;
$printPackQuestion-flagColor--survey:			$blue;
$printPackQuestion-flagColor--missed: 			$gray-6;

$printPackQuestion-flagLabelBG--correct: 	lighten($printPackQuestion-flagColor--correct, 41%);
$printPackQuestion-flagLabelBG--incorrect: lighten($printPackQuestion-flagColor--incorrect, 27%);
$printPackQuestion-flagLabelBG--survey: 		lighten($printPackQuestion-flagColor--survey, 		41%);
$printPackQuestion-flagLabelBG--missed: 		lighten($printPackQuestion-flagColor--missed, 		43%);

$printPackQuestion-flagLabelColor--correct: 		darken($printPackQuestion-flagColor--correct, 		12%);
$printPackQuestion-flagLabelColor--incorrect: 	darken($printPackQuestion-flagColor--incorrect, 	12%);
$printPackQuestion-flagLabelColor--survey: 		darken($printPackQuestion-flagColor--survey, 		12%);
$printPackQuestion-flagLabelColor--missed: 		darken($printPackQuestion-flagColor--missed, 		12%);

.printPackQuestion-flag svg{
	position: absolute;
	left: 0px;
	top: 0px;
	bottom: 0px;
	right: 0px;
}

.printPackQuestion-flag-label{
	position: absolute;
	z-index: 5;
	left: 6px;
	top: 0px;
	bottom: 0px;
	right: 0px;
}

//

.printPackQuestion-flag--correct {
	color: $printPackQuestion-flagLabelColor--correct;	
} 

.printPackQuestion-flag--correct svg{
	fill: $printPackQuestion-flagLabelBG--correct;	
}

//

.printPackQuestion-flag--incorrect	 {
	color: $printPackQuestion-flagLabelColor--incorrect;	
}

.printPackQuestion-flag--incorrect svg{
		fill: $printPackQuestion-flagLabelBG--incorrect;	
}

//

.printPackQuestion-flag--survey {
	color: $printPackQuestion-flagLabelColor--survey;	
} 

.printPackQuestion-flag--survey svg{
	fill: $printPackQuestion-flagLabelBG--survey;	
}

//

.printPackQuestion-flag--missed {
	color: $printPackQuestion-flagLabelColor--missed;	
}

.printPackQuestion-flag--missed svg{
	fill: $printPackQuestion-flagLabelBG--missed;	
}