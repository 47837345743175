
//
// SET 
//

// SET HEADER

.printPackSetHeader{
	padding-top: 32px;
	padding-bottom: 2px;
	box-sizing: border-box;
	display: flex;
	width: 100%;	
	//background: $gray-13;
}

.printPackSetHeader-counter{
	font-size: 12pt;
	font-weight: $lato-heavy;
	color: $textBlue;	
	width: $printPack-pageMargin--sideInnerOuterDiff;
	flex-grow: 0;
	flex-shrink: 0;
	text-align: right;
	padding-right: 12px;
}

.printPackSetHeader-name{
	font-size: 12pt;
	font-weight: $lato-heavy;
	color: $textBlack;
	margin-right: auto;
}

.printPackSetHeader-score{
	font-size: 12pt;
	font-weight: $lato-heavy;
	color: $textLight;
}

.printPackSetHeader-score--absent{
	font-size: 10px;
	font-weight: $lato-heavy;
}

.printPackSetFooter{
	height: 1px;
	margin-top: -1px;
	width: 20%;
	//background: $lineMid;
	background: $gray-10;
	position: relative;
	z-index: 5;
	margin-left: $printPack-pageMargin--sideOuter;
}