
.syncLiveView-player-students{	
	height: 100%;
	width: $syncLiveView-rightDefaultWidth; // temp	

	flex-grow: 0;
	flex-shrink: 0;

	// overflow-x: visible;
	// overflow-y: scroll;

	position: relative;
	z-index: 10;
}

.syncLiveView-player-students--over32Students{
	width: 300px;	
}

.syncLiveView-player-students--over47Students{
	width: 400px;
}

.syncLiveView-player-students--over32Students
.syncLiveView-player-students-title{
	display: none;
}

.syncLiveView-player-students--over32Students
.syncLiveView-player-students-studentCell{
	width: 97px;
	max-width: 97px;	
}



//
// TITLE
//
.syncLiveView-player-students-top{
	margin-bottom: 3px;
}

.syncLiveView-player-students-top-label{
	font-weight: $lato-bold;
	color: $textMid;
	font-size: 13.5px;
	letter-spacing: 0.01em;

	box-sizing: border-box;
	user-select: none;	

	height: 30px;

	box-sizing: border-box;
	position: relative;

	// background: $tempr;

	display: flex;
	align-items: center;
}

.syncLiveView-player-students-top-label-count{
	flex-grow: 0;
	flex-shrink: 0;	
	text-align: right;
	padding-right: 4px;
}

.syncLiveView-player-students-top-label--online
.syncLiveView-player-students-top-label-count{
	width: 20px;
}

.syncLiveView-player-students-top-label--scan
.syncLiveView-player-students-top-label-count{
	width: 40px;
}


.syncLiveView-player-students .syncLiveView-player-students-top-label--online{
	opacity: 0.5;
	transition: opacity 100ms linear;
}

.syncLiveView-player-students:hover .syncLiveView-player-students-top-label--online{
	opacity: 1;
	transition: opacity 100ms linear;
}

.syncLiveView-player-students .syncLiveView-player-students-top-label--allOffline{
	opacity: 0.5;
	margin-left: 10px;
}






//
//

.syncLiveView-player-students-list{
	width: calc(100% + 5px);	
	margin-left: -2px;
	display: flex;
	flex-wrap: wrap;
}




@import 'SyncLiveView-PlayerStudents-StudentCell';

