//
// SCORESHEET TOPBAR
// this is the bar at the top of scoresheet, with date information, granularity picker, and some buttons
//

.scoreSheet-topBar{
	width: 100%;
	height: $height-scoreSheetTopbar;

	position: absolute;
	top: 0px;
	left: 0px;
	display: flex;

	background: $color-navBar-BG;
	border-bottom: $border-navBar;
	box-shadow: $shadow-navBar;
	box-sizing: border-box;

	z-index: 20;
}

// GENERAL LAYOUT

.scoreSheet-topBar-left{
	display: flex;
	align-items: center;
	
	flex-shrink: 0;
	width: $width-scoreSheetSidePanel;

	padding-left: 18px;
	box-sizing: border-box;
}

//

.scoreSheet-topBar-center{
	flex-grow: 1;
	flex-shrink: 1;

	padding-left: 16px;

	display: flex;
	align-items: center;
	justify-content: center;
}

//

.scoreSheet-topBar-right{
	flex-shrink: 0;
	align-items: center;
	display: flex;
	justify-content: flex-end;

	padding-right: 14px;
}

//
// LEFT

.scoreSheet-topBar-left .btn.home{
	width: 38px;
}

.scoreSheet-topBar-left .btn.previous,
.scoreSheet-topBar-left .btn.next{
	width: 32px;
}

.scoreSheet-topBar-left .btn.previous{
	margin-right: -1px;
	border-top-right-radius: 0px;
	border-bottom-right-radius: 0px;
}

.scoreSheet-topBar-left .btn.next{
	border-top-left-radius: 0px;
	border-bottom-left-radius: 0px;
}

.scoreSheet-topBar-left .btn.today{
	margin-left: auto;
	min-width: 68px;
	width: 68px;
	color: $textBlack;

	margin-right: 8px;
}

.scoreSheet-topBar-left .btn.home .icon{
	width: 20px;
	height: 20px;
}

.scoreSheet-topBar-left .btn.previous .icon,
.scoreSheet-topBar-left .btn.next .icon{
	width: 18px;
	height: 18px;
}

//
// CENTER

.scoreSheet-topBar-center-label{
	font-size: 18px;
	font-weight: $lato-bold;
	color: $textBlackDark;
	text-align: left;
	width: 100%;
	user-select: none;
	cursor: default;
}

.scoreSheet-topBar--noSectionSelected
.scoreSheet-topBar-center-label{
	color: $textLight;
}

//
// RIGHT

// GRANULARITY TOGGLE
.scoreSheet-topBar-granularityToggle{
	display: flex;
}

// FREE LIMIT TOOLTIP (OVER CUSTOM TIMESPAN)

.scoreSheet-topBar-granularityToggle.tooltip.tooltip--label[data-tooltip]:hover:after{
	transition-delay: 1500ms;
}

.scoreSheet-topBar-granularityToggle button{
	min-width: 72px;
	width: 72px;
	border-radius: 0px;
	position: relative;
	margin-right: -1px;
	color: $textBlack;
	letter-spacing: 0.01em;
}

.scoreSheet-topBar-granularityToggle button.is--active{
	background: $blue-light;
	z-index: 10;
	border-color: $blue;
	color: $white;
	font-weight: $lato-bold;
}

.scoreSheet-topBar-granularityToggle button:first-child{
	border-top-left-radius: 3px;
	border-bottom-left-radius: 3px;
}

.scoreSheet-topBar-granularityToggle button:last-child{
	border-top-right-radius: 3px;
	border-bottom-right-radius: 3px;
}

// MAKE REPORTS PACK 
.scoreSheet-topBar-right button.btn.studentpack{
	min-width: 140px;
	width: 140px;
	color: $textBlack;
	font-weight: $lato-bold;

	margin-left: 8px;
	margin-right: 8px;
}

// COLLAPSE SETS AND EXPORT CSV 

.scoreSheet-topBar-right button.btn.collapsesets,
.scoreSheet-topBar-right button.btn.exportcsv{
	min-width: 39px;
	width: 39px;
}

.scoreSheet-topBar-right button.btn.collapsesets{
	border-top-right-radius: 0px;
	border-bottom-right-radius: 0px;
	position: relative;
	z-index: 5;
}


.scoreSheet-topBar-right button.btn.exportcsv{
	margin-right: 8px;
	border-top-left-radius: 0px;
	border-bottom-left-radius: 0px;
	margin-left: -1px;
}

.scoreSheet-topBar-right button.btn.collapsesets svg,
.scoreSheet-topBar-right button.btn.exportcsv svg{
	fill: $gray-5;
}

.scoreSheet-topBar-right button.btn.collapsesets svg{
	height: 19px;
	width: 19px;
	margin-top: 1px;
}

.scoreSheet-topBar-right button.btn.exportcsv svg{
	height: 19px;
	width: 19px;
}

.scoreSheet-topBar-right button.btn.btn--blue.collapsesets svg{
	fill: $white;
}

/// DROPDOWN

.scoreSheet-topBar-right button.btn.menu svg{
	fill: $gray-5;
}

// positioning the dropdown
.scoreSheet-topBar .dropdownMenu.position--left{
	left: -190px;
	width: 230px;
}
