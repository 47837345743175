//
// ELA: KEY TEXTS


//
// ROMEO AND JULIET 

.discover-market--ELA-romeoJulietContainer{
	margin-bottom: 20px;
	display: flex;
}


.discover-market--ELA-romeoJulietLeftContainer{
	width: 400px;
	flex-grow: 1;
	flex-shrink: 1;

	cursor: default;
	user-select: none;
}

.discover-market--ELA-romeoJulietRightContainer{
	width: 700px;
	flex-grow: 1;
	flex-shrink: 1;
}


.discover-market--ELA-romeoJuliet-header,
.discover-market--ELA-allTexts-header{
	text-transform: uppercase;

	user-select: none;
	cursor: default;

	font-size: 15.5px;
	font-weight: $lato-bold;
	
	color: $textBlack;

	margin-bottom: 8px;
}


.discover-market--ELA-romeoJuliet-description{
	font-size: 14px;
	font-weight: $lato-medium;
	color: $textDark;
	letter-spacing: 0.002em;
	max-width: 320px;
}

.discover-market--ELA-romeoJuliet-description-p{
	margin-bottom: 8px;
}



//
// ROMEO + JULIET

a.discover-market--ELA-romeoJuliet-repoLink{
	height: 125px;
	width: 100%;

	box-sizing: border-box;
	padding-top: 5px;

	display: block;

	cursor: pointer;

	display: flex;

	padding-left: 16px;
	padding-right: 8px;
	border-radius: 5px;

	// background: $tempr;
}

a.discover-market--ELA-romeoJuliet-repoLink:active{
	background: $bgColor-activeRepoLink;
}

.discover-market--ELA-romeoJulietRightContainer:hover
a.discover-market--ELA-romeoJuliet-repoLink{
	opacity: 0.8;
}

.discover-market--ELA-romeoJulietRightContainer
a.discover-market--ELA-romeoJuliet-repoLink:hover{
	opacity: 1;
}

//
//

.discover-market--ELA-romeoJuliet-repoLink-left{
	width: 115px;
	flex-grow: 0;
	flex-shrink: 0;
}

.discover-market--ELA-romeoJuliet-repoLink-repoImageContainer{
	width: 105px;
	height: 105px;
	background: $gray-14;
	border-radius: 4px;
	overflow: hidden;
	position: relative;

}

img.discover-market--ELA-romeoJuliet-repoLink-repoImage{
	position: absolute;
	top: 0px;
	left: 0px;
	right: 0px;
	bottom: 0px;
	height: 100%;
	width: 100%;
}

//
//

.discover-market--ELA-romeoJuliet-repoLink-center{
	flex-grow: 1;
	flex-shrink: 1;

	min-width: 0;
}


// CREATOR
.discover-market--ELA-romeoJuliet-repoLink-center-creator{
	margin-top: 6px;
	font-size: 14.5px;
	font-weight: $lato-semibold;
	color: $textBlue;
	margin-bottom: 4px;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  max-width: 300px;
}

// DESCRIPTION
.discover-market--ELA-romeoJuliet-repoLink-center-description{
	font-size: 13px;
	font-weight: $lato-medium;
	color: $textDark;

	max-width: 300px;
	letter-spacing: 0.002em;
}

a.discover-market--ELA-romeoJuliet-repoLink:hover
.discover-market--ELA-romeoJuliet-repoLink-center-description{
	color: $textBlack;
}


// RATING
.discover-market--ELA-romeoJuliet-repoLink-center-ratingContainer{
	margin-top: 4px;
	font-size: 12.5px;
	font-weight: $lato-medium;
	opacity: 0.8;
	margin-bottom: 3px;
}

.discover-market--ELA-romeoJuliet-repoLink:hover
.discover-market--ELA-romeoJuliet-repoLink-center-ratingContainer{
	opacity: 1;
}

.discover-market--ELA-romeoJuliet-repoLink-center-ratingContainer .discover-repoRatingStars-stars{
	margin-top: 1px;
}

.discover-market--ELA-romeoJuliet-repoLink-center-ratingContainer .discover-repoRatingStars-stars svg{
	width: 11px;
	height: 11px;
	margin-right: 1px;
}


//
//

.discover-market--ELA-romeoJuliet-repoLink-right{
	width: 30px;
	flex-grow: 0;
	flex-shrink: 0;

	display: flex;
	justify-content: flex-end;

}

.discover-market--ELA-romeoJuliet-repoLink svg.icon{
	width: 11px;
	height: 11px;
	// fill: $gray-10;
	margin-top: 0px;
}




.discover-market--ELA-romeoJuliet-repoLink-right svg.icon{
	width: 11px;
	height: 11px;
	fill: $gray-10;
	margin-top: 10px;
}

.discover-market--ELA-romeoJuliet-repoLink:hover 
.discover-market--ELA-romeoJuliet-repoLink-right svg.icon{
	fill: $blue;
}



//
// ALL TEXT SECTION

.discover-market--ELA-allTexts-header{
	margin-bottom: -32px;
}

$bookHeight-discoverELA-allTextsRepoLink: 170px;

.discover-market--ELA-allTextsContainer{
	position: relative;
	margin-top: 24px;
	padding-top: 48px;
}

.discover-market--ELA-allTexts-divider{
	width: calc(100% + 45px);
	height: 1px;
	background: $gray-115;
	position: absolute;
	top: 0px;
	left: 0px;
	box-shadow: 0px 6px 12px 0px rgba(0,0,40,0.32), 0px 2px 4px 0px rgba(0,0,40,0.12), 0px -1px 2px 0px rgba(0,0,40,0.12);
}

.discover-market-allTexts-bookshelfRow{
	display: flex;
	width: 100%;
	position: relative;
	padding-top: 52px;
}

.discover-market-allTexts-bookshelfRow-shelf{
	position: absolute;
	left: 0px;
	bottom: 0px;
	height: 1px;
	background: $gray-115;
	right: -45px;
	box-shadow: 0px 6px 12px 0px rgba(0,0,40,0.32), 0px 2px 4px 0px rgba(0,0,40,0.12), 0px -1px 2px 0px rgba(0,0,40,0.12);
}



a.discover-market--ELA-allTexts-repoLink{
	height: calc(#{$bookHeight-discoverELA-allTextsRepoLink} + 7px);
	width: 50%;

	box-sizing: border-box;
	padding-top: 5px;

	cursor: pointer;

	display: flex;

	padding-right: 8px;
	border-radius: 5px;
}

a.discover-market--ELA-allTexts-repoLink:active{
	background: $bgColor-activeRepoLink;
}

.discover-market--ELA-allTexts-repoLink-left{
	width: calc((#{$bookHeight-discoverELA-allTextsRepoLink} * 0.6521) + 12px);
	flex-grow: 0;
	flex-shrink: 0;
}

.discover-market--ELA-allTexts-repoLink-bookImageContainer{
	width: calc(#{$bookHeight-discoverELA-allTextsRepoLink} * 0.6521);
	height: $bookHeight-discoverELA-allTextsRepoLink;
	position: relative;

	background: $gray-13;
	border: 1px solid $lineMid;
	border-bottom: 1px solid $gray-115;
	box-shadow: 0px 1px 2px 0px rgba(0,0,40,0.12);
}

.discover-market--ELA-allTexts-repoLink-bookImage{
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0px;
	left: 0px;
}

.discover-market--ELA-allTexts-repoLink-center{
	flex-grow: 1;
	flex-shrink: 1;

	min-width: 0;
}

.discover-market--ELA-allTexts-repoLink-center-textTitle{
	font-size: 15px;
	font-weight: $lato-bold;
	color: $textBlackDark;
	margin-top: 10px;
	margin-bottom: 3px;
	letter-spacing: 0.015em;
}

.discover-market--ELA-allTexts-repoLink-center-textAuthor{
	font-size: 13.5px;
	font-weight: $lato-medium;
	color: $textMid;

	margin-bottom: 16px;
}

.discover-market--ELA-allTexts-repoLink-center-creator{
	font-size: 14px;
	font-weight: $lato-medium;
	color: $textBlue;
	margin-bottom: 4px;

	white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

// RATING
.discover-market--ELA-allTexts-repoLink-center-ratingContainer{
	margin-top: 4px;
	font-size: 12.5px;
	font-weight: $lato-medium;
	opacity: 0.8;
	margin-bottom: 3px;
}

.discover-market--ELA-allTexts-repoLink:hover
.discover-market--ELA-allTexts-repoLink-center-ratingContainer{
	opacity: 1;
}

.discover-market--ELA-allTexts-repoLink-center-ratingContainer .discover-repoRatingStars-stars{
	margin-top: 1px;
}

.discover-market--ELA-allTexts-repoLink-center-ratingContainer .discover-repoRatingStars-stars svg{
	width: 11px;
	height: 11px;
	margin-right: 1px;
}




.discover-market--ELA-allTexts-repoLink-center-description{
	font-size: 13px;
	font-weight: $lato-medium;
	color: $textMid;

	max-width: 300px;
	letter-spacing: 0.002em;

	display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;

	text-overflow: ellipsis;
  overflow: hidden;

  max-height: 58px;
}

a.discover-market--ELA-allTexts-repoLink:hover
.discover-market--ELA-allTexts-repoLink-center-description{
	color: $textBlack;
}



.discover-market--ELA-allTexts-repoLink-right{
	width: 36px;
	flex-grow: 0;
	flex-shrink: 0;

	display: flex;
	justify-content: flex-end;

	box-sizing: border-box;
	padding-right: 20px;

}

.discover-market--ELA-allTexts-repoLink-right svg.icon{
	width: 11px;
	height: 11px;
	fill: $gray-10;
	margin-top: 10px;
}

.discover-market--ELA-allTexts-repoLink:hover 
.discover-market--ELA-allTexts-repoLink-right svg.icon{
	fill: $blue;
}

