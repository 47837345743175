$editorSidePanelPadding: 14px;
//$editorSidePanelContrastBG: rgb(249,249,250);
$editorSidePanelContrastBG: $invisible;
//$editorSidePanelContrastBGBorder: rgb(220,220,221);
$editorSidePanelContrastBGBorder: $invisible;


.editor-sidePanel-video{
	width: 100%;
	height: 100%;

	text-align: left;

	font-size: 14px;

	box-sizing: border-box;

	display: flex;
	flex-direction: column;
	
	border-radius: 6px;
}

.editor-sidePanel-video-header{
	padding-left: $editorSidePanelPadding;
	padding-right: $editorSidePanelPadding;

	display: flex;
	align-items: center;

	margin-top: 8px;

	cursor: default;
	user-select: none;
}

.editor-sidePanel-video-header-icon svg{
	height: 24px;
	width: 24px;
	fill: $red;

	position: relative;
	left: -2px;
	opacity: 0.85;
}

.editor-sidePanel-video-header-duration{
	font-size: 12px;
	// font-weight: $lato-medium;
	// color: $textMid;
	margin-left: auto;

	// background: $blue-extraLight;
	padding-left: 5px;
	padding-right: 5px;
	padding-top: 2px;
	padding-bottom: 2px;

	border-radius: 2px;

	// color: $blue;
	color: lighten($textMid, 5%);
	background: $gray-14;
	font-weight: $lato-bold;
	letter-spacing: 0.01em;
}

.editor-sidePanel-video-top{
	padding-left: $editorSidePanelPadding;
	padding-right: $editorSidePanelPadding;
	padding-top: 1px;

	flex-grow: 0;
	flex-shrink: 0;

	cursor: default;
}

.editor-sidePanel-video-top-title{
	font-size: 15px;
	font-weight: $lato-bold;
	color: $textBlack;
	margin-bottom: 4px;
}

.editor-sidePanel-video-top-author{
	font-size: 13px;
	font-weight: $lato-semibold;
	color: $blue;

	margin-bottom: 4px;
}

.editor-sidePanel-video-top-URL{
	font-size: 12px;
	font-weight: $lato-medium;
	color: $textLight;
	text-decoration: underline;
	cursor: pointer;
}

.editor-sidePanel-video-top-URL:hoveR{
	color: $textDark;
}

.editor-sidePanel-video-edit,
.editor-sidePanel-video-actions{
	width: 100%;
	background: $editorSidePanelContrastBG;
	border-top: 1px solid $editorSidePanelContrastBGBorder;
	border-bottom: 1px solid $editorSidePanelContrastBGBorder;
	
	box-sizing: border-box;

	padding-left: $editorSidePanelPadding;
	padding-right: $editorSidePanelPadding;

	flex-grow: 0;
	flex-shrink: 0;
}

button.editor-sidePanel-video-edit-editBtn{
	margin-top: 16px;
	margin-bottom: 12px;

	cursor: pointer;

	width: 100%;
	height: 30px;

	border-radius: 5px;

	background: $white;
	color: $textBlackDark;

	font-size: 13.5px;
	font-weight: $lato-bold;

	letter-spacing: 0.02em;

	// border: 1px solid rgb(212,212,220);
	// border-bottom: 1px solid rgb(192,192,205);

	box-shadow: 0px 1px 1px 0px rgba(0,0,40,0.08);

	display: flex;
	align-items: center;
	justify-content: center;
}


button.editor-sidePanel-video-edit-editBtn svg{
	height: 14px;
	width: 14px;
	margin-right: 6px;
	margin-left: -10px;
	fill: $blue;
}

button.editor-sidePanel-video-edit-editBtn{
	background: $blue;
	border: 1px solid darken($blue, 7%);
	color: $white;
}

button.editor-sidePanel-video-edit-editBtn svg{
	fill: $white;
	opacity: 0.8;
}

button.editor-sidePanel-video-edit-editBtn:hover{
	background: darken($blue, 2%);
	border: 1px solid darken($blue, 9%);
	box-shadow: 0px 1px 1px 0px rgba(0,0,40,0.12);
}

button.editor-sidePanel-video-edit-editBtn:hover  svg{
	opacity: 0.9;
}


//

.editor-sidePanel-video-settings{
	flex-grow: 1;
	flex-shrink: 1;
}

button.editor-sidePanel-video-removeBtn{
	margin-top: 32px;
	margin-bottom: 12px;

	cursor: pointer;

	width: 100%;
	height: 30px;

	border-radius: 5px;

	background: $white;
	color: $textMid;

	font-size: 13px;
	font-weight: $lato-bold;

	border: 1px solid $lineMid;

	box-shadow: 0px 1px 0px 0px rgba(0,0,40,0.12);

}

button.editor-sidePanel-video-removeBtn:hover{
	color: $textDark;
	background: $white;	
	border: 1px solid rgb(213,212,220);
}

//
// OPTIONS

.editor-sidePanel-video-settings-option{
	padding-left: calc(#{$editorSidePanelPadding} / 2);
	padding-right: calc(#{$editorSidePanelPadding} / 2);
	margin-left: calc(#{$editorSidePanelPadding} / 2);
	margin-right: calc(#{$editorSidePanelPadding} / 2);
	box-sizing: border-box;
	width: calc(100% - #{$editorSidePanelPadding});

	// background: $tempr;

	padding-top: calc(#{$editorSidePanelPadding} / 2); 
	padding-bottom: calc(#{$editorSidePanelPadding} / 2); 

	border-radius: 2px;

	opacity: 0.88;
}

.editor-sidePanel-video-settings-option:hover{
	cursor: pointer;
	opacity: 1;
}

.editor-sidePanel-video-settings-option-titleRow{
	display: flex;
	
}

.editor-sidePanel-video-settings-option-titleRow-title{
	font-size: 13px;
	font-weight: $lato-semibold;
	color: $textBlackDark;
	letter-spacing: 0.02em;
}

.editor-sidePanel-video-settings-option-titleRow-checkbox{
	margin-left: auto;
	height: 14px;
	width: 14px;
	cursor: pointer;

	border-radius: 3px;
	position: relative;
	flex-shrink: 0;
	flex-grow: 0;
	box-sizing: border-box;
}

.editor-sidePanel-video-settings-option--checked
.editor-sidePanel-video-settings-option-titleRow-checkbox{
	border: 1px solid darken($blue, 10%);
	background: $blue;
}

.editor-sidePanel-video-settings-option-titleRow-checkbox svg{
	position: absolute;
	left: 1px;
	top: 1px;
	width: 10px;
	height: 10px;
}

.editor-sidePanel-video-settings-option--checked
.editor-sidePanel-video-settings-option-titleRow-checkbox svg #border{
	fill: darken($blue, 10%);
}

.editor-sidePanel-video-settings-option--checked
.editor-sidePanel-video-settings-option-titleRow-checkbox svg #fill{
	fill: $white;
}

.editor-sidePanel-video-settings-option--unchecked
.editor-sidePanel-video-settings-option-titleRow-checkbox{
	border: 1px solid $lineDark;
	background: $white;
}


.editor-sidePanel-video-settings-option--unchecked
.editor-sidePanel-video-settings-option-titleRow-checkbox svg{
	display: none;
}



.editor-sidePanel-video-settings-option-description{
	font-size: 12px;
	font-weight: $lato-medium;
	color: $textDark;	

	margin-top: 2px;

	box-sizing: border-box;
	padding-right: 24px;

	letter-spacing: 0.01em;
}

