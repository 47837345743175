.add-edit-paypal .modal-content{
	height: 470px;
	width: 620px;

	border-radius: 10px;
	padding-left: 44px;
	padding-right: 66px;
	padding-top: 36px;
	box-sizing: border-box;
}
.addEditPayPalModal{ 
	width: 100%;
	height: 100%;
	position: relative;
}

.addEditPayPalModal-header,
.addEditPayPalModal-sub{
	user-select: none;
	cursor: default;
}

.addEditPayPalModal-header{
	font-size: 28px;
	font-weight: $lato-heavy;
	margin-bottom: 10px;
	color: $textBlackDark;
}

.addEditPayPalModal-sub {
	margin-bottom: 8px;
	font-weight: $lato-medium;
	color: $textMid;
	font-size: 16px;
}


.addEditPayPalModal-sub a,
.addEditPayPalModal-sub a:visited{
	color: $blue-bold;
	text-decoration: underline !important;
}

.addEditPayPalModal-sub-removeEmail{
	font-size: 13px;
	font-weight: $lato-medium;
	color: $textLight;
	margin-top: 4px;
	cursor: pointer;
	width: 220px;
	margin-left: -4px;
	padding-left: 4px;
	padding-right: 4px;
	padding-top: 2px;
	padding-bottom: 2px;
	border-radius: 3px;

}

.addEditPayPalModal-sub-removeEmail:hover{
	color: $red-dark;
}


a.addEditPayPalModal-link,
a.addEditPayPalModal-link:visited{
	font-size: 14.5px;
	font-weight: $lato-semibold;
	color: $textLight;
	padding-top: 4px;
	padding-bottom: 4px;
	cursor: pointer;
	line-height: 1.3;
}

a.addEditPayPalModal-link:hover,
a.addEditPayPalModal-link:visited:hover{
	color: $textBlue;
	text-decoration: underline !important; //overwrite normalized base
}

// FORM

.addEditPayPalModal form{
	width: 480px;
}

.addEditPayPalModal form .formField.is--text.is--email,
.addEditPayPalModal form .formField.is--text.is--emailsMatch{
	margin-bottom: 0px;
}

.addEditPayPalModal form .formField.is--text.is--email input,
.addEditPayPalModal form .formField.is--text.is--emailsMatch input{
	height: 42px;	
	padding-left: 12px;
	font-weight: $lato-bold;
}

.addEditPayPalModal form .formField.is--text.is--email input::-webkit-input-placeholder,
.addEditPayPalModal form .formField.is--text.is--emailsMatch input::-webkit-input-placeholder{
	color: $textLight !important;
}

// FEES

.addEditPayPalModal-fees{
	margin-top: 14px;
	cursor: default;
	font-size: 13px;
	font-weight: $lato-medium;
	color: $textDark;
	user-select: none;
}

.addEditPayPalModal-fees-para{
	margin-bottom: 7px;
}

.addEditPayPalModal-fees a,
.addEditPayPalModal-fees a:visited{
	color: $blue-bold;
	text-decoration: underline !important;
}

// Creator Fund.com



// BUTTONS




.addEditPayPalModal-actionRow{
	position: absolute;
	display: flex;
	bottom: 36px;
	left: 0px;
	right: 66px;
}

.addEditPayPalModal-actionRow button{
	font-weight: $lato-bold;
	letter-spacing: 0.005em;
	cursor: pointer;
}

.addEditPayPalModal-actionRow button.submitpaypal{
	width: 400px;
	margin-right: 14px;
}

.addEditPayPalModal-actionRow button.cancel{
	width: 160px;
}


// //

// .accountForm-updatePaymentModal-hiddenEmailInput{
// 	visibility: hidden;
// 	height: 0px;
// }

// .accountForm-updatePaymentModal-addressRowContainer{
// 	display: flex;
// 	margin-top: -40px;
// }

// .formField.is--updatePaymentModal-streetAddress{
// 	width: 10px;
// 	flex-grow: 1;
// 	flex-shrink: 0;
// 	margin-right: 8px;
// }

// .formField.is--updatePaymentModal-postalCode{
// 	width: 80px;
// 	flex-grow: 0;
// 	flex-shrink: 0;
// }

// .update-payment-info .StripeElement{
// 	width: 100%;
// 	height: 42px;

// 	background: $white;

// 	font-size: 16px;
// 	border-radius: 4px;
// 	font-weight: $lato-medium;
// 	color: $textBlack;

// 	margin-top: -12px;
// 	margin-bottom: 24px;

// 	padding-left: 13px;
// 	padding-top: 10px;
// 	box-sizing: border-box;

// 	border: 1px solid rgb(200,200,210);

// 	box-shadow: none;
// 	outline: none;
// 	-webkit-appearance: none;

// 	font-family: 'Lato';
// }

// .update-payment-info .StripeElement--focus{
// 	outline: none;
// 	border: 1px solid $blue;
// 	background: $white;	
// }

// // Buttons

// //  Hacky, try and disable if 
// // .StripeElement--empty ~ .updatePaymentInfo-actionRow button.update-payment{
// // 	pointer-events: none;
// // 	// stolen from disabled, better way to do this 
// // 	color: $white;
// // 	background: linear-gradient(to bottom, rgb(155,155,163) 0%, rgb(155,155,161) 100%); 
// // 	border-color: rgb(146,146,156);
// // 	pointer-events: none;
// // 	cursor: default;
// // }
