.billing-info .modal-content{
	width: 80vw;
	max-width: 1200px;
	min-width: 900px;
	height: auto;
	max-height: 90vh;

	position: relative;
	overflow: hidden;
}

.billingInfo-content{

	padding-left: 64px;
	padding-right: 64px;
	padding-top: 16px;

	box-sizing: border-box;
	cursor: default;

	min-height: 400px;
	height: auto;
	max-height: 80vh;
	overflow-y: scroll;
	overflow-x: hidden;
}

.billingInfo-top{
	width: 100%;
	height: 70px;

	background: $white;
	border: 1px solid $lineMid;
	border-radius: 4px;

	margin-bottom: 24px;

	display: flex;

	padding-left: 22px;
	padding-right: 22px;
	box-sizing: border-box;

	align-items: center;
	cursor: default;
}

.billingInfo-top-item{
	width: 160px;
}

.billingInfo-top-item-label{
	font-size: 14px;
	font-weight: $lato-medium;
	color: $textMid;
	margin-bottom: 1px;
}

.billingInfo-top-item-value{
	font-size: 16px;
	font-weight: $lato-bold;
	color: $textBlack;
}

// ROWS

.billingInfo-invoiceTable{
	margin-bottom: 96px;
	cursor: default;
}

.billingInfo-invoiceRow{
	display: flex;
	border-bottom: 1px solid $lineMid;

}

.billingInfo-invoiceRow--headerRow{
	color: $textMid;
	font-weight: $lato-medium;
	font-size: 13px;
	height: 24px;
}

.billingInfo-invoiceRow--dataRow{
	font-weight: $lato-medium;
	color: $textDark;
	font-size: 15px;
	height: 28px;
}

.billingInfo-invoiceRow-col{
	flex-grow: 0;
	flex-shrink: 1;

	display: flex;
	align-items: center;
}

.billingInfo-invoiceRow-col--date{
	width: 180px;
}

.billingInfo-invoiceRow-col--description{
	width: 150px;
}

.billingInfo-invoiceRow-col--servicePeriod{
	width: 280px;
}

.billingInfo-invoiceRow-col--paymentMethod{
	width: 280px;
}

.billingInfo-invoiceRow-col--invoice{
	width: 50px;
}

.billingInfo-invoiceRow-col--invoice .icon{
  fill: $blue-light;
}

.billingInfo-invoiceRow-col--total{
	min-width: 80px;
	flex-grow: 1;
	text-align: right;
	justify-content: flex-end;
}

.billingInfo-invoiceRow--dataRow
.billingInfo-invoiceRow-col--total{
	font-weight: $lato-semibold;
	color: $textBlack;
}

// Done Button

.billingInfo-doneBtnContainer{
	position: absolute;
	bottom: 0px;
	left: 0px;
	right: 0px;
	height: 64px;
	background: $white;
	border-top: 1px solid $lineMid;
	display: flex;
	align-items: center;
	justify-content: center;
}

.billingInfo-doneBtnContainer .btn{
	width: 250px;
	min-width: 250px;
}
