

.editor-emojiSymbolPicker-listContainer{
	overflow-x: hidden;
	overflow-y: overlay;
}

.editor-emojiSymbolPicker-listContainer::-webkit-scrollbar {
  width: 8px;
}

// .editor-slideListContainer{
// 	scrollbar-width: thin;
// 	scrollbar-color: red green;
// }

// track
.editor-emojiSymbolPicker-listContainer::-webkit-scrollbar-track {
  background: rgba(0,0,20,0.04);
}
 
//thumb
.editor-emojiSymbolPicker-listContainer::-webkit-scrollbar-thumb {
  background: rgba(0,0,20,0.12);  
}


.editor-emojiSymbolPicker-listContainer{
	scrollbar-width: thin;
	scrollbar-color: rgba(0,0,20,0.12) rgba(0,0,20,0.04);
}



// //
// // HOVER ON LIST
// .editor-emojiSymbolPicker-listContainer:hover::-webkit-scrollbar {
//   width: 8px;
//   // width: 10px;
// }

// // thumb
// .editor-emojiSymbolPicker-listContainer:hover::-webkit-scrollbar-thumb{
// 	background: $tempg;
// 	background: rgba(0,0,24,0.18);
// }

// // track
// .editor-emojiSymbolPicker-listContainer:hover::-webkit-scrollbar-track {  
//   background: rgba(0,0,20,0.04);
//   // background: $tempr;
// }




.editor-emojiSymbolPicker-list{
	padding-bottom: 12px;
}

.editor-emojiSymbolPicker-list-group{

}

.editor-emojiSymbolPicker-list-group-title{
	font-size: 13px;
	font-weight: $lato-semibold;
	color: $textBlack;
	height: 40px;
	width: 100%;
	letter-spacing: 0.01em;

	box-sizing: border-box;
	padding-left: $sidePaddingInner-emojiSymbolPicker;
	padding-left: 16px;

	padding-top: 14px;

	text-align: left;

	line-height: 2;
}

.editor-emojiSymbolPicker-list-group--recent .editor-emojiSymbolPicker-list-group-title,
.editor-emojiSymbolPicker-list-group--search-results .editor-emojiSymbolPicker-list-group-title{
	margin-top: -4px;
}


.editor-emojiSymbolPicker-list-group-items{
	padding-left: $sidePadding-emojiSymbolPicker;	
	padding-right: $sidePadding-emojiSymbolPicker;
	display: flex;
	flex-wrap: wrap;
}

button.editor-emojiSymbolPicker-list-group-item{
	width: $rowSideDimension-emojiPickerSymbol-item;
	height: $rowSideDimension-emojiPickerSymbol-item;

	cursor: pointer;

	border: none;
	background: none;
	outline: none;

	
	
	position: relative;
}



.editor-emojiSymbolPicker-list-group-item-inner{
	position: absolute;
	top: 2px;
	left: 2px;
	right: 2px;
	bottom: 2px;
	
	border-radius: 4px;

	display: flex;
	align-items: center;
	justify-content: center;

	box-sizing: border-box;
	//padding-bottom: 1px; // improves vertical spacing a little bi

	//temp for emoji
	font-size: 22px;

	border: 1px solid lighten($gray-12, 2%);
  // border-bottom: 1px solid $gray-9;
  border-radius: 4px;
  background: $white;
  color: $textBlack;
  //box-shadow: 0px 1px 1px 0px rgba(0,0,20,0.04);

  user-select: none;
}






button.editor-emojiSymbolPicker-list-group-item:hover  .editor-emojiSymbolPicker-list-group-item-inner{
	// background: transparentize($blue, 0.9);
	border: 1px solid $gray-9;
	border-bottom: 1px solid $gray-7;
	color: $textBlackDark;
}


.editor-emojiSymbolPicker-list-group--recent button.editor-emojiSymbolPicker-list-group-item,
.editor-emojiSymbolPicker-list-group--search-results button.editor-emojiSymbolPicker-list-group-item{
	width: 48px;
	height: 48px;
}

.editor-emojiSymbolPicker-list-group--recent .editor-emojiSymbolPicker-list-group-item-inner,
.editor-emojiSymbolPicker-list-group--search-results .editor-emojiSymbolPicker-list-group-item-inner{
	
	border-radius: 5px;

	font-size: 24px;
}

.editor-emojiSymbolPicker-list-group--recent .editor-emojiSymbolPicker-list-group-title{
	color: $invisible;
	font-size: 2px;
	height: 12px;
}










//
//

.editor-emojiSymbolPicker-list-group-item-tooltip{	
  position: absolute;
  
  top: 41px;

  background: lighten(#282636, 7%);
  z-index: 10;
  pointer-events: none;

  left: 50%;
  transform: translate(-50%, 0);

  padding-top: 3px;
  padding-bottom: 4px;
  padding-left: 6px;
  padding-right: 6px;

  border-radius: 3px;
  opacity: 0;
  
  font-size: 12px;
  font-weight: $lato-semibold;
  letter-spacing: 0.025em;
  color: $white;

  white-space: nowrap;

  min-width: 42px;
}

.editor-emojiSymbolPicker-list-group-item-tooltip{
	opacity: 0;
}

button.editor-emojiSymbolPicker-list-group-item:hover
.editor-emojiSymbolPicker-list-group-item-tooltip{
	opacity: 0.85;
	transition: opacity 100ms linear;
	transition-delay: 650ms;
}

.editor-emojiSymbolPicker-list-group--recent
.editor-emojiSymbolPicker-list-group-item-tooltip{
	top: 47px;
}

.editor-emojiSymbolPicker-list-group--recent
button.editor-emojiSymbolPicker-list-group-item:hover
.editor-emojiSymbolPicker-list-group-item-tooltip{
	transition-delay: 250ms;
}

// TOOLTIPS AT EDGE
// for recent and not-recent, different number of symbols per row so different selectors
// Shift tooltips at left edge
.editor-emojiSymbolPicker-list-group:not(.editor-emojiSymbolPicker-list-group--recent)
button.editor-emojiSymbolPicker-list-group-item:nth-child(8n+1)
.editor-emojiSymbolPicker-list-group-item-tooltip,
.editor-emojiSymbolPicker-list-group.editor-emojiSymbolPicker-list-group--recent
button.editor-emojiSymbolPicker-list-group-item:nth-child(7n+1)
.editor-emojiSymbolPicker-list-group-item-tooltip{	
	left: -6px;
	transform: translate(0%,0%);	
}

.editor-emojiSymbolPicker-list-group:not(.editor-emojiSymbolPicker-list-group--recent)
button.editor-emojiSymbolPicker-list-group-item:nth-child(8n+8)
.editor-emojiSymbolPicker-list-group-item-tooltip,
.editor-emojiSymbolPicker-list-group.editor-emojiSymbolPicker-list-group--recent
button.editor-emojiSymbolPicker-list-group-item:nth-child(7n+7)
.editor-emojiSymbolPicker-list-group-item-tooltip {	
	// background: $red !important; 
	left: unset;
	right: -6px;
	transform: translate(0%,0%);	
}


///
/// DROPDOWN
/// CUSTOM STYLING FOR SYMBOLS
/// some symbols need to artifically decreased or increased font-size to look nice in preview
///

.editor-emojiSymbolPicker-list-group-item.editor-emojiSymbolPicker-list-group-item--long-arrow .editor-emojiSymbolPicker-list-group-item-inner{
	font-size: 18px;
	padding-bottom: 1px;
}

.editor-emojiSymbolPicker-list-group-item.editor-emojiSymbolPicker-list-group-item--blank .editor-emojiSymbolPicker-list-group-item-inner{
	letter-spacing: -0.08em;
	padding-right: 1px;
}

.editor-emojiSymbolPicker-list-group-item.editor-emojiSymbolPicker-list-group-item--celsius .editor-emojiSymbolPicker-list-group-item-inner,
.editor-emojiSymbolPicker-list-group-item.editor-emojiSymbolPicker-list-group-item--farenheit .editor-emojiSymbolPicker-list-group-item-inner{
	font-size: 20px;
	padding-right: 1px;
}

// ROMAN NUMERALS
.editor-emojiSymbolPicker-list-group-item.editor-emojiSymbolPicker-list-group-item--roman-numeral-one .editor-emojiSymbolPicker-list-group-item-inner,
.editor-emojiSymbolPicker-list-group-item.editor-emojiSymbolPicker-list-group-item--roman-numeral-two .editor-emojiSymbolPicker-list-group-item-inner,
.editor-emojiSymbolPicker-list-group-item.editor-emojiSymbolPicker-list-group-item--roman-numeral-three .editor-emojiSymbolPicker-list-group-item-inner,
.editor-emojiSymbolPicker-list-group-item.editor-emojiSymbolPicker-list-group-item--roman-numeral-four .editor-emojiSymbolPicker-list-group-item-inner,
.editor-emojiSymbolPicker-list-group-item.editor-emojiSymbolPicker-list-group-item--roman-numeral-five .editor-emojiSymbolPicker-list-group-item-inner,
.editor-emojiSymbolPicker-list-group-item.editor-emojiSymbolPicker-list-group-item--roman-numeral-six .editor-emojiSymbolPicker-list-group-item-inner,
.editor-emojiSymbolPicker-list-group-item.editor-emojiSymbolPicker-list-group-item--roman-numeral-seven .editor-emojiSymbolPicker-list-group-item-inner,
.editor-emojiSymbolPicker-list-group-item.editor-emojiSymbolPicker-list-group-item--roman-numeral-eight .editor-emojiSymbolPicker-list-group-item-inner,
.editor-emojiSymbolPicker-list-group-item.editor-emojiSymbolPicker-list-group-item--roman-numeral-nine .editor-emojiSymbolPicker-list-group-item-inner,
.editor-emojiSymbolPicker-list-group-item.editor-emojiSymbolPicker-list-group-item--roman-numeral-ten .editor-emojiSymbolPicker-list-group-item-inner,
.editor-emojiSymbolPicker-list-group-item.editor-emojiSymbolPicker-list-group-item--roman-numeral-eleven .editor-emojiSymbolPicker-list-group-item-inner,
.editor-emojiSymbolPicker-list-group-item.editor-emojiSymbolPicker-list-group-item--roman-numeral-twelve .editor-emojiSymbolPicker-list-group-item-inner,
.editor-emojiSymbolPicker-list-group-item.editor-emojiSymbolPicker-list-group-item--roman-numeral-fity .editor-emojiSymbolPicker-list-group-item-inner,
.editor-emojiSymbolPicker-list-group-item.editor-emojiSymbolPicker-list-group-item--roman-numeral-100 .editor-emojiSymbolPicker-list-group-item-inner,
.editor-emojiSymbolPicker-list-group-item.editor-emojiSymbolPicker-list-group-item--roman-numeral-500 .editor-emojiSymbolPicker-list-group-item-inner,
.editor-emojiSymbolPicker-list-group-item.editor-emojiSymbolPicker-list-group-item--roman-numeral-1000 .editor-emojiSymbolPicker-list-group-item-inner{
	font-size: 18px;
}