.downgradeModalBG{
	position: fixed;
	z-index: 9998;
	top: 0;
	left: 0;
	right: 0;
	height: 100vh;
	
	background: rgba(5,5,10,0.75);
	
	display: flex;
	
	align-items: center;
	justify-content: center;

	overflow: hidden;
}


.downgradeModal--coupon{
	width: 680px;
	height: 430px;
	border-radius: 0px;
	position: relative;

	padding-left: 10px;
	padding-right: 10px;
	padding-top: 31px;
	padding-bottom: 36px;

	box-sizing: border-box;

	background: rgb(248,248,254);
}

.downgradeModal--downgrade{
	width: 650px;
	border-radius: 6px;
	position: relative;

	background: rgb(252,252,255);
	border: 1px solid $gray-5;
	box-shadow: 
		0px 8px 16px 0px rgba(5,5,10,0.12),
		0px 16px 32px 0px rgba(5,5,10,0.08) ;

	padding-left: 72px;
	padding-right: 72px;
	padding-top: 28px;
	padding-bottom: 20px;

	box-sizing: border-box;
}


.downgradeModal--coupon-inner{
	width: 100%;
	height: 100%;
	border-radius: 7px;
	border: 2px solid $gray-13;
	background: rgb(253,253,255);

	box-sizing: border-box;

	padding-top: 36px;
	padding-bottom: 12px;

	padding-left: 40px;
	padding-right: 40px;

	cursor: default;
	user-select: none;

}


//

.downgradeModal--coupon-limitedOfferSash{
	position: absolute;
	top: 20px;
	left: 50%;
	transform: translateX(-50%);

	width: 220px;
	height: 24px;

	font-size: 15px;
	color: $white;
	font-weight: $lato-bold;
	text-align: center;
	letter-spacing: 0.01em;
	line-height: 1.7;
}

.downgradeModal--coupon-limitedOfferSash svg{
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: -1;
	fill: $red;
}

//


.downgradeModal-h1{
	font-size: 36px;
	font-weight: $effra-heavy;
	color: $textBlackDark;

	text-align: center;

	margin-bottom: 18px;
}

.downgradeModal--downgrade .downgradeModal-h1{
	font-size: 32px;
}

.downgradeModal-h1 b{
	font-weight: $effra-heavy;
	color: $textBlue;
}

.downgradeModal-h2{
	font-size: 24px;
	font-weight: $effra-bold;
	color: $textBlackDark;

	text-align: center;
}

.downgradeModal--downgrade .downgradeModal-h2{
	font-size: 20px;
	margin-bottom: 6px;
	text-align: left;
}

.downgradeModal-h3{
	font-size: 18px;
	font-weight: $effra-medium;
	color: $textDark;

	text-align: center;
	margin-bottom: 18px;
}

.downgradeModal--downgrade .downgradeModal-h3{
	font-size: 16px;
	text-align: left;
	margin-bottom: 24px;
}


.downgradeModal--coupon-btnContainer{
	position: absolute;
	bottom: 20px;
	left: 0px;
	right: 0px;
}

button.btn.downgradeModal-primaryButton{
	width: 500px;
	height: 80px;
	border-radius: 7px;
	cursor: pointer;
	border: 1px solid $blue-dark;
	
	color: $white;
	flex-direction: column;

	margin: 0 auto;

	box-shadow: 
		0px 4px 8px 0px rgba(0,0,0,0.12),
		0px 2px 4px 0px rgba(0,0,0,0.04),
		inset 0px 0px 20px rgba(0,0,0,0.03);
}

.downgradeModal--coupon button.btn.downgradeModal-primaryButton{
	width: 600px;
}

.downgradeModal-primaryButton-headline{
	font-size: 22px;
	font-weight: $effra-bold;
	margin-bottom: 3px;
	letter-spacing: 0.005em;
}

.downgradeModal-primaryButton-subline{
	font-size: 13px;
	opacity: 0.95;
	font-weight: $effra-medium;
	letter-spacing: 0.005em;
	margin-bottom: 1px;
}


.downgradeModal--coupon button.downgradeModal-primaryButton{
	background: $blue-light;
	border-color: $blue-dark;
}

.downgradeModal--coupon button.downgradeModal-primaryButton:hover{
	background: darken($blue-light, 3%);
	border-color: darken($blue-dark, 3%);
}


.downgradeModal--downgrade button.downgradeModal-primaryButton{
	background: $red;
	border-color: $red-dark;
	margin-top: 24px;
}

.downgradeModal--downgrade button.downgradeModal-primaryButton:hover{
	background: darken($red, 3%);
	border-color: darken($red-dark, 3%);
}


//

.downgradeModal button.downgrademodalsecondary{
	margin: 0 auto;
	margin-top: 12px;

	min-width: 300px;
	cursor: pointer;
	font-weight: $effra-medium;
}

.downgradeModal--downgrade  button.downgrademodalsecondary{
	color: $textBlackDark;
	font-size: 17px;
	letter-spacing: 0.005em;
	height: 40px;
}

//

.downgradeModal--coupon-holes{
	position: absolute;
	top: 0px;
	bottom: 0px;
	width: 28px;
	z-index: 10;
}

.downgradeModal--coupon-holes .icon.voucher-side{
	width: 28px;
	height: 430px;
	fill: rgb(248,248,254);
}

.downgradeModal--coupon-holes--left{
	left: -27px;
}

.downgradeModal--coupon-holes--right{
	right: -27px;	
}

.downgradeModal--coupon-holes--left .icon.voucher-side{
	transform: rotate(180deg);
}


/// DOWNGRADE FEATURES

.downgradeModal--downgrade-feature{
	display: flex;
	margin-bottom: 0px;
	margin-left: -10px;
}

.downgradeModal--downgrade-feature-left{
	flex-grow: 0;
	flex-shrink: 0;
	height: 100%;
	display: flex;
	margin-right: 5px;
	align-items: center;
	justify-content: center;
}

.downgradeModal--downgrade-feature-left .icon{
	width: 56px;
	height: 40px;
}

.downgradeModal--downgrade-feature-right{
	flex-grow: 1;
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.downgradeModal--downgrade-feature-right-label{
	font-weight: $effra-bold;
	color: $textBlackDark;
	font-size: 18px;
}

.downgradeModal--downgrade-feature-right-subLabel{
	font-weight: $effra-medium;
	color: $textMid;
	font-size: 15px;
	padding-bottom: 4px;
	margin-bottom: 14px;
}

.downgradeModal--downgrade-feature-right-subLabel b {
	color: $textBlackDark;
	font-weight: $effra-medium;
}


// SUBMITLOADING

.downgradeModal-primaryButton-submitLoading{
	display: flex;
	align-items: center;
	justify-content: center;
}

.downgradeModal-primaryButton-submitLoading-spinnerContainer{
	width: 50px;
	height: 50px;	
	display: flex;
	align-items: center;
	justify-content: center;
}

.downgradeModal-primaryButton-submitLoading-spinner{

	height: 32px;
	width: 32px;
  
  border-radius: 50%;

  border: 5px rgba(0,0,0,0.2) solid;
  border-top: 5px $white solid;

  opacity: 0.75;

  animation: spinnerRotateAnimation 0.4s infinite linear;
}




// CLOSE BUTTON
// Refactor out for use on marketing modals
// Used on Marketing Modals

.downgradeModal-closeBtn{
	position: absolute;
	width: 42px;
	height: 42px;
	top: 20px;
	right: 24px;
	cursor: pointer;
	border-radius: 21px;

	opacity: 0.9;
	transition: opacity 100ms linear;
	z-index: 9999;
}

.downgradeModal-closeBtn:hover{
	opacity: 0.95;
	transition: opacity 50ms linear;
}

.downgradeModal-closeBtn:active{
	opacity: 1;
}

.downgradeModal-closeBtn .icon{
	width: 42px;
	height: 42px;
	fill: $gray-6;
}

// FEEDBACK


.downgradeModal--feedback{
	width: 650px;
	border-radius: 6px;
	position: relative;

	background: rgb(252,252,255);
	border: 1px solid $gray-5;
	box-shadow: 
		0px 8px 16px 0px rgba(5,5,10,0.12),
		0px 16px 32px 0px rgba(5,5,10,0.08) ;

	padding-left: 36px;
	padding-right: 36px;
	padding-top: 36px;
	padding-bottom: 20px;

	box-sizing: border-box;
}

.downgradeModal--feedback-h1{
	font-size: 24px;
	font-weight: $effra-bold;
	color: $textBlack;

	text-align: center;

	margin-bottom: 12px;
}

.downgradeModal--feedback-h2{
	font-size: 18px;
	font-weight: $effra-medium;
	color: $textDark;

	text-align: center;

	margin-bottom: 18px;
}

.downgradeModal--feedback button.btn.btn--blue.btn--xLarge{
	font-size: 22px;
	height: 45px;
	margin: 0 auto;
	width: 320px;
	font-weight: $effra-bold;
	margin-bottom: 3px;
	letter-spacing: 0.005em;
}

.downgradeModal--feedback-optionsContainer{
	display: flex;
	width: 100%;
	flex-wrap: wrap;
	margin-bottom: 20px;
}

.downgradeModal--feedback-option{
	width: calc(50% - 24px);
	height: 70px;
	margin: 6px;
	
	box-sizing: border-box;
	padding: 12px;
	font-size: 16px;
	font-weight: $effra-medium;

	border-radius: 4px;
	cursor: pointer;

	position: relative;	
}

.downgradeModal--feedback-option--unselected{
	color: $textBlack;
	border: 1px solid $gray-11;
	background: $white;
}

.downgradeModal--feedback-option--unselected:hover{
	color: $textBlack;
	border: 1px solid $gray-10;
	background: $white;
}

.downgradeModal--feedback-option--selected{
	color: $textBlue;
	border: 1px solid lighten($textBlue, 10%);
	background: lighten($blue-extraLight, 2%);
}

.downgradeModal--feedback-option--selected:hover{	
	border: 1px solid lighten($textBlue, 15%);
	background:  lighten($blue-extraLight, 5%);
}

textarea.downgradeModal--feedback-option-textArea{
	box-sizing: border-box;
	padding: 12px;
	font-size: 16px;
	font-weight: $effra-medium;
	color: $textBlue;

	position: absolute;
	top: 0px;
	left: 0px;
	right: 0px;
	bottom: 0px;
	resize: none;

	border: none;
	outline: none;
	background: none;
	box-shadow: none;

	  font-family: 'Effra';
   -webkit-font-smoothing: antialiased;
}

textarea.downgradeModal--feedback-option-textArea:focused{	
	border: none;
	outline: none;
	box-shadow: none;
	background: none;
}

.downgradeModal--feedback-option:focus-within{
	
	border: 1px solid lighten($textBlue, 10%);
	background: lighten($blue-extraLight, 10%);
}