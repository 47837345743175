

a.discover-miniRepoLink{
	height: $height-discover-market-miniRepoLink;
	
	width: 33%;

	box-sizing: border-box;
	padding-top: 7px;

	display: block;

	cursor: pointer;

	display: flex;

	padding-left: 12px;
	padding-right: 8px;
	border-radius: 5px;

}


a.discover-miniRepoLink:active{
	background: $bgColor-activeRepoLink;
}

.discover-miniRepoLink-left{
	width: 62px;
	flex-grow: 0;
	flex-shrink: 0;
}

.discover-miniRepoLink-left-repoImageContainer{
	width: 54px;
	height: 54px;
	background: $gray-14;
	border-radius: 3px;
	overflow: hidden;
	position: relative;

}

.discover-miniRepoLink-left-repoImage-imgBorder{
	position: absolute;
	top: 0; 
	left: 0; 
	right: 0; 
	bottom: 0;
	z-index: 5;
	box-sizing: border-box;
	border: 1px solid rgba(0,0,20,0.06);
	border-radius: 3px;
}


img.discover-miniRepoLink-left-repoImage{
	position: absolute;
	top: 0px;
	left: 0px;
	right: 0px;
	bottom: 0px;
	height: 100%;
	width: 100%;
}

//
//

.discover-miniRepoLink-center{
	flex-grow: 1;
	flex-shrink: 1;

	min-width: 0;
}


// TITLE
.discover-miniRepoLink-center-title{
	font-size: 14.5px;
	font-weight: $lato-bold;
	letter-spacing: 0.005em;
	color: $textBlack;
	margin-top: 3px;
	margin-bottom: 0px;
	height: 18px;
	overflow: hidden;

	white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

}

a.discover-miniRepoLink:hover
.discover-miniRepoLink-center-title{
	color: $textBlackDark;
}

// CREATOR
.discover-miniRepoLink-center-creator{
	font-size: 13px;
	font-weight: $lato-medium;
	color: $textBlue;

	white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}


// INFO CONTAINER 
//
.discover-miniRepoLink-center-infoContainer{
	margin-top: 3px;
	font-size: 12.5px;
	font-weight: $lato-medium;
	color: darken($textLight, 5%);
	opacity: 0.8;
}

.discover-miniRepoLink:hover
.discover-miniRepoLink-center-infoContainer{
	opacity: 1;
}

// RATING
.discover-miniRepoLink-center-infoContainer .discover-repoRatingStars-stars{
	margin-top: 2px;
}

.discover-miniRepoLink-center-infoContainer .discover-repoRatingStars-stars svg{
	width: 9px;
	height: 9px;
	margin-right: 1px;
}

.discover-repoRatingStars-stars--active svg{
	fill: $gray-7;
}

.discover-repoRatingStars-stars--bg svg{
	fill: $gray-12;
}

// PLAY COUNT

.discover-miniRepoLink-center-infoContainer svg.icon.play{
	height: 8px;
	width: 8px;
	fill: $gray-9;
}



//
//

.discover-miniRepoLink-right{
	width: 16px;
	flex-grow: 0;
	flex-shrink: 0;

	opacity: 0.2;

	display: flex;
	justify-content: flex-end;

}

.discover-miniRepoLink-right svg.icon{
	width: 10px;
	height: 10px;
	fill: $gray-7;
	margin-top: 6px;
}

a.discover-miniRepoLink:hover
.discover-miniRepoLink-right{
	opacity: 0.8;
}