
$height-importQuestions-topBar: 48px;
$width-importQuestions-rightPanel: 450px;
$width-importQuestions-textInput-leftGutter: 70px;

.importQuestions{	
	position: fixed;
	top: 0px;
	left: 0px;
	right: 0px;
	bottom: 0px;
	z-index: 100;
	background: $white;
	overflow: hidden;
}

@import 'ImportQuestions-TopBar';
@import 'ImportQuestions-InputArea';
@import 'ImportQuestions-RightPanel';
@import 'ImportQuestions-FeedbackModal';
@import 'ImportQuestions-Helper'; // for dev test
@import 'ImportQuestions-OnboardingModal';
@import 'ImportQuestions-Notification';
@import 'ImportQuestions-FreeLimitMessage';

.importQuestionsCenterContainer{
	// provides flex container for input area and rightPanel
	display: flex;
	position: absolute;
	top: $height-importQuestions-topBar;
	left: 0px;
	right: 0px;
	bottom: 0px;
}
