// Context badge is in top right corner and provide's links to things 
// based on current user state

.contextBadge{
	height: 26px;
	display: flex;
	align-items: center;
	justify-content: center;

	border-radius: 4px;

	font-size: 14.5px;
	font-weight: $lato-bold;

	color: $white;
	background: $blue-light;
	border: 1px solid transparentize($blue-light, 0.18);

	padding-right: 10px;
	padding-left: 10px;

	user-select: none;
	cursor: pointer;
}

.contextBadge b {
	color: red;
}

.contextBadge:hover{
	background: darken($blue-light, 5%);
	border: 1px solid $blue;
}

.contextBadge--error, .contextBadge--error:hover{
	background: $red;
	border: 1px solid $red;
}

.contextBadge--creatorFundAnnounce svg{
	fill: $white;
	opacity: 0.75;
	
	height: 15px;
	width: 15px;
	margin-right: 2px;
	position: relative;
	top: 1px;
	left: -3px;
}