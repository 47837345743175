.importQuestionsFeedbackModalContainer{
	position: fixed;
	z-index: 9999;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: rgba(5,5,10,0.75);
	
	display: flex;
	
	align-items: center;
	justify-content: center;
}

.importQuestionsFeedbackModal{
	border-radius: 10px;
	
	background: rgb(252,252,255);
	border: 1px solid $gray-5;
	box-shadow: 
		0px 8px 16px 0px rgba(5,5,10,0.12),
		0px 16px 32px 0px rgba(5,5,10,0.08) ;

	padding-top: 40px;
	padding-bottom: 24px;

	padding-left: 50px;
	padding-right: 50px;

	overflow: hidden;

	width: 480px;
	
	position: relative;

	text-align: center;
}

.importQuestionsFeedbackModal-title{
	font-size: 24px;
	font-weight: $lato-heavy;
	color: $textBlack;

	
	cursor: default;
}

// Rating Btns

.importQuestionsFeedbackModal-ratingBtnRowContainer{
	display: flex;
	justify-content: center;
	margin-bottom: 8px;
	margin-top: 30px;
}

.importQuestionsFeedbackModal-ratingBtnRow{
	display: flex;
}

button.importQuestionsFeedbackModal-ratingBtn{
	box-shadow: none;
	border: none;

	display: flex;
	align-items: center;
	justify-content: center;

	flex-grow: 0;
	flex-shrink: 0;

	width: 74px;
	height: 70px;
	// border: 1px solid $red;

	padding-bottom: 20px;
	box-sizing: border-box;

	cursor: pointer;	
	background: none;

	// background: $tempr;

	position: relative;

	transition: opacity 100ms linear;
}

.importQuestionsFeedbackModal--noRating
.importQuestionsFeedbackModal-ratingBtnRow:hover
button.importQuestionsFeedbackModal-ratingBtn{
	opacity: 0.5;
}

.importQuestionsFeedbackModal--noRating
.importQuestionsFeedbackModal-ratingBtnRow:hover
button.importQuestionsFeedbackModal-ratingBtn:hover{
	opacity: 1;
}

// Icons

.importQuestionsFeedbackModal--noRating{
	
	button.importQuestionsFeedbackModal-ratingBtn--inactive .icon.feedbackSmile #faceBG{
		fill: white;
	}

	button.importQuestionsFeedbackModal-ratingBtn--inactive .icon.feedbackSmile #eyes,
	button.importQuestionsFeedbackModal-ratingBtn--inactive .icon.feedbackSmile #faceOutline,
	button.importQuestionsFeedbackModal-ratingBtn--inactive .icon.feedbackSmile #smile{
		fill: $gray-8;
	}

	.importQuestionsFeedbackModal-ratingBtnRow:hover
	.importQuestionsFeedbackModal-ratingBtn-label{
		color: $textMid;
	}

}

.importQuestionsFeedbackModal--hasRating{

	button.importQuestionsFeedbackModal-ratingBtn--inactive .icon.feedbackSmile #faceBG{
		fill: none;
	}

	button.importQuestionsFeedbackModal-ratingBtn--inactive .icon.feedbackSmile #eyes,
	button.importQuestionsFeedbackModal-ratingBtn--inactive .icon.feedbackSmile #faceOutline,
	button.importQuestionsFeedbackModal-ratingBtn--inactive .icon.feedbackSmile #smile{
		fill: $gray-11;
	}


	button.importQuestionsFeedbackModal-ratingBtn--inactive:hover .icon.feedbackSmile #eyes,
	button.importQuestionsFeedbackModal-ratingBtn--inactive:hover .icon.feedbackSmile #faceOutline,
	button.importQuestionsFeedbackModal-ratingBtn--inactive:hover .icon.feedbackSmile #smile{
		fill: $gray-8;
	}

	.importQuestionsFeedbackModal-ratingBtnRow:hover
	button.importQuestionsFeedbackModal-ratingBtn--inactive:hover
	.importQuestionsFeedbackModal-ratingBtn-label{
		color: $textMid;
	}

	.importQuestionsFeedbackModal-ratingBtnRow:hover
	button.importQuestionsFeedbackModal-ratingBtn--inactive
	.importQuestionsFeedbackModal-ratingBtn-label{
		color: $textLight;
	}

}

.importQuestionsFeedbackModal--hasRating{
	button.importQuestionsFeedbackModal-ratingBtn--active .icon.feedbackSmile #faceBG{
		fill: white;
	}

	button.importQuestionsFeedbackModal-ratingBtn--active .icon.feedbackSmile #eyes,
	button.importQuestionsFeedbackModal-ratingBtn--active .icon.feedbackSmile #faceOutline,
	button.importQuestionsFeedbackModal-ratingBtn--active .icon.feedbackSmile #smile{
		fill: $gray-6;
	}


	button.importQuestionsFeedbackModal-ratingBtn--active
	.importQuestionsFeedbackModal-ratingBtn-label{
		color: $textBlack;
	}

}

// Labels

.importQuestionsFeedbackModal-ratingBtn-label{
	position: absolute;
	bottom: 2px;
	left: 0px;
	right: 0px;
	
	text-align: center;
	font-size: 14px;
  font-weight: $lato-semibold;

  color: $invisible;
}


// TextArea

textarea.importQuestionsFeedbackModal-message{
	width: 100%;
	border: 1px solid darken($lineDark, 5%);
	resize: none;
	height: 140px;
	border-radius: 2px;

	font-size: 14.5px;
	font-weight: $lato-semibold;
	color: $textBlackDark;
	box-sizing: border-box;

	padding: 6px;
}

textarea.importQuestionsFeedbackModal-message:focus{
	outline: none;
	border: 1px solid $blue;
}

textarea.importQuestionsFeedbackModal-message::-webkit-input-placeholder{
	color: $textLight;
}

textarea.importQuestionsFeedbackModal-message:hover::-webkit-input-placeholder{
	color: $textLight;
}	

textarea.importQuestionsFeedbackModal-message:focus::-webkit-input-placeholder{
	color: $textLight;
}


// Send Button

.importQuestionsFeedbackModal button.sendfeedback{
	width: 100%;
	font-weight: $lato-bold;
	letter-spacing: 0.005em;
	margin-top: 10px;
	height: 48px;
	cursor: pointer;
}

.importQuestionsFeedbackModal button.sendfeedback.btn--disabled{
	pointer-events: none;
	opacity: 1; // override default
}

// Close Button

.importQuestionsFeedbackModal-closeBtn{
	position: absolute;
	width: 28px;
	height: 28px;
	top: 12px;
	right: 14px;
	cursor: pointer;
	border-radius: 12px;

	opacity: 0.3;
	transition: opacity 100ms linear;

	z-index: 100;
}

.importQuestionsFeedbackModal-closeBtn:hover{
	opacity: 0.8;
	transition: opacity 50ms linear;
}

.importQuestionsFeedbackModal-closeBtn:active{
	opacity: 1;
}

.importQuestionsFeedbackModal-closeBtn .icon{
	width: 28px;
	height: 28px;
	fill: $gray-6;
}
