.editor-editImageModalContainer{
	position: fixed;
	top: 0px;
	right: 0px;
	left: 0px;
	bottom: 0px;

	z-index: 9999;
	display: flex;
	align-items: center;
	justify-content: center;

	
}

// grabbing cursor when dragging
body.react-draggable-transparent-selection .editor-editImageModalContainer *,
body.react-draggable-transparent-selection .editor-editImageModal-canvas *{
	cursor: grabbing !important;
}

.editor-editImageModalContainerBG{
	position: absolute;
	top: 0px;
	left: 0px;
	right: 0px;
	bottom: 0px;
	background: rgba(0,0,5,0.75);
	z-index: 0;
}


.editor-editImageModal{
	// height and width calced in JS

	// max-width: 1200px;
	// width: calc(100vw - 130px);
	// min-width: 950px;

	// min-height: 550px;
	// height: calc(100vh - 100px);
	// max-height: 720px;
	
	background: rgb(0,0,10);
	// border: 1px solid rgb(10,10,20);
	// border-bottom: 1px solid rgb(0,0,0);
	border-top: 1px solid rgb(60,60,70);


	box-shadow: 0px 4px 8px 0px rgba(0,0,10,0.24), 0px 3px 12px 0px rgba(0,0,10,0.40);
	border-radius: 8px;

	box-sizing: border-box;

	text-align: left;

	display: flex;

	overflow: hidden;
	position: relative;
	z-index: 5;

	flex-grow: 0;
	flex-shrink: 0;
}


// LEFT PANEL
$leftPadding-leftPanel-editImageModal: 16px;
$rightPadding-leftPanel-editImageModal: 10px;
$width-leftBar-editImageModal: 280px;

.editor-editImageModal-leftBar{
	//width: $width-leftBar-editImageModal;
	// calculated in JS

	flex-grow: 0;
	flex-shrink: 0;

	background: rgb(30,30,32);
	height: 100%;

	display: flex;
	flex-direction: column;

	cursor: default;
	user-select: none;
	position: relative;
	z-index: 9999;
}

.editor-editImageModal-leftBar-title,
.editor-editImageModal-leftBar-transformPanel,
.editor-editImageModal-leftBar-aspectPanel,
.editor-editImageModal-leftBar-undoPanel,
.editor-editImageModal-leftBar-submitBtnPanel{
	flex-grow: 0;
	flex-shrink: 0;
}

.editor-editImageModal-leftBar-verticalSpacer{
	flex-grow: 1;
	flex-shrink: 1;
}

//

.editor-editImageModal-leftBar-title{
	box-sizing: border-box;
	padding-left: $leftPadding-leftPanel-editImageModal;
	height: 62px;

	width: 100%;

	display: flex;
	align-items: center;

	font-size: 17px;
	font-weight: $lato-bold;
	color: $white;
	letter-spacing: 0.02em;
}

.editor-editImageModal-leftBar-title svg.editorIcon{
	width: 18px;
	height: 18px;

	fill: $white;
	opacity: 0.24;

	margin-right: 8px;
	position: relative;
	top: 1px;
}

// // RESET TO ORIGINAL BUTTON

// button.editor-editImageModal-leftBar-title-resetBtn{
// 	margin-left: auto;
// 	margin-right: 4px;
// 	padding-right: 7px;
// 	padding-left: 7px;
// 	padding-top: 5px;
// 	padding-bottom: 5px;

// 	font-size: 14.5px;
// 	font-weight: $lato-semibold;
// 	color: $white;
// 	letter-spacing: 0.01em;

// 	border: none;
// 	cursor: pointer;

// 	border-radius: 3px;

// 	background: none;
// 	box-shadow: none;
	
// 	opacity: 0;

// 	user-select: none !important;
// }

// .editor-editImageModal-leftBar-title:hover
// button.editor-editImageModal-leftBar-title-resetBtn{
// 	opacity: 0.5;
// 	transition: opacity 50ms linear;
// 	transition-delay: 100ms;
// }

// .editor-editImageModal-leftBar-title:hover
// button.editor-editImageModal-leftBar-title-resetBtn:hover{
// 	opacity: 1;
// 	transition: opacity 50ms linear;
// 	transition-delay: 100ms;
// }

// button.editor-editImageModal-leftBar-title-resetBtn:focus,
// button.editor-editImageModal-leftBar-title-resetBtn:active{
// 	border: none;
// 	outline: none;
// 	box-shadow: none;
// }

// button.editor-editImageModal-leftBar-title-resetBtn:active{
// 	background: $black;
// 	opacity: 1;
// }

//

.editor-editImageModal-leftBar-transformPanel{
	width: 100%;
	display: flex;
	box-sizing: border-box;
	padding-left: $leftPadding-leftPanel-editImageModal;
	padding-right: $rightPadding-leftPanel-editImageModal;
}

button.editor-editImageModal-iconBtn{
	width: 46px;
	height: 32px;

	border-radius: 5px;
	background: $black;
	border: none;

	display: flex;
	align-items: center;
	justify-content: center;

	cursor: pointer;
}

.editor-editImageModal-iconBtn--flipVertical{
	margin-left: 6px;
}

.editor-editImageModal-iconBtn--rotateRight{
	margin-left: auto;
}

button.editor-editImageModal-iconBtn svg{
	fill: $white;
}

.editor-editImageModal-iconBtn--flipVertical svg,
.editor-editImageModal-iconBtn--flipHorizontal svg{
	height: 14px;
	width: 14px;
}

.editor-editImageModal-iconBtn--flipVertical svg #top,
.editor-editImageModal-iconBtn--flipHorizontal svg #left{
	opacity: 0.75;
}

.editor-editImageModal-iconBtn--rotateRight svg{
	width: 17px;
	height: 17px;
	position: relative;
	right: 1px;
	top: -1px;
}

button.editor-editImageModal-iconBtn{
	opacity: 0.6;
	transition: opacity 50ms linear;
}

button.editor-editImageModal-iconBtn:hover{
	opacity: 1;
}

button.editor-editImageModal-iconBtn:focus{
	border: none;
	outline: none;
}

button.editor-editImageModal-iconBtn:active{
	background: $gray-4;
}

button.editor-editImageModal-iconBtn--active{
	background: $blue;
	opacity: 1;
}

//

.editor-editImageModal-leftBar-divider{
	height: 1px;
	width: calc(100% - #{$leftPadding-leftPanel-editImageModal});
	margin-left: $leftPadding-leftPanel-editImageModal;

	background: rgb(0,0,0);
	opacity: 0.2;

	// background: $white;
	// opacity: 0.1;
	

	margin-top: 7px;
	margin-bottom: 7px;
}




//

.editor-editImageModal-leftBar-adjustColorPanel{	
	width: 100%;

	box-sizing: border-box;
	padding-left: $leftPadding-leftPanel-editImageModal;
	padding-right: $rightPadding-leftPanel-editImageModal;;
}

button.editor-editImageModal-adjustColorBtn{
	width: 100%;
	flex-grow: 1;
	flex-shrink: 1;
	border: none;
	background: none;
	height: 34px;
	border-radius: 5px;

	font-weight: $lato-bold;
	font-size: 14px;

	color: $white;

	display: flex;
	align-items: center;
	justify-content: center;

	cursor: pointer;

	letter-spacing: 0.02em;

}


button.editor-editImageModal-adjustColorBtn--active{
	background: $blue;
	color: $white;
}

button.editor-editImageModal-adjustColorBtn--active:hover{
	background: saturate($blue, 2%);
}

button.editor-editImageModal-adjustColorBtn--inactive{
	background: rgb(0,0,0);
	opacity: 0.6;
	transition: opacity 50ms linear;
}
button.editor-editImageModal-adjustColorBtn--inactive:hover{
	opacity: 1;
	background: rgb(10,10,15);
}


// IMAGE SLIDERS

.editor-editImageModal-leftBar-adjustColorPanel-slidersContainer{
	width: 100%;
	box-sizing: border-box;
	padding-right: $rightPadding-leftPanel-editImageModal;
	padding-left: 4px;
	margin-top: 20px;
	margin-bottom: 2px;
}

.editor-editImageModal-leftBar-adjustColorPanel-slider{
	// margin-top: 12px;
	height: 46px;
	position: relative;
}

// DEV
.editor-editImageModal-leftBar-adjustColorPanel-sliders-devValue{
	margin-bottom: 20px;
	color: $white;
	font-size: 15px;
}

.editor-editImageModal-leftBar-adjustColorPanel-slider-title{
	font-size: 13.5px;
	font-weight: $lato-semibold;
	color: $white;
	height: 14px;

	letter-spacing: 0.02em;

	opacity: 0.8;
	transition: opacity 50ms linear;
	position: absolute;

	top: 0px;
	left: 0px;
}

.editor-editImageModal-leftBar-adjustColorPanel-slider-value{
	position: absolute;
	top: 0px;
	right: 0px;

	font-size: 13.5px;
	font-weight: $lato-semibold;
	color: $white;
	height: 14px;

	letter-spacing: 0.02em;

	text-align: right;

	opacity: 0.5;
	transition: opacity 50ms linear;
}

.editor-editImageModal-leftBar-adjustColorPanel-slider:hover
.editor-editImageModal-leftBar-adjustColorPanel-slider-value{
	opacity: 0.5;
	transition: opacity 50ms linear;
}


.editor-editImageModal-leftBar-adjustColorPanel-slider:hover
.editor-editImageModal-leftBar-adjustColorPanel-slider-title{
	opacity: 1;
	transition: opacity 50ms linear;
}

.editor-editImageModal-leftBar-adjustColorPanel-slider-sliderContainer{
	position: relative;
}

.editor-editImageModal-leftBar-adjustColorPanel-slider .rc-slider{
	// background: $tempr;
	height: 46px;
	border-radius: 0px;
	cursor: pointer;
}


.editor-editImageModal-leftBar-adjustColorPanel-slider .rc-slider-handle,
.editor-editImageModal-leftBar-adjustColorPanel-slider .rc-slider-handle:hover,
.editor-editImageModal-leftBar-adjustColorPanel-slider .rc-slider-handle:focus,
.editor-editImageModal-leftBar-adjustColorPanel-slider .rc-slider-handle:active{
	position: relative;
	border: none;
	background: none;
	outline: none;
	box-shadow: none;
	width: 15px;
	height: 46px;

	margin-top: -5px;

	// background: $tempb;
	border-radius: 0px;
}

.editor-editImageModal-leftBar-adjustColorPanel-slider .rc-slider-handle:active{
	
}

.editor-editImageModal-leftBar-adjustColorPanel-slider .rc-slider-handle svg{
	position: absolute;
	top: 8px;
	right: 0px;
	left: 0px;
	bottom: 0px;
	margin: auto;
}



.editor-editImageModal-leftBar-adjustColorPanel-slider .rc-slider-handle:active svg{
	transform: scale(1.05);
}

.editor-editImageModal-leftBar-adjustColorPanel-slider .rc-slider-handle svg #edit-image-modal-slider-handle-outer{
	fill: rgba(0,0,0,0.25);
}

.editor-editImageModal-leftBar-adjustColorPanel-slider .rc-slider-handle svg #edit-image-modal-slider-handle-inner{
	fill: $gray-11;
}

.editor-editImageModal-leftBar-adjustColorPanel-slider:hover .rc-slider-handle svg #edit-image-modal-slider-handle-inner{
	fill: $white;
}

.editor-editImageModal-leftBar-adjustColorPanel-slider .rc-slider-track{
	display: none; // no track
}

.editor-editImageModal-leftBar-adjustColorPanel-slider .rc-slider-rail{
	top: 24px;
	background: $gray-4;
	height: 3px;
	border-radius: 2px;
	cursor: pointer;
}


.editor-editImageModal-leftBar-adjustColorPanel-slider:hover .rc-slider-rail{
	background: $gray-6;
}

.editor-editImageModal-leftBar-adjustColorPanel-slider
.rc-slider-mark{
	display: none; // tooltip for rc-slider step
}

.editor-editImageModal-leftBar-adjustColorPanel-slider
.rc-slider-step{
	top: 24px;
}

.editor-editImageModal-leftBar-adjustColorPanel-slider
.rc-slider-step .rc-slider-dot{	
	background: $gray-13;
	border: 1px solid $black;
	width: 9px;
	height: 9px;
	margin-left: -4px;

	opacity: 0.8;
}




























//



.editor-editImageModal-leftBar-addMarkerPanel{

}

//
.editor-editImageModal-leftBar-undoPanel{
	display: flex;
	justify-content: space-between;
	width: 100%;

	height: 44px; // in case both hidden

	box-sizing: border-box;
	padding-left: $leftPadding-leftPanel-editImageModal;
	padding-right: $rightPadding-leftPanel-editImageModal;
}

button.editor-editImageModal-undoRedo{
	width: calc(50% - 2px);
	height: 44px;
	border-radius: 6px;

	background: $black;
	border: none;
	box-shadow: none;
	outline: none;
	display: flex;
	align-items: center;
	justify-content: center;

	color: $white;

	font-size: 15px;
	font-weight: $lato-bold;
	letter-spacing: 0.02em;

	cursor: pointer;
	user-select: none;

}

button.editor-editImageModal-undoRedo:disabled{
	opacity: 0.2;
	pointer-events: none;
}

button.editor-editImageModal-undoRedo{
	opacity: 0.75;
	background: transparentize($black, 0.7);
}

button.editor-editImageModal-undoRedo:hover{
	opacity: 1;
	background: transparentize($black, 0.4);
}

button.editor-editImageModal-undoRedo:active{
	opacity: 1;
	background: $black;
}

button.editor-editImageModal-undoRedo svg.editorIcon{
	fill: $white;
	height: 15px;
	width: 15px;
}

button.editor-editImageModal-undoRedo--undo svg.editorIcon{
	margin-right: 6px;
	margin-left: -4px;
}


button.editor-editImageModal-undoRedo--redo svg.editorIcon{
	margin-left: 6px;
	margin-right: -4px;
}


// HELP LINK


a.editor-editImageModal-leftBar-helpBtn{
	margin-left: $leftPadding-leftPanel-editImageModal;
	width: calc(100% - #{$leftPadding-leftPanel-editImageModal} - #{$rightPadding-leftPanel-editImageModal});
	height: 32px;
	
	color: $white;
	margin-bottom: 0px;

	text-align: center;
	opacity: 0.5;
	background: none;

	border-radius: 4px;

	font-size: 13px;
	letter-spacing: 0.025em;

	font-weight: $lato-medium;

	display: flex;
	align-items: center;
	justify-content: center;

	transition: opacity 50ms linear;

	// background: $tempr;
}

.editor-editImageModal-leftBar:hover
a.editor-editImageModal-leftBar-helpBtn{
	opacity: 0.75;
}

.editor-editImageModal-leftBar:hover
a.editor-editImageModal-leftBar-helpBtn:hover{
	background: rgba(0,0,0,0.2);
	opacity: 1;
	
	// text-decoration: underline !important;
}




//
// DONE BUTTON
//

.editor-editImageModal-leftBar-submitBtnContainer{
	box-sizing: border-box;
	width: 100%;
	padding-left: $leftPadding-leftPanel-editImageModal;
	padding-right: $rightPadding-leftPanel-editImageModal;
}

button.editor-editImageModal-doneBtn{
	height: 55px;
	width: 100%;
	box-sizing: border-box;

	background: $blue;
	border-radius: 5px;

	border: none;
	box-shadow: none;

	padding-top: 10px;

	box-shadow: 0px 2px 2px 0px rgba(0,0,0,0.12);

	cursor: pointer;

	margin-top: 0px;
}

.editor-editImageModal-doneBtn-primary{
	font-size: 17px;
	font-weight: $lato-bold;
	letter-spacing: 0.03em;
	color: $white;
}

.editor-editImageModal-doneBtn-secondary{
	font-size: 10px;
	font-weight: $lato-semibold;
	color: $white;
	opacity: 0;
	letter-spacing: 0.02em;
	text-transform: uppercase;
	margin-top: 1px;
}

button.editor-editImageModal-doneBtn:hover
.editor-editImageModal-doneBtn-secondary{
	opacity: 0.5;
	transition: opacity 100ms;
	transition-delay: 150ms;
}

button.editor-editImageModal-cancelBtn{
	width: 100%;
	margin-top: 6px;
	margin-bottom: 8px;

	height: 34px;
	border-radius: 5px;
	background: rgba(0,0,0,0.7);
	background: none;

	color: $white;

	font-size: 14px;
	font-weight: $lato-semibold;
	letter-spacing: 0.015em;

	cursor: pointer;

	outline: none;
	border: none;
	box-shadow: none;

	display: flex;
	align-items: center;
	justify-content: center;
}

button.editor-editImageModal-cancelBtn--hide{
	opacity: 0.4;
	transition: opacity 50ms linear;
}

button.editor-editImageModal-cancelBtn--show{
	opacity: 0.8;
	transition: opacity 50ms linear;
	background: rgba(0,0,0,0.1);
}

button.editor-editImageModal-cancelBtn:hover{
	opacity: 1;
	transition: opacity 50ms linear;
	background: rgba(0,0,0,0.2);
}

//

.editor-editImageModal-canvasContainer{
	position: absolute;
	left: $width-leftBar-editImageModal;
	right: 0px;
	top: 0px;
	bottom: 0px;
	box-sizing: border-box;
	padding: 30px;
	padding-right: 90px; // markup container
}

.editor-editImageModal-canvas{
	width: 100%;
	height: 100%;

	position: relative;
	// overflow: hidden;

	//FOR DEV 
	// background: $red;
}

.editor-editImageModal-canvas-cropBox{
	
	z-index: 10;
	background: none;
	border: 1px solid rgba(255,255,255,0.5);
	box-shadow: 0 0 0 9999px rgba(0, 0, 0, 0.65)
}

.editor-editImageModal-canvas-cropBox--noCrop{
	cursor: default !important;
}

.editor-editImageModal-canvas-cropBox-cornerHandle{
	position: absolute;
	transform: scale(0.5);
	border-radius: 5px;
	background: $white;
	box-shadow: 0px 0px 0px 4px rgba(0,0,0,0.5);
}

.editor-editImageModal-canvas-cropBox-cornerHandle--topLeft{
	top: -6px;
	left: -6px;
	cursor: nwse-resize;
}

.editor-editImageModal-canvas-cropBox-cornerHandle--topRight{
	top: -6px;
	right: -6px;
	cursor: nesw-resize;
}

.editor-editImageModal-canvas-cropBox-cornerHandle--bottomLeft{
	bottom: -6px;
	left: -6px;
	cursor: nesw-resize;
}

.editor-editImageModal-canvas-cropBox-cornerHandle--bottomRight{
	bottom: -6px;
	right: -6px;
	cursor: nwse-resize;
}

.editor-editImageModal-canvas-cropBox-edgeContainer{
	background: none;
	display: flex;
	align-items: center;
	justify-content: center;
	// background: $tempr;	
}

.editor-editImageModal-canvas-cropBox-edge{
	
	cursor: grab;

	border-radius: 3px;
	background: $white;
	box-shadow: 0px 0px 0px 2px rgba(0,0,0,0.25);
}

.editor-editImageModal-canvas-cropBox-edge--topBottom{
	width: 24px;
	height: 9px;	
}

.editor-editImageModal-canvas-cropBox-edge--leftRight{
	width: 9px;
	height: 24px;
}


//
// THIRDS

.editor-editImageModal-canvas-cropBox-thirds{
	position: absolute;
	border: 1px solid white;	
}

.editor-editImageModal-canvas-cropBox-thirds--topCenter{
	position: absolute;
	left: 33%;
	right: 33%;
	top: 0%;
	height: 33%;

	
	border-top: none;
}

.editor-editImageModal-canvas-cropBox-thirds--bottomCenter{
	position: absolute;
	left: 33%;
	right: 33%;
	bottom: 0%;
	height: 33%;

	border-bottom: none;
}

.editor-editImageModal-canvas-cropBox-thirds--leftCenter{
	position: absolute;
	left: 0%;
	width: 33%;
	top: 33%;
	bottom: 33%;

	border-left: none;
}

.editor-editImageModal-canvas-cropBox-thirds--rightCenter{	
	right: 0%;
	width: 33%;
	top: 33%;
	bottom: 33%;

	border-right: none;
}


.editor-editImageModal-canvas-cropBox
.editor-editImageModal-canvas-cropBox-thirds{
	opacity: 0;
	transition: opacity 50ms linear;
}


.editor-editImageModal-canvas-cropBox:hover
.editor-editImageModal-canvas-cropBox-thirds{
	opacity: 0.3;
}


//
//
//

$transparentCheckBGColor: $gray-125;

.editor-editImageModal-canvas-intrinsicImageContainer{
	position: absolute;
	top: 0px;
	left: 0px;
	right: 0px;
	bottom: 0px;

	// background: blue;

	// transparent gray boxes background
	
	// add padding back in later
	// top: 30px;
	// left: 30px;
	// right: 30px;
	// bottom: 30px;

	// outline: 4px solid green; for DEV
}


.editor-editImageModal-canvas-intrinsicImageContainer{
	background: none;
}

//showCheckerBoxIfImageLoaded
.editor-editImageModal-canvas-intrinsicImageContainer--imageLoaded{
	background-color: #ffffff;
	background-image:  repeating-linear-gradient(45deg, $transparentCheckBGColor 25%, transparent 25%, transparent 75%, $transparentCheckBGColor 75%, $transparentCheckBGColor), repeating-linear-gradient(45deg, $transparentCheckBGColor 25%, #ffffff 25%, #ffffff 75%, $transparentCheckBGColor 75%, $transparentCheckBGColor);
	background-position: 0 0, 15px 15px;
	background-size: 30px 30px;
}



// img.editor-editImageModal-canvas-intrinsicImage{
// 	transform-origin: 0% 0%;
// }

.editor-editImageModal-canvas-intrinsicImage{
	pointer-events: none; // prevent dragging
	//transform-origin: center center; // i think this is right...
	transform-origin: 0% 0%;
}


// to prevent rounding errors on cover position comparison to imagecontainer, inset a safe area by 1 pixel
.editor-editImageModal-canvas-intrinsicImageContainer-coverSafeArea{
	position: absolute;
	top: 1px;
	left: 1px;
	bottom: 1px;
	right: 1px;

	pointer-events: none;
	background: none;
	z-index: -1;
	visibility: hidden;
}

.editor-editImageModal-rightPanelContainer{
	
	position: absolute;
	top: 0px;
	right: 16px;
	bottom: 0px;

	width: 60px;


	display: flex;
	align-items: center;
}

.editor-editImageModal-rightPanelContainer--initial{
	z-index: 750;
	opacity: 1;
}

.editor-editImageModal-rightPanelContainer--initial--show{
	z-index: 9999;
}

.editor-editImageModal-rightPanelContainer--initial--hide{
	display: none;
}


.editor-editImageModal-markupPanel{
	width: 100%;
	height: 335px;
	//background: darken(rgb(30,30,32), 6%);
	background: rgb(70,70,90);

	border-radius: 18px;

	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

}

.editor-editImageModal-rightPanelContainer--initial
.editor-editImageModal-markupPanel{
	background: darken(rgb(70,70,90), 20%);
	pointer-events: none;
	cursor: default;
}

.editor-editImageModal-rightPanelContainer--initial
.editor-editImageModal-markupPanel * {
	opacity: 0.7;
}

// FAKE BOX DRAG


.editor-editImageModal-markupPanel-boxSpacer{
	position: relative;

}

.editor-editImageModal-rightPanelContainer--initial
.editor-editImageModal-markupPanel-boxSpacer{
	opacity: 0.5;
}


.editor-editImageModal-markupPanel-boxSpacer-handle{
	position: absolute;
	height: 8px;
	width: 8px;
	border-radius: 1px;
	background: $white;
	box-shadow: 0px 0px 0px 1px rgba(0,0,0,0.5);

	opacity: 1 !important; // sorry
}

.editor-editImageModal-markupPanel-boxSpacer-handle:nth-child(1){
	top: -4px;
	left: -4px;
}

.editor-editImageModal-markupPanel-boxSpacer-handle:nth-child(2){
	top: -4px;
	right: -4px;
}

.editor-editImageModal-markupPanel-boxSpacer-handle:nth-child(3){
	bottom: -4px;
	left: -4px;
}

.editor-editImageModal-markupPanel-boxSpacer-handle:nth-child(4){
	bottom: -4px;
	right: -4px;
}

//

.editor-editImageModal-markupPanelTooltip{
	position: absolute;
	margin: auto;
	left: -10px;
	top: -380px;
	bottom: 0px;
	right: -10px;
	letter-spacing: 0.025em;

	height: 40px;
	background: $white;
	background: none;

	font-size: 13.5px;
	font-weight: $lato-semibold;
	color: $textLight;

	text-align: center;
	line-height: 1.15;

	z-index: 50;

	opacity: 1;

	cursor: default;
	user-select: none;
}

// NEEDS I THINK X/Y ON MOUSE, AS NEEDED FOR FAKE MARKUP PANEL AS WELL
.editor-editImageModal-markupPanelTooltip--show{
	opacity: 1;
	transition: opacity 50ms linear;
}

.editor-editImageModal-markupPanelTooltip--hide{
	opacity: 0;
	transition: opacity 100ms linear;
}


// CLEAR BUTTON

.editor-editImageModal-resetImageBtn{
	position: absolute;
	right: 16px;
	bottom: 16px;	


	width: 60px;
	height: 40px;
	
	background: none;
	
	border-radius: 6px;

	border: none;
	box-shadow: none;
	outline: none;

	font-size: 14px;
	font-weight: $lato-semibold;
	color: $white;
	letter-spacing: 0.03em;


	z-index: 9999;	

	opacity: 0.75;

	cursor: pointer;
}


.editor-editImageModal-resetImageBtn--hide{
	opacity: 0;
	transition: opacity 50ms linear;
	transition-delay: 0ms;
}

.editor-editImageModal-resetImageBtn--show{
	opacity: 0.85;
	transition: opacity 150ms linear;
	transition-delay: 150ms;
}


.editor-editImageModal-resetImageBtn:hover{
	opacity: 1;
	transition-delay: 0ms;
	background: rgb(14,14,24);
	transition: opacity 0ms linear;
}

///
/// DRAGGABLE MARKER
/// 

.editor-editImageModal-markupPanel-itemContainer{
	margin: 4px;
}

.editor-editImageModal-markupPanel-boxSpacer{
	height: 40px;
	width: 40px;
	background: $white;
	border-radius: 2px;
	margin-top: 8px;
}

.editor-editImageModal-draggableMarker{ 	
 	transform-origin: center center;


 	border-radius: 50%;
 	border: 3px solid rgba(255,255,255,0.9);
 	box-shadow: 0px 0px 0px 2px rgba(0,0,10,0.125);

 	cursor: grab;

 	display: flex;
 	align-items: center;
 	justify-content: center;

 	font-size: 21px;
 	font-weight: $lato-bold;
 	color: $white;

 	box-sizing: border-box;
 	padding-right: 0px;
 	padding-bottom: 3px;

 	line-height: 1.3;

 	user-select: none;

 	position: relative;

 	// TEMP DEV
 	// opacity: 0.5;
}

.editor-editImageModal-markupPanel
.editor-editImageModal-draggableMarker{
	cursor: pointer; // maybe click to return to original spot
}

.editor-editImageModal-draggableMarker--1{
	background: $blue;
}

.editor-editImageModal-draggableMarker--2{
	background: $highlight--2-textColor;
}

.editor-editImageModal-draggableMarker--3{
	background: $highlight--3-textColor;
	background: #9043c3; // purple
}

.editor-editImageModal-draggableMarker--4{
	background: $highlight--1-textColor;
	// background: #d8353a;
	background: #02ae5e; // green
}

.editor-editImageModal-draggableMarker--5{
	background: $highlight--5-textColor;
	// background: #9043c3;
	background: #EF4F53;
}

.editor-editImageModal-draggableMarker--initial{
	background: black;
	border: 1px solid $gray-10;
}

.editor-editImageModal-draggableMarker-position{
	position: absolute;
	right: -82px;
	bottom: -42px;
	width: 60px;
	height: 50px;

	background: $gray-6;
	font-size: 10px;
	opacity: 1;

	padding: 4px;

	pointer-events: none;
}

//

.editor-editImageModal-rotatePanel-marker{
	pointer-events: none;
	height: 4px;
	position: absolute;
	margin: auto;
	top: -1px;
	right: 0px;
	bottom: 0px;

	z-index: 100;

	border-radius: 2px;

	width: 18px;
	// background: $blue;
}

//
//
// MARKER CANVAS

.editor-editImageModal-markerCanvas{
	position: absolute;
	top: 0px;
	left: $width-leftBar-editImageModal;
	bottom: 0px;
	right: 0px; // temp
	// background: $tempr;
	z-index: 9998;
}

.editor-editImageModal-markerCanvas-markerContainerContainer{
	// background: $blue;
	// width: 30px;
	// height: 30px;
	// z-index: 500;
	z-index: 9997;
	position: relative;
}

.editor-editImageModal-markerCanvas-markerContainer{
	width: 32px;
	height: 32px;
	border-radius: 16px;

	position: relative;
}

.editor-editImageModal-markerCanvas-markerContainerContainer:focus-within{
	z-index: 9998;
}

.editor-editImageModal-markerCanvas-marker
.editor-editImageModal-draggableMarker{
	position: absolute;
	top: 0px;
	left: 0px;
}

.editor-editImageModal-markerCanvas-markerContainer:focus{
	outline: none;
}

.editor-editImageModal-markerCanvas-markerContainer:focus
.editor-editImageModal-draggableMarker{
	// box-shadow: 0px 0px 0px 2px red;
}

.editor-editImageModal-markerCanvas-markerContainerContainer--markerFaded
.editor-editImageModal-draggableMarker{
	opacity: 0.5;
}

//
// COVER

.editor-editImageModal-markerCanvas-cover,
.editor-editImageModal-markerCanvas-cover:focus{
	outline: none;
}

.editor-editImageModal-canvas:hover
.editor-editImageModal-markerCanvas-cover{
	outline: 1px solid rgba(0,0,5,0.08);
}

.editor-editImageModal-canvas:hover
.editor-editImageModal-markerCanvas-cover:hover{
	outline: 1px solid rgba(0,0,5,0.10);
}

.editor-editImageModal-canvas:hover
.editor-editImageModal-markerCanvas-cover:focus{
	//outline: 2px solid $red;
	outline: 1px solid rgba(0,0,5,0.12);
}

.editor-editImageModal-markerCanvas-coverContainer{
	z-index: 9994;
	position: relative;
}

.editor-editImageModal-markerCanvas-coverContainer:hover{
	z-index: 9995;
}

.editor-editImageModal-markerCanvas-coverContainer:focus-within{
	z-index: 9996;
}

.editor-editImageModal-markerCanvas-cover{
	position: absolute;
	top: 0px;
	left: 0px;
	right: 0px;
	bottom: 0px;

	background: rgba(255,255,255,0.5);
	// background: $white;
}

.editor-editImageModal-markerCanvas-cover-fullBG{
	position: absolute;
	background: $white;
	// background: red;
}



.editor-editImageModal-markerCanvas-cover-cornerHandleContainer{
	position: absolute;
	height: 100%;
	width: 100%;
	// background: $tempr;
	z-index: 10;
}

.editor-editImageModal-markerCanvas-cover-cornerHandle{
	position: absolute;
	top: 0px;
	bottom: 0px;
	right: 0px;
	left: 0px;

	margin: auto;

	border-radius: 2px;
	background: $white;
	box-shadow: 0px 0px 0px 2px rgba(0,0,0,0.5);

	width: 10px;
	height: 10px;

	// width: 10px;
	// height: 10px;
}

.editor-editImageModal-markerCanvas-cover-cornerHandleContainer--topLeft{
	top: -6px;
	left: -6px;
	cursor: nwse-resize;
}

.editor-editImageModal-markerCanvas-cover-cornerHandleContainer--topRight{
	top: -6px;
	right: -6px;
	cursor: nesw-resize;
}

.editor-editImageModal-markerCanvas-cover-cornerHandleContainer--bottomLeft{
	bottom: -6px;
	left: -6px;
	cursor: nesw-resize;
}

.editor-editImageModal-markerCanvas-cover-cornerHandleContainer--bottomRight{
	bottom: -6px;
	right: -6px;
	cursor: nwse-resize;
}




.editor-editImageModal-markerCanvas-coverContainer
.editor-editImageModal-markerCanvas-cover-cornerHandleContainer{
	opacity: 0;
}

.editor-editImageModal-markerCanvas-coverContainer:hover
.editor-editImageModal-markerCanvas-cover-cornerHandleContainer{	
	opacity: 1;
}

.editor-editImageModal-canvas:hover
.editor-editImageModal-markerCanvas-coverContainer:focus-within
.editor-editImageModal-markerCanvas-cover-cornerHandleContainer{	
	opacity: 1;
}

.editor-editImageModal-markerCanvas-coverContainer.react-draggable-dragging
.editor-editImageModal-markerCanvas-cover-cornerHandleContainer,
.editor-editImageModal-markerCanvas-coverContainer--resizing
.editor-editImageModal-markerCanvas-cover-cornerHandleContainer{	
	opacity: 1;
}




// FOR DEV
// .editor-editImageModal-markerCanvas-coverExample-position{
// 	position: absolute;
// 	bottom: -100px;
// 	right: 0px;
// 	left: 0px;
// 	margin: auto;

// 	height: 84px;
// 	width: 185px;

// 	background: $gray-6;
// 	color: $white;
// 	font-size: 10px;
// 	padding: 4px;

// 	line-height: 1.3;

// }









//
// SUBMIT LOADING

.editor-editImageModal-doneBtn-submitLoadingContainer{
	margin-top: -10px; //offset btn padding, a bit dirty
	width: 100%;
	height: 50px;	
	display: flex;
	align-items: center;
	justify-content: center;
}

.editor-editImageModal-doneBtn-submitLoadingSpinner{

	height: 20px;
	width: 20px;
  
  border-radius: 50%;

  border: 3px rgba(0,0,0,0.2) solid;
  border-top: 3px $white solid;

  opacity: 0.75;

  animation: spinnerRotateAnimation 0.4s infinite linear;
}


