//
// APPLY ORDER MODAL
// Join group orders using magic link
// similar to ISBE Match modal
//

.applyOrderModalContainer{
	position: fixed;
	z-index: 9999;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: rgba(5,5,10,0.75);
	
	display: flex;
	
	align-items: center;
	justify-content: center;
}

.applyOrderModal{
	border-radius: 10px;
	
	//background: rgb(245,245,252);
	//background: rgb(248,248,254);
	 background: $white;
	border: 1px solid $gray-5;
	box-shadow: 
		0px 8px 16px 0px rgba(5,5,10,0.12),
		0px 16px 32px 0px rgba(5,5,10,0.08);

	box-sizing: border-box;

	overflow: hidden;

	width: 880px;
	min-height: 494px;

	padding-top: 48px;
	padding-bottom: 24px;


	position: relative;

	text-align: center;

	cursor: default;
}


//

.applyOrderModal-h1,
.applyOrderModal--error-h1{
	font-size: 34px;
	font-weight: $lato-heavy;
	color: $textBlackDark;
	margin-bottom: 16px;
	word-break: break-word;
	padding-left: 32px;
	padding-right: 32px;
	box-sizing: border-box;
}	

.applyOrderModal-benefitsHeader{	
	font-weight: $lato-semibold;
	font-size: 14px;
	color: $textDark;

	text-transform: uppercase;
	letter-spacing: 0.04em;

	margin-top: 20px;
}

.applyOrderModal-benefitsContainer{
	display: flex;
	justify-content: space-between;
	margin: 0 auto;
	margin-top: 8px;
	margin-bottom: 18px;
	position: relative;
	width: 765px; // cloud BG SVG size
	height: 157px;
	box-sizing: border-box;
	padding-top: 40px;
	padding-left: 18px;
	padding-right: 18px;
}

.applyOrderModal-benefitsCloudBG{	
	position: absolute;
	top: 0px;
	left: 0px;
	right: 0px;
	bottom: 0px;
	z-index: 0;
}

.applyOrderModal-benefitsCloudBG svg{	
	fill: $cloudBGLightBlue;
}

.applyOrderModal-benefit{
	width: 32.5%;
	position: relative;
	z-index: 5;
}


.applyOrderModal-benefit-header{
	font-size: 18px;
	font-weight: $lato-heavy;
	color: $textBlack;

	margin-bottom: 6px;
}

.applyOrderModal-benefit-desc{
	font-weight: $lato-semibold;
	font-size: 14px;
	font-weight: $textMid;
}



// BUTTONS
.applyOrderModal button.applyorder,
.applyOrderModal button.applyorder:hover,
.applyOrderModal button.errordone{
	width: 610px;
	min-height: 66px;
	height: auto;
	padding-top: 18px;
	padding-bottom: 18px;
	padding-left: 16px;
	padding-right: 16px;

	background: $textBlue;
	display: block;
	line-height: 1.25;

	margin: 0 auto;

	border-radius: 5px;

	margin-bottom: 10px;
	cursor: pointer;

	font-size: 22px;
	font-weight: $lato-heavy;
	color: $white;
	letter-spacing: 0.002em;

	word-break: break-word;
}

.applyOrderModal button.applyorder:hover{
	background: darken($textBlue, 2%);
}


// SMALL PRINT
.applyOrderModal-smallPrint{
	font-size: 13.5px;
	font-weight: $lato-semibold;
	color: $textMid;
	margin-bottom: 16px;
	height: 16px;
}

.applyOrderModal-smallPrint--error{
	font-weight: $lato-bold;
	color: $red-dark;
}

// CANCEL BUTTON

.applyOrderModal-noThanks{
	font-weight: $lato-semibold;
	font-size: 14.5px;
	cursor: pointer;
	color: $textLight;
	text-decoration: underline;
}

.applyOrderModal-noThanks:hover{
	color: $textMid;
}

// .ISBEDomainMatchModal-h2{
// 	font-weight: $lato-bold;
// 	font-size: 18px;
// 	color: $textDark;
// 	margin-bottom: 6px;
// }

// .ISBEDomainMatchModal-h2 b{
// 	font-weight: $lato-heavy;
// 	color: $textBlack;
// }

// a.ISBEDomainMatchModal-ISBEInfoLink,
// a.ISBEDomainMatchModal-ISBEInfoLink:visited{
// 	font-weight: $lato-semibold;
// 	font-size: 14px;
// 	color: darken($textLight, 5%);
// 	text-decoration: underline !important; //overwritten at base
// }

// a.ISBEDomainMatchModal-ISBEInfoLink:hover{
// 	color: $textMid;
// }




// CLOSE BUTTON
.applyOrderModal-closeBtn{
	position: absolute;
	width: 36px;
	height: 36px;
	top: 20px;
	right: 24px;
	cursor: pointer;
	border-radius: 18px;

	opacity: 0.3;
	transition: opacity 100ms linear;

	z-index: 100;
}

.applyOrderModal-closeBtn:hover{
	opacity: 0.8;
	transition: opacity 50ms linear;
}

.applyOrderModal-closeBtn:active{
	opacity: 1;
}

.applyOrderModal-closeBtn .icon{
	width: 36px;
	height: 36px;
	fill: $gray-8;
}



//
// LOADING STATE
//


.applyOrderModal--loading-messageContainer{
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;

	display: flex;
	align-items: center;
	justify-content: center;
}

.applyOrderModal--loading-message{
	margin-top: -40px;

	font-size: 24px;
	font-weight: $lato-heavy;
	color: transparentize($textLight, 0.2);
}


// Error

.applyOrderModal--error-h1{
	margin-top: 24px;
}

.applyOrderModal--error-h2{
	margin-top: 24px;
	font-size: 18px;
	font-weight: $lato-bold;	
	margin-bottom: 230px;
}

.applyOrderModal--error-h2 b {
	color: $textBlue;
}

