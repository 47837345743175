//
// SIDENAV
//


.packReview-sideNav-activeAccount{
	font-weight: $lato-bold;
	font-size: 15px;
	margin-left: 9px;
	color: $textLight;
	//letter-spacing: 0.01em;
	margin-bottom: 24px;
	user-select: none;
	cursor: default;
}

button.packReview-sideNav-filter{
	width: 100%;
	height: 24px;

	background: none;
	outline: none;
	box-shadow: none;
	border: none;
	
	display: flex;

	font-size: 14px;
	font-weight: $lato-semibold;
	border-radius: 3px;
	border: 1px solid $invisible;

	box-sizing: border-box;	

	align-items: center;

	padding-left: 8px;
	padding-right: 3px;

	cursor: pointer;

	letter-spacing: 0.00em;
}

button.packReview-sideNav-filter--active{	
	border: 1px solid $gray-12;
	background: $white;
	box-shadow: 0px 1px 2px 0px rgba(0,0,40,0.04);
	color: $textBlackDark;
	font-weight: $lato-bold;
}

button.packReview-sideNav-filter--inactive{
	color: lighten($textBlack, 10%);
}

button.packReview-sideNav-filter--inactive:hover{
	color: darken($textBlack, 10%);
	background: rgba(0,0,40,0.03);
}

.packReview-sideNav-filter-label{
	flex-grow: 1;
	flex-shrink: 1;

	text-align: left;
}

.packReview-sideNav-filter-count{
	margin-left: auto;
	
	height: 18px;
	box-sizing: border-box;
	padding-right: 6px;
	padding-left: 2px;
	min-width: 20px;
	border-radius: 4px;

	font-size: 14px;

	// background: $tempr;

	display: flex;
	align-items: center;
	justify-content: flex-end;

	opacity: 0.75;
	transition: opacity 50ms linear;
}

.packReview-sideNav:hover
.packReview-sideNav-filter-count{
	opacity: 1;
	transition: opacity 50ms linear;
}


button.packReview-sideNav-filter--active
.packReview-sideNav-filter-count{
	color: $textMid;
}

button.packReview-sideNav-filter--inactive
.packReview-sideNav-filter-count{
	color: $textLight;
}


button.packReview-sideNav-filter--inbox.packReview-sideNav-filter--active
.packReview-sideNav-filter-count{
	background: transparentize($blue-bold, 0.9);
	color: darken($blue-bold, 5%);
	font-weight: $lato-bold;	
}

// button.packReview-sideNav-filter--inbox.packReview-sideNav-filter--inactive
// .packReview-sideNav-filter-count{
// 	background: $gray-8;
// 	color: $white;	
// }

button.packReview-sideNav-filter--active
.packReview-sideNav-filter-count{
	opacity: 1;
}


.packReview-sideNav-spacer{
	height: 10px;
}

