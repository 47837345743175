$height-cellRow--studentCell: 36px;
$leftHangingMargin-cellRow: 20px;
$rightHangingMargin-cellRow: 16px;
$width-cellRowLeft--studentCell: 35px;
$width-cellRowRight--studentCell: 35px;
$width-extendedHangingMargins: calc(100% + #{$leftHangingMargin-cellRow} + #{$rightHangingMargin-cellRow});	// width: calc(100% - 15px);

.studentCell{
	width: $width-extendedHangingMargins;
	margin-left: calc(-1 * #{$leftHangingMargin-cellRow});
	margin-right: calc(-1 * #{$rightHangingMargin-cellRow});
	padding-left: $leftHangingMargin-cellRow;
	padding-right: $rightHangingMargin-cellRow;
	box-sizing: border-box;

	
	border-radius: 3px;
	margin-bottom: 1px;
	cursor: default;
	user-select: none;
}

.studentCell-content{
	height: $height-cellRow--studentCell;
	width: 100%;
	display: flex;
	box-shadow: 0px 1px 0px 0px $lineMid;	
	position: relative;
}

$minWidth-studentCell-firstName: 115px;
$minWidth-studentCell-lastName: 250px;
$minWidth-studentCell-cardNo: 85px;

.studentCell-left{
	flex-grow: 0;
	flex-shrink: 0;
	width: $width-cellRowLeft--studentCell;
}

.studentCell-firstName{
	width: $minWidth-studentCell-firstName;
	flex-grow: 3;
	flex-shrink: 0;
}

.studentCell-lastName{
	padding-left: 15px; // can't add padding to firstName because of overflow;
	box-sizing: border-box;
	width: $minWidth-studentCell-lastName;
	flex-grow: 20;
	flex-shrink: 0;

}

.studentCell-cardNo{
	width: $minWidth-studentCell-cardNo;

	flex-grow: 1;
	flex-shrink: 0;

	display: flex;
	align-items: center;
}

.studentCell-right{
	flex-grow: 0;
	flex-shrink: 0;
	width: $width-cellRowRight--studentCell;
}

///


.stickyDivider.in--sectionStudents{
	border-bottom: 1px solid $lineMid;
	width: $width-extendedHangingMargins;
	margin-left: calc(-1 * #{$leftHangingMargin-cellRow});
	margin-right: calc(-1 * #{$rightHangingMargin-cellRow});
	padding-left: calc(#{$leftHangingMargin-cellRow} + #{$width-cellRowLeft--studentCell});
	padding-right: calc(#{$rightHangingMargin-cellRow} + #{$width-cellRowRight--studentCell});
	box-sizing: border-box;

	//margin-top: 8px;
	padding-top: 6px;
	height: 32px;


	background: $white;

	position: sticky;
	top: calc(#{$height-pageHeader--sticky} + #{$height-topNav});	
	z-index: 5;

	margin-bottom: 0px;
}

.stickyDivider.in--sectionStudents .sortableColumnHeaders{
	display: flex;
	width: 100%;
	box-sizing: border-box;
	padding-left: 0px;	
	font-size: 12px;
	font-weight: $lato-semibold;
	color: $textMid;
	text-transform: uppercase;
	letter-spacing: 0.1em;
	line-height: 2.2;

	cursor: default;
	user-select: none;
}

.stickyDivider.in--sectionStudents .sortableColumnHeaders-firstName 	{
	width: $minWidth-studentCell-firstName;
	flex-grow: 3;
	flex-shrink: 0;
}

.stickyDivider.in--sectionStudents .sortableColumnHeaders-lastName 	{
	width: $minWidth-studentCell-lastName;
	flex-grow: 20;
	flex-shrink: 0;
	padding-left: 15px;
	box-sizing: border-box;
}

.stickyDivider.in--sectionStudents .sortableColumnHeaders-cardNo 	{
	width: $minWidth-studentCell-cardNo;
	flex-grow: 1;
	flex-shrink: 0;
}



// STYLE 
.studentCell-firstName{
	font-size: 14px;
	line-height: 2.5;
	color: $textBlack;
	font-weight: $lato-semibold;

	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}



.studentCell-lastName{
	font-size: 14px;
	line-height: 2.5;
	color: $textMid;
	font-weight: $lato-medium;

	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}


.studentCell-cardNo-numberBlock{
	width: 24px;
	height: 24px;
	border: 1px solid;
	border-radius: 3px;
	
	border-top-color: $lineMid;
	border-left-color: $lineMid;
	border-right-color: $lineMid;
	border-bottom-color: $lineDark;

	font-size: 14px;
	font-weight: $lato-semibold;
	color: $textDark;
	text-align: center;

	line-height: 1.7;

	background: $white;
}

.studentCell{
	background: $white;	
}

.students-table .react-contextmenu-wrapper:nth-child(odd) .studentCell-content{
	background: $color-cellBG-zebra;
}




// HOVER STATE

.studentCell:hover .studentCell-content,
.students-table .react-contextmenu-wrapper:nth-child(odd):hover .studentCell .studentCell-content{
	background: rgb(245,245,253);
}

// ACTIVE STATE

.studentCell.is--active{
	background: $blue-light;
}

.studentCell.is--active .studentCell-content,
.students-table .react-contextmenu-wrapper:nth-child(odd) .studentCell.is--active .studentCell-content{
	background: none;
}

.students-table .react-contextmenu-wrapper:nth-child(odd):hover .studentCell.is--active:nth-child(odd) .studentCell-content{
	background: none;
}



.studentCell.is--active .studentCell-firstName{
	color: $white;
}
.studentCell.is--active .studentCell-lastName{
	color: $white;
}

.studentCell.is--active .studentCell-cardNo-numberBlock{
	border-color: rgba(0,0,0,0);
	background: rgba(0,0,0,0.1);
	color: $white;
}


//CONTEXT MENU STATE

.students-table .react-contextmenu-wrapper .studentCell.has--menu,
.students-table .react-contextmenu-wrapper .studentCell.has--menu:hover {
	background: transparentize($blue-light,0.9);
}

.students-table .react-contextmenu-wrapper:nth-child(odd) .studentCell.has--menu,
.students-table .react-contextmenu-wrapper:nth-child(odd) .studentCell.has--menu:hover{
	background: transparentize($blue-light,0.85);	
}

.students-table .react-contextmenu-wrapper .studentCell.has--menu .studentCell-content,
.students-table .react-contextmenu-wrapper:nth-child(odd) .studentCell.has--menu .studentCell-content,
.students-table .react-contextmenu-wrapper .studentCell.has--menu:hover .studentCell-content,
.students-table .react-contextmenu-wrapper:nth-child(odd) .studentCell.has--menu:hover .studentCell-content{
	background: none;
}



.students-table .react-contextmenu-wrapper .studentCell.is--active.has--menu,
.students-table .react-contextmenu-wrapper .studentCell.is--active.has--menu:hover{
	background: darken($blue-light,5%);
}




// ARCHIVED STUDENT

.studentCell.is--archived .btn {
	width: 85px;
	margin-top: 4px;
}

.studentCell.is--archived .studentCell-lastName{
	width: 150px;
}

.studentCell.is--archived .btn.unarchive{
	margin-right: 10px;
	margin-left: auto;
}

.studentCell.is--archived.is--active .btn{
	background: rgba(0,0,0,0.1);
	border-color: rgba(0,0,0,0.05);
	color: $white;
}