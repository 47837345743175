//$height-inProgress: 450px;
$maxHeight-inProgress-topSection: 110px;

.sectionHome-inProgress{
	width: 100%;
	margin-top: 25px;
	//height: $height-inProgress;
	position: relative;
	//display: flex;
	padding-bottom: 40px;
	// background: $tempr;
}

// 
// ACTIVE ITEM PREVIEW
// 

.sectionHome-inProgress-label{
	font-size: 16px;
	font-weight: $lato-bold;
	text-transform: uppercase;
	color: $textBlue;
}

.sectionHome-inProgress-topSection{
	max-height: $maxHeight-inProgress-topSection;	
	width: 100%;
	cursor: default;
}

.sectionHome-inProgress-titleBar{
	width: 100%;
	display: flex;
	// background: $tempr;
	margin-top: 20px;
}

.sectionHome-inProgress-titleBar-title{
	font-size: 26px;
	font-weight: $lato-heavy;
	color: $textBlack;

	line-height: 1.25;

	flex-grow: 1;
	
	max-height: 100px;

	display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;

  text-overflow: clip;
  white-space: normal;
  overflow: hidden; 
}

.sectionHome-inProgress-titleBar-title .icon.shared{
	width: 19px;
	height: 19px;
	fill: $gray-115;
	margin-left: 4px;
	position: relative;
	top: 1px;
}


.sectionHome-inProgress-subtitle{
	margin-top: 7px;

	font-size: 17px;
	font-weight: $lato-semibold;
	
	margin-bottom: 20px;

	display: flex;

}

a.sectionHome-inProgress-subtitle-containingRepo{
	flex-grow: 0;
	flex-shrink: 1;

	color: $textMid;
	margin-right: 8px;	

	white-space: nowrap; 
  overflow: hidden;
  text-overflow: ellipsis;
}

a.sectionHome-inProgress-subtitle-containingRepo:hover{
	color: $textBlue;
}

.sectionHome-inProgress-subtitle-timestamp{
	flex-grow: 0;
	flex-shrink: 0;

	color: $textLight;
}




/// REMOVE BUTTON
// copied from upcoming

.sectionHome-inProgress-titleBar-removeBtn{

	width: 24px;
	height: 24px;
	
	margin-top: 4px;	

	display: flex;
	align-items: center;
	justify-content: center;

	border: 1px solid $lineDark;
	border-bottom: 1px solid $lineDark;
	background: $white;
	border-radius: 3px;
	box-sizing: border-box;
	cursor: pointer;
	z-index: 1;
}

.sectionHome-inProgress-titleBar-removeBtn .icon{
	width: 12px;
	height: 12px;

	fill: $textLight;
}

.sectionHome-inProgress
.sectionHome-inProgress-titleBar-removeBtn{
	opacity: 0;
	transition: opacity 150ms ease-in-out;
}

.sectionHome-inProgress:hover
.sectionHome-inProgress-titleBar-removeBtn{
	opacity: 0.5;
	transition: opacity 25ms ease-in-out;
}

.sectionHome-inProgress
.sectionHome-inProgress-titleBar-removeBtn:hover{
	opacity: 1;
	transition: opacity 25ms ease-in-out;
}

//

.sectionHome-inProgress-actionRow{
	display: flex;
	width: 100%;
	margin-left: -9px;
}

.sectionHome-inProgress-actionRow .btn.btn--mid.btn--white.play-now{
	min-width: 100px;
	width: 100px;
}

.sectionHome-inProgress-counter{
	margin-left: auto;
	font-size: 18px;
	font-weight: $lato-bold;
	position: relative;
	top: 5px;
	cursor: default;
}

.sectionHome-inProgress-counter-count{
	color: $textBlack;
}

.sectionHome-inProgress-counter-slash{
	color: $gray-11;
	margin-left: 3px;
	margin-right: 3px;
}

.sectionHome-inProgress-counter-total{
	color: $textLight;
}

//
.sectionHome-inProgress-titleBar-scorePill{
	width: 100px;
	display: flex;
	align-items: center;
	justify-content: flex-end;
	height: 30px;
	position: relative;
	top: -3px;
}

.sectionHome-inProgress-titleBar-scorePill .scorePill{
	font-size: 22px;
	width: 100px;
	text-align: right;
}

.sectionHome-inProgress-titleBar-scorePill .scorePill .scorePill-dot{
	height: 9px;
	width: 9px;
	border-radius: 5px;
	top: -3px;
	right: 2px;
}

.sectionHome-inProgress-titleBar-scorePill .scorePill-percentSign{
	opacity: 0.5;
}


/// CAROUSEL

.sectionHome-inProgress-carouselContainer{
	width: calc(100% + #{$leftGutter-centerPanel} + #{$rightGutter-centerPanel});
	margin-left: calc(-1 * #{$leftGutter-centerPanel});
	overflow: hidden;	
	position: relative;
	margin-top: 8px;
}

.sectionHome-inProgress-carouselControls{
	display: flex;
	position: absolute;
	top: 0px;
	left: 0px;
	right: 0px;
	bottom: 0px;
	z-index: 50;
}
.sectionHome-inProgress-carouselControls-previous{
	position: absolute;
	left: 0px;
	top: 0px;
	bottom: 0px;
	width: $leftGutter-centerPanel;
}

.sectionHome-inProgress-carouselControls-next{
	position: absolute;
	right: 0px;
	top: 0px;
	bottom: 0px;
}

.sectionHome-inProgress-carousel{
	display: flex;
	transform: translateX(90px);
	position: absolute;
	bottom: 0px;
	left: 0px;
	height: 100%;
}

.sectionHome-inProgress-carousel-carouselItem{
	
	box-sizing: border-box;

	height: 100%;

	flex-shrink: 0;
	flex-grow: 0;
	
	display: flex;
	position: relative;

	border-radius: 3px;

	overflow: hidden;

	border: 1px solid rgba(0,0,30,0.1);
	border-bottom: 1px solid rgba(0,0,30,0.15);
	box-shadow: 0px 1px 2px 0px rgba(0,0,0,0.04),
							0px 5px 10px -5px rgba(0,0,0,0.1);
}



.sectionHome-inProgress-carousel-carouselItem .slide{
	position: absolute;
	top: 0px;
	left: 0px;	
}


// NEXT MARKER
.sectionHome-inProgress-carousel-carouselItem-nextMarker,
.sectionHome-inProgress-carousel-carouselItem-newMarker{
	position: absolute;
	bottom: 8px;
	right: 11px;
	z-index: 20;

	user-select: none;

	width: 50px;
	height: 22px;

	background: $gray-6;
	border-radius: 3px;
	font-size: 13px;
	font-weight: $lato-bold;
	text-transform: uppercase;

	text-align: center;
	letter-spacing: 0.025em;
	color: $white;

	line-height: 1.75;
}

.sectionHome-inProgress-carousel-carouselItem-newMarker{
	background: $blue;
}

.sectionHome-inProgress-carousel-carouselItem .scorePill.scorePill--mid{
	position: absolute;
	bottom: 8px;
	right: 11px;
	z-index: 20;
	width: 50px;
	height: 22px;
	border-radius: 3px;

	user-select: none;
}

.sectionHome-inProgress-carousel-carouselItem .scorePill.scorePill--mid span{
	font-size: 15px;
	font-weight: $lato-bold;
	line-height: 1.45;
}

///

.sectionHome-inProgress-bottomSection{
	display: flex;
	width: calc(100% + 16px); // to align labels of buttons
	margin-left: -8px; // to align labels of buttons
}

.sectionHome-inProgress-bottomSection-action{
	font-size: 14px;
	font-weight: $lato-semibold;
	color: $blue-light;
	border-radius: 3px;
	padding-left: 8px;
	padding-right: 8px;
	margin-left: 8px;
	height: 30px;
	line-height: 2.2;
	cursor: pointer;
	user-select: none;
	text-align: center;

	white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.sectionHome-inProgress-bottomSection-action:hover{
	background: $gray-15;
	color: $blue;
}

.sectionHome-inProgress-bottomSection-action:active{
	background: $gray-14;
}


.sectionHome-inProgress-bottomSection-action--editSet{
	margin-left: auto;
	
}

.sectionHome-inProgress-bottomSection-action--students{
	min-width: 135px;
	width: 135px;
	margin-right: auto;
	margin-left: 8px;
}

.sectionHome-inProgress-bottomSection-action--students--hide{
	background: $gray-8;
	color: $white;
}

.sectionHome-inProgress-bottomSection-action--students--hide:hover{
	background: $gray-7;
	color: $white;
}

///

.sectionHome-inProgress-divider{
	height: 1px;
	background: $color-sectionHome-dividerGray;
	width: calc(100% + #{$rightGutter-centerPanel});
	position: absolute;
	left: 0px;
	bottom: 0px;
}

// STUDENT LIST
// follows set report


// STUDENT LIST
.sectionHome-inProgress-studentScoresContainer{
	margin-top: 8px;
	//padding-bottom: 24px;
}

.sectionHome-inProgress-studentScores{
	column-count: 4;
  column-gap: 28px;
	width: calc(100% - 16px);
	margin-left: 8px;
	overflow: hidden;	
}

.sectionHome-inProgress-studentScores-blockLine{
	// blocks top border of top in each column
	position: relative;
	z-index: 5;
	width: 100%;
	border-bottom: 2px solid $white;
	margin: 0 auto;
	margin-bottom: -1px;
}


.sectionHome-inProgress-studentScores-studentCard{
	user-select: none; 
	cursor: default;

 	display: inline-block;
 	vertical-align: top;

 	break-inside: avoid-column;
 	-webkit-column-break-inside: avoid;

	width: 100%;

	height: 28px;
	border-top: 1px solid $lineMid;
	
	position: relative;

	display: flex;
	align-items: center;

}

.sectionHome-inProgress-studentScores-studentCard .sectionHome-inProgress-studentScores-studentCard-name{
	line-height: 1.9;
  	
	font-weight: $lato-semibold;
	color: $textBlack;
	font-size: 14px;

	width: 10px;
	flex-grow: 1;

	white-space: nowrap;
	overflow: hidden;

	white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}


.sectionHome-inProgress-studentScores-studentCard .scorePill{
	flex-grow: 0;
	transform: scale(0.9);
	transform-origin: 100% 50%;
}


