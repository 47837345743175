$paddingTop-importQuestions-inputArea: 15px;

.importQuestions-inputArea{
	background: $white;
	height: 100%;
	min-width: 10px;
	flex-grow: 1;

	overflow-y: scroll;

	display: flex;

	position: relative;
	background: $gray-14; // only displays with over max question limit
}


$bgColor-importQuestions-inputArea-scrollbar: $gray-1225;

.importQuestions-inputArea::-webkit-scrollbar-track{
  background-color: $bgColor-importQuestions-inputArea-scrollbar;
}

.importQuestions-inputArea::-webkit-scrollbar{
  width: 20px;
  background-color: $bgColor-importQuestions-inputArea-scrollbar;
}

.importQuestions-inputArea::-webkit-scrollbar-thumb{
  background-color: $white;
  border: 1px solid $gray-12; 
}



//
// LEFT GUTTER
// USED FOR SELECTING THE QUESTION AND CHOICE
//

.importQuestions-inputArea-leftGutter{
	width: $width-importQuestions-textInput-leftGutter;
	flex-grow: 0;
	flex-shrink: 0;
	background: $white;
	border-right: 1px solid $lineDark;

	box-sizing: border-box;
	padding-top: $paddingTop-importQuestions-inputArea;

	z-index: 100;
}


.importQuestionsInputAreaLeftGutter-question{
	position: relative;
}


.importQuestionsInputAreaLeftGutter-question-number,
.importQuestionsInputAreaLeftGutter-question-choice{
	box-sizing: border-box;
	padding-left: 10px;
	text-align: center;
	height: 22px;

	font-size: 15px;
	font-weight: $lato-bold;
	padding-top: 2px;
	padding-bottom: 2px;

	user-select: none;

}


///

.importQuestionsInputAreaLeftGutter-question-number{	
	color: transparentize($textBlue, 0.1);
	background: transparentize($blue, 0.95);
	background: $white;
	font-weight: $lato-heavy;
	font-size: 15px;
}

.importQuestionsInputAreaLeftGutter-question-choice{
	color: $gray-7;
	color: $gray-10;
	cursor: pointer;
}

.importQuestionsInputAreaLeftGutter-question-choice--isCorrect{
	background: transparentize($green, 0.95);
	color: darken($green, 0.4);	
}

.importQuestionsInputAreaLeftGutter-question-choice--isSurvey{
	background: transparentize($blue, 0.95);
	color: transparentize($textBlue, 0.2);
}

.importQuestionsInputAreaLeftGutter-question-choice--isNotCorrect{
	background: $gray-15;
}

.importQuestionsInputAreaLeftGutter-question-choice--isNotCorrect:hover{
	//background: $gray-14;
	background: rgb(252,252,255);
	color: $gray-7;
}

// STATE

// ActiveCursor limit (E.g. for free users)
.importQuestionsInputAreaLeftGutter-question--isActiveCursor
.importQuestionsInputAreaLeftGutter-question-number{
	color: $textBlue;
	background: transparentize($blue, 0.8);
	background: $white;
}

.importQuestionsInputAreaLeftGutter-question--isActiveCursor
.importQuestionsInputAreaLeftGutter-question-choice--isCorrect{
	background: transparentize($green, 0.85);
	color: darken($green, 0.8);	
}

.importQuestionsInputAreaLeftGutter-question--isActiveCursor
.importQuestionsInputAreaLeftGutter-question-choice--isNotCorrect{
	//background: $white;
	color: $gray-8;

}

// active cursor right border
.importQuestionsInputAreaLeftGutter-question-activeRightBorder{
	position: absolute;
	top: 0px;
	left: 0px;
	bottom: 0px;
	width: 6px;
	background: $textBlue;
	z-index: 10;
}

// outside limit (E.g. for free users)
.importQuestionsInputAreaLeftGutter-question--outsideLimit .importQuestionsInputAreaLeftGutter-question-number,
.importQuestionsInputAreaLeftGutter-question--outsideLimit .importQuestionsInputAreaLeftGutter-question-choice{
	background: $gray-13;
	color: $gray-11;
	pointer-events: none;
	cursor: default;
}

.importQuestionsInputAreaLeftGutter-question--outsideLimit .importQuestionsInputAreaLeftGutter-question-choice{
	color: $gray-12;
}

// Dropdown to set to survey

.importQuestionsInputAreaLeftGutter-question-dropdownContainer{
	position: absolute;
	//width: 30px;
	height: 20px;
	top: 0px;
	left: 0px;
	right: 0px;
	cursor: pointer;

	display: flex;
	justify-content: flex-end;

}

.importQuestionsInputAreaLeftGutter-question-dropdownContainer button.menu{
	
	width: $width-importQuestions-textInput-leftGutter;
	height: 20px;
	
	border: none;
	background: none;
	border-radius: 0px;
	cursor: pointer;

	position: relative;
}

.importQuestionsInputAreaLeftGutter-question-dropdownContainer button.menu.btn--mid.icon--large svg.icon{
	fill: $blue;
	width: 14px;
	height: 14px;

	position: absolute;
	right: 7px;
	top: 3px;
	
}


.importQuestionsInputAreaLeftGutter-question-dropdownContainer .dropdownMenu{
	width: 100px;
	padding-top: 6px;
	padding-bottom: 6px;
}

.importQuestionsInputAreaLeftGutter-question-dropdownContainer .dropdownMenu .dropdownMenu-item,
.importQuestionsInputAreaLeftGutter-question-dropdownContainer .dropdownMenu .dropdownSelect-header{
	padding-left: 12px;
}

.importQuestionsInputAreaLeftGutter-question-dropdownContainer .dropdownMenu.direction--down{
	top: 20px;
}

.importQuestionsInputAreaLeftGutter-question-dropdownContainer .dropdownMenu.position--left{
	left: 60px;
}

// only show the dots when hovering over question
.importQuestionsInputAreaLeftGutter-question:hover button.menu{
	opacity: 0.75;
}

.importQuestionsInputAreaLeftGutter-question button.menu{
	opacity: 0;
}



// Marker

.importQuestionsInputAreaLeftGutter-question-markerArrow{
	position: absolute;
	right: -9px;
	top: 0px;
	pointer-events: none;
}

.importQuestionsInputAreaLeftGutter-question-markerArrow svg.icon{
	width: 8px;
	height: 8px;
	position: relative;
	fill: $gray-12;
}


//
// TEXT AREA
//

.importQuestions-inputArea-textAreaContainer{
	flex-grow: 1;
	flex-shrink: 1;
	min-width: 10px;
	position: relative;
	height: 100%;
	background: $white;
}


textarea.importQuestions-inputArea-textArea{
	
	width: 100%;

	padding-top: calc(#{$paddingTop-importQuestions-inputArea} - 2px);
	padding-left: 12px;
	box-sizing: border-box;

	resize: none;
	white-space: pre;

	overflow: hidden;
	overflow-x: scroll;

	font-size: 15px;
	font-weight: $lato-medium;
	color: $textBlack;
	
	line-height: 22px;

	background: none;

	position: relative;
	z-index: 5;

	border: 1px solid $gray-14;
}

textarea.importQuestions-inputArea-textArea:hover{
	border: 1px solid $lineMid;
}

textarea.importQuestions-inputArea-textArea:focus{
	outline: none;
	box-shadow: none;
	border: 1px solid $blue;
}


//
// RIGHT GUTTER
// USED FOR DISPLAYING ALERTS
//

$width-importQuestions-textInput-rightGutter: 22px; // better for alert only
$width-importQuestions-textInput-rightGutter: 31px; // better with count

.importQuestions-inputArea-rightGutter{
	width: $width-importQuestions-textInput-rightGutter;
	position: absolute;
	right: 0px;
	top: 0px;
	bottom: 0px;
	
	z-index: 200;

	// background: $white;
	// border-left: 1px solid $lineDark;

	box-sizing: border-box;
	padding-top: 15px;


	background: none;//
	border-left: none;
	// background: $tempr;

	pointer-events: none;

	cursor: default;
}


.importQuestions-inputArea-rightGutter-row{

	box-sizing: border-box;
	
	height: 22px;

	display: flex;
	align-items: center;
	justify-content: flex-end;

	padding-right: 3px;	

	cursor: default;
}

.importQuestions-inputArea-rightGutter-row--severeAlertCount{
	pointer-events: auto;
}

.importQuestions-inputArea-rightGutter-row--severeAlertCount-count{

	background: $red--notification;
	
	font-size: 12px;	

	border-radius: 3px;

	cursor: default;
	user-select: none;

	padding-left: 3px;
	padding-right: 3px;
	padding-top: 2px;
	padding-bottom: 2px;
	
	color: $white;
}

// tooltip
.importQuestions-inputArea-rightGutter-row--severeAlertCount::after{
	top: -4px;
	margin-top: 0px;
	margin-left: -79px;
	box-shadow: none;
	filter: drop-shadow(0px 1px 2px rgba(0,0,0,0.12));
	transition-delay: 20ms;
}

/// ZEBRA BG

.importQuestions-inputArea-textAreaContainer-zebraBGContainer,
.importQuestions-inputArea-textAreaContainer-coverContainer{
	position: absolute;
	top: $paddingTop-importQuestions-inputArea;
	left: 0px;
	right: 0px;
	bottom: 0px;
	pointer-events: none;
}

.importQuestions-inputArea-textAreaContainer-coverContainer{
	z-index: 100; // appear above
	left: 1px;
	right: 1px;
}

.importQuestions-inputArea-textAreaContainer-zebraBG{
	background: $white; // over max question limit doesn't display
}

.importQuestions-inputArea-textAreaContainer-zebraBG,
.importQuestions-inputArea-textAreaContainer-coverBG{
	width: 100%;
	//height: 22px;
	height: 109px;
	border-bottom: 1px solid rgb(245,245,250);
}

.importQuestions-inputArea-textAreaContainer-zebraBG--choiceCount4,
.importQuestions-inputArea-textAreaContainer-coverBG--choiceCount4{
	height: calc(22px + 22px + 22px + 22px + 22px - 1px);
}

.importQuestions-inputArea-textAreaContainer-zebraBG--choiceCount3,
.importQuestions-inputArea-textAreaContainer-coverBG--choiceCount3{
	height: calc(22px + 22px + 22px + 22px - 1px);
}

.importQuestions-inputArea-textAreaContainer-zebraBG--choiceCount2,
.importQuestions-inputArea-textAreaContainer-coverBG--choiceCount2{
	height: calc(22px + 22px + 22px - 1px);
}


// Need to tweak, for over limit
.importQuestions-inputArea-textAreaContainer-zebraBG--overLimit{
	background: $gray-14;
}

.importQuestions-inputArea-textAreaContainer-coverBG--overLimit{
	background: rgba(255,255,255,0.4);	
}


///





// .importQuestions-inputArea-textAreaContainer-zebraBG:nth-child(5n-3),
// .importQuestions-inputArea-textAreaContainer-zebraBG:nth-child(5n-1){
// 	background: $white;
// }

// .importQuestions-inputArea-textAreaContainer-zebraBG:nth-child(even){
// 	//background: $tempr;
// 	// background: rgb(240,240,247);
// 	// background: rgb(252,252,255);
// }

// .importQuestions-inputArea-textAreaContainer-zebraBG:nth-child(odd){
// 	background: $white;
// 	//background: rgb(240,240,247);
// 	// background: rgb(252,252,255);
// }


// .importQuestions-inputArea-textAreaContainer-zebraBG:nth-child(5n-4){
// 	background: transparentize($blue, 0.92);
// 	background: $white;
// }

// .importQuestions-inputArea-textAreaContainer-zebraBG:nth-child(5n-2),
// .importQuestions-inputArea-textAreaContainer-zebraBG:nth-child(5n-0){
// 	//background: $tempr;
// 	//background: rgb(240,240,247);
// 	background: rgb(252,252,255);
// }

// .importQuestions-inputArea-textAreaContainer-zebraBG:nth-child(5n-4){
// 	background: transparentize($blue, 0.92);
// }