//
// SUBJECT
//

.publishingFlowModalPage--subject-subjectListContainer{
	display: flex;
	margin-top: 16px; // maybe refactor so universal
	margin-left: -9px; // offset for radio padding
}

.publishingFlowModalPage--subject-subjectListColumn{
	flex-grow: 0;
	flex-shrink: 0;
	width: 330px;
	margin-right: 30px;
}

//
// SUBSUBJECT LIST
//

.publishingFlowModalPage--subjectListSubSubjectGroup{
	padding-left: 22px; // inset
	box-sizing: border-box;
	margin-bottom: 12px; // additional space below
}

.publishingFlowModalPage--subjectListSubSubjectGroup
.publishingFlowSelectControl{
	padding-top: 4px; // appear tighter together
	padding-bottom: 4px;
}


// Two column for languages
.publishingFlowModalPage--subjectListSubSubjectGroup--twoColumn{
	display: flex;
}

.publishingFlowModalPage--subjectListSubSubjectGroup-column{
	flex-grow: 1;
	flex-shrink: 1;
}

.publishingFlowModalPage--subjectListSubSubjectGroup-column--wide{
	width: 150px;
}

// spacer for other
.publishingFlowModalPage--subject-subjectListColumn-allSubjectSpacer{
	height: 12px;
}


