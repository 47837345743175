//
// FILTER BAR
// Search-like input bar that appears in pageHeaders in e.g. Library and Reports
//

$bg-filterBar: rgb(247,247,255);

.pageHeaderTop .filterBar{
	margin-left: -10px;
}


.filterBar{
	height: 30px;
	position: relative;
}

.filterBar input { 
	height: 100%;
	width: 100%;
	box-sizing: border-box;
	padding-left: 29px;
	border-radius: 3px;
	//padding-bottom: 3px;
	box-shadow: none;
	
	font-size: 15px;
	font-weight: $lato-semibold;
	color: $textBlack;
	cursor: default;
}

.filterBar .filterBar-extras{
	width: 100%;
}

.filterBar-clear{
	visibility: hidden;
	position: absolute;
	right: 0px;
	top: 0px;
	height: 30px;
	width: 30px;
} 

.filterBar-clear .icon{
	position: absolute;
	top: 9px;
	left: 9px;
	height: 12px;
	width: 12px;
	fill: $gray-1125;
}

.filterBar .magnifying-glass{
	position: absolute;
	left: 10px;
	top: 9px;
	height: 12px;
	width: 12px;
	pointer-events: none;
} 

// 
// STATES
// 

// STANDARD
.filterBar input {
	border: 1px solid $invisible;
	background: $bg-filterBar;
}

.filterBar input::-webkit-input-placeholder{
	color: $textLight;
	user-select: none;
}

.filterBar input::-moz-placeholder{
	color: $textLight;
}

.filterBar input:-ms-input-placeholder{
	color: $textLight !important;
}

.filterBar .magnifying-glass{	
	fill: $gray-1125;
} 

.filterBar-clear{
	visibility: hidden;
}

.filterBar-clear:hover .icon{
	fill: $gray-10;
}

// HOVER
.filterBar input:hover {
	border: 1px solid $invisible;
	background: darken($bg-filterBar, 0.5%);
}

.filterBar input:hover::-webkit-input-placeholder{
	color: darken($textLight, 5%);
}

.filterBar:hover .magnifying-glass{	
	fill: darken($gray-1125, 5%)
} 

.filterBar-clear{
	visibility: hidden;
}

.filterBar-clear:hover .icon{
	fill: $gray-10;
}

// FOCUSED

.filterBar input:focus{
	box-shadow: none;
	outline: none;
	border: 1px solid $gray-1225;	
	background: $white;
	cursor: default;
}

.filterBar input:focus::-webkit-input-placeholder{
	color: $textLight;
	opacity: 0.75;
}

.filterBar input:focus ~ .filterBar-extras .magnifying-glass {
	fill: $gray-10;
}

.filterBar input:focus ~ .filterBar-extras .filterBar-clear {
	visibility: visible;
}

// FOCUSED NON-EMPTY

.filterBar.is--nonEmpty input:focus { 
	border: 1px solid $gray-10;
}


.filterBar.is--nonEmpty input:focus ~ .filterBar-extras .magnifying-glass {
	fill: $blue;
}

// NON-FOCUSED NON-EMPTY 

.filterBar.is--nonEmpty input { 
	border: 1px solid $gray-1225;
	background: $white;
}

.filterBar.is--nonEmpty input ~ .filterBar-extras .magnifying-glass {
	fill: $gray-10;
}

.filterBar.is--nonEmpty input ~ .filterBar-extras .filterBar-clear {
	visibility: visible;
}




