$height-proFeaturesSymbolRow: 960px;
$width-proFeaturesSymbolRow: 184px;

.upgrade--proFeaturesSymbolRow{
	width: $height-proFeaturesSymbolRow;
	height: $width-proFeaturesSymbolRow;
	// background: $tempr;
	margin: 0 auto;

	display: flex;
	align-items: center;
	justify-content: center;

	box-sizing: border-box;

	position: relative;
	cursor: default;
}

.upgrade--proFeaturesSymbolRow-symbol{
	width: 200px;
	flex-grow: 0;
	flex-shrink: 0;

	// background: $tempr;	

	position: relative;
	z-index: 5;
}

.upgrade--proFeaturesSymbolRow-symbol-iconContainer{
	width: 170px;
	height: 90px;
	// background: $tempr;
	margin: 0 auto;

	display: flex;
	align-items: center;
	justify-content: center;
}

.upgrade--proFeaturesSymbolRow-symbol-label{
	width: 100%;
	
	text-align: center;
	height: 40px;

	line-height: 40px;

	font-size: 19px;
	font-weight: $effra-bold;
	color: $textBlack;
}

.upgrade--proFeaturesSymbolRow-bgContainer{
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 1;
}

.upgrade--proFeaturesSymbolRow-bgContainer svg{
	width: $height-proFeaturesSymbolRow;
	height: $width-proFeaturesSymbolRow;
	fill: $upgrade-cloudBlue-bg;
}