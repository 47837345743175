

$width-editor-formatBar-center: 150px;

.editor-formatBar{
	background: $white;
	border-bottom: 1px solid rgb(208,209,214);
	box-shadow: 0px 2px 4px 0px rgba(0,0,40,0.12);

	display: flex;
}

.editor-formatBar-left,
.editor-formatBar-right,
.editor-formatBar-center{
	flex-grow: 0;
	flex-shrink: 0;
}
	
.editor-formatBar-left,
.editor-formatBar-right{
	width: calc((100% - #{$width-editor-formatBar-center})/2);
	
}


//
// CENTER

.editor-formatBar-center{
	
	// background: $tempr;

	width: $width-editor-formatBar-center;
	height: 100%;


	
}

.editor-formatBar-center-isSurveyToggle{
	width: 100%;
	height: 100%;

	display: flex;
	align-items: center;
	justify-content: center;


}

button.editor-formatBar-center-isSurveyToggleBtn{
	background: none;
	border: none;
	box-shadow: none;

	display: flex;
	align-items: center;
	justify-content: center;

	width: 70px;
	height: 27px;

	border-radius: 3px;

	font-size: 14px;

	cursor: pointer;

	margin-top: 1px; // better vertical alignment
	position: relative;
}

button.editor-formatBar-center-isSurveyToggleBtn--active{
	background: transparentize($blue, 0.9);
	color: $blue;

	font-weight: $lato-heavy;
}

button.editor-formatBar-center-isSurveyToggleBtn--inactive{
	background: none;
	color: $textLight;

	font-weight: $lato-semibold;
}

button.editor-formatBar-center-isSurveyToggleBtn--inactive:hover{
	color: $textDark;
}

button.editor-formatBar-center-isSurveyToggleBtn:focus{
	outline: none;
}

button.editor-formatBar-center-isSurveyToggleBtn:active{
	background: transparentize($blue,0.9);
	color: darken($blue, 5%);
}

// LEFT

.editor-formatBar-left{
	display: flex;
}

.editor-formatBar-slideListSpacer{
	width: $width-editor-slideList;
	height: 100%;
	// background: $tempg;
}


.editor-formatBar-format{
	display: flex;
	align-items: center;
	padding-left: 6px; // refactor out
}

//
// RIGHT SIDE
// contains maths/science, emoji+symbols, and right edge (duplicate, delete)
//

.editor-formatBar-right{
	display: flex;
	align-items: center;
	justify-content: flex-end;
}

.editor-formatBar-mathsScience,
.editor-formatBar-equationContainer,
.editor-formatBar-symbolsEmoji{
	display: flex;
	align-items: center;
	justify-content: center;
}

.editor-formatBar-symbolsEmoji{
	position: relative;
}

.editor-formatBar-equationContainer button.editor-formatBar-iconBtn{
	width: 56px; //larger hit area
}

.editor-formatBar-symbolsEmoji button.editor-formatBar-iconBtn{
	width: 90px;
}

.editor-formatBarContainer--showAdvancedEquationButton
.editor-formatBar-symbolsEmoji button.editor-formatBar-iconBtn{
	width: 80px;
}

// HIDE AND SHOW ADVANCED EQUATIOn


.editor-formatBarContainer
button.editor-formatBar-iconBtn.editor-formatBar-iconBtn--type-formatMathAdvancedEquation{
	display: none;
}

.editor-formatBarContainer.editor-formatBarContainer--showAdvancedEquationButton
button.editor-formatBar-iconBtn.editor-formatBar-iconBtn--type-formatMathAdvancedEquation{
	display: flex;
}

button.editor-formatBar-iconBtn.editor-formatBar-iconBtn--type-formatMathAdvancedEquation{
	width: 44px;
}

button.editor-formatBar-iconBtn.editor-formatBar-iconBtn--type-formatMathAdvancedEquation svg{
	width: 28px;
	height: 28px;
}

//
.editor-formatBar-right-slideAligned{
	width: 10px;
	flex-grow: 1;
	flex-shrink: 1;
	height: 100%;
	// background: $tempr;

	display: flex;
	justify-content: space-between;

	box-sizing: border-box;

	// box-sizing: content-box;
	// padding-right: 24px; // to offset the symbolso vertically
	
	// padding-left: 24px;
}

.editor-formatBar-mathsScience{
	width: 140px;
	flex-grow: 0;
	flex-shrink: 0;

	height: 100%;
	// margin-right: 20px;
	// background: $tempg;
}

.editor-formatBarContainer--showAdvancedEquationButton
.editor-formatBar-mathsScience{
	width: 190px;
}

.editor-formatBar-symbolsEmoji{
	width: 90px;
	height: 100%;
	// background: $tempb;
}

.editor-formatBar-right--extendedMath
.editor-formatBar-symbolsEmoji{
	width: 70px;
}

.editor-formatBar-right-slideAligned-spacer{
	width: 18px;
	height: 100%;
	// to center the mathbar visually (if not technically, because of slide list)
}

.editor-formatBar-symbolsEmoji{
	margin-right: -26px; // to horizontally center the icon on the slide
}


//


.editor-formatBar-rightEdge{
	height: 100%;
	
	flex-grow: 0;
	flex-shrink: 0;
	display: flex;
	justify-content: flex-end;
}

.editor-formatBar-rightEdge-contents{
	
	display: flex;
	align-items: center;
	justify-content: flex-end;

	padding-right: 6px;

}

button.editor-formatBar-rightEdgeBtn{
	background: none;
	border: none;
	cursor: pointer;
	outline: none;
	box-shadow: none;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 27px;

	padding-left: 6px;
	padding-right: 6px;

	border-radius: 3px;

	margin-top: 1px; // better vertical align

	font-size: 14px;
	font-weight: $lato-semibold;	

	color: $textLight;
}

button.editor-formatBar-rightEdgeBtn--duplicate:hover{
	background: transparentize($blue, 0.9);
	background: $gray-14;
	color: $textDark;
	// color: $blue;
}

button.editor-formatBar-rightEdgeBtn--duplicate:active{
	background: darken($gray-14, 5%);
	color: $textBlack;
}

button.editor-formatBar-rightEdgeBtn--delete:hover{
	background: transparentize($red, 0.9);
	color: $red;
}

button.editor-formatBar-rightEdgeBtn--delete:active{
	background: transparentize($red, 0.8);
	color: darken($red, 5%);
}

.editor-formatBarContainer--maxQuestionLimitReached
.editor-formatBar-rightEdgeBtn--duplicate{
	visibility: hidden;
}

//
// ICON BUTTON

button.editor-formatBar-iconBtn{
	width: 34px;
	height: 27px;
	
	border: none;
	background: none;
	outline: none;
	box-shadow: none;

	border-radius: 2px;
	margin-top: 1px;

	display: flex;
	align-items: center;
	justify-content: center;

	cursor: pointer;

	position: relative;
}

button.editor-formatBar-iconBtn svg{
	width: 22px;
	height: 22px;
}

button.editor-formatBar-iconBtn--type-formatSymbols svg,
button.editor-formatBar-iconBtn--type-formatEmoji svg{
	height: 20px;
	width: 20px;
}

button.editor-formatBar-iconBtn--type-insertSound svg,
button.editor-formatBar-iconBtn--type-insertSoundFilled svg{
	height: 18px;
	width: 18px;
	padding-left: 2px;
	padding-right: 2px;
}


button.editor-formatBar-iconBtn--type-formatMathEquation svg{
	width: 24px;
	height: 24px;
}


button.editor-formatBar-iconBtn--inactive{
	background: none;
}

button.editor-formatBar-iconBtn--inactive svg{
	fill: rgb(115,115,130);
}


button.editor-formatBar-iconBtn--inactive:hover{
	background: $gray-14;
}

button.editor-formatBar-iconBtn--inactive:hover svg{
	fill: rgb(90,90,110);
}


button.editor-formatBar-iconBtn--active{
	background: transparentize($blue, 0.9);
}

button.editor-formatBar-iconBtn--active svg{
	fill: $blue;
}


button.editor-formatBar-iconBtn:active{
	background: transparentize($blue, 0.89);
}

button.editor-formatBar-iconBtn:active svg{
	fill: darken($blue, 2%);
}

// ICON BTN TOOLTIP

.editor-formatBar-iconBtn-tooltip{	
  position: absolute;
  top: 31px;
	left: 50%;
  transform: translate(-50%, 0);
	
	z-index: 10;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;


  background: $white;
  border: 1px solid $lineDark;
  border-bottom: 1px solid darken($lineDark, 5%);

  background: lighten(#282636, 6%);
  border: 1px solid lighten(#282636, 6%);

  // box-shadow: 0px 1px 2px 0px rgba(0,0,10,0.04);
 	
 	border-radius: 4px; 

  padding-left: 7px;
  padding-right: 7px;

  opacity: 0;
  // opacity: 1;  
  visibility: hidden;


  pointer-events: none;
  user-select: none;

  // background: $gray-3;
	// border: 1px solid $gray-3;

}

.editor-formatBar-iconBtn-tooltip--hasShortcut{
	height: 46px;
}

.editor-formatBar-iconBtn-tooltip--noShortcut{
	height: 25px;
}

.editor-formatBar-iconBtn-tooltip-label{
	font-size: 13px;
  font-weight: $lato-bold;
  letter-spacing: 0.02em;
  color: $textBlackDark;

  white-space: nowrap;

  flex-grow: 1;
  flex-shrink: 1;

  // background: $tempb;

  display: flex;
  align-items: center;
  justify-content: center;

  box-sizing: border-box;
  padding-bottom: 1px;

  width: 100%;

  color: $white;
}


.editor-formatBar-iconBtn-tooltip-keyboardShortcuts{
	display: flex;
	align-items: flex-end;
	justify-content: center;

	flex-grow: 0;
	flex-shrink: 0;

	width: 100%;
	height: 21px;

	box-sizing: border-box;
	padding-bottom: 4px;

	// background: $tempr;
}


.editor-formatBar-iconBtn-tooltip-keyboardShortcuts-key{
	height: 17px;

	text-align: center;

	background: $gray-5;
	background: $gray-1;
	color: $white;
	font-size: 12px;
	font-weight: $lato-semibold;
	letter-spacing: 0.02em;

	border-radius: 2px;

	padding-left: 5px;
	padding-right: 5px;
	min-width: 18px;

	display: flex;
	align-items: center;
	justify-content: center;

	flex-grow: 0;
	flex-shrink: 0;

	box-sizing: border-box;	

	margin-left: 1px;
	margin-right: 1px;

	// background: $gray-14;
	// color: $textDark;
	// background: $classColor-purple;
	// background: $black;
}

.editor-formatBar-iconBtn-tooltip-keyboardShortcuts-plus{
	height: 16px;
	width: 12px;

	display: flex;
	align-items: center;
	justify-content: center;

	font-size: 13px;
	font-weight: $lato-bold;
	color: $textLight;

	box-sizing: border-box;
	padding-bottom: 1px;

	// display: none;

}


.editor-formatBar:hover button.editor-formatBar-iconBtn:hover .editor-formatBar-iconBtn-tooltip,
.editor-formatBar:hover button.editor-formatBar-center-isSurveyToggleBtn:hover .editor-formatBar-center-isSurveyToggleBtn-tooltip{
	visibility: visible;
}


.editor-formatBar:hover .editor-formatBar-iconBtn-tooltip{
	opacity: 0.95;
	transition: opacity 100ms linear;
	transition-delay: 200ms;
}


// button.editor-formatBar-iconBtn--active.editor-formatBar-iconBtn--type-formatSymbols 
// .editor-formatBar-iconBtn-tooltip{
// 	visibility: hidden !important; // refactor
// }

.editor-formatBar-right--symbolPickerOpen
.editor-formatBar-iconBtn-tooltip{
	display: none;
}
















// SURVEY AND GRADED SHORTCUTS

.editor-formatBar-center-isSurveyToggleBtn-tooltip{	
  position: absolute;
  
  top: 31px;

  background: lighten(#282636, 6%);
  z-index: 10;
  pointer-events: none;

  left: 50%;
  transform: translate(-50%, 0);

  padding-top: 7px;
  padding-bottom: 7px;
  padding-left: 7px;
  padding-right: 7px;

  border-radius: 4px;
  opacity: 0;
  
  font-size: 13px;
  font-weight: $lato-semibold;
  letter-spacing: 0.02em;
  color: $white;

  white-space: nowrap;
  visibility: hidden;
  // opacity: 1;

  line-height: 1.2;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

}

.editor-formatBar:hover .editor-formatBar-center-isSurveyToggleBtn-tooltip{
	opacity: 0.95;
	transition: opacity 100ms linear;
	transition-delay: 500ms;
}

.editor-formatBar-center-isSurveyToggleBtn-tooltip{
	width: 330px;	
	white-space: normal;
}

.editor-formatBar-center-isSurveyToggleBtn-tooltip b{
	font-weight: $lato-bold;
}

//
//

button.editor-formatBar-iconBtn--type-formatHighlight svg #letter{
	fill: rgb(115,115,130);
}


button.editor-formatBar-iconBtn--type-formatHighlight svg #background{
	fill: $gray-14;	
}

button.editor-formatBar-iconBtn--type-formatMathSuperscript svg #bigBox,
button.editor-formatBar-iconBtn--type-formatMathSubscript svg #bigBox,
button.editor-formatBar-iconBtn--type-formatMathFriendlyFraction svg #boxes{
	fill: $gray-12;
}




// SUPER/SUB/ALGEBRA MODEs STRONGER

button.editor-formatBar-iconBtn--active.editor-formatBar-iconBtn--type-formatMathSuperscript,
button.editor-formatBar-iconBtn--active.editor-formatBar-iconBtn--type-formatMathSubscript,
button.editor-formatBar-iconBtn--active.editor-formatBar-iconBtn--type-formatMathAlgebra{
	background: transparentize($blue, 0.15);
}

button.editor-formatBar-iconBtn--active.editor-formatBar-iconBtn--type-formatMathAlgebra svg{
	fill: $white;
}

button.editor-formatBar-iconBtn--active.editor-formatBar-iconBtn--type-formatMathSuperscript svg #bigBox,
button.editor-formatBar-iconBtn--active.editor-formatBar-iconBtn--type-formatMathSubscript svg #bigBox{
	fill: transparentize($white, 0.3);
}

button.editor-formatBar-iconBtn--active.editor-formatBar-iconBtn--type-formatMathSuperscript svg #smallBox,
button.editor-formatBar-iconBtn--active.editor-formatBar-iconBtn--type-formatMathSubscript svg #smallBox{
	fill: $white;
}


//
// HIGHLIGHT ICON CUSTOM COLORS
//

// MULTI

button.editor-formatBar-iconBtn--active.editor-formatBar-iconBtn--highlight--multi{
	background: conic-gradient(
			from 90deg,
			#E02020,
			#FA6400,
			#F7B500,
			#6DD400,			
			#0091FF,
			#6236FF,
			#B620E0,
		);

	background: conic-gradient(
			from 90deg,
			transparentize(#E02020, 0.8),
			transparentize(#FA6400, 0.8),
			transparentize(#F7B500, 0.8),
			transparentize(#6DD400, 0.8),
			transparentize(#0091FF, 0.8),
			transparentize(#6236FF, 0.8),
			transparentize(#B620E0, 0.8),
		)
}


button.editor-formatBar-iconBtn--active.editor-formatBar-iconBtn--highlight--multi svg #letter{
	fill: white;
}

.editorIcon-formatHighlightMulti-gradient{
	width: 30px;
	height: 30px;
	background-image: conic-gradient(
			from 90deg,
			transparentize(#E02020, 0.1),
			transparentize(#FA6400, 0.1),
			transparentize(#F7B500, 0.1),
			transparentize(#6DD400, 0.1),
			transparentize(#0091FF, 0.1),
			transparentize(#6236FF, 0.1),
			transparentize(#B620E0, 0.1),
		)
}


// Null on dropdown
.editor-formatBar-format-highlightDropdown-dropdown
button.editor-formatBar-iconBtn--highlight--null svg #letter{
	fill: $textMid;
}
button.editor-formatBar-iconBtn--highlight--null svg #background{
	fill: $invisible;	
}

.editor-formatBar-format-highlightDropdown-dropdown
button.editor-formatBar-iconBtn--highlight--null:hover svg #letter{
	fill: darken($textMid, 6%);
}



// 1


button.editor-formatBar-iconBtn--highlight--1 svg #letter{
	fill: $highlight--1-textColor;
}

button.editor-formatBar-iconBtn--highlight--1 svg #background{
	fill: $highlight--1-bgColor;	
}

button.editor-formatBar-iconBtn--active.editor-formatBar-iconBtn--highlight--1{
	background: $highlight--1-bgColor;
}

button.editor-formatBar-iconBtn--active.editor-formatBar-iconBtn--highlight--1 svg #background{
	fill: transparentize($highlight--1-textColor, 0.4);	
}

button.editor-formatBar-iconBtn--active.editor-formatBar-iconBtn--highlight--1 svg #letter{
	fill: white;	
}

.editor-formatBar-format-highlightDropdown-dropdown
button.editor-formatBar-iconBtn--highlight--1:hover svg #letter{
	fill: darken($highlight--1-textColor, 6%);
}

.editor-formatBar-format-highlightDropdown-dropdown
button.editor-formatBar-iconBtn--highlight--1:hover svg #background{
	fill: darken($highlight--1-bgColor, 2%);
}


//

button.editor-formatBar-iconBtn--highlight--2 svg #letter{
	fill: $highlight--2-textColor;
}

button.editor-formatBar-iconBtn--highlight--2 svg #background{
	fill: $highlight--2-bgColor;	
}

button.editor-formatBar-iconBtn--active.editor-formatBar-iconBtn--highlight--2{
	background: $highlight--2-bgColor;
}

button.editor-formatBar-iconBtn--active.editor-formatBar-iconBtn--highlight--2 svg #background{
	fill: transparentize($highlight--2-textColor, 0.4);	
}

button.editor-formatBar-iconBtn--active.editor-formatBar-iconBtn--highlight--2 svg #letter{
	fill: white;	
}

.editor-formatBar-format-highlightDropdown-dropdown
button.editor-formatBar-iconBtn--highlight--2:hover svg #letter{
	fill: darken($highlight--2-textColor, 6%);
}

.editor-formatBar-format-highlightDropdown-dropdown
button.editor-formatBar-iconBtn--highlight--2:hover svg #background{
	fill: darken($highlight--2-bgColor, 2%);
}

//

button.editor-formatBar-iconBtn--highlight--3 svg #letter{
	fill: $highlight--3-textColor;
}

button.editor-formatBar-iconBtn--highlight--3 svg #background{
	fill: $highlight--3-bgColor;	
}

button.editor-formatBar-iconBtn--active.editor-formatBar-iconBtn--highlight--3{
	background: $highlight--3-bgColor;
}

button.editor-formatBar-iconBtn--active.editor-formatBar-iconBtn--highlight--3 svg #background{
	fill: transparentize($highlight--3-textColor, 0.4);	
}

button.editor-formatBar-iconBtn--active.editor-formatBar-iconBtn--highlight--3 svg #letter{
	fill: white;	
}

.editor-formatBar-format-highlightDropdown-dropdown
button.editor-formatBar-iconBtn--highlight--3:hover svg #letter{
	fill: darken($highlight--3-textColor, 6%);
}

.editor-formatBar-format-highlightDropdown-dropdown
button.editor-formatBar-iconBtn--highlight--3:hover svg #background{
	fill: darken($highlight--3-bgColor, 2%);
}

//


button.editor-formatBar-iconBtn--highlight--4 svg #letter{
	fill: $highlight--4-textColor;
}

button.editor-formatBar-iconBtn--highlight--4 svg #background{
	fill: $highlight--4-bgColor;	
}

button.editor-formatBar-iconBtn--active.editor-formatBar-iconBtn--highlight--4{
	background: $highlight--4-bgColor;
}

button.editor-formatBar-iconBtn--active.editor-formatBar-iconBtn--highlight--4 svg #background{
	fill: transparentize($highlight--4-textColor, 0.4);	
}

button.editor-formatBar-iconBtn--active.editor-formatBar-iconBtn--highlight--4 svg #letter{
	fill: white;	
}

.editor-formatBar-format-highlightDropdown-dropdown
button.editor-formatBar-iconBtn--highlight--4:hover svg #letter{
	fill: darken($highlight--4-textColor, 6%);
}

.editor-formatBar-format-highlightDropdown-dropdown
button.editor-formatBar-iconBtn--highlight--4:hover svg #background{
	fill: darken($highlight--4-bgColor, 2%);
}

//


button.editor-formatBar-iconBtn--highlight--5 svg #letter{
	fill: $highlight--5-textColor;
}

button.editor-formatBar-iconBtn--highlight--5 svg #background{
	fill: $highlight--5-bgColor;	
}

button.editor-formatBar-iconBtn--active.editor-formatBar-iconBtn--highlight--5{
	background: $highlight--5-bgColor;
}

button.editor-formatBar-iconBtn--active.editor-formatBar-iconBtn--highlight--5 svg #background{
	fill: transparentize($highlight--5-textColor, 0.4);	
}

button.editor-formatBar-iconBtn--active.editor-formatBar-iconBtn--highlight--5 svg #letter{
	fill: white;	
}

.editor-formatBar-format-highlightDropdown-dropdown
button.editor-formatBar-iconBtn--highlight--5:hover svg #letter{
	fill: darken($highlight--5-textColor, 6%);
}

.editor-formatBar-format-highlightDropdown-dropdown
button.editor-formatBar-iconBtn--highlight--5:hover svg #background{
	fill: darken($highlight--5-bgColor, 2%);
}

//

button.editor-formatBar-iconBtn--highlight--6 svg #letter{
	fill: $highlight--6-textColor;
}

button.editor-formatBar-iconBtn--highlight--6 svg #background{
	fill: $highlight--6-bgColor;	
}

button.editor-formatBar-iconBtn--active.editor-formatBar-iconBtn--highlight--6{
	background: $highlight--6-bgColor;
}

button.editor-formatBar-iconBtn--active.editor-formatBar-iconBtn--highlight--6 svg #background{
	fill: transparentize($highlight--6-textColor, 0.4);	
}

button.editor-formatBar-iconBtn--active.editor-formatBar-iconBtn--highlight--6 svg #letter{
	fill: white;	
}

.editor-formatBar-format-highlightDropdown-dropdown
button.editor-formatBar-iconBtn--highlight--6:hover svg #letter{
	fill: darken($highlight--6-textColor, 6%);
}

.editor-formatBar-format-highlightDropdown-dropdown
button.editor-formatBar-iconBtn--highlight--6:hover svg #background{
	fill: darken($highlight--6-bgColor, 2%);
}


//

button.editor-formatBar-iconBtn--highlight--7 svg #letter{
	fill: $highlight--7-textColor;
}

button.editor-formatBar-iconBtn--highlight--7 svg #background{
	fill: $highlight--7-bgColor;	
}

button.editor-formatBar-iconBtn--active.editor-formatBar-iconBtn--highlight--7{
	background: $highlight--7-bgColor;
}

button.editor-formatBar-iconBtn--active.editor-formatBar-iconBtn--highlight--7 svg #background{
	fill: transparentize($highlight--7-textColor, 0.4);	
}

button.editor-formatBar-iconBtn--active.editor-formatBar-iconBtn--highlight--7 svg #letter{
	fill: white;	
}

.editor-formatBar-format-highlightDropdown-dropdown
button.editor-formatBar-iconBtn--highlight--7:hover svg #letter{
	fill: darken($highlight--7-textColor, 6%);
}

.editor-formatBar-format-highlightDropdown-dropdown
button.editor-formatBar-iconBtn--highlight--7:hover svg #background{
	fill: darken($highlight--7-bgColor, 2%);
}

//


button.editor-formatBar-iconBtn--highlight--8 svg #letter{
	fill: $highlight--8-textColor;
}

button.editor-formatBar-iconBtn--highlight--8 svg #background{
	fill: $highlight--8-bgColor;	
}

button.editor-formatBar-iconBtn--active.editor-formatBar-iconBtn--highlight--8{
	background: $highlight--8-bgColor;
}

button.editor-formatBar-iconBtn--active.editor-formatBar-iconBtn--highlight--8 svg #background{
	fill: transparentize($highlight--8-textColor, 0.4);	
}

button.editor-formatBar-iconBtn--active.editor-formatBar-iconBtn--highlight--8 svg #letter{
	fill: white;	
}

.editor-formatBar-format-highlightDropdown-dropdown
button.editor-formatBar-iconBtn--highlight--8:hover svg #letter{
	fill: darken($highlight--8-textColor, 6%);
}

.editor-formatBar-format-highlightDropdown-dropdown
button.editor-formatBar-iconBtn--highlight--8:hover svg #background{
	fill: darken($highlight--8-bgColor, 2%);
}


//
// HIGHLIGHT DROPDOWN
//

.editor-formatBar-format-highlightDropdownContainer{
	position: relative;
	width: 34px;
	height: 100%;
	
	display: flex;
	align-items: center;
	justify-content: center;


}

$padding-highlightDropdownContainer: 10px;
.editor-formatBar-format-highlightDropdown-dropdownContainer{
	// width: 62px;
	width: 88px;
	//height: 66px;
	height: 92px;
	position: absolute;
	left: calc(((88px - 34px) / -2) - #{$padding-highlightDropdownContainer});
	top: calc(#{$height-editor-formatBar} - 2px);
	z-index: 100;

	opacity: 1;
	// opacity: 0;
	// transform: translateY(-5px);
	// transition: all 200ms cubic-bezier(0.2, 0.3, 0.25, 0.9);
	// // transition-delay: 500ms; // won't work
	// background: $tempr;
	
	cursor: pointer;

	padding-left: $padding-highlightDropdownContainer;
	padding-right: $padding-highlightDropdownContainer;
	padding-bottom: $padding-highlightDropdownContainer;

	// background: $tempr;
}

.editor-formatBar-format-highlightDropdown-dropdownContainer--hide{
	pointer-events: none;
	opacity: 0;
	transform: translateY(-5px);
	transition: all 100ms cubic-bezier(0.2, 0.3, 0.25, 0.9);
}

.editor-formatBar-format-highlightDropdown-dropdownContainer--show{
	opacity: 1;
	transform: translateY(0px);
	transition: all 100ms cubic-bezier(0.2, 0.3, 0.25, 0.9);
	pointer-events: auto;
}


// .editor-formatBar-format-highlightDropdown-dropdownContainer
// .editor-formatBar-format-highlightDropdown-dropdownContainer:hover{
// 	opacity: 1;
// 	transform: translateY(0px);
// 	transition: all 200ms cubic-bezier(0.2, 0.3, 0.25, 0.9);
// 	pointer-events: auto;
// }



.editor-formatBar-format-highlightDropdown-dropdown{
	position: absolute;
	
	top: 4px;
	left: $padding-highlightDropdownContainer;
	right: $padding-highlightDropdownContainer;
	bottom: $padding-highlightDropdownContainer;

	background: $white;
	padding-top: 4px;
	padding-bottom: 6px;
	padding-left: 4px;
	padding-right: 4px;
	overflow: none;
	box-sizing: border-box;
	border: 1px solid lighten($lineDark,5%);
	border-radius: 5px;
	box-shadow: 0px 4px 8px 0px rgba(0,0,0,0.12);
	z-index: 500;

	display: flex;
	flex-wrap: wrap;
}

.editor-formatBar-format-highlightDropdown-dropdown button{
	margin: 1px;
	width: 24px;
	height: 24px;
	display: block;
	position: relative;
	//outline: 1px solid red !important;
	padding: 0px;
}

.editor-formatBar-format-highlightDropdown-dropdown button:hover,
.editor-formatBar-format-highlightDropdown-dropdown button:focus{
	background: none;
}

.editor-formatBar-format-highlightDropdown-dropdown button svg{
	width: 24px;
	height: 24px;
	position: absolute;
	top: 0px;
	left: 0px;
}

// PERMISSIONS TWEAKS
.editor--viewOnly .editor-formatBar,
.editor--itemIsLockedByAnotherUser .editor-formatBar{
	pointer-events: none;
}

.editor--viewOnly .editor-formatBar-left,
.editor--viewOnly .editor-formatBar-right{
	visibility: hidden;
}

.editor--itemIsLockedByAnotherUser .editor-formatBar-left,
.editor--itemIsLockedByAnotherUser .editor-formatBar-right{
	opacity: 0.25;
}