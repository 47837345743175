//
// TYPE
// font imports and font-weight definitions
// we use Lato for UI and Effra for marketing
// all font files are stored on S3
// you can use Effra font by adding 'marketing' className to a div (it will cascade)
//

//
// LATO
// note that "Plickers Lato" has vertical font metrics fixed for better vertical centering on Windows
// it also uses the full range of weights available (vs e.g. Google Fonts)

// FONT WEIGHTS
$lato-regular: 	400;
$lato-medium: 	500;
$lato-semibold: 600;
$lato-bold: 		700;
$lato-heavy: 		800;
$lato-black: 		900;

//
// LATO FONT IMPORTS

// LATO MEDIUM
@font-face {
	font-family: 'Plickers Lato';         
	src: url('https://assets.plickers.com/fonts/PlickersLato/PlickersLato-Medium.setcetera.woff2') format('woff2'), url('https://assets.plickers.com/fonts/PlickersLato/PlickersLato-Medium.setcetera.woff') format('woff');    
	font-style: normal;
	font-display: auto;
	font-weight: $lato-medium;
	text-rendering: optimizeLegibility;
}

// LATO SEMIBOLD
@font-face {
	font-family: 'Plickers Lato';       
	src: url('https://assets.plickers.com/fonts/PlickersLato/PlickersLato-Semibold.setcetera.woff2') format('woff2'), url('https://assets.plickers.com/fonts/PlickersLato/PlickersLato-Semibold.setcetera.woff') format('woff');    
	font-style: normal;
	font-display: auto;
	font-weight: $lato-semibold;
	text-rendering: optimizeLegibility;
}

// LATO BOLD
@font-face {
	font-family: 'Plickers Lato';   
	src: url('https://assets.plickers.com/fonts/PlickersLato/PlickersLato-Bold.setcetera.woff2') format('woff2'), url('https://assets.plickers.com/fonts/PlickersLato/PlickersLato-Bold.setcetera.woff') format('woff');        
	font-style: normal;
	font-display: auto;
	font-weight: $lato-bold;
	text-rendering: optimizeLegibility;
}

// LATO HEAVY
@font-face {
	font-family: 'Plickers Lato';    
	src: url('https://assets.plickers.com/fonts/PlickersLato/PlickersLato-Heavy.setcetera.woff2') format('woff2'), url('https://assets.plickers.com/fonts/PlickersLato/PlickersLato-Heavy.setcetera.woff') format('woff');    
	font-style: normal;
	font-display: auto;
	font-weight: $lato-heavy;
	text-rendering: optimizeLegibility;
}

// LATO BLACK
@font-face {
	font-family: 'Plickers Lato';    
	src: url('https://assets.plickers.com/fonts/PlickersLato/PlickersLato-Black.student1.woff2') format('woff2'), url('https://assets.plickers.com/fonts/PlickersLato/PlickersLato-Black.student1.woff') format('woff');    
	font-style: normal;
	font-display: auto;
	font-weight: $lato-black;
	text-rendering: optimizeLegibility;
}


// ITALIC
// italic is used in rich-text set editor, i.e. when teachers apply italic formatting 

// LATO MEDIUM ITALIC 
@font-face {
	font-family: 'Plickers Lato';         
	src: url('https://assets.plickers.com/fonts/PlickersLato/PlickersLato-MediumItalic.richtextitalic.woff2') format('woff2'), url('https://assets.plickers.com/fonts/PlickersLato/PlickersLato-MediumItalic.richtextitalic.woff') format('woff');    
	font-style: italic;
	font-display: auto;
	font-weight: $lato-medium;
	text-rendering: optimizeLegibility;
}

// LATO SEMIBOLD ITALIC
@font-face {
	font-family: 'Plickers Lato';       
	src: url('https://assets.plickers.com/fonts/PlickersLato/PlickersLato-SemiboldItalic.richtextitalic.woff2') format('woff2'), url('https://assets.plickers.com/fonts/PlickersLato/PlickersLato-SemiboldItalic.richtextitalic.woff') format('woff');    
	font-style: italic;
	font-display: auto;
	font-weight: $lato-semibold;
	text-rendering: optimizeLegibility;
}

// LATO BOLD ITALIC
@font-face {
	font-family: 'Plickers Lato';   
	src: url('https://assets.plickers.com/fonts/PlickersLato/PlickersLato-BoldItalic.richtextitalic.woff2') format('woff2'), url('https://assets.plickers.com/fonts/PlickersLato/PlickersLato-BoldItalic.richtextitalic.woff') format('woff');        
	font-style: italic;
	font-display: auto;
	font-weight: $lato-bold;
	text-rendering: optimizeLegibility;
}

// LATO HEAVY ITALIC
@font-face {
	font-family: 'Plickers Lato';    
	src:  url('https://assets.plickers.com/fonts/PlickersLato/PlickersLato-HeavyItalic.richtextitalic.woff2') format('woff2'), url('https://assets.plickers.com/fonts/PlickersLato/PlickersLato-HeavyItalic.richtextitalic.woff') format('woff');    
	font-style: italic;
	font-display: auto;
	font-weight: $lato-heavy;
	text-rendering: optimizeLegibility;
}

// LATO BLACK
@font-face {
	font-family: 'Plickers Lato';    
	src:  url('https://assets.plickers.com/fonts/PlickersLato/PlickersLato-BlackItalic.richtextitalic.woff2') format('woff2'), url('https://assets.plickers.com/fonts/PlickersLato/PlickersLato-BlackItalic.richtextitalic.woff') format('woff');    
	font-style: italic;
	font-display: auto;
	font-weight: $lato-black;
	text-rendering: optimizeLegibility;
}

.App, body, input, textarea, button {
	// Lato is default everywhere
	font-family: 'Plickers Lato', sans-serif;
	-webkit-font-smoothing: antialiased;
}




//
// EFFRA
// Effra is the marketing font
// see e.g. the upgrade page or get.plickers.com
// it is also used in signin/signup page
// note that we load unicode subsets if possible (Full vs. Latin versions)

// FONT WEIGHTS
$effra-regular:  400;
$effra-medium:   500;
$effra-bold:     700;
$effra-heavy:    800;

//
// EFFRA FONT IMPORTS

// FULL EFFRA REGULAR 
@font-face {
	font-family: 'Effra';   
	src: url('https://assets.plickers.com/fonts/Effra/Effra-Regular.setcetera.woff2') format('woff2'), url('https://assets.plickers.com/fonts/Effra/Effra-Regular.setcetera.woff') format('woff');    
	font-style: normal;
	font-display: auto;
	font-weight: $effra-regular;
	text-rendering: optimizeLegibility;
}

// LATIN EFFRA REGULAR 
@font-face {
	font-family: 'Effra';   
	src: url('https://assets.plickers.com/fonts/Effra/Effra-Latin-Regular.v50.woff2') format('woff2'), url('https://assets.plickers.com/fonts/Effra/Effra-Latin-Regular.v50.woff') format('woff');    
	font-style: normal;
	font-display: auto;
	font-weight: $effra-regular;
	text-rendering: optimizeLegibility;
	unicode-range: U+0020-007E, U+0040, U+00A9, U+00B7;
}

// FULL EFFRA MEDIUM
@font-face {
	font-family: 'Effra';   
	src: url('https://assets.plickers.com/fonts/Effra/Effra-Medium.setcetera.woff2') format('woff2'), url('https://assets.plickers.com/fonts/Effra/Effra-Medium.setcetera.woff') format('woff');    
	font-style: normal;
	font-display: auto;
	font-weight: $effra-medium;
	text-rendering: optimizeLegibility;
}

// LATIN EFFRA MEDIUM
@font-face {
	font-family: 'Effra';   
	src: url('https://assets.plickers.com/fonts/Effra/Effra-Latin-Medium.v50.woff2') format('woff2'), url('https://assets.plickers.com/fonts/Effra/Effra-Latin-Medium.v50.woff') format('woff');    
	font-style: normal;
	font-display: auto;
	font-weight: $effra-medium;
	text-rendering: optimizeLegibility;
	unicode-range: U+0020-007E, U+0040, U+00A9, U+00B7;
}

// FULL EFFRA BOLD
@font-face {
	font-family: 'Effra';   
	src: url('https://assets.plickers.com/fonts/Effra/Effra-Bold.setcetera.woff2') format('woff2'), url('https://assets.plickers.com/fonts/Effra/Effra-Bold.setcetera.woff') format('woff');   
	font-style: normal;
	font-display: auto;
	font-weight: $effra-bold;
	text-rendering: optimizeLegibility;
}

// LATIN EFFRA BOLD
@font-face {
	font-family: 'Effra';   
	src: url('https://assets.plickers.com/fonts/Effra/Effra-Latin-Bold.v50.woff2') format('woff2'), url('https://assets.plickers.com/fonts/Effra/Effra-Latin-Bold.v50.woff') format('woff');   
	font-style: normal;
	font-display: auto;
	font-weight: $effra-bold;
	text-rendering: optimizeLegibility;
	unicode-range: U+0020-007E, U+0040, U+00A9, U+00B7;
}

// FULL EFFRA HEAVY
@font-face {
	font-family: 'Effra';   
	src: url('https://assets.plickers.com/fonts/Effra/Effra-Heavy.setcetera.woff2') format('woff2'), url('https://assets.plickers.com/fonts/Effra/Effra-Heavy.setcetera.woff') format('woff');   
	font-style: normal;
	font-display: auto;
	font-weight: $effra-heavy;
	text-rendering: optimizeLegibility;
}


// LATIN EFFRA HEAVY
@font-face {
	font-family: 'Effra';   
	src: url('https://assets.plickers.com/fonts/Effra/Effra-Latin-Heavy.v50.woff2') format('woff2'), url('https://assets.plickers.com/fonts/Effra/Effra-Latin-Heavy.v50.woff') format('woff');   
	font-style: normal;
	font-display: auto;
	font-weight: $effra-heavy;
	text-rendering: optimizeLegibility;
	unicode-range: U+0020-007E, U+0040, U+00A9, U+00B7;
}

.marketing, .marketing input, .marketing button {
	// Effra is used by adding marketing classname
	font-family: 'Effra', sans-serif;
	-webkit-font-smoothing: antialiased;
}
