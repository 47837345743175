$width-creatorsListSideNav: 240px;
$width-creatorsListDetailPanel: 940px;

.page.creatorsList{
	background: $white;
}

//
// LAYOUT
//


.creatorsList-sideNav{
	position: fixed;
	width: $width-creatorsListSideNav;
	top: 0px;
	left: 0px;
	bottom: 0px;	

	box-sizing: border-box;
	padding-left: 36px;
	padding-right: 22px;
	padding-top: 20px;

	background: rgb(252,252,255);
	border-right: 1px solid $gray-125;
}

.creatorsList-centerPanel{
	position: fixed;
	left: $width-creatorsListSideNav;
	top: 0px;
	bottom: 0px;
	right: $width-creatorsListDetailPanel;
	overflow-y: scroll;

	box-sizing: border-box;
	padding-top: 56px;	
	padding-bottom: 108px;
}

.creatorsList-centerPanel:focus{
	outline: none;
}

.creatorsList-detailPanel{
	position: fixed;	
	top: 0px;
	bottom: 0px;
	width: $width-creatorsListDetailPanel;
	right: 0px;
	overflow-y: scroll;

	box-sizing: border-box;
	border-left: 1px solid $gray-125;

	padding-left: 50px;
	padding-top: 45px;
	padding-right: 40px;
}	


@import 'CreatorsList-SideNav';
@import 'CreatorsList-CreatorCell';
@import 'CreatorsList-DetailPanel';
// @import 'creatorsList-CrossFilterDisplayControl';
// @import 'creatorsList-CrossFilterMarketPanel';
// @import 'creatorsList-CrossFilterRepo';
// @import 'creatorsList-EditRepoNotesModal';


