.publishingFlowContainer{
	position: fixed;
	z-index: 9999;
	top: 0;
	left: 0;
	right: 0;
	height: 100vh;
	
	background: rgba(0,0,10,0.75);
	
	display: flex;
	align-items: center;
	justify-content: center;

	overflow: hidden;
}

$background-publishingFlowModal: rgb(252,253,255);

.publishingFlowModal{
	
	border-radius: 5px;
	
	background: $background-publishingFlowModal;
	border: 1px solid $gray-4;
	box-shadow: 
		0px 2px 4px 0px rgba(5,5,10,0.50),
		0px 8px 16px 0px rgba(5,5,10,0.25) ;

	box-sizing: border-box;
	
	overflow: hidden;

	width: 980px;
	height: 640px;

	position: relative;
	padding-left: 90px;
	padding-right: 60px;
}

////

.publishingFlowModalPage-header{
	margin-top: 18px;

	font-size: 22px;
	font-weight: $lato-heavy;
	color: $textBlack;
	letter-spacing: 0.01em;

	margin-bottom: 5px;

	cursor: default;
	user-select: none;
}


.publishingFlowModalPage-header-light{ // for description optional
	font-weight: $lato-bold;
	color: $textLight;
}

//

.publishingFlowModalPage-subheader{
	font-size: 15px;
	font-weight: $lato-medium;
	color: $textBlack;
	letter-spacing: 0.01em;

	cursor: default;
	user-select: none;
}	

.publishingFlowModalPage-subheader{
	
}	


@import 'PublishingFlow-Header'; // contains progress and cancel button
@import 'PublishingFlow-Footer'; // contains done and back buttons
@import 'PublishingFlow-SelectControl'; // checkboxes and radio buttons
@import 'PublishingFlow-CheckList'; 
@import 'PublishingFlow-Subject'; 
@import 'PublishingFlow-GradeLevel'; 
@import 'PublishingFlow-Description'; 
@import 'PublishingFlow-Image'; 
@import 'PublishingFlow-Review'; 
@import 'PublishingFlow-Confetti'; // confetti displays at success
@import 'PublishingFlow-Success'; 
