.editor-emojiSymbolPicker-tabs{
	display: flex;
	box-sizing: border-box;
	padding-left: $sidePadding-emojiSymbolPicker;
	padding-right: $sidePadding-emojiSymbolPicker;
}


.editor-emojiSymbolPicker--emoji
.editor-emojiSymbolPicker-tabs-tab{
	width: calc(100% / #{$numberTabs-emojiPickerSymbol-emoji});
}

.editor-emojiSymbolPicker-tabs-tab{
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
}

.editor-emojiSymbolPicker-tabs-tab--active{
	opacity: 1;
}

.editor-emojiSymbolPicker-tabs-tab--inactive{
	opacity: 0.5;
}

.editor-emojiSymbolPicker-tabs-tab--inactive:hover{
	opacity: 1;	
}

.editor-emojiSymbolPicker-tabs-tab-bottomBorder{
	position: absolute;
	bottom: 0px;
	left: 0px;
	right: 0px;
	height: 3px;
	background: $blue;

	visibility: hidden;
}

.editor-emojiSymbolPicker-tabs-tab--active
.editor-emojiSymbolPicker-tabs-tab-bottomBorder{
	visibility: visible;
}