.importQuestions-topBar{
	
	height: $height-importQuestions-topBar;
	position: absolute;
	top: 0px;
	left: 0px;
	right: 0px;
	z-index: 20;

	display: flex;
	padding-left: 14px;
	padding-right: 14px;
	box-sizing: border-box;

	background: $color-navBar-BG;
	border-bottom: $border-navBar;
	box-shadow: $shadow-navBar;

	user-select: none;
	cursor: default;
}

.importQuestions-topBar-left{
	flex-grow: 1;
	min-width: 10px;

	white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  display: flex;
  align-items: center;
}

.importQuestions-topBar-left-pageTitle{
	font-size: 20px;
	font-weight: $lato-heavy;
	color: $textBlack;
	margin-right: 10px;
	flex-shrink: 0;
}

.importQuestions-topBar-left-pageTitle-beta{
	color: lighten($textLight, 5%);
	font-weight: $lato-bold;
}

// temp

.importQuestions-topBar-left button.btn--mid.btn--white.testing,
.importQuestions-topBar-left button.btn--mid.btn--white.testing:hover,
.importQuestions-topBar-left button.btn--mid.btn--white.testing:active{
	background: none;
	border: none;
	color: $textBlue;
	text-decoration: underline;
	cursor: pointer;
	box-shadow: none;
}

///

.importQuestions-topBar-right{
	flex-grow: 0;
	padding-left: 10px;
	display: flex;
	align-items: center;
}

//

.importQuestions-topBar-right button.feedback{
	margin-right: 8px;
	padding-right: 14px;
	padding-left: 14px;

}

.importQuestions-topBar-right button.undo{
	min-width: 100px;
	width: 100px;
	font-weight: $lato-bold;
	margin-right: 38px;
}

.importQuestions-topBar-right button.undo svg.icon{
	height: 14px;
	width: 14px;
	margin-left: -2px;
}

.importQuestions-topBar-right button.btn--disabled.undo svg.icon{
	fill: $gray-10;
}

// 

.importQuestions-topBar-right-choiceCountToggle{
	display: flex;
}

.importQuestions-topBar-right-choiceCountToggle.tooltip--label.tooltip::after{
	margin-top: 3px;
}

.importQuestions-topBar-right-choiceCountToggle button{
	min-width: 85px;
	width: 85px;
	// padding-left: 0px;
	// padding-right: 0px;
}

.importQuestions-topBar-right-choiceCountToggle button.fourchoice{
	border-top-left-radius: 0px;
	border-bottom-left-radius: 0px;
}

.importQuestions-topBar-right-choiceCountToggle button.threechoice{
	border-radius: 0px;
	position: relative;
	right: -1px;
}


.importQuestions-topBar-right-choiceCountToggle button.twochoice{
	border-top-right-radius: 0px;
	border-bottom-right-radius: 0px;
	position: relative;
	right: -2px;
}

.importQuestions-topBar-right-choiceCountToggle button.btn--blue{
	position: relative;
	z-index: 10;
}

//

.importQuestions-topBar-right button.cancel{
	width: 130px;
	font-weight: $lato-bold;
	color: $textBlackDark;
	// color: $white;
	// background: $gray-7;	
	// border-color: $gray-7;
	margin-left: 12px;
	font-size: 15px;
}




// .importQuestions-topBar-right button.settingsdropdown{
// 	width: 100px;
// 	margin-left: 8px;
// }

// .importQuestions-topBar-right .dropdownMenu.position--left{
// 	left: -77px;
// }

