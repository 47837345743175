.add-license-key .modal-content{
	height: 380px;
	width: 620px;

	border-radius: 10px;
	padding-left: 44px;
	padding-right: 150px;
	padding-top: 36px;
	box-sizing: border-box;
}
.addLicenseKeyModal{ // bad, should refactor to separate form of modal as default
	width: 100%;
	height: 100%;
}

.addLicenseKeyModal-header,
.addLicenseKeyModal-sub{
	user-select: none;
	cursor: default;
}

.addLicenseKeyModal-header{
	font-size: 28px;
	font-weight: $lato-heavy;
	margin-bottom: 10px;
	color: $textBlackDark;
}

.addLicenseKeyModal-sub{
	margin-bottom: 14px;
	font-weight: $lato-medium;
	color: $textMid;
	font-size: 16px;
}


.addLicenseKeyModal-errorSpace{
	height: 64px;
	width: 420px;
	margin-bottom: 4px;
	
	border-radius: 4px;

	padding: 8px;

	box-sizing: border-box;

	position: relative;
	font-size: 14.5px;
	top: -174px;

	font-weight: $lato-bold;
	color: $red-dark;

	cursor: default;
	opacity: 0;
	transition: all 50ms linear;
}

.addLicenseKeyModal-errorSpace--hasError{
	background: transparentize($red, 0.9);
	opacity: 1;
	transition: opacity 100ms;
}


a.addLicenseKeyModal-link,
a.addLicenseKeyModal-link:visited{
	font-size: 14.5px;
	font-weight: $lato-semibold;
	color: $textLight;
	padding-top: 4px;
	padding-bottom: 4px;
	cursor: pointer;
	line-height: 1.3;
}

a.addLicenseKeyModal-link:hover,
a.addLicenseKeyModal-link:visited:hover{
	color: $textBlue;
	text-decoration: underline !important; //overwrite normalized base
}

// FORM

.add-license-key .modal-content form{
	width: 420px;
}

.add-license-key form .formField.is--text{
	margin-bottom: 14px;
}

.add-license-key form .formField.is--text input{
	height: 42px;	
	padding-left: 12px;
	font-weight: $lato-bold;
}

// Buttons


.addLicenseKeyModal-actionRow{
	display: flex;
	margin-top: 80px;
	margin-bottom: 18px;
}

.addLicenseKeyModal-actionRow button{
	font-weight: $lato-bold;
	letter-spacing: 0.005em;
	cursor: pointer;
}

.addLicenseKeyModal-actionRow button.submitlicense{
	width: 340px;
	margin-right: 14px;
}

.addLicenseKeyModal-actionRow button.cancel{
	width: 160px;
}


// //

// .accountForm-updatePaymentModal-hiddenEmailInput{
// 	visibility: hidden;
// 	height: 0px;
// }

// .accountForm-updatePaymentModal-addressRowContainer{
// 	display: flex;
// 	margin-top: -40px;
// }

// .formField.is--updatePaymentModal-streetAddress{
// 	width: 10px;
// 	flex-grow: 1;
// 	flex-shrink: 0;
// 	margin-right: 8px;
// }

// .formField.is--updatePaymentModal-postalCode{
// 	width: 80px;
// 	flex-grow: 0;
// 	flex-shrink: 0;
// }

// .update-payment-info .StripeElement{
// 	width: 100%;
// 	height: 42px;

// 	background: $white;

// 	font-size: 16px;
// 	border-radius: 4px;
// 	font-weight: $lato-medium;
// 	color: $textBlack;

// 	margin-top: -12px;
// 	margin-bottom: 24px;

// 	padding-left: 13px;
// 	padding-top: 10px;
// 	box-sizing: border-box;

// 	border: 1px solid rgb(200,200,210);

// 	box-shadow: none;
// 	outline: none;
// 	-webkit-appearance: none;

// 	font-family: 'Lato';
// }

// .update-payment-info .StripeElement--focus{
// 	outline: none;
// 	border: 1px solid $blue;
// 	background: $white;	
// }

// // Buttons

// //  Hacky, try and disable if 
// // .StripeElement--empty ~ .updatePaymentInfo-actionRow button.update-payment{
// // 	pointer-events: none;
// // 	// stolen from disabled, better way to do this 
// // 	color: $white;
// // 	background: linear-gradient(to bottom, rgb(155,155,163) 0%, rgb(155,155,161) 100%); 
// // 	border-color: rgb(146,146,156);
// // 	pointer-events: none;
// // 	cursor: default;
// // }
