.importQuestionsHelperContainer{
	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	z-index: 9999;
	background: $white;

	overflow-y: scroll;
}
.importQuestionsHelper{
	box-sizing: border-box;
	padding: 128px;
	padding-top: 64px;

	width: 100%;

}


.importQuestionsHelper-pageTitle{
	font-weight: $lato-heavy;
	font-size: 36px;
	color: $textBlack;

	margin-bottom: 24px;
}

.importQuestionsHelper-pageDescription{
	font-size: 18px;
	font-weight: $lato-semibold;
	color: $textDark;
	margin-bottom: 32px;
	line-height: 1.5;
}


.importQuestionsHelper-section{
	margin-bottom: 48px;
}

.importQuestionsHelper-section-title{
	display: flex;
	align-items: center;

	margin-left: -34px;
	margin-bottom: 14px;
}

.importQuestionsHelper-section-title-blob{
	width: 24px;
	height: 24px;
	background: $tempr;
	border-radius: 5px;
	margin-right: 10px;
}

.importQuestionsHelper-section-title-label{
	font-size: 24px;
	font-weight: $lato-bold;
}

.importQuestionsHelper-section-title-blob--excellent{
	background: $color-o85;
}

.importQuestionsHelper-section-title-blob--lightWork{
	background: $color-o70;
}

.importQuestionsHelper-section-title-blob--heavyWork{
	background: $color-o60;
}

.importQuestionsHelper-section-title-blob--unsupported{
	background: $color-u60;
}

// ROW

a.importQuestionsHelperSourceRow{
	display: flex;
	width: 100%;

	min-height: 60px;

	border-bottom: 1px solid $lineMid;
	align-items: center;

	cursor: default;

	cursor: pointer;
}

a.importQuestionsHelperSourceRow:hover{
	background: $blue-extraLight;
}

.importQuestionsHelperSourceRow-nameContainer,
.importQuestionsHelperSourceRow-typeContainer,
.importQuestionsHelperSourceRow-workLoadContainer{
	flex-grow: 0;
	flex-shrink: 0;
}

.importQuestionsHelperSourceRow-notesContainer{
	flex-grow: 1;
}

.importQuestionsHelperSourceRow-nameContainer{
	width: 320px;
}

.importQuestionsHelperSourceRow-name{
	width: 320px;
	height: 60px;
	padding-left: 16px;
	box-sizing: border-box;
	cursor: pointer;

	display: flex;
	align-items: center;

	font-size: 16px;
	font-weight: $lato-bold;
	color: $textBlack;
}




.importQuestionsHelperSourceRow-typeContainer{
	color: $textMid;
	width: 90px;
}


.importQuestionsHelperSourceRow-workLoadContainer{
	color: $textMid;
	width: 120px;
}

.importQuestionsHelperSourceRow-notesContainer{
	font-size: 14px;
	color: $textMid;
}

.importQuestionsHelperSourceRow-notes{
	padding-top: 10px;
	padding-bottom: 10px;
}
