.explorerCrossFilter-displayControl{
	margin-bottom: 15px;
	width: 100%;
	box-sizing: border-box;
	padding: 12px;
}

.explorerCrossFilter-displayControl-title{
	text-transform: uppercase;
	font-size: 12.5px;
	font-weight: $lato-bold;
	color: $textBlue;
	margin-left: 8px;
	margin-bottom: 3px;
}

// SORT CONTROLS TEMP

.explorerCrossFilterSortPanelControl{
	width: 100%;
	font-size: 14px;
	font-weight: $lato-semibold;
	
	padding-left: 8px;
	padding-top: 2px;
	padding-bottom: 2px;

	color: $textMid;

	user-select: none;
	cursor: pointer;

	position: relative;

	width: 90%;

	border-radius: 3px;
	letter-spacing: 0.008em;
}

.explorerCrossFilterSortPanelControl--isActive{
	font-weight: $lato-bold;
	background: $blue;
	color: $white;
}


.explorerCrossFilterSortPanelControl:hover{
	background: $gray-14;
	color: $textDark;
}

.explorerCrossFilterSortPanelControl--isActive:hover{
	background: darken($blue, 5%);
	color: $white;
}

.explorerCrossFilter-packsToRateCounter{
	font-size: 14px;
	font-weight: $lato-semibold;
	color: $textLight;
	margin-left: 20px;	
	cursor: default;

	position: absolute;
	
	right: 0px;
	left: 0px;
	bottom: 14px;

}

.explorerCrossFilter-packsToRateCounter b{
	color: $textDark;
	font-weight: $lato-bold;
}