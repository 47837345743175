$height-emojiSymbolPicker-tabs: 2px; // take out for now
//$height-emojiSymbolPicker-search: 60px;
$height-emojiSymbolPicker-search: 44px;
//$height-emojiSymbolPicker-listContainer: 270px;
$height-emojiSymbolPicker-listContainer: 340px;
$height-emojiSymbolPicker-footer: 61px;

$sidePadding-emojiSymbolPicker: 16px;
$sidePaddingInner-emojiSymbolPicker: 16px;

$numberTabs-emojiPickerSymbol-emoji: 8; // no longer used
$itemsPerRow-emojiPickerSymbol-list: 8;

//$rowSideDimension-emojiPickerSymbol-item: 36px;

$rowSideDimension-emojiPickerSymbol-item: 42px;

// width of emoji Picker is based off of height/width of each emoji/symbol preview, number per row, as well box padding either side
$width-emojiPicker: calc((#{$rowSideDimension-emojiPickerSymbol-item} * #{$itemsPerRow-emojiPickerSymbol-list}) + #{$sidePadding-emojiSymbolPicker} + #{$sidePadding-emojiSymbolPicker});

.editor-formatBar-symbolsEmoji-tempEmojiContainer{
	width: 400px;
	height: 400px;
	
	position: absolute;
	top: calc(#{$height-editor-formatBar});
	left: -115px;
	bottom: 0px;

	z-index: 20;
}


//
// EDITOR EMOJI SYMBOL PICKER
//

.editor-emojiSymbolPicker{
	width: $width-emojiPicker;
	height: calc(#{$height-emojiSymbolPicker-tabs} + #{$height-emojiSymbolPicker-search} + #{$height-emojiSymbolPicker-listContainer} + #{$height-emojiSymbolPicker-footer});

	border: 1px solid $lineDark;
	border-bottom: 1px solid darken($lineDark, 5%);
	background: $white;
	background: rgb(251,251,254);
	box-shadow: 0px 2px 4px 0px rgba(0,0,40,0.12), 0px 0px 2px 0px rgba(0,0,40,0.08);
	border-radius: 6px;

	cursor: default;

	position: relative;
	overflow: hidden;
}

.editor-emojiSymbolPicker-tabs{
	position: absolute;
	left: 0px;
	right: 0px;
	top: 0px;
	height: $height-emojiSymbolPicker-tabs;
	border-bottom: 1px solid $lineDark;	
}

@import 'Editor-EmojiSymbolPicker-tabs';

.editor-emojiSymbolPicker-search{
	position: absolute;
	left: 0px;
	right: 0px;
	top: $height-emojiSymbolPicker-tabs;
	height: $height-emojiSymbolPicker-search;	
}

@import 'Editor-EmojiSymbolPicker-search';

.editor-emojiSymbolPicker-listContainer{
	position: absolute;
	left: 0px;
	right: 0px;
	//bottom: $height-emojiSymbolPicker-footer;
	bottom: 0px;
	top: $height-emojiSymbolPicker-search;
	//height: $height-emojiSymbolPicker-listContainer;
	
	border-bottom: 1px solid $lineDark;
}

@import 'Editor-EmojiSymbolPicker-list';

.editor-emojiSymbolPicker-footer{
	position: absolute;
	left: 0px;
	right: 0px;
	bottom: 0px;
	height: $height-emojiSymbolPicker-footer;
	
}

@import 'Editor-EmojiSymbolPicker-footer';