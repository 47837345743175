.update-payment-info .modal-content{
	height: auto;
	width: 620px;

	border-radius: 10px;
	padding-left: 44px;
	padding-bottom: 34px;
	padding-top: 36px;
	box-sizing: border-box;
}
.updatePaymentInfoModal{ // bad, should refactor to separate form of modal as default
	width: 100%;
	height: 100%;
}

.updatePaymentInfoModal-header,
.updatePaymentInfoModal-subHeader,
.updatePaymentInfoModal-currentCard{
	user-select: none;
	cursor: default;
}

.updatePaymentInfoModal-header{
	font-size: 28px;
	font-weight: $lato-heavy;
	margin-bottom: 24px;
	color: $textBlackDark;
}

.updatePaymentInfoModal-subHeader{
	font-weight: $lato-bold;
	font-size: 12.5px;
	letter-spacing: 0.015em;
	text-transform: uppercase;	
}

.updatePaymentInfoModal-subHeader--current{
	color: $textBlue;
	margin-bottom: 8px;
}

.updatePaymentInfoModal-subHeader--replace{
	color: $textLight;
}

.updatePaymentInfoModal-currentCard{
	font-size: 17px;
	font-weight: $lato-heavy;
	color: $textBlackDark;

	margin-bottom: 20px;
}


// FORM

.update-payment-info .modal-content form{
	width: 420px;
}

.update-payment-info form .formField.is--text{
	margin-bottom: 14px;
}

.update-payment-info form .formField.is--text input{
	height: 42px;	
	padding-left: 12px;
	font-weight: $lato-bold;
}


//

.accountForm-updatePaymentModal-hiddenEmailInput{
	visibility: hidden;
	height: 0px;
}

.accountForm-updatePaymentModal-addressRowContainer{
	display: flex;
	margin-top: -40px;
}

.formField.is--updatePaymentModal-streetAddress{
	width: 10px;
	flex-grow: 1;
	flex-shrink: 0;
	margin-right: 8px;
}

.formField.is--updatePaymentModal-postalCode{
	width: 80px;
	flex-grow: 0;
	flex-shrink: 0;
}

.update-payment-info .StripeElement{
	width: 100%;
	height: 42px;

	background: $white;

	font-size: 16px;
	border-radius: 4px;
	font-weight: $lato-medium;
	color: $textBlack;

	margin-top: -12px;
	margin-bottom: 24px;

	padding-left: 13px;
	padding-top: 10px;
	box-sizing: border-box;

	border: 1px solid rgb(200,200,210);

	box-shadow: none;
	outline: none;
	-webkit-appearance: none;

	font-family: 'Lato';
}

.update-payment-info .StripeElement--focus{
	outline: none;
	border: 1px solid $blue;
	background: $white;	
}

// Buttons

//  Hacky, try and disable if 
// .StripeElement--empty ~ .updatePaymentInfo-actionRow button.update-payment{
// 	pointer-events: none;
// 	// stolen from disabled, better way to do this 
// 	color: $white;
// 	background: linear-gradient(to bottom, rgb(155,155,163) 0%, rgb(155,155,161) 100%); 
// 	border-color: rgb(146,146,156);
// 	pointer-events: none;
// 	cursor: default;
// }

.updatePaymentInfo-actionRow{
	display: flex;
	margin-bottom: 34px;
}

.updatePaymentInfo-actionRow button{
	font-weight: $lato-bold;
	letter-spacing: 0.005em;
	cursor: pointer;
}

.updatePaymentInfo-actionRow button.update-payment-method{
	width: 340px;
	margin-right: 14px;
}

.updatePaymentInfo-actionRow button.cancel{
	width: 160px;
}