.fontSizeCalculationVisualizer-templateSelectPanel-UISetup,
.fontSizeCalculationVisualizer-templateSelectPanel-template{
	background: $gray-2;

	color: $white;

	display: flex;
	align-items: center;
	justify-content: space-between;

	box-sizing: border-box;
	padding-left: 20px;
	padding-right: 20px;

	height: 30px;
}

.fontSizeCalculationVisualizer-templateSelectPanel-UISetup{
	background: $black;
}

.fontSizeCalculationVisualizer-templateSelectPanel-template{
  padding-top: 30px;
}

.fontSizeCalculationVisualizer-templateSelectPanel-pageTitle{
	font-size: 13.5px;
	font-weight: $lato-heavy;
	color: $textDark;
	text-transform: uppercase;
}

.fontSizeCalculationVisualizer-templateSelectPanel-hSpacer{
	flex-grow: 1;
	flex-shrink: 1;
}

.fontSizeCalculationVisualizer-templateSelectPanel-toggleGroup{
	font-size: 13.5px;
	color: $textLight;
	font-weight: $lato-bold;
}

.fontSizeCalculationVisualizer-templateSelectPanel-toggleGroup--slideWidth{
	margin-left: 20px;
}

button.fontSizeCalculationVisualizer-templateSelectPanel-toggle{
	font-size: 13.5px;
	margin: 2px;
	padding-left: 8px;
	padding-right: 8px;

	background: $black;
	color: $white;
	border: 1px solid $invisible;

	opacity: 0.5;

	padding-top: 3px;
	padding-bottom: 3px;
	border-radius: 3px;

	cursor: pointer;

	letter-spacing: 0.02em;
}


button.fontSizeCalculationVisualizer-templateSelectPanel-toggle--active{
	opacity: 0.8;
	color: $white;

	font-weight: $lato-bold;
}

.fontSizeCalculationVisualizer-templateSelectPanel-toggleGroup--template
button.fontSizeCalculationVisualizer-templateSelectPanel-toggle--active{
	opacity: 1;
	background: $blue;
}

button.fontSizeCalculationVisualizer-templateSelectPanel-toggle--inactive{
	opacity: 0.4;
}

.fontSizeCalculationVisualizer-templateSelectPanel:hover
button.fontSizeCalculationVisualizer-templateSelectPanel-toggle--inactive{
	opacity: 0.7;
}

.fontSizeCalculationVisualizer-templateSelectPanel:hover
button.fontSizeCalculationVisualizer-templateSelectPanel-toggle--inactive:hover{
	opacity: 1;
}

.fontSizeCalculationVisualizer-templateSelectPanel-toggleGroup-verticalGroup{
	display: flex;
	width: 700px;

	// background: red;
}

.fontSizeCalculationVisualizer-templateSelectPanel-toggleGroup-verticalGroup
.fontSizeCalculationVisualizer-templateSelectPanel-toggle{
	flex-grow: 1;
	flex-shrink: 1;
}
