.authorPage-repoList{
	padding-top: 24px;
}

.authorPageRepoLink{
	width: 100%;
	min-height: 136px;

	// background: $tempr;

	margin-left: -12px;

	// margin-bottom: 20px;
	//margin-bottom: 8px;
	padding-top: 10px;
	padding-bottom: 10px;
	padding-left: 146px;
	padding-right: 80px;

	box-sizing: border-box;

	user-select: none;

	// background: $tempr;
	border-radius: 4px;

	display: flex;
	flex-direction: column;
	position: relative;

	cursor: pointer;
}

.authorPageRepoLink--loader{
	pointer-events: none;
}


.authorPageRepoLink-repoImageContainer{
	position: absolute;
	top: 10px;
	left: 12px;
	width: 116px;
	height: 116px;

	overflow: hidden;
	border-radius: 5px;
}

.authorPageRepoLink-repoImageContainer img{
	width: 100%;
	height: 100%;
}

.authorPageRepoLink-repoImageLoader{
	width: 450px;
	height: 450px;
	background: $color-pageLoaderElement;
}

.authorPageRepoLink-title{
	font-size: 18px;
	font-weight: $lato-bold;
	color: $textBlack;

	display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;

  text-overflow: clip;
  white-space: normal;
  overflow: hidden; 

  max-height: 40px;

  margin-top: 6px;
  margin-bottom: 6px;

}

.authorPageRepoLink-title-pageLoaderPlaceholder{
	height: 20px;
	width: 160px;
	background: $color-pageLoaderElement;
	border-radius: 3px;
}

.authorPageRepoLink-metadata{
	font-size: 14px;
	font-weight: $lato-semibold;
	color: $textMid;
	margin-bottom: 7px;
}

.authorPageRepoLink-metadata-pageLoaderPlaceholder{
	height: 17px;
	width: 200px;
	background: $color-pageLoaderElement;
	border-radius: 3px;
}

.authorPageRepoLink-description{
	font-size: 13.5px;
	font-weight: $lato-medium;
	color: $textMid;

	padding-bottom: 10px;
	
  white-space: normal;
  overflow: hidden; 

	word-wrap: break-word;
}

.authorPageRepoLink-description-pageLoaderPlaceholder{
	height: 40px;
	width: 240px;
	background: $color-pageLoaderElement;
	border-radius: 3px;
	opacity: 0.75;
}

.authorPageRepoLink-rightArrow{
	position: absolute;
	height: 12px;
	width: 12px;
	top: 22px;
	right: 8px;
	opacity: 1;
	display: flex;
	//background: $tempr;
}

// SHARE SECTION
.authorPage-share{
	display: flex;
	margin-top: 20px;
	align-items: center;
}

.authorPage-share-URL{
	font-size: 15px;
	font-weight: $lato-medium;
	color: $textMid;
	cursor: text;	
	margin-right: auto;
}

.authorPage-share--loading .authorPage-share-URL{
	background: $color-pageLoaderElement;	
	height: 17px;
	width: 170px;
	border-radius: 3px;
	cursor: default;
}

.authorPage-share--loading .authorPage-share-socialMediaContainer{
	display: flex;
}

.authorPage-share--loading .authorPage-share-socialMediaBlob{
	margin-right: 7px;	
	height: 20px;
	border-radius: 3px;
	background: $color-pageLoaderElement;
	width: 61px;
}


//

.authorPageRepoLink-rightArrow .icon{
	height: 12px;
	width: 12px;
	fill: $blue;
	opacity: 0;
}


.authorPageRepoLink:hover .authorPageRepoLink-rightArrow .icon{	
	opacity: 1;
}



.authorPageRepoLink:hover{
	background: $gray-15;
}

//
// EMPTY STATE
//

.authorPage-repoList-empty{
	font-size: 24px;
	font-weight: $lato-heavy;
	color: transparentize($textLight, 0.75);
	margin-top: 45px;

	user-select: none;
	cursor: default;	
}



///
/// Annoyign hack for edit profile pic button

.btn.btn--mid.btn--white.edit-profile-pic{
	background: none;
	border: 1px solid $invisible;
	box-shadow: none;
	//cursor: pointer;

	color: rgb(54, 120, 250);
	font-weight: $lato-bold;

	font-size: 15px;
	height: 32px;

	display: flex;
	align-items: center;
	justify-content: center;

	padding-left: 8px;
	padding-right: 8px;

	border-radius: 3px;

	cursor: pointer;
}

.btn.btn--mid.btn--white.edit-profile-pic:hover{
	background: rgb(247, 250, 255);		
	color: rgb(45, 110, 240);	
}


		

///
/// LOADER ANIMATION
///

@keyframes authorPageRepoLinkLoaderFade {
  from { opacity: 1; }
  to { opacity: 0.5; }
}




.authorPageRepoLink--loader{	
	animation: authorPageRepoLinkLoaderFade 1s infinite linear;
	animation-direction: alternate;
}

.authorPageLoader .authorPageRepoLink--loader{
	animation: none; // otherwise it stutters through loading states
}


.authorPageRepoLink--loader:nth-child(1){
	animation-delay: 0ms;
}

.authorPageRepoLink--loader:nth-child(2){
	animation-delay: 250ms;	
}

.authorPageRepoLink--loader:nth-child(3){
	animation-delay: 500ms;	
}

.authorPageLoader,
.authorPageLoader *{
	pointer-events: none !important;
	cursor: default;
}

//
//
//

@import 'AuthorPage-EditAuthorDescriptionModal'; 


// .authorPageRepoLink:hover
// .authorPageRepoLink-questionPreview 
// .slideCarousel-slide {
// 	border-color: rgba(0,0,40,0.15);
// 	border-bottom-color: rgba(0,0,40,0.22);
// }

// .authorPageRepoLink:hover .authorPageRepoLink-timestamp{
// 	color: $textMid;
// }