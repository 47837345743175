$width-editor-bottomBarTemplateBtnGroup: 270px;

.editor-canvas-bottomBar{
	position: fixed;
	
	
	//top provided by JS
	//width provided by JS
	left: 50%;
	transform: translateX(-50%);

	margin-top: calc(#{$height-editor-topBar} + #{$height-editor-formatBar} + #{$height-editor-canvasTopPadding});
	height: $height-editor-canvasBottomPadding;
	
	// background: $tempg;

	display: flex;
}

.editor-canvas-bottomBar-left,
.editor-canvas-bottomBar-rightSpacer,
.editor-canvas-bottomBar-center{
	flex-grow: 0;
	flex-shrink: 0;
	height: 100%;
}


.editor-canvas-bottomBar-left,
.editor-canvas-bottomBar-rightSpacer{
	width: calc((100% - #{$width-editor-bottomBarTemplateBtnGroup})/2);
	// background: $tempb;
}

.editor-canvas-bottomBar-left{
	position: relative;
}

//
// LEFT CHOICES BTN

button.editor-canvas-bottomBar-choiceBtn{
	cursor: pointer;

	height: 30px;
	padding-left: 12px;
	padding-right: 12px;

	display: flex;
	align-items: center;

	outline: none;
	border: none;
	background: none;
	box-shadow: none;


	background: $white;

	font-size: 14px;
	font-weight: $lato-semibold;
	color: rgb(108,108,114);

	border-radius: 4px;

	line-height: 1.2;

	user-select: none;

	border: 1px solid rgb(225,224,231);
	border-bottom: 1px solid darken(rgb(225,224,231),3%);
	box-shadow: 0px 1px 2px 0px rgba(0,0,40,0.04);

	// border: 1px solid rgb(225,224,231);
	// border-bottom: 1px solid darken(rgb(225,224,231),3%);


  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  position: relative;
}

button.editor-canvas-bottomBar-choiceBtn--trueFalse{
	padding-left: 16px;
	padding-right: 16px;
	// without an icon, we have more space
}

button.editor-canvas-bottomBar-choiceBtn svg{
	height: 14px;
	width: 14px;
	fill: rgb(108,108,114);
	margin-right: 5px;
}

button.editor-canvas-bottomBar-choiceBtn:hover{
	color: $textDark;
}

button.editor-canvas-bottomBar-choiceBtn:hover svg{
	fill: $textDark;	
}

button.editor-canvas-bottomBar-choiceBtn:focus{
	// background: $blue;
	// color: $white;
	// border: 1px solid darken($blue, 5%);
	// // too strong

	border: 1px solid darken(rgb(225,224,231), 11%);
	border-bottom: 1px solid darken(rgb(225,224,231),14%);
	box-shadow: 0px 1px 2px 0px rgba(0,0,40,0.12);
	// background: red; // temp for dev
	color: $textBlack;
}

button.editor-canvas-bottomBar-choiceBtn:focus svg{
	fill: $textBlack;
}

button.editor-canvas-bottomBar-choiceBtn--addChoice{
	margin-left: 6px;
}

// when small screen, remove label from shuffle button if has choices
@media screen and (max-width: 1160px), (max-height: 610px) {
  .editor-canvas-bottomBar-left--showNewChoiceBtn
  .editor-canvas-bottomBar-choiceBtn--shuffle
  .editor-canvas-bottomBar-choiceBtn-label {
    display: none;
  }
  .editor-canvas-bottomBar-left--showNewChoiceBtn
  .editor-canvas-bottomBar-choiceBtn--shuffle svg{
    margin-right: 0px;
  }
}

// when really small screen, remove icon from add choice button
@media screen and (max-width: 1060px) {
  .editor-canvas-bottomBar-left--showNewChoiceBtn
  .editor-canvas-bottomBar-choiceBtn--addChoice svg {
    display: none;
  }
}

// when narrow, reduce padding to decongest area around T/F
@media screen and (max-width: 1100px) {
  .editor-canvas-bottomBar-left.editor-canvas-bottomBar-left--showNewChoiceBtn{
  	padding-left: 24px;
  }
}


// SHUFFLE TOOLTIP

.editor-canvas-bottomBar-choiceBtn-shuffleFocusTooltip{
	position: absolute;
	left: 40px;

	top: -19px;
	
	height: 21px;

	line-height: 1.9;
	// display: flex;
	// align-items: center;
	// justify-content: center;

	background: $tempb;
	background: rgba(255,255,255,0.5);

	border-radius: 3px;
	
	padding-left: 7px;
	padding-right: 7px;
	
	font-size: 12px;
	font-weight: $lato-semibold;
	color: $textMid;
	letter-spacing: 0.015em;

	pointer-events: none;

	overflow: hidden;
	white-space: nowrap;

	color: $white;
	background: $gray-5;

	opacity: 0;
	// transform: translateY(2px);
	// transition: all 50ms linear;
}

button.editor-canvas-bottomBar-choiceBtn:focus ~ .editor-canvas-bottomBar-choiceBtn-shuffleFocusTooltip{
	opacity: 1;
	// transform: translateY(0px);
	transition: opacity 50ms linear;
}

//
//

button.editor-canvas-bottomBar-resetButtonTemp{
	
	width: 100px;
	height: 30px;

	
	margin-left: 8px;
	border: 1px solid $lineMid;

	cursor: pointer;
	color: $textDark;
	background: rgba(0,0,40,0.1);
	border-radius: 4px;
	font-size: 14px;
	padding: 0px;

	opacity: 0.5;
}

button.editor-canvas-bottomBar-resetButtonTemp:hover{
	background: $red;
	border: 1px solid darken($red, 5%);
	color: $white;
	opacity: 1;
}

button.editor-canvas-bottomBar-sidePanelButtonTemp{
	background: none;
	border: none;
	margin-left: 8px;
	background: rgba(0,0,40,0.02);	
	height: 30px;
	padding-left: 8px;
	padding-right: 8px;
	color: $textMid;
	cursor: pointer;
}

//
//


//
// CENTER - TEMPLATE SELECTOR

.editor-canvas-bottomBar-center{
	width: $width-editor-bottomBarTemplateBtnGroup;
	display: flex;

	align-items: top;
	box-sizing: border-box;
	padding-top: 5px;
	justify-content: center;
}

.editor-canvas-bottomBar-templateBtnGroup{
	display: flex;
	height: 28px;
	padding: 2px;

	border: 1px solid rgb(225,224,231);
	border-bottom: 1px solid darken(rgb(225,224,231),3%);
	box-shadow: 0px 1px 2px 0px rgba(0,0,40,0.04);

	background: $white;
	border-radius: 5px;
}

button.editor-canvas-bottomBar-templateBtn{
	width: 64px;
	height: 28px;
	border: none;
	box-shadow: none;
	outline: none;
	background: none;

	border-radius: 3px;

	cursor: pointer;

	display: flex;
	align-items: center;
	justify-content: center;
}

.editor-canvas-bottomBar-templateBtnGroup--media
button.editor-canvas-bottomBar-templateBtn{
	width: 60px;
}

button.editor-canvas-bottomBar-templateBtn svg{
	width: 30px;
	height: 30px;
}

.editor-canvas-bottomBar-templateBtnGroup--media
button.editor-canvas-bottomBar-templateBtn svg{
	width: 32px;
	height: 32px;
}

button.editor-canvas-bottomBar-templateBtn--disabled{
	opacity: 0.5;
	pointer-events: none;
}


.editor-canvas-bottomBar-templateBtn-label{
	display: none; 
	// may add these later

	position: absolute;
	left: -10px;
	right: -10px;
	bottom: -17px;
	font-size: 11px;
	letter-spacing: 0.01em;
	font-weight: $lato-semibold;
	color: lighten($textMid, 5%);
	// background: $tempr;
}

.editor-canvas-bottomBar-templateBtnGroup:hover
.editor-canvas-bottomBar-templateBtn
.editor-canvas-bottomBar-templateBtn-label{
	opacity: 0.2;
}

.editor-canvas-bottomBar-templateBtnGroup:hover
.editor-canvas-bottomBar-templateBtn:hover
.editor-canvas-bottomBar-templateBtn-label{
	opacity: 1;
}


.editor-canvas-bottomBar-left{
	display: flex;
	align-items: top;

	justify-content: flex-start;
	box-sizing: border-box;
	padding-top: 5px;
	padding-left: 38px; // refactor out to align
}


button.editor-canvas-bottomBar-templateBtn--active{
	// background: transparentize($blue, 0.9);
	// color: $blue;
	background: $blue; // temp
	// background: $gray-4;
	color: $white;
}

button.editor-canvas-bottomBar-templateBtn--active svg{
	//fill: $blue;
	fill: $white;
}

button.editor-canvas-bottomBar-templateBtn--inactive svg{
	fill: rgb(185,185,193);
} 

button.editor-canvas-bottomBar-templateBtn--inactive:hover{
	//background: transparentize($blue, 0.97);
	background: transparentize($blue, 0.9);
}

button.editor-canvas-bottomBar-templateBtn--inactive:hover svg{
	// fill: transparentize($blue, 0.2);
	fill: $blue;
}

button.editor-canvas-bottomBar-templateBtn--active:hover{
	//background: transparentize($blue, 0.85);
	background: darken($blue, 5%);
}

@import 'Editor-CanvasBottomBar-TemplateTooltips';