//
// FEATURE MODAL
//

// FEATURE MODALS ARE COMPLEX INTERACTIVE MODALS
// THEY ARE USED FOR
//// PRINTING STUDENT REPORTS
//// PRINTING SETS
//// IMPORT QUESTIONS
//// MAYBE REFACTOR BULK ADD STUDENTS INTO THIS


// LAYOUT STUFF

@import 'FeatureModal-Layout'; // Layout
@import 'FeatureModal-Header'; // Header part with title
@import 'FeatureModal-OptionTabs'; // Section tabs for "Setup" and "Options"
@import 'FeatureModal-SelectControls'; // SelectControls
@import 'FeatureModal-PrimaryButton'; // PrimaryButton in featureModals
@import 'FeatureModal-CloseButton'; // CloseButton



///
///
/// REFACTOR THIS STUFF OUT
///

///

.featureModal button.show-all-students{
	transform: scale(1.3);
}

.featureModal--studentReport-preview-showAllStudentsContainer{
	width: calc(100% + 30px);
	height: 90px;
	padding-top: 0px;
	padding-bottom: 40px;
	display: flex;
	align-items: center;
	justify-content: center;
}

//
// CONFIG
//


.featureModal-configControls{
	width: 100%;

	padding-left: 50px;
	box-sizing: border-box;

}


/// PACK TYPE STUFF

.featureModal-configControls-controlSet--packSetup{	
	width: 100%;
}

// OPTION TABS
