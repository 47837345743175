//
// SCORE PILL
// Used everywhere - shows % score with appropriate score colors (either filled or as a dot)
//

.scorePill {
	color: $white;
	font-weight: $lato-bold;
	text-align: center;
}

.scorePill .scorePill-percentSign{
	opacity: 0.9;
	margin-left: 0.1em;
}

.scorePill.scorePill--small{
	width: 40px;
	height: 20px;
	border-radius: 2px;
	font-size: 14px;
	line-height: 1.4;
}

.scorePill.scorePill--mid{
	width: 44px;
	height: 22px;
	border-radius: 3px;
	font-size: 15px;
	line-height: 1.37;
}

.scorePill.scorePill--large{
	width: 66px;
	height: 33px;
	border-radius: 4px;
	font-size: 22.5px;
}

.scorePill.is--oneHundredPercent::first-letter{
	letter-spacing: -0.08em;
}

.scorePill.is--oneHundredPercent .scorePill-percentSign{
	margin-left: 0em;
}

.scorePill.is--survey{
	background: $blue;
	color: $white;
}

.scorePill.is--survey.scorePill--small{
	// width: 42px;
	width: 45px;
}

.scorePill.is--survey.scorePill--mid{
	// width: 54px;
	width: 60px;
	font-size: 14px;
	line-height: 1.5;
}

.scorePill.is--survey.scorePill--large{
	// width: 80px;
	width: 85px;
	font-size: 20px;
	line-height: 1.5;
}

.scorePill.is--absent{
	background: rgb(245,245,250);
	color: $textMid;
}

.scorePill.is--na{
	// background: rgb(245,245,250);
	color: $textLight;
}

// DOT TYPE

.scorePill.type--dot{
	color: $textBlack;
	padding-left: 10px;
}

// refactor for more sizes later
.scorePill.type--dot .scorePill-dot{
	height: 7px;
	width: 7px;
	border-radius: 4px;
	display: inline-block;
	margin-right: 5px;
	position: relative;
	top: -2px;
}

.scorePill.type--dot.is--survey{
	background: none;
} 

//
// COLOR ONLY – USED IN SCORESHEET

.scorePill.scorePill--mid.type--colorOnly{
	border-radius: 0px;
}

.scorePill.scorePill--mid.type--colorOnly span{
	display: none;
}