$richTextMarketingCoreWidth: 940px;

@import 'RichTextMarketing-PrereleasePopup';

@import 'RichTextMarketing-StickyNav';

.richTextMarketingModalContainer{
	position: fixed;

	top: 0;
	left: 0;
	right: 0;
	bottom: 0;

	background: rgba(0,0,2,0.8);

	display: flex;

	align-items: flex-start;
	justify-content: center;

	overflow-y: scroll;
	overflow-x: hidden;

	z-index: 99999;
	// background: red;
}

// .richTextMarketing{
// 	padding-bottom: 80px;
// }

.richTextMarketing--modalMode{
	min-width: 940px;
	width: calc(100vw - 80px);
	max-width: 1220px;
	background: $white;
	margin: 0 auto;
	margin-top: 20px;

	margin-bottom: 24px;

	overflow: hidden;
	border-radius: 10px;

	border: 1px solid $black;

	position: relative;
	z-index: 100;

}

.richTextMarketing-bgClick{
	position: fixed;
	top: 0px;
	left: 0px;
	right: 0px;
	bottom: 0px;
}

.richTextMarketing--pageMode{
	padding-bottom: 0px;
	width: 100%;
}

.richTextMarketing-closeBtn{
	position: absolute;
	width: 40px;
	height: 40px;
	top: 16px;
	right: 20px;
	cursor: pointer;
	border-radius: 10px;

	z-index: 1000;

	background: $white;
	background: $gray-7;
	// border: 1px solid $lineDark;
	// border-bottom: 1px solid darken($lineDark, 5%);
	// box-shadow: 0px 1px 2px 0px rgba(0,0,20,0.08);

	display: flex;
	align-items: center;
	justify-content: center;

}

.richTextMarketing-closeBtn:hover{
	// border: 1px solid darken($lineDark, 5%);
	// border-bottom: 1px solid darken($lineDark, 9%);
	// box-shadow: 0px 1px 2px 0px rgba(0,0,20,0.12);
}

.richTextMarketing-closeBtn .icon{
	width: 20px;
	height: 20px;
	fill: $gray-8;
	fill: $white;
}

.richTextMarketing-closeBtn:hover
.richTextMarketing-closeBtn .icon{
	fill: $gray-4;
	fill: $white;
}


//

.richTextMarketing-header{
	height: 300px;

	// border-bottom: 1px solid $lineMid;
	background: linear-gradient(rgb(248,248,254), white);
}

.richTextMarketing-header--notInitial{
	background: $white;
	height: 100px;
	margin-bottom: -20px;
	position: relative;
	z-index: 10;
}

.richTextMarketing-header--notInitial
.richTextMarketing-header-slideArray-small{
	display: none;
}

.richTextMarketing-header--notInitial
.richTextMarketing-header-slideArray-large{
	margin-top: -90px;
}

// SLIDE ARRAY

$smallSlideWidth-slideArray-richTextMarketing: 200px;
$smallSlideHeight-slideArray-richTextMarketing: calc(#{$smallSlideWidth-slideArray-richTextMarketing} / 1.6);

$largeSlideWidth-slideArray-richTextMarketing: 300px;
$largeSlideHeight-slideArray-richTextMarketing: calc(#{$largeSlideWidth-slideArray-richTextMarketing} / 1.6);

.richTextMarketing-header-slideArray{
	width: 100%;
	opacity: 0.7;
	// background: $tempr;

	overflow-x: hidden;
}

.richTextMarketing-header-slideArray-small{
	display: flex;
	margin-left: -64px;
	margin-top: -24px;

	margin-bottom: 8px;
}

.richTextMarketing-header-slideArray-small-slide,
.richTextMarketing-header-slideArray-large-slide{
	border: 1px solid $lineMid;
	border-bottom: 1px solid $lineDark;
	background: $white;

	overflow: hidden;

	box-shadow: 0px 2px 4px 0px rgba(0,0,20,0.02);
}

.richTextMarketing-header-slideArray-small-slide img,
.richTextMarketing-header-slideArray-large-slide img{
	width: 100%;
	height: 100%;
	object-fit: cover;
}


.richTextMarketing-header-slideArray-small-slide{
	width: $smallSlideWidth-slideArray-richTextMarketing;
	height: $smallSlideHeight-slideArray-richTextMarketing;
	flex-shrink: 0;

	margin-left: 8px;
}

.richTextMarketing-header-slideArray-large{
	display: flex;
	margin-left: -120px;
}

.richTextMarketing-header-slideArray-large-slide{
	width: $largeSlideWidth-slideArray-richTextMarketing;
	height: $largeSlideHeight-slideArray-richTextMarketing;

	flex-shrink: 0;
	margin-left: 8px;

	border-radius: 4px;
}

//

.richTextMarketing-header-text-h2{
	font-size: 24px;
	font-weight: $lato-bold;
	color: $textBlack;
	color: $textDark;

	letter-spacing: 0.008em;
	line-height: 1.45;

	margin-bottom: 46px;

	padding-right: 128px;
	box-sizing: border-box;

}

.richTextMarketing-header-text-h2 span{
	font-weight: $lato-heavy;
	color: $textBlack;

	//font-weight: $lato-heavy;

	// border-radius: 3px;

	// padding-top: 4px;
	// padding-bottom: 4px;
	// padding-left: 6px;
	// padding-right: 6px;

	// background: $highlight--4-bgColor;
	// color: $highlight--4-textColor;
}

.richTextMarketing-header-text-h1{
	font-size: 36px;
	font-weight: $lato-heavy;
	color: $textBlackDark;

	letter-spacing: 0.005em;

	margin-bottom: 10px;
}

//
//
//


.richTextMarketing-sectionContainer{
	width: 100%;
	height: 764px;

	// background: $tempr;
	border-bottom: 1px solid $lineMid;


	overflow: hidden;

	//background: rgb(252,252,255);
	position: relative;

}

.richTextMarketing-sectionContainer--mediaChoices,
.richTextMarketing-sectionContainer--math{
	background: rgb(250,250,255);
}

.richTextMarketing-sectionContainer--math{
	background: linear-gradient(white, rgb(249,249,255));
  height: 850px;
}

.richTextMarketing-sectionContainer--mediaChoices{
	height: 680px;
}

.richTextMarketing-sectionContainer--videos{
	// height: 874px;
	// height: 780px;
}

.richTextMarketing-sectionContainer--images{
	height: 800px;
	height: 750px;
	border-bottom: none;
	// background: $tempb;
}

.richTextMarketing-sectionContainer--shortcuts{
	height: 620px;
	height: 520px;
	// background: $tempr;
	border-bottom: none;
}

.richTextMarketing-section{
	width: $richTextMarketingCoreWidth;
	margin: 0 auto;
	cursor: default;
	user-select: none;
}

.richTextMarketing-section-label{
	margin-top: 72px;

	font-size: 14px;
	font-weight: $lato-heavy;
	color: $textBlue;
	letter-spacing: 0.05em;

	text-transform: uppercase;

}

.richTextMarketing-section-h1{
	width: 100%;

	margin-top: 36px;

	font-size: 36px;
	font-weight: $lato-heavy;
	color: $textBlack;
	letter-spacing: 0.005em;

	margin-bottom: 28px;

}


.richTextMarketing-section-videoTabArray{
	display: flex;
	align-items: center;
	justify-content: flex-start;

	margin-left: -2px;

	padding-top: 16px;
	padding-bottom: 16px;

}

.richTextMarketing-videoTab{
	display: flex;
	align-items: center;
	justify-content: center;

	padding-left: 14px;
	padding-right: 14px;

	height: 36px;

	font-size: 16px;

	font-weight: $lato-bold;

	border-radius: 4px;

	margin-left: 4px;
	margin-right: 4px;

	position: relative;

	user-select: none;
	cursor: pointer;

	overflow: hidden;

}

.richTextMarketing-videoTab-activeLabel{
	font-weight: $lato-bold;
	letter-spacing: 0.008em;
}

.richTextMarketing-videoTab-inactiveLabel{
	position: absolute;
	top: 0px;
	left: 0px;
	right: 0px;
	bottom: 0px;

	display: flex;
	align-items: center;
	justify-content: center;

	font-weight: $lato-semibold;
	letter-spacing: 0.008em;
}


.richTextMarketing-videoTab--active{
	background: $blue;
	color: $white;
}

.richTextMarketing-videoTab--active
.richTextMarketing-videoTab-activeLabel{
	visibility: visible;
}


.richTextMarketing-videoTab--active
.richTextMarketing-videoTab-activeLabel{
	visibility: hidden;
}



.richTextMarketing-videoTab--inactive
.richTextMarketing-videoTab-activeLabel{
	visibility: hidden;
}

.richTextMarketing-videoTab--inactive
.richTextMarketing-videoTab-inactiveLabel{
	visibility: visible;
}



.richTextMarketing-videoTab--inactive{
	font-weight: $lato-semibold;
	letter-spacing: 0.01em;

	background: lighten($gray-13, 3%);
	color: lighten($textBlack, 8%);
}

.richTextMarketing-sectionContainer--math
.richTextMarketing-videoTab--inactive{
	background: rgba(0,0,100,0.03);
}

.richTextMarketing-videoTab--inactive:hover{
	background: transparentize($blue, 0.92);
	color: $textBlack;

}



.richTextMarketing-videoTab-progressBar{
	position: absolute;

	bottom: 4px;
	left: 10px;
	right: 10px;

	height: 2px;

	overflow: hidden;
}

.richTextMarketing-videoTab-progressBar-bar{
	position: absolute;
	top: 0px;
	left: 0px;
	bottom: 0px;
	background: rgba(255,255,255,0.5);

	z-index: 5;
}

.richTextMarketing-videoTab-progressBar-track{


	position: absolute;
	top: 0px;
	left: 0px;
	bottom: 0px;
	right: 0px;
}


.richTextMarketing-section-videoDevice{
	width: 940px;
	height: 510px;

	margin: 0 auto;

	background: white;
	border: 1px solid lighten($lineDark, 5%);
	box-shadow: 0px 2px 4px 0px rgba(0,0,20,0.06), 0px 0px 4px 0px rgba(0,0,20,0.04);

	border-top-right-radius: 12px;
	border-top-left-radius: 12px;

	position: relative;
}

.richTextMarketing-section-videoDevice-videoContainer{
	position: absolute;
	top: 10px;
	left: 10px;
	right: 10px;
	bottom: 0px;

	border-radius: 8px;

	overflow: hidden;

	border: 1px solid $lineMid;
}



video.richTextMarketing-section-videoDevice-video{
	width: 100% !important;
	height: auto !important;
}

// MEDIA CHOICES
.richTextMarketing-section--mediaChoices{
	position: relative;
}

.richTextMarketing-mediaChoice-slide{
	width: 472px;
	height: calc(472px / 1.6);


	background: $white;
	border: 1px solid lighten($lineDark, 5%);
	border-bottom: 1px solid $lineDark;
	border-radius: 4px;

	box-shadow: 0px 2px 4px 0px rgba(0,0,20,0.08);

	overflow: hidden;

	position: absolute;
}

.richTextMarketing-mediaChoice-slide--1{
	left: -540px;
	top: -60px;
}

.richTextMarketing-mediaChoice-slide--2{
	left: 480px;
	top: -320px;
}

.richTextMarketing-mediaChoice-slide--3{
	left: 540px;
	top: 180px;
}

.richTextMarketing-mediaChoice-slide--4{
	left: 0px;
	top: 240px;
}

.richTextMarketing-mediaChoice-slide{
	transition: transform 200ms linear;
}

.richTextMarketing-mediaChoice-slide:hover{
	transform: scale(1.01);
	transition: transform 100ms linear;
}

.richTextMarketing-mediaChoice-slide img{
	width: 100%;
	height: 100%;
	object-fit: cover;
}

///
/// REMOTE CONTROL

.richTextMarketing-section--remoteControl .richTextMarketing-section-label,
.richTextMarketing-section--images .richTextMarketing-section-label{
	width: 100%;
	text-align: center;
}

.richTextMarketing-section--remoteControl .richTextMarketing-section-h1,
.richTextMarketing-section--images .richTextMarketing-section-h1,
.richTextMarketing-section--shortcuts .richTextMarketing-section-h1{
	width: 100%;
	text-align: center;
}

.richTextMarketing-section--remoteControl .richTextMarketing-section-videoTabArray,
.richTextMarketing-section--images .richTextMarketing-section-videoTabArray{
	justify-content: center;
}

.richTextMarketing-section--images .richTextMarketing-section-videoTabArray{
	position: relative;
	z-index: 10;
}

.richTextMarketing-section-remoteControlVideoContainer{
	width: 1000px;
	height: 480px;

	background: $tempb;
}

.richTextMarketing-section-imageVideoContainer{
	width: 1100px;
	height: 560px;

	margin-left: calc((1100px - #{$richTextMarketingCoreWidth}) / -2);

	// background: $tempb;
	margin-top: -20px;
}

.richTextMarketing-sectionContainer--images
.richTextMarketing-section-h1{
	margin-bottom: 10px;
}

.richTextMarketing-sectionContainer--images
.richTextMarketing-section-videoTabArray{
	padding-bottom: 0px;
}

///
/// SHORTCUTS

.richTextMarketing-section--shortcut-topContainer{
	width: 100%;
	// background: $tempr;

	display: flex;
	margin-top: 56px;
	border-bottom: 1px solid $lineDark;
}



.richTextMarketing-section--shortcut-para{
	width: 50%;
	flex-grow: 0;
	flex-shrink: 0;

	// border: 1px solid red;

	box-sizing: border-box;
	padding-right: 160px;
}

.richTextMarketing-section--shortcut-topRightContainer{
	width: 50%;
	flex-grow: 0;
	flex-shrink: 0;
}



.richTextMarketing-section--shortcut-para-title{
	font-size: 18px;
	font-weight: $lato-bold;
	color: $textBlack;
	letter-spacing: 0.008em;

	margin-bottom: 10px;
}

.richTextMarketing-section--shortcut-para-text{
	font-size: 16.5px;
	font-weight: $lato-medium;
	letter-spacing: 0.008em;
	line-height: 1.3;
	color: $textBlack;
}


.richTextMarketing-section--shortcut-para p{
	margin-block-start: 0px;
	margin-block-end: 14px;
}


.richTextMarketing-section-shortcutBottom{
	display: flex;
	width: 100%;

	padding-top: 48px;
	padding-bottom: 48px;
	border-bottom: 1px solid $lineMid;
}

.richTextMarketing-section--shortcut-videoContainerContainer{
	width: 50%;
	flex-grow: 0;
	flex-shrink: 0;

	display: flex;
	align-items: top;
	justify-content: flex-start;
}

.richTextMarketing-section--shortcut-videoContainer{
	width: 100%;

	position: relative;
	top: -20px;
	left: -60px;

}


video.richTextMarketing-section--shortcut-video{
	width: 100% !important;
	height: auto !important;
	background: $white;
}


///
/// LIST COLUMN

.richTextMarketing-listContainer{
	width: 100%;
	padding-bottom: 64px;
}

.richTextMarketing-section-listColumnContainer{
	display: flex;
	margin-top: 12px;
}

.richTextMarketing-listContainer
.richTextMarketing-section-label{
	margin-top: 24px;
}

.richTextMarketing-section-listColumn{
	width: 50%;
	flex-grow: 0;
	flex-shrink: 0;
}

.richTextMarketing-section-listColumn ul{
	margin-block-start: 20px;
	margin-block-end: 40px;
	margin-inline-start: 0px;
	padding-inline-start: 40px;

	margin-left: -40px;

	font-size: 18px;
	font-weight: $lato-bold;
	letter-spacing: 0.008em;
	color: $textBlack;

	  list-style: none; /* Remove default bullets */

}


.richTextMarketing-section-listColumn li {
	margin-bottom: 20px;
}

.richTextMarketing-section-listColumn li span {
	border-radius: 5px;
	padding-left: 16px;
	padding-right: 16px;
	margin-left: 2px;
	margin-right: 2px;

	color: $textDark;

	background: $white;
	border: 1px solid lighten($lineDark, 5%);
	border-bottom: 2px solid $lineDark;

	box-shadow: 0px 1px 2px 0px rgba(0,0,20,0.08);
}

.richTextMarketing-bottomAreaContainer{
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;

	// background: $black;

}

.richTextMarketing-bottomArea{
	padding-bottom: 36px;
	width: $richTextMarketingCoreWidth;

	display: flex;
	flex-direction: column;

	align-items: flex-start;
	justify-content: center;

}

button.richTextMarketing-bottomArea-doneBtn{
	margin: 0 auto;
	margin-bottom: 18px;

	width: 560px;
	height: 82px;

	color: $white;
	font-weight: $lato-heavy;
	font-size: 28px;
	letter-spacing: 0.015em;

	border-radius: 7px;

	display: flex;
	align-items: center;
	justify-content: center;

	border: 1px solid darken($blue, 5%);
	border-bottom: 1px solid darken($blue, 8%);
	background: $blue;
	box-shadow: 0px 2px 4px 0px rgba(0,0,20,0.08);


	cursor: pointer;
}

button.richTextMarketing-bottomArea-doneBtn:hover{
	background: darken($blue, 2%);

	border: 1px solid darken($blue, 8%);
	border-bottom: 1px solid darken($blue, 12%);
	box-shadow: 0px 2px 4px 0px rgba(0,0,20,0.12);
}

.richTextMarketing-bottomArea-text{
	font-size: 15px;
	font-weight: $lato-semibold;
	color: $white;
	color: black;

	margin-bottom: 24px;
	letter-spacing: 0.02em;

	width: 100%;
	text-align: center;
	cursor: default;

	opacity: 0.8;
}

.richTextMarketing-bottomArea-text a{
	color: $blue;
}

.richTextMarketing-header-slideArray--2
.richTextMarketing-header-slideArray-small{
	margin-top: 0px;
}
// .richTextMarketing-section-listColumn ul li::before {
//   content: "\2022";  /* Add content: \2022 is the CSS Code/unicode for a bullet */
//   color: $gray-12; /* Change the color */
//   //font-weight: bold; /* If you want it to be bold */
//   display: inline-block; /* Needed to add space between the bullet and the text */
//   width: 20px; /* Also needed for space (tweak if needed) */
//   margin-left: -20px; /* Also needed for space (tweak if needed) */
// }

.richTextMarketing-header-slideArray--2{
	padding-bottom: 24px;
}

/// NEW FEATURES

.richTextMarketing-repeatLoadHeader{
	width: $richTextMarketingCoreWidth;
	margin: 0 auto;
	margin-top: 96px;


	font-size: 14px;
	font-weight: $lato-heavy;
	color: $textBlue;
	letter-spacing: 0.05em;

	text-transform: uppercase;


}

.richTextMarketing-repeatLoad-newSection{
	width: $richTextMarketingCoreWidth;
	margin: 0 auto;

	margin-top: 24px;

	padding-bottom: 24px;
	border-bottom: 1px solid $lineDark;
	margin-bottom: 24px;

	cursor: default;

}


.richTextMarketing-repeatLoad-newSection-date{
	font-size: 15px;
	font-weight: $lato-medium;
	color: $textMid;
}

.richTextMarketing-repeatLoad-newSection-header{
	margin-top: 4px;
	font-size: 24px;
	font-weight: $lato-heavy;
	color: $textBlack;
}

.richTextMarketing-repeatLoad-newSection-featureContainer{
	margin-top: 24px;

	width: 100%;
	display: flex;
	flex-wrap: wrap;
}

.richTextMarketing-repeatLoad-newSection-newFeature{
	width: 40%;
	margin-right: 10%;
	padding-bottom: 24px;
}

.richTextMarketing-repeatLoad-newSection-newFeature-header{
	font-size: 17px;
	font-weight: $lato-bold;
	color: $textBlack;
	letter-spacing: 0.01em;
	margin-bottom: 4px;
}

.richTextMarketing-repeatLoad-newSection-newFeature-description{
	font-size: 14px;
	font-weight: $lato-medium;
	color: $textBlack;
	letter-spacing: 0.01em;
}
