//
// SLIDE GRID
// Grid of slides used in Set Detail and Set Report
// Note slide sizes are set directly as a prop on the slide
//

.slideGrid{	
	width: 100%;
	margin-top: 12px;	
	box-sizing: border-box;
	display: flex;
	flex-wrap: wrap;
  justify-content: start;
}

.slideGrid-gridItem{	  
	display: flex;   
  box-sizing: border-box;

	border-radius: 4px;

	position: relative;
}

.slideGrid-gridItem:hover{
	background: rgb(249,249,255);
}

.slideGrid-gridItem-slide{	
	width: 100%;
	height: 100%;

	background: white;
	border: 1px solid $lineDark;
	border-radius: 2px;

	box-shadow: 0px 2px 4px -2px rgba(0,0,0,0.12);

	display: flex;
	align-items: center;
	justify-content: center;

	position: relative;

	overflow: hidden;
	cursor: pointer;
}

.slideGrid-gridItem-slide .slide.slide--static{
	position: absolute;
	top: 0px;
	left: 0px;
	border: 1px solid $invisible;
	pointer-events: none;
}

.slideGrid-gridItem:hover .slideGrid-gridItem-slide{
	border: 1px solid $gray-12;
}

.slideGrid-gridItem .questionCountBadge{
	position: absolute;
	top: 14px;
	right: 14px;
	z-index: 10;	
}

.slideGrid-gridItem:hover .questionCountBadge{
	opacity: 0;	
}

.slideGrid-gridItem .scorePill{
	position: absolute;
	top: 14px;
	right: 14px;
}

.slideGrid-gridItem-activeQuestion,
.slideGrid-gridItem-upNext{
	position: absolute;
	bottom: 16px;
	right: 14px;
	z-index: 10;
	font-size: 12px;
	font-weight: $lato-bold;
	border-radius: 3px;
	letter-spacing: 0.01em;

	display: flex;
	align-items: center;
	justify-content: center;

	padding-right: 6px;
	padding-left: 6px;

	color: $white;
	height: 22px;
	text-transform: uppercase;
	
	opacity: 0.95;
}

.slideGrid-gridItem-activeQuestion{
	background: $blue;
}

.slideGrid-gridItem-upNext{
	background: $gray-8;
}

//
// EXPAND TO ZOOM

.slideGrid-gridItem-expandLabel{
	height: 26px;
	width: 26px;	

	position: absolute;
	top: 14px;
	right: 14px;

	z-index: 20;

	display: flex;
	align-items: center;
	justify-content: center;

	background: $white;
	border-radius: 3px;
	
	user-select: none;
	cursor: pointer;
}

.slideGrid-gridItem-expandLabel .icon{
	fill: $blue;
	width: 22px;
	height: 22px;
}

// INTERACTIONS: OPACITY MANAGEMENT

.slideGrid-gridItem 
.slideGrid-gridItem-expandLabel{
	opacity: 0;
	transition: 200ms all linear;
}

.slideGrid-gridItem:hover 
.slideGrid-gridItem-expandLabel{
	opacity: 0.85;
	transition: 100ms all linear;
}