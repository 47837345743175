// Microsoft Privacy Policy link and Youtube Terms of Service link
a.thirdPartyAttributionLink {
  color: $textLight;

  text-align: center;
  opacity: 0.8;
  background: none;

  font-size: 12px;
  letter-spacing: 0.025em;

  font-weight: $lato-medium;

  display: flex;
  align-items: center;
  justify-content: center;

  user-select: none;
}

a.thirdPartyAttributionLink:hover {
  opacity: 1;
  color: darken($textBlue, 5%);
  text-decoration: underline !important;
}
