//
// TOOLTIPS
// Tooltips, see e.g. section home page in top-right (Note: in some places e.g. new set editor we use custom styling)
// I think this code was originally copied from somewhere
//

$length-tooltipOffset: 10px;

// Base styles for the element that has a tooltip
[data-tooltip] {
  position: relative;
}

// Base styles for the entire tooltip
[data-tooltip]:before,
[data-tooltip]:after {
  position: absolute;
  visibility: hidden;  
  opacity: 0;
  // visibility: visible;
  // opacity: 1;
  pointer-events: none;
  transition: opacity 200ms ease-in-out;
}

// Show the entire tooltip on hover and focus
[data-tooltip]:hover:before,
[data-tooltip]:hover:after {
  visibility: visible;
  opacity: 1;
  transition: opacity 200ms ease-in-out;
  transition-delay: 200ms;
}

// Base styles for the tooltip's directional arrow
[data-tooltip]:before {
  z-index: 1000;
  color: $gray-3;
  display: inline-block;
  transform: scaleX(1.2) scaleY(0.6);
  filter: drop-shadow(0px 2px 4px rgba(0,0,0,0.24));
}

// Base styles for the tooltip's content area
[data-tooltip]:after {
  z-index: 1000;
  height: 24px;
  box-sizing: border-box;
  text-align: center;
  padding-left: 6px; padding-right: 6px;
  min-width: 24px;
  white-space: nowrap;
  max-width:none;
  border-radius: 3px;
  background-color: $gray-2;
  color: $white;
  content: attr(data-tooltip);
  font-size: 12.5px;
  letter-spacing: 0.02em;
  font-weight: $lato-semibold;
  line-height: 1.75;
  filter: drop-shadow(0px 2px 4px rgba(0,0,0,0.24));
}

// TEXTBOX

//Horizontal Position
.tooltip--above:after,
.tooltip:after {
  left: 50%;
  transform: translateX(-50%);
}

// Edge Support
.tooltip--rightEdge.tooltip--above:after,
.tooltip--rightEdge.tooltip:after {
  left: 0%;
  margin-left: 9px;
  transform: translateX(0%);
}

.tooltip--leftEdge.tooltip--above:after,
.tooltip--leftEdge.tooltip:after {
  transform: translateX(0%);
  margin-left: -12px;
}

// Vertical Position
.tooltip--above:after {
  bottom: 100%;
  margin-bottom: $length-tooltipOffset;
}

.tooltip:after {
  top: 100%;
  margin-top: $length-tooltipOffset;
}

// 
// ARROW 
// 
.tooltip--above:before {
  content: "▼";
}

.tooltip:before {
  content: "▲";
}

// Horizontal Position
[data-tooltip]:before {
  bottom: 100%;
  left: 50%;
  margin-left: -7.5px;
}

// Vertical Position
.tooltip--above:before {
  bottom: 100%;
  margin-bottom: calc(-12px + #{$length-tooltipOffset});
}
.tooltip:before {
  margin-top: calc(-8.5px + #{$length-tooltipOffset});
  line-height: 1.8;
  top: 100%;
}



// LABEL

.tooltip--label.tooltip--above:before,
.tooltip--label.tooltip:before {
  display: none;
}

.tooltip--label.tooltip--above:after,
.tooltip--label.tooltip:after {
  // filter: drop-shadow(0px 1px 2px rgba(0,0,0,0.12));
  //filter: drop-shadow(0px 1px 2px rgba(0,0,0,0));
  // background-color: $gray-2;
  // color: $white;
  // border: 1px solid $gray-2;
  // font-weight: $lato-bold;
  background-color: $white;
  color: $textBlack;
  border: 1px solid $lineDark;
  box-sizing: border-box;
  height: 20px;
  line-height: 1.5;
  letter-spacing: 0em;
}

.tooltip--label.tooltip:after {
  margin-top: 2px;
  margin-top: 5px;
}

.tooltip--label.tooltip--above.tooltip:after {
  margin-bottom: 2px;
  margin-top: 0px;
}


.tooltip--label.tooltip--above:hover:after,
.tooltip--label.tooltip--above:focus:after{
  visibility: visible;
  opacity: 1;
  transition: opacity 0ms;
  transition-delay: 0ms;
}

.tooltip--label.tooltip--above:after
.tooltip--label.tooltip--below:after {  
  transition: none;
}

// CUSTOM
.gridColumnToggle
.tooltip--label.tooltip--above.tooltip:after {
  margin-top: -50px;
}
