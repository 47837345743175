.joinCreatorProgramModal-visualContainer{
	flex-grow: 1;
	flex-shrink: 1;
	height: 100%;
	min-width: 10px;
	background: #0E3563; // temp
}

@keyframes movemoon {
  0%   {
    transform: translate(400%, -100%) scale(.7);
  }
  25%   {
    transform: translate(400%, 150vh) scale(.7);
  }
  25.001% {
    transform: translate(500%, -100%) scale(.4);
  }
  50% {
    transform: translate(500%, 85vh) scale(.4);
  }
  50.001% {
    transform: translate(350%, -100%) scale(.6);
  }
  75% {
    transform: translate(550%, 130vh) scale(.3);
  }
  75.001% {
    transform: translate(100%, -100%) scale(.5);
  }
  100% {
    transform: translate(100%, 110vh) scale(.5);
  }
}

@keyframes moveearth {
  0%   {
    transform: translate(220%, -100%) scale(.7);
  }
  25%   {
    transform: translate(150%, 150vh) scale(.7);
  }
  25.001% {
    transform: translate(100%, -100%) scale(.7);
  }
  50% {
    transform: translate(350%, 85vh) scale(.4);
  }
  50.001% {
    transform: translate(350%, -100%) scale(.6);
  }
  75% {
    transform: translate(350%, 130vh) scale(.6);
  }
  75.001% {
    transform: translate(500%, -100%) scale(.5);
  }
  100% {
    transform: translate(500%, 110vh) scale(.5);
  }
}

@keyframes movemars {
  0%   {
    transform: translate(350%, -100%) scale(.7);
  }
  25%   {
    transform: translate(150%, 150vh) scale(.7);
  }
  25.001% {
    transform: translate(470%, -100%) scale(.7);
  }
  50% {
    transform: translate(450%, 85vh) scale(.4);
  }
  50.001% {
    transform: translate(450%, -100%) scale(.6);
  }
  75% {
    transform: translate(500%, 130vh) scale(.6);
  }
  75.001% {
    transform: translate(100%, -100%) scale(.5);
  }
  100% {
    transform: translate(100%, 110vh) scale(.5);
  }
}

.moon {
  transform: translate(400%, -100%) scale(.7);
  left: 0;
  top: 0;
  width: 230px;
  height: 230px;
  background-color: #ecf0f1;
  border-bottom: 20px solid rgba(0, 0, 0, .05);
  border-right: 25px solid rgba(0, 0, 0, .05);
  border-radius: 50%;
  position: absolute;
  z-index: 1;
}

.earth {
  transform: translate(220%, -100%) scale(.7);
  border-radius: 100%;
  background-color: #319bf5;
  border-bottom: 20px solid rgba(0, 0, 0, .05);
  border-right: 25px solid rgba(0, 0, 0, .05);
  left: 0;
  top: 0;
  width: 230px;
  position: absolute;
  height: 230px;
  overflow:hidden;
}

 .earth:before {
  background-color: #39af5d;
  border-radius: 100% 70%;
  content: '';
  height: 68%;
  transform: rotate(-40deg);
  left: 0%;
  border-top: 4px solid rgba(0, 0, 0, .02);
  border-left: 4px solid rgba(0, 0, 0, .02);
  position: absolute;
  top: 30%;
  width: 35%;
}


.earth:after {
  background-color: #39af5d;
  border-radius: 100% 70%;
  content: '';
  border-top: 4px solid rgba(0, 0, 0, .02);
  border-left: 4px solid rgba(0, 0, 0, .02);
  position: absolute;
  height: 22%;
  left: 55%;
  top: 10%;
  width: 20%;
}

.mars {
  transform: translate(350%, -100%) scale(.7);
  background-color: #ef5737;
  border-bottom: 20px solid rgba(0, 0, 0, .05);
  border-right: 25px solid rgba(0, 0, 0, .05);
  width: 230px;
  border-radius: 100%;
  position: absolute;
  height: 230px;
  top: 0;
  left: 0;
}

.mars:before {
  background-color: #c25412;
  border-radius: 100%;
  content: '';
  height: 20%;
  transform: rotate(-40deg);
  left: 20%;
  border-top: 4px solid rgba(0, 0, 0, .05);
  border-left: 4px solid rgba(0, 0, 0, .05);
  position: absolute;
  top: 60%;
  width: 20%;    
}
 
.mars:after {
  background-color: #c25412;
  border-radius: 100%;
  content: '';
  position: absolute;
  border-top: 4px solid rgba(0, 0, 0, .05);
  border-left: 4px solid rgba(0, 0, 0, .05);
  height: 30%;
  left: 50%;
  top: 15%;
  width: 30%;
}

.crater {
  position: absolute;
  background-color: #babcc1;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border-top: 4px solid rgba(0, 0, 0, .05);
  border-left: 4px solid rgba(0, 0, 0, .05);
  margin-top: 20px;
  margin-left: 60px;
}

.crater-2 {
  position: absolute;
  background-color: #babcc1;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border-top: 4px solid rgba(0, 0, 0, .05);
  border-left: 4px solid rgba(0, 0, 0, .05);
  margin-top: 140px;
  margin-left: 30px;
}

.crater-3 {
  position: absolute;
  background-color: #babcc1;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border-top: 4px solid rgba(0, 0, 0, .05);
  border-left: 4px solid rgba(0, 0, 0, .05);
  margin-top: 170px;
  margin-left: 170px;
}

@keyframes twinkle_one {
  0%   { opacity: .5; }
  30%  { opacity: .8; }
  70%  { opacity: .6; }
  100% { opacity: .9; }
}

@keyframes twinkle_two {
  0%   { opacity: .7; }
  25%  { opacity: .4; }
  80%  { opacity: .6; }
  100% { opacity: .9; }
}

@keyframes twinkle_three {
  0%   { opacity: .8; }
  30%  { opacity: .4; }
  40%  { opacity: .6; }
  100% { opacity: .2; }
}

@keyframes movestars1 {
  0%   {
    transform: translateY(0%);
  }
  50%{
    transform: translateY(100%);
  }
  50.001%{
    transform: translateY(-100%);
  }
  100%   {
    transform: translateY(0%);
  }
}

@keyframes movestars2 {
  0%   {
    transform: translateY(-100%);
  }
  100%   {
    transform: translateY(100%);
  }
}

.galaxy{
  height: 200%;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
}

.galaxy .field{
  position: absolute;
  left: 0;
  height: 50%;
  width: 100%;
  top: 0;
}

.galaxy .field .distance-layer1{
  transform: translateY(-100%);
}

.star {
  position: absolute;
  background-color: #fff;
  z-index: 0;
}

.distance-layer{
  position: absolute;
  left: 0;
  height: 100%;
  width: 100%;
  top: 0;
}

.distance-layer2 .star{
  width: 2px;
  height: 2px;
  animation: twinkle_one 3s ease-in-out infinite;
}

.distance-layer1 .star{
  width: 2px;
  height: 2px;
  border-radius: 2px;
  box-shadow: 0 0 1px 1px #fff;
  animation: twinkle_two 8s ease-in-out infinite;  
}

.distance-layer0 .star{
  width: 3px;
  height: 3px;
  border-radius: 3px;
  box-shadow: 0 0 1px 1px #fff;
  animation: twinkle_two 6s ease-in-out infinite;  
}

.fly .rocket{
	transform: translate(-50%, -20%);
}

.fly .rocket .exhaust-flame{
  height: 130px;    
}

.fly .exhaust-fumes{
  transform: translate(-50%, 200%);
}

.fly .moon{
  animation: movemoon 27s linear 2s infinite;
}

.fly .earth{
  animation: moveearth 26s linear 8s infinite;
}

.fly .mars{
  animation: movemars 25s linear 10s infinite;
}

.fly .field0 .distance-layer0{
	animation: movestars1 3s linear infinite;
}

.fly .field0 .distance-layer1{
  animation: movestars2 3s linear infinite;
}
  
.fly .field1 .distance-layer0{
  animation: movestars1 6s linear infinite;
}

.fly .field1 .distance-layer1{
  animation: movestars2 6s linear infinite;
}
  
.fly .field2 .distance-layer0{
  animation: movestars1 12s linear infinite;
}

.fly .field2 .distance-layer1{
  animation: movestars2 12s linear infinite;
}

.rocket-wrap {
	height: 100%;
	background: #1d517d;
	overflow: hidden;
	position: relative;
}

.rocket {
	z-index: 1;
	position: absolute;
	bottom: 150px;
	width: 80px;
	left: 50%;
	transform: translateX(-50%);
	transition: transform 1s ease;
}

.rocket .rocket-body {
  width: 80px;
  left: calc(50% - 50px);
  animation: bounce 2s infinite;
}

.rocket .rocket-body .body {
  background-color: #de5050;
  height: 190px;
  left: calc(50% - 50px);
  border-top-right-radius: 100%;
  border-top-left-radius: 100%;
  border-bottom-left-radius: 0%;
  border-bottom-right-radius: 0%;
}

.rocket .rocket-body .body:before {
  content: '';
  position: absolute;
  left: calc(50% - 28px);
  width: 56px;
  height: 13px;
  background-color: #554842;
  bottom: -13px;
  border-bottom-right-radius: 60%;
  border-bottom-left-radius: 60%;
}

.window {
  position: absolute;
  width: 40px;
  height: 40px;
  border-radius: 100%;
  background-color: #ddd;
  left: calc(50% - 25px);
  top: 40px;
  border: 5px solid #554842;
  overflow: hidden;
}

.window:before{
  content: '';
  position: absolute;
  left: 0%;
  top: 50%;
  width: 200%;
  height: 200%;
  transform: rotate(45deg);
  transform-origin: center;
  background: rgba(0, 0, 0, .05);
}

.fin {
  position: absolute;
  z-index: -100;
  height: 75px;
  width: 50px;
  background-color: #bb4545;
}

.fin-left {
  left: -30px;
  top: calc(100% - 55px);
  border-top-left-radius: 80%;
  border-bottom-right-radius: 100%;
}
.fin-right {
  right: -30px;
  top: calc(100% - 55px);
  border-top-right-radius: 80%;
  border-bottom-left-radius: 100%;
}

.exhaust-flame {
  position: absolute;
  top: 50%;
  width: 28px;
  height: 160px;
  left: 50%;
  transition: height 1s linear;
  transform: translateX(-50%);
  animation: exhaust .2s infinite;
}

.exhaust-fumes{
  opacity: .8;
  left: 50%;
  position: absolute;
  bottom: 140px;
  transition: transform 5s linear;
  transform: translateX(-50%);
  width: 80px;
  height: 200px;
  padding: 0;
  z-index: 1;
  display: block;
  li {
    width: 60px;
    height: 60px;
    background-color: #f5f5f5;
    list-style: none;
    position: absolute;
    border-radius: 100%;
    &:first-child {
      width: 200px;
      height: 200px;
      top: 300px;
      animation: fumes 5s infinite;
    }
    &:nth-child(2) {
      width: 150px;
      height: 150px;
      left: -120px;
      top: 300px;
      animation: fumes 3.2s infinite;
    }
    &:nth-child(3) {
      width: 120px;
      height: 120px;
      left: -40px;
      top: 330px;
      animation: fumes 3s 1s infinite;
    }
    &:nth-child(4) {
      width: 100px;
      height: 100px;
      left: -170px;
      animation: fumes 4s 2s infinite;
      top: 380px;
    }
    &:nth-child(5) {
      width: 130px;
      height: 130px;
      left: -120px;
      top: 350px;
      animation: fumes 5s infinite;
    }
    &:nth-child(6) {
      width: 200px;
      height: 200px;
      left: -60px;
      top: 280px;
      animation: fumes2 10s infinite;
    }
    &:nth-child(7) {
      width: 100px;
      height: 100px;
      left: -100px;
      top: 320px;
    }
    &:nth-child(8) {
      width: 110px;
      height: 110px;
      left: 70px;
      top: 340px;
    }
    &:nth-child(9) {
      width: 90px;
      height: 90px;
      left: 200px;
      top: 380px;
      animation: fumes 20s infinite;
    }
  }
}

@keyframes fumes {
  50% {
    transform: scale(1.5);
    background-color: transparent;
  }
  51% {
    transform: scale(.8);
  }
  100% {
    background-color: #f5f5f5;
    transform: scale(1);
  }
}

@keyframes bounce {
  0% {
    transform: translate(0, 0);
  }
  50% {
    transform: translate(0, -4px);
  }
  100% {
    transform: translate(0, 0);
  }
}

@keyframes exhaust {
  0% {
    background: linear-gradient(to top, transparent 10%, #f5f5f5 100%);
  }
  50% {
    background: linear-gradient(to top, transparent 0%, #f5f5f5 100%);
  }
  75% {
    background: linear-gradient(to top, transparent 10%, #f5f5f5 100%);
  }
}

@keyframes fumes2 {
  50% {
    transform: scale(1.1);
  }
}