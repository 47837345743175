//
// SET SCORES ARRAY
// Minature set of set scores, goes color on hover. Used in set cards in reports for e.g.
//

$totalWidth-setScoresArray-setScore: 12px;

.setScoresArray{
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	
	width: calc(15 * #{$totalWidth-setScoresArray-setScore});
}

.historyCardContainer .setScoresArray{
	margin-top: 15px;
}

.sectionHome-history-historyCard .setScoresArray{
	position: absolute;
	bottom: 12px;
	left: 0px;

	margin-top: 6px;
	transform: scale(0.8);
	transform-origin: top left;
	opacity: 0.5;
	transition: 100ms opacity ease-in-out 0s;
}

.sectionHome-history-historyCard:hover .setScoresArray{
	opacity: 0.75;
	transition: 220ms opacity ease-in-out;
}

// increase width when many many setpollpolls,
// supporting up to 80 setpollpolls
.setScoresArray.setScoresArray--over40{
	width: calc(20 * #{$totalWidth-setScoresArray-setScore});
}


.setScoresArray-setScore{
	background: $gray-115;

	width: 10px;
	height: 4px;
	margin-right: 2px;
	margin-bottom: 4px;
	
	border-radius: 1px;

	position: relative;
}

.setScoresArray-setScore--isUnasked{
	background: $gray-13;
}

//

.setScoresArray-setScore-scorePill--container{
	width: 100%;
	height: 100%;
}

.setScoresArray-setScore .scorePill,
.setScoresArray-setScore .scorePill.is--survey{
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	height: 4px;
	width: 10px;

	border-radius: 1px;
	font-size: 0.01px;
	color: $invisible;
}

.syncLiveView-player-summary:not(:hover) .setScoresArray-setScore-scorePill--container,
.sectionHome-history-historyCard .setScoresArray-setScore-scorePill--container,
.historyCardContainer:not(:hover) .setScoresArray-setScore-scorePill--container{
	opacity: 0;
	transition: 100ms opacity ease-in-out 0s !important;
}

.syncLiveView-player-summary:hover .setScoresArray-setScore-scorePill--container,
.sectionHome-history-historyCard:hover .setScoresArray-setScore-scorePill--container,
.historyCardContainer:hover .setScoresArray-setScore-scorePill--container{
	opacity: 1;
	transition: 220ms opacity ease-in-out;
}
