.cellRow-dragPreviewWrapper{
	position: fixed;
	bottom: 100px; // safari fix, needs to be away from text at fixed level, also elastic scrolling
	left: 0;
  z-index: -1;

	// for Dnd, for each cell there is a "drag preview"
	// for implementation reasons, this is always displayed
	// we hide it in the corner to get it out of the way until it's picked up
	// we hide it in the corner for now
}


.cellRow-dragPreviewContainer{
	// for offsets, we need to add a container

	padding-left: 11px;
	padding-top: 1px;
}


.cellRow-dragPreview{
 	
 	height: 27px;
  box-sizing: border-box;

  position: relative;
  
  padding-left: 9px; 
  padding-right: 8px;

  min-width: 160px;
  max-width: 235px;
  
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  
  border-radius: 3px;

  background-color: $blue;
  border-color: $blue-dark;
  color: $white;
  
  font-size: 14px;
  letter-spacing: 0.01em;
  font-weight: $lato-semibold;

  line-height: 2.0;
}


// SET

// .cellRow-dragPreview--set {
// //	padding-right: 40px; // make space for question count
// }

// .cellRow-dragPreview--set .questionCountBadge{
// 	position: absolute;
// 	right: 5px;
// 	top: 5px;
	
// }

// MULTI 

.cellRow-dragPreview--multi{
	font-weight: $lato-bold;	
}

// FOLDER


.cellRow-dragPreview--folder {
  padding-left: 33px; // make space for folder icon
  max-width: 202px;
}

.cellRow-dragPreview--folder .icon{
  position: absolute;
  left: 9px;
  top: 5px;
  width: 16px;
  height: 16px;
}

.cellRow-dragPreview--folder .icon #main,
.cellRow-dragPreview--folder .icon #top{
  fill: $white;
}


