
$width-repoImageSearch-modal: 800px;
$height-repoImageSearch-modal: 600px;

.repoDetailsImageSearchModal{

  .repoImageSearch{
    height: 100%;
    width: 100%;
  }

  $height-repoImageSearch-footer: 60px;

  // SEARCH INPUT

  .repoImageSearch-search{
    box-sizing: border-box;
    width: calc(100% - 64px);
    margin: 0 auto;
    position: relative;
    margin-top: 20px;
  }

  .repoImageSearch-search input {
    height: 40px;
    width: 100%;
    border: 1px solid $gray-10;
    border-radius: 4px;
    padding-left: 40px;
    padding-bottom: 4px;
    box-sizing: border-box;
    font-size: 20px;
    font-weight: $lato-bold;
    color: $textBlack;
    outline: none;
    pointer-events: default;
  }

  .repoImageSearch-search input:focus{
    border: 1px solid $blue;
  }

  .repoImageSearch-search ::-webkit-input-placeholder {
    color: $gray-115 !important;
    font-size: 20px;
    font-weight: $lato-bold;
  }

  // .repoImageSearch-search input:valid{
  //     border: 1px solid red;
  // }

  .repoImageSearch-search input:valid ~ .repoImageSearch-search-inputUI .repoImageSearch-search-searchBtn {
      // background: $blue;
  }

  .repoImageSearch-search input:valid ~ .repoImageSearch-search-inputUI .repoImageSearch-search-searchBtn .icon {
    // fill: $white;
    fill: $blue;
  }

  .repoImageSearch-search-inputUI{
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
  }

  .repoImageSearch-search-searchBtn{
    // background: $gray-15;
    width: 39px;
    height: 38px;
    margin-left: 1px;
    margin-top: 1px;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    position: absolute;
    left: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .repoImageSearch-search-searchBtn .icon{
    // fill: $blue;
    fill: $gray-115;
    width: 22px;
    height: 22px;
  }

  .repoImageSearch-search-hint{
    display: inline-block;
    font-size: 12.5px;
    font-weight: $lato-semibold;
    // width: 200px;
    // background: $tempr;
    color: $textMid;
    margin-top: -2px;
    line-height: 3.4;
    text-align: right;
    position: absolute;
    right: 16px;

    pointer-events: none;

    // top: 200px;
    // right: 200px;
    // // transform: scale(2);
    opacity: 1;
    transition: opacity 100ms;
  }

  .repoImageSearch-search input:invalid ~ .repoImageSearch-search-inputUI .repoImageSearch-search-hint {
    opacity: 0;
    transition: opacity 100ms;
  }

  .repoImageSearch-search-hint-keyboard{
    display: inline-block;
    width: 58px;
    height: 22px;
    border: 1px solid $gray-115;
    border-radius: 3px;
    vertical-align: middle;
    margin-top: -2px;
    line-height: 1.5;
    text-align: left;
    box-sizing: border-box;
    padding-left: 5px;
    margin-left: 2px;
    margin-right: 2px;
    background: $white;
  }

  .repoImageSearch-search-hint-keyboard .icon{
    fill: $blue;
    width: 10px;
    height: 10px;
    margin-left: 1.5px;
    position: relative;
    top: 1px;
  }

  // BODY


  .repoImageSearch-body{
    // width: calc(#{$width-repoImageSearch-modal} - 64px);
    width: 100%;
    box-sizing: border-box;
    margin: 0 auto;
    // margin-top: 12px;
    min-height: calc(#{$height-repoImageSearch-modal} - 166px);
    height: calc(#{$height-repoImageSearch-modal} - 166px);
    overflow-y: scroll;
    overflow-x: hidden;
    padding-bottom: 30px;
  }

  .repoImageSearch-body.is--empty{
    overflow: hidden;
    position: relative;
  }

  .repoImageSearch-body-emptyBG {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .repoImageSearch-body-emptyBG-bingAttributionContainer{
    position: absolute;
    bottom: 40px;
  }

  .repoImageSearch-body-noResults{
    position: absolute;
    width: 100%;
    text-align: center;
    top: 50%;
    margin-top: -100px;
    font-weight: $lato-bold;
    color: $textMid;
    font-size: 16px;
  }

  .repoImageSearch-imageGrid{
    width: 100%;
    padding-left: 32px;
    padding-right: 32px;
    box-sizing: border-box;
  }

  .repoImageSearch-imageGrid:hover .tile {
    opacity: 0.85;
  }

  .repoImageSearch-imageGrid .tile:hover {
    opacity: 1;
  }

  .repoImageSearch-imageGrid #Gallery{
    padding-top: 12px;
    box-sizing: border-box;
  }

  // Clearfix to position loadmore button under results
  .repoImageSearch-imageGrid:after{
    content: "";
    display: table;
    clear: both;
  }


  .repoImageSearch-imageGrid-loadMore{
    width: 100%;
    display: flex;
    height: 45px;
    align-items: center;
    justify-content: center;
  }


  .repoImageSearch-imageGrid-loadMore .btn.show-more-results{
    width: 175px;
  }

  //
  // FOOTER
  //

  .repoImageSearch-footer {
    height: $height-repoImageSearch-footer;
    width: calc(100% - 64px);
    margin: 0 auto;
    // background: $tempb;
    display: flex;
    justify-content: center;
    padding-top: 9px;
    box-sizing: border-box;
  }


  .repoImageSearch-footer.has--images{
    border-top: 1px solid $gray-115;
  }

  .repoImageSearch-footer.has--images .repoImageSearch-footer-upload{
    display: none;
  }

  .repoImageSearch-footer.has--noImages .repoImageSearch-footer-bingAttributionContainer{
    display: none;
  }

  .repoImageSearch-footer-uploadBtn-hiddenInput{
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
  }

  // THIS ISN'T REALLY A BUTTON SO HAVE TO FORCE IN STYLES
  .repoImageSearch-footer-uploadBtn{
    width: 296px;
    display: block;
    cursor: default;
    text-align: center;
    user-select: none;
    border-radius: 4px;
    height: 36px;
    padding-left: 10px;
    padding-right: 10px;
    font-size: 16px;
    font-weight: $lato-semibold;
    line-height: 2.2;
    color: $textMid;
    border: 1px solid $gray-1225;
    background: linear-gradient(to bottom, rgb(255,255,255) 0%, rgb(251,251,251) 100%);
  }

  .repoImageSearch-footer-uploadBtn:hover{
    border: 1px solid $gray-115;
    background: $white;
    box-shadow: 0px 1px 1px 0px rgba(0,0,0,0.04);
  }

  .repoImageSearch-footer-uploadBtn:active{
    border: 1px solid $gray-10;
    background-color: $gray-15;
  }

  .btn.insert-gif,
  .btn.insert-image,
  .equationEditor-footer-submitBtn {
    min-width: 296px;
    width: 296px;
  }

}

  ///

  //ERRORS

  .cloudinaryError-modalContainer .modal-content{
    width: 700px;
    height: 540px;

    box-sizing: border-box;
  }

  .cloudinaryError-modalContent{
    padding: 20px;
    padding-left: 80px;
    padding-right: 80px;
  }

  .cloudinaryError-modalContent-header{
    font-size: 16px;
    font-weight: $lato-bold;
    color: $textBlack;
    margin-bottom: 12px;
  }

  .cloudinaryError-modalContent-messageContents{
    margin-top: 20px;
    padding: 16px;
    background: $white;
    border: 1px solid $lineMid;
    border-radius: 5px;
    margin-bottom: 20px;
  }

  .cloudinaryError-modalContent button{
    width: 200px;
    margin: 0 auto;
    margin-top: 24px;
  }
