
//
// STUDENT REPORT HEADER
//

.printPack--setHandout-setHandoutHeader{
	position: absolute;
	top: $printPack-pageMargin--top;
	left: $printPack-pageMargin--sideInner;
	right: $printPack-pageMargin--sideInner;
	
	height: $printPack-heightPackHeader;

  box-sizing: border-box;
}


.printPack--setHandout-setHandoutHeader-setName{
	font-size: 20pt;
	font-weight: $lato-heavy;
	color: $textBlackDark;

	width: 100%;

	white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

}

.printPack--setHandout-setHandoutHeader-instructionalText{
	margin-top: 8px;
	width: 100%;

	font-size: 12pt;
	font-weight: $lato-medium;
	color: $textDark;

	white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}


.printPack--setHandout-setHandoutHeader-additionalLabels{
	margin-top: 8px;

	font-size: 9.5pt;
	font-weight: $lato-medium;
	color: $textLight;

	width: 100%;

	white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}