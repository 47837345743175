// contained in 
.syncLiveViewContainer{
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 2500;
	background: rgb(249,249,253);

	overflow-y: scroll;
}

.syncLiveView{
	width: 100%;
	height: 100%;
}

$syncLiveView-leftWidth: 275px;
$syncLiveView-rightDefaultWidth: 275px;
$syncLiveView-progressBarHeight: 40px;

@media only screen and (max-width : 1160px) {
	.syncLiveView-player-summary{
		display: none;
	}
	.syncLiveView-toolbar-left{
		display: none;
	}
	.syncLiveView-toolbar-center-setCounter{
		display: none !important; //sorry
	}
	.syncLiveView-toolbar-narrowScreenSummary{
		display: flex;
	}
}

.syncLiveView--over47Students{

	.syncLiveView-player-summary{
		display: none;
	}
	.syncLiveView-toolbar-left{
		display: none;
	}
	.syncLiveView-toolbar-narrowScreenSummary{
		display: flex;
	}
}




.syncLiveView-player{
	width: 100%;
	max-width: 1500px;
	margin: 0 auto;
	height: calc(100vh - 48px);
	max-height: 800px;
	max-height: 600px;
	//min-height: 350px;

	display: flex;

	box-sizing: border-box;
	padding-left: 12px;
	padding-right: 12px;

	position: relative;
}

.syncLiveView-player-main{	
	height: 100%;

	flex-grow: 1;
	flex-shrink: 1;
	width: 10px;

	box-sizing: border-box;

	padding-left: 12px;
	padding-right: 12px;

	display: flex;
	flex-direction: column;

	position: relative;
	z-index: 5;
}

.syncLiveView-player-main:hover{
	z-index: 100; // make progress bar and next slide preview top
}

.syncLiveView-player-main-slideContainer{
	width: 100%;
	flex-grow: 1;
	flex-shrink: 1;

	position: relative;

	// background: $tempb;
}

.syncLiveView-player-main-slideContainer-progressBarContainer{
	position: absolute;	
	// top position calced in JS
	height: $syncLiveView-progressBarHeight;
	left: 50%;
	transform: translateX(-50%);

	display: flex;
	align-items: center;
	justify-content: center;
}

.syncLiveView-player-main-slide{	
	background: $white;
	border: 1px solid $lineDark;
	border-radius: 3px;
	box-shadow: 0px 2px 4px 0px rgba(0,0,40,0.08);
	overflow: hidden;

	position: absolute;
	top: 0px;
	left: 50%;
	transform: translateX(-50%);
}


.syncLiveView-player-main-controlBarContainer{
	
	width: 100%;
	height: 100px;
	padding-top: $syncLiveView-progressBarHeight;
	
	display: flex;
	align-items: center;
	justify-content: center;

	flex-grow: 0;
	flex-shrink: 0;
}

@import 'SyncLiveView-SlideTweaks';
@import 'SyncLiveView-Toolbar';
@import 'SyncLiveView-PlayerSummary';
@import 'SyncLiveView-PlayerStudents';
@import 'SyncLiveView-PlayerProgressBar';
@import 'SyncLiveView-PlayerControlBar';
@import 'SyncLiveView-SetReport';
