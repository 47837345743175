.releaseNotesContainer{
	position: fixed;
	z-index: 9999;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: rgba(5,5,10,0.75);
	
	display: flex;
	
	align-items: center;
	justify-content: center;
}

.releaseNotes{
	border-radius: 10px;
	
	background: $onboarding-bgColor;
	border: 1px solid $gray-5;
	box-shadow: 
		0px 8px 16px 0px rgba(5,5,10,0.12),
		0px 16px 32px 0px rgba(5,5,10,0.08) ;

	box-sizing: border-box;

	overflow: hidden;

	position: relative;

	width: 920px;
	height: calc(100vh - 50px);	
	max-height: 800px;
	min-height: 620px;

	cursor: default;
}


.releaseNotesScrollContainer{
	position: absolute;
	top: 0px;
	bottom: 0px;
	left: 0px;
	right: 0px;
	overflow-x: hidden;
	overflow-y: scroll;
}

.releaseNotes-header{
	width: 100%;
	font-size: 46px;
	font-weight: $effra-bold;
	color: $textDark;
	letter-spacing: 0.01em;

	margin-top: 70px;
	margin-bottom: 10px;
	text-align: center;
	position: relative;
	z-index: 5;
}

.releaseNotes-header-blue{
	color: $textBlue;
}

.releaseNotes-header-bg{
	position: absolute;
	left: 50%;
	transform: translateX(-50%);
	top: 40px;
	z-index: 2;

}



.releaseNotes-list{
	padding-left: 60px;
	padding-right: 140px;
	box-sizing: border-box;
	
	padding-top: 60px;
	margin-bottom: 60px;
}

.releaseNotes-list-item{
	display: flex;
	
}

.releaseNotes-list-item--left{
	flex-grow: 0;
	width: 150px;
	// background: $tempr;
}

.releaseNotes-list-item-releaseDate{
	text-align: right;
	font-size: 14px;
	font-weight: $effra-medium;
	text-transform: uppercase;
	letter-spacing: 0.15em;
	color: $textBlue;
	line-height: 2.3;
}

.releaseNotes-list-item--center{
	flex-grow: 0;
	width: 40px;
	// background: $tempb;
}

.releaseNotes-list-item--right{
	flex-grow: 1;
	width: 10px; // will grow
	// background: $tempg;
	padding-bottom: 40px; // space for center stuff
}

.releaseNotes-list-item-releaseTitle{
	font-size: 24px;
	font-weight: $effra-bold;
	color: $textBlack;
}

.releaseNotes-list-item-releaseDetail{
	margin-top: 8px;
}

.releaseNotes-list-item-releaseDetail-group{
	margin-bottom: 12px;
}

.releaseNotes-list-item-releaseDetail-group-title{
	font-size: 16px;
	font-weight: $effra-bold;
	color: $textDark;
	margin-bottom: 6px;
}

$releaseList-subItem-bulletOffset: 15px;

.releaseNotes-list-item-releaseDetail-group-subitem{
	display: flex;
	font-size: 14px;

	margin-left: calc(-1 * #{$releaseList-subItem-bulletOffset});
	font-weight: $effra-regular;
	color: $textDark;
	margin-bottom: 4px;	
	line-height: 1.3;

	width: 80%;
}



.releaseNotes-list-item-releaseDetail-group-subitem-bullet{
	width: $releaseList-subItem-bulletOffset;
	flex-shrink: 0;
	flex-grow: 0;
}


// CLOSE BUTTON
// Refactor out for use on marketing modals
// Used on Marketing Modals

.releaseNotes-closeBtn{
	position: absolute;
	width: 42px;
	height: 42px;
	top: 20px;
	right: 24px;
	cursor: pointer;
	border-radius: 21px;

	opacity: 0.4;
	transition: opacity 100ms linear;

	z-index: 100;
}

.releaseNotes-closeBtn:hover{
	opacity: 0.8;
	transition: opacity 50ms linear;
}

.releaseNotes-closeBtn:active{
	opacity: 1;
}

.releaseNotes-closeBtn .icon{
	width: 42px;
	height: 42px;
	fill: $gray-6;
}
