
///
/// SLIDE TWEAKS
/// NOT SURE ABOUT THESE...

.syncLiveView.syncLiveView--defaultMode.syncLiveView--scan .syncLiveView-player-main-slide .slide.slide--static,
.syncLiveView.syncLiveView--defaultMode.syncLiveView--scan .syncLiveView-player-main-slide .slide.slide--static .slide-choices,
.syncLiveView.syncLiveView--defaultMode.syncLiveView--scan .syncLiveView-player-main-slide .slide.slide--static .slide-choices *,
.syncLiveView.syncLiveView--defaultMode.syncLiveView--review .syncLiveView-player-main-slide .slide.slide--static,
.syncLiveView.syncLiveView--defaultMode.syncLiveView--review .syncLiveView-player-main-slide .slide.slide--static .slide-choices,
.syncLiveView.syncLiveView--defaultMode.syncLiveView--review .syncLiveView-player-main-slide .slide.slide--static .slide-choices *,{
	pointer-events: auto !important;
}

.syncLiveView.syncLiveView .syncLiveView-player-main .slide-choices.slide-choices--delayHovered .slide-choice{
	opacity: 0.25;
	transition: opacity 0ms linear;
}

.syncLiveView.syncLiveView .syncLiveView-player-main .slide-choices .slide-choice:hover{
	opacity: 1;
	transition: opacity 0ms linear;
}

// .syncLiveView.syncLiveView .syncLiveView-player-main .slide-choices .slide-choice.is--studentSyncHoverNotSelection{
// 	opacity: 0.25;
// }


// Show cursor on review and scan

.syncLiveView.syncLiveView--defaultMode.syncLiveView--scan .slide-choices.slide-choices--notDelayHovered,
.syncLiveView.syncLiveView--defaultMode.syncLiveView--scan .slide-choices.slide-choices--delayHovered,
.syncLiveView.syncLiveView--defaultMode.syncLiveView--scan .slide-choices.slide-choices--notDelayHovered *,
.syncLiveView.syncLiveView--defaultMode.syncLiveView--scan .slide-choices.slide-choices--delayHovered *,
.syncLiveView.syncLiveView--defaultMode.syncLiveView--review .slide-choices.slide-choices--notDelayHovered,
.syncLiveView.syncLiveView--defaultMode.syncLiveView--review .slide-choices.slide-choices--delayHovered,
.syncLiveView.syncLiveView--defaultMode.syncLiveView--review .slide-choices.slide-choices--notDelayHovered *,
.syncLiveView.syncLiveView--defaultMode.syncLiveView--review .slide-choices.slide-choices--delayHovered * {
	cursor: pointer !important; //overrights another important unfortunately
}


// IN REVIEW SCREEN, GOES GRAY UNLESS ON HOVER BEFORE SCAN



// SYNC
// When show review, fade in green as real
.syncLiveView-player-main-slide
.slide-choices--notDelayHovered
.slide-choice.slide-choice--graded.slide-choice--review.slide-choice--notAsk.slide-choice--notShowCorrect.slide-choice--notScan
.slide-choice-letterblock{
  border: 1px solid rgb(213,213,217); // default  
  background: rgb(252,252,255); // default
}

.syncLiveView-player-main-slide
.slide-choices--notDelayHovered
.slide-choice.slide-choice--graded.slide-choice--review.slide-choice--notAsk.slide-choice--notShowCorrect.slide-choice--notScan
.slide-choice-letterblock svg{
	fill: lighten(rgb(97, 97, 114), 40%); // default
}

// make graph gray until reveal answer
.syncLiveView-player-main-slide
.slide-choices--notDelayHovered
.slide-choice.slide-choice--graded.slide-choice--review.slide-choice--notAsk.slide-choice--notShowCorrect.slide-choice--notScan
.slide-choice-graph-bar{
	background: $gray-8;
}

// make graph semiopacity until show graph
.syncLiveView-player-main-slide
.slide-choices--notDelayHovered
.slide-choice.slide-choice--review.slide-choice--notAsk.slide-choice--notDisplaySyncGraph.slide-choice--notScan
.slide-choice-graph{
	opacity: 0.25;
	transition: opacity 100ms linear;
}

// make graph response counts semi-opacity until show graph
.syncLiveView-player-main-slide
.slide-choices--notDelayHovered
.slide-choice.slide-choice--review.slide-choice--notAsk.slide-choice--notDisplaySyncGraph.slide-choice--notScan
.slide-choice-responseCount {
	opacity: 0.25;
	transition: opacity 100ms linear;
}

.syncLiveView-player-main-slide
.slide-choices--notDelayHovered
.slide-choice.slide-choice--review.slide-choice--notAsk.slide-choice--displaySyncGraph.slide-choice--notScan
.slide-choice-graph{
	opacity: 1;
	transition: opacity 100ms linear;
}

.syncLiveView-player-main-slide
.slide-choices--notDelayHovered
.slide-choice.slide-choice--review.slide-choice--notAsk.slide-choice--displaySyncGraph.slide-choice--notScan
.slide-choice-responseCount {
	opacity: 1;
	transition: opacity 100ms linear;
}



.syncLiveView-player-main-slide
.slide-choice.slide-choice--correct.slide-choice--graded.slide-choice--review.slide-choice--notAsk.slide-choice--showCorrect.slide-choice--notScan
.slide-choice-letterblock{
  background: $green;
  border: 1px solid $green;
}

.syncLiveView-player-main-slide
.slide-choice.slide-choice--correct.slide-choice--graded.slide-choice--review.slide-choice--notAsk.slide-choice--showCorrect.slide-choice--notScan
.slide-choice-letterblock svg{
	fill: $white;
}