.printPack--twoColumn
.printPackQuestion-workingSpace{
	box-sizing: border-box;
	padding-bottom: 0px;
}

.printPack--singleColumn
.printPackQuestion-workingSpace{
	position: absolute;
	bottom: 20px;
	left: $printPack-pageMargin--counterWidth;	
	right: $printPack-pageMargin--counterWidth;	
}


.printPackQuestion-workingSpace
.printPackQuestion-divider{

}


.printPackQuestion-workingSpace-row{
	width: 100%;
	height: 8mm;
	border-bottom: 1px solid $lineDark;
	// border-bottom: 1px solid $red;
}