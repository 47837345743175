//
// PRINT PACK
//

@media print {
    html, body {
        height: auto !important;     
    }
}

.featureModal--printSet,
.featureModal--reportPack{
	opacity: 1;
	transform: translateY(0px);
	transition: all 0ms linear;
}

.featureModal--printSet--hide,
.featureModal--reportPack--hide{
	opacity: 0 !important;
	transform: translateY(-100px);
	transition: all 50ms linear;
	transition-delay: 4000ms;
}

$printPack-pageMargin--sideOuter: 8mm;
$printPack-pageMargin--sideInner: 16mm;

$printPack-pageMargin--top: 16mm;
$printPack-pageMargin--bottom: 12mm;

$printPack-columnGutter: 12mm;

$printPack-heightPackHeader: 85px;

$printPack-pageMargin--sideInnerOuterDiff: calc(#{$printPack-pageMargin--sideInner} - #{$printPack-pageMargin--sideOuter});
$printPack-pageMargin--counterWidth: $printPack-pageMargin--sideInnerOuterDiff;

.printPack-page{
	background: $white;
	position: relative;
	cursor: default !important;
}

.printPack--a4 .printPack-page{
	width: 210mm;
	height: 297mm;
}

.printPack--letter .printPack-page{
	width: 8.5in;
	height: 11in;	
}

@page {
	margin: 0; // set to zero to cover up headers
}	


@media print {	
	.printPack-page{
		width: 100%;
		height: 100%;		
	}
}


@media screen {
	.printPack-page{
		margin-bottom: 24px;		
		border: 1px solid rgb(204,204,210);		
		box-shadow: 0px 4px 4px 0px rgba(0,0,40,0.08);
		border-radius: 4px;
	}
}

@media print {
	.printPack-page{				
		page-break-inside: avoid;
		border: 1px solid white;
		box-sizing: border-box;
		background: white;
		transform: scale(1.38);
		transform-origin: top left;
		
	}
	
}


@media print {
	.printPack--safari .printPack-page{
		transform: scale(1.38);
		transform-origin: top left;
	}
}


@media print {
	.printPackPortal{
		width: 100%;
		height: 100%;
		position: static;
	}
}


@media screen {
	.printPackPortal{
		width: 0px;
		height: 0px;
	}
}


//
// GHOST
//

.printPack--ghost{
	position: absolute;
	top: -9999px;
	left: -9999px;
	z-index: -1;
	opacity: 0;
	visibility: hidden;
	pointer-events: none;

	// z-index: 10;

	// top: 0px;
	// right: 0px;
	// opacity: 0.75;		

}

.printPack--ghost .printPack-page{
	height: auto;
	border: 1px solid blue;
}

.printPack--ghost.printPack--singleColumn .printPack-page-pageColumn{
	width: calc(100% - #{$printPack-pageMargin--sideOuter} - #{$printPack-pageMargin--sideInner});
}

.printPack--ghost.printPack--twoColumn .printPack-page-pageColumn{
	width: calc(((100% - #{$printPack-pageMargin--sideOuter} - #{$printPack-pageMargin--sideOuter}) / 2) - (#{$printPack-columnGutter} / 2));
}

.printPack--ghostSpacer{
	position: absolute;
	left: -9999px;
	top: -9999px;
	opacity: 0;
	z-index: -1;
}

.printPack--ghostSpacer .printPack-page-spacer{
	position: absolute;
	top: $printPack-pageMargin--top;
	left: $printPack-pageMargin--sideOuter;
	right: $printPack-pageMargin--sideOuter;
	bottom: $printPack-pageMargin--bottom;
}


//
// RENDERED
// 

.printPack--rendered.printPack--singleColumn .printPack-page-pageColumn{
	position: absolute;
	top: $printPack-pageMargin--top;
	left: $printPack-pageMargin--sideOuter;
	right: $printPack-pageMargin--sideInner;
	bottom: $printPack-pageMargin--bottom;
}

.printPack--rendered.printPack--twoColumn .printPack-page-pageColumn{
	position: absolute;
	width: calc(((100% - #{$printPack-pageMargin--sideOuter} - #{$printPack-pageMargin--sideOuter}) / 2) - (#{$printPack-columnGutter} / 2));
	top: $printPack-pageMargin--top;
	bottom: $printPack-pageMargin--bottom;
}

.printPack--twoColumn .printPack-page-pageColumn--left{
	left: $printPack-pageMargin--sideOuter;
}

.printPack--twoColumn .printPack-page-pageColumn--right{
	right: $printPack-pageMargin--sideInner;
}

.printPack--rendered.printPack--twoColumn .printPack-page--firstPage .printPack-page-pageColumn,
.printPack--rendered.printPack--singleColumn .printPack-page--firstPage .printPack-page-pageColumn{
	top: calc(#{$printPack-pageMargin--top} + #{$printPack-heightPackHeader});
}	

$printPack-bodyFontSize: 9pt;
 
@import 'PrintPack-Question'; // Default question unit in a print pack
@import 'PrintPack-QuestionChoices';
@import 'PrintPack-QuestionFlags';
@import 'PrintPack-QuestionWorkingSpace';

@import 'PrintPack-PageHead'; // recurring page header with page numbers

@import 'PrintPack--StudentReport-StudentReportHeader'; // Header section at top of each student report
@import 'PrintPack--StudentReport-SetHeader'; // In-report divider for set headers

@import 'PrintPack--SetHandout-HandoutHeader'; // Header at the top of each handout


