//
// SIGN IN SIGN UP RESET PASSWORD PAGE
//

// Reset password page is mobile-friendly as it's unauthenticated and can be opened from a reset password email
// so need support here at smaller resolutions

@media (max-width: 767px) {
	body {
		overflow-x: none;
	}
	.App{
		min-width: 0px;
  }

  // if on mobile, it should not display "Back to Plickers"
  // to avoid encouraging users to use web app on mobile
  .resetPassword-backtoPlickersLogin{
    display: none !important;
  }
}

.resetPassword{
	position: fixed;

	top: 0;
	left: 0;

	width: 100vw;
	height: 100vh;

	background: rgb(253,253,255);

	overflow-y: hidden;
}

.resetPassword-centerBlock{
	height: 100%;
	width: $width-signinsignup-centerBlock;
	margin: 0 auto;

	overflow: hidden;
}

//

.resetPassword-centerBlock--top{
	width: 100%;
	height: 100px;

}

.resetPassword-centerBlock--middle{
	width: 100%;
	justify-content: center;
	align-items: center;
	position: relative;

	height: 320px;
}

//


.resetPassword-centerBlock-title{
	font-size: 42px;
	font-weight: $effra-bold;
	color: $textBlack;
	width: 100%;
	text-align: center;

	margin-bottom: 20px;
	cursor: default;
	user-select: none;
}

.resetPassword-centerBlock-subtitle{
	font-size: 16px;
	font-weight: $effra-medium;
	color: $textMid;
	width: 100%;
	text-align: center;

	margin-bottom: 22px;
	cursor: default;
	user-select: none;
}

.resetPassword .formField{
	margin-bottom: 0px;
}

.resetPassword .signup-form-submitBtn{
	margin-top: 10px;
}
