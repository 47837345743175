//
// EDITOR
//

$height-editor-topBar: 40px;
$height-editor-formatBar: 34px;
$width-editor-slideList: 188px;

$height-editor-canvasTopPadding: 8px;
$height-editor-canvasBottomPadding: 44px;
$height-editor-sideSpacerPadding: 10px;


.editor{
	position: fixed;
	top: 0px;
	left: 0px;	
	width: 100vw;
	height: 100vh;
}

.editor-topBar{
	position: fixed;	
	top: 0px;
	left: 0px;
	right: 0px;
	height: $height-editor-topBar;	
	z-index: 1000;
}

.editor-formatBar{
	position: fixed;	
	top: $height-editor-topBar;
	left: 0px; // padding applied internally
	right: 0px;
	height: $height-editor-formatBar;
	background: $tempb;
	z-index: 50;
}

.editor-slideListOuterContainer{
	position: fixed;
	
	left: 0px;
	width: $width-editor-slideList;
	bottom: 0px;
	top: $height-editor-topBar;	
	z-index: 500;

	border-right: 1px solid $lineDark;
	background: #F6F6FC;
}

.editor-slideListContainer{
	
	position: absolute;
	top: 0px;
	left: 0px;
	bottom: 0px;
	right: 0px;

	overflow-y: scroll;
	overflow-x: hidden;

	padding-top: 11px;
	padding-bottom: 20px;
}

.editor-canvas,
.editor-canvasGhost{
	position: fixed;
	left: 0px; // padding applied internally
	right: 0px;
	bottom: 0px;
	top: calc(#{$height-editor-topBar} + #{$height-editor-formatBar});
	background: rgba(255,255,0,0.25);
}

@import 'Editor-SlideList';
@import 'Editor-TopBar';
@import 'Editor-FormatBar';
@import 'Editor-Canvas';
@import 'Editor-CanvasInsertBar';
@import 'Editor-CanvasEditMediaBar';
@import 'Editor-CanvasBottomBar';
@import 'Editor-SaveStatus';
@import 'Editor-EmojiSymbolPicker';
@import 'Editor-EquationModal';
@import 'Editor-FontSizeCalculator';
@import 'Editor-Suggestions';

// VIDEO MEDIA
@import 'Editor-InsertVideoModal'; // search and youtube
@import 'Editor-EditVideoModal';
@import 'Editor-SidePanelVideo';
@import 'Editor-CanvasVideoControls';

// SOUND
@import 'Editor-CanvasSoundPlayer';
@import 'Editor-InsertSoundModal';
@import 'Editor-EditSoundModal';

// IMAGE
@import 'Editor-CanvasImageControls';
@import 'Editor-InsertImageModal';
@import 'Editor-EditImageModal';


// Font Size calculations
.editorGhostElementsContainer{
	position: fixed;
	top: 0px;
	left: 0px;
	pointer-events: none;
	visibility: hidden;
}