//
// FORM FIELD SELECT
// this is very old, may be able to be refactored out
// some overriding over react-select library css is required
//

.formField-select--container{
	display: flex;
	width: 100%;
	position: relative;
	margin-top: 10px;
	padding-bottom: 6px;
}

.formField.is--select .Select{
	width: 100%;
}

.formField.is--select .Select-control {
	width: 100%;
		
	padding-left: 10px;
	box-sizing: border-box;

	// height: calc(#{$height-formField} - 2px); // border
	height: 34px;

	border: $border-formField;
	border-radius: 4px;

	color: $textBlack;
	font-size: $fontSize-formField;
	font-weight: $lato-semibold;

	box-shadow: none;
	background: transparent;
	background-image: none;
	-webkit-appearance: none;

	font-family: 'Lato Web'; // 

	background: $white;
}	

.formField.is--select .Select-control .Select-placeholder{
	line-height: 32px;
	color: $textInputPlaceholder;
	font-family: "Plickers Lato";
}

.formField.is--select .Select-control .Select-value{
	line-height: 32px !important;
	font-family: "Plickers Lato";
}

.formField.is--select .Select-control .Select-value-label {
	line-height: 32px;
}

.formField.is--select .Select-control .Select-input {
	height: 32px;
}

.formField.is--select .Select.is-focused.is-open .Select-control {
	border: $border-formField--focus;
	outline: none;
	box-shadow: $boxShadow-formField--focus;

	border-bottom-left-radius: 0px;
	border-bottom-right-radius: 0px;
}

// FOlLOWS SIMILAR TO DROPDOWN MENU ITEM
.formField.is--select .Select-option {
	height: 28px;
	background: $white;
	
	box-sizing: border-box;
	padding: 0px;
	padding-left: 10px;

	font-size: 14px;
	margin-bottom: 0px;

	font-weight: $lato-semibold;
	color: $textDark;
	cursor: default;	

	line-height: 1.8;
	
	user-select: none;
}

.formField.is--select .Select-option.is-focused{
	background: $blue;
	color: $white;
}

.formField.is--select .Select-menu-outer{
	margin-top: 0px;
	border-top: none;
}

.formField.is--select .Select-arrow-zone{
	cursor: default;
}

.formField.is--select .Select.is-focused.is-open .Select-arrow-zone .Select-arrow{
	border-bottom-color: $blue;
}

.formField.is--select .Select .Select-arrow-zone .Select-arrow {
	border-top-color: transparentize($textLight,0.5);
}

.formField.is--select .Select:hover .Select-arrow-zone .Select-arrow {
	border-top-color: $textLight;
}

.formField.is--select select:hover ~  .formField-selectArrows .icon{
	fill: $textMid;
}

.formField.is--select select:focus ~  .formField-selectArrows .icon{
	fill: $blue;
}

// DISABLED

.formField.is--select .is-disabled .Select-control{
	border-color: $gray-13;
}

.formField.is--select .is-disabled .Select-control .Select-value{
	background: $gray-13;
	pointer-events: none;
	user-select: none;
}

.formField.is--select .is-disabled .Select-arrow-zone .Select-arrow{
	 opacity: 0;
}