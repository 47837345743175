.discover-repoRatingStars{
	display: flex;
}

.discover-repoRatingStars-starsContainer{
	position: relative;
}

.discover-repoRatingStars-stars{
	display: flex;
	// background: $tempr;
	width: 100%;
}

.discover-repoRatingStars-stars svg{
	position: static; // sorry
	width: 12px;
	height: 12px;
	margin-right: 2px;
	background: none;
}


.discover-repoRatingStars-stars--active{
	position: absolute;
	z-index: 10;
	left: 0px;
	top: 0px;
	bottom: 0px;
}

.discover-repoRatingStars-stars--active svg{
	fill: $gray-7;
}

.discover-repoRatingStars-stars--bg{

}

.discover-repoRatingStars-stars--bg svg{
	fill: $gray-12;
}


.discover-repoRatingStars-label{
	margin-left: 5px;
}