//
// SET DETAIL
//

//// GRID COLUMN TOGGLE INCLUDING CONTAINER IS DEFINED UIKIT


// SIDEPANEL

.setDetail-sidePanel{
	box-sizing: border-box;
	overflow: hidden;
	width: 100%;
	height: 100%;
}

.setDetail-sidePanel-header{
	font-size: 16px;
	color: $textDark;
	font-weight: $lato-bold;

	padding-top: 8px;	
	padding-bottom: 4px;
	border-bottom: 1px solid $lineMid;

	user-select: none;
	cursor: default;
}

.setDetail-sidePanel-historyList{
	padding-top: 12px;
}