
.explorerCrossFilter-marketPanel-filter{
	margin-bottom: 20px;
}


.explorerCrossFilter-marketPanel-filter-header{
	display: flex;
	align-items: center;
	user-select: none;
	width: 100%;
	margin-bottom: 4px;
}

.explorerCrossFilter-marketPanel-filter-header-title{
	font-size: 12.5px;
	font-weight: $lato-bold;
	color: $textBlue;
	text-transform: uppercase;
	margin-right: auto;
	cursor: default;
	margin-left: 4px;
}

button.explorerCrossFilter-marketPanel-filter-header-btn{
	border: none;
	background: none;
	padding-top: 2px;
	padding-bottom: 2px;
	padding-left: 4px;
	padding-right: 4px;
	border-radius: 2px;

	box-shadow: none;
	outline: none;

	cursor: pointer;
	
	color: $textLight;
	font-size: 12.5px;
	font-weight: $lato-medium;
}

button.explorerCrossFilter-marketPanel-filter-header-btn:hover{
	border: none;
	background: none;
	box-shadow: none;
	color: $textBlue;
	background: $blue-extraLight;
}

// CONTROL ARRAY

.explorerCrossFilter-marketPanel-filter-controlArray--subSubject{
	display: flex;
	margin-bottom: 2px;
	width: calc(100% - 18px);
	margin-left: 18px;

}

.explorerCrossFilter-marketPanel-filter-controlArray--subSubject
.explorerCrossFilter-marketPanel-filter-controlArray-col{
	width: 33%;
}

.explorerCrossFilter-marketPanel-filter-controlArray--twoColumns,
.explorerCrossFilter-marketPanel-filter-controlArray--threeColumns{
	display: flex;
}

.explorerCrossFilter-marketPanel-filter-controlArray--twoColumns
.explorerCrossFilter-marketPanel-filter-controlArray-col{
	width: 50%;
}

.explorerCrossFilter-marketPanel-filter-controlArray--threeColumns
.explorerCrossFilter-marketPanel-filter-controlArray-col{
	width: 33%;
}

.explorerCrossFilter-marketPanel-filter-controlArray--extraGrades{
	margin-top: 6px;
}

.explorerCrossFilter-marketPanel-filter--publishedMonth
.explorerCrossFilter-marketPanel-filter-controlArray{
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	height: 36px;
	width: 80%;
}

.explorerCrossFilter-marketPanel-filter--publishedMonth
.explorerCrossFilterMarketPanelSelectControl{
	width: 80px;
}

.explorerCrossFilter-marketPanel-filter--rating
.explorerCrossFilter-marketPanel-filter-controlArray{
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	height: 54px;
	width: 80%;
}

.explorerCrossFilter-marketPanel-filter--rating
.explorerCrossFilterMarketPanelSelectControl{
	width: 150px;
}


/// SELECT CONTROL

.explorerCrossFilterMarketPanelSelectControl{
	display: flex;

	position: relative;

	padding: 2px;
	padding-left: 22px;

	cursor: pointer;

	border-radius: 2px;
	// background: $tempr;
	max-width: 140px;
}

.explorerCrossFilter-marketPanel-filter--rating
.explorerCrossFilterMarketPanelSelectControl{
	max-width: initial;
}

.explorerCrossFilterMarketPanelSelectControl:hover{
	//background: rgb(246,247,252);
	background: $blue-extraLight;
}

// .explorerCrossFilterMarketPanelSelectControl--checked,
// .explorerCrossFilterMarketPanelSelectControl--checked:hover{
// 	background: $blue-extraLight;
// }

//
// LABEL
//

.explorerCrossFilterMarketPanelSelectControl-label{
	font-size: 13px;
	font-weight: $lato-semibold;
	color: $textDark;
	letter-spacing: 0.005em;
	user-select: none;
}

.explorerCrossFilterMarketPanelSelectControl:hover
.explorerCrossFilterMarketPanelSelectControl-label{
	color: $textBlack;
}

.explorerCrossFilterMarketPanelSelectControl--checked .explorerCrossFilterMarketPanelSelectControl-label,
.explorerCrossFilterMarketPanelSelectControl--checked:hover .explorerCrossFilterMarketPanelSelectControl-label{
	color: $textBlackDark;
	font-weight: $lato-bold;
}



//// CHECK BOX 

.explorerCrossFilterMarketPanelSelectControl-checkbox{
	position: absolute;
	top: 2px;
	left: 4px;

	height: 12px;
	width: 12px;
	
	border-radius: 3px;
	
}


.explorerCrossFilterMarketPanelSelectControl--checked
.explorerCrossFilterMarketPanelSelectControl-checkbox{
	border: 1px solid darken($blue, 10%);
	background: $blue;
}

.explorerCrossFilterMarketPanelSelectControl--checked
.explorerCrossFilterMarketPanelSelectControl-checkbox svg{
	position: absolute;
	left: 2px;
	top: 2px;
	width: 9px;
	height: 9px;
}

.explorerCrossFilterMarketPanelSelectControl-checkbox svg #border{
	fill: darken($blue, 10%);
}

.explorerCrossFilterMarketPanelSelectControl-checkbox svg #fill{
	fill: $white;
}

.explorerCrossFilterMarketPanelSelectControl--unchecked
.explorerCrossFilterMarketPanelSelectControl-checkbox{
	border: 1px solid $lineDark;
	background: $white;
}

.explorerCrossFilterMarketPanelSelectControl--unchecked:hover
.explorerCrossFilterMarketPanelSelectControl-checkbox{
	border: 1px solid darken($lineDark,10%);
	background: $white;
}

