.confirmModal-modal .modal-backdrop{
	background: rgba(0,0,3,0.75);
}

.confirmModal-modal .icon.close{
	fill: rgba(255,255,255,0.75);
}

.confirmModal-modal .modal-content{
	width: 480px;
	height: 320px;
	cursor: default;

	border-radius: 6px;

	background: rgb(252,252,255);
	border: 1px solid $gray-5;

	box-shadow: 
		0px 8px 16px 0px rgba(5,5,10,0.20),
		0px 16px 32px 0px rgba(5,5,10,0.12) ;
}

.confirmModal{

	box-sizing: border-box;
	padding-top: 22px;
	padding-left: 28px;
	padding-right: 28px;
	padding-bottom: 24px;

	width: 100%;
	height: 100%;

	position: relative;
}


.confirmModal-modal.has--dynamicHeight .modal-content{
	height: auto;
	min-height: 300px;
	max-height: 550px;

	overflow: hidden;
}

.confirmModal-modal.has--dynamicHeight .confirmModal{
	
	max-height: 550px;
	min-height: 300px;

	box-sizing: border-box;
	padding-bottom: 60px;
}


.confirmModal-h1{
	font-size: 26px;
	font-weight: $lato-bold;
	color: $textBlackDark;
	margin-bottom: 14px;
	overflow-wrap: break-word;
}

.confirmModal-h1 b{
	font-weight: $lato-heavy;
	color: rgb(0,0,0);
}

.confirmModal-h2{
	font-size: 16px;
	font-weight: $lato-semibold;
	color: $textMid;
	margin-bottom: 10px;
	overflow-wrap: break-word;
}

.confirmModal-h2--strong{
	color: $textBlackDark;
	font-weight: $lato-bold;
	color: $red--notification;
}



.confirmModal-item{
	width: 100%;
	white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  color: $textBlackDark;

  font-size: 16px;
  font-weight: $lato-semibold;

  margin-top: 8px;
}

.confirmModal .featureModal-selectControlGroup{
	position: absolute;
	left: 28px;
	right: 28px;
	bottom: 55px;
}

.confirmModal-buttonSet{
	display: flex;
	position: absolute;
	bottom: 24px;
	left: 28px;
	right: 28px;
}

.confirmModal-buttonSet button.btn.confirmmodalconfirm,
.confirmModal-buttonSet button.btn.confirmmodalcancel{
	cursor: pointer;
	font-weight: $lato-heavy;
	font-size: 17px;
	height: 38px;
	border-radius: 4px;
}

.confirmModal-buttonSet button.btn.confirmmodalconfirm{
	flex-grow: 1;
	width: 10px; //initial width needed for flex-grow
	margin-right: 8px;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.confirmModal-buttonSet button.btn.btn--white.confirmmodalconfirm{
	// if color is not set to blue, then instead is red
	color: $white;
	background: #fb4d4d; // sorry for the one off
	border: 1px solid darken(#fb4d4d, 5%);
}


.confirmModal-buttonSet button.btn.btn--white.confirmmodalconfirm:hover{
	background: darken(#fb4d4d, 5%);
	border-color: darken(#fb4d4d, 10%);
}

.confirmModal-buttonSet button.btn.confirmmodalcancel{
	width: 150px;
	color: $textBlack;
}

.confirmModal-modal--delinkSection .confirmModal-buttonSet button.btn.confirmmodalcancel{
	width: 100px; // need space for label
}



//


.confirmModal-dynamicItemList{
	width: calc(100% + 28px);
	margin-top: 10px;
	min-height: 40px;	
	max-height: 330px;

	overflow-y: auto;
	padding-bottom: 10px;
}