button.syncLiveView-player-main-controlBar-questionControlBtn{
	width: 60px;
	height: 50px;

	border: none;
	box-shadow: none;
	background: none;
	outline: none;

	flex-shrink: 0;
	flex-grow: 0;

	border-radius: 4px;

	position: relative;

	cursor: pointer;
	transition: all 250ms cubic-bezier(.2, .3, .25, .9);
}



.syncLiveView-player-main-controlBar-questionControlBtn-contents{
	position: absolute;
	top: 0px;
	left: 0px;
	right: 0px;
	bottom: 0px;
	border-radius: 4px;

	background: rgba(0,0,60,0.08);

	display: flex;
	align-items: center;
	justify-content: center;

	opacity: 0.6;
}

.syncLiveView-player-main-controlBar-questionControlBtn-keyboardTooltip{
	position: absolute;
	bottom: -17px;
	left: 50%;
	width: 100px;
	transform: translateX(-50%);

	display: flex;
	align-items: center;
	justify-content: center;

	font-size: 11.5px;
	font-weight: $lato-bold;
	color: $textLight;
	letter-spacing: 0.05em;
	text-transform: uppercase;
	margin-top: 2px;

	opacity: 0;
	transition: opacity 250ms linear;
	// background: $tempr;
}

button.syncLiveView-player-main-controlBar-questionControlBtn:hover
.syncLiveView-player-main-controlBar-questionControlBtn-keyboardTooltip{
	opacity: 1;
	transition: opacity 150ms linear;
	transition-delay: 1000ms;
}

.syncLiveView-player-main-controlBar-questionControlBtn-keyboardTooltip-lighten{
	color: lighten($textLight, 7%);
}

.syncLiveView-player-main-controlBar-questionControlBtn-keyboardTooltip svg{
	height: 12px;
	width: 12px;
	fill: lighten($textLight, 6%);
	margin-left: 3px;
	position: relative;	
}


button.syncLiveView-player-main-controlBar-questionControlBtn:disabled{
	opacity: 0.05;
	pointer-events: none;
}

button.syncLiveView-player-main-controlBar-questionControlBtn--fade:not(:hover){
	opacity: 0.25;
}

button.syncLiveView-player-main-controlBar-questionControlBtn--fadeIn:not(:hover){
	opacity: 1;
	transition-duration: 600ms;
	transition-delay: 1500ms;
}




.syncLiveView-player-main-controlBar-questionControlBtn-contents svg{
	fill: $gray-5;
	height: 22px;
	width: 22px;

	transition: all 250ms cubic-bezier(.2, .3, .25, .9);
}

button.syncLiveView-player-main-controlBar-questionControlBtn:hover
.syncLiveView-player-main-controlBar-questionControlBtn-contents{
	background: rgba(0,0,60,0.18);
	opacity: 0.8;
	transition: all 150ms cubic-bezier(.2, .3, .25, .9);
}

button.syncLiveView-player-main-controlBar-questionControlBtn:hover svg{
	transform: scale(1.08);
	transition: all 150ms cubic-bezier(.2, .3, .25, .9);
}

button.syncLiveView-player-main-controlBar-questionControlBtn:active
.syncLiveView-player-main-controlBar-questionControlBtn-contents{
	opacity: 1;	
	transition: all 0ms cubic-bezier(.2, .3, .25, .9);
}

button.syncLiveView-player-main-controlBar-questionControlBtn:active svg{
	transform: scale(1.15);
	transition: all 0ms cubic-bezier(.2, .3, .25, .9);
}


$syncLiveView-controlBar-questionControlBtn-slidePreviewWidth: 280px;

.syncLiveView-player-main-controlBar-questionControlBtn-slidePreviewContainer{
	position: absolute;
	z-index: 50;
	height: calc(#{$syncLiveView-controlBar-questionControlBtn-slidePreviewWidth}/1.6);
	width: $syncLiveView-controlBar-questionControlBtn-slidePreviewWidth;

	background: $white;
	border: 1px solid darken($lineMid, 5%);
	border-bottom: 1px solid darken($lineMid, 10%);
	border-radius: 4px;

	box-shadow: 0px 2px 4px 0px rgba(0,0,20,0.08), 0px 0px 8px 0px rgba(0,0,20,0.08);

	text-align: initial;
	
	bottom: -15px;
	right: calc(-1 * #{$syncLiveView-controlBar-questionControlBtn-slidePreviewWidth} - 10px);

	opacity: 0;

	pointer-events: none;

	overflow: hidden;
}


button.syncLiveView-player-main-controlBar-questionControlBtn:hover
.syncLiveView-player-main-controlBar-questionControlBtn-slidePreviewContainer{
	opacity: 1;
	transition: all 0ms ease-in-out;
	transition-delay: 350ms;	
}

button.syncLiveView-player-main-controlBar-questionControlBtn:active
.syncLiveView-player-main-controlBar-questionControlBtn-slidePreviewContainer{
	display: none;
}


