.editor-canvas-bottomBar-templateBtn{
	position: relative;
}

.editor-canvas-bottomBar-templateBtn-tooltip{

	position: absolute;
	bottom: 34px;


	// display: none;

	width: 310px;
	height: 194px;

	background: $white;
	border: 1px solid darken($lineDark, 5%);
	border-bottom: 1px solid darken($lineDark, 8%);
	border-radius: 4px;
	box-shadow: 0px 2px 4px 0px rgba(0,0,20,0.12);
	
	box-sizing: border-box;

	user-select: none;

	opacity: 0;
	transition: opacity 0ms linear;
	transition-delay: 00ms;
	visibility: hidden;

	pointer-events: none;
}


.editor-canvas-bottomBar-templateBtnGroup:hover
.editor-canvas-bottomBar-templateBtn-tooltip{
	opacity: 1;
	transition: opacity 50ms linear;
	transition-delay: 200ms;
}

.editor-canvas-bottomBar-templateBtn:hover
.editor-canvas-bottomBar-templateBtn-tooltip{
	visibility: visible;	
}

.editor-canvas-bottomBar-templateBtn--active:hover
.editor-canvas-bottomBar-templateBtn-tooltip{
	visibility: hidden;
}

///
/// MEDIA CHOICES

.editor-canvas-bottomBar-templateBtn-tooltip-title{
	font-size: 19px;
	font-weight: $lato-heavy;
	color: $textBlack;
	letter-spacing: 0.02em;
	
	text-align: center;

	position: absolute;
	top: 20px;
	left: 20px;
	right: 20px;
	height: 20px;

	// background: $tempg;

}



.editor-canvas-bottomBar-templateBtn-tooltip-subtitle{
	text-align: center;
	margin-top: 7px;
	font-size: 14.5px;
	font-weight: $lato-semibold;
	letter-spacing: 0.01em;	
	color: $textLight;
	line-height: 1.2;

	position: absolute;
	top: 38px;
	
	left: 20px;
	right: 20px;
	

	// background: $tempg;
}

.editor-canvas-bottomBar-templateBtn-tooltip-media{
	position: absolute;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 5px;
}

.editor-canvas-bottomBar-templateBtn-tooltip-media-imageContainer{
	width: 55px;
	height: 55px;
}

.editor-canvas-bottomBar-templateBtn-tooltip-media-imageContainer img{
	height: 100%;
	width: 100%;
	object-fit: cover;
}
//

.editor-canvas-bottomBar-templateBtn-tooltip-choice{
	height: 12px;
	width: 112px;
	border-radius: 3px;
	background: $blue-extraLight;
	margin-bottom: 5px;
}


.editor-canvas-bottomBar-templateBtn-tooltip-choice:nth-child(1){
	width: 100%;
}

.editor-canvas-bottomBar-templateBtn-tooltip-choice:nth-child(2){
	width: 60%;
}

.editor-canvas-bottomBar-templateBtn-tooltip-choice:nth-child(3){
	width: 80%;
}

.editor-canvas-bottomBar-templateBtn-tooltip-choice:nth-child(4){
	width: 70%;
}


// BODY LEFT
.editor-canvas-bottomBar-templateBtn-tooltip--bodyLeft
.editor-canvas-bottomBar-templateBtn-tooltip-title{
	text-align: left;
}
.editor-canvas-bottomBar-templateBtn-tooltip--bodyLeft
.editor-canvas-bottomBar-templateBtn-tooltip-subtitle{
	text-align: left;
}

.editor-canvas-bottomBar-templateBtn-tooltip--bodyLeft
.editor-canvas-bottomBar-templateBtn-tooltip-choices{
	position: absolute;
	bottom: 15px;
	left: 20px;
	right: 100px;
}

// BODY LEFT MEDIA RIGHT
.editor-canvas-bottomBar-templateBtn-tooltip--bodyLeftMediaRight
.editor-canvas-bottomBar-templateBtn-tooltip-title{
	text-align: left;
	right: 160px;
}
.editor-canvas-bottomBar-templateBtn-tooltip--bodyLeftMediaRight
.editor-canvas-bottomBar-templateBtn-tooltip-subtitle{
	text-align: left;
	right: 160px;
}

.editor-canvas-bottomBar-templateBtn-tooltip--bodyLeftMediaRight
.editor-canvas-bottomBar-templateBtn-tooltip-choices{
	position: absolute;
	bottom: 15px;
	left: 20px;
	right: 160px;
}

.editor-canvas-bottomBar-templateBtn-tooltip--bodyLeftMediaRight
.editor-canvas-bottomBar-templateBtn-tooltip-media{
	top: 20px;
	right: 20px;
	bottom: 20px;
	width: 125px;
	background: #FDEDE2;
}

// BODY RIGHT MEDIA LEFT
.editor-canvas-bottomBar-templateBtn-tooltip--bodyRightMediaLeft
.editor-canvas-bottomBar-templateBtn-tooltip-title{
	text-align: left;
	left: 160px;
}
.editor-canvas-bottomBar-templateBtn-tooltip--bodyRightMediaLeft
.editor-canvas-bottomBar-templateBtn-tooltip-subtitle{
	text-align: left;
	left: 160px;
}

.editor-canvas-bottomBar-templateBtn-tooltip--bodyRightMediaLeft
.editor-canvas-bottomBar-templateBtn-tooltip-choices{
	position: absolute;
	bottom: 15px;
	right: 20px;
	left: 160px;
}

.editor-canvas-bottomBar-templateBtn-tooltip--bodyRightMediaLeft
.editor-canvas-bottomBar-templateBtn-tooltip-media{
	top: 20px;
	left: 20px;
	bottom: 20px;
	width: 125px;
	background: #FFEFEE;
}

.editor-canvas-bottomBar-templateBtn-tooltip--bodyRightMediaLeft
.editor-canvas-bottomBar-templateBtn-tooltip-media-imageContainer{
	width: 64px;
	height: 64px;
}

// BODY LEFT MEDIA CHOICES RIGHT

.editor-canvas-bottomBar-templateBtn-tooltip--bodyLeftMediaRightChoicesMedia
.editor-canvas-bottomBar-templateBtn-tooltip-title{
	text-align: left;
	right: 128px;
}
.editor-canvas-bottomBar-templateBtn-tooltip--bodyLeftMediaRightChoicesMedia
.editor-canvas-bottomBar-templateBtn-tooltip-subtitle{
	text-align: left;
	right: 128px;
}


.editor-canvas-bottomBar-templateBtn-tooltip--bodyLeftMediaRightChoicesMedia
.editor-canvas-bottomBar-templateBtn-tooltip-media{
	top: 20px;
	right: 20px;
	bottom: 90px;
	width: 100px;
	border-radius: 8px;
	background: #FEFADF;
}

.editor-canvas-bottomBar-templateBtn-tooltip--bodyLeftMediaRightChoicesMedia
.editor-canvas-bottomBar-templateBtn-tooltip-media-imageContainer{
	width: 50px;
	height: 50px;
}


// CENTER STUFF

.editor-canvas-bottomBar-templateBtn-tooltip--bodyCenterMediaCenter
.editor-canvas-bottomBar-templateBtn-tooltip-media{
	top: 52px;
	right: 20px;
	left: 20px;
	bottom: 63px;
	background: #E9F4E5;
}

.editor-canvas-bottomBar-templateBtn-tooltip--bodyCenter .editor-canvas-bottomBar-templateBtn-tooltip-choices,
.editor-canvas-bottomBar-templateBtn-tooltip--bodyCenterMediaCenter .editor-canvas-bottomBar-templateBtn-tooltip-choices{
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;

	position: absolute;
	left: 20px;
	right: 20px;
	bottom: 15px;
	top: unset;

	height: 34px;
}

.editor-canvas-bottomBar-templateBtn-tooltip--bodyCenter .editor-canvas-bottomBar-templateBtn-tooltip-choice:nth-child(1),
.editor-canvas-bottomBar-templateBtn-tooltip--bodyCenterMediaCenter .editor-canvas-bottomBar-templateBtn-tooltip-choice:nth-child(1){
	width: 112px;
}

.editor-canvas-bottomBar-templateBtn-tooltip--bodyCenter .editor-canvas-bottomBar-templateBtn-tooltip-choice:nth-child(2),
.editor-canvas-bottomBar-templateBtn-tooltip--bodyCenterMediaCenter .editor-canvas-bottomBar-templateBtn-tooltip-choice:nth-child(2){
	width: 60px;
}

.editor-canvas-bottomBar-templateBtn-tooltip--bodyCenter .editor-canvas-bottomBar-templateBtn-tooltip-choice:nth-child(3),
.editor-canvas-bottomBar-templateBtn-tooltip--bodyCenterMediaCenter .editor-canvas-bottomBar-templateBtn-tooltip-choice:nth-child(3){
	width: 112px;
}

.editor-canvas-bottomBar-templateBtn-tooltip--bodyCenter .editor-canvas-bottomBar-templateBtn-tooltip-choice:nth-child(4),
.editor-canvas-bottomBar-templateBtn-tooltip--bodyCenterMediaCenter .editor-canvas-bottomBar-templateBtn-tooltip-choice:nth-child(4){
	width: 82px;
}


////

.editor-canvas-bottomBar-templateBtn-tooltip--bodyCenterChoicesMedia .editor-canvas-bottomBar-templateBtn-tooltip-choices,
.editor-canvas-bottomBar-templateBtn-tooltip--bodyLeftMediaRightChoicesMedia .editor-canvas-bottomBar-templateBtn-tooltip-choices{
	display: flex;
	position: absolute;
	bottom: 14px;
	left: 20px;
	right: 20px;

	justify-content: space-between;
}

.editor-canvas-bottomBar-templateBtn-tooltip--bodyCenterChoicesMedia .editor-canvas-bottomBar-templateBtn-tooltip-choice,
.editor-canvas-bottomBar-templateBtn-tooltip--bodyLeftMediaRightChoicesMedia .editor-canvas-bottomBar-templateBtn-tooltip-choice{
	width: 60px;
	height: 60px;

	font-size: 34px;
	border-radius: 4px;

	display: flex;
	align-items: center;
	justify-content: center;
}

.editor-canvas-bottomBar-templateBtn-tooltip--bodyCenterChoicesMedia .editor-canvas-bottomBar-templateBtn-tooltip-choice img,
.editor-canvas-bottomBar-templateBtn-tooltip--bodyLeftMediaRightChoicesMedia .editor-canvas-bottomBar-templateBtn-tooltip-choice img{
	width: 65%;
	height: 65%;
}


.editor-canvas-bottomBar-templateBtn-tooltip--bodyCenterChoicesMedia .editor-canvas-bottomBar-templateBtn-tooltip-choice:nth-child(1),
.editor-canvas-bottomBar-templateBtn-tooltip--bodyLeftMediaRightChoicesMedia .editor-canvas-bottomBar-templateBtn-tooltip-choice:nth-child(1){
	background: transparentize(#F9C28B, 0.3);
}
.editor-canvas-bottomBar-templateBtn-tooltip--bodyCenterChoicesMedia .editor-canvas-bottomBar-templateBtn-tooltip-choice:nth-child(2),
.editor-canvas-bottomBar-templateBtn-tooltip--bodyLeftMediaRightChoicesMedia .editor-canvas-bottomBar-templateBtn-tooltip-choice:nth-child(2){
	background: transparentize(#ACD2A2, 0.4);

}
.editor-canvas-bottomBar-templateBtn-tooltip--bodyCenterChoicesMedia .editor-canvas-bottomBar-templateBtn-tooltip-choice:nth-child(3),
.editor-canvas-bottomBar-templateBtn-tooltip--bodyLeftMediaRightChoicesMedia .editor-canvas-bottomBar-templateBtn-tooltip-choice:nth-child(3){
	background: transparentize(#F2CFD8, 0.4);
}

.editor-canvas-bottomBar-templateBtn-tooltip--bodyCenterChoicesMedia .editor-canvas-bottomBar-templateBtn-tooltip-choice:nth-child(4),
.editor-canvas-bottomBar-templateBtn-tooltip--bodyLeftMediaRightChoicesMedia .editor-canvas-bottomBar-templateBtn-tooltip-choice:nth-child(4){
	background: transparentize(#C3E6EF, 0.4);
}