// 
// PREVIEW PANEL
//

.previewPanelcontainer--library,
.previewPanelcontainer--recent{
	height: 100%;
	width: 100%;
}

// inner spacer used for measuring width to pass to slideCarousel
.previewPanelcontainer-innerSpacer{
	width: 100%;
}

.previewPanel{
	width: 100%;
	height: 100%;
	
	box-sizing: border-box;

	overflow: visible;

	display: flex;
	flex-direction: column;

	position: relative;
}


.previewPanel-header{
	box-sizing: border-box;
	position: relative;
	margin-bottom: 18px;
}

// SET TITLE

@import 'LibraryAndRecent-PreviewPanel-TitleInput';

.previewPanel-questionPreview{
	margin-bottom: 10px;
}

// CONTAINING REPO LINK AND MOVE TO REPO BUTTON

a.previewPanel-header-containingRepoLink,
.previewPanel-header-movetoRepoBtn{
	font-size: 14px;
	font-weight: $lato-bold;
}

a.previewPanel-header-containingRepoLink{
	width: 100%;
	display: block;
	margin-top: 2px;
	color: $textMid;
	white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;	
}

a.previewPanel-header-containingRepoLink:hover{
	color: $textBlue;
}

.previewPanel-header-movetoRepoBtnContainer{
	margin-left: -4px;
	margin-top: -4px;
	margin-bottom: -4px;
	width: 100%;
	
	display: flex;
}

.previewPanel--folder .previewPanel-header-movetoRepoBtnContainer{
	margin-top: -2px;
	margin-bottom: -16px;
}

.previewPanel--set .previewPanel-header-movetoRepoBtnContainer{
	margin-top: -2px;
}

.previewPanel-header-movetoRepoBtn{
	padding: 4px;
	color: $textBlue;
	border-radius: 3px;
	cursor: pointer;
	// background: $tempr;
}

.previewPanel-header-movetoRepoBtn .icon.open-left-arrow{
	transform: rotate(180deg);
	height: 10px;
	width: 10px;
	fill: $blue;
	margin-left: 1px;
	margin-right: 5px;
	opacity: 0.65;
}

.previewPanel-header-movetoRepoBtn:hover{
	background: rgba(0,0,20,0.025);
}

.previewPanel-header-movetoRepoBtn:hover .icon.open-left-arrow{
	opacity: 1;
}





// MODIFIED

.previewPanel-header-modifiedLabel,
.previewPanel-header-modifiedDatetime {
	font-size: 13px;
	color: $textMid;
	user-select: none;
	cursor: default;
}

.previewPanel-header-modifiedLabel{
	font-weight: $lato-semibold;
	margin-right: 3px;
}

.previewPanel-header-modifiedDatetime{
	font-weight: $lato-medium;
}

.previewPanel:hover .previewPanel-header-modifiedLabel,
.previewPanel:hover .previewPanel-header-modifiedDatetime{
	color: $textDark;
}


// BUTTON ROW

.previewPanel-buttonRow {
	display: flex;
	min-height: 30px;
	padding-bottom: 10px;
	// border-bottom: 1px solid $lineMid;
}

.previewPanel-buttonRow .btn.edit,
.previewPanel-buttonRow .btn.play-now{
	flex-grow: 1;
	max-width: 100px;
	margin-right: 8px;
}

.previewPanel-buttonRow--viewOnly .btn.play-now{
	max-width: 125px;
	margin-right: auto;
}

.previewPanel-buttonRow .btn.edit{
	margin-right: auto;
}

.previewPanel-buttonRow .btn.open-folder{
	width: 140px;
	margin-right: auto;
}

.previewPanel-buttonRow .btn.menu{
	flex-grow: 0;
	width: 32px;
}

.previewPanel-buttonRow .btn.menu svg.icon{
	fill: $gray-8;
	width: 14px;
	height: 14px;
}
.previewPanel-buttonRow .btn.menu:hover svg.icon{
	fill: $blue;
}

.previewPanel.previewPanel--folder
.previewPanel-buttonRow{
	margin-top: 3px; 
	// for hint, this should be refactored later
}


.previewPanel-buttonRow .dropdownMenu.position--left{
	left: -160px;
}


// FROM TRASH

.previewPanel-buttonRow .btn.restore,
.previewPanel-buttonRow .btn.delete{
	flex-grow: 1;
	margin-right: 8px;
}	


// CAROUSEL

.previewPanel .slideCarousel-slide:hover{
	box-shadow: 0px 1px 2px 0px rgba(0,0,0,0.04),
							0px 5px 10px -5px rgba(0,0,0,0.1);
}

//


.previewPanel-pollsForItemListContainer{
	margin-top: 10px;
	width: 100%;
	display: flex;
	flex-wrap: wrap;
}

.previewPanel-pollsForItemListContainer .pollsForItemList-section{
	margin: 4px;
}
//

.previewPanel-addToQueueMenuContainer{
	position: absolute;
	bottom: 15px;
	left: 0px;
	right: 0px;
}

.previewPanel-addToQueueMenu{
	width: 100%;
	// margin: 0 auto;
	max-width: 200px;
}


.previewPanel button.add-to-queue{ // button strips out naming
	width: 100%;
}

.previewPanel-addToQueueMenu .dropdownMenu{
	width: 100%;
	min-width: 195px;

	max-height: calc(100vh - 110px);
	overflow: auto;
	overflow-y: auto;
	overflow-x: hidden;
}


// .slideCarousel .slideCarousel-setNavigator{
// 	opacity: 0;
// 	transition: opacity 50ms ease-in-out;
// }

// .slideCarousel:hover .slideCarousel-setNavigator{
// 	opacity: 1;
// 	transition: opacity 150ms ease-in-out;
// }