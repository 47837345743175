/// REPORT PACK PAGE HEAD

.printPackPageNumbers-current{
	color: $textBlackAbsolute;
	font-weight: $lato-heavy;
}

.printPackPageNumbers-total{
	font-weight: $lato-semibold;
	color: $textLight;
}


.printPackPageHead{
	
	position: absolute;
	display: flex;

	top: 8mm;
	left: $printPack-pageMargin--sideInner;
	right: $printPack-pageMargin--sideInner;
	
	font-size: 10pt;

	color: $gray-10;
	font-weight: $lato-medium;
	
}

.printPackPageHead-name{
	white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 150px;
  flex-grow: 1;
}

.printPackPageHead-name,
.printPackPageHead-pageNumbers{
	
}

.printPackPageHead-pageNumbers{
	width: 150px;
	flex-grow: 0;
	flex-shrink: 0;
	
	margin-left: auto;
	color: $textMid;
	display: flex;
	justify-content: flex-end;
	
}
