.backToSchoolModalContainer{
	position: fixed;
	z-index: 9999;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: rgba(5,5,10,0.75);
	
	display: flex;
	
	align-items: center;
	justify-content: center;
}

.backToSchoolModal{
	border-radius: 10px;
	
	background: rgb(248,248,254);
	border: 1px solid $gray-5;
	box-shadow: 
		0px 8px 16px 0px rgba(5,5,10,0.12),
		0px 16px 32px 0px rgba(5,5,10,0.08);

	box-sizing: border-box;

	overflow: hidden;

	width: 720px;

	padding-top: 90px;
	padding-bottom: 20px;
	padding-right: 40px;
	padding-left: 40px;

	position: relative;

	text-align: center;

	cursor: default;
}


.backToSchoolModal-limitedOfferSash{
	width: 272px;
	height: 34px;

	position: absolute;
	top: 30px;
	left: 50%;
	transform: translateX(-50%);

	font-weight: $effra-bold;
	color: $white;
	font-size: 19px;
	letter-spacing: 0.001em;

	line-height: 1.8;

	user-select: none;
	
}

.backToSchoolModal-limitedOfferSash svg{
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;

	z-index: -1;

	fill: $red;
}

.backToSchoolModal-h1{
	font-weight: $effra-bold;
	font-size: 42px;
	color: $textBlackDark;
	line-height: 1;
	margin-bottom: 18px;
}

.backToSchoolModal-h1 b{
	color: $textBlue;
}

.backToSchoolModal-h2{
	font-size: 23px;
	font-weight: $effra-bold;
	color: $textBlack;
	margin-bottom: 16px;
}

.backToSchoolModal-h3{
	font-size: 18px;
	font-weight: $effra-medium;
	color: $textDark;
	margin-bottom: 12px;
}

.backToSchoolModal button.btn{
	font-size: 20px;
	font-weight: $effra-bold;
	
	height: 50px;	
	cursor: pointer;
}

.backToSchoolModal--activeMonthly button.btn{
	width: 90%;
	margin: 0 auto;
	margin-bottom: 8px;
}

.backToSchoolModal--activeMonthly
.featureModal-selectControlGroup{
	margin: 0 auto;
	margin-top: 18px;
}


.backToSchoolModal-smallprint{
	font-size: 14px;
	font-weight: $effra-regular;
	color: $textDark;

	margin-top: 12px;
}

.backToSchoolModal--churnedPro{
	
}

.backToSchoolModal--churnedPro-upgradeBtnContainer{
	display: flex;
	width: 100%;
	margin-bottom: 8px;
}

button.btn.backToSchoolModal--churnedPro-upgradeBtn{
	display: block;
	flex-grow: 0;
	flex-shrink: 0;
	margin: 5px;
	height: 65px;
	line-height: 1.2;

	width: calc(50% - 10px); // cant use flexgrow because of submit loading
}

.backToSchoolModal--churnedPro-upgradeBtn-primary{
	// font-size: 15px;
}

.backToSchoolModal--churnedPro-upgradeBtn-sub{
	font-size: 17px;
	//margin-top: 1px;
	font-weight: $effra-medium;
	color: rgba(255,255,255,0.95);
}


.backToSchoolModal--churnedPro button.btn.btn--white{
	width: 90%;
	margin: 0 auto;
}


//

.backToSchoolModal--churnedPro-currentCardContainer{
	font-size: 16px;
	font-weight: $effra-medium;
	color: $textBlack;

	margin-top: 16px;
	margin-bottom: -6px;
}

.backToSchoolModal--churnedPro-currentCardContainer-link{
	text-decoration: underline;
	color: $textBlue;
	cursor: pointer;
}


///


// SUBMITLOADING

.backToSchoolModal--churnedPro-upgradeBtn-submitLoading{
	display: flex;
	align-items: center;
	justify-content: center;
}


.backToSchoolModal--churnedPro-upgradeBtn-submitLoading-spinnerContainer{
	width: 32px;
	height: 32px;	
	display: flex;
	align-items: center;
	justify-content: center;
}

.backToSchoolModal--churnedPro-upgradeBtn-submitLoading-spinner{

	height: 24px;
	width: 24px;
  
  border-radius: 50%;

  border: 3px rgba(0,0,0,0.2) solid;
  border-top: 3px $white solid;

  opacity: 0.75;

  animation: spinnerRotateAnimation 0.4s infinite linear;
}

