

//
// AUTHENTICATION MODAL
//

// A lot of custom positioning styling
// I think this is okay for now

.accountAuthentication-modal .modal-content{
  height: auto;
  min-height: 290px;
}

.accountAuthentication{
  display: flex;
  flex-direction: column;

  align-items: center;
  box-sizing: border-box;
  padding: 16px;

  text-align: center;
}

.accountAuthentication.is--add {
  padding: 30px;
}

.accountAuthentication.is--remove {
  padding: 30px;
}

.accountAuthentication-selectedExternalAccountCard{
  font-size: 15px;

  font-weight: $lato-bold;
  color: $textLight;

  margin-top: 10px;
  margin-bottom: 20px;

}
.accountAuthentication-selectedExternalAccountCard-card{
  width: 280px;
  height: 55px;

  margin-top: 10px;

  border-radius: 2px;
  border: 1px solid $lineDark;
  background: $white;

  box-sizing: border-box;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 12px;
  padding-bottom: 12px;

  display: flex;

  user-select: none;
  cursor: default;

  text-align: center;
  justify-content: center;
}

.accountAuthentication-selectedExternalAccountCard-card-details-name{
  font-size: 16px;
  font-weight: $lato-bold;
  color: $textDark;
  line-height: 1.0;
}

.accountAuthentication-selectedExternalAccountCard-card-details-email{
  font-size: 13px;
  font-weight: $lato-medium;
  color: $textLight;
  margin-top: 3px;
}

.accountAuthentication-selectExternalAccount-title {
  font-size: 15px;
  font-weight: $lato-bold;
  color: $textMid;
  margin-top: 10px;
}

.accountAuthentication-selectExternalAccount-title--noDetails {
  font-size: 18px;
  margin-top: 30px;
  margin-bottom: 30px;
}

.accountAuthentication-selectExternalAccount-buttonContainer {
  margin-top: 20px;
  margin-bottom: 30px;
}

.accountAuthentication-explanationText {
  margin: 40px auto 30px auto;
  font-size: 15px;
  font-weight: $lato-medium;
  color: $textDark;
}

.accountAuthentication.is--remove .accountAuthentication-signOutEverywhere {
  margin-bottom: 0px;
}

.accountAuthentication form button{
  position: relative;
}

.accountAuthentication.is--add .accountAuthentication-actionRow {
  margin-top: 20px;
}

.accountAuthentication .accountAuthentication-actionRow {
  margin-bottom: 10px;
  width: 230px;
}

.accountAuthentication .accountAuthentication-actionRow button {
  width: 100%;
  cursor: pointer;
}

.accountAuthentication .formField{
  width: 320px;
}

.accountAuthentication-error{
  width: 80%;
  margin: 0 auto;
  text-align: center;
  color: $red-dark;
  font-size: 15px;
  font-weight: $lato-semibold;
}

// checkox for sign out everywhere

.accountAuthentication-signOutEverywhere{
  margin-top: 10px;
  margin-bottom: -12px;
}

.accountAuthentication-signOutEverywhere .featureModal-selectControl-label{
  font-size: 14px;
  font-weight: $lato-semibold;
  letter-spacing: 0.005em;
  margin-top: 1px;
}

.accountAuthentication--update .accountAuthentication-signOutEverywhere{
  margin-top: 0px;
  margin-bottom: 8px;
}
