//
//
// SCORESHEET
// 
//

.scoreSheet * {
  box-sizing: border-box !important; 
}

// LAYOUT
$height-scoreSheetTopbar: 48px;

$width-scoreSheetSidePanel: 212px;
$defaultWidth-scoreSheetStudents: 208px;

$width-scoreSheetQuestion: 90px;
$width-scoreSheetCollapsedColumn: 140px;
$height-scoreSheetSidePanelCalendar: 215px;
$margin-studentsLeft: 16px;

// CELL DIMENSIONS
$height-scoreSheetSetHeader: 40px;
$height-scoreSheetQuestionHeader: 54px;

$height-scoreSheetResponseCell: 26px;

$height-scoreSheetSetFooter: 28px; // this is set score at bottom of head row

$height-scoreSheetXScrollBar: 0px;
$height-scoreSheetXScrollPlaceholder: 20px;

$height-scoreSheet-studentsHeader: calc(#{$height-scoreSheetSetHeader} + #{$height-scoreSheetQuestionHeader});
$height-scoreSheet-studentsFooter: calc(#{$height-scoreSheetSetFooter} + #{$height-scoreSheetXScrollPlaceholder} + 1px);

// COLORS

$scoreSheet-lineLight: rgb(239,239,242);
$scoreSheet-lineMid: rgb(204,204,216);

$color-scoreSheetCell-bg: rgb(255,255,255);
$color-scoreSheet-bg: rgb(250,250,255);

$color-scoreSheetCellZebra-bg: rgb(246,246,251);

$color-scoreSheetCellHover-bg: rgb(252,252,255);
$color-scoreSheetCellActive-bg: rgb(240,240,248);

$scoresheet-excluded-BG: rgb(252,252,255);
$scoresheet-excluded-color: $gray-12;
$scoresheet-excluded-cell-BG: rgb(252,252,255);
$scoresheet-excluded-BG-hover: $gray-14;

$bgColor-scoresheet-table-scrollbar: $gray-12;

//

.scoreSheetContainer{
  min-width: 1020px; // minimum supported dimension
  min-height: 500px; // minimum supported dimension
  margin: 0;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
  padding: 0;
  overflow-x: hidden;
  overflow-y: hidden;
}

.scoreSheet{
  position: absolute;
  top: 0px;
  bottom: 0px;  
  left: 0px;
  right: 0px;
  overflow: hidden;
}

@import 'ScoreSheet-TopBar'; // bar at the top with e.g. granularity buttons

.scoreSheet-content{
    position: absolute;
    top: $height-scoreSheetTopbar;
    left: $width-scoreSheetSidePanel;
    right: 0px;
    bottom: 0px;
    
    background: $white;
}

@import 'ScoreSheet-Students'; // this is the large student list section/"fixed" column

.scoreSheet-centerContainer{
  display: flex;
  position: absolute;
  top: $height-scoreSheetTopbar;
  left: $width-scoreSheetSidePanel;
  right: 0px;
  bottom: 0px;
}

.scoreSheet-table{
  height: 100%;

  flex-grow: 1;
  flex-shrink: 1;

  width: 20px; // initial for flex grow  

  background: $color-scoreSheet-bg;

  display: flex;

  overflow-x: scroll; // this is the key horizontal scroll element
  overflow-y: hidden;
  overflow-y: -moz-scrollbars-none;

  //Scroll snap didn't seem to work that well
  //scroll-snap-type: proximity;
  //scroll-snap-type: x mandatory;
  position: relative;
}


.scoreSheet-table-noData{
  // dispayed when class and date selections are made but no
  // data found, i.e. "No student results for your class and date selection"  
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  padding-bottom: 20vh;
  font-weight: $lato-bold;
  color: $textLight;
  opacity: 0.5;
  text-align: center;
}


// Custom scrollbar styling

.scoreSheet-table::-webkit-scrollbar-track{
  background-color: $bgColor-scoresheet-table-scrollbar;
}

.scoreSheet-table::-webkit-scrollbar{
  height: $height-scoreSheetXScrollPlaceholder;
  background-color: $bgColor-scoresheet-table-scrollbar;
}

.scoreSheet-table::-webkit-scrollbar-thumb{
  background-color: $white;
  border: 1px solid $gray-11; 
}


@import 'ScoreSheet-ReviewModal'; // popup showing individual question or set summary

@import 'ScoreSheet-Table-HeaderRow'; // row showing question title and set title
@import 'ScoreSheet-Table-FooterRow'; // footer row is at bottom of header row, showing question score
@import 'ScoreSheet-Table-ResponseRows'; // core rows in the middle of the table

@import 'ScoreSheet-SidePanel'; // contains section-selection list and calendar

@import 'ScoreSheet-CustomRangeModal.scss'; // available for pro users, can set custom timespan

@import 'ScoreSheet-EmptyState.scss'; // load state with shortcuts

