//
// LOCAL LATO FONTS

@font-face {
    font-family: "Lato";         
    src: local("PlickersLato-Medium.ttf") format("truetype");
    font-style: normal;
    font-display: auto;
    font-weight: $lato-medium;
    text-rendering: optimizeLegibility;
}


@font-face {
    font-family: "Lato";       
    src: local("PlickersLato-Semibold.ttf") format("truetype");
    font-style: normal;
    font-display: auto;
    font-weight: $lato-semibold;
    text-rendering: optimizeLegibility;
}


@font-face {
    font-family: "Lato";
    src: local("PlickersLato-Bold.ttf") format("truetype");
    font-style: normal;
    font-display: auto;
    font-weight: $lato-bold;
    text-rendering: optimizeLegibility;
}


@font-face {
    font-family: "Lato";    
    src: local("PlickersLato-Heavy.ttf") format("truetype");
    font-style: normal;
    font-display: auto;
    font-weight: $lato-heavy;
    text-rendering: optimizeLegibility;
}

@font-face {
    font-family: "Lato";    
    src: local("PlickersLato-Black.ttf") format("truetype");
    font-style: normal;
    font-display: auto;
    font-weight: $lato-black;
    text-rendering: optimizeLegibility;
}

// ITALIC
//

@font-face {
    font-family: "Lato";    
    src: local("PlickersLato-MediumItalic.ttf") format("truetype");
    font-style: italic;
    font-display: auto;
    font-weight: $lato-medium;
    text-rendering: optimizeLegibility;
}


@font-face {
    font-family: "Lato";    
    src: local("PlickersLato-SemiboldItalic.ttf") format("truetype");
    font-style: italic;
    font-display: auto;
    font-weight: $lato-semibold;
    text-rendering: optimizeLegibility;
}


@font-face {
    font-family: "Lato";    
    src: local("PlickersLato-BoldItalic.ttf") format("truetype");
    font-style: italic;
    font-display: auto;
    font-weight: $lato-bold;
    text-rendering: optimizeLegibility;
}


@font-face {
    font-family: "Lato";    
    src: local("PlickersLato-HeavyItalic.ttf") format("truetype");
    font-style: italic;
    font-display: auto;
    font-weight: $lato-heavy;
    text-rendering: optimizeLegibility;
}


@font-face {
    font-family: "Lato";       
    src: local("PlickersLato-BlackItalic.ttf") format("truetype");
    font-style: italic;
    font-display: auto;
    font-weight: $lato-black;
    text-rendering: optimizeLegibility;
}

// THAI
//
// look like thai gets quite thick, so we'll default to normal is semibold and rt bold being being

@font-face {
    font-family: "Noto Sans Thai";
    src: local("NotoSansThai-SemiBold.ttf") format("truetype");
    font-style: normal;
    font-display: auto;
    font-weight: $lato-bold;
    text-rendering: optimizeLegibility;
}

@font-face {
    font-family: "Noto Sans Thai";    
    src: local("NotoSansThai-Bold.ttf") format("truetype");
    font-style: normal;
    font-display: auto;
    font-weight: $lato-black;
    text-rendering: optimizeLegibility;
}

// Arabic

@font-face {
    font-family: "Noto Sans Arabic";
    src: local("NotoSansArabic-Bold.ttf") format("truetype");
    font-style: normal;
    font-display: auto;
    font-weight: $lato-bold;
    text-rendering: optimizeLegibility;
}

@font-face {
    font-family: "Noto Sans Arabic";    
    src: local("NotoSansArabic-Black.ttf") format("truetype");
    font-style: normal;
    font-display: auto;
    font-weight: $lato-black;
    text-rendering: optimizeLegibility;
}

// Hebrew

@font-face {
    font-family: "Noto Sans Hebrew";
    src: local("NotoSansHebrew-SemiBold.ttf") format("truetype");
    font-style: normal;
    font-display: auto;
    font-weight: $lato-bold;
    text-rendering: optimizeLegibility;
}

@font-face {
    font-family: "Noto Sans Hebrew";    
    src: local("NotoSansHebrew-ExtraBold.ttf") format("truetype");
    font-style: normal;
    font-display: auto;
    font-weight: $lato-black;
    text-rendering: optimizeLegibility;
}

// Noto Sans for other e.g. Turkish fallback

@font-face {
    font-family: "Noto Sans";
    src: local("NotoSans-Bold.ttf") format("truetype");
    font-style: normal;
    font-display: auto;
    font-weight: $lato-bold;
    text-rendering: optimizeLegibility;
}

@font-face {
    font-family: "Noto Sans";    
    src: local("NotoSans-Black.ttf") format("truetype");
    font-style: normal;
    font-display: auto;
    font-weight: $lato-black;
    text-rendering: optimizeLegibility;
}





.slideServicesPageContainer{
	position: fixed;
	top: 0px;
	left: 0px;
	right: 0px;
	bottom: 0px;

	// Multi-Language Support
	// Simplified Chinese, Korean, and Japanese	
	font-family: 'Lato', 'Noto Sans CJK {JP, KR, SC, TC}', 'Noto Sans Thai', 'Noto Sans Arabic', 'Noto Sans Hebrew', 'Noto Sans', sans-serif !important;
	-webkit-font-smoothing: antialiased;
	font-display: swap;
	// @import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@700&family=Noto+Sans+KR:wght@700&family=Noto+Sans+SC:wght@700&family=Noto+Sans+TC:wght@700&family=Noto+Sans+HK:wght@700&display=swap');
	// 	@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@700&display=swap');
	// 	@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@700&display=swap');
	// 	@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+SC:wght@700&display=swap');
	// 	@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+TC:wght@700&display=swap');
	// 	@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+HK:wght@700&display=swap');
	// }

	
}

// .slideServicesPageContainer * {
// 	font-family: 'Plickers Lato', 'Noto Sans JP', 'Noto Sans KR', 'Noto Sans SC' !important;
// }	

.slideServices-slideContainer{
	position: absolute;
	top: 0px;
	width: 1280px;
	height: 800px;
	overflow: hidden;
	z-index: 9999;
}

.slideServices-slideContainer .slide{
	border: 1px solid $invisible;
}

.slideServicesPageContainer-languageSet{
	position: absolute;
	left: 10px;
	top: 50px;
	width: 500px;
	font-weight: 700 !important;
}

.slideServicesPageContainer-languageSet div{
	margin-bottom: 20px;
}