$printPack-oneColumn-studentWorkingHeight: 120px;

//
// QUESTION
//

.printPackQuestion{
  width: 100%;
  position: relative;

  display: inline-block; //prevent column break

  padding-left: $printPack-pageMargin--counterWidth;
  box-sizing: border-box;

  //border-bottom: 1px solid $gray-14;

  page-break-inside: avoid;

  padding-top: 14px;
  padding-bottom: 20px;

  overflow-x: hidden; // to prevent latex overrunning the edges
}

.printPackQuestion-divider{
  position: absolute;
  left: $printPack-pageMargin--counterWidth;
  bottom: 0px;
  right: 0px;
  height: 1px;
  background: $gray-14;
}

.printPack--singleColumn
.printPackQuestion.printPackQuestion--includeWorkingSpace{
  padding-bottom: calc(#{$printPack-oneColumn-studentWorkingHeight} + 20px);
}

.printPackQuestion.printPackQuestion--includeWorkingSpace
.printPackQuestion-divider{
  display: none;
}

///

.printPack--singleColumn
.printPackQuestion-mainContentContainer{
  display: flex;
  //background: $tempb;
}

.printPack--singleColumn
.printPackQuestion-body{
  flex-grow: 1;
  flex-shrink: 1;
  width: 32%;
}


$printPack-question-imageSide: 150px;

.printPack--singleColumn
.printPackQuestion-imageContainer{
  flex-grow: 0;
  flex-shrink: 0;
  margin-top: 0px;
  padding-left: 10px;
  //max-height: $printPack-question-imageSide;
  //width: $printPack-question-imageSide;
  //background: $tempr;
}


.printPack--singleColumn
.printPackQuestion-choices{
  margin-top: 0px;
  flex-grow: 0;
  flex-shrink: 0;
  box-sizing: border-box;
  padding-left: 20px;
  width: 50%;
}


.printPack--twoColumn
.printPackQuestion-imageContainer{
  margin-top: 10px;
  //width: calc(50% - 20px);
  //background: $tempr;
}

.printPackQuestion-body-number{
  position: absolute;
  left: calc(-1 * (#{$printPack-pageMargin--sideInner} - #{$printPack-pageMargin--sideOuter}));
  width: $printPack-pageMargin--counterWidth;
  font-weight: $lato-bold;
  // color: $textBlue;
  color: $gray-9;
  text-align: right;
  //background: $tempr;
  box-sizing: border-box;
  padding-right: 12px; // distance to text
  top: 0px;
  font-size: $printPack-bodyFontSize;
}

.printPackQuestion-body{
  //padding-left: $printPack-counterPadding;
  font-weight: $lato-medium;
  color: $textBlackAbsolute;
  letter-spacing: 0.005em;
  position: relative;
  font-size: $printPack-bodyFontSize;
  line-height: 1.15;
}

.printPackQuestion-body-body{
  width: 100%;
}

// MEDIA SUPPORT

.printPackQuestion-body-video,
.printPackQuestion-body-sound{
  box-sizing: border-box;
  // width: calc(100% - 8px);
  // margin-left: 4px;

  margin-top: 4px;

  padding: 4px;
  padding-left: 24px;
  border-radius: 4px;
  // background: $blue-extraLight;

  position: relative;

  width: 100%;

  word-break: break-word; // for long URLs

  // font-weight: $lato-semibold;

}

.printPackQuestion-body-video svg{
  position: absolute;

  height: 16px;
  width: 16px;

  top: 3px;
  left: 4px;
}

.printPackQuestion-body-sound svg{
  position: absolute;

  height: 13px;
  width: 13px;

  top: 4px;
  left: 5px;

  fill: $blue;
}

.printPackQuestion-body-video-clip{
  margin-top: 2px;
  font-weight: $lato-medium;
  font-size: calc(#{$printPack-bodyFontSize} - 1px);
  color: $textDark;
}

//

.printPackQuestion-imageContainer img{
  display: block;
  max-width: $printPack-question-imageSide;
  max-height: $printPack-question-imageSide;
  width: auto;
  height: auto;
}

.printPackQuestion-imageContainer .printPackQuestion-ghostImageSpacer{
  width: $printPack-question-imageSide;
  height: $printPack-question-imageSide;
}

.printPackQuestion-imageContainer .printPackQuestion-ghostImageSpacer{
  display: none;
}

// HIDE IMAGE AND SHOW SPACER WHEN GHOSTS

.printPack--ghost .printPackQuestion-imageContainer .printPackQuestion-ghostImageSpacer{
  display: block;
}

.printPack--ghost .printPackQuestion-imageContainer img{
  display: none;
}

//
// RICH TEXT FORMATTING



//
// RULES LIKE BOLD AND ITALIC AND HIGHLIGHT
//


//maybe refactor
.printPackQuestion sup {
  vertical-align: super;
  font-size: smaller;
}


.printPackQuestion p{
  margin-block-start: 0px !important;
  margin-block-end: 0px !important;
}


//
// BOLD
//

.printPackQuestion-body b{
  font-weight: $lato-black;
  color: rgb(0,0,0);
}


.printPackQuestion-choice b{
  font-weight: $lato-heavy;
  color: rgb(0,0,0);
}

// CONVERT HIGHLIGHT INTO BOLD

.printPackQuestion-body span.highlight{
  font-weight: $lato-black;
  color: rgb(0,0,0);
}

.printPackQuestion-choice span.highlight{
  font-weight: $lato-heavy;
  color: rgb(0,0,0);
}


//
// ITALIC - TO DO
//

.printPackQuestion-body em,
.printPackQuestion-choice em{
  font-style: italic;
}
