

// SECTION HEADERS
.discover-market-featuredRepos-header,
.discover-market-subsectionRepos-header{
	
	text-transform: uppercase;

	user-select: none;
	cursor: default;
}

.discover-market-featuredRepos-header{
	color: $blue;
	color: $textBlack;

	font-size: 15.5px;
	font-weight: $lato-bold;

	margin-bottom: 16px;

}

.discover-market-subsectionRepos-header{
	font-size: 15.5px;
	font-weight: $lato-bold;
	
	// letter-spacing: 0.02em;
	margin-bottom: 12px;

	font-weight: $lato-bold;
	color: $textBlack;

	display: flex;
	align-items: center;
}

//
// SEE MORE BUTTON

button.discover-market-subsectionRepos-header-seeMoreBtn{
	margin-left: auto;
	height: 24px;

	background: none;
	outline: none;
	border: none;
	box-shadow: none;

	border-radius: 3px;
	padding-left: 8px;
	padding-right: 5px;
	margin-right: -8px;

	display: flex;
	align-items: center;
	justify-content: center;

	color: $blue;
	font-size: 14px;
	font-weight: $lato-semibold;


	cursor: pointer;
}

button.discover-market-subsectionRepos-header-seeMoreBtn svg{
	margin-left: 5px;
	height: 10px;
	width: 10px;

	fill: transparentize($blue, 0.75);
}

button.discover-market-subsectionRepos-header-seeMoreBtn:hover{
	color: darken($blue, 5%);
}

button.discover-market-subsectionRepos-header-seeMoreBtn:hover svg{
	fill: $blue;
}

button.discover-market-subsectionRepos-header-seeMoreBtn:active{
	background: $bgColor-activeRepoLink;
}

//
// FEATURED REPOS

.discover-market-featuredReposContainer{
	margin-bottom: 32px;
}

.discover-market-featuredRepos-list{
	display: flex;
	flex-wrap: wrap;

	width: calc(100% + 16px + 8px); // left padding and right padding on cards
	margin-left: -16px;
}




//
// SUBSECTION

.discover-market-subsectionReposContainer{
	position: relative;
	padding-top: 28px;

}

.discover-market-subsectionRepos-divider{
	width: calc(100% + 45px);
	height: 1px;
	background: $gray-125;
	position: absolute;
	top: 0px;
	left: 0px;
}

.discover-market-subsectionRepos-list{
	display: flex;
	flex-wrap: wrap;
	flex-direction: column;
	

	width: calc(100% + 12px + 14px); // aligned to featured repo list
	margin-left: -12px;

	padding-bottom: 20px;
}

.discover-market-subsectionRepos-list--9grid{
	height: calc(3 * #{$height-discover-market-miniRepoLink});
}

.discover-market-subsectionRepos-list--12grid{
	height: calc(4 * #{$height-discover-market-miniRepoLink});
}


