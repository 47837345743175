//
// STICKY DIVIER
// Used for sticky in-page sortable table headers in e.g. Library
// Unfortunately some styling code (e.g. stickyness ha) is in LibraryAndRecent-Cells
//

.stickyDivider{
	width: 100%;
	height: 24px;

	font-size: 13px;
	font-weight: $lato-medium;
	color: $textMid;

	border-bottom: 1px solid $lineDark;
	
	margin-bottom: 10px;
}

.stickyDivider.in--questionReport,
.stickyDivider.in--questionDetail{
	width: 100%;
	margin-bottom: 20px;
}

.stickyDivider.in--questionDetail{
	font-size: 15px;
	font-weight: $lato-semibold;
	height: 30px;
	color: $textMid;
	padding-top: 15px;
}

.stickyDivider.in--history,
.stickyDivider.in--recent {
	font-size: 16px;
	font-weight: $lato-semibold;
	height: 20px;
	border-bottom: 1px solid $invisible;
	line-height: 1.8;
	padding-left: 2px;
	color: $textMid;	
	box-sizing: border-box;
}

.stickyDivider.in--history{
	margin-bottom: 24px;
}

.stickyDivider-reveal{
	float: right;
	color: $blue;
	cursor: pointer;
}

.stickyDivider-reveal:hover{
	color: $blue-dark;
}

// SORTABLE COLUMN HEADERS
.stickyDivider.in--library .icon,
.stickyDivider.in--sectionStudents .icon,
.stickyDivider.in--repo .icon {
	height: 11px;
	width: 11px;
	position: relative;
	left: 5px;
	top: 2px;
}

.stickyDivider.in--library .sortableColumnHeaders-name .icon,
.stickyDivider.in--repo .sortableColumnHeaders-name .icon{
	top: 7px; // refactor this later
}

.stickyDivider.in--library .icon,
.stickyDivider.in--sectionStudents .icon,
.stickyDivider.in--repo .icon {
	fill: $textLight;
	opacity: 0.3;
}

.sortableColumnHeaders-name:hover,
.sortableColumnHeaders-modified:hover,
.sortableColumnHeaders-firstName:hover,
.sortableColumnHeaders-lastName:hover,
.sortableColumnHeaders-card:hover {
	color: $textBlack;
}

.sortableColumnHeaders-name:hover .icon,
.sortableColumnHeaders-modified:hover .icon,
.sortableColumnHeaders-firstName:hover .icon,
.sortableColumnHeaders-lastName:hover .icon,
.sortableColumnHeaders-cardNo:hover .icon {
	fill: $blue;
	opacity: 1;
}

.stickyDivider .icon.chevron-small-down,
.stickyDivider.in--sectionStudents .icon.chevron-small-down {
	left: calc(5px - 11px);	
}

// HIDE ALL ARROWS IF NOT ACTIVE
.stickyDivider.in--library .is--sortedByDate .sortableColumnHeaders-name .icon,
.stickyDivider.in--library .is--sortedByName .sortableColumnHeaders-modified .icon,
.stickyDivider.in--repo .is--sortedByDate .sortableColumnHeaders-name .icon,
.stickyDivider.in--repo .is--sortedByName .sortableColumnHeaders-modified .icon,
.stickyDivider.in--sectionStudents .is--sortedByfirstName .sortableColumnHeaders-lastName .icon,
.stickyDivider.in--sectionStudents .is--sortedByfirstName .sortableColumnHeaders-cardNo .icon,
.stickyDivider.in--sectionStudents .is--sortedBylastName .sortableColumnHeaders-firstName .icon,
.stickyDivider.in--sectionStudents .is--sortedBylastName .sortableColumnHeaders-cardNo .icon,
.stickyDivider.in--sectionStudents .is--sortedBycard .sortableColumnHeaders-firstName .icon,
.stickyDivider.in--sectionStudents .is--sortedBycard .sortableColumnHeaders-lastName .icon {
	visibility: hidden;
}

// SHOW CORRECT ARROW BASED ON ORDER

.stickyDivider.in--library
.is--sortedAscending .icon.chevron-small-down,
.stickyDivider.in--repo
.is--sortedAscending .icon.chevron-small-down{
	visibility: hidden;
}

.stickyDivider.in--library
.is--sortedDescending .icon.chevron-small-up,
.stickyDivider.in--repo
.is--sortedDescending .icon.chevron-small-up{
	visibility: hidden;
}

.stickyDivider.in--sectionStudents
.is--sortedAscending .icon.chevron-small-up{
	visibility: hidden;
}

.stickyDivider.in--sectionStudents
.is--sortedDescending .icon.chevron-small-down{
	visibility: hidden;
}
