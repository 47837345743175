//TOPBAR



.editor-topBar{
	background: rgb(249,249,254);
	border-bottom: 1px solid rgb(210,214,218);
	box-shadow: 0px 2px 2px 0px rgba(0,0,20,0.05);

	display: flex;
}

.editor-topBar-left,
.editor-topBar-right{
	width: 325px;
	flex-grow: 1;
	flex-shrink: 1;
	display: flex;
	align-items: center;

	box-sizing: border-box;

	// background: $tempb;
}

.editor-topBar-left{
	padding-left: 8px;
}

.editor-topBar-right{
	justify-content: flex-end;
	padding-right: 8px;
}

.editor-topBar-center{
	flex-grow: 2;
	flex-shrink: 0;

	display: flex;
	align-items: center;
	justify-content: center;

	position: relative;

	// background: $tempr;
	width: 400px;
	max-width: 500px;
}

// QUESTION EDITOR
.editor-topBar--questionEditor .editor-topBar-left,
.editor-topBar--questionEditor .editor-topBar-right{
	width: 500px;
}

.editor-topBar--questionEditor
.editor-topBar-center{
	width: 180px; // more space required for convert to set and copy to clipboard buttons
}

//
// SET TITLE INPUT 
//
@import 'Editor-TopBar-SetTitleInput';

// QUESTION EDITOR TITLE





//


button.editor-topBar-btn{
	height: 30px;
	width: 36px;
	border-radius: 3px;
	background: linear-gradient($white, rgb(251,251,251));

	border: 1px solid rgb(225,225,230);
	border-bottom: 1px solid rgb(218,218,224);

	cursor: pointer;

	display: flex;
	align-items: center;
	justify-content: center;

	font-size: 14px;
	
	font-weight: $lato-bold;
	color: rgb(39,39,51);
	line-height: 1.25;

	position: relative;

	user-select: none;
}

button.editor-topBar-btn:hover{
	background: $white;
	border: 1px solid darken(rgb(225,225,230), 6%);
	border-bottom: 1px solid darken(rgb(218,218,224), 7%);
}

button.editor-topBar-btn svg{
	width: 16px;
	height: 16px;
	fill: $blue;
}

button.editor-topBar-btn:hover svg{
	fill: darken($blue, 5%);	
}

button.editor-topBar-btn:active{
	background: #F5FAFF;	
	border: 1px solid darken(rgb(225,225,230), 8%);
	border-bottom: 1px solid darken(rgb(218,218,224), 10%);
}

button.editor-topBar-btn:active svg{
	fill: darken($blue, 8%);	
}

button.editor-topBar-btn:disabled{
	pointer-events: none;
	opacity: 0.5;
}

button.editor-topBar-btn:disabled svg{
	fill: $gray-7;
}

//

button.editor-topBar-btn--menu{
	width: 36px;
	margin-right: 6px;
}

.editor-topBar-left .dropdownMenu{
	left: 0px;
	top: 32px;
	width: 230px;
}

// override, show cursor
.editor-topBar-left .dropdownMenu,
.editor-topBar-left .dropdownMenu *{
	cursor: pointer;
}


// used for undo-=redo
.editor-topBar-btnGroup{
	display: flex;
}

.editor-topBar-btnGroup--undoRedo{
	margin-left: 12px;
}

.editor-topBar-btnGroup--undoRedo button.editor-topBar-btn--undo{
	margin-right: 0px;
	border-top-right-radius: 0px;
	border-bottom-right-radius: 0px;
}

.editor-topBar-btnGroup--undoRedo button.editor-topBar-btn--redo{
	margin-left: -1px;
	border-top-left-radius: 0px;
	border-bottom-left-radius: 0px;
}

button.editor-topBar-btn--undo,
button.editor-topBar-btn--redo{
	width: 48px;
}

button.editor-topBar-btn--undo svg,
button.editor-topBar-btn--redo svg{
	height: 18px;
	width: 18px;
}


// too specific
.editor-topBar-btnGroup--undoRedo button.editor-topBar-btn--disabled{
	opacity: 0.5;
	pointer-events: none; // will need to refactor to allow for tooltips
}

.editor-topBar-btnGroup--undoRedo button.editor-topBar-btn--disabled svg{
	fill: $gray-7;
}

//





button.editor-topBar-btn--newSlide{
	width: calc(#{$width-editor-slideList} - 6px - 36px - 8px;);
}

button.editor-topBar-btn--newSlide:focus{
	background: $blue;
	border: 1px solid darken($blue, 5%);
}

button.editor-topBar-btn--newSlide:focus svg{
	fill: $white;
}

button.editor-topBar-btn--addToClass{
	padding-left: 14px;
	padding-right: 14px;
	width: auto;
	margin-right: 6px;
	// keep padding for internationalization
}

button.editor-topBar-btn--print svg{
	width: 15px;
	height: 15px;
}

button.editor-topBar-btn--print,
button.editor-topBar-btn.editor-topBar-btn--importQuestions{
	width: 42px;
}

button.editor-topBar-btn--print svg,
button.editor-topBar-btn--importQuestions svg #boxOutline,
button.editor-topBar-btn--importQuestions svg #arrow{
	fill: $gray-5;
}

button.editor-topBar-btn--importQuestions svg #boxFill{
	fill: $gray-14;
}

button.editor-topBar-btn--print:hover svg,
button.editor-topBar-btn--importQuestions svg:hover #boxOutline,
button.editor-topBar-btn--importQuestions svg:hover #arrow{
	fill: $gray-3;
}

button.editor-topBar-btn--importQuestions:hover svg #boxFill{
	fill: $gray-15;
}


button.editor-topBar-btn--print:active,
button.editor-topBar-btn--importQuestions:active{
	background: $gray-14;	
	border: 1px solid darken(rgb(225,225,230), 8%);
	border-bottom: 1px solid darken(rgb(218,218,224), 10%);
}


button.editor-topBar-btn.editor-topBar-btn--print--printAndImport{
	border-top-left-radius: 0px;
	border-bottom-left-radius: 0px;
	margin-left: -1px;

	position: relative;
	z-index: 1;
}

button.editor-topBar-btn.editor-topBar-btn--importQuestions{
	border-top-right-radius: 0px;
	border-bottom-right-radius: 0px;
	position: relative;
	z-index: 0;
}

button.editor-topBar-btn.editor-topBar-btn--importQuestions:hover{
	z-index: 5;
}

// QUESTION EDITOR BUTTONS

button.editor-topBar-btn--textBtn{
	padding-left: 12px;
	padding-right: 12px;
	width: auto;	
}

.editor-topBar--questionEditor .editor-topBar-left button.editor-topBar-btn--textBtn:nth-child(3){
	margin-right: 6px;
}

.editor-topBar--questionEditor .editor-topBar-btnGroup--undoRedo{
	margin-left: 0px;
	margin-right: 6px;
}


// TOOLTIPS

.editor-topBar-tooltip{	
  position: absolute;
  
  top: 32px;

  background: lighten(#282636, 10%);
  z-index: 10;
  pointer-events: none;

  left: 50%;
  transform: translate(-50%, 0);

  padding-top: 5px;
  padding-bottom: 6px;
  padding-left: 7px;
  padding-right: 7px;

  border-radius: 3px;
  opacity: 0;
  
  font-size: 12px;
  font-weight: $lato-bold;
  letter-spacing: 0.025em;
  color: $white;

  white-space: nowrap;
  visibility: hidden;

  // visibility: visible;
  // opacity: 1;
}


.editor-topBar:hover button:hover .editor-topBar-tooltip{
	visibility: visible;
	
}

.editor-topBar:hover .editor-topBar-tooltip{
	opacity: 0.95;
	transition: opacity 100ms linear;
	transition-delay: 500ms;
}


.editor-topBar-btn--print .editor-topBar-tooltip{
	margin-left: -32px;
}


// ADD TO QUEUE BTN

.editor-topBar-right{
	// background: $tempb;
}

.editor-topBar-right button.btn.btn--mid.addToClassDropdownButton{
	
	height: 30px;
	border-radius: 3px;
	background: linear-gradient($white, rgb(251,251,251));

	border: 1px solid rgb(225,225,230);
	border-bottom: 1px solid rgb(218,218,224);

	cursor: pointer;

	display: flex;
	align-items: center;
	justify-content: center;	

	font-size: 14px;
	
	font-weight: $lato-bold;
	color: rgb(39,39,51);
	line-height: 1.25;

	position: relative;

	user-select: none;

	flex-shrink: 0;

	margin-right: 6px;
	min-width: 0px;

	padding-left: 12px;
	padding-right: 12px;

	margin-left: 6px;

	white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
	
}

.editor-topBar-right button.btn.btn--mid.addToClassDropdownButton:disabled{
	pointer-events: none;
	opacity: 0.5;
}

// poll list
.editor-topBar-right .pollsForItemList-section{
	width: 20px;
	cursor: pointer;
	position: relative;
	z-index: 300;
	height: 18px;

	margin-right: 2px;
	margin-left: 2px;

	border-radius: 3px;

	flex-shrink: 1;
	flex-grow: 0;
}

.editor-topBar-right .pollsForItemList-section-name{
	position: absolute;
	top: 21px;

	height: 22px;
	left: 50%;
	transform: translateX(-50%);
	width: auto;

	margin: 0 auto;

	

	padding-left: 8px;
	padding-right: 8px;

	font-size: 13.5px;
	font-weight: $lato-bold;

	border-radius: 3px;

	pointer-events: none;
	opacity: 0;

	transition: all 50ms linear;
}


.editor-topBar-right .pollsForItemList-section:hover
.pollsForItemList-section-name{
	opacity: 1;	

	transition: opacity 100ms linear;

}

.editor-topBar-right .pollsForItemList-section-playContainer{
	background: none;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;
	opacity: 1;
	border-radius: 3px;
}

.editor-topBar-right .pollsForItemList-section-playContainer{
	background: none;
}

.editor-topBar-right .pollsForItemList-section-playContainer svg{
	left: 2px;
	height: 8px;
	width: 8px;
}

.editor-topBar-right .pollsForItemList-section-playContainer{
	opacity: 0.75;
	opacity: 0;
}

.editor-topBar-right .pollsForItemList-section:hover .pollsForItemList-section-playContainer{
	opacity: 1;
}

.editor-topBar-right .pollsForItemList-section--classColor--blue .pollsForItemList-section-name{
	background: $classColor-blue;
}
.editor-topBar-right .pollsForItemList-section--classColor--purple .pollsForItemList-section-name{
	background: $classColor-purple;
}
.editor-topBar-right .pollsForItemList-section--classColor--red .pollsForItemList-section-name{
	background: $classColor-red;
}
.editor-topBar-right .pollsForItemList-section--classColor--orange .pollsForItemList-section-name{
	background: $classColor-orange;
}
.editor-topBar-right .pollsForItemList-section--classColor--green .pollsForItemList-section-name{
	background: $classColor-green;
}
.editor-topBar-right .pollsForItemList-section--classColor--sky .pollsForItemList-section-name{
	background: $classColor-sky;
}
.editor-topBar-right .pollsForItemList-section--classColor--pink .pollsForItemList-section-name{
	background: $classColor-pink;
}
.editor-topBar-right .pollsForItemList-section--classColor--teal .pollsForItemList-section-name{
	background: $classColor-teal;
}
.editor-topBar-right .pollsForItemList-section--classColor--grey .pollsForItemList-section-name{
	background: $classColor-grey;
}
.editor-topBar-right .pollsForItemList-section--classColor--none .pollsForItemList-section-name{
	background: $classColor-blue;
}



// $classColor-blue: 	rgb(54,153,249);
// $classColor-purple: rgb(181,120,221);
// $classColor-red: 		rgb(243,127,130);
// $classColor-orange: rgb(255,170,112);
// $classColor-green:	rgb(49,201,157);
// $classColor-sky:		rgb(88,199,249);
// $classColor-pink:		rgb(247,145,201);
// $classColor-teal:		rgb(0,191,205);
// $classColor-grey:		rgb(128,129,142);

// $classColor-none: 	$classColor-blue;
