//
// MATH AND SCIENCE
// contains KaTeX tweaks and friendly fractions
//

$selectionBlue: rgba(0,116,255,0.29);
$equationSelectionBlueOpaque: #BFD7FF;
$hoverEquationBG: lighten(#DEEAFF, 3%);

//
// KATEX TWEAKS

.ProseMirror-selectednode{
  outline: none !important;
}

.katexOuterContainer{
  display: inline-block;
  margin: 0;
  position: relative;
  text-align: center;
  white-space: nowrap; // breaks ghost horizontal stuff
}


.katexInnerContainer{
  border: solid 1px transparent;
  display: inline-block;
  padding: 0 2px;
  user-select: none;
}

.katexOuterContainer.ProseMirror-selectednode .katexInnerContainer{
  background: $equationSelectionBlueOpaque;
}

.katexInnerContainer-imageSpacer{
  bottom: 0;
  left: 0;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.katexInnerContainer-content{
  pointer-events: none;
}

.katexInnerContainer-content .katex {
  font-display: block;
}

.slide.slide--editor .katexOuterContainer{
  cursor: pointer;
}

.slide.slide--editor .katexOuterContainer:hover:not(.ProseMirror-selectednode){
  background: $hoverEquationBG;
  box-shadow: 0px 0px 0px 3px $hoverEquationBG;
}

.slide.slide--editor .katexOuterContainer:active:not(.ProseMirror-selectednode){
  background: darken($hoverEquationBG, 5%);
  box-shadow: 0px 0px 0px 3px darken($hoverEquationBG, 5%);
}

.katex-container{
  white-space: nowrap; //prevents wrapping
}


//
// FRIENDLY FRACTIONS

.friendlyFraction {
  // background: red;
  display: inline-block;
  position: relative;
  vertical-align: middle; 
  letter-spacing: 0.001em;
  text-align: center;

  // background: $tempr;
}

.friendlyFraction-denominator,
.friendlyFraction-numerator{
  display: block;
  min-width: 0.9em;
  padding: 0.03em; 

  min-height: 1em;
}

.friendlyFraction-denominator--empty,
.friendlyFraction-numerator--empty{
  background: $blue-extraLight;
}


.friendlyFraction-denominator{
  border-top: 0.07em solid black;
}

