
////
//// DESCRIPTION
//// 

$height-publishingFlowModalDecsription: 215px;

.publishingFlowModalPage--description textarea{
	margin-top: 15px;

	height: $height-publishingFlowModalDecsription;
	width: 700px;
	resize: none;
  
  border: 1px solid $lineDark;
  border-radius: 3px;
  padding-left: 8px;
  padding-top: 6px;
  padding-right: 8px;

  line-height: 1.35;
  
  box-sizing: border-box;

  font-size: 16px;
  font-weight: $lato-medium;
  color: $textBlack;
  outline: none;
}

.publishingFlowModalPage--description textarea:focus{
   border: 1px solid $blue;
}

.publishingFlowModalPage--description textarea::-webkit-input-placeholder {
   color: $textInputPlaceholder !important;
   font-size: 15px;
   font-weight: $lato-medium;
   user-select: none;
}

// Character Count for description

.publishingFlowModalPage--description-characterCount{
	margin-top: calc(-1 * #{$height-publishingFlowModalDecsription});
	height: 30px;
	padding-right: 50px;

	font-size: 14.5px;

	text-align: right;

	font-weight: $lato-medium;
	color: $textLight;
	opacity: 0;

	user-select: none;
	cursor: default;
}

.publishingFlowModalPage--description-characterCount--max{
	font-weight: $lato-bold;
	color: $textBlack;
}

.publishingFlowModalPage--description-characterCount--show{
	opacity: 1;
}

