.repoReleaseModal-header-h1{
	font-size: 41px;
	font-weight: $effra-bold;

	color: $textBlue;

	margin-top: 32px;
	margin-bottom: 6px;

	text-align: center;

}

.repoReleaseModal-header-h2{
	font-size: 31px;
	font-weight: $effra-bold;	

	color: $textBlack;

	margin-bottom: 16px;

	text-align: center;
}

.repoReleaseModal-header-textBlocks{
	display: flex;
	width: 860px;

	margin: 0 auto;

	align-items: flex-start;
	justify-content: space-between;


}

.repoReleaseModal-header-textBlock{
	width: 33.333%;

	box-sizing: border-box;

	padding-left: 13px;
	padding-right: 13px;

	flex-grow: 0;
	flex-shrink: 0;

	// background: $tempb;

	height: 75px;

	font-size: 15px;
	font-weight: $effra-regular;
	color: $textBlack;	
}


.repoReleaseModal-header-textBlock b{
	font-weight: $effra-bold;
}