
// .onboardingWizardTask--completed{
// 	background: $tempr;
// 	border: 1px solid red;
// 	box-sizing: border-box;
// }

// .onboardingWizardTask--incomplete{
// 	background: $tempb;
// 	border: 1px solid blue;
// 	box-sizing: border-box;
// }

// .onboardingWizard-header{
// 	background: $tempg;
// 	border: 1px solid green;
// 	box-sizing: border-box;
// }


.onboardingWizard{
	position: fixed;
	bottom: 10px;
	right: 4vw; // refactor later!

	width: 380px;

	box-sizing: border-box;
	padding: 26px;
	padding-top: 20px;
	padding-bottom: 20px;

	background: $white;
	//background: linear-gradient($white, $gray-14);

	border: 1px solid $lineDark;
	border-radius: 12px;
	box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.12);

	z-index: 400;

	cursor: default;
	user-select: none;
}


/// ANIMATE SHOW
// @keyframes onboardingWizardEnter {
//   0% { 
//   	opacity: 0;
//   	transform: translateY(100px);
//    }  
//   75% {
//   	opacity: 1;
//   }
//   100% {  	  	
//   	transform: translateY(0px);
//   }
// }

// .onboardingWizard{
// 	animation-name: onboardingWizardEnter;
//  	animation-duration: 600ms;
//  	animation-iteration-count: 1;
//  	animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.075);
//  	animation-fill-mode: backwards;
// }
 


/// HEADER

.onboardingWizard-header{
	width: 100%;
	display: flex;
	height: 80px;
}

.onboardingWizard-header-left{
	flex-grow: 1;
	flex-shrink: 1;
}

.onboardingWizard-header-right{
	flex-grow: 0;
	flex-shrink: 0;
	width: 70px;
	display: flex;
	justify-content: flex-end;
}

.onboardingWizard-header-left-h1{
	font-size: 20px;
	font-weight: $lato-heavy;
	color: $textBlackDark;
	
}

.onboardingWizard-header-left-h2{
	font-size: 15px;
	font-weight: $lato-medium;
	color: $textLight;
	margin-top: 5px;
}

.onboardingWizard-header-left-progressBar{
	margin-top: 10px;
	width: 100%;
	border-radius: 2px;
	height: 4px;
	overflow: hidden;
	position: relative;
}

.onboardingWizard-header-left-progressBar-bar,
.onboardingWizard-header-left-progressBar-track{
	position: absolute;
	left: 0px;
	top: 0px;
	bottom: 0px;
}

.onboardingWizard-header-left-progressBar-bar{
	width: 40%;
	background: $green;
	z-index: 5;
}

.onboardingWizard-header-left-progressBar-track{
	background: $green;
	opacity: 0.2;
	right: 0px;
}


.onboardingWizard-header-right-mark{
	width: 100px;
	margin-top: -4px;

	position: relative;
	//background: $tempr;
}

.onboardingWizard-header-right-mark .icon{
	width: 65px;
	height: 65px;
	position: absolute;
	top: 3px;
	left: 12px;
}

/// TASK


.onboardingWizardTask{
	width: 100%;
	position: relative;
	padding-left: 22px;
	box-sizing: border-box;
	padding-top: 8px;
	
}

// .onboardingWizardTask--incomplete{
// 	height: 95px;
// }

.onboardingWizardTask--completed{
	pointer-events: none;
}

.onboardingWizardTask-h1{
	font-size: 15.5px;
	font-weight: $lato-bold;
	color: $textBlackDark;
	height: 17px;
}


// .onboardingWizardTask--completed .onboardingWizardTask-h1{	
// 	color: $textLight;
// 	opacity: 0.75;
// }

// .onboardingWizardTask--completed{
// 	display: none;
// }

.onboardingWizardTask-body{
	margin-bottom: 4px;
	margin-top: -2px;
	font-size: 14px;
	font-weight: $lato-medium;
	color: $textMid;
	line-height: 1.4;
}




/// TASK MARKER

.onboardingWizardTask-statusMark{
	width: 14px;
	height: 14px;
	border-radius: 4px;

	position: absolute;
	top: 9px;
	left: -2px;
	box-sizing: border-box;
}

.onboardingWizardTask--incomplete
.onboardingWizardTask-statusMark{
	border: 1px solid $gray-9;
	background: $white;
	display: flex;
	align-items: center;
	justify-content: center;
}

.onboardingWizardTask--incomplete
.onboardingWizardTask-statusMark .icon{
	height: 10px;
	width: 10px;
	fill: $gray-9;
}
//

.onboardingWizardTask--completed
.onboardingWizardTask-statusMark{
	background: $gray-11;
	border: 2px solid $gray-11;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 3px;
}

.onboardingWizardTask--completed
.onboardingWizardTask-statusMark .icon{
	height: 10px;
	width: 10px;
	fill: $white;
}


/// TASKBUTTON

button.onboardingWizardTask-button{
	box-shadow: none;
	-webkit-appearance: none;
	border: none;
	border-radius: 2px;
	outline: none;
	background: none;
	
	font-size: 14px;
	font-weight: $lato-semibold;
	
	padding-left: 4px;
	padding-right: 4px;

	height: 24px;
	line-height: 1.3;

	margin-left: -4px;
	margin-right: 4px;

	cursor: pointer;
}

button.onboardingWizardTask-button .icon{
	height: 8px;
	width: 8px;
}

//


button.onboardingWizardTask-button--primary{
	color: $blue;
}
button.onboardingWizardTask-button--primary:hover{
	background: $blue-extraLight;
}
button.onboardingWizardTask-button--primary .icon{
	fill: $blue;
}

//

button.onboardingWizardTask-button--secondary{
	color: $blue-light;
}

button.onboardingWizardTask-button--secondary:hover{
	background: $blue-extraLight;
}

button.onboardingWizardTask-button--secondary .icon{
	fill: $blue-light;
}

button.onboardingWizardTask-button--primary:active,
button.onboardingWizardTask-button--secondary:active{
	background: transparentize($blue-light, 0.75);
}


//

button.onboardingWizardTask-button--green{
	color: $green;

}

button.onboardingWizardTask-button--labelOnly{
	cursor: default;
}


button.onboardingWizardTask-button--labelOnly:hover{
	background: none;
}

button.onboardingWizardTask-tempbutton{
	width: 20px;
	height: 20px;
	
	box-shadow: none;
	-webkit-appearance: none;
	border: none;
	border-radius: 2px;
	outline: none;
	background: none;
	position: absolute;
	bottom: 0px;
	left: 0px;
}

button.onboardingWizardTask-tempbutton:hover{
	background: $tempr;
}



/// SUCCESS

.onboardingWizard-success-h1{
	font-size: 16.5px;
	font-weight: $lato-bold;
	color: $textBlackDark;
	padding-top: 1px;
	margin-bottom: 16px;
}

.onboardingWizard-success-item{
	display: flex;
	margin-bottom: 11px;
}

.onboardingWizard-success-item-number{
	width: 20px;
	flex-shrink: 0;
	flex-grow: 0;
	font-size: 15px;
	font-weight: $lato-medium;
	line-height: 1.3;
	color: $textLight;
}

.onboardingWizard-success-item-label{
	font-size: 15px;
	color: $textBlack;
	font-weight: $lato-medium;
	line-height: 1.3;
}


.onboardingWizard button.success-done{
	width: 100%;
	margin: 0 auto;
	margin-top: 24px;
}