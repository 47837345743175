.joinCreatorProgramModalContainer{
	position: fixed;
	z-index: 9999;
	top: 0;
	left: 0;
	right: 0;
	height: 100vh;
	
	background: rgba(255,255,255,0.75);
	background: rgba(0,0,10,0.75);
	
	display: flex;
	align-items: center;
	justify-content: center;

	overflow: hidden;
}

.joinCreatorProgramModal{
	border-radius: 5px;
	
	background: rgb(252,253,255);
	border: 1px solid $gray-4;
	box-shadow: 
		0px 2px 4px 0px rgba(5,5,10,0.50),
		0px 8px 16px 0px rgba(5,5,10,0.25) ;

	box-sizing: border-box;
	
	overflow: hidden;

	min-width: 980px;
	width: calc(100vw - 120px);
	max-width: 1160px;

	height: 640px;

	position: relative;
	
	display: flex;
}

$leftPadding-joinCreatorProgramModal: 90px;

.joinCreatorProgramModal-pageContainer{
	width: 750px;
	// width: 350px;

	height: 100%;

	box-sizing: border-box;
	padding-left: $leftPadding-joinCreatorProgramModal;
	padding-right: 70px;
	padding-top: 90px;
	flex-grow: 0;
	flex-shrink: 0;

	cursor: default;
}

.joinCreatorProgramModal-header{
	font-size: 32px;
	font-weight: $lato-heavy;
	color: $textBlack;
}

.joinCreatorProgramModal-subheader{
	margin-top: 10px;
	font-size: 21px;
	font-weight: $lato-bold;
	color: $textDark;
}





//
// INTRO PAGE
//

.joinCreatorProgramModal--intro-topLabel{
	font-weight: $lato-bold;
	color: $textBlue;
	font-size: 16px;
	margin-bottom: 5px;

	position: absolute;
	left: $leftPadding-joinCreatorProgramModal;
	top: 50px;

}

.joinCreatorProgramModal--intro-howItWorks{
	margin-top: 26px;
	font-weight: $lato-semibold;
	color: $textLight;
	font-size: 12.5px;

	text-transform: uppercase;
	letter-spacing: 0.002em;
	margin-bottom: 12px;
}

//
// INTRO PAGE BULLETS
// AND GUIDELINES GUIDE BLOCK
//

.joinCreatorProgramModal--intro
.joinCreatorProgramModal-pageContainer{
	padding-right: 115px;
}

.joinCreatorProgramModal--intro-bulletItem{
	position: relative;
	margin-bottom: 20px;

	box-sizing: border-box;
	padding-right: 100px;
}


.joinCreatorProgramModal--intro-bulletItem-title{
	font-weight: $lato-bold;
	font-size: 17px;
	color: $textBlack;
	margin-top: 21px;
}



.joinCreatorProgramModal--intro-bulletItem-label{
	font-size: 15px;
	font-weight: $lato-medium;
	color: $textDark;
	margin-top: 4px;
}

.joinCreatorProgramModal--intro-bulletItem-label--two,
.joinCreatorProgramModal--guidelines-grid-block-label--two{
	margin-top: 5px;
}

.joinCreatorProgramModal--guidelines
.joinCreatorProgramModal-pageContainer{
	padding-right: 20px;
}

.joinCreatorProgramModal--guidelines-grid{
	display: flex;
	
	flex-wrap: wrap;
	
	width: 100%;
	margin-top: 40px;
	margin-left: -45px; 
}

.joinCreatorProgramModal--guidelines-grid-block{
	margin-bottom: 24px;
	width: 275px;
	margin-left: 45px;

}

.joinCreatorProgramModal--guidelines-grid-block-title,
.joinCreatorProgramModal--success-block-title{
	font-weight: $lato-heavy;
	font-size: 18px;
	color: $textBlack;
	margin-bottom: 10px;
}

.joinCreatorProgramModal--guidelines-grid-block-label,
.joinCreatorProgramModal--success-block-label{
	font-size: 15px;
	font-weight: $lato-medium;
	color: rgb(75,75,90);
}

.joinCreatorProgramModal--success-block-label--two{
	margin-top: 5px;
}

.joinCreatorProgramModal--success-block{
	margin-bottom: 24px;
}

.joinCreatorProgramModal--success-block--1{
	margin-top: 36px;
}

.joinCreatorProgramModal--success-block-label a,
.joinCreatorProgramModal--success-block-label a:visited {
	font-weight: $lato-semibold;
	color: $textBlue;
	text-decoration: underline;
}
//
// Username
//

.joinCreatorProgramModal--username
.joinCreatorProgramModal-subheader{
	margin-top: 60px;
	font-weight: $lato-heavy;
	font-size: 18px;
	color: $textBlack;
	margin-bottom: 20px;
}


.joinCreatorProgramModal-input{
	margin-top: 12px;
	position: relative;
}


input.joinCreatorProgramModal-input-input{
	height: 52px;
	width: 450px;
	padding-left: 18px;
	padding-top: 2px;
	box-sizing: border-box;

	font-size: 21px;
	font-weight: $lato-bold;
	color: $textBlackDark;

	border-radius: 4px;
	border: 1px solid $lineDark;

	outline: none;
	box-shadow: none;

	margin-bottom: 10px;
	text-transform: lowercase;
}

input.joinCreatorProgramModal-input-input:hover{
	border: 1px solid $gray-9;
}

input.joinCreatorProgramModal-input-input:focus,
input.joinCreatorProgramModal-input-input:focus:hover{
	border: 1px solid $textBlue;
}

input.joinCreatorProgramModal-input-input::-webkit-input-placeholder{
	text-transform: none;
	color: $textInputPlaceholder !important;
}

// Character counter

.joinCreatorProgramModal-input-characterCounter{
	position: absolute;

	left: 391px; // quite brittle
	top: 14px;

	width: 50px;
	height: 24px;
	text-align: right;
	
	box-sizing: border-box;
	padding-right: 5px;
	pointer-events: none;
	border-radius: 4px;
	z-index: 5;

	font-size: 14px;
	font-weight: $lato-semibold;
	color: $textLight;

	display: flex;
	align-items: center;
	justify-content: flex-end;
}

.joinCreatorProgramModal-input-characterCounter--aboveMax{
	background: transparentize($red, 0.9);
	color: $red-dark;
	font-weight: $lato-bold;
}


//

.joinCreatorProgramModal-input-instructionsLabel{
	font-size: 14px;
	font-weight: $lato-medium;
	color: $textMid;
	margin-bottom: 5px;
}

// used for weak errors
.joinCreatorProgramModal-input-instructionsLabel--error{
	font-size: 14px;
	font-weight: $lato-semibold;
	color: $textBlackDark;
	margin-bottom: 5px;
}


// used for big errors
.joinCreatorProgramModal-error{
	font-size: 14px;
	font-weight: $lato-bold;
	padding: 5px;
	border-radius: 4px;
	background: transparentize($red, 0.95);
	color: $red-dark;
	width: 300px;
}


//
// Success
//

// Animate on present


@keyframes animateSuccess {
  0%   {
    opacity: 0;
  }
  100%   {
    opacity: 1;
  }
}

@keyframes animateSuccessHeader {
  0%   {
    opacity: 0;
    transform: translateY(15px);
  }
  100%   {
    opacity: 1;
    transform: translateY(0px);
  }
}


.joinCreatorProgramModal--success .joinCreatorProgramModal-header,
.joinCreatorProgramModal--success .joinCreatorProgramModal-subheader{
	animation-name: animateSuccessHeader;
  
  animation-iteration-count: 1;
  animation-fill-mode: backwards;
}

.joinCreatorProgramModal--success .joinCreatorProgramModal-header{
	animation-duration: 1s;
  animation-delay: 0ms;
}

.joinCreatorProgramModal--success-block,
.joinCreatorProgramModal--success button.btn.btn--large.submit,
.joinCreatorProgramModal--success button.btn.btn--large.cancel{
	animation-name: animateSuccess;
  animation-duration: 700ms;
  
  animation-iteration-count: 1;
  animation-fill-mode: backwards;
}

.joinCreatorProgramModal--success-block--1{
	animation-delay: 600ms;
}

.joinCreatorProgramModal--success-block--2{
	animation-delay: 900ms;
}

.joinCreatorProgramModal--success-block--3{
	animation-delay: 1200ms;
}

.joinCreatorProgramModal--success button.btn.btn--large.submit{
	animation-delay: 1500ms;
}

.joinCreatorProgramModal--success button.btn.btn--large.cancel{
	animation-delay: 1700ms;
}



.joinCreatorProgramModal--success
.joinCreatorProgramModal-subheader{
	margin-top: 60px;
}


.joinCreatorProgramModal-publicRepoURL{
	font-size: 18px;
	font-weight: $lato-bold;
	margin-top: 15px;
	color: $textDark;
	margin-bottom: 20px;
}

.joinCreatorProgramModal--success a,
.joinCreatorProgramModal--success a:visited{
	color: $textBlue;
	cursor: pointer;
}

.joinCreatorProgramModal--success a:hover{
	text-decoration: underline !important;
}


.joinCreatorProgramModal-supportLink{
	font-size: 15px;
	font-weight: $lato-semibold;
	color: $textMid;
	margin-bottom: 10px;
}

.joinCreatorProgramModal-supportLink:hover{
	color: $textDark;
}




//
// SUBMIT AREA INC. BUTTONS
//

.joinCreatorProgramModal-submitArea{
	position: absolute;
	bottom: 42px;
	left: $leftPadding-joinCreatorProgramModal;
}

.joinCreatorProgramModal-submitArea-primaryConfirm{
	margin-bottom: 5px;
	display: flex;
}

.joinCreatorProgramModal-submitArea-primaryConfirm .featureModal-selectControl-label, 
.joinCreatorProgramModal-submitArea-primaryConfirm a ,
.joinCreatorProgramModal-submitArea-primaryConfirm{
	letter-spacing: 0em;
	font-weight: $lato-bold;
	font-size: 15px;
	color: $textBlack;
}

.joinCreatorProgramModal-submitArea-primaryConfirm a,
.joinCreatorProgramModal-submitArea-primaryConfirm a:visited{
	color: $textBlue;
	cursor: pointer;
}

.joinCreatorProgramModal-submitArea-primaryConfirm a:hover,
.joinCreatorProgramModal-submitArea-primaryConfirm a:visited:hover{
	text-decoration: underline !important;
}

.joinCreatorProgramModal-submitArea-feedbackConfirm{
	margin-bottom: 18px;
}

.joinCreatorProgramModal-submitArea-feedbackConfirm .featureModal-selectControl-label {
	letter-spacing: 0em;
	font-weight: $lato-semibold;
	font-size: 14.5px;
	color: $textDark;
	margin-top: 1px;
}


.joinCreatorProgramModal button.btn.btn--large.submit{
	height: 54px;
	width: 354px;
	font-weight: $lato-heavy;
	font-size: 21px;
	cursor: pointer;
	background: $blue !important; //sorry for this horrendous
	opacity: 1 !important;
}

.joinCreatorProgramModal button.btn.btn--large.submit.btn--gray {
	background: $gray-10 !important;
	border: 1px solid $gray-9 !important;
}

.joinCreatorProgramModal button.btn.btn--large.submit .button-loadingSpinner{
	height: 20px;
	width: 20px;
	
  border-radius: 18px;

  border: 3px rgba(26,28,34,0.55) solid;
  border-top: 3px $white solid;

  opacity: 0.5;

  animation: spinnerCircleRotate .5s infinite linear;
}


.joinCreatorProgramModal button.btn.btn--large.submit.submit--loading ~
button.btn.btn--large.cancel{
	opacity: 0;
	transition: opacity 100ms linear;
}


.joinCreatorProgramModal button.btn.btn--large.cancel{
	height: 40px;
	margin-top: 4px;
	width: 354px;
	font-weight: $lato-bold;
	font-size: 15px;
	cursor: pointer;
	color: $textLight;

	border: none;
	box-shadow: none;
	background: none;
}

.joinCreatorProgramModal button.btn.btn--large.cancel:hover{
	border: none;
	background: rgba(0,0,40,0.02);
	color: $textDark;
}


//
// VISUAL 
// rocket launch thing
//

@import 'JoinCreatorProgram-Visual'; 

//
// CLOSE BUTTON
//

.joinCreatorProgramModal-closeBtn{
	position: absolute;
	width: 36px;
	height: 36px;
	top: 20px;
	right: 24px;
	cursor: pointer;
	border-radius: 18px;

	opacity: 0.4;
	transition: opacity 100ms linear;

	z-index: 100;
}

.joinCreatorProgramModal-closeBtn:hover{
	opacity: 0.8;
	transition: opacity 50ms linear;
}

.joinCreatorProgramModal-closeBtn:active{
	opacity: 1;
}

.joinCreatorProgramModal-closeBtn .icon{
	width: 36px;
	height: 36px;
	//fill: rgba(0,0,40,0.4);
	fill: $white;
}