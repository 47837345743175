
//
// REVEAL ANSWER SUBMIT LOADING

button.syncLiveView-player-main-controlBar-revealAnswer--submitLoading{
	opacity: 1;
	animation: liveSyncsubmitLoadingRevealDisappear;
	animation-duration: 1800ms;
	//animation-duration: 10000ms;
	animation-iteration-count: 1;
	animation-fill-mode: forwards;
	animation-easing-function: linear;
	box-shadow: none;
}

@keyframes liveSyncsubmitLoadingRevealDisappear {
  0% {
    opacity: 1;
    transform: scale(1),
  }
  1% {
    opacity: 1;
    transform: scale(0.98),
  }
  6% {
    opacity: 1;
    transform: scale(1),
  }
  85% {
    opacity: 1;
    transform: scale(1),
  }
  100% {
    opacity: 0.4;
    transform: scale(1),
  }
}



//

.syncLiveView-player-main-controlBar-revealAnswer-submitLoadingContainer{		
	position: absolute;
	left: 0px;
	right: 0px;
	top: 0px;
	bottom: 0px;

}

.syncLiveView-player-main-controlBar-revealAnswer-submitLoading{
	position: absolute;
	left: 0px;
	right: 0px;
	bottom: 0px;
	top: 0px;

	margin: auto;

	display: flex;
	align-items: center;
	justify-content: center;

	border-radius: 5px;

	box-sizing: border-box;
	
	overflow: hidden;
}



button.syncLiveView-player-main-controlBar-revealAnswer--submitLoading
.syncLiveView-player-main-controlBar-revealAnswer-submitLoading{
	opacity: 1;
	animation: syncLiveReviewSubmitLoadingAnimation;
	animation-duration: 2000ms;	
	animation-iteration-count: 1;
	animation-fill-mode: forwards;
	animation-easing-function: linear;	
}


@keyframes syncLiveReviewSubmitLoadingAnimation {
  0% {
    opacity: 1;    
  }  
 	85% {
    opacity: 1;
  } 
  100% {
    opacity: 0;
  }
}

.syncLiveView-player-main-controlBar-revealAnswer-submitLoading-progressBar{
	position: absolute;
	height: 6px;
	width: 80%;	

	border-radius: 4px;
	overflow: hidden;
	background: transparentize($green, 0.8);
	opacity: 0;
}

button.syncLiveView-player-main-controlBar-revealAnswer--submitLoading
.syncLiveView-player-main-controlBar-revealAnswer-submitLoading-progressBar{
	opacity: 0;
	animation: syncLiveReviewSubmitLoadingProgressBarAnimation;
	animation-duration: 1800ms;
	//animation-duration: 10000ms;
	animation-delay: 0ms;
	animation-iteration-count: 1;
	animation-fill-mode: forwards;
	animation-easing-function: linear;	
}

@keyframes syncLiveReviewSubmitLoadingProgressBarAnimation {
  0% {
    opacity: 0;    
  }  
  20% {
    opacity: 1;    
  }    
  100% {
    opacity: 1;    
  }
}


.syncLiveView-player-main-controlBar-revealAnswer-submitLoading-progressBar-bar{	
	position: absolute;
	left: 0px;
	top: 0px;
	bottom: 0px;
	width: 100%;
	background: $green;
	transform-origin: 0% 0%;
}

button.syncLiveView-player-main-controlBar-revealAnswer--submitLoading
.syncLiveView-player-main-controlBar-revealAnswer-submitLoading-progressBar-bar{
	transform: scaleX(0);
	animation: syncLiveReviewSubmitLoadingProgressBarBarAnimation;
	animation-duration: 800ms;
	animation-delay: 300ms;
	animation-iteration-count: 1;
	animation-fill-mode: forwards;
	animation-easing-function: linear;	
}

@keyframes syncLiveReviewSubmitLoadingProgressBarBarAnimation {
  0% {
    transform: scaleX(0);    
  }  
  100% {
    transform: scaleX(1);
  }  
}


.syncLiveView-player-main-controlBar-revealAnswer-submitLoading-bg{
	position: absolute;
	left: 3px;
	right: 3px;
	bottom: 3px;
	top: 3px;

	border-radius: 2px;	

	background: $green;
	opacity: 0;
}

button.syncLiveView-player-main-controlBar-revealAnswer--submitLoading
.syncLiveView-player-main-controlBar-revealAnswer-submitLoading-bg{
	opacity: 0;
	animation: syncLiveReviewSubmitLoadingBackgroundAnimation;
	animation-duration: 1750ms;
	animation-delay: 50ms;
	animation-iteration-count: 1;
	animation-fill-mode: forwards;
	animation-easing-function: linear;	
}


@keyframes syncLiveReviewSubmitLoadingBackgroundAnimation {
  0% {
    opacity: 0;    
  }  
  3% {
    opacity: 0.06;    
  }  
  5% {
    opacity: 0.04;    
  }  
 
  100% {
    opacity: 0.1;
  }
}