//
// INITIALIZE PAGE
// 

// Reset Padding and ensure full size width
html, body, #root, .App { margin: 0; padding: 0; height: 100%; width: 100%;}

body {
	min-height: 100vh;
	width: 100vw;
	overflow-y: overlay;
	overflow-y: auto;
	overflow-x: hidden;
}

// Body overflows horizontally if too small (instead of breaking)
@media (max-width: 939px) {
	body {
		overflow-x: auto; 
	}
}

$min--pageWidth: 940px;

.App {	
	// we rarely touch .App itself, it's the top-level div
	width: 100%;
	height: 100%;
	min-width: $min--pageWidth;
}

.App.noBodyScroll .page{
	overflow: hidden; //used to block scrolling when eg dropdowns are open
}


