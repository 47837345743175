
.upgrade--landing-featureSection{
	height: 600px;
	width: 100%;
	box-sizing: border-box;
	
	border-bottom: 1px solid $lineMid;

	overflow: hidden;
	position: relative;

	padding-top: 80px;
	margin-bottom: 20px;

	text-align: center;
	cursor: default;
}

.upgrade--landing-featureSection--instantImages{
	height: 633px;
}

.upgrade--landing-featureSection--sharedPacks{
	height: 275px;
}

.upgrade--landing-featureSection--layoutTemplates{
	height: 633px;
}

.upgrade--landing-featureSection--unlimitedScoresheet{
	height: 600px;
}



.upgrade--landing-featureSection-featureName{
	font-size: 16px;
	font-weight: $effra-medium;
	text-transform: uppercase;
	color: $blue;

	letter-spacing: 0.2em;

	margin-bottom: 20px;

}

.upgrade--landing-featureSection-featureHeader{
	font-size: 36px;
	font-weight: $effra-bold;
	color: $textBlack;
	margin-bottom: 12px;
	position: relative;
	z-index: 5;
}

.upgrade--landing-featureSection-featureSub{
	font-size: 22px;
	font-weight: $effra-medium;
	color: $textLight;
	position: relative;
	z-index: 5;
}

.upgrade--landing-featureSection-featureSub b{
	font-weight: $effra-medium;
	color: $blue-light;
}

.upgrade--landing-featureSection-cloudBG{
	position: absolute;
	left: 50%;
	transform: translateX(-50%);
	bottom: 0px;
	width: 1100px;
	height: 600px;	
	z-index: 1;
}

.upgrade--landing-featureSection-cloudBG svg{
	fill: $upgrade-cloudBlue-bg;
}

@import 'Upgrade-Landing-FeatureSelection-InstantImages';
@import 'Upgrade-Landing-FeatureSelection-LayoutTemplates';
@import 'Upgrade-Landing-FeatureSelection-UnlimitedScoresheet';
