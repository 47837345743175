$width-questionReport-responseCount: 30px;

.choiceDetailBlock{
	width: calc(100% - #{$width-questionReport-responseCount});
	//box-sizing: border-box;
	// background: $tempr;
	position: relative;
	padding-bottom: 36px;

	cursor: default;
	user-select: none;
}

.choiceDetailBlock.choiceDetailBlock--specialChoice{
	padding-bottom: 24px;
}

.questionReport-choiceDetail:hover
.choiceDetailBlock{
	opacity: 0.9;
	transition: opacity 100ms;
}

.questionReport-choiceDetail
.choiceDetailBlock:hover{
	opacity: 1;
	transition: opacity 50ms;
}

.questionReport .choiceDetailBlock.is--zeroPercent, 
.questionReport .choiceDetailBlock--specialChoice{
	opacity: 0.8;
	transition: opacity 100ms;
}

.questionReport .choiceDetailBlock.is--zeroPercent:hover, 
.questionReport .choiceDetailBlock--specialChoice:hover{
	opacity: 1;	
	transition: opacity 50ms;
}

.choiceDetailBlock-content{
	width: 100%;
	display: flex;
	flex-direction: column;
	box-sizing: border-box;
	//padding-left: 48px;
	// height: 200px;
}


//
// CHOICE DETAIL BLOCK
//

.choiceDetailBlock-letter{
	position: absolute;
	top: 1px;
	left: -44px;

	width: 28px;
	height: 28px;

	border: 1px solid $lineDark;
  border-radius: 4px;
  box-sizing: border-box;
  font-weight: 800;
  font-size: 18px;
  text-align: center;
  line-height: 1.55;
  background: $white;
  color: $textLight;
}



// Correct
.choiceDetailBlock.is--report.is--correct .choiceDetailBlock-letter{
  border-color: $green;
  background: transparentize($green, 0.98);
  color: $green-dark;
}


// Incorrect
.choiceDetailBlock.is--report.is--incorrect .choiceDetailBlock-letter{
  border-color: $red;
  background: transparentize($red, 0.98);
  color: $red-dark;
}

// Survey
.choiceDetailBlock.is--report.is--survey .choiceDetailBlock-letter{
  border-color: $blue;
  background: transparentize($blue, 0.98);
  color: $blue;
}


//

.choiceDetailBlock-title{
	margin-top: 2px;
	font-size: 20px;
	font-weight: $lato-bold;
	color: $textBlack;
	line-height: 1.3;
	word-wrap: break-word;

	min-height: 5px; // for media choices
}

.choiceDetailBlock-responseCount{
	position: absolute;
	top: 4px;
	right: calc(-1 * #{$width-questionReport-responseCount});
	font-size: 20px;
	font-weight: $lato-bold;
	color: $textMid;
	width: $width-questionReport-responseCount;
	text-align: right;
}


.choiceDetailBlock-track{
	margin-top: 6px;
	height: 6px;
	background: rgb(240,240,243);
	border-radius: 3px;
	width: 100%;
}

.choiceDetailBlock-bar{
	margin-top: -6px;
	height: 6px;
	border-radius: 3px;
	border-top-right-radius: 0px;
	border-bottom-right-radius: 0px;
	box-sizing: border-box;
}

.choiceDetailBlock-bar.is--hundredPercent{
	border-top-right-radius: 3px;
	border-bottom-right-radius: 3px;
}

.choiceDetailBlock-bar.is--zeroPercent{
	display: none;
}

.is--correct .choiceDetailBlock-bar{
	background: $green;
}

.is--incorrect .choiceDetailBlock-bar{
	background: $red;
}

.is--survey .choiceDetailBlock-bar{
	background: $blue;
}

// STUDENT LIST

.choiceDetailBlock-studentList{
	margin-top: 6px;

	column-count: 4;
  column-gap: 28px;
	width: 100%;
	overflow: hidden;

	max-width: 500px;
}


.choiceDetailBlock-studentCard{
	user-select: none; 
	cursor: default;

 	display: inline-block;
 	vertical-align: top;

 	break-inside: avoid-column;
 	-webkit-column-break-inside: avoid;

	width: 100%;

	height: 22px;
	//border-top: 1px solid $lineMid;
	
	position: relative;

	line-height: 1.9;
  	
	font-weight: $lato-medium;
	color: $textBlack;
	font-size: 14px;
	
	white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}


.choiceDetailBlock-studentCard-cardNumber{
	display: inline-block;
	width: 15px;
	text-align: right;
	font-weight: $lato-semibold;
	color: $textLight;
	margin-right: 5px;
}




// RESPONSE COUNT

