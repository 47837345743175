.sectionHome-upcoming-upcomingCard-dragHandleContainer{
	width: $leftGutter-centerPanel;
	position: absolute;
	top: 0px;	
	left: 0px;

	height: calc(100% - #{$marginBottom-sectionHome-upcomingCard}); // 

	display: flex;
	align-items: center;
	justify-content: flex-end;
}

.sectionHome-upcoming-upcomingCard-dragHandle{
	position: relative;

	width: 24px;
	height: 80%;	
	margin-right: 13px;
	
	
	border-radius: 4px;
	
	cursor: grab;

	display: flex;
	
	align-items: center;
	justify-content: center;

	opacity: 0;

	box-sizing: border-box;

	border: 1px solid $lineDark;
	
}

.sectionHome-upcoming-upcomingCard-dragHandle-dotGrid{
	width: 80%;
	height: auto;

//	background: $tempr;

	flex-wrap: wrap;
	display: flex;
	
	align-items: center;
	justify-content: center;
}

.sectionHome-upcoming-upcomingCard-dragHandle-dot{
	width: 3px;
	height: 3px;
	border-radius: 2px;
	margin: 2px;

	background: $gray-8;
}

.sectionHome-upcoming-itemList
.sectionHome-upcoming-upcomingCard--isPressed,
.sectionHome-upcoming-itemList
.sectionHome-upcoming-upcomingCard--isPressed
.sectionHome-upcoming-upcomingCard-dragHandle{
	cursor: grabbing !important;
}


//
// INTERACTIONS
//

// when hover over card
.sectionHome-upcoming-upcomingCard:hover
.sectionHome-upcoming-upcomingCard-dragHandle{
	opacity: 0.5;
}

// when hover over or are dragging
.sectionHome-upcoming-upcomingCard
.sectionHome-upcoming-upcomingCard-dragHandle:hover{
	opacity: 1;
}

.sectionHome-upcoming-upcomingCard.sectionHome-upcoming-upcomingCard--isPressed
.sectionHome-upcoming-upcomingCard-dragHandle {
	opacity: 0.8;
}

//
// TOOLTIPS
//

// copy styling for tooltip label
.sectionHome-upcoming-upcomingCard-dragHandle-tooltip{
	position: absolute;
	left: -88px;
	width: 135px;
	height: 40px;

  align-items: center;
  justify-content: center;

  box-sizing: border-box;
  padding-top: 5px;

  font-weight: $lato-semibold;
  background-color: $white;
  color: $textBlack;
  font-size: 12.5px;
  text-align: center;

  border-radius: 3px;
  border: 1px solid $lineDark;
  box-sizing: border-box;
  
  
  line-height: 1.15;

  box-shadow: 0px 1px 2px 0px rgba(0,0,40,0.08);
  
  transition: opacity 50ms linear;
  transition-delay: 0ms;
  z-index: 1000;

  pointer-events: none;

  opacity: 0;

}

// show tooltip when hover
.sectionHome-upcoming-upcomingCard-dragHandle:hover ~
.sectionHome-upcoming-upcomingCard-dragHandle-tooltip{
	opacity: 1;
	transition: opacity 100ms linear;
	transition-delay: 500ms;
	
}

// hide tooltip when card pressed
.sectionHome-upcoming-upcomingCard.sectionHome-upcoming-upcomingCard--isPressed
.sectionHome-upcoming-upcomingCard-dragHandle-tooltip{
	opacity: 0;
	
	transition: opacity 50ms linear;
	transition-delay: 0ms;
}

// show tooltip when above up next
.sectionHome-upcoming-itemList.sectionHome-upcoming-itemList--cardIsAboveUpNext
.sectionHome-upcoming-upcomingCard.sectionHome-upcoming-upcomingCard--isPressed
.sectionHome-upcoming-upcomingCard-dragHandle-tooltip{
	opacity: 1;
	
	transition: opacity 50ms linear;
	transition-delay: 0ms;

	// special styling for drag-drop-tooltip
	width: 120px;
	padding-left: 10px;
	padding-right: 10px;
	left: -75px;
}


//
// SWAPPING TOOLTIP LABELS
//

//default, show default label
.sectionHome-upcoming-upcomingCard
.sectionHome-upcoming-upcomingCard-dragHandle-tooltip--labelDefault{
	display: block;
}

.sectionHome-upcoming-upcomingCard
.sectionHome-upcoming-upcomingCard-dragHandle-tooltip--labelMoveUpNext{
	display: none;
}

//isUpNext, show drop label
.sectionHome-upcoming-itemList.sectionHome-upcoming-itemList--cardIsAboveUpNext
.sectionHome-upcoming-upcomingCard-dragHandle-tooltip--labelDefault{
	display: none;
}

.sectionHome-upcoming-itemList.sectionHome-upcoming-itemList--cardIsAboveUpNext
.sectionHome-upcoming-upcomingCard-dragHandle-tooltip--labelMoveUpNext{
	display: block;
}
