
///
// FOOTER
//


.discover-market-footer{
	display: flex;
	margin-top: 64px;
	cursor: default;
	user-select: none;

	position: relative;
	padding-top: 32px;

	flex-wrap: wrap;
}

.discoverHome .discover-market-footer{
	margin-top: 24px;
}

.discover-market-footer-divider{
	width: calc(100% + 45px);
	height: 1px;
	background: $gray-125;
	
	// width: 100px;
	// // height: 2px;
	// // background: $blue;
	position: absolute;
	top: 0px;
	left: 0px;
}

.discover-market-footer-block{
	width: 50%;
	flex-grow: 0;
	flex-shrink: 0;
	margin-bottom: 28px;

	box-sizing: border-box;
	padding-right: 20px;
}


.discover-market-footer-block-header{
	text-transform: uppercase;

	user-select: none;
	cursor: default;

	color: $textDark;

	font-size: 13.5px;
	font-weight: $lato-bold;
	
	letter-spacing: 0.02em;
	margin-bottom: 8px;
}

.discover-market-footer-block-description,
.discover-market-footer-block-description a{
	font-size: 14px;
	font-weight: $lato-medium;
	color: $textDark;

	max-width: 360px;
	letter-spacing: 0.002em;
}

.discover-market-footer-block emph{
	color: $textBlack;
	font-weight: $lato-semibold;
}

// FAQ ITEM

.discover-market-footer-block-FAQ-item{
	margin-bottom: 12px;
	max-width: 360px;
	user-select: text;
	cursor: default;
}

.discover-market-footer-block-FAQ-item-title{
	font-size: 14px;
	font-weight: $lato-semibold;
	color: $textBlack;
	letter-spacing: 0.002em;
	margin-bottom: 2px;
}

.discover-market-footer-block-FAQ-item-description{
	font-size: 14px;
	font-weight: $lato-medium;
	color: $textDark;

	max-width: 360px;
	letter-spacing: 0.002em;
}


// Narrow screen support
@media (max-width: 1100px) {	
	.discover-market-footer-block{
		width: 100%;
		flex-grow: 0;
		flex-shrink: 0;
		padding-right: 0px;
	}

	.discover-market-footer-block-description,
	.discover-market-footer-block-FAQ-item,
	.discover-market-footer-block-FAQ-item-description{
		max-width: 450px;
	}
}



// Link
.discover-market-footer-block-description a{
	position: relative;
	top: 8px;

	color: $textBlue;
	padding-top: 5px;
	padding-bottom: 6px;
	padding-left: 7px;
	margin-left: -7px;
	padding-right: 7px;
	font-weight: $lato-semibold;
	letter-spacing: 0.01em;
	border-radius: 3px;
}

.discover-market-footer-block-description a:hover{
	color: darken($textBlue, 5%);
	background: transparentize($blue, 0.95);
	text-decoration: underline !important;
}

.discover-market-footer-block-description-p{
	margin-bottom: 6px;
}

// LOOKING FOR A TOPIC AND GENERAL FEEDBACK

.discover-market-footer-block--requestTopic-inputContainer,
.discover-market-footer-block--generalFeedback-textAreaContainer,
.discover-market-footer-block--requestSubject-inputContainer{
	margin-top: 10px;
}

.discover-market-footer-block--requestTopic-inputContainer,
.discover-market-footer-block--requestSubject-inputContainer{
	display: flex;
	
}

input.discover-market-footer-block--requestTopic-input,
textarea.discover-market-footer-block--generalFeedback-textArea,
input.discover-market-footer-block--requestSubject-input{
	
	max-width: 220px;
	border: 1px solid $lineDark;
	margin-right: 6px;
	font-size: 14px;
	font-weight: $lato-medium;
	color: $textBlack;
	background: $gray-15;
	border-radius: 2px;
	padding-left: 6px;
	box-sizing: border-box;
}

input.discover-market-footer-block--requestTopic-input,
input.discover-market-footer-block--requestSubject-input{
	flex-grow: 1;
	flex-shrink: 1;
}

textarea.discover-market-footer-block--generalFeedback-textArea{
	width: 100%;
	resize: none;
	max-width: 400px;
	height: 65px;
	padding-top: 6px;
}

.discover-market-footer-block--requestTopic-input:focus,
textarea.discover-market-footer-block--generalFeedback-textArea:focus,
input.discover-market-footer-block--requestSubject-input:focus{
	border: 1px solid $blue;
	outline: none;
}

.discover-market-footer-block--requestTopic-inputContainer button,
.discover-market-footer-block--generalFeedback button,
.discover-market-footer-block--requestSubject-inputContainer button{
	font-weight: $lato-bold;
	
	letter-spacing: 0.01em;
	width: 120px;

	cursor: pointer;
}

.discover-market-footer-block--requestTopic-inputContainer button .button-loadingSpinner,
.discover-market-footer-block--generalFeedback button .button-loadingSpinner,
.discover-market-footer-block--requestSubject-inputContainer button .button-loadingSpinner{
	height: 14px;
	width: 14px;
	border-radius: 10px;
	border-width: 2px;
}

.discover-market-footer-block--generalFeedback button{
	margin-top: 4px;
}

.discover-market-footer-block--requestTopic-inputDescription,
.discover-market-footer-block--generalFeedback-textAreaDescription{
	margin-top: 8px;

	font-size: 13px;
	font-weight: $lato-medium;

	color: lighten($textMid, 5%);

	max-width: 375px;
	letter-spacing: 0.002em;
}