.sectionHome-upNext{
	width: 100%;
	height: $height-UpNext;
	margin-top: 26px;

	display: flex;
	padding-bottom: 20px;

	position: relative;
	cursor: default;
	display: flex;
}

// 

.sectionHome-upNext--left{
	width: calc(100% - 360px - 20px);
	margin-right: 20px;
	height: 100%;

	position: relative;
	z-index: 1;
}

.sectionHome-upNext-label{
	font-size: 16px;
	font-weight: $lato-bold;
	text-transform: uppercase;
}

.sectionHome-upNext-label.is--upnext{
	color: $textBlue;
}

.sectionHome-upNext--livePoll
.sectionHome-upNext-label.is--upnext{
	color: $textDark;
}

.sectionHome-upNext-title{
	margin-top: 20px;

	font-size: 26px;
	font-weight: $lato-heavy;
	color: $textBlack;

	line-height: 1.2;

	max-height: 96px;

	//background: $tempr;

	display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;

  text-overflow: clip;
  white-space: normal;
  overflow: hidden; 

	overflow: hidden;
}

.sectionHome-upNext-title .icon.shared{
	fill: $gray-115;
	height: 19px;
	width: 19px;
	margin-left: 3px;
	position: relative;
	top: 1px;
}

.sectionHome-upNext-subline{
	margin-top: 7px;

	font-size: 17px;
	font-weight: $lato-semibold;
	
	display: flex;
}

a.sectionHome-upNext-subline-containingRepo{
	color: $textMid;
	margin-right: 8px;
	flex-grow: 0;
	flex-shrink: 1;

	white-space: nowrap; 
  overflow: hidden;
  text-overflow: ellipsis;
}

a.sectionHome-upNext-subline-containingRepo .icon.globe-shared{
	fill: $gray-9;
	height: 16px;
	width: 16px;
	position: relative;
	top: 2px;
	margin-right: 2px;
}


a.sectionHome-upNext-subline-containingRepo:hover{
	color: $blue;
}

a.sectionHome-upNext-subline-containingRepo:hover .icon.globe-shared{
	fill: $textBlue;	
}

.sectionHome-upNext-subline-timestamp{
	color: $textLight;
	flex-grow: 0;
	flex-shrink: 0;
}



.sectionHome-upNext-actionList{
	display: flex;
	flex-direction: column;

	justify-content: flex-end;

	position: absolute;
	left: 0px;
	bottom: 46px;

	margin-left: -8px;
}

.sectionHome-upNext-actionList button{
	margin-bottom: 0px;

}

/// REMOVE BUTTON
// copied from upcoming


.sectionHome-upNext-removeBtn{
	position: absolute;

	width: 24px;
	height: 24px;
	top: 40px;
	right: -32px;

	display: flex;
	align-items: center;
	justify-content: center;

	border: 1px solid $lineDark;
	border-bottom: 1px solid $lineDark;
	background: $white;
	border-radius: 3px;
	box-sizing: border-box;
	cursor: pointer;
	z-index: 1;
}

.sectionHome-upNext-removeBtn .icon{
	width: 12px;
	height: 12px;

	fill: $textLight;
}



.sectionHome-upNext
.sectionHome-upNext-removeBtn{
	opacity: 0;
	transition: opacity 150ms ease-in-out;
}

.sectionHome-upNext:hover
.sectionHome-upNext-removeBtn{
	opacity: 0.5;
	transition: opacity 25ms ease-in-out;
}

.sectionHome-upNext
.sectionHome-upNext-removeBtn:hover{
	opacity: 1;
	transition: opacity 25ms ease-in-out;
}

///

.sectionHome-upNext--right{
	width: 360px;
	height: 100%;
	position: relative;
	padding-top: 35px;
	box-sizing: border-box;
	display: flex;
	z-index: 1;
}

.sectionHome-upNext--right .slideCarousel{
	margin-left: auto;
}

//// EMPTY

.sectionHome-upNext
.sectionHome-upNext-background{
	position: absolute;
	top: -26px; // margin top
	right: -45px;
	//left: -60px;
	left: -90px;
	bottom: 0px;
	
	background: $gray-15;
		
	box-sizing: border-box;
	z-index: 0;
	opacity: 0;
}


.sectionHome-upNext.sectionHome-upNext--contextOpen
.sectionHome-upNext-background{
	opacity: 1;
}
