img.bingAttributionLogo {
  width: 80px;
  overflow: hidden;
  object-fit: contain;
  margin-left: 5px;
}

// BING ATTRIBUTION
.bingAttributionContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0.7;
}

.bingAttributionContainer div {
  color: $textMid;
  font-size: 13px;
}

// BING ATTRIBUTION WITH PRIVACY LINK

.bingAttributionWithPrivacyLinkContainer-bingLogoContainer {
  height: 40px;
  width: 180px;

  margin: auto;

  font-size: 13px;

  box-sizing: border-box;

  user-select: none;

  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
}

.bingAttributionWithPrivacyLinkContainer-bingLogoContainer-bingLogo img {
  width: 80px;
  overflow: hidden;
  object-fit: contain;
  margin-left: 5px;
}

.bingAttributionWithPrivacyLinkContainer-bingPrivacyContainer {
  width: 200px;
  margin: auto;
}
