.repoReleaseModal-visual-topBar{
	display: flex;
	padding-left: 52px;
	padding-right: 4px;
	box-sizing: border-box;
	align-items: center;
	justify-content: space-between;

}


.repoReleaseModal-visual-topBar-section{
	display: flex;
}


.repoReleaseModal-visual-topBar-btn{
	height: 21px;
	margin-right: 5px;
	border-radius: 3px;
	background: $white;
	border: 1px solid $gray-1225;
}
