.submitEarlyReleaseApprovalModalContainer{
	position: fixed;
	z-index: 9999;
	top: 0;
	left: 0;
	right: 0;
	height: 100vh;
	
	background: rgba(0,0,10,0.75);
	
	display: flex;
	align-items: center;
	justify-content: center;

	overflow: hidden;
}

.submitEarlyReleaseApprovalModal{
	
	border-radius: 5px;
	
	background: rgb(252,253,255);
	border: 1px solid $gray-4;
	box-shadow: 
		0px 2px 4px 0px rgba(5,5,10,0.50),
		0px 8px 16px 0px rgba(5,5,10,0.25) ;

	box-sizing: border-box;
	
	overflow: hidden;

	width: 980px;
	height: 620px;

	position: relative;

	padding-top: 24px;

	padding-left: 60px;
	padding-right: 60px;

	cursor: default;
}

.submitEarlyReleaseApprovalModal-subtitle{
	width: 100%;
	text-align: center;
	
	font-size: 15px;
	font-weight: $lato-semibold;
	color: $textLight;

	margin-bottom: 10px;
}

.submitEarlyReleaseApprovalModal-title{
	width: 100%;
	text-align: center;

	font-size: 24px;
	font-weight: $lato-heavy;
	color: $textBlackDark;

	margin-bottom: 10px;
	letter-spacing: 0.01em;
}

.submitEarlyReleaseApprovalModal-description{
	width: 100%;
	text-align: center;

	font-size: 14px;
	font-weight: $lato-semibold;
	color: $textDark;
}

//
// Guidelines
//

.submitEarlyReleaseApprovalModal-guidelinesContainer{
	margin-top: 10px;

	width: 100%;

	display: flex;
	flex-direction: column;
	flex-wrap: wrap;

	margin-top: 32px;
	height: 420px;
}

.submitEarlyReleaseApprovalModal-guidelineContainer{
	width: 50%;
	min-height: 122px;
	// background: $tempb;
	// outline: 1px solid red;

	box-sizing: border-box;
	padding-left: 32px;
	position: relative;
	padding-right: 20px;
}

.submitEarlyReleaseApprovalModal-guidelineContainer-icon{
	position: relative;
	top: -26px;
	left: -15px;
	opacity: 0.75;
	pointer-events: none;
}

.submitEarlyReleaseApprovalModal-guidelineContainer-icon svg{
	fill: $green;
	height: 9px;
	width: 9px;
}

.submitEarlyReleaseApprovalModal-guidelineContainer
.featureModal-selectControl--active ~ .submitEarlyReleaseApprovalModal-guidelineContainer-icon{
	opacity: 0;
}

.submitEarlyReleaseApprovalModal-guideline-number{
	position: absolute;
	top: 0px;
	left: 0px;
	height: 22px;
	width: 22px;

	display: flex;
	align-items: center;
	justify-content: center;

	font-size: 16px;
	font-weight: $lato-bold;
	color: $blue-bold;
	background: $blue-extraLight;

	border-radius: 11px;
}

.submitEarlyReleaseApprovalModal-guideline-title{
	font-weight: $lato-heavy;
	color: $textBlackDark;
	font-size: 17px;

	margin-top: 2px;	
	margin-bottom: 4px;
}

.submitEarlyReleaseApprovalModal-guideline-description{
	font-weight: $lato-medium;
	font-size: 14px;

	color: $textDark;

}

// Checkbox

.submitEarlyReleaseApprovalModal-guidelineContainer
.featureModal-selectControl{
	cursor: pointer;
	padding-top: 8px;
}

.submitEarlyReleaseApprovalModal-guidelineContainer
.featureModal-selectControl-checkbox{
	margin-right: 5px;
	margin-top: 1px;
	cursor: pointer;
}

.submitEarlyReleaseApprovalModal-guidelineContainer
.featureModal-selectControl--inactive .featureModal-selectControl-checkbox{
	border: 1px solid $gray-10;

}

.submitEarlyReleaseApprovalModal-guidelineContainer
.featureModal-selectControl-label{
	font-weight: $lato-bold;
	font-size: 14px;
	cursor: pointer;
	color: $textBlack;
	letter-spacing: initial;
	
}



// Button

.submitEarlyReleaseApprovalModal-btnContainer{
	position: absolute;
	bottom: 0px;
	left: 0px;
	right: 0px;
	height: 110px;

}

button.submitearlyreleaseapprovalmodalsubmit{
	left: 50%;
	transform: translate(-50%);
	margin-top: 40px;

	height: 48px;
	width: 450px;
	

	font-size: 19px;
	font-weight: $lato-heavy;
	
	letter-spacing: 0.015em;
	cursor: pointer;
}

button.btn--blue.submitearlyreleaseapprovalmodalsubmit{
	background: $blue-bold;
}

button.btn--blue.submitearlyreleaseapprovalmodalsubmit:hover{
	background: darken($blue-bold, 5%);
}



.submitEarlyReleaseApprovalModal-btnContainer-description{
	margin-top: 10px;
	width: 100%;
	text-align: center;

	font-size: 13px;
	font-weight: $lato-medium;
	color: $invisible;
}

.submitEarlyReleaseApprovalModal-btnContainer:hover
.submitEarlyReleaseApprovalModal-btnContainer-description{
	color: $textMid;
}



//
// Cancel Button
//

.submitEarlyReleaseApprovalModal-cancel{
	position: absolute;
	top: 19px;
	right: 14px;
	padding-top: 6px;
	padding-bottom: 6px;
	padding-right: 20px;
	padding-left: 20px;	

	text-align: center;

	cursor: pointer;

	font-size: 14px;
	font-weight: $lato-semibold;
	color: $textLight;

	user-select: none;

}

.submitEarlyReleaseApprovalModal-cancel:hover{
	color: $textDark;
}


