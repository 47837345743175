.syncScanHelper{
	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	z-index: 9999;

	overflow: scroll;
	min-width: 650px;
}



.syncScanHelper-toolbar{
	display: flex;
	width: 100%;
	height: 50px;
	box-sizing: border-box;
	padding-left: 16px;
	padding-right: 16px;

	align-items: center;
	font-size: 14px;
}

.syncScanHelper--ready
.syncScanHelper-toolbar{
	background: transparentize($color-o85,0.9);
}

.syncScanHelper--ready.syncScanHelper--isScanning
.syncScanHelper-toolbar{
	background: transparentize($color-o85,0.5);
}

.syncScanHelper--loading
.syncScanHelper-toolbar{
	background: $tempr;
}

.syncScanHelper-toolbar-title{
	font-weight: $lato-heavy;
}

.syncScanHelper-toolbar-nowplaying{
	margin-left: 24px;
	max-width: 400px;
	text-overflow: ellipses;
	white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.syncScanHelper-toolbar-section{
	margin-left: 24px;
	max-width: 400px;
	text-overflow: ellipses;
	white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.syncScanHelper-toolbar-controls{
	margin-left: auto;
	display: flex;
	align-items: center;
}

.syncScanHelper-toolbar-controls-number{
	width: 60px;
	text-align: right;
}

.syncScanHelper-toolbar-controls-label{
	width: 100px;
}

.syncScanHelper-toolbar-scanning{
	margin-left: auto;

	width: 100px;
	height: 20px;

	font-size: 14px;
}


.syncScanHelper-questionBody{
	width: 100%;
	box-sizing: border-box;
	padding-left: 28px;
	opacity: 0.5;
	margin-top: 6px;
	font-size: 14px;
}


.syncScanHelper-studentGrid{
	height: calc(100vh - 100px);
	margin-top: 14px;
	width: 500px;
	box-sizing: border-box;
	padding-left: 20px;
	padding-right: 20px;

	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	align-items: top;
	justify-content: flex-start;
	cursor: default;
	user-select: none;
}

.syncScanHelper-studentGrid--notScanning{
	opacity: 0.4;
	pointer-events: none;
}

.syncScanHelper--loading .syncScanHelper-studentGrid{
	opacity: 0.2 !important;
	pointer-events: none !important;
}




.syncScanHelper-student{
	width: 200px;
	flex-grow: 0;
	flex-shrink: 0;
	border-radius: 4px;
	background: $white;

	cursor: default;
	user-select: none;

	display: flex;
	align-items: center;
	justify-content: center;
}

.syncScanHelper-student--hasResponse{
	opacity: 0.5;
}

.syncScanHelper-student-name{
	font-size: 15px;
	font-weight: $lato-bold;
	padding-left: 8px;
	width: 100px;
}

.syncScanHelper-student-buttonArray{
	display: flex;
	width: 100px;
}

button.syncScanHelper-student-respondBtn{
	background: none;
	border: none;
	outline: none;
	box-shadow: none;
	padding: none;
	height: 24px;
	width: 24px;
	flex-grow: 1;
	flex-shrink: 1;
	

	font-size: 15px;

	cursor: pointer;

	color: $textMid;
	font-weight: $lato-semibold;
}

.syncScanHelper-student-buttonArray:hover
button.syncScanHelper-student-respondBtn{
	opacity: 0.5;
}

.syncScanHelper-student-buttonArray:hover
button.syncScanHelper-student-respondBtn:hover{
	opacity: 1;
	background: $blue-extraLight;
}

button.syncScanHelper-student-respondBtn--correctAnswer{
	color: $textBlack;
	font-weight: $lato-heavy;
}


.syncScanHelper--loading .syncScanHelper-autoRespond,
.syncScanHelper--isNotScanning .syncScanHelper-autoRespond{
	opacity: 0.5;
	pointer-events: none;
}


.syncScanHelper-autoRespond{
	position: fixed;
	bottom: 25px;
	right: 25px;
	width: 350px;
	height: 600px;
	box-sizing:border-box;
	padding: 10px;
	border: 1px solid $lineDark;
	border-radius: 3px;
	z-index: 50;
	background: $white;
}

.syncScanHelper-autoRespond-title{
	font-size: 18px;
	font-weight: $lato-heavy;
	margin-bottom: 10px;
	text-align: center;
}

.syncScanHelper-autoRespond-requestInterval,
.syncScanHelper-autoRespond-requestTime{
	text-align: center;
}

.syncScanHelper-autoRespond-intervalButtons{
	display: flex;
	align-items: center;
	justify-content: center;
	margin-top: 10px;

}

.syncScanHelper-autoRespond-intervalButtons button.btn--large{
	font-size: 20px;
	width: 100px;
	line-height: 0.9;
}

.syncScanHelper-autoRespond-submitButton button.btn--large{
	width: 250px;

	margin: 0 auto;
	margin-top: 20px;
}

.syncScanHelper-autoRespond-results{
	margin-top: 15px;
	margin-left: 15px;
}

.syncScanHelper-autoRespond-results-label{
	font-weight: $lato-bold;
	margin-bottom: 5px;
}