
.syncLiveView-player-main-controlBar-reviewDev{
	position: absolute;
	left: 0px;
	right: 0px;
	bottom: -80px;
	height: 40px;

	background: $tempb;
}
// REVIEW


.syncLiveView-player-main-controlBar-reviewContainer{
	position: absolute;
	top: 0px;
	left: 0px;
	right: 0px;
	bottom: 0px;

	display: flex;
	align-items: center;
	justify-content: center;
}

button.syncLiveView-player-main-controlBar-revealAnswer,
button.syncLiveView-player-main-controlBar-showGraph,
button.syncLiveView-player-main-controlBar-reopenAnswers{
	height: 56px;
	border-radius: 7px;
	cursor: pointer;
	letter-spacing: 0.02em;
	user-select: none;
	border: 1px solid darken($lineMid, 4%);
	border-bottom: 1px solid darken($lineMid, 8%);
	background: $white;
	box-shadow: 0px 2px 4px 0px rgba(0,0,40,0.08);	
	position: relative;
}


//
// REVEAL ANSWER

button.syncLiveView-player-main-controlBar-revealAnswer,
.syncLiveView-player-main-controlBar-surveyCenterSpacer{
	width: 100%;
	max-width: 420px;
}

button.syncLiveView-player-main-controlBar-revealAnswer--preActive:hover{
	border: 1px solid darken($lineMid, 6%);
	border-bottom: 1px solid darken($lineMid, 10%);
	box-shadow: 0px 2px 4px 0px rgba(0,0,40,0.10);	
}



.syncLiveView-player-main-controlBar-revealAnswer-primary{
	font-size: 16px;
	font-weight: $lato-heavy;
	color: #00B481;
	color: $textBlackDark;
	margin-top: 2px;
	position: relative;
	z-index: 5;
	opacity: 1;
}

// Keyboard Prompt
.syncLiveView-player-main-controlBar-revealAnswer-secondary{
	font-size: 10.5px;
	font-weight: $lato-bold;
	color: #00B481;
	color: $blue;
	opacity: 0.75;
	
	margin-top: 2px;
	position: relative;
	z-index: 5;

	opacity: 1;
}

button.syncLiveView-player-main-controlBar-revealAnswer--submitLoading
.syncLiveView-player-main-controlBar-revealAnswer-primary{
	opacity: 0;
	transition: opacity 200ms linear;
	transition-delay: 0ms;
}

button.syncLiveView-player-main-controlBar-revealAnswer--preActive:hover
.syncLiveView-player-main-controlBar-revealAnswer-secondary{	
	opacity: 1;
	animation-name: syncRevealAnswerSecondaryTextPulse;
 	animation-duration: 2300ms;
 	animation-delay: 2000ms;
 	animation-iteration-count: infinite;
}

@keyframes syncRevealAnswerSecondaryTextPulse {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.75;
  }
  100% {
    opacity: 1;
  }
}

button.syncLiveView-player-main-controlBar-revealAnswer--submitLoading
.syncLiveView-player-main-controlBar-revealAnswer-secondary{
	opacity: 0;
	transition: opacity 200ms linear;
	transition-delay: 50ms;
}


///
/// SUBMIT LOADING ANIMATION
///

@import 'SyncLiveView-PlayerControlBar-Review-SubmitLoading';



// ACTIVE i.e. PRESSED STATE
button.syncLiveView-player-main-controlBar-revealAnswer--active{
	opacity: 0.4;		
	box-shadow: none;
}


button.syncLiveView-player-main-controlBar-revealAnswer--active
.syncLiveView-player-main-controlBar-revealAnswer-primary{
	color: $textLight;
	opacity: 0;
	animation: syncLiveReviewRevealedTextFade;
	animation-duration: 500ms;
	animation-easing-function: linear;
	animation-iteration-count: 1;
	animation-fill-mode: forwards;
	animation-delay: 100ms;
}

@keyframes syncLiveReviewRevealedTextFade {
  0% {
    opacity: 0;    
  } 
  100% {
    opacity: 1;
  }
}



//
// REOPEN AND SHOW GRAPH

button.syncLiveView-player-main-controlBar-reopenAnswers,
button.syncLiveView-player-main-controlBar-showGraph{
	width: 70px;

	outline: none;

	flex-shrink: 0;
	flex-grow: 0;

	position: relative;

	cursor: pointer;

	display: flex;
	align-items: center;
	justify-content: center;
}

.syncLiveView-player-main-controlBar-showGraph-icon{
	z-index: 5;
	position: relative;
}

.syncLiveView-player-main-controlBar-showGraph-icon svg,
.syncLiveView-player-main-controlBar-reopenAnswers svg{
	fill: rgb(183,183,188);
}

.syncLiveView-player-main-controlBar-reopenAnswers svg{
	width: 27px;
	height: 27px;
	margin-top: 1px;
}

.syncLiveView-player-main-controlBar-showGraph-icon svg{
	width: 27px;
	height: 27px;
	margin-top: 2px;
}


.syncLiveView-player-main-controlBar-reopenAnswers-label,
.syncLiveView-player-main-controlBar-showGraph-label{
	position: absolute;
	bottom: 4px;
	left: 0px;
	right: 0px;
	// background: $tempr;

	font-size: 9.5px;
	font-weight: $lato-bold;
	opacity: 0;

	color: lighten($textLight, 13%);

	text-transform: uppercase;
	letter-spacing: 0.04em;

	transition: opacity 50ms linear;
}

button.syncLiveView-player-main-controlBar-showGraph--preActive:hover
.syncLiveView-player-main-controlBar-showGraph-label,
button.syncLiveView-player-main-controlBar-showGraph--active:hover
.syncLiveView-player-main-controlBar-showGraph-label,
button.syncLiveView-player-main-controlBar-reopenAnswers:hover
.syncLiveView-player-main-controlBar-reopenAnswers-label{
	color: $textMid;
	opacity: 1;
	transition-delay: 0ms;
}

.syncLiveView-player-main-controlBar-showGraph:hover svg,
.syncLiveView-player-main-controlBar-reopenAnswers:hover svg{
	fill: $gray-6;
}

.syncLiveView-player-main-controlBar-showGraph-BG{
	position: absolute;
	top: 4px;
	left: 4px;
	bottom: 4px;
	right: 4px;
	background: $blue;
	z-index: 0;
	border-radius: 4px;
	opacity: 0;
	transition: opacity 100ms linear;
}




.syncLiveView-player-main-controlBar-showGraph--active
.syncLiveView-player-main-controlBar-showGraph-BG{
	opacity: 0.2;	
}

.syncLiveView-player-main-controlBar:hover
.syncLiveView-player-main-controlBar-showGraph--active
.syncLiveView-player-main-controlBar-showGraph-label{
	color: transparentize($blue, 0.2);
}

.syncLiveView-player-main-controlBar-showGraph--active
.syncLiveView-player-main-controlBar-showGraph-icon svg{
	fill: $blue;
}

button.syncLiveView-player-main-controlBar-showGraph.syncLiveView-player-main-controlBar-showGraph--active
.syncLiveView-player-main-controlBar-showGraph-label{
	color: $blue !important; // sorry
}



//
// GRAPH BAR
// 

$syncLiveView-showGraph-barWidth: 6px;
$syncLiveView-showGraph-barMargin: 2px;

.syncLiveView-player-main-controlBar-showGraph-graph{	
	width: 30px;
	height: 30px;
	// background: $tempr;
	z-index: 5;
	position: relative;
}



.syncLiveView-player-main-controlBar-showGraph-graph-bar{
	position: absolute;
	width: $syncLiveView-showGraph-barWidth;
	height: 20px;
	background: rgb(183,183,188);
	bottom: 5px;
	opacity: 1;
	border-radius: 1.5px;
}


.syncLiveView-player-main-controlBar-showGraph--active
.syncLiveView-player-main-controlBar-showGraph-graph-bar{
	background: $blue;
}

.syncLiveView-player-main-controlBar-showGraph-graph-bar--1{
	left: 0px;
}

.syncLiveView-player-main-controlBar-showGraph-graph-bar--2{
	left: calc((1 * #{$syncLiveView-showGraph-barWidth}) + (1 * #{$syncLiveView-showGraph-barMargin}))
}

.syncLiveView-player-main-controlBar-showGraph-graph-bar--3{
	left: calc((2 * #{$syncLiveView-showGraph-barWidth}) + (2 * #{$syncLiveView-showGraph-barMargin}))
}

.syncLiveView-player-main-controlBar-showGraph-graph-bar--4{
	left: calc((3 * #{$syncLiveView-showGraph-barWidth}) + (3 * #{$syncLiveView-showGraph-barMargin}))	
}


.syncLiveView-player-main-controlBar-showGraph-graph-bar--1{
	height: 10px;
}

.syncLiveView-player-main-controlBar-showGraph-graph-bar--2{
	height: 17px;		
}

.syncLiveView-player-main-controlBar-showGraph-graph-bar--3{
	height: 22px;		
}

.syncLiveView-player-main-controlBar-showGraph-graph-bar--4{
	height: 14px;
}





.syncLiveView-player-main-controlBar-showGraph--submitLoading
.syncLiveView-player-main-controlBar-showGraph-graph-bar{
	opacity: 1;
	background: rgb(183,183,188);
	animation: liveSyncsubmitLoadingGraphBar;
	animation-duration: 500ms;
	animation-iteration-count: 1;
	animation-fill-mode: forwards;
	animation-easing-function: linear;
	box-shadow: none;
}

@keyframes liveSyncsubmitLoadingGraphBar {
  0% {
    opacity: 1;    
    background: rgb(183,183,188);
  }    
  30% {
    opacity: 0.9;    
    background: $blue;
  }     
  60% {
    opacity: 0.8;    
    background: $blue;
  }     
  100% {
    opacity: 1;    
    background: $blue;
  }  
}


.syncLiveView-player-main-controlBar-showGraph--submitLoading{

	.syncLiveView-player-main-controlBar-showGraph-graph-bar--1{
		animation-delay: 0ms;
	}

	.syncLiveView-player-main-controlBar-showGraph-graph-bar--2{
		animation-delay: 50ms;
	}

	.syncLiveView-player-main-controlBar-showGraph-graph-bar--3{
		animation-delay: 100ms;
	}

	.syncLiveView-player-main-controlBar-showGraph-graph-bar--4{
		animation-delay: 150ms;
	}

}





.syncLiveView-player-main-controlBar-showGraph--submitLoading
.syncLiveView-player-main-controlBar-showGraph-BG{
	opacity: 0;
	animation: liveSyncsubmitLoadingGraphBG;
	animation-duration: 1000ms;
	//animation-duration: 10000ms;
	animation-iteration-count: 1;
	animation-fill-mode: forwards;
	animation-easing-function: linear;
	box-shadow: none;
}

@keyframes liveSyncsubmitLoadingGraphBG {
  0% {
    opacity: 0;    
  }
  // 5% {
  //   opacity: 0.3;    
  // }    
  100% {
    opacity: 0.2;    
  }  
}


///
// SHOW GRAPH TOOLTIP


.syncLiveView-player-main-controlBar-showGraph-keyboardTooltip{
	position: absolute;
	bottom: -17px;
	left: 50%;
	width: 100px;
	transform: translateX(-50%);

	display: flex;
	align-items: center;
	justify-content: center;

	font-size: 11.5px;
	font-weight: $lato-bold;
	color: $textLight;
	letter-spacing: 0.05em;
	text-transform: uppercase;
	margin-top: 2px;

	opacity: 0;
	transition: opacity 250ms linear;
	// background: $tempr;
}

button.syncLiveView-player-main-controlBar-showGraph:hover
.syncLiveView-player-main-controlBar-showGraph-keyboardTooltip{
	opacity: 1;
	transition: opacity 150ms linear;
	transition-delay: 2000ms;
}

.syncLiveView-player-main-controlBar-showGraph-keyboardTooltip-lighten{
	color: lighten($textLight, 7%);
}





// .syncLiveView-player-main-controlBar-showGraph--active,
// .syncLiveView-player-main-controlBar-showGraph--active:hover{
// 	background: transparentize($blue, 0.85);
// }

// .syncLiveView-player-main-controlBar-showGraph--active svg,
// .syncLiveView-player-main-controlBar-showGraph--active:hover svg{
// 	fill: $blue;
// }




.syncLiveView-player-main-controlBar-reviewSpacer{
	width: 12px;
}
