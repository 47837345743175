@import 'LibraryAndRecent-Cells';
@import 'LibraryAndRecent-PreviewPanel';
@import 'LibraryAndRecent-MultiselectPanel';
@import 'LibraryAndRecent-RepoGrid';


.library-table,
.recent-table{
	width: 100%;
	box-sizing: border-box;
}

.library-table:focus,
.recent-table:focus{
	outline: none
}

.recentDateGroup{
	margin-bottom: 15px;
}


/// RECENT DATE header
.recentDateGroup-dateHeader{
	cursor: default;

	font-size: 15px;
	color: $textLight;	
	font-weight: $lato-semibold;


	height: 70px;

	padding-top: 40px;
	
	box-sizing: border-box;
	//background: $tempr;
	border-bottom: 1px solid $lineMid;
	// same as recent cell
	user-select: none;
	cursor: default;
}

.recentDateGroup-dateHeader:first-child{
	height: 50px;
	padding-top: 20px;
}


// FOLDER MODALS

.library-editFolderModal--container .modal-content{
	width: 490px;
	height: 238px;
}


.library-editFolderModal{
	box-sizing: border-box;
	padding-left: 50px;
	padding-right: 50px;
	padding-top: 40px;
	position: relative;
}

.library-editFolderModal .btn{	
	min-width: 232px;
	margin: 0 auto;
	margin-top: 60px;
}


// LIBRARY FOLDER PATH NAV

.library .btn--libraryHeader {	
	display: inline-block;
	box-sizing: border-box;	
	height: 38px;
	vertical-align: top;
	color: $gray-4;
	font-size: 24px;
	font-weight: $lato-bold;
	cursor: pointer;
	border-radius: 4px;
	padding-right: 9px;
	padding-left: 9px;
	margin-left: -9px;
}


.library-toolbar-pathitem{
	display: inline-block;
	box-sizing: border-box;
	height: 38px;
	font-size: 24px;
}

.btn--library-toolbar-pathitem{
	display: inline-block;
	border-radius: 4px;
	height: 100%;
	box-sizing: border-box;
	vertical-align: top;
	cursor: pointer;
	padding-left: 9px;
	padding-right: 9px;
	color: $gray-8;
	font-size: 24px;
	font-weight: $lato-bold;
}

.library-toolbar-pathitem .icon{
	fill: $gray-9;
	margin-top: 9px;
	padding-right: 3px;
	padding-left: 3px;
}

.btn--libraryHeader:hover, 
.btn--library-toolbar-pathitem:hover {
	color: $blue;
}

.library-toolbar-pathitem:last-child .btn--library-toolbar-pathitem{
	color: $gray-4;
	cursor: default;
}

.library-toolbar-pathitem:last-child .btn--library-toolbar-pathitem:hover{
	color: $gray-4;
}



// TOOLBAR

$width--toolbar-right-content: 550px;

.library .toolbar,
.recent .toolbar {
	width: calc(100% - 216px);
	position: fixed;
	top: 0;
	left: 216px;
	height: 72px;
	box-sizing: border-box;
}

// LEFT CONTENT

.library .toolbar-left-content,
.recent  .toolbar-left-content {
	display: inline-block;
	width: calc(100% - #{$width--toolbar-right-content});
	
	height: 100%;
	vertical-align: top;
	box-sizing: border-box;
	padding-left: 30px;
	padding-top: 13px;
}




// TOOLBAR RIGHT CONTENT

.library .toolbar-right-content,
.recent  .toolbar-right-content {
	display: inline-block;
	width: $width--toolbar-right-content;
	height: 100%;
	vertical-align: top;
	box-sizing: border-box;
	padding-top: 16px;
	padding-right: 16px;
	text-align: right;
}

// TEMP HIDE RIGHT CONTENT
// .library .toolbar-left-content {
// 	width: 100%;
// }

// .library .toolbar-right-content {
// 	display: none;
// }
// 


.library .btn--toolbar-newSet,
.recent  .btn--toolbar-newSet, {
	display: inline-block;
	min-width: 106px;
	width: 106px;
	height: 32px;
	box-sizing: border-box;
	margin-right: 10px;
	vertical-align: top;
}

.library .btn--toolbar-newSet .icon,
.recent  .btn--toolbar-newSet .icon {
	fill: white;
	width: 12px;
	height: 12px;
	margin-right: 6px;
	margin-left: -2px;
}


.library .btn--toolbar-newFolder {
	display: inline-block;
	min-width: 54px;
	width: 54px;
	height: 32px;
	box-sizing: border-box;
	margin-right: 10px;
	vertical-align: top;
}

.library .btn--toolbar-newFolder .icon {
	display: inline-block;
	vertical-align: top;

}

.library .btn--toolbar-newFolder .icon.plus {
	fill: $gray-11;
	width: 12px;
	height: 12px;
	margin-top: 8.5px;
	margin-right: 5px;
	margin-left: -2px;
}

.library .btn--toolbar-newFolder .icon.folder{
	fill: $gray-11;
	width: 18px;
	margin-top: 5px;
}

.library .btn--toolbar-newFolder:hover .icon{
	fill: $gray-8;
}


.library .btn--toolbar-searchBar,
.recent  .btn--toolbar-searchBar, {
	display: inline-block;
	width: 302px;
	height: 32px;
	// background: $tempb;
	box-sizing: border-box;
	text-align: left;
	margin-right: 12px;
	background: $white;
	color: rgba(0,0,0,0);
	border: 1px solid $gray-11;
	border-radius: 5px;
	box-shadow: 0px 1px 1px 0px rgba(0,0,0,0.04);
	vertical-align: top;
}

.library .btn--toolbar-more,
.recent  .btn--toolbar-more, {
	display: inline-block;
	width: 44px;
	height: 32px;
	background: white;
	box-sizing: border-box;
	vertical-align: top;
	text-align: center;
	padding-top: 5px;
}

.library .btn--toolbar-more .icon,
.recent  .btn--toolbar-more .icon, {
	fill: $gray-8;
	// display: none;
}


// EMPTY FOLDER STATE

.library-table-emptyStateContainer{
	width: 100%;
	height: 100%;
	
	display: flex;
	align-items: center;
	justify-content: center;
}

.library-table-emptyState{
	width: 100%;
	margin-top: 140px;
	text-align: center;
	font-size: 22px;
	font-weight: $lato-heavy;
	color: $gray-125;
	user-select: none;
	cursor: default;
}



