
/// INPUT

.repoInvitationModal-header-inviteInput{
	position: relative;
	width: 100%;
}

input.repoInvitationModal-inviteInput-input{
	width: 100%;
	height: 36px;
	box-sizing: border-box;

	font-size: 17px;
	padding-left: 12px;
	padding-right: 12px;
	
	padding-bottom: 1px;
	

	font-weight: $lato-semibold;
	outline: none;
	box-shadow: none;
	border: 1px solid $gray-11;
	border-radius: 4px;
}

input.repoInvitationModal-inviteInput-input:focus{
	border: 1px solid $blue;
}

input.repoInvitationModal-inviteInput-input::-webkit-input-placeholder{
	color: $gray-10 !important;
}

// change permissions

.repoInvitationModal-header-inviteInput-changePermissionNewMemberContainer{
	position: absolute;
	top: 0px;
	right: 0px;
	bottom: 0px;
	width: 90px;

	//background: $tempr;

	user-select: none;
	cursor: pointer;

	box-sizing: border-box;
}

.repoInvitationModal-header-inviteInput-changePermissionNewMemberButton{
	position: absolute;
	top: 0px;
	left: 0px;
	right: 0px;

	height: 36px;

	box-sizing: border-box;
	padding-right: 14px;

	cursor: pointer;
	
	color: $textMid;	
	//background: $tempb;
	text-align: right;

	font-size: 15px;

	line-height: 2.4;

	user-select: none;
}

.repoInvitationModal-header-inviteInput-changePermissionNewMemberButton:hover{
	color: $blue;
}

.repoInvitationModal-header-inviteInput-changePermissionNewMemberButton .icon{
	width: 11px;
	height: 11px;
	position: relative;
	top: 1px;
	left: 5px;
	fill: $textMid;
}

.repoInvitationModal-header-inviteInput-changePermissionNewMemberButton:hover .icon{
	fill: $blue;
}


.repoInvitationModal-header-inviteInput-changePermissionNewMemberContainer .dropdown{
	z-index: initial !important;
}

.repoInvitationModal-header-inviteInput-changePermissionNewMemberContainer .dropdownMenu{
	width: 240px;
	left: -140px;
	top: 38px;
}

// custom stuff, add bold
.repoInvitationModal-header-inviteInput-changePermissionNewMemberContainer .dropdownMenu-item.is--selectItem.is--active{
	font-weight: $lato-bold;
	color: $textBlue;
}

.repoInvitationModal-header-inviteInput-changePermissionNewMemberContainer .dropdownMenu-item.is--selectItem.is--active:hover{	
	color: $white;
}

.repoInvitationModal-header-inviteInput-changePermissionNewMemberContainer .dropdownMenu-item.is--selectItem.is--active
.dropdownMenu-item--multiline-sublabel{
	font-weight: $lato-medium; //inherit original
	color: transparentize($textMid, 0.1);
}

.repoInvitationModal-header-inviteInput-changePermissionNewMemberContainer .dropdownMenu-item.is--selectItem.is--active:hover
.dropdownMenu-item--multiline-sublabel{
	color: rgba(255,255,255,0.9); // inherit original
}


////


.repoInvitationModal-header-inviteInput-changePermissionToggle--validEmail{
	color: $blue;
}	

.repoInvitationModal-header-inviteInput-changePermissionToggle--invalidEmail{
	color: $textLight;
	opacity: 0.75;
}	

.repoInvitationModal-header-inviteInput-changePermissionToggle--invalidEmail:hover{
	opacity: 1;
}

// .repoInvitationModal-header-inviteInput-changePermissionToggle .icon{
// 	width: 10px;
// 	height: 10px;
// 	position: relative;
// 	top: 1px;
// 	left: 5px;
// 	fill: $blue;
// }




//

.repoInvitationModal-inviteInput-suggestionDropdown{
	position: absolute;
	top: 38px;

	width: 100%;
	background: $white;	

	border: 1px solid $lineDark;
	
	border-radius: 3px;
	
	box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.12);
	z-index: 250;
}

.repoInvitationModal-inviteInput-suggestion{
	padding-left: 10px;
	padding-top: 6px;
	padding-bottom: 6px;
	display: flex;
}


.repoInvitationModal-inviteInput-suggestion-profilePicContainer{
	padding-right: 12px;
	flex-grow: 0;
	flex-shrink: 0;
}

.repoInvitationModal-inviteInput-suggestion-name{
	flex-grow: 1;
	flex-shrink: 1;

	font-size: 16px;
	font-weight: $lato-semibold;
	letter-spacing: 0.01em;
	color: $textDark;
	display: flex;
	align-items: center;
}

.react-autosuggest__suggestion--highlighted .repoInvitationModal-inviteInput-suggestion-name{
	color: $blue;
}

.react-autosuggest__suggestion--highlighted .repoInvitationModal-inviteInput-suggestion{
	background: $blue-extraLight;
	color: $blue-dark;
}


.react-autosuggest__suggestion--highlighted .repoInvitationModal-inviteInput-suggestion--alreadyJoined,
.repoInvitationModal-inviteInput-suggestion--alreadyJoined {
	display: none;
	opacity: 0.5;
	background: $gray-15;
	color: $textDark;
}

.react-autosuggest__suggestion--highlighted .repoInvitationModal-inviteInput-suggestion--alreadyJoined .repoInvitationModal-inviteInput-suggestion-hint,
.repoInvitationModal-inviteInput-suggestion--alreadyJoined .repoInvitationModal-inviteInput-suggestion-hint{
	display: none;
}

.repoInvitationModal-inviteInput-suggestion-hint{
	flex-grow: 0;
	flex-shrink: 0;

	text-align: right;

	font-size: 12.5px;
	padding-right: 10px;

	line-height: 2.5;

	font-weight: $lato-semibold;
}

.repoInvitationModal-inviteInput-suggestion-hint strong{
	font-weight: $lato-bold;
}

.repoInvitationModal-inviteInput-suggestion-hint{
	opacity: 0;
}

.react-autosuggest__suggestion--highlighted .repoInvitationModal-inviteInput-suggestion-hint{
	opacity: 1;
}

// Press enter to invite hint 

.repoInvitationModal-header-inviteInput-hint{
	padding-left: 5px;
	padding-right: 12px;
	
	pointer-events: none;
	font-size: 12px;
	font-weight: $lato-semibold;
	color: $textMid;

	position: absolute;


	border-radius: 3px;
	
	right: 1px;
	top: 1px;
	bottom: 1px;

	background: rgba(255,255,255,0.75);

	display: flex;

	align-items: center;
	justify-content: center;	

	user-select: none;
	cursor: default;
}

.repoInvitationModal-header-inviteInput-hint--show{
	opacity: 1;
	transform: translateX(0px);
	transition: all 150ms ease-in;
}

.repoInvitationModal-header-inviteInput-hint--hide{
	opacity: 0;
	transform: translateX(-10px);
	transition: all 0ms ease-in;
}

.repoInvitationModal-header-inviteInput-hint strong{
	color: $textBlue;
	font-weight: $lato-bold;
	padding-left: 2px;
	padding-right: 2px;
}

// Submit Inviting


.repoInvitationModal-header-inviteInput-submitInvitingContainer{
	position: absolute;

	display: flex;
	align-items: center;
	justify-content: center;
	top: 0px;
	bottom: 0px;
	right: 0px;
	width: 40px;
	z-index: 10;
}


.repoInvitationModal-header-inviteInput-submitInviting{
	height: 14px;
	width: 14px;
	
  border-radius: 14px;

  border: 3px $gray-13 solid;
  border-top: 3px $gray-10 solid;

  opacity: 0.7;

  animation: spinnerRotateAnimation .35s infinite linear;
}



