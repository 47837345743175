.sectionHome-upcoming-itemList
.sectionHome-upcoming-itemList-upNextBlocker{
	position: absolute;
	height: calc(#{$height-UpNext} + 40px);
	width: 200px;
	top: calc(-1 * #{$height-UpNext} - 40px);
	left: 0px;
	background: rgba(255,255,255,0.75);
	width: 100%;

	opacity: 0;	
	z-index: 90;
	display: none;
}

.sectionHome-upcoming-itemList.sectionHome-upcoming-itemList--isPressed
.sectionHome-upcoming-itemList-upNextBlocker{
	opacity: 0.5;
	display: flex;
}

.sectionHome-upcoming-itemList.sectionHome-upcoming-itemList--isPressed.sectionHome-upcoming-itemList--cardIsAboveUpNext
.sectionHome-upcoming-itemList-upNextBlocker{
	opacity: 1;
	display: flex;
}