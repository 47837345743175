
.liveView-scanProgressBar{
	position: absolute;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	box-sizing: border-box;
}

.liveView-scanProgressBar-label{	
	width: 100%;
	font-weight: $lato-heavy;
	display: flex;
	align-items: center;
	height: 10px;
	flex-grow: 1;
}

.liveView-scanProgressBar-label-emptyLabel{	
	width: 100%;
	text-align: center;
	color: $textLight;
}

.liveView-scanProgressBar-label-progressLabel,
.liveView-scanProgressBar-label-totalLabel{
	flex-grow: 1;
	width: 200px;
}

.liveView-scanProgressBar-label-progressLabel{	
	text-align: right;
	color: $blue;
}

.liveView-scanProgressBar-label-totalLabel{
	text-align: left;
	color: $gray-10;
}

.liveView-scanProgressBar-label-progressLabel--allGuests{
	text-align: center;
}

.liveView-scanProgressBar-progressBar{
	width: 100%;
	border-radius: 0.5vh;
	overflow: hidden;
	position: relative;
	flex-shrink: 0; 
}

.liveView-scanProgressBar-progressBar-bar{
	position: absolute;
	top: 0;
	left: 0;	
	bottom: 0;
	background: $blue;
	z-index: 5;
}

.liveView-scanProgressBar-progressBar-track{
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: $gray-125;
}