//
// UPGRADE FLOW
//

$upgrade-btn-green: rgb(49,201,136);
$upgrade-btn-greenBorder: rgb(40,178,122);
$upgrade-cloudBlue-bg: rgb(241,247,255);

.upgradeContainer{
	position: fixed;
	z-index: 9999;
	top: 0;
	left: 0;
	right: 0;
	height: 100vh;
	
	background: rgba(5,5,10,0.75);
	
	display: flex;
	
	align-items: flex-start;
	justify-content: center;

	overflow-y: overlay;
	overflow-x: hidden;
}

.upgrade{
	margin-top: 50px;
	margin-bottom: 50px;

	border-radius: 10px;
	
	background: rgb(252,252,255);
	border: 1px solid $gray-5;
	box-shadow: 
		0px 8px 16px 0px rgba(5,5,10,0.12),
		0px 16px 32px 0px rgba(5,5,10,0.08) ;

	box-sizing: border-box;
	
	overflow: hidden;

	width: calc(100vw - 100px);
	max-width: 1280px;	
	min-width: 960px;
}

.upgrade--landing{
	padding-top: 100px;
	padding-bottom: 80px;	
	position: relative;

	text-align: center;
}

@import 'Upgrade-Landing-Header';
@import 'Upgrade-Landing-FeatureSelection';
@import 'Upgrade-Landing-CompareTable';

@import 'Upgrade-Payment';
@import 'Upgrade-Success';
@import 'Upgrade-ProFeaturesSymbolRow';
@import 'Upgrade-DiscountSash';

///

button.upgrade--landing-btn{
	width: 426px;
	height: 64px;
	background: $upgrade-btn-green;
	border: 1px solid $upgrade-btn-greenBorder; 
	color: $white;

	margin: 0 auto;

	margin-top: 44px;

	display: flex;
	align-items: center;
	justify-content: center;

	font-size: 25px;
	font-weight: $effra-bold;

	border-radius: 8px;

	cursor: pointer;

	box-shadow: 
		0px 4px 8px 0px rgba(0,0,0,0.12),
		0px 2px 4px 0px rgba(0,0,0,0.04),
		inset 0px 0px 20px rgba(0,0,0,0.03);
}

button.upgrade--landing-btn:hover{
	box-shadow: 
		0px 4px 8px 0px rgba(0,0,0,0.12),
		0px 2px 4px 0px rgba(0,0,0,0.04);
}


//

button.upgrade--landing-btn--small{
	
	height: 56px;
	margin-top: 24px;

	font-size: 20px;
	font-weight: $effra-medium;

	display: flex;
	align-items: center;
	justify-content: center;

	border-radius: 5px;

	box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.12);

	cursor: pointer;

	letter-spacing: 0.01em;
}

button.upgrade--landing-btn--small--white{
	background: $white;
	border: 1px solid $lineMid;
	color: transparentize($textMid, 0.1);
}

button.upgrade--landing-btn--small--white:hover{
	color: $textMid;
}

button.upgrade--landing-btn--small--green{
	background: $upgrade-btn-green;
	border: 1px solid $upgrade-btn-greenBorder;
	color: $white;
}

button.upgrade--landing-btn--small--blue{
	background: $blue-light;
	border: 1px solid $blue;
	color: $white;
}




// CLOSE BUTTON
// Refactor out for use on marketing modals
// Used on Marketing Modals

.upgrade-closeBtn{
	position: absolute;
	width: 42px;
	height: 42px;
	top: 20px;
	right: 24px;
	cursor: pointer;
	border-radius: 21px;

	opacity: 0.4;
	transition: opacity 100ms linear;
}

.upgrade-closeBtn:hover{
	opacity: 0.8;
	transition: opacity 50ms linear;
}

.upgrade-closeBtn:active{
	opacity: 1;
}

.upgrade-closeBtn-iconContainer{
	width: 24px;
	height: 24px;
	border-radius: 50%;
	background: $white;
}

.upgrade-closeBtn .icon{
	width: 42px;
	height: 42px;
	fill: $gray-6;
}

// BULK ORDER
//

.upgrade--landing-bulkOrder{
	margin: 0 auto;
	text-align: center;
	cursor: default;
}

.upgrade--landing-bulkOrder-text{
	font-size: 22px;
	font-weight: $effra-medium;
	color: $textMid;
	margin-top: 100px;
	margin-bottom: 30px;
}

.upgrade--landing-bulkOrder button.upgrade--landing-btn--small{
	width: 360px;
	margin: 0 auto;
}