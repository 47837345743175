.repoReleaseModal-bottom-acceptBtn{

	height: 50px;
	
	display: flex;
	align-items: center;
	justify-content: center;
	
	border-radius: 5px;
	border: 1px solid $blue;
	box-sizing: border-box;

	letter-spacing: 0.005em;

	background: $blue-light;

	color: $white;
	font-size: 22px;
	font-weight: $effra-bold;

	cursor: pointer;

	user-select: none;

	width: 275px;

	margin: 0 auto;

	margin-bottom: 8px;
	margin-top: 10px;
}

.repoReleaseModal-bottom-acceptBtn:hover{
	background: darken($blue-light, 5%);
}


.repoReleaseModal-bottom-privacyPolicyText{
	text-align: center;
	font-size: 13.5px;
	font-weight: $effra-regular;

	color: $textDark;

	letter-spacing: 0.02em;

	text-align: center;
	margin-bottom: 3px;
}

.repoReleaseModal-bottom-privacyPolicyText a.repoReleaseModal-bottom-privacyPolicyText-link{
	color: $textDark;
	text-decoration: underline !important; // override global no underline link
	cursor: pointer;
}

.repoReleaseModal-bottom-privacyPolicyText a.repoReleaseModal-bottom-privacyPolicyText-link:hover{
	color: $textBlue;
}