//
// SCORESHEET TABLE HEADER ROWS
// this is the set/question titles at the top
// each set or each independent question is a column.
// within a set (setHeaderCellGroup), each setquestion is a column
// 

.scoreSheet-table-headerRow{
  position: absolute;
  top: 0px;
  left: 0px;
  display: flex;
  background: $white;
  //box-shadow: 0px 1px 0px 0px $scoreSheet-lineMid;
  z-index: 5;
}


// SET ROW

.scoreSheet-table-headerRow-setHeaderCellGroup{
  height: $height-scoreSheet-studentsHeader;
  

  background: $white;
  position: relative;

  cursor: default;
  user-select: none;
  //scroll-snap-align: start none; // prototyped scroll snap aligning
}

.scoreSheet-table-headerRow-setHeaderCellGroup-rightBorder{
  position: absolute;
  right: 0px;
  top: 0px;
  bottom: 0px;
  width: 1px;
  background: $scoreSheet-lineMid;
  z-index: 10;
}


.scoreSheet-table-headerRow-setHeaderCellGroup-titleCell{
  width: 100%;
  height: $height-scoreSheetSetHeader;
  display: flex;
  
  padding-left: 12px;
  padding-right: 12px;

  cursor: pointer;
  color: $textBlack;
}

.scoreSheet-table-headerRow-setHeaderCellGroup-titleCell-name{
  font-size: 15.5px;
  font-weight: $lato-bold;
  
  flex-shrink: 1;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  line-height: 2.6;

  margin-right: 5px;
}

.scoreSheet-table-headerRow-setHeaderCellGroup-titleCell-timestamp{
  font-size: 14.5px;
  font-weight: $lato-medium;
  opacity: 0.75;
  flex-shrink: 0;
  min-width: 0px;

  line-height: 2.9;
}


.scoreSheet-table-headerRow-setHeaderCellGroup-titleCell .scorePill{
  font-size: 15px;
  margin-left: 0px;
  flex-shrink: 0;
  width: auto;

  margin-top: 10px;
}


.scoreSheet-table-headerRow-setHeaderCellGroup-titleCell-modifiedScore{
  font-size: 15px;
  
  font-weight: $lato-bold;
  color: $textBlack;
  margin-top: 12px;
  margin-left: 5px;
}

.scoreSheet-table-headerRow-setHeaderCellGroup-titleCell-modifiedScore-percent{
  opacity: 0.75;
  margin-left: 1px;
}

.scoreSheet-table-headerRow-setHeaderCellGroup-titleCell .scorePill--isDisabled{
  opacity: 0.4;
}

.scoreSheet-table-headerRow-setHeaderCellGroup-titleCell .scorePill--isDisabled .scorePill-dot{
  display: none;
}

// EXCLUDED QUESTION CELL

.scoreSheet-table-headerRow-setHeaderCellGroup--isAllExcluded{
  background: $scoresheet-excluded-BG;
}

.scoreSheet-table-headerRow-setHeaderCellGroup--isAllExcluded
.scoreSheet-table-headerRow-setHeaderCellGroup-titleCell{
  color: $scoresheet-excluded-color;
}


.scoreSheet-table-headerRow-setHeaderCellGroup--isAllExcluded
.scoreSheet-table-headerRow-setHeaderCellGroup-titleCell .scorePill{
  opacity: 0.75;
  color: $scoresheet-excluded-color;
}




// NARROW VERSION

.scoreSheet-table-headerRow-setHeaderCellGroup--narrow
.scoreSheet-table-headerRow-setHeaderCellGroup-titleCell{  
  position: relative;
  padding-left: 6px;
  padding-right: 6px;
}

.scoreSheet-table-headerRow-setHeaderCellGroup--narrow
.scoreSheet-table-headerRow-setHeaderCellGroup-titleCell-name{
  font-size: 14.5px;
  line-height: 2.8;
}

.scoreSheet-table-headerRow-setHeaderCellGroup--narrow
.scoreSheet-table-headerRow-setHeaderCellGroup-titleCell 
.scorePill{
  display: none;
}


.scoreSheet-table-headerRow-setHeaderCellGroup--narrow
.scoreSheet-table-headerRow-setHeaderCellGroup-titleCell-timestamp{
  display: none;
}

.scoreSheet-table-headerRow-setHeaderCellGroup--narrow
.scoreSheet-table-headerRow-setHeaderCellGroup-titleCell-modifiedScore{
  display: none;
}

//

.scoreSheet-table-headerRow-setHeaderCellGroup-setQuestionCellGroup{
  width: 100%;
  height: $height-scoreSheetQuestionHeader;
  display: flex;
}




//
// SET QUESTION ROW
//

.scoreSheet-table-headerRow-setHeaderCellGroup-setQuestionCell{
  cursor: pointer;
  user-select: none;

  height: $height-scoreSheetQuestionHeader;
  flex-shrink: 0;
  width: $width-scoreSheetQuestion;
  
  padding: 6px;
  padding-top: 8px;
  
  position: relative;

  overflow: hidden;
  border-right: 1px solid $scoreSheet-lineMid;
}

.scoreSheet-table-headerRow-setHeaderCellGroup-setQuestionCell:last-child{
  border-right: 1px solid $invisible; 
}

.scoreSheet-table-headerRow-setHeaderCellGroup-setQuestionCell-label{
  font-size: 11.5px;
  height: calc(#{$height-scoreSheetQuestionHeader} - 14px);
  font-weight: $lato-medium;
  color: $textBlack;
  letter-spacing: 0.005em;
  text-align: center;

  overflow: hidden;

}

.scoreSheet-table-headerRow-setHeaderCellGroup-setQuestionCell .scorePill.type--colorOnly{
  position: absolute;
  top: 0px;
  right: 0px;
  width: 100%;
  height: 3px;
  transform: translateY(-1px);
}

.scoreSheet-table-headerRow-setHeaderCellGroup-setQuestionCell:hover .scorePill.type--colorOnly{
  transform: translateY(0px);
}

.scoreSheet-table-headerRow-setQuestionCell--isExcluded:hover .scorePill.type--colorOnly{ 
  transform: translateY(-1px);
}


// EXCLUDED SET QUESTION 

.scoreSheet-table-headerRow-setQuestionCell--isExcluded{
  background: $scoresheet-excluded-BG;
}


.scoreSheet-table-headerRow-setQuestionCell--isExcluded
.scoreSheet-table-headerRow-setHeaderCellGroup-setQuestionCell-label{
  color: $scoresheet-excluded-color;
}

//
// QUESTION CELL
//

.scoreSheet-table-headerRow-questionCell{
  cursor: pointer;
  user-select: none;

  height: $height-scoreSheet-studentsHeader;
  flex-shrink: 0;
  width: $width-scoreSheetQuestion;
  padding: 4px;
  padding-top: 8px;
  
  position: relative;

  overflow: hidden;
  border-right: 1px solid $scoreSheet-lineMid;
  color: $textBlack;
}

.scoreSheet-table-headerRow-questionCell-timestamp{
  font-size: 12.5px;
  font-weight: $lato-medium;
  opacity: 0.75;
  margin-top: 2px;
  margin-bottom: 5px;
  text-align: center;
}

.scoreSheet-table-headerRow-questionCell-label{
  font-size: 12.5px;
  height: calc(#{$height-scoreSheet-studentsHeader} - 51px);  
  font-weight: $lato-semibold;
  
  letter-spacing: 0.005em;
  text-align: center;

  overflow: hidden;
}


.scoreSheet-table-headerRow-questionCell .scorePill.type--colorOnly{
  position: absolute;
  top: 0px;
  right: 0px;
  width: 100%;
  height: 4px;
  transform: translateY(-2px);
}

.scoreSheet-table-headerRow-questionCell:hover .scorePill.type--colorOnly{
  transform: translateY(0px);
}

.scoreSheet-table-headerRow-questionCell--isExcluded:hover .scorePill.type--colorOnly{ 
  transform: translateY(-2px);
}


// EXCLUDED QUESTION CELL

.scoreSheet-table-headerRow-questionCell--isExcluded{
  background: $scoresheet-excluded-BG;
  color: $scoresheet-excluded-color;
}



//
//// COLLAPSED
//

.scoreSheet-table-headerRow-collapsedSetCell{
  height: $height-scoreSheet-studentsHeader;
  width: $width-scoreSheetCollapsedColumn;
  min-width: $width-scoreSheetCollapsedColumn;

  background: $white;
  position: relative;

  cursor: pointer;
  user-select: none;
  scroll-snap-align: start none;
  padding-left: 9px;
  padding-right: 9px;

  text-align: left;
  color: $textBlack;
}


.scoreSheet-table-headerRow-collapsedSetCell-name{
  
  font-size: 15px;
  font-weight: $lato-bold;

  max-height: 54px;

  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;

  text-overflow: clip;
  white-space: normal;
  overflow: hidden; 
}

.scoreSheet-table-headerRow-collapsedSetCell-timestamp{
  opacity: 0.75;
  font-weight: $lato-medium;
  font-size: 13px;
  margin-top: 14px;
  margin-bottom: 5px;
}

.scoreSheet-table-headerRow-collapsedSetCell-rightBorder{
  position: absolute;
  right: 0px;
  top: 0px;
  bottom: 0px;
  width: 1px;
  background: $scoreSheet-lineMid;
}

.scoreSheet-table-headerRow-collapsedSetCell .scorePill.type--colorOnly{
  position: absolute;
  top: 0px;
  right: 0px;
  width: 100%;
  height: 5px;
  transform: translateY(-2px);
}

.scoreSheet-table-headerRow-collapsedSetCell:hover .scorePill.type--colorOnly{
  transform: translateY(0px);
}

// EXCLUDED

.scoreSheet-table-headerRow-collapsedSetCell--isExcluded{
  background: $scoresheet-excluded-BG;
  color: $scoresheet-excluded-color;
}


///

// INTERACTION ON CELLS

// HOVER
.scoreSheet-table-headerRow
.scoreSheet-table-headerRow-setHeaderCellGroup-titleCell:hover,
.scoreSheet-table-headerRow-setHeaderCellGroup-setQuestionCellGroup
.scoreSheet-table-headerRow-setHeaderCellGroup-setQuestionCell:hover,
.scoreSheet-table-headerRow
.scoreSheet-table-headerRow-collapsedSetCell:hover,
.scoreSheet-table-headerRow
.scoreSheet-table-headerRow-questionCell:hover{
  //background: $color-scoreSheetCellHover-bg;
}

.scoreSheet-table-headerRow-setHeaderCellGroup-setQuestionCellGroup
.scoreSheet-table-headerRow-setHeaderCellGroup-setQuestionCell:hover{
  border-right: 1px solid $scoreSheet-lineMid;
}

// ACTIVE
.scoreSheet-table-headerRow
.scoreSheet-table-headerRow-setHeaderCellGroup-titleCell:active,
.scoreSheet-table-headerRow-setHeaderCellGroup-setQuestionCellGroup
.scoreSheet-table-headerRow-setHeaderCellGroup-setQuestionCell:active,
.scoreSheet-table-headerRow
.scoreSheet-table-headerRow-collapsedSetCell:active,
.scoreSheet-table-headerRow
.scoreSheet-table-headerRow-questionCell:active{
  background: $color-scoreSheetCellActive-bg;
}