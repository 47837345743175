.upgrade--landing-header{
	font-size: 56px;
	font-weight: $effra-bold;
	color: $textBlack;
	margin-bottom: 16px;

	cursor: default;
}

.upgrade--landing-header span.upgrade--landing-header--plickersPro-plickers{
	color: $blue;
}

.upgrade--landing-header span.upgrade--landing-header--plickersPro-pro{
	color: $blue-light;
}

///

.upgrade--landing-sub{
	display: flex;
	width: 100%;
	align-items: center;
	justify-content: center;

	font-size: 26px;
	font-weight: $effra-medium;

	cursor: default;

	margin-bottom: 36px;

	color: $textMid;
}

.upgrade--landing-sub-trial{
	color: $blue;

	position: relative;
	cursor: pointer;
	
}

.upgrade--landing-sub-trial-underline{
	position: absolute;
	left: 0px;
	bottom: -4px;
	right: 0px;

	height: 2px;
	background: $blue-light;
	border-radius: 1px;

	
}
.upgrade--landing-sub-trial:hover{
	color: darken($blue, 8%);
}

.upgrade--landing-sub-trial:hover
.upgrade--landing-sub-trial-underline{
	background: $blue;
}

.upgrade--landing-sub-price{
	color: $textMid;
}

.upgrade--landing-sub-normally{
	color: $textLight;
}



//

.upgrade--landing-btnSub{
	font-size: 16px;
	font-weight: $effra-medium;
	color: $textLight;
	margin-top: 10px;
	opacity: 0.8;

	text-align: center;
	cursor: default;
}
