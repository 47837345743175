 // Search Results Search Bar

.searchResults-noResults{
	font-size: 16px;
	font-weight: $lato-semibold;
	margin-top: 50px;
	color: $textMid;
}

.searchResults-list{
	width: calc(100% + 24px);	
	margin-left: -12px;
	box-sizing: border-box;	
}

// Search Results Card

$height-searchResultsCard: 142px;
$height-searchResultsCard--folder: 46px;
$height-searchResultsCard--repo: 46px;

$height-searchResultsCard--hideSlide: 94px;

.searchResultsCard{
	width: 100%;
	height: $height-searchResultsCard;

	padding-top: 16px;
	padding-bottom: 8px;
	//margin-bottom: 8px;
	padding-left: 226px;
	padding-right: 48px;

	box-sizing: border-box;

	user-select: none;


	//background: $tempr;
	border-radius: 4px;

	display: flex;
	flex-direction: column;
	position: relative;

	cursor: pointer;
}

.searchResultsCard--hideSlide{
	height: $height-searchResultsCard--hideSlide;
	padding-left: 12px;
}

.searchResultsCard--folder,
.searchResultsCard--repo{
	flex-direction: row;
	align-items: center;
	padding-top: 0px;
	padding-bottom: 0px;
	padding-right: 0px; 
}

.searchResultsCard--folder{
	height: $height-searchResultsCard--folder;
	padding-left: 46px;
}

.searchResultsCard--repo{
	height: $height-searchResultsCard--repo;
	padding-left: 12px;
}

// 

.searchResultsCard--folder .icon.folder{
	width: 20px;
	height: 20px;
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	left: 14px;
}

.searchResultsCard-title{
	font-size: 18px;
	font-weight: $lato-bold;
	color: $textBlack;

	display: -webkit-box;
  // -webkit-line-clamp: 3; // with slide thumbnail
  -webkit-line-clamp: 2; // without slide thumbnail
  -webkit-box-orient: vertical;

  text-overflow: clip;
  white-space: normal;
  overflow: hidden; 

  // max-height: 61px;  // with slide thumbnail
  max-height: 44px;  // without slide thumbnail

}



.searchResultsCard--folder .searchResultsCard-title,
.searchResultsCard--repo .searchResultsCard-title {
	text-overflow: ellipsis;
	overflow: hidden;
  white-space: nowrap;

}

.searchResultsCard--folder-repo{ // link
	margin-left: 6px;
	font-weight: $lato-medium;
	color: $textLight;
	position: relative;
	z-index: 2000; // allow link to be clicked
}

.searchResultsCard .icon.shared,
.searchResultsCard .icon.globe-shared,
.searchResultsCard .icon.globe-shared-author{
	width: 14px;
	height: 14px;
	fill: $gray-115;
	margin-left: 2px;
	flex-shrink: 0;
	position: relative;
	top: 1px;
}

.searchResultsCard--repo .icon.shared,
.searchResultsCard--folder .icon.shared,
.searchResultsCard--repo .icon.globe-shared,
.searchResultsCard--folder .icon.globe-shared,
.searchResultsCard--repo .icon.globe-shared-author,
.searchResultsCard--folder .icon.globe-shared-author{
	width: 15px;
	height: 15px;
	margin-left: 7px;
	margin-right: 3px;
}



// 

.searchResults-subHeader{
	//spacer between folders and sets
	width: 100%;
	
	background: $invisible;
	// background: $tempr;

	font-size: 13px;
	font-weight: $lato-semibold;
	color: $textLight;
	letter-spacing: 0.02em;
	text-transform: uppercase;

	//padding-left: 14px;

	box-sizing: border-box;

	//background: rgba(0,0,40,0.025);
	//border-radius: 4px;
	margin-top: 20px;
	margin-bottom: 0px;
	cursor: default;
	user-select: none;
}


// for links

.searchResultsCard-details{
	display: flex;
	margin-top: 4px;	
	align-items: center;
}

.searchResultsCard-details-folder,
.searchResultsCard-details-repo{
	font-size: 15px;
	
	text-overflow: ellipsis;
	overflow: hidden;
  white-space: nowrap;
  cursor: pointer;
}

.searchResultsCard-details-repo{
	margin-right: 4px;
	color: $textMid;
	font-weight: $lato-semibold;
}

.searchResultsCard-details .icon.chevron-right{
	// used when repo and folder
	height: 10px;
	width: 10px;
	fill: $textLight;
	opacity: 0.4;
	margin-right: 4px;
	flex-shrink: 0;
	flex-grow: 0;
}

	

.searchResultsCard-details-folder{
	color: $textMid;
	font-weight: $lato-medium;
}

.searchResultsCard-details-folder:hover,
.searchResultsCard-details-repo:hover,
.searchResultsCard--folder-repo:hover{
	color: $blue;
}

// .searchResultsCard-details-timestamp{
// 	font-size: 15px;
// 	font-weight: $lato-medium;
// 	color: $textLight;
// 	margin-left: 4px;
// 	cursor: default;
// 	flex-shrink: 0;
// 	flex-grow: 0;
// }


.searchResultsCard-timestamp{
	margin-top: 4px;
	font-size: 14.5px;
	font-weight: $lato-medium;
	color: $textLight;
}


.searchResultsCard-rightArrow{
	position: absolute;
	height: 14px;
	width: 14px;
	top: 22px;
	right: 8px;
	opacity: 1;
	display: flex;
	//background: $tempr;
}

.searchResultsCard--folder .searchResultsCard-rightArrow,
.searchResultsCard--repo .searchResultsCard-rightArrow{
	position: static;
	padding-right: 8px;
	opacity: 1;
	display: flex;
	flex-grow: 0;
	flex-shrink: 0;
	margin-left: auto;
	//background: $tempr;
}



.searchResultsCard-rightArrow .icon{
	height: 14px;
	width: 14px;
	fill: $lineDark;
}

.searchResultsCard-questionPreview{
	position: absolute;
	top: 8px;
	left: 12px;
	bottom: 8px;
	width: 200px;
	//cursor: pointer;
}

.searchResultsCard-questionPreview
.slideCarousel-playBtn{
	cursor: pointer;
}

.searchResultsCard:hover .searchResultsCard-rightArrow .icon{
	fill: $blue;
}

.searchResultsCard:hover .icon.shared{
	fill: $gray-11;
}

.searchResults-list:hover .searchResultsCard{
	opacity: 0.92;
}

.searchResultsCard
.slideCarousel-slide .questionCountBadge{
	transform: scale(1.1);
}

.searchResults-list .searchResultsCard:hover{
	opacity: 1;
}

.searchResultsCard:hover .searchResultsCard-title{
	color: $textBlackDark;
}

.searchResultsCard:hover
.searchResultsCard-questionPreview 
.slideCarousel-slide {
	border-color: rgba(0,0,40,0.15);
	border-bottom-color: rgba(0,0,40,0.22);
}

.searchResultsCard:hover .searchResultsCard-timestamp{
	color: $textMid;
}

.searchResultsCard:hover{
	background: lighten($blue-extraLight, 1.5%);
	background: $gray-15;
}





// SHOW MORE BUTTON
// extremely rare

.searchResults-list-showMoreContainer{
	margin-top: 20px;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.searchResults-list-showMoreContainer button{
	min-width: 250px;
}

// FIX
.page.searchResults
.ais-InstantSearch__root{
	width: inherit;
	//width: calc(100% + #{$leftGutter-centerPanel} + #{$rightGutter-centerPanel});
	margin-left: calc(-1 * #{$leftGutter-centerPanel});
	padding-left: $leftGutter-centerPanel;
	padding-right: $rightGutter-centerPanel;
	box-sizing: border-box;
	position: relative;
}