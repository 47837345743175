.searchDropdown{
	position: absolute;
	top: 38px;
	margin-left: 12px;

	width: calc(#{$maxWidth-searchBar} - 2px);
	background: $white;	

	border: 1px solid $lineDark;
	
	border-radius: 3px;
	border-top-left-radius: 0px;
	border-top-right-radius: 0px;

	box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.12);
	z-index: 250;
}

// HIDE BULLET POINTS
.react-autosuggest__suggestions-list{
	list-style-type: none;
	padding: 0px;
	margin: 0px;
}

// HIDE SECTION TITLE
.react-autosuggest__section-title{
	display: none;
}

.searchDropdownItem{
	display: flex;
	align-items: center;

	height: 44px;
	width: 100%;
	
	box-sizing: border-box;
	padding-left: 42px;
	padding-right: 10px;
	position: relative;

	
	-webkit-user-select: none;
	cursor: default;

	// background: $tempr;
	// margin-bottom: 2px;
}



.searchDropdownItem .questionCountBadge{
	position: absolute;
	left: 9px;
	top: 12px;
}

.searchDropdownItem .icon.folder{
	position: absolute;
	width: 18px;
	height: 18px;
	left: 14px;
	top: 12px;
}

.searchDropdownItem-title{
	font-weight: $lato-semibold;
	font-size: 14px;
	color: $textBlack;
	line-height: 1.2;
	position: relative;
	top: -1px;

	// long string handling
	white-space: nowrap;
  overflow: hidden;

  flex-grow: 1;
  // background: $tempr;

}

//
// Show for items and repos
//

.searchDropdownItem .icon.shared,
.searchDropdownItem .icon.globe-shared,
.searchDropdownItem .icon.globe-shared-author{
	height: 12px;
	width: 12px;
	fill: $gray-12;
	margin-left: 6px;
	position: relative;
	top: 2px;
	
	flex-shrink: 0;
	flex-grow: 0;
}

.searchDropdownItem .icon.globe-shared,
.searchDropdownItem .icon.globe-shared-author{
	top: 1px;
}

.searchDropdownItem--repo .icon.shared{
	padding-bottom: 5px;
}
.searchDropdownItem--repo .icon.globe-shared,
.searchDropdownItem--repo .icon.globe-shared-author,{
	padding-bottom: 6px;
}



// REPO
.searchDropdownItem--repo{
	padding-left: 16px;
}



.searchDropdownItem--repo .searchDropdownItem-title{
	color: $textBlackDark;
	letter-spacing: 0.005em;
	flex-shrink: 1;
	flex-grow: 0;
}



// for containingin repo
.searchDropdownItem-title-repo{
	color: $textLight;
	font-weight: $lato-medium;
	margin-left: 4px;
}



// HIGHLIGHTED
.react-autosuggest__suggestion--highlighted .searchDropdownItem{
	background: rgb(246,246,252);
}

.react-autosuggest__suggestion--highlighted .searchDropdownItem .questionCountBadge{
	background: $blue;
	color: $white;
}


// 
.is--showMore{
	// height: 36px;
	// background: rgb(250,250,255);
	// background: $tempr;
}

.is--showMore .searchDropdownItem-title{
	margin-left: -30px;
	color: $textBlue;	
	font-weight: $lato-bold;
}

// SCOREPILL EXAMPLEreact-autosuggest__suggestion--highlighted"

.searchDropdownItem-scorePills{
	display: flex;
	flex-grow: 0;
	// background: $tempb;
	align-items: center;
	box-sizing: border-box;
	padding-left: 10px;
}

.searchDropdownItem .scorePill{
	color: $invisible;
	overflow: hidden;
	height: 2px;
	width: 8px;
	margin-left: 3px;
}


// ONLY ON MOUSE CLICK FOR NOW
.searchDropdownItem:active{
	background: $gray-13;
}