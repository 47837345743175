$gutterWidth-sectionHome-eLearningBanner: 24px;

.sectionHome-eLearningBanner{
	background: $tempr;

	position: relative;
	width: calc(100% + #{$gutterWidth-sectionHome-eLearningBanner} + #{$gutterWidth-sectionHome-eLearningBanner});
	margin-left: calc(-1 * #{$gutterWidth-sectionHome-eLearningBanner});
	padding-left: $gutterWidth-sectionHome-eLearningBanner;
	padding-right: $gutterWidth-sectionHome-eLearningBanner;

	padding-top: 15px;
	padding-bottom: 15px;

	box-sizing: border-box;

	position: relative;

	border-radius: 8px;

	margin-top: -14px;

	height: 190px;
	margin-bottom: 36px;
}


//
// TITLE AND SUBTITLE

.sectionHome-eLearningBanner-title{
	margin-top: 12px;

	font-weight: $lato-heavy;
	color: $textBlackDark;
	font-size: 18px;
	letter-spacing: 0.01em;

	margin-bottom: 5px;
}

.sectionHome-eLearningBanner-subtitle{
	font-size: 15px;
	font-weight: $lato-medium;
	color: $textBlackDark;
	opacity: 0.7;

	margin-bottom: 48px;
}

//
// SUBMIT BUTTON

button.btn.btn--mid.btn--white.elearningbannersubmit{
	font-weight: $lato-bold;
	cursor: pointer;
	border-radius: 3px;
	color: $white;
	letter-spacing: 0.015em;
	font-size: 16px;
}


//
// DISMISS BUTTON

.sectionHome-eLearningBanner-secondLevelButtons{
	display: flex;
}

button.btn.btn--mid.btn--white.elearningbannerdismiss{
	background: none;
	border: none;
	box-shadow: none;
	cursor: pointer;

	margin-top: 6px;
	font-size: 14px;

	opacity: 0.8;

	height: 24px;

	margin-right: 4px;
}

button.btn.btn--mid.btn--white.elearningbannerdismiss:hover{
	opacity: 1;
}

//
// ILLUSTRATION

.sectionHome-eLearningBanner-illustrationContainer{
	width: 252px;
	height: 154px;
	position: absolute;
	right: 70px;
	top: 32px;
}

.sectionHome-eLearningBanner-illustrationContainer img{
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0px;
	left: 0px;
	right: 0px;
	bottom: 0px;
}

.sectionHome-eLearningBanner-illustration-backgroundContainer svg{
	width: 100%;
	height: 100%;
}


//
// CLOSE BTN

.sectionHome-eLearningBanner-closeBtn{
	position: absolute;
	width: 22px;
	height: 22px;
	top: 10px;
	right: 10px;
	cursor: pointer;
	z-index: 50;
	border-radius: 12px;

	opacity: 0.5;
	transition: opacity 100ms linear;
}

.sectionHome-eLearningBanner-closeBtn:hover{
	opacity: 0.8;
	transition: opacity 50ms linear;
}

.sectionHome-eLearningBanner-closeBtn:active{
	opacity: 1;
}

.sectionHome-eLearningBanner-closeBtn .icon{
	width: 22px;
	height: 22px;
	fill: $gray-6;
}

//
// COLORING

.sectionHome-eLearningBanner--classColor-purple{

	background: transparentize($classColor-purple, 0.95);	

	button.btn.btn--mid.btn--white.elearningbannersubmit{
		background: $classColor-purple;
		border: 1px solid darken($classColor-purple, 5%);
	}
	button.btn.btn--mid.btn--white.elearningbannersubmit:hover{
		background: darken($classColor-purple, 3%);
		border: 1px solid darken($classColor-purple, 6%);
	}

	button.btn.btn--mid.btn--white.elearningbannerdismiss{
		color: $classColor-purple;
	}

	.sectionHome-eLearningBanner-illustration-backgroundContainer svg #background{
		fill: transparentize($classColor-purple, 0.5);
	}

	.sectionHome-eLearningBanner-illustration-backgroundContainer svg #shadow{
		fill: transparentize($classColor-purple, 0.75);
	}

	.sectionHome-eLearningBanner-closeBtn .icon{
		fill: $classColor-purple;
	}

}

.sectionHome-eLearningBanner--classColor-blue{

	background: transparentize($classColor-blue, 0.95);	

	button.btn.btn--mid.btn--white.elearningbannersubmit{
		background: $classColor-blue;
		border: 1px solid darken($classColor-blue, 5%);
	}
	button.btn.btn--mid.btn--white.elearningbannersubmit:hover{
		background: darken($classColor-blue, 3%);
		border: 1px solid darken($classColor-blue, 6%);
	}

	button.btn.btn--mid.btn--white.elearningbannerdismiss{
		color: $classColor-blue;
	}

	.sectionHome-eLearningBanner-illustration-backgroundContainer svg #background{
		fill: transparentize($classColor-blue, 0.5);
	}

	.sectionHome-eLearningBanner-illustration-backgroundContainer svg #shadow{
		fill: transparentize($classColor-blue, 0.75);
	}

	.sectionHome-eLearningBanner-closeBtn .icon{
		fill: $classColor-blue;
	}

}


.sectionHome-eLearningBanner--classColor-orange{

	background: transparentize($classColor-orange, 0.9);	

	button.btn.btn--mid.btn--white.elearningbannersubmit{
		background: darken($classColor-orange, 5%);
		border: 1px solid darken($classColor-orange, 10%);
	}
	button.btn.btn--mid.btn--white.elearningbannersubmit:hover{
		background: darken($classColor-orange, 8%);
		border: 1px solid darken($classColor-orange, 12%);
	}

	button.btn.btn--mid.btn--white.elearningbannerdismiss{
		color: $classColor-orange;
	}

	.sectionHome-eLearningBanner-illustration-backgroundContainer svg #background{
		fill: transparentize($classColor-orange, 0.4);
	}

	.sectionHome-eLearningBanner-illustration-backgroundContainer svg #shadow{
		fill: transparentize($classColor-orange, 0.65);
	}

	.sectionHome-eLearningBanner-closeBtn .icon{
		fill: darken($classColor-orange, 5%);
	}

}


.sectionHome-eLearningBanner--classColor-red{

	background: transparentize($classColor-red, 0.95);

	button.btn.btn--mid.btn--white.elearningbannersubmit{
		background: $classColor-red;
		border: 1px solid darken($classColor-red, 5%);
	}
	button.btn.btn--mid.btn--white.elearningbannersubmit:hover{
		background: darken($classColor-red, 3%);
		border: 1px solid darken($classColor-red, 6%);
	}

	button.btn.btn--mid.btn--white.elearningbannerdismiss{
		color: $classColor-red;
	}

	.sectionHome-eLearningBanner-illustration-backgroundContainer svg #background{
		fill: transparentize($classColor-red, 0.5);
	}

	.sectionHome-eLearningBanner-illustration-backgroundContainer svg #shadow{
		fill: transparentize($classColor-red, 0.75);
	}

	.sectionHome-eLearningBanner-closeBtn .icon{
		fill: $classColor-red;
	}

}


.sectionHome-eLearningBanner--classColor-green{

	background: transparentize($classColor-green, 0.92);

	button.btn.btn--mid.btn--white.elearningbannersubmit{
		background: $classColor-green;
		border: 1px solid darken($classColor-green, 5%);
	}
	button.btn.btn--mid.btn--white.elearningbannersubmit:hover{
		background: darken($classColor-green, 3%);
		border: 1px solid darken($classColor-green, 6%);
	}

	button.btn.btn--mid.btn--white.elearningbannerdismiss{
		color: $classColor-green;
	}

	.sectionHome-eLearningBanner-illustration-backgroundContainer svg #background{
		fill: transparentize($classColor-green, 0.5);
	}

	.sectionHome-eLearningBanner-illustration-backgroundContainer svg #shadow{
		fill: transparentize($classColor-green, 0.75);
	}

	.sectionHome-eLearningBanner-closeBtn .icon{
		fill: $classColor-green;
	}

}

.sectionHome-eLearningBanner--classColor-sky{

	background: transparentize($classColor-sky, 0.92);

	button.btn.btn--mid.btn--white.elearningbannersubmit{
		background: darken($classColor-sky, 3%);
		border: 1px solid darken($classColor-sky, 8%);
	}
	button.btn.btn--mid.btn--white.elearningbannersubmit:hover{
		background: darken($classColor-sky, 6%);
		border: 1px solid darken($classColor-sky, 10%);
	}

	button.btn.btn--mid.btn--white.elearningbannerdismiss{
		color: $classColor-sky;
	}

	.sectionHome-eLearningBanner-illustration-backgroundContainer svg #background{
		fill: transparentize($classColor-sky, 0.45);
	}

	.sectionHome-eLearningBanner-illustration-backgroundContainer svg #shadow{
		fill: transparentize($classColor-sky, 0.7);
	}

	.sectionHome-eLearningBanner-closeBtn .icon{
		fill: darken($classColor-sky, 5%);
	}

}

.sectionHome-eLearningBanner--classColor-pink{

	background: transparentize($classColor-pink, 0.95);

	button.btn.btn--mid.btn--white.elearningbannersubmit{
		background: darken($classColor-pink, 3%);
		border: 1px solid darken($classColor-pink, 7%);
	}
	button.btn.btn--mid.btn--white.elearningbannersubmit:hover{
		background: darken($classColor-pink, 6%);
		border: 1px solid darken($classColor-pink, 9%);
	}

	button.btn.btn--mid.btn--white.elearningbannerdismiss{
		color: $classColor-pink;
	}

	.sectionHome-eLearningBanner-illustration-backgroundContainer svg #background{
		fill: transparentize($classColor-pink, 0.5);
	}

	.sectionHome-eLearningBanner-illustration-backgroundContainer svg #shadow{
		fill: transparentize($classColor-pink, 0.75);
	}

	.sectionHome-eLearningBanner-closeBtn .icon{
		fill: $classColor-pink;
	}

}

.sectionHome-eLearningBanner--classColor-teal{

	background: transparentize($classColor-teal, 0.95);

	button.btn.btn--mid.btn--white.elearningbannersubmit{
		background: $classColor-teal;
		border: 1px solid darken($classColor-teal, 5%);
	}
	button.btn.btn--mid.btn--white.elearningbannersubmit:hover{
		background: darken($classColor-teal, 3%);
		border: 1px solid darken($classColor-teal, 6%);
	}

	button.btn.btn--mid.btn--white.elearningbannerdismiss{
		color: $classColor-teal;
	}

	.sectionHome-eLearningBanner-illustration-backgroundContainer svg #background{
		fill: transparentize($classColor-teal, 0.5);
	}

	.sectionHome-eLearningBanner-illustration-backgroundContainer svg #shadow{
		fill: transparentize($classColor-teal, 0.75);
	}

	.sectionHome-eLearningBanner-closeBtn .icon{
		fill: $classColor-teal;
	}

}

.sectionHome-eLearningBanner--classColor-grey{

	background: transparentize($classColor-grey, 0.95);

	button.btn.btn--mid.btn--white.elearningbannersubmit{
		background: $classColor-grey;
		border: 1px solid darken($classColor-grey, 5%);
	}
	button.btn.btn--mid.btn--white.elearningbannersubmit:hover{
		background: darken($classColor-grey, 3%);
		border: 1px solid darken($classColor-grey, 6%);
	}

	button.btn.btn--mid.btn--white.elearningbannerdismiss{
		color: $classColor-grey;
	}

	.sectionHome-eLearningBanner-illustration-backgroundContainer svg #background{
		fill: transparentize($classColor-grey, 0.5);
	}

	.sectionHome-eLearningBanner-illustration-backgroundContainer svg #shadow{
		fill: transparentize($classColor-grey, 0.75);
	}

	.sectionHome-eLearningBanner-closeBtn .icon{
		fill: $classColor-grey;
	}

}

