//
// SCORESHEET STUDENTS
// the sortable student list panel
//

$height-scoreSheet-studentsHeader: calc(#{$height-scoreSheetSetHeader} + #{$height-scoreSheetQuestionHeader});
$height-scoreSheet-studentsFooter: calc(#{$height-scoreSheetSetFooter} - 1px);

$width-scoreSheet-studentCol--leftPadding: 16px;
$width-scoreSheet-studentCol--name: 130px;
$width-scoreSheet-studentCol--firstName: 100px;
$width-scoreSheet-studentCol--lastName: 100px;
$width-scoreSheet-studentCol--cardNumber: 30px;
$width-scoreSheet-studentCol--score: 62px;

$width-scoreSheet-students--base: calc(#{$width-scoreSheet-studentCol--leftPadding} + #{$width-scoreSheet-studentCol--score});
$width-scoreSheet-students--default: calc(#{$width-scoreSheet-students--base} + #{$width-scoreSheet-studentCol--name});
$width-scoreSheet-students--showCardNumber: calc(#{$width-scoreSheet-students--default} + #{$width-scoreSheet-studentCol--cardNumber});
$width-scoreSheet-students--showLastName: calc(#{$width-scoreSheet-students--base} + #{$width-scoreSheet-studentCol--firstName} + #{$width-scoreSheet-studentCol--lastName});
$width-scoreSheet-students--maxWidth: calc(#{$width-scoreSheet-students--base} + #{$width-scoreSheet-studentCol--firstName} + #{$width-scoreSheet-studentCol--lastName} + #{$width-scoreSheet-studentCol--cardNumber});

.scoreSheet-students{
    height: 100%;
    border-right: 1px solid $scoreSheet-lineMid;
    z-index: 5;
    background: $color-scoreSheet-bg;
    overflow: hidden;
    flex-grow: 0;
    flex-shrink: 0;
    position: relative;
}

// Settings are available from the top right dropdown to show extra columns
.scoreSheet-students--default{
		width: $width-scoreSheet-students--default;
}

.scoreSheet-students--showCardNumber{
		width: $width-scoreSheet-students--showCardNumber;
}

.scoreSheet-students--showLastName{
		width: $width-scoreSheet-students--showLastName;
}

.scoreSheet-students--showLastName.scoreSheet-students--showCardNumber{
		width: $width-scoreSheet-students--maxWidth;
}

//
// LAYOUT

.scoreSheet-students-header{
	height: calc(#{$height-scoreSheetSetHeader} + #{$height-scoreSheetQuestionHeader} + 1px);
	background: $white;
	border-bottom: 1px solid $scoreSheet-lineMid;
	display: flex;
	width: 100%;
	box-sizing: border-box;

	cursor: pointer;
	user-select: none;

	padding-left: $width-scoreSheet-studentCol--leftPadding;
}

.scoreSheet-students-students{
  overflow-x: hidden;
  overflow-y: auto;
  
  width: 100%;
  position: absolute;
  top: calc(#{$height-scoreSheetSetHeader} + #{$height-scoreSheetQuestionHeader} + #{$height-scoreSheetSetFooter} - 1px);
  // header and footer added together, have to break out like this for IE

  bottom: calc(#{$height-scoreSheetXScrollPlaceholder} - 2px);
  margin-bottom: 1px;
  left: 0px;
  border-bottom: 1px solid $scoreSheet-lineMid;
}

.scoreSheet-students-students::-webkit-scrollbar {
  width: 0px;  
}

.scoreSheet-students-header-col.is--cardNumber,
.scoreSheetStudent-col.is--cardNumber{
	width: $width-scoreSheet-studentCol--cardNumber;
	padding-right: 7px;
	text-align: right;
	justify-content: flex-end;
}

.scoreSheet-students-header-col.is--name,
.scoreSheetStudent-col.is--name{
	width: $width-scoreSheet-studentCol--name;
}

.scoreSheet-students-header-col.is--firstName,
.scoreSheetStudent-col.is--firstName{
	width: $width-scoreSheet-studentCol--firstName;	
}

.scoreSheet-students-header-col.is--lastName,
.scoreSheetStudent-col.is--lastName{
	width: $width-scoreSheet-studentCol--lastName;	
}

.scoreSheet-students-header-col.is--score,
.scoreSheetStudent-col.is--score{	
	width: $width-scoreSheet-studentCol--score;
}

/// HEADER 

.scoreSheet-students-header-col{
	display: flex;
	align-items: flex-end;
	font-size: 14px;
	font-weight: $lato-bold;
	color: $textDark;
	padding-bottom: 5px;
}

.scoreSheet-students-header-col:hover{
	color: $textBlackDark;
}

.scoreSheet-students-header-col.is--score{	
	justify-content: flex-end;
	padding-right: 8px;
}


/// SORT ICON HEADER

.scoreSheet-students-header-sortIcon .icon{
	height: 12px;
	width: 12px;
	position: relative;
	margin-left: 4px;
	margin-right: 4px;
	top: 2px;
	fill: $textLight;
}

.scoreSheet-students-header-col:hover .scoreSheet-students-header-sortIcon .icon{
	fill: $blue;
}

.scoreSheet-students-header-sortIcon.scoreSheet-students-header-sortIcon--ascending
.icon.chevron-small-down{
	display: none;
}

.scoreSheet-students-header-sortIcon.scoreSheet-students-header-sortIcon--descending
.icon.chevron-small-up{
	display: none;
}


//// CELLS

.scoreSheetStudent{	
	user-select: none !important;

	display: flex;
	
	line-height: 1.8;

	height: $height-scoreSheetResponseCell;
	width: 100%;
	
	background: $color-scoreSheetCell-bg;

	font-size: 14px;

	box-sizing: border-box;
	padding-left: $margin-studentsLeft;

	position: relative;

	cursor: pointer !important;
}

.scoreSheetStudent--guest{
	cursor: default !important;	
}

.scoreSheetStudent:nth-child(even){
	background: $color-scoreSheetCellZebra-bg;
}


.scoreSheetStudent:last-child{
	box-shadow: 0px 1px 0px 0px $lineDark;
}

.scoreSheetStudent-col{
	text-align: left;
	border-right: 1px solid transparentize($scoreSheet-lineLight,0.5);

	box-sizing: border-box;
	position: relative;
	z-index: 5;

	letter-spacing: 0.005em;
	cursor: pointer !important;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.scoreSheetStudent--guest
.scoreSheetStudent-col{
	cursor: default !important;	
}

.scoreSheetStudent-col.is--cardNumber{
	color: $textMid;
}

.scoreSheetStudent-col.is--name,
.scoreSheetStudent-col.is--firstName,
.scoreSheetStudent-col.is--lastName{
	color: $textBlackDark;
	font-weight: $lato-medium;
}

.scoreSheetStudent-col.is--score{
	border-right: none;
	text-align: right;

	display: flex;
	align-items: center;
	justify-content: flex-end;

	color: $textBlackDark;	
	box-sizing: border-box;
	
	padding-right: 5px;

	overflow: hidden;
	cursor: default !important;
}

.scoreSheetStudent-col.is--score .scorePill.type--dot{
	width: auto;
	font-size: 14px;
	color: $textBlackDark;	
	font-weight: $lato-medium;
	margin-top: 2px;
}

.scoreSheetStudent-col.is--score .scorePill.type--dot
.scorePill-percentSign{
	color: $textMid;
	font-weight: $lato-medium;
}

.scoreSheetStudent-col.is--score .scorePill.type--dot
.scorePill-dot{
	width: 6px;
	height: 6px;
	margin-top: 4px;
	margin-right: 3px;
}

.scoreSheetStudent-col.is--score .scorePill.type--dot.is--absent{
	opacity: 0;
}

.scoreSheetStudent .scorePill.type--colorOnly{
	position: absolute;
	left: -8px;
	top: 0;
	width: 5px;
	height: 100%;
	border-radius: 0px;
	color: $invisible;
	transform: translateX(5px);
	border-top: 0.5px solid $white;
	border-bottom: 0.5px solid $white;
	transition: all 200ms cubic-bezier(0.2, 0.3, 0.25, 0.9);
}

/// On hover of student cell, shows report pack icon
.scoreSheetStudent-reportPackIcon{
	width: $height-scoreSheetResponseCell;
	height: $height-scoreSheetResponseCell;
	display: flex;
	align-items: center;
	justify-content: center;

	position: absolute;
	top: 0px;
	right: calc(#{$width-scoreSheet-studentCol--score});
	opacity: 0;
}

.scoreSheetStudent:hover .scoreSheetStudent-reportPackIcon{
	opacity: 1;
}

.scoreSheetStudent-reportPackIcon svg{
	width: 14px;
	height: 14px;
	fill: $gray-11;
}

// FOOTER
// now at the top

.scoreSheet-students-footer{
	position: absolute;
  top: calc(#{$height-scoreSheetSetHeader} + #{$height-scoreSheetQuestionHeader} + 1px);
  left: 0px;
  width: 100%;
  height: $height-scoreSheet-studentsFooter;
	background: $white;
	border-bottom: 1px solid $scoreSheet-lineMid;
	
	width: 100%;
	box-sizing: border-box;

	display: flex;
	align-items: top;
	cursor: default;

	padding-top: 3px;
	padding-left: $margin-studentsLeft;
}

.scoreSheet-students-footer-label{
	font-size: 14px;
	color: $textDark;
	font-weight: $lato-semibold;
	margin-right: auto;
	margin-top: 2px;
}

.scoreSheet-students-footer .scorePill.type--dot{
	width: 60px;
	font-size: 14px;
	//margin-right: 3px;
}

.scoreSheet-students-footer .scorePill.type--dot
.scorePill-percentSign{
	color: $textMid;
	font-weight: $lato-medium;
}

.scoreSheet-students-footer .scorePill.type--dot
.scorePill-dot{
	width: 6px;
	height: 6px;
	margin-top: 4px;
	margin-right: 3px;
}
