a.discover-repoLink{
	height: 148px;
	width: 100%;

	box-sizing: border-box;
	padding-top: 10px;

	display: block;

	cursor: pointer;

	display: flex;

	padding-left: 16px;
	padding-right: 16px;
	margin-left: -16px;
	border-radius: 5px;
}

a.discover-repoLink:hover{
	background: $gray-15;
}

//
//

.discover-repoLink-left{
	width: 140px;
	flex-grow: 0;
	flex-shrink: 0;
}

.discover-repoLink-left-repoImageContainer{
	width: 128px;
	height: 128px;
	background: $gray-14;
	border-radius: 4px;
	overflow: hidden;
	position: relative;

}

img.discover-repoLink-left-repoImage{
	position: absolute;
	top: 0px;
	left: 0px;
	right: 0px;
	bottom: 0px;
	height: 100%;
	width: 100%;
}

//
//

.discover-repoLink-center{
	flex-grow: 1;
	flex-shrink: 1;
}


.discover-repoLink-center-title{
	font-size: 17px;
	font-weight: $lato-bold;
	color: $textBlack;
	margin-top: 4px;
	margin-bottom: 3px;
}

a.discover-repoLink:hover
.discover-repoLink-center-title{
	color: $textBlackDark;
}

.discover-repoLink-center-creator{
	font-size: 14px;
	font-weight: $lato-semibold;
	color: $textDark;
	margin-bottom: 3px;
}

a.discover-repoLink:hover
.discover-repoLink-center-creator{
	color: $textBlack;
}

.discover-repoLink-center-grades{
	font-size: 13px;
	font-weight: $lato-semibold;
	color: $textMid;
	margin-bottom: 4px;
}

a.discover-repoLink:hover
.discover-repoLink-center-grades{
	color: $textBlack;
}

.discover-repoLink-center-description{
	font-size: 12.5px;
	font-weight: $lato-medium;
	color: lighten($textMid, 8%);
}


//
//

.discover-repoLink-right{
	width: 40px;
	flex-grow: 0;
	flex-shrink: 0;

	opacity: 0.2;

	display: flex;
	justify-content: flex-end;

}

.discover-repoLink-right svg.icon{
	width: 13px;
	height: 13px;
	fill: $gray-5;
	margin-top: 8px;
}

a.discover-repoLink:hover
.discover-repoLink-right{
	opacity: 1;
}