.manageSections-modal .modal-content{
	height: 520px;
	width: 670px;
}

.manageSections{
	box-sizing: border-box;
	padding-top: 20px;
	
	display: flex;
	flex-direction: column;
	// justify-content: center;
	align-items: center;
}

// TOGGLES

.manageSections-sectionTypeView-toggles{
	display: flex;
	width: 400px;
	height: 28px;
	margin-bottom: 15px;

	cursor: default;
	user-select: none;
}

.manageSections-sectionTypeView-toggles-toggle{
	width: 50%;
	height: 100%;
	
	text-align: center;
	font-size: 15px;
	font-weight: $lato-bold;
	line-height: 1.8;

	position: relative;
}

.manageSections-sectionTypeView-toggles-toggle-count{
	position: absolute;
	right: 10px;
	text-align: right;
	top: 0px;
	opacity: 0.5;
}

.manageSections-sectionTypeView-toggles-toggle.is--inactive{
	background: $white;
	color: $textMid;
	font-weight: $lato-semibold;
	border: 1px solid $lineDark;
}

.manageSections-sectionTypeView-toggles-toggle.is--inactive:hover{
	color: $textDark;
}

.manageSections-sectionTypeView-toggles-toggle.is--inactive:first-child{
	border-right: 1px solid $invisible;
}

.manageSections-sectionTypeView-toggles-toggle.is--inactive:last-child{
	border-left: 1px solid $invisible;
}

.manageSections-sectionTypeView-toggles-toggle.is--active{
	background: $blue-light;
	color: $white;
	border: 1px solid darken($blue-light,0.5);
}

.manageSections-sectionTypeView-toggles-toggle:first-child{
	border-top-left-radius: 3px;
	border-bottom-left-radius: 3px;
}

.manageSections-sectionTypeView-toggles-toggle:last-child{
	border-top-right-radius: 3px;
	border-bottom-right-radius: 3px;
}


//

.manageSections-explainText{
	font-size: 12px;
	font-weight: $lato-medium;
	color: $textDark;
	text-align: center;
	margin-bottom: 10px;
}

.manageSections-explainText b{
	font-size: 13px;
	font-weight: $lato-semibold;
	color: $red;
}

// sectionList and Rows

.manageSections-sectionList{
	display: flex;
	flex-direction: column;
	width: 100%;
	align-items: center;

	
	height: 296px;
	// background: $tempr;
	overflow-y: scroll;

	box-sizing: border-box;
	
	padding-left: 60px;
	padding-right: 60px;
}

.manageSections-sectionList-sectionRow{
	width: 100%;
	display: flex;
	min-height: 45px;
	height: 45px;
	align-items: center;
	
	border-bottom: 1px solid $lineMid;

	margin-left: 20px;
}

.manageSections-sectionList-sectionRow--submitLoading{
	pointer-events: none;
	opacity: 0.25;
}

.manageSections-sectionList-sectionRow:last-child{
	border-bottom: none;
}

.manageSections-sectionList-sectionRow .sectionMarker{
	margin-left: -20px;

	font-size: 16px;
	font-weight: $lato-semibold;
	color: $textDark;

	//max-width: 200px;

	white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.manageSections-sectionList-sectionRow .sectionMarker .icon{
	height: 12px;
	width: 12px;
	margin-right: 10px;
}

.manageSections-sectionList-sectionRow .btn{
	margin-left: auto;
	width: 100px;
	flex-shrink: 0;
}

.manageSections-sectionList-sectionRow.is--archivedSection .btn.delete{
	margin-left: 10px;
}

.manageSections-sectionList-sectionRow.is--archivedSection .btn.delete:hover{
	border: 1px solid $red;
	background: $red;
	color: $white;
}

.manageSections-sectionList-sectionRow.is--archivedSection .sectionMarker .icon{
	opacity: 0;
}

.manageSections-sectionList-sectionRow.is--archivedSection .btn.confirmdelete,
.manageSections-sectionList-sectionRow.is--archivedSection .btn.cancel{
	margin-left: 10px;
}

.manageSections-sectionList-sectionRow.is--archivedSection .btn.cancel{
	min-width: 70px;
	width: 70px;
}

.manageSections-sectionList-sectionRow.is--archivedSection .btn.confirmdelete{
	min-width: 150px;
	width: 150px;
}

.manageSections-sectionList-sectionRow:hover .sectionMarker{
	color: $textBlack;
}

.manageSections-sectionList-sectionRow.is--archivedSection 
span.manageSections-sectionList-sectionRow-confirmDeleteLabel{
	font-size: 15px;
	font-weight: $lato-bold;
	color: $textDark;
	margin-left: auto;
	flex-shrink: 0;
}
// BUTTON

.manageSections-btnContainer{
	width: 100%;
	height: 68px;
	background: $white;
	border-top: 1px solid $lineMid;
	border-bottom-left-radius: 3px;
	border-bottom-right-radius: 3px;

	display: flex;
	align-items: center;
	justify-content: center;
}

.manageSections-btnContainer .btn.btn--xLarge{
	width: 250px;
}