//
// SCORESHEET REVIEW MODAL: SET REVIEW
//

.scoreSheet-setReviewModal-header{
	width: 100%;
	display: flex;
	border-bottom: 1px solid $lineMid;
	cursor: default;
	padding-bottom: 18px;
}


.scoreSheet-setReviewModal-header--left{
	height: 100%;
	width: 10px; // initial, will flex grow
	flex-grow: 1;
	flex-shrink: 1;
}

.scoreSheet-setReviewModal-header-setTitle{
	font-size: 28px;
	font-weight: $lato-bold;
	color: $textBlack;
	margin-bottom: 6px;
}

.scoreSheet-setReviewModal-header-timestamp{
	font-size: 18px;
	font-weight: $lato-medium;
	color: $textLight;
}

// Header Right

.scoreSheet-setReviewModal-header--right{
	height: 100%;
	width: 120px;
	flex-grow: 0;
	flex-shrink: 0;
}

// Scorepill


.scoreSheet-setReviewModal-header-scorePillContainer{
	height: 40px;
}

.scoreSheet-setReviewModal-header-scorePillContainer .scorePill{
	width: 100%;
	font-size: 28px;
	text-align: right;
}

.scoreSheet-setReviewModal-header-scorePillContainer .scorePill .scorePill-dot{
	width: 10px;
	height: 10px;
	top: -5px;
}

.scoreSheet-setReviewModal-header-scorePillContainer .scorePill .scorePill-percentSign {
	opacity: 0.75;
}

// Class Average Label

.scoreSheet-setReviewModal-header-classAverageLabel{
	text-align: right;
	font-size: 16px;
	font-weight: $lato-medium;
	color: $textLight;
	opacity: 0;
	transition: opacity 200ms ease-in-out;
	user-select: none;
}

.scoreSheet-setReviewModal-header--right:hover
.scoreSheet-setReviewModal-header-classAverageLabel{
	opacity: 1;
	transition: opacity 100ms ease-in-out;
	transition-delay: 200ms;
}


//

.scoreSheet-setReviewModal-studentSummaryContainer{
	width: 100%;
	padding-top: 12px;
}

.scoreSheet-setReviewModal-studentSummary{
	width: 100%;
	column-count: 4;
  column-gap: 28px;
	width: 100%;
	overflow: hidden;	
}


.scoreSheet-setReviewModal-studentSummary-blockLine{
	// blocks top border of top in each column
	position: relative;
	z-index: 5;
	width: 100%;
	border-bottom: 2px solid $white;
	margin: 0 auto;
	margin-bottom: -1px;
}


.scoreSheet-setReviewModal-studentSummary-studentCard{
	
	user-select: none; 
	cursor: default;

 	display: inline-block;
 	vertical-align: top;

 	break-inside: avoid-column;
 	-webkit-column-break-inside: avoid;

	width: 100%;

	height: 30px;
	border-top: 1px solid $lineMid;
	
	position: relative;

	display: flex;
	align-items: center;

}

.scoreSheet-setReviewModal-studentSummary-studentCard-name{
	line-height: 1.9;
  	
	font-weight: $lato-semibold;
	color: $textBlack;
	font-size: 15px;

	width: 10px;
	flex-grow: 1;

	white-space: nowrap;
	overflow: hidden;

	white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.scoreSheet-setReviewModal-studentSummary-studentCard .scorePill{
	flex-grow: 0;
}

// 

.scoreSheet-setReviewModal-noSummary{
	width: 100%;
	height: 120px;
	display: flex;
	align-items: center;
	justify-content: center;

	font-size: 18px;
	font-weight: $lato-semibold;
	color: $textLight;
}

//

.scoreSheet-setReviewModal-reportLinkContainer{
	width: 100%;
	display: flex;
	margin-top: 14px;
	justify-content: flex-end;
}




