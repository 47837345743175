.newSection-modal .modal-content{
  height: 440px;
  width: 720px;
  position: relative;

  border-radius: 7px;
}

.newSection{
  box-sizing: border-box;
  height: calc(100% - 125px);

  padding-left: 36px;
  padding-right: 36px;
  padding-top: 24px;

}

.newSection-header{
  user-select: none;
  cursor: default;

  padding-bottom: 18px;

  position: relative;
}

.newSection-header-primary{
  font-size: 24px;
  font-weight: $lato-heavy;
  color: $textBlackDark;
  margin-bottom: 4px;
}

.newSection-header-secondary{
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.newSection-header-secondary-explainText{
  font-size: 14px;
  font-weight: $lato-semibold;
  color: $textMid;

  width: 50%;
}

// SELECT GC HEADER

.newSection-header--GCSelect{
  padding-bottom: 12px;
}

.newSection-header--GCSelect
.newSection-header-secondary{
  justify-content: flex-start;
}

.newSection-header--GCSelect
.newSection-header-secondary-iconContainer{
  width: 26px;
  height: 26px;
  margin-right: 10px;
}

.newSection-header--GCSelect
.newSection-header-secondary-iconContainer svg{
  height: 100%;
  width: 100%;
}

.newSection-header--GCSelect
.newSection-header-secondary-explainText{
  width: auto;
  font-size: 16px;
  font-weight: $lato-medium;
  color: $textDark;
  margin-right: auto;
  letter-spacing: 0.001em;
}

.newSection-header-secondary-explainText span{
  color: $textMid;
}

button.btn.gcimportdeselect,
button.btn.gcimportdeselect:hover{
  margin-right: -6px;
  background: none;
  border: none;
  color: $textLight;
  box-shadow: none;
}

// IMPORT FROM GOOGLE CLASSROOM BUTTON

button.newSection-importGoogleBtn{
  border: 1px solid $gray-1125;
  background: $white;
  border-radius: 4px;
  box-shadow: 0px 2px 4px 0px rgba(0,0,40,0.08);

  display: flex;

  align-items: center;
  justify-content: center;

  padding-left: 10px;
  padding-right: 10px;

  height: 36px;

  cursor: pointer;
}

button.newSection-importGoogleBtn:hover{
  border: 1px solid darken($gray-1125, 5%);
  box-shadow: 0px 2px 4px 0px rgba(0,0,40,0.12);
}

button.newSection-importGoogleBtn:active{
  border: 1px solid darken($gray-1125, 8%);
  box-shadow: 0px 2px 4px 0px rgba(0,0,40,0.14);
  background: $gray-15;
}

.newSection-importGoogleBtn-iconContainer{
  width: 22px;
  height: 22px;
  margin-right: 6px;
}

.newSection-importGoogleBtn-iconContainer svg{
  width: 100%;
  height: 100%;
}

.newSection-importGoogleBtn-labelContainer {
  width: 220px;
}

.newSection-importGoogleBtn-label{
  font-size: 15px;
  font-weight: $lato-bold;
  color: $textBlack;
}

.newSection-importGoogleBtn-labelContainer .button-loadingSpinner {
  margin: auto;
}

.newSection-importGoogleErrorMessage {
  color: red;
  text-align: center;
  font-size: 15px;
  font-weight: $lato-semibold;
}


// DIVIDER

.newSection-header-divider{
  position: absolute;
  bottom: 0px;
  left: 0px;
  right: 0px;
  background: darken($lineMid,4%);
  height: 1px;
}



//

.newSection-forms--container{
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin-top: 20px;
  height: calc(100% - 50px);
  width: calc(100% + 30px);
  margin-left: 0px;
}

.newSection-form-section{
  width: 50%;
  padding-right: 30px;
  box-sizing: border-box;
  position: relative;
  margin-bottom: 15px;
}




.newSection-form-section form{
  display: flex;
}

.newSection-form-section .formField{
  margin-bottom: 0px;
}

/// SELECT COLOR FIELD

.newSection-form-section .formField.is--color{
  width: 34px;
  height: 34px;
  margin: 0px;
  margin-right: 10px;
  cursor: pointer;
}

.newSection-form-section .formField.is--color.is--select .Select,
.newSection-form-section .formField.is--color.is--select .Select-multi-value-wrapper{
  width: 34px;
  height: 34px;
  cursor: pointer;
}

.formField.is--select.is--color .Select-control {
    width: 100%;
    height: 100%;

    height: 34px;
    width: 34px;

    padding: 0px;

    border: none;
    border-radius: 4px;

    box-shadow: none;
    background: transparent;
    background-image: none;
    -webkit-appearance: none;

    background: $lineMid;
}

.selectColor-menuOption{
  width: 100%;
  height: 100%;
  border-radius: 16px;
}

.formField.is--select.is--color .Select-control .Select-input {
  height: 34px;
}

.formField.is--select.is--color .Select-option.is-focused{
  background: inherit;
}


// DROPDOWN MENU FOR COLORS SIMILAR TO DROPDOWN MENU ITEM
.formField.is--select.is--color .Select-option {
  height: 28px;
  width: 28px;

  border: none;
  margin: 2px;
  background: $white;
  padding: 3px;

  box-sizing: border-box;

  cursor: pointer
}

.formField.is--select.is--color .Select-menu-outer{
  margin-top: 0px;
  border: 1px solid $lineDark;

  width: 100px;
  height: auto;
  box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.15);
  margin-left: -33px;
  margin-top: 2px;
  border-radius: 2px;
}


.formField.is--select.is--color .Select.is-focused.is-open .Select-control {
  border: $invisible;
  outline: none;
  box-shadow: none;
}

.formField.is--select.is--color .Select-menu{
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.formField.is--select.is--color .Select .Select-input{
  width: 0px;
}


// don't allow to change color without name
.newSection-form-section--nameEmpty .formField.is--select.is--color{
  pointer-events: none;
}

/// VALUE

.formField.is--select.is--color .Select-value{
  padding: 0px;
  width: 34px;
  height: 34px;
}

.selectColor-valueOption{
  width: 100%;
  height: 100%;
}

.newSection-form-section .formField.is--color.is--select .Select-input{
  cursor: pointer;
}

.newSection-form-section .formField.is--color.is--select .selectColor-valueOption {
  border-radius: 4px;
}

.newSection-form-section .formField.is--color.is--select .Select-arrow-zone{
  display: none;
}


// CHECK MARK

.newSection-form-section-icons{
  position: absolute;
  left: 0px;
  top: 0px;
  width: 34px;
  height: 34px;
  z-index: 10;

  display: flex;
  align-items: center;
  justify-content: center;

  pointer-events: none;
}

.newSection-form-section-icons .icon.check {
  display: none;
  fill: $white;
  height: 20px;
  width: 20px;
}

.newSection-form-section.newSection-form-section--nameNonEmpty
.icon.check{
  display: block;
}

.newSection-form-section.newSection-form-section--nameNonEmpty
.formField.is--select.is--color:hover ~
.newSection-form-section-icons
.icon.check{
  display: none;
}

.newSection-form-section-icons
.icon.three-dots-horizontal {
  display: none;
  fill: $white;
  height: 16px;
  width: 16px;
}

.newSection-form-section.newSection-form-section--nameNonEmpty
.formField.is--select.is--color:hover ~
.newSection-form-section-icons
.icon.three-dots-horizontal{
  display: block;
}

////


.newSection-form-section .formField.is--text{
  flex-grow: 1;
}


.newSection-form-section input.formFieldText-input{
  width: 100%;
  font-size: 18px;
  font-weight: $lato-bold;
}

.newSection-form-section input.formFieldText-input::-webkit-input-placeholder{
  font-size: 18px;
  font-weight: $lato-bold;
}

.newSection-form-section input.formFieldText-input,
.newSection-form-section .formField-select--container{
  margin-top: 0px;
}

//
// SUBMIT BUTTON

.newSection .btn.newSection-submitBtn{
  width: 380px;
  height: 54px;
  position: absolute;
  bottom: 20px;
  left: calc(50% - 190px);


  border-radius: 6px;

  display: block;

  cursor: pointer;
}

.newSection .btn.newSection-submitBtn.btn--blue{
  background: $blue;
  box-shadow: 0px 2px 4px 0px rgba(0,0,40,0.08);
}


.newSection-submitBtn-primary{
  font-weight: $lato-heavy;
  font-size: 20px;
  letter-spacing: 0.02em;
}

.newSection-submitBtn.btn--gray{
  opacity: 0.6;
}

.newSection-submitBtn.btn--blue
.newSection-submitBtn-primary{
  line-height: 2.1;
}

.newSection-submitBtn-secondary{
  font-size: 11px;
  font-weight: $lato-semibold;
  color: white; // only displayed when active
  opacity: 0.75;
  margin-top: -14px;
}

.newSection-submitBtn-loadingSpinnerContainer{
  position: absolute;

  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.newSection-submitBtn-loadingSpinner{

  height: 20px;
  width: 20px;

  border-radius: 16px;

  border: 3px rgba(26,28,34,0.55) solid;
  border-top: 3px $white solid;

  opacity: 0.5;

  animation: spinnerRotateAnimation .3s infinite linear;
}


///

.selectColor-menuOption.classColor--blue				{ background: $classColor-blue; }
.selectColor-menuOption.classColor--purple			{ background: $classColor-purple; }
.selectColor-menuOption.classColor--red					{ background: $classColor-red; }
.selectColor-menuOption.classColor--orange			{ background: $classColor-orange; }
.selectColor-menuOption.classColor--green				{ background: $classColor-green; }
.selectColor-menuOption.classColor--sky					{ background: $classColor-sky; }
.selectColor-menuOption.classColor--pink				{ background: $classColor-pink; }
.selectColor-menuOption.classColor--teal				{ background: $classColor-teal; }
.selectColor-menuOption.classColor--grey				{ background: $classColor-grey; }

// Show color when has value
.newSection-form-section--nameNonEmpty .selectColor-valueOption.classColor--blue				{ background: $classColor-blue; }
.newSection-form-section--nameNonEmpty .selectColor-valueOption.classColor--purple			{ background: $classColor-purple; }
.newSection-form-section--nameNonEmpty .selectColor-valueOption.classColor--red					{ background: $classColor-red; }
.newSection-form-section--nameNonEmpty .selectColor-valueOption.classColor--orange			{ background: $classColor-orange; }
.newSection-form-section--nameNonEmpty .selectColor-valueOption.classColor--green				{ background: $classColor-green; }
.newSection-form-section--nameNonEmpty .selectColor-valueOption.classColor--sky					{ background: $classColor-sky; }
.newSection-form-section--nameNonEmpty .selectColor-valueOption.classColor--pink				{ background: $classColor-pink; }
.newSection-form-section--nameNonEmpty .selectColor-valueOption.classColor--teal				{ background: $classColor-teal; }
.newSection-form-section--nameNonEmpty .selectColor-valueOption.classColor--grey				{ background: $classColor-grey; }


// GC COURSE LIST

.newSection-GCCourseListContainer{
  max-height: 250px;
  overflow-y: scroll;
}

.newSection-GCCourseList{
  // display: flex;
  // flex-direction: column;
  // flex-wrap: wrap;
  margin-top: 20px;
  // max-height: calc(100% - 70px);
  width: calc(100% + 30px);
  margin-left: 0px;

  column-count: 2;
  column-gap: 28px;

}

.newSection-GCCourseList
.featureModal-selectControl{
  margin-bottom: 8px;
  width: 100%;
  cursor: pointer;
  margin-right: 15px;

   vertical-align: top;

   break-inside: avoid-column;
   -webkit-column-break-inside: avoid;
}

.newSection-GCCourseList
.featureModal-selectControl.featureModal-selectControl--inactive{
  opacity: 0.5;
}

.newSection-GCCourseList
.featureModal-selectControl.featureModal-selectControl--inactive:hover{
  opacity: 1;
}


.newSection-GCCourseList
.featureModal-selectControl-checkbox{
  height: 16px;
  width: 16px;
  border-radius: 2px;
}

.newSection-GCCourseList
.featureModal-selectControl-checkbox svg.icon.checkbox-check{
  height: 10px;
  width: 10px;
  left: 2px;
  top: 2px;
}

.newSection-GCCourseList
.featureModal-selectControl-label{
  font-size: 17px;
  letter-spacing: initial;
  cursor: pointer;
}

.newSection-GCCourseList-emptyState{
  font-size: 17px;
  font-weight: $lato-semibold;
  color: $textLight;
}



// CLOSE BUTTON

.newSection-closeBtn{
  position: absolute;
  width: 30px;
  height: 30px;
  top: 14px;
  right: 12px;
  cursor: pointer;
  z-index: 50;
  border-radius: 14px;

  opacity: 0.18;
  transition: opacity 100ms linear;
}

.newSection-closeBtn:hover{
  opacity: 0.5;
  transition: opacity 50ms linear;
}

.newSection-closeBtn:active{
  opacity: 1;
}

.newSection-closeBtn .icon{
  width: 30px;
  height: 30px;
  fill: $gray-6;
}

// LOADING FROM GC STATE

.newSection-loadingFromGCContainer{
  position: absolute;
  top: 40px;
  left: 40px;
  right: 40px;
  bottom: 40px;

  display: flex;
  align-items: center;
  justify-content: center;
}

.newSection-loadingFromGC{
  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
}

.newSection-loadingFromGC-loadingSpinnerContainer{
  display: flex;
  align-items: center;
  justify-content: center;
}

.newSection-loadingFromGC-loadingSpinner{

  height: 60px;
  width: 60px;

  border-radius: 36px;

  border: 6px rgba(26,28,34,0.3) solid;
  border-top: 6px rgba(26,28,34,0.9) solid;

  opacity: 0.4;

  animation: spinnerRotateAnimation .8s infinite linear;
}


