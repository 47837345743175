.editor-emojiSymbolPicker-footer{
	//background: $gray-14;
	background: rgb(251,251,254);
	box-sizing: border-box;
	padding-left: $sidePadding-emojiSymbolPicker;
	padding-right: $sidePadding-emojiSymbolPicker;

	display: flex;
	align-items: center;
}

.editor-emojiSymbolPicker-footer-result{
	display: flex;
	align-items: center;
}
.editor-emojiSymbolPicker-footer-result-preview{
	width: 46px;
	height: 46px;

	display: flex;
	align-items: center;
	justify-content: center;

	// background: $tempb;

	font-size: 31px; // temp
	box-sizing: border-box; // temp
	padding-top: 2px; //

	margin-left: -4px;
}

.editor-emojiSymbolPicker-footer-result-label{
	margin-left: 4px;
	margin-top: -3px; // account for lowercase
}

.editor-emojiSymbolPicker-footer-result-label-title{
	font-size: 15.5px;
	font-weight: $lato-bold;
	text-align: left;
	color: $textBlackDark;
}

.editor-emojiSymbolPicker-footer-result-label-inputRule{
	font-size: 13px;
	font-weight: $lato-medium;
	color: $textDark;
	text-align: left;
}