

a.discover-featuredRepoLink{
	height: 96px;
	width: 50%;

	box-sizing: border-box;
	padding-top: 5px;

	display: block;

	cursor: pointer;

	display: flex;

	padding-left: 16px;
	padding-right: 8px;
	border-radius: 5px;

	// background: $tempr;
}

a.discover-featuredRepoLink:active{
	background: $bgColor-activeRepoLink;
}

//
//

.discover-featuredRepoLink-left{
	width: 94px;
	flex-grow: 0;
	flex-shrink: 0;
}

.discover-featuredRepoLink-left-repoImageContainer{
	width: 84px;
	height: 84px;
	background: $gray-14;
	border-radius: 4px;
	overflow: hidden;
	position: relative;

}

.discover-featuredRepoLink-left-repoImage-imgBorder{
	position: absolute;
	top: 0; 
	left: 0; 
	right: 0; 
	bottom: 0;
	z-index: 5;
	box-sizing: border-box;
	border: 1px solid rgba(0,0,20,0.06);
	border-radius: 4px;
}

img.discover-featuredRepoLink-left-repoImage{
	position: absolute;
	top: 0px;
	left: 0px;
	right: 0px;
	bottom: 0px;
	height: 100%;
	width: 100%;
}

//
//

.discover-featuredRepoLink-center{
	flex-grow: 1;
	flex-shrink: 1;

	min-width: 0;

}


// TITLE
.discover-featuredRepoLink-center-title{
	font-size: 15.5px;
	font-weight: $lato-bold;
	letter-spacing: 0.007em;
	color: $textBlack;
	margin-top: 8px;
	margin-bottom: 3px;

	display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;

	text-overflow: ellipsis;
  overflow: hidden;

  max-height: 36px;
}

a.discover-featuredRepoLink:hover
.discover-featuredRepoLink-center-title{
	color: $textBlackDark;
}

// CREATOR
.discover-featuredRepoLink-center-creator{
	font-size: 13.5px;
	font-weight: $lato-medium;
	color: $textBlue;

	white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}


// RATING
.discover-featuredRepoLink-center-ratingContainer{
	margin-top: 4px;
	font-size: 12.5px;
	font-weight: $lato-medium;
	color: $textLight;
	opacity: 0.8;
}

.discover-featuredRepoLink:hover
.discover-featuredRepoLink-center-ratingContainer{
	opacity: 1;
}

.discover-featuredRepoLink-center-ratingContainer .discover-repoRatingStars-stars{
	margin-top: 1px;
}

.discover-featuredRepoLink-center-ratingContainer .discover-repoRatingStars-stars svg{
	width: 11px;
	height: 11px;
	margin-right: 1px;
}

.discover-repoRatingStars-stars--active svg{
	fill: $gray-7;
}

.discover-repoRatingStars-stars--bg svg{
	fill: $gray-12;
}



//
//

.discover-featuredRepoLink-right{
	width: 30px;
	flex-grow: 0;
	flex-shrink: 0;

	opacity: 0.2;

	display: flex;
	justify-content: flex-end;

}

.discover-featuredRepoLink-right svg.icon{
	width: 12px;
	height: 12px;
	fill: $gray-7;
	margin-top: 11px;
}

a.discover-featuredRepoLink:hover
.discover-featuredRepoLink-right{
	opacity: 0.8;
}