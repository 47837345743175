//
// INPUT FIELD
// Old, used in some modals, input field component with basic styling 
//

$fontSize-inputField: 20px;

.inputField {
	height: 40px;
	width: 100%;
	border: 1px solid $lineDark;
	border-radius: 4px;
	padding-left: 12px;
	
	box-sizing: border-box;
	font-size: $fontSize-inputField;
	font-weight: $lato-bold;
	color: $textBlack;
	outline: none;
	pointer-events: default;
}

.inputField:focus{
	border: 1px solid $blue;
}

.inputField.is--nonEmpty:focus{
	box-shadow: 0px 0px 4px 0px transparentize($blue, 0.5);
}

.inputField::-webkit-input-placeholder {
	color: $textInputPlaceholder !important;
	font-size: $fontSize-inputField;
	font-weight: $lato-bold;
}