.sectionHome-live{
	width: 100%;
	margin-top: 25px;
	position: relative;
	//display: flex;
	padding-bottom: 60px;
}

// 
// ACTIVE ITEM PREVIEW
// 

.sectionHome-live-label{
	font-size: 16px;
	font-weight: $lato-bold;
	text-transform: uppercase;
	color: $textBlue;
}

.sectionHome-live-title{
	margin-top: 20px;

	font-size: 26px;
	font-weight: $lato-heavy;
	color: $textBlack;

	line-height: 1.25;

	max-width: 500px;
	max-height: 115px;

	display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;

  text-overflow: clip;
  white-space: normal;
  overflow: hidden; 
}

.sectionHome-live-subtitle{
	margin-top: 7px;

	font-size: 17px;
	font-weight: $lato-semibold;
	color: $textLight;
}

//

.sectionHome-live-counter{
	width: 100%;
	text-align: right;
	font-size: 18px;
	font-weight: $lato-bold;
	margin-top: 6px;
	cursor: default;
}

.sectionHome-live-counter-count{
	color: $textBlack;
}

.sectionHome-live-counter-slash{
	color: $gray-11;
	margin-left: 3px;
	margin-right: 3px;
}

.sectionHome-live-counter-total{
	color: $textLight;
}

.sectionHome-live-progressBar{
	width: 100%;
	margin-top: 13px;
	height: 4px;
	position: relative;
	border-radius: 2px;
	overflow: hidden;

	display: flex;
}

.sectionHome-live-progressBar-item{
	width: 1px;
	flex-grow: 1;

	height: 100%;

	position: relative;
	z-index: 10;
}

.sectionHome-live-progressBar-item--active{
	background: $blue;
	opacity: 1;
}

.sectionHome-live-progressBar-item--before{
	background: $blue-light;
	opacity: 0.75;
}

.sectionHome-live-progressBar-item--after{
	background: $gray-10;
	opacity: 0.5;
}

///

.sectionHome-live-divider{
	height: 1px;
	background: $color-sectionHome-dividerGray;
	width: calc(100% + #{$rightGutter-centerPanel});
	position: absolute;
	left: 0px;
	bottom: 0px;
}