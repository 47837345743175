//
// PAGE HEADER TOP
// These are the main page headers. After scrolling they are replaced with sticky headers
// Top page headers contain a lot of the functionality on a page
// They can get very complex, and there's unfortunately a bit of customization for different pages
//

$marginTop-pageHeaderTop-headerRow: 64px;
$marginBottom-pageHeaderTop-headerRow: 2px;
$height-pageHeaderTop-headerRow: 48px;
$height-pageHeaderTop-headerRow--multiLine: 92px;
$height-pageHeaderTop-subHeaderRow: 30px; // like section subpages
$height-pageHeaderTop-headerInfoRow: 24px;
$height-pageHeaderTop-actionRow: 32px;

.pageHeaderTop{
  width: 100%;
  position: relative;
  box-sizing: border-box;
  padding-top: $marginTop-pageHeaderTop-headerRow;
}

@import 'PageHeaderTop-headerRow.scss';
@import 'PageHeaderTop-backButton.scss';

//
// SUBHEADER ROW
// Big subpage header, like in section subpages, account, and search

@import 'PageHeaders-subHeaderRow.scss';

//
// HEADER INFO ROW
// Small timestamp or section marker, like in Set Detail or Set Report

.pageHeaderTop-headerInfoRow{
  width: 100%;
  position: relative;
  //background: $tempr;
  height: $height-pageHeaderTop-headerInfoRow;
  font-size: 17px;
  color: $textLight;
  font-weight: $lato-semibold;
  cursor: default;
  margin-top: 6px;
  display: flex;
}

.pageHeaderTop--discoverHome .pageHeaderTop-headerInfoRow,
.pageHeaderTop--discoverMarket .pageHeaderTop-headerInfoRow{
  margin-top: 3px; // a bit tighter to the top here
  user-select: none;
}

.pageHeaderTop--discoverHome .pageHeaderTop-headerInfoRow{
  color: $textMid;
}

.pageHeaderTop-headerInfoRow .sectionMarker{
  cursor: pointer;
  color: $textBlack;

  padding-top: 4px;
  padding-bottom: 4px;
  padding-left: 8px;
  padding-right: 8px;

  //offset to keep in line
  margin-left: -8px;
  margin-top: -4px;
  border-radius: 5px;

  max-width: 600px; // unfortunate, support legacy long section name

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.pageHeaderTop-headerInfoRow .sectionMarker:hover{
  background: $gray-14;
}

.pageHeaderTop-headerInfoRow .sectionMarker .icon{
  width: 12px;
  height: 12px;
  margin-right: 9px;
}

.pageHeaderTop-headerInfoRow-timestamp{
  flex-shrink: 0;
}

// FOLDER AND REPO LINK IN DETAIL PAGES
.pageHeaderTop-headerInfoRow  a.pageHeaderTop-headerInfoRow-folderLink,
.pageHeaderTop-headerInfoRow  a.pageHeaderTop-headerInfoRow-repoLink,
.pageHeaderTop-headerInfoRow  a.pageHeaderTop-headerInfoRow-repoFolderLink{
  color: $textDark;
  font-weight: $lato-bold;
  margin-right: 12px;

  user-select: none;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.pageHeaderTop-headerInfoRow  a.pageHeaderTop-headerInfoRow-folderLink{
  padding-left: 25px;
}

.pageHeaderTop-headerInfoRow  a.pageHeaderTop-headerInfoRow-folderLink,
.pageHeaderTop-headerInfoRow  a.pageHeaderTop-headerInfoRow-repoLink{
  color: $textBlack;
}

.pageHeaderTop-headerInfoRow  a.pageHeaderTop-headerInfoRow-repoFolderLink{
  font-weight: $lato-semibold;
  color: $textMid;
}

.pageHeaderTop-headerInfoRow  a.pageHeaderTop-headerInfoRow-repoLink:hover,
.pageHeaderTop-headerInfoRow  a.pageHeaderTop-headerInfoRow-repoFolderLink:hover{
  cursor: pointer;
  color: $textBlue;
}

// Chevron for repo -> folder
.pageHeaderTop--setDetail .pageHeaderTop-headerInfoRow .icon.chevron-right,
.pageHeaderTop--questionDetail .pageHeaderTop-headerInfoRow .icon.chevron-right{
  height: 12px;
  width: 12px;
  fill: $gray-115;
  position: relative;
  top: 5px;
  left: -5px;
}

.pageHeaderTop-headerInfoRow  a.pageHeaderTop-headerInfoRow-folderLink .icon{
  width: 18px;
  height: 18px;
  position: absolute;
  top: 1px;
  left: 1px;
  margin-right: 7px;
}

a.pageHeaderTop-headerInfoRow-folderLink .icon #top,
a.pageHeaderTop-headerInfoRow-folderLink .icon #main{
  fill: $blue;
}

.pageHeaderTop-headerInfoRow  a.pageHeaderTop-headerInfoRow-reportContainingRepoLink{
  color: $textLight;
  user-select: none;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.pageHeaderTop-headerInfoRow  a.pageHeaderTop-headerInfoRow-reportContainingRepoLink:hover{
  color: $textBlue;
}

//
// REPO WITH IMAGE

.pageHeaderTop--repo .pageHeaderTop-headerInfoRow{
  margin-left: -5px;
}

.pageHeaderTop--repoWithImage{
  padding-left: 100px; // important offset!
}

.pageHeaderTop--repoWithImage.pageHeaderTop--repo .pageHeaderTop-headerInfoRow{
  min-height: 58px;
}

//
// REPO HEADER INFO ROW
// DESCRIPTION GOES HERE and other things

.pageHeaderTop--repo .pageHeaderTop-headerInfoRow{

  position: relative;

  height: auto;

  box-sizing: border-box;
  padding-left: 6px;
  padding-right: 6px;

  margin-top: 0px;
  padding-top: 8px; // use padding so cursor - pointer is everywhere for edit repo header
  padding-bottom: 14px;

  font-size: 14.5px;
  font-weight: $lato-medium;

  line-height: 1.2;

  word-break: break-word;
  word-wrap: break-word;
  color: $textMid;
}

// public repo extra information

.pageHeaderTop--repo-headerInfoRow-contents-authorContainer{
  display: flex;
  margin-left: -8px;

}
.pageHeaderTop--repo-headerInfoRow-contents-author{
  display: flex;
  flex-grow: 0;
  flex-shrink: 0;
  align-items: center;
  margin-top: -4px;
  margin-bottom: 4px;
  padding-left: 8px;
  padding-right: 12px;
  padding-top: 4px;
  padding-bottom: 4px;

  border-radius: 4px;
  user-select: none;

  cursor: pointer;
}

.pageHeaderTop--repo-headerInfoRow-contents-author:hover{
  background: $gray-14;
}

.pageHeaderTop--repo-headerInfoRow-contents-author-profilePicContainer{
  margin-right: 8px;
  flex-grow: 0;
  flex-shrink: 0;
}

.pageHeaderTop--repo-headerInfoRow-contents-author-profilePicPageLoader{
  width: 26px;
  height: 26px;
  background: $color-pageLoaderElement;
  border-radius: 13px;
}

.pageHeaderTop--repo-headerInfoRow-contents-author-username{
  font-size: 15.5px;
  flex-grow: 0;
  flex-shrink: 0;
  font-weight: $lato-bold;
  letter-spacing: 0.005em;
  color: $textBlackDark;
}

.pageHeaderTop--repo-headerInfoRow-contents-author-username-pageLoaderPlaceholder{
  width: 85px;
  height: 18px;
  border-radius: 3px;
  background: $color-pageLoaderElement;
}

.pageHeaderTop--repo-headerInfoRow-contents-rating{
  font-weight: $lato-semibold;
  font-size: 14.5px;
  color: darken($textDark, 5%);

  display: flex;
  align-items: center;

  margin-top: -1px;
  margin-bottom: 6px;
}

.pageHeaderTop--repo-headerInfoRow-contents-rating
.discover-repoRatingStars{
  margin-right: 2px;
}

.pageHeaderTop--repo-headerInfoRow-contents-rating
.discover-repoRatingStars-stars--active svg{
  fill: $gray-6;
}

.pageHeaderTop--repo-headerInfoRow-contents-rating
.discover-repoRatingStars-stars svg{
  width: 13px;
  height: 13px;
}

.pageHeaderTop--repo-headerInfoRow-contents-metadata{
  font-weight: $lato-semibold;
  font-size: 14.5px;
  color: $textMid;
  margin-bottom: 6px;
}

.pageHeaderTop--repo-headerInfoRow-contents-metadata--noRating{
  color: $textDark;
  margin-bottom: 6px;
}

.pageHeaderTop--repo-headerInfoRow-contents-metadata--hasRating{
  color: $textMid;
  margin-bottom: 5px;
}

.pageHeaderTop--repo-headerInfoRow-contents-metadata-pageLoaderPlaceholder{
  width: 205px;
  height: 17px;
  border-radius: 3px;
  background: $color-pageLoaderElement;
}

.pageHeaderTop--repo-headerInfoRow-contents-description-pageLoaderPlaceholder{
  width: 165px;
  height: 17px;
  border-radius: 3px;
  display: none; //not sure we need this
}

// Shared Icon positioning

.pageHeaderTop--repo .pageHeaderTop-headerRow-header .icon.shared{
  fill: $gray-12;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0px;
  height: 24px;
  width: 24px;
}

//
// ACTION ROW
// Where are all the buttons and filters are

.pageHeaderTop-actionRow{
  position: absolute;
  bottom: 5px;

  display: flex;
  height: $height-pageHeaderTop-actionRow;
  padding-top: 20px;
  width: 100%;
  cursor: default;
  user-select: none;

  align-items: center;
}

.pageHeaderTop-actionRow button.btn.btn--mid.btn--white,
.pageHeaderTop-actionRow .dropdown{
  flex-shrink: 0;
}

.pageHeaderTop-actionRow button{
  cursor: pointer !important;
}

//
// SECTION HEADER with organization display

.pageHeader--sectionHome-headerInfoRow-dataOwnerInfoContainer {
  margin-top: -10px;
  margin-left: 3px;
  padding: 4px 7px 2px 5px;
  font-size: 15px;
  display: flex;
  border-radius: 4px;
  color: $textLight;
  height: 20px;
  cursor: pointer;
}

.pageHeader--sectionHome-headerInfoRow-dataOwnerInfoContainer:hover {
  background: $gray-14;
}

.pageHeader--sectionHome-headerInfoRow-dataOwnerInfoContainer .icon {
  fill: $gray-11;
  width: 21px;
  height: 21px;
  margin-top: -2px;
  margin-right: 5px;
}

.pageHeader--sectionHome-headerInfoRow-text--notAssigned {
  opacity: 0.7;
}


//
// SECTION STUDENTS
// entend width of menu for long 'Delink Google Classroom Label'

.pageHeaderTop--sectionStudents
.dropdownMenu{
  width: 215px;
}

//
// REPO

.pageHeaderTop--repo{
  margin-bottom: -15px; // brings up the table so similar to library
}

.pageHeaderTop--repo-actionRowSpacer{
  height: $height-pageHeaderTop-actionRow;
}

.pageHeaderTop--repo.pageHeaderTop--repoWithImage{
  min-height: 208px; // this is magic number that aligns action row with image

}

.pageHeaderTop--repo.pageHeaderTop--repoTextOnly{
  min-height: 170px; // this is a "magic" number that fits in line with library style
}

.pageHeaderTop--repo .pageHeaderTop-actionRow{
  bottom: 0px;
  right: 0px;
}

.pageHeaderTop--repo.pageHeaderTop--repoWithImage
.pageHeaderTop-actionRow{
  left: calc(99px - 10px); // account for image and offset for new set button
  width: auto;
}

// REPO VIEW-ONLY BANNER

.pageHeaderTop--repo .pageHeaderTop-actionRow-viewOnlyBanner{
  height: 30px;
  padding-left: 12px;
  padding-right: 12px;

  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: -5px;

  border-radius: 4px;

  color: $textMid;
  background: rgba(0,0,40,0.01);

  z-index: 50;

  font-size: 15px;
  font-weight: $lato-bold;

  user-select: none;
  cursor: default;
}

.pageHeaderTop--repo .pageHeaderTop-actionRow-viewOnlyBanner .icon.eye{
  width: 16px;
  height: 16px;
  fill: $gray-9;
  margin-left: 5px;
}

//
// REPO MEMBER LIST

.pageHeaderTop--repo .pageHeaderTop-actionRow--repoMemberList{
  display: flex;
  justify-content: flex-start;

  width: calc(100% - 32px); // provide space for the more dropdown
}

.pageHeaderTop--repo .pageHeaderTop-actionRow--repoMemberList--shortForm{
  overflow: hidden;
  position: absolute;
  bottom: 1px;
  left: 0px;
  right: 0px;
}

.pageHeaderTop--repoWithImage.pageHeaderTop--repoViewOnly .pageHeaderTop-actionRow{
  margin-bottom: 7px; // with edit, sinks down action row to make button text align. here we want profile pics to align
  margin-left: 12px;
}

.pageHeaderTop-actionRow--repoMemberList-repoMember--shortForm{
  margin-right: 5px;
  flex-shrink: 1;
  min-width: 0px;
}

.pageHeaderTop-actionRow--repoMemberList-repoMember--shortForm:nth-child(n+11){
  display: none;
  width: 0px;
  height: 0px;
}


.pageHeaderTop-actionRow--repoMemberList-overflow{
  flex-shrink: 0;

  font-size: 14px;
  font-weight: $lato-semibold;
  color: $textLight;

  min-width: 100px;

  display: flex;
  justify-content: center;
  align-items: center;

  cursor: default;
  user-select: none;

}

.pageHeaderTop-actionRow--repoMemberList-repoMember--longForm{
  display: flex;
  align-items: center;
  justify-content: flex-start;

  width: 100%;
}

.pageHeaderTop-actionRow--repoMemberList-repoMember--longForm .profilePicContainer{
  flex-grow: 0;
  flex-shrink: 0;
}

.pageHeaderTop-actionRow--repoMemberList-repoMember--longForm.tooltip--label.tooltip:after,
.pageHeaderTop-actionRow--repoMemberList-repoMember--longForm.tooltip--label.tooltip:before{
  display: none;
}

.pageHeaderTop-actionRow--repoMemberList-repoMember--shortForm .pageHeaderTop-actionRow--repoMemberList-repoMember-name{
  display: none;
}

.pageHeaderTop--repo .pageHeaderTop-actionRow--repoMemberList-repoMember-name{
  font-size: 14.5px;
  font-weight: $lato-semibold;
  color: $textDark;
  margin-left: 6px;
}

.pageHeaderTop-actionRow--repoMemberList-repoMember--longForm
.pageHeaderTop-actionRow--repoMemberList-repoMember-name{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.pageHeaderTop--repo .pageHeaderTop-actionRow--repoMemberList .tooltip--label.tooltip:after{
  filter: none; // remove dropshadow
  margin-top: 3px; // bring a bit closer
}

.pageHeaderTop--repo.pageHeaderTop--repoTextOnly .pageHeaderTop-actionRow button.new-set{
  margin-left: -11px; // sorry deadline nears
}


//offset into page, refactor to see if works for everything

$pageHeaderTop-actionRow--marginOffset: 9px;

.pageHeaderTop--setDetail .pageHeaderTop-actionRow,
.pageHeaderTop--questionDetail .pageHeaderTop-actionRow{
  bottom: 10px;

  margin-left: calc(-1 * #{$pageHeaderTop-actionRow--marginOffset});
}

.pageHeaderTop--questionDetail .pageHeaderTop-actionRow{
  width: calc(100% + #{$pageHeaderTop-actionRow--marginOffset} + #{$pageHeaderTop-actionRow--marginOffset});
}

.pageHeaderTop--setDetail .pageHeaderTop-actionRow{
  width: calc(100% + #{$pageHeaderTop-actionRow--marginOffset} + #{$pageHeaderTop-actionRow--marginOffset} + 30px);
  // account for hanging grid toggle display button
}

.pageHeaderTop-actionRow .filterBar,
.pageHeaderTop-actionRow .addStudentBar{
  flex-grow: 1;
}

.pageHeaderTop--sectionStudents
.addStudentBar{
  margin-right: 5px;
}


// GOOGLE CLASSROOM STUDENTS

.pageHeaderTop--sectionStudents
.pageHeaderTop-actionRow-googleClassroomInfo{
  display: flex;
}

.pageHeaderTop--sectionStudents
.pageHeaderTop-actionRow-googleClassroomInfo-logo{
  width: 40px;
  height: 40px;
  margin-right: 10px;
  position: relative;
  top: -3px;
}

.pageHeaderTop-actionRow-googleClassroomInfo-logo svg{
  height: 40px;
  width: 40px;
}

.pageHeaderTop--sectionStudents
.pageHeaderTop-actionRow-googleClassroomInfo-left-primary{
  font-size: 15px;
  font-weight: $lato-semibold;
  color: $textBlack;
}

.pageHeaderTop--sectionStudents
.pageHeaderTop-actionRow-googleClassroomInfo-accountEmail{
  font-size: 14px;
  font-weight: $lato-medium;
  color: $textLight;
}


.pageHeaderTop-actionRow-spacer{
  width: 1px;
  flex-grow: 1; // expands to fill space
}

//

// ITEMLISTPOLL IN HEADER INFO ROW
.pageHeaderTop-actionRow .pollsForItemList-section{
  margin-top: 4px;
}



// MULTILINE FIXES

.pageHeaderTop--multiLine .pageHeaderTop-headerInfoRow{
  margin-top: 12px;
}

.pageHeaderTop--multiLine .pageHeaderTop-actionRow{
  position: static;
  margin-bottom: -5px;
}

.pageHeaderTop--multiLine
.pageHeaderTop-borderBottom{
  position: static;
  margin-top: 10px;
}


// SMALL CUSTOM ROTATE ICON FOR MOVE TO REPO
.pageHeaderTop-actionRow button.moverepo .icon.open-left-arrow{
  transform: rotate(180deg) ;
}

//
// AUTHOR PAGE
//

.pageHeaderTop--authorPage{
  min-height: 180px; // to manage height of profile pic
}

// page loader placeholder
.pageHeaderTop--authorPage-profilePic-pageLoader{
  width: 100px;
  height: 100px;
  border-radius: 50px;
  background: $color-pageLoaderElement;
}


.pageHeaderTop--authorPage .pageHeaderTop-headerInfoRow{
  display: block;
  margin-top: 14px;
  height: auto;
  width: calc(100% - 150px); // make space for profile pic
}

.pageHeaderTop--author-headerInfoRow-contents-name{
  color: $textMid;
  font-weight: $lato-bold;
}

.pageHeaderTop--author-headerInfoRow-contents-bio{
  font-weight: $lato-medium;
  color: $textMid;
  font-size: 14px;
  margin-top: 8px;
  white-space: pre-wrap; // preserve line breaks
  word-wrap: break-word;
  // max-width: 100%;
}

.pageHeaderTop--author-headerRow-header-pageLoaderPlaceholder{
  height: 20px;
  width: 120px;
  background: $color-pageLoaderElement;
  border-radius: 4px;
}

.pageHeaderTop--authorPage .pageHeaderTop-actionRow{
  margin-left: -8px; // line up buttons
  padding-top: 12px;
}

.pageHeaderTop--authorPage .pageHeaderTop-actionRow .btn.btn--mid.btn--white.usernameedit{
  color: $textLight;
}

.authorPage.authorPage--isNotAuthor .pageHeaderTop-actionRow{
  display: none; // no action row if not author
}








// Uploading Profile Pic
.pageHeaderTop--authorPage-profilePic-profilePicUploading-loadingSpinnerContainer{
  width: 100px;
  height: 100px;
  border-radius: 50px;
  background: $gray-10;;
  display: flex;
  align-items: center;
  justify-content: center;
}


.pageHeaderTop--authorPage-profilePic-profilePicUploading-loadingSpinner{

  height: 30px;
  width: 30px;

  border-radius: 40px;

  border: 5px rgba(26,28,34,0.55) solid;
  border-top: 5px $white solid;

  opacity: 0.5;

  animation: spinnerRotateAnimation .5s infinite linear;
}


// ADD TO QUEUE DROPDOWN OVERFLOW

.pageHeaderTop-actionRow button.add-to-queue ~
.dropdownMenu{
  max-height: calc(100vh - 250px);
  overflow: auto;
  overflow-y: auto;
  overflow-x: hidden;
  width: 240px;
}

//

.pageHeaderTop--setReport .pageHeaderTop-actionRow,
.pageHeaderTop--questionReport .pageHeaderTop-actionRow,
.pageHeaderTop--setQuestionReport .pageHeaderTop-actionRow,
.pageHeaderTop--searchResults .pageHeaderTop-actionRow{
  font-size: 16px;
  color: $textMid;
  font-weight: $lato-semibold;
}

.pageHeaderTop-borderBottom{
  position: absolute;
  bottom: 0px;
  width: 100%;
  height: 1px;
  background: $lineMid;
}

.pageHeaderTop--sectionHome
.pageHeaderTop-borderBottom{
  background: $color-sectionHome-dividerGray;
  width: calc(100% + #{$rightGutter-centerPanel});
}


@import 'PageHeaders-folderNavRow.scss'; // library folder navigation (visible once you've entered a folder)
@import 'PageHeaders-sectionHomeLinkTray.scss'; // links at top of section home
