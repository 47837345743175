.explorer-topBar{
	width: 100%;
	height: $height-explorerTopbar;

	position: fixed;
	top: 0px;
	left: 0px;
	display: flex;

	background: $color-navBar-BG;
	border-bottom: $border-navBar;
	box-shadow: $shadow-navBar;
	box-sizing: border-box;

	z-index: 1000;

	display: flex;
}

.explorer-topBar-left{
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: flex-start;

	margin-right: auto;
	padding-left: 10px;
	box-sizing: border-box;
	width: $leftWidth-explorerCrossFilter;
	text-transform: uppercase;
	font-size: 13px;
	opacity: 0.5;

	flex-grow: 0;
	flex-shrink: 0;

	cursor: default;
	user-select: none;
}


.explorer-topBar-center{
	flex-grow: 1;
	flex-shrink: 1;
	height: 100%;
	// background: $tempb;
}

.explorer-topBar-tagArray{
	display: flex;
	height: 100%;
	align-items: center;
	justify-content: flex-start;
}

.explorer-topBar-tag{
	margin-right: 4px;
	border-radius: 3px;
	padding-top: 4px;
	padding-bottom: 4px;
	padding-left: 7px;
	padding-right: 7px;

	font-size: 16px;
	
	font-weight: $lato-bold;
	box-shadow: 0px 2px 4px 0px rgba(0,0,40,0.12);
	cursor: default;
	user-select: none;
}

.explorer-topBar-tag--subject{
	color: $white;
	background: $blue;
	border: 1px solid $blue-dark;
}
.explorer-topBar-tag--grade{
	color: $textBlack;
	background: $white;
	border: 1px solid $gray-12;
}
.explorer-topBar-tag--language{
	background: $gray-6;
	border: 1px solid $gray-5;
	color: $white;
}


.explorer-topBar-right{
	width: $rightWidth-explorerCrossFilter;
	flex-grow: 0;
	flex-shrink: 0;

	display: flex;
	align-items: center;
	justify-content: center;
}

// .scoreSheet-topBar
// .dropdownMenu.position--left{
// 	left: -190px;
// 	width: 230px;
// }


// .scoreSheet-topBar-left{
// 	display: flex;
// 	align-items: center;
	
// 	flex-shrink: 0;
// 	width: $width-scoreSheetSidePanel;

// 	padding-left: 18px;
// 	box-sizing: border-box;
// }

// //

// .scoreSheet-topBar-center{
// 	flex-grow: 1;
// 	flex-shrink: 1;
// 	padding-left: 16px;
// 	display: flex;
// 	align-items: center;
// 	justify-content: center;
// }

// //

// .scoreSheet-topBar-right{
// 	flex-shrink: 0;
// 	align-items: center;
// 	display: flex;
// 	justify-content: flex-end;

// 	padding-right: 14px;
// }


// // LEFT

// .scoreSheet-topBar-left .btn.home{
// 	width: 38px;
// }

// .scoreSheet-topBar-left .btn.previous,
// .scoreSheet-topBar-left .btn.next{
// 	width: 32px;
// }

// .scoreSheet-topBar-left .btn.previous{
// 	margin-right: -1px;
// 	border-top-right-radius: 0px;
// 	border-bottom-right-radius: 0px;
// }

// .scoreSheet-topBar-left .btn.next{
// 	border-top-left-radius: 0px;
// 	border-bottom-left-radius: 0px;
// }

// .scoreSheet-topBar-left .btn.today{
// 	margin-left: auto;
// 	min-width: 68px;
// 	width: 68px;
// 	color: $textBlack;

// 	margin-right: 8px;
// }

// .scoreSheet-topBar-left .btn.home .icon{
// 	width: 20px;
// 	height: 20px;
// }

// .scoreSheet-topBar-left .btn.previous .icon,
// .scoreSheet-topBar-left .btn.next .icon{
// 	width: 18px;
// 	height: 18px;
// }

// // CENTER

// .scoreSheet-topBar-center-label{
// 	font-size: 18px;
// 	font-weight: $lato-bold;
// 	color: $textBlackDark;
// 	text-align: left;
// 	width: 100%;
// 	user-select: none;
// 	cursor: default;
// }

// .scoreSheet-topBar--noSectionSelected
// .scoreSheet-topBar-center-label{
// 	color: $textLight;
// }

// // RIGHT

// // GRANULARITY TOGGLE
// .scoreSheet-topBar-granularityToggle{
// 	display: flex;
// }

// // FREE LIMIT TOOLTIP

// .scoreSheet-topBar-granularityToggle.tooltip.tooltip--label[data-tooltip]:hover:after{
// 	transition-delay: 1500ms;
// }

// .scoreSheet-topBar-granularityToggle button{
// 	min-width: 72px;
// 	width: 72px;
// 	border-radius: 0px;
// 	position: relative;
// 	margin-right: -1px;
// 	color: $textBlack;
// 	letter-spacing: 0.01em;
// }

// .scoreSheet-topBar-granularityToggle button.is--active{
// 	background: $blue-light;
// 	z-index: 10;
// 	border-color: $blue;
// 	color: $white;
// 	font-weight: $lato-bold;
// }

// .scoreSheet-topBar-granularityToggle button:first-child{
// 	border-top-left-radius: 3px;
// 	border-bottom-left-radius: 3px;
// }

// .scoreSheet-topBar-granularityToggle button:last-child{
// 	border-top-right-radius: 3px;
// 	border-bottom-right-radius: 3px;
// }


// /// MAKE PACK 
// .scoreSheet-topBar-right button.btn.studentpack{
// 	min-width: 140px;
// 	width: 140px;
// 	color: $textBlack;
// 	font-weight: $lato-bold;

// 	margin-left: 8px;
// 	margin-right: 8px;
// }

// /// COLLAPSE SETS AND EXPORT CSV 

// .scoreSheet-topBar-right button.btn.collapsesets,
// .scoreSheet-topBar-right button.btn.exportcsv{
// 	min-width: 39px;
// 	width: 39px;
// }

// .scoreSheet-topBar-right button.btn.collapsesets{
// 	border-top-right-radius: 0px;
// 	border-bottom-right-radius: 0px;
// 	position: relative;
// 	z-index: 5;
// }


// .scoreSheet-topBar-right button.btn.exportcsv{
// 	margin-right: 8px;
// 	border-top-left-radius: 0px;
// 	border-bottom-left-radius: 0px;
// 	margin-left: -1px;
// }

// .scoreSheet-topBar-right button.btn.collapsesets svg,
// .scoreSheet-topBar-right button.btn.exportcsv svg{
// 	fill: $gray-5;
// }

// .scoreSheet-topBar-right button.btn.collapsesets svg{
// 	height: 19px;
// 	width: 19px;
// 	margin-top: 1px;
// }

// .scoreSheet-topBar-right button.btn.exportcsv svg{
// 	height: 19px;
// 	width: 19px;
// }

// .scoreSheet-topBar-right button.btn.btn--blue.collapsesets svg{
// 	fill: $white;
// }

// /// DROPDOWN

// .scoreSheet-topBar-right button.btn.menu svg{
// 	fill: $gray-5;
// }