// CLOSE BUTTON

.featureModal-closeBtn{
	position: absolute;
	width: 42px;
	height: 42px;
	top: 20px;
	right: 24px;
	cursor: pointer;
	border-radius: 21px;

	opacity: 0.4;
	transition: opacity 100ms linear;

	z-index: 100;
}

.featureModal-closeBtn:hover{
	opacity: 0.8;
	transition: opacity 50ms linear;
}

.featureModal-closeBtn:active{
	opacity: 1;
}

.featureModal-closeBtn .icon{
	width: 42px;
	height: 42px;
	fill: $gray-6;
}
