.upgrade--landing-compareTableContainer{
	width: 100%;

	display: flex;
	align-items: center;
	justify-content: center;

	height: 660px;
	position: relative;
}

.upgrade--landing-compareTable{
	width: 370px;
	// height: 516px;
	margin: 32px;
	border-radius: 13px;

	background: $white;
	border: 1px solid $lineDark;
	box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.12);
	position: relative;
	z-index: 5;

	box-sizing: border-box;
	padding-left: 30px;
	padding-right: 30px;
	padding-bottom: 24px;
	padding-top: 24px;

	text-align: left;

	cursor: default;
}

.upgrade--landing-compareTable-header{
	font-weight: $effra-bold;
	font-size: 28px;
	margin-bottom: 6px;
}

.upgrade--landing-compareTable--free
.upgrade--landing-compareTable-header{
	color: $textMid;
}

.upgrade--landing-compareTable--free
span.upgrade--landing-compareTable-header-append{
	color: $textLight;
}

.upgrade--landing-compareTable--pro
.upgrade--landing-compareTable-header{
	color: $textBlue;
}

.upgrade--landing-compareTable--pro
span.upgrade--landing-compareTable-header-append{
	color: $blue-light;
}


.upgrade--landing-compareTable-price{
	font-size: 40px;
	font-weight: $effra-bold;
	color: $textBlack;
	position: relative;
}

.upgrade--landing-compareTable-price
span.upgrade--landing-compareTable-normally{
	color: $gray-115;
	margin-left: 7px;
	margin-right: 2px;
}


.upgrade--landing-compareTable-price
span.upgrade--landing-compareTable-month{
	font-size: 18px;
	font-weight: $effra-regular;
	color: $textMid;
}

.upgrade--landing-compareTable-price
span.upgrade--landing-compareTable-from{
	font-size: 24px;
	font-weight: $effra-medium;
}



.upgrade--landing-compareTable-price-strikeThrough{
	position: absolute;
	top: 22px;
	left: 109px;
	width: 104px;
	height: 3px;
	background: $gray-11;
	opacity: 0.95;
	transform: rotate(15deg);
	z-index: 5;
}
//


.upgrade--landing-compareTable-divider{
	width: 100%;
	margin-top: 6px;
	margin-bottom: 18px;
	height: 26px;
	font-size: 15px;
	font-weight: $effra-medium;
	color: $textLight;
	color: rgb(222,111,112); // for fall 2020
	//background: $tempr;
	border-bottom: 1px solid $lineDark;
}

.upgrade--landing-compareTable button.upgrade--landing-btn--small {
	width: 100%;	
}


//

.upgrade--landing-compareTable-row{
	display: flex;
	height: 36px;

	align-items: center;
	justify-content: center;
}

.upgrade--landing-compareTable-row-checkContainer{
	width: 16px;
	height: 16px;
	flex-shrink: 0;
	flex-grow: 0;
	margin-right: 22px;
}


.upgrade--landing-compareTable-row-checkContainer svg{
	fill: $green;
	height: 16px;
	width: 16px;
}

.upgrade--landing-compareTable-row-featureLabel{
	min-width: 10px;
	flex-grow: 1;
	flex-shrink: 1;

	font-size: 16px;
	font-weight: $effra-medium;
	color: $textBlack;

}

.upgrade--landing-compareTable-row--inactive
.upgrade--landing-compareTable-row-checkContainer svg{
	fill: $gray-12;
}

.upgrade--landing-compareTable-row--inactive
.upgrade--landing-compareTable-row-featureLabel{
	color: $textLight;
}

//


.upgrade--landing-compareTableContainer-bg{
	width: 974px;
	height: 634px;
	position: absolute;
	top: 30px;
	left: 50%;
	transform: translateX(-50%);
}

.upgrade--landing-compareTableContainer-bg svg{
	fill: $upgrade-cloudBlue-bg;
}