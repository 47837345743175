$explorerCrossFilterRepo--internalLeftPadding: 12px;

// explorerCrossFilterRepo

.explorerCrossFilterRepo{
	width: 100%;

	border-bottom: 1px solid $lineMid;

	user-select: none;
	cursor: default;

	position: relative;

	padding-left: 8px; // space for InternalRatingBar
	padding-bottom: 12px;

	cursor: pointer;

}

.explorerCrossFilterRepo:hover{
	background: $gray-15;
}


// InternalRatingBar
// add this properly after ratings worked out

.explorerCrossFilterRepo-internalRatingColorBar{


	width: 2px;
	height: 42px;
	border-radius: 1px;

	background: $red; // default no rating

	position: absolute;

	top: 8px;
	left: 3px;
}

.explorerCrossFilterRepo-internalRatingColorBar--rating5{
	background: $color-o85;
}

.explorerCrossFilterRepo-internalRatingColorBar--rating4{
	background: $color-o85;
}

.explorerCrossFilterRepo-internalRatingColorBar--rating3{
	background: $color-o85;
}

.explorerCrossFilterRepo-internalRatingColorBar--rating2,
.explorerCrossFilterRepo-internalRatingColorBar--rating1,
.explorerCrossFilterRepo-internalRatingColorBar--rating0{
	background: none;
}

.explorerCrossFilterRepo-internalRatingColorBar--rating-1{
	background: $red;
}

//

.explorerCrossFilterRepo--top{
	width: 100%;
	display: flex;
}

.explorerCrossFilterRepo--top--left{
	flex-grow: 1;
	flex-shrink: 1;
	width: 100%;
	height: 100%;
}

.explorerCrossFilterRepo--top--center{
	flex-grow: 0;
	flex-shrink: 0;
	width: 180px;
	height: 100%;
	display: flex;
}

.explorerCrossFilterRepo--top--right{
	width: 180px;
	height: 100%;
	// background: $red;
	flex-grow: 0;
	flex-shrink: 0;
}







.explorerCrossFilterRepo-title{
	font-size: 16px;
	font-weight: $lato-bold;
	color: $textBlackDark;
	padding-top: 10px;
	padding-left: $explorerCrossFilterRepo--internalLeftPadding;
	padding-bottom: 4px;
}

// .explorerCrossFilterRepo-title:hover{
// 	background: transparentize($blue-extraLight, 0.5);
// 	color: $textBlue;
// }

// .explorerCrossFilterRepo-title svg.icon{
// 	fill: $gray-13;
// 	width: 12px;
// 	height: 12px;
// 	position: relative;
// 	top: 1px;
// 	left: 3px;
// }

// .explorerCrossFilterRepo-title:hover svg.icon{
// 	fill: $blue;
// }

.explorerCrossFilterRepo-author{
	font-size: 14px;
	font-weight: $lato-semibold;
	color: $textMid;
	padding-left: $explorerCrossFilterRepo--internalLeftPadding;
	cursor: pointer;
	// background: $tempr;
	width: 150px;
}

.explorerCrossFilterRepo-author:hover{
	color: $textBlue;
}

//
// Total Response Count
.explorerCrossFilterRepo--top-sevenDayConsumerResponses,
.explorerCrossFilterRepo--top-totalConsumerResponses{
	width: 50%;
	text-align: right;
	box-sizing: border-box;
	padding-top: 10px;
}

.explorerCrossFilterRepo--top-sevenDayConsumerResponses-count,
.explorerCrossFilterRepo--top-totalConsumerResponses-count{
	font-size: 16px;
	font-weight: $lato-bold;
	color: $textBlackDark;
	margin-bottom: 5px;
}

.explorerCrossFilterRepo--top-sevenDayConsumerResponses-label,
.explorerCrossFilterRepo--top-totalConsumerResponses-label{
	font-size: 11.5px;
	font-weight: $lato-semibold;
	color: $textLight;
	text-transform: uppercase;
	// opacity: 0;
}

// .explorerCrossFilterRepo:hover
// .explorerCrossFilterRepo--top-totalConsumerResponses-label{
// 	opacity: 1;
// 	transition: opacity 50ms linear;
// 	transition-delay: 500ms;
// }



// InternalRatingButton and Dropdown
button.explorerCrossFilterRepo-rating-btn{
	width: 100%;
	height: 100%;
	display: block;
	border: none;
	background: none;
	box-shadow: none;
	cursor: pointer;
	padding-top: 8px;
	padding-bottom: 10px;
	padding-right: 8px;
}

button.explorerCrossFilterRepo-rating-btn:hover{
	background: rgba(0,0,30,0.03);
	border: none;
	box-shadow: none;
}

.explorerCrossFilterRepo-rating-btn-value{
	text-align: right;
	font-size: 18px;
	font-weight: $lato-heavy;
	color: $textBlackDark;
	margin-top: -3px;
}

button.explorerCrossFilterRepo-rating-btn---1
.explorerCrossFilterRepo-rating-btn-value,
button.explorerCrossFilterRepo-rating-btn--noRating
.explorerCrossFilterRepo-rating-btn-value{
	color: transparentize($textLight, 0.3);
}

.explorerCrossFilterRepo-rating-btn-description{
	text-align: right;
	font-size: 11px;
	font-weight: $lato-medium;
	color: $invisible;
	margin-top: -5px;
}

button.explorerCrossFilterRepo-rating-btn:hover .explorerCrossFilterRepo-rating-btn-description,
button.explorerCrossFilterRepo-rating-btn.btn--dropdown--open .explorerCrossFilterRepo-rating-btn-description{
	color: $textMid;
}

.explorerCrossFilterRepo:hover .dropdown{
	z-index: 9999;
}


.explorerCrossFilterRepo .dropdownMenu{
	left: 0px;
	top: 50px;
	width: 380px;
}

.explorerCrossFilterRepo .dropdownMenu.is--open{
	z-index: 100;
}

.explorerCrossFilterRepo .dropdownMenu-item{
	cursor: pointer;
}



//
//
//

.explorerCrossFilterRepo--bottom{
	display: flex;
	min-height: 60px;
}

.explorerCrossFilterRepo-metadata{
	width: 240px;
	flex-grow: 0;
	flex-shrink: 0;
	padding-left: $explorerCrossFilterRepo--internalLeftPadding;

	font-size: 11.5px;
	font-weight: $lato-semibold;
	color: $textLight;
	text-transform: uppercase;
}

.explorerCrossFilterRepo-metadata-reviews--hasReviews{
	color: $textBlack;
	font-weight: $lato-bold;
}

//
// NOTES
//

.explorerCrossFilterRepo-notes{
	width: 50px;
	flex-grow: 1;
	flex-shrink: 1;
	cursor: pointer;
	padding: 5px;
	padding-left: 10px;
	padding-right: 10px;
	margin-top: -5px;

	border-radius: 4px;
}

.explorerCrossFilterRepo-notes:hover{
	background: rgba(0,0,40,0.02);
}

.explorerCrossFilterRepo-notes:hover{
	// background: $blue-extraLight;
}

.explorerCrossFilterRepo-notes-header{
	text-transform: uppercase;
	font-size: 11.5px;
	font-weight: $lato-semibold;
	color: $textMid;
}

.explorerCrossFilterRepo-notes-header--empty{
	color: $textLight;
}

.explorerCrossFilterRepo-notes:hover
.explorerCrossFilterRepo-notes-header{
	color: $textBlack;
}

// .explorerCrossFilterRepo-notes:hover
// .explorerCrossFilterRepo-notes-header{
// 	color: $textBlue;
// }


.explorerCrossFilterRepo-notes-body{
	margin-top: 4px;
	font-size: 12.5px;
	font-weight: $lato-medium;
	color: $textBlack;


	white-space: pre-wrap;
  overflow: hidden;

	word-wrap: break-word;

	max-height: 120px;
}

.explorerCrossFilterRepo-notes-body--noNotes{
	color: $textLight;
}

//
// DATE
//

.explorerCrossFilterRepo-date{
	width: 240px;
	flex-grow: 0;
	flex-shrink: 0;
	padding-right: 8px;
	// display: flex;
	// flex-direction: column;
	// align-items: bottom;
	// justify-content: flex-end;
	text-align: right;


}


.explorerCrossFilterRepo-date-relative{
	font-size: 12px;
	font-weight: $lato-semibold;
	color: $textMid;
	margin-bottom: 4px;
}

.explorerCrossFilterRepo-date:hover
.explorerCrossFilterRepo-date-relative{
	color: $textBlack;
}

.explorerCrossFilterRepo-date-full{
	font-size: 11.5px;
	font-weight: $lato-medium;
	color: $textLight;
}

.explorerCrossFilterRepo-date:hover
.explorerCrossFilterRepo-date-full{
	color: $textDark;
}


// .explorerCrossFilterRepo--right button.explorerCrossFilterRepo-openBtn{
// 	height: 100%;
// 	width: 250px;
// 	background: none;
// 	box-shadow: none;
// 	border: none;
// 	outline: none;

// 	text-transform: uppercase;
// 	font-size: 13px;
// 	font-weight: $lato-semibold;
// 	color: $textLight;
// 	letter-spacing: 0.02em;

// 	cursor: pointer;
// }

// .explorerCrossFilterRepo--right button.explorerCrossFilterRepo-openBtn:hover{
// 	background: $blue-extraLight;
// 	color: $textBlue;
// }

// .explorerCrossFilterRepo--right button.explorerCrossFilterRepo-openBtn:active{
// 	background: transparentize($blue, 0.8);
// }
