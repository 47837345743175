

//
// IMAGE
//


// UPLOAD FROM COMPUTER BUTTON

.publishingFlowModalPage--image-subheader{
  display: flex;
}

.publishingFlowModalPage-subheader-uploadBtnHidden{
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.publishingFlowModalPage--image-subheader-uploadBtn{
    cursor: pointer;
    color: darken($textBlue, 5%);
}

.publishingFlowModalPage--image-subheader-uploadBtn:hover{
  text-decoration: underline;
}


//
// Image Search UI General
//


.publishingFlowModalPage--image-imageInterfaceContainer{
  margin-top: 15px;
}

// SEARCH INPUT

.publishingFlowModalPage--image-imageSearchBox{
  width: 100%;
  display: flex;
  align-items: center;

  box-sizing: border-box;
  position: relative;
  // background: $tempr;
}

.publishingFlowModalPage--image-imageSearchBox
input.publishingFlowModalPage--image-imageSearchBox-input{
  height: 36px;
  line-height: 36px;
  flex-grow: 1;

  border-radius: 4px;
  outline: none;
  border: none;
  box-shadow: none;

  border: 1px solid $lineMid;

  background: $white;
  color: $textBlack;
  font-size: 18px;
  font-weight: $lato-semibold;
  letter-spacing: 0.005em;

  box-sizing: border-box;
  padding-left: 12px;

  -moz-appearance: none;
}

.publishingFlowModalPage--image-imageSearchBox
input.publishingFlowModalPage--image-imageSearchBox-input:focus{
  border: 1px solid $blue;
}

.publishingFlowModalPage--image-imageSearchBox
input.publishingFlowModalPage--image-imageSearchBox-input::-webkit-input-placeholder{
  color: $textInputPlaceholder !important;
}

$width-publishingFlowImage-Btn: 140px;

.publishingFlowModalPage--image-imageSearchBox-hint{
  position: absolute;
  //right: calc(#{$width-publishingFlowImage-Btn} + 20px);
  top: 39px;
  left: 9px;

  padding-top: 2px;
  padding-bottom: 2px;
  padding-left: 5px;
  padding-right: 5px;
  // background: $tempr;

  font-size: 12.5px;
  pointer-events: none;
  color: $textMid;

  opacity: 0;

  transition: all 0ms linear;
}

.publishingFlowModalPage--image-imageSearchBox-hint b{
  font-weight: $lato-bold;
  color: $textDark;
  text-transform: uppercase;
}

input.publishingFlowModalPage--image-imageSearchBox-input:focus ~
.publishingFlowModalPage--image-imageSearchBox-hint.publishingFlowModalPage--image-imageSearchBox-hint--hasSearchTerm{
  opacity: 1;
}

.publishingFlowModalPage--image-imageSearchBox
button.publishingFlowModalPage--image-imageSearchBox-searchBtn{
  height: 36px;
  width: $width-publishingFlowImage-Btn;
  flex-grow: 0;
  border-radius: 3px;
  -webkit-appearance: none;
  outline: none;
  box-shadow: none;
  border: none;

  font-size: 17px;
  font-weight: $lato-bold;
  letter-spacing: 0.005em;

  // background: $blue;
  // color: $white;
  // border: 1px solid darken($blue, 5%);

  background: $white;
  color: $textDark;
  border: 1px solid $gray-12;

  margin-left: 6px;


  text-align: center;
  line-height: 32px;

  cursor: pointer;

  position: relative;
}

button.publishingFlowModalPage--image-imageSearchBox-searchBtn:hover{
  border: 1px solid $gray-10;
  color: $textBlack;
}

.publishingFlowModalPage--image-imageSearchBox
button.publishingFlowModalPage--image-imageSearchBox-searchBtn--submitLoading{
  opacity: 0.75;
}

.publishingFlowModalPage--image-bingAttributionContainer,
.publishingFlowModalPage--image-bingAttributionContainer--result{
  position: absolute;
  left: 50%;
}

.publishingFlowModalPage--image-bingAttributionContainer{
  bottom: 150px;
  margin-left: -100px;
}

.publishingFlowModalPage--image-bingAttributionContainer--result{
  bottom: 80px;
  margin-left: -76px;
}

//
// Submit Loading
//

.publishingFlowModalPage--image-imageSearchBox-searchBtn-loadingSpinnerContainer{
  position: absolute;

  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.publishingFlowModalPage--image-imageSearchBox-searchBtn-loadingSpinner{
  height: 15px;
  width: 15px;

  border-radius: 11px;

  border: 3px $gray-13 solid;
  border-top: 3px $gray-7 solid;

  animation: spinnerRotateAnimation .4s infinite linear;
}




//
// GALLERY
//

.publishingFlowModalPage--image-imageGrid{
  width: 100%;
  position: relative;
  z-index: 10;
  margin-top: 5px;
  box-sizing: border-box;
   overflow-y: scroll;
   overflow-y: overlay;
   max-height: 320px;
   padding-bottom: 40px;
}

.publishingFlowModalPage--image-imageGrid:hover .tile {
  opacity: 0.75;
}

.publishingFlowModalPage--image-imageGrid .tile:hover {
  opacity: 1;
}

.publishingFlowModalPage--image-imageGrid #Gallery{
  box-sizing: border-box;
  padding-top: 0px;
}

// Clearfix to position loadmore button under results
.publishingFlowModalPage--image-imageGrid:after{
    content: "";
    display: table;
    clear: both;
}


.publishingFlowModalPage--image-imageGrid-loadMore{
    width: 100%;
    display: flex;
    height: 45px;
    align-items: center;
    justify-content: center;
}


.publishingFlowModalPage--image-imageGrid .btn.show-more-results{
    width: 175px;
}

//
// No results
//

.publishingFlowModalPage--image-imageGrid-noResults{
  margin-top: 80px;
  width: 100%;
  text-align: center;
  font-size: 16px;
  font-weight: $lato-semibold;
  color: $textLight;
  overflow: hidden;
}

.publishingFlowModalPage--image-imageGrid-loadMoreBtnContainer{
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  padding-right: 31px;
}

//
// FROM PACK IMAGES AND GRID
//

.publishingFlowModalPage--image-fromPackImageGrid{
  width: 100%;
  position: relative;
  z-index: 10;
  margin-top: 5px;
  box-sizing: border-box;
   overflow-y: scroll;
   overflow-y: overlay;
   max-height: 360px; // hmmmm
   padding-bottom: 40px;
}

.publishingFlowModalPage--image-fromPackImageGrid-header{
  font-size: 15px;
  font-weight: $lato-medium;
  color: $textDark;
  letter-spacing: 0.01em;

  cursor: default;
  user-select: none;

  margin-top: 20px;
  margin-bottom: 10px;


}

.publishingFlowModalPage--image-fromPackImageGrid-grid{
  display: flex;
  flex-wrap: wrap;
}

.publishingFlowModalPage--image-fromPackImageGrid-grid img{
  height: 130px;
  padding: 2px;
  width: auto;
  cursor: pointer;
}

.publishingFlowModalPage--image-fromPackImageGrid-grid:hover img{
  opacity: 0.75;
}

.publishingFlowModalPage--image-fromPackImageGrid-grid img:hover{
  opacity: 1;
}


// .publishingFlowModalPage--image-fromPackImageGrid:hover .tile {
//   opacity: 0.75;
// }

// .publishingFlowModalPage--image-imageGrid .tile:hover {
//   opacity: 1;
// }

// .publishingFlowModalPage--image-imageGrid #Gallery{
//   box-sizing: border-box;
//   padding-top: 0px;
// }


//
// Disable things when uploading image
//

.publishingFlowModalPage--image--uploading .publishingFlowModalPage--image-imageGrid,
.publishingFlowModalPage--image--uploading .publishingFlowModalPage--image-imageInterfaceContainer,
.publishingFlowModalPage--image--uploading .publishingFlowModalPage-subheader,
.publishingFlowModalPage--image--uploading .publishingFlowModalPage-header{
  overflow-y: hidden;
  pointer-events: none;
  opacity: 0.45;
  transition: opacity 250ms linear;
}

.publishingFlowModalPage--image--uploading
.publishingFlowModalPage--image-imageSearchBox{
  pointer-events: none;
}
