//
// PAGE LAYOUT
// this defines the common page layout used
// see readmes/PAGELAYOUT.md for more information
//

$height-topNav: 48px;

$height-pageHeader--top: 178px;
$height-pageHeader--sticky: 75px;

$leftGutter-centerPanel: 90px;
$rightGutter-centerPanel: 45px;

.page{
	display: flex;
	width: 100vw;
	min-height: 100%;
	background: $tempb;
	padding-top: $height-topNav;
	box-sizing: border-box; 
	background: $color-pageBG;
}

.page-centerPanel{	
	position: relative;

	box-sizing: border-box;
	padding-left: $leftGutter-centerPanel;
	padding-right: $rightGutter-centerPanel;
	
	padding-bottom: 75px;

	min-height: calc(100vh - #{$height-topNav});
	
	border-left: 1px solid $color-pageCenterBorder;
	border-right: 1px solid $color-pageCenterBorder;
	background: $white;
}

// inner measure spacer is used on set detail
// and report detail to find internal width after
// padding. it is measured with JS.

.page-centerPanel-innerMeasureSpacer{
	width: 100%;
	height: 1px;
	visibility: hidden;
}

// keep to left when below minimum

@media (min-width: 1020px) {
	.page-rightPanel .sidePanelContainer{
		right: calc(0px - (100vw - 100%));
	}
}

// RESPONSIVE LAYOUT TEMPLATES 
@import 'PageLayout-layoutTemplates';

// 

.page-rightPanel .sidePanelContainer{
	position: fixed;

	width: inherit;
	max-width: inherit;
	
	top: $height-topNav;
	height: calc(100vh - #{$height-topNav});
	display: flex;
	box-sizing: border-box;
	padding-top: 75px;
}

.page-rightPanel .sidePanel{
	height: 100%; // refactor maybe
	margin-right: auto;
}


