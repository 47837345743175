

button.liveView-sideNavigationControls-btn{
	outline: none;
	border: none;

	width: 125px;
	height: calc(100% - 220px - 220px);
	top: 220px;
	position: absolute;
	border-radius: 5px;
	display: flex;
	align-items: center;
	justify-content: center;
	box-sizing: border-box;
	cursor: pointer;

	z-index: 9999;

}

button.liveView-sideNavigationControls-btn--previous{
	left: 0px;
}

button.liveView-sideNavigationControls-btn--next{
	right: 0px;
}

.liveView-sideNavigationControls-btn .icon{
	width: 40px;
	height: 40px;
	fill: $lineDark;
}

button.liveView-sideNavigationControls-btn--previous{
	border-top-left-radius: 0px;
	border-bottom-left-radius: 0px;
	padding-right: 8px; //optical icon centering
}


button.liveView-sideNavigationControls-btn--next{
	border-top-right-radius: 0px;
	border-bottom-right-radius: 0px;
}

// STATES
// to prevent wrapper div, opacity is set globally for timeout
// so instead of opacity we change BG color
.liveView-sideNavigationControls-btn.liveView-sideNavigationControls-btn--globalVisible{
	background: rgba(0,0,40,0.02);
}

.liveView-sideNavigationControls-btn.liveView-sideNavigationControls-btn--globalVisible .icon{
	opacity: 0.5;
}

.liveView-sideNavigationControls-btn.liveView-sideNavigationControls-btn--globalHidden{
	background: rgba(0,0,40,0);
}

.liveView-sideNavigationControls-btn.liveView-sideNavigationControls-btn--globalHidden .icon{
	opacity: 0;
}

// DEFAULT

.liveView-sideNavigationControls-btn{
	background: rgba(0,0,40,0.02);
}

.liveView-sideNavigationControls-btn .icon{
	opacity: 0.5;
}

// FOCUS/HOVER

.liveView-sideNavigationControls-btn:hover,
.liveView-sideNavigationControls-btn:focus{
	background: rgba(0,0,40,0.04);
	border: none;
	outline: none;
	box-shadow: none;
}

.liveView-sideNavigationControls-btn:hover .icon,
.liveView-sideNavigationControls-btn:focus .icon{
	opacity: 1;
}

// ACTIVE

.liveView-sideNavigationControls-btn:active{
	background: rgba(0,0,40,0.1);
	border: none;
	outline: none;
	box-shadow: none;
}

.liveView-sideNavigationControls-btn:active .icon{
	opacity: 1;
}