
/// invitation banner
@keyframes animateInRepoInvitationBanner {
  from { transform: translateY(20px); opacity: 0;}
  to { transform: translateY(0px); opacity: 1;}
}




.repo-invitationBannerContainer{
	opacity: 0;
	// background: $tempr;
	position: absolute;

	left: 0px;
	top: calc(100vh - 132px);
	right: 0px;
	box-sizing: border-box;
	padding-left: 48px;
	padding-right: 36px;
	//padding-bottom: 20px;
	z-index: 1250;

	animation-name: animateInRepoInvitationBanner;
	animation-duration: 750ms;
	animation-delay: 50ms; 
	animation-iteration-count: 1;
	animation-fill-mode: forwards;
	animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.075);
}


.repo-invitationBanner{
	height: 64px;
	width: 100%;
	background: $white;
	border: 1px solid $lineDark;
	box-shadow: 0px 2px 4px 0px rgba(0,0,20,0.06);
	border-radius: 5px;

	display: flex;

	box-sizing: border-box;
	padding-left: 15px;
	padding-right: 15px;

	align-items: center;

	user-select: none;
	cursor: default;

	
}



.repo-invitationBanner-inviteeProfilePicContainer{
	flex-grow: 0;
	flex-shrink: 0;

	width: 36px;
	height: 36px;

	border-radius: 5px;

	overflow: hidden;

	margin-right: 12px;
}


.repo-invitationBanner-center{
	flex-grow: 1;
	flex-shrink: 1;
	min-width: 0px; // allows text to truncate on children
	margin-right: 10px;
}

.repo-invitationBanner-center-headline{
	font-size: 16.5px;
	color: $textBlackDark;
	font-weight: $lato-semibold;
	margin-bottom: 3px;

	white-space: nowrap; 
  overflow: hidden;
  text-overflow: ellipsis;
}

.repo-invitationBanner-center-headline b{
	font-weight: $lato-bold;
}


.repo-invitationBanner-center-subline{
	display: flex;
	font-size: 14px;
	font-weight: $lato-medium;
	color: $textMid;
}

.repo-invitationBanner-center-subline-label{
	flex-shrink: 1;
	min-width: 0px;

	white-space: nowrap; 
  overflow: hidden;
  text-overflow: ellipsis;	
}

.repo-invitationBanner-center-subline-rejectBtn{
	color: lighten($textLight, 10%);
	margin-left: 5px;
	cursor: pointer;

	flex-grow: 0;
	flex-shrink: 0;
}

.repo-invitationBanner-center-subline-rejectBtn:hover{
	color: $red;
}

.repo-invitationBanner-right{
	flex-grow: 0;
	flex-shrink: 0;
}

.repo-invitationBanner-right button{
	cursor: pointer;
	width: 180px;
	box-shadow: 0px 4px 8px 0px rgba(0,0,20,0.04);
}
