.multiSelectPreviewPanelContainer{
	height: 100%;
	width: 100%;
}

.multiSelectPreviewPanel{
	width: 100%;
	height: 100%;
	box-sizing: border-box;
	overflow: visible;

	display: flex;
	flex-direction: column;

	position: relative;
}


.multiSelectPreviewPanel-header{
	box-sizing: border-box;
	position: relative;
}

.multiSelectPreviewPanel-header-title{
	font-size: 20px;
	font-weight: $lato-heavy;		
	padding-top: 7px; // bit messy sorry, aligning with default previewpanel
	padding-bottom: 6px;
	box-sizing: border-box;
}

.multiSelectPreviewPanel-header-questionCount{
	font-size: 13px;
	color: $textMid;
	user-select: none;
	cursor: default;
	margin-top: 5px;
	margin-bottom: 19px;
}

.multiSelectPreviewPanel-header-questionCount-count{
	font-weight: $lato-semibold;
	margin-right: 3px;
}

.multiSelectPreviewPanel-header-questionCount-label{
	font-weight: $lato-medium;
}

.multiSelectPreviewPanel:hover .multiSelectPreviewPanel-header-questionCount{
	color: $textDark;
}


.multiSelectPreviewPanel-header-subtitle{
	font-size: 20px;
	font-weight: $lato-heavy;		
	padding-top: 7px;
	padding-bottom: 18px;
	box-sizing: border-box;
}

.multiSelectPreviewPanel-buttonRow{
	display: flex;
	min-height: 30px;
	padding-bottom: 20px;
}

.multiSelectPreviewPanel-buttonRow .btn.movealltopack{
	flex-grow: 0;
	flex-shrink: 0;
	width: 170px;
	min-width: 170px;
	margin-right: auto;
}

.multiSelectPreviewPanel-buttonRow .btn.movealltopack .icon{
	transform: rotate(180deg);
}

.multiSelectPreviewPanel-buttonRow .btn.menu{
	flex-grow: 0;
	width: 32px;	
}

.multiSelectPreviewPanel-buttonRow .btn.menu svg.icon{
	fill: $gray-8;
	width: 14px;
	height: 14px;
}

.multiSelectPreviewPanel-buttonRow .btn.menu:hover svg.icon{
	fill: $blue;
}


.multiSelectPreviewPanel-buttonRow .dropdownMenu.position--left{
	left: -160px;
}



/////

.multiSelectPreviewPanel-divider{
	width: 100%;
	height: 1px;
	background: $lineMid;
}

//

.multiSelectPreviewPanel-moveToFolder{	
	//background: $tempr;

	position: absolute; // messy, but need to for scroll I think
	left: 0px;
	right: 0px;
	top: 140px;
	bottom: 50px;

	user-select: none;
	cursor: default;
}

.multiSelectPreviewPanel-moveToFolder-header{
	font-size: 11px;
	color: $textMid;
	font-weight: $lato-semibold;
	letter-spacing: 0.02em;
	text-transform: uppercase;
	margin-bottom: 8px;
}

.multiSelectPreviewPanel-moveToFolder-scrollList{
	max-height: calc(100% - 40px);
	
	overflow-y: auto;
	margin-left: -8px; // buttons overhang
	max-width: 200px;
}



//



.multiSelectPreviewPanel-moveToFolder-scrollList-folder,
.multiSelectPreviewPanel-moveToFolder-selectFolderBtn{
	height: 26px;
	line-height: 1.7;
	font-weight: $lato-medium;
	font-size: 14px;

	box-sizing: border-box;
	position: relative;
	cursor: pointer;
	border-radius: 2px;
	color: $textBlack;
	border: 1px solid $invisible;

	white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  padding-left: 28px;
  padding-right: 8px;
}



.multiSelectPreviewPanel-moveToFolder-scrollList:hover
.multiSelectPreviewPanel-moveToFolder-scrollList-folder{
	opacity: 0.5;
}

.multiSelectPreviewPanel-moveToFolder-scrollList
.multiSelectPreviewPanel-moveToFolder-scrollList-folder:hover{
	opacity: 1;
}



.multiSelectPreviewPanel-moveToFolder-selectFolderBtn{
	padding-left: 8px;
	color: $textMid;
	margin-left: -8px;
	max-width: 200px;
}


// Hover Statues

.multiSelectPreviewPanel-moveToFolder-scrollList-folder:hover,
.multiSelectPreviewPanel-moveToFolder-selectFolderBtn:hover{
	border: 1px solid $lineMid;
	background: $white;
}



.multiSelectPreviewPanel-moveToFolder-selectFolderBtn:hover{
	color: $textBlack;
}

.multiSelectPreviewPanel-moveToFolder-scrollList-folder:hover{
	font-weight: $lato-semibold;
}

.multiSelectPreviewPanel-moveToFolder-scrollList-folder .icon.folder,
.multiSelectPreviewPanel-moveToFolder-scrollList-folder .icon.sign-in-arrow{
	position: absolute;
	top: 6px;
	left: 8px;
	height: 14px;
	width: 14px;
}

.multiSelectPreviewPanel-moveToFolder-scrollList-folder .icon.folder #top,
.multiSelectPreviewPanel-moveToFolder-scrollList-folder .icon.folder #main{
	fill: $blue;
}


// Disabled State
.multiSelectPreviewPanel-moveToFolder-scrollList-folder.multiSelectPreviewPanel-moveToFolder-scrollList-folder--disabled{
	opacity: 0.5;
	pointer-events: none;
}

.multiSelectPreviewPanel-moveToFolder-scrollList-folder.multiSelectPreviewPanel-moveToFolder-scrollList-folder--disabled .icon.folder #top,
.multiSelectPreviewPanel-moveToFolder-scrollList-folder.multiSelectPreviewPanel-moveToFolder-scrollList-folder--disabled .icon.folder #main{
	fill: $gray-11;
}


///


.multiSelectPreviewPanel-addToQueueMenuContainer{
	position: absolute;
	bottom: 15px;
	left: 0px;
	right: 0px;
}

.multiSelectPreviewPanel-addToQueueMenu{
	width: 100%;
	// margin: 0 auto;
	max-width: 200px;
}


.multiSelectPreviewPanel button.add-all-to-queue{ // button strips out naming
	width: 100%;
}

.multiSelectPreviewPanel-addToQueueMenu .dropdownMenu{
	width: 100%;
	min-width: 195px;

	max-height: calc(100vh - 110px);
	overflow: auto;
	overflow-y: auto;
	overflow-x: hidden;
}
