.repo-table-inlineRatingPanel{
	
	width: 100%;
	background: transparentize($blue, 0.95);
	//background: $tempr;

	box-sizing: border-box;
	padding-left: 12px;
	padding-right: 12px;

	border-radius: 6px;
	
	margin-bottom: 10px;
}

.repo-table-inlineRatingPanel--noRating{
	height: 62px;
}

.repo-table-inlineRatingPanel--rating{
	
}

//
// TOP ROW

.repo-table-inlineRatingPanel-topRow{
	height: 62px;
	display: flex;
	align-items: center;

	cursor: default;
}

//

.repo-table-inlineRatingPanel-topRow-icon{
	width: 32px;
	height: 32px;
	border-radius: 16px;
	background: transparentize($blue, 0.9);

	display: flex;
	align-items: center;
	justify-content: center;

	box-sizing: border-box;
	padding-bottom: 1px;
	padding-right: 1px;

	margin-right: 12px;
}

.repo-table-inlineRatingPanel-topRow-icon svg.icon{
	width: 15px;
	height: 15px;
	fill: $blue;
}

//

.repo-table-inlineRatingPanel-topRow-title{
	font-size: 15px;
	font-weight: $lato-heavy;
	color: $textBlackDark;
	letter-spacing: 0.01em;

	margin-right: auto;
}

.repo-table-inlineRatingPanel-topRow-title span{
	font-weight: $lato-semibold;
	opacity: 0.5;
	margin-left: 4px;
}

//

.repo-table-inlineRatingPanel-topRow-stars{
	display: flex;
	margin-right: 6px;
}

// STARS

button.repo-table-inlineRatingPanel-starBtn{
	box-shadow: none;
	border: none;

	display: flex;
	align-items: center;
	justify-content: center;

	flex-grow: 0;
	flex-shrink: 0;

	width: 32px;
	height: 32px;
	// border: 1px solid $red;

	box-sizing: border-box;

	cursor: pointer;	
	background: none;

	// background: $tempr;

	position: relative;

	transition: opacity 100ms linear;
}

button.repo-table-inlineRatingPanel-starBtn svg{
	width: 26px;
	height: 26px;
	position: static; // IE bug fix
	background: none;
}

button.repo-table-inlineRatingPanel-starBtn.repo-table-inlineRatingPanel-starBtn--active svg{
	//fill: #FFA940;
	fill: $blue;
}

button.repo-table-inlineRatingPanel-starBtn.repo-table-inlineRatingPanel-starBtn--inactive svg{
	fill: transparentize($blue, 0.9);
}

.repo-table-inlineRatingPanel.repo-table-inlineRatingPanel--noRating
.repo-table-inlineRatingPanel-topRow-stars:not(:hover)
button.repo-table-inlineRatingPanel-starBtn svg{
	fill: transparentize($blue, 0.55);
}


//
// BOTTOM ROW


.repo-table-inlineRatingPanel-bottomRow{
	display: flex;
	align-items: flex-start;
}

// TEXT AREA

textarea.repo-table-inlineRatingPanel-messageArea{
	width: 100%;
	border: 1px solid $lineDark;
	resize: none;
	height: 90px;
	border-radius: 2px;

	font-size: 14.5px;
	font-weight: $lato-semibold;
	color: $textBlackDark;
	box-sizing: border-box;

	padding: 6px;


	margin-bottom: 12px;
	margin-left: 4px;
	margin-right: 8px;
}

textarea.repo-table-inlineRatingPanel-messageArea:focus{
	outline: none;
	border: 1px solid $blue;
}

textarea.repo-table-inlineRatingPanel-messageArea::-webkit-input-placeholder{
	color: transparentize($textLight, 0.2);
}

textarea.repo-table-inlineRatingPanel-messageArea:hover::-webkit-input-placeholder{
	color: transparentize($textLight, 0.4);
}	

textarea.repo-table-inlineRatingPanel-messageArea:focus::-webkit-input-placeholder{
	color: transparentize($textLight, 0.6);
}

// SUBMIT BUTTON

.repo-table-inlineRatingPanel button.submitfeedback{
	width: 170px;
	flex-grow: 0;
	flex-shrink: 0;
	font-weight: $lato-heavy;
	letter-spacing: 0.02em;
	height: 88px;
	min-height: 88px;

	font-size: 16px;
	cursor: pointer;

	line-height: 1.2;

	position: relative;

	padding-top: 32px; // make space for icon
}

.repo-table-inlineRatingPanel button.submitfeedback svg.icon{
	position: absolute;
	top: 16px;
	left: 50%;
	transform: translateX(-50%);

	height: 28px;
	width: 28px;

	opacity: 0.7;
}

.repo-table-inlineRatingPanel button.submitfeedback:hover svg.icon{
	opacity: 0.9;
}

.repo-table-inlineRatingPanel button.submitfeedback.btn--disabled{
	pointer-events: none;
	background: $gray-10;
	border: 1px solid $gray-9;
}