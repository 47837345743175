.repoReleaseModal-visual-pageTable{
	box-sizing: border-box;

	position: absolute;
	left: 39px;
	right: 32px;
	top: $repoReleaseModal-visual-pageHeaderHeight
}

.repoReleaseModal-visual-pageTable-cell{
	width: 100%;
	border-bottom: 1px solid $lineMid;
	height: 30px;

	display: flex;
	align-items: center;
}

.repoReleaseModal-visual-pageTable-cell-leftBlob,
.repoReleaseModal-visual-pageTable-cell-center-centerBlob,
.repoReleaseModal-visual-pageTable-cell-right-rightBlob{
	height: 11px;
	border-radius: 3px;
}

.repoReleaseModal-visual-pageTable-cell-leftBlob{
	flex-grow: 0;
	flex-shrink: 0;

	background: $blue-extraLight;

	width: 14px;
	margin-right: 7px;
}

.repoReleaseModal-visual-pageTable-cell-center{
	flex-grow: 0;
	flex-shrink: 0;

	width: 220px;
}

.repoReleaseModal-visual-pageTable-cell-center-centerBlob,
.repoReleaseModal-visual-pageTable-cell-right-rightBlob{
	background: $gray-14;
}

.repoReleaseModal-visual-pageTable-cell-right{
	flex-grow: 1;
	flex-shrink: 1;

	
}