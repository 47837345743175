.editor-insertSoundModal--initial-devSoundGalleryContainer{
	position: absolute;
	top: 0px;
	left: 0px;
	right: 0px;
	bottom: 0px;

	display: flex;
	align-items: center;
	justify-content: center;

	z-index: 50;
}

.editor-insertSoundModal--initial-devSoundGallery{
	width: 600px;
	height: 400px;
	background: white;
	border: 1px solid $lineMid;
	border-radius: 5px;

	box-shadow: 0px 2px 4px 0px rgba(0,0,5,0.12);

	position: relative;
	z-index: 5;

	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;

	box-sizing: border-box;
	padding-left: 20px;
	padding-right: 20px;

	padding-top: 14px;
	padding-bottom: 14px;
}

.editor-insertSoundModal--initial-devSoundGalleryContainerBG{
	position: absolute;
	top: 0px;
	right: 0px;
	left: 0px;
	bottom: 0px;

	background: rgba(0,0,5,0.75);
}


.editor-insertSoundModal--initial-devSoundGallery-title{
	flex-grow: 0;
	flex-shrink: 0;

	width: 100%;
	text-align: center;
	font-size: 12px;
	font-weight: $lato-semibold;
	color: $textDark;

	padding-bottom: 8px;

	text-transform: uppercase;

	letter-spacing: 0.04em;
}

.editor-insertSoundModal--initial-devSoundGallery-colContainer{
	width: 100%;

	flex-grow: 1;
	flex-shrink: 1;

	display: flex;

	overflow-y: scroll;
}

.editor-insertSoundModal--initial-devSoundGallery-col{
	width: 50%;
	flex-grow: 0;
	flex-shrink: 0;



	box-sizing: border-box;
	padding-right: 24px;
}


button.editor-insertSoundModal--initial-devSoundGallery-item{
	width: 100%;

	font-size: 14px;

	background: none;
	border: none;
	padding: 0px;
	padding-top: 4px;
	padding-bottom: 4px;
	border-bottom: 1px solid $lineMid;

	cursor: pointer;

	text-align: left;
}

button.editor-insertSoundModal--initial-devSoundGallery-item:hover{
	background: transparentize($blue, 0.95);
	// border-bottom: 1px solid $blue;
}

button.editor-insertSoundModal--initial-devSoundGallery-item:active{
	background: transparentize($blue, 0.7);
	// border-bottom: 1px solid $lineMid;
}

.editor-insertSoundModal--initial-devSoundGallery-item-primary{
	font-weight: $lato-bold;
	color: $textBlack;
	margin-bottom: 2px;
}

.editor-insertSoundModal--initial-devSoundGallery-item-duration{
	font-weight: $lato-medium;
	color: $textMid;
	font-size: 13px;
}


.editor-insertSoundModal--initial-devSoundGallery-sectionHeader{
	margin-top: 15px;
	margin-bottom: 3px;

	font-weight: $lato-bold;
	color: $blue;

	font-size: 13px;

	cursor: default;
	user-select: none;

}