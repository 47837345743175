//
// APP.SCSS IMPORTS ALL OTHER STYLE FILES
// it is imported into index.js
// many imported SCSS files also import their own files, see e.g. /editor
//

// SOME KEY LAYOUT TO SETUP HTML, BODY, .APP
@import "base/InitializePage.scss";

// COLOR VARIABLES
@import "base/Colors.scss";

// TYPE - including font imports/definitions
@import "base/Type.scss";

// BROWSER RESET
@import "base/NormalizeV8.scss";

// POLY FLUID SIZING MIXIN - used for PageLayout see /readmes/PAGELAYOUT.md
@import "mixins/Poly-Fluid-Sizing.scss";

// PAGE LAYOUT TEMPLATES - see /readmes/PAGELAYOUT.md
@import "base/PageLayout.scss";

// CORE
@import "core/SideNav.scss"; 					// sideNav (with page links etc.)
@import "core/TopNav.scss"; 					// topNav (with search bar etc.)
@import "core/ModalWrapper.scss"; 		// styling for older redux modals see /readmes/MODALS.md
@import "core/SubmitLoading.scss"; 		// provides animation for spinner on buttons after click

// PAGEHEADERS
@import "pageHeaders/pageHeaders.scss"; 	// top and sticky headers on pages

// SIGN IN AND SIGN UP AND ONBOARDING
@import "signinSignup/CartoonDevices.scss"; 	// illustrations of app used on signin/signup pages
@import "signinSignup/SignInSignUp.scss"; 		// sign in and signup pages

// UIKIT
@import "uiKit/Buttons.scss"; 								// <Button> component used in many pages
@import "uiKit/DropdownAndContextMenus.scss";
@import "uiKit/FormField.scss"; 							// Styling for older forms, created with Redux forms
@import "uiKit/FilterBar.scss"; 							// Search bar that appears in pageHeaders
@import "uiKit/FloatingNPControl.scss"; 			// Picture-in-Picture slide-preview when minimize NP
@import "uiKit/GridColumnToggle.scss"; 				// Toggle in e.g. Set Detail to change # of colums
@import "uiKit/HelpVideoThumbnail.scss"; 			// Used in a few places, e.g. empty pack state and import questions help
@import "uiKit/InputField.scss";							// Old, used in some modals, input field component with styling
@import "uiKit/KeyboardKey.scss";							// Keyboard key "icon" used in e.g. ScoreSheet empty state, can be used in other keyboard shortcut contexts
@import "uiKit/PollsForItemList.scss";				// Small pill-buttons to play in class queued in, in e.g. Library sidepanel and Set Detail header
@import "uiKit/QuestionCountBadge.scss";			// Used everywhere - small badge with count of questions in it
@import "uiKit/QuestionSlideCarousel.scss";		// Slide preview carousel click through - in e.g. preview panel in right-side of library
@import "uiKit/ScorePill.scss";								// Used everywhere - shows % score with appropriate score colors (either filled or as a dot)
@import "uiKit/SectionMarker.scss";						// Used everywhere - creates div with appropriate class color, with layout and other styles added where used
@import "uiKit/SetScoresArray.scss";					// Minature set of set scores, goes color on hover. Used in set cards in reports for e.g.
@import "uiKit/SlideGrid.scss";								// Grid of slides used in Set Detail and Set Report
@import "uiKit/StickyDivider.scss";						// Used for sticky in-page sortable table headers in e.g. Library
@import "uiKit/Tooltips.scss";								// Tooltips, see e.g. section home page in top-right (Note: in some places e.g. new set editor we use custom styling)
@import "uiKit/attribution/ThirdPartyAttributionLink.scss"; // for Microsoft Privacy Policy link and Youtube Terms of Service link
@import "uiKit/attribution/BingAttribution.scss";         // Bing attribution for image search

// SECTION PAGES/MODALS
@import "sections/SectionHome.scss";										// Section home page, lots of partials imported to it
@import "sections/SectionHistory.scss";									// For completeness - no custom styling actually required for section history atm
@import "sections/AddStudentBar.scss";									// Input bar in pageHeader in students for quick-adding a student to a class, similar to filter bar
@import "sections/ConfirmEditStudentCardModal.scss";		// When changing/swapping student card numbers, this modal is displayed
@import "sections/DistributeStudentCodesGCModal.scss"; 	// Distribute e-learning student codes via Google Classroom
@import "sections/AssignSectionToDataOwnerModal.scss";  // Assign section to dataOwner modal
@import "sections/DataOwnerInfoModal.scss";             // DataOwner info modal
@import "sections/BulkStudentsModal.scss"; 							// Add students to class modal
@import "sections/ManageSectionsModal.scss";						// Edit sections modal, available by clicking "Edit Classes" in sidenav
@import "sections/NewSectionModal.scss";  							// New section modal using modern modal styling
@import "sections/SectionStudents.scss";								// Section students page
@import "sections/SectionSetup.scss";										// Edit section-name etc. form-page, accessible from cog icon in section homepage top right
@import "sections/SectionRoster.scss";									// Printable section roster. This is a lot simpler than print-student-reports and print-set code.

// DISCOVER
@import "discover/Discover.scss";

// LIBRARY RECENT
@import "libraryrecent/LibraryAndRecent.scss";

// LIVE VIEW
@import "liveView/LiveView.scss";

// SYNC LIVE VIEW - FOR E-LEARNING
@import "syncLiveView/SyncLiveView.scss";
@import "syncLiveView/SyncLearnMoreModal.scss";

// FEATURE MODALS: PRINT SET, STUDENT REPORT, IMPORT QUESTION, REVISION SET
@import "featureModal/FeatureModal.scss";

// REPOS
@import "repo/Repo.scss";									// Repo page
@import "repo/RepoDetailsModal.scss"; 		// Edit repo modal
@import "repo/RepoImageSearchModal.scss"; // When add image in edit repo modal

// SCORESHEET
@import "scoreSheet/ScoreSheet.scss";

//PRINT PACKS: SET HANDOUT AND STUDENT REPORT;
@import "printPack/PrintPack.scss";

// ACCOUNT PAGE AND MODALS
@import "account/Account.scss";

//IMPORT QUESTIONS
@import "importQuestions/ImportQuestions.scss";

//PUBLISHING FLOW
@import "JoinCreatorProgramModal.scss";
@import "publishingFlow/PublishingFlow.scss";

// ACCOUNT PAGE AND MODALS
@import "account/Account.scss";

// REPORT AND DETAIL PAGES (SET REPORT/QUESTION REPORT/QUESTION DETAIL)
@import "reportAndDetailPages/SetReport.scss";
@import "reportAndDetailPages/QuestionReport.scss";
@import "reportAndDetailPages/SetDetail.scss";
@import "reportAndDetailPages/QuestionDetail.scss";

// MISC
@import "AuthorPage.scss"; 								// Author page for public creators
@import "ApplyOrderModal.scss"; 					// Seen when click group order link
@import "AutoPlaySetup.scss";  						// Help modal shown to users who browsers don't autoplay (see read-me)
@import "BackToSchoolModal.scss"; 				// Marketing modal for 2019 BTS
@import "ConfirmModal.scss"; 							// General confirmation modal used everywhere
@import "ConfirmEmailPage.scss"; 					// I think displayed when users clicks confirm email in email?
@import "ContextBadge.scss"; 							// Context badge is in top-right of navbar, showing a pill with upgrade, discount, or other messages.
@import "DowngradeModal.scss"; 						// Displayed when someone tries to downgrade
@import "HelpVideoModal.scss"; 						// Embedded youtube videos used in different places, works with HelpVideoThumbnail
@import "History.scss"; 									// aka Reports page
@import "ItemDetailHistoryList.scss"; 		// In item detail, we show if that item has history, and where
@import "ItemPreviewModal.scss"; 					// Modal triggered by tapping spacebar in Library
@import "LiveViewNotification.scss"; 			// Countdown modal that opens on web when start playing from mobile
@import "MobileUpdateNotification.scss"; 	// Q42021 special notification displayed if user needs to update their app
@import "MoveToFolderModal.scss"; 				// Move to folder modal (available from context menu in library)
@import "Notification.scss"; 							// General notification
@import "OnboardingWizard.scss"; 					// Displayed in bottom right on new accounts
@import "PageNotFoundLabel.scss"; 				// Small label used on pages when not found
@import "ProfilePic.scss"; 								// General profile pic (with no-image initials-based client-side fallback)
@import "ReauthenticationModal.scss";     // Reauthentication Modal used for security update
@import "ReleaseNotes.scss"; 							// Old pre-rich text what's-new/changelog modal (can be refactored out)
@import "SearchDropdown.scss"; 						// Search dropdown from topnav
@import "SearchResults.scss"; 						// Search results page
@import "SelectionModal.scss"; 						// Used for play-now section selection and move-to-repo repo selection
@import "DeleteAccountPage.scss"; 				// Delete account page
@import "VerifyPasswordToSigninModal.scss"; // Verify password modal used when signing in
@import "AddDataOwnerPage.scss";

// RELEASE MARKETING MODALS
@import "repoReleaseModal/RepoReleaseModal.scss"; 	// Can be refactored out later (it's still displayed to very old users bc private policy update)
@import "richTextMarketing/RichTextMarketing.scss"; // Rich text release marketing (still displayed as of Q12022 to all new users and old users)

// UPGRADE MODAL
@import "upgrade/Upgrade.scss";

// SET EDITOR
@import "editor/Editor.scss";										// Core Editor File (imports other files)
@import "editor/DevOutput.scss"; 								// Developer Output-Panel in Set Editor
@import "editor/ProseMirrorCSSOverrides.scss"; 	// The supplied ProseMirror CSS, with overrides
@import "editor/MathQuillCSSOverrides.scss"; 		// The supplied MathQuill CSS, with overrides

// SLIDE
@import "slide/Slide.scss";

// INTERNAL TOOLS - see readmes/INTERNALTOOLS.md for better description of below
@import "internalTools/UIKitTesting.scss";
@import "internalTools/creatorsList/CreatorsList.scss"; 		// Page with list of all creators and any outstanding payments
@import "internalTools/explorer/Explorer.scss"; 						// Admin search-and-filter page for all published repos
@import "internalTools/packReview/PackReview.scss"; 				// Tool to support pack review process
@import "internalTools/ScanHelper.scss";										// Card-based scan-helper
@import "internalTools/SyncScanHelper.scss";								// E-Learning scan-helper
@import "internalTools/FontSizeCalculationVisualizer.scss"; // Helps visualize and test Font Size calcalator
@import "internalTools/StaticSlideTest.scss"; 							// Static slide test page
@import "internalTools/SlideServices.scss";									// Slide service generator, see readmes/SLIDESERVICE.md
