//
// ADD EMAIL PASSWORD FORM
//

.addPassword-modal .modal-content{
  width: 600px;
  height: auto;
}

.addPassword{
  box-sizing: border-box;
  padding-left: 60px;
  padding-right: 60px;
  padding-top: 20px;

  display: flex;
  flex-direction: column;
  align-items: center;
}

.addPassword form{
  width: 100%;
}


.addPassword form button.btn{
  width: 100%;
}


.addPassword form .accountForm-actionRow button {
  width: 80%;
}
