// PRINT BUTTON

button.featureModal-primaryButton{
	width: 300px;
	position: absolute;
	bottom: 50px;
	left: 50px;
	z-index: 1000;

	cursor: pointer;

	height: 50px;
	background: $blue;
	border: none;

	display: flex;
	align-items: center;
	justify-content: center;

	font-weight: $lato-bold;
	font-size: 21px;
	color: $white;

	border-radius: 4px;
}


@media screen and (max-height: 620px) {
  button.featureModal-primaryButton{
    bottom: 25px;
  }
}


.featureModal-primaryButton-loadingSpinnerContainer{
	position: absolute;
	
	display: flex;
	align-items: center;
	justify-content: center;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
}

.featureModal-primaryButton-loadingSpinner{	
	height: 28px;
	width: 28px;
  
  border-radius: 14px;

  border: 3px rgba(26,28,34,0.25) solid;
  border-top: 3px $white solid;

  opacity: 0.5;

  animation: spinnerRotateAnimation .3s infinite linear;
}
