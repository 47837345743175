//
// SCORESHEET EMPTY/ON LOAD STATE
//

.scoreSheet-centerContainer-empty{
  width: 100%;
  height: 100%;
  background: rgb(255,255,255);

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  padding-bottom: 20vh;

  cursor: default;
  user-select: none;

  position: relative;
}

.scoreSheet-centerContainer-empty-h1{
  font-size: 50px;
  font-weight: $lato-bold;
  color: $textLight;
  opacity: 0.4;
  margin-bottom: 20px;
}

.scoreSheet-centerContainer-empty-h2{
  font-size: 20px;
  opacity: 0.75;
  font-weight: $lato-bold;
  color: $textMid;
}

// 
// KEYBOARD SHORTCUTS

.scoreSheet-centerContainer-empty-keyboardShortcuts{
  position: absolute;
  bottom: 10px;
  width: 300px;
  height: 170px;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0.75;
}


.scoreSheet-centerContainer-empty-keyboardShortcuts-label{
  text-align: center;
  margin-bottom: 16px;
  font-weight: $lato-bold;
  font-size: 15px;
  color: $textMid;
}


.scoreSheet-centerContainer-empty-keyboardShortcuts-shortcut{
  width: 100%;
  position: relative;
  box-sizing: border-box;  
  cursor: default;
  user-select: none;
  height: 40px;
}

.scoreSheet-centerContainer-empty-keyboardShortcuts-shortcut-right{
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: center;  
  border-bottom: 1px solid $lineDark;
}

.scoreSheet-centerContainer-empty-keyboardShortcuts-shortcut:last-child
.scoreSheet-centerContainer-empty-keyboardShortcuts-shortcut-right{
  border-bottom: 1px solid $invisible;
}

.scoreSheet-centerContainer-empty-keyboardShortcuts-shortcut-shortcut--right
.keyboardKey .icon.icon-key--arrowup{
  transform: rotate(90deg);
}

.scoreSheet-centerContainer-empty-keyboardShortcuts-shortcut-shortcut--left
.keyboardKey .icon.icon-key--arrowup{
  transform: rotate(270deg);
}

.scoreSheet-centerContainer-empty-keyboardShortcuts-shortcut-desc{
  font-size: 14px;
  font-weight: $lato-semibold;
  color: $textDark;  
  white-space: break-word;
  margin-right: auto;
}

.scoreSheet-centerContainer-empty-keyboardShortcuts-shortcut-shortcut{
  display: flex;
  margin-left: auto;
}