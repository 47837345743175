//
// SLIDE CHOICE
// 

$min-height-choice: 52px;
$fontSize-letterblock: 34px;
$lineHeight-letterblock: 1.45;


.slide-choice{	     
	text-align: left;
}

//

.slide .slide-choice,
.fontSizeChoicesArray .slide-choice{
	min-height: $min-height-choice;
	margin-bottom: 20px;
	position: relative;
}

.slide--editor .slide-choice--focused{
	z-index: 50; // so shortcut hint appears above
}

.fontSizeChoicesArray .slide-choice
.slide-choice-text{
	overflow-x: scroll !important;
	// allows us to measure if overflow to downscale font size
}


.slide-choices .slide-choice:last-child,
.fontSizeChoicesArray .slide-choice:last-child{
	margin-bottom: 0px; // new
}

//
// For Body Center Media Center, no margin on last two
.slide--template--bodyCenter .slide-choices .slide-choice:nth-child(n+3),
.slide--template--bodyCenterMediaCenter .slide-choices .slide-choice:nth-child(n+3),
.fontSizeChoicesArray--template--bodyCenter .slide-choice:nth-child(n+3),
.fontSizeChoicesArray--template--bodyCenterMediaCenter .slide-choice:nth-child(n+3){
	margin-bottom: 0px; // new
}



.slide.slide--template--bodyLeft
.slide-choice{
  width: 100%;
}

.slide.slide--template--bodyLeftMediaRight
.slide-choice{
  width: 100%;
}

.slide.slide--template--bodyRightMediaLeft
.slide-choice{
  width: 100%;
}

.slide.slide--template--bodyCenter .slide-choice,
.fontSizeChoicesArray--template--bodyCenter .slide-choice{
  width: 540px;
}

.fontSizeChoicesArray--template--bodyCenter .slide-choice:nth-child(n+3),
.fontSizeChoicesArray--template--bodyCenterMediaCenter .slide-choice:nth-child(n+3){
	margin-bottom: 0;
}

//

// REFACTOR TO TAKE ACCOUNT OF NUMBER OF IMAGES
.slide.slide--template--bodyCenterChoicesMedia .slide-choice,
.slide.slide--template--bodyLeftMediaRightChoicesMedia .slide-choice{
  width: 25%;
  height: 342px;
  margin-bottom: 0px;
}


//


.slide.slide--template--bodyCenterMediaCenter .slide-choice,
.fontSizeChoicesArray--template--bodyCenterMediaCenter .slide-choice{
  width: 540px;
}

//

.slide .slide-choice-letterblock,
.fontSizeChoicesArray .slide-choice-letterblock,{
	position: absolute;
	width: $min-height-choice;
	height: $min-height-choice;
	top: 0px;
	left: 0px;
	pointer-events: auto;
	z-index: 50; // make clickable
}


//
// THIS IS A BIT FUNKY

.slide-choice-content{
	height: 100%;
	width: 100%;
	position: relative;
}

.slide-choice-mediaContainer{
	position: absolute;
	top: -2px;
	left: 2px;
	right: 2px;
	bottom: 58px; // refactor
}




// hide choice media containers for text-choice
.slide.slide--template--bodyLeft .slide-choice-mediaContainer,
.slide.slide--template--bodyLeftMediaRight .slide-choice-mediaContainer,
.slide.slide--template--bodyRightMediaLeft .slide-choice-mediaContainer,
.slide.slide--template--bodyCenterMediaCenter .slide-choice-mediaContainer,
.slide.slide--template--bodyCenter .slide-choice-mediaContainer{
  display: none; 
}

// hide text container if media choices
.slide.slide--template--bodyCenterChoicesMedia .slide-choice-text,
.slide.slide--template--bodyLeftMediaRightChoicesMedia .slide-choice-text{
  display: none;
}

// hide remove choice button if media, remove is in empty state
.slide.slide--template--bodyCenterChoicesMedia .slide-choice-deleteBtn,
.slide.slide--template--bodyLeftMediaRightChoicesMedia .slide-choice-deleteBtn{
  display: none; 
}

//
//

.slide-choice-text{
	position: relative;

	font-size: inherit;
	font-weight: 700; // bold
	color: rgb(0,0,0);
	box-sizing: border-box;
	background: rgba(0,0,0,0);
	padding-left: 8px;
	//padding-top: 3px; I'm not sure we need this?
	//padding-bottom: 3px; I'm not sure we need this?
	border: 1px solid rgba(0,0,0,0);
	
	white-space: pre-wrap;
	padding-right: 2px;
	word-wrap: break-word;
	overflow: hidden;
	letter-spacing: 0.01em;

	width: calc(100% - 60px);
	// margin-left: 60px; old
	left: 60px; // experimental, solves cursor issues potentially on letterblock
	min-height: $min-height-choice;
}

// MEDIA CHOICES

.slide.slide--template--bodyCenterChoicesMedia .slide-choice-letterblock,
.slide.slide--template--bodyLeftMediaRightChoicesMedia .slide-choice-letterblock{
	position: absolute;
	width: $min-height-choice;
	height: $min-height-choice;
	top: initial;
	left: calc(50% - (#{$min-height-choice}/2));
	bottom: 0px;
	// pointer-events: auto; // not sure why this is here
	z-index: 100; // make clickable
}

// NOW PLAYING LOWER OPACITY IF INCORRECT
// might need to fix
.slide-choice.slide-choice--showCorrect.slide-choice--incorrect.slide-choice--scheme1.slide-choice--notAsk.slide-choice--notScan{
	opacity: 0.5;
}

//
// EDITOR
//

// placeholder - will need a refactor
// PLACEHOLDER STYLING - probably needs refactor
.slide.slide--editor .slide-choice-text-placeholder {
  color: rgb(198, 198, 212);
}

.slide--editor .slide-choice-text {
	cursor: text;
	white-space: pre-wrap !important; // for prosemirror firefox support  
}

.slide--editor:hover .slide-choice .slide-choice-text {
	border-color: $lineLight;
}

.slide--editor .slide-choice:hover .slide-choice-text {
	border-color: $lineMid;	
}

.slide--editor .slide-choice .slide-choice-text:hover {
	border-color: $gray-11;	
}

.slide--editor .slide-choice.slide-choice--focused .slide-choice-text{
	border-color: $blue;
	outline: none;
	box-shadow: none;
}

@import 'slide-Choice-LetterBlock';
@import 'slide-Choice-DeleteBtn'; // in editor
@import 'slide-Choice-AddMediaBtn'; // in editor


@import 'slide-Choice-Media'; // media choices

@import 'slide-Choice-Graph'; // reseponse Graph

@import 'slide-Choice-ShortcutHints'; // tooltips