//
// PAGE HEADER - SECTION HOME PAGE LINK TRAY
// these are the icons in the top-right of section home
// I guess they should have labels for clarity, this was very difficult to get right
//

.pageHeaderTop--sectionHome
.pageHeader--sectionHome-linkTray,
.pageHeaderSticky--sectionHome
.pageHeader--sectionHome-linkTray{
  margin-left: auto;
  margin-right: -12px;
  display: flex;
}

.pageHeader--sectionHome-linkTray-link{
  width: 44px;
  height: 44px;

  display: flex;
  justify-content: center;
  align-items: center;

  position: relative;
  cursor: pointer;
}

.pageHeader--sectionHome-linkTray-link .icon.history{
  height: 23px;
  width: 23px;
}

.pageHeader--sectionHome-linkTray-link .icon.student{
  height: 28px;
  width: 28px;
}

.pageHeader--sectionHome-linkTray-link .icon.settings{
  height: 23px;
  width: 23px;
}

.pageHeader--sectionHome-linkTray-link-bg{
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;

  border-radius: 4px;

  opacity: 0;
}

.pageHeader--sectionHome-linkTray-link:hover
.pageHeader--sectionHome-linkTray-link-bg{
  opacity: 0.04;
}


// ON HOVER, FADE OUT OTHER OPTIONS
.pageHeader--sectionHome-linkTray:hover
.pageHeader--sectionHome-linkTray-link{
  opacity: 0.5;
}

.pageHeader--sectionHome-linkTray
.pageHeader--sectionHome-linkTray-link:hover{
  opacity: 1;
}


// OVERWRITE TOOLTIPS TRANSITION DELAY SO SHOWS IMMEDIATELY
.pageHeader--sectionHome-linkTray
[data-tooltip]:hover:before,
.pageHeader--sectionHome-linkTray
[data-tooltip]:hover:after {
  transition-delay: 0ms;
}

//
// E-LEARNING BADGE
// displayed if a class is e-learning enabled

.pageHeader--sectionHome-linkTray-eLearningBadge{
  display: flex;
  align-items: center;
  justify-content: center;
  //background: $tempr;
  height: 44px;
  padding-right: 8px;
  padding-left: 4px;

  user-select: none;
  cursor: pointer;
}

.pageHeader--sectionHome-linkTray-eLearningBadge-badge{
  color: $white;
  font-size: 15px;
  letter-spacing: 0.01em;
  font-weight: $lato-heavy;
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 2px;
  padding-bottom: 2px;
  border-radius: 2px;
}


//
// CLASS COLOR
//

// ICONS
.pageHeader--sectionHome-linkTray.has--classcolor-blue			.icon		{ fill: $classColor-blue; }
.pageHeader--sectionHome-linkTray.has--classcolor-purple		.icon		{ fill: $classColor-purple; }
.pageHeader--sectionHome-linkTray.has--classcolor-red				.icon		{ fill: $classColor-red; }
.pageHeader--sectionHome-linkTray.has--classcolor-orange		.icon		{ fill: $classColor-orange; }
.pageHeader--sectionHome-linkTray.has--classcolor-green			.icon		{ fill: $classColor-green; }
.pageHeader--sectionHome-linkTray.has--classcolor-sky				.icon		{ fill: $classColor-sky; }
.pageHeader--sectionHome-linkTray.has--classcolor-pink			.icon		{ fill: $classColor-pink; }
.pageHeader--sectionHome-linkTray.has--classcolor-teal			.icon		{ fill: $classColor-teal; }
.pageHeader--sectionHome-linkTray.has--classcolor-grey			.icon		{ fill: $classColor-grey; }

// LINK BG
.pageHeader--sectionHome-linkTray.has--classcolor-blue			.pageHeader--sectionHome-linkTray-link-bg		{ background: $classColor-blue; }
.pageHeader--sectionHome-linkTray.has--classcolor-purple		.pageHeader--sectionHome-linkTray-link-bg		{ background: $classColor-purple; }
.pageHeader--sectionHome-linkTray.has--classcolor-red				.pageHeader--sectionHome-linkTray-link-bg		{ background: $classColor-red; }
.pageHeader--sectionHome-linkTray.has--classcolor-orange		.pageHeader--sectionHome-linkTray-link-bg		{ background: $classColor-orange; }
.pageHeader--sectionHome-linkTray.has--classcolor-green			.pageHeader--sectionHome-linkTray-link-bg		{ background: $classColor-green; }
.pageHeader--sectionHome-linkTray.has--classcolor-sky				.pageHeader--sectionHome-linkTray-link-bg		{ background: $classColor-sky; }
.pageHeader--sectionHome-linkTray.has--classcolor-pink			.pageHeader--sectionHome-linkTray-link-bg		{ background: $classColor-pink; }
.pageHeader--sectionHome-linkTray.has--classcolor-teal			.pageHeader--sectionHome-linkTray-link-bg		{ background: $classColor-teal; }
.pageHeader--sectionHome-linkTray.has--classcolor-grey			.pageHeader--sectionHome-linkTray-link-bg		{ background: $classColor-grey; }

// ELEARNING BADGE
.pageHeader--sectionHome-linkTray.has--classcolor-blue			.pageHeader--sectionHome-linkTray-eLearningBadge-badge		{ background: transparentize($classColor-blue, 0.92); }
.pageHeader--sectionHome-linkTray.has--classcolor-purple		.pageHeader--sectionHome-linkTray-eLearningBadge-badge		{ background: transparentize($classColor-purple, 0.92); }
.pageHeader--sectionHome-linkTray.has--classcolor-red				.pageHeader--sectionHome-linkTray-eLearningBadge-badge		{ background: transparentize($classColor-red, 0.92); }
.pageHeader--sectionHome-linkTray.has--classcolor-orange		.pageHeader--sectionHome-linkTray-eLearningBadge-badge		{ background: transparentize($classColor-orange, 0.92); }
.pageHeader--sectionHome-linkTray.has--classcolor-green			.pageHeader--sectionHome-linkTray-eLearningBadge-badge		{ background: transparentize($classColor-green, 0.92); }
.pageHeader--sectionHome-linkTray.has--classcolor-sky				.pageHeader--sectionHome-linkTray-eLearningBadge-badge		{ background: transparentize($classColor-sky, 0.92); }
.pageHeader--sectionHome-linkTray.has--classcolor-pink			.pageHeader--sectionHome-linkTray-eLearningBadge-badge		{ background: transparentize($classColor-pink, 0.92); }
.pageHeader--sectionHome-linkTray.has--classcolor-teal			.pageHeader--sectionHome-linkTray-eLearningBadge-badge		{ background: transparentize($classColor-teal, 0.92); }
.pageHeader--sectionHome-linkTray.has--classcolor-grey			.pageHeader--sectionHome-linkTray-eLearningBadge-badge		{ background: transparentize($classColor-grey, 0.92); }

.pageHeader--sectionHome-linkTray.has--classcolor-blue			.pageHeader--sectionHome-linkTray-eLearningBadge-badge		{ color: $classColor-blue; }
.pageHeader--sectionHome-linkTray.has--classcolor-purple		.pageHeader--sectionHome-linkTray-eLearningBadge-badge		{ color: $classColor-purple; }
.pageHeader--sectionHome-linkTray.has--classcolor-red				.pageHeader--sectionHome-linkTray-eLearningBadge-badge		{ color: $classColor-red; }
.pageHeader--sectionHome-linkTray.has--classcolor-orange		.pageHeader--sectionHome-linkTray-eLearningBadge-badge		{ color: $classColor-orange; }
.pageHeader--sectionHome-linkTray.has--classcolor-green			.pageHeader--sectionHome-linkTray-eLearningBadge-badge		{ color: $classColor-green; }
.pageHeader--sectionHome-linkTray.has--classcolor-sky				.pageHeader--sectionHome-linkTray-eLearningBadge-badge		{ color: $classColor-sky; }
.pageHeader--sectionHome-linkTray.has--classcolor-pink			.pageHeader--sectionHome-linkTray-eLearningBadge-badge		{ color: $classColor-pink; }
.pageHeader--sectionHome-linkTray.has--classcolor-teal			.pageHeader--sectionHome-linkTray-eLearningBadge-badge		{ color: $classColor-teal; }
.pageHeader--sectionHome-linkTray.has--classcolor-grey			.pageHeader--sectionHome-linkTray-eLearningBadge-badge		{ color: $classColor-grey; }
