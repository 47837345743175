//
// SLIDE
// both static slide and editor slide styling
//

.slide{
	width: 1280px;
	height: 800px;

	border: 1px solid rgb(180,180,200);
	
	background: $white;

	padding: 60px;
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	background: white;

	position: relative;

	transform-origin: top left;
}

// HIDE SLIDE IF INACTIVE

.slide--editor--hidden{
	content-visibility: hidden; // this improves performance
	display: none;
	user-select: none !important;
}

.editor-canvas .slide{
	background: $white;
	
	border-radius: 5px;	

	border: 1px solid $lineDark;
	border-bottom: 1px solid darken($lineDark, 8%);

	box-shadow: 0px 1px 2px 0px rgba(0,0,40,0.08);

	cursor: default;
}

// Paragraph - matches legacy behaviour I think
.slide p,
.slide-body p{
  margin-block-start: 0px !important;
  margin-block-end: 0px !important;
}

.richTextEditorContainer-outputContainer-slideContainer--large
.slide{
	box-shadow: 0px 6px 12px 0px rgba(0,0,80,0.08);
	border-radius: 5px;
}

@import 'slide-Body';
@import 'slide-Media';
@import 'slide-Choices';
@import 'slide-Choice';
@import 'slide-MathsAndScience'; 
@import 'slide-RichTextFormatting';

//
// SELECTION HACKS
//

// Math Selection we add a class for this
.slide.slide--editor .slide-body.slide-body--focused span.katex-container *::selection,
.slide.slide--editor .slide-choice.slide-choice--focused span.katex-container *::selection{
  background: $invisible !important;
  // katex-container-selected covers all of our bases
}

$defaultSelectionBackgroundColor: rgba(0,116,255,0.29);

.slide-choice-text::selection,
.slide-choice-text *::selection {
	// background: $tempr !important;
	background: $invisible !important;
}

.slide-body::selection,
.slide-body *::selection {
	background: $invisible !important;
}

.slide-body.slide-body--focused::selection,
.slide-body.slide-body--focused *::selection{
	background: $defaultSelectionBackgroundColor !important;
}

.slide-choice--focused .slide-choice-text::selection,
.slide-choice--focused .slide-choice-text *::selection{
	background: $defaultSelectionBackgroundColor !important;
}

.slide-choice-text-placeholder{
	pointer-events: none;
}

//
// FOR FONT-SIZE-CALCULATOR
// add a small character to body and choices to act as a buffer
.fontSizeCalculator-ghostArrays-bodyArrayContainer
.slide-body p:last-child:after{
	content: "–" !important;
}
 
.fontSizeCalculator-ghostArrays-choiceArrayContainer
.slide-choice-content:after {
	content: "–" !important;
}

