//
// TOP NAV
// this is simple bar with searchbar at top
// searchbar dropdown styling is contained in SearchDropdown.scss
//

$maxWidth-searchBar: 380px; // was 420px, reduced for discount period (contextBadge is larger)

.topNav{
	position: fixed;
	left: 0px;
	top: 0px;
	width: 100vw;

	height: $height-topNav;
	background: $color-pageBG;
	border-bottom: $border-navBar;
	box-shadow: $shadow-navBar;

	z-index: 500;

	display: flex;
	box-sizing: border-box;
	align-items: center;
}

.topNav-left{
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: flex-end;

	padding-right: 15px; // intentionally not border box
}

.topNav a:focus {
	outline: none;
}

.topNav-plickersLogo{
	height: $height-topNav;
	width: $height-topNav;

	display: flex;
	align-items: center;
	justify-content: center;
	margin-right: 8px;
	margin-left: 25px;

	overflow: hidden;
}

@media (max-width: 1200px) {
	.topNav-plickersLogo{
		margin-right: 4px;
	}
}

@media (max-width: 1109px) {
	.topNav-plickersLogo{
		display: none;
	}
}

.topNav-plickersLogo .icon{
	width: 28px;
}

.topNav-center{
	height: 100%;
	display: flex;
	align-items: center;
}

.topNav button.btn.btn--white.btn--mid.now-playing{
	margin-right: -20px;
	font-weight: $lato-bold;
	width: 120px;
	flex-grow: 1;
	max-width: 150px;
}

.topNav button.btn.btn--white.btn--mid .icon.now-playing {
	width: 15px;
	height: 15px;
	margin-top: 1px;
}

.topNav input.topNav-search {
	position: absolute;
	top: 9px;
	width: $maxWidth-searchBar;
	height: 30px;
	background: $white;
	border-radius: 3px;
	border: 1px solid $gray-1225;
	margin-left: 12px;
	box-sizing: border-box;
	font-size: 14px;
	padding-left: 8px;
	padding-bottom: 1px;
	font-weight: $lato-semibold;
	color: $textBlackDark;
}

.topNav input.topNav-search::-webkit-input-placeholder{
	color: $textLight;
}

.topNav input.topNav-search:focus::-webkit-input-placeholder{
	color: $textInputPlaceholder;
}

.topNav input.topNav-search:focus{
	outline: none;
	box-shadow: none;
	border-color: $gray-12;
}

.react-autosuggest__container--open{
	border-bottom-left-radius: 0px;
	border-bottom-right-radius: 0px;
	border-color: $gray-11;
}

.topNav-right{
	margin-left: auto;
	padding-right: 24px;

	display: flex;
	align-items: center;
	justify-content: fletx-end;
	box-sizing: border-box;
}

.topNav-right .btn.account{
	min-width: 75px;
	width: auto;
	flex-shrink: 0;
	margin-left: 4px;
	padding-left: 14px;
	padding-right: 14px;
	font-weight: $lato-bold;
}

.topNav-right .btn.account svg {
  padding-right: 3px;
  margin-left: -3px;
}

.topNav-right button.btn--white.btn--mid.helpDropdown{
	width: 65px;
	margin-left: 4px;
}

.topNav-right button.btn--white.btn--mid.helpDropdown svg.icon{
	fill: $gray-7;
	margin-left: 4px;
	margin-right: -4px;
	margin-top: 2px;
	width: 9px;
	height: 9px;
}

.topNav-right button.btn--white.btn--mid.helpDropdown:hover svg.icon{
	fill: $blue;
}

.topNav-right .dropdownMenu.position--left{
	left: -120px;
}

.topNav-right .btn.updates{
	min-width: 100px;
	width: 100px;
	margin-left: 4px;
}

// hide on narrow screens
@media screen and (max-width: 1080px) {
  .topNav-right .btn.updates {
    display: none;
  }
}
