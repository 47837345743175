//
// MEMBER ROW
//

$repoInvitationModal-row-leftWidth: 78px;
$repoInvitationModal-row-rightWidth: 120px;

$repoInvitationModal-row-centerWidth: calc(#{$repoInvitationModal-width} - #{$repoInvitationModal-row-leftWidth} - #{$repoInvitationModal-row-rightWidth}) ;


.repoInvitationModal-permissionsList-permissionRow{
	width: 100%;
	height: 64px;
	display: flex;
	cursor: default;
	position: relative;
}

.repoInvitationModal-permissionsList-permissionRow--left{
	height: 100%;
	
	flex-grow: 0;
	flex-shrink: 0;
	
	display: flex;
	justify-content: flex-end;
	align-items: center;

	box-sizing: border-box;

	padding-left: 30px;

	width: $repoInvitationModal-row-leftWidth;

	padding-right: 12px;
}

.repoInvitationModal-permissionsList-permissionRow--center{
	height: 100%;

	flex-grow: 0;
	flex-shrink: 0;

	white-space: nowrap;
  	overflow: hidden;
  	text-overflow: ellipsis;

  	width: $repoInvitationModal-row-centerWidth;
  
	// display: flex;
	// align-items: center;

	// // background: $tempb;
}

.repoInvitationModal-permissionsList-permissionRow--right{
	position: absolute;
	top: 0px;
	left: calc(#{$repoInvitationModal-width} - #{$repoInvitationModal-row-rightWidth}) ;
	height: 64px;
	width: $repoInvitationModal-row-rightWidth;

	box-sizing: border-box;
	
	display: flex;
	align-items: center;


	// background: $tempb;
}


.repoInvitationModal-permissionsList-permissionRow--center,
.repoInvitationModal-permissionsList-permissionRow--right{
	border-bottom: 1px solid $lineMid;
}


// add padding top of list

.repoInvitationModal-permissionsList-permissionRow:nth-child(1) {
	margin-top: 5px;
}

// add padding bottom of list
.repoInvitationModal-permissionsList-permissionRow:last-child {
	margin-bottom: 5px;
}

// hide border for last invite
.repoInvitationModal-permissionsList-permissionRow:last-child .repoInvitationModal-permissionsList-permissionRow--center,
.repoInvitationModal-permissionsList-permissionRow:last-child .repoInvitationModal-permissionsList-permissionRow--right{
	border-bottom: 1px solid $white !important;
	
}




// Left - Profile Pic


// .repoInvitationModal-permissionsList-permissionRow--pending img.repoInvitationModal-permissionsList-permissionRow-profilePic,
// .repoInvitationModal-permissionsList-permissionRow--pending .repoInvitationModal-permissionsList-permissionRow-profilePicFallback{
// 	opacity: 0.5;
// }


// Center - Name and information
.repoInvitationModal-permissionsList-permissionRow-headLine{
	font-size: 16px;
	font-weight: $lato-bold;
	color: $textBlack;
	letter-spacing: 0.01em;

	margin-top: 14px;
	margin-bottom: 1px;

	white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.repoInvitationModal-permissionsList-permissionRow-headLine--noSubLine{
	margin-top: 21px;
}


.repoInvitationModal-permissionsList-permissionRow-subLine{
	font-size: 15px;
	font-weight: $lato-medium;
	color: $textLight;
	letter-spacing: 0.008em;

	white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.repoInvitationModal-permissionsList-permissionRow-subLine b {
	font-weight: $lato-medium;
	color: $textMid;
}


// Right - Edit Button

.repoInvitationModal-permissionsList-permissionRow-editBtn{
	width: 120px;
	box-sizing: border-box;
	padding-right: 32px;

	cursor: pointer;

	height: 100%;
	
	color: $textMid;	
	text-align: right;
	height: 55px;

	font-size: 15px;

	line-height: 3.6;

	user-select: none;


}

.repoInvitationModal-permissionsList-permissionRow-editBtn:hover{
	color: $blue;
}

.repoInvitationModal-permissionsList-permissionRow-editBtn .icon{
	width: 11px;
	height: 11px;
	position: relative;
	top: 1px;
	left: 5px;
	fill: $textMid;
}

.repoInvitationModal-permissionsList-permissionRow-editBtn:hover .icon{
	fill: $blue;
}

//

.repoInvitationModal-permissionsList-permissionRow .dropdown{
	z-index: initial !important;
}

.repoInvitationModal-permissionsList-permissionRow .dropdownMenu{
	width: 285px;
	left: calc(50vw - 45px);
}

// .repoInvitationModal-permissionsList-permissionRow .dropdownMenu.direction--down{
// 	top: 43px;
// }

// .repoInvitationModal-permissionsList-permissionRow .dropdownMenu.direction--up{
// 	bottom: 40px;
// }


// custom stuff, add bold
.repoInvitationModal-permissionsList-permissionRow .dropdownMenu-item.is--selectItem.is--active{
	font-weight: $lato-bold;
	color: $textBlue;
}

.repoInvitationModal-permissionsList-permissionRow .dropdownMenu-item.is--selectItem.is--active:hover{	
	color: $white;
}

.repoInvitationModal-permissionsList-permissionRow .dropdownMenu-item.is--selectItem.is--active
.dropdownMenu-item--multiline-sublabel{
	font-weight: $lato-medium; //inherit original
	color: transparentize($textMid, 0.1);
}

.repoInvitationModal-permissionsList-permissionRow .dropdownMenu-item.is--selectItem.is--active:hover
.dropdownMenu-item--multiline-sublabel{
	color: rgba(255,255,255,0.9); // inherit original
}


//

.repoInvitationModal-permissionsList-permissionRow-editBtn--isYou{
	padding-right: 42px;
}

.repoInvitationModal-permissionsList-permissionRow-editBtn--isYou:hover{	
	color: $textMid;
	cursor: default;
}

.repoInvitationModal-permissionsList-permissionRow--pending
.repoInvitationModal-permissionsList-permissionRow-editBtn{
	opacity: 0.5;
}

.repoInvitationModal-permissionsList-permissionRow--pending
.repoInvitationModal-permissionsList-permissionRow-editBtn:hover{
	opacity: 1;
}
