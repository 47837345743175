.editor-canvas{
	background: rgb(240,237,243);
	box-sizing: border-box;
	padding-top: $height-editor-canvasTopPadding;	
	padding-bottom: $height-editor-canvasBottomPadding;	

	display: flex;
}

// Question Editor
.editor-canvas--questionEditor{
	justify-content: center;
}

.editor-canvas-right{

	height: 100%;

	display: flex;
	align-items: top;
}

.editor-canvas-right-insertBarContainer{
	height: 100%;
	width: 100%;

	display: flex;
	align-items: center;

	width: 66px;
	flex-shrink: 0;
	flex-grow: 0;
}

.editor-canvas-right-editMediaBarContainer{
	height: 100%;
	width: 100%;

	display: flex;
	align-items: flex-start;

	width: 66px;
	flex-shrink: 0;
	flex-grow: 0;
}


.editor-canvas-slideInnerContainer{
	transform-origin: top left;
}



//sidePnael
.editor-canvas-sidePanelContainer{
	height: 100%;
	width: 100%;
	//background: $tempr;

	box-sizing: border-box;
	padding-left: 8px;
	padding-right: 12px;

	padding-top: 14px;
	padding-bottom: 14px;


}

.editor-canvas-sidePanel{
	width: 100%;
	height: 100%;

	border: 1px solid $lineDark;
	border-bottom: 1px solid darken($lineDark, 8%);
	//background: $white;
	background: rgb(254,254,255);
	box-shadow: 0px 1px 2px 0px rgba(0,0,40,0.08);
	
	border-radius: 4px;
		
	box-sizing: border-box;

	overflow: hidden;
}


//
// CANVAS GHOST USED FOR SIZING


.editor-canvasGhost{
	background: $tempg;

	display: flex;
	bottom: calc(#{$height-editor-canvasTopPadding} + #{$height-editor-canvasBottomPadding}); // overrides for height from parent css
	
	visibility: hidden; //need to look at this
}

// NOW DONE IN JS

// .editor-canvasGhost-leftSpacer{
// 	height: 100%;
// 	width: calc(#{$width-editor-slideList} + #{$height-editor-sideSpacerPadding});
// 	background: $tempg;
// }

// .editor-canvasGhost-slideContainer{
// 	width: 10px;
// 	flex-grow: 1;
// 	flex-shrink: 1;
// 	height: 100%;
// 	background: $tempr;
// }

// .editor-canvasGhost-rightSpacer{
// 	height: 100%;
// 	width: calc(#{$width-editor-slideList} + #{$height-editor-sideSpacerPadding});
// 	background: $tempb;	
// }

