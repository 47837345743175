

//
//

@import 'Repo-InvitationBanner';
@import 'Repo-InvitationModal';
@import 'Repo-DefaultPreviewPanel';
@import 'Repo-EmptyStateHelpVideo';
@import 'Repo-SocialMediaShareRow';
@import 'Repo-ReviewPublishedRepoModal';
@import 'Repo-SubmitEarlyReleaseApprovalModal';
@import 'Repo-InlineRatingPanel';

.repo-content{	
	position: relative; // used for aligning the pending-cover
}

.repo-content.repo-content--repoBase{
	margin-top: 20px;	
}

.repo.repo--publicConsumer .repo-content{
	margin-top: 10px;
}

.repo-table--container{
	// nothing here, used for keypress
}

.repo-table{
	width: 100%;
	box-sizing: border-box;
}

.repo-table--shopWindow{
	pointer-events: none;
	margin-top: 30px;
	max-height: calc(100vh - 350px); // bit messy, sorry
	overflow: hidden;
}

.repo-table:focus{
	outline: none;
}
// don't show focus ring

.repo-table-emptyState{
	width: 100%;
	margin-top: 60px;
	padding-bottom: 120px;
	text-align: center;
	font-size: 20px;
	font-weight: $lato-bold;
	color: $gray-10;
	letter-spacing: 0.005em;
	user-select: none;
	cursor: default;
}


///

.repo-content-pendingInvitePageCover{
	// maybe a bit messy, consider

	background: $white;

	position: absolute;
	z-index: 1000;
	top: 0px;
	left: -50px; // ensure full coverage
	right: -20px;
	bottom: -20px;

	user-select: none !important; // not sure if strictly necessary
	pointer-events: none !important; // not sure if strictly necessary

	background: linear-gradient(to bottom, rgba(255,255,255,0) 0%, rgba(255,255,255,0.6) 100px, rgba(255,255,255,1) 350px); 
}

// Viewer Preview Panel

.repoViewerPreviewPanel{
	display: flex;
	flex-direction: column;
	justify-content: flex-end;

	height: 100%;
	width: 100%;
	position: relative;

	box-sizing: border-box;

	padding-bottom: 12px;


	cursor: default;
	opacity: 0.9;
	transition: opacity 100ms linear;
}

.repoViewerPreviewPanel:hover{
	opacity: 1;
	transition: opacity 100ms linear;
}

.repoViewerPreviewPanel-viewAccessMessage{
	//background: $tempr;
	transform: translateY(60px);
	padding-top: 150px; // provides space to hover onto
	transition: transform 200ms cubic-bezier(0.08, 0.52, 0.52, 1);
	transition-delay: 50ms;
}

.repoViewerPreviewPanel-viewAccessMessage-label,
.repoViewerPreviewPanel-viewAccessMessage-link{
	opacity: 0;
	transition: opacity 200ms cubic-bezier(0.08, 0.52, 0.52, 1);
}

.repoViewerPreviewPanel-viewAccessMessage:hover .repoViewerPreviewPanel-viewAccessMessage-label,
.repoViewerPreviewPanel-viewAccessMessage:hover .repoViewerPreviewPanel-viewAccessMessage-link{
	opacity: 1;
	transition: opacity 200ms cubic-bezier(0.08, 0.52, 0.52, 1);
	transition-delay: 50ms;
}


.repoViewerPreviewPanel-viewAccessMessage:hover{
	transform: translateY(0px);
	transition: transform 200ms cubic-bezier(0.08, 0.52, 0.52, 1);
}

.repoViewerPreviewPanel-viewAccessMessage-title{
	font-weight: $lato-bold;
	font-size: 14px;
	margin-bottom: 6px;
	color: $textBlack;
}

.repoViewerPreviewPanel-viewAccessMessage-title .icon.lock{
	width: 14px;
	height: 14px;
	fill: $gray-8;
	margin-right: 4px;
	position: relative;
	top: 1px;
}

.repoViewerPreviewPanel-viewAccessMessage-label,
a.repoViewerPreviewPanel-viewAccessMessage-link{
	font-weight: $lato-medium;
	font-size: 12.5px;
	margin-bottom: 3px;	
	color: $textDark;
}

a.repoViewerPreviewPanel-viewAccessMessage-link{
	color: $textBlue;
	margin-top: 3px;
}

.repoViewerPreviewPanel
a.repoViewerPreviewPanel-viewAccessMessage-link:hover{
	text-decoration: underline !important;
}


//
// REPO PAGE LOADING
//

.repoPageLoader,
.repoPageLoader *{
	pointer-events: none !important;
	cursor: default;
}

.repoPageLoader .sortableColumnHeaders-name,
.repoPageLoader .sortableColumnHeaders-modified{
	color: $invisible;
}

@keyframes repoPageHeaderLoaderFade {
  from { opacity: 1; }
  to { opacity: 0.5; }
}

.repoPageLoader .pageHeaderTop{	
	animation: repoPageHeaderLoaderFade 1s infinite linear;
	animation-direction: alternate;
}