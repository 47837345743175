.featureModalContainer{
	position: fixed;
	left: 0;
	top: 0;
	bottom: 0;
	right: 0;
	z-index: 5000;
	display: flex;
	align-items: center;
	justify-content: center;

	overflow: hidden;
}

$reportPackBuilder-topMargin: 32px;
$reportPackBuilder-sideMargin: 40px;

.featureModal{

	width: calc(100vw - #{$reportPackBuilder-sideMargin} - #{$reportPackBuilder-sideMargin});
	max-width: 1200px;

	height: calc(100vh - #{$reportPackBuilder-topMargin} - #{$reportPackBuilder-topMargin});
	max-height: 680px;

	background: rgb(252,252,255);
	overflow: hidden;
	position: relative;
	z-index: 10;
	
	border-radius: 10px;
	
	background: $onboarding-bgColor;
	border: 1px solid $gray-5;
	box-shadow: 
		0px 8px 16px 0px rgba(5,5,10,0.12),
		0px 16px 32px 0px rgba(5,5,10,0.08) ;

	display: flex;

}

//
// for small screen-heights
//

@media screen and (max-height: 670px) {
  .featureModal{
    height: calc(100vh - 22px);
  }
}



.featureModalOverlay{
	position: absolute;
	left: 0;
	right: 0;
	bottom: 0;
	top: 0;
	background: rgba(0,0,3,0.75);
	overflow: hidden;
}

.featureModal-left{
	// background: $tempr;
	width: 440px;
	flex-grow: 0;
	flex-shrink: 0;
}


.featureModal-previewContainer{
	// background: $tempb;
	width: 10px;
	flex-grow: 1;
	flex-shrink: 1;
	overflow-y: scroll;
	position: relative;
	overflow-x: hidden;
	padding-bottom: 60px;
}


.featureModal-preview{	
	transform: scale(0.75); // will have to do this properly
	transform-origin: top left;
	margin-left: 24px;
	margin-top: 50px;

	width: 100%;
	height: 75%;

	box-sizing: border-box;
	position: relative;
}

