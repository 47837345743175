// SHOULD REFACTOR THESE INTO BUTTON SCSS 

.sectionHome-eLearningBeta,
.sectionHome-upNext-actionList,
.sectionHome-inProgress-actionRow,
.sectionHome-upcoming-upcomingCard-actionRow{

	//targeting default
	button.btn.btn--mid.btn--white{
		background: none;
		border: 1px solid $invisible;
		box-shadow: none;

		color: rgb(54, 120, 250);
		font-weight: $lato-bold;

		justify-content: flex-start;
	
	}	

	button.btn.btn--mid.btn--white .icon{
		fill: $textBlue;
	}
	

	button.btn.btn--mid.btn--white:hover{
		background: rgb(247, 250, 255);		
		color: rgb(45, 110, 240);
		
	}

	button.btn.btn--mid.btn--white:active{
		background: rgb(242, 245, 255);	
	}

	button.btn.btn--mid.btn--white{
		opacity: 0.8;
	}

	button.btn.btn--mid.btn--white:hover{
		opacity: 1;
	}

	button.btn.btn--mid.btn--white.play-now,
	button.btn.btn--mid.btn--white.resume-playing{
		opacity: 1;
	}

}

.sectionHome-upNext-actionList,
.sectionHome-inProgress-actionRow {
	button.btn.btn--mid.btn--white{
		font-size: 16.5px;
		height: 32px;	
		min-width: 175px;
		width: 175px;
		cursor: pointer;
	}	

	button.btn.btn--mid.btn--white .icon{
		width: 15px;
		height: 15px;
		margin-right: 6px;		
	}	
	

	button.btn.btn--mid.btn--white .icon.compose{
		width: 16px;
		height: 16px;
		position: relative;
		top: -1px;
		margin-right: 4px;
	}

}

.sectionHome-eLearningBeta,
.sectionHome-upcoming-upcomingCard-actionRow{
	button.btn.btn--mid.btn--white{
		font-size: 15px;
		height: 32px;	
		cursor: pointer;
	}	

	button.btn.btn--mid.btn--white .icon{
		width: 14px;
		height: 14px;
		margin-right: 4px;		
	}	

	button.btn.btn--mid.btn--white .icon.compose{
		width: 15px;
		height: 15px;
		position: relative;
		top: -1px;
	}
}




.sectionHome-upNext-activeItemPreview-actionList
button.btn.btn--mid.btn--white.play-now .icon{
	margin-left: -1px;
	margin-right: 6px;
}

.sectionHome-inProgress-actionRow {
	button.btn.btn--mid.btn--white{
		width: 135px;
		min-width: 135px;		
}
}
	button.btn.btn--mid.btn--white.mark-done{
		width: 110px;
		min-width: 110px;
	}


// .sectionHome-upcoming-itemList.sectionHome-upcoming-itemList--pressed
// .sectionHome-upcoming-upcomingCard:not(:hover)
// .sectionHome-upcoming-upcomingCard--right-actionRow
// button.btn.btn--mid.btn--white{
// 	color: $textDark;
// }

// .sectionHome-upcoming-itemList.sectionHome-upcoming-itemList--pressed
// .sectionHome-upcoming-upcomingCard:not(:hover)
// .sectionHome-upcoming-upcomingCard--right-actionRow
// button.btn.btn--mid.btn--white .icon{
// 	fill: $textDark;
// }



// .sectionHome-upNext-activeItemPreview-actionList
// button.btn.btn--mid.btn--white .icon{
// 	position: absolute;
// 	left: 12px;
// }