//
// GOOGLE AND APPLE SIGN IN BUTTONS
// there are a lot of states that need to be covered unfortunately
// we avoided using important, I think that was still the right call
//

//
// FOCUS AND DEFAULT
//

button.signin-signInWithGoogleBtn,
button.signup-signInWithGoogleBtn,
button.signin-signInWithAppleBtn,
button.signup-signInWithAppleBtn {
  color: $textBlack;

  background: rgba(255,255,255,0.75);

  border: 1px solid #dadce0; //align with google provided button
  border-radius: 4px; //align with google provided button

  box-shadow: 0px 2px 4px 0px rgba(0,0,20,0.05);
}

button.signin-signInWithAppleBtn .icon,
button.signup-signInWithAppleBtn .icon,
button.signin-signInWithAppleBtn:hover .icon,
button.signup-signInWithAppleBtn:hover .icon,
button.signin-signInWithAppleBtn:focus .icon,
button.signup-signInWithAppleBtn:focus .icon,
form.signin-form.signup-form--anyTouched ~ .signin-thirdPartyBtnContainer button.signin-signInWithAppleBtn:hover .icon,
form.signup-form.signup-form--anyTouched ~ .signup-thirdPartyBtnContainer button.signup-signInWithAppleBtn:hover .icon,
form.signin-form.signup-form--anyTouched ~ .signin-thirdPartyBtnContainer button.signin-signInWithAppleBtn:focus .icon,
form.signup-form.signup-form--anyTouched ~ .signup-thirdPartyBtnContainer button.signup-signInWithAppleBtn:focus .icon{
  opacity: 1;
}


// Fancy for modern browsers
form.signin-form:focus-within ~ .signin-thirdPartyBtnContainer button.signin-signInWithAppleBtn:hover .icon,
form.signup-form:focus-within ~ .signup-thirdPartyBtnContainer button.signup-signInWithAppleBtn:hover .icon,
form.signin-form:focus-within ~ .signin-thirdPartyBtnContainer button.signin-signInWithAppleBtn:focus .icon,
form.signup-form:focus-within ~ .signup-thirdPartyBtnContainer button.signup-signInWithAppleBtn:focus .icon{
  opacity: 1;
}

// //
// // FOCUS AND DEFAULT: HOVER
// //

button.signin-signInWithGoogleBtn:hover,
button.signin-signInWithGoogleBtn:focus,
button.signup-signInWithGoogleBtn:hover,
button.signup-signInWithGoogleBtn:focus {
  box-shadow: 0px 3px 4px 0px rgba(0,0,20,0.1);
}

button.signin-signInWithAppleBtn:hover,
button.signin-signInWithAppleBtn:focus,
button.signup-signInWithAppleBtn:hover,
button.signup-signInWithAppleBtn:focus,
form.signin-form.signup-form--anyTouched ~ .signin-thirdPartyBtnContainer button.signin-signInWithAppleBtn:hover,
form.signup-form.signup-form--anyTouched ~ .signup-thirdPartyBtnContainer button.signup-signInWithAppleBtn:hover,
form.signin-form.signup-form--anyTouched ~ .signin-thirdPartyBtnContainer button.signin-signInWithAppleBtn:focus,
form.signup-form.signup-form--anyTouched ~ .signup-thirdPartyBtnContainer button.signup-signInWithAppleBtn:focus{
  background: $white;
  border: 1px solid $gray-12;

  color: darken($textBlue, 2%);
  color: darken($textBlack, 5%);
  box-shadow: 0px 2px 4px 0px rgba(0,0,20,0.1);
}

// Fancy for modern browsers
form.signin-form:focus-within ~ .signin-thirdPartyBtnContainer button.signin-signInWithAppleBtn:hover,
form.signup-form:focus-within ~ .signup-thirdPartyBtnContainer button.signup-signInWithAppleBtn:hover,
form.signin-form:focus-within ~ .signin-thirdPartyBtnContainer button.signin-signInWithAppleBtn:focus,
form.signup-form:focus-within ~ .signup-thirdPartyBtnContainer button.signup-signInWithAppleBtn:focus{
  background: $white;
  border: 1px solid $gray-12;

  color: darken($textBlue, 2%);
  color: darken($textBlack, 5%);
  box-shadow: 0px 2px 4px 0px rgba(0,0,20,0.1);
}


//
// POSITIONING
//
// to hide-show without javascript, google button is after the form in the DOM

// POSITIONING DETERMINED BY CONTAINER
// after the form in the DOM for focus styling

.signin-centerBlock-spacer,
.signup-centerBlock-spacer{
  width: 100%;
  height: 86px; // 38 + 38 + 10
}

.signin-thirdPartyBtnContainer,
.signup-thirdPartyBtnContainer{
  position: absolute;
  width: 100%;
}

.signin-thirdPartyBtnContainer{
  top: 118px;
}

.signup-thirdPartyBtnContainer{
  top: 85px;
}


button.signin-signInWithGoogleBtn,
button.signup-signInWithGoogleBtn{
  margin-bottom: 10px;
}

//
// GOOGLE AND APPLE BUTTON LAYOUT
//

.signin-signInWithAppleBtn-centerContent,
.signup-signInWithAppleBtn-centerContent{
  display: flex;
  box-sizing: border-box;
  padding-right: 5px;
}

.signin-signInWithAppleBtn-centerContent-icon,
.signup-signInWithAppleBtn-centerContent-icon{
  width: 20px;
  height: 20px;
  margin-right: 8px;
  position: relative;
}

.signin-signInWithAppleBtn-centerContent-icon .icon,
.signup-signInWithAppleBtn-centerContent-icon .icon{
  height: 20px;
  width: 20px;
  position: absolute;
  top: -1px;
  left: 0px;
  fill: $textBlack;
}

.signin-signInWithAppleBtn-centerContent-label,
.signup-signInWithAppleBtn-centerContent-label{
  margin-top: 2px;
}
