//
// FLOATING NP CONTROL
// Picture-in-Picture slide-preview when minimize Now Playing
// Contains a slide with a fixed size
// On hover displays a small open in now playing label (expand label)
//

$height-floatingNPControl: 229px;
$width-floatingNPControl: 366px;

.floatingNPControl{
	position: fixed;
	width: $width-floatingNPControl;
	height: $height-floatingNPControl;
	background: $white;

	bottom: 20px;
	right: 25px;

	z-index: 1000;

	border: 1px solid $lineDark;
	box-shadow: 0px 10px 20px 0px rgba(0,0,0,0.12), 0px 0px 5px 0px rgba(0,0,0,0.0625);

	border-radius: 4px;	

	cursor: pointer;
	user-select: none;
}

.floatingNPControl-header{
	height: 28px;
	width: 100%;
	display: flex;
	pointer-events: none;

	position: absolute;
	top: 10px;
	left: 0px;

	z-index: 500;

	box-sizing: border-box;
	padding-left: 12px;
	padding-right: 12px;
}

.floatingNPControl-questionPreview{
	width: 100%;
	height: 100%;	
	cursor: pointer;
}

.floatingNPControl-header-expandLabel{
	height: 28px;
	padding-left: 8px;
	padding-right: 8px;

	margin-right: auto;

	color: $white;
	font-weight: $lato-bold;
	font-size: 15px;
	// text-transform: uppercase;	
	background: rgba(85,85,105,0.95);
	position: relative;
	line-height: 1.7;
	border-radius: 3px;
	text-align: center;

	user-select: none;

	position: relative;
}

button.floatingNPControl-header-closeButton{
	width: 32px;
	pointer-events: auto;
	height: 28px;
	
	border-radius: 3px;
	box-shadow: none;
	cursor: pointer;
	background: $colorBG-fullScreen-controls;
	border-color: $colorBG-fullScreen-controls;
	display: flex;
	align-items: center;
	justify-content: center;
}

button.floatingNPControl-header-closeButton .icon{
	width: 16px;
	height: 16px;
	fill: $white;
}

//
// ANIMATION (SCALES ON HOVER)

.floatingNPControl{
	transition: 200ms all ease-in-out;
}

.floatingNPControl:hover{
	transform: scale(1.03);	
	transition: 200ms all ease-in-out;
}

//
// INTERACTION - OPACITY MANAGEMENT

.floatingNPControl .floatingNPControl-header-expandLabel{
	opacity: 0;
	transition: 200ms all linear;
}

.floatingNPControl:hover .floatingNPControl-header-expandLabel{
	opacity: 1;
	transition: 100ms all linear;
}

.floatingNPControl button.floatingNPControl-header-closeButton{
	opacity: 0.4;
	transition: 100ms all linear;
}

.floatingNPControl:hover button.floatingNPControl-header-closeButton{
	opacity: 0.65;
	transition: 100ms all linear;
}

.floatingNPControl button.floatingNPControl-header-closeButton:hover{
	opacity: 0.9;
	transform: scale(1.05);
	transition: 100ms all linear;
}

.floatingNPControl button.floatingNPControl-header-closeButton:active{
	opacity: 1;
	transform: scale(1.05);
	transition: 100ms all linear;
}