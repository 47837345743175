$setSidePadding-itemPreviewModal: 80px;
$questionSidePadding-itemPreviewModal: 32px;
$setTopPadding-itemPreviewModal: 38px;
$questionTopPadding-itemPreviewModal: 20px;


.itemPreviewModalContainer{
	position: fixed;
	top: 0px;
	right: 0px;
	bottom: 0px;
	left: 0px;
	z-index: 9999;

	display: flex;
	align-items: center;
	justify-content: center;
}

.itemPreviewModal{
	position: relative;
	z-index: 5;

	background: rgba(0,0,10,0.5);

	border-radius: 11px;

	

	overflow: hidden;
}

.itemPreviewModal--set{
	padding-left: $setSidePadding-itemPreviewModal;
	padding-right: $setSidePadding-itemPreviewModal;
}

.itemPreviewModal--question{
	padding-left: $questionSidePadding-itemPreviewModal;
	padding-right: $questionSidePadding-itemPreviewModal;
	padding-top: $questionTopPadding-itemPreviewModal;
	padding-bottom: $questionTopPadding-itemPreviewModal;
}


//
// NAVIGATE BUTTON


button.itemPreviewModal-navigateBtn{
	position: absolute;
	top: 50px;
	bottom: 50px;
	width: 50%;

	cursor: default;

	border: none;
	background: none;
	outline: none;
	box-shadow: none;
}

button.itemPreviewModal-navigateBtn--next{
	right: 0px;
}

button.itemPreviewModal-navigateBtn--previous{
	left: 0px;	
}


.itemPreviewModal-navigateBtn-iconContainer{
	position: absolute;
	top: 0px;
	bottom: 0px;
	width: $setSidePadding-itemPreviewModal;

	display: flex;
	align-items: center;
	justify-content: center;

	cursor: pointer;
}

button.itemPreviewModal-navigateBtn--next
.itemPreviewModal-navigateBtn-iconContainer{
	right: 0px;
}


button.itemPreviewModal-navigateBtn--previous
.itemPreviewModal-navigateBtn-iconContainer{
	left: 0px;	
}

button.itemPreviewModal-navigateBtn svg.icon{
	width: 32px;
	height: 32px;
	fill: $white;

	opacity: 0.4;
}

button.itemPreviewModal-navigateBtn:hover svg.icon{
	opacity: 0.6;
	transition: all 100ms linear;
}

button.itemPreviewModal-navigateBtn:hover
.itemPreviewModal-navigateBtn-iconContainer:hover svg.icon{
	opacity: 0.7;
	transition: all 100ms linear;
}

button.itemPreviewModal-navigateBtn:active svg.icon{
	opacity: 0.9;
	transform: scale(1.025);
	transition: all 50ms linear;
}

// UNAVAILABLE

button.itemPreviewModal-navigateBtn--unavailable{
	pointer-events: none;
}

button.itemPreviewModal-navigateBtn--unavailable
.itemPreviewModal-navigateBtn-iconContainer svg.icon{
	opacity: 0;
}





//
//

.itemPreviewModal-topBar{
	height: $setTopPadding-itemPreviewModal;
	width: 100%;

	display: flex;
	align-items: center;
	justify-content: space-between;

	min-width: 0;
}
	

.itemPreviewModal-topBar-setTitle{
	flex-grow: 1;
	flex-shrink: 1;

	font-size: 16.5px;
	font-weight: $lato-semibold;
	color: $white;

	cursor: default;
	user-select: none;

	letter-spacing: 0.01em;

	opacity: 0.7;
	transition: opacity 100ms linear;

	white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  text-align: center;
}

.itemPreviewModal-topBar:hover
.itemPreviewModal-topBar-setTitle{
	opacity: 1;
	transition: opacity 100ms linear;
}

.itemPreviewModal-topBar-counter{
	font-size: 15px;
	font-weight: $lato-medium;
	color: $white;

	opacity: 0.5;
	transition: opacity 100ms linear;

	width: 120px;

	text-align: right;
	flex-grow: 0;
	flex-shrink: 0;
}

.itemPreviewModal-topBar:hover
.itemPreviewModal-topBar-counter{
	opacity: 1;
	transition: opacity 100ms linear;
}

//

.itemPreviewModal-slideContainer{
	border-radius: 4px;
	overflow: hidden;

	box-shadow: 0px 2px 4px 0px rgba(0,0,20,0.5);
}



//
// SET COUNTER

.itemPreviewModal-setCounterContainer{
	display: flex;
	align-items: center;
	justify-content: center;
	height: $setTopPadding-itemPreviewModal;
	width: 100%;
}

.itemPreviewModal-setCounter{
	height: $setTopPadding-itemPreviewModal;
	width: 70%;

	display: flex;
	align-items: center;
	justify-content: center;
}

.itemPreviewModal-setCounter{
	opacity: 0.7;
}

.itemPreviewModal-setCounter:hover{
	opacity: 1;
}

.itemPreviewModal-setCounter-item{
	flex-grow: 1;
	flex-shrink: 1;
	height: 100%;
	cursor: pointer;

	display: flex;
	align-items: center;

	position: relative;
	
	// margin: 2px;
	padding-left: 2px;
	padding-right: 2px;

}

.itemPreviewModal-setCounter-item-pillContainer{
	width: 100%;
	height: 4px;
	
	border-radius: 2px;
	overflow: hidden;
	position: relative;
}

.itemPreviewModal-setCounter-item-pill{
	position: absolute;
	top: 0px;
	left: 0px;
	right: 0px;
	bottom: 0px;
	z-index: 5;
	background: $gray-3;
}





.itemPreviewModal-setCounter-item--activeQuestion
.itemPreviewModal-setCounter-item-pill,
.itemPreviewModal-setCounter-item--activeQuestion:hover
.itemPreviewModal-setCounter-item-pill{
	background: $gray-14;
}

.itemPreviewModal-setCounter-item:hover
.itemPreviewModal-setCounter-item-pill{
	background: $gray-9;
}

.itemPreviewModal-setCounter-item:active
.itemPreviewModal-setCounter-item-pill{
	background: $white;
}



$slidePreviewWidth-itemPreviewModal-setCounter: 320px;

.itemPreviewModal-setCounter-item-slideContainer{
	position: absolute;
	z-index: 50;
	height: calc(#{$slidePreviewWidth-itemPreviewModal-setCounter}/1.6);
	width: $slidePreviewWidth-itemPreviewModal-setCounter;

	background: $white;
	border: 1px solid darken($lineDark, 5%);
	border-bottom: 1px solid darken($lineDark, 9%);
	border-radius: 2px;
	box-shadow: 0px 1px 2px 0px rgba(0,0,20,0.14);

	top: calc((-1 * #{$slidePreviewWidth-itemPreviewModal-setCounter}/1.6) + 4px);
	left: 50%;
	transform: translateX(-50%);

	opacity: 0;
	visibility: hidden;

	pointer-events: none;
	overflow: hidden;
}


.itemPreviewModal-setCounter-item--notActiveQuestion:hover
.itemPreviewModal-setCounter-item-slideContainer{
	visibility: visible;
}

.itemPreviewModal-setCounter
.itemPreviewModal-setCounter-item-slideContainer{
	opacity: 0;
}

.itemPreviewModal-setCounter-item:hover
.itemPreviewModal-setCounter-item-slideContainer{
	opacity: 1;
	transition: opacity 0ms linear;
	transition-delay: 0ms;
}



//

//
//
// CLOSE BUTTON


button.itemPreviewModal-closeBtn{
	position: absolute;
	top: 0px;
	right: 0px;
	z-index: 100;

	display: flex;
	align-items: center;
	justify-content: flex-end;

	box-sizing: border-box;
	padding-right: 12px;

	opacity: 0.2;
	transition: opacity 100ms linear;

	cursor: pointer;

	outline: none;
	border: none;
	background: none;
	box-shadow: none;

	// background: $tempr;

}


button.itemPreviewModal-closeBtn:hover{
	opacity: 0.5;
	transition: opacity 50ms linear;
}

button.itemPreviewModal-closeBtn:active{
	opacity: 0.8;
}


.itemPreviewModal-closeBtn-iconContainer{
	width: 30px;
	height: 30px;
	border-radius: 50%;
	background: $black;

	display: flex;
	align-items: center;
	justify-content: center;
}

button.itemPreviewModal-closeBtn svg.icon{
	width: 12px;
	height: 12px;
	fill: $white;
}



.itemPreviewModal--set
button.itemPreviewModal-closeBtn{
	width: $setSidePadding-itemPreviewModal;
	height: $setTopPadding-itemPreviewModal;
}




.itemPreviewModalContainer-bg{
	background: rgba(0,0,5,0.75);

	position: absolute;
	top: 0px;
	left: 0px;
	right: 0px;
	bottom: 0px;
}

