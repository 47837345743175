//
// SLIDE BODY
//

.slide-body{
	font-size: 64px; // font size is set via JS, this is fallback of sorts
	color: rgb(33,33,51);
  font-weight: 700;
  box-sizing: border-box;
  padding: 4px;
  overflow: hidden; //prevents scrollbars
  white-space: pre-line; //preserves linebreaks
  word-wrap: break-word; //allows unbreakable long words to break line
   
	cursor: default;
  letter-spacing: 0.005em;
  border: 1px solid rgba(0,0,0,0); //form needs border, static its transparent    

  text-align: left;
}

// if empty p, then inserts blank space, to mimic empty line break
.slide-body p:empty::after{
  content: "\00a0"
}

.editorGhostElementsContainer .slide-body{
  overflow-x: scroll !important;
  // allows us to measure if overflow to downscale font size
}

//
// LAYOUT/TEMPLATES

.slide.slide--template--bodyLeft .slide-body{
  width: 960px;
  flex-grow: 2;
}

.fontSizeBodyArray--template--bodyLeft .slide-body{
  width: 960px;
}

//

.slide.slide--template--bodyLeftMediaRight .slide-body{
  width: 550px;
  flex-grow: 2;
}

.slide.slide--template--bodyRightMediaLeft .slide-body{
  margin-left: 610px;
  width: 550px;
  flex-grow: 2;
}

.fontSizeBodyArray--template--bodyLeftMediaRight .slide-body,
.fontSizeBodyArray--template--bodyRightMediaLeft .slide-body{
  width: 550px;
}

//

.slide.slide--template--bodyCenter .slide-body{
  width: 1160px;
  flex-grow: 2;
  text-align: center;
}

.fontSizeBodyArray--template--bodyCenter .slide-body{
  width: 1160px;
  text-align: center;
}

//

.slide.slide--template--bodyCenterChoicesMedia .slide-body{
  width: 1160px;
  flex-grow: 2;
  text-align: center; 
}

.fontSizeBodyArray--template--bodyCenterChoicesMedia .slide-body{
  width: 1160px;
  text-align: center;
}

//

.slide.slide--template--bodyLeftMediaRightChoicesMedia .slide-body{
  width: 550px;
  flex-grow: 2;  
}

.fontSizeBodyArray--template--bodyLeftMediaRightChoicesMedia .slide-body{
  width: 550px;
}

//

.slide.slide--template--bodyCenterMediaCenter .slide-body{
  width: 1160px;
  text-align: center;
}

.fontSizeBodyArray--template--bodyCenterMediaCenter .slide-body{
  width: 1160px;
  text-align: center; 
}


//
// EDITOR

.slide.slide--editor .slide-body {
  border: 1px solid $invisible;
  cursor: text;
  white-space: pre-wrap !important; // for prosemirror firefox support    
}

// HOVER AND FOCUS STYLING

.slide.slide--editor:hover .slide-body {
  border-color: $lineLight;
}

.slide.slide--editor .slide-body:hover {
  border-color: $lineMidDark;
  outline: none;
}

.slide.slide--editor .slide-body.slide-body--focused {
  border-color: $blue;
  background: $white;
  color: $textBlackDark;
  outline: none;
  box-shadow: none;
}

// PLACEHOLDER STYLING - probably needs refactor
.slide.slide--editor .slide-body-placeholder {
  color: rgb(198, 198, 212);
  height: 0; // prevents growing text for body center media center
}
