.discoverHome-marketBtnArray{
	display: flex;
	width: calc(100% + 8px);
	margin-left: -8px;
	margin-top: -12px;

	flex-wrap: wrap;

	max-width: 875px;
}

button.discoverHome-marketBtn{
	// background: $tempr;
	background: none;
	width: 50%;
	height: 125px;
	flex-grow: 0;
	flex-shrink: 0;
	text-align: left;


	border: none;
	padding: none;

	box-sizing: border-box;

	outline: none;
	box-shadow: none;
	border-radius: 0px;

	cursor: pointer;
	
	padding: 8px;

	user-select: none;
	position: relative;
}

.discoverHome-marketBtn-inner{
	border-radius: 0px;
	position: relative;

	background: $white;

	display: flex;
	align-items: flex-end;

	width: 100%;
	height: 100%;
	border: 1px solid $gray-125;
	border-bottom: 1px solid $gray-115;

	border-radius: 5px;
	box-sizing: border-box;

	padding: 16px;

	box-shadow: 0px 4px 8px 0px rgba(0,0,40,0.06);

	position: relative;
	overflow: hidden;


}

button.discoverHome-marketBtn:hover
.discoverHome-marketBtn-inner{
	border: 1px solid $gray-12;
	border-bottom: 1px solid $gray-11;
	box-shadow: 0px 4px 8px 0px rgba(0,0,40,0.10);
}


//
//

.discoverHome-marketBtn-inner-left{

}

.discoverHome-marketBtn-inner-left-title{
	display: flex;
	align-items: center;

	margin-bottom: 4px;
}

.discoverHome-marketBtn-inner-left-title-text{
	font-size: 22px;
	font-weight: $lato-heavy;
	color: $textBlack;
}

button.discoverHome-marketBtn:hover
.discoverHome-marketBtn-inner-left-text{
	color: $textBlackDark;
}

// CHEVRON CONTAINER

.discoverHome-marketBtn-inner-left-title-chevronContainer{
	margin-left: 7px;
	position: relative;
	top: 2px;
}

.discoverHome-marketBtn-inner-left-title-chevronContainer svg{
	width: 14px;
	height: 14px;
}


// SUBTITLE
.discoverHome-marketBtn-inner-left-subtitle{
	font-size: 14px;
	font-weight: $lato-semibold;
	color: $black;
	opacity: 0.75;
}

// PRIMARY ICON CONTAINER

.discoverHome-marketBtn-inner-primaryIconContainer{
	width: 60px;
	height: 60px;
	// background: $tempr;
	margin-left: auto;

	display: flex;
	align-items: flex-end;
	justify-content: flex-end;
}

.discoverHome-marketBtn-inner-primaryIconContainer svg{
	width: 44px;
	height: 44px;
}

@media (max-width: 1200px) {	
	.discoverHome-marketBtn-inner-primaryIconContainer{
		display: none;
	}
}

@media (max-width: 1100px) {	
	.discoverHome-marketBtn-inner-left-subtitle{
		display: none;
	}
}

// SECONDARY ICON CONTAINER

.discoverHome-marketBtn-inner-secondaryIconContainer{
	position: absolute;
	top: -10px;
	left: -10px;
	// height: 50px;
	// width: 50px;
	opacity: 0.4;
}


.discoverHome-marketBtn-inner-secondaryIconContainer svg{
	width: 40px;
	height: 40px;
}


//
// SUBJECT SPECIFIC


// MATH

button.discoverHome-marketBtn--math
.discoverHome-marketBtn-inner{
	border: 1px solid #A4BEFC;
	background: lighten(#F4F7FF, 0.25%);
	box-shadow: 0px 4px 8px 0px rgba(108,150,250,0.2)
}

button.discoverHome-marketBtn--math:hover
.discoverHome-marketBtn-inner{
	border: 1px solid darken(#A4BEFC, 1%);
	background: darken(#F4F7FF, 0.5%);
	box-shadow: 0px 4px 8px 0px rgba(108,150,250,0.22);
}

button.discoverHome-marketBtn--math:active
.discoverHome-marketBtn-inner{
	border: 1px solid darken(#A4BEFC, 4%);
	background: darken(#F4F7FF, 2%);
	box-shadow: 0px 3px 6px 0px rgba(108,150,250,0.22);
}


button.discoverHome-marketBtn--math
.discoverHome-marketBtn-inner-left-title-chevronContainer svg{
	fill: darken(#A4BEFC, 4%);
	opacity: 0.7;
}

button.discoverHome-marketBtn--math:hover
.discoverHome-marketBtn-inner-left-title-chevronContainer svg{
	opacity: 1;
}


// ELA

button.discoverHome-marketBtn--ELA
.discoverHome-marketBtn-inner{
	border: 1px solid #E4A4AD;
	background: lighten(#FFF5F6, 0.25%);
	box-shadow: 0px 4px 8px 0px rgba(239,160,172,0.2)
}

button.discoverHome-marketBtn--ELA:hover
.discoverHome-marketBtn-inner{
	border: 1px solid darken(#E4A4AD, 1%);
	background: darken(#FFF5F6, 0.5%);
	box-shadow: 0px 4px 8px 0px rgba(239,160,172,0.22)
}

button.discoverHome-marketBtn--ELA:active
.discoverHome-marketBtn-inner{
	border: 1px solid darken(#E4A4AD, 4%);
	background: darken(#FFF5F6, 2%);
	box-shadow: 0px 3px 6px 0px rgba(239,160,172,0.22)
}


button.discoverHome-marketBtn--ELA
.discoverHome-marketBtn-inner-left-title-chevronContainer svg{
	fill: darken(#E4A4AD, 4%);
	opacity: 0.7;
}

button.discoverHome-marketBtn--ELA:hover
.discoverHome-marketBtn-inner-left-title-chevronContainer svg{
	opacity: 1;
}


//
// BIOLOGY

button.discoverHome-marketBtn--biology
.discoverHome-marketBtn-inner{
	border: 1px solid #97C6A3;
	background: lighten(#F5FFF7, 0.25%);
	box-shadow: 0px 4px 8px 0px rgba(0,185,54,0.15)
}

button.discoverHome-marketBtn--biology:hover
.discoverHome-marketBtn-inner{
	border: 1px solid darken(#97C6A3, 1%);
	background: darken(#F5FFF7, 0.75%);
	box-shadow: 0px 4px 8px 0px rgba(0,185,54,0.19)
}

button.discoverHome-marketBtn--biology:active
.discoverHome-marketBtn-inner{
	border: 1px solid darken(#97C6A3, 4%);
	background: darken(#F5FFF7, 2%);
	box-shadow: 0px 3px 6px 0px rgba(0,185,54,0.19)
}

button.discoverHome-marketBtn--biology
.discoverHome-marketBtn-inner-left-title-chevronContainer svg{
	fill: darken(#97C6A3, 4%);
	opacity: 0.7;
}

button.discoverHome-marketBtn--biology:hover
.discoverHome-marketBtn-inner-left-title-chevronContainer svg{
	opacity: 1;
}

button.discoverHome-marketBtn--biology
.discoverHome-marketBtn-inner-secondaryIconContainer{
	transform: rotate(50deg);
	top: -8px;
	left: -7px;
}


//
// MIDDLE SCIENCE

button.discoverHome-marketBtn--middleScience{
	cursor: default;
}

button.discoverHome-marketBtn--middleScience
.discoverHome-marketBtn-inner-left-title-text{
	font-size: 22px;
	font-weight: $lato-heavy;
	color: $textDark;
}

button.discoverHome-marketBtn--middleScience .discoverHome-marketBtn-inner,
button.discoverHome-marketBtn--middleScience:hover .discoverHome-marketBtn-inner,
button.discoverHome-marketBtn--middleScience:active .discoverHome-marketBtn-inner{
	border: 1px solid $gray-12;
	background: $gray-15;
	box-shadow: none;
}

button.discoverHome-marketBtn--middleScience
.discoverHome-marketBtn-inner-primaryIconContainer{
	opacity: 0.75;
}

button.discoverHome-marketBtn--middleScience
.discoverHome-marketBtn-inner-primaryIconContainer svg{
	height: 44px;
	width: 44px;
}	


// MORE MIDDLE SCIENCE: COMING SOON
.discoverHome-marketBtn-inner-left-comingSoonBadgeContainer{
	display: flex;
}

.discoverHome-marketBtn-inner-left-comingSoonBadge{
	margin-top: -2px;
	margin-bottom: -2px;

	font-size: 14px;
	font-weight: $lato-semibold;
	letter-spacing: 0.01em;
	// text-transform: uppercase;


	background: $gray-13;
	color: $textDark;

	padding-left: 5px;
	padding-right: 5px;
	padding-top: 2px;
	padding-bottom: 2px;

	border-radius: 4px;

}

// MORE MIDDLE SCIENCE: NOTIFICATION
.discoverHome-marketBtn-notificationContainer{
	position: absolute;
	top: 18px;
	right: -2px;
}

button.discoverHome-marketBtn-notificationBtn{
	font-size: 13.5px;
	font-weight: $lato-bold;

	background: none;
	border: none;
	outline: none;

	letter-spacing: 0.03em;

	cursor: pointer;

	padding-right: 10px;
	padding-left: 10px;
	height: 26px;
	display: flex;
	align-items: center;
	justify-content: center;

	border-radius: 4px;

	cursor: pointer;

	position: relative;
	z-index: 10;

}

button.discoverHome-marketBtn-notificationBtn--notSubscribed{
	background: $blue;
	color: $white;
	letter-spacing: 0.01em;
	border: 1px solid darken($blue, 5%);
	
	box-shadow: 0px 2px 4px 0px rgba(0,0,40,0.08);
}

button.discoverHome-marketBtn-notificationBtn--notSubscribed:hover{
	background: darken($blue, 2%);
}

button.discoverHome-marketBtn-notificationBtn--notSubscribed svg.icon{
	height: 12px;
	width: 12px;
	fill: $white;

	margin-left: 6px;
	opacity: 0.7;

	position: relative;
}

button.discoverHome-marketBtn-notificationBtn--notSubscribed:hover svg.icon{
	opacity: 0.8;
}



button.discoverHome-marketBtn-notificationBtn--subscribed{
	background: darken($green, 2%);
	color: $white;
	border: 1px solid darken($green, 4%);
}

.discoverHome-marketBtn-notificationContainer 
svg.discoverNotificationRibbon{
	position: absolute;
	bottom: -9px;
	right: 0px;
}


.discoverHome-marketBtn-notificationContainer--notSubscribed
svg.discoverNotificationRibbon path{
	fill: darken($blue, 7%);
	stroke: darken($blue, 10%);
}

.discoverHome-marketBtn-notificationContainer--subscribed
svg.discoverNotificationRibbon path{
	fill: darken($green, 7%);
	stroke: darken($green, 10%);
}