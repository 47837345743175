.publishingFlowSelectControl{
	display: flex;
}


// CONTROL

.publishingFlowSelectControl{
	display: block;
	position: relative;

	padding: 6px;
	padding-left: 30px;

	cursor: pointer;

	border-radius: 5px;
}

.publishingFlowSelectControl:hover{
	background: rgb(246,247,252);
}

.publishingFlowSelectControl--checked,
.publishingFlowSelectControl--checked:hover{
	background: $blue-extraLight;
}

//
// LABEL
//

.publishingFlowSelectControl-label{
	font-size: 16px;
	font-weight: $lato-bold;
	color: $textDark;
	letter-spacing: 0.005em;
	user-select: none;
}

.publishingFlowSelectControl:hover
.publishingFlowSelectControl-label{
	color: $textBlack;
}

.publishingFlowSelectControl--checked .publishingFlowSelectControl-label,
.publishingFlowSelectControl--checked:hover .publishingFlowSelectControl-label{
	color: $textBlackDark;
}

// DESCRIPTION

.publishingFlowSelectControl-description{
	font-size: 14px;
	font-weight: $lato-medium;
	color: $textLight;
	margin-top: 3px;
	letter-spacing: 0.005em;
	user-select: none;
}

// RADIO

.publishingFlowSelectControl-radio{
	position: absolute;
	left: 6px;
	top: 7px;	

	height: 16px;
	width: 16px;
	border-radius: 50%;
	flex-shrink: 0;
	flex-grow: 0;
	box-sizing: border-box;
}


.publishingFlowSelectControl--checked
.publishingFlowSelectControl-radio{
	border: 1px solid $blue-light;
	background: $blue-extraLight;
	background: $white;

	display: flex;
	align-items: center;
	justify-content: center;
	box-sizing: border-box;
}

.publishingFlowSelectControl--checked
.publishingFlowSelectControl-radio
.publishingFlowSelectControl-radio-inner{
	width: 8px;
	height: 8px;
	border-radius: 50%;
	background: $blue;
	box-sizing: border-box;

	// opacity: 0.75;
}

.publishingFlowSelectControl--unchecked
.publishingFlowSelectControl-radio{
	border: 1px solid $lineDark;
	background: $white;
	box-sizing: border-box;
}





// // CHECK BOX 

.publishingFlowSelectControl-checkbox{
	position: absolute;
	top: 7px;
	left: 6px;

	height: 14px;
	width: 14px;
	
	border-radius: 3px;
	
}


.publishingFlowSelectControl--checked
.publishingFlowSelectControl-checkbox{
	border: 1px solid darken($blue, 10%);
	background: $blue;
}

.publishingFlowSelectControl--checked
.publishingFlowSelectControl-checkbox svg{
	position: absolute;
	left: 2px;
	top: 2px;
	width: 10px;
	height: 10px;
}

.publishingFlowSelectControl-checkbox svg #border{
	fill: darken($blue, 10%);
}

.publishingFlowSelectControl-checkbox svg #fill{
	fill: $white;
}

.publishingFlowSelectControl--unchecked
.publishingFlowSelectControl-checkbox{
	border: 1px solid $lineDark;
	background: $white;
}




// NOT CHECKABLE THINGS

// .publishingFlowSelectControl--uncheckable{
// 	pointer-events: none;
// 	opacity: 0.75;
// }

// .publishingFlowSelectControl--uncheckable.publishingFlowSelectControl--checked
// .publishingFlowSelectControl-checkbox{
// 	border: 1px solid $gray-8;
// 	background: $gray-8;
// }

// .publishingFlowSelectControl--uncheckable.publishingFlowSelectControl--checked
// .publishingFlowSelectControl-checkbox svg #border{
// 	fill: 1px solid $gray-8;
// }

// .publishingFlowSelectControl--uncheckable.publishingFlowSelectControl--checked
// .publishingFlowSelectControl-radio{
// 	border: 1px solid darken($gray-8, 10%);
// 	background: $gray-8;
// }

// .publishingFlowSelectControl--uncheckable.publishingFlowSelectControl--checked
// .publishingFlowSelectControl-radio-activeCircle{	
// 	border: 1px solid $gray-8;
// }



//
// Optional other input box
// 

input.publishingFlowSelectControl-optionalOtherInputBox{
	display: block;
	margin-top: 4px;
	margin-left: -4px;
	margin-bottom: 4px;

	width: 100%;
	height: 30px;

	font-size: 15px;

	padding-left: 6px;
	border-radius: 3px;
	padding-bottom: 1px;

	line-height: 1.6;
	box-shadow: none;
	border: none;
	box-sizing: border-box;
	border: 1px solid $lineDark;
	color: $textBlack;
	outline: none;
	font-weight: $lato-semibold;
}

input.publishingFlowSelectControl-optionalOtherInputBox::-webkit-input-placeholder{
	color: $textLight;
}

input.publishingFlowSelectControl-optionalOtherInputBox::-moz-placeholder{
	color: $textLight;
}

input.publishingFlowSelectControl-optionalOtherInputBox:-ms-input-placeholder{
	color: $textLight;
}



input.publishingFlowSelectControl-optionalOtherInputBox:hover{
	border: 1px solid darken($lineMid, 0.2);
}

input.publishingFlowSelectControl-optionalOtherInputBox:hover::-webkit-input-placeholder{
	color: transparentize($textLight, 0.5);
}

input.publishingFlowSelectControl-optionalOtherInputBox:hover::-moz-placeholder{
	color: transparentize($textLight, 0.5);
}

input.publishingFlowSelectControl-optionalOtherInputBox:hover:-ms-input-placeholder{
	color: transparentize($textLight, 0.5);
}


input.publishingFlowSelectControl-optionalOtherInputBox:focus::-webkit-input-placeholder{
	color: transparentize($textLight, 1);
}


input.publishingFlowSelectControl-optionalOtherInputBox:focus::-moz-placeholder{
	color: transparentize($textLight, 1);
}


input.publishingFlowSelectControl-optionalOtherInputBox:focus:-ms-input-placeholder{
	color: transparentize($textLight, 1);
}


input.publishingFlowSelectControl-optionalOtherInputBox:focus{
	border: 1px solid $blue;
	box-shadow: none;
	outline: none;
}