//
// SUGGESTIONS DROPDOWN FOR INPUT RULES with / and :
//

$height-suggestion-item: 32px;

.suggestion-item-list{
  
 	width: 300px;

 	// I think we want height from number of suggestions
 	max-height: calc(#{$height-suggestion-item} * 8);
 	overflow: hidden;
  
  padding-top: 4px;
  padding-bottom: 4px;
  padding-left: 0px;
  padding-right: 0px;

  background: $white;

  border-radius: 5px;
  background: rgb(252,252,255);
  border: 1px solid $gray-1225;
  box-shadow: 0px 2px 4px 0px rgba(0,0,40,0.12);

}

.suggestion-item{
	height: $height-suggestion-item;
	display: flex;
	opacity: 1;
	border-top: 1px solid $invisible;
 	border-bottom: 1px solid $invisible;

 	align-items: center;

 	cursor: pointer;

 	box-sizing: border-box;
 	padding-right: 10px;

 	position: relative;
}

.suggestion-item-keyHint{
	visibility: hidden;
}

.suggestion-item-preview{
	width: 36px;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	color: $blue;
	color: darken($blue, 5%);
	font-size: 18px;
	font-size: 20px;

	margin-top: -1px;

	overflow: hidden;
	//background: $tempr;
	box-sizing: border-box;
}

.suggestion-item-label{
	height: 100%;
	display: flex;
	opacity: 0.8;
	align-items: center;
	font-weight: $lato-semibold;
	color: $textBlackDark;
	margin-top: -1px;

	font-size: 15.5px;
	letter-spacing: 0.01em;
}

.suggestion-item--active{  
 	background: $white;   
 	opacity: 1;
 	border-top: 1px solid $gray-13;
 	border-bottom: 1px solid $gray-13;
 	
}

.suggestion-item--active .suggestion-item-label{
	opacity: 1;
}

.suggestion-item--active .suggestion-item-keyHint{
	visibility: visible;
}

.suggestion-item-keyHint{
	position: absolute;
	right: 6px;
	top: 3px;

	display: flex;
	justify-content: center;
	align-items: center;

	margin-left: auto;

	height: 24px;
	background: $tempr;
	font-size: 12.5px;
	letter-spacing: 0.025em;
	font-weight: $lato-medium;
	font-weight: $lato-semibold;

	
}


// DARK HIGHLIGHTED OPTION
//

.suggestion-item--active{
	background: $blue;   
 	opacity: 1;
 	border-top: 1px solid darken($blue, 5%);
 	border-bottom: 1px solid darken($blue, 5%);
}

.suggestion-item--active
.suggestion-item-label{
	color: $white;
}

.suggestion-item--active
.suggestion-item-preview{
	color: $white;
}

.suggestion-item-keyHint{	
	color: $white;

	background: rgba(0,0,40,0.14);
	padding-left: 7px;
	padding-right: 7px;
	border-radius: 4px;

}

.suggestion-item--active:active{
	background: darken($blue, 6%);
	border-top: 1px solid darken($blue, 9%);
 	border-bottom: 1px solid darken($blue, 9%);
}

// .suggestion-item--active:active .suggestion-item-keyHint{
// 	opacity: 0.5;
// }


///
/// CUSTOM PER SYMBOL STYLING

.suggestion-item.suggestion-item--blank .suggestion-item-preview{
	letter-spacing: -0.08em;
}

.suggestion-item.suggestion-item--long-blank .suggestion-item-preview{
	letter-spacing: -0.15em;
}

.suggestion-item.suggestion-item--long-arrow .suggestion-item-preview{
	font-size: 16px;
	padding-bottom: 1px;
}

// ROMAN NUMERALS
.suggestion-item.suggestion-item--roman-numeral-one .suggestion-item-preview,
.suggestion-item.suggestion-item--roman-numeral-two .suggestion-item-preview,
.suggestion-item.suggestion-item--roman-numeral-three .suggestion-item-preview,
.suggestion-item.suggestion-item--roman-numeral-four .suggestion-item-preview,
.suggestion-item.suggestion-item--roman-numeral-five .suggestion-item-preview,
.suggestion-item.suggestion-item--roman-numeral-six .suggestion-item-preview,
.suggestion-item.suggestion-item--roman-numeral-seven .suggestion-item-preview,
.suggestion-item.suggestion-item--roman-numeral-eight .suggestion-item-preview,
.suggestion-item.suggestion-item--roman-numeral-nine .suggestion-item-preview,
.suggestion-item.suggestion-item--roman-numeral-ten .suggestion-item-preview,
.suggestion-item.suggestion-item--roman-numeral-eleven .suggestion-item-preview,
.suggestion-item.suggestion-item--roman-numeral-twelve .suggestion-item-preview,
.suggestion-item.suggestion-item--roman-numeral-fity .suggestion-item-preview,
.suggestion-item.suggestion-item--roman-numeral-100 .suggestion-item-preview,
.suggestion-item.suggestion-item--roman-numeral-500 .suggestion-item-preview,
.suggestion-item.suggestion-item--roman-numeral-1000 .suggestion-item-preview{
	font-size: 16px;
}