.cardAssignmentSelectMenu{
	height: 30px;
	margin-bottom: 0px;
	margin-top: 10px;

	position: absolute;
	bottom: 8px;
	left: 0px;
	right: 0px;
}

.cardAssignmentSelectMenu .Select-control{
	height: 30px;
	font-size: 15px;
	font-weight: $lato-semibold;

	border-radius: 3px;
	line-height: 1.6;
	// padding-left: 4px;
	//background: rgba(0,//0,0,0.02);
	//border-color: rgba(0,0,0,0.02);
}

.cardAssignmentSelectMenu .Select-control:hover,
.cardAssignmentSelectMenu .Select.is-focused,
.cardAssignmentSelectMenu .Select.is-open{
	background: $white;
	border-color: $lineDark;
}

.cardAssignmentSelectMenu .Select-control .Select-placeholder{
	line-height: 28px;
	padding-left: 14px;
	color: $textMid;
	
}

.cardAssignmentSelectMenu .Select-control .Select-value{
	line-height: 28px !important;
	
}

.cardAssignmentSelectMenu .Select-control .Select-value-label {
	line-height: 28px;
}

.cardAssignmentSelectMenu .Select-control .Select-input {
    height: 28px;
}

// ARROW

.cardAssignmentSelectMenu-arrow .icon{
	width: 10px;
	height: 10px;
	fill: $textLight;
	opacity: 0.5;
}

.cardAssignmentSelectMenu .Select-control:hover .cardAssignmentSelectMenu-arrow .icon,
.cardAssignmentSelectMenu .Select.is-focused .cardAssignmentSelectMenu-arrow .icon{
	opacity: 1;
}

.cardAssignmentSelectMenu .Select.is-open .cardAssignmentSelectMenu-arrow .icon{
	fill: $blue;
	opacity: 1;
}

// MENU

.cardAssignmentSelectMenu .Select-menu{
	max-height: calc(100vh - 350px); //this should allow to fill as much of page as possible
}

.cardAssignmentSelectMenu .Select-menu-outer{
	max-height: calc(100vh - 350px + 2px);
}

// OPTION

.cardAssignmentSelectMenu .Select-option{
	height: 25px;
	box-sizing: border-box;
	padding-left: 8px;
	padding-right: 15px;
	padding-bottom: 0px;
	padding-top: 0px;
}

.cardAssignmentSelectMenu .Select-option:nth-child(even){
	background: rgb(246,246,251);
}


.cardAssignmentSelectMenu-option{
	display: flex;
	height: 25px;
}

.cardAssignmentSelectMenu-option-cardNo{
	font-size: 13px;
	font-weight: $lato-semibold;
	color: $textBlack;
	line-height: 1.8;
}

.cardAssignmentSelectMenu-option-explainText{
	margin-left: auto;
	font-size: 12px;
	font-weight: $lato-medium;
	color: $textMid;
	line-height: 2.1;
}


// FOCUSED

.cardAssignmentSelectMenu .Select-option.is-focused{
	background: $blue;
}


.cardAssignmentSelectMenu .Select-option.is-focused .cardAssignmentSelectMenu-option-cardNo{
	color: $white;
}

.cardAssignmentSelectMenu .Select-option.is-focused .cardAssignmentSelectMenu-option-explainText{
	color: $white;
}