.syncLearnMoreModalContainer{
	position: fixed;
	z-index: 9999;
	top: 0;
	left: 0;
	right: 0;
	height: 100vh;
	
	background: rgba(255,255,255,0.8);
	
	display: flex;
	
	align-items: flex-start;
	justify-content: center;

	overflow-y: overlay;
	overflow-x: hidden;
}

.syncLearnMoreModal{
	margin-top: 32px;
	margin-bottom: 32px;
	
	padding-top: 50px;
	padding-bottom: 40px;

	border-radius: 6px;
	
	background: rgb(252,252,255);
	border: 1px solid $gray-125;
	box-shadow: 
		0px 4px 8px 0px rgba(5,5,10,0.12),
		0px 8px 16px 0px rgba(5,5,10,0.08) ;

	box-sizing: border-box;
	
	overflow: hidden;

	width: 950px;

	position: relative;

	cursor: default;

}



//
// HEADER

.syncLearnMoreModal-header{
	margin-bottom: 10px;

	padding-left: 65px;
	box-sizing: border-box;

	margin-bottom: 12px;
}

.syncLearnMoreModal-header-headline{
	font-size: 28px;
	font-weight: $lato-heavy;
	color: $textBlackDark;
	letter-spacing: 0.01em;

	margin-bottom: 14px;
	margin-left: -1px; // just so T overhangs
}

.syncLearnMoreModal-header-subline{
	font-size: 18px;
	font-weight: $lato-medium;
	color: lighten($textBlack, 5%);
}


.syncLearnMoreModal-header-subline-p{
	margin-bottom: 7px;
}

.syncLearnMoreModal-header-subline a{
	color: lighten($textMid, 5%);
	cursor: pointer;
}

.syncLearnMoreModal-header-subline a:hover{
	color: $textBlue;
	text-decoration: underline !important;
}

// BUTTON

.syncLearnMoreModal-header-primaryButtonContainer{
	display: flex;
	position: relative;

	margin-top: 40px;
}

.syncLearnMoreModal-header-primaryButton-leftLine{
	position: absolute;
	top: -12px;
	left: -64px;
	width: 62px;
	height: 54px;

}

.syncLearnMoreModal-header-primaryButton-leftLine svg #line{
	stroke: $blue-bold;
}

button.syncLearnMoreModal-header-primaryButton{
	display: flex;
	height: 47px;
	font-weight: $lato-heavy;
	font-size: 19px;

	color: $white;
	background: $blue-bold;
	border: 1px solid darken($blue-bold, 5%);
	border-radius: 5px;

	padding-left: 16px;
	padding-right: 16px;

	align-items: center;
	justify-content: center;

	letter-spacing: 0.01em;

	box-shadow: 0px 2px 4px 0px rgba(0,0,40,0.08);

	cursor: pointer;
	user-select: none;

	
}

button.syncLearnMoreModal-header-primaryButton:hover{
	background: darken($blue-bold, 2%);
	border: 1px solid darken($blue-bold, 8%);
}

.syncLearnMoreModal-header-primaryButton-text{
	position: relative;
	top: -1px;

	max-width: 600px;
	white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

button.syncLearnMoreModal-header-primaryButton 
.syncLearnMoreModal-header-primaryButton-icon svg{
	margin-left: 8px;
	fill: $white;
	opacity: 0.5;

	height: 15px;
	width: 15px;

	position: relative;
	top: 1px;
	right: 4px;

	transition: opacity 100ms linear;
}


button.syncLearnMoreModal-header-primaryButton:hover
.syncLearnMoreModal-header-primaryButton-icon svg{
	opacity: 0.7;
	transition: opacity 50ms linear;
}

//
// BLOCK
//

.syncLearnMoreModal-block{

	display: flex;

	padding-top: 20px;

	border-bottom: 1px solid $lineDark;
}

.syncLearnMoreModal-block:last-child,
.syncLearnMoreModal-block:nth-last-child(2){
	border-bottom: 1px solid $invisible;
}

.syncLearnMoreModal-block-text{
	width: 10px;
	flex-grow: 1;
	flex-shrink: 1;

	padding-top: 108px;


	box-sizing: border-box;
}

.syncLearnMoreModal-block--students
.syncLearnMoreModal-block-text{
	padding-left: 65px;
	padding-right: 18px;
}

.syncLearnMoreModal-block--teachers
.syncLearnMoreModal-block-text{
	padding-right: 65px;
	padding-left: 28px;
}

.syncLearnMoreModal-block--faq
.syncLearnMoreModal-block-text{
	padding-top: 48px;
	padding-left: 65px;
}

.syncLearnMoreModal-block--scoresheet
.syncLearnMoreModal-block-text{
	width: 100%;
	text-align: center;
	padding-top: 48px;
}


//

.syncLearnMoreModal-block-text-header{
	font-size: 24px;
	font-weight: $lato-heavy;
	letter-spacing: 0.01em;
	margin-bottom: 20px;
}

.syncLearnMoreModal-block--scoresheet
.syncLearnMoreModal-block-text-header{
	margin-bottom: 10px;
}

.syncLearnMoreModal-block-text-description{
	font-weight: $lato-medium;
	font-size: 16.5px;
	color: $textDark;
}

.syncLearnMoreModal-block-text-description-p{
	margin-bottom: 18px;
}


//

.syncLearnMoreModal-block-visual{
	
	flex-grow: 0;
	flex-shrink: 0;

	position: relative;

	height: 420px;
	width: 526px;
}

.syncLearnMoreModal-block--scoresheet
.syncLearnMoreModal-block-visual{
	width: 757px;
	height: 298px;
	margin: 0 auto;
}

//

.syncLearnMoreModal-block-visual-screenContainer{

	width: 495px;
	height: 390px;

	position: absolute;
	bottom: 0px;
	
	background: $white;
	border: 1px solid $lineDark;
	border-bottom: none;

	z-index: 5;
}

.syncLearnMoreModal-block--students
.syncLearnMoreModal-block-visual-screenContainer{
	border-top-left-radius: 10px;
	border-right: none;
	right: 0px;
}

.syncLearnMoreModal-block--teachers
.syncLearnMoreModal-block-visual-screenContainer{
	border-top-right-radius: 10px;
	border-left: none;
	left: 0px;
}

.syncLearnMoreModal-block--scoresheet
.syncLearnMoreModal-block-visual-screenContainer{
	border-top-right-radius: 10px;
	border-top-left-radius: 10px;

	left: 50%;
	transform: translateX(-50%);
	width: 680px;
	height: 280px;

}

//
//

.syncLearnMoreModal-block-visual-screen{
	position: absolute;
	bottom: 0px;
	
	width: 480px;
	height: 375px;

	background: $gray-13;
	border: 1px solid $lineMid;
	border-bottom: none;
	
	overflow: hidden;
}

.syncLearnMoreModal-block--students
.syncLearnMoreModal-block-visual-screen{
	right: 0px;
	border-right: none;
	border-top-left-radius: 4px;
	border-bottom-left-radius: 4px;
}

.syncLearnMoreModal-block--teachers
.syncLearnMoreModal-block-visual-screen{
	left: 0px;
	border-left: none;
	border-top-right-radius: 4px;
	border-bottom-right-radius: 4px;
}

.syncLearnMoreModal-block--scoresheet
.syncLearnMoreModal-block-visual-screen{
	height: initial;
	width: initial;
	left: 15px;
	right: 15px;
	top: 15px;
	border-top-right-radius: 4px;
	border-top-left-radius: 4px;
}

//
//

.syncLearnMoreModal-block-visual video{
	object-fit: cover;
	position: absolute;
	top: 0px;
	left: 0px;
	height: 100%;	
}

//

.syncLearnMoreModal-block-visual-bg{
	position: absolute;

	top: 0px;
	left: 0px;
	right: 0px;
	bottom: 0px;

}

.syncLearnMoreModal-block-visual-bg svg{
	width: 100%;
	height: 100%;
	opacity: 0.1;
}

.syncLearnMoreModal-block--students
.syncLearnMoreModal-block-visual-bg svg{
	fill: $green;
}

.syncLearnMoreModal-block--teachers
.syncLearnMoreModal-block-visual-bg svg{
	fill: $blue;
}

.syncLearnMoreModal-block--scoresheet
.syncLearnMoreModal-block-visual-bg svg{
	fill: $classColor-pink;
}

//
// SCORESHEET

.syncLearnMoreModal-block--scoresheet{
	display: block;
}

//
// HELP VIDEOS

.syncLearnMoreModal-block--helpVideo{
	display: flex;
	box-sizing: border-box;
	padding-left: 65px;
	padding-right: 65px;
	padding-bottom: 40px;
}

.syncLearnMoreModal-block-helpVideoContainer{
	width: 818px;
	height: 511px;
	border-radius: 4px;
	overflow: hidden;
	box-shadow: 0px 4px 8px 0px rgba(0,0,40,0.25);
	position: relative;
}


iframe.syncLearnMoreModal-block-helpVideo{	
	position: absolute;
	// top: -1px;
	// left: -1px;
	// right: -1px;
	// bottom: -1px;
	
	top: -1px;
	bottom: -1px;
	left: -1px;
	right: -1px;
	width: calc(100% + 2px);
	height: calc(100% + 2px);
}


//
// FAQ

.syncLearnMoreModal-block--faq{
	display: block;
}

.syncLearnMoreModal--activateMode
.syncLearnMoreModal-block--faq{
	border-bottom: none; // video is just underneath
}

.syncLearnMoreModal-block-faqContainer{
	display: flex;
	flex-wrap: wrap;
	width: calc(100% - 65px - 65px);
	margin-left: 65px;
	margin-top: 32px;
}

.syncLearnMoreModal-block-faqItem{
	margin-bottom: 20px;
	width: 50%;
	box-sizing: border-box;
	padding-right: 70px;
}

.syncLearnMoreModal-block-faqItem-title{
	font-size: 16px;
	font-weight: $lato-bold;
	color: $textBlackDark;
	margin-bottom: 8px;
	letter-spacing: 0.01em;
}

.syncLearnMoreModal-block-faqItem-description{
	font-size: 14.5px;
	font-weight: $lato-medium;
	color: $textBlack;
	margin-bottom: 7px;
	letter-spacing: 0.005em;
}


//
// DONE BTN

.syncLearnMoreModal-block--doneBtn{
	// border-bottom: none;
	display: flex;
	align-items: center;
	justify-content: center;
	padding-top: 24px;
	padding-bottom: 24px;

}

button.syncLearnMoreModal-notNowBtn{
	display: flex;
	height: 47px;
	font-weight: $lato-heavy;
	font-size: 19px;

	color: $textDark;
	background: $white;
	border: 1px solid $gray-125;
	border-radius: 5px;

	padding-left: 32px;
	padding-right: 32px;

	align-items: center;
	justify-content: center;

	letter-spacing: 0.01em;

	box-shadow: 0px 2px 4px 0px rgba(0,0,40,0.04);

	cursor: pointer;
	user-select: none;

	margin-left: 16px;
}

button.syncLearnMoreModal-notNowBtn:hover{
	border: 1px solid $gray-12;
	color: $textBlack;
}

// LEARN MORE
.syncLearnMoreModal-block--helpBtn{
	border-top: none;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	padding-top: 0px;
	margin-top: -10px;
}

a.syncLearnMoreModal-helpLink,
a.syncLearnMoreModal-helpLink:hover,
a.syncLearnMoreModal-helpLink:focus,
a.syncLearnMoreModal-helpLink:visited{
	
	height: 36px;
	padding-left: 14px;
	padding-right: 14px;
	background: $invisible;
	border-radius: 4px;

	font-size: 18px;
	font-weight: $lato-bold;

	color: $blue;

	display: flex;
	align-items: center;
	justify-content: center;
}

a.syncLearnMoreModal-helpLink:hover{
	background: $blue-extraLight;
	color: darken($blue, 3%);
}
//
// HELP LINK





// .syncBetaModal-videoTestContainer{
// 	position: absolute;
// 	top: 50px;
// 	right: 0px;
// 	padding-left: 24px;
// 	padding-top: 24px;
// 	padding-bottom: 24px;
// 	background: $tempb;
// 	display: flex;
// 	align-items: center;
// 	justify-content: flex-end;

// 	border-radius: 5px;
// }

// .syncBetaModal-videoTest{
	
// 	border-radius: 4px;
// 	width: 479px;
// 	height: 374px;

// 	overflow: hidden;

// 	position: relative;
// }

// .syncBetaModal-videoTestContainer img{
// 	width: 100%;
// 	height: 100%;

// }

// .syncBetaModal-videoTest video.syncBetaModal-videoTest-video{
// 	position: absolute;
// 	top: 0px;
// 	left: 0px;
// 	height: 100%;

// }

// //

// .syncBetaModal-welcome{
	
// 	font-size: 20px;
// 	font-weight: $effra-medium;
// 	color: $textBlue;
// 	margin-bottom: 26px;
// 	text-align: center;
// }

// .syncBetaModal-title{
// 	font-size: 36px;
// 	font-weight: $effra-bold;
// 	color: $textBlack;
// 	margin-bottom: 14px;
// 	text-align: center;
// }

// .syncBetaModal-tagline{
// 	font-size: 22px;
// 	font-weight: $effra-medium;
// 	color: $textLight;
// 	text-align: center;

// 	margin-bottom: 12px;

// }

// .syncBetaModal--tutorial
// .syncBetaModal-tagline{
// 	margin-top: -32px;
// 	opacity: 0.5;
// }


// //

// .syncBetaModal-visual{
// 	width: 100%;
// 	height: 500px;
// 	background: $tempr;
// }

// //

// .syncBetaModal-getStarted{
// 	padding-top: 12px;
// 	display: flex;
// 	justify-content: center;
// }

// .syncBetaModal--initialLaunch
// .syncBetaModal-getStarted{
// 	padding-top: 40px;
// }

// .syncBetaModal-getStarted-stepsContainer{
// 	display: flex;
// 	width: calc(100% - 180px);
// }


// .syncBetaModal-getStarted-step{
// 	width: 33%;
// 	box-sizing: border-box;

// 	padding: 18px;
// }


// .syncBetaModal-getStarted-step-number{
// 	font-size: 17px;
// 	font-weight: $effra-bold;
// 	color: lighten($textLight, 7%);
// 	margin-bottom: 3px;
// }

// .syncBetaModal-getStarted-step-title{
// 	font-size: 21px;
// 	font-weight: $effra-bold;
// 	color: $textBlackDark;
// 	margin-bottom: 5px;
// }

// .syncBetaModal-getStarted-step-description{
// 	font-size: 16px;
// 	font-weight: $effra-regular;
// 	color: $textBlack;
// 	margin-bottom: 8px;
// }


// //

// .syncBetaModal-initialButtonContainer{
	
	

// 	display: flex;
// 	align-items: center;
// 	justify-content: center;

	
// 	width: 800px;
// 	margin: 0 auto;

// 	margin-top: 150px;
// }

// .syncBetaModal-initialButtonContainer--bottom{
// 	margin-top: 25px;
// }

// .syncBetaModal-doneButtonContainer{
// 	margin-top: 20px;
// 	width: 100%;
// 	display: flex;
// 	align-items: center;
// 	justify-content: center;
// }

// .syncBetaModal button.btn.btn--mid.syncbetamodaljoinbutton,
// .syncBetaModal button.btn.btn--mid.syncbetamodalrejectbutton,
// .syncBetaModal button.btn.btn--mid.syncbetamodaldonebutton{
// 	margin: 0 auto;

// 	width: 500px;
// 	height: 64px;
// 	background: $blue;
// 	border: 1px solid $blue-bold; 
// 	color: $white;

// 	display: flex;
// 	align-items: center;
// 	justify-content: center;

// 	font-size: 25px;
// 	font-weight: $effra-bold;

// 	border-radius: 8px;

// 	cursor: pointer;

// 	box-shadow: 
// 		0px 4px 8px 0px rgba(0,0,0,0.12),
// 		0px 2px 4px 0px rgba(0,0,0,0.04),
// 		inset 0px 0px 20px rgba(0,0,0,0.03);
// }

// .syncBetaModal button.btn.btn--mid.syncbetamodaldonebutton:hover,
// .syncBetaModal button.btn.btn--mid.syncbetamodaljoinbutton:hover{
// 	background: darken($blue, 2%);
// 	box-shadow: 
// 		0px 4px 8px 0px rgba(0,0,0,0.18),
// 		0px 2px 4px 0px rgba(0,0,0,0.08);
// }

// .syncBetaModal button.btn.btn--mid.syncbetamodaljoinbutton,
// .syncBetaModal button.btn.btn--mid.syncbetamodalrejectbutton{
// 	flex-grow: 1;
// 	font-size: 23px;
// 	margin: 5px;
// }

// .syncBetaModal button.btn.btn--mid.syncbetamodalrejectbutton{
// 	margin-top: 5px;
// 	background: $white;
// 	border: 1px solid $lineDark;

// 	color: $textBlack;
// }

// //

// .syncBetaModal-videoContainer{
// 	width: 1027px;
// 	height: 582px;
// 	margin: 0 auto;
// 	margin-top: 48px;
// 	position: relative;
// 	border-radius: 4px;
// 	overflow: hidden;
// 	border: 1px solid $gray-9;
// }

// .syncBetaModal--tutorial
// .syncBetaModal-videoContainer{
// 	margin-top: 0px;
// }

// .syncBetaModal-video{
// 	position: absolute;
// 	top: 0px;
// 	left: 0px;
// 	width: 100%;
// 	height: 100%;
// }

// //

// .syncBetaModal-FAQContainer{
// 	display: flex;
// 	flex-wrap: wrap; 
// 	width: calc(100% - 180px);
// 	margin-left: 90px;
// 	margin-top: 48px;
// }

// .syncBetaModal-FAQItem{
// 	margin-bottom: 20px;
// 	width: 50%;
// 	box-sizing: border-box;
// 	padding-right: 100px;

// }

// .syncBetaModal-FAQItem-title{
// 	font-size: 18px;
// 	font-weight: $effra-bold;
// 	color: $textBlackDark;
// 	margin-bottom: 8px;
// }

// .syncBetaModal-FAQItem-description{
// 	font-size: 15px;
// 	font-weight: $effra-regular;
// 	color: $textDark;
// 	margin-bottom: 4px;
// }




.syncLearnMoreModal-closeBtn{
	position: absolute;
	width: 32px;
	height: 32px;
	top: 20px;
	right: 24px;
	cursor: pointer;
	border-radius: 16px;

	opacity: 0.4;
	transition: opacity 100ms linear;
}

.syncLearnMoreModal-closeBtn:hover{
	opacity: 0.8;
	transition: opacity 50ms linear;
}

.syncLearnMoreModal-closeBtn:active{
	opacity: 1;
}

.syncLearnMoreModal-closeBtn .icon{
	width: 32px;
	height: 32px;
	fill: $gray-6;
}
