//
// GRID COLUMN TOGGLE
// Toggle in e.g. Set Detail to change # of colums
//

.gridColumnToggle{
	height: 32px;	
	width: 34px;
	display: flex;
}

.gridColumnToggle-toggle{
	width: 100%;
}

.gridColumnToggle-toggle .icon{
	fill: $gray-12;
}

.gridColumnToggle-toggle:hover .icon{
	fill: $gray-9;
}

.gridColumnToggle.tooltip[data-tooltip]:after{
	margin-top: -2px;
}