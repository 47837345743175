//
// MOBILE UPDATE NOTIFICATION
//

.mobileUpdateNotification{
	position: fixed;
	z-index: 9990;	

	right: 8px;
	bottom: 8px;

	width: 296px;
	background: $white;	

	border-radius: 5px;
	border: 1px solid $lineDark;	
	border-bottom: 1px solid darken($lineDark, 5%);
	border-left: 8px solid red;

	box-shadow: 0px 2px 4px 0px rgba(0,0,20,0.12);

	box-sizing: border-box;
	padding-left: 18px;
	padding-right: 20px;
	padding-top: 9px;
	padding-bottom: 12px;

	cursor: default;
	user-select: none;
}

.mobileUpdateNotification--hide{
	pointer-events: none;
}


.mobileUpdateNotification--level--warning{
	border-left-color: $classColor-orange;
}

.mobileUpdateNotification--level--error{
	border-left-color: $red--notification;
}

.mobileUpdateNotification-emoji{
	width: 30px;
	height: 30px;

	margin-bottom: 8px;

	position: relative;
	left: -1px;
}

.mobileUpdateNotification-emoji img{
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.mobileUpdateNotification-header{
	font-size: 16px;
	font-weight: $lato-bold;
	letter-spacing: 0.005em;
	color: $textBlackDark;

	margin-bottom: 2px;
}

.mobileUpdateNotification-message{
	font-size: 15px;
	font-weight: $lato-medium;
	color: $textBlack;
	letter-spacing: 0.005em;

	margin-bottom: 12px;
}

.mobileUpdateNotification-buttonRow{
	display: flex;
}

button.mobileUpdateNotification-buttonRow-dismissBtn,
a.mobileUpdateNotification-buttonRow-helpLink{
	display: flex;
	align-items: center;
	justify-content: center;

	height: 32px;
	min-width: 72px;
	padding-left: 15px;
	padding-right: 15px;

	box-shadow: none;
	border: none;
	outline: none;

	cursor: pointer;

	border-radius: 3px;
}

button.mobileUpdateNotification-buttonRow-dismissBtn{
	background: $blue-extraLight;

	color: darken($textBlue, 5%);
	font-weight: $lato-bold;
	font-size: 15px;	
	letter-spacing: 0.005em;

	margin-right: 4px;
}

button.mobileUpdateNotification-buttonRow-dismissBtn:hover{
	background: lighten($blue, 42%);
	color: darken($textBlue, 8%);
}

a.mobileUpdateNotification-buttonRow-helpLink{
	background: none;
	color: $textMid;

	font-weight: $lato-medium;
	font-size: 15px;
	letter-spacing: 0.005em;
}

a.mobileUpdateNotification-buttonRow-helpLink:hover{
	color: $textBlack;
	background: $gray-15;
}