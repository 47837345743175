//
// PAGE HEADER - RIGHT BADGE
// right-badge is less common than left-badge
//

.pageHeaderTop-headerRow-rightBadge{	
	padding-left: 12px;
	flex-grow: 0;
	flex-shrink: 0;
}

//
// SHARED REPO

.pageHeaderTop--repo .pageHeaderTop-headerRow-rightBadge{
	margin-right: -7px;
	cursor: default !important; // don't make look clickable
}

.pageHeaderTop--repo .pageHeaderTop-headerRow-rightBadge .icon.shared,
.pageHeaderTop--repo .pageHeaderTop-headerRow-rightBadge .icon.globe-shared,
.pageHeaderTop--repo .pageHeaderTop-headerRow-rightBadge .icon.globe-shared-author{
	width: 30px;
	height: 30px;
	fill: $gray-125;
	position: relative;
	top: 5px;
}

.pageHeaderTop--repo .pageHeaderTop-headerRow-rightBadge .icon.globe-shared-author{
	fill: $color-o85;
}

.page-centerPanel.repo.repoPageLoader
.pageHeaderTop--repo .pageHeaderTop-headerRow-rightBadge .icon.globe-shared{
	fill: $gray-14;
}

//
// QUESTION COUNT

.pageHeaderTop-headerRow-rightBadge .questionCountBadge{
	width: 46px;
	height: 31px;
	border-radius: 4px;
	font-size: 23px;
	line-height: 1.35;
	position: relative;
	top: 7px;
	user-select: none;
}

//
// AUTHOR PAGE

.pageHeaderTop--authorPage .pageHeaderTop-headerRow-rightBadge{
	position: absolute;
	top: 0px;
	right: 0px;
}

//
// SCORE PILL

.pageHeaderTop-headerRow-rightBadge
.scorePill.scorePill--mid.type--dot{
	//background: $tempr;
	font-size: 24px;
	height: 32px;
	width: 80px;
	top: 6px;
	position: relative;
	user-select: none;
	cursor: default;
}

.pageHeaderTop-headerRow-rightBadge
.scorePill.scorePill--mid.type--dot
.scorePill-dot{
	width: 10px;
	height: 10px;
	border-radius: 5px;
	top: -3px;
	margin-right: 7px;
}

.pageHeaderTop-headerRow-rightBadge
.scorePill.scorePill--mid .scorePill-percentSign{
	color: $textMid;
}

//
// STICKY HEADER SUPPORT

.pageHeaderSticky-actionGroup
.scorePill.scorePill--mid.type--dot{
	font-size: 24px;
	height: 32px;
	width: 80px;
	top: 0px;
	position: relative;
	user-select: none;
	cursor: default;
}

.pageHeaderSticky-actionGroup
.scorePill.scorePill--mid.type--dot
.scorePill-dot{
	width: 10px;
	height: 10px;
	border-radius: 5px;
	top: -3px;
	margin-right: 7px;
}

.pageHeaderSticky-actionGroup
.scorePill.scorePill--mid .scorePill-percentSign{
	color: $textMid;
}