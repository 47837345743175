.helpVideoModalContainer{
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 900;
	
	display: flex;
	align-items: center;
	justify-content: center;	
}

.helpVideoModalContainer--BG{
	position: absolute;
	top: 0; left: 0; bottom: 0; right: 0;

	background: rgba(0,0,0,0.75);
	z-index: 0;
}

.helpVideoModal{

	overflow: hidden;
	border-radius: 5px;

	width: 720px;
	height: 405px;	

	position: relative;
	z-index: 5;

	background: white;
	box-shadow: 0px 8px 16px 0px rgba(0,0,10,0.12), 0px 24px 48px 0px rgba(0,0,10,0.08);
}

.helpVideoModal-video{
	position: absolute;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;
}