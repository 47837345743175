//
// SCORESHEET CUSTOM RANGE MODAL
// clicking on custom in Scoresheet topbar will open this
// primarily composed of a react-dates date-range-picker component
//

.scoreSheet-customRangeModalContainer .modal-content{
	// note this is using the modal component which adds a modal-backdrop parent fixed div
	width: 1040px;
	height: 450px;
	overflow: hidden;

	box-sizing: border-box;
	position: relative;

	background: $gray-15;
}

.scoreSheet-customRangeModal{
	width: 100%;
	height: 100%;

	display: flex;
	flex-direction: column;
}

//
.scoreSheet-customRangeModal-header{
	width: 100%;
	height: 80px;

	flex-grow: 0;
	flex-shrink: 0;

	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	border-bottom: 1px solid $lineMid;
	box-shadow: 0px 2px 4px 0px rgba(0,0,40,0.08);
}


.scoreSheet-customRangeModal-header-label{
	text-align: center;
	font-size: 17px;
	font-weight: $lato-bold;
	margin-bottom: 4px;
	color: $blue;
}

.scoreSheet-customRangeModal-header-range{
  text-align: center;
  font-size: 20px;
  font-weight: $lato-heavy;
  color: $textBlackDark;
  display: flex;
}

.scoreSheet-customRangeModal-header-range div{
  border-radius: 3px;
  padding: 5px;
}

.scoreSheet-customRangeModal-header-range-date--highlighted{
  color: $highlight--4-textColor;
  background: $highlight--4-bgColor;
}

.scoreSheet-customRangeModal-header .btn.cancel{
	position: absolute;
	right: 20px;
	top: 14px;
	min-width: 87px;
}
.scoreSheet-customRangeModal-header .btn.select-all{
	position: absolute;
	left: 20px;
	top: 14px;
	min-width: 87px;
}

// IMPORT CALENDAR STYLING

@import 'ScoreSheet-CustomRangeModal-Calendar.scss';

// FOOTER BUTTON

.scoreSheet-customRangeModal-footer{
	height: 80px;
	flex-grow: 0;
	flex-shrink: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	box-sizing: border-box;
	border-top: 1px solid $lineMid;
}

.scoreSheet-customRangeModal-footer button.btn{
	width: 250px;
	min-width: 250px;
}

.scoreSheet-customRangeModal-footer button.btn.btn--disabled{
	opacity: 1;
}
