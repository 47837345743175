//
// SCORESHEET FOOTER ROW
// this is actually just underneath the header-row, might make sense to rename to score row or something 
//

.scoreSheet-table-footerRow{  
  position: absolute;
  top: calc(#{$height-scoreSheetSetHeader} + #{$height-scoreSheetQuestionHeader});
  left: 0px;  
  display: flex;
  background: $white;
}

.scoreSheet-table-footerRow-footerCellGroup{  
 // width: 100%;
  display: flex;
}


//

.scoreSheet-table-footerRow-footerCell{
  cursor: pointer;
  user-select: none;
  border-top: 1px solid $gray-12;
  border-bottom: 1px solid $scoreSheet-lineMid;

  height: $height-scoreSheetSetFooter;
  flex-shrink: 0;
  width: $width-scoreSheetQuestion;
  min-width: $width-scoreSheetQuestion;
  
  border-right: 1px solid $scoreSheet-lineMid;

  position: relative;
  background: $white;
}

// COLLAPSED

.scoreSheet-table-footerRow-footerCell--collapsed{
  width: $width-scoreSheetCollapsedColumn;

}

.scoreSheet-table-footerRow-footerCell--collapsed .scoreSheet-table-footerRow-footerCell-label,
.scoreSheet-table-footerRow-footerCell--collapsedQuestion .scoreSheet-table-footerRow-footerCell-label{
  text-align: right;
  box-sizing: border-box;
  padding-right: 9px;
}

//

.scoreSheet-table-footerRow-footerCell-label{
  position: absolute;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
  z-index: 5;

  text-align: center;

  font-size: 14px;
  font-weight: $lato-semibold;
  color: $textBlack;

  line-height: 1.8;
}

.scoreSheet-table-footerRow-footerCell-label--survey{
  color: $textLight;
}

span.scoreSheet-table-footerRow-footerCell-label-percent{
  opacity: 0.75;
  margin-left: 0.1em;
}

.scoreSheet-table-footerRow-footerCell-label-primary--disabled{
  opacity: 0.4;
  margin-right: 2px;
}

// INTERACTION

.scoreSheet-table-footerRow-footerCell:hover{  
  background: $scoresheet-excluded-BG-hover;
}

// .scoreSheet-table-footerRow-footerCellGroup
// .scoreSheet-table-footerRow-footerCell:active{
//   background: $color-scoreSheetCellActive-bg;
// }



// EXCLUDED

.scoreSheet-table-footerRow-footerCell--isExcluded,
.scoreSheet-table-footerRow-footerCell--isExcluded:hover{
  background: $scoresheet-excluded-BG;
}

.scoreSheet-table-footerRow-footerCell--isExcluded
.scoreSheet-table-footerRow-footerCell-label{
  color: $scoresheet-excluded-color;
}


.scoreSheet-table-footerRow-footerCell-label-secondary{
  margin-left: 4px;
}

//

.scoreSheet-table-footerRow-footerCell-hideIndicator{
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  font-size: 14px;
  font-weight: $lato-semibold;
  background: $gray-14;
  z-index: 100;

  color: $textMid;

  text-align: center;
  line-height: 1.8;
}

.scoreSheet-table-footerRow-footerCell:hover
.scoreSheet-table-footerRow-footerCell-hideIndicator{
  opacity: 1;
  transition: opacity 0ms linear;
  transition-delay: 500ms;
}

.scoreSheet-table-footerRow-footerCell
.scoreSheet-table-footerRow-footerCell-hideIndicator{
  opacity: 0;
  transition: opacity 0ms linear;
  transition-delay: 0ms;
}


.scoreSheet-table-footerRow-footerCell--isExcluded
.scoreSheet-table-footerRow-footerCell-hideIndicator{
  display: none;
}



// WITH SCOREPILL

// .scoreSheet-table-footerRow-footerCell:hover
// .scoreSheet-table-footerRow-footerCell-label{
//   color: $white;
//   font-weight: $lato-bold;
// }

// .scoreSheet-table-footerRow-footerCell:hover
// span.scoreSheet-table-footerRow-footerCell-percent{
//   color: $white;
//   opacity: 0.75;
// }

// .scoreSheet-table-footerRow-footerCell .scorePill{
//   position: absolute;
//   top: 0px;
//   left: 0px;
//   height: 100%;
//   width: 100%;
//   visibility: hidden;
// }

// .scoreSheet-table-footerRow-footerCell:hover .scorePill{
//   visibility: visible;
// }