
.autoPlaySetupContainer--page{
	position: fixed;
	top: 0px;
	left: 0px;
	bottom: 0px;
	right: 0px;

	z-index: 9000;

	display: flex;
	justify-content: center;
	align-items: center;

	background: rgb(250,250,255);
}

.autoPlaySetupContainer--modal{
	position: fixed;
	top: 0px;
	left: 0px;
	bottom: 0px;
	right: 0px;

	z-index: 9000;

	display: flex;
	justify-content: center;
	align-items: center;

	background: rgba(26,28,34,0.85);
}

.autoPlaySetupContainer-bgClick{
	position: absolute;
	top: 0px;
	left: 0px;
	bottom: 0px;
	right: 0px;
}

.autoPlaySetup{
	height: 620px;
	width: 1080px;

	border: 1px solid $lineDark;
	border-bottom: 1px solid darken($lineDark, 5%);

	box-shadow: 0px 4px 8px 0px rgba(0,0,20,0.06), 0px 0px 16px 0px rgba(0,0,20,0.02);

	border-radius: 6px;
	background: $white;

	overflow: hidden;

	box-sizing: border-box;
	padding-left: 64px;

	position: relative;
	z-index: 10;
}

//

.autoPlaySetup-h1{
	margin-top: 40px;

	font-size: 28px;
	font-weight: $lato-heavy;
	color: $textBlack;

	letter-spacing: 0.01em;

	width: 70%;

	cursor: default;

}


.autoPlaySetup-initialBrowser-h2,
.autoPlaySetup-selected-h2{
	font-size: 21px;
	font-weight: $lato-heavy;
	color: $textBlack;

	letter-spacing: 0.01em;
}


// UNSELECTED BUTTON ARRAY

.autoPlaySetup-initialBrowser-h2{
	margin-top: 64px;
	margin-bottom: 20px;

	cursor: default;
}

.autoPlaySetup-initialBrowser-btnArray{
	display: flex;
}

button.autoPlaySetup-initialBrowser-btnArray-button{
	width: 125px;
	height: 100px;

	margin-right: 14px;

	background: none;
	border: none;
	box-shadow: none;

	border: 1px solid $lineDark;
	border-bottom: 1px solid darken($lineDark, 5%);
	box-shadow: 0px 1px 2px 0px rgba(0,0,0,0.04);

	border-radius: 4px;

	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	cursor: pointer;

	box-sizing: border-box;
	padding-top: 4px;


}

.autoPlaySetup-initialBrowser-btnArray-button-iconContainer{
	width: 100%;
	height: 50px;
	width: 50px;
}

button.autoPlaySetup-initialBrowser-btnArray-button img{
	width: 100%;
	height: 100%;
	object-fit: contain;
}

.autoPlaySetup-initialBrowser-btnArray-button-label{
	font-size: 15.5px;
	font-weight: $lato-bold;
	color: $textBlack;
	letter-spacing: 0.02em;

	margin-top: 8px;
}

button.autoPlaySetup-initialBrowser-btnArray-button:hover{
	border: 1px solid $lineDark;
	border-bottom: 1px solid darken($lineDark, 5%);
	box-shadow: 0px 1px 2px 0px rgba(0,0,0,0.12);
	background: rgb(253,253,255);

	background: #f7fbff;
	border: 1px solid $blue;
	border-bottom: 1px solid $blue;
}

//
// SELECTED BUTTON ARRAY


.autoPlaySetup-selectedBrowser-btnArray{
	display: flex;
	margin-top: 24px;
}

button.autoPlaySetup-selectedBrowser-btnArray-button{
	height: 36px;
	padding-left: 14px;
	padding-right: 14px;

	background: none;
	border: none;
	box-shadow: none;

	border-radius: 5px;

	display: flex;
	align-items: center;
	justify-content: center;

	cursor: pointer;
}

button.autoPlaySetup-selectedBrowser-btnArray-button--active{
	background: $gray-15;
	border: 1px solid $lineMid;
	opacity: 1;
}

button.autoPlaySetup-selectedBrowser-btnArray-button--inactive{
	opacity: 0.5;
	border: 1px solid $invisible;
}

button.autoPlaySetup-selectedBrowser-btnArray-button--inactive:hover{
	opacity: 0.9;
}

.autoPlaySetup-selectedBrowser-btnArray-button-iconContainer{
	width: 16px;
	height: 16px;
}

button.autoPlaySetup-selectedBrowser-btnArray-button img{
	width: 100%;
	height: 100%;
	object-fit: contain;
}

.autoPlaySetup-selectedBrowser-btnArray-button-label{
	font-size: 15.5px;
	font-weight: $lato-bold;
	color: $textBlack;
	letter-spacing: 0.015em;

	margin-left: 6px;
}



.autoPlaySetup-selected-h2{
	margin-top: 36px;

	width: 100%;
	border-bottom: 1px solid $lineDark;
	padding-bottom: 8px;
}

// BROWSER INSTRUCTIONS

.autoPlaySetup-selectedBrowser-browserInstructions{
	width: 380px;

}


.autoPlaySetup-selectedBrowser-browserInstructions-step{
	margin-top: 14px;
	position: relative;

	cursor: default;
}

.autoPlaySetup-selectedBrowser-browserInstructions-step-number{
	position: absolute;
	top: -1px;
	left: -34px;

	width: 24px;
	height: 22px;

	border-radius: 4px;

	background: $blue-extraLight;

	display: flex;
	align-items: center;
	justify-content: center;

	font-size: 15px;
	font-weight: $lato-bold;
	color: $textBlue;
}


.autoPlaySetup-selectedBrowser-browserInstructions-step-label{
	font-size: 16px;
	font-weight: $lato-semibold;
	color: $textBlack;
	letter-spacing: 0.001em;
	line-height: 1.4;
}

.autoPlaySetup-selectedBrowser-browserInstructions-step-label emph{
	font-weight: $lato-bold;
	color: $textBlackDark;
}

.autoPlaySetup-selectedBrowser-browserInstructions-step-label svg{
	width: 13px;
	height: 13px;
	margin-left: 2px;
	fill: $gray-2;
}

.autoPlaySetup-selectedBrowser-browserInstructions-step-label svg.editorIcon-playbackVolume{
	width: 16px;
	height: 16px;
	position: relative;
	top: 3px;
	margin-right: 1px;
}

.autoPlaySetup-selectedBrowser-browserInstructions-step-label svg.editorIcon-firefoxSettings{
	width: 15px;
	height: 15px;
	position: relative;
	top: 1px;
	margin-right: 1px;
}

.autoPlaySetup-selectedBrowser-browserInstructions-step-label svg.editorIcon-firefoxAutoplay{
	width: 16px;
	height: 16px;
	position: relative;
	top: 2px;
	margin-left: 3px;
	margin-right: 1px;
}

.autoPlaySetup-selectedBrowser-browserInstructions-step-label svg.three-dots-horizontal{
	width: 13px;
	height: 13px;
	position: relative;
	top: 1px;
	margin-right: 1px;
}


.autoPlaySetup-selected-videoContainer{
	position: absolute;
	bottom: 0px;
	right: 0px;
	width: 600px;
	height: 450px;

	background: $white;

	overflow: hidden;
	border-top-left-radius: 5px;

	border: 1px solid $lineDark;
	box-shadow: 0px 4px 8px 0px rgba(0,0,20,0.08);
}

video.autoPlaySetup-selected-video {
	position: absolute;
	top: 0px;
	left: 0px;

	width: 100% !important; 
	height: auto !important;
	background: $gray-2;
}

video.autoPlaySetup-selected-video--macChrome{
	width: 130% !important;
}

video.autoPlaySetup-selected-video--macFirefox{
	width: 110% !important;
}

video.autoPlaySetup-selected-video--macSafari{
	width: 110% !important;
}

video.autoPlaySetup-selected-video--winChrome{
	width: 120% !important;
	left: -3px;
}

video.autoPlaySetup-selected-video--winFirefox{
	width: 110% !important;
	left: -3px;
}

// Win Edge
// full screen video

.autoPlaySetup-selected-videoContainer--winEdge{
	width: 694px;
}

video.autoPlaySetup-selected-video--winEdge{
	width: 101% !important;
	left: -3px;
}

//
// TOP RIGHT
// OS selection and close button
.autoPlaySetup-topRightContainer{
	position: absolute;
	top: 12px;
	right: 0px;

	display: flex;
	align-items: center;
	justify-content: flex-end;
}


.autoPlaySetup-osToggle{
	display: flex;
	margin-right: 24px;

}

button.autoPlaySetup-osToggle-toggle{

	height: 40px;
	padding-left: 8px;
	padding-right: 8px;

	font-size: 13px;
	font-weight: $lato-heavy;
	letter-spacing: 0.03em;
	color: $textBlack;
	text-transform: uppercase;

	background: none;
	border: none;
	box-shadow: none;

	// background: $tempr;

	cursor: pointer;
	user-select: none;



}

button.autoPlaySetup-osToggle-toggle--inactive{
	opacity: 0.4;
	font-weight: $lato-bold;
}

button.autoPlaySetup-osToggle-toggle--inactive:hover{
	opacity: 0.7;
}


button.autoPlaySetup-closeBtn{
	
	margin-right: 12px;

	width: 40px;
	height: 40px;

	background: $gray-13;
	border: none;
	box-shadow: none;

	border-radius: 5px;

	opacity: 0.6;

	display: flex;
	align-items: center;
	justify-content: center;

	cursor: pointer;

}

button.autoPlaySetup-closeBtn svg{
	width: 20px;
	height: 20px;

	fill: $gray-5;
}

button.autoPlaySetup-closeBtn:hover{
	opacity: 0.9;
}

button.autoPlaySetup-closeBtn:active{
	opacity: 1;
}

//
// DONE BUTTON

button.autoPlaySetup-doneBtn{
	position: absolute;
	bottom: 36px;
	left: 64px;

	width: 320px;
	height: 64px;

	display: flex;
	align-items: center;
	justify-content: center;

	font-size: 26px;
	font-weight: $lato-bold;
	letter-spacing: 0.02em;

	color: $white;
	background: $blue;
	border: 1px solid darken($blue, 5%);
	box-shadow: 0px 2px 4px 0px rgba(0,0,20,0.12);

	border-radius: 5px;

	cursor: pointer;
}

button.autoPlaySetup-doneBtn:hover{
	background: darken($blue, 2%);
	border: 1px solid darken($blue, 8%);
}


///
/// ALERT



.liveView-autoPlayFailedAlert{
	position: fixed;
	width: 310px;	
	background: $white;	

	bottom: 10px;
	right: 10px;

	z-index: 9990;

	border: 1px solid $lineDark;
	box-shadow: 0px 4px 8px 0px rgba(0,0,20,0.12), 0px 0px 3px 0px rgba(0,0,20,0.04);

	border-radius: 6px;	
	
	user-select: none;

	box-sizing: border-box;
	padding: 16px;
	padding-top: 10px;
	padding-bottom: 12px;
}

.liveView-autoPlayFailedAlert--hidden{
	pointer-events: none;	
}


.liveView-autoPlayFailedAlert-top{
	display: flex;
	width: 100%;
	justify-content: space-between;
	align-items: center;

	margin-bottom: 8px;
}

.liveView-autoPlayFailedAlert-top-hand{
	height: 30px;
	width: 30px;
}

.liveView-autoPlayFailedAlert-top-hand img {
	width: 100%;
	height: 100%;
}

button.liveView-autoPlayFailedAlert-top-dismiss{

	display: flex;
	align-items: center;
	justify-content: center;

	margin-right: -16px;
	margin-top: -10px;

	padding-top: 8px;
	padding-right: 8px;
	padding-left: 4px;
	padding-bottom: 4px;

	background: none;
	border: none;
	box-shadow: none;

	height: 40px;
	width: 40px;

	cursor: pointer;

	// background: $tempr;
}

.liveView-autoPlayFailedAlert-top-dismiss-inner{
	
	width: 100%;
	height: 100%;

	border-radius: 3px;

	background: $tempr;
	background: $gray-13;

	display: flex;
	align-items: center;
	justify-content: center;

	opacity: 0.6;
}

.liveView-autoPlayFailedAlert-top-dismiss-inner svg{
	height: 14px;
	width: 14px;

	fill: $gray-4;
}

button.liveView-autoPlayFailedAlert-top-dismiss:hover
.liveView-autoPlayFailedAlert-top-dismiss-inner{
	opacity: 1;
}

//

.liveView-autoPlayFailedAlert-h1{
	font-weight: $lato-heavy;
	color: $textBlack;	
	font-size: 17px;

	letter-spacing: 0.01em;

	margin-bottom: 6px;

	box-sizing: border-box;
	padding-right: 14px;
}


.liveView-autoPlayFailedAlert-h2{
	font-weight: $lato-semibold;
	color: lighten($textMid, 10%);
	font-size: 14px;

	letter-spacing: 0.005em;

	margin-bottom: 2px;

	
}

button.liveView-autoPlayFailedAlert-openModal{
	color: $white;
	font-weight: $lato-heavy;
	font-size: 16px;

	background: $blue;
	box-shadow: none;
	border: none;

	letter-spacing: 0.02em;

	// background: $tempr;

	padding-left: 12px;
	padding-right: 12px;

	height: 32px;
	display: flex;
	align-items: center;
	justify-content: center;

	border-radius: 5px;

	cursor: pointer;

	margin-top: 24px;

	// background: $tempr;
}

button.liveView-autoPlayFailedAlert-link:hover{
	background: darken($blue, 2%);
}

button.liveView-autoPlayFailedAlert-link:active{
	color: darken($blue, 4%);
}

