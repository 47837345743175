//
// SECTION HOME
// Many different SCSS partials are imported into this, one for each section
// This is a complex and mature page
//

$innerPageMargin-sectionHome: $leftGutter-centerPanel; // 90px

// BLOCKS

.sectionHome--block{
  width: calc(100% + #{$innerPageMargin-sectionHome} + (#{$innerPageMargin-sectionHome} / 2));
  margin-left: calc(-1 * #{$innerPageMargin-sectionHome});
  box-sizing: border-box;
  padding-top: 26px;
  padding-left: $innerPageMargin-sectionHome;
  padding-right: calc(#{$innerPageMargin-sectionHome}/2);
  position: relative;

  user-select: none;
  cursor: default;
}

.sectionHome--block-header{
  // background: $tempr;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  user-select: none;
  cursor: default;
}

// BLOCK DIVIDER: CANT DO BORDER BECAUSE SOME CONTENTS OVERFLOW
.sectionHome--block-divider{
  position: absolute;
  top: 0px;
  left: $innerPageMargin-sectionHome;
  right: 0px;
  background: $color-sectionHome-dividerGray;
  height: 1px;

}

// BLOCK HEADER TITLE (EG UP NEXT, STUDENTS, HISTORY)
.sectionHome--block-header-title{
  font-size: 16px;
  font-weight: $lato-bold;
  color: $textBlack;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  flex-grow: 1;
  user-select: none;
}

// BLOCK HEADER COUNT (STUDENT COUNT, UPCOMING COUNT)
.sectionHome--block-header-title-count{
  color: $textLight;
}

// BLOCK HEADER LINK (EG GO TO HISTORY)
.sectionHome--block-header-link{
  font-size: 14px;
  font-weight: $lato-semibold;
  color: $textBlue;
  border-radius: 3px;
  padding-left: 8px;
  padding-right: 8px;
  margin-right: -8px; // extend button size so easier to click
  margin-left: 8px;
  height: 30px;
  line-height: 2;
}

.sectionHome--block-header-link:hover{
  background: transparentize($blue, 0.90);
}

.sectionHome--block-header-link:active{
  background: transparentize($blue, 0.75);
}

.sectionHome--block-header-link .icon{
  fill: $blue;
  height: 10px;
  width: 10px;
  margin-left: 5px;
  position: relative;
}


$height-UpNext: 285px;

@import 'SectionHome-Live';
@import 'SectionHome-InProgressSet';
@import 'SectionHome-UpNext';
@import 'SectionHome-Upcoming';
@import 'SectionHome-History';
@import 'SectionHome-Students';
@import 'SectionHome-Buttons'; // Custom buttons should refactor
@import 'SectionHome-AddToClass';
@import 'SectionHome-CompleteSetupPrompt';
@import 'SectionHome-ELearningBanner';
@import 'SectionHome-ActivateELearningModal';
@import 'SectionHome-ActivateELearningWizard';


//Link Icon Fill
.sectionHome-header.has--classcolor-blue      .sectionHome-header-linkTray-link .icon {fill: $classColor-blue;}
.sectionHome-header.has--classcolor-purple    .sectionHome-header-linkTray-link .icon {fill: $classColor-purple;}
.sectionHome-header.has--classcolor-red       .sectionHome-header-linkTray-link .icon {fill: $classColor-red;}
.sectionHome-header.has--classcolor-orange    .sectionHome-header-linkTray-link .icon {fill: $classColor-orange;}
.sectionHome-header.has--classcolor-green     .sectionHome-header-linkTray-link .icon {fill: $classColor-green;}
.sectionHome-header.has--classcolor-sky       .sectionHome-header-linkTray-link .icon {fill: $classColor-sky;}
.sectionHome-header.has--classcolor-pink      .sectionHome-header-linkTray-link .icon {fill: $classColor-pink;}
.sectionHome-header.has--classcolor-teal      .sectionHome-header-linkTray-link .icon {fill: $classColor-teal;}
.sectionHome-header.has--classcolor-grey      .sectionHome-header-linkTray-link .icon {fill: $classColor-grey;}

//Link Icon Fill:Hover
.sectionHome-header.has--classcolor-blue      .sectionHome-header-linkTray-link:hover .icon {fill: saturate($classColor-blue, 7%);}
.sectionHome-header.has--classcolor-purple    .sectionHome-header-linkTray-link:hover .icon {fill: saturate($classColor-purple, 7%);}
.sectionHome-header.has--classcolor-red       .sectionHome-header-linkTray-link:hover .icon {fill: saturate($classColor-red, 7%);}
.sectionHome-header.has--classcolor-orange    .sectionHome-header-linkTray-link:hover .icon {fill: saturate($classColor-orange, 7%);}
.sectionHome-header.has--classcolor-green     .sectionHome-header-linkTray-link:hover .icon {fill: saturate($classColor-green, 7%);}
.sectionHome-header.has--classcolor-sky       .sectionHome-header-linkTray-link:hover .icon {fill: saturate($classColor-sky, 7%);}
.sectionHome-header.has--classcolor-pink      .sectionHome-header-linkTray-link:hover .icon {fill: saturate($classColor-pink, 7%);}
.sectionHome-header.has--classcolor-teal      .sectionHome-header-linkTray-link:hover .icon {fill: saturate($classColor-teal, 7%);}
.sectionHome-header.has--classcolor-grey      .sectionHome-header-linkTray-link:hover .icon {fill: saturate($classColor-grey, 7%);}

//Link Tray BG for Hover
.sectionHome-header.has--classcolor-blue      .sectionHome-header-linkTray-link-bg {background: $classColor-blue; }
.sectionHome-header.has--classcolor-purple    .sectionHome-header-linkTray-link-bg {background: $classColor-purple; }
.sectionHome-header.has--classcolor-red       .sectionHome-header-linkTray-link-bg {background: $classColor-red; }
.sectionHome-header.has--classcolor-orange    .sectionHome-header-linkTray-link-bg {background: $classColor-orange; }
.sectionHome-header.has--classcolor-green     .sectionHome-header-linkTray-link-bg {background: $classColor-green; }
.sectionHome-header.has--classcolor-sky       .sectionHome-header-linkTray-link-bg {background: $classColor-sky; }
.sectionHome-header.has--classcolor-pink      .sectionHome-header-linkTray-link-bg {background: $classColor-pink; }
.sectionHome-header.has--classcolor-teal      .sectionHome-header-linkTray-link-bg {background: $classColor-teal; }
.sectionHome-header.has--classcolor-grey      .sectionHome-header-linkTray-link-bg {background: $classColor-grey; }


//
// EMPTY QUEUE

.sectionHome-emptyQueue{
  height: 60px;
  width: 100%;

  margin-top: 28px;

  position: relative;

  user-select: none;
  cursor: default;

  box-sizing: border-box;
}

.sectionHome-emptyQueue-title{
  font-size: 18px;
  font-weight: $lato-bold;
  color: $textDark;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.sectionHome-emptyQueue-subtitle{
  font-size: 16px;
  //width: 400px;
  font-weight: $lato-semibold;
  color: $textLight;

  margin-top: 8px;
}
