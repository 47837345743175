//
// PAGE HEADER - LEFT BADGE ICONS
// General styling for top and sticky left-badge icons
// Also includes small tweaks and customizations
//

//
// PAGE HEADER TOP

.pageHeaderTop-leftBadge .icon{
  height: 28px;
  width: 28px;
  position: relative; // for custom positioning below
}

// SOME CUSTOM ICON POSITIONING
.pageHeaderTop--library .pageHeaderTop-leftBadge .icon.library,
.pageHeaderTop--libraryFolder .pageHeaderTop-leftBadge .icon.library,
.pageHeaderTop--repoFolder .pageHeaderTop-leftBadge .icon.library{
  top: -2px;
}

.pageHeaderTop--history .pageHeaderTop-leftBadge .icon.history,
.pageHeaderTop--archivedReports .pageHeaderTop-leftBadge .icon.history{
  top: -1px;
  height: 26px;
  width: 26px;
}

.pageHeaderTop--trash .pageHeaderTop-leftBadge .icon.trash{
  top: -2px;
}

.pageHeaderTop--searchResults .pageHeaderTop-leftBadge .icon{
  height: 26px;
  width: 26px;
  top: -1px;
}

//
// SMALL BACK ICON
.pageHeaderTop--libraryFolder,
.pageHeaderTop--repoFolder,
.pageHeaderTop--trash,
.pageHeaderTop--discoverMarket,
.pageHeaderTop--discoverSeeAll,
.pageHeaderTop--archivedReports{

  .pageHeaderTop-leftBadgeContainer{
    cursor: pointer;
  }

  .pageHeaderTop-leftBadge .icon.chevron-left{
    margin-right: 4px;
    height: 20px;
    width: 20px;
    fill: $lineMid;
  }

  .pageHeaderTop-leftBadgeContainer:hover .icon.chevron-left {
    opacity: 0.6;
    fill: $blue;
  }

  .pageHeaderTop-leftBadgeContainer:active .icon.chevron-left{
    fill: $blue;
    opacity: 0.9;
  }

}

.pageHeaderTop--discoverMarket .pageHeaderTop-leftBadge .icon.chevron-left,
.pageHeaderTop--discoverSeeAll .pageHeaderTop-leftBadge .icon.chevron-left{
  top: -3px;
  left: -5px;
  fill: $gray-125;
}

//
// LARGE BACK ICON
.pageHeaderTop--setQuestionDetail,
.pageHeaderTop--setQuestionReport,
.pageHeaderTop--sectionHistory,
.pageHeaderTop--sectionStudents,
.pageHeaderTop--sectionSettings,
.pageHeaderTop--sectionArchivedReports,
.pageHeaderTop--sectionArchivedStudents{

  .pageHeaderTop-leftBadgeContainer{
    cursor: pointer;
  }

  .pageHeaderTop-leftBadge .icon.chevron-left{
    top: -2px;
    right: 8px;
    width: 30px;
    height: 30px;
    fill: $gray-12;
  }

  .pageHeaderTop-leftBadgeContainer:hover .icon.chevron-left {
    fill: $gray-7;
  }

}



//
// STICKY

.pageHeaderSticky-leftBadge .icon{
  height: 24px;
  width: 24px;
  position: relative; // for custom positioning below
}


// SOME CUSTOM ICON POSITIONING
.pageHeaderSticky--library .pageHeaderSticky-leftBadge .icon.library,
.pageHeaderSticky--libraryFolder .pageHeaderSticky-leftBadge .icon.library,
.pageHeaderSticky--repoFolder .pageHeaderSticky-leftBadge .icon.library{
  top: -0.5px;
}

.pageHeaderSticky--recent .pageHeaderSticky-leftBadge .icon.recent{
  top: 1px;
}

.pageHeaderSticky--history .pageHeaderSticky-leftBadge .icon.history,
.pageHeaderSticky--archivedReports .pageHeaderSticky-leftBadge .icon.history,
.pageHeaderSticky--sectionHistory .pageHeaderSticky-leftBadge .icon.history,
.pageHeaderSticky--sectionArchivedReports .pageHeaderSticky-leftBadge .icon.history{
  height: 22px;
  width: 22px;
}

.pageHeaderSticky--sectionStudents .pageHeaderSticky-leftBadge .icon.student,
.pageHeaderSticky--sectionArchivedStudents .pageHeaderSticky-leftBadge .icon.student{
  height: 27px;
  width: 27px;
  top: 1px;
}

.pageHeaderSticky--sectionSettings .pageHeaderSticky-leftBadge .icon.settings{
  width: 22px;
  height: 22px;
}

.pageHeaderSticky--trash .pageHeaderSticky-leftBadge .icon.trash{
  top: -1px;
}


//
// LARGE BACK

.pageHeaderSticky--setQuestionReport{
  .pageHeaderSticky-leftBadgeContainer{
    cursor: pointer;
    flex-shrink: 0;
  }

   .pageHeaderSticky-leftBadge .icon.chevron-left{
    fill: $gray-12;
  }

  .pageHeaderSticky-leftBadgeContainer:hover .icon.chevron-left {
    fill: $gray-7;
  }
}

//
// SMALL BACK WITH ICON

.pageHeaderSticky--libraryFolder,
.pageHeaderSticky--repoFolder,
.pageHeaderSticky--trash,
.pageHeaderSticky--archivedReports,
.pageHeaderSticky--sectionStudents,
.pageHeaderSticky--sectionHistory,
.pageHeaderSticky--sectionSettings,
.pageHeaderSticky--sectionArchivedReports,
.pageHeaderSticky--sectionArchivedStudents,
.pageHeaderSticky--discoverMarket{

  .pageHeaderSticky-leftBadgeContainer{
    cursor: pointer;
    flex-shrink: 0;
  }

   .pageHeaderSticky-leftBadge .icon.chevron-left{
    margin-right: 4px;
    height: 14px;
    width: 14px;
    fill: $gray-12;
  }

  .pageHeaderSticky-leftBadgeContainer:hover .icon.chevron-left {
    fill: $gray-7;
  }

}

.pageHeaderSticky--libraryFolder .pageHeaderSticky-leftBadge .icon.chevron-left,
.pageHeaderSticky--repoFolder .pageHeaderSticky-leftBadge .icon.chevron-left{
  top: 2px;
}

.pageHeaderSticky--trash .pageHeaderSticky-leftBadge .icon.chevron-left{
  margin-right: 2px;
  top: 1px;
}

//
// SEARCH RESULTS

.pageHeaderTop--searchResults .pageHeaderTop-leftBadge .icon,
.pageHeaderSticky--searchResults .pageHeaderSticky-leftBadge .icon{
  fill: $blue;
}

//
// CLASS COLORS FOR SECTION PAGES

.pageHeaderSticky--classcolor-blue			.pageHeaderSticky-leftBadge .icon		{ fill: $classColor-blue; }
.pageHeaderSticky--classcolor-purple		.pageHeaderSticky-leftBadge .icon		{ fill: $classColor-purple; }
.pageHeaderSticky--classcolor-red				.pageHeaderSticky-leftBadge .icon		{ fill: $classColor-red; }
.pageHeaderSticky--classcolor-orange		.pageHeaderSticky-leftBadge .icon		{ fill: $classColor-orange; }
.pageHeaderSticky--classcolor-green			.pageHeaderSticky-leftBadge .icon		{ fill: $classColor-green; }
.pageHeaderSticky--classcolor-sky				.pageHeaderSticky-leftBadge .icon		{ fill: $classColor-sky; }
.pageHeaderSticky--classcolor-pink			.pageHeaderSticky-leftBadge .icon		{ fill: $classColor-pink; }
.pageHeaderSticky--classcolor-teal			.pageHeaderSticky-leftBadge .icon		{ fill: $classColor-teal; }
.pageHeaderSticky--classcolor-grey			.pageHeaderSticky-leftBadge .icon		{ fill: $classColor-grey; }



