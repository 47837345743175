.notification{

	position: fixed;

	bottom: 10px;
	left: 10px;
	
	z-index: 8000;
	
	padding-left: 8px;
	padding-right: 10px;
	padding-top: 10px;
	padding-bottom: 16px;

	width: 245px;
	min-height: 68px;
	max-height: 95px;

	box-sizing: border-box;

	border: 1px solid $lineDark;
	background: $white;
	border-radius: 3px;	
	box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.12);

	cursor: default;
	user-select: none;

	border-left: 5px solid;
}

.notification-message{	
	color: $textDark;
	font-size: 15px;
	font-weight: $lato-bold;
	overflow-wrap: break-word;

	display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;

  text-overflow: clip;
  white-space: normal;
  overflow: hidden;

  max-height: 69px; // max height and padding

  // background: $tempr;
}

.notification--actionType--default{
	border-left-color: $blue;
}

.notification--actionType--create{
	border-left-color: $green;
}

.notification--actionType--destroy{
	border-left-color: $red;
}

.notification span.notification-actionWord{
	font-weight: $lato-heavy;
	color: $textBlack;
}

.notificationTransition-enter {
  opacity: 0.01;
  transform: translateX(-50px);
}

.notificationTransition-enter.notificationTransition-enter-active {
  opacity: 1;
  transform: translateX(0px);
  transition: all 250ms cubic-bezier(0.18, 0.89, 0.32, 1.05);
}

.notificationTransition-leave {
  opacity: 1;
  transform: translateX(0px);
}

.notificationTransition-leave.notificationTransition-leave-active {
  opacity: 0.01;
  transform: translateX(-50px);
  transition: all 350ms cubic-bezier(0.18, 0.89, 0.32, 1.05);
}

// ADD BUTTON


.notification--hasButton{
	max-height: 400px; // make sure has enough space to show button
	padding-bottom: 8px;
}

.notification-buttonContainer{
	margin-top: 12px;
}

button.notification-button{
	margin-left: -4px;
	padding-left: 8px;
	padding-right: 8px;
	padding-top: 5px;
	padding-bottom: 5px;

	font-size: 14.5px;
	font-weight: $lato-bold;

	color: $textBlue;
	
	border-radius: 4px;

	border: none;
	box-shadow: none;

	cursor: pointer;
	background: none;
	background: $blue-extraLight;

	text-align: left;
}

button.notification-button{
	background: transparentize($red, 0.95);
	color: darken($red, 4%);
}

button.notification-button:hover{
	background: darken($blue-extraLight, 1%);
	color: darken($textBlue, 7%);

	background: transparentize($red, 0.9);
	color: darken($red, 10%);
}