.selectionModal-modal--section .modal-content{
	width: 450px;
}

.selectionModal-modal--repo .modal-content,
.selectionModal-modal--copyLocation .modal-content{
	width: 600px;
}

.selectionModal-modal .modal-content{
	min-height: 400px;
	height: auto;
	max-height: 90vh;

	//margin-top: -60px;
	background: rgb(252,252,255);
	border-radius: 5px;
	position: relative;
	box-shadow: 
    	0px 2px 4px 0px rgba(0,0,0,0.20),
    	0px 4px 8px 0px rgba(0,0,0,0.12),
    	0px 8px 16px 0px rgba(0,0,0,0.10);

  overflow-y: auto;
}

.selectionModal{    
  height: 100%;  
  width: 100%;

  user-select: none;
  cursor: default;
}

.selectionModal-header{
    width: 100%;
    padding-left: 36px;
    padding-right: 36px;
    box-sizing: border-box;

    // background: $tempr;
    margin-top: 22px;
    position: relative;
}

.selectionModal-header-title{
    width: 100%;
    font-size: 20px;
    font-weight: $lato-bold;
    color: $textBlue;
    margin-bottom: 4px;
}

.selectionModal-header-itemTitle{
    width: 100%;
    min-height: 26px;
    max-height: 78px;

    display: -webkit-box;
	  -webkit-line-clamp: 3;
	  -webkit-box-orient: vertical;

	  text-overflow: clip;
	  white-space: normal;
	  overflow: hidden; 
		word-break: break word;
		overflow-wrap: break-word;
    
    font-size: 22px;
    font-weight: $lato-heavy;
    color: $textBlack; 
}

.selectionModal.selectionModal--set
.selectionModal-header-itemTitle{
		width: calc(100% - 45px);
}

.selectionModal-header
.questionCountBadge{
	position: absolute;
	top: 36px;
	right: 36px;
	transform: scale(1.5);
	transform-origin: bottom right;
}

.selectionModal-header-sharedItemWarning{
	margin-top: 8px;
	margin-bottom: -6px;
	margin-left: -6px;

	background: #FFF7D8; // yellow
	border: 1px solid #F0C36D; // yellow
	color: #CC7C1B; // yellow

	border-radius: 3px;

	padding-top: 4px;
	padding-bottom: 4px;
	padding-left: 6px;
	padding-right: 6px;

	font-size: 13px;
	font-weight: $lato-semibold;
	letter-spacing: 0.002em;

	cursor: default;
	
	width: 418px; // not sure why I had to do this to

}

.selectionModal-header-sharedItemWarning--multi{
	width: 458px;
}

.selectionModal-header-sharedItemWarning--public{
	width: 475px;
}

.selectionModal-header-sharedItemWarning--publicMulti{
	width: 507px;
}

.selectionModal-divider{
	width: calc(100% - 36px - 36px);
	height: 1px;
	margin: 0 auto;
	background: $lineMid;
	margin-top: 12px;
}

//

.selectionModal-selectionList{
  width: 100%;
  padding-left: 36px;
  padding-right: 36px;
  box-sizing: border-box;
 
  padding-bottom: 32px;
  padding-top: 10px;
}

.selectionModal-selectionList-helpLabel{
	font-size: 14px;
	font-weight: $lato-medium;
	color: $textMid;
	margin-bottom: 6px;
}

.selectionModal-selectionList-emptyState{
	font-size: 17px;
	font-weight: $lato-bold;
	color: $textLight;

	margin-top: 20px;
}

.selectionModal-selectionList-emptyState b {
	font-weight: $lato-heavy;
}


.selectionModal-selectionList-item {
	position: relative;
	cursor: pointer;
	height: 34px;

	width: calc(100% + 11px);
	margin-left: -11px;
}

// SHARED: REPO AND SECTION;

.selectionModal-selectionList-item--section .sectionMarker.is--circle,
.selectionModal-selectionList-item--repo,
.selectionModal-selectionList-item--library{
	
	font-size: 18px;
	font-weight: $lato-semibold;
	
	line-height: 1.9;
	padding-right: 10px;

	box-sizing: border-box;

	overflow: hidden;
	white-space: nowrap;  
  text-overflow: ellipsis;
}


// REPO

// .selectionModal-selectionList:hover
// .selectionModal-selectionList-item--repo{
// 	opacity: 0.5;
// }

// .selectionModal-selectionList
// .selectionModal-selectionList-item--repo:hover{
// 	opacity: 1;
// }

// .selectionModal-selectionList:hover
// .selectionModal-selectionList-item--repo--unselectable{
// 	opacity: 0.25;
// }

// .selectionModal-selectionList
// .selectionModal-selectionList-item--repo--unselectable{
// 	opacity: 0.5;
// }


.selectionModal-selectionList-item--repo,
.selectionModal-selectionList-item--library{
  padding-left: 12px;
  border-radius: 3px;
  position: relative;
  letter-spacing: 0.004em;
  overflow: hidden;
}

.selectionModal-selectionList-item--library{
	margin-bottom: 4px;
	font-weight: $lato-heavy;
	letter-spacing: 0em;
}

.selectionModal-selectionList-item--repo:hover,
.selectionModal-selectionList-item--library:hover{
	background: $blue-extraLight;
	color: $textBlackDark;
	font-weight: $lato-heavy;
	letter-spacing: 0em;
}

.selectionModal-selectionList-item--repo:active,
.selectionModal-selectionList-item--repo:active
.selectionModal-selectionList-item--repo-arrow,
.selectionModal-selectionList-item--repo--submitLoading,
.selectionModal-selectionList-item--repo--submitLoading-loadingSpinnerContainer,
.selectionModal-selectionList-item--library:active,
.selectionModal-selectionList-item--library:active
.selectionModal-selectionList-item--library-arrow,
.selectionModal-selectionList-item--library--submitLoading,
.selectionModal-selectionList-item--library--submitLoading-loadingSpinnerContainer{
	background: darken($blue-extraLight, 5%) !important; // refactor to variable better than deep nesting
}

.selectionModal-selectionList-item--repo--unselectable,
.selectionModal-selectionList-item--repo--currentRepo{
	opacity: 0.5;
	pointer-events: none;
}

.selectionModal-selectionList-item--repo--unselectable:hover,
.selectionModal-selectionList-item--repo--currentRepo:hover{
	background: none;
	cursor: default;
	font-weight: $lato-semibold;
	color: $textBlackDark;
	letter-spacing: 0.004em;
}



//

.selectionModal-selectionList-item--repo-arrow,
.selectionModal-selectionList-item--library-arrow {
	top: 0px;
	bottom: 0px;
	right: 0px;
	width: 36px;

	position: absolute;
	background: $blue-extraLight;

	display: flex;
	align-items: center;
	justify-content: center;

	opacity: 0;
}

.selectionModal-selectionList-item--repo-arrow .icon,
.selectionModal-selectionList-item--library-arrow .icon{
	height: 14px;
	width: 14px;

	fill: $blue;

	transform: rotate(180deg);
	
}

.selectionModal-selectionList-item--repo:hover .selectionModal-selectionList-item--repo-arrow,
.selectionModal-selectionList-item--library:hover .selectionModal-selectionList-item--library-arrow{
	opacity: 1;
	
}

.selectionModal-selectionList-item--repo--unselectable:hover .selectionModal-selectionList-item--repo-arrow,
.selectionModal-selectionList-item--repo--currentRepo:hover .selectionModal-selectionList-item--repo-arrow{
	opacity: 0;
}

// SECTION

.selectionModal-selectionList-item--section .sectionMarker.is--circle {
	width: 100%;	
	height: 100%;

	position: absolute;

	padding-left: 36px;

	
	z-index: 1;
	
}


.selectionModal-selectionList-item--section:hover .sectionMarker.is--circle {
	opacity: 1;
	color: $white;
}

.selectionModal-selectionList-item--section .sectionMarker .icon {
	height: 15px;
	width: 15px;
	top: 10px;
	left: 11px;
	position: absolute;
}

.selectionModal-selectionList-item--section .sectionMarker.is--colorOnly{
	position: absolute;
	top: 0px;
	bottom: 0px;
	left: 0px;
	right: 0px;
	z-index: 0;
	opacity: 0;

	border-radius: 3px;
}

.selectionModal-selectionList-item--section .icon.play {
	position: absolute;
	fill: $white;	
	z-index: 5;
	opacity: 0;

	width: 15px;
	height: 15px;
	left: 11px;
	top: 10px;
}


.selectionModal-selectionList-item--section:hover .sectionMarker.is--colorOnly{
	opacity: 1;
}

.selectionModal-selectionList-item--section:hover .icon.play{
	opacity: 1;
}

.selectionModal-selectionList-item--section:hover .sectionMarker .icon.circle{
	opacity: 0;
}

/// Submit Loading

// prevent any click events //

.selectionModal--submitLoading{
	pointer-events: none;
}

.selectionModal--submitLoading .selectionModal-selectionList-helpLabel{
	opacity: 0.25;
	transition: opacity 100ms linear;
}

.selectionModal--submitLoading .selectionModal-selectionList-item--repo.selectionModal-selectionList-item--repo--submitLoading,
.selectionModal--submitLoading .selectionModal-selectionList-item--library.selectionModal-selectionList-item--library--submitLoading{
	color: $textBlackDark;
	font-weight: $lato-heavy;
	letter-spacing: 0em;
	opacity: 1;
}

.selectionModal--submitLoading .selectionModal-selectionList-item--repo,
.selectionModal--submitLoading .selectionModal-selectionList-item--library{
	opacity: 0.5;
	transition: opacity 100ms linear;
}


.selectionModal-selectionList-item--repo--submitLoading-loadingSpinnerContainer,
.selectionModal-selectionList-item--library--submitLoading-loadingSpinnerContainer{
	opacity: 0;
}

.selectionModal--submitLoading
.selectionModal-selectionList-item--repo--submitLoading
.selectionModal-selectionList-item--repo--submitLoading-loadingSpinnerContainer,
.selectionModal--submitLoading
.selectionModal-selectionList-item--library--submitLoading
.selectionModal-selectionList-item--library--submitLoading-loadingSpinnerContainer{
	opacity: 1;	
}


.selectionModal-selectionList-item--repo--submitLoading-loadingSpinnerContainer,
.selectionModal-selectionList-item--library--submitLoading-loadingSpinnerContainer{
	position: absolute;

	
	display: flex;
	align-items: center;
	justify-content: center;
	top: 0;
	bottom: 0;
	right: 0px;
	width: 40px;
	z-index: 10;
}




.selectionModal-selectionList-item--repo--submitLoading-loadingSpinner,
.selectionModal-selectionList-item--library--submitLoading-loadingSpinner{
	height: 16px;
	width: 16px;
	
  border-radius: 16px;

  border: 3px transparentize($blue, 0.8) solid;
  border-top: 3px $blue solid;

  opacity: 0.7;

  animation: spinnerRotateAnimation .4s infinite linear;
}


//
// RATE AND REVIEW IN DUPLICATE FLOW
// when users make a copy and edit on a public repo item from item editor, they see this modal to rate and review the pack


.selectionModal--preRateAndReview-packTitle{
	margin-top: 48px;
	width: 100%;
	text-align: center;
	color: $textMid;
	font-size: 18px;
	font-weight: $lato-semibold;

	user-select: none;
	cursor: default;

	box-sizing: border-box;
	padding-left: 24px;
	padding-right: 24px;

	white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.selectionModal--preRateAndReview-headline{
	margin-top: 20px;
	color: $textBlack;
	font-weight: $lato-bold;
	width: 100%;
	text-align: center;
	font-size: 20px;
	letter-spacing: 0.01em;

	user-select: none;
	cursor: default;
}

.selectionModal--preRateAndReview-headline emph{
	font-weight: $lato-bold;
	color: $textBlackDark;
}

.selectionModal--preRateAndReview-starBtnRowContainer{
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 70px;
	margin-top: 32px;
	// background: $tempr;

	margin-bottom: 32px;
}

.selectionModal--preRateAndReview-starBtnRow{
	display: flex;
}


button.selectionModal--preRateAndReview-starBtn{
	box-shadow: none;
	border: none;

	display: flex;
	align-items: center;
	justify-content: center;

	flex-grow: 0;
	flex-shrink: 0;

	width: 48px;
	height: 48px;
	// border: 1px solid $red;

	box-sizing: border-box;

	cursor: pointer;	
	background: none;

	// background: $tempr;

	position: relative;

	transition: opacity 100ms linear;
}

button.selectionModal--preRateAndReview-starBtn svg{
	width: 42px;
	height: 42px;
	position: static; // IE bug fix
	background: none;
}

button.selectionModal--preRateAndReview-starBtn.selectionModal--preRateAndReview-starBtn--active svg{
	//fill: #FFA940;
	fill: $blue;
}

button.selectionModal--preRateAndReview-starBtn.selectionModal--preRateAndReview-starBtn--inactive svg{
	fill: transparentize($blue, 0.9);
}

.selectionModal--preRateAndReview-starBtnRowContainer.selectionModal--preRateAndReview-starBtnRowContainer--noRating
.selectionModal--preRateAndReview-starBtnRow:not(:hover)
button.selectionModal--preRateAndReview-starBtn svg{
	fill: transparentize($blue, 0.55);
}





button.btn.btn--xLarge.duplicateandeditsubmitfeedback{
	margin: 0 auto;
	width: 350px;
	margin-top: 20px;
	cursor: pointer;

	height: 60px;

	font-weight: $lato-heavy;
	letter-spacing: 0.02em;
}

button.selectionModal--preRateAndReview-skipReviewBtn{
	display: block;
	margin: 0 auto;
	padding-top: 5px;
	padding-bottom: 5px;
	cursor: pointer;
	margin-top: 10px;
	background: none;
	width: 340px;
	border: none;
	font-size: 13.5px;
	font-weight: $lato-semibold;
	color: $textMid;
	text-decoration: underline;
}

