//
// ADD CHOICE MEDIA BUTTON
// this is the in-choice-body "Add Media Choices" button
//

// addChoiceMediaBtn 

.slide-choice-addChoiceMediaBtn{
	width: 210px;
	
	border-radius: 4px;
	position: absolute;
	top: 6px;
	height: 40px;
	// bottom: 6px;
	right: 6px;

	cursor: pointer;
	
	text-align: center;
	box-sizing: border-box;

	border-radius: 4px;
	
	opacity: 0;
	
	-webkit-appearance: none;
	
	font-size: 20px;
	font-weight: $lato-bold;
	letter-spacing: 0.02em;
	display: flex;
	align-items: center;
	justify-content: center;

	background: $gray-15;
	color: $gray-6;
}

// shortLabel used in some instances

.slide-choice-addChoiceMediaBtn--shortLabel{
	width: 85px;
}

.editor-canvas .slide-choice-addChoiceMediaBtn{
	visibility: hidden;
	pointer-events: none;
}

.editor-canvas--showChoiceAddMediaBtn .slide-choice-addChoiceMediaBtn{
	visibility: visible;
	pointer-events: auto;
}

//

.slide .slide-choices .slide-choice:hover .slide-choice-addChoiceMediaBtn {
	opacity: 0.8;
}

.slide .slide-choices .slide-choice--focused:hover .slide-choice-addChoiceMediaBtn{
	opacity: 1;
}

.slide .slide-choices .slide-choice:hover .slide-choice-addChoiceMediaBtn:hover {
	opacity: 1;
	background: $blue-extraLight;
	color: $blue;
}
