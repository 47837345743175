.previewPanel-header-title{
	width: calc(100% + 12px);	
	position: relative;
	margin-left: -7px;
	max-height: 121px;
}

textarea.previewPanel-header-title-input,
.previewPanel-header-title-spacer,
.previewPanel-header-title-iconShared{
	font-size: 20px;
	font-weight: $lato-heavy;
	padding-left: 6px;
	padding-right: 6px;
	padding-top: 6px;
	padding-bottom: 6px;
	box-sizing: border-box;
	border: 1px solid $invisible;
	word-wrap: break-word;
}


textarea.previewPanel-header-title-input{		
	opacity: 1;
	position: absolute;
	height: 100%;
	width: 100%;
	top: 0px;
	left: 0px;

	z-index: 5;

	background: none;
	border-radius: 2px;

	overflow: hidden;

	color: $textBlack;
	
	resize: none;
}


textarea.previewPanel-header-title-input:hover{
	background: rgba(0,0,40,0.025);
	color: $textBlackDark;
	cursor: default;
}

textarea.previewPanel-header-title-input:disabled:hover{
	background: none;
}

textarea.previewPanel-header-title-input:focus{
	color: $textBlackDark;
	background: $white;
	border: 1px solid $lineDark;
	box-shadow: none;
	outline: none;
	cursor: text;
}

.previewPanel-header-title-spacer{
	width: 100%;
	font-size: 20px;
	color: $invisible;
	min-height: 38px;
	position: relative;
}

.previewPanel-header-title--showSharedIcon textarea.previewPanel-header-title-input,
.previewPanel-header-title--showSharedIcon .previewPanel-header-title-spacer{
	width: calc(100% - 22px);
}

.previewPanel-header-title-spacer .icon.shared{
	position: absolute;
	top: 11px;
	right: -18px;
	width: 18px;
	height: 18px;
	fill: $gray-11;
}

.previewPanel-header-title-spacer .icon.lock{
	position: absolute;
	top: 11px;
	right: -16px;
	width: 18px;
	height: 18px;
	fill: $gray-10;	
}


.previewPanel-header-title-spacer .icon.globe-shared,
.previewPanel-header-title-spacer .icon.globe-shared-author{
	position: absolute;
	top: 11px;
	right: -18px;
	width: 18px;
	height: 18px;
	fill: $gray-11;
}



.previewPanel:hover .previewPanel-header-title{
	color: $textBlackDark;
}

// HINTS


textarea.previewPanel-header-title-input:focus ~
.previewPanel-header-title-input-hint{
	opacity: 1;
	transition: opacity 50ms ease-in-out;
}


.previewPanel-header-title-input-hint{
	position: absolute;
	z-index: 100;
	bottom: -24px;
	left: 0px;
	right: 0px;

	opacity: 0;
	transition: opacity 150ms ease-in-out;

	height: 20px;
	padding-left: 7px;
	padding-right: 8px;
	
	background: rgba(255,255,255,0.5);
	pointer-events: none;
	font-size: 12.5px;
	font-weight: $lato-semibold;
	color: $textDark;

	background: $color-pageBG;
}

.previewPanel-header-title-input-hint strong{
	color: $textMid;
	font-weight: $lato-bold;
	color: $textBlue;
}

.previewPanel.previewPanel--folder
.previewPanel-header-title-input-hint{
	left: -40px;
	padding-left: 47px;
}



// .previewPanel.previewPanel--folder
// .previewPanel-header-title-input-hint{
// 	height: 50px;
// 	bottom: -54px;
// }


// FOLDER ICON

.previewPanel.previewPanel--folder
.previewPanel-header-title{
	// background: $tempr;
	width: calc(100% - 26px);	
	position: relative;
	margin-left: 26px;
}

.previewPanel-header-title .icon.folder{
	width: 22px;
	height: 22px;
	position: absolute;
	left: -26px;
	top: 9px;
	fill: $blue;
}

