//
// PAGE HEADER LEFT BADGE
// the left badge contains an icon or image
// sometimes it can contain multiple icons (e.g. in folder in library)
// 

.pageHeaderTop-leftBadgeContainer{
	position: absolute;
	width: $leftGutter-centerPanel;
	left: calc(-1 * #{$leftGutter-centerPanel});
	top: 0;	
	bottom: $height-pageHeaderTop-actionRow; 
	// do not want back button to overflow onto the actionRow	
	// may be better way to do this
}

.pageHeaderSticky-leftBadgeContainer{
	width: $leftGutter-centerPanel;
	flex-shrink: 0;
}

// REPO WITH IMAGE CASE
.pageHeaderTop--repoWithImage .pageHeaderTop-leftBadgeContainer{
	width: calc(104px + #{$leftGutter-centerPanel});
	height: 200px;
	bottom: 0px; // higher than action row
}

.pageHeaderTop--repoWithImage .pageHeaderTop-leftBadge{
	top: 55px;
	padding-right: 20px;
}

.pageHeaderTop-leftBadge-repoImageContainer{
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: flex-end;
}

.pageHeaderTop-leftBadge-repoImageContainer img.pageHeaderTop-leftBadge-repoImage,
.pageHeaderTop-leftBadge-repoImageContainer-pageLoader{
	width: 145px;
	height: 145px;
	
	border-radius: 7px;
}

.pageHeaderTop-leftBadge-repoImageContainer-pageLoader{	
	background: $color-pageLoaderElement;
}

// BADGE
.pageHeaderTop-leftBadge,
.pageHeaderSticky-leftBadge{
	position: relative;
	width: 100%;
	
	box-sizing: border-box;	
	padding-right: 10px; // padding between icon and header

	display: flex;
	align-items: center;
	justify-content: flex-end;
}

.pageHeaderTop-leftBadge{
	top: $marginTop-pageHeaderTop-headerRow;
	height: $height-pageHeaderTop-headerRow;
}

.pageHeaderSticky-leftBadge{
	top: 0px;
	height: 100%;
}

// STICKY REPO IMAGE
.pageHeaderSticky--repo .pageHeaderSticky-leftBadge-repoImageContainer{
	width: 48px;
	height: 48px;	
	background: $tempr;
	border-radius: 6px;
	overflow: hidden;
	margin-right: 4px;
}

.pageHeaderSticky--repo .pageHeaderSticky-leftBadge-repoImage{
	width: 48px;
	height: 48px;	
}


// ICON – SIZE AND COLOR TWEAKS
@import 'PageHeaders-leftBadge-icons.scss';


// QUESTION COUNT BADGE IN SET DETAIL
.pageHeaderSticky--setDetail .pageHeaderSticky-leftBadge .questionCountBadge{
	width: 34px;
	height: 23px;
	border-radius: 2px;
	font-size: 17px;
	position: relative;
	top: 1px;
	user-select: none;
}