$pageMargin-sectionRosterSheet: 2.54cm;
// Microsoft word default margins

@media screen {
	.sectionRoster{
		width: 100%;
		min-height: 100%;
		display: flex;
		justify-content: center;
		background: rgb(236,236,240);
	}
}

@media print {
	@page{
		margin: 0mm;
	}
}

// TOPNAV

@media screen {
	.sectionRoster-topBar{
		position: fixed;
		top: 0px;
		left: 0px;
		right: 0px;
		height: $height-topNav;
		//background: $color-pageBG;
		background: $white;
		border-bottom: 1px solid rgb(204,204,210);		
		box-shadow: $shadow-navBar;	
		
		z-index: 1000;
		display: flex;
		justify-content: space-between;
		align-items: center;
	
	}
}

@media print {
	.sectionRoster-topBar{
		display: none;
	}
}

.sectionRoster-topBar-btnGroup{
	display: flex;
	width: 225px;
}


.sectionRoster-topBar-btn{
	line-height: 1.5;
	font-size: 14px;
	cursor: pointer;
	width: 50%;
	
	border-radius: 0px;
	margin: 0.5px;

	display: flex;
	height: 28px;
	align-items: center;
	justify-content: center;

	color: $textDark;
	font-weight: $lato-semibold;
	border-color: $invisible !important;

	background: transparentize($blue, 0.9);
	color: $textBlue;

	

	white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.sectionRoster-topBar-btn:first-child{
	border-top-left-radius: 3px;
	border-bottom-left-radius: 3px;
}

.sectionRoster-topBar-btn:last-child{
	border-top-right-radius: 3px;
	border-bottom-right-radius: 3px;
}

.sectionRoster-topBar-btn:hover{
	background: transparentize($blue, 0.85);
}


.sectionRoster-topBar-btn.sectionRoster-topBar-btn--active,
.sectionRoster-topBar-btn.sectionRoster-topBar-btn--active:hover{
	color: $white;
	background: $blue;
}

.sectionRoster-topBar-btn .icon{
	width: 12px;
	height: 12px;
	fill: $blue;
	position: relative;
}
.sectionRoster-topBar-btn .icon.portrait{
	// top: 1px;
	margin-right: 3px;
}

.sectionRoster-topBar-btn .icon.landscape{
	width: 13px;
	height: 13px;
	top: 1px;
	//margin-left: 5px;
	margin-right: 3px;
}

.sectionRoster-topBar-btn.sectionRoster-topBar-btn--active .icon{
	fill: $white;
}

.sectionRoster-topBar-title{	
	color: $textBlack;
	padding-left: 16px;
	box-sizing: border-box;
	font-size: 22px;
	font-weight: $lato-heavy;
	width: 260px;
	line-height: 2;
	margin-right: auto;
}

.sectionRoster-topBar-printBtn{
	height: 32px;
	background: $blue;
	color: $white;
	margin-left: auto;
	text-align: center;
	box-sizing: border-box;
	font-size: 16px;
	border-radius: 2px;
	font-weight: $lato-heavy;
	width: 190px;
	line-height: 2;
	cursor: pointer;
}

.sectionRoster-topBar-printBtn:hover{
	background: darken($blue, 2%);
}

.sectionRoster-topBar-printBtn:active{
	background: darken($blue, 5%);
}

.sectionRoster-topBar-closeBtn{
	display: flex;
	align-items: center;
	justify-content: flex-end;
	padding-right: 20px;
	box-sizing: border-box;
	

	width: 70px;
	margin-left: 10px;
	padding-left: 10px;
	height: 100%;
	cursor: pointer;

	background: $white;
}	

.sectionRoster-topBar-closeBtn .icon{
	width: 24px;
	height: 24px;
	opacity: 0.9;
	fill: $gray-7;
	transition: all 50ms cubic-bezier(.2, .3, .25, .9);	
}

.sectionRoster-topBar-closeBtn:hover .icon{
	opacity: 1;
	transform: scale(1.05);
	transition: all 150ms cubic-bezier(.2, .3, .25, .9);
}

.sectionRoster-topBar-closeBtn:active .icon{
	fill: $gray-6;
	opacity: 1;
	transform: scale(1.05);
}

.sectionRoster-topBar-closeBtn:active{
	background: rgba(0,0,25,0.04);
}

// SHEET

@media screen {
	.sectionRosterSheet{
		margin-top: 68px;		
		padding: $pageMargin-sectionRosterSheet;
		padding-top: 1.8cm;
		box-sizing: border-box;
		background: $white;
		border: 1px solid rgb(204,204,210);		
		box-shadow: 0px 2px 4px 0px rgba(0,0,40,0.12);
		position: relative;
	}
	  	
	.sectionRosterSheet--portrait{		
		width: 220mm;
		height: 280mm;  
		margin-bottom: 80px;
	}

	.sectionRosterSheet--landscape{		
		width: 280mm;
		height: 220mm;  
	}
}

@media print {
	.sectionRosterSheet{	
		padding: $pageMargin-sectionRosterSheet;	
		padding-top: 1.8cm;
		box-sizing: border-box;
		position: relative;
	}
}

$chromeFixScale: 1.17;
@media print{
	.sectionRosterSheet--portrait
	.sectionRosterSheetContents{
		width: calc(100% / #{$chromeFixScale});
		height: calc(100% / #{$chromeFixScale});
		transform: scale($chromeFixScale);
		transform-origin: top left;
	}
}


// HEADER

.sectionRosterSheet-header{	
	width: 100%;
	position: relative;
}

.sectionRosterSheet-header-colorBlock{
	position: absolute;
	left: -1.75cm;	
	bottom: 1.25cm;
	top: -0.25cm;
	width: 1cm;

	box-sizing: border-box;
	border-left: 0.25cm solid $red;
}

.sectionRosterSheet-header-top{
	font-size: 18pt;
	font-weight: $lato-heavy;
	color: $textMid;
	//background: $tempb;
	height: 0.75cm;
	line-height: 0.8;
	margin-bottom: 0.2cm;

	display: flex;
}

.sectionRosterSheet-header-sectionName{
	font-size: 32pt;
	font-weight: $lato-heavy;
	color: black;

	height: 2.9cm;

	display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;

  text-overflow: clip;
  white-space: normal;
  overflow: hidden; 

}

// TABLE

.sectionRosterSheet-studentTable{
 	width: 100%;
}

.sectionRosterSheet--portrait.sectionRosterSheet--under37
.sectionRosterSheet-studentTable{
	column-count: 2;
}

.sectionRosterSheet--portrait.sectionRosterSheet--over36
.sectionRosterSheet-studentTable{
	column-count: 3;
}

.sectionRosterSheet--landscape.sectionRosterSheet--under37
.sectionRosterSheet-studentTable{
	column-count: 3;
}

.sectionRosterSheet--landscape.sectionRosterSheet--over36
.sectionRosterSheet-studentTable{
	column-count: 4;
}


// STUDENT CELL

.sectionRosterSheet-studentTable-studentCell{
	display: inline-block;
 	vertical-align: top;

 	break-inside: avoid-column;
 	-webkit-column-break-inside: avoid;
	
	color: $textBlack;
	font-weight: $lato-semibold;

	display: flex;
}

.sectionRosterSheet--under37
.sectionRosterSheet-studentTable-studentCell{
	height: 10mm;
	font-size: 18pt;
	width: 90%;
}

.sectionRosterSheet--over36
.sectionRosterSheet-studentTable-studentCell{
	height: 8mm;
	width: 100%;
	font-size: 15pt;
}

// NAME

.sectionRosterSheet-studentTable-studentCell-name{
	font-weight: $lato-semibold;
	
	white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  -webkit-backface-visibility: hidden; // fix for flickering
}

/// STUDENT CARD NUMBER
.sectionRosterSheet-studentTable-studentCell-card{
	font-weight: $lato-medium;
	flex-shrink: 0;
}

.sectionRosterSheet--color
.sectionRosterSheet-studentTable-studentCell-card{
	color: $textBlue;
}
.sectionRosterSheet--blackandwhite
.sectionRosterSheet-studentTable-studentCell-card{
	color: $textMid;
}

.sectionRosterSheet--under37
.sectionRosterSheet-studentTable-studentCell--sortName
.sectionRosterSheet-studentTable-studentCell-card{	
	margin-left: 3mm;
}

.sectionRosterSheet--over36
.sectionRosterSheet-studentTable-studentCell--sortName
.sectionRosterSheet-studentTable-studentCell-card{	
	margin-left: 2mm;
}

.sectionRosterSheet--under37
.sectionRosterSheet-studentTable-studentCell--sortNumber
.sectionRosterSheet-studentTable-studentCell-card{	
	margin-right: 3mm;
	width: 10mm;
	text-align: right;
}

.sectionRosterSheet--sortbynumber.sectionRosterSheet--under37
.sectionRosterSheet-studentTable{
	margin-left: -13mm;
}

.sectionRosterSheet--over36
.sectionRosterSheet-studentTable-studentCell--sortNumber
.sectionRosterSheet-studentTable-studentCell-card{	
	margin-right: 2mm;
	width: 7mm;
	text-align: right;
}

.sectionRosterSheet--sortbynumber.sectionRosterSheet--over36
.sectionRosterSheet-studentTable{
	margin-left: -9mm;
}




.sectionRosterSheet-header-colorBlock--classcolor-blue				{ border-color: $classColor-blue; }
.sectionRosterSheet-header-colorBlock--classcolor-purple			{ border-color: $classColor-purple; }
.sectionRosterSheet-header-colorBlock--classcolor-red					{ border-color: $classColor-red; }
.sectionRosterSheet-header-colorBlock--classcolor-orange			{ border-color: $classColor-orange; }
.sectionRosterSheet-header-colorBlock--classcolor-green				{ border-color: $classColor-green; }
.sectionRosterSheet-header-colorBlock--classcolor-sky					{ border-color: $classColor-sky; }
.sectionRosterSheet-header-colorBlock--classcolor-pink				{ border-color: $classColor-pink; }
.sectionRosterSheet-header-colorBlock--classcolor-teal				{ border-color: $classColor-teal; }
.sectionRosterSheet-header-colorBlock--classcolor-grey				{ border-color: $classColor-grey; }



//get rid of feedback
@media print {
	.emerald.rotate-right{
	  display: none !important; //get rid of feedback
	}
}



