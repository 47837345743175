//
// SCORESHEET CUSTOM RANGE MODAL CALENDAR
// loaded with props in ScoreSheetCustomRangeModalContainer.js
// uses react-dates library - see https://github.com/airbnb/react-dates
// this file is mostly styling overrides
//

.scoreSheet-customRangeModal-calendarContainer{
	width: 100%;
	height: 50px;
	flex-grow: 1;
	flex-shrink: 1;

	display: flex;
	align-items: flex-start;
	justify-content: center;

	background: $white;
	padding-top: 6px;
	padding-left: 14px;
	padding-right: 14px;
	box-sizing: border-box;
}




// OVERRIDES

.scoreSheet-customRangeModal-calendarContainer{


// HIGHLIGHTED
// primarily useful in mini-calendar

.CalendarDay__default.CalendarDay__highlighted_calendar,
.CalendarDay__default.CalendarDay__highlighted_calendar:hover{	
	border-radius: 4px;
	background: #ffe8bc; //initial value
	border-color: #ffe8bc;
	//font-weight: $lato-bold !important;
	color: $textBlackDark !important;
}

.DayPicker{
	box-shadow: none;
	background: none;
	border: none;
}

.CalendarDay__default{
	font-weight: $lato-semibold;
	color: $textDark;
	border-color: $lineMid;
}

.CalendarDay__default:hover{
	background: $gray-15;
	color: $textBlack;
}

.CalendarDay__selected_span,
.CalendarDay__selected,
.CalendarDay__selected:active,
.CalendarDay__selected_span:hover,
.CalendarDay__selected:hover,
.CalendarDay__selected.CalendarDay__highlighted_calendar,
.CalendarDay__selected.CalendarDay__highlighted_calendar:hover,
.CalendarDay__selected_span.CalendarDay__highlighted_calendar,
.CalendarDay__selected_span.CalendarDay__highlighted_calendar:hover,
.CalendarDay__default.CalendarDay__selected_span.CalendarDay__blocked_out_of_range,
.CalendarDay__default.CalendarDay__selected_span.CalendarDay__outside,
.CalendarDay__default.CalendarDay__blocked_out_of_range.CalendarDay__selected_end{
	background: $blue !important;
	border-color: $blue !important;
	color: $white !important;
	border-radius: 0px !important;
}

// OUT OF RANGE BUT STILL SELECTED
.CalendarDay__default.CalendarDay__selected_span.CalendarDay__blocked_out_of_range,
.CalendarDay__default.CalendarDay__blocked_out_of_range.CalendarDay__selected_end{
	color: transparentize($white, 0.25);
}


.CalendarDay__selected:active,
.CalendarDay__selected_span:hover,
.CalendarDay__selected:hover {
	background: darken($blue, 5%);
	border-color: darken($blue, 8%);
	color: $white;
}



.CalendarDay__hovered_span,
.CalendarDay__highlighted_calendar.CalendarDay__hovered_span,
.CalendarDay__highlighted_calendar.CalendarDay__hovered_span:hover,
.CalendarDay__hovered_span:hover{
	background: $blue !important;
	border-color: $blue !important;
	color: $white !important;
	border-radius: 0px !important;
}


.CalendarDay__default.CalendarDay__blocked_out_of_range,
.CalendarDay__default.CalendarDay__blocked_out_of_range:hover{
	background: rgb(248,248,253);
	color: lighten($textLight, 20%);
}

// MONTH LABELS

.CalendarMonth_caption{
	font-size: 17px;
	font-weight: $lato-bold;
	color: $textDark;
	padding-top: 20px;
	padding-bottom: 32px;
}

// WEEK HEADERS
.DayPicker_weekHeader{
	top: 50px;
	color: $textMid;
}


// .CalendarDay__default.CalendarDay__highlighted_calendar:before{
// 	 content:" •";
// 	 position: absolute;
// 	 font-size: 10px;
// 	 color: $lineLight;
// 	 top: 1px;
// 	 left: 50%;
// 	 transform: translateX(-50%);
// }


// NAV BUTTONS
button.DayPickerNavigation_button,
button.DayPickerNavigation_button:hover,
button.DayPickerNavigation_button:active{
	width: 40px;
	height: 60px;
	background: none;
	border: none;
}

.DayPickerNavigation_rightButton__horizontal{
	right: 0px;
}

.DayPickerNavigation_leftButton__horizontal{
	left: 0px;
}

.DayPickerNavigation_button__horizontal{
	padding: 0px;
	top: 0px;
	border-radius: 0px;
}


.scoreSheet-calendarNavCustom{
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}
.scoreSheet-calendarNavCustom .icon {
	fill: $blue;
	width: 15px;
	height: 15px;
}

button.DayPickerNavigation_button:hover
.scoreSheet-calendarNavCustom .icon {
	transform: scale(1.2);
}

button.DayPickerNavigation_button:active
.scoreSheet-calendarNavCustom .icon {
	transform: scale(1.2);
	fill: $textBlue;
}

}