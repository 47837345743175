
.upgrade--success{
	//height: calc(100vh - 50px);	
	
	padding-top: 60px;
	padding-bottom: 24px;
	padding-right: 50px;
	padding-left: 50px;

	position: relative;

	display: block;

	user-select: none;
	cursor: default;
}

//

.upgrade--success-header{
	font-size: 46px;
	font-weight: $effra-bold;
	color: $textBlack;
	text-align: center;

	margin-bottom: 20px;
	width: 100%;

}

.upgrade--success-header--plickersPro-plickers{
	color: $blue;
	margin-right: 5px;
}

.upgrade--success-header--plickersPro-pro{
	color: $blue-light;
}

//

.upgrade--success-sub{
	font-size: 24px;
	font-weight: $effra-medium;
	color: $textMid;
	text-align: center;

	margin-bottom: 30px;
	width: 100%;
}



.upgrade-success-btnContainer{
	width: 100%;
	margin-top: 46px;
	margin-bottom: 18px;
	display: flex;
	align-items: center;
	justify-content: center;
}

button.upgrade--success-doneBtn{
	font-size: 24px;
	font-weight: $effra-bold;
	color: $white;
	text-align: center;

	outline: none;
	box-shadow: none;
	border: none;
	-webkit-appearance: none;
	-moz-appearance: none;

	background: darken($blue-light, 5%);
	border-color: darken($blue, 5%);

	

	width: 480px;
	height: 56px;
	border-radius: 5px;
	cursor: pointer;


}

.upgrade--success-cancelSub{
	font-size: 16px;
	font-weight: $effra-medium;
	color: $textLight;
	text-align: center;
	width: 100%;
}