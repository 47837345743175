.richTextMarketing-stickyNavContainer{
	width: $richTextMarketingCoreWidth;
	margin: 0 auto;
	margin-top: 48px;
	padding-top: 8px;
	padding-bottom: 0px;
	// background: $tempr;

	background: rgba(255,255,255,0.9);

	position: sticky;
	top: -1px;

	z-index: 1000;
	-webkit-transform: translateZ(0) 
}

.richTextMarketing-stickyNav{
	width: calc(100% + 24px + 24px);
	margin-left: -24px;
	height: 100%;
	
	display: flex;
	align-items: center;
	justify-content: center;

	// background: $tempr;

	padding-left: 2px;
	padding-right: 2px;

	padding-top: 2px;
	padding-bottom: 2px;

	background: $white;
	border: 1px solid lighten($lineDark, 5%);
	border-bottom: 1px solid $lineDark;
	//box-shadow: 0px 1px 2px 0px rgba(0,0,20,0.05);

	border-radius: 5px;

}

.richTextMarketing-stickyNav-tab{
	padding-left: 10px;
	padding-right: 10px;

	flex-grow: 1;
	flex-shrink: 0;

	height: 28px;

	display: flex;
	align-items: center;
	justify-content: center;

	text-transform: uppercase;
	font-size: 13px;
	letter-spacing: 0.04em;

	// border: 1px solid red;

	cursor: pointer;

	border-radius: 4px;	
}

.richTextMarketing-stickyNav-tab--active{
	// background: $gray-4;
	color: $white;
	color: black;
	font-weight: $lato-bold;
	letter-spacing: 0.05em;
}

.richTextMarketing-stickyNav-tab--inactive{
	// background: $gray-15;
	color: lighten($textMid, 5%);
	font-weight: $lato-bold;

	letter-spacing: 0.05em;

}

.richTextMarketing-stickyNav-tab--inactive:hover{
	background: $gray-15;
	color: $textBlack;
}

//
// MEDIA CHOICE CONTAINER

.richTextMarketing-mediaChoiceContainer{
	width: 100%;
	height: 640px;
	background: rgb(250,250,255);
}


