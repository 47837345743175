.importQuestions-freeLimitMessage{
	position: fixed;	
	right: calc(#{$width-importQuestions-rightPanel} + 30px);
	bottom: 10px;
	width: 180px;

	font-size: 14px;

	text-align: center;
	pointer-events: auto;

	cursor: pointer;

	padding-top: 8px;
	padding-bottom: 8px;

	border-radius: 5px;
	background: white;
	border: 1px solid $lineDark;
	box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.12);

	z-index: 200;
}

.importQuestions-freeLimitMessage--hide{
	pointer-events: none;
}

.importQuestions-freeLimitMessage--show{
	pointer-events: auto;
}

.importQuestions-freeLimitMessage-title{
	font-weight: $lato-semibold;
	font-size: 13px;
	color: $textDark;
	margin-bottom: 6px;
	text-align: center;
}

.importQuestions-freeLimitMessage:hover
.importQuestions-freeLimitMessage-title{
	color: $textDark;
}


.importQuestions-freeLimitMessage-sub{
	font-weight: $lato-semibold;
	font-size: 12.5px;
	color: $textBlue;
}

.importQuestions-freeLimitMessage:hover
.importQuestions-freeLimitMessage-sub{
	text-decoration: underline;
}
