.editor-bottomRightPanel{
	position: fixed;
	bottom: 5px;
	right: 6px; // don't know why this being weird

	border-radius: 5px;

	min-width: 100px;
	height: 32px;

	background: none;

	display: flex;
	align-items: flex-end;
	justify-content: flex-end;

	padding-left: 12px;
	padding-right: 0px;

	// background: $tempr;

	// width: 400px;
	width: calc(50vw - 160px);
	max-width: 550px;
}

.editor-bottomRightPanel:hover{
	// background: rgba(0,0,10,0.03);
	// maybe put back in later,see how it goes
}

.editor-saveStatus {

	position: absolute;
	right: 150px; // sorry about this - REFACTOR
	left: 0px;
	top: 0px;
	bottom: 0px;

	opacity: 0.8;

	// background: rgba(255,255,255,0.5);

	// background: $tempr;

	cursor: default;
	margin-right: 8px;

	display: flex;
	align-items: center;
	justify-content: flex-end;

	user-select: none;

	box-sizing: border-box;

	height: 100%;

	line-height: 2.2;
	font-size: 14px;
	color: $textDark;
	font-weight: $lato-bold;

	border-radius: 3px;

	z-index: 75;
}

.editor-bottomRightPanel:hover
.editor-saveStatus{
	opacity: 1;
}

.editor--questionEditor
.editor-saveStatus{
	right: 20px;
}



.editor-saveStatus .profilePicContainer{
	margin-right: 8px;
	flex-shrink: 0;
	flex-grow: 0;
}

.editor-saveStatus-label{
	flex-grow: 0;
	flex-shrink: 1;
	min-width: 10px;

	white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}


//  Live edit

.editor-saveStatus-label--isEditing{

	padding-left: 10px;
	padding-right: 38px; // account for pulse
	height: 26px;
	line-height: 1.9;
	color: $white;
	position: relative;
	border-radius: 4px;
	background: $blue-light;

	font-weight: $lato-semibold;
}


.editor-saveStatus-label-liveEditPulse{
	position: absolute;
	right: 5px;
	top: 0px;
	bottom: 0px;
	width: 31px;

	display: flex;
	align-items: center;
	justify-content: center;

}

.editor-saveStatus-label-liveEditPulse-dot{
	width: 5px;
	height: 5px;

	margin: 1.5px;

	border-radius: 50%;
	background: $white;

	opacity: 0.5;
}

@keyframes lockBlink {
  40%, 60% {
    opacity: 1;
  }
}

.editor-saveStatus-label-liveEditPulse-dot:nth-child(1){
	animation: 2s lockBlink infinite 0.3333s;
}

.editor-saveStatus-label-liveEditPulse-dot:nth-child(2){
	animation: 2s lockBlink infinite 0.6666s;
}

.editor-saveStatus-label-liveEditPulse-dot:nth-child(3){
	animation: 2s lockBlink infinite 0.9999s;
}


//


.editor-saveStatus-icon{
	margin-left: 8px;
	//margin-right: 8px;
	width: 16px;
	height: 16px;
	flex-grow: 0;
	flex-shrink: 0;
	position: relative;
}

.editor-saveStatus-icon .icon{
	height: 16px;
	width: 16px;
	position: absolute;
	top: 0px;
	left: 0px;
}

.editor-saveStatus.is--saved.timestampState--short
.editor-saveStatus-icon{
 	display: none;
}

//// SAVED

// Short

.editor-saveStatus.is--saved.timestampState--short .icon{
	fill: $green;
	fill: $color-o85;
}

// Mid

.editor-saveStatus.is--saved.timestampState--medium .icon{
	fill: $gray-11;
}

// Long

.editor-saveStatus.is--saved.timestampState--long
.editor-saveStatus-icon{
	display: none;
}


//// SAVING

.editor-saveStatus-icon--isSavingSpinner{
	margin-right: 8px;
}

.editor-saveStatus-isSavingSpinner {
	margin-top: 1px;
	height: 10px;
	width: 10px;
	border-radius: 14px;

	border: 2px rgba(0,0,10,0.08) solid;
	border-top: 2px $gray-8 solid;

	animation: spinnerRotateAnimation .8s infinite linear;
}




//// UNABLE TO SAVE

.editor-saveStatus.is--unableToSave
.editor-saveStatus-icon{
	margin-left: 0px;
	margin-right: 8px;
	// background: rgb(247,127,130);
	// padding-right: 8px;
	// padding-left: 8px;
}

.editor-saveStatus.is--unableToSave
.editor-saveStatus-icon .icon{
	// fill: $white;
	fill: $gray-7;
}

//
// PUBLIC AUTHOR USERNAME LINK
// seen by consumers

a.editor-saveStatus-publicAuthorUsernameLink{
	display: flex;
	padding-top: 6px;
	padding-bottom: 5px;
	padding-left: 8px;
	padding-right: 12px;
	margin-right: -12px;
	border-radius: 5px;
	cursor: pointer;
}

a.editor-saveStatus-publicAuthorUsernameLink .editor-saveStatus-publicAuthorUsernameLink-username,
a.editor-saveStatus-publicAuthorUsernameLink:visited .editor-saveStatus-publicAuthorUsernameLink-username{
	color: $textDark;
}

a.editor-saveStatus-publicAuthorUsernameLink:hover{
	background: rgba(0,0,40,0.03);
}
a.editor-saveStatus-publicAuthorUsernameLink:hover
.editor-saveStatus-publicAuthorUsernameLink-username{
	color: $textBlackDark;
}


//
//
// HELP

.editor-bottomRightPanel button.editor-bottomRightPanel-whatsPossibleBtn{
	height: 28px;
	display: flex;
	align-items: center;
	justify-content: center;

	flex-grow: 0;
	flex-shrink: 0;

	padding-left: 12px;
	padding-right: 12px;

	margin-left: 12px;
	margin-right: 12px;

	font-size: 14.5px;
	font-weight: $lato-bold;
	color: $textBlack;

	border: 1px solid $lineDark;
	border-bottom: 1px solid darken($lineDark, 5%);
	background: $white;

	border-radius: 4px;

	cursor: pointer;
	box-shadow: 0px 1px 2px 0px rgba(0,0,20,0.02);
}

.editor-bottomRightPanel button.editor-bottomRightPanel-whatsPossibleBtn{
	height: 30px; // not sure why this is required
}

.editor-bottomRightPanel button.editor-bottomRightPanel-whatsPossibleBtn:hover{
	border: 1px solid darken($lineDark, 5%);
	border-bottom: 1px solid darken($lineDark, 8%);
	box-shadow: 0px 1px 2px 0px rgba(0,0,20,0.05);
}

// HIDE WHAT'S NEW ON NARROW SCREENS

@media screen and (max-width: 1320px) {
	.editor-saveStatus{
		right: 100px; // sorry about this - REFACTOR
	}
  .editor-bottomRightPanel button.editor-bottomRightPanel-whatsPossibleBtn {
    display: none;
  }
}
