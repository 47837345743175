
.editor-slideList{
  // background: $tempr;
  min-height: calc(100vh - #{$height-editor-topBar} - 40px); // fix for drag to bottom
  box-sizing: border-box;
  padding-right: 2px;
  padding-left: 4px;
}


.editor-slideList-slideItem{
  width: 100%;
  height: 102px;

  display: flex;
  align-items: center;
  border-radius: 5px;

  cursor: pointer; // overrule the grab thing
  // border-top-right-radius: 5px;
  // border-bottom-right-radius: 5px;

  // border-top-left-radius: 4px;
  // border-bottom-left-radius: 4px;
}

.editor-slideList-slideItem--active{
  background: rgb(22,123,240);
  box-shadow: 0px 1px 0px 0px rgb(22,123,240), 0px -1px 0px 0px rgb(22,123,240);
}

.editor-slideList-slideItem-number{
  width: 25px;
  height: 100%;
  flex-grow: 0;
  flex-shrink: 0;

  display: flex;
  align-items: flex-end;
  justify-content: flex-end;

  box-sizing: border-box;
  padding-right: 6px;

  font-size: 12px;
  font-weight: $lato-bold;
  letter-spacing: 0.01em;
  padding-top: 12px;
  padding-bottom: 8px;

  color: rgb(150,149,157);

  user-select: none;
  cursor: pointer;
}

.editor-slideList-slideItem--active
.editor-slideList-slideItem-number{
  font-size: 13px;
  font-weight: $lato-heavy;
  color: $white;
}


.editor-slideList-slideItem-slideContainer{
  width: 142px;
  height: 88px;

  // width: 1280px;
  // height: 800px;

  position: relative;

  flex-grow: 0;
  flex-shrink: 0;

  border-radius: 2px;
  border: 1px solid rgb(210,214,220);
  overflow: hidden;

  // display: flex;
  // align-items: center;
  // justify-content: center;
}




.editor-slideList-slideItem--active
.editor-slideList-slideItem-slideContainer{
  border: 1px solid rgb(0,114,255);
  border-radius: 3px;

  box-shadow: 0px 1px 1px 0px rgba(0,0,20,0.2);
}

.editor-slideList-slideItem--inactive
.editor-slideList-slideItem-slideContainer{
  border: 1px solid $gray-12;
  border-radius: 3px;
  //box-shadow: 0px 1px 1px 0px rgba(0,0,20,0.25);
}




.editor-slideList-slideItem .slide{
  position: absolute;
  top: 0px;
  left: 0px;

  transform-origin: 0% 0%;
  border: 1px solid $invisible;

  // width: 140px;
  // height: 88px;
  // background: $white;
  //border: 1px solid rgb(210,214,220);

}

.editor-slideList-slideItem--active
.editor-slideList-slideItem-slide{
  border: 1px solid darken(rgb(0,114,255), 5%);

}

.editor-slideList-slideItem--inactive
.editor-slideList-slideItem-slide{
  border-bottom: 1px solid rgb(204,204,210);
}

.editor-slideList-slideItem--active:focus
.editor-slideList-slideItem-slideContainer{
  border: 1px solid rgb(255,223,60);
  box-shadow: 0px 0px 0px 1px rgb(255,223,60);
  border-radius: 2px;
}

.editor-slideList-slideItem--active:focus{
  outline: none;
}




































//
// SCROLLBAR
//

// DEFAULT

.editor-slideListContainer{
  // overflow-y: overlay;
  // overflow: visible;
}

.editor-slideListContainer::-webkit-scrollbar {
  width: 8px;
}

.editor-slideListContainer{
  scrollbar-width: thin;
  scrollbar-color: rgba(0,0,20,0.12) $invisible;
}

// track
.editor-slideListContainer::-webkit-scrollbar-track {
  //background: #ddd;
  background: $tempr;
  background: $invisible;

}

//thumb
.editor-slideListContainer::-webkit-scrollbar-thumb {
  background: rgba(0,0,20,0.12);
  // background: transparentize($gray-4, 0.9);
  border-right: 1px solid $invisible;

  // background: black;
  // background: $tempb;
}

//
// HOVER ON LIST
.editor-slideListContainer:hover::-webkit-scrollbar {
  width: 8px;
  // width: 10px;
}
.editor-slideListContainer:hover{
  scrollbar-color: rgba(0,0,24,0.18) rgba(0,0,20,0.08);
}


// thumb
.editor-slideListContainer:hover::-webkit-scrollbar-thumb{
  background: $tempg;
  background: rgba(0,0,24,0.18);
}

// track
.editor-slideListContainer:hover::-webkit-scrollbar-track {
  background: rgba(0,0,20,0.12);
  background: $tempb;
  background: rgba(0,0,20,0.08);
  // background: $tempr;
}


.editor-slideListContainer.editor-slideListContainer--noScroll:hover::-webkit-scrollbar-track {
  background: $invisible;
}

.editor-slideListContainer.editor-slideListContainer--noScroll{
  scrollbar-color: $invisible $invisible;
}



// CURSOR FIXES
// Pointer by default, otherwise grabbing
//

.editor-slideList {
  cursor: pointer;
}

.react-contextmenu-wrapper{
  cursor: unset;
}

.editor-slideList-slideItem .slide,
.editor-slideList-slideItem .slide * {
  pointer-events: none !important;
}

.editor-slideListContainer[data-rbd-scroll-container-context-id="0"],
.editor-slideListContainer[data-rbd-scroll-container-context-id="0"] *{
  cursor: grabbing !important;
}


// MAX FREE LIMIT MESSAGE

.editor-slideListContainer-maxFreeLimitMessage{
  position: absolute;

  left: 0px;
  right: 0px;
  bottom: 0px;
  height: 100px;

  z-index: 1000;

  padding-top: 8px;

  box-sizing: border-box;


  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  background: transparentize(#F6F6FC, 0.2);
  // background: $tempr;

  cursor: pointer;



}

.editor-slideListContainer-maxFreeLimitMessage--short{
  height: 37px;
}


.editor-slideListContainer-maxFreeLimitMessage-head{
  font-weight: $lato-semibold;
  font-size: 13px;
  color: $textDark;
  margin-bottom: 6px;
  text-align: center;
}


.editor-slideListContainer-maxFreeLimitMessage-head--visible,
.editor-slideListContainer-maxFreeLimitMessage-maxReachedSection--visible {
  opacity: 1;
  height: auto;
}

.editor-slideListContainer-maxFreeLimitMessage-head--hidden{
  opacity: 0;
  height: 0px;
}

.editor-slideListContainer-maxFreeLimitMessage-maxReachedSection--hidden {
  opacity: 0;
  text-align: center;
}

.editor-slideListContainer-maxFreeLimitMessage:hover
.editor-slideListContainer-maxFreeLimitMessage-head,
.editor-slideListContainer-maxFreeLimitMessage-sub{
  color: $textDark;
}

.editor-slideListContainer-maxFreeLimitMessage-proLink,
.editor-slideListContainer-maxFreeLimitMessage-or,
.editor-slideListContainer-maxFreeLimitMessage-setLink{
  font-size: 12.5px;
  letter-spacing: 0.01em;
  text-align: center;
}

.editor-slideListContainer-maxFreeLimitMessage-proLink,
.editor-slideListContainer-maxFreeLimitMessage-setLink{
  font-weight: $lato-semibold;
  color: $textBlue;
}

.editor-slideListContainer-maxFreeLimitMessage-proLink:hover,
.editor-slideListContainer-maxFreeLimitMessage-setLink:hover{
  text-decoration: underline;
}
