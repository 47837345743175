//
// QUESTION COUNT BADGE
// Used everywhere - small badge with count of questions in it
// has different sizes and colors as props
// sometimes custom styling is tweaked per component
//

.questionCountBadge {
	font-weight: $lato-bold;
	text-align: center;
}

.questionCountBadge--mid {
	width: 25px;
	height: 17px;
	font-size: 13px;
	border-radius: 2px;
	line-height: 1.25;
}

.questionCountBadge--large {
	width: 34px;
	height: 24px;
	border-radius: 3px;
	font-size: 18px;
	line-height: 1.275;
}

.questionCountBadge--blue {
	color: $white;
	background: $blue-light;
}

.questionCountBadge--blueLight {
	color: $blue;
	background: transparentize($blue-extraLight,0.1);
}

.questionCountBadge--blueDark {
	color: $white;
	background: rgba(0,0,40,0.15);
}

.questionCountBadge--white {
	color: $textDark;
	background: $gray-115;
}

.questionCountBadge--gray {
	color: $textMid;
	background: rgb(245,245,250);
}





