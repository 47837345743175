//
// FORM FIELD
// these are quite old, used with Redux forms
// they have nice built in effects for focus, warning, error, etc.
//

$fontSize-formField: 16px;

$height-formField: 34px;

$border-formField: 1px solid rgb(200,200,210);
$border-formField--hover: 1px solid rgb(195,195,205);
$border-formField--focus: 1px solid $blue;
$boxShadow-formField--focus: 0px 0px 2px 0px transparentize($blue, 0.5);

$color-formField--warningBorder: rgb(255,169,64);
$color-formField--warningText: rgb(234,152,15);
$color-formField--warningBG: rgb(255,241,223);

.formField{
	margin-bottom: 20px;
}

// TITLE

.formField-label{
	font-size: 15px;
	font-weight: $lato-bold;
	color: $textDark;
	text-transform: capitalize;
}

.formField-subLabel{
	font-size: 12px;
	margin-top: 2px;
	font-weight: $lato-medium;
	color: $textMid;
}

.formField.is--large .formField-label{
	font-size: 18px;
}

.formField.is--large .formField-subLabel{
	font-size: 13px;
}

//

@import 'FormField--Text';
@import 'FormField--Select';
@import 'FormField--ClassColor';

// ERROR TEXT

.formField-error{
	color: $color-formField--warningText;
	font-size: 13px;
	font-weight: $lato-bold;
}


