//
// SECTION MARKER
// Used everywhere - creates div with appropriate class color, with layout and other styles added where used
//

// DECIDED THIS WAS BETTER THAN INLINE with JS
.sectionMarker.has--classcolor-blue			 	.icon		{ fill: $classColor-blue; }
.sectionMarker.has--classcolor-purple		 	.icon		{ fill: $classColor-purple; }
.sectionMarker.has--classcolor-red				.icon		{ fill: $classColor-red; }
.sectionMarker.has--classcolor-orange		 	.icon		{ fill: $classColor-orange; }
.sectionMarker.has--classcolor-green			.icon		{ fill: $classColor-green; }
.sectionMarker.has--classcolor-sky				.icon		{ fill: $classColor-sky; }
.sectionMarker.has--classcolor-pink			 	.icon		{ fill: $classColor-pink; }
.sectionMarker.has--classcolor-teal			 	.icon		{ fill: $classColor-teal; }
.sectionMarker.has--classcolor-grey			 	.icon		{ fill: $classColor-grey; }

.sectionMarker.is--pill.has--classcolor-blue			 	{ background: $classColor-blue; }
.sectionMarker.is--pill.has--classcolor-purple		 	{ background: $classColor-purple; }
.sectionMarker.is--pill.has--classcolor-red					{ background: $classColor-red; }
.sectionMarker.is--pill.has--classcolor-orange		 	{ background: $classColor-orange; }
.sectionMarker.is--pill.has--classcolor-green				{ background: $classColor-green; }
.sectionMarker.is--pill.has--classcolor-sky					{ background: $classColor-sky; }
.sectionMarker.is--pill.has--classcolor-pink			 	{ background: $classColor-pink; }
.sectionMarker.is--pill.has--classcolor-teal			 	{ background: $classColor-teal; }
.sectionMarker.is--pill.has--classcolor-grey			 	{ background: $classColor-grey; }

.sectionMarker.is--colorOnly.has--classcolor-blue			 	{ background: $classColor-blue; }
.sectionMarker.is--colorOnly.has--classcolor-purple		 	{ background: $classColor-purple; }
.sectionMarker.is--colorOnly.has--classcolor-red				{ background: $classColor-red; }
.sectionMarker.is--colorOnly.has--classcolor-orange		 	{ background: $classColor-orange; }
.sectionMarker.is--colorOnly.has--classcolor-green			{ background: $classColor-green; }
.sectionMarker.is--colorOnly.has--classcolor-sky				{ background: $classColor-sky; }
.sectionMarker.is--colorOnly.has--classcolor-pink			 	{ background: $classColor-pink; }
.sectionMarker.is--colorOnly.has--classcolor-teal			 	{ background: $classColor-teal; }
.sectionMarker.is--colorOnly.has--classcolor-grey			 	{ background: $classColor-grey; }

.sectionMarker.has--classcolor-blue			 	.sectionMarker-underline	{ background: $classColor-blue; }
.sectionMarker.has--classcolor-purple		 	.sectionMarker-underline	{ background: $classColor-purple; }
.sectionMarker.has--classcolor-red				.sectionMarker-underline	{ background: $classColor-red; }
.sectionMarker.has--classcolor-orange		 	.sectionMarker-underline	{ background: $classColor-orange; }
.sectionMarker.has--classcolor-green			.sectionMarker-underline	{ background: $classColor-green; }
.sectionMarker.has--classcolor-sky				.sectionMarker-underline	{ background: $classColor-sky; }
.sectionMarker.has--classcolor-pink			 	.sectionMarker-underline	{ background: $classColor-pink; }
.sectionMarker.has--classcolor-teal			 	.sectionMarker-underline	{ background: $classColor-teal; }
.sectionMarker.has--classcolor-grey			 	.sectionMarker-underline	{ background: $classColor-grey; }