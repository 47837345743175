.liveView-controlBar-setNavigator{
	height: 36px;
	position: absolute;
	bottom: $height-controlBar-bar;
	width: 100%;

	background: $invisible;

	display: flex;

	box-sizing: border-box;
	opacity: 1;

}

.liveView-controlBar-setNavigator-item{
	
	// padding-left: 5px;
	// padding-right: 5px;
	// box-sizing: border-box;
	display: flex;
	width: 10px;
	flex-grow: 1;
	box-sizing: border-box;
	padding-top: 10px; // allows for extra hover area above
	align-items: center;
	position: relative;
	height: 100%;
	// background: rgba(255,255,255,0.5);
	cursor: pointer;
	// margin-right: 10px;
	//margin-right: 1px;
}

.liveView-controlBar-setNavigator-item:last-child{
	margin-right: 0px;
}

.liveView-controlBar-setNavigator-item-tab{	
	height: 8px;
	width: 100%;
	// transition: opacity 200ms cubic-bezier(.2, .3, .25, .9);
}

.liveView-controlBar-setNavigator-item.liveView-controlBar-setNavigator-item--hasResponses
.liveView-controlBar-setNavigator-item-tab {
	background: $blue-light;
	opacity: 0.75;
}

.liveView-controlBar-setNavigator-item.liveView-controlBar-setNavigator-item--noResponses
.liveView-controlBar-setNavigator-item-tab {
	background: $gray-10;
	opacity: 0.5;

}
.liveView-controlBar-setNavigator-item:hover
.liveView-controlBar-setNavigator-item-tab{
	opacity: 1;
}

.liveView-controlBar-setNavigator-item.liveView-controlBar-setNavigator-item--currentQuestion
.liveView-controlBar-setNavigator-item-tab {
	background: $blue;
	opacity: 1;
}

.liveView-controlBar-setNavigator-item:first-child
.liveView-controlBar-setNavigator-item-tab{
	border-top-left-radius: 5px;
	border-bottom-left-radius: 5px;
}

.liveView-controlBar-setNavigator-item:last-child
.liveView-controlBar-setNavigator-item-tab{
	border-top-right-radius: 5px;
	border-bottom-right-radius: 5px;
}

/// PREVIEW

.liveView-controlBar-setNavigator-item-preview {
	opacity: 0;

	width: 256px;
	height: 160px;
	background: $white;
	position: absolute;

	border: 1px solid $lineDark;
	border-radius: 1px;

	left: 50%;
	transform: translateX(-50%);
	bottom: 22px;

	pointer-events: none;
	

}

.liveView-controlBar-setNavigator-item:hover .liveView-controlBar-setNavigator-item-preview {
	opacity: 1;
}

