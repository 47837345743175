.editor-insertImageModalContainer{
  position: fixed;
  top: 0px;
  right: 0px;
  left: 0px;
  bottom: 0px;

  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;

  background: rgba(0,0,10,0.25);
}

.editor-insertImageModalContainerBG{
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}


.editor-insertImageModal{
  width: 752px;
  height: 500px;
  background: rgb(253,253,255);
  border: 1px solid rgb(204,204,208);
  border-bottom: 1px solid darken(rgb(204,204,208),5%);

  box-shadow: 0px 2px 4px 0px rgba(0,0,40,0.16), 0px 6px 12px 0px rgba(0,0,40,0.12);
  border-radius: 8px;

  box-sizing: border-box;

  padding-left: 28px;
  padding-right: 28px;
  padding-top: 24px;

  text-align: left;

  position: relative;

  z-index: 10;
}


.editor-insertImageModal--image{
  // for inset image modal, add 40px for
  // bing attribution footer
  height: 540px;
}

.editor-insertImageModal-searchBar{
  height: 48px;
  width: 100%;
  position: relative;
}

input.editor-insertImageModal-searchBar-input{
  width: 100%;
  height: 100%;

  box-sizing: border-box;

  font-size: 21.5px;
  font-weight: $lato-bold;
  color: $textBlackDark;

  border: 1px solid $lineDark;
  background: $white;

  padding-left: 14px;

  padding-left: 38px;
  padding-bottom: 2px;



  border-radius: 4px;
}




input.editor-insertImageModal-searchBar-input:focus{
  border: 1px solid $blue;
  outline: none;
  box-shadow: 0px 0px 0px 0.5px $blue;

  padding-right: 185px; // prevent overlap with tab keyboard hint
}

input.editor-insertImageModal-searchBar-input.editor-insertImageModal-searchBar-input--isSearching:focus,
input.editor-insertImageModal-searchBar-input.editor-insertImageModal-searchBar-input--isSearching{
  padding-right: 70px; // prevent overlap search spinner
}

input.editor-insertImageModal-searchBar-input.editor-insertImageModal-searchBar-input--showClearButton,
input.editor-insertImageModal-searchBar-input.editor-insertImageModal-searchBar-input--showClearButton:focus{
  padding-right: 100px;
}

input.editor-insertImageModal-searchBar-input::placeholder{
  color: #D3D2D8;
  color: rgb(192,192,205);
}

//
// SEARCH UI

.editor-insertImageModal-searchBar-searchBarUI{
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
}

// ICON

.editor-insertImageModal-searchBar-searchBarUI-searchIconContainer{
  position: absolute;
  top: 2px;
  left: 2px;
  width: 36px;

  height: 44px;
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;

  box-sizing: border-box;

  padding-left: 4px;

  border: none;
  outline: none;
  box-shadow: none;

  // background: $tempr;

  z-index: 5;

  display: flex;
  align-items: center;
  justify-content: center;

  pointer-events: none;
}

.editor-insertImageModal-searchBar-searchBarUI-searchIconContainer svg{
  width: 18px;
  height: 18px;
  fill: rgb(192,192,205);
}

// input.editor-insertImageModal-searchBar-input.editor-insertImageModal-searchBar-input--nonEmpty:focus ~
// .editor-insertImageModal-searchBar-searchBarUI
// .editor-insertImageModal-searchBar-searchBarUI-searchIconContainer svg{
// 	fill: $gray-11;
// }


// BUTTON/HINT

.editor-insertImageModal-searchBar-searchBarUI-hint{
  position: absolute;
  top: 1px;
  right: 1px;

  height: 46px;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;

  box-sizing: border-box;
  padding-right: 14px;

  min-width: 178px;

  background: none;

  // background: $tempr;

  cursor: pointer;
  user-select: none;

  border: none;
  box-shadow: none;
  outline: none;

  display: flex;
  align-items: center;
  justify-content: flex-end;

  font-size: 13px;
}

.editor-insertImageModal-searchBar-searchBarUI-hint-secondaryLabel{
  font-weight: $lato-semibold;
  color: lighten($textDark, 5%);

  letter-spacing: 0.01em;
}

.editor-insertImageModal-searchBar-searchBarUI-hint-key{
  display: flex;

  align-items: center;
  justify-content: center;

  padding-top: 4px;
  padding-bottom: 4px;

  padding-left: 6px;
  padding-right: 8px;

  font-weight: $lato-bold;
  color: $white;
  background: $gray-5;
  border-radius: 4px;

  margin-left: 6px;
  margin-right: 6px;

  letter-spacing: 0.025em;
}

.editor-insertImageModal-searchBar-searchBarUI-hint-key svg{
  width: 10px;
  height: 10px;
  fill: $white;

  margin-left: 3px;
}


.editor-insertImageModal-searchBar-searchBarUI-hint{
  opacity: 0;
  transition: opacity 50ms linear;
  pointer-events: none;
}

input.editor-insertImageModal-searchBar-input.editor-insertImageModal-searchBar-input--showSearchHint ~
.editor-insertImageModal-searchBar-searchBarUI
.editor-insertImageModal-searchBar-searchBarUI-hint{
  pointer-events: auto;
}

input.editor-insertImageModal-searchBar-input.editor-insertImageModal-searchBar-input--showSearchHint:focus ~
.editor-insertImageModal-searchBar-searchBarUI
.editor-insertImageModal-searchBar-searchBarUI-hint{
  opacity: 1;
  transition: opacity 50ms linear;
  pointer-events: auto;
}

.editor-insertImageModal-searchBar-searchBarUI-hint:hover
.editor-insertImageModal-searchBar-searchBarUI-hint-key,
.editor-insertImageModal-searchBar-searchBarUI-hint:hover
.editor-insertImageModal-searchBar-searchBarUI-hint-secondaryLabel{
  display: none;
}

// SEARCHING SPINNER

.editor-insertImageModal-searchBar-searchBarUI-searchingHint{
  position: absolute;
  top: 1px;
  right: 1px;

  height: 46px;

  width: 64px;

  display: flex;
  align-items: center;
  justify-content: center;
}

.editor-insertImageModal-searchBar-searchBarUI-searchingHint-spinnerContainer{
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.editor-insertImageModal-searchBar-searchBarUI-searchingHint-spinner{

  height: 20px;
  width: 20px;

  border-radius: 50%;

  border: 3px transparentize($blue, 0.8) solid;
  border-top: 3px $blue solid;

  opacity: 0.75;

  animation: spinnerRotateAnimation 0.4s infinite linear;
}



// SEARCH BTN

.editor-insertImageModal-searchBar-searchBarUI-hint-searchBtn{

  position: absolute;
  right: 4px;
  left: 36px;
  top: 4px;
  bottom: 4px;

  background: $blue;
  background: transparentize($blue, 0.95);

  // background: $gray-15;
  // background: none;

  border: none;
  //border: 1px solid transparentize($blue, 0.98);

  box-shadow: none;

  color: $white;
  color: darken($blue, 5%);

  font-size: 15px;
  font-weight: $lato-bold;
  letter-spacing: 0.025em;

  border-radius: 4px;

  opacity: 0;

  display: flex;
  align-items: center;
  justify-content: center;

}

button.editor-insertImageModal-searchBar-searchBarUI-hint:active
.editor-insertImageModal-searchBar-searchBarUI-hint-searchBtn{
  background: transparentize($blue, 0.9);
}

.editor-insertImageModal-searchBar-searchBarUI-hint:hover .editor-insertImageModal-searchBar-searchBarUI-hint-searchBtn,
.editor-insertImageModal-searchBar-searchBarUI-hint:active .editor-insertImageModal-searchBar-searchBarUI-hint-searchBtn{
  opacity: 1;
}




// CANCEL BUTTON
button.editor-insertImageModal-searchBar-searchBarUI-cancelBtn,
button.editor-insertImageModal-searchBar-searchBarUI-clearBtn{
  position: absolute;
  top: 6px;
  right: 6px;
  height: 36px;
  width: 80px;

  background: $tempr;
  background: $gray-15;
  background: none;
  border: none;
  box-shadow: none;

  cursor: pointer;

  font-size: 14px;
  font-weight: $lato-semibold;
  letter-spacing: 0.015em;

  border-radius: 4px;

  color: $textMid;
}

button.editor-insertImageModal-searchBar-searchBarUI-cancelBtn,
button.editor-insertImageModal-searchBar-searchBarUI-clearBtn:hover{
  background: $gray-15;
  border: 1px solid $gray-13;
  color: $textBlack;
}

button.editor-insertImageModal-searchBar-searchBarUI-cancelBtn{
  color: $textMid;
}

button.editor-insertImageModal-searchBar-searchBarUI-cancelBtn:hover{
  background: $gray-15;
  border: 1px solid $gray-125;
  color: $textBlackDark;
}








//

.editor-insertImageModal-results{
  margin-top: 5px;

  box-sizing: border-box;

  width: 100%;

  width: calc(100% + 24px + 24px); // extra wide so scrollbar doesn't cause issues.
  margin-left: -24px;

  box-sizing: border-box;
  padding-left: 24px;
  padding-right: 24px;

  height: calc(100% - 53px);

  overflow-x: hidden;
}

.editor-insertImageModal-results--image{
  // for inset image modal, subtract extra 40px for
  // bing attribution footer
  height: calc(100% - 93px);
}

.editor-insertImageModal-results--hasImages{
  overflow-y: scroll;
}

.editor-insertImageModal-results--noImages{
  overflow-y: hidden;
}

.editor-insertImageModal-results--isSearching
.editor-insertImageModal-results-imageGrid{
  opacity: 0.8;
  //transition: opacity 100ms linear;
}

.editor-insertImageModal-results-imageGrid{
  margin-top: 8px;
  margin-bottom: 48px;
}

.editor-insertImageModal-results .ReactGridGallery_tile-icon-bar svg,
.editor-insertImageModal-results .ReactGridGallery_tile-overlay{
  display: none;
}

.editor-insertImageModal-results-imageGrid:hover .ReactGridGallery_tile {
  opacity: 0.85;
}

.editor-insertImageModal-results-imageGrid .ReactGridGallery_tile:hover {
  opacity: 1;
}

// LOAD MORE BUTTON

.editor-insertImageModal-results-loadMoreContainer{
  width: 100%;
  height: 50px;
  // background: $tempr;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;

  padding-top: 12px;
  padding-bottom: 24px;
}

button.editor-insertImageModal-results-loadMoreBtn{
  width: 100%;
  height: 100%;
  background: none;
  background: $blue-extraLight;
  border: none;

  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;

  color: $textBlue;
  letter-spacing: 0.02em;

  font-size: 14.5px;
  font-weight: $lato-semibold;

  border-radius: 5px;
}

button.editor-insertImageModal-results-loadMoreBtn:hover{
  background: lighten($blue, 43%);
}

button.editor-insertImageModal-results-loadMoreBtn--loading,
button.editor-insertImageModal-results-loadMoreBtn--loading:hover{
  background: lighten($blue, 41%);
}

// NO RESULTS STYLING

.editor-insertImageModal-results--noResults{
  padding-top: 96px;
  //padding-left: 40px;

  width: 100%;
  text-align: center;

  font-size: 15.5px;
  font-weight: $lato-medium;
  color: $textMid;

  user-select: none;
  cursor: default;
}

// COPY PASTE HELP LINK

a.editor-insertImageModal-copyPasteTooltip{

  position: absolute;

  height: 32px;
  bottom: 12px;
  left: 50%;

  transform: translateX(-50%);

  padding-right: 14px;
  padding-left: 14px;

  height: 30px;

  color: $textBlack;
  margin-bottom: 8px;

  text-align: center;
  opacity: 0.8;
  background: none;

  border-radius: 4px;

  font-size: 13px;
  letter-spacing: 0.025em;

  font-weight: $lato-medium;

  display: flex;
  align-items: center;
  justify-content: center;

  transition: opacity 50ms linear;

  z-index: 10;

  user-select: none;

}

a.editor-insertImageModal-copyPasteTooltip--searching{
  opacity: 0.25;
  transition: opacity 50ms linear;
}

a.editor-insertImageModal-copyPasteTooltip:hover{
  opacity: 1;
  color: darken($textBlue, 5%);
  text-decoration: underline !important;
}

// FOOTER
.editor-insertImageModal-footer {

  position: absolute;

  height: 108px;
  bottom: 16px;
  left: 50%;

  transform: translateX(-50%);

  padding-left: 60px;
  padding-right: 60px;
  padding-top: 6px;
  box-sizing: border-box;

  border-radius: 10px;

  background: rgb(249,249,254);

  display: flex;
  justify-content: center;
  align-items: top;

}

.editor-insertImageModal--gif
.editor-insertImageModal-footer{
  padding-top: 0px;
  align-items: center;
  height: 86px;

  padding-left: 50px;
  padding-right: 50px;
}


.editor-insertImageModal-footer--images{
  display: none; // maybe fancier if we include in-set
}

.editor-insertImageModal-footer--searching{
  opacity: 0.25;
  transition: opacity 50ms linear;
}


.editor-insertImageModal-footer-hiddenUploadInput{
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
    pointer-events: none;
}

.editor-insertImageModal-footer-uploadBtn{
  width: 320px;
  height: 56px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  padding-top: 2px;
  box-sizing: border-box;

  cursor: pointer;

  user-select: none;
  border-radius: 5px;

  margin: 10px;

  border: 1px solid $gray-12;
  border-bottom: 1px solid $gray-11;
  background: $white;

  box-shadow: 0px 2px 4px 0px rgba(0,0,5,0.04);
}

.editor-insertImageModal--gif
.editor-insertImageModal-footer-uploadBtn{
  width: 340px;
}

.editor-insertImageModal-footer-uploadBtn-primary{
  font-size: 14.5px;
  font-weight: $lato-bold;
  color: $textBlackDark;
  letter-spacing: 0.02em;
  margin-bottom: 2px;
}

.editor-insertImageModal-footer-uploadBtn-secondary{
  font-size: 12px;
  font-weight: $lato-medium;
  color: lighten($textMid, 10%);
  letter-spacing: 0.02em;
}

.editor-insertImageModal-footer-uploadBtn:hover{
  border: 1px solid $gray-10;
  border-bottom: 1px solid $gray-9;
}

.editor-insertImageModal-footer-uploadBtn:active{
  border: 1px solid $gray-9;
  border-bottom: 1px solid $gray-8;
  background: $gray-14;
}

// BING ATTRIBUTION CONTAINER
.editor-insertImageModal-footer-bingAttributionContainer{
  position: absolute;
  top: -70px;
  left: 0;
  right: 0;
}

// GIPHY LOGO CONTAINER

.editor-insertImageModal-footer-giphyLogoContainerWrapper{
  position: absolute;
  left: 0px;
  top: calc(-46px - 8px);
  // bottom: -8px;

  height: 46px;
  right: 0px;

  display: flex;
  align-items: center;
  justify-content: center;

  // background: $tempr;

}

// GIPHY ICON CONTAINER

a.editor-insertImageModal-footer-giphyLogoContainer{

  height: 46px;
  width: 150px;

  box-sizing: border-box;

  user-select: none;

  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;

}

.editor-insertImageModal-footer-giphyLogoContainer img{
  width: 150px;
  overflow: hidden;
  border-radius: 4px; // add in later

  object-fit: contain;

  // margin-top: 6px;
}

// SEARCH RESULT ATTRIBUTION FOOTER

.editor-insertImageModal-searchResult-footer-bingAttributionContainer--noImages {
  display: none;
}

// // PUT BACK IN LATER WITH PROPER SEARCH BUTTON
// // input.editor-insertImageModal-searchBar-input.editor-insertImageModal-searchBar-input--showSearchHint ~
// // .editor-insertImageModal-searchBar-searchBarUI
// // .editor-insertImageModal-searchBar-searchBarUI-hint{
// // 	pointer-events: auto;
// // }
