$liveView-1280ratio: calc(100vw / 1280);

.liveView-toolModal--container{
	position: absolute;
	top: 0px;
	left: 0px;
	bottom: 0px;
	right: 0px;
	z-index: 99000; // above media
	background: $colorBG-fullScreen-controls;
	display: flex;
	align-items: center;
	justify-content: center;
	user-select: none;
	// cursor: pointer;
	flex-direction: column;
}

.liveView-toolModal{
	background: $white;
	border-radius: 7px;
	border: 1px solid $lineDark;
	box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.24);
	cursor: default;
	overflow-x: hidden;
	overflow-y: auto;
}

.liveView-toolModal--container-paddingBox{
	display: flex;
	align-items: center;
	justify-content: center;
	// background: $tempr;
	width: 100%;
	font-size: 18px;
	font-weight: $lato-bold;
	color: $invisible;
	flex-grow: 1;
	opacity: 1;
}

.liveView-toolModal:hover ~ .liveView-toolModal--container-paddingBox{
	opacity: 0.5;
}


// CARD LIST

.liveView-toolModal.toolModal--cardList{
	//width: 920px;
	width: calc(#{$liveView-1280ratio} * 920);
	// min-height: 500;
	min-height: calc(#{$liveView-1280ratio} * 500);
	display: flex;
	flex-direction: column;
	align-items: center;
	box-sizing: border-box;
	//padding-bottom: 30px;
	padding-bottom: calc(#{$liveView-1280ratio} * 30);
	max-height: calc(100% - 100px);
}

.toolModal--cardList-title{
	font-size: calc(#{$liveView-1280ratio} * 36);
	// font-size: 36px;
	color: $textBlack;
	font-weight: $lato-heavy;
	width: 100%;
	text-align: center;
	//margin-top: 40px;
	margin-top: calc(#{$liveView-1280ratio} * 40);
	
}

.toolModal--cardList-studentCardGrid{
	// width: 780px;
	width: calc(#{$liveView-1280ratio} * 780);
	column-count: 4;

  // column-gap: 15px;
  column-gap: calc(#{$liveView-1280ratio} * 15);
  // margin-top: 50px; 
  margin-top: calc(#{$liveView-1280ratio} * 50); 
}

.toolModal--cardList-studentCardGrid.classSizeIs--over36{
	column-count: 5;
  //column-gap: 10px;
  column-gap: calc(#{$liveView-1280ratio} * 10); 
  //margin-top: 30px;
  margin-top: calc(#{$liveView-1280ratio} * 30); 
}



.toolModal--cardList-studentCardGrid-studentCard--container{	
	display: inline-block;
 	vertical-align: top;

 	break-inside: avoid-column;
 	-webkit-column-break-inside: avoid;

	//width: 180px;
	width: calc(#{$liveView-1280ratio} * 180); 
	// height: 40px;
	height: calc(#{$liveView-1280ratio} * 40); 

	// background: $tempr;
	position: relative;
}

.toolModal--cardList-studentCardGrid.classSizeIs--over36 .toolModal--cardList-studentCardGrid-studentCard--container{
 	// height: 26px;
 	height: calc(#{$liveView-1280ratio} * 26); 
 	// width: 148px;
 	width: calc(#{$liveView-1280ratio} * 148); 
}


.toolModal--cardList-studentCardGrid-studentCard{
	//height: 32px;
	height: calc(#{$liveView-1280ratio} * 32); 
	vertical-align: top;
	width: 100%;
	// background: $tempr;
	display: flex;

	//font-size: 21px;
	font-size: calc(#{$liveView-1280ratio} * 21); 
	font-weight: $lato-semibold;
}

/// big class handling
.toolModal--cardList-studentCardGrid.classSizeIs--over36 .toolModal--cardList-studentCardGrid-studentCard{
 	//font-size: 18px;
 	font-size: calc(#{$liveView-1280ratio} * 18); 
 	//height: 24px;
 	height: calc(#{$liveView-1280ratio} * 24); 
}
///


.toolModal--cardList-studentCardGrid-studentCard-name{
	color: $textBlack;
	max-width: calc(100% - (#{$liveView-1280ratio} * 35)); //long name handling
	white-space: nowrap;
	height: 100%;
  overflow: hidden;
  text-overflow: ellipsis;

  -webkit-backface-visibility: hidden; // fix for flickering
}

.toolModal--cardList-studentCardGrid-studentCard-cardNumber{
	color: $textBlue;
	//width: 35px;
	width: calc(#{$liveView-1280ratio} * 35); 
	box-sizing: border-box;
	//padding-left: 6px;
	padding-left: calc(#{$liveView-1280ratio} * 6); 
}




