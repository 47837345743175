////
//// Grade Level
//// 

.publishingFlowModalPage--gradeLevel-gradeLevelCheckboxContainer{
	display: flex;
	width: 100%;
	margin-top: 16px;
}


.publishingFlowModalPage--gradeLevel-gradeLevelCheckbox{
	text-align: center;
	border: 1px solid $lineDark;
	width: calc(100%/13);
	margin-right: -1px;
	height: 70px;

	background: $white;

	user-select: none;
	cursor: pointer;
}

.publishingFlowModalPage--gradeLevel-gradeLevelCheckbox--active{
	background: $blue;

	border: 1px solid darken($blue,5%);

	position: relative;
	z-index: 10;
}

.publishingFlowModalPage--gradeLevel-gradeLevelCheckbox--inactive:hover{
	background: $blue-extraLight;
	border: 1px solid darken($lineDark, 5%);
	position: relative;
	z-index: 5;
}

.publishingFlowModalPage--gradeLevel-gradeLevelCheckbox--inactive:hover
.publishingFlowModalPage--gradeLevel-gradeLevelCheckbox-gradeLabel{
	color: $textBlack;
}

.publishingFlowModalPage--gradeLevel-gradeLevelCheckbox--inactive:hover
.publishingFlowModalPage--gradeLevel-gradeLevelCheckbox-ageLabel{
	color: $textMid;
}

.publishingFlowModalPage--gradeLevel-gradeLevelCheckbox-gradeLabel{
	font-size: 17px;
	font-weight: $lato-bold;
	color: $textDark;
	letter-spacing: 0.01em;
	margin-top: 18px;
	margin-bottom: 4px;
}

.publishingFlowModalPage--gradeLevel-gradeLevelCheckbox--active
.publishingFlowModalPage--gradeLevel-gradeLevelCheckbox-gradeLabel{
	color: $white;
}


.publishingFlowModalPage--gradeLevel-gradeLevelCheckbox-ageLabel{
	font-size: 12.5px;
	font-weight: $lato-semibold;
	letter-spacing: 0.005em;
	color: $textLight;
}

.publishingFlowModalPage--gradeLevel-gradeLevelCheckbox--active
.publishingFlowModalPage--gradeLevel-gradeLevelCheckbox-ageLabel{
	color: $white;
	opacity: 0.75;
}



//curved edges
.publishingFlowModalPage--gradeLevel-gradeLevelCheckbox:first-child{
	border-top-left-radius: 4px;
	border-bottom-left-radius: 4px;
}
.publishingFlowModalPage--gradeLevel-gradeLevelCheckbox:last-child{
	border-top-right-radius: 4px;
	border-bottom-right-radius: 4px;
}

//
// Tooltip
//

.publishingFlowModalPage--gradeLevel-gradeLevelCheckbox:after{
	transition-duration: 0ms;
	filter: none; // remove drop-shadow	
}

.publishingFlowModalPage--gradeLevel-gradeLevelCheckbox:hover:after{
	transition-delay: 0ms;
	transition-duration: 0ms;
}

.publishingFlowModalPage--gradeLevel-gradeLevelCheckboxContainer:hover ~ 
.publishingFlowModalPage--gradeLevel-gradeLevel-extraContainer{
	opacity: 0.5;
}
///

.publishingFlowModalPage--gradeLevel-gradeLevel-extraContainer{
	margin-top: 14px;
	
}

.publishingFlowModalPage--gradeLevel-gradeLevel-extraOptions{
	display: flex;
	margin-left: -6px; // for padding overflow
}

.publishingFlowModalPage--gradeLevel-gradeLevel-extraOptions .publishingFlowSelectControl{
	margin-right: 5px;
	padding-right: 20px;
}

.publishingFlowModalPage--gradeLevel-gradeLevel-otherContainer{
	margin-top: 10px;
	width: 450px;
	margin-left: -6px; // for padding overflow
	margin-bottom: 32px;
}

.publishingFlowModalPage--gradeLevel-gradeLevel-otherContainer 
.publishingFlowSelectControl--unchecked{
	width: 200px;
}
 // what is this?



// 
// Language Select
// 

.publishingFlowModalPage--gradeLevel-languageSelectContainer{
	width: 300px;
	margin-top: 10px;
}




