//
// PAGE HEADER SUBHEADER ROW
// a large row that can appear under the main headerRow in the header
// for example, see the section reports page
// on sticky, it appears in the flex row (i.e. next to main headerRow)
//

//
// TOP

.pageHeaderTop-subHeaderRow{
  width: 100%;
  position: relative;
  height: $height-pageHeaderTop-subHeaderRow;
  display: flex;
  user-select: none;
  cursor: default;
}

.pageHeaderTop-subHeaderRow-subHeader{
  font-size: 28px;
  font-weight: $lato-heavy;
  color: $textMid;
}

.pageHeaderTop-subHeaderRow-badge{
  height: 100%;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pageHeaderTop-subHeaderRow-badge .icon{
  width: 22px;
  height: 22px;
  position: relative;
  top: 3px;
}

.pageHeaderTop-subHeaderRow-badge .icon.student{
  width: 24px;
  height: 24px;
}

.pageHeaderTop-subHeaderRow-badge .icon.history{
  width: 21px;
  height: 21px;
  top: 2px;
}

//
// STICKY

.pageHeaderSticky-subHeader{
  font-size: 28px;
  font-weight: $lato-heavy;
  color: $textBlack;

  flex-shrink: 0;
  margin-left: 10px;
}

//
// CUSTOM COLORING

.pageHeaderTop--account .pageHeaderTop-subHeaderRow-subHeader,
.pageHeaderSticky--account .pageHeaderSticky-subHeader{
  color: $textLight;
}

.pageHeaderTop--searchResults .pageHeaderTop-subHeaderRow-subHeader,
.pageHeaderSticky--searchResults .pageHeaderSticky-subHeader{
  color: $textLight;
}

.pageHeaderTop--discoverSeeAll .pageHeaderTop-subHeaderRow-subHeader,
.pageHeaderTop--discoverSeeAll .pageHeaderSticky-subHeader{
  color: $textLight;
}

//
// CUSTOM POSITIONING

.pageHeaderTop--sectionHistory .pageHeaderTop-subHeaderRow-badge,
.pageHeaderTop--sectionArchivedReports .pageHeaderTop-subHeaderRow-badge{
  margin-left: -10px;
}

.pageHeaderTop--sectionStudents .pageHeaderTop-subHeaderRow-badge,
.pageHeaderTop--sectionArchivedStudents .pageHeaderTop-subHeaderRow-badge{
  margin-left: -8px;
}

.pageHeaderTop--sectionSettings .pageHeaderTop-subHeaderRow-badge{
  margin-left: -8px;
}


//
// CLASS COLORS
//

// ICON
// note sticky icon here is in left badge
.pageHeaderTop--classcolor-blue			.pageHeaderTop-subHeaderRow-badge .icon		{ fill: $classColor-blue; }
.pageHeaderTop--classcolor-purple		.pageHeaderTop-subHeaderRow-badge .icon		{ fill: $classColor-purple; }
.pageHeaderTop--classcolor-red			.pageHeaderTop-subHeaderRow-badge .icon		{ fill: $classColor-red; }
.pageHeaderTop--classcolor-orange		.pageHeaderTop-subHeaderRow-badge .icon		{ fill: $classColor-orange; }
.pageHeaderTop--classcolor-green		.pageHeaderTop-subHeaderRow-badge .icon		{ fill: $classColor-green; }
.pageHeaderTop--classcolor-sky			.pageHeaderTop-subHeaderRow-badge .icon		{ fill: $classColor-sky; }
.pageHeaderTop--classcolor-pink			.pageHeaderTop-subHeaderRow-badge .icon		{ fill: $classColor-pink; }
.pageHeaderTop--classcolor-teal			.pageHeaderTop-subHeaderRow-badge .icon		{ fill: $classColor-teal; }
.pageHeaderTop--classcolor-grey			.pageHeaderTop-subHeaderRow-badge .icon		{ fill: $classColor-grey; }

// COLOR TOP
.pageHeaderTop--classcolor-blue			.pageHeaderTop-subHeaderRow-subHeader		{ color: $classColor-blue; }
.pageHeaderTop--classcolor-purple		.pageHeaderTop-subHeaderRow-subHeader		{ color: $classColor-purple; }
.pageHeaderTop--classcolor-red			.pageHeaderTop-subHeaderRow-subHeader		{ color: $classColor-red; }
.pageHeaderTop--classcolor-orange		.pageHeaderTop-subHeaderRow-subHeader		{ color: $classColor-orange; }
.pageHeaderTop--classcolor-green		.pageHeaderTop-subHeaderRow-subHeader		{ color: $classColor-green; }
.pageHeaderTop--classcolor-sky			.pageHeaderTop-subHeaderRow-subHeader		{ color: $classColor-sky; }
.pageHeaderTop--classcolor-pink			.pageHeaderTop-subHeaderRow-subHeader		{ color: $classColor-pink; }
.pageHeaderTop--classcolor-teal			.pageHeaderTop-subHeaderRow-subHeader		{ color: $classColor-teal; }
.pageHeaderTop--classcolor-grey			.pageHeaderTop-subHeaderRow-subHeader		{ color: $classColor-grey; }

// COLOR STICKY
.pageHeaderSticky--classcolor-blue			.pageHeaderSticky-subHeader		{ color: $classColor-blue; }
.pageHeaderSticky--classcolor-purple		.pageHeaderSticky-subHeader		{ color: $classColor-purple; }
.pageHeaderSticky--classcolor-red				.pageHeaderSticky-subHeader		{ color: $classColor-red; }
.pageHeaderSticky--classcolor-orange		.pageHeaderSticky-subHeader		{ color: $classColor-orange; }
.pageHeaderSticky--classcolor-green			.pageHeaderSticky-subHeader		{ color: $classColor-green; }
.pageHeaderSticky--classcolor-sky				.pageHeaderSticky-subHeader		{ color: $classColor-sky; }
.pageHeaderSticky--classcolor-pink			.pageHeaderSticky-subHeader		{ color: $classColor-pink; }
.pageHeaderSticky--classcolor-teal			.pageHeaderSticky-subHeader		{ color: $classColor-teal; }
.pageHeaderSticky--classcolor-grey			.pageHeaderSticky-subHeader		{ color: $classColor-grey; }
