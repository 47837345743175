//
// INSERT MEDIA BAR AND EDIT MEDIA BAR SHARE A LOT OF SIMILAR STYLS
//

.editor-canvas-insertBar{

  width: 44px;
  border-radius: 13px;

  background: white;
  border: 1px solid rgb(208,209,214);

  box-shadow: 0px 2px 4px 0px rgba(0,0,40,0.12);

  margin-left: 10px;

  margin-bottom: 56px;

  padding-top: 11px;
  padding-bottom: 11px;

  position: relative;
}


.editor-canvas-right:hover .editor-canvas-insertBar-insertMenuLabel{
  opacity: 0.5;
  transition: opacity 100ms linear;
  transition-delay: 500ms;
}

.editor-canvas-insertBar-insertMenuLabel{
  position: absolute;
  top: 0px;
  left: 100%;

  margin-left: 12px;

  font-size: 10px;
  letter-spacing: 0.05em;
  font-weight: $lato-bold;
  text-transform: uppercase;
  color: $textBlack;

  opacity: 0;
}

button.editor-canvas-insertBar-btn{
  position: relative;
  height: 36px;
  width: 100%;

  background: none;
  border: none;
  outline: none;
  box-shadow: none;

  cursor: pointer;

  opacity: 0.7;
}


button.editor-canvas-insertBar-btn svg{
  width: 32px;
  height: 32px;

  fill: rgb(80,80,90);
}

button.editor-canvas-insertBar-btn svg.editorIcon-insertVideo{
  height: 24px;
  width: 24px;
}

button.editor-canvas-insertBar-btn svg.editorIcon-insertSound{
  height: 22px;
  width: 22px;
}

button.editor-canvas-insertBar-btn svg.editorIcon-insertSoundLibrary{
  height: 22px;
  width: 22px;
}


// button.editor-canvas-editMediaBar-btn svg.editorIcon-editImage{
// 	height: 20px;
// 	width: 20px;
// }

// button.editor-canvas-editMediaBar-btn svg.editorIcon-editMediaSearch{
// 	width: 18px;
// 	height: 18px;
// }

// button.editor-canvas-editMediaBar-btn svg.editorIcon-editVideo{
// 	width: 22px;
// 	height: 22px;
// }

// button.editor-canvas-editMediaBar-btn svg.editorIcon-imageFit{
// 	width: 16px;
// 	height: 16px;
// }

// button.editor-canvas-editMediaBar-btn svg.editorIcon-editMediaTrim{
// 	width: 28px;
// 	height: 28px;
// }

// button.editor-canvas-editMediaBar-btn svg.editorIcon-microphone{
// 	width: 20px;
// 	height: 20px;
// }

// button.editor-canvas-editMediaBar-btn svg.editorIcon-replaceMedia{
// 	width: 20px;
// 	height: 20px;
// }

//

.editor-canvas-insertBar:hover button.editor-canvas-insertBar-btn{
  opacity: 0.3;
}

.editor-canvas-insertBar:hover button.editor-canvas-insertBar-btn:hover{
  background: transparentize($blue,0.9);
  opacity: 1;
}

.editor-canvas-insertBar:hover button.editor-canvas-insertBar-btn:hover svg{
  fill: $blue;
}

// .editor-canvas-editMediaBar:hover button.editor-canvas-editMediaBar-btn:hover{
// 	background: transparentize($gray-4,0.9);
// 	opacity: 1;
// }

//.editor-canvas-insertBar:hover button.editor-canvas-insertBar-btn:hover svg,
// .editor-canvas-editMediaBar:hover button.editor-canvas-editMediaBar-btn:hover svg{
// 	fill: $gray-4;
// }

// .editor-canvas-editMediaBar:hover button.editor-canvas-editMediaBar-btn--remove:hover{
// 	background: transparentize($red,0.9);
// 	opacity: 1;
// }

// .editor-canvas-editMediaBar:hover button.editor-canvas-editMediaBar-btn--remove:hover svg{
// 	fill: $red;
// }


// //
// // EDIT MEDIA BAR ACTIVE
// .editor-canvas-editMediaBar{
// 	padding-bottom: 9px;
// 	margin-top: 52px;
// }

// .editor-canvas-editMediaBar-mediaIconContainer{
// 	width: calc(100% - 4px);

// 	margin-left: 2px;


// 	height: 36px;

// 	// border-radius: 6px;
// 	margin-top: -4px;
// 	margin-bottom: 4px;
// 	// margin-bottom: 6px;


// 	display: flex;
// 	align-items: center;
// 	justify-content: center;

// 	position: relative;

// 	// background: transparentize($blue, 0.95);
// }

// .editor-canvas-editMediaBar-mediaIconContainer svg{
// 	fill: $blue;
// 	// width: 34px;
// 	// height: 34px;
// }

// .editor-canvas-editMediaBar-divider{
// 	width: calc(100% - 6px);
// 	margin-left: 3px;
// 	height: 1px;
// 	background: lighten($lineMid, 3%);

// 	margin-bottom: 0px;
// }

// .editor-canvas-editMediaBar-mediaIconContainer svg.editorIcon-insertImage{
// 	width: 26px;
// 	height: 26px;
// }

// .editor-canvas-editMediaBar-mediaIconContainer svg.editorIcon-insertVideo{
// 	// fill: $classColor-red;
// 	width: 30px;
// 	height: 30px;
// }

// .editor-canvas-editMediaBar-mediaIconContainer svg.editorIcon-insertSound{
// 	// fill: $classColor-orange;
// 	width: 20px;
// 	height: 20px;
// }

//
// TOOLTIPS

button.editor-canvas-insertBar-btn .editor-canvas-insertBar-btn-tooltipLabelContainer,
button.editor-canvas-editMediaBar-btn .editor-canvas-editMediaBar-btn-tooltipLabelContainer{

  opacity: 0;

  position: absolute;
  left: 100%;
  top: 0px;
  bottom: 0px;
  width: 110px;

  display: flex;
  align-items: center;
  transition: opacity 0ms linear;

  // display: none; // TEMP REMOVE FOR OUTPUT CODE CONTAINER
}


button.editor-canvas-insertBar-btn:hover .editor-canvas-insertBar-btn-tooltipLabelContainer{
  opacity: 1;
  transition: opacity 100ms linear;
}

.editor-canvas-right:hover .editor-canvas-insertBar-btn-tooltipLabelContainer{
  opacity: 0.8;
  transition: opacity 100ms linear;
  // transition-delay: 200ms;
}

.editor-canvas-insertBar-btn-tooltipLabel{
  margin-left: 6px;

  font-size: 14px;
  font-weight: $lato-bold;
  color: $textBlack;

  padding-top: 2px;
  padding-bottom: 2px;
  padding-left: 5px;
  padding-right: 5px;

  user-select: none;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  // background: $tempr;

}

//
//

// .editor-canvas-editMediaBar-titleTooltipLabelContainer{

// 	opacity: 0;

// 	position: absolute;
// 	left: 100%;
// 	top: 0px;
// 	bottom: 0px;
// 	width: 100px;

// 	display: flex;
// 	align-items: center;
// 	transition: opacity 0ms linear;

// 	// display: none; // TEMP REMOVE FOR OUTPUT CODE CONTAINER
// }

// .editor-canvas-editMediaBar-titleTooltipLabelContainer{
// 	width: 150px;
// }

// .editor-canvas-editMediaBar:hover .editor-canvas-editMediaBar-titleTooltipLabelContainer{
// 	opacity: 1;
// 	transition: opacity 100ms linear;
// }

// .editor-canvas-right:hover .editor-canvas-editMediaBar-titleTooltipLabelContainer{
// 	opacity: 0.9;
// 	transition: opacity 100ms linear;
// 	transition-delay: 0ms;
// }

// .editor-canvas-editMediaBar-titleTooltipLabel{
// 	margin-left: 8px;
// 	cursor: default;

// 	font-size: 14px;
// 	font-weight: $lato-heavy;
// 	color: $textBlackDark;

// 	padding-top: 2px;
// 	padding-bottom: 2px;
// 	padding-left: 5px;
// 	padding-right: 5px;

// 	// background: $tempr;

// }


// ACTIVE - VIDEO

// button.editor-canvas-insertBar-btn--active{
// 	background: transparentize($classColor-red, 0.9);
// 	opacity: 1;
// }

// button.editor-canvas-insertBar-btn--active svg{
// 	fill: $classColor-red;
// }

// //
