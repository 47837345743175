.confirmEmailPage{
	width: 100%;
	height: calc(100vh - #{$height-topNav});

	padding-top: calc(#{$height-topNav} + 20vh);
	padding-left: 20vw;
	padding-right: 20vw;
	
	box-sizing: border-box;

	font-size: 24px;
	font-weight: $lato-bold;
	color: $textBlack;

	text-align: center;
	cursor: default;
}

span.confirmEmailPage-supportEmail{
	color: $textBlue;
}

span.confirmEmailPage-confirming{
	color: $textLight;
	opacity: 0.75;
}


.confirmEmailPage button{
	margin: 0 auto;
	margin-top: 40px;
}