
@import 'SectionStudents-StudentCell';
@import 'SectionStudents-SidePanel';

.page-centerPanel.sectionStudents{
	//padding-top: $height-sectionPageHeader--top;
}

.students-table{
	width: 100%;	
	padding-bottom: 50px;	
	box-sizing: border-box;

}

.students-table:focus{
	outline: none;
	box-shadow: none;
}

@import 'SectionStudents-CardAssigmentSelectMenu';

// EDIT STUDENT MODAL
.editStudent-modal .modal-content{
	width: 500px;
	height: 280px;
}

.editStudent{
	padding: 20px;
	padding-top: 30px;
	padding-left: 50px;
	padding-right: 50px;
	box-sizing: border-box;
	display: flex;
	justify-content: center;
	
	position: relative;

	height: calc(100% - 46px);
	width: 100%;
}

.editStudent-form form{
	display: flex;
}

.editStudent-form .inputField{
	width: 100%;
	margin: 4px;
}

.editStudent-form .btn.btn--xLarge.update-name{
	position: absolute;
	bottom: 30px;
	left: 50px;
	right: 50px;
	width: calc(100% - 100px);
}