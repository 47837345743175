.explorerEditRepoNotesModalContainer{
	position: fixed;
	top: 0px;
	left: 0px;
	right: 0px;
	bottom: 0px;
	z-index: 5000;

	display: flex;
	align-items: center;
	justify-content: center;

	overflow: hidden;
}


.explorerEditRepoNotesModalContainer-background{
	position: absolute;
	left: 0px;
	top: 0px;
	right: 0px;
	bottom: 0px;

	background: rgba(255,255,255,0.75);
	z-index: -1;
}

.explorerEditRepoNotesModal{

	width: 700px;
	height: 80vh;
	
	overflow: hidden;
	position: relative;
	z-index: 10;
	
	border-radius: 5px;

	background: rgb(252,252,255);
	border: 1px solid $gray-12;
	box-shadow: 
		0px 8px 16px 0px rgba(5,5,10,0.12),
		0px 16px 32px 0px rgba(5,5,10,0.08) ;


	box-sizing: border-box;
	padding-left: 32px;
	padding-right: 32px;

	padding-top: 22px;
	padding-bottom: 26px;

}
.explorerEditRepoNotesModal-title{
	font-size: 24px;
	font-weight: $lato-heavy;
	color: $textBlackDark;

	margin-bottom: 4px;
	margin-left: 5px;

	user-select: none;
	cursor: default;

	width: calc(100% - 200px);
	white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.explorerEditRepoNotesModal-instructions{
	background: $blue-extraLight;

	padding-left: 5px;
	
	padding-right: 5px;
	padding-top: 2px;
	padding-bottom: 2px;

	border-radius: 3px;

	width: 205px;

	font-size: 13px;
	font-weight: $lato-semibold;
	color: $textBlue;

	margin-bottom: 10px;

	cursor: default;
	user-select: none;
}

.explorerEditRepoNotesModal-input textarea{
	height: calc(80vh - 180px);
	width: 100%;
	resize: none;
  
  border: 1px solid $lineDark;
  border-radius: 3px;
  padding-left: 8px;
  padding-top: 6px;
  padding-right: 8px;

  line-height: 1.35;
  
  box-sizing: border-box;

  font-size: 15px;
  font-weight: $lato-medium;
  color: $textBlack;
  outline: none;
  pointer-events: default;
}

.explorerEditRepoNotesModal-input textarea:focus{
   border: 1px solid $blue;
}

.explorerEditRepoNotesModal-input textarea.is--nonEmpty:focus{
    box-shadow: 0px 0px 4px 0px transparentize($blue, 0.5);
}


.explorerEditRepoNotesModal-input textarea::-webkit-input-placeholder {
   color: $textInputPlaceholder !important;
   font-size: 15px;
   font-weight: $lato-medium;
}




///
/// DONE BUTTON
///

.explorerEditRepoNotesModal button.btn.btn--xLarge.explorerreponotessubmit{
	position: absolute;
	bottom: 20px;
	left: 50%;
	transform: translateX(-50%);

	width: 450px;
	font-size: 22px;
	font-weight: $lato-heavy;
	height: 60px;
	border-radius: 5px;

	padding-bottom: 14px;
}

.explorerEditRepoNotesModal button.btn.btn--xLarge.explorerreponotessubmit:focus{
	background: darken($blue-light, 6%);
	border-color: darken($blue, 6%);
	color: $white;

	box-shadow: 0px 2px 4px 0px transparentize($blue, 0.9),
	0px 0px 14px 0px transparentize($blue, 0.7);
}

.explorerEditRepoNotesModal-fakeBtnSecondLine{
	pointer-events: none;
	position: absolute;
	z-index: 5;
	left: 50%;
	transform: translateX(-50%);

	width: 100px;

	bottom: 30px;
	color: $white;
	opacity: 0.75;
	font-size: 12px;
	font-weight: $lato-bold;

	text-align: center;

	letter-spacing: 0.01em;
}

.explorerEditRepoNotesModal button.btn.btn--xLarge.explorerreponotessubmit.submit--loading ~
.explorerEditRepoNotesModal-fakeBtnSecondLine{
	display: none;
}



///

.explorerEditRepoNotesModal button.btn.btn--mid.explorerreponotescancel{
	position: absolute;
	top: 20px;
	right: 10px;
	width: 90px;
	cursor: pointer;
	font-weight: $lato-semibold;

	border: 1px solid $invisible;
	background: none;
	opacity: 0.5;

	//background: $tempr;
}

.explorerEditRepoNotesModal button.btn.btn--mid.explorerreponotescancel:hover{
	border: 1px solid $invisible;
	background: rgba(0,0,40,0.08);
	opacity: 0.5;
	color: $textBlack;
}
