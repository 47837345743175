.dataOwnerInfo-modal .modal-content {
  width: 450px;
  border-radius: 10px;
  height: 250px;
  display: table;
  overflow: auto;
}

.dataOwnerInfoContainer {
  margin: auto;
  width: 400px;
  padding: 30px 0px;
}

.dataOwnerInfoContainer-row {
  margin-bottom: 20px;
}

.dataOwnerInfoContainer-row-label {
  font-size: 16px;
  font-weight: $lato-medium;
  color: $textMid;
  margin-bottom: 5px;
}

.dataOwnerInfoContainer-row-value {
  font-size: 20px;
  font-weight: $lato-heavy;
  color: $textDark;
  margin-bottom: 10px;
}
