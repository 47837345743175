.repoReleaseModal-visual-sideNav{
	box-sizing: border-box;
	padding-left: 16px;
	padding-top: 17px;
}

.repoReleaseModal-visual-sideNav-item{
	background: rgb(236,236,240);
	height: 11px;
	margin-bottom: 7px;
	border-radius: 2px;
}

.repoReleaseModal-visual-sideNav-break{
	height: 14px;
}


.repoReleaseModal-visual-sideNav-activeItem{
	position: absolute;
	width: 85px;
	height: 16px;
	left: 8px;

	border-radius: 3px;
	background: $white;
	border: 1px solid $lineDark;

	box-shadow: 0px 1px 2px 0px rgba(0,0,40,0.05);

}