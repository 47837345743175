
button.account-accountBtn{
	font-size: 14px;
	font-weight: $lato-semibold;

	border: none;
	color: $blue;
	background: none;

	border-radius: 3px;

	height: 28px;
	line-height: 2;
	padding-left: 8px;
	padding-right: 8px;

	cursor: pointer;
	user-select: none;
	text-align: center;

	// margin-top: 6px;
	margin-right: 12px;
	margin-left: -8px; // aligns labels vertically
}

button.account-accountBtn:hover{
	background: $gray-15;
}

button.account-accountBtn:active{
	background: $gray-14;
}

button.account-accountBtn--editPassword{
	margin-top: 2px;
	margin-bottom: 10px;
}

button.account-accountBtn--removeGoogle{
	margin-top: 2px;
}

button.account-accountBtn--signOut,
button.account-accountBtn--deleteAccount{
	margin-top: 18px;
	margin-bottom: 24px;
}

button.account-accountBtn.account-accountBtn--subtle{
	color: $textLight;
}
