.reviewPublishedRepoModalContainer{
	position: fixed;
	z-index: 9999;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: rgba(5,5,10,0.75);
	
	display: flex;
	
	align-items: center;
	justify-content: center;
}

.reviewPublishedRepoModal{
	border-radius: 10px;
	
	background: rgb(252,252,255);
	border: 1px solid $gray-5;
	box-shadow: 
		0px 8px 16px 0px rgba(5,5,10,0.12),
		0px 16px 32px 0px rgba(5,5,10,0.08) ;

	padding-top: 40px;
	padding-bottom: 24px;

	padding-left: 50px;
	padding-right: 50px;

	overflow: hidden;

	width: 480px;
	
	position: relative;

	text-align: center;
}

.reviewPublishedRepoModal.reviewPublishedRepoModal--noRating{
	padding-top: 60px;
	padding-bottom: 56px;
}

.reviewPublishedRepoModal-title{
	font-size: 22px;
	font-weight: $lato-heavy;
	color: $textBlack;
	cursor: default;
	margin-bottom: 5px;
}

.reviewPublishedRepoModal-subtitle{
	font-weight: $lato-bold;
}

.reviewPublishedRepoModal-subtitle,
.reviewPublishedRepoModal-anonymous{
	font-size: 19px;	
	color: $textDark;
	cursor: default;
}

.reviewPublishedRepoModal-anonymous{
	font-weight: $lato-semibold;
	font-size: 16px;
	margin-top: 6px;
	color: $textLight;
}

// Rating Btns

.reviewPublishedRepoModal-starBtnRowContainer{
	display: flex;
	justify-content: center;
	margin-bottom: 12px;
	margin-top: 12px;
	height: 45px;
}

.reviewPublishedRepoModal.reviewPublishedRepoModal--noRating
.reviewPublishedRepoModal-starBtnRowContainer{
	margin-top: 28px;
	margin-bottom: 36px;

	transform: scale(1.25);
}

.reviewPublishedRepoModal-starBtnRow{
	display: flex;
}

button.reviewPublishedRepoModal-starBtn{
	box-shadow: none;
	border: none;

	display: flex;
	align-items: center;
	justify-content: center;

	flex-grow: 0;
	flex-shrink: 0;

	width: 45px;
	height: 45px;
	// border: 1px solid $red;

	box-sizing: border-box;

	cursor: pointer;	
	background: none;

	// background: $tempr;
	background: none;

	position: relative;

	transition: opacity 100ms linear;
}

button.reviewPublishedRepoModal-starBtn svg{
	width: 36px;
	height: 36px;
	position: static; // IE bug fix
}

button.reviewPublishedRepoModal-starBtn svg.star{
	background-color: $invisible;
}

button.reviewPublishedRepoModal-starBtn.reviewPublishedRepoModal-starBtn--active svg{
	fill: #FFA940;
}

button.reviewPublishedRepoModal-starBtn.reviewPublishedRepoModal-starBtn--inactive svg{
	fill: $gray-13;
}

.reviewPublishedRepoModal.reviewPublishedRepoModal--noRating
.reviewPublishedRepoModal-starBtnRowContainer:not(:hover)
button.reviewPublishedRepoModal-starBtn svg{
	fill: $gray-8;
}


// .reviewPublishedRepoModal--noRating
// .reviewPublishedRepoModal-starBtnRow:hover
// button.reviewPublishedRepoModal-starBtn{
// 	opacity: 0.5;
// }

// .reviewPublishedRepoModal--noRating
// .reviewPublishedRepoModal-starBtnRow:hover
// button.reviewPublishedRepoModal-starBtn:hover{
// 	opacity: 1;
// }

// // Icons

// .reviewPublishedRepoModal--noRating{
	
// 	button.reviewPublishedRepoModal-starBtn--inactive .icon.feedbackSmile #faceBG{
// 		fill: white;
// 	}

// 	button.reviewPublishedRepoModal-starBtn--inactive .icon.feedbackSmile #eyes,
// 	button.reviewPublishedRepoModal-starBtn--inactive .icon.feedbackSmile #faceOutline,
// 	button.reviewPublishedRepoModal-starBtn--inactive .icon.feedbackSmile #smile{
// 		fill: $gray-8;
// 	}

// 	.reviewPublishedRepoModal-starBtnRow:hover
// 	.reviewPublishedRepoModal-starBtn-label{
// 		color: $textMid;
// 	}

// }

// .reviewPublishedRepoModal--hasRating{

// 	button.reviewPublishedRepoModal-starBtn--inactive .icon.feedbackSmile #faceBG{
// 		fill: none;
// 	}

// 	button.reviewPublishedRepoModal-starBtn--inactive .icon.feedbackSmile #eyes,
// 	button.reviewPublishedRepoModal-starBtn--inactive .icon.feedbackSmile #faceOutline,
// 	button.reviewPublishedRepoModal-starBtn--inactive .icon.feedbackSmile #smile{
// 		fill: $gray-11;
// 	}


// 	button.reviewPublishedRepoModal-starBtn--inactive:hover .icon.feedbackSmile #eyes,
// 	button.reviewPublishedRepoModal-starBtn--inactive:hover .icon.feedbackSmile #faceOutline,
// 	button.reviewPublishedRepoModal-starBtn--inactive:hover .icon.feedbackSmile #smile{
// 		fill: $gray-8;
// 	}

// 	.reviewPublishedRepoModal-starBtnRow:hover
// 	button.reviewPublishedRepoModal-starBtn--inactive:hover
// 	.reviewPublishedRepoModal-starBtn-label{
// 		color: $textMid;
// 	}

// 	.reviewPublishedRepoModal-starBtnRow:hover
// 	button.reviewPublishedRepoModal-starBtn--inactive
// 	.reviewPublishedRepoModal-starBtn-label{
// 		color: $textLight;
// 	}

// }

// .reviewPublishedRepoModal--hasRating{
// 	button.reviewPublishedRepoModal-starBtn--active .icon.feedbackSmile #faceBG{
// 		fill: white;
// 	}

// 	button.reviewPublishedRepoModal-starBtn--active .icon.feedbackSmile #eyes,
// 	button.reviewPublishedRepoModal-starBtn--active .icon.feedbackSmile #faceOutline,
// 	button.reviewPublishedRepoModal-starBtn--active .icon.feedbackSmile #smile{
// 		fill: $gray-6;
// 	}


// 	button.reviewPublishedRepoModal-starBtn--active
// 	.reviewPublishedRepoModal-starBtn-label{
// 		color: $textBlack;
// 	}

// }


// TextArea

textarea.reviewPublishedRepoModal-message{
	width: 100%;
	border: 1px solid $lineDark;
	resize: none;
	height: 140px;
	border-radius: 2px;

	font-size: 14.5px;
	font-weight: $lato-semibold;
	color: $textBlackDark;
	box-sizing: border-box;

	padding: 6px;
}

textarea.reviewPublishedRepoModal-message:focus{
	outline: none;
	border: 1px solid $blue;
}

textarea.reviewPublishedRepoModal-message::-webkit-input-placeholder{
	color: transparentize($textLight, 0.2);
}

textarea.reviewPublishedRepoModal-message:hover::-webkit-input-placeholder{
	color: transparentize($textLight, 0.4);
}	

textarea.reviewPublishedRepoModal-message:focus::-webkit-input-placeholder{
	color: transparentize($textLight, 0.6);
}


// Send Button

.reviewPublishedRepoModal button.submitfeedback{
	width: 100%;
	font-weight: $lato-bold;
	letter-spacing: 0.005em;
	margin-top: 10px;
	height: 48px;
	cursor: pointer;
}

.reviewPublishedRepoModal button.submitfeedback.btn--disabled{
	pointer-events: none;
	background: $gray-10;
	border: 1px solid $gray-9;
}

// Close Button

.reviewPublishedRepoModal-closeBtn{
	position: absolute;
	width: 28px;
	height: 28px;
	top: 12px;
	right: 14px;
	cursor: pointer;
	border-radius: 12px;

	opacity: 0.3;
	transition: opacity 100ms linear;

	z-index: 100;
}

.reviewPublishedRepoModal-closeBtn:hover{
	opacity: 0.8;
	transition: opacity 50ms linear;
}

.reviewPublishedRepoModal-closeBtn:active{
	opacity: 1;
}

.reviewPublishedRepoModal-closeBtn .icon{
	width: 28px;
	height: 28px;
	fill: $gray-6;
}
