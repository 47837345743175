.reauthenticationModal-modal .modal-backdrop {
  background: rgba(80, 80, 80, 0.75);
}

.reauthenticationModal-modal .icon.close {
  fill: rgba(255,255,255,0.75);
}

.reauthenticationModal-modal .modal-content {
  width: 400px;
  height: auto;
}

.reauthenticationModal-column {
  width: 300px;
  margin: 70px auto;
  text-align: center;
}

.reauthenticationModal-title {
  font-size: 24px;
  color: $textDark;
  font-weight: $lato-bold;
}

.reauthenticationModal-instruction {
  font-size: 14px;
  color: $textMid;
  margin: 30px auto;
}

.reauthenticationModal-signinMethodContainer {
  margin: auto;
  width: 250px;
}

.reauthenticationModal-signinMethodContainer-google,
.reauthenticationModal-signinMethodContainer-apple {
  margin-bottom: 10px;
  font-family: 'Effra', sans-serif;
}

.reauthenticationModal-signinMethodContainer-divider {
  margin-top: 10px;
  margin-bottom: 10px;
}

.reauthenticationModal-signinMethodContainer-password-actionRow {
  margin-top: 20px;
}

.reauthenticationModal-signinMethodContainer-password-actionRow button {
  margin: auto;
  width: 80%;
  cursor: pointer;
}
