.repoReleaseModal-visual-rightPanel{
	box-sizing: border-box;
	padding-top: 40px;
}

.repoReleaseModal-visual-rightPanel-userBubble{
	margin-left: 12px;
	margin-bottom: 3px;

	display: flex;
	align-items: center;
	justify-content: flex-start;
}

.repoReleaseModal-visual-rightPanel-userBubble-avatar{
	width: 28px;
	height: 28px;
	border-radius: 14px;
	box-sizing: border-box;

	background: $white;
	border: 2px solid $white;
	box-shadow: 0px 0px 2px 0px rgba(0,0,40,0.08), 0px 2px 4px 0px rgba(0,0,40,0.04);

	margin-right: 6px;

	position: relative;
}

.repoReleaseModal-visual-rightPanel-userBubble-avatar img {
	position: absolute;
	top: 0px;
	left: 0px;
	width: 24px;
	height: 24px;
}



.repoReleaseModal-visual-rightPanel-userBubble-name{
	height: 11px;
	border-radius: 2px;
	background: $gray-128;
}