//
// PAGE HEADER TOP - BACK BUTTON
// visible if e.g. you navigate into a set report for reports page
// has a small animation on hover
//

.pageHeaderTop .backButton{	
	position: absolute;
	top: 0px;
	left: calc(0px - #{$leftGutter-centerPanel});
	padding: 10px;
	padding-left: 12px;
	padding-right: 12px;
	color: $textDark;

	opacity: 1;
	font-size: 13.5px;
	font-weight: $lato-semibold;

	display: flex;
	align-items: center;
	justify-content: center;

	cursor: pointer;
}

.pageHeaderTop .backButton span{
	max-width: 500px; // unfortunate, support legacy long section name
	white-space: nowrap; 
  overflow: hidden;
  text-overflow: ellipsis;
}

.pageHeaderTop .backButton:hover{
	color: $textBlack;
	font-weight: $lato-bold;
}

.pageHeaderTop .backButton .icon{
 	height: 10px;
 	width: 10px;

 	fill: $blue;
 	margin-right: 5px;
 	margin-bottom: 1px;
}

@keyframes buttonBackBounce {
  from { transform: translateX(1px); }
  to { transform: translateX(-3px); }
}

.pageHeaderTop .backButton:hover .icon{
	 animation: buttonBackBounce .4s infinite ease-in-out;
	 animation-direction: alternate;
}


