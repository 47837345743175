.UIKitTestingPage{
	background: rgb(241,241,245);

	width: 100vw;	
	box-sizing: border-box;
	padding-top: 72px;

	height: 100vh;

	display: flex;
	align-items: center;
	justify-content: center;
}

.UIKitTestingPage-FontTest{
	position: fixed;
	z-index: 9999;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;

	display: flex;
	align-items: center;
	justify-content: center;

	background: rgb(240,240,250);
}

.EffraTestBoxContainer,
.LatoTestBoxContainer{

	width: 400px;
	height: 81px;

	margin: 20px;
	background: $white;
	border: 1px solid $red;

	color: rgb(0,0,0);

	position: relative;

	font-size: 24px;

	display: flex;
	align-items: center;
	justify-content: center;
}

.EffraTestBoxContainer{
	font-family: "Effra";
	font-weight: $effra-medium;
}

.LatoTestBoxContainer{
	font-family: "Plickers Lato";
	font-weight: $lato-medium;
}

.EffraTestBoxContainer-halfLine,
.LatoTestBoxContainer-halfLine{
	position: absolute;
	left: 0px;
	right: 0px;
	top: 40px;
	height: 1px;
	background: $red;
	z-index: 5;
	opacity: 1;
}

.richStaticSlideContainerPage{
	background: $gray-2;
	position: fixed;
	top: 0px;
	left: 0px;
	right: 0px;
	bottom: 0px;

	overflow-y: auto;
	
	display: flex;
	flex-direction: column;
	align-items: center;

	padding-top: 0px;
	padding-bottom: 80px;
}

.richStaticSlideContainer{
	width: 600px;
	height: 375px;
	margin-top: 40px;
	flex-shrink: 0;
	flex-grow: 0;

	border-radius: 5px;
	overflow: hidden;
	box-shadow: 0px 4px 8px 0px rgba(0,0,20,0.4);
	border: 1px solid black;
}

.richStaticSlideContainer .slide{
	transform-origin: 0% 0%;
}