$editVideoModal-seekRed: #FF2323;
//$editVideoModal-seekRed: #fd5e62;
$leftPadding-leftPanel-editVideoModal: 16px;
$rightPadding-leftPanel-editVideoModal: 10px;
$width-leftBar-editVideoModal: 280px;

$previewControlHeight: 132px;

//$previewSideMargin-editVideoModal: 114px;
$previewSideMargin-editVideoModal: 140px;

$previewTrimHeight-editVideoModal: 44px;

$previewBarContainer-editVideoModal: 40px;
$previewHeightSeekBar: calc(#{$previewControlHeight} - #{$previewBarContainer-editVideoModal} - #{$previewTrimHeight-editVideoModal});


$trimBarThumbWidth-editVideoModal: 16px;


.editor-editVideoModalContainer{
	position: fixed;
	top: 0px;
	right: 0px;
	left: 0px;
	bottom: 0px;

	z-index: 9999;
	display: flex;
	align-items: center;
	justify-content: center;

	background: rgba(0,0,5,0.75);
}

.editor-editVideoModalContainer--dragging,
.editor-editVideoModalContainer--dragging *{
	cursor: grabbing;
}

.editor-editVideoModal{
	width: 1025px;
	//height: 512px;
	height: 527px; // refactor to better fit

	background: rgb(255,255,255);
	background: rgb(253,253,255);
	box-shadow: 0px 4px 8px 0px rgba(0,0,10,0.24), 0px 3px 12px 0px rgba(0,0,10,0.40);

	display: flex;

	text-align: left;

	border-radius: 8px;
	overflow: hidden;
}

.editor-editVideoModal--waitingForPlayerLoad{
	pointer-events: none !important;
}

.editor-editVideoModal-info{
	flex-grow: 0;
	flex-shrink: 0;

	width: 280px;

	height: 100%;

	background: rgb(248,248,252);
	border-right: 1px solid $lineDark;

	
	display: flex;
	flex-direction: column;
}

//

button.editor-editVideoModal-info-backToSearchBtn{
	height: 44px;
	width: 100%;
	
	margin-bottom: -18px;

	display: flex;
	align-items: center;
	justify-content: center;

	box-sizing: border-box;
	padding-left: calc(#{$leftPadding-leftPanel-editVideoModal} / 2);
	padding-right: calc(#{$leftPadding-leftPanel-editVideoModal} / 2);

	border: none;
	box-shadow: none;
	outline: none;

	cursor: pointer;

	padding-top: calc(#{$leftPadding-leftPanel-editVideoModal} / 2);
	padding-bottom: 2px;

	background: none;
	position: relative;
	z-index: 10;
	// background: $tempr;
}

button.editor-editVideoModal-info-backToSearchBtn:hover,
button.editor-editVideoModal-info-backToSearchBtn:focus{
	background: none;
	border: none;
	outline: none;
	box-shadow: none;
}

.editor-editVideoModal-info-backToSearchBtn-inner{
	border-radius: 4px;
	width: 100%;
	height: 100%;

	display: flex;
	align-items: center;
	justify-content: flex-start;

	// background: $tempr;
	//background: transparentize($blue, 0.9);

	box-sizing: border-box;

	padding-left: calc(#{$leftPadding-leftPanel-editVideoModal} / 2);
	padding-right: calc(#{$rightPadding-leftPanel-editVideoModal} / 2);

	font-weight: $lato-bold;
	color: $textBlack;
	letter-spacing: 0.014em;

	font-size: 14px;
	opacity: 0.7;

}

button.editor-editVideoModal-info-backToSearchBtn:hover
.editor-editVideoModal-info-backToSearchBtn-inner{
	background: transparentize($blue, 0.85);
	opacity: 1;
}

button.editor-editVideoModal-info-backToSearchBtn:active
.editor-editVideoModal-info-backToSearchBtn-inner{
	background: transparentize($blue, 0.75);
	opacity: 1;
}

button.editor-editVideoModal-info-backToSearchBtn svg.icon{
 	height: 12px;
 	width: 12px;

 	fill: $blue;
 	margin-right: 8px;
 	margin-left: 2px;
 	fill: $gray-8;
 	// margin-bottom: 1px;
}

@keyframes editVideoButtonBackBounce {
  from { transform: translateX(1px); }
  to { transform: translateX(-3px); }
}

button.editor-editVideoModal-info-backToSearchBtn:hover svg.icon{
	 animation: editVideoButtonBackBounce .4s infinite ease-in-out;
	 animation-direction: alternate;
	 fill: $blue;
}

//

.editor-editVideoModal-info-top{
	padding-left: $leftPadding-leftPanel-editVideoModal;
	padding-top: 22px;
	padding-right: $rightPadding-leftPanel-editVideoModal;

	box-sizing: border-box;

	flex-grow: 1;
	flex-shrink: 1;

	display: flex;
	flex-direction: column;
}
.editor-editVideoModal-info-top-title{
	font-size: 17px;
	font-weight: $lato-bold;
	margin-bottom: 4px;
	letter-spacing: 0.02em;

	cursor: default;

	// background: $tempg;
}

.editor-editVideoModal-info-top-author{
	font-size: 14px;
	font-weight: $lato-semibold;
	color: $blue;
	letter-spacing: 0.01em;
	margin-bottom: 4px;

	cursor: default;

	// background: $tempr;
}	  

a.editor-editVideoModal-info-top-url{
	font-size: 13px;
	font-weight: $lato-semibold;
	color: lighten($textMid, 5%);
	text-decoration: underline !important;


	cursor: pointer;

	// background: $tempb;
}

.editor-editVideoModal-info-top-url:hover{
	color: $blue;
	text-decoration: underline !important;
}

// DESCRIPTION
// includes show more and show less states

.editor-editVideoModal-info-top-descriptionContainer{
	flex-grow: 1;
	flex-shrink: 1;
	min-height: 10px;

	// background: $tempb;
	position: relative;

	margin-bottom: 32px;
	
}

.editor-editVideoModal-info-top-description{
	margin-top: 8px;

	font-size: 12.5px;
	letter-spacing: 0.01em;
	font-weight: $lato-medium;
	color: $textDark;
	line-spacing: 1.5;

	box-sizing: border-box;
	padding-right: 6px;

	white-space: pre-wrap;

	cursor: default;

	// background: $tempr;

}

.editor-editVideoModal-info-top-description--showingLess{
	//max-height: 145px; // need chross browser support here
	overflow: hidden;
	// background: $tempr;

	// nice Chrome handling 
	display: -webkit-box;
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;
}

.editor-editVideoModal-info-top-description--showingMore{
	position: absolute;
	top: 0px;
	left: 0px;
	right: 0px;
	bottom: 0px;

	overflow-y: scroll;
	// background: $tempb;
}

button.editor-editVideoModal-info-top-descriptionShowMore{
	border: none;
	background: none;
	font-size: 12px;
	font-weight: $lato-semibold;
	color: $textBlack;
	padding-top: 4px;
	padding-bottom: 4px;
	margin-left: -12px;
	padding-left: 12px;
	padding-right: 12px;
	// background: $tempg;
	cursor: pointer;
	opacity: 0.4;
}

button.editor-editVideoModal-info-top-descriptionShowMore:hover{
	opacity: 1;
}

.editor-editVideoModal-info-top-description--showingMore ~
.editor-editVideoModal-info-top-descriptionShowMore{
	position: absolute;
	bottom: -22px;
	left: 0px;
}

//

a.editor-editVideoModal-info-helpBtn{
	margin-left: $leftPadding-leftPanel-editVideoModal;
	width: calc(100% - #{$leftPadding-leftPanel-editVideoModal} - #{$rightPadding-leftPanel-editVideoModal});
	height: 32px;
	
	color: $textBlack;
	margin-bottom: 8px;

	text-align: center;
	opacity: 0.4;
	background: none;

	border-radius: 4px;

	font-size: 13px;
	letter-spacing: 0.025em;

	font-weight: $lato-medium;

	display: flex;
	align-items: center;
	justify-content: center;

	transition: opacity 50ms linear;

	// background: $tempr;
}

.editor-editVideoModal-info:hover
a.editor-editVideoModal-info-helpBtn{
	opacity: 0.8;
}

.editor-editVideoModal-info:hover
a.editor-editVideoModal-info-helpBtn:hover{
	background: rgba(0,0,20,0.04);
	opacity: 1;
	
	// text-decoration: underline !important;
}
	
.editor-editVideoModal-info-submitBtnContainer{	
	padding-left: $leftPadding-leftPanel-editVideoModal;
	padding-right: $rightPadding-leftPanel-editVideoModal;
	box-sizing: border-box;
}

//
// 


button.editor-editVideoModal-doneBtn{
	height: 55px;
	width: 100%;
	box-sizing: border-box;

	background: $blue;
	border-radius: 5px;

	border: none;
	box-shadow: none;

	padding-top: 10px;

	box-shadow: 0px 2px 2px 0px rgba(0,0,0,0.12);

	cursor: pointer;

	margin-top: 0px;
}

.editor-editVideoModal-doneBtn-primary{
	font-size: 17px;
	font-weight: $lato-bold;
	letter-spacing: 0.03em;
	color: $white;
}

.editor-editVideoModal-doneBtn-secondary{
	font-size: 10px;
	font-weight: $lato-semibold;
	color: $white;
	opacity: 0;
	letter-spacing: 0.02em;
	text-transform: uppercase;
	margin-top: 1px;
}

button.editor-editVideoModal-doneBtn:hover
.editor-editVideoModal-doneBtn-secondary{
	opacity: 0.5;
	transition: opacity 100ms;
	transition-delay: 150ms;
}

button.editor-editVideoModal-cancelBtn{
	width: 100%;
	//margin-top: 6px;
	margin-top: 2px;
	//margin-bottom: 8px;
	margin-bottom: 8px;

	height: 32px;
	border-radius: 5px;
	background: rgba(0,0,0,0.7);
	background: none;

	color: $textBlack;

	font-size: 14px;
	font-weight: $lato-semibold;
	letter-spacing: 0.015em;

	cursor: pointer;

	outline: none;
	border: none;
	box-shadow: none;

	display: flex;
	align-items: center;
	justify-content: center;
}


button.editor-editVideoModal-cancelBtn--hide{
	opacity: 0.5;
	transition: opacity 50ms linear;
}

button.editor-editVideoModal-cancelBtn--show{
	opacity: 0.7;
	transition: opacity 50ms linear;
}

button.editor-editVideoModal-cancelBtn:hover{
	opacity: 1;
	background: rgba(0,0,20,0.04);
}





//
//



.editor-editVideoModal-preview{
	flex-grow: 1;
	flex-shrink: 1;

	width: 255px;

	height: 100%;

	background: $gray-14;

	position: relative;
}


.editor-editVideoModal-preview-videoContainer{
	background: $black;
	position: absolute;
	top: 0px;
	left: 0px;
	right: 0px;
	bottom: $previewControlHeight;
}

.editor-editVideoModal-preview-videoContainer--draggingSeekBar,
.editor-editVideoModal-preview-videoContainer--draggingTrimBar{
	pointer-events: none !important;	
}


iframe.editor-editVideoModal-preview-video{
	width: 100%;
	height: 100%;
}

	
.editor-editVideoModal-preview-controls{
	position: absolute;
	background: $white;
	bottom: 0px;
	left: 0px;
	right: 0px;
	height: $previewControlHeight;
}

// inactive and active state while loading player

.editor-editVideoModal-preview-controls--inactive{
	opacity: 0.5;
	pointer-events: none !important;
	cursor: default !important;
}

.editor-editVideoModal-preview-controls--active{
	opacity: 1;
	transition: opacity 75ms linear;
}

// .editor-editVideoModal-preview-controls--inactive .editor-editVideoModal-preview-controls-seekBar-thumb-circle,
// .editor-editVideoModal-preview-controls--inactive .editor-editVideoModal-preview-controls-seekBar-thumb-line,
// .editor-editVideoModal-preview-controls--inactive .editor-editVideoModal-preview-controls-trimBar-thumb,
// .editor-editVideoModal-preview-controls--inactive .editor-editVideoModal-preview-controls-seekBar-track-0
.editor-editVideoModal-preview-controls--inactive .editor-editVideoModal-preview-controls-playPauseBtn svg,
.editor-editVideoModal-preview-controls--inactive .editor-editVideoModal-preview-controls-timestamp-current,
.editor-editVideoModal-preview-controls--inactive .editor-editVideoModal-preview-controls-timestamp-total{
	opacity: 0.25;
}


// .editor-editVideoModal-preview-controls--inactive .editor-editVideoModal-preview-controls-seekBar-thumb-line{
// 	background: $gray-8;
// }

.editor-editVideoModal-preview-controls--inactive .editor-editVideoModal-preview-controls-seekBar-thumb-circle--loading,
.editor-editVideoModal-preview-controls--inactive .editor-editVideoModal-preview-controls-seekBar-thumb-line--loading
.editor-editVideoModal-preview-controls--inactive .editor-editVideoModal-preview-controls-trimBar-thumb--loading{
	opacity: 1;
}

.editor-editVideoModal-preview-controls--active .editor-editVideoModal-preview-controls-seekBar-thumb-line,
.editor-editVideoModal-preview-controls--active .editor-editVideoModal-preview-controls-trimBar-thumb,
.editor-editVideoModal-preview-controls--active .editor-editVideoModal-preview-controls-timestamp-current,
.editor-editVideoModal-preview-controls--active .editor-editVideoModal-preview-controls-timestamp-total,
.editor-editVideoModal-preview-controls--active .editor-editVideoModal-preview-controls-playPauseBtn svg,
.editor-editVideoModal-preview-controls--active .editor-editVideoModal-preview-controls-seekBar-track-0{
	opacity: 1;
	transition: opacity 100ms linear;
	transition-delay: 25ms;
}

.editor-editVideoModal-preview-controls--active  .editor-editVideoModal-preview-controls-seekBar-thumb-circle--loading,
.editor-editVideoModal-preview-controls--active  .editor-editVideoModal-preview-controls-seekBar-thumb-line--loading,
.editor-editVideoModal-preview-controls--active  .editor-editVideoModal-preview-controls-trimBar-thumb--loading{
	opacity: 0;
	transition: opacity 100ms linear;
	transition-delay: 25ms;
}

.editor-editVideoModal-preview-controls--inactive .editor-editVideoModal-preview-controls-previewBarContainer{
	opacity: 0;
}

.editor-editVideoModal-preview-controls--active .editor-editVideoModal-preview-controls-previewBarContainer{
	opacity: 1;
	transition: opacity 25ms linear;
	transition-delay: 100ms;
}

// .editor-editVideoModal-preview-controls--active .editor-editVideoModal-preview-controls-seekBar-thumb-circle{
// 	transition-delay: 100ms;
// }

// .editor-editVideoModal-preview-controls--active .editor-editVideoModal-preview-controls-seekBar-thumb-line{
// 	transition-delay: 200ms;	
// }

// .editor-editVideoModal-preview-controls--active .editor-editVideoModal-preview-controls-trimBar-thumb{
// 	transition-delay: 100ms;
// }

// .editor-editVideoModal-preview-controls--active .editor-editVideoModal-preview-controls-timestamp-current{
// 	transition-delay: 50ms;	
// }

// .editor-editVideoModal-preview-controls--active .editor-editVideoModal-preview-controls-timestamp-total{
// 	transition-delay: 50ms;		
// }

// .editor-editVideoModal-preview-controls--active .editor-editVideoModal-preview-controls-playPauseBtn svg{
// 	transition-delay: 50ms;	
// }

.editor-editVideoModal-preview-controls--active .editor-editVideoModal-preview-controls-seekBar-track-0{
	transition-delay: 200ms;		
}

.editor-editVideoModal-preview-controls--draggingSeekbar,
.editor-editVideoModal-preview-controls--draggingSeekbar *{
	cursor: grabbing !important;
}


//

.editor-editVideoModal-preview-controls-seekBarContainer{
	position: absolute;
	left: $previewSideMargin-editVideoModal;
	right: $previewSideMargin-editVideoModal;
	top: 0px;
	//bottom: calc(#{$previewBarContainer-editVideoModal} + #{$previewTrimHeight-editVideoModal});
	bottom: $previewBarContainer-editVideoModal;

	// background: $tempr;

	cursor: pointer;

	z-index: 5;
}

.editor-editVideoModal-preview-controls-seekBar{
	width: 100%;
	height: 100%;

	user-select: none;
	cursor: pointer;
}

// TRACK

.editor-editVideoModal-preview-controls-seekBar-track-0{
	height: 4px;
	background: $gray-5;
	margin-top: calc((#{$previewHeightSeekBar} * 0.5) - 3px);
	border-radius: 3px;
	// border-top-right-radius: 0px;
	// border-bottom-right-radius: 0px;
}

.editor-editVideoModal-preview-controls-seekBar-track-1{
	height: 4px;
	background: transparentize($gray-8, 0.5);
	margin-top: calc((#{$previewHeightSeekBar} * 0.5) - 3px);
	border-radius: 3px;

	// border-top-left-radius: 0px;
	// border-bottom-left-radius: 0px;
}


// THUMB
.editor-editVideoModal-preview-controls-seekBar-thumb{
	width: 2px;
	height: 100%;
	cursor: grab;
}

.editor-editVideoModal-preview-controls-seekBar-thumb:focus{
	outline: $invisible;
}

.editor-editVideoModal-preview-controls-seekBar-thumb-tooltip{
	position: absolute;
	// width: 16px;
	height: 24px;
	// width: 46px;
	padding-left: 10px;
	padding-right: 10px;

	top: -20px;
	//top: calc((#{$previewHeightSeekBar} * 0.5) - 12px);
	//left: calc(-26px);
	left: 50%;
	transform: translateX(-50%);

	background: $gray-1;
	//border: 1px solid darken($gray-2, 5%);
	border: 1px solid rgba(255,255,255,0.15);
	//box-shadow: 0px 0px 0px 1px rgba(0,0,10,0.06);

	border-radius: 4px;

	pointer-events: none;
	user-select: none;
	
	font-size: 14px;
	font-weight: $lato-bold;
	letter-spacing: 0.01em;

	color: $white;

	display: flex;
	align-items: center;
	justify-content: center;

	// border: 2px solid white;
	// box-shadow: 0px 0px 0px 1px rgba(0,0,10,0.06);

	// s
}

.editor-editVideoModal-preview-controls-seekBar-thumb-circle,
.editor-editVideoModal-preview-controls-seekBar-thumb-circle--loading{
	position: absolute;
	width: 16px;
	height: 16px;

	top: calc((#{$previewHeightSeekBar} * 0.5) - 12px);
	left: -10px;	

	background: $editVideoModal-seekRed;
	border-radius: 50%;

	border: 2px solid white;
	box-shadow: 0px 0px 0px 1px rgba(0,0,10,0.06);

	z-index: 2;

	// opacity: 0.5;

	transition: transform 50ms linear;
	transform-origin: center center;
	
}

.editor-editVideoModal-preview-controls-seekBar-thumb-circle--loading{
	z-index: 5;
	background: $gray-8;
	pointer-events: none;
}

.editor-editVideoModal-preview-controls-seekBar-thumb:hover
.editor-editVideoModal-preview-controls-seekBar-thumb-circle{
	transform: scale(1.05);

	box-shadow: 0px 0px 0px 1px rgba(0,0,10,0.1);

	transition: transform 0ms linear;
}

.editor-editVideoModal-preview-controls--draggingSeekbar
.editor-editVideoModal-preview-controls-seekBar-thumb-circle,
.editor-editVideoModal-preview-controls-seekBar-thumb:active
.editor-editVideoModal-preview-controls-seekBar-thumb-circle{
	transform: scale(1.2);
	transform-origin: center center;

	transition: transform 0ms linear;

	box-shadow: 0px 0px 0px 1px rgba(0,0,10,0.15);

	//background: $green; //temp
}



.editor-editVideoModal-preview-controls-seekBar-thumb:active{
	cursor: grabbing !important;
}

.editor-editVideoModal-preview-controls-seekBar-thumb-line,
.editor-editVideoModal-preview-controls-seekBar-thumb-line--loading{
	position: absolute;

	width: 2px;
	border-radius: 2px;
	margin-left: -1px;

	height: calc((#{$previewHeightSeekBar} * 0.5) + #{$previewTrimHeight-editVideoModal});

	top: calc(#{$previewHeightSeekBar} * 0.5);
	background: $editVideoModal-seekRed;
}


.editor-editVideoModal-preview-controls-seekBar-thumb-line--loading{
	z-index: 1;
	background: $gray-8;
}

.editor-editVideoModal-preview-controls-seekBar-thumb-circle--seeking{
	transform: scale(1.05);
}






// 
// TRIM BAR

.editor-editVideoModal-preview-controls-trimBarContainer{
	position: absolute;
	left: $previewSideMargin-editVideoModal;
	right: $previewSideMargin-editVideoModal;
	top: calc(#{$previewControlHeight} - #{$previewTrimHeight-editVideoModal} - #{$previewBarContainer-editVideoModal});
	//bottom: $previewBarContainer-editVideoModal;
	height: 0px; // to prevent clicks

	//
	// cursor: pointer;
	z-index: 5;
}



.editor-editVideoModal-preview-controls-trimBar{
	width: 100%;
	height: 0px; // clicks are saved for seekbar

	user-select: none;
}

//

.editor-editVideoModal-preview-controls-trimBar-track-1{
	height: $previewTrimHeight-editVideoModal;
	background: transparentize($blue, 0.95);
	// display: none;
	pointer-events: none;
}


// THUMB

.editor-editVideoModal-preview-controls-trimBar-thumbContainer{
	width: 0px;
	height: $previewTrimHeight-editVideoModal;
	background: $red;

	user-select: none;
	position: relative;
}

.editor-editVideoModal-preview-controls-trimBar-thumbContainer:focus{
	outline: $invisible;
}

.editor-editVideoModal-preview-controls-trimBar-thumb{
	background: $blue;
	position: absolute;
	width: $trimBarThumbWidth-editVideoModal;
	height: $previewTrimHeight-editVideoModal;
	top: 0px;
	cursor: grab;
}



.editor-editVideoModal-preview-controls-trimBar-thumb--loading{
	background: $gray-8;
	pointer-events: none;
}

.editor-editVideoModal-preview-controls-trimBar-thumbContainer-0 .editor-editVideoModal-preview-controls-trimBar-thumb{
	left: calc(-1 * #{$trimBarThumbWidth-editVideoModal});
	border-top-left-radius: 5px;
	border-bottom-left-radius: 5px;
}

.editor-editVideoModal-preview-controls-trimBar-thumbContainer-1 .editor-editVideoModal-preview-controls-trimBar-thumb{
	right: calc(-1 * #{$trimBarThumbWidth-editVideoModal});
	border-top-right-radius: 5px;
	border-bottom-right-radius: 5px;
}


.editor-editVideoModal-preview-controls-trimBar-thumb-line{
	width: 2px;
	border-radius: 2px;
	height: 70%;

	margin: auto;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;	

	background: $white;	

	opacity: 0.7;
}


.editor-editVideoModal-preview-controls-trimBar-thumb:active
.editor-editVideoModal-preview-controls-trimBar-thumb-line{
	opacity: 1;
}

.editor-editVideoModal-preview-controls-trimBar-thumb:active{
	cursor: grabbing !important;
}


button.editor-editVideoModal-preview-controls-trimBar-thumb-align{
	position: absolute;
	width: 24px;
	height: 28px;
	top: 50%;
	transform: translateY(-50%);

	display: flex;
	align-items: center;
	justify-content: center;

	border: none;
	background: none;

	z-index: 15;

	border-radius: 4px;
	background: transparentize(#D7E4FD, 0.1);
	// background: #D7E4FD;

	cursor: pointer;
}


button.editor-editVideoModal-preview-controls-trimBar-thumb-align:hover{	
	background: #d1e0fe;
}


// DEV PUT BACK
button.editor-editVideoModal-preview-controls-trimBar-thumb-align--show{
	opacity: 1;
	transition: opacity 100ms linear; 
}

button.editor-editVideoModal-preview-controls-trimBar-thumb-align--hide{
	opacity: 0;
	transition: opacity 100ms linear;
	pointer-events: none;
}

.editor-editVideoModal-preview-controls-trimBar-thumbContainer-0
button.editor-editVideoModal-preview-controls-trimBar-thumb-align{
	left: -27px;
}

.editor-editVideoModal-preview-controls-trimBar-thumbContainer-1
button.editor-editVideoModal-preview-controls-trimBar-thumb-align{
	right: -27px;
}

button.editor-editVideoModal-preview-controls-trimBar-thumb-align svg{
	width: 12px;
	height: 12px;
	fill: $blue;
}


.editor-editVideoModal-preview-controls-trimBar-thumbContainer-1
button.editor-editVideoModal-preview-controls-trimBar-thumb-align--start{
	display: none;
}

.editor-editVideoModal-preview-controls-trimBar-thumbContainer-0
button.editor-editVideoModal-preview-controls-trimBar-thumb-align--end{
	display: none;	
}

.editor-editVideoModal-preview-controls-trimBarContainer-bgTrack{
	position: absolute;
	top: 0px;
	left: 0px;
	right: 0px;
	height: $previewTrimHeight-editVideoModal;
	z-index: 1;

	border-radius: 5px;

	background: rgba(0,0,30,0.03);
	pointer-events: none;

}





//
// TIMESTAMPS
//

.editor-editVideoModal-preview-controls-timestamp-current,
.editor-editVideoModal-preview-controls-timestamp-total{
	position: absolute;
	width: $previewSideMargin-editVideoModal;
	height: calc(#{$previewControlHeight} - #{$previewTrimHeight-editVideoModal} - #{$previewBarContainer-editVideoModal});	
	box-sizing: border-box;
	padding-bottom: 5px;
	//background: $tempr;
}	  

.editor-editVideoModal-preview-controls-timestamp-current{
	left: 0px;
	// background: $tempb;
}

.editor-editVideoModal-preview-controls-timestamp-total{
	right: 0px;
	// background: $tempb;
}

.editor-editVideoModal-preview-controls-timestamp-current,
.editor-editVideoModal-preview-controls-timestamp-total{

	font-size: 15px;
	font-weight: $lato-medium;
	display: flex;
	align-items: center;

	box-sizing: border-box;
		

	cursor: default;
	user-select: none;

	color: $textDark;
}

.editor-editVideoModal-preview-controls-timestamp-current{
	justify-content: flex-end;
	padding-right: 18px;
	font-weight: $lato-bold;
	font-size: 15px;
}

.editor-editVideoModal-preview-controls-timestamp-total{
	justify-content: flex-start;
	padding-left: 18px;
	font-weight: $lato-bold;
	opacity: 0.8;
}



// BTN CONTAINERS 

$widthTrimBarEdges: 48px;

.editor-editVideoModal-preview-controls-leftContainer,
.editor-editVideoModal-preview-controls-rightContainer{
	position: absolute;
	top: calc(#{$previewControlHeight} - #{$previewTrimHeight-editVideoModal} - #{$previewBarContainer-editVideoModal} - 2px);	
	bottom: calc(#{$previewBarContainer-editVideoModal} - 2px);
	width: $previewSideMargin-editVideoModal;
	box-sizing: border-box;
}

.editor-editVideoModal-preview-controls-leftContainer{
	left: 0px;
	padding-left: 12px;
	padding-right: $widthTrimBarEdges;
	// background: $tempb;
}
.editor-editVideoModal-preview-controls-rightContainer{
	right: 0px;

	display: flex;
	align-items: center;
	justify-content: center;
	// background: $tempr;
	padding-left: $widthTrimBarEdges;
	padding-right: 12px;
}

//
// PLAY BUTTON
// 

button.editor-editVideoModal-preview-controls-playPauseBtn{
	width: 100%;
	height: 100%;
	
	background: $white;
	border-radius: 4px;

	border: 1px solid $lineDark;
	border-bottom: 1px solid darken($lineDark, 5%);

	border: 1px solid darken($lineDark, 5%);
	border-bottom: 1px solid darken($lineDark, 8%);	
	
	box-shadow: 0px 1px 2px 0px rgba(0,0,40,0.06);

	cursor: pointer;

	display: flex;
	align-items: center;
	justify-content: center;
}

// button.editor-editVideoModal-preview-controls-playPauseBtn--playing{
// 	border: 1px solid $gray-2;
// 	border-bottom: 1px solid darken($gray-2, 5%);

// 	background: $gray-3;	
// }

button.editor-editVideoModal-preview-controls-playPauseBtn svg{
	width: 22px;
	height: 22px;

	fill: $blue;
	fill: $gray-3;
}

// button.editor-editVideoModal-preview-controls-playPauseBtn--playing svg{
// 	fill: $white;
// }

button.editor-editVideoModal-preview-controls-playPauseBtn:hover{
	border: 1px solid darken($lineDark, 8%);
	border-bottom: 1px solid darken($lineDark, 12%);
	box-shadow: 0px 1px 2px 0px rgba(0,0,40,0.10);
}

// button.editor-editVideoModal-preview-controls-playPauseBtn--playing:hover{
// 	border: 1px solid $gray-1;
// 	border-bottom: 1px solid $black;
// 	box-shadow: 0px 1px 2px 0px rgba(0,0,40,0.14);
// }

button.editor-editVideoModal-preview-controls-playPauseBtn:hover svg{
	transform: scale(1.08);
}

button.editor-editVideoModal-preview-controls-playPauseBtn:active{
	border: 1px solid darken($lineDark, 8%);
	border-bottom: 1px solid darken($lineDark, 10%);
	box-shadow: 0px 0px 1px 0px rgba(0,0,40,0.10);
	background: $gray-15;
}

button.editor-editVideoModal-preview-controls-playPauseBtn:active svg{
	transform: scale(1.15);
}


button.editor-editVideoModal-preview-controls-playPauseBtn svg.editorIcon-playbackPlay{
	position: relative;
	left: 2px;
}

button.editor-editVideoModal-preview-controls-playClip{
	position: absolute;
	
	top: 8px;
	bottom: 8px;
	right: 8px;
	width: calc(100% - 38px);

	flex-grow: 0;
	flex-shrink: 0;

	background: $gray-15;
	border-radius: 5px;
	border: 1px solid $lineDark;

	font-size: 13.5px;
	font-weight: $lato-bold;
	
	//box-shadow: 0px 2px 4px 0px rgba(0,0,40,0.08);

	cursor: pointer;

	display: flex;
	align-items: center;
	justify-content: center;
}



.editor-editVideoModal-preview-controls-nudgeBtnContainer{
	height: 38px;

	display: flex;
	align-items: center;
	justify-content: center;

	width: 100%;

	// background: $tempr;
}

.editor-editVideoModal-preview-controls-nudgeBtnContainer--playing{
	opacity: 0;
	transition: opacity 50ms linear;
}

.editor-editVideoModal-preview-controls-nudgeBtnContainer--paused{
	opacity: 1;
	transition: opacity 50ms linear;
}

button.editor-editVideoModal-preview-controls-nudgeBtn{
	width: 42px;
	flex-grow: 0;
	flex-shrink: 0;
	height: 38px;
	display: flex;
	align-items: center;
	justify-content: center;

	background: $white;
	border-radius: 3px;
	border: 1px solid $lineDark;

	cursor: pointer;
	position: relative;

	box-sizing: border-box;
}

button.editor-editVideoModal-preview-controls-nudgeBtn svg{
	height: 14px;
	width: 14px;
	fill: $blue;
	fill: $gray-7;
}

button.editor-editVideoModal-preview-controls-nudgeBtn:first-child{
	border-top-right-radius: 0px;
	border-bottom-right-radius: 0px;
	margin-right: -1px;
	padding-right: 2px;
}

button.editor-editVideoModal-preview-controls-nudgeBtn:last-child{
	border-top-left-radius: 0px;
	border-bottom-left-radius: 0px;
	padding-left: 1px;
}

button.editor-editVideoModal-preview-controls-nudgeBtn:hover{
	z-index: 10;
	border: 1px solid darken($lineDark, 4%);
}

button.editor-editVideoModal-preview-controls-nudgeBtn:hover svg{
	transform: scale(1.1);
	fill: $gray-6;
}

button.editor-editVideoModal-preview-controls-nudgeBtn:active{
	background: $gray-15;
	border: 1px solid darken($lineDark, 8%);
}

button.editor-editVideoModal-preview-controls-nudgeBtn:active svg{
	fill: $gray-5;
	transform: scale(1.15);
}

.editor-editVideoModal-preview-controls-nudgeBtnContainer:hover
button.editor-editVideoModal-preview-controls-nudgeBtn{
	opacity: 0.5;
}

.editor-editVideoModal-preview-controls-nudgeBtnContainer:hover
button.editor-editVideoModal-preview-controls-nudgeBtn:hover{
	opacity: 1;
}


// CONTROL TOOLTIPS
.editor-editVideoModal-preview-controls-playPauseBtn-tooltip,
.editor-editVideoModal-preview-controls-nudgeBtn-tooltip{
	position: absolute;

	background: $gray-7;

	display: flex;
	align-items: center;
	justify-content: center;

	border-radius: 3px;

	box-sizing: border-box;

	font-size: 12px;
	font-weight: $lato-bold;
	color: $white;
	letter-spacing: 0.035em;

	padding-left: 8px;
	padding-right: 8px;
	
	opacity: 0;
	transition: opacity 100ms linear;

	pointer-events: none;
}

.editor-editVideoModal-preview-controls-playPauseBtn-tooltip{
	bottom: -24px;
	width: 70px;
	height: 20px;
	padding-bottom: 1px;
}

.editor-editVideoModal-preview-controls-nudgeBtn-tooltip{
	flex-direction: column;

	bottom: -35px;
	width: 64px;
	height: 32px;

	line-height: 1.1;
}

.editor-editVideoModal-preview-controls-nudgeBtn-tooltip svg.icon{
	width: 9px;
	height: 9px;
	fill: $white;
	opacity: 1;
}

.editor-editVideoModal-preview-controls-nudgeBtn-tooltip-keyboard{
	font-size: 11.5px;
	opacity: 0.75;
	margin-top: 1px;
}

button.editor-editVideoModal-preview-controls-nudgeBtn:last-child
.editor-editVideoModal-preview-controls-nudgeBtn-tooltip{
	margin-left: -10px;
}

button.editor-editVideoModal-preview-controls-playPauseBtn:hover .editor-editVideoModal-preview-controls-playPauseBtn-tooltip,
button.editor-editVideoModal-preview-controls-nudgeBtn:hover .editor-editVideoModal-preview-controls-nudgeBtn-tooltip{
	opacity: 1;
	transition: opacity 50ms linear;
	transition-delay: 500ms;
}

button.editor-editVideoModal-preview-controls-playPauseBtn:hover .editor-editVideoModal-preview-controls-playPauseBtn-tooltip{
	transition-delay: 1000ms;
}


//
// PREVIEW BAR
//

.editor-editVideoModal-preview-controls-previewBarContainer{
	position: absolute;
	left: $previewSideMargin-editVideoModal;
	right: $previewSideMargin-editVideoModal;
	bottom: 0px;
	height: $previewBarContainer-editVideoModal;
	// background: $tempb;
}	  

.editor-editVideoModal-preview-controls-previewBar{
	position: absolute;
	height: 100%;
	// background: $tempr;
}

.editor-editVideoModal-preview-controls-previewBar-line{
	position: absolute;
	top: 3px;
	left: 0px;
	right: 0px;
	height: 2px;
	border-radius: 2px;
}

.editor-editVideoModal-preview-controls-previewBar--readyToPlayClip
.editor-editVideoModal-preview-controls-previewBar-line{
	background: $gray-125;
}

.editor-editVideoModal-preview-controls-previewBar--draggingTrimBarHandles
.editor-editVideoModal-preview-controls-previewBar-line{
	background: $gray-10;
}

.editor-editVideoModal-preview-controls-previewBar--draggingTrimBarHandles
.editor-editVideoModal-preview-controls-previewBar-line--playingClip{
	background: transparentize($blue, 0.75);
}

.editor-editVideoModal-preview-controls-previewBar--playingClip
.editor-editVideoModal-preview-controls-previewBar-line,
.editor-editVideoModal-preview-controls-previewBar--resumeClip
.editor-editVideoModal-preview-controls-previewBar-line{
	height: 2px;
	background: $blue-extraLight;
}

.editor-editVideoModal-preview-controls-previewBar--playingClip
.editor-editVideoModal-preview-controls-previewBar-playClipProgressBar,
.editor-editVideoModal-preview-controls-previewBar--resumeClip
.editor-editVideoModal-preview-controls-previewBar-playClipProgressBar,
.editor-editVideoModal-preview-controls-previewBar--draggingTrimBarHandles
.editor-editVideoModal-preview-controls-previewBar-playClipProgressBar{
	position: absolute;
	top: 3px;
	height: 2px;
	left: 0px;
	background: $blue;
	z-index: 5;
	border-radius: 2px;
}

// MAIN BUTTON/LABEL THING

.editor-editVideoModal-preview-controls-previewBar-clipDuration,
button.editor-editVideoModal-preview-controls-previewBar-clipProgress,
button.editor-editVideoModal-preview-controls-previewBar-btn,
button.editor-editVideoModal-preview-controls-previewBar-clipRestartBtn{
	position: absolute;
	
	left: 50%;
	transform: translateX(-50%);
	
	top: 7px;

	height: 26px;

	font-size: 14px;
	color: $white;

	display: flex;
	align-items: center;
	justify-content: center;

	border: none;
	box-shadow: none;
	border-radius: 4px;

	user-select: none;
}

// STATE: READY TO PLAY
button.editor-editVideoModal-preview-controls-previewBar-btn{
	padding-left: 12px;
	padding-right: 12px;
	
	background: $gray-2;
	// background: $blue;
	

	font-weight: $lato-bold;	
	letter-spacing: 0.025em;

	white-space: nowrap;

	cursor: pointer;

	width: auto;
	opacity: 0.75;
	transition: opacity 75ms linear;
	margin-bottom: 2px;
}

button.editor-editVideoModal-preview-controls-previewBar-btn:hover{
	opacity: 1;
	transition: opacity 50ms linear;
}

button.editor-editVideoModal-preview-controls-previewBar-btn svg{
	height: 12px;
	width: 12px;
	fill: $white;
	margin-right: 6px;
	opacity: 0.8;
}

.editor-editVideoModal-preview-controls-previewBar--resumeClip
button.editor-editVideoModal-preview-controls-previewBar-btn{
	background: $blue;
	opacity: 1;
}

// STATE: CLIP DURATION
.editor-editVideoModal-preview-controls-previewBar-clipDuration{
	font-weight: $lato-bold;

	width: auto;
	padding-left: 9px;
	padding-right: 9px;
	letter-spacing: 0.04em;

	background: $gray-5;
	height: 26px;
	margin-bottom: 2px;

	background: $gray-4;
	color: $white;
}

.editor-editVideoModal-preview-controls-previewBar-clipDuration--playingClip{
	background: darken($blue, 4%);
	color: $white;

	padding-left: 11px;
	padding-right: 11px;
}

.editor-editVideoModal-preview-controls-previewBar-clipDuration--playingClip--clipProgress{
	opacity: 0.75;
	white-space: nowrap;
}

.editor-editVideoModal-preview-controls-previewBar-clipDuration--playingClip--duration{
	padding-left: 5px;
}

.editor-editVideoModal-preview-controls-previewBar-clipDuration-milliseconds{
	opacity: 0.5;
}

// STATE: CLIP PROGRESS
button.editor-editVideoModal-preview-controls-previewBar-clipProgress{
	font-weight: $lato-bold;
	width: auto;

	padding-left: 18px;
	padding-right: 18px;

	background: $blue;
	letter-spacing: 0.05em;
	height: 26px;
	margin-bottom: 2px;

	cursor: pointer;

	display: flex;
	align-items: center;

	padding-left: 8px;
	padding-right: 11px;
}

.editor-editVideoModal-preview-controls-previewBar-clipProgress-icon{
	padding-right: 5px;
}

.editor-editVideoModal-preview-controls-previewBar-clipProgress-icon svg{
	fill: rgba(255,255,255,0.7);
	height: 10px;
	width: 10px;
	position: relative;
	top: 0px;
	// transition: opacity 50ms linear; // removed because of safari bug
}

button.editor-editVideoModal-preview-controls-previewBar-clipProgress:hover 
.editor-editVideoModal-preview-controls-previewBar-clipProgress-icon svg{
	fill: rgba(255,255,255,1);
	// transition: opacity 50ms linear;
}

// rewind clip button



button.editor-editVideoModal-preview-controls-previewBar-clipRestartBtn{
	
	margin-left: -60px;
	width: 36px;

	background: transparentize($blue, 0.9);
	height: 26px;
	margin-bottom: 2px;

	opacity: 0.9;

	cursor: pointer;

	display: flex;
	align-items: center;
	justify-content: center;
}

.editor-editVideoModal-preview-controls-previewBar--resumeClip
button.editor-editVideoModal-preview-controls-previewBar-clipRestartBtn{
	margin-left: -76px;
}

button.editor-editVideoModal-preview-controls-previewBar-clipRestartBtn{
	opacity: 0.9;
	transition: opacity 50ms linear;
}

button.editor-editVideoModal-preview-controls-previewBar-clipRestartBtn:hover{
	opacity: 1;	
	transition: opacity 50ms linear;
}

button.editor-editVideoModal-preview-controls-previewBar-clipRestartBtn svg{	
	fill: $blue;
	height: 13px;
	width: 13px;
	
	position: relative;
	left: -1px;
	transition: opacity 50ms linear;
}


// iPAD Styling

@media (hover: none) {
	.editor-editVideoModal-preview-controls-seekBarContainer{
		// background: $tempr;
		bottom: 10px;
	}

	.editor-editVideoModal-preview-controls-seekBar-thumb-circle,
	.editor-editVideoModal-preview-controls-seekBar-thumb-circle--loading{
		// width: 20px;
		// height: 20px;
		// left: -11px;
		// top: calc((160px * 0.5) - 9px);

		width: 30px;
		height: 30px;
		left: -20px;
		top: 7px;

		border-width: 4px;
	}

	.editor-editVideoModal-preview-controls-seekBar-thumb-line{
		height: 98px;
	}

	.editor-editVideoModal-preview-controls-seekBar-thumb-tooltip{
		top: -32px;
	}
	.editor-editVideoModal-preview-controls-trimBarContainer{
		//bottom: calc(64px + 36px)
	}

	button.editor-editVideoModal-preview-controls-trimBar-thumb-align{
		display: none;
	}

	.editor-editVideoModal-preview-controls-trimBar-thumb{
		width: 28px;
	}

	.editor-editVideoModal-preview-controls-trimBar-thumbContainer-0 .editor-editVideoModal-preview-controls-trimBar-thumb{
		left: calc(-1 * 28px);
		border-top-left-radius: 8px;
		border-bottom-left-radius: 8px;
		border-top-right-radius: 3px;
		border-bottom-right-radius: 3px;
	}

	.editor-editVideoModal-preview-controls-trimBar-thumbContainer-1 .editor-editVideoModal-preview-controls-trimBar-thumb{
		right: calc(-1 * 28px);
		border-top-left-radius: 3px;
		border-bottom-left-radius: 3px;
		border-top-right-radius: 8px;
		border-bottom-right-radius: 8px;
	}

	.editor-editVideoModal-preview-controls-trimBarContainer-bgTrack,
	.editor-editVideoModal-preview-controls-trimBar-track-1,
	.editor-editVideoModal-preview-controls-trimBar-thumb{
		height: 64px;
	}

	.editor-editVideoModal-preview-controls-trimBar-thumb-line{
		width: 4px;
		border-radius: 4px;
	}

	.editor-editVideoModal-preview-controls-playPauseBtn-tooltip,
	.editor-editVideoModal-preview-controls-nudgeBtn-tooltip{
		visibility: hidden;
	}

	.editor-editVideoModal-preview-controls-previewBarContainer{
		display: none;
	}

	.editor-editVideoModal-preview-controls-trimBarContainer{
		top: 58px;
	}

	.editor-editVideoModal-preview-controls-seekBar-track{
		margin-top: 26px;
	}

	.editor-editVideoModal-preview-controls-leftContainer{
		padding-right: 36px;
		bottom: 10px;
		top: 58px;
	}

	.editor-editVideoModal-preview-controls-rightContainer{
		padding-left: 36px;
		bottom: 10px;
		top: 58px;
	}

	button.editor-editVideoModal-preview-controls-playPauseBtn{
		border-radius: 8px;
	}

	button.editor-editVideoModal-preview-controls-nudgeBtn{
		height: 48px;
	}

	.editor-editVideoModal-preview-controls-timestamp-current{
		padding-right: 30px;
		padding-top: 6px;
	}

	.editor-editVideoModal-preview-controls-timestamp-total{
		padding-left: 30px;
		padding-top: 6px;
	}
}