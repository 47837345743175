.assignToDataOwner-modal .modal-content {
  width: 780px;
  height: auto;
  overflow: auto;
  max-height: 80vh;
}

.assignSectionToDataOwnerContainer {
  padding: 50px 60px;
  text-align: center;
}

.assignSectionToDataOwnerContainer-dataOwnerSelectionContainer {
  background: $gray-14;
  padding: 30px;
  border-radius: 50px;
  width: 85%;
  margin: auto;
}

.assignSectionToDataOwnerContainer-sectionLabel {
  margin: 0px auto 30px auto;
  display: inline-block;
}

.assignSectionToDataOwnerContainer-sectionLabel-circle,
.assignSectionToDataOwnerContainer-sectionLabel-name {
  display: inline;
}

.assignSectionToDataOwnerContainer-sectionLabel-circle--classColor--blue { fill: $classColor-blue; }
.assignSectionToDataOwnerContainer-sectionLabel-circle--classColor--purple { fill: $classColor-purple; }
.assignSectionToDataOwnerContainer-sectionLabel-circle--classColor--red { fill: $classColor-red; }
.assignSectionToDataOwnerContainer-sectionLabel-circle--classColor--orange { fill: $classColor-orange; }
.assignSectionToDataOwnerContainer-sectionLabel-circle--classColor--green{ fill: $classColor-green; }
.assignSectionToDataOwnerContainer-sectionLabel-circle--classColor--sky { fill: $classColor-sky; }
.assignSectionToDataOwnerContainer-sectionLabel-circle--classColor--pink { fill: $classColor-pink; }
.assignSectionToDataOwnerContainer-sectionLabel-circle--classColor--teal { fill: $classColor-teal; }
.assignSectionToDataOwnerContainer-sectionLabel-circle--classColor--grey { fill: $classColor-grey; }

.assignSectionToDataOwnerContainer-sectionLabel-circle .icon.circle {
  width: 18px;
  height: 18px;
}

.assignSectionToDataOwnerContainer-sectionLabel-name {
  font-size: 34px;
  font-weight: $lato-heavy;
  margin-left: 13px;
}

.dataOwnerSelection-header {
  font-size: 16px;
  font-weight: $lato-semibold;
  color: $textMid;
  margin-bottom: 10px;
}

.dataOwnerSelection-dataOwnerContainer {
  display: flex;
  width: 380px;
  margin: 0 auto 10px auto;
}

.dataOwnerSelection-dataOwnerContainer .featureModal-selectControl-radio {
 margin: auto 10px auto 0px;
}

.dataOwnerSelection-dataOwnerContainer-card {
  padding: 10px 20px;
  border-radius: 10px;
  width: 350px;
  cursor: pointer;
  background: $white;
}

.dataOwnerSelection-dataOwnerContainer-card--active {
  background: $white;
  border: 1px solid $blue;
}

.dataOwnerSelection-dataOwnerContainer-card--inactive {
  border: 1px solid $lineDark;
  opacity: 0.7;
  transition: opacity 150ms linear;
  background: inherit;
}

.dataOwnerSelection-dataOwnerContainer-card--inactive:hover {
  border: 1px solid $gray-115;
  opacity: 0.95;
  transition: opacity 50ms linear;
}


.dataOwnerSelection-dataOwnerContainer-card-name {
  font-size: 18px;
  font-weight: $lato-heavy;
  color: $textDark;
  letter-spacing: 0.02em;
}

.dataOwnerSelection-dataOwnerContainer-card-location {
  font-size: 14px;
  color: $textMid;
}

.assignSectionToDataOwnerContainer-description {
  margin: 0px auto 0px auto;
  color: $textMid;
  padding: 20px 15px;
  border-radius: 10px;
  width: 500px;
  font-size: 14px;
  line-height: 1.4;
  text-align: left;
}

.assignSectionToDataOwnerContainer-description div {
  margin-top: 5px;
}

.assignSectionToDataOwnerContainer-action {
  margin-top: 40px;
}

.assignSectionToDataOwnerContainer-action .featureModal-selectControl {
  width: 500px;
  text-align: left;
  margin: auto;
}

.assignSectionToDataOwnerContainer-action .featureModal-selectControl-label {
  font-weight: $lato-regular;
}

.assignSectionToDataOwnerContainer-action button {
  min-width: 184px;
  width: 184px;
  margin: 15px auto 0px auto;
}

.assignSectionToDataOwnerContainer-action-errorMessage {
  margin-top: 10px;
  color: $red;
}
