.liveView-studentList{
	position: absolute;

	display: flex;
	flex-wrap: wrap;	
	align-items: flex-start;
	justify-content: flex-start;
	align-content: flex-start;

	box-sizing: border-box;
}

.liveView-studentList-studentItem{
	box-sizing: border-box;
	position: relative;
}

.liveView-studentList-studentItem-pill{
	position: absolute;

	background: $white;

	font-weight: $lato-semibold;

	display: flex;
	align-items: center;


	box-sizing: border-box;
	border: 1px solid $lineDark;
	
	color: $textBlackDark;

	padding-left: 6%; // seems overkill to load into JS
	box-shadow: 0px 1px 2px 0px rgba(0,0,10,0.04);
	
	letter-spacing: 0.005em;

	box-sizing: border-box;	
	cursor: default;
	user-select: none;

	white-space: nowrap;
  overflow: hidden;
}

.liveView-studentList-studentItem-pill span{
	-webkit-transform: translate3d(0,0,0)	
}


.liveView-studentList-studentItem-pill--hasResponse{
	background: $blue;
	color: $white;
	border: 1px solid $blue-dark;	
}

.liveView-studentList--isShowCorrect.liveView-studentList--isShowIndividualStudentResponsesInStudentList .liveView-studentList-studentItem-pill--hasResponse{
	background: $white;
	color: $textBlackDark;
	border: 1px solid $lineDark;
}


//

.liveView-studentList-studentItem-pill-name-cardNumber,
.liveView-studentList-studentItem-pill-name-letterBlock{
	position: absolute;	
	top: -1px;
	right: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 5;
}

.liveView-studentList-studentItem-pill-name-letterBlock{
	color: $white;
	z-index: 10;
}

.liveView-studentList--isNotShowCorrect .liveView-studentList-studentItem-pill
.liveView-studentList-studentItem-pill-name-letterBlock{
	background: $blue;
}

.liveView-studentList--isShowCorrect .liveView-studentList-studentItem-pill--isCorrect
.liveView-studentList-studentItem-pill-name-letterBlock{
	background: $green;
}

.liveView-studentList--isShowCorrect .liveView-studentList-studentItem-pill--isIncorrect
.liveView-studentList-studentItem-pill-name-letterBlock.is--scheme1{
	background: $gray-14;
	color: $textDark;
}

.liveView-studentList--isShowCorrect .liveView-studentList-studentItem-pill--isIncorrect
.liveView-studentList-studentItem-pill-name-letterBlock.is--scheme2{
	background: $red;
	color: $white;
}



.liveView-studentList--isShowCorrect .liveView-studentList-studentItem-pill--isSurvey
.liveView-studentList-studentItem-pill-name-letterBlock{
	background: $blue;
}

.liveView-studentList-studentItem-pill-name-cardNumber{
	background: $white;	
	color: $blue;
}

.liveView-studentList-studentItem-pill--hasResponse .liveView-studentList-studentItem-pill-name-cardNumber{
	background: $blue;
	color: $white;
}


///

.liveView-studentList-studentItem-pill-guestCard{
	opacity: 0.75;
}

// LONG NAME HANDLING

.liveView-studentList-studentItem-pill
span.liveView-studentList-studentItem-pill-name--long{
	transform: scale(0.85);
	transform-origin: left center;
}

//
// ONLINE STATUS

.liveView-studentList-studentItem-pill-hybridOnlineStatus{
	position: absolute;
	left: 8px;
	bottom: 2px;

	display: flex;
	align-items: center;
	justify-content: space-around;

	// background: $tempr;
}

.liveView-studentList-studentItem-pill-hybridOnlineStatus-marker{
	height: 2px;
	width: 10px;

	border-radius: 0px;
	background: $green;
	margin-right: 2px;

	opacity: 1;
}