//
// POLLS FOR ITEMS LIST
// Small pill-buttons to play in class queued in, in e.g. Library sidepanel and Set Detail header
// 

.pollsForItemList-section{
	cursor: pointer;
	user-select: none;

	flex-shrink: 1;

	height: 24px;
	margin-left: 4px;
	margin-right: 4px;
	color: $white;
	border-radius: 4px;

  min-width: 0;

  position: relative;
}

.pollsForItemList-section-name{
	line-height: 1.7;

	flex-shrink: 1;
	min-width: 0;

	font-size: 14px;
	height: 100%;

	margin-left: 25px;
	margin-right: 10px;
	
	color: $white;

	font-weight: $lato-semibold;

	white-space: nowrap;
  overflow: hidden;
  //text-overflow: ellipsis; // took up too much space

  min-width: 0;
}

//
// SMALL PLAY ICON

.pollsForItemList-section .pollsForItemList-section-playContainer{
	position: absolute;
	width: 20px;
	height: 100%;	

	top: 3px;
	left: 3px;

	width: 18px;
	height: 18px;

	border-radius: 9px;

	box-sizing: border-box;

	display: flex;
	justify-content: center;
	align-items: center;

	background: rgba(0,0,0,0.04);	
	opacity: 0.85;

	transition: all 150ms linear;
}

.pollsForItemList-section .pollsForItemList-section-playContainer .icon{
	height: 10px;
	width: 10px;
	fill: $white;
	position: relative;
	left: 1px;
}

.pollsForItemList-section:hover .pollsForItemList-section-playContainer{
	opacity: 1;
	background: rgba(0,0,0,0.12);

	transition: all 50ms linear;
}

//
// SECTION COLORING

.pollsForItemList-section--classColor--blue				{ background: $classColor-blue; }
.pollsForItemList-section--classColor--purple			{ background: $classColor-purple; }
.pollsForItemList-section--classColor--red				{ background: $classColor-red; }
.pollsForItemList-section--classColor--orange			{ background: $classColor-orange; }
.pollsForItemList-section--classColor--green			{ background: $classColor-green; }
.pollsForItemList-section--classColor--sky				{ background: $classColor-sky; }
.pollsForItemList-section--classColor--pink				{ background: $classColor-pink; }
.pollsForItemList-section--classColor--teal				{ background: $classColor-teal; }
.pollsForItemList-section--classColor--grey				{ background: $classColor-grey; }