//
// SIGN IN/UP CARTOON DEVICES
// these are the "device" cartoons displayed around the four corners of signup
// the screenshots themselves are fully SVGs loaded from cartoonScreenSVG
// but some amount of the device frame are built just using divs
// note that the positioning on the page, and scaling, happens in SignInSignUp.scss
//

$borderColor-cartoonDevice: rgb(215,215,225);
$borderColor-cartoonDevice-screen: rgb(235,235,240);
$boxShadow-cartoonDevice: 0px 40px 80px 0px rgba(0,15,40,0.06),
		0px 16px 32px 0px rgba(0,15,40,0.03),
		inset 0px 0px 30px rgba(0,15,40,0.03);


//
// iPHONE DEVICE FRAME

.cartoonDevice--iPhone{
	width: 427px;
	height: 863px;
	position: relative;
}

.cartoonDevice--iPhone .cartoonDevice-body{
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 1;	
	
	background: $white;
	border-radius: 64px;
	border: 1px solid $borderColor-cartoonDevice;
	box-shadow: $boxShadow-cartoonDevice;		
}

.cartoonDevice--iPhone-topSensors{
	position: absolute;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 66px;
	z-index: 5;
}

.cartoonDevice--iPhone .cartoonDevice-screen{
	position: absolute;
	top: 108px;
	left: 50%;
	transform: translateX(-50%);
	z-index: 5;

	width: 375px;
	height: 667px;
	background: $white;
	border: 1px solid $borderColor-cartoonDevice-screen;
}

.cartoonDevice--iPhone .cartoonDevice-screen .cartoonScreenSVG{
	width: 375px;
	height: 667px;
	position: absolute;
	left: 0px;
	top: 0px;
}

//
// LAPTOP DEVICE FRAME

.cartoonDevice--laptop{
	width: 1436px;
	height: 877px;

	background: $white;
	position: relative;

	border-radius: 30px;

	border: 1px solid $borderColor-cartoonDevice;

	box-shadow: $boxShadow-cartoonDevice;		
}

.cartoonDevice--laptop .cartoonDevice-screen{
	width: 1366px;
	height: 768px;

	position: absolute;
	top: 35px;
	left: 50%;
	transform: translateX(-50%);

	background: $white;
	border: 1px solid $gray-11;
	box-sizing: border-box;

	border-radius: 10px;
	overflow: hidden;
}

.cartoonDevice--laptop .cartoonDevice-screen .cartoonScreenSVG{
	width: 1366px;
	height: 768px;
	position: absolute;
	left: 0px;
	top: 0px;
}