.sectionHome-history{
	cursor: default;
	user-select: none;
	padding-bottom: 20px;
	position: relative;
	overflow: hidden;
}

.sectionHome-history-link,
.sectionHome-history-link:visited{
	font-size: 15px;
	color: $blue;
	font-weight: $lato-semibold;
	width: auto;
}

//  

$sectionHomeHistoryGrid-rightPadding: 40px;
$sectionHomeHistoryGrid-columnGutter: 30px;
$sectionHomeHistoryCard-height: 100px;
$sectionHomeHistoryCard-paddingTop: 15px;

.sectionHome-history-historyCards{
	width: calc(100% + #{$sectionHomeHistoryGrid-columnGutter} + #{$sectionHomeHistoryGrid-rightPadding} - 20px);
	// height is set via JS 
	
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;

	margin-top: calc(-1 * #{$sectionHomeHistoryCard-paddingTop} + 3px); //offset top padding on card
}

.sectionHome-history-historyCardContainer{
	width: calc(50% - #{$sectionHomeHistoryGrid-columnGutter});
	margin-right: $sectionHomeHistoryGrid-columnGutter;
	
	height: $sectionHomeHistoryCard-height;
	
	padding-right: $sectionHomeHistoryGrid-rightPadding;
	
	cursor: pointer;
	box-sizing: border-box;
}

.sectionHome-history-historyCard{
	width: 100%;
	height: 100%;
	border-top: 1px solid $invisible;
	border-bottom: 1px solid $lineMid;
	padding-top: $sectionHomeHistoryCard-paddingTop;
	box-sizing: border-box;
	position: relative;
}

.sectionHome-history-historyCard-timestamp{
	font-weight: $lato-medium;
	font-size: 14px;
	color: $textMid;
	opacity: 0.75;
}

.sectionHome-history-historyCardContainer:hover
.sectionHome-history-historyCard-timestamp{
	opacity: 0.85;
}

.sectionHome-history-historyCard-title{
	padding-top: 3px;
	
	font-size: 15px;
	color: $textBlack;
	opacity: 0.9;
	font-weight: $lato-bold;
	
	width: 100%;
	line-height: 1.3;
	
	
	overflow: hidden;

	
	padding-right: 60px; // top prevent overflow with scorepill
	box-sizing: border-box;
}

.sectionHome-history-historyCardContainer.is--question
.sectionHome-history-historyCard-title{
	height: 44px;
	//background: $tempr;
	display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;

  text-overflow: clip;
  white-space: normal;
  overflow: hidden; 
}

.sectionHome-history-historyCardContainer.is--set
.sectionHome-history-historyCard-title{
	height: 24px;
	//background: $tempr;
	
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}


.sectionHome-history-historyCardContainer:hover
.sectionHome-history-historyCard-title{
	opacity: 1;
}

.sectionHome-history-historyCard-scorePillContainer{
	position: absolute;
	top: 0px;
	bottom: 12px;
	right: 0px;

	display: flex;
	align-items: center;
	justify-content: flex-end;
}

//

.sectionHome-history-historyCard-navBtn{
	
	position: absolute;	
	right: -24px;
	width: 12px;
	top: 0px;
	bottom: 12px;

	display: flex;
	align-items: center;
	justify-content: flex-end;
		
	opacity: 0.15;
}

.sectionHome-history-historyCard-navBtn .icon{
	width: 12px;
	height: 12px;
	fill: $textDark;
}

.sectionHome-history-historyCardContainer
.sectionHome-history-historyCard-navBtn{
	opacity: 0.2;
	transition: opacity 150ms ease-in-out;
}

.sectionHome-history-historyCardContainer:hover
.sectionHome-history-historyCard-navBtn{
	opacity: 0.7;
	transition: opacity 50ms ease-in-out;
}

.sectionHome-history-historyCards:hover
.sectionHome-history-historyCardContainer{
	opacity: 0.9;
	transition: opacity 150ms ease-in-out;
}

.sectionHome-history-historyCards
.sectionHome-history-historyCardContainer:hover{
	opacity: 1;
	transition: opacity 50ms ease-in-out;
}

// blocker blocks bottom lines
.sectionHome-history-historyCards-bottomBlocker{
	background: $white;
	width: 100%;
	height: 1px;
	position: absolute;
	bottom: 20px;

}