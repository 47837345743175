.verifyPasswordToSignin-modalContent {
  text-align: center;
  margin: 50px;
}

.verifyPasswordToSignin-modalContent-title {
  font-size: 28px;
  font-weight: $effra-heavy;
  color: $textBlackDark;

  margin-bottom: 30px;
}

.verifyPasswordToSignin-modalContent-description {
  font-size: 16px;
  font-weight: $effra-medium;
  color: $textMid;
  margin: 0 20px;
}
.verifyPasswordToSignin-modalContent-description div {
  margin-bottom: 20px;
}

.verifyPasswordToSignin-modalContent-main {
  width: 250px;
  margin: 30px auto 0px auto;
}

.verifyPasswordToSignin-modalContent-main .cancel {
  width: 100%;
  margin-top: 20px;
  pointer-events: auto; //revert gray button effect back to normal
  cursor: pointer;
}

.verifyPasswordToSignin-modalContent-main-error {
  color: $red;
  text-align: center;
  font-size: 14px;
  font-weight: $effra-medium;
}
