//
// DISCOVER
//

.discover-repoListContainer{
	width: 100%;
}

$height-discover-market-miniRepoLink: 66px;

$height-discover-market-seeAllRepoLink: 105px;

$bgColor-activeRepoLink: rgb(248,248,254);;


@import 'Discover-RepoLink';
@import 'Discover-RepoListHeader';

@import 'Discover-RepoRatingStars';

@import 'Discover-Home';
@import 'Discover-Market';

@import 'Discover-FeaturedRepoLink';
@import 'Discover-MiniRepoLink';
@import 'Discover-MarketFooter';

@import 'Discover-Upcoming'; // on home page

@import 'Discover-ELAKeyTexts'; // custom styling for ELA

@import 'Discover-SeeAll'; // see all pages
@import 'Discover-SeeAllRepoLink'; // see all pages