.confirmEditStudentCard-modal.is--swapStudent .modal-content{
	width: 480px;
 	height: 410px;
}

.confirmEditStudentCard-modal.is--notSwapStudent .modal-content{
	width: 480px;
 	height: 350px;
}

.confirmEditStudentCard{
	position: relative;
	width: 100%;
	height: calc(100% - 46px);

	box-sizing: border-box;
	padding-left: 20px;
	padding-right: 20px;
	padding-bottom: 68px; // for the button area

	display: flex;
	flex-direction: column;

	align-items: center;
	justify-content: center;

}

// Diagram

.confirmEditStudentCard-diagram--container{
	width: 100%;
}

.confirmEditStudentCard-diagram--container:nth-child(2){
	border-top: 1px solid $lineMid;
}

.confirmEditStudentCard-diagram{
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding-top: 15px;
	padding-bottom: 15px;
}

.confirmEditStudentCard-diagram-name{
	width: 100%;
	text-align: center;
	font-size: 18px;
	font-weight: $lato-bold;
	color: $textDark;
	margin-bottom: 10px;
}

.confirmEditStudentCard-diagram-main{
	display: flex;
	align-items: center;
}

.confirmEditStudentCard-diagram-main .icon.long-triangle-right{
	fill: $lineMid;
	width: 45px;
	height: 45px;

	margin-left: 20px;
	margin-right: 20px;
}

.confirmEditStudentCard-diagram-cardNo{
	width: 48px;
	height: 48px;
	border-radius: 5px;

	background: $white;
	border: 1px solid $lineDark;

	font-size: 25px;
	font-weight: $lato-bold;
	color: $textBlack;
	line-height: 1.95;

	text-align: center;

	box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.08);
}

.confirmEditStudentCard-diagram-cardNo.is--old{
	transform: scale(0.75);
	opacity: 0.85;

	background: none;
	color: $textMid;

	box-shadow: none;
}

.confirmEditStudentCard-diagram-explainText{
	font-size: 12px;
	font-weight: $lato-medium;
	color: $textMid;
	margin-top: 10px;
}

.confirmEditStudentCard-buttonContainer{
	position: absolute;
	bottom: 0px;
	left: 0px;
	right: 0px;

	height: 68px;
	display: flex;
	align-items: center;
	justify-content: center;

	background: $white;
	border-top: 1px solid $lineMid;

	border-bottom-left-radius: 3px;
	border-bottom-right-radius: 3px;
}

.confirmEditStudentCard-buttonContainer .btn.btn--xLarge{
	width: 320px;
}