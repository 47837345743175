//
// FEATURE MODAL HEADER
//

.featureModal-header{
	margin-left: 50px;
	margin-top: 44px;
	
	width: calc(100% - 50px);
	
	margin-bottom: 24px;

	cursor: default;
}

.featureModal-header-h1{
	font-size: 28px;
	font-weight: $lato-heavy;
	color: $textBlack;
	margin-bottom: 12px;
}

.featureModal-header-h2{
	font-size: 18px;
	font-weight: $lato-bold;
	color: $textDark;
	margin-bottom: 8px;

	white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.featureModal-header-h3{
	font-size: 15px;
	font-weight: $lato-semibold;
	color: $textMid;	
}