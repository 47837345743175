
.importQuestionsNotification{
	position: fixed;
	bottom: 18px;
	left: 82px;

	color: $textBlack;
	font-size: 15px;
	font-weight: $lato-bold;

	z-index: 1000;
	
	padding-left: 10px;
	padding-right: 24px;
	padding-top: 8px;
	padding-bottom: 10px;

	min-width: 240px;
	max-width: 450px;

	box-sizing: border-box;

	border: 1px solid $lineDark;
	background: $white;
	
	box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.12);

	cursor: default;
	user-select: none;

	border-left: 5px solid $blue;

	border-radius: 4px;	
	border-top-left-radius: 1px;
	border-bottom-left-radius: 1px;

	white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

}

.importQuestionsNotification--hide{
	pointer-events: none;
}

.importQuestionsNotification--show{
	pointer-events: auto;
}

.importQuestionsNotification--kahoot{
	max-width: 650px;
}


.importQuestionsNotification-button{
	margin-top: 4px;
	color: $textLight;
	opacity: 0.75;
	font-weight: $lato-semibold;
	cursor: pointer;
	font-size: 14px;
}

.importQuestionsNotification:hover .importQuestionsNotification-button{
	opacity: 1;
}

.importQuestionsNotification .importQuestionsNotification-button:hover{
	color: $textBlue;
	text-decoration: underline;
}