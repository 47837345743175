.liveView-reviewControls{
	display: flex;
	position: absolute;
	// top: 64px;
	// right: 16px;
	z-index: 4000;
}

.liveView-reviewControls.liveView-reviewControls--fullScreen{
	top: 20px;
}

//

.liveView-reviewControls-btn{
	// padding-left: 16px;
	// padding-right: 16px;

	height: 36px;
	cursor: pointer;
	// margin-left: -1px;
	margin: 2px;
	border-radius: 6px;

	display: flex;
	align-items: center;
	justify-content: center;

	position: relative;

	color: $textDark;
	text-align: center;

	font-weight: $lato-heavy;

	user-select: none;
}


.liveView-reviewControls-btn--manualShowIndividualStudentResponses{
	width: 125px;
	// border-top-left-radius: 0px;
	// border-bottom-left-radius: 0px;
}

.liveView-reviewControls-btn--showCorrect{
	width: 115px;
	// border-top-right-radius: 0px;
	// border-bottom-right-radius: 0px;
}

.liveView-reviewControls-btn--showGraph{
	width: 115px;
	// border-radius: 0px;
}
//

// DEFAULT / INACTIVE

button.liveView-reviewControls-btn--showCorrect--inactive,
button.liveView-reviewControls-btn--showGraph--inactive,
button.liveView-reviewControls-btn--manualShowIndividualStudentResponses--inactive,{	
	background: $white;
	border: 1px solid $gray-10;
	border-bottom: 1px solid $gray-11;
	box-shadow: 0px 2px 4px rgba(0,0,0,0.08);
		
}

.liveView-reviewControls:hover button.liveView-reviewControls-btn--showCorrect--inactive,
.liveView-reviewControls:hover button.liveView-reviewControls-btn--showGraph--inactive,
.liveView-reviewControls:hover button.liveView-reviewControls-btn--manualShowIndividualStudentResponses--inactive{
	opacity: 0.5;
}

.liveView-reviewControls button.liveView-reviewControls-btn--showCorrect--inactive:hover,
.liveView-reviewControls button.liveView-reviewControls-btn--showGraph--inactive:hover,
.liveView-reviewControls button.liveView-reviewControls-btn--manualShowIndividualStudentResponses--inactive:hover{
	opacity: 1;
}


// ACTIVE

.liveView-reviewControls-btn--showCorrect--active{
	background: $green;
	border: 1px solid $green-dark;
}

.liveView-reviewControls-btn--showGraph--active{
	background: $blue-light;
	border: 1px solid $blue;
}

.liveView-reviewControls-btn--manualShowIndividualStudentResponses--active{
	background: #F08642;
	border: 1px solid darken(#F08642, 5%);
}

.liveView-reviewControls-btn--showCorrect--active,
.liveView-reviewControls-btn--showGraph--active,
.liveView-reviewControls-btn--manualShowIndividualStudentResponses--active{
	transform: scale(1.02);	
	color: $white;
}



/// HIDE SHOW CORRECT IF SURVEY

.liveView-reviewControls.liveView-reviewControls--isSurvey
.liveView-reviewControls-btn--showCorrect{
	opacity: 0.5;
	pointer-events: none;
}

