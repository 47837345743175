//
// SCORESHEET REVIEW MODAL
// this is a custom modal (doesn't use modal component)
// you open it by clicking on header row, on set or question
//

$innerMargin-scoreSheet-reviewModal: 40px;

.scoreSheet-reviewModalContainer,
.scoreSheet-setReviewModalContainer{
	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	z-index: 2000;

	display: flex;

	align-items: center;
	justify-content: center;
}

.scoreSheet-reviewModalContainer-bg,
.scoreSheet-setReviewModalContainer-bg{
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	background: rgba(0, 0, 3, 0.75);
}

.scoreSheet-reviewModal,
.scoreSheet-setReviewModal{	
	border-radius: 4px;
	border: 1px solid $gray-5;

	box-shadow: 
		0px 4px 8px 0px rgba(0,0,40,0.12), 
		0px 2px 4px 0px rgba(0,0,40,0.20);

	position: relative;
	z-index: 5;
}

.scoreSheet-reviewModal{
	background: rgb(250,250,255);

	display: flex;
	flex-direction: column;

	align-items: center;

	padding-left: $innerMargin-scoreSheet-reviewModal;
	padding-right: $innerMargin-scoreSheet-reviewModal;
	padding-bottom: 24px;
}

.scoreSheet-setReviewModal{
	background: $white;

	width: 800px;
	height: auto;
	max-height: 85vh;

	overflow: auto;
	overflow-x: hidden;

	box-sizing: border-box;
	padding-left: 45px;
	padding-right: 45px;
	padding-top: 30px;
	padding-bottom: 30px; 
}

//
// SET REVIEW MODAL STYLING
// when clicking on set, see a grid of students similar to top of set report
@import 'ScoreSheet-ReviewModal-SetReview.scss';


//
// QUESTION REVIEW MODAL
// also includes styling for the left and right nav buttons

@import 'ScoreSheet-ReviewModal-QuestionReview.scss';

