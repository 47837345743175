.discover-repoList-header{
	width: 100%;
	display: flex;
	margin-bottom: 10px;
	margin-left: -3px; // for overflow button
}

.discover-repoList-header-toggleGroup{
	display: flex;
}

.discover-repoList-header-toggleGroup--grade{
	margin-left: 20px;
}

button.discover-repoList-header-toggleItem{
	font-size: 15px;

	background: none;
	padding-top: 2px;
	padding-bottom: 2px;
	padding-right: 6px;
	padding-left: 6px;
	border: none;
	border-radius: 0px;
	height: 28px;

	box-shadow: none;

	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
}

button.discover-repoList-header-toggleItem--active{
	color: $textBlack;
	color: $blue-bold;
	font-weight: $lato-semibold;
}

button.discover-repoList-header-toggleItem--inactive{
	opacity: 0.5;
	font-weight: $lato-semibold;
}

.discover-repoList-header-toggleGroup:hover
button.discover-repoList-header-toggleItem{
	opacity: 0.5;
}

.discover-repoList-header-toggleGroup:hover
button.discover-repoList-header-toggleItem:hover{
	opacity: 1;
}


.discover-repoList-header-spacer{
	flex-grow: 1;
	flex-shrink: 1;
}

.discover-repoList-header-packCount{
	font-size: 14px;	
	margin-top: 7px;
	cursor: default;
	margin-right: 26px;
	
	color: $textLight;
	font-weight: $lato-medium;
}