$colorBG-fullScreen-controls: rgba(40,41,45,0.95);

$bgColor-liveView: rgb(248,248,254);


.liveViewContainer{
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 2500;
	background: $bgColor-liveView;
	overflow: hidden;
}


.liveView-toolbar{
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;

	// background: $color-pageBG;
	// border-bottom: $border-navBar;
	// box-shadow: $shadow-navBar;
	display: flex;
	align-items: center;
	//z-index: 9000;
	z-index: 8000; //

}

.liveView-toolbar--dropdownOpen{
	z-index: 99000; // above media
}

.liveView:-webkit-full-screen .LiveView-BarControl--container{
	height: 100vh;
	width: 100vw;
	top: 0;
	position: fixed;
}


/// STYLE

.liveView-slide{
	position: absolute;
	box-sizing: border-box;
	z-index: 1000;
}

.liveView-slide .slide{
	background: $white;
	//border-radius: 4px;
	border: 1px solid $lineDark;
	box-shadow: 0px 2px 4px 0px rgba(0,0,10,0.08);
}


//
// CONTROLS
//

//OPACITY MANAGEMENT
// .liveView-controlBarContainer,

.liveView-reviewControls.liveView-reviewControls--hidden,
.liveView-toolbar.liveView-toolbar--nonEmpty.liveView-toolbar--invisible{
	opacity: 0;
	transition: all 500ms cubic-bezier(.2, .3, .25, .9);
}

// .liveView-controlBarContainer:hover,

.liveView-reviewControls.liveView-reviewControls--visible,
.liveView-toolbar.liveView-toolbar--nonEmpty.liveView-toolbar--visible{
	opacity: 1;
	transition: all 100ms cubic-bezier(.2, .3, .25, .9);
}

// SECTION DATA OWNER LABEL
.liveView-sectionDataOwnerLabel {
  max-width: 80px; // fits 6 upper case, 7 lower case letters
  display: flex;
  align-items: center;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  flex-shrink: 0;
}

.liveView-sectionDataOwnerLabel .icon {
  fill: $gray-11;
  width: 20px;
  height: 20px;
}

.liveView-sectionDataOwnerLabel-name {
  color: $textLight;
  margin-left: 2px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: inline;
}

@import "LiveView-Toolbar"; // Toolbar at top of modal
@import "LiveView-BlankState"; // Blank State when open Now Playing on web when nothing's playing

@import "LiveView-ToolModals"; // Card assignment and screen cover views
@import "LiveView-SideNavigationControls"; // Navigate forward and backward in set with big buttons
@import "LiveView-ControlBar"; // bar across bottom with slide navigator and other things

@import "LiveView-ScanProgressBar"; // Shows counts and bar when scanning
@import "LiveView-StudentList"; // Displays list of students while scanning on right side

@import "LiveView-NPModeSwitchDropdown"; // Switching between normal, hybrid, and e-learning

@import "LiveView-ReviewControls"; // Shows Reveal Answer and Show Graph buttons

@import "LiveView-PerfectScoreCelebration"; // Overlay when class gets 100% and showgraph and showcorrect

@import "LiveView-ZoomMedia"; // Zoom Media bits
