//
// SLIDE CHOICE LETTERBLOCK
//

.slide .slide-choice-letterblock{
  border: 1px solid rgb(213,213,217);
  border-radius: 4px;
  box-sizing: border-box;  
  background: rgb(252,252,255);
  //color: rgb(97, 97, 114); // now done via SVG fill
  user-select: none !important;  

  display: flex;
  align-items: center;
  justify-content: center;
  // position: relative;
}

// Letters ('A','B' etc.) is now SVG, to prevent any issues with ProseMirror

.slide .slide-choice-letterblock svg.editorIcon-letterBlock{
  width: 26px;
  height: 26px;
  fill: rgb(97, 97, 114);
}

.slide--editor .slide-choice-letterblock{
  cursor: pointer;

}

.slide--editor .slide-choice-letterblock:hover{
  transform: scale(1.05);
  transition: transform 75ms ease-in-out;  
}


//
// STATES

// Ask always has default state

.slide-choice-letterblock{
  border: 1px solid rgb(213,213,217); // default 
  background: rgb(252,252,255); // default
}

.slide-choice-letterblock svg {
  color: rgb(97, 97, 114); // default
}

// General Letterblock Correct State
.slide--static.slide--reportView .slide-choice.slide-choice--correct.slide-choice--graded .slide-choice-letterblock,
.slide--static .slide-choice.slide-choice--correct.slide-choice--graded.slide-choice--showCorrect .slide-choice-letterblock--isLetterblock{
  border-color: $green;
  background: transparentize($green, 0.98);  
}

.slide--static.slide--reportView .slide-choice.slide-choice--correct.slide-choice--graded .slide-choice-letterblock svg,
.slide--static .slide-choice.slide-choice--correct.slide-choice--graded.slide-choice--showCorrect .slide-choice-letterblock--isLetterblock svg{
  fill: $green-dark;
}

.slide--editor .slide-choice.slide-choice--correct.slide-choice--graded .slide-choice-letterblock,
.slide--editor .slide-choice.slide-choice--incorrect .slide-choice-letterblock:active{
  border-color: $green-dark;
  background: $green;  
}

.slide--editor .slide-choice.slide-choice--correct.slide-choice--graded .slide-choice-letterblock svg,
.slide--editor .slide-choice.slide-choice--incorrect .slide-choice-letterblock:active svg{  
  fill: $white;
}


//General Letterblock Incorrect States
//incorrect as gray
.slide--editor .slide-choice.slide-choice--incorrect .slide-choice-letterblock{
  border-color: $gray-11;
  background: $gray-15;
}

.slide--editor .slide-choice.slide-choice--incorrect .slide-choice-letterblock svg{
  fill: $gray-6;
}

//incorrect as red
.slide--static.slide--reportView .slide-choice.slide-choice--incorrect.slide-choice--graded .slide-choice-letterblock{
  border-color: $red;
  background: transparentize($red, 0.98);
}

.slide--static.slide--reportView .slide-choice.slide-choice--incorrect.slide-choice--graded .slide-choice-letterblock svg{
  fill: $red-dark;
}

// General Letterblock Survey State
// Always show as blue
.slide-choice.slide-choice--survey .slide-choice-letterblock{
  border-color: $blue;
  background: transparentize($blue, 0.98);
}

.slide-choice.slide-choice--survey .slide-choice-letterblock svg{  
  fill: $blue;
}

// General Letterblock Incomplete State
// This is where choices haven't been "touched" yet, so appear all grey
.slide--editor .slide-choices--incomplete .slide-choice.slide-choice--incorrect.slide-choice--graded  .slide-choice-letterblock,
.slide--editor .slide-choices--incomplete .slide-choice.slide-choice--correct.slide-choice--graded  .slide-choice-letterblock{
  border: 1px solid $gray-1225;
  background: rgb(252,252,255);
}

.slide--editor .slide-choices--incomplete .slide-choice.slide-choice--incorrect.slide-choice--graded  .slide-choice-letterblock svg,
.slide--editor .slide-choices--incomplete .slide-choice.slide-choice--correct.slide-choice--graded  .slide-choice-letterblock svg{
  fill: $textLight;
}

// Incomplete but focused
.slide--editor .slide-choices--incomplete .slide-choice.slide-choice--incorrect.slide-choice--graded.slide-choice--focused  .slide-choice-letterblock,
.slide--editor .slide-choices--incomplete .slide-choice.slide-choice--correct.slide-choice--graded.slide-choice--focused  .slide-choice-letterblock{
  border: 1px solid $gray-9;
  background: rgb(252,252,255);
}

.slide--editor .slide-choices--incomplete .slide-choice.slide-choice--incorrect.slide-choice--graded.slide-choice--focused  .slide-choice-letterblock svg,
.slide--editor .slide-choices--incomplete .slide-choice.slide-choice--correct.slide-choice--graded.slide-choice--focused  .slide-choice-letterblock svg{
  fill: $textDark;
}


//
// STATIC 

.slide .slide-choice-letterblock--static{
  border: 1px solid rgb(213,213,217);
  border-radius: 4px;
  box-sizing: border-box;
  font-weight: 800;
  font-size: $fontSize-letterblock;
  text-align: center;
  line-height: $lineHeight-letterblock;
  background: rgb(252,252,255);
  color: rgb(97, 97, 114);
}

//
// STATES

// Ask always has default state

.slide-choice.slide-choice--ask .slide-choice-letterblock--static{
  border: 1px solid rgb(213,213,217); // default  
  background: rgb(252,252,255); // default
  color: rgb(97, 97, 114); // default
}

// Choice Icons

.slide-choice .slide-choice-letterblock--static.slide-choice-letterblock--isIcon{
  border-radius: 50%;
}


.slide-choice.slide-choice--correct .slide-choice-letterblock--isIcon{
  background: $green;
  border: 1px solid $green;
}

.slide-choice.slide-choice--incorrect.slide-choice--scheme1 .slide-choice-letterblock--isIcon{
  background: $gray-12;
  border: 1px solid $gray-12;
}

.slide-choice.slide-choice--incorrect.slide-choice--scheme2 .slide-choice-letterblock--isIcon{
  background: $red;
  border: 1px solid $red;
}

.slide-choice .slide-choice-letterblock--isIcon svg{
  position: relative;
  margin-top: -1px;
  margin-left: -1px;
  width: $min-height-choice;
  height: $min-height-choice;  
}


.slide-choice.slide-choice--incorrect .slide-choice-letterblock--static.slide-choice-letterblock--isIcon svg path{
  fill: $white;
}

// General Letterblock Correct State
.slide-choice.slide-choice--correct.slide-choice--graded.slide-choice--showCorrect .slide-choice-letterblock--isLetterblock,
.slide.slide--reportView .slide-choice.slide-choice--correct.slide-choice--graded .slide-choice-letterblock--isLetterblock{
  border-color: $green;
  background: transparentize($green, 0.98);
}

.slide-choice.slide-choice--correct.slide-choice--graded.slide-choice--showCorrect .slide-choice-letterblock--isLetterblock svg,
.slide.slide--reportView .slide-choice.slide-choice--correct.slide-choice--graded .slide-choice-letterblock--isLetterblock svg{  
  fill: $green-dark;
}


// General Letterblock Incorrect States
.slide-choice.slide-choice--incorrect.slide-choice--scheme1.slide-choice--graded.slide-choice--showCorrect .slide-choice-letterblock--isLetterblock{
  border-color: $gray-11;
  background: $gray-15;
}
.slide-choice.slide-choice--incorrect.slide-choice--scheme1.slide-choice--graded.slide-choice--showCorrect .slide-choice-letterblock--isLetterblock svg{
  fill: $gray-6;
}

.slide-choice.slide-choice--incorrect.slide-choice--scheme2.slide-choice--graded.slide-choice--showCorrect .slide-choice-letterblock--isLetterblock,
.slide.slide--reportView .slide-choice.slide-choice--incorrect.slide-choice--graded .slide-choice-letterblock--isLetterblock {
  border-color: $red;
  background: transparentize($red, 0.98);
}

.slide-choice.slide-choice--incorrect.slide-choice--scheme2.slide-choice--graded.slide-choice--showCorrect .slide-choice-letterblock--isLetterblock svg,
.slide.slide--reportView .slide-choice.slide-choice--incorrect.slide-choice--graded .slide-choice-letterblock--isLetterblock svg {  
  fill: $red-dark;
}

// General Letterblock Survey State
// Always show as blue
.slide-choice.slide-choice--survey .slide-choice-letterblock--isLetterblock{
  border-color: $blue;
  background: transparentize($blue, 0.98);
}

.slide-choice.slide-choice--survey .slide-choice-letterblock--isLetterblock svg{
  fill: $blue;
}


//
// LIVE VIEW
// another choice media playing

.slide-choice.slide-choice--liveViewAnotherChoicePlaying .slide-choice-letterblock--isLetterblock{
  opacity: 0.15;
  transition: opacity 100ms linear;
}