
// //
// // ALSO CONTAINS EDIT SOUND MODAL STUFF

.editor-insertSoundMiniModal--quickRecordReview{
	height: 232px; // refactor
	display: flex;
	flex-direction: column;
	background: $white;
}

//
// TOP 

.editor-insertSoundMiniModal--quickRecordReview-top,
.editor-editSoundModal-top{
	height: 30px;
	width: 100%;
	flex-grow: 0;
	flex-shrink: 0;

	margin-top: -4px;

	// background: $tempr;

	display: flex;

	align-items: center;
}

.editor-insertSoundMiniModal--quickRecordReview-top--leftContainer,
.editor-insertSoundMiniModal--quickRecordReview-top--rightContainer,
.editor-editSoundModal-top--leftContainer,
.editor-editSoundModal-top--rightContainer{
	display: flex;
	width: 100px;
	flex-grow: 0;
	flex-shrink: 0;
}

.editor-insertSoundMiniModal--quickRecordReview-top--centerContainer,
.editor-editSoundModal-top--centerContainer{
	flex-grow: 1;
	flex-shrink: 1;

	display: flex;

	justify-content: center;
}

.editor-insertSoundMiniModal--quickRecordReview-top--rightContainer,
.editor-editSoundModal-top--rightContainer{
	justify-content: flex-end;
}

// Buttons

button.editor-insertSoundMiniModal--quickRecordReview-top-backBtn,
button.editor-insertSoundMiniModal--quickRecordReview-top-editBtn,
button.editor-insertSoundMiniModal--quickRecordReview-top-cancelBtn,
button.editor-editSoundModal-top-backBtn,
button.editor-editSoundModal-top-editBtn,
button.editor-editSoundModal-top-cancelBtn{
	height: 24px;

	display: flex;
	align-items: center;
	justify-content: center;

	background: $gray-14;
	border: none;
	box-shadow: none;
	border-radius: 4px;

	cursor: pointer;
}


button.editor-insertSoundMiniModal--quickRecordReview-top-backBtn,
button.editor-editSoundModal-top-backBtn{
	width: 28px;
}
button.editor-insertSoundMiniModal--quickRecordReview-top-editBtn,
button.editor-editSoundModal-top-editBtn{
	width: 40px;
}

button.editor-insertSoundMiniModal--quickRecordReview-top-backBtn svg,
button.editor-insertSoundMiniModal--quickRecordReview-top-editBtn svg,
button.editor-editSoundModal-top-backBtn svg,
button.editor-editSoundModal-top-editBtn svg{
	fill: $gray-6;
}

button.editor-insertSoundMiniModal--quickRecordReview-top-backBtn svg,
button.editor-editSoundModal-top-backBtn svg{
	height: 12px;
	width: 12px;

	position: relative;
	left: -1px;
}

button.editor-insertSoundMiniModal--quickRecordReview-top-editBtn svg,
button.editor-editSoundModal-top-editBtn svg{
	height: 16px;
	width: 16px;
}


button.editor-insertSoundMiniModal--quickRecordReview-top-backBtn,
button.editor-editSoundModal-top-backBtn{
	margin-right: 4px;
}

button.editor-insertSoundMiniModal--quickRecordReview-top-cancelBtn,
button.editor-editSoundModal-top-cancelBtn{
	font-size: 13px;
	font-weight: $lato-semibold;
	color: $textDark;

	letter-spacing: 0.01em;
	padding-left: 9px;
	padding-right: 9px;
}

//

button.editor-insertSoundMiniModal--quickRecordReview-top-backBtn:hover,
button.editor-insertSoundMiniModal--quickRecordReview-top-editBtn:hover,
button.editor-editSoundModal-top-backBtn:hover,
button.editor-editSoundModal-top-editBtn:hover{
	background: transparentize($blue, 0.95);
}

button.editor-insertSoundMiniModal--quickRecordReview-top-backBtn:hover svg,
button.editor-insertSoundMiniModal--quickRecordReview-top-editBtn:hover svg,
button.editor-editSoundModal-top-backBtn:hover svg,
button.editor-editSoundModal-top-editBtn:hover svg{
	fill: $blue;
}

button.editor-insertSoundMiniModal--quickRecordReview-top-cancelBtn:hover,
button.editor-editSoundModal-top-cancelBtn:hover{
	background: transparentize($red, 0.95);
	color: darken($red, 5%);
}

//

.editor-insertSoundMiniModal--quickRecordReview-top-timestamp,
.editor-editSoundModal-top-timestamp{
	font-size: 15px;
	font-weight: $lato-semibold;
	color: $textBlack;

	cursor: default;
	user-select: none;
}

.editor-insertSoundMiniModal--quickRecordReview-top-timestamp--current,
.editor-insertSoundMiniModal--quickRecordReview-top-timestamp--total,
.editor-editSoundModal-top-timestamp--current,
.editor-editSoundModal-top-timestamp--total{
	width: 44px;
	text-align: center;
}

.editor-insertSoundMiniModal--quickRecordReview-top-timestamp--current,
.editor-editSoundModal-top-timestamp--current{
	text-align: right;
}

.editor-insertSoundMiniModal--quickRecordReview-top-timestamp--total,
.editor-editSoundModal-top-timestamp--total{
	text-align: left;
}

.editor-insertSoundMiniModal--quickRecordReview-top-timestamp--dash,
.editor-editSoundModal-top-timestamp--dash{
	padding-left: 4px;
	padding-right: 3px;
}

.editor-insertSoundMiniModal--quickRecordReview-top-timestamp--dash,
.editor-insertSoundMiniModal--quickRecordReview-top-timestamp--total,
.editor-editSoundModal-top-timestamp--dash,
.editor-editSoundModal-top-timestamp--total{
	opacity: 0.75;
}



//

.editor-insertSoundMiniModal--quickRecordReview-player{
	flex-grow: 1;
	flex-shrink: 1;
	min-height: 10px;
	width: 100%;

	// background: $tempg;

	position: relative;
}

//
button.editor-insertSoundMiniModal--quickRecordReview-player-player{
	width: 82px;
	height: 82px;

	position: absolute;
	margin: auto;
  top: 18px;
  left: 0;
  right: 0;

  z-index: 5;

  border-radius: 50%;

  background: transparentize($blue, 0.8);

  display: flex;
  align-items: center;
  justify-content: center;

  border: none;
  box-shadow: none;

  cursor: pointer;

  


}

button.editor-insertSoundMiniModal--quickRecordReview-player-player svg{
	width: 28px;

	fill: $blue;
}

//

.editor-insertSoundMiniModal--quickRecordReview-player-progressBarContainer{
	width: 90px;
	height: 90px;

	margin: auto;
  position: absolute;
  top: 14px;
  left: 0;
  right: 0;

}

.editor-insertSoundMiniModal--quickRecordReview-player-progressBarContainer .CircularProgressbar .CircularProgressbar-path{
	stroke: $blue;
	transition: none;
}

.editor-insertSoundMiniModal--quickRecordReview-player-progressBarContainer .CircularProgressbar .CircularProgressbar-trail{
	stroke: transparentize($blue, 0.95);
	transition: none;
}	


.editor-insertSoundMiniModal--quickRecordReview-player-tip{
	// formatting is in parent with other tips
	bottom: 3px;	
	opacity: 0.8;
}



//
// BUTTON

.editor-insertSoundMiniModal--quickRecordReview-submitBtnContainer,
.editor-editSoundModal-submitBtnContainer{
	height: 60px;
	flex-grow: 0;
	flex-shrink: 0;

	width: 100%;

	display: flex;
	align-items: center;
	justify-content: center;
}

button.editor-insertSoundMiniModal--quickRecordReview-submitBtn,
button.editor-editSoundModal-submitBtn{
	height: 44px;
	width: 260px;

	border-radius: 6px;

	background: $blue;
	border: none;
	box-shadow: none;

	cursor: pointer;
}

button.editor-editSoundModal-submitBtn{
	width: 350px;
}

.editor-insertSoundMiniModal--quickRecordReview-submitBtn-primary,
.editor-editSoundModal-submitBtn-primary{
	font-size: 14.5px;
	font-weight: $lato-bold;
	color: $white;
	letter-spacing: 0.01em;
	margin-top: 2px;
	margin-bottom: -1px;
}

.editor-insertSoundMiniModal--quickRecordReview-submitBtn-sub,
.editor-editSoundModal-submitBtn-sub{
	font-size: 10px;
	font-weight: $lato-medium;
	text-transform: uppercase;
	color: $white;
	letter-spacing: 0.01em;
	opacity: 0.75;

}

button.editor-editSoundModal-submitBtn:disabled{
	background: $gray-8;
}

button.editor-editSoundModal-submitBtn:disabled
.editor-editSoundModal-submitBtn-sub{
	display: none;
}

button.editor-editSoundModal-submitBtn:disabled
.editor-editSoundModal-submitBtn-primary{
	margin-bottom: 0px;
}