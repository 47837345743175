//
// SCORESHEET REVIEW MODAL: QUESTION
//

.scoreSheet-reviewModal-header{
	display: flex;
	width: 100%;
	height: 54px;

	align-items: center;
	color: $textLight;

	user-select: none;
	cursor: default;
	line-height: 1.5;
}

.scoreSheet-reviewModal-header .sectionMarker{
	font-size: 17px;
	
	font-weight: $lato-semibold;
	color: $textBlack;
	margin-right: 10px;

	white-space: nowrap; 
  overflow: hidden;
  text-overflow: ellipsis;

  max-width: 450px; //unfortunate legacy support for super-long names
}

.scoreSheet-reviewModal-header .sectionMarker .icon{
	width: 12px;
	height: 12px;
	margin-right: 9px;
}

.scoreSheet-reviewModal-header .btn.btn--mid{
	
	margin-left: auto;
}

// Score pill

.scoreSheet-reviewModal-questionSlideContainer{
	width: 816px;
	height: 510px;
	border: 1px solid $lineDark;
	background: $white;
	border-radius: 3px;
	box-shadow: 0px 3px 6px 0px rgba(0,0,20,0.08);

	overflow: hidden;
	position: relative;
}


.scoreSheet-reviewModal-questionSlideContainer .scorePill{
	transform: scale(1.25);
	transform-origin: right center;
	position: absolute;
	top: 12px;
	right: 10px;
	z-index: 100;
	user-select: none;
}

.scoreSheet-reviewModal-questionSlideContainer .slide{
	border: none;
	box-shadow: none;
}


/// NAV BUTTON


.scoreSheet-reviewModalContainer-navButton{
	height: 400px;
	width: 80px;
	
	margin: 8px;
	display: flex;
	align-items: center;
	justify-content: center;
	
	border-radius: 0px;
	opacity: 0.4;
	cursor: pointer;

	background: rgba(0, 0, 10, 0.2);

	border-radius: 5px;

	position: relative;
	z-index: 5;
}


.scoreSheet-reviewModalContainer-navButton:hover{
	opacity: 0.75;
	transition: opacity 100ms ease-in-out;
}

.scoreSheet-reviewModalContainer-navButton:active{
	opacity: 1;
	transition: opacity 50ms ease-in-out;
}


.scoreSheet-reviewModalContainer-navButton .icon{
	width: 32px;
	height: 32px;
	fill: $white;
	transition: scale 100ms ease-in-out;
}


.scoreSheet-reviewModalContainer-navButton:active .icon{
	transform: scale(1.025s);
	transition: scale 50ms ease-in-out;
}


