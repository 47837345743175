//
// SIGN IN AND SIGN UP FORMS
// Some of the styling is very sophisticated for field states
// This is very mature code and has been fine for years
// But it's not very extensible or clear (sorry)
//

form.signin-form,
form.signup-form{
  width: 100%;
  position: relative;

  display: flex;
  flex-direction: column;
  position: relative;
}

//

$height-signinsignup-form-field: 38px;

.signin-form-field,
.signup-form-field{
  position: relative;
}

input.signin-form-field-input,
input.signup-form-field-input {
  width: 100%;
  height: $height-signinsignup-form-field;
  line-height: $height-signinsignup-form-field;

  background: $white;

  font-size: 16px;
  border-radius: 3px;
  font-weight: $effra-medium;
  color: $textBlackDark;

  padding-left: 14px;
  box-sizing: border-box;
}

//
// SIGN IN SPECIFIC

.signin-form-field--email input.signin-form-field-input{
  width: 100%;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  margin-bottom: -1px; // so borders don't overlap

  position: relative;
  z-index: 5;
}

.signin-form-field--password input.signin-form-field-input{
  width: 100%;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;

  position: relative;
  z-index: 1;
}

// make sure when focused blue border ontop
.signin-form-field--password input.signin-form-field-input:focus{
  z-index: 10;
}

//
// FORGOT PASSWORD LINK

.signin-form-forgotPasswordContainer {
  width: 100%;
}

.signin-form-forgotPasswordLink,
.verifyPasswordToSignin-form-forgotPasswordLink {
  margin: 0 auto 5px auto;

  width: 175px;

  background: none;
  height: 36px;
  border-radius: 5px;

  font-size: 14px;
  font-weight: $effra-medium;

  color: $blue-light;

  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;
}

.signin-form-forgotPasswordLink:hover,
.signin-form-forgotPasswordLink:focus-within,
.verifyPasswordToSignin-form-forgotPasswordLink:hover,
.verifyPasswordToSignin-form-forgotPasswordLink:focus-within {
  outline: none;
  color: $textBlue;
  text-decoration: underline;
}


//
// SIGN UP SPECIFIC
.signup-form-field--email input.signup-form-field-input,
.signup-form-field--password input.signup-form-field-input {
  width: 100%;
  margin-bottom: 7px;
}

//
// PLACEHOLDER STYLING
// Slightly fancy - keeping Firefox and IE rules simpler

// WEBKIT

input.signin-form-field-input::-webkit-input-placeholder,
input.signup-form-field-input::-webkit-input-placeholder {
  color: transparentize($textLight, 0.2);
}

.signin-form-field:hover input.signin-form-field-input::-webkit-input-placeholder,
.signup-form-field:hover input.signup-form-field-input::-webkit-input-placeholder {
  color: transparentize($textLight, 0.4);
}

.signin-form-field input.signin-form-field-input:focus::-webkit-input-placeholder,
.signin-form-field:hover input.signin-form-field-input:focus::-webkit-input-placeholder,
.signup-form-field input.signup-form-field-input:focus::-webkit-input-placeholder,
.signup-form-field:hover input.signup-form-field-input:focus::-webkit-input-placeholder{
  color: transparentize($textLight, 0.6);
}

// MOZILLA

input.signin-form-field-input::-moz-placeholder,
input.signup-form-field-input::-moz-placeholder {
  color: $textLight;
}

// INTERNET EXPLORER

input.signin-form-field-input:-ms-input-placcreholder,
input.signup-form-field-input:-ms-input-placeholder {
  color: $textLight !important;
}

//
// STATES
//


// EMPTY (LIGHT)
.signin-form-field--empty input.signin-form-field-input,
.signup-form-field--empty input.signup-form-field-input{
  border: 1px solid $gray-128;
  background: rgba(255,255,255,0.85);
}

// EMPTY :HOVER
.signin-form-field--empty:hover input.signin-form-field-input,
.signup-form-field--empty:hover input.signup-form-field-input{
  border: 1px solid $gray-125;
  background: rgba(255,255,255,0.85);
}

// NONEMPTY (DARK)
.signin-form-field--nonEmpty input.signin-form-field-input,
.signup-form-field--nonEmpty input.signup-form-field-input{
  border: 1px solid $gray-125;
  background: rgba(255,255,255,1);
}

// NONEMPTY (DARK):HOVER
.signin-form-field--nonEmpty:hover input.signin-form-field-input,
.signup-form-field--nonEmpty:hover input.signup-form-field-input{
  border: 1px solid $gray-12;
  background: rgba(255,255,255,1);
}

// FOCUS (ALWAYS BLUE)
.signin-form-field--empty input.signin-form-field-input:focus,
.signin-form-field--nonEmpty input.signin-form-field-input:focus,
.signup-form-field--empty input.signup-form-field-input:focus,
.signup-form-field--nonEmpty input.signup-form-field-input:focus{
  outline: none;
  border: 1px solid $blue-light;
  background: rgba(255,255,255,1);
}

// HAS WARNING
.signin-form-field--hasWarning input.signin-form-field-input,
.signup-form-field--hasWarning input.signup-form-field-input{
  border-color: 1px solid rgb(255,169,64);
  background: rgb(255,241,223);
  color: rgb(234,152,15);
}

// HAS ERROR

$bgColor-signup-form-field--error: rgb(255,241,223);
$borderColor-signup-form-field--error: rgb(255,169,64);
$textColor-signup-form-field--error: rgb(234,152,15);


.signup-form-field--nonEmpty.signup-form-field--hasError
input.signup-form-field-input,
.signup-form-field--empty.signup-form-field--hasError
input.signup-form-field-input{
  border: 1px solid $borderColor-signup-form-field--error;
  //background: $bgColor-signup-form-field--error;
  background: $white;
  color: $textColor-signup-form-field--error;
}

.signup-form-field--nonEmpty.signup-form-field--hasError
input.signup-form-field-input::-webkit-input-placeholder,
.signup-form-field--empty.signup-form-field--hasError
input.signup-form-field-input::-webkit-input-placeholder{
  color: transparentize($textColor-signup-form-field--error, 0.5);
}

.signup-form-field--nonEmpty.signup-form-field--hasError
input.signup-form-field-input:focus,
.signup-form-field--empty.signup-form-field--hasError
input.signup-form-field-input:focus{
  background: $white;
  border-color: $blue;
}

.signup-form-field--nonEmpty.signup-form-field--hasError
input.signup-form-field-input:focus::-webkit-input-placeholder,
.signup-form-field--empty.signup-form-field--hasError
input.signup-form-field-input:focus::-webkit-input-placeholder{
    color: transparentize($textColor-signup-form-field--error, 0.7);
}

// FIELD ERROR ALERT ICON

.signup-form-field-errorIcon{
  position: absolute;
  height: $height-signinsignup-form-field;
  right: 0px;
  top: 0px;
  z-index: 5;
  width: $height-signinsignup-form-field;

  display: flex;
  align-items: center;
  justify-content: center;
}

.signup-form-field-errorIcon .icon{
  width: 16px;
  height: 16px;
  fill: $borderColor-signup-form-field--error;
  opacity: 0.75;
}

// FIELD ERROR ALERT TEXT

.signup-form-field-errorText{

  color: $textColor-signup-form-field--error;

  padding-left: 8px;
  padding-top: 3px;
  padding-bottom: 1px;

  font-size: 12px;
  font-weight: $effra-regular;

}


.signup-form-field--email .signup-form-field-errorText,
.signup-form-field--password .signup-form-field-errorText{
  margin-top: -7px; //offset the bottom margin
  margin-bottom: 7px;
}

// RECAPTCHA ERROR

.signin-centerBlock-form--disabled,
.signup-centerBlock-form--disabled,
.forgotPassword-centerBlock-form--disabled {
  opacity: 0.4;
  pointer-events: none;
}

.signin-centerBlock-error--recaptcha,
.signup-centerBlock-error--recaptcha,
.forgotPassword-centerBlock-error--recaptcha {
  margin-top: 20px;
}

.forgotPassword-centerBlock-error--recaptcha {
  padding: 0px 30px;
}

.signin-centerBlock-error--recaptcha div,
.signup-centerBlock-error--recaptcha div,
.forgotPassword-centerBlock-error--recaptcha div {
  margin-top: 5px;
  margin-bottom: 5px;
}

// FORM ERROR ALERT TEXT

.signup-form-error{

  position: absolute;
  bottom: -50px;
  //height: 40px;
  left: 0px;
  right: 0px;

  //background: $tempb;

  color: $red;

  // padding-left: 8px;
  // padding-top: 3px;
  // padding-bottom: 1px;
  text-align: center;

  font-size: 15px;
  font-weight: $effra-medium;

  display: flex;
  align-items: center;
  justify-content: center;

}


// SHOW HIDE PASSWORD

.signin-form-field--password-showPassword,
.signup-form-field--password-showPassword{
  width: 70px;
  height: $height-signinsignup-form-field;

  position: absolute;
  top: 0px;
  right: 0px;

  cursor: pointer;
  z-index: 15;
  transition: opacity 100ms linear;

  font-size: 12px;
  text-transform: uppercase;

  font-weight: $effra-medium;
  line-height: 1.5;
  letter-spacing: 0.005em;

  display: flex;
  align-items: center;
  justify-content: center;

  color: transparentize($textLight, 0.3);
}

//when error

.signin-form-field--password.signin-form-field--hasError
.signin-form-field--password-showPassword,
.signup-form-field--password.signup-form-field--hasError
.signup-form-field--password-showPassword{
  width: 110px;
  color: $textColor-signup-form-field--error
}

.signin-form-field--password-showPassword:hover,
.signup-form-field--password-showPassword:hover{
  color: $textBlue;
}

// HIDE WHEN EMPTY
.signin-form-field--password.signin-form-field--empty
.signin-form-field--password-showPassword,
.signup-form-field--password.signup-form-field--empty
.signup-form-field--password-showPassword{
  opacity: 0;
}

// SHOW WHEN NON EMPTY
.signin-form-field--password.signin-form-field--nonEmpty
.signin-form-field--password-showPassword,
.signup-form-field--password.signup-form-field--nonEmpty
.signup-form-field--password-showPassword{
  opacity: 1;
}

// ALWAYS SHOW WHEN FOCUSED
input.signin-form-field-input:focus ~
.signin-form-field--password-showPassword,
input.signup-form-field-input:focus ~
.signup-form-field--password-showPassword{
  opacity: 1;
}


// SIGN UP - NAME CONTAINER LAYOUT

.signup-form-nameContainer{
  width: 100%;
  display: flex;
  margin-bottom: 7px;
}

.signup-form-field--firstName{
  width: calc(50% - 7px);
  margin-right: 7px;
  flex-grow: 0;
  flex-shrink: 0;
}

.signup-form-field--lastName{
  width: calc(50%);
  flex-grow: 0;
  flex-shrink: 0;
}


// RESET PASSWORD
.reset-password-form-note{
  margin-top: 20px;
  text-align: center;
  font-size: 14px;
  font-weight: $effra-medium;
  color: $textMid;
}
