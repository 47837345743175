.fontSizeCalculationVisualizerContainer{
	background: rgb(250,250,255);
	height: 10000px;
	overflow-y: scroll;
}

.fontSizeCalculationVisualizer-slideContainer{
	position: absolute;
	width: 640px;
	height: 400px;

	top: 200px;
	left: 50px;

	background: $white;
}

.fontSizeCalculationVisualizer-slideContainer .slide{
	transform-origin: 0% 0%;
}

.fontSizeCalculationVisualizer-templateSelectPanel{
	position: absolute;
	top: 0px;
	left: 0px;
	right: 0px;
	height: 60px;
}

@import 'FontSizeCalculationVisualizer-TemplateSelectPanel';


.fontSizeCalculationVisualizer-fieldLengthSelectPanel{
	position: absolute;
	top: 60px;
	left: 0px;
	right: 0px;
	height: 100px;

	background: $white;
	border-bottom: 1px solid $gray-8;
	box-shadow: 0px 2px 4px 0px rgba(0,0,20,0.08);
}

@import 'FontSizeCalculationVisualizer-FieldLengthSelectPanel';

// HEIGHTS TABLE

.fontSizeCalculationVisualizer-calculatedHeightsTable{
	position: absolute;
	top: 200px;
	right: 40px;
	height: 300px;

	background: $white;

	font-size: 13.5px;
	display: flex;

	border: 1px solid red;
}

.fontSizeCalculationVisualizer-calculatedHeightsTable-column{
	border-left: 1px solid red;
	width: 60px;
}

.fontSizeCalculationVisualizer-calculatedHeightsTable-column:first-child{
	border-left: none;
}

.fontSizeCalculationVisualizer-calculatedHeightsTable-column:nth-child(4){
	margin-right: 20px;
	border-right: 1px solid red;
}

.fontSizeCalculationVisualizer-calculatedHeightsTable-row{
	border-bottom: 1px solid red;
	padding: 2px;
	background: rgb(255,235,235);
}

.fontSizeCalculationVisualizer-calculatedHeightsTable-row--invalid{
  color: $textLight
}

.fontSizeCalculationVisualizer-calculatedHeightsTable-row:first-child{
	font-weight: $lato-bold;
}

.fontSizeCalculationVisualizer-calculatedHeightsTable-column:nth-child(1) .fontSizeCalculationVisualizer-calculatedHeightsTable-row:last-child,
.fontSizeCalculationVisualizer-calculatedHeightsTable-column:nth-child(2) .fontSizeCalculationVisualizer-calculatedHeightsTable-row:last-child{
	border-bottom: none;
}

//

.fontSizeCalculationVisualizer-outcomesGrid{
	position: absolute;
	top: 800px;
	left: 50%;
	transform: translateX(-50%);
	width: 750px;
	height: 400px;

	background: $tempb;

	font-size: 13px;
	background: $white;

	display: flex;
	border: 2px solid $blue;
}

.fontSizeCalculationVisualizer-outcomesGrid-column{
	width: 50px;
	border-right: 1px solid blue;
}

.fontSizeCalculationVisualizer-outcomesGrid-row{
	height: 25px;
	border-bottom: 1px solid blue;
}

.fontSizeCalculationVisualizer-outcomesGrid-row--comboTooBig{
	background: $tempr;
}

.fontSizeCalculationVisualizer-outcomesGrid-row--perfectCombo{
	background: $tempg;
}

.fontSizeCalculationVisualizer-outcomesGrid-row--perfectCombo--choiceBiggerThanBody{
	opacity: 0.25;
}



//
// move this somewhere else
.fontSizeCalculator-ghostArrays--hidden{
	visibility: hidden;
}
