
//
// CONTROL GROUP
//

.featureModal-selectControlGroup{	
	margin-bottom: 16px;
	width: 320px;
}


.featureModal-selectControlGroup-title{	
	font-size: 13px;
	color: $textMid;
	font-weight: $lato-bold;
	letter-spacing: 0.02em;
	text-transform: uppercase;
	margin-bottom: 8px;
	cursor: default;
}




// CONTROLS


// CONTROL

.featureModal-selectControl{
	min-height: 24px;
	display: flex;
	align-items: top;
}

.featureModal-selectControl-label{
	font-size: 15px;
	font-weight: $lato-bold;
	color: $textDark;
	letter-spacing: 0.015em;
	cursor: default;
	user-select: none;
}

.featureModal-selectControl-label-sub{
	font-size: 13px;
	font-weight: $lato-semibold;
	color: $textLight;
	margin-top: 2px;
	letter-spacing: 0em;
}


// SUB CONTROL
.featureModal-selectControl--subControl{	
	height: 21px;
	min-height: 21px;
	margin-left: 25px;
}

.featureModal-selectControl--subControl
.featureModal-selectControl-label{
	font-size: 14px;
	font-weight: $lato-bold;
	letter-spacing: 0.01em;
}

.featureModal-selectControl--subControl
.featureModal-selectControl-checkbox{
	margin-top: 0px;
	margin-right: 6px;
}


.featureModal-selectControl-spacer{
	width: 8px;
	height: 8px;
}

// CHECK BOX 

.featureModal-selectControl-checkbox{
	margin-top: 2px;
	height: 14px;
	width: 14px;
	cursor: pointer;
	margin-right: 10px;
	border-radius: 2px;
	position: relative;
	flex-shrink: 0;
	flex-grow: 0;
	box-sizing: border-box;
}


.featureModal-selectControl--active
.featureModal-selectControl-checkbox{
	border: 1px solid darken($blue, 10%);
	background: $blue;
}

.featureModal-selectControl--active
.featureModal-selectControl-checkbox svg{
	position: absolute;
	left: 1px;
	top: 1px;
	width: 10px;
	height: 10px;
}

.featureModal-selectControl-checkbox svg #border{
	fill: darken($blue, 10%);
}

.featureModal-selectControl-checkbox svg #fill{
	fill: $white;
}

.featureModal-selectControl--inactive
.featureModal-selectControl-checkbox{
	border: 1px solid $lineDark;
	background: $white;
}



// RADIO

.featureModal-selectControl-radio{
	height: 16px;
	width: 16px;
	border-radius: 50%;
	cursor: pointer;
	margin-right: 6px;
	flex-shrink: 0;
	flex-grow: 0;
	box-sizing: border-box;
}


.featureModal-selectControl--active
.featureModal-selectControl-radio{
	border: 1px solid darken($blue, 10%);
	background: $blue;

	display: flex;
	align-items: center;
	justify-content: center;
	box-sizing: border-box;
}

.featureModal-selectControl--active
.featureModal-selectControl-radio
.featureModal-selectControl-radio-activeCircle{
	width: 8px;
	height: 8px;
	border-radius: 50%;
	background: $white;
	border: 1px solid darken($blue, 10%);
	box-sizing: border-box;
}

.featureModal-selectControl--inactive
.featureModal-selectControl-radio{
	border: 1px solid $lineDark;
	background: $white;
	box-sizing: border-box;
}




// NOT CHECKABLE THINGS

.featureModal-selectControl--uncheckable{
	pointer-events: none;
	opacity: 0.75;
}

.featureModal-selectControl--uncheckable.featureModal-selectControl--active
.featureModal-selectControl-checkbox{
	border: 1px solid $gray-8;
	background: $gray-8;
}

.featureModal-selectControl--uncheckable.featureModal-selectControl--active
.featureModal-selectControl-checkbox svg #border{
	fill: 1px solid $gray-8;
}

.featureModal-selectControl--uncheckable.featureModal-selectControl--active
.featureModal-selectControl-radio{
	border: 1px solid darken($gray-8, 10%);
	background: $gray-8;
}

.featureModal-selectControl--uncheckable.featureModal-selectControl--active
.featureModal-selectControl-radio-activeCircle{	
	border: 1px solid $gray-8;
}



//
// TEXT INPUT
// 

input.featureModal-selectControl-textInput{
	margin-top: 5px;
	width: 300px;
	height: 28px;
	font-size: 14px;
	padding-left: 6px;
	border-radius: 2px;
	padding-bottom: 2px;
	line-height: 1.6;
	box-shadow: none;
	border: none;
	box-sizing: border-box;
	border: 1px solid $lineMid;
	color: $textDark;
	outline: none;
	font-weight: $lato-semibold;
}

input.featureModal-selectControl-textInput::-webkit-input-placeholder{
	color: transparentize($textLight, 0.3);
}

input.featureModal-selectControl-textInput::-moz-placeholder{
	color: transparentize($textLight, 0.3);
}

input.featureModal-selectControl-textInput:-ms-input-placeholder{
	color: transparentize($textLight, 0.3);
}



input.featureModal-selectControl-textInput:hover{
	border: 1px solid darken($lineMid, 0.2);
}

input.featureModal-selectControl-textInput:hover::-webkit-input-placeholder{
	color: transparentize($textLight, 0.5);
}

input.featureModal-selectControl-textInput:hover::-moz-placeholder{
	color: transparentize($textLight, 0.5);
}

input.featureModal-selectControl-textInput:hover:-ms-input-placeholder{
	color: transparentize($textLight, 0.5);
}



input.featureModal-selectControl-textInput:focus::-webkit-input-placeholder{
	color: transparentize($textLight, 1);
}


input.featureModal-selectControl-textInput:focus::-moz-placeholder{
	color: transparentize($textLight, 1);
}


input.featureModal-selectControl-textInput:focus:-ms-input-placeholder{
	color: transparentize($textLight, 1);
}



input.featureModal-selectControl-textInput:focus{
	border: 1px solid $blue;
	box-shadow: none;
	outline: none;
}