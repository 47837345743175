.delete-account-modal{
  padding: 40px;
  min-height: 200px;
}

.delete-account-modal-step{
  text-align: center;
  padding-bottom: 20px;
  font-weight: $lato-bold;
  color: $textMid;
}

.delete-account-modal-description,
.delete-account-modal-warning{
  font-size: 14px;
  color: $textMid;
  text-align: center;
}

.delete-account-modal-description div{
  padding-top: 3px;
}

.delete-account-modal-description span{
  color: $textBlack;
  font-weight: $lato-semibold;
}

.delete-account-modal-warning{
  margin: 40px auto;
}

.delete-account-modal-warning span{
  color: $textBlack;
}

.delete-account-modal button.btn {
  margin: auto;
  width: 170px;
  cursor: pointer;
}

.delete-account-modal .accountForm-error {
  margin-top: 30px;
  font-size: 14px;
  text-align: center;
}
