
.sectionSettings-form{
	width: 60%;
	box-sizing: border-box;	
	margin-top: -40px;
}

.sectionSettings-actionRow{
	margin-top: 75px;
	display: flex;
}

.sectionSettings-actionRow .btn{
	margin-right: 12px;
}

.sectionSettings{
	position: relative;
}

.sectionSettings button.btn--mid.btn--white.archive{
	position: relative;
	top: -83px; //little bit hacky sorry
	left: calc(100% + 55px);
}