.syncLiveView-setReportContainer{
	width: 100%;
	background: $tempg;
}

.syncLiveView-setReport{
	width: 860px;
	margin: 0 auto;
	padding-bottom: 120px;
}

.syncLiveView-setReport-studentSummary{
	width: 100%;
	margin-top: 24px;
}

.syncLiveView-setReport-questionSummary{
	width: 100%;
	margin-top: 24px;

	cursor: default;
}

.syncLiveView-setReport-questionSummary * {
	pointer-events: none;
}

.syncLiveView-setReport-questionSummary .slideGrid-gridItem-expandLabel{
	display: none; // temp until work out what click should do
}

//

.syncLiveView-setReport-sectionHeader{
	font-weight: $lato-bold;
	color: $textLight;
	font-size: 14px;
	text-transform: uppercase;
	letter-spacing: 0.01em;

	margin-bottom: 12px;

	display: flex;
	align-items: center;

	cursor: default;
}

// STUDENT SORT TOGGLE

.syncLiveView-setReport-studentSortToggle{
	cursor: pointer;

	font-size: 13px;
	font-weight: $lato-semibold;
	color: $textLight;
	letter-spacing: 0.02em;
	text-transform: uppercase;

	display: flex;
	align-items: center;

	margin-left: auto;

	user-select: none;

}

.syncLiveView-setReport-studentSortToggle-toggle{
	padding-left: 5px;
	padding-right: 5px;
}

.syncLiveView-setReport-studentSortToggle-toggle--active{
	color: $textBlue;
}


.syncLiveView-setReport-studentSortToggle-toggle:hover{
	color: $textMid;
}

.syncLiveView-setReport-studentSortToggle-toggle--active:hover{
	color: darken($textBlue, 5%);
}

//
// STUDENT LIST
//

.syncLiveView-setReport-studentListContainer{
	margin-top: 12px;
	padding-bottom: 24px;
	// border-bottom: 1px solid $lineMid;
}

.syncLiveView-setReport-studentList{
	column-count: 5;
  column-gap: 28px;
	width: 100%;
	overflow: hidden;	
}


.syncLiveView-setReport-studentList-blockLine{
	// blocks top border of top in each column
	position: relative;
	z-index: 5;
	width: 100%;
	border-bottom: 2px solid rgb(250,250,255); // refactor color
	margin: 0 auto;
	margin-bottom: -1px;
}


//
// Student Cell
//

.syncLiveView-setReport-studentList-studentCell{
	user-select: none; 
	cursor: default;

 	display: inline-block;
 	vertical-align: top;

 	break-inside: avoid-column;
 	-webkit-column-break-inside: avoid;

	width: 100%;

	height: 27px;
	border-top: 1px solid $lineMid;
	
	position: relative;

	display: flex;
	align-items: center;
}



.syncLiveView-setReport-studentList-studentCell-name{
	line-height: 1.9;
  	
	font-weight: $lato-semibold;
	color: $textBlack;
	font-size: 14px;

	width: 10px;
	flex-grow: 1;

	white-space: nowrap;
	overflow: hidden;

	white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}


.syncLiveView-setReport-studentList-studentCell .scorePill{
	flex-grow: 0;
}

//
// No responses yet handling

.syncLiveView-setReport-studentList-studentCell-score-noGradedResponsesDash{
	color: lighten($textMid, 5%);
	font-weight: $lato-medium;
	margin-right: 6px;
}

.syncLiveView-setReport-studentList-studentCell--offline
.syncLiveView-setReport-studentList-studentCell-name,
.syncLiveView-setReport-studentList-studentCell--offline
.syncLiveView-setReport-studentList-studentCell-score{
	opacity: 0.35;
}



// .syncLiveView-setReport-studentList-studentCell--noGradedResponses:hover
// .syncLiveView-setReport-studentList-studentCell-name,
// .syncLiveView-setReport-studentList-studentCell--noGradedResponses:hover
// .syncLiveView-setReport-studentList-studentCell-score{
// 	opacity: 1;
// }