.liveViewNotificationContainer{
	position: fixed;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	background: rgba(0,0,0,0.75);
	z-index: 9999;

	display: flex;
	align-items: center;
	justify-content: center;
}

.liveViewNotification{
	// width: $width-liveViewAlert;
	// height: $height-liveViewAlert;
	border: 1px solid $lineDark;

	border-radius: 5px;
	box-sizing: border-box;

	background: $gray-15;

	box-shadow: 0px 20px 50px 0px rgba(0,0,0,0.25), 0px 0px 5px 0px rgba(0,0,0,0.0625);

	cursor: default;
	user-select: none;

	box-sizing: border-box;
	padding-top: 20px;
	padding-bottom: 32px;
	padding-left: 35px;
	padding-right: 35px;
}

// LIVE INDICATOR
// SAME AS LIVE VIEW TOOLBAR


.liveViewNotification-live{
	margin: 0 auto;

	margin-bottom: 8px;

	width: 48px;
	height: 24px;
	flex-grow: 0;

	color: $white;
	font-weight: $lato-bold;
	font-size: 15px;
	letter-spacing: 0.05em;
	text-transform: uppercase;	
	position: relative;
	line-height: 1.5;
	text-align: center;

	cursor: default;
	user-select: none;
}

.liveViewNotification-live .sectionMarker{
	position: absolute;
	top: 0px;
	left: 0px;
	bottom: 0px;
	right: 0px;
	border-radius: 3px;	
}

///



.liveViewNotification-sectionName{
	margin-bottom: 16px;

	font-size: 22px;
	font-weight: $lato-heavy;
	color: $textBlack;
	line-height: 1.4;
	text-align: center;

	max-width: 352px; //magic number, refactor

	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}


.liveViewNotification-slidePreview{
	width: 350px;
	height: 218.75px;
	margin-bottom: 18px;

	border-radius: 3px;
	overflow: hidden;
	position: relative;
	cursor: pointer;

	background: $white;
	border: 1px solid rgba(0,0,30,0.1);
	border-bottom: 1px solid rgba(0,0,30,0.15);

	transition: transform 300ms cubic-bezier(0.2, 0.3, 0.25, 0.9);

	box-shadow: 0px 1px 2px 0px rgba(0,0,0,0.04),
							0px 5px 10px -5px rgba(0,0,0,0.1);
}

.liveViewNotification-slidePreview:hover{
	transform: scale(1.025);
	transition: all 150ms cubic-bezier(0.2, 0.3, 0.25, 0.9);

	box-shadow: 0px 1px 2px 0px rgba(0,0,0,0.06),
							0px 5px 10px -5px rgba(0,0,0,0.12);
}

//

.liveViewNotification-slidePreview-countDown{
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);

	width: 120px;
	height: 120px;
	border-radius: 50%;

	background: rgba(40, 41, 45, 0.95);

	display: flex;
	align-items: center;
	justify-content: center;

	font-size: 64px;
	font-weight: $lato-heavy;
	color: $white;
}
//

.liveViewNotification-slidePreview-container-spinnerContainer{
	position: absolute;
	
	display: flex;
	align-items: center;
	justify-content: center;
	top: -8px;
	bottom: 0;
	left: -8px;
	right: 0;
}

.liveViewNotification-slidePreview-container-spinnerContainer{

	height: 120px;
	width: 120px;
  
  border-radius: 50%;

  border: 8px rgba(220,220,228,0.95) solid;
  border-top: 8px $blue-light solid;

  opacity: 0.75;

  animation: spinnerRotateAnimation 0.9s infinite linear;
}


//
.liveViewNotification-actionRow{
	width: 100%;
	box-sizing: border-box;
	display: flex;
	align-items: center;
	justify-content: center;
}

.liveViewNotification-actionRow .btn.btn--mid{
	margin-left: 5px;
	margin-right: 5px;
	min-width: 150px;
	width: 150px;
	cursor: pointer;
}