.profilePicContainer{
	position: relative;
	overflow: hidden;

	// fixes for overflow not clipping because of transform
	-webkit-backface-visibility: hidden; 
 	-moz-backface-visibility: hidden;
 	-webkit-transform: translate3d(0, 0, 0);
 	-moz-transform: translate3d(0, 0, 0);
}

.profilePic--fallback,
.profilePic--pendingEmailOnly{
	overflow: hidden;
	font-size: 125px;
	font-weight: $lato-semibold;
	color: $white;
	width: 100%;
	height: 100%;
	transform-origin: 0% 0%;

	display: flex;
	align-items: center;
	justify-content: center;

	background: $gray-5; // fallback for fallback

	user-select: none;
	cursor: default;
}

.profilePic--fallback-initialsContainer{
	width: 90%;
	display: flex;
	justify-content: center;
	text-transform: uppercase;
}

.profilePicContainer--hasImage{
	display: flex;
	box-sizing: border-box;
}

.profilePicContainer--hasImage-imgBorder{
	position: absolute;
	top: 0; left: 0; right: 0; bottom: 0;
	z-index: 5;
	box-sizing: border-box;
	border: 1px solid rgba(0,0,20,0.05);
}

.profilePicContainer--hasImage img{
	width: 100%;
	height: 100%;
}

.profilePic--fallback-initial{
	flex-shrink: 0;
}

.profilePic--fallback-spacer{
	flex-shrink: 1;
	flex-grow: 1;
	max-width: 20px;
}

.profilePicContainer .profilePic--fallback--blue		{ background: $classColor-blue; }
.profilePicContainer .profilePic--fallback--purple	{ background: $classColor-purple; }
.profilePicContainer .profilePic--fallback--red			{ background: $classColor-red; }
.profilePicContainer .profilePic--fallback--orange	{ background: $classColor-orange; }
.profilePicContainer .profilePic--fallback--green		{ background: $classColor-green; }
.profilePicContainer .profilePic--fallback--sky			{ background: $classColor-sky; }
.profilePicContainer .profilePic--fallback--pink		{ background: $classColor-pink; }
.profilePicContainer .profilePic--fallback--teal		{ background: $classColor-teal; }
.profilePicContainer .profilePic--fallback--grey		{ background: $classColor-grey; }

//

.profilePic--pendingEmailOnly{
	background: $blue-extraLight;
}

.profilePic--pendingEmailOnly .icon{
	fill: $blue;
	height: 156px;
	width: 156px;
	opacity: 0.75;
}
