
//
// STUDENT CELL
//

.syncLiveView-player-students-studentCell{
	width: 130px;
	max-width: 130px;
	height: 31px;
	background: $white;
	margin-left: 3px;
	margin-bottom: 3px;
	box-sizing: border-box;
	// padding-left: 2px;

	display: flex;
	align-items: center;

	border-radius: 3px;

	cursor: default;

	border: 1px solid darken($lineMid, 4%);
	border-bottom: 1px solid darken($lineMid, 5%);

	position: relative;

	flex-grow: 1;
	flex-shrink: 1;
	
	white-space: nowrap;
  overflow: hidden;

  opacity: 1;
  transition: opacity 100ms linear;
}

// TO DO 
// .syncLiveView-player-students--over32Students
// .syncLiveView-player-students-studentCell{
// 	width: 105px;
// 	margin-bottom: 4px;
// 	margin-left: 4px;
// 	height: 21px;
// }


//
// OFFLINE

.syncLiveView-player-students-studentCell--offline{
	opacity: 0.5;
	border: 1px solid $invisible;
	border-bottom: 1px solid $invisible;
	transition: opacity 100ms linear;
	background: none;
	// background: rgba(0,0,20,0.02);
}

.syncLiveView-player-students-studentCell--offline.syncLiveView-player-students-studentCell--hasResponse{
	opacity: 0.6;
}

.syncLiveView-player-students-studentCell-offlineMarkerContainer{
	height: 100%;
	width: 21px;
	display: flex;
	align-items: center;
	justify-content: flex-end;
	margin-right: -1px;
}

.syncLiveView-player-students-studentCell-offlineMarker{
	width: 10px;
	height: 10px;
	margin-right: 1px;
	border-radius: 10px;
	border: 2px solid $lineMid;
	background: none;
}

// Offline but with response

.syncLiveView-player-students-studentCell--hasResponse.syncLiveView-player-students-studentCell--isCorrect.syncLiveView-player-students-studentCell--isGraded
.syncLiveView-player-students-studentCell-offlineMarker{
	border-color: $color-o85;
}

.syncLiveView-player-students-studentCell--hasResponse.syncLiveView-player-students-studentCell--isIncorrect.syncLiveView-player-students-studentCell--isGraded
.syncLiveView-player-students-studentCell-offlineMarker{
	border-color: $color-u60;
}

.syncLiveView-player-students-studentCell--hasResponse.syncLiveView-player-students-studentCell--isSurvey
.syncLiveView-player-students-studentCell-offlineMarker{
	border-color: $blue-bold;
}

//
// ONLINE


.syncLiveView-player-students-studentCell-onlineMarkerContainer{
	position: absolute;
	left: 0px;
	bottom: 3px;

	display: flex;
	align-items: center;
	justify-content: space-around;
}

.syncLiveView-player-students-studentCell--isScanOrReview
.syncLiveView-player-students-studentCell-onlineMarkerContainer{
	left: 24px;
}

.syncLiveView-player-students-studentCell--isAsk
.syncLiveView-player-students-studentCell-onlineMarkerContainer{
	left: 7px;
}

.syncLiveView-player-students-studentCell-onlineMarker{
	height: 2px;
	width: 10px;

	border-radius: 0px;
	background: $green;
	margin-right: 1px;

	opacity: 0;
	transition: opacity 400ms linear;
}

.syncLiveView-player-students-studentCell-onlineMarkerContainer--show
.syncLiveView-player-students-studentCell-onlineMarker{
	animation: syncOnlineMarkerAnimate;
	animation-duration: 1000ms;
	animation-iteration-count: 1;
	animation-fill-mode: forward;
	// animation-delay: 500ms;
	// transition: opacity 100ms linear;
}

.syncLiveView-player-students-studentCell-onlineMarkerContainer--show
.syncLiveView-player-students-studentCell-onlineMarker:nth-child(1){
	animation-delay: 200ms;
}

.syncLiveView-player-students-studentCell-onlineMarkerContainer--show
.syncLiveView-player-students-studentCell-onlineMarker:nth-child(2){
	animation-delay: 250ms;
}

.syncLiveView-player-students-studentCell-onlineMarkerContainer--show
.syncLiveView-player-students-studentCell-onlineMarker:nth-child(3){
	animation-delay: 300ms;
}


@keyframes syncOnlineMarkerAnimate {
	0% {
		opacity: 0;		
	}
	25% {
		opacity: 0.8;				
	}
	50% {
		opacity: 0.8;				
	}
	100% {
		opacity: 0;				
	}
}






//
// RESPONSE MARKER
// Online Only


.syncLiveView-player-students-studentCell-responseMarker{
	width: 16px;
	height: 16px;
	margin-left: 4px;
	border-radius: 4px;
	// background: $gray-12 !important;
	display: flex;
	align-items: center;
	justify-content: center;

	flex-shrink: 0;
	flex-grow: 0;

	position: relative;
	z-index: 5;
}

.syncLiveView-player-students-studentCell-responseMarker-inner{
	width: 8px;
	height: 8px;
	border-radius: 4px;
}


// HAS RESPONSE

.syncLiveView-player-students-studentCell--hasResponse.syncLiveView-player-students-studentCell--isScan{
	opacity: 0.75;
	transition: opacity 100ms linear;
}

// CORRECT

.syncLiveView-player-students-studentCell--isCorrect.syncLiveView-player-students-studentCell--isGraded.syncLiveView-player-students-studentCell--isScanOrReview
.syncLiveView-player-students-studentCell-responseMarker{
	background: transparentize($color-o85, 0.87);
}

.syncLiveView-player-students-studentCell--isCorrect.syncLiveView-player-students-studentCell--isGraded.syncLiveView-player-students-studentCell--isScanOrReview
.syncLiveView-player-students-studentCell-responseMarker-inner{
	background: $color-o85;
}

// INCORRECT

.syncLiveView-player-students-studentCell--isIncorrect.syncLiveView-player-students-studentCell--isGraded.syncLiveView-player-students-studentCell--isScanOrReview
.syncLiveView-player-students-studentCell-responseMarker{
	background: transparentize($color-u60, 0.87);
}

.syncLiveView-player-students-studentCell--isIncorrect.syncLiveView-player-students-studentCell--isGraded.syncLiveView-player-students-studentCell--isScanOrReview
.syncLiveView-player-students-studentCell-responseMarker-inner{
	background: $color-u60;
}

// SURVEY

.syncLiveView-player-students-studentCell--hasResponse.syncLiveView-player-students-studentCell--isSurvey.syncLiveView-player-students-studentCell--isScanOrReview
.syncLiveView-player-students-studentCell-responseMarker{
	background: transparentize($blue-bold, 0.87);
}

.syncLiveView-player-students-studentCell--hasResponse.syncLiveView-player-students-studentCell--isSurvey.syncLiveView-player-students-studentCell--isScanOrReview
.syncLiveView-player-students-studentCell-responseMarker-inner{
	background: $blue-bold;
}

// WAITING FOR RESPONSE

.syncLiveView-player-students-studentCell--noResponse.syncLiveView-player-students-studentCell--isReview{	
	opacity: 0.5;
	transition: opacity 100ms linear;
}

.syncLiveView-player-students-studentCell--noResponse.syncLiveView-player-students-studentCell--isScanOrReview
.syncLiveView-player-students-studentCell-responseMarker{
	background: $gray-13;	
}

.syncLiveView-player-students-studentCell--noResponse.syncLiveView-player-students-studentCell--isScanOrReview
.syncLiveView-player-students-studentCell-responseMarker-inner{
	background: $white;
	background: none;
	
	position: relative;
	z-index: 5;
}


//

.syncLiveView-player-students-studentCell-name{
	font-size: 14px;
	//font-weight: $lato-semibold;
	font-weight: $lato-bold;
	color: $textBlack;
	letter-spacing: 0.024em;	

	user-select: none;
	z-index: 5;

	position: absolute;
	left: 6px;
	top: 7px;
}

.syncLiveView-player-students-studentCell--isScanOrReview
.syncLiveView-player-students-studentCell-name{
	left: 24px;
}


// 
// STATES
//

//
// RESPONSE LETTERS

.syncLiveView-player-students-studentCell-responseLetter{
	position: absolute;
	right: 3px;
	top: 50%;
	transform: translateY(-50%);
	
	z-index: 50;

	width: 22px;
	height: 22px;
	border-radius: 2px;
	
	display: flex;
	align-items: center;
	justify-content: center;

	font-size: 14px;
	font-weight: $lato-heavy;
	letter-spacing: 0.01em;	

	opacity: 0;
	transition: opacity 400ms linear;
}


.syncLiveView-player-students-studentCell-responseLetter--surveyResponse{
	color: $blue-bold;
	background: lighten($blue, 45%);
}

.syncLiveView-player-students-studentCell-responseLetter--incorrectResponse{
	color: $red;
	background: lighten($red, 30%);
}

.syncLiveView-player-students-studentCell-responseLetter--correctResponse{
	color: $color-o85;
	background: lighten($green, 45%);
}

.syncLiveView-player-students:hover
.syncLiveView-player-students-studentCell-responseLetter--surveyResponse,
.syncLiveView-player-students:hover
.syncLiveView-player-students-studentCell-responseLetter--incorrectResponse{
	opacity: 1;
	transition: opacity 75ms linear;
}


.syncLiveView-player-students-studentCell--choiceHoverResponded
.syncLiveView-player-students-studentCell-responseLetter{
	opacity: 1;	
	transition: opacity 0ms linear;
}




//
// CROSS HOVER EFFECT

.syncLiveView-player-students-studentCell.syncLiveView-player-students-studentCell--choiceHoverNotResponded{
	opacity: 0.1;
}


//
// RESPONSE RIPPLE

.syncLiveView-player-students-studentCell::after {
  content: "";
  position: absolute;
  top: 2px;
  left: 2px;
  right: 2px;
  bottom: 2px;

  border-radius: 2px;
  opacity: 0;
}

.syncLiveView-player-students-studentCell.syncLiveView-player-students-studentCell--isCorrect::after{
	background: $color-o85;
}

.syncLiveView-player-students-studentCell.syncLiveView-player-students-studentCell--isIncorrect::after{
	background: $color-u60;
}

.syncLiveView-player-students-studentCell.syncLiveView-player-students-studentCell--isSurvey::after{
	background: $blue;
}


/* Active */
.syncLiveView-player-students-studentCell.syncLiveView-player-students-studentCell--hasResponse::after{       
  animation: syncLiveStudentResponsePulse;
  animation-duration: 1000ms;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}



@keyframes syncLiveStudentResponsePulse {
	0% {
		opacity: 0;		
	}
	20% {
		opacity: 0.06;		
	}
	100% {
		opacity: 0;		
	}
}



//
// STUDENT CELL
//

.syncLiveView-player-students-studentCell{
	width: 130px;
	max-width: 130px;
	height: 31px;
	background: $white;
	margin-left: 3px;
	margin-bottom: 3px;
	box-sizing: border-box;
	// padding-left: 2px;

	display: flex;
	align-items: center;

	border-radius: 3px;

	cursor: default;

	border: 1px solid darken($lineMid, 4%);
	border-bottom: 1px solid darken($lineMid, 5%);

	position: relative;

	flex-grow: 1;
	flex-shrink: 1;
	
	white-space: nowrap;
  overflow: hidden;

  opacity: 1;
  transition: opacity 100ms linear;
}

// TO DO 
// .syncLiveView-player-students--over32Students
// .syncLiveView-player-students-studentCell{
// 	width: 105px;
// 	margin-bottom: 4px;
// 	margin-left: 4px;
// 	height: 21px;
// }


//
// OFFLINE

.syncLiveView-player-students-studentCell--offline{
	opacity: 0.5;
	border: 1px solid $invisible;
	border-bottom: 1px solid $invisible;
	transition: opacity 100ms linear;
	background: none;
	// background: rgba(0,0,20,0.02);
}

.syncLiveView-player-students-studentCell-offlineMarkerContainer{
	height: 100%;
	width: 21px;
	display: flex;
	align-items: center;
	justify-content: flex-end;
	margin-right: -1px;
}

.syncLiveView-player-students-studentCell-offlineMarker{
	width: 10px;
	height: 10px;
	margin-right: 1px;
	border-radius: 10px;
	border: 2px solid $lineMid;
	background: none;
}

//
// ONLINE


.syncLiveView-player-students-studentCell-onlineMarkerContainer{
	position: absolute;
	left: 0px;
	bottom: 3px;

	display: flex;
	align-items: center;
	justify-content: space-around;
}

.syncLiveView-player-students-studentCell--isScanOrReview
.syncLiveView-player-students-studentCell-onlineMarkerContainer{
	left: 24px;
}

.syncLiveView-player-students-studentCell--isAsk
.syncLiveView-player-students-studentCell-onlineMarkerContainer{
	left: 7px;
}

.syncLiveView-player-students-studentCell-onlineMarker{
	height: 2px;
	width: 10px;

	border-radius: 0px;
	background: $green;
	margin-right: 1px;

	opacity: 0;
	transition: opacity 400ms linear;
}

.syncLiveView-player-students-studentCell-onlineMarkerContainer--show
.syncLiveView-player-students-studentCell-onlineMarker{
	animation: syncOnlineMarkerAnimate;
	animation-duration: 1000ms;
	animation-iteration-count: 1;
	animation-fill-mode: forward;
	// animation-delay: 500ms;
	// transition: opacity 100ms linear;
}

.syncLiveView-player-students-studentCell-onlineMarkerContainer--show
.syncLiveView-player-students-studentCell-onlineMarker:nth-child(1){
	animation-delay: 200ms;
}

.syncLiveView-player-students-studentCell-onlineMarkerContainer--show
.syncLiveView-player-students-studentCell-onlineMarker:nth-child(2){
	animation-delay: 250ms;
}

.syncLiveView-player-students-studentCell-onlineMarkerContainer--show
.syncLiveView-player-students-studentCell-onlineMarker:nth-child(3){
	animation-delay: 300ms;
}


@keyframes syncOnlineMarkerAnimate {
	0% {
		opacity: 0;		
	}
	25% {
		opacity: 0.8;				
	}
	50% {
		opacity: 0.8;				
	}
	100% {
		opacity: 0;				
	}
}






//
// RESPONSE MARKER
// Online Only


.syncLiveView-player-students-studentCell-responseMarker{
	width: 16px;
	height: 16px;
	margin-left: 4px;
	border-radius: 4px;
	// background: $gray-12 !important;
	display: flex;
	align-items: center;
	justify-content: center;

	flex-shrink: 0;
	flex-grow: 0;

	position: relative;
	z-index: 5;
}

.syncLiveView-player-students-studentCell-responseMarker-inner{
	width: 8px;
	height: 8px;
	border-radius: 4px;
}


// HAS RESPONSE

.syncLiveView-player-students-studentCell--hasResponse.syncLiveView-player-students-studentCell--isScan{
	opacity: 0.75;
	transition: opacity 100ms linear;
}

// CORRECT

.syncLiveView-player-students-studentCell--isCorrect.syncLiveView-player-students-studentCell--isGraded.syncLiveView-player-students-studentCell--isScanOrReview
.syncLiveView-player-students-studentCell-responseMarker{
	background: transparentize($color-o85, 0.87);
}

.syncLiveView-player-students-studentCell--isCorrect.syncLiveView-player-students-studentCell--isGraded.syncLiveView-player-students-studentCell--isScanOrReview
.syncLiveView-player-students-studentCell-responseMarker-inner{
	background: $color-o85;
}

// INCORRECT

.syncLiveView-player-students-studentCell--isIncorrect.syncLiveView-player-students-studentCell--isGraded.syncLiveView-player-students-studentCell--isScanOrReview
.syncLiveView-player-students-studentCell-responseMarker{
	background: transparentize($color-u60, 0.87);
}

.syncLiveView-player-students-studentCell--isIncorrect.syncLiveView-player-students-studentCell--isGraded.syncLiveView-player-students-studentCell--isScanOrReview
.syncLiveView-player-students-studentCell-responseMarker-inner{
	background: $color-u60;
}

// SURVEY

.syncLiveView-player-students-studentCell--hasResponse.syncLiveView-player-students-studentCell--isSurvey.syncLiveView-player-students-studentCell--isScanOrReview
.syncLiveView-player-students-studentCell-responseMarker{
	background: transparentize($blue-bold, 0.87);
}

.syncLiveView-player-students-studentCell--hasResponse.syncLiveView-player-students-studentCell--isSurvey.syncLiveView-player-students-studentCell--isScanOrReview
.syncLiveView-player-students-studentCell-responseMarker-inner{
	background: $blue-bold;
}

// WAITING FOR RESPONSE

.syncLiveView-player-students-studentCell--noResponse.syncLiveView-player-students-studentCell--isReview{	
	opacity: 0.5;
	transition: opacity 100ms linear;
}

.syncLiveView-player-students-studentCell--noResponse.syncLiveView-player-students-studentCell--isScanOrReview
.syncLiveView-player-students-studentCell-responseMarker{
	background: $gray-13;	
}

.syncLiveView-player-students-studentCell--noResponse.syncLiveView-player-students-studentCell--isScanOrReview
.syncLiveView-player-students-studentCell-responseMarker-inner{
	background: $white;
	background: none;
	
	position: relative;
	z-index: 5;
}


//

.syncLiveView-player-students-studentCell-name{
	font-size: 14px;
	//font-weight: $lato-semibold;
	font-weight: $lato-bold;
	color: $textBlack;
	letter-spacing: 0.024em;	

	user-select: none;
	z-index: 5;

	position: absolute;
	left: 6px;
	top: 7px;
}

.syncLiveView-player-students-studentCell--isScanOrReview
.syncLiveView-player-students-studentCell-name{
	left: 24px;
}


// 
// STATES
//









//
// RESPONSE LETTERS

.syncLiveView-player-students-studentCell-responseLetter{
	position: absolute;
	right: 3px;
	top: 50%;
	transform: translateY(-50%);
	
	z-index: 50;

	width: 22px;
	height: 22px;
	border-radius: 2px;
	
	display: flex;
	align-items: center;
	justify-content: center;

	font-size: 14px;
	font-weight: $lato-heavy;
	letter-spacing: 0.01em;	

	opacity: 0;
	transition: opacity 400ms linear;
}


.syncLiveView-player-students-studentCell-responseLetter--surveyResponse{
	color: $blue-bold;
	background: lighten($blue, 45%);
}

.syncLiveView-player-students-studentCell-responseLetter--incorrectResponse{
	color: $red;
	background: lighten($red, 30%);
}

.syncLiveView-player-students-studentCell-responseLetter--correctResponse{
	color: $color-o85;
	background: lighten($green, 45%);
}

.syncLiveView-player-students:hover
.syncLiveView-player-students-studentCell--online
.syncLiveView-player-students-studentCell-responseLetter--surveyResponse,
.syncLiveView-player-students:hover
.syncLiveView-player-students-studentCell--online
.syncLiveView-player-students-studentCell-responseLetter--incorrectResponse{
	opacity: 1;
	transition: opacity 75ms linear;
}


.syncLiveView-player-students-studentCell--choiceHoverResponded
.syncLiveView-player-students-studentCell-responseLetter{
	opacity: 1;	
	transition: opacity 0ms linear;
}




//
// CROSS HOVER EFFECT

.syncLiveView-player-students-studentCell.syncLiveView-player-students-studentCell--choiceHoverNotResponded{
	opacity: 0.1;
}


//
// RESPONSE RIPPLE

.syncLiveView-player-students-studentCell::after {
  content: "";
  position: absolute;
  top: 2px;
  left: 2px;
  right: 2px;
  bottom: 2px;

  border-radius: 2px;
  opacity: 0;
}

.syncLiveView-player-students-studentCell.syncLiveView-player-students-studentCell--isCorrect::after{
	background: $color-o85;
}

.syncLiveView-player-students-studentCell.syncLiveView-player-students-studentCell--isIncorrect::after{
	background: $color-u60;
}

.syncLiveView-player-students-studentCell.syncLiveView-player-students-studentCell--isSurvey::after{
	background: $blue;
}


/* Active */
.syncLiveView-player-students-studentCell.syncLiveView-player-students-studentCell--hasResponse::after{       
  animation: syncLiveStudentResponsePulse;
  animation-duration: 1000ms;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}



@keyframes syncLiveStudentResponsePulse {
	0% {
		opacity: 0;		
	}
	20% {
		opacity: 0.06;		
	}
	100% {
		opacity: 0;		
	}
}


