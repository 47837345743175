//
// SUBMIT LOADING
// submit loading is when you spinner on a button after you click it 
//

// Per-component styling to get appropriate size and width of spinner,
// Also colors are customized per component
// Also speed of animation are customized per component (depending on UX)
// However the core simple spinner animation imported is here

@keyframes spinnerRotateAnimation {
  from { transform: rotate(0deg); }
  to { transform: rotate(359deg); }
}