.upgrade--landing-featureSection-unlimitedScoresheet-demoContainer{
  position: absolute;
  width: 1000px;
  height: 346px;
  bottom: 0px;
  left: 50%;
  transform: translateX(-50%);
  
  z-index: 10;
  overflow: hidden;
}

.upgrade--landing-featureSection-unlimitedScoresheet-demoBG{
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  background: $white;
  border: 1px solid $lineDark;
  border-bottom: 0px;

  border-top-right-radius: 11px;
  border-top-left-radius: 11px;
}

.upgrade--landing-featureSection-unlimitedScoresheet-demoBG-screen{
  position: absolute;
  top: 20px;
  bottom: 0px;
  width: 960px;
  left: 50%;
  transform: translateX(-50%);
  background: $gray-2;
  border: 1px solid $lineDark;
  border-bottom: 0px;
  border-top-right-radius: 2px;
  border-top-left-radius: 2px;
}

.upgrade--landing-featureSection-unlimitedScoresheet-demoBG-screen-image{
  width: 960px;
  height: 326px;
}