.addStudentBar{
	height: 30px;
	position: relative;
}

.addStudentBar input { 
	height: 100%;
	width: 100%;
	box-sizing: border-box;
	
	padding-left: 29px;
	border-radius: 3px;
	//padding-bottom: 4px;
	box-shadow: none;
	
	font-size: 15px;
	font-weight: $lato-semibold;
	color: $textBlack;

	background: $red;
}

.addStudentBar .addStudentBar-extras{
	width: 100%;
}

.addStudentBar-addBtn{
	// visibility: hidden;
	cursor: default;
	position: absolute;
	right: 1px;
	top: 1px;
	
	height: 28px;
	width: 80px;
	
	border-radius: 2px;
	
	font-size: 14px;
	font-weight: $lato-semibold;
	line-height: 2;
	text-transform: uppercase;
	text-align: center;
	
	background: $gray-14;
	color: $textMid;
} 

.addStudentBar-addBtn:hover{
	background: $blue;
	color: $white;
}


.addStudentBar .plus{
	position: absolute;
	left: 10px;
	top: 9px;
	height: 12px;
	width: 12px;
	pointer-events: none;
} 


// 
// STATES
// 

// STANDARD
.addStudentBar input {
	border: 1px solid $invisible;
	background: $bg-filterBar;
}

.addStudentBar input::-webkit-input-placeholder{
	color: $textLight;
	font-weight: $lato-bold;
}

.addStudentBar .plus{	
	fill: $gray-1125;
} 

.addStudentBar-addBtn{
	visibility: hidden;
}


// HOVER
.addStudentBar input:hover {
	border: 1px solid $gray-125;
	background: $gray-15;
}

.addStudentBar input:hover ~ .addStudentBar-extras .plus{	
	fill: $blue;
} 

// FOCUSED

.addStudentBar input:focus{
	box-shadow: none;
	outline: none;
	border: 1px solid $gray-12;
	background: $white;
}

.addStudentBar input:focus::-webkit-input-placeholder{
	color: $textLight;
	opacity: 0.75;
}

.addStudentBar input:focus ~ .addStudentBar-extras .plus {
	fill: $blue;
}

.addStudentBar input:focus ~ .addStudentBar-extras .addStudentBar-addBtn {
	visibility: hidden;
}

// FOCUSED NON-EMPTY

.addStudentBar.is--nonEmpty input:focus { 
	border: 1px solid $gray-10;
}


.addStudentBar.is--nonEmpty input:focus ~ .addStudentBar-extras .plus {
	fill: $gray-1125;
}

// NON-FOCUSED NON-EMPTY 

.addStudentBar.is--nonEmpty input { 
	border: 1px solid $gray-1225;
	background: $white;
}

.addStudentBar.is--nonEmpty input ~ .addStudentBar-extras .plus {
	fill: $gray-1125;
}

.addStudentBar.is--nonEmpty input ~ .addStudentBar-extras .addStudentBar-addBtn {
	visibility: visible;
}


// DISABLED FOR ISBE
.addStudentBar input:disabled, 
.addStudentBar input:disabled ~ .addStudentBar-extras .plus {
	opacity: 0;
	pointer-events: none;
} 




