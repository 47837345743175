
////
//// REVIEW
//// 


//
// IMAGE INPUT
//

.publishingFlowModalPage--review-contentContainer{
	display: flex;
	margin-top: 25px;
}

.publishingFlowModalPage--review-packImageContainer{
	background: $blue-extraLight;
	overflow: hidden;
	margin-top: 5px;
	border-radius: 4px;

	width: 196px;
	height: 196px;
	margin-right: 16px;

	flex-grow: 0;
	flex-shrink: 0;

	position: relative;
}


.publishingFlowModalPage--review-packImageContainer img{
	width: 100%;
	height: 100%;
}


button.publishingFlowModalPage--review-packImageContainer-editBtn{
	position: absolute;
	top: 8px;
	right: 8px;

	font-size: 13px;

	background: rgba(0,0,40,0.65);
	border: none;
	color: $white;

	padding-left: 8px;
	padding-right: 8px;
	padding-top: 4px;
	padding-bottom: 5px;

	border-radius: 4px;

	font-weight: $lato-semibold;
	cursor: pointer;

	opacity: 0;
}



.publishingFlowModalPage--review-packImageContainer:hover
button.publishingFlowModalPage--review-packImageContainer-editBtn{
	opacity: 1;
}


//
// NAME AND DESCRIPTION INPUT
//

.publishingFlowModalPage--review-packInfo-textAreaContainer{
	position: relative;
	margin-bottom: 2px;

	margin-left: -1px; // account for border in textarea
	
}

.publishingFlowModalPage--review-packInfo-textAreaGhost{
	padding-bottom: 10px; //firefox bug
}

.publishingFlowModalPage--review-packInfo-textAreaGhost,
.publishingFlowModalPage--review-packInfo-textAreaContainer textarea{
	
	padding-left: 10px;
	padding-right: 10px;
	
	background: $invisible;
	
	word-wrap: break-word;
	width: 550px;
	box-sizing: border-box;

	
}


// NAME FONT SIZE
.publishingFlowModalPage--review-packInfo-textAreaContainer--name
.publishingFlowModalPage--review-packInfo-textAreaGhost,
.publishingFlowModalPage--review-packInfo-textAreaContainer--name textarea{
	font-size: 18px;
	font-weight: $lato-heavy;
	
}

.publishingFlowModalPage--review-packInfo-textAreaContainer--name{
	min-height: 36px;
}

// DESCRIPTION FONT SIZE
.publishingFlowModalPage--review-packInfo-textAreaContainer--description
.publishingFlowModalPage--review-packInfo-textAreaGhost,
.publishingFlowModalPage--review-packInfo-textAreaContainer--description textarea{
	font-size: 14px;
  font-weight: $lato-medium;
}

.publishingFlowModalPage--review-packInfo-textAreaContainer--description{
	margin-top: -3px; // closer to label
	max-height: 120px;
	overflow: hidden;
}

.publishingFlowModalPage--review-packInfo-textAreaContainer--description textarea{
	max-height: 120px;
	overflow-y: scroll;
}


.publishingFlowModalPage--review-packInfo-textAreaContainer--description
.publishingFlowModalPage--review-packInfo-textAreaGhost{
	min-height: 50px;
}

.publishingFlowModalPage--review-packInfo-textAreaContainer--description textarea:focus ~
.publishingFlowModalPage--review-packInfo-textAreaGhost{
	min-height: 94px;
}

.publishingFlowModalPage--review-packInfo-textAreaGhost{	
	color: $invisible;
	pointer-events: none;
	min-height: 32px;

	padding-top: 4px;
	padding-right: 15px;

	position: relative;

}

.publishingFlowModalPage--review-packInfo-textAreaContainer textarea{
	
	background: none;

	outline: none;
	box-shadow: none;
	border: none;
	

	position: absolute;
	height: 100%;
	width: 100%;
	top: 0px;
	left: 0px;

	z-index: 5;

	resize: none;


	border: 1px solid $invisible;

	padding-top: 6px;

	border-radius: 4px;
	
	color: $textBlack;

	overflow: hidden;
}


.publishingFlowModalPage--review-packInfo-textAreaContainer--description textarea{
	padding-top: 4px;
	border-radius: 3px;
}

.publishingFlowModalPage--review-packInfo-textAreaContainer textarea:hover{
	background: rgba(0,0,40,0.04);
	border: 1px solid $invisible;
}

.publishingFlowModalPage--review-packInfo-textAreaContainer textarea:focus{
	border: 1px solid $textBlue;
	background: $white;
}

.publishingFlowModalPage--review-packInfo-textAreaContainer textarea::webkit-placeholder{
	font-weight: $lato-bold;
	color: $textMid;
}

.publishingFlowModalPage--review-packInfo-textAreaContainer--description textarea::webkit-placeholder{
	color: $textInputPlaceholder !important;
}


.publishingFlowModalPage--review-packInfo-field-title--description{
	padding-left: 10px;
	padding-top: 8px;
}

// Character count indicators

.publishingFlowModalPage--review-packInfo-textAreaCounter{
	position: absolute;
	
	height: 30px;
	
	// background: $tempr;
	text-align: right;
	font-size: 13px;
	color: $textLight;
	font-weight: $lato-medium;

	opacity: 0; // only show if text area is in focus and close to limit
	user-select: none;
	cursor: default;
	pointer-events: none;
}


// show character count if in focus and over a certain size
.publishingFlowModalPage--review-packInfo-textAreaContainer textarea:focus ~
.publishingFlowModalPage--review-packInfo-textAreaGhost
.publishingFlowModalPage--review-packInfo-textAreaCounter--show{
	opacity: 1;
}

.publishingFlowModalPage--review-packInfo-textAreaCounter--max{
	font-weight: $lato-bold;
	color: $textBlack;
}

// tweak positioning for each

.publishingFlowModalPage--review-packInfo-textAreaContainer--name
.publishingFlowModalPage--review-packInfo-textAreaCounter{
	top: 9px;
	right: -51px;
	width: 55px;
}

.publishingFlowModalPage--review-packInfo-textAreaContainer--description
.publishingFlowModalPage--review-packInfo-textAreaCounter{
	top: 5px;
	right: -66px;
	width: 65px;
}


//

//
// Buttons for Subject/Grade Level/Language Edit
//


button.publishingFlowModalPage--review-packInfo-field{
	display: block;
	padding-left: 10px;
	padding-top: 8px;
	padding-bottom: 8px;
	width: 300px;
	border-radius: 4px;
}

button.publishingFlowModalPage--review-packInfo-field{

	outline: none;
	border: none;
	background: none;
	box-shadow: none;
	
	text-align: left;

	cursor: pointer;
}

button.publishingFlowModalPage--review-packInfo-field:hover{
	background: rgba(0,0,40,0.02);	
}

.publishingFlowModalPage--review-packInfo-field-title{
	font-size: 13px;
	font-weight: $lato-semibold;
	letter-spacing: 0.005em;
	color: lighten($textMid, 5%);
	margin-bottom: 4px;
	cursor: default;
	user-select: none;
}

span.publishingFlowModalPage--review-packInfo-field-title-edit{
	color: lighten($textLight, 5%);
}

button.publishingFlowModalPage--review-packInfo-field:hover
.publishingFlowModalPage--review-packInfo-field-title{
	color: $textDark;
}

button.publishingFlowModalPage--review-packInfo-field:hover
span.publishingFlowModalPage--review-packInfo-field-title-edit{
	color: $textBlue;
}

button.publishingFlowModalPage--review-packInfo-field:hover
span.publishingFlowModalPage--review-packInfo-field-title-value{
	color: $textBlackDark;
}

.publishingFlowModalPage--review-packInfo-field-value{
	font-weight: $lato-bold;
	font-size: 16px;
	color: $textBlack;
}

.publishingFlowModalPage--review-packInfo-field-value--small{
	font-size: 15px;
}


//
// CHECKBOX
//

.publishingFlowModalPage--review-checkboxContainer{
	position: absolute;
	left: 50%;
	right: 50%;
	transform: translateX(-50%);
	bottom: 87px;
	width: 800px;

	display: flex;
	align-items: center;
	justify-content: center;
}

.publishingFlowModalPage--review-checkbox{

	cursor: pointer;

	display: flex;
	align-items: center;
	justify-content: center;

	padding-left: 12px;
	padding-right: 12px;
	padding-top: 6px;
	padding-bottom: 6px;

	border-radius: 5px;
}

.publishingFlowModalPage--review-checkbox .featureModal-selectControl{
	align-items: center;
}

.publishingFlowModalPage--review-checkbox:hover{
	background: $blue-extraLight;
}

.publishingFlowModalPage--review .featureModal-selectControl-label,
.publishingFlowModalPage--review-checkbox,
.publishingFlowModalPage--review-checkbox a{
	font-weight: $lato-bold;
	font-size: 15px;
	cursor: pointer;
}

.publishingFlowModalPage--review-checkbox:hover
.featureModal-selectControl--inactive
.featureModal-selectControl-checkbox{
	border: 1px solid lighten($blue, 20%);
}


.publishingFlowModalPage--review-checkbox a,
.publishingFlowModalPage--review-checkbox a:visited{
	color: $textBlue;
	cursor: pointer;
	position: relative;
}

.publishingFlowModalPage--review-checkbox a:hover,
.publishingFlowModalPage--review-checkbox a:visited:hover{
	text-decoration: underline !important;
}

.publishingFlowModalPage--review-checkbox-label{
	user-select: none;
}