$width-testStaticSlidePanel: 300px;

.staticSlideTestPage{
	position: fixed;
	background: $tempr;
	top: 0px;
	left: 0px;
	right: 0px;
	bottom: 0px;

	z-index: 10;
}

.staticSlideTestPage--dark{
	background: rgb(0,0,10);
}

.staticSlideTestPage--light{
	background: rgb(250,250,255);
}

//
// LIST

.staticSlideTest-slideListContainer{
	position: absolute;
	top: 0px;
	left: 0px;
	right: $width-testStaticSlidePanel;
	bottom: 0px;

	overflow-y: scroll;

	padding-top: 50px;
	padding-bottom: 50px;

	z-index: 25;

	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
}

.staticSlideTest-slideListContainer-slideContainer{	
	// border: 1px solid red;
	flex-shrink: 0;
	flex-grow: 0;

	margin-bottom: 40px;
	overflow: hidden;
	border-radius: 4px;

}

.staticSlideTestPage--dark
.staticSlideTest-slideListContainer-slideContainer{
	border: 1px solid $invisible;
}

.staticSlideTestPage--light
.staticSlideTest-slideListContainer-slideContainer{
	border: 1px solid darken($lineMid, 5%);
	border-bottom: 1px solid  darken($lineMid, 8%);
	box-shadow: 0px 2px 4px 0px rgba(0,0,20,0.08);
}


.staticSlideTest-slideListContainer-slideContainer .slide{
	transform-origin: 0% 0%;
	border-color: $invisible;
}

.staticSlideTest-tempDevSlide{
	//border: 1px solid $blue;
	background: $white;
}


// CAROUSEL


.staticSlideTest-slideCarouselContainer{
	position: absolute;

	z-index: 25;

	// background: rgba(255,0,0,0.1);
	// outline: 1px solid rgba(255,0,0,0.5);
}

.staticSlideTest-slideCarouselContainer-slideContainer{
	position: absolute;
}

.staticSlideTest-slideCarouselContainer-slideContainer .slide{
	transform-origin: 0% 0%;
}




// PANEL


.staticSlideTest-panelContainer{
	position: absolute;
	right: 0px;
	top: 0px;
	bottom: 0px;
	width: $width-testStaticSlidePanel;

	overflow-y: scroll;

	box-sizing: border-box;
	padding: 24px;

	z-index: 10;
	padding-right: 36px;

	display: flex;
	justify-content: center;
}

.staticSlideTestPage--light
.staticSlideTest-panelContainer{
	// background: rgba(0,0,50,0.02);
	// border-left: 1px solid $lineDark;
}

.staticSlideTestPage--dark
.staticSlideTest-panelContainer{
	background: rgba(250,250,255,0.04);
	border-left: 1px solid $gray-1;
	background: rgb(30,30,38);
	background: rgb(10,10,18);;
}


.staticSlideTestPanel{
	flex-grow: 1;
	flex-shrink: 1;

	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
}

.staticSlideTestPanel--dark{
	color: $white;
}

.staticSlideTestPanel--light{
	color: lighten($textBlack, 5%);
}


//

.staticSlideTestPanel-section{
	border-radius: 5px;
	margin-bottom: 12px;
	flex-grow: 0;
	flex-shrink: 0;
	padding: 10px;
	width: 100%;

	
}

.staticSlideTestPanel--dark
.staticSlideTestPanel-section{
	border: 1px solid $black;
	background: rgb(30,30,32);
	background: rgb(30,30,34);
}

.staticSlideTestPanel--light
.staticSlideTestPanel-section{
	border: 1px solid $lineDark;
	background: $white;
	box-shadow: 0px 2px 4px 0px rgba(0,0,20,0.12);
}

.staticSlideTestPanel-section-title{
	font-size: 11.5px;
	font-weight: $lato-semibold;
	letter-spacing: 0.07em;
	text-transform: uppercase;

	margin-bottom: 10px;

	cursor: default;
}

.staticSlideTestPanel--dark
.staticSlideTestPanel-section-title{
	color: $gray-12;
}

.staticSlideTestPanel--light
.staticSlideTestPanel-section-title{
	color: $textMid;
}



button.staticSlideTestPanel-btn{
	border-radius: 4px;
	font-size: 13.5px;
	height: 26px;
	font-weight: $lato-semibold;

	letter-spacing: 0.02em;

	display: flex;
	align-items: center;
	justify-content: center;

	outline: none;
	box-shadow: none;
	border: 1px solid $blue;

	cursor: pointer;

}

button.staticSlideTestPanel-btn--active{
	background: $blue;
	border-color: $blue;
	color: $white;
}

button.staticSlideTestPanel-btn--active.staticSlideTestPanel-btn--activeGray{
	background: $gray-6;
	border-color: $gray-5;
	color: $white;
}
.staticSlideTestPanel--dark
button.staticSlideTestPanel-btn--inactive{
	background: $black;
	border: 1px solid $gray-2;
	color: $white;
	opacity: 0.5;
}

.staticSlideTestPanel--light
button.staticSlideTestPanel-btn--inactive{
	background: $white;
	border: 1px solid $gray-10;
	color: $textBlack;
	opacity: 0.5;
}


//

.staticSlideTestPanel-section--btnRow{
	display: flex;
	align-items: center;
	justify-content: center;
}

.staticSlideTestPanel-section--btnRow
button.staticSlideTestPanel-btn{
	width: 40%;
	flex-grow: 1;
	flex-shrink: 1;
	margin: 2px;
}

.staticSlideTestPanel-section--manyBtn
button.staticSlideTestPanel-btn{
	margin: 1px;
	flex-grow: 1;
	flex-shrink: 1;
}

//

.staticSlideTestPanel-section-row{
	display: flex;
	align-items: center;

	padding-bottom: 6px;
	padding-top: 6px;

	border-bottom: 1px solid $lineMid;
}

.staticSlideTestPanel-section-row--value{
	padding-top: 3px;
	padding-bottom: 3px;
}

.staticSlideTestPanel--dark
.staticSlideTestPanel-section-row{
	border-color: $gray-3;
}

.staticSlideTestPanel--light
.staticSlideTestPanel-section-row{
	border-color: $lineMid;
}

.staticSlideTestPanel-section-row-label{
	flex-grow: 1;
	flex-shrink: 1;

	font-size: 13.5px;	
	font-weight: $lato-medium;
	letter-spacing: 0.015em;
}

.staticSlideTestPanel--dark
.staticSlideTestPanel-section-row-label{
	color: $white;
}

.staticSlideTestPanel--light
.staticSlideTestPanel-section-row-label{
	color: $textBlackDark;
}

.staticSlideTestPanel-section-row-value{
	font-size: 13.5px;
	
}



.staticSlideTestPanel-section-row{
	opacity: 0.8;
	cursor: default;
}
.staticSlideTestPanel-section-row:hover{
	opacity: 1;
}

.staticSlideTestPanel-section-row button.staticSlideTestPanel-btn{
	// width: 36px;
	margin-left: 6px;
	padding-left: 6px;
	padding-right: 6px;
	height: 22px;
	font-size: 13px;
}

input.staticSlideTestPanel-section-row-input{
	width: 30px;
	height: 20px;
	border-radius: 2px;

	font-size: 13px;
	font-weight: $lato-semibold;
	letter-spacing: 0.02em;
	text-align: right;
	padding-right: 4px;

	outline: none;
	box-shadow: none;
	border-radius: 3px;
	border: 1px solid $lineMid;
}

.staticSlideTestPanel--dark
input.staticSlideTestPanel-section-row-input{
	background: rgba(0,0,0,0.3);
	border-color: $gray-2;
	color: $white;
}

.staticSlideTestPanel-section-spacer{
	width: 100%;
	height: 80px;
}

//
// ZOOM TOGGLE BUTTON

button.staticSlideTest-zoomToggleBtn{
	position: fixed;
	bottom: 10px;
	left: 50%;
	transform: translateX(-50%);
	z-index: 200;

	height: 40px;
	width: 200px;

	background: $gray-4;
	border: 1px solid $black;
	
	box-shadow: none;

	border-radius: 5px;

	color: $white;
	font-weight: $lato-bold;
	font-size: 13px;
	text-transform: uppercase;

	letter-spacing: 0.2em;

	opacity: 0;

	cursor: pointer;

}	

button.staticSlideTest-zoomToggleBtn:hover{
	opacity: 1;
}



// ZOOM TEST



.staticSlideTest-zoomMediaContainer{
	display: none;

	position: absolute;
	outline: 1px solid green;
	background: $tempg;
	z-index: 100;
	transform-origin: 0% 0%;
	// transform-origin: top center;
	
}

.staticSlideTest-zoomMediaContainer iframe{
	display: none;
}

.staticSlideTest-zoomMediaBG{
	display: none;

	position: absolute;
	z-index: 50;
	pointer-events: none;
	position: fixed;
	top: 0px;
	left: 0px;
	right: 0px;
	bottom: 0px;
	background: rgb(10,10,30);
}

.staticSlideTest-zoomMedia-youTubeExample {
	position: relative;
	padding-bottom: 56.25%; /* 16:9 Aspect Ratio */	

	opacity: 1;
}

.staticSlideTest-zoomMedia-youTubeExample iframe {
	position: absolute;
	width: 100% !important;
	height: 100% !important;
}



///
///

.testVideoZoomContainer{
	position: fixed;
	top: 0%;
	left: 0%;
	
	height: 100vh;
	width: 100vw;

	background: rgba(255,0,0,0.05);
	// background: $tempb;

	display: flex;
	align-items: center;
	justify-content: center;

	transform-origin: 0% 0%;

	pointer-events: none;
}

// .testVideoZoomContainer-zoomVideo--initial{
// 	background: $tempr;
// 	outline: 2px solid red;
// }

// .testVideoZoomContainer-zoomVideo--end{
// 	background: $tempb;
// 	outline: 2px solid blue;
// }

// .testVideoZoomContainer-ghostVideo{
// 	width: 80%;
// 	height: 80%;

// 	background: $tempr;
// }

// .testVideoZoomContainer-bg{
// 	position: absolute;
// 	top: 0px;
// 	left: 0px;
// 	bottom: 0px;
// 	right: 0px;
// 	// background: $tempb;
// }

.zoomStateSwitchTempButtonContainer{
	position: fixed;
	bottom: 0px;
	left: 0px;
	width: 300px;
	height: 50px;
	background: $gray-5;
	z-index: 9999;
}


// REFACTOR INTO VIDEO

.slide-mediaContainer--video--zoomable{
	z-index: 100; // above choices
}

.slide-media--video-video{
	z-index: 100; // above zoom BG
	position: absolute;

	// background: rgba(255,0,0,0.2);
	// outline: 2px solid red;
}

.slide-media--video-zoomBG,
.slide-media--image-zoomBG{
	position: absolute;
	z-index: 50;
	pointer-events: none;
	position: fixed;
	top: 0px;
	left: 0px;
	right: 0px;
	bottom: 0px;
	background: rgb(10,10,14);
	outline: 2px solid rgb(10,10,14);
}

.slide-media--video-video--rescalerContainer{
	display: none;
	// background: rgba(255,0,0,0.2);
	// outline: 2px solid red;
}


// REFACTOR INTO IMAGE

.slide-media--imageCover{
	// outline: 2px solid blue;
}

.slide-media--image-zoomContainer{
	// outline: 2px solid red;
	// pointer-events: none;
	z-index: 100;
}

// .slide-media--image-zoomContainer--zoomInactivePhase{
// 	visibility: hidden;
// }

.slide-media--image-zoom-imageCover{
	// outline: 2px solid green;
	// background: $tempg;	
	z-index: 100;
	position: absolute;

	height: 100%;
	width: 100%;
	position: relative;
}


.slide-media--image-zoom-imageCover{
	overflow: hidden;
	position: absolute;
	right: 0px;
	top: 0px;
	transform-origin: 0% 0%;
}

.slide-media--image-zoom-imageContainer{
	position: absolute;
	overflow: hidden;
	top: 0px;
	left: 0px;
}
.slide-media--image-zoom-imageCover--fill
.slide-media--image-zoom-imageContainer{
	top: 50%;
	left: 50%;
	transform: translateX(-50%) translateY(-50%);
}

.slide-media--image-zoom-image{
	position: absolute;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;
}

//
//
// ZOOM CHOICES

.staticSlideTest-zoomToggleBtnContainer{
	position: fixed;
	bottom: 5px;
	left: 30px;

	display: flex;
	justify-content: space-between;

	z-index: 9999;

	opacity: 0;
}

.staticSlideTest-zoomToggleBtnContainer:hover{
	opacity: 1;
}

button.staticSlideTest-zoomToggleChoiceBtn{
	width: 140px;
	height: 40px;

	background: $gray-4;
	border: 1px solid $black;

	box-shadow: none;

	border-radius: 5px;

	color: $white;
	font-weight: $lato-bold;
	font-size: 13px;
	text-transform: uppercase;

	letter-spacing: 0.2em;

	opacity: 0.4;

	cursor: pointer;

	margin: 5px;


}

button.staticSlideTest-zoomToggleChoiceBtn--choicesZoom{
	width: 240px;
}

button.staticSlideTest-zoomToggleChoiceBtn--active{
	background: $blue;
	border: 1px solid $blue;
	color: $white;
	opacity: 1;
}
	

button.staticSlideTest-zoomToggleChoiceBtn:hover{
	opacity: 0.5;
}

//
//
//


.slide-zoomMediaBG{
	// background: $tempr;
	z-index: 100;
	pointer-events: none;
	z-index: 5000;
}

.slide-zoomMediaBG-BG{
	position: absolute;
	z-index: 50;
	pointer-events: none;
	top: 0px;
	left: 0px;
	right: 0px;
	bottom: 0px;
	background: rgb(0,0,5);
}

.slide-zoomMediaBG-activeChoiceToggleContainer{
	position: absolute;
	top: 0px;
	left: 0px;
	right: 0px;
	height: 75px;
	z-index: 100;
	// background: $tempr;

	display: flex;
	align-items: center;
	justify-content: center;


}

// button.slide-zoomMediaBG-activeChoiceToggle{
// 	height: 60px;
// 	width: 160px;

// 	display: flex;
// 	align-items: center;
// 	justify-content: center;

// 	box-shadow: none;

	
	
// }

.slide-zoomMediaBG-activeChoiceToggleToggle{
	height: 45px;
	width: 90px;
	margin-left: 15px;
	margin-right: 15px;
	//margin-left: -40px;
	//margin-right: -40px;

	transform-origin: center center;

	display: flex;
	align-items: center;
	justify-content: center;

	background: $white;
	color: $black;

	border: 1px solid rgba(255,255,255,0.75);
	border-radius: 4px;

	box-shadow: none;

	font-size: 28px;
	font-weight: $lato-bold;

}

.slide-zoomMediaBG-activeChoiceToggleToggle-label{
	transform-origin: center center;
}


// .slide-zoomMediaBG-activeChoiceToggle--active{
// 	background: $white;
// 	border: 1px solid $black;
// 	color: $textBlackDark;
// 	border-radius: 6px;
// }

// .slide-zoomMediaBG-activeChoiceToggle--inactive{
// 	border: 1px solid $gray-5;
// 	color: $white;
// 	background: $black;

// 	opacity: 0.5;

// 	transform: scale(0.7);
// 	transform-origin: center center;

// 	border-radius: 8px;
// }

// // temp
// .slide-choice--index--1 .slide-choice-mediaContainer *,
// .slide-choice--index--2 .slide-choice-mediaContainer *,
// .slide-choice--index--3 .slide-choice-mediaContainer *{
// 	visibility: hidden;
// }

.slide-media--video-video--startZoomScale{
	display: flex;
	align-items: flex-end;
}

// temp
.slide-media--video-video{
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: default;
}

.slide-choice-media--video-video--startZoom{
	font-size: 14px;
}

// .slide-choice-mediaContainer--video--choicesAreZoomed{
// 	opacity: 0.9;
// }

// .slide-choice--isActiveChoiceMediaZoomed{
// 	position: relative;
// 	z-index: 500;
// }

// .slide-choice--isNotActiveChoiceMediaZoomed{
// 	z-index: 100;
// }

.slide-media--video-video iframe{
	position: absolute;
	width: 100%;
	height: 100%;
}

//
// ZOOM TEST PAGE
// 

.zoomTestPageContainer{
	position: fixed;
	top: 0px;
	left: 0px;
	right: 0px;
	bottom: 0px;

	background: rgb(250,250,255);
}

.zoomTestItem{
	position: absolute;

	cursor: pointer;

	font-size: 12px;
}

.zoomTestItem--topLeftOrigin{
	transform-origin: 0% 0%;

	outline: 2px solid $blue;
	background: rgba(0,0,255,0.05);
}

.zoomTestItem--centerCenterOrigin{
	outline: 2px solid $red;
	background: rgba(255,0,0,0.05);
}

.zoomTestItem-aimer{
	position: absolute;
	width: 60px;
	height: 60px;

	display: flex;
	align-items: center;
	justify-content: center;

	font-size: 60px;
}

.zoomTestItem--topLeftOrigin
.zoomTestItem-aimer{
	position: absolute;
	top: -33px;
	left: -30px;

	color: $blue;
}

.zoomTestItem-originMarker{
	height: 20px;
	width: 20px;
	background: red;
}

//

.zoomTestPage-initialPositionSliderContainer{
	position: fixed;
	bottom: 5px;
	left: 5px;
	height: 30px;
	width: 500px;
	
	background: white;
	border: 1px solid $lineDark;
	box-shadow: 0px 1px 2px 0px rgba(12,12,24,0.06);
	border-radius: 5px;

	display: flex;
	align-items: center;
	justify-content: center;

	padding-right: 20px;

	//opacity: 0.5;
}

.zoomTestPage-initialPositionSliderContainer
.rc-slider-track{
	background: $blue;
}

.zoomTestPage-initialPositionSliderContainer
.rc-slider-handle{
	border: 2px solid $blue;
}

.zoomTestPage-initialPositionSlider{
	color: $white;
	width: 20%;
	flex-grow: 1;
	flex-shrink: 1;

	margin-left: 20px;
}

.zoomTestPage-outputContainer{
	position: fixed;
	bottom: 15px;
	right: 15px;
	width: 200px;
	font-size: 12px;
	pointer-events: none;
}

//
.slide-media--image-zoomContainer--zoomInactivePhase{
	opacity: 0;
	// transition: opacity 50ms linear;
	// transition-delay: 100ms;
}

.slide-media--image-zoomContainer--zoomActivePhase{
	opacity: 1;

}

// .slide-media--image-zoomBG-minimizeIconContainer{
// 	position: absolute;
// 	top: 20px;
// 	right: 30px;
// 	width: 50px;
// 	height: 50px;
// 	z-index: 9000;

// 	border-radius: 12px;
// 	background: rgb(10,10,14);;
// 	background: $black;

// 	display: flex;
// 	align-items: center;
// 	justify-content: center;

// 	opacity: 1;
// }

// .slide-media--image-zoomBG-minimizeIconContainer:hover{
// 	background: red;
// }




// .slide-media--image-zoomBG-minimizeIconContainer svg{
// 	fill: $white;
// 	width: 24px;
// 	height: 24px;
// 	opacity: 0.4;
// 	transition: opacity 50ms linear;
// }

// .slide-media--image-zoomBG-minimizeIconContainer:hover svg{
// 	opacity: 1;
// }

//
//


.liveViewTestAnimateBar{
	position: fixed;
	z-index: 9999;
	left: 20px;
	top: 100px;
	height: 50px;
	width: 100px;

	font-size: 12px;

	background: $tempr;
}