.liveView-mediaItem,
.liveView-mediaItemContainer{
	position: fixed;

	user-select: none;
	cursor: pointer;

	z-index: 90000;
	
	box-sizing: border-box;
}

.liveView-mediaItemContainer{
	z-index: 89000; // below media items
}


//
// FIT IMAGE // ANIMATED GIF

.liveView-mediaItem--fitImage,
.liveView-mediaItem--fitAnimatedGIF{		
	overflow: hidden;
	border-radius: 5px;
}

img.liveView-mediaItem--fitImage-image{
	height: 100%;
	width: 100%;
}

.liveView-mediaItem--fitAnimatedGIF-GIF img{
	height: 100%;
	width: 100%;
}

#fitImageZoomable,
#fitAnimatedGIFZoomable{
	background: $gray-15;
}


//
// FILL IMAGE

.liveView-mediaItem--fillImage,
.liveView-mediaItem--fillAnimatedGIF{	
	overflow: hidden;
	will-change: transform;
	border-radius: 15px;
}

.liveView-mediaItem--fillImage-imageFillCover,
.liveView-mediaItem--fillAnimatedGIF-imageFillCover{
	position: absolute;	
	left: 50%;
	top: 50%;
	will-change: transform;
}

img.liveView-mediaItem--fillImage-image{
	height: 100%;
	width: 100%;
}

.liveView-mediaItem--fillAnimatedGIF-GIF img{
	height: 100%;
	width: 100%;
}

#fillImageZoomable,
#fillAnimatedGIFZoomable{
	background: $gray-15;
}


//
// VIDEO PLAYER

.liveView-mediaItem--promptVideoPlayer{

}

.liveView-mediaItem--promptVideoPlayer-videoContainer{
	transform-origin: top left;
}

#videoPlayerZoomable{
	// background: $gray-15;
}

.liveView-mediaItem--promptVideoPlayer-startZoomControlBarContainer{

}

.liveView-mediaItem--promptVideoPlayer-startZoomControlBarContainer,
.liveView-mediaItem--promptVideoPlayer-endZoomControlBarContainer{	
	z-index: 94000; // above video
}

.liveView-mediaItem--promptVideoPlayer-endZoomControlBarContainer{
	position: fixed;
	transform-origin: bottom left;
}



//
// PROMPT SOUND PLAYER
// at bottom as rebuilt UI


//
// CHOICE MEDIA
//


//
// CHOICE FIT IMAGE

// showOGGasGray
.slide-choice-media--image-fitImageZoomable{
	background: $gray-15;
	// background: $tempr;
}

.liveView-mediaItem--choiceFitImage{	
	overflow: hidden;
	border-radius: 2px;
}

img.liveView-mediaItem--choiceFitImage-image{
	height: 100%;
	width: 100%;
}

// CHOICE FILL IMAGE and NAIMTED GIF

.slide-choice-media--image-fillImageZoomable
.slide-choice-media--animatedGIF-animatedGIFZoomable{
	background: $gray-15;
	// background: $tempb;
}

.liveView-mediaItem--choiceFillImage,
.liveView-mediaItem--choiceAnimatedGIF{	
	overflow: hidden;
	// will-change: transform;
	border-radius: 15px;
}

.liveView-mediaItem--choiceFillImage-imageFillCover,
.liveView-mediaItem--choiceAnimatedGIF-imageFillCover{
	position: absolute;	
	left: 50%;
	top: 50%;
	// will-change: transform;
}

img.liveView-mediaItem--choiceFillImage-image{
	height: 100%;
	width: 100%;
}

.liveView-mediaItem--choiceAnimatedGIF-GIF img{
	height: 100%;
	width: 100%;
}



//
// CHOICE SOUND

.liveView-mediaItem--choiceSoundPlayer{
	background: $white;
	border-radius: 12px;
	border: 1px solid $gray-13;
	box-shadow: 0px 2px 2px 0px rgba(0,0,20,0.05);
	cursor: default;

}

// TWEAKS TO PLAYER
// Maybe want to construct a new UI/new CSS if we had visualization

.liveView-mediaItem--choiceSoundPlayer
.slide-choice-media--sound-bottom-seekBarContainer .rc-slider-handle{
	opacity: 0; // don't show handle by default
	margin-top: -3px;
}
.liveView-mediaItem--choiceSoundPlayer:hover
.slide-choice-media--sound-bottom-seekBarContainer .rc-slider-handle{
	opacity: 1;
}

// thicker lines
.liveView-mediaItem--choiceSoundPlayer
.slide-choice-media--sound-bottom-seekBarContainer .rc-slider-track,
.liveView-mediaItem--choiceSoundPlayer
.slide-choice-media--sound-bottom-seekBarContainer .rc-slider-rail{
	height: 5px;
	border-radius: 2.5px;
	top: 45%;
}

.liveView-mediaItem--choiceSoundPlayer
.slide-choice-media--sound-bottom-seekBarContainer .rc-slider-track{
	border-top-right-radius: 0px;
	border-bottom-right-radius: 0px;
}

// stronger playing state
.liveView-mediaItem--choiceSoundPlayer
button.slide-choice-media--sound-playPauseBtn.slide-choice-media--sound-playPauseBtn--playing{
	background: $blue;
}

.liveView-mediaItem--choiceSoundPlayer
.slide-choice-media--sound-playPauseBtn--playing svg{
	fill: $white;
}

.slide-choice-mediaContainer--sound--zoomable{
	visibility: hidden; //
}



//
// CHOICE VIDEO

.liveView-mediaItemContainer--choiceVideoContainer{
	cursor: default;
}

.liveView-mediaItem--choiceVideo-videoContainer{
	// background: $tempr;
	transform-origin: top left;
}

.liveView-mediaItem--choiceVideo-startZoomControlBarContainer,
.liveView-mediaItem--choiceVideo-endZoomControlBarContainer{	
	z-index: 94000; // above video
}

.liveView-mediaItem--choiceVideo-endZoomControlBarContainer{
	position: fixed;
}

.slide-choice-media--videoChoiceZoomable-placeholderBG{
	background: $gray-15;
}





// 
// INCREASE Z-INDEX OF LAST ZOOMED ITEM SO ABOVE OTHER ITEMS
.liveView-mediaItem--isLastZoomedItem{
	z-index: 92000; // above black BG
}

//
// DECREASE Z-INDEX OF NOT ZOOMED ITEM SO BELOW DARK BG/OTHER ITEMS
.liveView-mediaItem--moveItemBelowDarkBG{
	z-index: 4000; // lower than black BG
}

//
// LETTER BLOCK PANEL

.liveView-choiceZoomLetterBlockPanel{	
	width: 900px;
	top: 0px;
	left: 0px;
	right: 0px;
	margin: auto;

	position: fixed;	

	z-index: 95000;

	display: flex;
	align-items: center;
	justify-content: center;

	transform-origin: top center;
}


.liveView-choiceZoomLetterBlockPanel-letterBlock{
	height: 70px;
	width: 120px;
	margin-left: 25px;
	margin-right: 25px;

	user-select: none;
	cursor: pointer;

	transform-origin: center center;

	display: flex;
	align-items: center;
	justify-content: center;

	background: $white;
	color: $black;

	border: 1px solid rgba(255,255,255,0.75);
	border-radius: 5px;

	box-shadow: none;

	font-size: 44px;
	font-weight: $lato-heavy;

}

.liveView-ChoiceZoomLetterBlockPanel-letterBlock-label{
	transform-origin: center center;
}


// GLOBAL UNZOOM BUTTON

button.liveView-universalMediaUnzoomBtn{
	position: fixed;
	z-index: 99999;
	top: 0px;
	right: 0px;
	width: 100px;
	height: 100px;

	transform-origin: top right;

	cursor: pointer;

	//background: rgb(0,0,0);

	border: none;
	outline: none;
	box-shadow: none;
	background: $invisible;
	
	// background: $tempr;

	// opacity: 1 !important;


	box-sizing: border-box;
	padding: 0px;
	padding-top: 6px;
	padding-right: 6px;
	padding-bottom: 14px;
	padding-left: 14px;

}

.liveView-universalMediaUnzoomBtn-inner{
	height: 100%;
	width: 100%;
	border-radius: 18px;

	background: rgba(0,0,0,0.8);

	box-sizing: border-box;
	padding: 18px;

}

.liveView-universalMediaUnzoomBtn-inner svg{
	width: 100%;
	height: 100%;
	fill: $white;
}


// opacity management
// we want to it to hide and show quickly for mouse move, but slowly for media zoom/unzoom

button.liveView-universalMediaUnzoomBtn--mouseMove{
	opacity: 1;
	transition: opacity 100ms linear;
}



button.liveView-universalMediaUnzoomBtn--mouseNotMoved{
	opacity: 0;
	transition: opacity 300ms linear;
}

button.liveView-universalMediaUnzoomBtn--mediaZoomed{
	pointer-events: auto;
}

button.liveView-universalMediaUnzoomBtn--mediaNotZoomed{
	pointer-events: none;
}

//

.liveView-universalMediaUnzoomBtn-inner--mediaNotZoomed{
	opacity: 0;
	transition: opacity 50ms linear;
	transition-delay: 0ms;
}

.liveView-universalMediaUnzoomBtn-inner--mediaZoomed{
	opacity: 0.6;
	transition: opacity 200ms linear;
	transition-delay: 600ms; // allow for zoom animation to finish
}

button.liveView-universalMediaUnzoomBtn:hover
.liveView-universalMediaUnzoomBtn-inner--mediaZoomed{
	opacity: 1;
	transition: oapcity 50ms linear;
	transition-delay: 0ms;
}

//
// REFACTOR

.liveView-darkZoomBackground{
	
	//pointer-events: none;
	cursor: pointer;
	position: fixed;

	top: 0px;
	left: 0px;
	right: 0px;
	bottom: 0px;
	background: rgb(10,10,14);
}

.liveView-darkZoomBackground--noMediaZoomed{
	pointer-events: none;
	z-index: 80000;
}

.liveView-darkZoomBackground--mediaZoomed{
	z-index: 80000;
}

.liveView-controlBarContainer{
	z-index: 95000 !important;
}



///
/// PROMPT SOUND PLAYER
///


.liveView-mediaItem--promptSoundPlayer{
	background: none;
	border: none;
	padding: none;
	// overflow: hidden;

	opacity: 1;
	transition: opacity 50ms linear;
}

.liveView-mediaItem--choiceSoundPlayer--otherChoicesPlaying{
	opacity: 0.25;
	transition: opacity 100ms linear;
}

.slide-media--sound--zoomablePlayer{
	border: none !important; // I think this is actually the best thing to do
	box-shadow: none !important;
	background: $invisible !important;
}

.liveView-mediaItem--promptSoundPlayer-player{
	position: absolute;
	top: 0px;
	left: 0px;
	transform-origin: top left;

	// similar to core sound player
	border: 1px solid $lineMid;
	border-bottom: 1px solid $lineDark;
	box-shadow: 0px 2px 2px 0px rgba(0,0,20,0.06);

	border-radius: 9px;

	box-sizing: border-box;

	background: $white;

	display: flex;
	flex-direction: column;

	cursor: default;
}

//
// TIMESTAMPS
//

.liveView-mediaItem--promptSoundPlayer-player-top{
	height: 70px;
	width: 100%;


	display: flex;
	align-items: center;
	justify-content: center;

	box-sizing: border-box;
	padding-top: 20px;

	transform-origin: 50% 20%;
}

.liveView-mediaItem--promptSoundPlayer-player-top-timestamp{
	color: $textBlackDark;
	
	letter-spacing: 0.02em;

	font-size: 24px;

	padding-left: 8px;
	padding-right: 8px;
}

.liveView-mediaItem--promptSoundPlayer-player-top-timestamp--currentTime{
	font-weight: $lato-heavy;
}

.liveView-mediaItem--promptSoundPlayer-player-top-timestamp--duration{
	font-weight: $lato-bold;
	opacity: 0.5;
}

.liveView-mediaItem--promptSoundPlayer-player-top-timestamp--currentTimePlaying{
	font-weight: $lato-heavy;
	color: black;
	font-size: 28px;
}

//
// SEEKBAR

.liveView-mediaItem--promptSoundPlayer-player-seekBarContainer{
	
	flex-grow: 1;
	flex-shrink: 1;

	height: 50px;
	width: calc(100% - 50px);
	margin-left: 25px;
	// background: $tempg;	

	// background: $gray-14;

	box-sizing: border-box;
	

	cursor: pointer;

	position: relative;
}

.liveView-mediaItem--promptSoundPlayer-player--widePlayer
.liveView-mediaItem--promptSoundPlayer-player-seekBarContainer{
	width: calc(100% - 80px);
	margin-left: 40px;
}

.liveView-mediaItem--promptSoundPlayer-player-seekBarContainer .rc-slider{
	height: 100%;
	cursor: pointer;
	position: relative;
	z-index: 10;
}

.liveView-mediaItem--promptSoundPlayer-player-seekBarContainer .rc-slider-handle{
	width: 18px;
	height: 18px;
	margin-top: -6px;
	// border-radius: 50%;
	background: $blue;
	border: 2px solid white;
	box-shadow: none;
	cursor: pointer;

	top: 0px;
	bottom: 0px;
	margin: auto;
}

.liveView-mediaItem--promptSoundPlayer-player-seekBarContainer .rc-slider-handle:hover,
.liveView-mediaItem--promptSoundPlayer-player-seekBarContainer .rc-slider-handle:focus,
.liveView-mediaItem--promptSoundPlayer-player-seekBarContainer .rc-slider-handle:active{
	border: 2px solid white;
	box-shadow: none;
	cursor: pointer;
}

.liveView-mediaItem--promptSoundPlayer:hover
.liveView-mediaItem--promptSoundPlayer-player-seekBarContainer .rc-slider-handle{
	width: 24px;
	height: 24px;
	border: 2px solid white;
}

.liveView-mediaItem--promptSoundPlayer-player-seekBarContainer
.rc-slider-track{
	background: $blue;
	height: 8px;
	top: 0px;
	bottom: 0px;
	margin: auto;

	border-radius: 4px;
	border-top-right-radius: 0px;
	border-bottom-right-radius: 0px;
}

.liveView-mediaItem--promptSoundPlayer-player-seekBarContainer
.rc-slider-rail{	
	background: rgba(0,0,20,0.2);
	height: 8px;
	top: 0px;
	bottom: 0px;
	margin: auto;
	border-radius: 4px;

	opacity: 1;
}

.liveView-mediaItem--promptSoundPlayer-player--playing
.rc-slider-rail{
	opacity: 0.5;
	transition: opacity 150ms linear;
}


.liveView-mediaItem--promptSoundPlayer-player-seekBarContainer-waveformBG{
	position: absolute;
	top: 10px;
	left: 0px;
	right: 0px;
	bottom: 10px;
}

.liveView-mediaItem--promptSoundPlayer-player-seekBarContainer-waveformBG img{
	position: absolute;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;

	opacity: 0.12;
}

.liveView-mediaItem--promptSoundPlayer-player--paused
.liveView-mediaItem--promptSoundPlayer-player-seekBarContainer-waveformBG{
	opacity: 1;
	transition: opacity 150ms linear;
}

.liveView-mediaItem--promptSoundPlayer-player--playing
.liveView-mediaItem--promptSoundPlayer-player-seekBarContainer-waveformBG{
	opacity: 0.5;
	transition: opacity 150ms linear;
}




//
// CONTROLS
//

.liveView-mediaItem--promptSoundPlayer-player-controls{
	height: 86px;
	width: 100%;	

	display: flex;
	align-items: center;
	justify-content: center;

	transform-origin: 50% 80%;
}

button.liveView-mediaItem--promptSoundPlayer-player-controls-playPauseBtn{
	width: 160px;
	height: 70px;
	border-radius: 10px;

	border: none;
	box-shadow: none;

	cursor: pointer;

	margin-left: 20px;
	margin-right: 20px;

	display: flex;
	align-items: center;
	justify-content: center;

}

button.liveView-mediaItem--promptSoundPlayer-player-controls-playPauseBtn--paused{
	background: $blue-extraLight;
}

button.liveView-mediaItem--promptSoundPlayer-player-controls-playPauseBtn--playing{
	background: $blue;
}

button.liveView-mediaItem--promptSoundPlayer-player-controls-playPauseBtn:focus{
	outline: none;
}

button.liveView-mediaItem--promptSoundPlayer-player-controls-playPauseBtn svg{
	width: 36px;
	height: 36px;
}

button.liveView-mediaItem--promptSoundPlayer-player-controls-playPauseBtn--paused svg {
	fill: $blue;
	position: relative;
	right: -2px;
}

button.liveView-mediaItem--promptSoundPlayer-player-controls-playPauseBtn--playing svg {
	fill: $white;
}

// SEEK BUTTONS
button.liveView-mediaItem--promptSoundPlayer-player-controls-seekJump{
	border: none;
	box-shadow: none;
	// background: $tempr;
	background: none;

	border-radius: 10px;

	width: 70px;
	height: 70px;

	display: flex;
	align-items: center;
	justify-content: center;

	cursor: pointer;
}



button.liveView-mediaItem--promptSoundPlayer-player-controls-seekJump svg{
	height: 30px;
	width: 30px;

	fill: $textLight;
}

button.liveView-mediaItem--promptSoundPlayer-player-controls-seekJump:active{
	background: $gray-15;
}

button.liveView-mediaItem--promptSoundPlayer-player-controls-seekJump:active svg{
	fill: darken($textLight, 10%);
}
