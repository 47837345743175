
// USER ONBOARDING

.repoReleaseModalContainer{
	position: fixed;
	z-index: 9999;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: rgba(5,5,10,0.75);
	
	display: flex;
	
	align-items: center;
	justify-content: center;
}

.repoReleaseModal{
	border-radius: 10px;
	
	background: $onboarding-bgColor;
	border: 1px solid $gray-5;
	box-shadow: 
		0px 8px 16px 0px rgba(5,5,10,0.12),
		0px 16px 32px 0px rgba(5,5,10,0.08) ;

	box-sizing: border-box;

	overflow: hidden;

	width: calc(100vw - 100px);
	height: calc(100vh - 50px);
	max-width: 1120px;
	max-height: 670px;
	min-height: 620px;
	min-width: 1056px;
	
	position: relative;
}

$repoReleaseModal-headerHeight: 200px;

.repoReleaseModal-header{
	position: absolute;
	top: 0px;
	left: 0px;
	right: 0px;

	height: $repoReleaseModal-headerHeight;

	cursor: default;

	//background: $tempb;
}

.repoReleaseModal-visual{
	position: absolute;
	top: $repoReleaseModal-headerHeight;
	//top: 100px;
	// bottom: 0px;
	left: 0px;
	right: 0px;
	height: 440px;

	overflow: hidden;

	//background: $tempr;
}

.repoReleaseModal-bottom{
	position: absolute;
	bottom: 0px;
	left: 0px;
	right: 0px;
	background: $tempg;
	height: 120px;

	z-index: 10;

	background: linear-gradient(to bottom, rgba(252,252,255,0), rgba(252,252,255,1));
}

@import 'RepoReleaseModal-Header';
@import 'RepoReleaseModal-Visual';
@import 'RepoReleaseModal-Bottom';

/// DOTS

.repoReleaseModal-leftDots{
	position: absolute;
	left: calc(50% - 390px);
	width: 57px;
	height: 214px;
	top: calc(#{$repoReleaseModal-headerHeight} - 2px);	
	z-index: 100;
}

.repoReleaseModal-rightDots{
	position: absolute;
	left: calc(50% + 270px);
	width: 39px;
	height: 111px;
	top: calc(#{$repoReleaseModal-headerHeight} - 5px);
	z-index: 100;
}