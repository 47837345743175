.sectionHome-activateELearningWizardContainer{
	position: fixed;
	z-index: 9999;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: rgba(5,5,10,0.75);
	
	display: flex;
	
	align-items: center;
	justify-content: center;
}

.sectionHome-activateELearningWizard{
	border-radius: 8px;
	
	background: rgb(250,250,255);
	border: 1px solid $gray-5;
	box-shadow: 
		0px 8px 16px 0px rgba(5,5,10,0.12),
		0px 16px 32px 0px rgba(5,5,10,0.08);

	box-sizing: border-box;

	overflow: hidden;

	width: 720px;
	min-height: 480px;

	padding-top: 24px;
	padding-right: 42px;
	padding-left: 42px;

	position: relative;

	cursor: default;
}

//
// HEADER

.sectionHome-activateELearningWizard-header{
	display: flex;
	font-size: 16.5px;
	font-weight: $lato-bold;

	align-items: center;

	color: $textBlack;

	margin-bottom: 28px;
}


.sectionHome-activateELearningWizard-header .sectionMarker .icon.circle{
	width: 9px;
	height: 9px;
	position: relative;
	top: -1px;

	margin-right: 5px;
}

.sectionHome-activateELearningWizard-header .icon.chevron-right{
	width: 9px;
	height: 9px;
	fill: $gray-11;

	margin-left: 6px;
	margin-right: 6px;
}

.sectionHome-activateELearningWizard-header-label{
	color: $textMid;
}


//
// TITLE

.sectionHome-activateELearningWizard-title{
	display: flex;
	font-size: 21px;
	font-weight: $lato-heavy;
	letter-spacing: 0.01em;
	color: $textBlackDark;
	margin-bottom: 5px;
}

.sectionHome-activateELearningWizard-title-icon{
	height: 24px;
	width: 24px;
	margin-left: 6px;
}

.sectionHome-activateELearningWizard-title-icon svg{
	width: 100%;
	height: 100%;
}

.sectionHome-activateELearningWizard-subtitle{
	font-size: 15px;
	font-weight: $lato-medium;
	color: $textMid;
	max-width: 450px;
}


//
// ANNOUNCEMENT

.sectionHome-activateELearningWizard-announcementContainer{
	margin-top: 20px;
}

.sectionHome-activateELearningWizard-announcement-title{
	font-size: 16.5px;
	font-weight: $lato-bold;
	color: $textBlack;
	margin-bottom: 5px;
}

textarea.sectionHome-activateELearningWizard-announcement-textarea{
	width: 400px;
	height: 106px;
	overflow-y: scroll;
	padding: 8px;

	font-size: 14.5px;
	font-weight: $lato-medium;
	color: $textBlackDark;

	resize: none;

	margin-bottom: 4px;

	border: 1px solid $lineDark;
	border-radius: 3px;

	white-space: pre-wrap; 
}

.sectionHome-activateELearningWizard-announcement-tip{
	margin-bottom: 8px;
	font-size: 14px;
	font-weight: $lato-medium;
	color: $textMid;
}
//
// SUBMIT BUTTON

.sectionHome-activateELearningWizard .btn.sectionHome-activateELearningWizard-submitBtn{
	width: 440px;
	height: 60px;
	position: absolute;
	bottom: 24px;
	left: calc(50% - 220px);

	
	border-radius: 6px;

	display: block;
	cursor: pointer;
}

.sectionHome-activateELearningWizard .btn.sectionHome-activateELearningWizard-submitBtn.btn--blue{
	background: $blue;
	box-shadow: 0px 2px 4px 0px rgba(0,0,40,0.08);
}


.sectionHome-activateELearningWizard-submitBtn-primary{
	font-weight: $lato-heavy;
	font-size: 20px;
	letter-spacing: 0.02em;	
}

.sectionHome-activateELearningWizard-submitBtn.btn--gray{
	opacity: 0.6;
}

.sectionHome-activateELearningWizard-submitBtn.btn--blue
.sectionHome-activateELearningWizard-submitBtn-primary{
	line-height: 2.1;
}

.sectionHome-activateELearningWizard-submitBtn-secondary{
	font-size: 11px;
	font-weight: $lato-semibold;
	color: white; // only displayed when active
	opacity: 0.75;
	margin-top: -11px;
}

.sectionHome-activateELearningWizard-submitBtn-loadingSpinnerContainer{
	position: absolute;
	
	display: flex;
	align-items: center;
	justify-content: center;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
}

.sectionHome-activateELearningWizard-submitBtn-loadingSpinner{

	height: 20px;
	width: 20px;
  
  border-radius: 16px;

  border: 3px rgba(26,28,34,0.55) solid;
  border-top: 3px $white solid;

  opacity: 0.5;

  animation: spinnerRotateAnimation .3s infinite linear;
}



// CLOSE BUTTON

.sectionHome-activateELearningWizard-closeBtn{
	position: absolute;
	width: 30px;
	height: 30px;
	top: 24px;
	right: 20px;
	cursor: pointer;
	border-radius: 14px;

	opacity: 0.4;
	transition: opacity 100ms linear;
}

.sectionHome-activateELearningWizard-closeBtn:hover{
	opacity: 0.8;
	transition: opacity 50ms linear;
}

.sectionHome-activateELearningWizard-closeBtn:active{
	opacity: 1;
}

.sectionHome-activateELearningWizard-closeBtn .icon{
	width: 30px;
	height: 30px;
	fill: $gray-6;
}