.upgrade--introDiscountSashContainer{
	position: absolute;
	top: 0px;
	left: 0px;

	width: 277px;
	height: 192px;
	//background: $tempr;

	overflow: hidden;
}

// little bit dodgy, not sure how to do it nicely probs need some math
.upgrade--introDiscountSash{
	background: $red;
	height: 62px;
	width: 360px;
	text-align: center;
	position: absolute;
	top: 32px;
	left: -50px;

	box-sizing: border-box;
	padding-right: 60px;


	transform: rotate(-35deg);

	user-select: none;
	cursor: default;
}

.upgrade--introDiscountSash-title{
	color: $white;
	font-weight: $effra-bold;
	font-size: 36px;
	margin-top: 2px;
}

.upgrade--introDiscountSash-sub{
	margin-top: -3px;
	color: $white;
	font-weight: $effra-medium;
	font-size: 13px;	
}