// Default Preview Panel with member list

.repoDefaultPreviewPanelContainer{
	height: 100%;
	width: 100%;
}

.repoDefaultPreviewPanel{
	width: 100%;
	height: 100%;
	box-sizing: border-box;
	overflow: visible;

	position: relative;

	// background: $tempr;
}


.repoDefaultPreviewPanel button.invite,
.repoDefaultPreviewPanel button.publish,
.repoDefaultPreviewPanel button.follow,
.repoDefaultPreviewPanel button.submitearlyreleaseapproval{
	width: 100%;
	max-width: 180px;
	font-weight: $lato-heavy;
	font-size: 15.5px;
	letter-spacing: 0.018em;
	cursor: pointer;
	position: absolute;
	
	left: 0px;
}

.repoDefaultPreviewPanel button.submitearlyreleaseapproval{
	max-width: 240px;
}


// Positioning, swap order for free vs. pro

$repoDefaultPreviewPanel-firstButtonTop: 31px;
$repoDefaultPreviewPanel-buttonLabelSpace: 41px;
$repoDefaultPreviewPanel-buttonBottomMargin: 40px;

.repoDefaultPreviewPanel--pro button.invite,
.repoDefaultPreviewPanel--free button.publish,
.repoDefaultPreviewPanel button.follow,
.repoDefaultPreviewPanel button.submitearlyreleaseapproval,
.repoDefaultPreviewPanel-earlyReleaseApprovalStatus {
	top: $repoDefaultPreviewPanel-firstButtonTop;
}

.repoDefaultPreviewPanel--pro .repoDefaultPreviewPanel-message--invite,
.repoDefaultPreviewPanel--free .repoDefaultPreviewPanel-message--publish,
.repoDefaultPreviewPanel .repoDefaultPreviewPanel-message--follow,
.repoDefaultPreviewPanel .repoDefaultPreviewPanel-message--submitEarlyReleaseApproval{
	top: calc(#{$repoDefaultPreviewPanel-firstButtonTop} + #{$repoDefaultPreviewPanel-buttonLabelSpace});
}

//

.repoDefaultPreviewPanel--free button.invite,
.repoDefaultPreviewPanel--pro button.publish{
	top: calc(#{$repoDefaultPreviewPanel-firstButtonTop} + #{$repoDefaultPreviewPanel-buttonLabelSpace} + #{$repoDefaultPreviewPanel-buttonBottomMargin});
}

.repoDefaultPreviewPanel--free .repoDefaultPreviewPanel-message--invite,
.repoDefaultPreviewPanel--pro .repoDefaultPreviewPanel-message--publish{
	top: calc(#{$repoDefaultPreviewPanel-firstButtonTop} + #{$repoDefaultPreviewPanel-buttonLabelSpace} + #{$repoDefaultPreviewPanel-buttonLabelSpace} + #{$repoDefaultPreviewPanel-buttonBottomMargin});
}


.repoDefaultPreviewPanel button.invite:disabled{
	//opacity: 0; // override default
	transition: opacity 150ms linear;
}

.repoDefaultPreviewPanel:hover button.invite:disabled{
	//opacity: 0.85; // override default
	transition: opacity 100ms linear;
}

.repoDefaultPreviewPanel button.invite svg.icon{
	width: 16px;
	height: 16px;
	margin-left: -10px;
	opacity: 0.8;
}

.repoDefaultPreviewPanel button.invite:hover svg.icon{
	opacity: 1;
}

.repoDefaultPreviewPanel button.invite svg.icon #primary{
	fill: white;
}

.repoDefaultPreviewPanel button.invite svg.icon #secondary{
	fill: rgba(255,255,255,0.75);
}

.repoDefaultPreviewPanel button.invite:disabled svg.icon #primary{
	fill: $gray-6;
}

.repoDefaultPreviewPanel button.invite:disabled svg.icon #secondary{
	fill: $gray-9;
}

.repoDefaultPreviewPanel button.publish{
	display: flex;
	align-items: center;
	justify-content: center;
}

.repoDefaultPreviewPanel button.publish svg.icon{
	width: 17px;
	height: 17px;
	margin-right: 7px;
	margin-left: -7px;
	fill: $gray-115;
}

.repoDefaultPreviewPanel button.publish:hover svg.icon{
	fill: $color-o85;
}

// Follow improve loader

.repoDefaultPreviewPanel button.follow.submit--loading .button-loadingSpinner{
	height: 16px;
	width: 16px;
	animation-duration: 500ms;
}

//


.repoDefaultPreviewPanel-message{

	width: 100%;
	max-width: 180px;

	position: absolute;
	left: 0px;

	cursor: default;
	user-select: none;

	text-align: center;

	font-size: 12.5px;
	color: $textMid;
	letter-spacing: 0.005em;
	font-weight: $lato-medium;
}

.repoDefaultPreviewPanel--free .repoDefaultPreviewPanel-message--invite{
	cursor: pointer;
	opacity: 0;
	transition: opacity 150ms linear;
}

.repoDefaultPreviewPanel-message--follow{
	margin-left: -5px;
	max-width: 190px;
}

.repoDefaultPreviewPanel-message--follow-lineSpacer{
	height: 5px;
}

// show upgrade message if hover on button or on message
.repoDefaultPreviewPanel--free button.invite{
	pointer-events: auto; // allow free message
	cursor: default;
}

.repoDefaultPreviewPanel--free button.invite:hover ~ .repoDefaultPreviewPanel-message--invite,
.repoDefaultPreviewPanel--free .repoDefaultPreviewPanel-message--invite:hover{
	opacity: 0.7;
	transition: opacity 100ms linear;
}

.repoDefaultPreviewPanel:hover .repoDefaultPreviewPanel-message{
	color: $textBlack;
}

.repoDefaultPreviewPanel--free .repoDefaultPreviewPanel-message--invite:hover {
	color: $textBlue;
	opacity: 1;
	text-decoration: underline;
}

.repoDefaultPreviewPanel-message--submitEarlyReleaseApproval{
	max-width: 240px;	
	color: $textBlack;
}

a.repoDefaultPreviewPanel-message--submitEarlyReleaseApproval-link,
a.repoDefaultPreviewPanel-message--submitEarlyReleaseApproval-link:visited{
	margin-top: 2px;
	padding-top: 3px;
	padding-bottom: 3px;
	display: block;
	color: inherit;
}

a.repoDefaultPreviewPanel-message--submitEarlyReleaseApproval-link:hover{
	color: $blue-bold;
	text-decoration: underline !important;
}


//
// MEMBER LIST 
//

.repoDefaultPreviewPanel-memberList{
	
	position: absolute; // for scrolling

	cursor: default;
	//background: $tempr;
	top: 82px;
	bottom: 0px;
	left: 0px;
	right: 0px;

	overflow-y: auto;
}

.repoDefaultPreviewPanel--viewer
.repoDefaultPreviewPanel-memberList{
	top: 30px;
}

.repoDefaultPreviewPanel-memberList-item{

	margin-bottom: 6px;
	height: 30px;	
	
	display: flex;

	align-items: center;
		
}

.repoDefaultPreviewPanel-memberList-item--pending
.repoDefaultPreviewPanel-memberList-item-labelPrimary{
	color: $textMid;
}

.repoDefaultPreviewPanel-memberList-item .profilePicContainer{
	flex-shrink: 0;
	flex-grow: 0;
}

.repoDefaultPreviewPanel-memberList-item-labelPrimary{
	flex-shrink: 1;
	flex-grow: 1;

	font-size: 14.5px;
	font-weight: $lato-semibold;
	color: $textBlackDark;

	margin-left: 10px;
	white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

}

//
// CONSUMER FOOTER
//


.repoDefaultPreviewPanel-consumerFooter{
	position: absolute;
	bottom: 10px;
	left: 0px;
	right: 0px;
	// height: 50px;
	//background: $tempr;
}

// Review Button

button.btn.btn--mid.repoDefaultPreviewPanel-reviewBtn{
	margin-top: 3px;	
	margin-bottom: -6px;
	
	border-radius: 4px;
	width: 100%;
	background: none;
	// background: $tempr;
	box-shadow: none;
	border: none;
	padding-top: 6px;
	padding-bottom: 6px;
	padding-left: 6px;
	height: auto; // overwrite default height
	padding-right: 6px;
	margin-left: -6px;
	cursor: pointer;

	display: flex;

	text-align: left;

	line-height: initial;

	font-weight: $lato-bold;
	color: $textDark;
	color: transparentize($textBlue, 0.15);
	color: $textBlue;
	font-size: 13px;

	align-items: center;
	justify-content: flex-start;
}


button.btn.btn--mid.repoDefaultPreviewPanel-reviewBtn svg.icon{
	height: 11px;
	width: 11px;
	margin-right: 5px;
	fill: $textBlue;
}



button.btn.btn--mid.repoDefaultPreviewPanel-reviewBtn:hover{
	background: $blue-extraLight;
	background: rgba(0,0,20,0.02);

	color: $textBlue;
}


//
//

.repoDefaultPreviewPanel-authorBlock--headerLayout{
	// default state
	position: absolute;
	left: 0px;
	right: 0px;
	top: 22px;
}

.repoDefaultPreviewPanel-authorBlock--footerLayout{
	// used when can be submitted for pack review
	position: absolute;
	bottom: 10px;
	left: 0px;
	right: 0px;
}

	

.repoDefaultPreviewPanel-authorBlock-sectionHeader{
	font-weight: $lato-bold;
	font-size: 12px;	
	text-transform: uppercase;
	letter-spacing: 0.005em;
	color: $textLight;
	margin-top: 16px;
	margin-bottom: 3px;
	user-select: none;
	cursor: default;
}

.repoDefaultPreviewPanel-authorBlock-sectionNumber{
	font-weight: $lato-semibold;
	color: $textBlackDark;
	font-size: 14px;
	user-select: none;
	cursor: default;
}

.repoDefaultPreviewPanel-authorBlock-shareBlock{
	margin-top: 0px;
	margin-bottom: 22px;
}

.repoDefaultPreviewPanel-authorBlock--footerLayout
.repoDefaultPreviewPanel-authorBlock-shareBlock{
	margin-bottom: 0px;
}

.repoDefaultPreviewPanel-authorBlock-statusLive{
	display: flex;
	align-items: center;
	justify-content: center;

	font-size: 12px;
	font-weight: $lato-bold;
	letter-spacing: 0.05em;
	color: $white;
	
	width: 64px;
	padding-top: 3px;
	padding-bottom: 3px;
	
	background: $color-o85;
	border-radius: 2px;
	margin-top: 2px;

	text-transform: uppercase;

	margin-bottom: 2px;

	cursor: default;
	user-select: none;

}

.repoDefaultPreviewPanel-authorBlock-statusLive .icon{
	fill: $white;
	height: 13px;
	width: 13px;
	margin-left: 5px;
}

//
// SHARE BLOCK
// used for consumers and authors
//



.repoDefaultPreviewPanel-consumerFooter-shareBlock-header,
.repoDefaultPreviewPanel-authorBlock-shareBlock-header{
	font-size: 14px;
	font-weight: $lato-bold;
	color: $textBlack;	
	width: 100%;		
	user-select: none;	
	cursor: default;
	margin-bottom: 3px;
}

.repoDefaultPreviewPanel-consumerFooter-shareBlock-URL,
.repoDefaultPreviewPanel-authorBlock-shareBlock-URL{
	font-size: 13px;
	font-weight: $lato-medium;
	color: $textBlack;
	word-break: break-all;
	margin-bottom: 8px;
}

.repoDefaultPreviewPanel-consumerFooter-shareBlock-license,
.repoDefaultPreviewPanel-authorFooter-shareBlock-license{
	font-size: 12.5px;
	font-weight: $lato-medium;
	color: $textLight;
	margin-top: 6px;
	cursor: default;
	user-select: none;
}




//
// SUBMIT STATUS
//

.repoDefaultPreviewPanel-earlyReleaseApprovalStatus{
	max-width: 200px;	
	cursor: default;
}

.repoDefaultPreviewPanel-earlyReleaseApprovalStatus-title{
	font-weight: $lato-bold;
	font-size: 12px;	
	text-transform: uppercase;
	letter-spacing: 0.005em;
	color: $textLight;	
	

	margin-bottom: 7px;
}

.repoDefaultPreviewPanel-earlyReleaseApprovalStatus-statusLabel{
	width: 100%;
	display: flex;
	margin-bottom: 5px;
}

.repoDefaultPreviewPanel-earlyReleaseApprovalStatus-statusLabel-iconContainer{
	width: 16px;
	height: 16px;
	
	border-radius: 2px;
	margin-right: 6px;
	flex-grow: 0;
	flex-shrink: 0;
}

// .repoDefaultPreviewPanel-earlyReleaseApprovalStatus--submitted{
// 	border-left: 2px solid $blue-bold;
// 	padding-left: 8px;
// 	margin-right: -8px;
// 	// background: white;
// 	// border: 1px solid $blue-bold;
// }

// .repoDefaultPreviewPanel-earlyReleaseApprovalStatus--submitted
// .repoDefaultPreviewPanel-earlyReleaseApprovalStatus-statusLabel-iconContainer svg{
// 	fill: $blue-bold;
// 	height: 14px;
// 	width: 14px;

// 	position: relative;
// 	top: -1px;
// 	right: -3px;

// }

.repoDefaultPreviewPanel-earlyReleaseApprovalStatus--in-review
.repoDefaultPreviewPanel-earlyReleaseApprovalStatus-statusLabel-iconContainer{
	background: $classColor-orange;
}

.repoDefaultPreviewPanel-earlyReleaseApprovalStatus--approved
.repoDefaultPreviewPanel-earlyReleaseApprovalStatus-statusLabel-iconContainer{
	background: $color-o85;
}

.repoDefaultPreviewPanel-earlyReleaseApprovalStatus-statusLabel-label{
	font-size: 14px;
	font-weight: $lato-bold;
	color: $textBlack;
}



.repoDefaultPreviewPanel-earlyReleaseApprovalStatus-statusDescription{
	font-size: 12px;
	font-weight: $lato-medium;
	color: $textMid;
	line-height: 1.2;
}

.repoDefaultPreviewPanel:hover
.repoDefaultPreviewPanel-earlyReleaseApprovalStatus-statusDescription{
	color: $textBlack;
}
