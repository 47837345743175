.upgrade--payment{
	padding-top: 64px;
	padding-bottom: 96px;

	padding-left: 56px;
	padding-right: 56px;

	position: relative;

	cursor: default;
	user-select: none;
}

.upgrade--payment-content{
	width: 100%;
	max-width: 960px;
	margin: 0 auto;
}


.upgrade--payment-logoContainer{
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;

	margin-bottom: 32px;
}

.upgrade--payment-logoContainer .icon{
	height: 42px;
}

.upgrade--payment-header{
	font-size: 46px;
	font-weight: $effra-bold;
	color: $textBlack;
	text-align: center;

	margin-bottom: 10px;
}

.upgrade--payment-sub{
	font-size: 24px;
	font-weight: $effra-medium;
	color: $textMid;
	text-align: center;

	margin-bottom: 60px;
}

// HEADERS

.upgrade--payment-sectionStep{
	font-size: 14px;
	font-weight: $effra-medium;
	color: $textBlue;
	text-transform: uppercase;
	margin-bottom: 8px;
}

.upgrade--payment-sectionHeader{
	font-size: 28px;
	font-weight: $effra-medium;
	color: $textDark;
}

.upgrade--payment-sectionHeader-sub{
	font-size: 21px;
	font-weight: $effra-medium;
	color: rgb(222,111,112);
	margin-top: 3px;
}

@import 'Upgrade-Payment-PlanSelection';
@import 'Upgrade-Payment-PaymentForm';