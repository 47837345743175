.update-username .modal-content{
	height: 500px;
	width: 660px;

	border-radius: 10px;
	padding-left: 44px;
	padding-right: 60px;
	padding-top: 36px;
	box-sizing: border-box;
}
.updateUserNameModal{ // bad, should refactor to separate form of modal as default
	width: 100%;
	height: 100%;
}

.updateUserNameModal-header,
.updateUserNameModal-sub{
	user-select: none;
	cursor: default;
}

.updateUserNameModal-header{
	font-size: 28px;
	font-weight: $lato-heavy;
	margin-bottom: 10px;
	color: $textBlackDark;
}

.updateUserNameModal-sub{
	margin-bottom: 14px;
	font-weight: $lato-medium;
	color: $textMid;
	font-size: 16px;
}



// FORM

.update-username .modal-content form{
	width: 510px;
}

.update-username form .formField.is--text{
	margin-bottom: 5px;
}

.update-username form .formField.is--text input{
	height: 42px;	
	padding-left: 12px;
	font-weight: $lato-bold;	
}


// Character Counter
.updateUserNameModal-characterCounter{
	position: relative;

	left: 445px; // quite brittle
	top: -44px;

	width: 60px;
	height: 24px;
	text-align: right;
	
	box-sizing: border-box;
	padding-right: 5px;
	pointer-events: none;
	border-radius: 4px;
	z-index: 5;

	font-size: 14px;
	font-weight: $lato-semibold;
	color: $textLight;

	// background: $tempr;

	display: flex;
	align-items: center;
	justify-content: flex-end;
}

.updateUserNameModal-characterCounter--aboveMax{
	background: transparentize($red, 0.9);
	color: $red-dark;
	font-weight: $lato-bold;
}


// Notes

.updateUserNameModal-notes{
	height: 150px;
	cursor: default;
	margin-top: -24px; // to offset character count
	// background: $tempr;
}

.updateUserNameModal-notes-note{
	font-size: 14.5px;
	font-weight: $lato-medium;
	color: $textDark;
	margin-bottom: 7px;
}

.updateUserNameModal-notes-note--bold{
	color: $red-dark;
	color: $textBlackDark;
	font-weight: $lato-bold;
}


// Buttons

.updateUserNameModal-actionRow{
	display: flex;
	margin-top: 20px;
	margin-bottom: 18px;
}

.updateUserNameModal-actionRow button{
	font-weight: $lato-bold;
	letter-spacing: 0.005em;
	cursor: pointer;
}

.updateUserNameModal-actionRow button.update{
	width: 340px;
	margin-right: 14px;
}

.updateUserNameModal-actionRow button.cancel{
	width: 160px;
}



.updateUserNameModal-errorSpace {
	height: 31px;
	width: 510px;
	margin-bottom: 4px;
	
	border-radius: 4px;

	padding: 8px;

	box-sizing: border-box;

	position: relative;
	font-size: 14.5px;
	top: -101px;

	font-weight: $lato-bold;
	color: $red-dark;

	cursor: default;
	opacity: 0;
	transition: all 50ms linear;
}

.updateUserNameModal-errorSpace--hasError{
	background: transparentize($red, 0.9);
	opacity: 1;
	transition: opacity 100ms;
}


.updateUserNameModal-smallPrint{
	margin-top: -38px;
	font-size: 14.5px;
	font-weight: $lato-semibold;
	color: $textMid;
	padding-top: 4px;
	padding-bottom: 4px;
	cursor: default;
	line-height: 1.3;
}

// a.addLicenseKeyModal-link,
// a.addLicenseKeyModal-link:visited{
// 	font-size: 14.5px;
// 	font-weight: $lato-semibold;
// 	color: $textLight;
// 	padding-top: 4px;
// 	padding-bottom: 4px;
// 	cursor: pointer;
// 	line-height: 1.3;
// }

// a.addLicenseKeyModal-link:hover,
// a.addLicenseKeyModal-link:visited:hover{
// 	color: $textBlue;
// 	text-decoration: underline !important; //overwrite normalized base
// }